import { Card, CardBody, Table } from "reactstrap";
import DownloadFile from "../../assets/images/download.svg";
import CollapseComponent from "../../components/layout/Collapse";
import { realreppDocuments } from "./documentFile";

const RealreppDocuments = () => {
  return (
    <div className="admin-tabs team-tabs">
      <div className="primaryHeading primaryHeaderSpacing">
        <h5 className="m-0">RealREPP Documents</h5>
      </div>

      <div className="reports-wrapper">
        {realreppDocuments?.map((item, index) => (
          <CollapseComponent title={item?.name} key={index}>
            <Card className="border-0">
              <CardBody>
                <div className="table-responsive">
                  <Table borderless={true} className="border-0 document-table">
                    <tbody>
                      {item?.data?.map((value, index: number) => (
                        <tr key={index} className="border-divider">
                          <td className="w-70 p-0">{value.name}</td>
                          <td className="justify-content-end d-flex">
                            <a
                              href={value.url}
                              rel="noreferrer"
                              target="_blank"
                              className="d-flex align-items-center"
                            >
                              <img
                                src={DownloadFile}
                                alt="download"
                                className="mr-2"
                              />
                              Download
                            </a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </CardBody>
            </Card>
          </CollapseComponent>
        ))}
      </div>
    </div>
  );
};

export default RealreppDocuments;
