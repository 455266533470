import { useMutation } from '@apollo/client';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Form, Spinner } from 'reactstrap';
import { states_candidate_constant } from '../../../utils/constant';
import '../../company/company.scss';
import { ADD_NEW_COMPANY_ADDRESS, UPDATE_ADDRESS } from '../gql';
import AddressInfo from './AddressInfo';

const AddUpdateAddress = ({
  companyId,
  formToggle,
  setFormToggle,
  addressObj,
  addressId,
  listLength,
  ...props
}: any) => {
  //constants
  const { errors, handleSubmit, register, reset, watch,setValue } = useForm();
  //mutations
  const [addNewCompanyAddress, { loading }] = useMutation(
    ADD_NEW_COMPANY_ADDRESS
  );
  const [updateAddress] = useMutation(UPDATE_ADDRESS);

  //methods
  const createCompanyAddress = async (address: any) => {
    address.companyId = companyId;
    address.country=address.countryCode;
    address.state=address.stateCode;
    delete address.countryCode;
    delete address.stateCode;
    await addNewCompanyAddress({ variables: { addressInput: { ...address } } });
    props.refetchAddress();
    setFormToggle(false);
    reset();
    toast.success('Address successfully added!');
  };

  const updateAddressHandler = async (data) => {
    data.addressId = addressId;
    data.country = data.countryCode;
    data.state = data.stateCode;
    delete data.countryCode;
    delete data.stateCode;
 
    let response = await updateAddress({ variables: { updateAddressInput: {...data} }});
    if (response?.data?.updateAddress?.status === 200) {
      setFormToggle(false);
      reset();
      props.refetchAddress();
      toast.success('Address Updated Successfully!');
    } else {
      setFormToggle(false);
      toast.error('Address could not be Updated!');
    }
  }

  useEffect(() => {
    if (addressId && addressObj) {
      const { streetAddress, city, state, zipCode, country } = addressObj;
      
      // Set the initial values of the form fields using an object
      const initialValues = {
        streetAddress: streetAddress || '',
        city: city || '',
        stateCode: state || '',
        zipCode: zipCode || '',
        countryCode: country || 'US'
      };
  
      // Call the reset function from react-hook-form with the initial values object
      reset(initialValues);
    } else {
      // Call the reset function with an empty object to clear the form
      reset && reset({stateCode: '', city: '', zipCode: '', streetAddress: '', countryCode: 'US'});
    }
  }, [addressId, addressObj, reset]);


    useEffect(()=>{
      if (!addressObj?.id) {
        reset && reset({stateCode: '', city: '', zipCode: '', streetAddress: '', countryCode: 'US'});
      }
    },[addressObj, reset])

  const handleFormSubmit = async (data) => {
    if (addressId){
      updateAddressHandler(data);
    } else {
      createCompanyAddress(data);
    }
  }

  return (
    <Form>
      {formToggle &&    
        <div>
          <AddressInfo
            {...{ register, errors, companyId, watch, reset}}
            setValue={setValue}
            headQatar={false}
            addressObj={addressObj}
            givenStates={states_candidate_constant}
            defaultChecked={listLength ? false : true}
            formToggle={formToggle}
            addressId={addressId}
          />
          <div className="address-action">
            <button
              onClick={() => {
                setFormToggle(false);
              }}
              type="button"
              className="buttonPrimary mr-3"
            >
              Cancel
            </button>
            <button
              type="submit"
              onClick={handleSubmit(handleFormSubmit)}
              className="btn btn-primary"
            >
              {loading && <Spinner size="sm" className="mr-2" />}
              Save
            </button>
          </div>
        </div>}
    </Form>
  );
};

export default AddUpdateAddress;