import { useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Form } from 'reactstrap';
import GoBack from '../../components/GoBack';
import CustomButton from '../../components/layout/CustomButton';
// import DomainInfo from "./company-components/DomainInfo";
import SocialMediaInfo from '../../components/SocialMediaInfo';
import { UPDATE_COMPANY_ROUTE } from '../../utils/constant';
import { IComingState } from '../agreements/interfaces';
import '../company/company.scss';
import AddressInfo from './company-components/AddressInfo';
import BasicInfo from './company-components/BasicInfo';
import { CREATE_COMPANY } from './gql';

const CreateUpdateCompany = () => {
  const {
    errors,
    handleSubmit,
    register,
    reset,
    control,
    unregister,
    getValues,
    setValue,
    watch,
  } = useForm();

  const history = useHistory();
  const location = useLocation<IComingState>();

  const comingState = location.state;
  const [selectedIndustry, setSelectedIndustry] = useState<Array<any>>([]);
  const [_createCompany, { loading: waiting }] = useMutation(CREATE_COMPANY);

  const createCompany = async (values: any) => {
    values.hasLinkedIn =
      values.hasLinkedIn && !values.linkedInUrl ? false : true;
    values.industry =
      selectedIndustry && selectedIndustry?.length
        ? selectedIndustry?.map((item) => item.value)
        : [''];

    values.domains = values?.domain
      ? [
        {
          isPrimary: true,
          domain: values?.domain,
        },
      ]
      : [];
    values.country = values.countryCode;
    values.state = values.stateCode;
    delete values.domain;
    delete values.countryCode;
    delete values.stateCode;
    let responseOfCreateCompany = await _createCompany({
      variables: { companyInput: { ...values } },
    });
    reset();
    if (responseOfCreateCompany?.data) {
      toast.success('Company successfully created!');
      setTimeout(() => {
        history.push(
          `${UPDATE_COMPANY_ROUTE}/${responseOfCreateCompany?.data?.createCompany?.id}`
        );
      }, 800);
    }
  };

  // setting title page
  useEffect(() => {
    document.title = 'Create Company - RealREPP';
  }, []);

  return (
    <div className="admin-tabs team-tabs">
      <GoBack url="/companies" search={comingState?.searchParameters} />
      <div className="primaryHeading">
        <h5 className="m-0">Add Company</h5>
      </div>

      <div className="jobOrderRevamp agreementLabel">
        <Form onSubmit={handleSubmit(createCompany)}>
          <h5>Basic Info</h5>
          <BasicInfo
            {...{
              getValues,
              setValue,
              unregister,
            }}
            control={control}
            register={register}
            errors={errors}
            selectedIndustry={selectedIndustry}
            setSelectedIndustry={setSelectedIndustry}
          />

          <h5>Address</h5>
          <AddressInfo
            watch={watch}
            register={register}
            errors={errors}
            setValue={setValue}
          />

          <h5 className="mt-2">Social Media</h5>
          <SocialMediaInfo
            setValue={setValue}
            isLinkedinRequired={true}
            register={register}
            errors={errors}
            companyAddress={true}
            getValues={getValues}
          />

          <div className="d-flex align-items-center justify-content-end tab-pane-padding pt-0">
            <CustomButton
              buttonText="Add Company"
              buttonColor="primary"
              buttonType="submit"
              className="big-width"
              loading={waiting}
              disabled={waiting}
            />
          </div>
        </Form>
      </div>
    </div>
  );
};

export default CreateUpdateCompany;
