import { useMutation } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  Button,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import CustomButton from "../../../components/layout/CustomButton";
import { CREATE_RECRUITER_FOLDER, EDIT_RECRUITER_FOLDER } from "../gql";

import CLOSE_ICON from "../../../assets/images/close-icon.svg";

const CreateFolderModal = ({
  isOpen,
  toggle,
  reFetch,
  folderName: name,
  folderId,
}) => {
  const [folderName, setFolderName] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [createFolder, { loading, error }] = useMutation(
    CREATE_RECRUITER_FOLDER
  );
  const [editFolder, { loading: waiting }] = useMutation(EDIT_RECRUITER_FOLDER);

  const createRecruiterFolder = async () => {
    if(!folderName.trim().length){
      setErrMsg("Folder name is required");
      return
    }
    let res = await createFolder({ variables: { folderName } });
    if (res?.data) {
      toast.success("Folder successfully created!");
      setFolderName("");
      toggle();
      reFetch();
    } else {
      if (error?.graphQLErrors[0]?.message) {
        setErrMsg(error?.graphQLErrors[0]?.message);
        return;
      }
      setErrMsg("Problem while creating folder!");
    }
  };

  const updateRecruiterFolder = async () => {
    let res = await editFolder({ variables: { folderName, folderId } });
    if (res?.data) {
      toast.success("Updated successfully!");
      setFolderName("");
      toggle();
      reFetch();
    } else {
      if (error?.graphQLErrors[0]?.message) {
        setErrMsg(error?.graphQLErrors[0]?.message);
        return;
      }
      setErrMsg("Problem while creating folder!");
    }
  };

  useEffect(() => {
    if (name) {
      setFolderName(name);
    } else {
      setFolderName("");
    }
    setErrMsg("");
  }, [name]);
  
  return (
    <Modal isOpen={isOpen} toggle={toggle} className="revampDialog">
      <div className="modalHeaderCustom d-flex align-items-center justify-content-between">
        <h5 className="m-0">
          {folderId ? "Edit Folder" : "Create Recruiter Folders"}
        </h5>

        <Button className="p-0 bg-transparent border-0" onClick={toggle}>
          <img src={CLOSE_ICON} alt="close" />
        </Button>
      </div>

      <ModalBody>
        <FormGroup>
          <Label>Folder Name</Label>
          <Input
            name="content"
            placeholder="Folder Name"
            onChange={({ target: { value } }) => setFolderName(value)}
            value={folderName}
          />
          <small className="text-danger">{errMsg}</small>
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <button
          onClick={() => {
            toggle();
            setFolderName("");
          }}
          className="buttonPrimary mr-3"
          type="button"
        >
          Cancel
        </button>
        <CustomButton
          buttonText={folderId ? "Save" : "Create"}
          buttonColor="primary"
          buttonType="submit"
          className="big-width"
          buttonClick={() => {
            folderId ? updateRecruiterFolder() : createRecruiterFolder();
          }}
          disabled={loading || waiting}
          loading={loading || waiting}
        />
      </ModalFooter>
    </Modal>
  );
};

export default CreateFolderModal;
