import  { useState } from 'react'
import { Tooltip } from "reactstrap";
const AttachmentDocument = ({onDownloadAttachment, item}) => {
  const [tooltipOpen3, setTooltipOpen3] = useState(false);
  const toggle3 = () => setTooltipOpen3(!tooltipOpen3);  
  return (
       // download
    <div
        onClick={() => {    
            onDownloadAttachment(
                item?.documentUrl,
                item?.documentName
            );
        }}
        >
        <Tooltip
                placement="right"
                isOpen={tooltipOpen3}
                target={`attachment-${item?.id}`}
                toggle={toggle3}
            >
            {item?.documentName || "--"}
        </Tooltip>
        <div className="ellipsisTextStyle" id={`attachment-${item?.id}`}> {item?.documentName || "--"}</div>       
    </div>
  )
}
export default AttachmentDocument