import { FC } from "react";

type LOADER = {
  errorText?: string;
  loaderClass?: string;
};

const Loader: FC<LOADER> = ({ errorText, loaderClass }) => {
  return (
    <div
      className={loaderClass ? `${loaderClass} error-loading` : "error-loading"}
    >
      {errorText ? errorText : <div className="loader"></div>}
    </div>
  );
};
export default Loader;
