import { useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import {
  Form,
  FormGroup,
  Label,
} from 'reactstrap';
import CustomButton from '../../components/layout/CustomButton';
import { Sidedrawer, DrawerHeader } from '../../components/layout/Sidedrawer';
import { CREATE_SUBMISSION_DECLINE } from './gql';

export default function DeclineSubmissionModal({
  isOpen,
  toggle,
  submissionId,
  refetch,
  applicantId,
}) {
  const { register, handleSubmit, errors } = useForm();

  const [submissionDecline, { loading }] = useMutation(
    CREATE_SUBMISSION_DECLINE
  );

  const declineSubmission = async (values) => {
    values.submissionId = submissionId;
    values.applicantId = applicantId;
    let res = await submissionDecline({
      variables: { submissionHistory: values },
    });
    if (res?.data) {
      toast.info('Submission decline!');
      toggle();
      refetch();
    }
  };

  return (
    <Sidedrawer toggle={toggle} isOpen={isOpen}>
      <div className="mb-3">
        <DrawerHeader
          prevClick={() => toggle()}
          nextClick={handleSubmit(declineSubmission)}
          buttonText="Decline"
          heading="Candidate Submission"
          // prevButtonView={false}
          nextButtonView={false}
        />
      </div>
      <Form>
        <FormGroup>
          <Label className="text-14 text-blackele pb-3">
            Decline Reason<span className="text-danger">*</span>
          </Label>

          <textarea
            className="form-control"
            name="reason"
            rows={5}
            ref={register({
              required: { value: true, message: 'Reason must be define!' },
            })}
            placeholder="Candidate decline reason..."
          />
          <small className="text-danger mt-1">{errors?.reason?.message}</small>
        </FormGroup>

        <div className="text-right py-3">
          <CustomButton
            buttonText="Decline"
            buttonColor="primary"
            buttonType="submit"
            className="big-width"
            loading={loading}
            disabled={loading}
            buttonClick={handleSubmit(declineSubmission)}
          />
        </div>
      </Form>
    </Sidedrawer>
  );
}
