import { useLazyQuery, useMutation } from "@apollo/client";
import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Badge, Button, Input, InputGroup, InputGroupAddon, InputGroupText, Spinner } from "reactstrap";
import REFRESH_ICON from '../../assets/images/refresh-icon.png';
import SEARCH_IMG from '../../assets/images/search.svg';
import ContentViewModal from "../../components/ContentViewModal";
import DownloadButton from "../../components/DownloadButton";
import CustomButton from "../../components/layout/CustomButton";
import InputField from "../../components/layout/InputField";
import Loader from "../../components/layout/Loader";
import NoDataFound from "../../components/layout/NoDataFound";
import Pagination from "../../components/layout/Pagination";
import TableComponent from "../../components/layout/TableComponent";
import { ICSVImportsData } from "../../interfaces";
import { formatDateUSWithMoment } from "../../utils/commonFn";
import { ADMIN_IMPORTS_HEADER, ADMIN_IMPORT_SOURCE, ALL_IMPORTS_HEADER, FAILED_IMPORT, IMPORT_SOURCE, PAGE_LIMIT } from "../../utils/constant";
import { getCSVImportsStatusColor } from "../../utils/helper";
import ModalUpload from "./ModalUpload";
import { LIST_DATA_CSV_IMPORTS, RETRY_DATA_CSV_IMPORTS } from "./gql";

const RetryButton = ({ id, status, refetch }) => {
  const [retryDataCsvImport, { loading: retryLoader }] = useMutation(RETRY_DATA_CSV_IMPORTS)
  const retryAction = async (id: string) => {
    try {
      const response = await retryDataCsvImport({ variables: { id } })
      if (response?.data?.retryDataCsvImport?.status === 201) {
        toast.success("Import has been re-started!")
      }
      refetch()
    } catch {
      toast.error("Unable to retry")
    }
  }
  return (
    <Button className="bg-transparent border-0 p-0 mr-3 fade-button"
      disabled={status !== FAILED_IMPORT}
    >
      {
        retryLoader ?
          <Spinner size="sm" style={{ color: "#eaa827" }} className="m-0" /> :
          <img className="m-0" src={REFRESH_ICON}
            onClick={async () => {
              await retryAction(id)
            }} alt="retry" />}
    </Button>
  )
}

const AllImports = ({ adminImport }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [isOpenErrorModal, setIsOpenErrorModal] = useState<boolean>(false)
  const [ErrorMessage, setErrorMessage] = useState<string>()
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [fileName, setFileName] = useState<string>('')
  const [listDataCSVImports, { data: listData, loading: listLoading, refetch }] = useLazyQuery(LIST_DATA_CSV_IMPORTS);

  const listCSVImports = useCallback(async () => {
    if (fileName === '') {
      await listDataCSVImports({
        variables: {
          adminImport,
          limit,
          fileName: '',
          page: currentPage,
        },
      });
    }
  }, [currentPage, limit, fileName, listDataCSVImports]);

  useEffect(() => {
    listCSVImports();
  }, [listCSVImports]);

  // const deleteEntry = async () => {
  //   try {
  //     const response = await removeDataCSVImport({ variables: { id: recordId } })
  //     if (response?.data?.removeDataCsvImport?.status === 200) {
  //       toast.success("Successfully deleted!")
  //       refetch()
  //     }
  //   }
  //   catch {
  //     toast.error("Error while deletion!")
  //   }
  //   setDeleteStatus(false)
  // }

  const searchControl = async (value: any) => {
    setFileName(value);
    if (value?.length > 2) {
      await listDataCSVImports({
        variables: {
          limit: 10,
          fileName: value,
          adminImport,
          page: 1,
        },
      });
    }
  };
  const mapCSVListData = ({
    id, fileName, sourceType, user: { firstName, lastName },
    jobStartedAt, status, fileUrl, jobResponse, totalCSVRows
  }: ICSVImportsData) => {
    return (
      <>
        <td>{fileName}</td>
        <td>{[...IMPORT_SOURCE, ...ADMIN_IMPORT_SOURCE].find(({ value }) => value === sourceType)?.label}</td>

        <td> {`${firstName} ${lastName}`}</td>
        <td>{formatDateUSWithMoment(jobStartedAt)}</td>

        {!adminImport && <td>{totalCSVRows || '--'}</td>}
        <td className='badge-pading'>
          <button className="bg-transparent border-0 p-0 mr-3"
            disabled={status !== FAILED_IMPORT}
            onClick={() => {
              if (status === FAILED_IMPORT) {
                setErrorMessage(jobResponse)
                setIsOpenErrorModal(true)
              }
            }}>
            <Badge
              color={getCSVImportsStatusColor(status)}
              className="text-capitalize"
            >
              {status}
            </Badge>
          </button>
        </td>
        <td>
          {!adminImport && <RetryButton id={id} status={status} refetch={refetch} />}
          <DownloadButton url={fileUrl} fileType="csv" nickName={fileName?.split(".csv")[0]}
          />
          {/* <Button className="bg-transparent border-0 p-0">
            <img src={DELETE_ICON}
              onClick={() => {
                setDeleteStatus(true)
                setRecordId(id)
              }} alt="delete"
            />
          </Button > */}
        </td>
      </>
    )
  }

  return (
    <>
      <div className="search-filter primaryHeaderSpacing">
        <div>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <img src={SEARCH_IMG} alt="search" />
              </InputGroupText>
            </InputGroupAddon>
            <Input type="text" placeholder="Search File Name"
              value={fileName} onChange={(e) => searchControl(e.target.value)} className="search-by-company"
            />
          </InputGroup>
          <small className="text-info">
            {fileName &&
              fileName.length <= 2 &&
              "Please add more than two characters"}
          </small>
        </div>
        <div className="filter-status">
          <div className="filter-by">
            <CustomButton className="ml-auto" buttonText="Import CSV"
              buttonClick={() => setIsOpen(!isOpen)}
            />
          </div>
        </div>
      </div>

      <div className="table-responsive">
        <TableComponent tableHeader={adminImport ? ADMIN_IMPORTS_HEADER : ALL_IMPORTS_HEADER}>
          <tbody>
            {listLoading && (
              <td colSpan={14}>
                <Loader loaderClass="sm-height " />
              </td>
            )}
            {!listLoading && !listData?.listDataCsvImport?.dataCsvImports?.length && (
              <td colSpan={14} className="empty-table-td">
                <NoDataFound text="No Record Found!" />
              </td>
            )}
            {!listLoading && listData?.listDataCsvImport?.dataCsvImports?.map((item: ICSVImportsData, index: number) => {
              return <tr key={index}>{mapCSVListData(item)}</tr>
            })}
          </tbody>
        </TableComponent>
      </div >

      {!listLoading && !!listData?.listDataCsvImport?.dataCsvImports?.length &&
        <div className="users-pagination" >
          <Pagination
            onPageChange={(pageClicked: number) => {
              setCurrentPage(pageClicked);
            }} currentPage={currentPage}
            pageCount={Math.ceil(listData?.listDataCsvImport?.count / limit)}
          />
          <div className="d-flex align-items-center">
            <div className="pagination-display paginationItemsSelect">
              <p className="m-0">
                {(currentPage - 1) * limit + 1} to{' '}
                {Math.min(
                  currentPage * limit,
                  listData?.listDataCsvImport?.count
                )}
                {` of `} {listData?.listDataCsvImport?.count}
              </p>

              <div className="divider"></div>
              <label>Display</label>
              <InputField
                label=""
                inputtype="select"
                selectItems={PAGE_LIMIT.filter((item) => item.value !== limit)}
                inputid="limit"
                placeholder={limit.toString()}
                inputMethod={(value) => {
                  setCurrentPage(1);
                  setLimit(parseInt(value));
                }}
              />
            </div>
          </div>
        </ div>}

      <ModalUpload isOpen={isOpen} refetch={refetch} toggle={() => setIsOpen(!isOpen)} adminImport={adminImport} />
      <ContentViewModal isOpen={isOpenErrorModal} heading={"Error Message"} toggle={() => setIsOpenErrorModal(!isOpenErrorModal)}
        content={ErrorMessage}
      />
    </>
  )
}

export default AllImports