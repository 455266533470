import { useLazyQuery, useMutation } from "@apollo/client";
import { useCallback, useEffect, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { FormGroup, Label, Spinner } from "reactstrap";
import { reactQuillReg } from "../../utils/constant";
// import ConfirmPopUp from "../../components/ConfirmPopUp";
import { CREATE_OR_UPDATE_JOB_LIST_NOTES, GET_JOB_LIST_NOTES } from "./gql";
import { AllJobListNotes, IJobListNotes } from "../../interfaces";
import ReactQuill from "react-quill";
import { useLocation } from "react-router-dom";
import { IComingState } from "../agreements/interfaces";
import Loader from "../../components/layout/Loader";

const JobListNotes = ({
  jobOrderId,
  haveActionPersmissions,
}: {
  jobOrderId: string;
  haveActionPersmissions?: boolean;
}) => {

  //location
  const location = useLocation<IComingState>();
  const { pathname } = location;
  const isViewJobOrder = pathname.split('/')[1] === 'view-job-order'

  //localstates  
  const page = parseInt('1');
  const limit = parseInt('10')
  //apollo
  const [getNotes] = useLazyQuery(GET_JOB_LIST_NOTES);
  const [_createOrUpdateJobListNotes, { loading: createOrUpdateLoading }] = useMutation(CREATE_OR_UPDATE_JOB_LIST_NOTES);

  //useForm
  const { handleSubmit, control, errors, reset, setValue } = useForm();

  //api call
  const updateNote = async (values: IJobListNotes) => {
    const { data } = await _createOrUpdateJobListNotes({ variables: { createOrUpdateJobListNotes: { jobOrderId, ...values } } });
    const { createOrUpdateJobListNotes } = data || {};
    const { jobListNotes, message, status } = createOrUpdateJobListNotes || {};

    toast.dismiss()
    if ([200, 201, 202, 203].includes(status)) {
      // Show success toast
      toast.success(message, {
        onOpen: () => {
          // Set form values
          setValue('salaryNote', jobListNotes?.salaryNote || "");
          setValue('urgencyNote', jobListNotes?.urgencyNote || "");
          setValue('pipelineNote', jobListNotes?.pipelineNote || "");
        }
      });
    }
    else {
      toast.error('Something Went Wrong!!');
      reset();
    }
  };

  //hooks
  const getNoteList = useCallback(async () => {
    if (jobOrderId) {
      const { data } = await getNotes({ variables: { input: { jobOrderId, limit, page } } });
      const { getAllJobListNotes }: AllJobListNotes = data || {};
      const { jobListNotes } = getAllJobListNotes || {};
      if (jobListNotes) {
        setValue('salaryNote', jobListNotes[0]?.salaryNote || "")
        setValue('urgencyNote', jobListNotes[0]?.urgencyNote || "")
        setValue('pipelineNote', jobListNotes[0]?.pipelineNote || "")
      }
      else {
        reset()
      }
    }
  }, [jobOrderId, getNotes, limit, page, setValue, reset])

  useEffect(() => {
    getNoteList()
  }, [getNoteList]);

  const customStyles = {
    control: (styles, provided) => ({
      ...styles,
      fontSize: 12,
    }),

    container: (provided, state) => ({
      ...provided,
      fontSize: 12,
    }),

    option: (provided, state) => ({
      ...provided,
      fontSize: 12,
    }),
  };

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: [2, 3, 4, false] }],
          ["bold", "italic", "underline", "blockquote"],
          [{ color: [] }, { background: [] }],
          [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
          ],
          ["link", "image"],
          ["clean"],
        ],
        // handlers: {
        //   image: imageHandler,
        // },
      },
      clipboard: {
        matchVisual: true,
      },
    }),
    []
  );

  return (
    <>
      {
        createOrUpdateLoading ?
          <Loader />
          :
          <div>
            <FormGroup>
              <Label>
                Salary Note {!isViewJobOrder ? <span className="text-danger">*</span> : ''}
              </Label>
              <div className="editor-height">
                <Controller
                  control={control}
                  name="salaryNote"
                  rules={{
                    ...(!isViewJobOrder
                      ?
                      {
                        required: {
                          value: true,
                          message: 'Add some salary note content!',
                        },
                      }
                      : {}),// No rules for salaryNote when the path is 'view-job-order'
                  }}
                  defaultValue={null}
                  render={({ onChange, ref, value }) => (
                    <div className="editor-height">
                      <ReactQuill
                        modules={modules}
                        onChange={(value) => {
                          if (reactQuillReg.test(value)) {
                            onChange(null);
                          } else {
                            onChange(value);
                          }
                        }}
                        readOnly={isViewJobOrder}
                        ref={ref}
                        value={value}
                      />
                      <small className="text-danger pt-2">
                        {errors?.salaryNote?.message}
                      </small>
                    </div>
                  )}
                  styles={customStyles}
                />
              </div>
            </FormGroup>

            <FormGroup>
              <Label>
                Urgency Note {!isViewJobOrder ? <span className="text-danger">*</span> : ''}
              </Label>
              <div className="editor-height">
                <Controller
                  control={control}
                  name="urgencyNote"
                  rules={{
                    ...(!isViewJobOrder
                      ?
                      {
                        required: {
                          value: true,
                          message: 'Add some urgency note content!',
                        },
                      }
                      : {}),// No rules for salaryNote when the path is 'view-job-order'
                  }}
                  defaultValue={null}
                  render={({ onChange, ref, value }) => (
                    <div className="editor-height">
                      <ReactQuill
                        modules={modules}
                        onChange={(value) => {

                          if (reactQuillReg.test(value)) {
                            onChange(null);
                          } else {
                            onChange(value);
                          }
                        }}
                        readOnly={isViewJobOrder}
                        ref={ref}
                        value={value}
                      />
                      <small className="text-danger pt-2">
                        {errors?.urgencyNote?.message}
                      </small>
                    </div>
                  )}
                  styles={customStyles}
                />
              </div>
            </FormGroup>

            <FormGroup>
              <Label>
                Pipeline Note {!isViewJobOrder ? <span className="text-danger">*</span> : ''}
              </Label>
              <div className="editor-height">
                <Controller
                  control={control}
                  name="pipelineNote"
                  rules={{
                    ...(!isViewJobOrder
                      ?
                      {
                        required: {
                          value: true,
                          message: 'Add some pipeline note content!',
                        },
                      }
                      : {}),// No rules for salaryNote when the path is 'view-job-order'
                  }}
                  defaultValue={null}
                  render={({ onChange, ref, value }) => (
                    <div className="editor-height">
                      <ReactQuill
                        modules={modules}
                        onChange={(value) => {

                          if (reactQuillReg.test(value)) {
                            onChange(null);
                          } else {
                            onChange(value);
                          }
                        }}
                        readOnly={isViewJobOrder}
                        ref={ref}
                        value={value}
                      />
                      <small className="text-danger pt-2">
                        {errors?.pipelineNote?.message}
                      </small>
                    </div>
                  )}
                  styles={customStyles}
                />
              </div>
            </FormGroup>
            {!isViewJobOrder ?
              <>
                <div className="text-right actionLogTableRecamp">
                  <button
                    type="button"
                    disabled={createOrUpdateLoading}
                    className="buttonGenericStyle filledButton"
                    onClick={handleSubmit(updateNote)}
                  >
                    {(createOrUpdateLoading) && (
                      <Spinner
                        size="sm"
                        style={{ color: 'white' }}
                        className="mr-2"
                      />
                    )}
                    Save
                  </button>
                </div>
              </>
              : <></>
            }
          </div>
      }
    </>
  );
}

export default JobListNotes;