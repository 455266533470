import { Input } from 'reactstrap';

const notIncludedStatus = ['SUBMITTED', 'OFFERED', 'PLACED'];

export const CheckJobApplicantComponent = ({
  item,
  actionData,
  setActionData,
  forInterview
}) => {
  const handleCheckBox = (
    e,
    email: string,
    itemId: string,
    name: string,
    jobTitle: string,
    employment: Array<any>,
    candidateId: string,
    item: any
  ) => {
    if (item?.JobAppStatus !== "INTERVIEWED") {
      const userData = [...actionData];
      if (e.target.checked) {
        let data = userData?.filter((val) => val?.userId !== itemId && val);
        setActionData([
          ...data,
          { userId: itemId, url: email, name, jobTitle, employment, candidateId },
        ]);
      } else {
        const usersData = [...actionData];
        // eslint-disable-next-line
        const itemIndex = usersData.findIndex((el) => {
          if (el?.userId === itemId) {
            return el;
          }
        });

        usersData.splice(itemIndex, 1);

        setActionData(usersData);
      }
    } else {
      const pendingInterviewIds = item?.interviews?.filter((interviews) => { return interviews.InterviewState === "PENDING" }).map(pendingInterveiw => {
        return pendingInterveiw.id;
      });
      if (e.target.checked) {
        setActionData([...actionData, ...pendingInterviewIds]);
      } else {
        const pendingInterviewData = [...actionData];
        pendingInterviewIds.forEach(id => {
          const itemIndex = pendingInterviewData.indexOf(id);
          pendingInterviewData.splice(itemIndex, 1);
        });
        setActionData(pendingInterviewData);
      }
    }
  };

  return (
    <div>
      <Input
        type="checkbox"
        value={actionData.filter(data => data?.userId === item?.id)[0]}
        onChange={(e) => 
          handleCheckBox(
            e,
            item?.candidate?.emails?.find((item) => item.isPrimary)?.email ||
            item?.candidate?.linkedInUrl,
            item?.id,
            item?.candidate?.fullName,
            item?.candidate?.idealJobTitle,
            item?.candidate?.employmentHistory,
            item?.candidate?.id, item
            )
        }
        disabled={notIncludedStatus.includes(item?.JobAppStatus) || forInterview}
      />
    </div>
  );
};
