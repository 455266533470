import { gql } from "@apollo/client";

export const CREATE_OFFER = gql`
  mutation CreateOffer($createOfferDTO: CreateOfferDTO!) {
    createOffer(createOfferDTO: $createOfferDTO) {
      message
      status
    }
  }
`;

export const UPDATE_OFFER = gql`
  mutation UpdateOffer($updateOfferDTO: UpdateOfferDTO!) {
    updateOffer(updateOfferDTO: $updateOfferDTO) {
      message
      status
    }
  }
`;

export const GET_COUNTER_OFFER = gql`
  query CounterOffer(
    $candidateId: String!
    $jobOrderId: String!
    $jobApplicantId: String!
  ) {
    counterOffer(
      candidateId: $candidateId
      jobOrderId: $jobOrderId
      jobApplicantId: $jobApplicantId
    ) {
      id
      offeredSalaryType
      offeredSalary
      hireType
      offerStatus
      offerStartDateTime
      details
    }
  }
`;

export const GET_ALL_OFFER_LIST = gql`
  query AllOfferList(
    $page: Int!
    $limit: Int!
    $searchText: String
    $candidateId: String
    $jobApplicantId: String
    $jobOrderId: String
  ) {
    allOfferList(
      payload: { page: $page, limit: $limit }
      filters: {
        searchText: $searchText
        candidateId: $candidateId
        jobApplicantId: $jobApplicantId
        jobOrderId: $jobOrderId
      }
    ) {
      offers {
        id
        offeredSalary
        offeredSalaryType
        details
        hireType
        locked
        offerStatus
        offerStartDateTime
        dateString
        jobOrder {
          id
          jobTitle
          company {
            id
            name
          }
        }
        candidate {
          id
          fullName
          emails {
            id
            isPrimary
            email
          }
        }
        jobApplicant {
          id
        }
      }
      count
    }
  }
`;

export const GET_SINGLE_OFFER = gql`
  query GetSingleOffer($candidateId: String!, $jobOrderId: String!) {
    getSingleOffer(candidateId: $candidateId, jobOrderId: $jobOrderId) {
      id
      offeredSalaryType
      dateString
      offerStartDateTime
      offeredSalary
    }
  }
`;

export const UPDATE_OFFER_STATUS = gql`
  mutation UpdateOfferStatus($updatingOfferStatusInput: UpdatingOfferStatusInput!) {
    updatingOfferStatus(updatingOfferStatusInput: $updatingOfferStatusInput) {
      message
      status
      placementId
    }
  }
`;

export const REPLACE_PLACEMENT = gql `
  mutation ReplacePlacement($replacePlacementInput: ReplacePlacementInput!) {
    replacePlacement(replacePlacementInput: $replacePlacementInput) {
      message
      status
    }
  }
`;

export const VERIFY_INTERVIEW = gql`
  mutation verifyInterview(
    $verifyInterviewInput: verifyInterviewInput!) {
      verifyInterview( verifyInterviewInput: $verifyInterviewInput) {
      message
      status
    }
  }
`;
