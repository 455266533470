import { useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Col,
  Form,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
} from 'reactstrap';
import CustomButton from '../../../../components/layout/CustomButton';
import InputField from '../../../../components/layout/InputField';
import {
  EMAIL_PATTERN,
  PLACEMENT_CANCELLED,
  PLACEMENT_ENDED,
  VIEW_ACCOUNTING_DASHBOARD_ROUTE,
  VIEW_CANDIDATE_ROUTE,
  VIEW_PLACEMENT_MANAGEMENT_ROUTE,
  states_candidate_constant,
} from '../../../../utils/constant';

import {
  countryWiseDetailedlocation,
  pushInHistory,
} from '../../../../utils/helper';
import { IComingState } from '../../../agreements/interfaces';
import { VERIFY_EMAIL } from '../../../candidate/gql';
import AddressInfo from '../../../company/company-components/AddressInfo';
import { UPDATE_CANDIDATE_OF_PLACEMENT } from '../gql';

const CandidateWidgetPlacement = ({
  placement,
  widgetPermissions,
  placementAccountsStatus,
  candidateOwner,
  placementStatus,
  refetchPlacement,
}: any) => {
  const [loadingPage, setLoadingPage] = useState(false);
  const [modal, setModal] = useState(false);
  const [updateForCandidate, setUpdateForCandidate] = useState(false);
  const [verifyEmail] = useMutation(VERIFY_EMAIL);
  const toggle = () => setModal(!modal);
  const [updateCandidateForPlacement, { loading }] = useMutation(
    UPDATE_CANDIDATE_OF_PLACEMENT
  );
  const location = useLocation<IComingState>();
  const { register, handleSubmit, errors, reset, setValue, watch, clearErrors } = useForm();
  const history = useHistory();
  const backURL = location.pathname.includes(VIEW_ACCOUNTING_DASHBOARD_ROUTE)
    ? `${VIEW_ACCOUNTING_DASHBOARD_ROUTE}/${placement?.id}`
    : `${VIEW_PLACEMENT_MANAGEMENT_ROUTE}/${placement?.id}`;

  const updateCandidateInfo = async (values) => {
    const { city, streetAddress, zipCode, ...reset } = values;
    let address: any;
    values?.countryCode === 'MX'
      ? (address = {
        candidateCity: city,
        candidateAddressOne: values?.address1,
        candidateAddressTwo: values?.address2,
        candidateStateCode: values?.stateCode,
        candidatePostalCode: zipCode,
        candidateCountryCode: values?.countryCode,
      })
      : (address = {
        candidateCity: city,
        candidateStreet: streetAddress,
        candidateStateCode: values?.stateCode,
        candidatePostalCode: zipCode,
        candidateCountryCode: values?.countryCode,
      });
    delete values?.countryCode;
    delete reset?.countryCode;
    delete values?.stateCode;
    delete reset?.stateCode;
    delete values?.address1;
    delete reset?.address1;
    delete values?.address2;
    delete reset?.address2;
    let res = await updateCandidateForPlacement({
      variables: {
        candidateInput: {
          ...address,
          ...reset,
          placementId: placement?.id,
          candidateId: placement?.candidateId,
          companyId: placement?.jobOrder?.company?.id,
        },
      },
    });

    if (res?.data?.updatePlacementCandidate?.status === 200) {
      toast.success('Successfully Update!');
      refetchPlacement();
      toggle();
    }
  };

  useEffect(() => {
    if (placement) {
      let address = {
        city: placement?.candidateCity,
        streetAddress: placement?.candidateStreet,
        stateCode: placement?.candidateStateCode,
        zipCode: placement?.candidatePostalCode,
        countryCode: placement?.candidateCountryCode,
        address1: placement?.candidateAddressOne,
        address2: placement?.candidateAddressTwo,
      };
      reset({ ...placement, ...address });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [placement]);

  const [applyEmailValidation, setApplyEmailValidation] = useState(true);

  const emailValidationRules = {
    required: { value: true, message: 'Email is required!' },
    pattern: {
      value: EMAIL_PATTERN,
      message: 'Email is not valid!',
    },
    validate: async (value) => {
      if (value && applyEmailValidation) {
        let { data } = await verifyEmail({
          variables: { email: value },
        });

        if (data?.verifyEmailExist?.exist) {
          return 'Email is already there.';
        } else {
          return true;
        }
      }
    },
  };

  useEffect(() => {
    setApplyEmailValidation(updateForCandidate);
  }, [updateForCandidate]);

  return (
    <div>
      <div className="d-flex align-items-center justify-content-between mb-2">
        <h5 className="m-0">Candidate Info</h5>
        {placementAccountsStatus && placementStatus !== PLACEMENT_ENDED && placementStatus !== PLACEMENT_CANCELLED ? (
          <button
            type="button"
            onClick={toggle}
            disabled={widgetPermissions}
            className="buttonGenericStyle outlinedButtonDark"
          >
            Edit
          </button>
        ) : null}
      </div>

      <div>
        <div className="d-flex justify-content-between mb-2 contentGridLabel">
          <label>Candidate</label>

          <h6
            className="anchorColorText route-link"
            onClick={() => {
              history.push({
                pathname: `${VIEW_CANDIDATE_ROUTE}/${placement?.candidate.id}`,
                state: {
                  backUrl: backURL,
                },
              });

              pushInHistory(backURL);
            }}
          >
            {placement?.candidateFullName || '--'}
          </h6>
        </div>

        <div className="d-flex justify-content-between mb-2 contentGridLabel">
          <label>Company</label>
          <h6>{placement?.candidateCompanyName || '--'}</h6>
        </div>

        <div className="d-flex justify-content-between mb-2 contentGridLabel">
          <label>Position Title</label>
          <h6>{placement?.candidateJobTitle || '--'}</h6>
        </div>

        <div className="d-flex justify-content-between mb-2 contentGridLabel">
          <label>Email</label>
          <h6>{placement?.candidateEmail || '--'}</h6>
        </div>

        <div className="d-flex justify-content-between mb-2 contentGridLabel">
          <label>Primary Phone</label>
          <h6>{placement?.candidatePhone || '--'}</h6>
        </div>
        <div className="d-flex justify-content-between mb-2 contentGridLabel">
          <label>Address</label>
          <h6>
            {countryWiseDetailedlocation(
              placement?.candidateCountryCode,
              placement?.candidateCity,
              placement?.candidateStateCode,
              placement?.candidateStreet,
              placement?.candidatePostalCode,
              placement?.candidateAddressOne,
              placement?.candidateAddressTwo
            )}
          </h6>
        </div>

        <div className="d-flex justify-content-between mb-2 contentGridLabel">
          <label>Candidate Owner</label>
          <h6>{candidateOwner || '--'}</h6>
        </div>
      </div>

      <Modal
        isOpen={modal}
        toggle={toggle}
        className="revampDialog revampDialogWidth"
      >
        <div className="d-flex justify-content-between align-items-center px-4 py-3">
          <h5>Update Placement Candidate</h5>
          <div>
            <div className="d-flex align-items-center">
              <h6 className="mr-2 my-auto switchLabel">Update For Candidate</h6>
              <label className="switch m-0">
                <Input
                  type="checkbox"
                  name="updateForCandidate"
                  onChange={(e) => {
                    setUpdateForCandidate(e.target.checked);
                    clearErrors('candidateEmail');
                  }}
                  innerRef={register()}
                />
                <span className="slider"></span>
              </label>
            </div>
          </div>
        </div>

        <Form>
          <ModalBody>
            <Row>
              <Col>
                <InputField
                  inputtype="text"
                  label="First Name"
                  inputid="candidateFirstName"
                  isRequired={true}
                  placeholder="First Name"
                  inputRef={register({
                    required: {
                      value: true,
                      message: 'First name is required!',
                    },
                  })}
                  errMsg={errors?.candidateFirstName?.message}
                />
              </Col>
              <Col>
                <InputField
                  inputtype="text"
                  label="Last Name"
                  isRequired={true}
                  inputid="candidateLastName"
                  placeholder="Last Name"
                  inputRef={register({
                    required: {
                      value: true,
                      message: 'Last name is required!',
                    },
                  })}
                  errMsg={errors?.candidateLastName?.message}
                />
              </Col>
            </Row>

            <Row>
              <Col>
                <InputField
                  inputtype="text"
                  label="Phone"
                  isRequired={true}
                  inputid="candidatePhone"
                  placeholder="Phone"
                  inputRef={register({
                    required: { value: true, message: 'Phone is required!' },
                  })}
                  errMsg={errors?.candidatePhone?.message}
                />
              </Col>

              <Col>
                <InputField
                  inputtype="text"
                  label="Email"
                  isRequired={true}
                  inputid="candidateEmail"
                  placeholder="Email"
                  inputRef={register(emailValidationRules)}
                  errMsg={errors?.candidateEmail?.message}
                />
              </Col>
            </Row>

            <Row>
              <Col>
                <InputField
                  inputtype="text"
                  label="Company"
                  inputid="candidateCompanyName"
                  placeholder="Company"
                  inputRef={register()}
                  errMsg={errors?.candidateCompanyName?.message}
                />
              </Col>

              <Col>
                <InputField
                  inputtype="text"
                  label="Position Title"
                  inputid="candidateJobTitle"
                  placeholder="Position Title"
                  inputRef={register()}
                  errMsg={errors?.candidateJobTitle?.message}
                />
              </Col>
            </Row>

            <Row>
              <Col>
                <AddressInfo
                  watch={watch}
                  register={register}
                  errors={errors}
                  headQatar={false}
                  givenStates={states_candidate_constant}
                  setValue={setValue}
                  fromCandidate={true}
                  placementAddressInfo={true}
                />
              </Col>
            </Row>
          </ModalBody>

          <ModalFooter>
            <button
              type="button"
              onClick={toggle}
              className="buttonPrimary mr-3"
            >
              Cancel
            </button>
            <CustomButton
              buttonText="Update"
              buttonColor="primary"
              buttonType="submit"
              className="big-width"
              buttonClick={handleSubmit(updateCandidateInfo)}
              loading={loading}
              disabled={loading}
            />
          </ModalFooter>
        </Form>
      </Modal>
    </div >
  );
};

export default CandidateWidgetPlacement;
