import Select from "react-select";
import deleteIcon from "../../../../assets/images/delete-field.svg";
import editImage from "../../../../assets/images/edit.svg";

import {
  Button,
  Col,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  Row,
} from "reactstrap";
import { useState, useEffect, useContext } from "react";
import { IRecruiterPercentage } from "../create/IndexUpdatePlacement";
import { ZERO_TO_HUNDRED } from "../../../../utils/constant";
import { AppContext } from "../../../../context";
import { customSelectDropDown } from "../../../../components/styled/customSelect";

export default function CreateRecruiter({
  recruiters,
  errors,
  recruiterListChange,
  recruiterList,
  defaultCandidateOwner,
  readonlyStepThree,
  defaultPercentage,
}) {
  const [recruiter, setRecruiter] = useState<any>();
  const [comingRecruiters, setComingRecruiters] = useState<any>(recruiters);
  const [percentageError, setPercentageError] = useState<any>();
  const [edit, setEdit] = useState<boolean>(false);
  const [recruiterPercentage, setRecruiterPercentage] =
    useState<any>(defaultPercentage);

  const onPercentageChange = (percentage: string) => {
    setRecruiterPercentage(parseFloat(percentage));
    if (percentage) {
      const mailRegex = new RegExp(ZERO_TO_HUNDRED);
      const test = mailRegex.test(percentage);
      if (test) {
        setPercentageError("");
      } else {
        setPercentageError("Percentage is not valid!");
      }
    }
  };

  const onDeleteItem = (item: any) => {
    let objectToAdd: any = {};

    objectToAdd.id = item?.recruiter?.value;
    objectToAdd.fullName = item?.recruiter?.label;

    let cloneArr = [...comingRecruiters, { ...objectToAdd }];

    setComingRecruiters(cloneArr);

    setEdit(false);
    recruiterListChange(item);
    setRecruiter("");
    setRecruiterPercentage("");
  };

  useEffect(() => {
    if (!recruiter) {
      setComingRecruiters(
        comingRecruiters?.filter(
          (item) => item.id !== defaultCandidateOwner?.value
        )
      );
      setRecruiter(defaultCandidateOwner);
    }

    // eslint-disable-next-line
  }, []);

  const onEditItem = (item: any) => {
    setRecruiter({
      label: item?.recruiter?.label,
      value: item?.recruiter?.value,
    });
    onPercentageChange(item?.percentage);
  };

  const editCurrentSplit = () => {
    const index = recruiterList?.findIndex(
      (element) => element?.recruiter?.value === recruiter?.value
    );

    recruiterList?.splice(index, 1);

    recruiterListChange({
      recruiter: recruiter,
      percentage: recruiterPercentage,
    });

    setEdit(false);
  };

  console.log(comingRecruiters);
  const { theme } = useContext(AppContext)

  return (
    <div>
      <Row>
        <Col md="6">
          <Label>
            Recruiter Name <span className="text-danger">*</span>
          </Label>
          <Select
            isDisabled={readonlyStepThree}
            value={recruiter}
            name="recruiterName"
            options={comingRecruiters?.map((item: any) => {
              return {
                label: item?.fullName,
                value: item?.id,
              };
            })}
            onChange={setRecruiter}
            styles={{ ...customSelectDropDown(theme) }}
          />
        </Col>

        <Col md="6">
          <Label>
            Recruiter Percentage <span className="text-danger">*</span>
          </Label>
          <InputGroup>
            <InputGroupAddon addonType="prepend">%</InputGroupAddon>
            <Input
              readOnly={readonlyStepThree}
              placeholder="Recruiter Percentage"
              name="recruiterPercentage"
              type="number"
              value={`${recruiterPercentage}`}
              onChange={(e) => onPercentageChange(e.target.value)}
            />
          </InputGroup>
          <small className="text-danger">
            {errors?.recruiterPercentage?.message || percentageError}
          </small>
        </Col>

        <Col md="12" className="pt-4 float-right">
          {edit ? (
            <Button
              disabled={
                !(recruiter && recruiterPercentage) || readonlyStepThree
              }
              onClick={() => {
                setRecruiter("");
                setRecruiterPercentage("");
                editCurrentSplit();
              }}
            >
              Edit
            </Button>
          ) : (
            <Button
              disabled={
                readonlyStepThree ||
                percentageError ||
                !(recruiter && recruiterPercentage) ||
                recruiterList?.find(
                  (item: IRecruiterPercentage) =>
                    item?.recruiter?.value === recruiter?.value
                )
                  ? true
                  : false
              }
              onClick={() => {
                !recruiterList?.find(
                  (item: IRecruiterPercentage) =>
                    item?.recruiter?.value === recruiter?.value
                ) &&
                  recruiterListChange({
                    recruiter: recruiter,
                    percentage: recruiterPercentage,
                  });

                setRecruiter("");
                setComingRecruiters(
                  comingRecruiters?.filter(
                    (item) => item.id !== recruiter?.value
                  )
                );
                setRecruiterPercentage("");
              }}
            >
              Add
            </Button>
          )}
        </Col>
      </Row>

      <div className="d-flex mt-3">
        {recruiterList?.map((item: IRecruiterPercentage, index: number) => {
          return (
            <Row>
              <Col md="12">
                <Label>
                  {index + 1}
                  {")"}
                </Label>{" "}
                <Label> {item?.recruiter?.label}</Label>
                <Label className="percentage-am">{item?.percentage} %</Label>
                <Button
                  disabled={readonlyStepThree}
                  onClick={() => {
                    setEdit(true);
                    onEditItem(item);
                  }}
                  className="my-auto bg-transparent border-0 w-auto"
                >
                  <img src={editImage} alt="Edit" className="m-0" width="16" />
                </Button>
                <Button
                  disabled={readonlyStepThree}
                  onClick={() => onDeleteItem(item)}
                  className="my-auto bg-transparent border-0 w-auto"
                >
                  <img src={deleteIcon} alt="" width="20" height="20" />
                </Button>
              </Col>
            </Row>
          );
        })}
      </div>
    </div>
  );
}
