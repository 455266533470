import {FC} from 'react'
import { Modal,ModalBody, ModalFooter ,Button } from 'reactstrap';
import CLOSE_ICON from "../../assets/images/close-icon.svg";

interface Props{
  isOpen : boolean;
  toggle : ()=>void
}

const AgreementRequest:FC<Props> = ({isOpen, toggle}) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} className="revampDialog">
      <div className="modalHeaderCustom d-flex align-items-center justify-content-between">
        <h5 className="m-0"> Agreement Request </h5>

        <Button className="p-0 bg-transparent border-0" onClick={toggle}>
          <img src={CLOSE_ICON} alt="close" />
        </Button>
      </div>

      <ModalBody>
        <p className="agreement-request-wrap">
          Please send the agreement request to <span>johnnyrenaudo@realrepp.com</span>
        </p>
      </ModalBody>

      <ModalFooter>
        <button
          className="buttonPrimary mr-3"
          type="button"
          onClick={toggle}
        >
          Cancel
        </button>
      </ModalFooter>
    </Modal>
  )
}
export default AgreementRequest
