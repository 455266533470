import { useState } from "react";
import { Col, Row } from "reactstrap";
import PreviewStep from "./step-components/PreviewStep";
import ContactSelectSection from "./preview-step-components/ContactSelectSection";

export default function ContactStepPreview({
  refetchSteps,
  stepSubject,
  compaignId,
  sequenceSteps,
  loading,
  tabId,
  emailContactStep,
  setEmailContactStep,
  setEmailTemp,
  emailTemp,
  setContactIdForTab,
  setPersonzailedStepID,
  personzailedStepID,
  stepIndexNumber,
  setstepIndexNumber,
  contactIdForTab
}) {
  const [contactId, setContactId] = useState<string>();

  const getContactDetailsHandler = (contactId: string) => {
    setContactId(contactId);
  };

  return (
    <div>
      <Row>
        <Col className="col-sm-12 col-md-7">
          <ContactSelectSection
            fromPreview={true}
            onContactDetailsClick={getContactDetailsHandler}
            sequenceSteps={sequenceSteps}
            refetchSteps={refetchSteps}
            stepSubject={stepSubject}
            compaignId={compaignId}
            loading={loading}
            emailContactStep={emailContactStep}
            setEmailContactStep={setEmailContactStep}
            setEmailTemp={setEmailTemp}
            emailTemp={emailTemp}
            setContactIdForTab={setContactIdForTab}
            contactIdForTab={contactIdForTab}
            setPersonzailedStepID={setPersonzailedStepID}
            stepIndexNumber={stepIndexNumber}
            setstepIndexNumber={setstepIndexNumber}
          />
        </Col>

        <Col className="col-sm-12 col-md-5">
          <PreviewStep
            tabId={tabId}
            contactId={contactId}
            fromPreview={true}
            compaignId={compaignId}
            refetchStepsPreviewStep={refetchSteps}
            emailContactStep={emailContactStep}
            setEmailContactStep={setEmailContactStep}
            setEmailTemp={setEmailTemp}
            emailTemp={emailTemp}
            personzailedStepID={personzailedStepID}
            stepIndexNumber={stepIndexNumber}
            contactIdForTab={contactIdForTab}
            setContactIdForTab={setContactIdForTab}

          />
        </Col>
      </Row>
    </div>
  );
}
