import { FC, useState } from "react";
import { Tooltip } from "reactstrap";
import { InfoBarItemProps } from "../../../../interfaces";

const HeaderItems: FC<InfoBarItemProps> = ({ label, src, tooltip, divider, id }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  return <>
    <div className="infoCardDivider">

      <Tooltip placement="top" isOpen={isOpen} target={`target_${id}`}
        toggle={() => setIsOpen(!isOpen)}> {tooltip} </Tooltip>

      <img src={src} alt="address" width="19px" id={`target_${id}`} />
      <label>   {label || '--'}</label>
    </div>
    {divider && <div className="divider" />}
  </>
}
export default HeaderItems;