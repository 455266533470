import { gql } from '@apollo/client';

export const GET_ALL_JOB_ORDERS_DROPDOWN = gql`
  query FetchAllJobOrders(
    $page: Int!
    $limit: Int!
    $searchText: String
    $statusSearch: String
    $companyId: String
  ) {
    fetchAllJobOrders(
      payload: { page: $page, limit: $limit }
      filters: {
        searchText: $searchText
        statusSearch: $statusSearch
        companyId: $companyId
      }
    ) {
      jobOrders {
        id
        jobTitle
        status
      }
      count
    }
  }
`

export const GET_ALL_JOB_ORDERS = gql`
  query FetchAllJobOrders(
    $page: Int!
    $limit: Int!
    $searchText: String
    $statusSearch: String
    $companyId: String
    $myJobOrder: Boolean
    $onlyMe: Boolean
    $cityName: String
    $myPendingInterview: Boolean
    $myPendingSubmission: Boolean
    $isHotJob: Boolean
    $columnFilters: JobOrdersColumnFilter
  ) {
    fetchAllJobOrders(
      payload: { page: $page, limit: $limit }
      columnFilters: $columnFilters
      filters: {
        searchText: $searchText
        statusSearch: $statusSearch
        companyId: $companyId
        myJobOrder: $myJobOrder
        onlyMe: $onlyMe
        cityName: $cityName
        myPendingInterview: $myPendingInterview
        myPendingSubmission: $myPendingSubmission
        isHotJob: $isHotJob
      }
    ) {
      jobOrders {
        id
        jobTitle
        status
        positions
        allowRemote
        estimatedFee
        feeType
        isHotJob
        showSalary
        newApplications
        totalApplications
        workplaceTypes
        category {
          id
          name
        }
        advertisedLocations {
          id
          postedAt
        }
        workType
        rateType
        salaryMin
        salaryMax
        recruitingLevel
        updatedAt
        companyLocation {
          state
          city
          country
        }
        mainContact {
          id
          contact {
            id
            fullName
          }
        }
        company {
          id
          name
          owner {
            id
            fullName
          }
          ownerAndSharees {
          id
          sharingId
          ownerType
          owner {
            id
            fullName
          }
        }
          companyOwner {
            owner {
              id
              fullName
            }
          }
        }
      }
      count
    }
  }
`;

export const GET_JOB_ORDER_APPLICANTS_LISTS = gql`
  query FetchJobOrderApplicantList(
    $page: Int!
    $limit: Int!
    $searchText: String
    $statusSearch: String
    $companyId: String
    $myJobOrder: Boolean
    $onlyMe: Boolean
  ) {
    fetchJobOrderApplicants(
      payload: { page: $page, limit: $limit }
      filters: {
        searchText: $searchText
        statusSearch: $statusSearch
        companyId: $companyId
        myJobOrder: $myJobOrder
        onlyMe: $onlyMe
      }
    ) {
      jobOrders {
        id
        jobTitle
        newApplications
        totalApplications
        workplaceTypes
        updatedAt
        status
        workType
        allowRemote
        companyLocation {
          state
          city
          country
        }
        company {
          id
          name
          owner {
            id
            fullName
          }
          ownerAndSharees {
            id
            owner {
              id
            }
          }
        }
      }
      count
    }
  }
`;

export const APPLY_CANDIDATE_TO_JOB = gql`
  mutation ApplyCandidateToJob(
    $applyCandidateToJobInput: ApplyCandidateToJobInput!
  ) {
    applyCandidateToJob(applyCandidateToJobInput: $applyCandidateToJobInput) {
      message
      status
    }
  }
`;
export const FETCH_All_JOB_ORDER_COMPANIES = gql`
  query FetchAllCompanies($page: Int!, $limit: Int!, $searchText: String) {
    companiesForDropDown(
      payload: { page: $page, limit: $limit }
      filters: { searchText: $searchText }
    ) {
      companies {
        id
        name
        createdAt
        ownerAndSharees {
          ownerType
          owner {
            id
            fullName
          }
        }
        companyContacts {
          id
          fullName
        }
        companyAgreement {
          id
          nickName
          agreementType
          commissionPercentage
          replacementDays
          payableDays
          status
        }
        address {
          id
          isPrimary
          isDeleted
          streetAddress
          city
          state
          zipCode
          country
        }
      }
      count
    }
  }
`;

// export const GET_COMPANIES_WITH_ADDRESS_CONTACT = gql`
//   query GetCompanies {
//     getCompanies {
//       id
//       name
//       status
//       ownerAndSharees {
//         ownerType
//         owner {
//           id
//           fullName
//         }
//       }
//       companyContacts {
//         id
//         fullName
//       }
//       companyAgreement {
//         id
//         nickName
//         status
//       }
//       address {
//         id
//         isPrimary
//         isDeleted
//         streetAddress
//         city
//         state
//         zipCode
//         country
//       }
//     }
//   }
// `;

export const GET_JOB_APPLICANTS = gql`
  query GetJobApplicants(
    $id: String!
    $page: Int!
    $limit: Int!
    $status: String!
    $searchParams: String!
  ) {
    getApplicants(
      jobOrderId: $id
      payload: { limit: $limit, page: $page }
      status: $status
      searchParams: $searchParams
    ) {
      jobApplicants {
        id
        createdAt
        updatedAt
        JobAppStatus
        candidate {
          id
          fullName
          city
          stateCode
          status
          address2
          address1
          source
          idealJobTitle
          employmentStatus
          linkedInUrl
          resume {
            id
            documentName
            documentUrl
            updatedAt
            createdAt
          }
          employmentHistory {
            id
            jobTitle
            companyName
            startedAt
            endedAt
          }
          emails {
            id
            email
            isPrimary
          }
          recruiterOwner {
            id
            fullName
          }
          skillsSet {
            id
            skill {
              id
              name
            }
          }
        }
      }
      count
    }
  }
`;

export const GET_JOB_APPLICANTS_INCLUDING_APPLIED = gql`
  query GetApplicantsIncludingApplied(
    $id: String!
    $page: Int!
    $limit: Int!
    $status: [String!]!
    $searchParams: String!
  ) {
    getApplicantsIncludingApplied(
      jobOrderId: $id
      payload: { limit: $limit, page: $page }
      status: $status
      searchParams: $searchParams
    ) {
      jobApplicants {
        id
        createdAt
        updatedAt
        source
        JobAppStatus
        submission {
          status
        }
        candidate {
          id
          fullName
          jobTitle
          companyName
          isPrivateCandidate
          address2
          address1
          status
          source
          idealJobTitle
          employmentStatus
          resume {
            id
            originalUrl
            resumeFileName
            updatedAt
            createdAt
          }

          recruiterOwner {
            id
            fullName
          }
        }
      }
      count
    }
  }
`;

export const GET_JOB_APPLICANTS_PIPELINE = gql`
  query GetJobApplicants(
    $id: String
    $page: Int!
    $limit: Int!
    $status: [String!]!
    $searchParams: String!
    $applicantId: String
    $companyId: String
  ) {
    getApplicants(
      getApplicantsInput: {
        candidateId: $id
        payload: { limit: $limit, page: $page }
        status: $status
        searchParams: $searchParams
        applicantId: $applicantId
        companyId: $companyId
      }
    ) {
      jobApplicants {
        id
        createdAt
        sentTime
        JobAppStatus

        JobOrder {
          id
          timezone
          timezoneId
          dstOffset
          jobTitle
          rateType
          allowRemote
          workplaceTypes
          status
          workType
          mainContact {
            id
            contact {
              id
              contactEmail {
                email
                id
                isPrimary
              }
            }
          }
          companyLocation {
            streetAddress
            city
            state
            zipCode
            country
          }
          company {
            name
            id
            ownerAndSharees {
              id
              ownerType
              owner {
                id
                fullName
              }
            }
            companyOwner {
              id
              owner {
                id
                fullName
              }
            }
          }
        }
        submission {
          id
          status
          submitWith
          createdAt
          targetSalary
          salaryType
          salary
          resumeUrl
          resumeId
          resumeName
          submissionSummary
          submittedBy {
            fullName
          }
          createdBy {
            id
            fullName
          }
        }
        offers {
          id
          offeredSalary
          offeredSalaryType
          details
          hireType
          locked
          offerStatus
          offerStartDateTime
          dateString
        }
        interviews {
          id
          interviewRoundIndex
          interviewType
          interviewDateTime
          timeZoneId
          timeZone
          location
          details
          interviewStatus
          locked
          clientInviteFlag
          candidateInviteFlag
          InterviewState
        }
      }
      count
    }
  }
`;
export const GET_JOB_ORDER_LIGHT = gql`
  query GetJobOrder($id: String!) {
    getJobOrder(id: $id) {
      id
      jobTitle
      rateType
      workType
      jobOrderSummary {
        id
        jobAdCount
        daySinceLastActivity
        jobApplicantCount
        candidatePoolCount
        candidatePipelineCount
        createdAt
        updatedAt
      }
      company {
        id
        name
        ownerAndSharees {
          id
          ownerType
          owner {
            id
            fullName
          }
        }
      }
      companyLocation {
        id
        state
        streetAddress
        city
        country
      }
    }
  }
`;

export const GET_JOB_ORDER_LIGHT_ON_PIPELINE = gql`
  query GetJobOrder($id: String!) {
    getJobOrder(id: $id) {
      id
      jobTitle
      rateType
      workType
      company {
        id
        name
        ownerAndSharees {
          id
          ownerType
          owner {
            id
            fullName
          }
        }
      }
      companyLocation {
        id
        state
        streetAddress
        city
      }
    }
  }
`;
export const GET_JOB_ORDER_SUBMISSION = gql`
  query GetJobOrder($id: String!) {
    getJobOrder(id: $id) {
      id
      jobTitle
      rateType
      status
      workplaceTypes
      allowRemote
      company {
        id
        name
        companyOwner {
          id
          owner {
            id
            fullName
          }
        }
      }
      companyLocation {
        id
        streetAddress
        city
        state
        zipCode
        country
      }
      jobOrderSummary {
        id
        jobAdCount
        daySinceLastActivity
        jobApplicantCount
        candidatePoolCount
        candidatePipelineCount
        createdAt
        updatedAt
      }
      mainContact {
        id
        contact {
          id
          contactEmail {
            email
            id
            isPrimary
          }
        }
      }
      recruitingAssigned {
        id
        user {
          fullName
          id
        }
      }
    }
  }
`;
export const GET_LIST_OF_JOB_ORDERS = gql`
  query GetJobOrderList {
    getListJobOrder {
      id
      jobTitle
    }
  }
`;
export const GET_COMPANIES_FOR_OWNER_SHARE_ASSIGN_JOB = gql`
  query GetCompaniesOfJobOrderAssignAndOwnerShree(
    $page: Int!
    $limit: Int!
    $searchText: String
  ) {
    getShareOwnerAndJobOrderAssign(
      payload: { page: $page, limit: $limit }
      filters: { searchText: $searchText }
    ) {
      id
      name
      owner {
        id
        fullName
      }
    }
  }
`;

export const GET_COMPANIES_FOR_OWNER_ASSIGN_JOB = gql`
  query GetCompaniesOfJobOrderAssignAndOwnerShree {
    getShareOwnerAndJobOrderAssign {
      owner {
        id
        fullName
      }
      id
      name
    }
  }
`;

export const GET_CATEGORIES_LIST = gql`
  query GetCategoriesList {
    getCategories {
      id
      name
    }
  }
`;

export const GET_SKILLS = gql`
  query GetSkills {
    getSkills {
      id
      name
    }
  }
`;

// export const GET_JOB_LOCATION =gql`
//   query GetJobLocations($jobOrderid:String!){
//     getJobLocation(jobOrderId: $jobOrderId){
//       id
//       state
//       city
//     }
//   }
// `;

export const GET_JOB_ORDER_STATS = gql`
  query GetJobOderStats($jobOrderId: String!) {
    getJobOrderStats(jobOrderId: $jobOrderId) {
      id
      jobAdCount
      daySinceLastActivity
      jobApplicantCount
      candidatePoolCount
      candidatePipelineCount
      createdAt
    }
  }
`;
export const GET_DEGREE = gql`
  query GetDegree {
    getDegrees {
      id
      name
    }
  }
`;
export const GET_JOB_ORDER = gql`
  query GetJobOrder($id: String!) {
    getJobOrder(id: $id) {
      id
      jobTitle
      advertisedJobTitle
      allowRemote
      recruitingLevel
      positions
      isHotJob
      showSalary
      estimatedFee
      feeType
      travelPercentage
      workType
      rateType
      status
      fee
      salaryMax
      salaryMin
      experienceMin
      experienceMax
      postToLinkedin
      timezone
      timezoneId
      dstOffset
      confidentialJob
      workplaceTypes
        company {
          id
          name
          companyContacts {
            id
            fullName
          }
          ownerAndSharees {
            id
            ownerType
            owner {
              id
              fullName
            }
          }
          companyOwner {
            id
            owner {
              id
              fullName
            }
          }
        }

      companyLocation {
        id
        streetAddress
        city
        state
        zipCode
        country
      }

      agreement {
        id
        nickName
      }

      mustSkills {
        id
        skill {
          name
          id
        }
      }

      niceSkills {
        id

        skill {
          name
          id
        }
      }

      degree {
        id
        name
      }

      mainContact {
        id

        contact {
          id
          fullName

          contactEmail {
            email
            id
            isPrimary
          }
        }
      }

      remoteContact {
        id

        contact {
          id
          fullName
        }
      }

      billingContact {
        id

        contact {
          id
          fullName
        }
      }

      recruitingAssigned {
        id

        user {
          fullName
          id
        }
      }

      user {
        id
        fullName
      }

      isFeatured
      advertisedJobDescription
      category {
        id
        name
      }
    }
  }
`;

export const CreateJobOrderInput = gql`
  input CreateJobOrderInput {
    jobTitle: String!
    companyId: String!
    companyLocationId: String
    status: String
    recruitingLevel: String
    allowRemote: Boolean
    estimatedFee: Float
    experienceMax: Float
    experienceMin: Float
    positions: Float
    isHotJob: Boolean
    showSalary: Boolean
    salaryMax: Float
    salaryMin: Float
    workType: String
    rateType: String
    feeType: String
    advertisedJobDescription: String
  }
`;
export const CREATE_JOB_ORDER = gql`
  mutation CreateJobOrder($CreateJobOrderInput: CreateJobOrderInput!) {
    createJobOrder(jobOrderInput: $CreateJobOrderInput) {
      id
      jobTitle
    }
  }
`;

export const UpdateJobOrderDto = gql`
  input UpdateJobOrderDto {
    jobTitle: String!
    jobOrderId: String!
    companyId: String!
    companyLocationId: String
    status: String
    recruitingLevel: String
    allowRemote: Boolean
    estimatedFee: Float
    experienceMax: Float
    experienceMin: Float
    positions: Float
    isHotJob: Boolean
    showSalary: Boolean
    salaryMax: Float
    salaryMin: Float
    workType: String
    rateType: String
    feeType: String
    advertisedJobDescription: String
    isFeatured: boolean
  }
`;
export const UPDATE_JOB_ORDER = gql`
  mutation UpdateJobOrder($UpdateJobOrderDto: UpdateJobOrderDto!) {
    updateJobOrder(updateJobOrderInput: $UpdateJobOrderDto) {
      message
      status
    }
  }
`;

export const GET_RECRUITER_LIST = gql`
  query GetRecruiters {
    getRecruiterList {
      id
      fullName
    }
  }
`;

export const GET_RECRUITER_LIST_WITHOUT_MANAGER = gql`
  query GetRecruitersWithoutManager {
    getRecruiterListWithoutManager {
      id
      fullName
    }
  }
`;

export const FINDING_PARALLEL_TEAM_MEMBERS = gql`
  query FindingParallelTeamMembers($companyId: String!) {
    findingParallelTeamUsers(companyId: $companyId) {
      id
      fullName
    }
  }
`;

export const BulkUpdateJobs = gql`
  input BulkUpdateJobs {
    ids: [String!]
  }
`;

export const RecruitersList = gql`
  input RecruitersList {
    recruitersList: [String!]
  }
`;

export const UPDATE_RECRUITERS_ASSIGNED = gql`
  mutation UpdateRecruiterAssigned(
    $list: RecruitersList!
    $jobOrderId: String!
  ) {
    updateRecruiterAssigned(list: $list, jobOrderId: $jobOrderId) {
      message
      status
    }
  }
`;

export const REMOVE_SINGLE_RECRUITER = gql`
  mutation RemoveSingleRecruiter($recruiterId: String!, $jobOrderId: String!) {
    removeSingleRecruiter(recruiterId: $recruiterId, jobOrderId: $jobOrderId) {
      message
      status
    }
  }
`;

export const HOT_JOB_ORDER = gql`
  mutation HotJobOrder($ids: BulkUpdateJobs!) {
    hotJobOrder(ids: $ids) {
      message
      status
    }
  }
`;

export const UPDATE_STATUS_WITH_LOCATION = gql`
  mutation UpdateStatusWithLocationUpdates(
    $bulkJobOrderUpdateStatus: BulkJobOrderUpdateStatus!
  ) {
    updateStatusWithLocationUpdates(
      bulkJobOrderUpdateStatus: $bulkJobOrderUpdateStatus
    ) {
      message
      status
    }
  }
`;

export const UNHOT_JOB_ORDER = gql`
  mutation UnhotJobOrder($ids: BulkUpdateJobs!) {
    unHotJobOrder(ids: $ids) {
      message
      status
    }
  }
`;

export const REMOVE_JOB_ORDER = gql`
  mutation RemoveJobOrder($id: String!) {
    removeJobOrder(id: $id) {
      message
      status
    }
  }
`;

export const POST_JOB_ORDER = gql`
  mutation PostJobOrder($ids: BulkUpdateJobs!) {
    postJobOrder(ids: $ids) {
      message
      status
    }
  }
`;

export const UNPOST_JOB_ORDER = gql`
  mutation UnPostJobOrder($ids: BulkUpdateJobs!) {
    unPostJobOrder(ids: $ids) {
      message
      status
    }
  }
`;

export const POST_ONE_JOB_ORDER = gql`
  mutation PostOneJobOrder($id: String!) {
    postOneJobOrder(id: $id) {
      message
      status
    }
  }
`;

export const UNPOST_ONE_JOB_ORDER = gql`
  mutation UnPostOneJobOrder($id: String!) {
    unPostOneJobOrder(id: $id) {
      message
      status
    }
  }
`;

export const UPDATE_JOB_APPLICANT_STATUS_TO_INTERVIEW = gql`
  mutation UpdateApplicantsStatusToInterview($id: String!) {
    updateApplicantsStatusToInterview(id: $id) {
      message
      status
    }
  }
`;

export const NOTIFY_CANDIDATE_OWNER_FOR_INVITE = gql`
  mutation NotifyCandidateOwnerToSendCalendarInvite($jobApplicantId: String!) {
    notifyCandidateOwnerToSendCalendarInvite(jobApplicantId: $jobApplicantId) {
      message
      status
    }
  }
`;

// Job order advertised location

export const GET_ADVERTISED_LOCATION = gql`
  query GetAdvertisedLocation($id: String!, $page: Int!, $limit: Int!) {
    getAdvertisedLocations(id: $id, payload: { page: $page, limit: $limit }) {
      advertisedLocation {
        city
        state
        country
        id
        postedAt
      }
      count
    }
  }
`;

export const GET_SINGLE_ADVERTISED_LOCATION = gql`
  query GetSingleAdvertisedLocation($id: String!) {
    getSingleLocation(jobOrderId: $id) {
      city
      state
      country
      id
      postedAt
    }
  }
`;

export const DELETE_ADVERTISED_LOCATION = gql`
  mutation DeleteAdvertisedLocation($id: String!) {
    deleteAdvertisedLocation(id: $id) {
      message
      status
    }
  }
`;

export const CreateAdvertisedLocationInput = gql`
  input CreateAdvertisedLocationInput {
    state: String
    city: String
    isPrimary: boolean
    jobOrderId: String!
  }
`;
export const ADD_NEW_ADVERTISED_LOCATION = gql`
  mutation AddNewCompanyAdvertisedLocation(
    $advertisedLocationInput: CreateAdvertisedLocationInput!
  ) {
    createAdvertisedLocation(
      advertisedLocationInput: $advertisedLocationInput
    ) {
      city
    }
  }
`;

export const GET_CLIENT_DOCUMENTS = gql`
  query GetClientDocuments($jobOrderId: String!, $page: Int!, $limit: Int!) {
    getClientDocuments(
      jobOrderId: $jobOrderId
      payload: { page: $page, limit: $limit }
    ) {
      clientDocuments {
        id
        attachableId
        attachableType
        documentName
        documentUrl
        createdAt
        user {
          id
          fullName
        }
      }
      count
    }
  }
`;

export const REMOVE_CLIENT_DOCUMENT = gql`
  mutation RemoveClientDocument($clientDocumentId: String!) {
    removeClientDocument(clientDocumentId: $clientDocumentId) {
      message
      status
    }
  }
`;

export const UPDATE_APPLICANT_UPDATE = gql`
  mutation UpdateApplicantStatus($applicantId: String!, $status: String!) {
    updateApplicant(applicantId: $applicantId, status: $status) {
      message
      status
    }
  }
`;
export const FIND_OR_CREATE_JOBAPPLICANT = gql`
  mutation FindOrCreateJobApplicant(
    $candidateId: String!
    $jobOrderId: String!
  ) {
    findOrCreateJobApplicant(
      candidateId: $candidateId
      jobOrderId: $jobOrderId
    ) {
      id
      JobAppStatus
    }
  }
`;

export const APPLY_TO_JOB = gql`
  mutation CreateSubmissionOnApply(
    $candidateId: String!
    $jobOrderId: String!
  ) {
    createSubmissionOnApply(
      candidateId: $candidateId
      jobOrderId: $jobOrderId
    ) {
      jobApplicant {
        id
        JobAppStatus
      }
      status
    }
  }
`;

export const UPDATE_APPLICANTS_STATUS = gql`
  mutation UpdateApplicantsStatus($data: UpdateApplicantStatusDTO!) {
    updateApplicantsStatus(data: $data) {
      message
      status
    }
  }
`;

export const CREATE_CLIENT_DOCUMENT = gql`
  mutation CreateClientDocument(
    $attachableId: String!
    $attachableType: String!
    $documentUrl: String!
    $documentName: String
  ) {
    createClientDocument(
      clientDocumentInput: {
        attachableId: $attachableId
        attachableType: $attachableType
        documentUrl: $documentUrl
        documentName: $documentName
      }
    ) {
      message
      status
    }
  }
`;

export const FETCH_TEAM_AND_MEMBERS = gql`
  query FetchUserTeamMembers {
    widgetTeamMembers {
      team {
        id
      }
    }
  }
`;

export const FETCH_TEAM_AND_MEMBERS_MANUAL_USER = gql`
  query FetchUserTeamMembersManualUser($id: String!) {
    widgetTeamMembersManualUser(id: $id) {
      team {
        id
      }
    }
  }
`;

export const GET_PIPELINE_LISTING = gql`
  query GetPipelineListing(
    $page: Int!
    $limit: Int!
    $searchText: String
    $statusSearch: String
    $companyId: String
    $myJobOrder: Boolean
    $onlyMe: Boolean
  ) {
    jobOrderCandidatePipeline(
      payload: { page: $page, limit: $limit }
      filters: {
        searchText: $searchText
        statusSearch: $statusSearch
        companyId: $companyId
        myJobOrder: $myJobOrder
        onlyMe: $onlyMe
      }
    ) {
      jobOrders {
        id
        jobTitle
        submittedCount
        submittedDeclinedCount
        submittedPendingCount
        interviewsCount
        placedCount
        offersCount
        referencesCount
        workType
        allowRemote
        workplaceTypes
        companyLocation {
          state
          city
        }
        company {
          id
          name
          owner {
            id
            fullName
          }
          ownerAndSharees {
            id
            owner {
              id
            }
          }
        }
      }
      count
    }
  }
`;

export const GET_SINGLE_JOB_APPLICANT_ON_INTERVIEW = gql`
  query GetSingleJobApplicantOnInterview($id: String!) {
    getSingleJobApplicantOnInterview(id: $id) {
      id
      candidate {
        id
        fullName
        streetAddress
        address2
        address1
        city
        stateCode
        postalCode
        countryCode
        geoLocation
        jobTitle
        companyName
        candidateStats {
          id
          jobAppCount
          interviewCount
          ownershipExpiration
          submissionCount
          daySinceLastAction
          updatedAt
          lastCheckedInRecruiter {
            id
            fullName
          }
        }
        emails {
          id
          isPrimary
          email
        }
        recruiterOwner {
          id
          fullName
        }
      }
      JobOrder {
        id
        timezone
        timezoneId
        dstOffset
        jobTitle
        rateType
        allowRemote
        status
        workType
        workplaceTypes
        companyLocation {
          streetAddress
          city
          state
          zipCode
          country
        }
        company {
          id
          name
          owner {
            id
            fullName
          }
          ownerAndSharees {
            id
            sharingId
            ownerType
            owner {
              id
              fullName
            }
          }
        }
      }
    }
  }
`;

export const COMPANY_AND_CANDIDATE_OWNER = gql`
  query GetSingleJobApplicantOnInterview($id: String!) {
    getSingleJobApplicantOnInterview(id: $id) {
      id
      candidate {
        id
        recruiterOwner {
          id
          fullName
        }
      }
      JobOrder {
        id
        company {
          id
          owner {
            id
            fullName
          }
        }
      }
    }
  }
`;

export const GET_JOB_ORDER_MAIN_CONTACT = gql`
  query GetJobOrderMainContact($id: String!) {
    getJobOrderMainContact(id: $id) {
      id
      contact {
        id
        fullName
      }
    }
  }
`;

export const GET_SINGLE_JOB_APPLICANT_ON_SUBMISSION = gql`
  query GetSingleApplicantSubmission($id: String!) {
    getSingleJobApplicantOnInterview(id: $id) {
      id
      candidate {
        id
        fullName
        jobTitle
        city
        stateCode
        streetAddress
        address2
        address1
        recruiterOwner {
          id
          fullName
        }
      }
      submission {
        id
        salaryType
        targetSalary
        salary
        submissionSummary
        resumeName
        resumeUrl
      }
      JobOrder {
        id
        jobTitle
        allowRemote
        status
        companyLocation {
          streetAddress
          city
          state
          zipCode
          country
        }
        company {
          id
          name
          ownerAndSharees {
            id
            sharingId
            ownerType
            owner {
              id
              fullName
            }
          }
        }
      }
    }
  }
`;

export const GET_JOB_APPLICANTS_FOR_JOB = gql`
  query GetJobApplicantsForJob(
    $jobOrderId: String!
    $page: Int!
    $limit: Int!
    $status: [String!]!
    $searchParams: String!
    $applicantId: String
    $companyId: String
    $pendingInterview: Boolean
    $pendingSubmission: Boolean
  ) {
    getApplicantsOfJob(
      getApplicantsInputJob: {
        jobOrderId: $jobOrderId
        payload: { limit: $limit, page: $page }
        status: $status
        searchParams: $searchParams
        applicantId: $applicantId
        companyId: $companyId
        pendingInterview: $pendingInterview
        pendingSubmission: $pendingSubmission
      }
    ) {
      jobApplicants {
        id
        createdAt
        sentTime
        JobAppStatus

        candidate {
          id
          fullName
          streetAddress
          address2
          address1
          city
          stateCode
          postalCode
          countryCode
          geoLocation
          linkedInUrl
          jobTitle
          status
          source
          companyName
          skillsSet {
            id
            skill {
              id
              name
            }
          }
          resume {
            id
            resumeFileName
            highlightUrl
            fileType
            originalUrl
            pdfVersionUrl
            createdAt
            updatedAt
          }

          phones {
            id
            phoneType
            phoneNumber
            isPrimary
          }

          candidateStats {
            id
            jobAppCount
            interviewCount
            ownershipExpiration
            submissionCount
            daySinceLastAction
            updatedAt

            lastCheckedInRecruiter {
              id
              fullName
            }
          }

          emails {
            id
            isPrimary
            email
          }

          recruiterOwner {
            id
            fullName
          }
        }

        submission {
          id
          status
          submitWith
          createdAt
          targetSalary
          salaryType
          salary
          resumeUrl
          resumeId
          resumeName
          submissionSummary

          submittedBy {
            fullName
          }

          createdBy {
            id
            fullName
          }
        }

        offers {
          id
          offeredSalary
          offeredSalaryType
          details
          hireType
          locked
          offerStatus
          offerStartDateTime
          dateString
        }

        interviews {
          id
          interviewRoundIndex
          interviewType
          interviewDateTime
          timeZoneId
          timeZone
          location
          details
          interviewStatus
          locked
          clientInviteFlag
          candidateInviteFlag
          InterviewState
        }
      }

      count
    }
  }
`;

export const GET_CANDIDATES_OF_SPECIFIC_JOB_ORDER = gql`
query GetJobOrderCandidates($jobOrderId: String! , $searchText: String!) {
  getJobOrderCandidates(jobOrderId: $jobOrderId , searchText: $searchText) {
    jobApplicants{
      id
      candidate {
        firstName
        lastName
        emails{
          email
        }
      }
    }
  }
}
`
