import { useState } from 'react';
import { Col, Input, Label, Row } from 'reactstrap';
import { MONTHS_IN_YEAR } from '../../../../utils/constant';
import { YEARS_TO_CURRENT } from '../../../../utils/helper';

const YearMonthSelector = ({ reportData, selectedYear, selectedMonth, setSelectedYear, setSelectedMonth }) => {
  const [availableReports, setAvailableReports] = useState<number[]>([]);

  const handleYearChange = (e) => {
    const year = e.target.value;
    setSelectedYear(year);
    setAvailableReports(reportData[year] || []);
    setSelectedMonth('');
  };

  const handleMonthChange = (e) => {
    setSelectedMonth(e.target.value);
  };

  return (
    <Row>
      <Col>
        <Label>Year</Label>
        <Input type="select" value={selectedYear} onChange={handleYearChange} className="mb-3">
          <option value="" disabled>
            Select a year
          </option>
          {YEARS_TO_CURRENT.map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </Input>
      </Col>

      <Col>
        <Label>Month</Label>
        <Input type="select" value={selectedMonth} onChange={handleMonthChange} disabled={!selectedYear}>
          <option value="" disabled>
            Select a month
          </option>
          {MONTHS_IN_YEAR.map((month, index) => (
            <option key={index} value={index + 1} disabled={!availableReports.includes(index + 1)}
              style={{ color: availableReports.includes(index + 1) ? 'black' : 'gray', }}>
              {month}
            </option>
          ))}
        </Input>
      </Col>
    </Row>
  );
};

export default YearMonthSelector;
