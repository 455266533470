import { useLazyQuery, useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Button } from "reactstrap";
import ConfirmPopUp from "../../../components/ConfirmPopUp";
import NoDataFound from "../../../components/layout/NoDataFound";
import deleteIcon from "../../../assets/images/delete-field.svg";
import {
  DELETE_ADVERTISED_LOCATION,
  GET_ADVERTISED_LOCATION,
} from "../gql/index";
import { Pagination } from "../../../components/layout/Pagination";
import Loader from "../../../components/layout/Loader";
import AddNewAdvertisedLocation from "./AddNewAdvertisedLocation";
import PostUnpost from "./PostUnpost";
import TableComponent from "../../../components/layout/TableComponent";
import InputField from "../../../components/layout/InputField";
import { PAGE_LIMIT } from "../../../utils/constant";

const ADVERTISE_HEADER = ["LOCATION", "POST", "ACTION"];

const AdvertiseLocaitonJobOrder = ({
  jobOrderId,
  reFetchJobOrder,
  checkEditPermission,
  setAdvertisedLocationList,
}) => {
  const page = parseInt("1");
  const [currentPage, setCurrentPage] = useState(page);
  const [limit, setLimit] = useState(10);
  const [deleteAdvertisedLocation] = useMutation(DELETE_ADVERTISED_LOCATION);
  const [delAddressConfirm, setDelAddressConfirm] = useState(false);
  const [addressId, setAddressId] = useState("");
  const [addNewAddressToggle, setAddNewAddressToggle] = useState(false);
  const [addressConfirm, setAddressConfirm] = useState(false);

  const [
    getAdvertisedLocationsQuery,
    { data: advertisedLocations, loading: advertisedLocationsLoading },
  ] = useLazyQuery(GET_ADVERTISED_LOCATION);

  const removeAddress = async () => {
    await deleteAdvertisedLocation({ variables: { id: addressId } });
    setDelAddressConfirm(false);
    getAdvertisedLocationsQuery({
      variables: { id: jobOrderId, limit, page: currentPage },
    });
    toast.success("Address Successfully deleted!");
  };

  useEffect(() => {
    if (jobOrderId) {
      getAdvertisedLocationsQuery({
        variables: { id: jobOrderId, limit, page: currentPage },
      });
    }
    // eslint-disable-next-line
  }, [jobOrderId, limit, currentPage]);

  useEffect(() => {
    if (
      advertisedLocations?.getAdvertisedLocations?.advertisedLocation?.length
    ) {
      setAdvertisedLocationList(
        advertisedLocations?.getAdvertisedLocations?.advertisedLocation
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [advertisedLocations]);

  return (
    <div className="jobOrdersActionLog">
      {checkEditPermission && (
        <div className="jobOrderRevamp d-flex justify-content-end align-items-center m-0">
          <button
            className="buttonGenericStyle outlinedButton m-0"
            type="button"
            onClick={() => {
              setAddNewAddressToggle(!addNewAddressToggle);
            }}
          >
            Add
          </button>
        </div>
      )}
      <div className="">
        {advertisedLocationsLoading ? (
          <Loader loaderClass="sm-height" />
        ) : (
          <div>
            <div className="table-responsive">
              <TableComponent tableHeader={ADVERTISE_HEADER}>
                {!advertisedLocationsLoading &&
                  !advertisedLocations?.getAdvertisedLocations
                    ?.advertisedLocation?.length && (
                    <tbody>
                      <tr>
                        <td colSpan={14} className="empty-table-td">
                          <div className="for-position-multiselect">
                            <NoDataFound text="No Advertised Location Found!" />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  )}

                <tbody>
                  {
                    advertisedLocations?.getAdvertisedLocations?.advertisedLocation?.map(
                      (item: any, index: number) => (
                        <tr
                          className={`advertisedLocation-card ${item.isPrimary ? "active" : ""
                            }`}
                          key={index}
                        >
                          {
                            ['US', 'CA', null, undefined, ''].includes(item?.country) ? (
                              <td>
                                {`${item?.city}, `} {`${item?.state}`}
                              </td>
                            ) : (
                              <td>
                                {`${item?.city}, `} {`${item?.country}`}
                              </td>
                            )
                          }


                          <td>
                            <PostUnpost
                              reFetchJobOrder={reFetchJobOrder}
                              checkEditPermission={checkEditPermission}
                              _locationId={item?.id}
                              _postedAt={item?.postedAt}
                              _city={item?.city}
                              _state={item?.state}
                            />
                          </td>
                          <td>
                            <Button
                              onClick={() => {
                                setDelAddressConfirm(true);
                                setAddressId(item?.id);
                              }}
                              className="bg-transparent border-0"
                              disabled={!checkEditPermission}
                            >
                              <img
                                className="m-0"
                                src={deleteIcon}
                                alt=""
                                width="20"
                                height="20"
                              />
                            </Button>
                          </td>
                        </tr>
                      )
                    )}
                </tbody>
              </TableComponent>
            </div>
          </div>
        )}

        {advertisedLocations?.getAdvertisedLocations?.advertisedLocation
          ?.length ? (
          <div className="users-pagination">
            <Pagination
              key={
                advertisedLocations?.getAdvertisedLocations?.advertisedLocation
                  ?.id
              }
              onPageChange={(pageClicked: number) => {
                setCurrentPage(pageClicked);
              }}
              pageCount={Math.ceil(
                advertisedLocations?.getAdvertisedLocations?.count / limit
              )}
              currentPage={currentPage}
            />

            <div className="d-flex align-items-center">
              <div className="pagination-display paginationItemsSelect">
                <p className="m-0">
                  {(currentPage - 1) * limit + 1} to{" "}
                  {Math.min(
                    currentPage * limit,
                    advertisedLocations?.getAdvertisedLocations?.count
                  )}{" "}
                  of {advertisedLocations?.getAdvertisedLocations?.count}
                </p>

                <div className="divider"></div>

                <label>Display</label>

                <InputField
                  label=""
                  inputtype="select"
                  selectItems={PAGE_LIMIT?.filter(({ label }) => label !== limit.toString())}
                  inputid="limit"
                  placeholder={limit?.toString()}
                  inputMethod={(value: string) => {
                    setCurrentPage(1);
                    setLimit(parseInt(value));
                  }}
                />
              </div>
            </div>
          </div>
        ) : null}

        {addNewAddressToggle && (
          <AddNewAdvertisedLocation
            jobOrderId={jobOrderId}
            refetchAddress={() => {
              getAdvertisedLocationsQuery({
                variables: { id: jobOrderId, limit, page: currentPage },
              });
            }}
            addressConfirm={addressConfirm}
            setSelectAddress={setAddressConfirm}
            setAddNewAddressToggle={setAddNewAddressToggle}
          />
        )}

        <ConfirmPopUp
          confirmText="Are you sure you want to delete this advertised location?"
          isOpen={delAddressConfirm}
          toggle={setDelAddressConfirm}
          confirmAction={removeAddress}
          modalHeading="Delete Address"
          btnText="Delete"
          btnColor="primary"
        />
      </div>
    </div>
  );
};

export default AdvertiseLocaitonJobOrder;
