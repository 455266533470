import { useLazyQuery } from "@apollo/client";
import { useEffect, useState, Fragment } from "react";
import { FETCHING_ALL_USERS_WITH_PAGINATION } from "../../../admin/gql";
import CreatePlacementStepOne from "./CreatePlacementStepOne";
import CreatePlacementStepThree from "./CreatePlacementStepThree";
import CreatePlacementStepTwo from "./CreatePlacementStepTwo";
import { compactObject, prefillPlacementData } from "./utils";
import { useLocation } from "react-router-dom";

interface IPlacementType {
  label?: string;
  value?: string;
}

export default function CreatePlacementSteps({
  companyId,
  companyName,
  defaultOwner,
  setReadonlyStepThree,
  readonlyStepThree,
  watch,
  editPlacementContact,
  defaultPercentage,
  accountManagerList,
  salaryType,
  editPlacementData,
  setSalaryType,
  managerPermissions,
  control,
  readonly,
  accountManagerListChange,
  recruiterListChange,
  defaultCandidateOwner,
  register,
  reset,
  setValue,
  setError,
  recruiterList,
  setFinalPlacementValues,
  currentStep,
  errors,
}) {
  const { search, state }: any = useLocation();
  const [startDateString, setStartDateString] = useState("");
  const pickState = new URLSearchParams(search).get("pickState");
  const [localSalary, setLocalSalary] = useState<number>(0);
  const [assignedDuration, setAssignedDuration] = useState<number>(0);
  const [placementType, setPlacementType] = useState<IPlacementType>();
  const [estimatedLessPayrollBurdenPercenatge, SetestimatedLessPayrollBurdenPercenatge] = useState<number>(0);
  const [fetchUsers, { data }] = useLazyQuery(
    FETCHING_ALL_USERS_WITH_PAGINATION
  );

  useEffect(() => {
    if (editPlacementData) {
      const data = compactObject(editPlacementData);
      let prefill = prefillPlacementData(data);

      setAssignedDuration(editPlacementData?.assignmentDuration);
      setPlacementType({
        label: prefill?.placementType?.label,
        value: prefill.placementType?.value,
      });

      setLocalSalary(editPlacementData?.salaryAmount);
      setSalaryType(editPlacementData?.salaryType);
      setStartDateString(editPlacementData?.startDateString);
      SetestimatedLessPayrollBurdenPercenatge(editPlacementData?.estimatedLessPayrollBurdenPercenatge)
      // Because we have a time card approver...
      if (editPlacementData) {
        if (editPlacementContact?.contactType === "TIME_CARD_APPROVER") {
          prefill.timeCardApprover = {
            label: editPlacementContact?.companyContact?.fullName,
            value: editPlacementContact?.companyContact?.id,
          };
        }
      }

      const compactPrefilledValues = compactObject(prefill);

      if (pickState) {
        setFinalPlacementValues(state);
        reset({ ...state });
      } else {
        setFinalPlacementValues(compactPrefilledValues);
        reset({ ...compactPrefilledValues });
      }
    }

    // eslint-disable-next-line
  }, [editPlacementData, editPlacementContact, pickState, state]);

  useEffect(() => {
    fetchUsers({
      variables: {
        limit: 20,
        page: 1,
      },
    });

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (managerPermissions) {
      setReadonlyStepThree(false);
    }

    // eslint-disable-next-line
  }, [managerPermissions]);

  return (
    <Fragment>
      {currentStep === 1 ? (
        <CreatePlacementStepOne
          setValue={setValue}
          readonly={readonly}
          startDateString={startDateString}
          setError={setValue}
          localSalary={localSalary}
          watch={watch}
          assignedDuration={assignedDuration}
          setAssignedDuration={setAssignedDuration}
          placementType={placementType}
          setPlacementType={setPlacementType}
          companyId={companyId}
          companyName={companyName}
          salaryType={salaryType}
          setSalaryType={setSalaryType}
          control={control}
          register={register}
          errors={errors}
          estimatedLessPayrollBurdenPercenatge={estimatedLessPayrollBurdenPercenatge}
        />
      ) : currentStep === 2 ? (
        <CreatePlacementStepTwo
          readonly={readonly}
          defaultPercentage={defaultPercentage}
          defaultOwner={defaultOwner}
          accountManagerListChange={accountManagerListChange}
          accountManagers={data?.fetchAllUsers?.users}
          accountManagerList={accountManagerList}
          errors={errors}
        />
      ) : currentStep === 3 ? (
        <CreatePlacementStepThree
          readonlyStepThree={readonlyStepThree}
          defaultPercentage={defaultPercentage}
          defaultCandidateOwner={defaultCandidateOwner}
          recruiterListChange={recruiterListChange}
          recruiters={data?.fetchAllUsers?.users}
          recruiterList={recruiterList}
          errors={errors}
        />
      ) : null}
    </Fragment>
  );
}
