import { toast } from "react-toastify";
import axios from "axios";

export const handleAccountBillingPrint = async (id: string) => {
  // Pass that billValues to backend for dynamic passing
  try {
    const response = await axios.get(
      `${
        process.env.REACT_APP_API_BASE_URL || "http://localhost:3001"
      }/accounts/billingsheetdirecthire/${id}`,
      {
        responseType: "blob",
      }
    );
    const file = new Blob([response.data], { type: "application/pdf" });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  } catch (error) {
    toast.error("Error while Printing file..");
  }
};

export const handleAccountNewAssignmentPrint = async (id: string) => {
  // Pass that billValues to backend for dynamic passing
  try {
    const response = await axios.get(
      `${
        process.env.REACT_APP_API_BASE_URL || "http://localhost:3001"
      }/accounts/newassignment/${id}`,
      {
        responseType: "blob",
      }
    );
    const file = new Blob([response.data], { type: "application/pdf" });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  } catch (error) {
    toast.error("Error while Printing file..");
  }
};
