import { useState } from "react";
import { useForm } from "react-hook-form";
import {
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";

import VIEW_ICON from "../../../assets/images/view-icon.svg";

const ViewDesciption = ({ item, updateUI, candidateId }: any) => {
  const [editModal, setEditModal] = useState(false);

  const { register } = useForm();

  return (
    <div className="mr-2">
      <button
        className="iconButtonAction"
        onClick={() => setEditModal(!editModal)}
      >
        <img src={VIEW_ICON} alt="view" width="21px" />
      </button>

      <Modal
        isOpen={editModal}
        toggle={() => setEditModal(!editModal)}
        className="revampDialog revampDialogWidth"
      >
        <ModalHeader toggle={() => setEditModal(!editModal)}>
          Employment History
        </ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label>Description</Label>
              <textarea
                className="form-control bg-transparent"
                style={{ minHeight: "150px" }}
                // rows={4}
                id="description"
                ref={register({ required: false })}
                name="description"
                disabled={true}
                defaultValue={item?.description}
                placeholder={"No description Found"}
              />
            </FormGroup>

            <div className="text-right pb-2">
              <button
                onClick={() => setEditModal(!editModal)}
                type="button"
                className="buttonPrimary"
              >
                Close
              </button>
            </div>
          </Form>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ViewDesciption;
