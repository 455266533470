// packages block
import { useMutation } from '@apollo/client';
import { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
// components block
import ConfirmPopUp from '../../../components/ConfirmPopUp';
import CustomButton from '../../../components/layout/CustomButton';
import ExecutiveAcceptModalEscalation from './agreement-modal-escalations/ExecutiveAcceptModalEscalation';
import ExecutiveRejectModalEscalation from './agreement-modal-escalations/ExecutiveRejectModalEscalation';
import ManagerAcceptModalEscalation from './agreement-modal-escalations/ManagerAcceptModalEscalation';
import ManagerRejectModalEscalation from './agreement-modal-escalations/ManagerRejectModalEscalation';
import RecruiterModalEscalation from './agreement-modal-escalations/RecruiterModalEscalation';
import VoidAgreementModal from './agreement-modal-escalations/VoidAgreementModal';
// context, graphql
import { AppContext } from '../../../context';
import { AGREEMENT_STATUS_UNDELIVERABLE, AWAITING_EXECUTIVE_APPROVAL, AWAITING_MANAGER_APPROVAL, CLIENT_PREVIEW, CUSTOM, DIRECT_HIRE, DRAFT, EXECUTIVE_ROLE, EXECUTIVE_APPROVED, EXECUTIVE_REJECTED, MANAGER, MANAGER_REJECTED, OUT_FOR_SIGNATURE, PENDING_EXECUTIVE_APPROVAL, RESENT, SIGNED, TEMP } from '../../../utils/constant';
import {
  CLIENT_PREVIEW_PROTOCOL,
  CORRECT_DOCUSIGN_EMAIL_WORKFLOW,
  EXECUTIVE_ACCEPT_PROTOCOL,
  EXECUTIVE_REJECT_PROTOCOL,
  MANAGER_ACCEPT_PROTOCOL,
  MANAGER_REJECT_PROTOCOL,
  RECRUITERS_PROTOCOL,
  RESEND_AGREEMENT_DOCUSIGN,
} from '../gql/index';
import { AgreementEscalationModalActionProps } from '../interfaces';
// import ClientPreviewModalEscalation from './agreement-modal-escalations/ClientPreviewModalEscalation';

export default function AgreementEscalationModalAction({
  userIsReviewer,
  agreementStatus,
  currentUserAgreement,
  agreementId,
  agreementType,
  companyName,
  companyContactName,
  register,
  setValue,
  errors,
  getValues,
  envelopeId,
  fetchAgreement,
  currentUserAccess,
}: AgreementEscalationModalActionProps) {
  const history = useHistory();
  const { userRoles } = useContext(AppContext);
  // states
  const [recruiterAcceptModal, setRecruiterAcceptModal] = useState<boolean>(false);
  const [managerAcceptModal, setManagerAcceptModal] = useState<boolean>(false);
  // const [clientPreviewModal, setClientPreviewModal] = useState<boolean>(false);
  const [managerRejectModal, setManagerRejectModal] = useState<boolean>(false);
  const [executiveAcceptModal, setExecutiveAcceptModal] = useState<boolean>(false);
  const [executiveRejectModal, setExecutiveRejectModal] = useState<boolean>(false);
  const [voidModal, setVoidModal] = useState<boolean>(false);
  const [resendModal, setResendModal] = useState<boolean>(false);
  const [emailCorrectionModal, setEmailCorrectionModal] = useState<boolean>(false);
  const [note, setNote] = useState<string>('');
  const [noteValue, setNoteValue] = useState<boolean>(false);

  // mutations
  const [recruiterProtocol, { loading: recruiterProtocolLoading }] =
    useMutation(RECRUITERS_PROTOCOL);

  const [managerAcceptProtocol, { loading: managerAcceptProtocolLoading }] =
    useMutation(MANAGER_ACCEPT_PROTOCOL);

  const [clientPreviewProtocol, { loading: clientPreviewProtocolLoading }] =
    useMutation(CLIENT_PREVIEW_PROTOCOL);

  const [managerRejectProtocol, { loading: managerRejectProtocolLoading }] =
    useMutation(MANAGER_REJECT_PROTOCOL);

  const [executiveAcceptProtocol, { loading: executiveAcceptProtocolLoading }] =
    useMutation(EXECUTIVE_ACCEPT_PROTOCOL);

  const [executiveRejectProtocol, { loading: executiveRejectProtocolLoading }] =
    useMutation(EXECUTIVE_REJECT_PROTOCOL);

  const [resendAgreementMutation, { loading: resendLoading }] =
    useMutation(RESEND_AGREEMENT_DOCUSIGN);

  const [correctDocusignEmailWorkflow, { loading: correctEmailLoading }] =
    useMutation(CORRECT_DOCUSIGN_EMAIL_WORKFLOW);



  const recruiterProtocolHandler = async () => {
    if (note) {
      setNoteValue(false);
      const recruiterProtocolSaved = await recruiterProtocol({
        variables: {
          agreementInput: { agreementId, note },
        },
      });
      if (recruiterProtocolSaved?.data) {
        recruiterModalToggle();
        setNote('');
        toast.success('Recruiter sent agreement to manager');
        reload();
      }
      if (recruiterProtocolSaved?.errors) {
        recruiterModalToggle();
        setNote('');
        toast.error(recruiterProtocolSaved.errors[0]?.message);
      }
    } else {
      setNoteValue(true);
    }
  };

  const clientPreviewProtocolHandler = async () => {
    // if (note) {
    const clientPreviewResult = await clientPreviewProtocol({
      variables: {
        agreementInput: { agreementId, note },
      },
    });
    if (clientPreviewResult?.data) {
      // clientPreviewToggle();
      // setNote('');
      toast.success('Agreement status changed to client preview.');
      reload();
    }
    if (clientPreviewResult?.errors) {
      // clientPreviewToggle();
      toast.error(clientPreviewResult.errors[0]?.message);
    }
    // }
  }

  const managerAcceptProtocolHandler = async () => {
    if (note) {
      const managerAcceptProtocolSaved = await managerAcceptProtocol({
        variables: {
          agreementInput: { agreementId, note },
        },
      });
      if (managerAcceptProtocolSaved?.data) {
        managerModalToggle();
        setNote('');
        toast.success('Manager sent agreement to Executive');
        reload();
      }
      if (managerAcceptProtocolSaved?.errors) {
        managerModalToggle();
        toast.error(managerAcceptProtocolSaved.errors[0]?.message);
      }
    }
  };

  const managerRejectProtocolHandler = async () => {
    if (note) {
      const managerRejectProtocolSaved = await managerRejectProtocol({
        variables: {
          agreementInput: { agreementId, note },
        },
      });
      if (managerRejectProtocolSaved) {
        managerRejectModalToggle();
        setNote('');
        toast.warn('Manager rejected this agreement');
        reload();
      }
    }
  };

  const executiveAcceptProtocolHandler = async () => {
    const executiveAcceptProtocolSaved = await executiveAcceptProtocol({
      variables: {
        agreementInput: {
          agreementId,
          note,
          body: getValues()?.body,
          subject: getValues()?.subject,
        },
      },
    });

    if (executiveAcceptProtocolLoading) {
      toast.info('Please wait, we are sending an agreement for both parties');
    }

    if (executiveAcceptProtocolSaved?.data) {
      executiveAcceptModalToggle();
      (agreementType === TEMP || agreementType === CUSTOM)
        ? history.push(`/update-agreement/${agreementId}`)
        : reload();
      toast.success('Executive accepted this agreement');
    }

    if (executiveAcceptProtocolSaved?.errors) {
      toast.error(executiveAcceptProtocolSaved.errors[0]?.message);
    }
  };

  const executiveRejectProtocolHandler = async () => {
    if (note) {
      const executiveRejectedProtocolSaved = await executiveRejectProtocol({
        variables: {
          agreementInput: { agreementId, note },
        },
      });

      if (executiveRejectedProtocolSaved) {
        executiveRejectModalToggle();
        setNote('');
        toast.warn('Executive rejected this agreement');
        reload();
      }
    }
  };

  // toggle functions
  const recruiterModalToggle = () => setRecruiterAcceptModal(!recruiterAcceptModal);
  // const clientPreviewToggle = () => setClientPreviewModal(!clientPreviewModal);
  const managerModalToggle = () => setManagerAcceptModal(!managerAcceptModal);
  const managerRejectModalToggle = () => setManagerRejectModal(!managerRejectModal);
  const executiveAcceptModalToggle = () => setExecutiveAcceptModal(!executiveAcceptModal);
  const executiveRejectModalToggle = () => setExecutiveRejectModal(!executiveRejectModal);

  const resendAgreement = async () => {
    let res = await resendAgreementMutation({ variables: { envelopeId } });
    if (res?.data?.resendEnvelope?.status === 200) {
      toast.success('Resend Successfully');
      setResendModal(!resendModal);
      fetchAgreement();
    } else {
      let err: any = res?.errors?.length && res?.errors[0];
      toast.error(err?.message);
      setResendModal(!resendModal);
    }
  };

  const emailCorrectionHandler = async () => {
    let response = await correctDocusignEmailWorkflow({ variables: { envelopeId } });
    if (response?.data?.correctDocusignEmailWorkflow?.status === 400) {
      toast.error("The company contact hasn't corrected their email!");
    } else if (response?.data?.correctDocusignEmailWorkflow?.status === 404) {
      toast.error(`${response?.data?.correctDocusignEmailWorkflow?.message}`);
    } else if (response?.data?.correctDocusignEmailWorkflow?.status === 200) {
      toast.success("Envelope has been resent successfully!");
      setResendModal(!resendModal);
      fetchAgreement();
    } else {
      toast.error(`${response?.data?.correctDocusignEmailWorkflow?.message}`);
    }
  }

  const reload = () => {
    setTimeout(() => {
      return window.location.reload();
    }, 1000);
  };

  return (
    <div>
      {(userIsReviewer || userRoles?.includes(EXECUTIVE_ROLE)) && (
        <>
          <div className='d-flex' style={{ gap: '15px' }}>
            {(agreementStatus === DRAFT || agreementStatus === MANAGER_REJECTED || agreementStatus === EXECUTIVE_REJECTED) && (
              <CustomButton
                buttonClick={() => clientPreviewProtocolHandler()}
                buttonText="Client preview"
                buttonType="button"
                buttonColor="success"
                loading={clientPreviewProtocolLoading}
              />
            )}

            {(agreementStatus === PENDING_EXECUTIVE_APPROVAL) && (
              <CustomButton
                buttonClick={() => setManagerAcceptModal(true)}
                buttonText="Send for executive approval"
                buttonType="button"
                buttonColor="success"
                loading={managerAcceptProtocolLoading}
              />
            )}

            {agreementStatus === DRAFT ||
              agreementStatus === CLIENT_PREVIEW ||
              agreementStatus === MANAGER_REJECTED ||
              agreementStatus === EXECUTIVE_REJECTED ? (
              <div>
                {currentUserAgreement && userRoles?.includes(MANAGER) ? (
                  <CustomButton
                    buttonClick={() => setManagerAcceptModal(true)}
                    buttonText="Send for executive approval"
                    buttonType="button"
                    buttonColor="success"
                    loading={managerAcceptProtocolLoading}
                  />
                ) : (
                  <CustomButton
                    buttonClick={() => { userRoles?.includes(EXECUTIVE_ROLE) ? setManagerAcceptModal(true) : setRecruiterAcceptModal(true) }}
                    buttonText="Send for manager approval"
                    buttonType="button"
                    buttonColor="success"
                    loading={userRoles?.includes(EXECUTIVE_ROLE) ? managerAcceptProtocolLoading : recruiterProtocolLoading}
                  />
                )}
              </div>
            ) : agreementStatus === AWAITING_MANAGER_APPROVAL ||
              agreementStatus === EXECUTIVE_REJECTED ? (
              <div>
                {(currentUserAgreement ||
                  userRoles?.includes(MANAGER) ||
                  userRoles?.includes(EXECUTIVE_ROLE)) &&
                  (currentUserAgreement || userRoles?.includes(MANAGER) ? (
                    <>
                      <CustomButton
                        buttonClick={() => setManagerRejectModal(true)}
                        className="mr-4 mb-2"
                        buttonText="Reject"
                        buttonType="button"
                        buttonColor="danger"
                        loading={managerRejectProtocolLoading}
                      />

                      <CustomButton
                        buttonClick={() => setManagerAcceptModal(true)}
                        className="mb-2"
                        buttonText="Send for executive approval"
                        buttonType="button"
                        buttonColor="success"
                        loading={managerAcceptProtocolLoading}
                      />
                    </>
                  ) : (
                    userRoles?.includes(EXECUTIVE_ROLE) && (
                      <>
                        <CustomButton
                          buttonClick={() => {
                            setExecutiveRejectModal(true);
                          }
                          }
                          className="mr-4 mb-2"
                          buttonText="Reject"
                          buttonType="button"
                          buttonColor="danger"
                          loading={executiveRejectProtocolLoading}
                        />

                        <CustomButton
                          buttonClick={() => {
                            setManagerAcceptModal(true)
                          }}
                          className="mb-2"
                          buttonText="Approve"
                          buttonType="button"
                          buttonColor="success"
                          loading={executiveAcceptProtocolLoading}
                        />
                      </>
                    )
                  ))}
              </div>
            ) : agreementStatus === AWAITING_EXECUTIVE_APPROVAL ? (
              <div>
                {(currentUserAgreement || userRoles?.includes(EXECUTIVE_ROLE)) && (
                  <>
                    <CustomButton
                      buttonClick={() => {
                        setExecutiveRejectModal(true)
                      }
                      }
                      className="mr-4 mb-2"
                      buttonText="Reject"
                      buttonType="button"
                      buttonColor="danger"
                      loading={executiveRejectProtocolLoading}
                    />

                    <CustomButton
                      buttonClick={() => {
                        agreementType === TEMP || agreementType === CUSTOM
                          ? executiveAcceptProtocolHandler()
                          : setExecutiveAcceptModal(true)
                      }
                      }
                      className="mb-2"
                      buttonText="Approve"
                      buttonType="button"
                      buttonColor="success"
                      loading={executiveAcceptProtocolLoading}
                    />
                  </>
                )}
              </div>
            ) : null}
          </div>
        </>
      )}

      <RecruiterModalEscalation
        modal={recruiterAcceptModal}
        onClosed={() => {
          setRecruiterAcceptModal(false);
        }}
        toggle={recruiterModalToggle}
        setNote={setNote}
        noteValue={noteValue}
        setNoteValue={setNoteValue}
        confirmAction={() => {
          recruiterProtocolHandler();
        }}
        recruiterProtocolLoading={recruiterProtocolLoading}
      />

      {userRoles?.includes(EXECUTIVE_ROLE) &&
        (agreementType === TEMP || agreementType === CUSTOM) &&
        (agreementStatus === OUT_FOR_SIGNATURE ||
          agreementStatus === EXECUTIVE_APPROVED) && (
          <CustomButton
            buttonClick={() => history.push(`/update-agreement/${agreementId}`)}
            buttonText="Update Agreement status"
            className="mx-0"
            buttonType="button"
            buttonColor="warning"
            loading={managerAcceptProtocolLoading}
          />
        )}

      {(userRoles?.includes(EXECUTIVE_ROLE) || currentUserAccess) &&
        agreementType === DIRECT_HIRE &&
        (agreementStatus === OUT_FOR_SIGNATURE ||
          agreementStatus === RESENT) && (
          <>
            <CustomButton
              buttonClick={() => setResendModal(!resendModal)}
              className="ml-2"
              buttonText="Resend"
              buttonType="button"
              buttonColor="success"
            />

            <CustomButton
              buttonClick={() => setVoidModal(!voidModal)}
              className="ml-2"
              buttonText="Void"
              buttonType="button"
              buttonColor="danger"
            />
          </>
        )}

      {(agreementStatus === AGREEMENT_STATUS_UNDELIVERABLE) && (userRoles?.includes(EXECUTIVE_ROLE) || currentUserAccess) && (
        <>
          <CustomButton
            buttonClick={() => setEmailCorrectionModal(!emailCorrectionModal)}
            className="ml-2"
            buttonText="Resend"
            buttonType="button"
            buttonColor="success"
          />
        </>
      )}

      <ManagerAcceptModalEscalation
        modal={managerAcceptModal}
        onClosed={() => {
          setManagerAcceptModal(false);
        }}
        toggle={managerModalToggle}
        setNote={setNote}
        confirmAction={() => {
          managerAcceptProtocolHandler();
        }}
        managerAcceptProtocolLoading={managerAcceptProtocolLoading}
      />

      <ManagerRejectModalEscalation
        modal={managerRejectModal}
        onClosed={() => {
          setManagerRejectModal(false);
        }}
        toggle={managerRejectModalToggle}
        setNote={setNote}
        rejectAction={() => {
          managerRejectProtocolHandler();
        }}
        managerRejectProtocolLoading={managerRejectProtocolLoading}
      />

      {userRoles?.includes(EXECUTIVE_ROLE) && (
        <>
          <ExecutiveAcceptModalEscalation
            modal={executiveAcceptModal}
            onClosed={() => {
              setExecutiveAcceptModal(false);
            }}
            toggle={executiveAcceptModalToggle}
            confirmAction={() => {
              executiveAcceptProtocolHandler();
            }}
            executiveAcceptProtocolLoading={executiveAcceptProtocolLoading}
            agreementType={agreementType}
            companyName={companyName}
            companyContactName={companyContactName}
            register={register}
            setValue={setValue}
            errors={errors}
            getValues={getValues}
          />

          <ExecutiveRejectModalEscalation
            modal={executiveRejectModal}
            onClosed={() => {
              setExecutiveRejectModal(false);
            }}
            toggle={executiveRejectModalToggle}
            setNote={setNote}
            rejectAction={() => {
              executiveRejectProtocolHandler();
            }}
            executiveRejectProtocolLoading={executiveRejectProtocolLoading}
          />
        </>
      )}

      <VoidAgreementModal
        isOpen={voidModal}
        envelopeId={envelopeId}
        toggle={() => {
          setVoidModal(!voidModal);
        }}
        fetchAgreement={() => fetchAgreement()}
      />

      <ConfirmPopUp
        confirmText="Are you sure you want to resend this agreement?"
        isOpen={resendModal}
        toggle={() => {
          setResendModal(!resendModal);
        }}
        confirmAction={resendAgreement}
        modalHeading="Resend Agreement"
        btnText="Resend"
        btnColor="success"
        loading={resendLoading}
        disabled={resendLoading}
        className="revampDialog revampDialogWidth"
      />

      <ConfirmPopUp
        confirmText="Are you sure you want to resend this agreement? Make sure to correct your email before pressing resend."
        isOpen={emailCorrectionModal}
        toggle={() => {
          setEmailCorrectionModal(!emailCorrectionModal);
        }}
        confirmAction={emailCorrectionHandler}
        modalHeading="Resend Agreement"
        btnText="Resend"
        btnColor="success"
        loading={correctEmailLoading}
        disabled={correctEmailLoading}
        className="revampDialog revampDialogWidth"
      />
    </div>
  );
}