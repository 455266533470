import { useLazyQuery, useMutation } from '@apollo/client';
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import { useHistory, useLocation } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import {
  Alert,
  Badge,
  Button,
  Col,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import CLOSE_ICON from '../../../../assets/images/close.svg';
import ConfirmPopUp from '../../../../components/ConfirmPopUp';
import GoBack from '../../../../components/GoBack';
import Loader from '../../../../components/layout/Loader';
import { AppContext } from '../../../../context';
import {
  IAccountManagerPercentage,
  IRecruiterPercentage,
} from '../../../../interfaces';
import {
  ACCOUNTING_INVOICED,
  placementUpdateStatusPermission,
  PLACEMENT_MANAGEMENT_ROUTE,
  REQUEST_PLACEMENT_CANCELLED,
  VIEW_ACCOUNTING_DASHBOARD_ROUTE,
  VIEW_PLACEMENT_MANAGEMENT_ROUTE,
  EXECUTIVE_ROLE,
  ADMIN_ROLE,
  ACCOUNTING_MANAGER_ROLE,
  ACCOUNTING_COORDINATOR_ROLE,
  ACCOUNTING_APPROVED,
  DIRECT_HIRE,
  PAID,
} from '../../../../utils/constant';
import { GET_PLACEMENT_BY_ID } from '../../../AccountingDashboard/gql';
import { ComingStateType, IComingState } from '../../../agreements/interfaces';
import { CREATE_NOTES, GET_OWNER_SHAREES } from '../../../company/gql';
import { FETCH_CONTACT_HISTORY_DETAIL } from '../../../contacts/gql';
import CandidateWidgetPlacement from '../components/CandidateWidgetPlacement';
import JobOrderWidgetDetail from '../components/JobOrderWidgetDetail';
import RejectNotes from '../components/RejectNotesModal';
import ViewPlacementSteps from '../edit/DetailedViewPlacement';
import EditPlacement from '../edit/EditPlacement';
import {
  GET_SUBMISSION_BY_JOBAPPLICANT,
  ON_APPROVE_PLACEMENT,
  ON_REJECT_PLACEMENT,
  PLACEMENT_SPLITTING,
} from '../gql';
import '../placement.scss';
import { CancelAlert } from './CancelAlert';
import MoldalForDirectHire from './MoldalForDirectHire';
import { RejectionAlert } from './RejectionAlert';
// import CancelPlacementModal from './CancelPlacementModal';
// import { REPLACE_PLACEMENT } from '../../offers/gql';
import InvoiceStatusTable from '../components/InvoiceStatusTable';

const ViewPlacementManagement = () => {
  const location = useLocation<IComingState>();
  const history = useHistory();

  const { control, register, errors, reset, watch, setError, setValue } =
    useForm();

  const comingState: ComingStateType = location.state;
  const [splitEdit, setSplitEdit] = useState<boolean>(false);
  const [recruiterSplitEdit, setRecruiterSplitEdit] = useState<boolean>(false);
  const [placementEndOrCancel, setPlacementEndOrCancel] =
    useState<boolean>(false);
  const [defaultCandidateOwner, setDefaultCandidateOwner] = useState<any>();
  const [defaultPercentage, setDefaultPercentage] = useState<number>();
  const [placementViewReadonlyModal, setPlacementViewReadonlyModal] =
    useState<boolean>(false);

  const [, setCancelButton] = useState<boolean>(true);

  const [endDateModal, setEndDateModal] = useState<boolean>(false);
  const [disableAllFields, setDisableAllFields] = useState<boolean>(false);

  const [editPermit, setEditPermit] = useState<boolean>(false);
  const [contactWarning, setContactWarning] = useState<boolean>(false);
  const [candidateEditPermit, setCandidateEditPermit] =
    useState<boolean>(false);
  const [approveRejectManagerPermit, setApproveRejectManagerPermit] =
    useState<boolean>(false);
  const [reject, setReject] = useState<boolean>(false);
  const [accountManagersManagerApproval, setAccountManagersManagerApproval] =
    useState<boolean>(false);
  const { user, userRoles } = useContext(AppContext);
  const { id }: any = useParams();
  const [defaultOwner, setDefaultOwner] = useState<any>();

  const [getCompanyAndOwner, { data: companyOwnerSharees }] =
    useLazyQuery(GET_OWNER_SHAREES);

  const [accountManagerList, setAccountManagerList] = useState<
    Array<IAccountManagerPercentage>
  >([]);

  const [ownerSharees, setOwnerSharees] = useState<Array<string>>([]);
  const [recruiterList, setRecruiterList] = useState<
    Array<IRecruiterPercentage>
  >([]);

  const [accountManagerListQuery, setAccountManagerListQuery] = useState<
    Array<IAccountManagerPercentage>
  >([]);
  const [recruiterListQuery, setRecruiterListQuery] = useState<
    Array<IRecruiterPercentage>
  >([]);

  const [_getSubmission, { data: _getSubmissionData }] = useLazyQuery(
    GET_SUBMISSION_BY_JOBAPPLICANT
  );

  const [_onRejectPlacement] = useMutation(ON_REJECT_PLACEMENT);

  const [_onApprovingPlacement] = useMutation(ON_APPROVE_PLACEMENT);

  const [createNotes, { loading: rejectLoading }] = useMutation(CREATE_NOTES);

  const [_placementSplit, { data: _placementSplitData }] =
    useLazyQuery(PLACEMENT_SPLITTING);
  const [getContactHistory, { data: contactHistory }] = useLazyQuery(
    FETCH_CONTACT_HISTORY_DETAIL
  );

  const [
    _getPlacement,
    { data: _getPlacementData, loading: _getPlacementLoading, refetch },
  ] = useLazyQuery(GET_PLACEMENT_BY_ID);

  useEffect(() => {
    document.title = 'Placement Management View - RealREPP';

    _getPlacement({
      variables: { id },
    });

    // eslint-disable-next-line
  }, [id]);
  useEffect(() => {
    if (_getPlacementData?.getPlacementById?.jobOrder?.company?.id) {
      getCompanyAndOwner({
        variables: {
          id: _getPlacementData?.getPlacementById?.jobOrder?.company?.id,
        },
      });
    }
    // eslint-disable-next-line
  }, [_getPlacementData?.getPlacementById]);

  useEffect(() => {
    if (id) {
      _placementSplit({
        variables: {
          placementId: id,
        },
      });
    }

    // eslint-disable-next-line
  }, [id]);



  useEffect(() => {
    const recruitingList: IRecruiterPercentage | unknown | any = [];
    const accountManagingList: IAccountManagerPercentage[] = [];

    if (_placementSplitData && companyOwnerSharees?.getShareesAndOwner) {
      if (_placementSplitData?.placementSplitting?.length) {
        for (
          let index = 0;
          index < _placementSplitData?.placementSplitting.length;
          index++
        ) {
          const element = _placementSplitData?.placementSplitting[index];
          if (element?.splitType === 'RECRUITING') {
            setRecruiterSplitEdit(true);
            recruitingList.push({
              percentage: element?.splitPercentage,
              recruiter: {
                label: element?.user?.fullName,
                value: element?.user?.id,
              },
            });
          } else {
            setSplitEdit(true);
            accountManagingList.push({
              percentage: element?.splitPercentage,
              accountManager: {
                label: element?.user?.fullName,
                value: element?.user?.id,
              },
            });
          }
        }

        const owner = companyOwnerSharees?.getShareesAndOwner?.find(
          (item: { ownerType: string }) => item?.ownerType === 'OWNER'
        )?.owner;

        setDefaultOwner({ label: owner?.fullName, value: owner?.id });
        setDefaultPercentage(100);

        setDefaultCandidateOwner({
          label:
            _getSubmissionData?.submissionCandidatePipelined?.createdBy
              ?.fullName,
          value:
            _getSubmissionData?.submissionCandidatePipelined?.createdBy?.id,
        });

        setAccountManagerListQuery(accountManagingList);
        setRecruiterListQuery(recruitingList);
      } else {
        if (companyOwnerSharees?.getShareesAndOwner) {
          const owner = companyOwnerSharees?.getShareesAndOwner?.find(
            (item: { ownerType: string }) => item?.ownerType === 'OWNER'
          )?.owner;

          setDefaultOwner({ label: owner?.fullName, value: owner?.id });
          setDefaultPercentage(100);
        }
        if (_getSubmissionData?.submissionCandidatePipelined?.createdBy) {
          setDefaultCandidateOwner({
            label:
              _getSubmissionData?.submissionCandidatePipelined?.createdBy
                ?.fullName,
            value:
              _getSubmissionData?.submissionCandidatePipelined?.createdBy?.id,
          });
        }
      }
    }

    if (companyOwnerSharees?.getShareesAndOwner?.length) {
      let ownerSharees = companyOwnerSharees?.getShareesAndOwner?.map(
        (item) => item.owner?.id
      );
      setOwnerSharees(ownerSharees);
    }

    // eslint-disable-next-line
  }, [
    _placementSplitData,
    companyOwnerSharees?.getShareesAndOwner,
    _getSubmissionData?.submissionCandidatePipelined,
  ]);

  useEffect(() => {
    if (
      _getPlacementData?.getPlacementById &&
      companyOwnerSharees?.getShareesAndOwner
    ) {
      const placement = _getPlacementData?.getPlacementById;
      const inputDate = new Date(placement?.startDateString);
      const currentDate = new Date();
      if (
        placementUpdateStatusPermission.includes(
          _getPlacementData?.getPlacementById?.placementStatus
        )
      ) {
        let ownerAndShare = companyOwnerSharees?.getShareesAndOwner?.map(
          (item) => item?.owner?.id
        );

        let currentUserIsOwnerOrShare = ownerAndShare?.includes(user?.id);
        let candidateOwnerCurrentUser =
          _getPlacementData?.getPlacementById?.recruiter?.id === user?.id;

        let companyOwnerAndCandidateOwnerSame = ownerAndShare?.includes(
          _getPlacementData?.getPlacementById?.recruiter?.id
        );
        if (
          (companyOwnerAndCandidateOwnerSame && candidateOwnerCurrentUser) ||
          currentUserIsOwnerOrShare
        ) {
          setCandidateEditPermit(true);
        }
        if (
          (companyOwnerAndCandidateOwnerSame && currentUserIsOwnerOrShare) ||
          currentUserIsOwnerOrShare
        ) {
          setEditPermit(true);
        }
      }
      // If we have manager, AM, or exec/admin with pending manager status of placement
      if (
        (_getPlacementData?.getPlacementById?.managerOfAM?.id === user?.id ||
          userRoles?.includes(ADMIN_ROLE) ||
          userRoles?.includes(EXECUTIVE_ROLE)) &&
        _getPlacementData?.getPlacementById?.placementStatus ===
        'PENDING_MANAGER'
      ) {
        setApproveRejectManagerPermit(true);
      }
      // ATS-908: For Accounting Manager and Exec user roles display candidate edit and job info edit || for AM (Company Owner)
      // if placement status not cancelled/ended (check handled withing components)
      if (
        userRoles?.includes(ACCOUNTING_MANAGER_ROLE) ||
        userRoles?.includes(EXECUTIVE_ROLE) ||
        _getPlacementData?.getPlacementById?.accountManager?.id === user?.id
      ) {
        setCandidateEditPermit(true);
        setEditPermit(true);
      }
      // ATS-908: Date Check
      if (inputDate.getTime() < currentDate.getTime()) {
        setCandidateEditPermit(false);
        setEditPermit(false);
      }
      if (_getPlacementData?.getPlacementById?.jobApplicant?.id) {
        _getSubmission({
          variables: {
            jobApplicantId:
              _getPlacementData?.getPlacementById?.jobApplicant?.id,
          },
        });
      }

      if (_getPlacementData?.getPlacementById?.recruiter?.id !== user?.id &&
        (_getPlacementData?.getPlacementById?.placementStatus !==
          ACCOUNTING_APPROVED &&
          _getPlacementData?.getPlacementById?.placementStatus !==
          PAID &&
          _getPlacementData?.getPlacementById?.placementStatus !==
          ACCOUNTING_INVOICED) &&
        (currentDate.getTime() < inputDate.getTime())
      ) {
        setCandidateEditPermit(false);
      }

      if (_getPlacementData?.getPlacementById?.accountManager?.id !== user?.id &&
        (_getPlacementData?.getPlacementById?.placementStatus !==
          ACCOUNTING_APPROVED &&
          _getPlacementData?.getPlacementById?.placementStatus !==
          PAID &&
          _getPlacementData?.getPlacementById?.placementStatus !==
          ACCOUNTING_INVOICED) &&
        (currentDate.getTime() < inputDate.getTime())
      ) {
        setEditPermit(false);
      }

      if ((_getPlacementData?.getPlacementById?.placementStatus !==
        ACCOUNTING_APPROVED ||
        _getPlacementData?.getPlacementById?.placementStatus !==
        PAID ||
        _getPlacementData?.getPlacementById?.placementStatus !==
        ACCOUNTING_INVOICED) && (currentDate.getTime() > inputDate.getTime())) {
        setEditPermit(false);
        setCandidateEditPermit(false);
      }

      if (_getPlacementData?.getPlacementById?.recruiter?.id !== user?.id && (_getPlacementData?.getPlacementById?.placementStatus ===
        ACCOUNTING_APPROVED || _getPlacementData?.getPlacementById?.placementStatus === ACCOUNTING_INVOICED || _getPlacementData?.getPlacementById?.placementStatus === PAID) && (currentDate.getTime() > inputDate.getTime())) {
        setCancelButton(false)
      }

      if (_getPlacementData?.getPlacementById?.recruiter?.id === user?.id) {
        setCandidateEditPermit(true);
      }
      if (_getPlacementData?.getPlacementById?.accountManager?.id === user?.id) {
        setEditPermit(true);
      }
      if (userRoles?.includes(EXECUTIVE_ROLE)) {
        setCandidateEditPermit(true);
        setEditPermit(true);
      }
    }

    // eslint-disable-next-line
  }, [
    _getPlacementData?.getPlacementById,
    _getPlacementLoading,
    companyOwnerSharees,
  ]);

  const onApprovingPlacement = async () => {
    const approveRes = await _onApprovingPlacement({
      variables: {
        id,
      },
    });
    if (approveRes.data) {
      toast.success(
        'Placement is approved and passed to accounts person for further steps'
      );

      setTimeout(() => {
        history.push(PLACEMENT_MANAGEMENT_ROUTE);
      }, 800);
    }
  };

  const toogleReject = () => {
    setReject(!reject);
  };

  const onRejectPlacement = async (values: any) => {
    const response = await createNotes({
      variables: {
        noteableType: 'Candidate',
        noteableId: _getPlacementData?.getPlacementById?.candidate?.id,
        category: `Placement - Manager Rejected`,
        content: values?.content,
      },
    });

    if (response?.data) {
      const rejectRes = await _onRejectPlacement({
        variables: {
          id,
          reason: values?.content,
        },
      });

      if (rejectRes?.data) {
        toast.success(
          'Placement is rejected and moved to Recruiter Pending state'
        );
        toogleReject();
        history.push(PLACEMENT_MANAGEMENT_ROUTE);
      }
    }
  };

  const afterManagerApproval = (): boolean => {
    let status: string = _getPlacementData?.getPlacementById?.placementStatus;
    if (
      !(
        status === 'DRAFT' ||
        status === 'PENDING_AM' ||
        status === 'PENDING_MANAGER' ||
        status === 'PENDING_RECRUITER'
      )
    ) {
      return true;
    }

    return false;
  };

  const changeCompanyContactHandler = (contactId: string) => {
    if (contactId) {
      getContactHistory({ variables: { contactId: contactId } });
    } else {
      setContactWarning(false);
    }
  };

  useEffect(() => {
    if (contactHistory?.getContactHistoryDetail && !contactWarning) {
      setContactWarning(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactHistory?.getContactHistoryDetail]);

  const newAssignmentAndBillingSheetPermission = () => {
    return userRoles?.some((role) =>
      [EXECUTIVE_ROLE, ACCOUNTING_MANAGER_ROLE, ACCOUNTING_COORDINATOR_ROLE]?.includes(role)
    );
  };

  //ATS-1252 this use-effect was causing issue if the user have not followed the correct workflow 
  //and this leads to data discrepancy so commenting this and will remove it in the future

  // ATS 1011: replace placement logic
  // const [replacePlacementMutation] = useMutation(REPLACE_PLACEMENT);

  // useEffect(() => {
  //   const replacementId = localStorage.getItem('isReplacement')
  //   if (replacementId) {
  //     const input = {
  //       id,
  //       replacementId,
  //     };

  //     replacePlacementMutation({
  //       variables: {
  //         replacePlacementInput: input,
  //       },
  //     });
  //     localStorage.removeItem('isReplacement');
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  // ATS 1010: requests module - placement cancellation
  const isAccountingManagerForRequest = () => {
    const placementStartDate = new Date(_getPlacementData?.getPlacementById?.startDateString);
    const currentDate = new Date();
    if (_getPlacementData?.getPlacementById?.jobOrder?.company?.ownerId === user?.id &&
      _getPlacementData?.getPlacementById?.placementType === DIRECT_HIRE &&
      currentDate > placementStartDate) {
      return true;
    }
    return false;
  }

  return (
    <div className="admin-tabs team-tabs">
      <>
        <GoBack
          url={comingState?.backUrl ?? PLACEMENT_MANAGEMENT_ROUTE}
          search={comingState?.searchParameters}
        />

        <ToastContainer autoClose={3000} />

        <div className="primaryHeading primaryHeaderSpacing mobileResponsiveFlexAlign justify-content-between gap-10">
          <div>
            <h5 className="m-0">
              <div className="d-flex align-items-center">
                <span>
                  Placement Management Details{' '}
                  {_getPlacementData?.getPlacementById?.placementId &&
                    ("  -  " + _getPlacementData?.getPlacementById?.placementId)}
                </span>
                {_getPlacementData?.getPlacementById?.placementType ===
                  DIRECT_HIRE &&
                  _getPlacementData?.getPlacementById?.isPaid && (
                    <span className="green-dot" />
                  )}{' '}
                {/* {_getPlacementData?.getPlacementById?.companyFirstPlacement && (
                  <Badge
                    color="info font-weight-bold text-capitalize  "
                    className="ml-2"
                  >
                    First Placement
                  </Badge>
                )} */}
              </div>
            </h5>

            {_getPlacementData?.getPlacementById?.replacementId && (
              <Button color="link" onClick={() => window.open(`${VIEW_PLACEMENT_MANAGEMENT_ROUTE}/${_getPlacementData?.getPlacementById?.replacementId}`)} target="_blank" className="p-0 text-left text-blue-link">
                This is a replacement
              </Button>
            )}
          </div>

          <div className="d-flex flex-wrap gap-10">
            {approveRejectManagerPermit ? (
              <>
                <Button
                  className="border-0 mr-3"
                  color="success "
                  onClick={() => {
                    setAccountManagersManagerApproval(
                      !accountManagersManagerApproval
                    );
                  }}
                >
                  Approve
                </Button>
                <Button
                  className="border-0 mr-2"
                  color="danger"
                  onClick={toogleReject}
                >
                  Reject
                </Button>
              </>
            ) : null}

            {_getPlacementData?.getPlacementById?.placementStatus !==
              'PLACEMENT_CANCELLED' &&
              _getPlacementData?.getPlacementById?.placementType ===
              'CONTRACT' &&
              afterManagerApproval() &&
              newAssignmentAndBillingSheetPermission() && (
                <button
                  type="button"
                  className="buttonGenericStyle filledButton mr-0"
                  onClick={() => {
                    history.push({
                      pathname: `${VIEW_ACCOUNTING_DASHBOARD_ROUTE}/${id}/newassignment`,
                      state: {
                        from: 'placement-management',
                      },
                    });
                  }}
                >
                  New Assignment
                </button>
              )}

            {!['PLACEMENT_CANCELLED', 'PENDING_ACCOUNTING'].includes(
              _getPlacementData?.getPlacementById?.placementStatus
            ) &&
              ['CONTRACT', DIRECT_HIRE].includes(
                _getPlacementData?.getPlacementById?.placementType
              ) &&
              afterManagerApproval() &&
              newAssignmentAndBillingSheetPermission() && (
                <button
                  type="button"
                  className="buttonGenericStyle filledButton m-0"
                  onClick={() => {
                    history.push({
                      pathname: `${VIEW_ACCOUNTING_DASHBOARD_ROUTE}/${id}/billingsheet`,
                    });
                  }}
                >
                  Billing Sheet
                </button>
              )}

            {_getPlacementData?.getPlacementById?.accountManager?.id ===
              user?.id &&
              [ACCOUNTING_INVOICED, ACCOUNTING_APPROVED].includes(
                _getPlacementData?.getPlacementById?.placementStatus
              ) &&
              _getPlacementData?.getPlacementById?.placementType ===
              'CONTRACT' ? (
              <button
                className="buttonGenericStyle filledButton mx-0"
                onClick={() => {
                  setPlacementEndOrCancel(false);
                  setEndDateModal(true);
                  setDisableAllFields(true);
                }}
              >
                Convert To Direct Hire
              </button>
            ) : null}

            <button
              className="buttonGenericStyle filledButton"
              onClick={() => {
                setPlacementViewReadonlyModal(true);
                setDisableAllFields(true);
              }}
            >
              View Placement Detail
            </button>

            {_getPlacementData?.getPlacementById?.placementStatus !==
              'PLACEMENT_CANCELLED' &&
              (userRoles?.includes(EXECUTIVE_ROLE) ||
                ownerSharees?.includes(user?.id || '')) && (
                <button
                  className="buttonGenericStyle text-light  border-0 shadow-sm"
                  onClick={() => {
                    setPlacementEndOrCancel(true);
                    setEndDateModal(true);
                  }}
                  style={{
                    background:
                      (!userRoles?.includes(EXECUTIVE_ROLE) && _getPlacementData?.getPlacementById?.placementStatus === REQUEST_PLACEMENT_CANCELLED)
                        ? 'grey'
                        : '#4D6985',
                    cursor:
                      (!userRoles?.includes(EXECUTIVE_ROLE) && _getPlacementData?.getPlacementById?.placementStatus === REQUEST_PLACEMENT_CANCELLED)
                        ? 'not-allowed'
                        : 'pointer',
                  }}
                  disabled={
                    (!userRoles?.includes(EXECUTIVE_ROLE) && _getPlacementData?.getPlacementById?.placementStatus === REQUEST_PLACEMENT_CANCELLED)
                  }
                >
                  Cancel Placement
                </button>
              )}
          </div>
        </div>

        {contactWarning && (
          <Alert color="danger">
            Company contact{' '}
            <strong>
              {' '}
              {contactHistory?.getContactHistoryDetail?.contact?.fullName}
            </strong>{' '}
            is currently assigned to client{' '}
            <strong>
              {contactHistory?.getContactHistoryDetail?.newCompany?.name}
            </strong>
          </Alert>
        )}
        {_getPlacementLoading ? (
          <Loader />
        ) : (
          <>
            {_getPlacementData?.getPlacementById?.approved === false ? (
              <RejectionAlert
                rejectedBy={
                  _getPlacementData?.getPlacementById?.rejectOrApproveBy
                }
                statusNote={_getPlacementData?.getPlacementById?.statusNote}
              />
            ) : _getPlacementData?.getPlacementById?.placementStatus ===
              'PLACEMENT_CANCELLED' ? (
              <CancelAlert
                cancelBy={
                  _getPlacementData?.getPlacementById?.rejectOrApproveBy
                }
                statusNote={_getPlacementData?.getPlacementById?.statusNote}
              />
            ) : (
              ''
            )}

            {_getPlacementData?.getPlacementById && (
              <div className="jobOrderRevamp">
                <Row>
                  <Col lg={6} md={6} sm={12} xs={12}>
                    <CandidateWidgetPlacement
                      watch={null}
                      fromWhichPlacement="placement-management"
                      placement={_getPlacementData?.getPlacementById}
                      jobId={_getPlacementData?.getPlacementById?.jobOrder?.id}
                      jobApplicantId={
                        _getPlacementData?.getPlacementById?.jobApplicant?.id
                      }
                      candidateOwner={
                        _getPlacementData?.getPlacementById?.recruiter?.fullName
                      }
                      id={_getPlacementData?.getPlacementById?.candidate?.id}
                      placementAccountsStatus={candidateEditPermit}
                      widgetPermissions={!candidateEditPermit}
                      placementStatus={
                        _getPlacementData?.getPlacementById?.placementStatus
                      }
                      refetchPlacement={refetch}
                    />
                  </Col>

                  <Col lg={6} md={6} sm={12} xs={12}>
                    <JobOrderWidgetDetail
                      watch={null}
                      fromWhichPlacement="placement-management"
                      placement={_getPlacementData?.getPlacementById}
                      candidateId={
                        _getPlacementData?.getPlacementById?.candidate?.id
                      }
                      jobApplicantId={
                        _getPlacementData?.getPlacementById?.jobApplicant?.id
                      }
                      accountManager={
                        _getPlacementData?.getPlacementById?.accountManager
                          ?.fullName
                      }
                      placementAccountsStatus={editPermit}
                      widgetPermissions={!editPermit}
                      from="view"
                      id={_getPlacementData?.getPlacementById?.jobOrder?.id}
                      companyId={
                        _getPlacementData?.getPlacementById?.jobOrder?.company
                          ?.id
                      }
                      pipeCompanyIdToParent={() => { }}
                      placementStatus={
                        _getPlacementData?.getPlacementById?.placementStatus
                      }
                      contactChangeHandler={changeCompanyContactHandler}
                      refetchPlacement={refetch}
                    />
                  </Col>
                </Row>
              </div>
            )}
          </>
        )}

        <div className="mt-3">
          {_getPlacementLoading ? (
            <Loader />
          ) : (
            <ViewPlacementSteps
              setAccountManagerList={setAccountManagerList}
              defaultPercentage={defaultPercentage}
              defaultOwner={defaultOwner}
              defaultCandidateOwner={defaultCandidateOwner}
              splitEdit={splitEdit}
              recruiterSplitEdit={recruiterSplitEdit}
              viewingAccountManagerArr={accountManagerList}
              accountManagerListQuery={accountManagerListQuery}
              recruiterListQuery={recruiterListQuery}
              viewingRecruiterList={recruiterList}
              setRecruiterList={setRecruiterList}
              viewData={_getPlacementData?.getPlacementById}
              companyOwnerAndShares={companyOwnerSharees?.getShareesAndOwner}
              placementSplitData={_placementSplitData?.placementSplitting}
              placementStatus={
                _getPlacementData?.getPlacementById?.placementStatus
              }
              refetchingPlacement={() => {
                _getPlacement({
                  variables: { id },
                });
              }}
              refetchingPlacementSplit={() => {
                _placementSplit({
                  variables: {
                    placementId: id,
                  },
                });
              }}
            />
          )}
        </div>
        {(_getPlacementData?.getPlacementById?.placementStatus === ACCOUNTING_APPROVED
          || _getPlacementData?.getPlacementById?.placementStatus === ACCOUNTING_INVOICED
          || _getPlacementData?.getPlacementById?.placementStatus === PAID)
          && _getPlacementData?.getPlacementById?.placementType === DIRECT_HIRE
          && < InvoiceStatusTable invoiceNumber={_getPlacementData?.getPlacementById?.invoiceNumber}
            refetchPlacement={refetch} placementId={id} />}
      </>

      <Modal
        isOpen={placementViewReadonlyModal}
        toggle={() =>
          setPlacementViewReadonlyModal(!placementViewReadonlyModal)
        }
        scrollable
        className="for-modal-styling editPlacementDialog viewPlacementDetails-modal"
      >
        <ModalHeader
          toggle={() =>
            setPlacementViewReadonlyModal(!placementViewReadonlyModal)
          }
        >
          <div className="d-flex align-items-center">
            <span>View placement details</span>
            {_getPlacementData?.getPlacementById?.companyFirstPlacement && (
              <Badge
                color="info  font-weight-bold"
                className="ml-2 text-capitalize"
              >
                First Placement
              </Badge>
            )}
          </div>
          <button
            className="iconButton"
            onClick={() => setPlacementViewReadonlyModal(false)}
            type="button"
          >
            <img src={CLOSE_ICON} alt="close" />
          </button>
        </ModalHeader>
        <ModalBody>
          <Form>
            <EditPlacement
              disableAllFields={disableAllFields}
              readonly={true}
              setValue={setValue}
              setError={setError}
              watch={watch}
              control={control}
              reset={reset}
              register={register}
              errors={errors}
              viewData={_getPlacementData?.getPlacementById}
            />
          </Form>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            onClick={() => setPlacementViewReadonlyModal(false)}
            className="buttonPrimary mr-3"
          >
            Cancel
          </button>
        </ModalFooter>
      </Modal>

      {!isAccountingManagerForRequest() ? (
        <MoldalForDirectHire
          endDateModal={endDateModal}
          setEndDateModal={setEndDateModal}
          placementId={_getPlacementData?.getPlacementById}
          refetch={refetch}
          AccountingCancel={placementEndOrCancel}
          history={history}
        />) : (
        <MoldalForDirectHire
          isCancellationRequest={true}
          endDateModal={endDateModal}
          setEndDateModal={setEndDateModal}
          placementId={_getPlacementData?.getPlacementById}
          refetch={refetch}
          AccountingCancel={placementEndOrCancel}
          history={history}
        />
      )}

      <ConfirmPopUp
        confirmText={'Are you sure you want to approve the placement?'}
        isOpen={accountManagersManagerApproval}
        toggle={() => {
          setAccountManagersManagerApproval(!accountManagersManagerApproval);
        }}
        confirmAction={onApprovingPlacement}
        modalHeading="Confirmation Action"
        btnText={'Approve'}
        btnColor="primary"
        className="revampDialog revampDialogWidth"
      />

      <RejectNotes
        modal={reject}
        toggle={toogleReject}
        onRejectConfirm={onRejectPlacement}
        loading={rejectLoading}
      />
    </div>
  );
};

export default ViewPlacementManagement;
