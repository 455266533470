import { gql } from "@apollo/client";

const contactDetails = `
contacts{
  id
  fullName
  jobTitle
  source
  status
  contactTypes
  isPrivate
  contactPhone{
    isPrimary
    phoneType
    number
  }
  company{
    name
    id
    owner{
      id
      fullName
    }
    ownerAndSharees {
    id
    sharingId
    ownerType
      owner {
        id
        fullName
      }
    }
    companyAgreement {
      id
      nickName
      status
      signedAt
    }
  }
  lastContactedUser{
    fullName
  }
  companyLocation{
    streetAddress
    isPrimary
  }
  contactEmail{
    email
    isPrimary
  }
}
  count
`;

// const Aggrements = `
// Aggrements{
//   id
//   nickName
//   updateAt
//   agreementType
//   commissionPercentage
//   status
//   documentId
//   company
//   {
//     id
//     name
//     companyAgreement {
//       id
//       nickName
//       status
//       signedAt
//     }
//   }
//   companyContactId{
//     firstName
//     lastName
//     id

//   }
// }
// `;

export const FETCH_CONTACTS = gql`
 query FetchAllContacts($page: Int!, $limit: Int!, $searchText:String, $statusSearch:String, $companyId:String,$columnFilters: ContactColumnFilter!) {
  allContacts(
    payload: { page: $page, limit: $limit}, 
    filters:{searchText:$searchText,statusSearch:$statusSearch,  companyId:$companyId},
    columnFilters:$columnFilters
  )
 {
  ${contactDetails}
 }
 }
`;

// export const FETCH_AGREEMENTS = gql`
//  query FetchAllContacts($page: Int!, $limit: Int!, $searchText:String, $statusSearch:String,$contactType:String, $companyId:String) {
//   allContacts(
//     payload: { page: $page, limit: $limit},
//     filters:{searchText:$searchText,statusSearch:$statusSearch, contactType :$contactType, companyId:$companyId}
//   )
//  {
//   ${Aggrements}
//  }
//  }
// `;

export const FETCH_CONTACTS_DETAIL = gql`
  query FetchContactDetail($id: String!) {
    fetchContactDetail(id: $id) {
      id
      firstName
      lastName
      jobTitle
      source
      status
      contactTypes
      twitterUrl
      instaUrl
      fbUrl
      fullName
      isPrivate
      linkedInUrl
      lastContactedAt
      preferredContact
      lastContactedUser {
        id
        fullName
      }
      contactPhone {
        id
        phoneType
        number
        isPrimary
      }
      contactEmail {
        id
        email
        isPrimary
      }
      companyLocation {
        id
        streetAddress
        city
        state
        zipCode
        country
      }
      company {
        id
        name
        owner {
          id
          fullName
        }
        ownerAndSharees {
        id
        sharingId
        ownerType
        owner {
          id
          fullName
        }
      }
        address {
          streetAddress
          id
        }
      }
    }
  }
`;

// eslint-disable-next-line
const CreateCompanyContact = gql`
  input CreateContactInput {
    firstName: String
    lastName: String
    jobTitle: String
    source: String
    twitterUrl: String
    fbUrl: String
    locationId: String
    contactEmails: [ContactEmailInput!]
    contactPhones: [ContactPhoneInput!]
    companyId: String
    linkedInUrl: String
    instaUrl: String
    isPrivate: Boolean
  }
`;

export const CREATE_COMPANY_CONTACT = gql`
  mutation CreateCompanyContact($createContactInput: CreateContactInput!) {
    createContact(createContactInput: $createContactInput) {
      id
      isPrivate
      firstName
      fullName
      lastName
      jobTitle
      contactEmail {
        id
        isPrimary
        email
      }
    }
  }
`;
// eslint-disable-next-line
const UpdateContactInput = gql`
  input UpdateContactInput {
    firstName: String
    lastName: String
    jobTitle: String
    source: String
    status: String
    twitterUrl: String
    fbUrl: String
    locationId: String
    contactEmails: [ContactEmailInput!]
    contactPhones: [ContactPhoneInput!]
    companyId: String
    linkedInUrl: String
    instaUrl: String
  }
`;

export const UPDATE_CONTACT = gql`
  mutation CreateCompanyContact($UpdateContactInput: UpdateContactInput!) {
    updateContact(updateContactInput: $UpdateContactInput) {
      message
      status
    }
  }
`;

export const CHANGE_CONTACT_COMPANY = gql`
  mutation ChangeCompanyContact(
    $changeContactCompany: ChangeContactCompanyInput!
  ) {
    changeContactCompany(changeContactCompany: $changeContactCompany) {
      message
      status
    }
  }
`;

export const DELETE_CONTACT_PHONE = gql`
  mutation DeletePhone($id: String!) {
    deletePhone(id: $id) {
      message
      status
    }
  }
`;

export const DELETE_CONTACT_EMAIL = gql`
  mutation DeleteEmail($id: String!) {
    deleteContactEmail(id: $id) {
      message
      status
    }
  }
`;
export const FETCH_CONTACT_HISTORY = gql`
  query FetchContactHistory($contactId: String!, $page: Int!, $limit: Int!) {
    fetchContactHistory(
      contactId: $contactId
      payload: { page: $page, limit: $limit }
    ) {
      contactHistory {
        id
        oldCompany {
          id
          name
        }
        contact {
          id
          fullName
        }
        newCompany {
          id
          name
        }
        movedBy {
          id
          fullName
        }
        createdAt
      }
      count
    }
  }
`;

export const ENABLE_DISABLE_CONTACT = gql`
  mutation EnableDisableContact($contactId: String!) {
    enableDisableContact(contactId: $contactId) {
      message
      status
    }
  }
`;

export const FETCH_CONTACT_HISTORY_DETAIL = gql`
  query FetchContactHistoryDetail($contactId: String!) {
    getContactHistoryDetail(contactId: $contactId) {
      id
      oldCompany {
        id
        name
      }
      newCompany {
        id
        name
      }
      contact {
        id
        fullName
      }
    }
  }
`;
