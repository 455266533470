import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
import { Button } from "reactstrap";
import NoDataFound from "../../../components/layout/NoDataFound";
import TableComponent from "../../../components/layout/TableComponent";
import { downloadSignedUrlFile, formatDateUS } from "../../../utils/commonFn";
import DeleteIcon from "../../../assets/images/delete-field.svg";
import ConfirmPopUp from "../../../components/ConfirmPopUp";
import { toast } from "react-toastify";
import { Pagination } from "../../../components/layout/Pagination";
import Loader from "../../../components/layout/Loader";
import AttachmentModal from "../../../components/AttachmentModal";
import InputField from "../../../components/layout/InputField";
import { PAGE_LIMIT } from "../../../utils/constant";
import AttachmentDocument from "./AttachmentDocument";

export const GET_ATTACHMENTS = gql`
  query GetAttachments($companyId: String!, $page: Int!, $limit: Int!) {
    getAttachments(
      companyId: $companyId
      payload: { page: $page, limit: $limit }
    ) {
      attachments {
        id
        attachableId
        attachableType
        documentName
        documentUrl
        createdAt
        user {
          id
          fullName
        }
      }
      count
    }
  }
`;

export const REMOVE_ATTACHMENT = gql`
  mutation RemoveAttachment($attachmentId: String!) {
    removeAttachment(attachmentId: $attachmentId) {
      message
      status
    }
  }
`;

export const CREATE_ATTACHMENTS = gql`
  mutation CreateAttachment(
    $attachableId: String!
    $attachableType: String!
    $documentUrl: String!
    $documentName: String
    $driveUrl: String
  ) {
    createAttachment(
      attachmentInput: {
        attachableId: $attachableId
        attachableType: $attachableType
        documentUrl: $documentUrl
        documentName: $documentName
        driveUrl: $driveUrl
      }
    ) {
      message
      status
    }
  }
`;

const Attachments = ({
  companyId,
  attachableType,
  haveActionPersmissions,
}: {
  companyId: string | null;
  attachableType: string | null;
  haveActionPersmissions?: boolean;
}) => {
  const [confirmToggle, setConfirmToggle] = useState(false);
  const [attachmentId, setAttachmentId] = useState("");
  const [modal, setModal] = useState(false);

  const page = parseInt("1");
  const [currentPage, setCurrentPage] = useState(page);
  const [limit, setLimit] = useState(3);

  const toggle = () => setModal(!modal);

  const [getAttachments, { data, loading }] = useLazyQuery(GET_ATTACHMENTS);
  const [_removeAttachment] = useMutation(REMOVE_ATTACHMENT);

  const removeAttachment = async () => {
    let res = await _removeAttachment({ variables: { attachmentId } });
    if (res?.data) {
      toast.success("Attachment removed successfully!");
      getAttachments({ variables: { companyId, limit, page: 1 } });
      setConfirmToggle(false);
    }
  };

  const _getAttachments = () => {
    getAttachments({
      variables: { companyId, limit, page: currentPage },
    });
  };

  useEffect(() => {
    if (companyId) {
      _getAttachments();
    }

    // eslint-disable-next-line
  }, [companyId, limit, currentPage]);

  const onDownloadAttachment = async (
    unsignedOriginalUrl: string,
    name: string
  ) => {
    downloadSignedUrlFile(unsignedOriginalUrl, name);
  };

  return (
    <div>
      <div className="resumeHeader d-flex justify-content-end">
        <button
          type="button"
          className="buttonGenericStyle filledButton"
          onClick={toggle}
          disabled={haveActionPersmissions}
        >
          Upload Attachment
        </button>
      </div>

      <div>
        <div className="table-responsive">
          {loading ? (
            <Loader loaderClass="sm-height" />
          ) : (
            <TableComponent
              tableHeader={
                attachableType === "Candidate"
                  ? ["Name", "Uploaded By", "Uploaded At", "Type", "Action"]
                  : ["Name", "Uploaded By", "Uploaded At", "Action"]
              }
            >
              {!loading && !data?.getAttachments?.attachments?.length && (
                <tbody>
                  <tr>
                    <td colSpan={5} className="empty-table-td">
                      <div className="for-position-multiselect">
                        <NoDataFound text="No Attachment Found!" />
                      </div>
                    </td>
                  </tr>
                </tbody>
              )}

              <tbody>
                {data?.getAttachments?.attachments &&
                  data?.getAttachments?.attachments
                    .filter((item) => item?.attachableType !== "Candidate")
                    .map((item, index) => (
                      <tr key={index}>
                        <td className="cursor-pointer">
                          <AttachmentDocument
                            onDownloadAttachment={onDownloadAttachment}
                            item={item}
                          />
                        </td>
                        <td>{item?.user?.fullName || "--"}</td>
                        <td>
                          {item?.createdAt
                            ? formatDateUS(item?.createdAt)
                            : "--"}
                        </td>
                        {attachableType === "Candidate" && (
                          <td>{item?.attachableType}</td>
                        )}
                        <td>
                          <Button
                            disabled={haveActionPersmissions}
                            className="btn-delete bg-transparent border-0"
                            onClick={() => {
                              setAttachmentId(item?.id);
                              setConfirmToggle(!confirmToggle);
                            }}
                          >
                            <img src={DeleteIcon} alt="" />
                          </Button>
                        </td>
                      </tr>
                    ))}
              </tbody>
            </TableComponent>
          )}
        </div>

        {data?.getAttachments?.attachments?.length ? (
          <div className="users-pagination">
            <Pagination
              onPageChange={(pageClicked: number) => {
                setCurrentPage(pageClicked);
              }}
              pageCount={Math.ceil(data?.getAttachments?.count / limit)}
              currentPage={currentPage}
            />

            <div className="d-flex align-items-center">
              <div className="pagination-display paginationItemsSelect">
                <p className="m-0">
                  {(currentPage - 1) * limit + 1} to{" "}
                  {Math.min(currentPage * limit, data?.getAttachments?.count)}{" "}
                  of {data?.getAttachments?.count}
                </p>

                <div className="divider"></div>

                <label>Display</label>

                <InputField
                  label=""
                  inputtype="select"
                  selectItems={PAGE_LIMIT.filter(
                    (item) => item.value !== limit
                  )}
                  inputid="limit"
                  placeholder={limit.toString()}
                  inputMethod={(value) => {
                    setCurrentPage(1);
                    setLimit(parseInt(value));
                  }}
                />
              </div>
            </div>
          </div>
        ) : null}
      </div>

      <AttachmentModal
        toggle={toggle}
        modal={modal}
        attachmentType={attachableType}
        attachmentId={companyId}
        prevTable={"Attachment"}
        refetch={_getAttachments}
      />
      <ConfirmPopUp
        confirmText="Are you sure you want to remove this attachment?"
        isOpen={confirmToggle}
        toggle={setConfirmToggle}
        confirmAction={removeAttachment}
        modalHeading="Remove Attachment"
        btnText="Confirm"
        btnColor="primary"
      />
    </div>
  );
};

export default Attachments;
