import { gql } from "@apollo/client";

export const FETACH_ALL_ALERTS_OR_WARNINGS = gql`
  query GetAllSystemAlertsQuery($getAllSystemAlertsInput: GetAllSystemAlertsInput!) {
    getAllSystemAlerts(getAllSystemAlertsInput: $getAllSystemAlertsInput) {
      systemAlerts {
        id
        name
        message
        expirationDate
      }
      count
    }
  }
`