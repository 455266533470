import { useLazyQuery, useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import {
  CLEARBIT_AUTOCOMPLETE,
  CONTACT_LOOKUP,
  CONTACT_LOOKUP_ADD,
} from "../gql";
import CustomButton from "../../../../components/layout/CustomButton";
import { useForm } from "react-hook-form";
import CompanySuggestions from "./CompanySuggestions";

let time = 0;

export default function LookupContact({
  addContactToggle,
  compaignId,
  refetchStats,
  reFetchContacts,
}) {
  const { errors, setValue, getValues } = useForm();

  const [companyName, setCompanyName] = useState<string>("");
  const [companyToggle, setCompanyToggle] = useState<boolean>(false);
  const [searchingText, setSearchingText] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [contactLookup, { data, loading, error }] =
    useLazyQuery(CONTACT_LOOKUP);

  const [
    getClearbitSuggestions,
    { loading: loadingClearbitSuggestions, data: dataClearbitSuggestions },
  ] = useLazyQuery(CLEARBIT_AUTOCOMPLETE);

  const [createLookupContact, { loading: loadingContactAddLookup }] =
    useMutation(CONTACT_LOOKUP_ADD);

  const lookupContacts = () => {
    contactLookup({
      variables: { name: name, query: companyName },
    });
  };

  const validateLookupInputs = (): boolean => {
    if (!(name && companyName)) {
      return true;
    }
    return false;
  };

  const lookupContactAdd = async () => {
    const response = await createLookupContact({
      variables: {
        compaignId,
        email: data?.contactLookup[0]?.email,
        name,
        company: companyName,
      },
    });

    if (response?.data) {
      window?.location?.reload();
      toast.success("Successfully created contact");
      reFetchContacts();
      refetchStats();
      addContactToggle();
    }
  };

  const getSelectedCompanyData = (value: any) => {
    setCompanyName(value?.name);
    setValue("company", value?.name);
  };

  const getClearbitAutocompleteSuggestions = (query: string) => {
    getClearbitSuggestions({ variables: { query } });
  };

  useEffect(() => {
    if (dataClearbitSuggestions?.clearbitAutocompleteForCompanyFinding) {
      setCompanyToggle(true);
    }
  }, [dataClearbitSuggestions?.clearbitAutocompleteForCompanyFinding]);

  useEffect(() => {
    if (searchingText && searchingText?.length > 2) {
      window?.clearTimeout(time);
      time = window?.setTimeout(() => {
        getClearbitAutocompleteSuggestions(searchingText);
      }, 1000);
    }

    // eslint-disable-next-line
  }, [searchingText]);

  return (
    <div>
      <Row>
        <Col lg="12" md="12">
          <FormGroup className="mb-3">
            <Label>Name</Label>
            <Input
              type="text"
              name="name"
              value={name}
              onChange={(event) => setName(event?.target?.value)}
              placeholder="Please enter contact name"
            />
          </FormGroup>
        </Col>

        <Col xl="12" lg="12">
          <Label>Company</Label>
          <FormGroup>
            <Input
              inputtype="input"
              inputid="company"
              aria-label="Company"
              placeholder="Please enter company name"
              loading
              value={companyName || getValues()?.company}
              errMsg={errors?.company?.message}
              onChange={({ target }: React.ChangeEvent<HTMLInputElement>) => {
                setCompanyName("");
                setSearchingText(target?.value);
              }}
            />

            {companyToggle && searchingText?.length > 2 && (
              <CompanySuggestions
                type="lookup"
                loading={loadingClearbitSuggestions}
                companies={
                  dataClearbitSuggestions?.clearbitAutocompleteForCompanyFinding
                }
                setSelectedCompany={getSelectedCompanyData}
                setCompanyToggle={setCompanyToggle}
              />
            )}
          </FormGroup>
        </Col>

        <Col lg="12" md="12" className="mt-2">
          <CustomButton
            buttonText="Look Up Contact"
            buttonColor="secondary"
            buttonType="button"
            disabled={validateLookupInputs()}
            buttonClick={lookupContacts}
            loading={loading}
          />
        </Col>

        <Col lg="12" md="12" className="mt-2">
          {error && <h5>Could not find a verified email address</h5>}

          {data?.contactLookup && (
            <h5>Found a contact against {companyName}</h5>
          )}
        </Col>
      </Row>

      <div className="d-flex justify-content-end align-items-center mt-2">
        <button
          onClick={addContactToggle}
          className="buttonPrimary mr-3"
          type="button"
        >
          Cancel
        </button>

        <CustomButton
          buttonText="Create Contact"
          buttonType="submit"
          buttonClick={lookupContactAdd}
          loading={loadingContactAddLookup}
          disabled={!data?.contactLookup?.length}
        />
      </div>
    </div>
  );
}
