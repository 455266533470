import { useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Loader from "../../../../components/layout/Loader";
import { MESSAGES_IN_QUEUE } from "../gql";
import { weekDays } from "../utils";
import {
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Card,
  CardBody,
  Spinner,
} from "reactstrap";

export default function Cards({ loading, count, name, type, sequences }) {
  const [modal, setModal] = useState<boolean>(false);
  const [days, setDays] = useState<Array<string>>([]);
  const history = useHistory();

  const [getMessagesQueue, { loading: messagesQueueLoading }] =
    useMutation(MESSAGES_IN_QUEUE);

  const getMessagesInQueue = async () => {
    setModal(true);

    await getMessagesQueue({
      variables: {
        limit_day: "user",
        max_days: 7,
      },
    });
  };

  const contactsAddedToday = () => {
    history.push("/contacts-added-today", {
      from: "created",
      sequencesArray: sequences,
    });
  };

  const meetingsAddedToday = () => {
    history.push("/contacts-added-today", {
      from: "booked",
      sequencesArray: sequences,
    });
  };

  useEffect(() => {
    setDays(weekDays());
  }, []);

  return (
    <>
      <Card
        onClick={() => {
          type === "messages" && getMessagesInQueue();
          type === "contacts" && contactsAddedToday();
          type === "meetings" && meetingsAddedToday();
        }}
      >
        <CardBody className="p-0">
          <label className="w-100 text-center mt-2 text-muted">{name}</label>
          {loading ? (
            <div className="text-center mb-2">
              <Spinner size="sm" style={{ color: "#eaa827" }} />
            </div>
          ) : (
            <h5 className="text-center mb-2">{count}</h5>
          )}
        </CardBody>
      </Card>

      <Modal
        isOpen={modal}
        toggle={() => setModal(!modal)}
        className="revampDialog"
        size="xl"
      >
        <ModalHeader>{name}</ModalHeader>
        <ModalBody>
          {messagesQueueLoading ? (
            <Loader loaderClass="sm-height" />
          ) : (
            <div className="message-queue">
              <p>
                A brief summary of upcoming messages being sent through the week
              </p>

              <div>
                <div>
                  <label>Rest Of Today</label>
                  <h5>0/0</h5>
                </div>
                <div>
                  <label>{days?.length && days[0]}</label>
                  <h5>0/0</h5>
                </div>
                <div>
                  <label>{days?.length && days[1]}</label>
                  <h5>0/0</h5>
                </div>
                <div>
                  <label>{days?.length && days[2]}</label>
                  <h5>0/0</h5>
                </div>
                <div>
                  <label>{days?.length && days[3]}</label>
                  <h5>0/0</h5>
                </div>
                <div>
                  <label>{days?.length && days[4]}</label>
                  <h5>0/0</h5>
                </div>
                <div>
                  <label>{days?.length && days[5]}</label>
                  <h5>0/0</h5>
                </div>
                <div>
                  <label>Beyond 7 Days</label>
                  <h5>0/0</h5>
                </div>
              </div>

              <label>
                This is an estimation of emails being sent per day and only
                includes messages that are ready to be sent (and not waiting to
                be personalized. The actual number of messages sent depends on
                your email schedule and other email deliverability factor.
              </label>
            </div>
          )}
        </ModalBody>
        <ModalFooter className="mt-3">
          <button
            onClick={() => setModal(false)}
            className="buttonPrimary mr-3"
            type="button"
          >
            Close
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
}
