import { useLazyQuery, useQuery } from "@apollo/client"
import { useCallback, useEffect, useState } from "react"
import { toast } from "react-toastify"
import { Col, Modal, Row } from "reactstrap"
import Preview from "../../../../Pages/job-order/job-order-components/document-preview/Preview"
import { GET_SIGNED_URL } from "../../../../Pages/settings/gql"
import CustomButton from "../../../layout/CustomButton"
import { GET_SINGLE_ACTIVITY_REPORT, PRE_LOAD_MONTHS_AND_YEAR } from "./gql"
import YearMonthSelector from "./ReportMonthPicker"

const UserActivityReports = () => {
  const [getSignedURL] = useLazyQuery(GET_SIGNED_URL);
  const [getActivityReport, { loading }] = useLazyQuery(GET_SINGLE_ACTIVITY_REPORT, {
    onCompleted: (data) => {
      console.log("data :: ", data)
      viewReport(data?.getSingleReport?.userActivityReport?.documentLink,
        data?.getSingleReport?.userActivityReport?.documentName)
    },
    onError: () => {
      toast.error("No record found !")
    }
  })
  useQuery(PRE_LOAD_MONTHS_AND_YEAR, {
    onCompleted: async (data) => {
      const monthsAndYear = await organizeData(data?.getPreLoadedMonthAndYear?.monthAndYear)
      setReportData(monthsAndYear)
    }
  })
  const [selectedYear, setSelectedYear] = useState<string>('');
  const [selectedMonth, setSelectedMonth] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [viewDocument, setViewDocument] = useState<boolean>()
  const [documentUrl, setDocumentUrl] = useState<string>('')
  const [documentName, setDocumentName] = useState<string>('')
  const [reportData, setReportData] = useState<{ [key: string]: number[] }>();

  const organizeData = async (data: { month: string, year: string }[]) => {
    return data?.reduce((acc, { year, month }) => {
      if (!acc[year]) {
        acc[year] = [];
      }
      acc[year].push(Number(month));
      return acc;
    }, {});
  }

  const viewReport = async (url: string, name: string) => {
    setViewDocument(true)
    setDocumentUrl(await SignedUrl(url))
    setDocumentName(name)
  }

  const SignedUrl = useCallback(async (url) => {
    const { data } = await getSignedURL({ variables: { url } })
    const { getSignedUrl } = data || {};
    const { signedUrl } = getSignedUrl || {};
    return signedUrl;
  }, [getSignedURL])

  useEffect(() => {
    if (selectedMonth && selectedYear)
      setErrorMessage('')
  }, [selectedMonth, selectedYear])

  const onViewClick = () => {
    try {
      if (selectedMonth && selectedYear) {
        setErrorMessage("")
        getActivityReport({ variables: { month: selectedMonth, year: selectedYear } })
      }
      else
        setErrorMessage("please select month and year first")
    }
    catch (error) {
      toast.error("something went wrong")
    }
  }
  return <>
    <Row >
      <Col lg="4" md="4" xs="12">
        <YearMonthSelector reportData={reportData || {}}
          selectedMonth={selectedMonth} selectedYear={selectedYear}
          setSelectedMonth={setSelectedMonth} setSelectedYear={setSelectedYear} />

        <small className="text-danger pt-2">
          {errorMessage}
        </small>
      </Col>
    </Row>

    <CustomButton
      buttonColor="primary"
      buttonText="View Report "
      className="big-width mt-4"
      loading={loading}
      buttonClick={onViewClick}
    />
    <Modal
      isOpen={viewDocument} toggle={() => setViewDocument(!viewDocument)}
      centered={true} size="xl" className="revampDialog border-0">
      <div className="pdf-viewer">
        <Preview documentUrl={documentUrl} documentName={`${documentName}.pdf`} isDownload={true} />
      </div>
    </Modal>
  </>
}

export default UserActivityReports