// packages block
import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { Button, Col, Form, Input, Label, Row } from "reactstrap";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
// components block
import UserTerminate from "./UserTerminate";
import GoBack from "../../../components/GoBack";
import TestEmailSignature from "./TestEmailSignature";
import Loader from "../../../components/layout/Loader";
import InputField from "../../../components/layout/InputField";
import MultiSelect from "../../../components/layout/MultiSelect";
import CustomButton from "../../../components/layout/CustomButton";
import UploadImage from "../../../components/layout/UploadImage.tsx";
import EmailSignatureTemp from "../../../components/EmailSignatureTemp";
import EmailSignatureInput from "../../../components/EmailSignatureInput";
// utils, graphql and constants block
import { USERTYPE } from "./User-data-type";
import { emailSignatureType } from "./mocks";
import { AppContext } from "../../../context";
import { uploadImage } from "../../../utils/helper";
import AvatarImage from "../../../assets/images/user-empty.svg";
import { IUserTerminateDetail, OptionType } from "../../agreements/interfaces";
import { URL_REGEX, SELECT_STATUS, ROLES, bucketFolders, earningGoalOptions, } from "../../../utils/constant";
import { maxDate } from "../../../utils/helper";
import {
  ADD_NEW_USER, ADD_UPDATE_ROLE, EDIT_USER_DETAILS, FETCHING_USERS, FETCH_USER_DETAIL,
} from "../gql";
import "./style.scss";

const UserTerminateDetailMock = {
  id: "",
  fullName: "",
  teamName: "string",
};

const AddAndEditUser = ({ ...props }) => {
  const [selectedManager, setSelectManager] = useState<(OptionType | null)[]>(
    []
  );
  const [userDetail, setUserDetail] = useState<IUserTerminateDetail>(
    UserTerminateDetailMock
  );
  const { userRoles: userRolesContext } = useContext(AppContext);
  const [terminateUserModal, setTerminateUserModal] = useState<boolean>(false);
  const [emailSendModal, setEmailSendModal] = useState<boolean>(false);
  const [getUserDetail, { data, loading }] = useLazyQuery(FETCH_USER_DETAIL);
  const { data: users } = useQuery(FETCHING_USERS);
  const [updateUser, { loading: updateUserLoading }] =
    useMutation(EDIT_USER_DETAILS);
  const [createUser, { loading: addUserLoading }] = useMutation(ADD_NEW_USER);
  const [updateUserRole, { loading: roleLoading }] =
    useMutation(ADD_UPDATE_ROLE);
  const { handleSubmit, register, reset, watch, formState: { errors } } = useForm();
  const [userId, setUserId] = useState<string>("");
  const [userRoles, setUserRoles] = useState([]);
  const [userRank, setUserRank] = useState([]);
  const [isSelectToggle, setIsSelectToggle] = useState<boolean>(false);
  const [profileImage, setProfileImage] = useState<any>();
  const [previewURL, setPreviewURL] = useState<any>();
  const [isFeatured, setIsFeature] = useState<boolean>(false);
  const [intersellerCSVPermissions, setIntersellerCSVPermissions] =
    useState<boolean>(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [userManager, setUserManager] = useState("");
  const [minimumExpectation, setMinimumExpectation] = useState("");
  const [earningGoal, setEarningGoal] = useState("");
  const [defaultRank, setDefaultRank] = useState(NaN);
  const [signatureDetail, setSignatureDetail] = useState(emailSignatureType);

  useEffect(() => {
    const userId = props.match.params.id;
    setUserRoles([]);
    if (userId) {
      setUserId(userId);
      getUserDetail({ variables: { userId } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.match?.params?.id]);

  useEffect(() => {
    if (data?.findUserById) {
      let values = { ...data?.findUserById };
      let signature =
        values?.userSignature && JSON.parse(values?.userSignature);
      const manager =
        data?.findUserById?.userManager?.length &&
        data?.findUserById?.userManager[0]?.manager?.email;
      setUserManager(manager);
      setMinimumExpectation(values?.minimumExpectation)
      setEarningGoal(values?.earningGoal)
      setDefaultRank(data?.findUserById?.rank);
      if (data?.findUserById?.dateOfBirth) {
        values.dateOfBirth = data?.findUserById?.dateOfBirth || '';
      }
      reset({ ...values, ...signature });
      setIsFeature(data?.findUserById?.isFeatured);
      setIntersellerCSVPermissions(
        data?.findUserById?.intersellerCSVPermissions
      );
      let roles = data?.findUserById?.userRoles;
      if (roles && roles.length) {
        roles = roles.map((item: any) => item.role.name);
        setUserRoles(roles);
      }

      // this is used for the set up the user singnature

      setSignatureDetail({
        firstName: values?.firstName,
        lastName: values?.lastName,
        phone: values?.phone,
        email: values?.email,
        linkedinURL: values?.linkedinURL,
        companyUrl: signature
          ? signature?.companyUrl
          : "http://www.realrepp.com",
        fbUrl: signature ? signature?.fbUrl : "",
        instaUrl: signature ? signature?.instaUrl : "",
        twitterUrl: signature ? signature?.twitterUrl : "",
        youtubeUrl: signature ? signature?.youtubeUrl : "",
        mobile: values?.mobilePhone || "",
        jobTitle: values?.jobTitle || "",
      });
    }

    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    if (users?.allUsers) {
      const _selectMager: any = users.allUsers.map((user: USERTYPE) => ({
        label: user.fullName,
        value: user.email,
      }));
      const managerList =
        _selectMager &&
        _selectMager.filter(
          (v: OptionType) => v.value !== data?.findUserById?.email
        );
      setSelectManager([...managerList]);
      //create rank list for dropdown manue
      let usersRank = users.allUsers.map((a: any, index: number) => ({
        label: `${index + 1}`,
        value: `${index + 1}`,
      }));
      setUserRank(usersRank);
    }

    // eslint-disable-next-line
  }, [users?.allUsers, data?.findUserById]);

  const editOrAddUser = async (values: USERTYPE) => {
    try {
      values.id = userId;
      values.rank = Number(defaultRank);
      values.manager = userManager;
      // values.minimumExpectation = minimumExpectation
      values.isFeatured = isFeatured;
      values.intersellerCSVPermissions = intersellerCSVPermissions;
      let signatureInput = {
        companyUrl: values?.companyUrl,
        fbUrl: values?.fbUrl,
        instaUrl: values?.instaUrl,
        twitterUrl: values?.twitterUrl,
        youtubeUrl: values?.youtubeUrl,
      };
      values.userSignature = JSON.stringify(signatureInput);
      if (profileImage) {
        setUploadLoading(true);
        let response = await uploadImage(
          profileImage,
          bucketFolders.user,
          false,
          false,
          true
        );
        setUploadLoading(false);
        if (!response.success && !response?.ok) {
          toast.error(response?.message || "Error while uploading image");
          setProfileImage(null);
          setPreviewURL(null);
          return;
        }
        if (response.success) {
          Object.assign(values, { photo: response.source });
        }
      }
      if (userId) {
        const {
          confirmPassword,
          youtubeUrl,
          instaUrl,
          fbUrl,
          twitterUrl,
          companyUrl,

          ...result
        } = values;
        //this check is used for password update if its value is exist
        if (!result.password) {
          delete result.password;
        }
        const responseUpdateUser = await updateUser({
          variables: {
            ...result, intersellerUserId: result.email, intersellerCSVPermissions: false,
            minimumExpectation, earningGoal
          }
        });
        if (responseUpdateUser?.errors) {
          toast.error(responseUpdateUser?.errors[0]?.message);
        } else {
          toast.success("Updated successfully!");
          getUserDetail({ variables: { userId } });
        }
        return;
      }
      if (!userId) {
        values.role = [...userRoles];
        values.status = values.status ? values.status : "ACTIVE";
        const { confirmPassword, ...result } = values;
        await createUser({ variables: result });
        setUserRoles([]);
        props.history.push("/admin/users");
      }
    } catch (error) {
      setUploadLoading(false);
    }
  };

  const updateAddRoles = async () => {
    try {
      await updateUserRole({ variables: { userId, roles: userRoles } });
      toast.success("Updated successfully!");
    } catch (error) {
      // ;
      console.log(error);
    }
  };

  const terminateToggle = () => {
    setTerminateUserModal(!terminateUserModal);
  };

  const sendTestToggle = () => {
    setEmailSendModal(!emailSendModal);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <GoBack url="/admin/users" />

      <div className="mobileResponsiveFlexAlign justify-content-between primaryHeaderSpacing primaryHeading">
        <h5 className="m-0">{userId ? "Edit User" : "Add User"}</h5>

        {(userRolesContext?.includes(ROLES.EXECUTIVE) ||
          userRolesContext?.includes(ROLES.ADMIN)) && (
            <div className="d-flex align-content-between">
              <button
                id={!data?.findUserById?.outLookToken ? userId : ""}
                type="button"
                onClick={() => {
                  setEmailSendModal(true);
                }}
                disabled={data?.findUserById?.outLookToken ? false : true}
                className="buttonGenericStyle filledButton mr-2"
              >
                Send Test Email
              </button>

              {data?.findUserById?.status === "TERMINATED" ? (
                <Button
                  className="btn-danger"
                  style={{
                    fontSize: 12,
                  }}
                  disabled={true}
                >
                  Terminated
                </Button>
              ) : (
                <button
                  type="button"
                  onClick={() => {
                    setUserDetail({
                      ...userDetail,
                      fullName: data?.findUserById?.fullName,
                      teamName: data?.findUserById?.userTeamName,
                      id: data?.findUserById?.id,
                    });

                    setTerminateUserModal(true);
                  }}
                  className="buttonGenericStyle filledButton"
                >
                  Terminate
                </button>
              )}
            </div>
          )}
      </div>

      <div className="revampContainer mb-2 d-flex justify-content-between">
        <UploadImage
          setPreviewURL={setPreviewURL}
          setImage={setProfileImage}
          src={previewURL || data?.findUserById?.photo || AvatarImage}
        />

        <div className="d-flex justify-content-end mobileResponsiveFlexDevice">
          <div className="d-flex align-items-center mr-4">
            <span className="mr-2 switchLabel">Featured User</span>
            <label className="switch m-0">
              <Input
                type="checkbox"
                name="isFeatured"
                checked={isFeatured}
                onChange={(e) => {
                  setIsFeature(e.target.checked);
                }}
              />
              <span className="slider"></span>
            </label>
          </div>

          {/* <div className="d-flex align-items-center">
            <span className="mr-2 switchLabel">
              Interseller CSV Permissions
            </span>
            <label className="switch m-0">
              <Input
                type="checkbox"
                name="intersellerCSVPermissions"
                checked={intersellerCSVPermissions}
                onChange={(e) => {
                  setIntersellerCSVPermissions(e.target.checked);
                }}
              />
              <span className="slider"></span>
            </label>
          </div> */}
        </div>
      </div>

      <div className="jobOrderRevamp">
        <Form
          onSubmit={handleSubmit(editOrAddUser)}
        >
          <h5>Basics</h5>

          <Row>
            <Col lg="4" md="6" sm="12" xs="12">
              <InputField
                label="First Name"
                isRequired
                inputtype="text"
                placeholder="First Name"
                inputid="firstName"
                inputRef={register({
                  required: {
                    value: true,
                    message: "First name is required!",
                  },
                })}
                inputMethod={(value) => {
                  setSignatureDetail({
                    ...signatureDetail,
                    firstName: value,
                  });
                }}
                errMsg={errors?.firstName?.message}
              />
            </Col>

            <Col lg="4" md="6" sm="12" xs="12">
              <InputField
                label="Last Name"
                isRequired
                inputtype="text"
                placeholder="Last Name"
                inputid="lastName"
                inputRef={register({
                  required: {
                    value: true,
                    message: "Last name is required!",
                  },
                })}
                inputMethod={(value) => {
                  setSignatureDetail({
                    ...signatureDetail,
                    lastName: value,
                  });
                }}
                errMsg={errors?.lastName?.message}
              />
            </Col>

            <Col lg="4" md="6" sm="12" xs="12">
              <InputField
                label="Email"
                isRequired
                inputtype="email"
                placeholder="Email"
                inputid="email"
                inputRef={register({
                  required: {
                    value: true,
                    message: "Email is required!",
                  },
                })}
                inputMethod={(value) => {
                  setSignatureDetail({
                    ...signatureDetail,
                    email: value,
                  });
                }}
                errMsg={errors?.email?.message}
              />
            </Col>

            <Col lg="4" md="6" sm="12" xs="12">
              <InputField
                label="Phone"
                inputtype="text"
                placeholder="Phone"
                isRequired={true}
                inputid="phone"
                inputRef={register({
                  required: {
                    value: true,
                    message: "Phone is required!",
                  },
                })}
                inputMethod={(value) => {
                  setSignatureDetail({
                    ...signatureDetail,
                    phone: value,
                  });
                }}
                errMsg={errors?.phone?.message}
              />
            </Col>

            <Col lg="4" md="6" sm="12" xs="12">
              <InputField
                label="Mobile Phone"
                inputtype="text"
                placeholder="Mobile Phone"
                inputid="mobilePhone"
                inputRef={register}
                inputMethod={(value) => {
                  setSignatureDetail({
                    ...signatureDetail,
                    mobile: value,
                  });
                }}
              />
            </Col>

            <Col lg="4" md="6" sm="12" xs="12">
              <InputField
                label="LinkedIn URL"
                inputtype="text"
                placeholder="LinkedIn URL"
                inputid="linkedinURL"
                inputRef={register({
                  pattern: {
                    value: URL_REGEX,
                    message: "LinkedIn URL is not valid!",
                  },
                })}
                inputMethod={(value) => {
                  setSignatureDetail({
                    ...signatureDetail,
                    linkedinURL: value,
                  });
                }}
                errMsg={errors["linkedinURL"]?.message}
              />
            </Col>

            <Col lg="4" md="6" sm="12" xs="12">
              <InputField
                label="Prefix name"
                isRequired
                disabled={!!props?.match?.params?.id && data?.findUserById?.namePrefix}
                inputtype="text"
                placeholder="Prefix name"
                inputid="namePrefix"
                inputRef={register({
                  required: {
                    value: true,
                    message: "Prefix for name is required!",
                  },
                })}
                errMsg={errors?.namePrefix?.message}
              />
            </Col>
            {props?.location?.pathname.includes('/admin/edit-user') &&
              <Col lg="4" md="6" sm="12" xs="12" className="pb-3 form-input">
                <Label className="text-14 text-blackele">
                  Date of Birth
                </Label>
                <Input
                  type="date"
                  name="dateOfBirth"
                  max={maxDate()}
                  innerRef={register({ required: false })}
                />
              </Col>}

          </Row>

          <h5>Role & Permissions</h5>

          <Row>
            <Col lg="4" md="6" sm="12" xs="12">
              <InputField
                label="Job Title"
                inputtype="text"
                placeholder="Job Title"
                inputid="jobTitle"
                inputRef={register({ required: false })}
                inputMethod={(value) => {
                  setSignatureDetail({
                    ...signatureDetail,
                    jobTitle: value,
                  });
                }}
              />
            </Col>

            <Col lg="4" md="6" sm="12" xs="12">
              <InputField
                isRequired
                label="Status"
                inputtype="select"
                selectItems={SELECT_STATUS}
                inputid="status"
                placeholder="Status"
                inputRef={register({
                  required: {
                    value: true,
                    message: "Status is required"
                  }
                })}
                inputMethod={(value) => {
                  if (value === "TERMINATED") {
                    setUserDetail({
                      ...userDetail,
                      fullName: data?.findUserById?.fullName,
                      teamName: data?.findUserById?.userTeamName,
                      id: data?.findUserById?.id,
                    });

                    setTerminateUserModal(true);
                  }
                }}
                errMsg={errors?.status?.message}
              />
            </Col>

            <Col lg="4" md="6" sm="12" xs="12"
              class="for-position-multiselect"
              onClick={() => setIsSelectToggle(!isSelectToggle)}
            >
              <label>Roles</label>
              <div className="form-group">
                <Input
                  inputtype="input"
                  inputid="role"
                  placeholder="Roles"
                  inputclass="roles"
                  onClick={() => {
                    setIsSelectToggle(!isSelectToggle);
                  }}
                  autoComplete="false"
                  defaultValue={userRoles && userRoles.map((role) => role)}
                />

                <MultiSelect
                  isToggle={isSelectToggle}
                  setUserRoles={setUserRoles}
                  userRoles={userRoles}
                  updateAddRoles={updateAddRoles}
                  userId={userId}
                  loading={roleLoading}
                  setIsSelectToggle={setIsSelectToggle}
                />
              </div>
            </Col>

            <Col lg="4" md="6" sm="12" xs="12">
              <InputField
                label="Rank"
                inputtype="select"
                selectItems={userRank}
                inputid="rank"
                placeholder="Rank"
                inputRef={register({ required: false })}
                inputValue={defaultRank}
                inputMethod={setDefaultRank}
              />
            </Col>

            <Col lg="4" md="6" sm="12" xs="12">
              <InputField
                label="Manager"
                inputtype="select"
                selectItems={selectedManager}
                inputid="manager"
                placeholder="Manager"
                inputRef={register({ required: false })}
                inputValue={userManager}
                inputMethod={setUserManager}
              />
            </Col>


            <Col lg="4" md="6" sm="12" xs="12">
              <InputField
                label="Earning Goal"
                inputtype="select"
                selectItems={earningGoalOptions}
                inputid="earningGoal"
                placeholder="Select Min Expectation"
                inputRef={register({ required: false })}
                inputValue={earningGoal}
                inputMethod={setEarningGoal}
              />
            </Col>

            <Col lg="4" md="6" sm="12" xs="12">
              <InputField
                label="Min Earning Expectation"
                inputtype="select"
                selectItems={earningGoalOptions}
                inputid="minExpectation"
                placeholder="Select Min Expectation"
                inputRef={register({ required: false })}
                inputValue={minimumExpectation}
                inputMethod={setMinimumExpectation}
              />
            </Col>

            {/* <Col lg="4" md="6" sm="12" xs="12">
              <InputField
                label="Interseller User ID"
                inputtype="text"
                placeholder="Interseller User ID"
                inputid="intersellerUserId"
                errMsg={errors?.intersellerUserId?.message}
                inputRef={register({
                  maxLength: {
                    value: 24,
                    message:
                      "Invalid Interseller UserID, ID cant't be more than 24 characters",
                  },
                  minLength: {
                    value: 24,
                    message:
                      "Invalid Interseller UserID, ID cant't be less than 24 characters",
                  },
                })}
              />
            </Col> */}
          </Row>

          <h5>Security</h5>

          <Row>
            <Col lg="4" md="6" sm="12" xs="12">
              <InputField
                label="Password"
                inputtype="password"
                placeholder="Password"
                inputid="password"
                isRequired={userId ? false : true}
                inputRef={register({
                  required: {
                    value: userId ? false : true,
                    message: "Password is required!",
                  },
                  minLength: {
                    value: 6,
                    message: "Password length must be 6 !",
                  },
                })}
                isPassword
                errMsg={errors?.password?.message}
              />
            </Col>

            <Col lg="4" md="6" sm="12" xs="12">
              <InputField
                label="Confirm Password"
                inputtype="password"
                placeholder="Confirm password"
                inputid="confirmPassword"
                inputRef={register({
                  validate: (value) =>
                    value === watch("password") ||
                    "Password or confirm password didn't match!!",
                })}
                isPassword
                errMsg={errors?.confirmPassword?.message}
              />
            </Col>
          </Row>

          <h5>About</h5>

          <Row>
            <Col md="6" sm="12" xs="12">
              <div className="form-group">
                <label>Quote</label>
                <textarea
                  className="form-control"
                  ref={register({ required: false })}
                  name="quote"
                  rows={4}
                  placeholder="It’s about listening to the client and understanding each company has a unique culture then finding that ..."
                ></textarea>
              </div>
            </Col>

            <Col md="6" sm="12" xs="12">
              <div className="form-group">
                <label>Bio</label>
                <textarea
                  className="form-control"
                  name="bio"
                  rows={4}
                  ref={register}
                  placeholder="Kendra founded RealREPP in 2015 and has over 14 years of experience ..."
                ></textarea>
              </div>
            </Col>
          </Row>

          <h5>Email Signature</h5>

          <EmailSignatureInput
            errors={errors}
            register={register}
            setDetail={setSignatureDetail}
            signatureDetail={signatureDetail}
          />

          <EmailSignatureTemp values={signatureDetail} />

          <div className="form-actions">
            <CustomButton
              buttonColor="primary"
              buttonText={`${userId ? "Update User" : "Create User"}`}
              className="big-width"
              loading={addUserLoading || updateUserLoading || uploadLoading}
              disabled={addUserLoading || updateUserLoading || uploadLoading}
            />
          </div>
        </Form>
      </div>

      <UserTerminate
        isOpen={terminateUserModal}
        toggle={terminateToggle}
        {...{ ...userDetail }}
      />

      <TestEmailSignature
        modal={emailSendModal}
        toggle={sendTestToggle}
        userId={data?.findUserById?.id}
      />
    </>
  );
};

export default AddAndEditUser;
