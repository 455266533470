import { Progress } from 'reactstrap';
import { moneyFormatter } from '../../../utils/helper';
import AvatarImage from '../../../assets/images/user-empty.svg';
import '../style.scss';
import { Tooltip } from 'reactstrap';
import { FC, useState } from 'react';
import { RecruiterProps } from '../BillingBoard/BillingBoard';

interface GraphProps {
  recruiter: RecruiterProps;
  index: number;
  typeBoard:string
}
const BillingBoardGraph: FC<GraphProps> = ({
  recruiter: {
    id,
    firstName,
    lastName,
    photo,
    startedBillingCredit,
    totalBillingCredit,
    percentageStartedBilling,
    percentageTotalBilling,
    percentageTempBilling,
    tempBillingCredit,
    percentagetotalBillingCreditForBoard,
    totalBillingCreditForBoard,
    expectedDirectHireAmount,
    percentageExpectedDirectHireAmount,
    tempStartedBillingCredit,
    tempTotalBillingCredit,
    
    invoicedTotalbillingCredit,
    totalBillingAmountfortotalBoard,
    expectedTotalCreditAmount,
    percentageInvoicedTotalbillingCredit,
    percntageExpectedTotalCreditAmount,
    
  },
  index,
  typeBoard
}) => {
  const [activeToolTip, setActiveToolTip] = useState<boolean>(false);

  const [ids, setId] = useState<boolean>(false);
  const isOpenToolTip = (tId) => {
    if (tId === ids) {
      return activeToolTip;
    }
  };
  const toggles = (id) => {
    setId(id);
    setActiveToolTip(!activeToolTip);
  };
  return (
    <div className="leader-board  d-flex align-items-center flex-wrap">
      <div className="d-flex align-items-center">
        <span className="mr-2 dark-theme-text">{index + 1}</span>
        <div className="leader-board-profile">
          <img src={photo || AvatarImage} alt="Avatar" />
        </div>
      </div>
      {typeBoard==="directHireBilling"?(
        <div className="flex-fill pl-2">
          <div className="d-flex  justify-content-between align-items-center">
            <h6 className="text-sm person-name mb-2">
              {firstName} {lastName}
            </h6>
            <p className="text-sm mb-2 font-weight-bold person-credit">
              {moneyFormatter(
                totalBillingCredit
                  ? parseFloat(totalBillingCredit.toFixed(2))
                  : totalBillingCredit
              )}{' '}
            </p>
          </div>
          <div>
            {totalBillingCredit !== startedBillingCredit ? (
              <div>
                <Tooltip
                  placement="top"
                  isOpen={isOpenToolTip(id)}
                  target={`TotalBillingCredit-${id}-Direct`}
                  toggle={(e) => {
                    toggles(id);
                  }}
                  style={{ minWidth: '350px', padding: '10px 10px 0 10px' }}
                >
                  <div>
                    <div className="d-flex align-align-items-center">
                      <p className="progress-box mt-1"></p>
                      <p className="ml-2">
                      Total Direct Hire Billing Credit:
                       {moneyFormatter(totalBillingCredit ? parseFloat(totalBillingCredit?.toFixed(2)):totalBillingCredit)}
                      </p>
                    </div>
  
                    <div className="d-flex align-items-center">
                      <p className="progress-box progress-box2"></p>
                      <p className="ml-2">
                        Invoiced Direct Hire Billing Credit:
                        {moneyFormatter(
                          startedBillingCredit
                            ? parseFloat(startedBillingCredit.toFixed(2))
                            : startedBillingCredit 
                        )}
                      </p>
                    </div>
                  </div>
                </Tooltip>
                <Progress className="progressbar-main" id={`TotalBillingCredit-${id}-Direct`} multi>
                  <Progress
                    bar
                    value={!percentageTotalBilling ? 0 : percentageTotalBilling}
                    className="progressbar-parent"
                  >
                    <div
                      className="progressbar-child"
                      style={{
                        width: `${
                          !percentageStartedBilling ? 0 : percentageStartedBilling
                        }%`,
                        height: '100%',
                      }}
                    ></div>
                  </Progress>
                </Progress>
              </div>
            ) : (
              <div>
                <Tooltip
                  placement="top"
                  isOpen={isOpenToolTip(id)}
                  target={`TotalBillingCredit-${id}-Direct`}
                  toggle={() => {
                    toggles(id);
                  }}
                  style={{ minWidth: '350px', padding: '10px 10px 0 10px' }}
                >
                  <div>
                    <div className="d-flex align-align-items-center">
                      <p className="progress-box mt-1"></p>
                      <p className="ml-2">
                      Total Direct Hire Billing Credit:
                        {moneyFormatter(
                          totalBillingCredit
                            ? parseFloat(totalBillingCredit?.toFixed(2))
                            : totalBillingCredit
                        )}
                      </p>
                    </div>
  
                    <div className="d-flex align-items-center">
                      <p className="progress-box progress-box2"></p>
                      <p className="ml-2">
                        Invoiced Direct Hire Billing Credit:
                        {moneyFormatter(
                          startedBillingCredit
                            ? parseFloat(startedBillingCredit?.toFixed(2))
                            : startedBillingCredit
                        )}
                      </p>
                    </div>
                  </div>
                </Tooltip>
  
                <Progress className="progressbar-main" id={`TotalBillingCredit-${id}-Direct`} multi>
                  <Progress
                    bar
                    className="progressbar-main"
                    value={!percentageTotalBilling ? 0 : percentageTotalBilling}
                    
                  ></Progress>
                </Progress>
              </div>
            )}
          </div>
        </div>
      ): typeBoard==='tempHireBilling'?(
        <div className="flex-fill pl-2">
        <div className="d-flex  justify-content-between align-items-center">
          <h6 className="text-sm person-name mb-2">
            {firstName} {lastName}
          </h6>
          <p className="text-sm mb-2 font-weight-bold person-credit">
            {moneyFormatter(tempTotalBillingCredit ? parseFloat(tempTotalBillingCredit.toFixed(2)) : tempTotalBillingCredit)}{' '}
          </p>
        </div>
        <div>
        <Tooltip
                placement="top"
                isOpen={isOpenToolTip(id)}
                target={`TempBillingCredit-${id}-temp`}
                toggle={() => {
                  toggles(id);
                }}
                style={{ minWidth: '350px', padding: '10px 10px 0 10px' }}
              >
                <div>
                  <div className="d-flex align-align-items-center">
                    <p className="progress-box mt-1"></p>
                    <p className="ml-2">
                      Total Temp Billing Credit:
                      {moneyFormatter(tempTotalBillingCredit ? parseFloat(tempTotalBillingCredit?.toFixed(2)) : tempTotalBillingCredit)}

                    </p>
                  </div>

                  <div className="d-flex align-items-center">
                    <p className="progress-box progress-box2"></p>
                    <p className="ml-2">
                    Invoiced Temp Billing Credit:
                      {moneyFormatter(tempStartedBillingCredit ? parseFloat(tempStartedBillingCredit?.toFixed(2)) : tempStartedBillingCredit)}

                    </p>
                  </div>
                </div>
              </Tooltip>

              <Progress className="progressbar-main"  id={`TempBillingCredit-${id}-temp`} multi>
                <Progress
                  bar
                  className="progressbar-main"
                  value={!percentageTempBilling?0:percentageTempBilling}
                
                ></Progress>
              </Progress>
        </div>
      </div>
      ):typeBoard==='totalHireBilling'?(
        <div className="flex-fill pl-2">
          <div className="d-flex  justify-content-between align-items-center">
            <h6 className="text-sm person-name mb-2">
              {firstName} {lastName}
            </h6>
            <p className="text-sm mb-2 font-weight-bold person-credit">
              {moneyFormatter(
                totalBillingAmountfortotalBoard
                  ? parseFloat(totalBillingAmountfortotalBoard.toFixed(2))
                  : totalBillingAmountfortotalBoard
              )}{' '}
            </p>
          </div>
          <div>
            {invoicedTotalbillingCredit !== totalBillingAmountfortotalBoard ? (
              <div>
                <Tooltip
                  placement="top"
                  isOpen={isOpenToolTip(id)}
                  target={`TotalBillingCredit-${id}-Total`}
                  toggle={(e) => {
                    toggles(id);
                  }}
                  style={{ minWidth: '350px', padding: '10px 10px 0 10px' }}
                >
                  <div>
                    <div className="d-flex align-align-items-center">
                      <p className="progress-box mt-1"></p>
                      <p className="ml-2">
                       Total Billing Credit:
                       {moneyFormatter(
                          totalBillingAmountfortotalBoard
                            ? parseFloat(totalBillingAmountfortotalBoard.toFixed(2))
                            : totalBillingAmountfortotalBoard
                        )}
                      </p>
                    </div>
  
                    <div className="d-flex align-items-center">
                      <p className="progress-box progress-box2"></p>
                      <p className="ml-2">
                         Total Invoiced Billing Credit:
                        {moneyFormatter(
                          invoicedTotalbillingCredit
                            ? parseFloat(invoicedTotalbillingCredit.toFixed(2))
                            : invoicedTotalbillingCredit 
                        )}
                      </p>
                    </div>
                  </div>
                </Tooltip>
                <Progress className="progressbar-main" id={`TotalBillingCredit-${id}-Total`} multi>
                  <Progress
                    bar
                    value={!percentagetotalBillingCreditForBoard ? 0 : percentagetotalBillingCreditForBoard}
                    className="progressbar-parent"
                  >
                    <div
                      className="progressbar-child"
                      style={{
                        width: `${
                          !percentageInvoicedTotalbillingCredit ? 0 : percentageInvoicedTotalbillingCredit
                        }%`,
                        height: '100%',
                      }}
                    ></div>
                  </Progress>
                </Progress>
              </div>
            ) : (
              <div>
                <Tooltip
                  placement="top"
                  isOpen={isOpenToolTip(id)}
                  target={`TotalBillingCredit-${id}-Total`}
                  toggle={() => {
                    toggles(id);
                  }}
                  style={{ minWidth: '350px', padding: '10px 10px 0 10px' }}
                >
                  <div>
                    <div className="d-flex align-align-items-center">
                      <p className="progress-box mt-1"></p>
                      <p className="ml-2">
                       Total Billing Credit:
                       {moneyFormatter(
                          totalBillingAmountfortotalBoard
                            ? parseFloat(totalBillingAmountfortotalBoard.toFixed(2))
                            : totalBillingAmountfortotalBoard
                        )}
                      </p>
                    </div>
  
                    <div className="d-flex align-items-center">
                      <p className="progress-box progress-box2"></p>
                      <p className="ml-2">
                         Total Invoiced Billing Credit:
                        {moneyFormatter(
                          invoicedTotalbillingCredit
                            ? parseFloat(invoicedTotalbillingCredit.toFixed(2))
                            : invoicedTotalbillingCredit 
                        )}
                      </p>
                    </div>
                  </div>
                </Tooltip>
  
                <Progress className="progressbar-main" id={`TotalBillingCredit-${id}-Total`} multi>
                  <Progress
                    bar
                    className="progressbar-main"
                    value={!percentagetotalBillingCreditForBoard ? 0 : percentagetotalBillingCreditForBoard}
                    
                  ></Progress>
                </Progress>
              </div>
            )}
          </div>
        </div>
      ):null}
    </div>
  );
};

export default BillingBoardGraph;
