
const EmailHeader = ({ ccEmail, bccEmail, fromEmail, subjectEmail, toEmail }) => {
  return (
    <div>
      <small className="text-muted d-block">
        <strong>From Contacts:</strong>{" " + fromEmail}
      </small>
      <small className="text-muted d-block">
        <strong>To Contacts:</strong>{" " + toEmail}
      </small>
      <small className="text-muted d-block">
        <strong>Cc Contacts:</strong> {" " + ccEmail}
      </small>
      <small className="text-muted d-block">
        <strong>BCc Contacts:</strong> {" " + bccEmail}
      </small>
      <small className="text-muted d-block">
        <strong>Subject:</strong>{" " + subjectEmail}
      </small>
    </div>
  )
}

export default EmailHeader;