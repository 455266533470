// packages blocks
import {
  Reducer,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Badge, Input } from 'reactstrap';
// components block
import Loader from '../../components/layout/Loader';
import Pagination from '../../components/layout/Pagination';
import InputField from '../../components/layout/InputField';
import NoDataFound from '../../components/layout/NoDataFound';
import TableComponent from '../../components/layout/TableComponent';
import CandidateRecruiterModal from './candidate-components/CandidateRecruiterModal';
// graphql, utils and context block
import {CANDIDATES_LISTING_FOR_CANDIDATE_PAGE } from './gql';
import { AppContext } from '../../context';
import {
  CANDIDATES_ROUTE,
  CANDIDATE_HEADER,
  COLUMN_INTERFACE_TYPES_FOR_CANDIDATE,
  PAGE_LIMIT,
  ROLES,
  UPDATE_CANDIDATE_ROUTE,
  VIEW_CANDIDATE_ROUTE,
} from '../../utils/constant';
import VIEW_ICON from '../../assets/images/view-icon.svg';
import EDIT_ICON from '../../assets/images/edit-icon-menu.svg';
import { getCandidatesParams } from '../../utils/getNoteParams';
import { checkUserFeatureAccess, compactObject, formatDateUS } from '../../utils/commonFn';
import {
  Action,
  ActionType,
  candidateReducer,
  initialState,
  State,
} from '../../reducers/candidateReducer';
import { countryWiseHeaderLocation, pushInHistory } from '../../utils/helper';
import { useForm } from 'react-hook-form';
import { PhoneType } from '../../interfaces';
import { ALL_MODAL_ACCCESS_SETTINGS } from '../moduleAccess-settings/gql';

let time = 0;

const AllCandidates = ({ location, history }: RouteComponentProps) => {
  const { data: settingsData } = useQuery(ALL_MODAL_ACCCESS_SETTINGS);
  const [state, dispatch] = useReducer<Reducer<State, Action>>(
    candidateReducer,
    initialState
  );
  const { register, setValue, control } = useForm();
  const { setContextPrivateCandidate, privateCandidate } =
    useContext(AppContext);
  const fieldNames = [
    'nameSearch',
    'jobTitleSearch',
    'emailSearch',
    'phoneSearch',
    'locationSearch',
    'candidateStatusSearch',
  ];
  const defaultColumnFilters = {
    // emptySearch: '',
    nameSearch: '',
    jobTitleSearch: '',
    emailSearch: '',
    phoneSearch: '',
    locationSearch: '',
    candidateStatusSearch: '',
    // Sort
    sorting: {
      columnName: '',
      columnValue: '',
    },
  };
  const {
    currentPage,
    limit,
    recruiterFolder,
    checkBoxArray,
    filterCheck,
    myCandidatesFlag,
    privateCandidatesFlag,
  } = state;
  const { user, userRoles } = useContext(AppContext);
  const [columnFilters, setColumnFilters] =
    useState<COLUMN_INTERFACE_TYPES_FOR_CANDIDATE>(defaultColumnFilters);
  const [errorMsg, setErrorMsg] = useState({});
  const [isSetTimeOut, setIsSetTime] = useState<boolean>(true);

  useEffect(() => {
    if (localStorage.getItem('privateOption')) {
      if (
        userRoles?.includes(ROLES.EXECUTIVE) ||
        userRoles?.includes(ROLES.ADMIN) ||
        userRoles?.includes(ROLES.HR)
      ) {
        dispatch({
          type: ActionType.SET_PRIVATE_CANDIDATES_FLAG,
          privateCandidatesFlag: true,
        });
      } else {
        dispatch({
          type: ActionType.SET_PRIVATE_CANDIDATES_FLAG,
          privateCandidatesFlag: false,
        });
      }
    }
  }, [userRoles]);

  const changeFiled = (fieldName, value) => {
    if (fieldName?.endsWith('Sort')) {
      const columnName = fieldName.replace('Sort', '');
      setIsSetTime(true);
      setColumnFilters((prevFilters) => {
        const { sorting, ...otherFilters } = prevFilters;
        return {
          ...otherFilters,
          sorting: {
            columnName: columnName,
            columnValue: value ? 'ASC' : 'DESC',
          },
        };
      });
    } else {
      dispatch({ type: ActionType.SET_CURRENT_PAGE, currentPage: 1 });
      dispatch({ type: ActionType.SET_LIMIT, limit: limit });
      setIsSetTime(false);
      setColumnFilters((prevFilters) => ({
        ...prevFilters,
        [fieldName]: value,
      }));
    }
  };

  const [getCandidatesList, { loading, error, data }] =
    useLazyQuery(CANDIDATES_LISTING_FOR_CANDIDATE_PAGE);

  const resetPage = () => {
    dispatch({ type: ActionType.SET_CURRENT_PAGE, currentPage: 1 });
    dispatch({ type: ActionType.SET_LIMIT, limit: 25 });
  };

  const persistPrivateOption = () => {
    if (privateCandidatesFlag) {
      localStorage.removeItem('privateOption');
    } else {
      localStorage.setItem('privateOption', 'selected');
    }
  };

  const setQueryParams = () => {
    let newColumn: COLUMN_INTERFACE_TYPES_FOR_CANDIDATE = Object.assign(
      {},
      columnFilters
    );
    const { sorting, ...rest } = newColumn;

    const criteria = compactObject({
      currentPage,
      filterCheck,
      limit,
      myCandidatesFlag,
      privateCandidatesFlag,
      ...rest,
      ...sorting,
    });

    const urlQueryParams: any = [];
    if (Object.keys(criteria).length) {
      for (const key in criteria) {
        if (
          Object.prototype.hasOwnProperty.call(criteria, key) &&
          criteria[key]
        ) {
          urlQueryParams.push(`${key}=${criteria[key]}`);
        }
      }

      history.push(`${CANDIDATES_ROUTE}?${urlQueryParams.join('&')}`);
    }
  };
  const editPermission = (candidate: { recruiterOwner: { id: string } }) => {
    if (
      userRoles?.includes('ADMIN') ||
      userRoles?.includes('EXECUTIVE') ||
      userRoles?.includes('HR')
    ) {
      return true;
    } else if (user?.id === candidate?.recruiterOwner?.id) {
      return true;
    } else if (candidate?.recruiterOwner === null) {
      return true;
    } else {
      return false;
    }
  };
  const _getCandidatesList = useCallback(async () => {
    if (isSetTimeOut) {
      setQueryParams();
      getCandidatesList({
        variables: {
          limit,
          page: currentPage,
          myCandidates: myCandidatesFlag,
          privateCandidate: privateCandidatesFlag,
          columnFilters: columnFilters,
        },
      });
    } else {
      setQueryParams();
      dispatch({ type: ActionType.SET_LIMIT, limit: limit });
      window.clearTimeout(time);
      time = window.setTimeout(async () => {
        getCandidatesList({
          variables: {
            limit,
            page: currentPage,
            myCandidates: myCandidatesFlag,
            privateCandidate: privateCandidatesFlag,
            columnFilters: columnFilters,
          },
        });
      }, 1000);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentPage,
    myCandidatesFlag,
    limit,
    privateCandidatesFlag,
    columnFilters,
  ]);

  useEffect(() => {
    _getCandidatesList();
    // eslint-disable-next-line
  }, [_getCandidatesList]);

  useEffect(() => {
    document.title = 'Candidates - RealREPP';
    let params: any = getCandidatesParams(location.search);
    dispatch({
      type: ActionType.SET_CURRENT_PAGE,
      currentPage: params?.currentPage ? parseInt(params?.currentPage) : 1,
    });

    dispatch({
      type: ActionType.SET_LIMIT,
      limit: params?.limit ? parseInt(params?.limit) : 10,
    });

    dispatch({ type: ActionType.SET_RECRUITER_FOLDER, recruiterFolder: false });
    dispatch({ type: ActionType.SET_CHECK_BOX_ARRAY, checkBoxArray: [] });

    dispatch({ type: ActionType.SET_FILTER_CHECK, filterCheck: true });
    dispatch({
      type: ActionType.SET_MY_CANDIDATES_FLAG,
      myCandidatesFlag: params?.myCandidatesFlag === 'true',
    });
    dispatch({
      type: ActionType.SET_PRIVATE_CANDIDATES_FLAG,
      privateCandidatesFlag: privateCandidate as boolean,
    });

    if (Object.entries(params?.columnFilters)?.length) {
      if (Object.entries(params?.columnFilters)?.length === 1) {
        setColumnFilters((prevFilters) => ({
          ...prevFilters,
          [Object.keys(params?.columnFilters)[0]]: Object.values(
            params?.columnFilters
          )[0],
        }));
      } else {
        const updatedFilters = { ...columnFilters };
        Object.entries(params?.columnFilters).forEach(([key, value]) => {
          updatedFilters[key] = value;
        });
        setColumnFilters(updatedFilters);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [privateCandidate]);

  const handleCheckBoxes = (value: string) => {
    const currentIndex = checkBoxArray.indexOf(value);
    const newChecked = [...checkBoxArray];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    dispatch({
      type: ActionType.SET_CHECK_BOX_ARRAY,
      checkBoxArray: newChecked,
    });
  };

  const onRecruiterFolderToggle = () =>
    dispatch({
      type: ActionType.SET_RECRUITER_FOLDER,
      recruiterFolder: !recruiterFolder,
    });

  return (
    <div className="admin-tabs team-tabs">
      <div className="primaryHeading primaryHeaderSpacing">
        <h5 className="m-0">Candidates</h5>
      </div>

      <div
        className={`search-filter searchFilterResponsive w-100 ${userRoles?.includes(ROLES.ADMIN)?'justify-content-between':'justify-content-end'} `}
      >     
        <div>
        {
          checkUserFeatureAccess(user?.id,'ADVANCE_CANDIDATE_SEARCH',settingsData) && (
            <button className="btn btn-light-yellow" onClick={() => history.push('/candidate-search')}>
             Advance Search
            </button>
          )
        }
        </div> 

        <div className="mobileResponsiveFlex align-items-start flex-wrap">
          <div>
            {(userRoles?.includes(ROLES.EXECUTIVE) ||
              userRoles?.includes(ROLES.ADMIN) ||
              userRoles?.includes(ROLES.HR)) && (
              <button
                type="button"
                onClick={() => {
                  setQueryParams();
                  dispatch({
                    type: ActionType.SET_PRIVATE_CANDIDATES_FLAG,
                    privateCandidatesFlag: !privateCandidatesFlag,
                  });
                  resetPage();
                  persistPrivateOption();
                  setContextPrivateCandidate(!privateCandidatesFlag);
                }}
                style={{
                  backgroundColor: `${
                    privateCandidatesFlag ? '#f4f3f3' : 'white'
                  }`,
                  color: `${privateCandidatesFlag ? '#eaa827' : '#495057'}`,
                  border: `${'1px solid #ced4da'}`,
                  marginRight: `${'1rem'}`,
                }}
                className="filter-job-order"
              >
                Private Candidates
              </button>
            )}
          </div>

          <div className="mobileResponsiveFlex buttonSpacing mr-3">
            {!!checkBoxArray?.length && (
              <>
                <button
                  type="button"
                  onClick={onRecruiterFolderToggle}
                  className="buttonGenericStyle filledButton mr-3"
                >
                  Add To Recruiter Folder
                </button>
              </>
            )}

            <button
              onClick={() => {
                history.push({
                  pathname: '/create/candidate',
                  state: {
                    backUrl: CANDIDATES_ROUTE,
                    searchParameters: location.search,
                  },
                });

                pushInHistory(CANDIDATES_ROUTE, location.search);
              }}
              type="button"
              className="buttonGenericStyle filledButton"
            >
              Add Candidate
            </button>
          </div>

          <div className="filter-by filterCandidateSelect">
            <button
              style={{
                backgroundColor: `${myCandidatesFlag ? '#f4f3f3' : 'white'}`,
                color: `${myCandidatesFlag ? '#eaa827' : '#495057'}`,
                border: `${'1px solid #ced4da'}`,
              }}
              className="filter-job-order ml-3"
              onClick={() => {
                setQueryParams();
                dispatch({
                  type: ActionType.SET_MY_CANDIDATES_FLAG,
                  myCandidatesFlag: !myCandidatesFlag,
                });
                resetPage();
              }}
              type="button"
              disabled={loading}
            >
              My Candidates
            </button>

            <button
              className="buttonGenericStyle filledButton ml-2 btn-candidate-clear"
              onClick={() => {
                persistPrivateOption();
                setIsSetTime(true);
                dispatch({
                  type: ActionType.SET_MY_CANDIDATES_FLAG,
                  myCandidatesFlag: false,
                });
                dispatch({
                  type: ActionType.SET_PRIVATE_CANDIDATES_FLAG,
                  privateCandidatesFlag: false,
                });
                setErrorMsg({});
                setColumnFilters(defaultColumnFilters);
                if (columnFilters?.sorting.columnName) {
                  setValue(columnFilters?.sorting.columnName, false);
                }
                fieldNames.forEach((fieldName) => {
                  setValue(fieldName, defaultColumnFilters[fieldName]);
                });
              }}
            >
              Clear
            </button>
          </div>
        </div>
      </div>

      <div className="table-responsive mt-3 candidates-list-table table-custom-width candidateTable">
        <TableComponent
          advanceSearching={true}
          tableHeaderAdvSeacrh={CANDIDATE_HEADER}
          handleChange={(filedName, value) => changeFiled(filedName, value)}
          columnFilters={columnFilters}
          register={register}
          setValue={setValue}
          control={control}
          tableName="Candidate"
          setErrorMsg={setErrorMsg}
          errorMsg={errorMsg}
        >
          {loading && (
            <tbody>
              <tr>
                <td colSpan={11}>
                  <Loader loaderClass="sm-height " />
                </td>
              </tr>
            </tbody>
          )}

          <tbody className="placement-table">
            {!loading &&
              data &&
              data?.allCandidates &&
              data?.allCandidates?.candidates?.map(
                (item: any, index: number) => {
                  return (
                    <tr key={index}>
                      <td>
                        <div className="d-flex align-items-start ">
                          <Input
                            bsSize="sm"
                            className="m-0"
                            type="checkbox"
                            onChange={() => handleCheckBoxes(item?.id)}
                          />

                          <div className="ml-3">
                            <div
                              className="route-link"
                              style={{ marginTop: '7.3px' }}
                              onClick={() => {
                                history.push({
                                  pathname: `${VIEW_CANDIDATE_ROUTE}/${item?.id}`,
                                  state: {
                                    backUrl: CANDIDATES_ROUTE,
                                    searchParameters: location.search,
                                  },
                                });

                                pushInHistory(
                                  CANDIDATES_ROUTE,
                                  location.search
                                );
                              }}
                            >
                              {item.fullName}
                              {item.isPrivateCandidate && (
                                <small className="text-success ml-2">
                                  (Private)
                                </small>
                              )}{' '}
                            </div>

                            <div className="subtitleText ">
                              {item?.recruiterOwner?.fullName ?? '--'}
                            </div>
                          </div>
                        </div>
                      </td>

                      <td>
                        <div className="oneLineClampText">
                          {item?.jobTitle ||
                            item?.recentEmployment?.jobTitle ||
                            '--'}
                        </div>

                        <div className="subtitleText">
                          {item?.companyName ||
                            item?.recentEmployment?.companyName ||
                            '--'}
                        </div>
                      </td>
                      <td>
                        {item?.emails?.length
                          ? item?.emails?.map(
                              (email) => email.isPrimary && email.email
                            )
                          : '--'}
                      </td>

                      <td>
                        {item?.phones?.length
                          ? item?.phones?.map((phone: PhoneType) => {
                              return phone?.isPrimary && phone?.phoneNumber;
                            })
                          : '--'}
                      </td>

                      <td>
                        {countryWiseHeaderLocation(
                          item?.countryCode,
                          item?.city,
                          item?.stateCode
                        )}
                      </td>

                      <td className="cand-status-column badge-pading">
                        <Badge
                          color={
                            item.status === 'ACTIVE'
                              ? 'success'
                              : item.status === 'DO_NOT_CONTACT'
                              ? 'danger'
                              : ''
                          }
                          className="text-capitalize"
                        >
                          {item.status ?? '--'}
                        </Badge>
                      </td>

                      <td>
                        {item?.resume?.length
                          ? formatDateUS(item?.resume[0]?.createdAt)
                          : '--'}
                      </td>

                      <td>
                        <Link
                          onClick={() =>
                            pushInHistory(CANDIDATES_ROUTE, location.search)
                          }
                          to={{
                            pathname: `${VIEW_CANDIDATE_ROUTE}/${item?.id}`,
                            state: { searchParameters: location.search },
                          }}
                          className="m-2"
                        >
                          <img src={VIEW_ICON} alt="view" width="21px" />
                        </Link>

                        {editPermission(item) && (
                          <Link
                            onClick={() =>
                              pushInHistory(CANDIDATES_ROUTE, location.search)
                            }
                            to={{
                              pathname: `${UPDATE_CANDIDATE_ROUTE}/${item?.id}`,
                              state: { searchParameters: location.search },
                            }}
                          >
                            <img src={EDIT_ICON} alt="edit" />
                          </Link>
                        )}
                      </td>
                    </tr>
                  );
                }
              )}
          </tbody>

          {!loading && !data?.allCandidates?.candidates?.length && (
            <tbody>
              <tr>
                <td colSpan={11} className="empty-table-td">
                  <NoDataFound text="No Candidate Found!" />
                </td>
              </tr>
            </tbody>
          )}
        </TableComponent>

        {recruiterFolder && checkBoxArray?.length && (
          <CandidateRecruiterModal
            isOpen={recruiterFolder}
            toggle={onRecruiterFolderToggle}
            recordToAdd={checkBoxArray}
            refetch={() => _getCandidatesList()}
            clearRecord={() =>
              dispatch({
                type: ActionType.SET_CHECK_BOX_ARRAY,
                checkBoxArray: [],
              })
            }
          />
        )}
      </div>

      {data?.allCandidates?.candidates?.length ? (
        <div className="users-pagination">
          <Pagination
            onPageChange={(pageClicked: number) => {
              setIsSetTime(true);
              setQueryParams();
              dispatch({
                type: ActionType.SET_CURRENT_PAGE,
                currentPage: pageClicked,
              });
            }}
            pageCount={Math.ceil(data?.allCandidates?.count / limit)}
            currentPage={currentPage}
          />

          <div className="d-flex align-items-center">
            <div className="pagination-display paginationItemsSelect">
              <p className="m-0">
                {(currentPage - 1) * limit + 1} to{' '}
                {Math.min(currentPage * limit, data?.allCandidates?.count)} of{' '}
                {data?.allCandidates?.count}
              </p>

              <div className="divider"></div>
              <label>Display</label>
              <InputField
                label=""
                inputtype="select"
                selectItems={PAGE_LIMIT.filter((item) => item.value !== limit)}
                inputid="limit"
                placeholder={limit.toString()}
                inputMethod={(value: string) => {
                  setIsSetTime(true);
                  setQueryParams();
                  dispatch({
                    type: ActionType.SET_CURRENT_PAGE,
                    currentPage: 1,
                  });
                  dispatch({
                    type: ActionType.SET_LIMIT,
                    limit: parseInt(value),
                  });
                }}
              />
            </div>
          </div>
        </div>
      ) : null}

      {error?.graphQLErrors[0]?.message}
    </div>
  );
};

export default AllCandidates;
