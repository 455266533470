export const realreppDocuments = [
  {
    name: "HR Documents",
    data: [
      {
        name: "Direct Deposit Form",
        url: "https://storage.googleapis.com/ats-realrepp-prod-public/documents/Direct%20Deposit%20Form.pdf",
      },
      {
        name: "Emergency Contact Form",
        url: "https://storage.googleapis.com/ats-realrepp-prod-public/documents/Emergency%20Contact%20Form.pdf",
      },
      {
        name: "RealREPP Employee Handbook",
        url: "https://storage.googleapis.com/ats-realrepp-prod-public/documents/RealREPP%20LLC%202020%20Employee%20Handbook%20-%20220301.pdf",
      },
      {
        name: "RealREPP Handbook Acknowledgment",
        url: "https://storage.googleapis.com/ats-realrepp-prod-public/documents/documents_RealREPP%202020%20Handbook%20Acknowledgment_Jan%202020.pdf",
      },
      {
        name: "RealREPP Holiday Schedule",
        url: "https://storage.googleapis.com/ats-realrepp-prod-public/documents/2023%20RealREPP%20Holiday%2BFlex%20Schedule%20(1).pdf",
      },
      {
        name: "RealREPP Title and Promotion Guidelines",
        url: "https://storage.googleapis.com/ats-realrepp-prod-public/documents/Titles%20and%20Promotions%20Guidelines_Recruiters%20221219.pdf",
      },
      {
        name: "W4 Form",
        url: "https://storage.googleapis.com/ats-realrepp-prod-public/documents/W4%20Form.pdf",
      },
      {
        name: "RealREPP Event Calendar",
        url: "https://storage.googleapis.com/ats-realrepp-prod-public/documents/RealREPP%20Event%20Calendar_2023.pdf",
      },
    ],
  },
  {
    name: "Training Documents",
    data: [
      {
        name: "RealREPP Training Binder",
        url: "https://storage.googleapis.com/ats-realrepp-prod-public/documents/RealREPP%20Training%20Binder.pdf",
      },
      {
        name: "Vonage Voicemail Greeting Guide",
        url: "https://storage.googleapis.com/ats-realrepp-prod-public/documents/Vonage%20Vociemail%20Greeting%20Guide.pdf",
      },
      {
        name: "RealREPP Welcome - Training Expectations",
        url: "https://storage.googleapis.com/ats-realrepp-prod-public/documents/documents_RealREPP%20Welcome%20-%20Training%20Expectations.pdf",
      },
      {
        name: "RealREPP Guidelines for Ownership",
        url: "https://storage.googleapis.com/ats-realrepp-prod-public/documents/220425%20Guidelines%20for%20Ownership%20-%20Candidate_Client.pdf",
      }
    ],
  },
];
