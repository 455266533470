// packages block
import { useEffect, useState, useContext } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import FileDownload from "js-file-download";
import { Badge, Button, Spinner } from "reactstrap";
import { useLazyQuery, useMutation } from "@apollo/client";
// components block
import AddReferenceModal from "./AddReferenceModal";
import Loader from "../../../components/layout/Loader";
import ConfirmPopUp from "../../../components/ConfirmPopUp";
import InputField from "../../../components/layout/InputField";
import Pagination from "../../../components/layout/Pagination";
import NoDataFound from "../../../components/layout/NoDataFound";
import TableComponent from "../../../components/layout/TableComponent";
// utils, constants and graphql block
import { AppContext } from "../../../context";
import { PAGE_LIMIT } from "../../../utils/constant";
import { REFERENCE_HEADER } from "../../../constants";
import { formatDateUS } from "../../../utils/commonFn";
import EDIT_ICON from "../../../assets/images/edit.svg";
import VIEW_ICON from "../../../assets/images/view-icon.svg";
import DOWNLOAD_ICON from "../../../assets/images/download-icon.svg";
import DELETE_ICON from "../../../assets/images/candidate-delete.svg";
import { DELETE_CANDIDATE_REFERENCE, GET_CANDIDATE_REFERENCES } from "./gql";
import ViewReferenceModal from "./ViewReferenceModal";

const DownloadReference = ({ item }: any) => {
  const [loading, setLoading] = useState<boolean>(false);

  const handleDownload = async (item: {
    id: string;
    referenceName: string;
  }) => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL || "http://localhost:3001"
        }/candidate-pipelining-reference/downloadreference/${item?.id}`,
        {
          responseType: "blob",
        }
      );
      FileDownload(response.data, `${item?.referenceName}.pdf`);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("Error while downloading file..");
    }
  };

  return (
    <>
      {loading ? (
        <Spinner size="sm" className="mr-2" style={{ color: "#f6c04a" }} />
      ) : (
        <Button
          className="border-0 bg-transparent color-default px-2"
          size="sm"
          disabled={loading ? true : item.status === "PENDING" ? true : false}
          onClick={() => {
            handleDownload(item);
          }}
        >
          <img
            src={DOWNLOAD_ICON}
            alt="download"
            width="18px"
            className="m-0"
          />
        </Button>
      )}
    </>
  );
};

const ReferenceListing = ({ candidateId, candidate }: any) => {
  const { user, userRoles } = useContext(AppContext);
  const [modal, setModal] = useState(false);
  const [isView, setIsView] = useState(false);

  const page = parseInt("1");
  const [currentPage, setCurrentPage] = useState(page);
  const [limit, setLimit] = useState(3);
  const [deleteReferenceId, setDeleteReferenceId] = useState<string>();
  const [editReferenceId, setEditReferenceId] = useState<string>("");
  const [actionConfirm, setReferenceDeleteConfirm] = useState(false);
  const [editPermissions, setEditPermissions] = useState(true);
  const [referenceData, setReferenceData] = useState({});

  const [deleteReference] = useMutation(DELETE_CANDIDATE_REFERENCE);
  const [getReferences, { data, loading }] = useLazyQuery(
    GET_CANDIDATE_REFERENCES
  );

  useEffect(() => {
    if (candidateId && candidate) {
      getReference(candidateId);
      editPermission(candidate);
    }

    // eslint-disable-next-line
  }, [candidateId, candidate, currentPage, limit]);

  const getReference = (candidateId: string) => {
    getReferences({
      variables: { candidateId, page: currentPage, limit },
    });
  };

  const editPermission = (candidate: {
    shareWith: { id: string };
    recruiterOwner: { id: string };
  }) => {
    if (
      userRoles?.includes("ADMIN") ||
      userRoles?.includes("EXECUTIVE") ||
      candidate?.shareWith?.id === user?.id
    ) {
      setEditPermissions(false);
      return;
    } else if (user?.id === candidate?.recruiterOwner?.id) {
      setEditPermissions(false);
      return;
    } else if (candidate?.recruiterOwner === null) {
      setEditPermissions(false);
      return;
    } else {
      setEditPermissions(true);
      return;
    }
  };

  const viewToggle = () => {
    setIsView(!isView)
    !!editReferenceId && setEditReferenceId("")
  }

  const toggle = () => {
    setModal(!modal)
    !!editReferenceId && setEditReferenceId("")
  }

  const handleView = (item: any) => {
    setReferenceData(item);
    setEditReferenceId(item?.id);
    setIsView(true)
  }

  // const handleView = async (item: { id: string; referenceName: string }) => {
  //   setViewId(item?.id);
  //   setViewLoading(true);
  //   try {
  //     const response = await axios.get(
  //       `${process.env.REACT_APP_API_BASE_URL || "http://localhost:3001"
  //       }/candidate-pipelining-reference/downloadreference/${item?.id}`,
  //       {
  //         responseType: "blob",
  //       }
  //     );

  //     const file = new Blob([response.data], { type: "application/pdf" });
  //     const fileURL = URL.createObjectURL(file);

  //     if (fileURL) {
  //       setViewLoading(false);
  //       setBlobFileUrl(fileURL);
  //       setBlobFileName(`${item?.referenceName}.pdf`);
  //       togglePreview();
  //     }
  //   } catch (error) {
  //     toast.error("Error while previewing file..");
  //   }
  // };

  const confirmAction = async () => {
    const response = await deleteReference({
      variables: { referenceId: deleteReferenceId },
    });
    if (response?.data) {
      toast.success("Successfully deleted!");
      setReferenceDeleteConfirm(false);
      getReferences({
        variables: { candidateId, page: currentPage, limit },
      });
    } else {
      setReferenceDeleteConfirm(false);
    }
  };

  return (
    <div>
      <div className="d-flex align-items-center justify-content-end resumeHeader">
        <button
          type="button"
          className="buttonGenericStyle filledButton"
          onClick={() => {
            setReferenceData({});
            setEditReferenceId("");
            setModal(true);
          }}
          disabled={editPermissions}
        >
          Add
        </button>
      </div>

      <div className="table-responsive tableResponsiveInitial">
        <TableComponent tableHeader={REFERENCE_HEADER}>
          {loading && (
            <tbody>
              <tr>
                <td colSpan={14}>
                  <Loader loaderClass="sm-height " />
                </td>
              </tr>
            </tbody>
          )}

          <tbody>
            {data?.allReferenceList?.references?.map((item) => (
              <tr>
                <td className="rich-text-paragraph">
                  <div className="textTruncateName textTruncateWidthEllipsis">
                    {item.referenceName}
                  </div>
                </td>

                <td>
                  <div className="textTruncateWidth textTruncateWidthEllipsis">
                    {item?.email}
                  </div>
                </td>
                <td>{item?.phoneNumber}</td>
                <td>
                  <Badge
                    color={
                      item.status === "COMPLETED"
                        ? "success"
                        : item.status === "PENDING"
                          ? "warning"
                          : ""
                    }
                    className="text-capitalize"
                  >
                    {item.status ?? "--"}
                  </Badge>
                </td>
                <td>
                  {item?.updatedAt ? formatDateUS(item?.updatedAt) : "--"}
                  {"  "}
                  {item?.updatedAt &&
                    new Date(item.updatedAt).toLocaleTimeString()}{" "}
                </td>

                <td align="left">
                  <div
                    className="d-flex align-items-center justify-content-start"
                    style={{ width: 150 }}
                  >
                    <Button
                      className="border-0 bg-transparent color-default px-2"
                      size="sm"
                      onClick={() => handleView(item)}
                    >
                      <img src={VIEW_ICON} alt="" />
                    </Button>

                    <Button
                      className="border-0 bg-transparent color-default px-2"
                      size="sm"
                      onClick={() => {
                        setReferenceData(item);
                        setEditReferenceId(item?.id);
                        setModal(true);
                      }}
                      disabled={editPermissions}
                    >
                      <img src={EDIT_ICON} alt="edit" width="18px" />
                    </Button>

                    <Button
                      className="border-0 bg-transparent color-default px-2"
                      size="sm"
                      onClick={() => {
                        setReferenceDeleteConfirm(true);
                        setDeleteReferenceId(item?.id);
                      }}
                      disabled={editPermissions}
                    >
                      <img src={DELETE_ICON} alt="delete" />
                    </Button>

                    <DownloadReference item={item} />
                  </div>
                </td>
              </tr>
            ))}

            {!loading && !data?.allReferenceList?.references?.length && (
              <tr>
                <td colSpan={6} className="empty-table-td">
                  <NoDataFound text="No Notes Found!" />
                </td>
              </tr>
            )}
          </tbody>
        </TableComponent>

        {data?.allReferenceList?.references?.length ? (
          <div className="users-pagination">
            <Pagination
              onPageChange={(pageClicked: number) => {
                setCurrentPage(pageClicked);
              }}
              pageCount={Math.ceil(data?.allReferenceList?.count / limit)}
              currentPage={currentPage}
            />

            <div className="d-flex align-items-center">
              <div className="pagination-display paginationItemsSelect">
                <p className="m-0">
                  {(currentPage - 1) * limit + 1} to{" "}
                  {Math.min(currentPage * limit, data?.allReferenceList?.count)}{" "}
                  of {data?.allReferenceList?.count}
                </p>

                <div className="divider"></div>

                <label>Display</label>

                <InputField
                  label=""
                  inputtype="select"
                  selectItems={PAGE_LIMIT}
                  inputid="limit"
                  placeholder={limit.toString()}
                  inputMethod={(value: string) => {
                    setCurrentPage(1);
                    setLimit(parseInt(value));
                  }}
                />
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>

      <ConfirmPopUp
        confirmText="Are you sure you want to delete this Reference from Reference list?"
        isOpen={actionConfirm}
        toggle={setReferenceDeleteConfirm}
        confirmAction={confirmAction}
        modalHeading="Delete Action"
        btnText="Delete"
        btnColor="primary"
        className="revampDialog revampDialogWidth"
      />

      <ViewReferenceModal
        isOpen={isView}
        toggle={viewToggle}
        modalHeader={"View Reference"}
        referenceData={referenceData}
      />

      <AddReferenceModal
        isOpen={modal}
        toggle={toggle}
        candidateId={candidateId}
        refetch={() => getReference(candidateId)}
        referenceId={editReferenceId}
        modalHeader={editReferenceId ? "Update Reference" : "Add Reference"}
        referenceData={referenceData}
      />
    </div>
  );
};

export default ReferenceListing;
