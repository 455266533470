const RecieverInformationClient = ({ billingContactInfo }) => {
  return (
    <div className="jobOrderRevamp">
      <h5>Account Payable</h5>

      <div className="d-flex justify-content-between mb-2 contentGridLabel">
        <label>First Name</label>
        <h6>{billingContactInfo?.firstName}</h6>
      </div>

      <div className="d-flex justify-content-between mb-2 contentGridLabel">
        <label>MI</label>
        <h6>{"GOOGLE"}</h6>
      </div>

      <div className="d-flex justify-content-between mb-2 contentGridLabel">
        <label>Last Name</label>
        <h6>{billingContactInfo?.lastName}</h6>
      </div>

      <div className="d-flex justify-content-between mb-2 contentGridLabel">
        <label>Street Address</label>
        <h6>{billingContactInfo?.address?.streetAddress}</h6>
      </div>

      <div className="d-flex justify-content-between mb-2 contentGridLabel">
        <label>City</label>
        <h6>{billingContactInfo?.address?.city}</h6>
      </div>

      {['US',null,undefined,''].includes(billingContactInfo?.address?.country)? (
          <div className="d-flex justify-content-between mb-2 contentGridLabel">
          <label>State</label>
          <h6>{billingContactInfo?.address?.state}</h6>
        </div>
        ) :(
          <div className="d-flex justify-content-between mb-2 contentGridLabel">
          <label>Country</label>
          <h6>{billingContactInfo?.address?.country}</h6>
        </div>
        )}

      <div className="d-flex justify-content-between mb-2 contentGridLabel">
        <label>Zip Code</label>
        <h6>{billingContactInfo?.address?.zipCode}</h6>
      </div>

      <div className="d-flex justify-content-between mb-2 contentGridLabel">
        <label>Primary Phone</label>
        <h6>{billingContactInfo?.primaryPhone || "--"}</h6>
      </div>

      {/* <div className="d-flex justify-content-between mb-2 contentGridLabel">
        <label>Cell Phone</label>
        <h6>{billingContactInfo?.cellPhone || "--"}</h6>
      </div> */}

      <div className="d-flex justify-content-between contentGridLabel">
        <label>Email Address</label>
        <h6>{billingContactInfo?.email || "--"}</h6>
      </div>
    </div>
  );
};

export default RecieverInformationClient;
