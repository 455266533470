import { Col, Label, Row } from "reactstrap";
import { commaRemover, moneyFormatter } from "../../../../utils/helper";
import "../placement.scss";

const RateInfoCalculations = ({ watch,estimatedLessPayrollBurdenPercenatge=0 }) => {
  return (
    <div className="rate-info-calculations">
      <div className="noteSection light-dark-color">
        <h5>Rate Information & Calculations</h5>
      </div>

      <Row>
        <Col md="6" sm="12" xs="12">
          <div className="d-flex justify-content-between mb-2">
            <div className="labelWidth">
              <Label>Company Bill Rate</Label>
            </div>

            <div className="contentWidth">
              <h6>
                {moneyFormatter(commaRemover(watch("billRate")) || 0)}
                /hour
              </h6>
            </div>
          </div>
        </Col>

        <Col md="6" sm="12" xs="12">
          <div className="d-flex justify-content-between mb-2">
            <div className="labelWidth">
              <Label>Mark Up</Label>
            </div>

            <div className="contentWidth">
              <h6>
                {Number(
                  ((commaRemover(watch("billRate")) -
                    commaRemover(watch("payRate"))) /
                    commaRemover(watch("payRate"))) *
                    100
                ).toFixed(2)}
                %
              </h6>
            </div>
          </div>
        </Col>

        <Col md="6" sm="12" xs="12">
          <div className="d-flex justify-content-between mb-2">
            <div className="labelWidth">
              <Label>Overtime Bill Rate </Label>
            </div>

            <div className="contentWidth">
              <h6>
                {moneyFormatter(commaRemover(watch("overtimeBillRate")) || 0)}
              </h6>
            </div>
          </div>
        </Col>

        <Col md="6" sm="12" xs="12">
          <div className="d-flex justify-content-between mb-2">
            <div className="labelWidth">
              <Label>Less Employee Pay Rate</Label>
            </div>

            <div className="contentWidth">
              <h6>
                ({moneyFormatter(commaRemover(watch("payRate")) || 0)}/hour)
              </h6>
            </div>
          </div>
        </Col>

        <Col md="6" sm="12" xs="12">
          <div className="d-flex justify-content-between">
            <div className="labelWidth">
              <Label className="m-0">Less Payroll Burden </Label>
              <div style={{ lineHeight: "10px" }}>
                <span className="subtitleText">
                  (burden * employee pay rate)
                </span>
              </div>
            </div>

            <div className="d-flex contentWidth">
              <h6>
                ({moneyFormatter(estimatedLessPayrollBurdenPercenatge?(estimatedLessPayrollBurdenPercenatge/100)* commaRemover(watch("payRate")):0.1366 * commaRemover(watch("payRate")) || 0)})
              </h6>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default RateInfoCalculations;
