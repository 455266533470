import { useContext, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import Select from "react-select";
import {
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import CustomButton from "../../../components/layout/CustomButton";
import { GET_JOB_ORDERS_FOR_COMPANY } from "../gql";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { APPLY_TO_JOB, FIND_OR_CREATE_JOBAPPLICANT } from "../../job-order/gql";
import { AppContext } from "../../../context";
import CompanyOwnerSelect from "../../../components/CompanyOwnerSelect";
import { toast } from "react-toastify";
import { customSelectDropDown } from "../../../components/styled/customSelect";
import { CREATE_SUBMISSION } from "../../candidate-submission/gql";

const RecruiterCandidateSubmitModal = ({
  open,
  toggle,
  BackURL,
  candidate,
  applyJob,
}: any) => {
  const history = useHistory();
  const { theme } = useContext(AppContext);

  const [fetchAllJobOrders, { loading: waiting, data }] = useLazyQuery(
    GET_JOB_ORDERS_FOR_COMPANY
  );
  const [selectedCompany, setSelectedCompany] = useState<string>("");
  const [selectedCompanyName, setSelectedCompanyName] = useState<string>();
  const [, setSelectedCompanyOwner] = useState<string>();
  const [selectedJob, setSelectedJob] = useState<string>("");
  const [jobOrderOptions, setJobOrderOptions] = useState<Array<any>>([]);

  const [createJobApplicantSubmitted, { loading }] = useMutation(
    FIND_OR_CREATE_JOBAPPLICANT
  );
  const [submissionCreate, { loading: submitting }] =
    useMutation(CREATE_SUBMISSION);
  const [onApplyMutation] = useMutation(APPLY_TO_JOB);

  useEffect(() => {
    if (selectedCompany) {
      fetchAllJobOrders({
        variables: { companyId: selectedCompany, limit: 50, page: 1 },
      });
    }

    // eslint-disable-next-line
  }, [selectedCompany]);

  const onSubmitHandler = async () => {
    if (applyJob) {
      await onApplyHandler();
    } else {
      let res: any = await createJobApplicantSubmitted({
        variables: { candidateId: candidate, jobOrderId: selectedJob },
      });
      const submission = await submissionCreate({
        variables: {
          createSubmission: {
            applicantId: res?.data?.findOrCreateJobApplicant?.id,
            candidateId: candidate,
            jobOrderId: selectedJob,
          },
        },
      });

      if (res?.data) {
        toggle();
        history.push({
          pathname: `/view-candidate/${candidate}`,
          search:
            "?" +
            new URLSearchParams({
              applicantId: res?.data?.findOrCreateJobApplicant?.id,
              companyId: selectedCompany,
              jobOrderId: selectedJob,
              submissionId: submission?.data?.createSubmission?.submission?.id
            }).toString(),

        });
      }
    }
  };

  const onApplyHandler = async () => {
    let res = await onApplyMutation({
      variables: { candidateId: candidate, jobOrderId: selectedJob },
    });

    if (res?.data?.createSubmissionOnApply?.status === 201) {
      toast.error(
        `Job applicant already exists with status ${res?.data?.createSubmissionOnApply?.jobApplicant?.JobAppStatus}`
      );
    } else {
      toast.success(
        `Job applicant applied and status changed to Reviewed with source Recruiter`
      );
    }
    toggle();
  };

  const createJobOrderOption = () => {
    let jobs = data?.findJobOrdersForCompany?.jobOrders;
    if (jobs?.length) {
      let options = jobs?.map((item) => ({
        label: `${item?.jobTitle} ${['US', null, undefined, ''].includes(item?.companyLocation?.country) ? item?.companyLocation?.city ? `- ${item?.companyLocation?.city + ', ' + item?.companyLocation?.state}` : `- ${item?.companyLocation?.state}` : item?.companyLocation?.city ? `- ${item?.companyLocation?.city + ', ' + item?.companyLocation?.country}` : `- ${item?.companyLocation?.country}`}`,
        value: item?.id,
      }));

      setJobOrderOptions(options);
    } else {
      setJobOrderOptions([{ label: "", value: "" }]);
    }
  };

  useEffect(() => {
    if (data?.findJobOrdersForCompany?.jobOrders) {
      createJobOrderOption();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.findJobOrdersForCompany?.jobOrders, selectedCompany]);

  return (
    <>
      <div>
        <Modal
          isOpen={open}
          toggle={() => toggle()}
          className="revampDialog revampDialogWidth"
        >
          <ModalHeader>
            {applyJob ? "Applying To Job" : "Candidate Submission With"}
          </ModalHeader>
          <ModalBody>
            <Row className="">
              <Col xs="12">
                <CompanyOwnerSelect
                  onChange={(data) => {
                    setSelectedCompany(data?.value?.companyId);
                    setSelectedCompanyName(data?.label);
                    setSelectedCompanyOwner(data?.value?.ownerId);
                  }}
                />
              </Col>

              <Col xs="12" className="pt-4">
                <Select
                  isSearchable
                  isLoading={waiting}
                  placeholder="Select Job"
                  name="jobs"
                  isDisabled={
                    data?.findJobOrdersForCompany?.jobOrders?.length
                      ? false
                      : true
                  }
                  options={jobOrderOptions}
                  className="basic-multi-select mb-0"
                  classNamePrefix="select"
                  styles={{ ...customSelectDropDown(theme) }}
                  onChange={(item: any) => {
                    setSelectedJob(item?.value);
                  }}
                />

                {!waiting && (
                  <>
                    {!data?.findJobOrdersForCompany?.jobOrders?.length &&
                      selectedCompany ? (
                      <span
                        className="text-danger"
                        style={{ fontSize: 12, marginTop: 0 }}
                      >
                        No active job order found for {selectedCompanyName}
                      </span>
                    ) : null}
                  </>
                )}
              </Col>
            </Row>
          </ModalBody>

          <ModalFooter>
            <button type="button" onClick={toggle} className="buttonPrimary">
              Close
            </button>

            <CustomButton
              disabled={!(selectedJob && selectedCompany)}
              buttonText={`${applyJob ? "Apply" : "Submit"}`}
              buttonColor="primary"
              buttonType="submit"
              className="big-width"
              buttonClick={applyJob ? onApplyHandler : onSubmitHandler}
              loading={loading}
            />
          </ModalFooter>
        </Modal>
      </div>
    </>
  );
};

export default RecruiterCandidateSubmitModal;
