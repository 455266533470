import { useMutation } from "@apollo/client";
import React, { useState } from "react";
import ConfirmPopUp from "../../../components/ConfirmPopUp";
import { DELETE_EMPLOYMENT_HISTORY } from "../gql";
import "../candidate.scss";
import { toast } from "react-toastify";

import DELETE_ICON from "../../../assets/images/delete-icon-menu.svg";

const DeleteEmpHistory = ({ id, updateUI }: any) => {
  const [deleteEmploymentHistory, { loading }] = useMutation(DELETE_EMPLOYMENT_HISTORY);
  const [confirmDelPopup, setConfirmDelPopup] = useState(false);
  const deleteEmpHistory = async () => {
    let res = await deleteEmploymentHistory({ variables: { id: id } });
    if (res?.data) {
      setConfirmDelPopup(!confirmDelPopup);
      updateUI();
      toast.success("Successfully Deleted!");
    }
  };
  return (
    <div>
      <button
        className="iconButtonAction"
        onClick={() => setConfirmDelPopup(!confirmDelPopup)}
      >
        <img src={DELETE_ICON} alt="delete" />
      </button>

      <ConfirmPopUp
        confirmText="Are you sure, you want to delete employment history?"
        isOpen={confirmDelPopup}
        toggle={setConfirmDelPopup}
        loading={loading}
        disabled={loading}
        confirmAction={deleteEmpHistory}
        modalHeading="Delete Employment History"
        btnText="Delete"
        btnColor="primary"
        className="revampDialog"
      />
    </div>
  );
};

export default DeleteEmpHistory;
