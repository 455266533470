
export const customSelectDropDown = (theme) => ({
  control: (styles, provided) => ({
    ...styles,
    background: theme === 'light' ? provided.background : '#282828',
    borderColor: theme === 'light' ? '#ced4da' : '#525252',
    color: theme === 'light' ? provided.background : '#d9d9d9',
    fontSize: 12,
    // minHeight : 50,
    '&:hover': {
      borderColor: theme === 'light' ? '#ced4da' : '#525252',
      color: theme === 'light' ? provided.background : '#d9d9d9',
    },
  }),
  menu: (provided) => ({
    ...provided,
    background: theme === 'light' ? provided.background : '#282828',
    color: theme === 'light' ? provided.background : '#d9d9d9',
  }),
  option: (provided) => ({
    ...provided,
    fontSize: 12,
    background: theme === 'light' ? provided.background : '#363636',
    '&:hover': {
      background: theme === 'light' ? provided.background : '#474444',
      color: theme === 'light' ? provided.background : '#d9d9d9',
    },
  }),
  container: (provided, state) => ({
    ...provided,
    fontSize: 12,
    innerHeight: 40,
  }),
});

export const customSelectDropDownForEmail = (theme) => ({
  control: (styles, provided) => ({
    ...styles,
    background: theme === "light" ? provided.background : "#282828",
    borderColor: theme === "light" ? "#ffffff" : "#ffffff",
    color: theme === "light" ? provided.background : "#d9d9d9",
    fontSize: 12,
    "&:hover": {
      borderColor: theme === "light" ? "#ffffff" : "#ffffff",
      color: theme === "light" ? provided.background : "#d9d9d9",
    },
    boxShadow: provided.isFocused && `${theme === "light" ? "#ffffff" : "#ffffff"}`
  }),
  menu: (provided) => ({
    ...provided,
    width: "109%"
  }),
  option: (provided) => ({
    ...provided,
    fontSize: 12,
    background: theme === "light" ? provided.background : "#363636",
    "&:hover": {
      background: theme === "light" ? provided.background : "#474444",
      color: theme === "light" ? provided.background : "#d9d9d9",
    },
  }),
  container: (provided, state) => ({
    ...provided,
    fontSize: 12,
    width: "100%",
  }),
  placeholder: (provided) => ({
    ...provided,
    display: 'none'
  }),
  // indicatorSeparator: () => ({
  //   display: 'none'
  // }),
  indicatorsContainer: () => ({
    display: 'none'
  }),
  // multiValue: (styles, { data }) => ({
  //   ...styles,
  //   backgroundColor: data.color === 'include' ? '#e0ffe0' : '#ffe0e0',
  // }),
  // multiValueLabel: (styles, { data }) => ({
  //   ...styles,
  //   color: data.color === 'include' ? '#008000' : '#ff0000',
  // }),
  // multiValueRemove: (styles, { data }) => ({
  //   ...styles,
  //   color: data.color === 'include' ? '#008000' : '#ff0000',
  //   "&:hover": {
  //     background: data.color === 'include' ? '#cbf8cb' : '#ffbbbb',
  //     color: data.color === 'include' ? '#008000' : '#ff0000',
  //   },
  // }),
});

export const customSelectStyleNew = (theme) => ({
  control: (styles, provided) => ({
    ...styles,
    background: theme === "light" ? provided.background : "#282828",
    borderColor: theme === "light" ? "#ced4da" : "#525252",
    color: theme === "light" ? provided.background : "#d9d9d9",
    fontSize: 12,
    padding: 5,
    borderRadius: 7,
    "&:hover": {
      borderColor: theme === "light" ? "#ced4da" : "#525252",
      color: theme === "light" ? provided.background : "#d9d9d9",
    },
  }),
  menu: (provided) => ({
    ...provided,
    background: theme === "light" ? provided.background : "#282828",
    zIndex: 999999,
    color: theme === "light" ? provided.background : "#d9d9d9",
  }),
  option: (provided) => ({
    ...provided,
    fontSize: 12,
    background: theme === "light" ? provided.background : "#363636",
    "&:hover": {
      background: theme === "light" ? provided.background : "#474444",
      color: theme === "light" ? provided.background : "#d9d9d9",
    },
  }),
  container: (provided, state) => ({
    ...provided,
    fontSize: 12,
  }),
});

export const customSelectDropDownMin = (theme, height) => ({
  control: (styles, provided) => ({
    ...styles,
    background: theme === 'light' ? provided.background : '#282828',
    borderColor: theme === 'light' ? '#ced4da' : '#525252',
    color: theme === 'light' ? provided.background : '#d9d9d9',
    fontSize: 12,
    minHeight: height,
    '&:hover': {
      borderColor: theme === 'light' ? '#ced4da' : '#525252',
      color: theme === 'light' ? provided.background : '#d9d9d9',
    },
  }),
  menu: (provided) => ({
    ...provided,
    background: theme === 'light' ? provided.background : '#282828',
    color: theme === 'light' ? provided.background : '#d9d9d9',
  }),
  option: (provided) => ({
    ...provided,
    fontSize: 12,
    background: theme === 'light' ? provided.background : '#363636',
    '&:hover': {
      background: theme === 'light' ? provided.background : '#474444',
      color: theme === 'light' ? provided.background : '#d9d9d9',
    },
  }),
  container: (provided, state) => ({
    ...provided,
    fontSize: 12,
    innerHeight: 40,
  }),
});
