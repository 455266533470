import React, { useContext } from "react";
import { toast } from "react-toastify";
import client from "../apollo";
import { AppContext } from "../context";
import AsyncSelect from "react-select/async";
import { GET_COMPANIES_FOR_OWNER_SHARE_ASSIGN_JOB } from "../Pages/job-order/gql";
let time = 0;
const CompanyOwnerSelect = (props: any) => {
  const { theme } = useContext(AppContext);
  // const [selectValue, setSelectValue] = useState();
  const _filterCompanies = async (inputValue: string) => {
    try {
      const { data } = await client.query({
        query: GET_COMPANIES_FOR_OWNER_SHARE_ASSIGN_JOB,
        variables: {
          limit: 50,
          page: 1,
          searchText: inputValue,
        },
      });
      return data && data.getShareOwnerAndJobOrderAssign
        ? data?.getShareOwnerAndJobOrderAssign?.map((item) => ({
            value: { companyId: item.id, ownerId: item?.owner?.id },
            label: item?.name,
          }))
        : [];
    } catch (error) {
      toast.error("Error while loading companies!");
    }
  };

  const promiseOptions = (value: any): any =>
    new Promise((resolve) => {
      if (!value) {
        resolve(_filterCompanies(value));
      } else window.clearTimeout(time);
      time = window.setTimeout(() => {
        resolve(_filterCompanies(value));
      }, 1000);
    });

  const customStyles = {
    control: (styles, provided) => ({
      ...styles,
      width: props.width,
      background: theme === "light" ? provided.background : "#282828",
      borderColor: theme === "light" ? "#ced4da" : "#282828",
      color: theme === "light" ? provided.background : "#d9d9d9",
      fontSize: 12,
      "&:hover": {
        borderColor: theme === "light" ? "#ced4da" : "#282828",
        color: theme === "light" ? provided.background : "#d9d9d9",
      },
    }),
    menu: (provided) => ({
      ...provided,
      background: theme === "light" ? provided.background : "#282828",
      zIndex: props?.zIndex || 999999,
      color: theme === "light" ? provided.background : "#d9d9d9",
    }),
    option: (provided) => ({
      ...provided,
      fontSize: 12,
      background: theme === "light" ? provided.background : "#363636",
      "&:hover": {
        background: theme === "light" ? provided.background : "#474444",
        color: theme === "light" ? provided.background : "#d9d9d9",
      },
    }),
    container: (provided, state) => ({
      ...provided,
      fontSize: 12,
    }),
  };

  return (
    <>
      <AsyncSelect
        cacheOptions
        isDisabled={props?.isDisabled || false}
        value={props?.value}
        isSearchable
        isClearable
        {...(props?.selectedCompany?.value && {
          value: {
            value: props.selectedCompany.value,
            label: props.selectedCompany.label,
          },
        })}
        ref={props.ref}
        styles={{ ...customStyles }}
        defaultOptions
        onChange={(selected: any) => {
          props?.onChange(selected);
        }}
        loadOptions={promiseOptions}
        placeholder={props?.placeholder}
      />
    </>
  );
};

export default CompanyOwnerSelect;
