import { Col, Form, Input, Label, Row } from 'reactstrap';
import Select from 'react-select';
import { Controller, useForm } from 'react-hook-form';
import {
  interviewRound,
  interviewType,
  reactQuillReg,
  timeZoneArray,
} from '../../../utils/constant';
import { useLazyQuery, useMutation } from '@apollo/client';
import InputField from '../../../components/layout/InputField';
import { GET_INTERVIEW_DETAIL, UPDATE_INTERVIEW } from './gql';
import { toast } from 'react-toastify';
import { ICreateInterview, IUpdateInterviewDTO } from './interview-interfaces';
import { useContext, useEffect } from 'react';
import moment from 'moment-timezone';
import ReactQuill from 'react-quill';

import { AppContext } from '../../../context';
import {
  customSelectDropDown,
  customSelectDropDownMin,
} from '../../../components/styled/customSelect';
import {
  Sidedrawer,
  DrawerHeader,
} from '../../../components/layout/Sidedrawer';
import CustomButton from '../../../components/layout/CustomButton';

export interface ISingleCandidateData {
  name: string;
  jobLocation: string;
  company: string;
  jobTitle: string;
  jobTimeZone: string;
  jobTimeZoneId: string;
}

export default function EditInterviewScheduleModal({
  interviewId,
  editInterviewScheduleModal,
  applicantData,
  refetch,
  parentToggle,
  editInterviewScheduleModalToggle,
  reFetchInterviewEmail,
}) {
  const { theme } = useContext(AppContext);
  const { register, errors, control, handleSubmit, reset } = useForm();
  const [_updateInterview, { loading }] = useMutation(UPDATE_INTERVIEW);
  const [fetchInterviewDetail, { data: detailsForInterview }] =
    useLazyQuery(GET_INTERVIEW_DETAIL);



  useEffect(() => {
    if (interviewId) {
      fetchInterviewDetail({
        variables: { id: interviewId },
      });
    }

    // eslint-disable-next-line
  }, [interviewId]);

  useEffect(() => {
    if (detailsForInterview?.interviewDetail) {
      const prefilledResult = prefillingData(
        detailsForInterview?.interviewDetail
      );
      if (prefilledResult) {
        reset({ ...prefilledResult });
      }
    }

    // eslint-disable-next-line
  }, [detailsForInterview?.interviewDetail]);

  const onInterviewClickHandler = async (values: ICreateInterview) => {
    let inputDate = moment(values?.interviewDateTime).format(
      `YYYY-MM-DDTHH:mm:ss${values?.timeZone?.value}`
    );

    values.interviewDateTime = inputDate;

    const obj: IUpdateInterviewDTO = {
      id: interviewId,
      interviewType: values?.interviewType?.value,
      jobApplicantId: applicantData?.id,
      interviewDateTime: values.interviewDateTime,
      details: values?.details || '',
      location: values?.location || '',
      timeZoneId: values.timeZone?.value,
      timeZone: values.timeZone?.label,
      interviewRoundIndex: values?.interviewRoundIndex?.value,
    };

    const interviewCreateResponse = await _updateInterview({
      variables: {
        updateInterviewDTO: obj,
      },
    });

    if (interviewCreateResponse?.data) {
      refetch();
      fetchInterviewDetail({
        variables: { id: interviewId },
      });

      // fetching the updated invites for that interview
      reFetchInterviewEmail();
      toast.success('Interview  updated successfully');
      editInterviewScheduleModalToggle();
      parentToggle();
    }
  };

  const prefillingData = (data: any) => {
    if (data) {
      let values = { ...data };
      values.interviewType = values.interviewType
        ? {
          label: values.interviewType,
          value: values.interviewType,
        }
        : null;

      values.interviewDateTime = moment(values.interviewDateTime)
        .utcOffset(values?.timeZoneId)
        .format('YYYY-MM-DDTHH:mm');

      const rounds = interviewRound.find(
        (item) => item.value === values?.interviewRoundIndex
      );

      values.interviewRoundIndex = rounds;

      values.timeZone = {
        label: values?.timeZone,
        value: values?.timeZoneId,
      };
      return values;
    }
  };

  const jobOrderCompanyLocation = (location) => {
    return location?  ['US',null,undefined,''].includes(location?.country)?`${location.streetAddress || '--'}, ${location?.city || '--' }, ${location?.state || '--'}, ${location?.zipCode || '--'}`:`${location.streetAddress || '--'}, ${location?.city || '--' }, ${location?.zipCode || '--'}, ${location?.country || '--'}`:"--"
  };

  return (
<div>
      <Sidedrawer toggle={() => { }} isOpen={editInterviewScheduleModal}>
        <div className="mb-4">
          <DrawerHeader
            prevClick={() => editInterviewScheduleModalToggle()}
            buttonText="Update"
            heading="Interview Update"
            nextClick={handleSubmit(onInterviewClickHandler)}
            loading={loading}
            disabled={loading}
            nextButtonView={false}
          />
        </div>
        <Form>
          <Row>
            <Col lg="12" md="12" sm="12" xs="12">
              <div className="jobOrderRevamp p-0 bg-transparent">
                <div className=" row pb-3">
                  <div className="col-4">
                    <h6 className="text-14 text-blackele">Candidate</h6>
                  </div>
                  <div className="col-8">
                    <h5 className="font-weight-normal text-12 text-blackten">
                      {applicantData?.candidate?.fullName}
                    </h5>
                  </div>
                </div>

                <div className="row pb-3">
                  <div className="col-4">
                    <h6 className="text-14 text-blackele">Company</h6>
                  </div>
                  <div className="col-8">
                    <h5 className="font-weight-normal text-12 text-blackten">
                      {applicantData?.JobOrder?.company?.name}
                    </h5>
                  </div>
                </div>

                <div className="row pb-3">
                  <div className="col-4">
                    <h6 className="text-14 text-blackele">Job Title</h6>
                  </div>
                  <div className="col-8">
                    <h5 className="font-weight-normal text-12 text-blackten">
                      {applicantData?.JobOrder?.jobTitle}
                    </h5>
                  </div>
                </div>

                <div className="row pb-3">
                  <div className="col-4">
                    <h6 className="text-14 text-blackele">Job Location</h6>
                  </div>
                  <div className="col-8">
                    <h5 className="font-weight-normal text-12 text-blackten">
                      {jobOrderCompanyLocation(
                        applicantData?.JobOrder?.companyLocation
                      )}
                    </h5>
                  </div>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg="6" md="6" sm="12" xs="12">
              <Label className="text-14 text-blackele pb-3">
                Select Int. Type <span className="text-danger">*</span>
              </Label>
              <Controller
                control={control}
                name="interviewType"
                rules={{
                  required: {
                    value: true,
                    message: 'Select Int. Type must be selected!',
                  },
                }}
                options={interviewType}
                as={Select}
                styles={{ ...customSelectDropDownMin(theme, 48) }}
              />
              <small className="text-danger">
                {errors?.interviewType?.message}
              </small>
            </Col>

            <Col lg="6" md="6" sm="12" xs="12">
              <Label className="text-14 text-blackele pb-3">
                Select Int. Round <span className="text-danger">*</span>
              </Label>
              <Controller
                isDisabled={true}
                control={control}
                name="interviewRoundIndex"
                rules={{
                  required: {
                    value: true,
                    message: 'Select Int. Round must be selected!',
                  },
                }}
                options={interviewRound}
                as={Select}
                styles={{ ...customSelectDropDownMin(theme, 48) }}
              />
              <small className="text-danger">
                {errors?.interviewRoundIndex?.message}
              </small>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col lg="6" md="6" sm="12" xs="12">
              <Label className="text-14 text-blackele pb-3">
                Select Timezone <span className="text-danger">*</span>
              </Label>
              <Controller
                control={control}
                name="timeZone"
                rules={{
                  required: {
                    value: true,
                    message: 'Select Timezone must be selected!',
                  },
                }}
                options={timeZoneArray["US"]}
                as={Select}
                styles={{ ...customSelectDropDownMin(theme, 48) }}
              />
              <small className="text-danger">{errors?.timeZone?.message}</small>
            </Col>

            <Col lg="6" md="6" sm="12" xs="12">
              <div className="custom-form-input form-input">
                <Label className="text-14 text-blackele pb-3">
                  Select Date <span className="text-danger">*</span>
                </Label>
                <Input
                  min={new Date().toISOString().substring(0, 19)}
                  type="datetime-local"
                  name="interviewDateTime"
                  innerRef={register({
                    required: {
                      value: true,
                      message: 'Interview Date Time is required!',
                    },
                  })}
                />
              </div>
              <small className="text-danger">
                {errors?.interviewDateTime?.message}
              </small>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col lg="12" md="12" sm="12" xs="12">
              <div className="custom-form-input form-input">
                <Label className="text-14 text-blackele pb-3">Location</Label>
                <InputField
                  inputtype="text"
                  label="Location"
                  inputid="location"
                  placeholder="location"
                  isSidebarField={true}
                  inputRef={register({
                    required: { value: false, message: 'Location is required' },
                  })}
                />
              </div>
            </Col>
            <Col lg="12" className="mt-2">
              <Label className="text-14 text-blackele pb-3">
                Details <span className="text-danger">*</span>
              </Label>

              <Controller
                control={control}
                name="details"
                rules={{
                  required: {
                    value: true,
                    message: 'Add some detail content!',
                  },
                }}
                render={({ onChange, ref, value }) => (
                  <div className="editor-height w-100">
                    <ReactQuill
                      onChange={(value) => {
                        if (reactQuillReg.test(value)) {
                          onChange(null);
                        } else {
                          onChange(value);
                        }
                      }}
                      ref={ref}
                      value={value || ''}
                    />
                    <small className="text-danger pt-2">
                      {errors?.details?.message}
                    </small>
                  </div>
                )}
                styles={{ ...customSelectDropDown(theme) }}
              />
            </Col>
          </Row>
          <div className="d-flex justify-content-end align-items-center mt-3">
            <CustomButton
              loading={loading}
              buttonText="Update Interview"
              disabled={loading}
              buttonClick={handleSubmit(onInterviewClickHandler)}
              buttonType="submit"
            />
          </div>
        </Form>
      </Sidedrawer>
    </div>

   
    
  );
}
