import { gql } from "@apollo/client";

export const ALL_CHATGPT_TEMPLATES = gql`
  query AllChatGptTemplates {
    AllChatGptTemplates {
      chatGPTtemplates {
        id
        updatedAt
        createdAt
        template
        module
        originalTemplate
      }
      count
    }
  }
`;

export const SAVE_CHATGPT_TEMPLATES = gql`
  mutation SaveChatgptTemplates($settingDetails: settingDetailsForChatGPTInput!) {
    SaveChatgptTemplates( settingDetails: $settingDetails) {
      message
      status
    }
  }
`;