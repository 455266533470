import { Col, Row } from "reactstrap";
import { moneyFormatter } from "../../../../utils/helper";
import "./new-assignment.scss";

const RateInformationCalculator = ({ rateInfo }) => {
  return (
    <div className="jobOrderRevamp">
      <h5>{"Rate Information & Calculations"}</h5>

      <Row>
        <Col md="6" sm="12" xs="12">
          <div className="d-flex justify-content-between mb-2 contentGridLabel">
            <label>Company Bill Rate</label>
            <h6>{moneyFormatter(rateInfo?.billRate || 0)}/hour</h6>
          </div>

          <div className="d-flex justify-content-between mb-2 contentGridLabel">
            <label>Overtime Bill Rate </label>
            <h6>{moneyFormatter(rateInfo?.overtimeBillRate || 0)}/hour</h6>
          </div>

          <div className="d-flex justify-content-between mb-2 contentGridLabel">
            <label>Mark Up</label>
            <h6>{rateInfo?.markupPercentage?.toFixed(2) || "--"}%</h6>
          </div>
        </Col>

        <Col md="6" sm="12" xs="12">
          <div className="d-flex justify-content-between mb-2 contentGridLabel">
            <label>Less Employee Pay Rate</label>
            <h6>({moneyFormatter(rateInfo?.payRate || 0)}/hour)</h6>
          </div>

          <div className="d-flex justify-content-between mb-2 contentGridLabel">
            <label>
              Less Payroll Burden <br />
              <span className="link-text-btn">
                (burden * less employee pay rate)
              </span>
            </label>

            <h6>
              ({moneyFormatter(rateInfo?.estimatedLessPayrollBurden || 0)})
            </h6>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default RateInformationCalculator;
