import { useEffect, useRef, useState } from 'react';
import { MessageIcon } from '../../assets/images/svgs';
import { DrawerHeader, Sidedrawer } from '../../components/layout/Sidedrawer';
import ChatItem from './ChatItem';

const ResumeDrawer = ({ toggle, isOpen, submitChat, messages, loading, chunkMessage, promptMessage, promptMessageForDisplay, promptMessageForVerification,heading,clearChat }) => {
  const [message, setMessage] = useState(localStorage.getItem(`savedMessage-${heading}`) || '');
  const [isLoading, setIsLoading] = useState(false);
  const chatContainerRef = useRef<HTMLDivElement | null>(null);

  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    const textareaElement = textareaRef.current;

    const resizeTextarea = () => {
      if (textareaElement) {
        textareaElement.style.height = 'auto';
        textareaElement.style.height = `${textareaElement.scrollHeight}px`;
      }
    };

    if (textareaElement) {
      textareaElement.addEventListener('input', resizeTextarea);
    }

    return () => {
      if (textareaElement) {
        textareaElement.removeEventListener('input', resizeTextarea);
      }
    };
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    await submitChat(message);
    localStorage.removeItem(`savedMessage-${heading}`);
    setMessage("");
    setIsLoading(false);
  };

  useEffect(() => {
    if (promptMessage) {
      submitChat(promptMessage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [messages]);

  useEffect(() => {
    if(message){
      localStorage.setItem(`savedMessage-${heading}`, message);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);


  return (
    <Sidedrawer toggle={toggle} isOpen={isOpen}>
      <div className='chatgpt-div'>
        <div className="mb-4">
          <DrawerHeader prevClick={() => toggle()} nextClick={() => clearChat()} buttonText="Clear Chat"
            heading={heading}
            nextButtonView={true}
          >
          </DrawerHeader>
        </div>

        <div className="pb-1 chatgpt-container" ref={chatContainerRef}>
          {messages?.map((message, index) => (
            <ChatItem key={message?.id} loading={loading && messages.length - 1 === index} message={message} id={message?.id} promptMessageForDisplay={promptMessageForDisplay} promptMessageForVerification={promptMessageForVerification} heading={heading}/>
          ))}

          {chunkMessage && (
            <ChatItem
              key={"chunk" + chunkMessage?.id}
              message={chunkMessage}
              loading={loading}
              id={"chunk" + chunkMessage?.id}
              promptMessageForDisplay={promptMessageForDisplay}
              promptMessageForVerification={promptMessageForVerification}
              heading={heading}
            />
          )}
        </div>

        <div className="chatgpt-prompt">
          <form onSubmit={handleSubmit}>
            <div className="chatgpt-input-body">
              <textarea
                name="text"
                value={message}
                placeholder="Send a message"
                onChange={(event) => setMessage(event.target.value)}
                disabled={isLoading}
                ref={textareaRef}
                className="chat-textarea"
              />

              <button type="submit" className="button">
                <MessageIcon />
              </button>
            </div>
          </form>
        </div>
      </div>
    </Sidedrawer>
  )
}

export default ResumeDrawer;
