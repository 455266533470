import { useLazyQuery, useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import {
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import InputField from "../../../../components/layout/InputField";
import { VIEW_PLACEMENT_MANAGEMENT_ROUTE } from "../../../../utils/constant";
import { ENDED_PLACEMENT, } from "../gql";
import { GET_PLACEMENT_BY_ID } from "../../../AccountingDashboard/gql";
import CustomButton from "../../../../components/layout/CustomButton";


const MoldalForDirectHire = ({
  isCancellationRequest = false,
  endDateModal,
  setEndDateModal,
  placementId,
  refetch,
  AccountingCancel,
  history,
}) => {

  const { register, errors, handleSubmit } = useForm();
  const [endedPlacement, { loading: endingPlacementLoading }] = useMutation(ENDED_PLACEMENT);

  // ATS-1010: requests module - placement cancellation: fetching request note for defaultValue
  const [getPlacementById, { data: placementData, loading }] = useLazyQuery(GET_PLACEMENT_BY_ID);
  const [cancelReason, setCancelReason] = useState<string>('');

  useEffect(() => {
    if (placementId) {
      getPlacementById({
        variables: {
          id: placementId?.id,
        },
      });
    }
  }, [getPlacementById, placementId]);

  useEffect(() => {
    if (!loading && placementData) {
      setCancelReason(placementData?.getPlacementById?.statusNote)
    }
  }, [placementData, loading])

  const reload = () => {
    setTimeout(() => {
      return window.location.reload();
    }, 1000);
  };

  const handleSave = async (value) => {
    const finalValues = {
      id: placementId?.id ? placementId.id : placementId,
      placementEndDate: value.placementEndDate,
      placementStartDate: value?.placementStartDate,
      AccountingCancel: AccountingCancel,
      cancelReason: value?.cancelReason,
      jobTitle: value?.placementJobTitle
    };
    const placementUpdateSaving = await endedPlacement({
      variables: {
        updatePlacementDTO: finalValues,
      },
    });
    if (placementUpdateSaving?.data?.endedPlacement?.status === 200) {
      !AccountingCancel
        ? toast.success("Placement ended successfully.")
        : toast.success("Placement cancelled successfully.")
      setEndDateModal(false);
      !AccountingCancel
        ? history.push({
          pathname: `${VIEW_PLACEMENT_MANAGEMENT_ROUTE}/${placementUpdateSaving?.data?.endedPlacement?.newPlacementID}`,
        })
        : refetch();
    } else if (placementUpdateSaving?.data?.endedPlacement?.status === 202) {
      setEndDateModal(false);
      toast.success("Placement cancellation request generated.");
      reload()
      refetch();
    } else if (placementUpdateSaving?.data?.endedPlacement?.status === 206) {
      setEndDateModal(false);
      toast.success("Placement cancelled successfully.");
      toast.success("Request status changed to approved.");
      refetch();
    } else {
      setEndDateModal(false);
      !AccountingCancel
        ? toast.warn("Placement did not end.")
        : toast.warn("Placement not cancelled.");
    }
  };

  return (
    <Modal
      isOpen={endDateModal}
      toggle={() => setEndDateModal(!endDateModal)}
      className="revampDialog"
    >
      <ModalHeader>
        {AccountingCancel && !isCancellationRequest ? (
          <div className="d-flex align-items-center">
            Are You Sure To Cancel This Placement?
          </div>
        ) : (AccountingCancel && isCancellationRequest) ? (
          <div className="d-flex align-items-center">
            Generating Cancellation Request
          </div>
        ) : (
          <div className="d-flex align-items-center">Enter dates</div>
        )}
      </ModalHeader>

      <ModalBody>
        {!AccountingCancel && (
          <Form>
            <InputField
              inputtype="text"
              label="Job Title"
              name="placementJobTitle"
              inputid="placementJobTitle"
              isRequired
              placeholder="Job Title"
              defaultValue={placementId?.jobTitle}
              inputRef={register({
                required: {
                  value: true,
                  message: "Job Title is required!",
                },
              })}
            />
            <div className="d-flex flex-column">
              <small className="text-danger">
                {errors?.placementJobTitle?.message}
              </small>
            </div>

            <InputField
              inputtype="date"
              label="Temp Assignment End Date"
              name="placementEndDate"
              inputid="placementEndDate"
              isRequired
              placeholder="Temp Assignment End Date"
              inputRef={register({
                required: {
                  value: true,
                  message: "Temp Assignment End Date is required!",
                },
              })}
            />
            <div className="d-flex flex-column">
              <small className="text-danger">
                {errors?.placementEndDate?.message}
              </small>
            </div>

            <InputField
              inputtype="date"
              label="DirectHire Start Date"
              name="placementStartDate"
              inputid="placementStartDate"
              isRequired
              placeholder="DirectHire Start Date"
              inputRef={register({
                required: {
                  value: true,
                  message: "DirectHire Start Date is required!",
                },
              })}
            />
            <div className="d-flex flex-column">
              <small className="text-danger">
                {errors?.placementStartDate?.message}
              </small>
            </div>
          </Form>
        )}

        {AccountingCancel && (
          <div>
            <Label>
              Cancel Reason<span className="text-danger">*</span>
            </Label>
            <Input
              type="textarea"
              rows="5"
              name="cancelReason"
              innerRef={register({
                required: {
                  value: AccountingCancel,
                  message: "Reason is required!",
                },
              })}
              defaultValue={cancelReason} // Set the initial value of the input field
            />
            <small className="text-danger">
              {errors?.cancelReason?.message}
            </small>
          </div>
        )}
      </ModalBody>

      <ModalFooter>
        <button
          type="button"
          onClick={() => setEndDateModal(false)}
          className="buttonPrimary mr-2"
        >
          Cancel
        </button>
        <CustomButton
          buttonClick={handleSubmit(handleSave)}
          loading={endingPlacementLoading}
          disabled={endingPlacementLoading}
          className="buttonGenericStyle filledButton"
          buttonText="Confirm"
        />

      </ModalFooter>
    </Modal>
  );
};

export default MoldalForDirectHire;
