// packages block
import { Fragment } from "react";
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap";
// constants and svgs block
import DeleteIcon from "../assets/images/delete-field.svg";
import { ContactPhoneType } from "../utils/constant";
import "./style.scss";

const AddAbleFields = ({
  index,
  value,
  checked = false,
  handleChange,
  handleRemove,
  recordId = "",
  label,
  inputName,
  isPhone = false,
  phoneType = "",
  isRequired = false,
  isPrimary = false,
  length,
}: {
  handleChange: Function;
  handleRemove: Function;
  index: number;
  value: string;
  checked?: boolean;
  recordId?: string;
  label: string;
  inputName: string;
  isPhone?: boolean;
  phoneType?: string;
  isRequired?: boolean;
  isPrimary?: boolean;
  length?: number;
}) => {
  return (
    <div className="d-flex-field">
      <Row className="d-flex align-items-end">
        <Col xl="4" lg="4" md="6" sm="12" xs="12">
          <FormGroup className="mb-0">
            <Label>
              {label} {isRequired && <span className="text-danger">*</span>}
            </Label>
            <Input
              type="text"
              value={value}
              name={inputName}
              placeholder={label}
              onChange={(e) => {
                handleChange(index, e);
              }}
            />
          </FormGroup>
        </Col>

        {isPhone && (
          <Col xl="4" lg="4" md="6" sm="12" xs="12" className="for-mobile-padding">
            <select
              className="form-control"
              name="phoneType"
              onChange={(e) => {
                handleChange(index, e);
              }}
              value={phoneType}
            >
              <option value="" disabled hidden>
                Phone
              </option>              {ContactPhoneType.map(
                (item: { label: string; value: string }, index: number) => (
                  <Fragment key={index}>
                    <option value={item.value}>{item.label}</option>
                  </Fragment>
                )
              )}
            </select>
          </Col>
        )}

        <Col xl="4" lg="4" md="6" sm="12" xs="12">
          <div className="radio-delete mb-0">
            <Button
              className="bg-transparent border-0"
              onClick={() => {
                handleRemove(index, recordId);
              }}
              disabled={!!isPrimary}
            >
              <img
                src={DeleteIcon}
                alt=""
                width="20"
                height="20"
                className="m-0"
              />
            </Button>

            <div>
              <Label className="radio-container w-100">
                is Primary
                <input
                  type="checkbox"
                  name="isPrimary"
                  onChange={(e) => {
                    handleChange(index, e);
                  }}
                  checked={checked}
                  disabled={checked}
                />
                <span className="checkmark"></span>
              </Label>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default AddAbleFields;
