import { FC, ReactNode, useEffect, useState } from 'react';
import { Button, Input, Table } from 'reactstrap';
import { DownArrowIcon } from '../../../assets/images/svgs';
import { TableComponentType } from '../../../interfaces';
import { compactObject } from '../../../utils/commonFn';
import {
  HEADERS_ITEM_WITHOUT_ARROW,
  HEADERS_ITEM_WITHOUT_INPUT,
  HEADERS_ITEM_WITH_CALENDER,
  HEADERS_ITEM_WITH_NUMERIC_FIELD,
  HEADERS_ITEM_WITH_SELECTED
} from '../../../utils/constant';
import { maxDate, numericValueHandler, placeHolderForStatus, selecionOfInputFieldInHeader } from '../../../utils/helper';
import InputField from '../InputField';
import InputFieldColumn from '../inputFieldForColumnSorting';
import './style.scss';

const TableComponent: FC<TableComponentType> = ({
  tableHeader,
  lastRight,
  advanceSearching = false,
  handleChange = (filedName, value) => { },
  tableHeaderAdvSeacrh,
  columnFilters,
  register,
  setValue,
  control,
  tableName = '',
  textCenter,
  setErrorMsg = () => { },
  errorMsg = {},
  ...props
}) => {
  const [sort, setSort] = useState({});
  useEffect(() => {
    if (columnFilters?.sorting?.columnName) {
      setSort({ [columnFilters?.sorting?.columnName]: columnFilters?.sorting?.columnValue === 'ASC' ? true : false })
    }
    if (!columnFilters?.sorting?.columnName) {
      setSort({ [columnFilters?.sorting?.columnName]: null })
    }
    if (Object.entries(compactObject(columnFilters ? columnFilters : {}))?.length) {
      if (Object.entries(compactObject(columnFilters ? columnFilters : {}))?.length === 1) {
        setValue && setValue(`${Object.keys(compactObject(columnFilters))[0]}`, `${Object.values(compactObject(columnFilters))[0]}`)
      } else {
        Object.entries(compactObject(columnFilters ? columnFilters : {}))?.forEach(([key, value]) => {
          setValue && setValue(key, value);
        });
      }
    }
  }, [columnFilters, setValue])

  return (
    <Table className="table" hover responsive>
      <thead>
        {advanceSearching && tableHeaderAdvSeacrh ? (
          <tr>
            {tableHeaderAdvSeacrh.map((headerItem, index) => (
              <th
                className={
                  tableHeaderAdvSeacrh.length - 1 === index && lastRight
                    ? 'text-right'
                    : 'th-width'
                }
                key={index}
              >
                {!HEADERS_ITEM_WITHOUT_ARROW.includes(
                  Object?.values(headerItem)[0] as string
                ) ? (
                  <div className="sort-btn-div">
                    <Button
                      className="sort-btn d-block"
                      onClick={async () => {
                        setSort((prevSort) => {
                          const newSort = {
                            [Object?.values(headerItem)[0] as string]:
                              !prevSort[
                              Object?.values(headerItem)[0] as string
                              ],
                          };
                          return newSort;
                        });
                        handleChange(
                          (Object?.values(headerItem)[0] as string) + 'Sort',
                          !sort[Object?.values(headerItem)[0] as string]
                        );
                      }}
                    >
                      {placeHolderForStatus(
                        (Object?.values(headerItem)[0] as ReactNode)
                          ?.toString()
                          .toUpperCase(), false
                      )}
                      <span
                        className={
                          sort[Object?.values(headerItem)[0] as string]
                            ? 'icon-rotate'
                            : ''
                        }
                      >
                        <DownArrowIcon />
                      </span>
                    </Button>
                  </div>
                ) : (
                  <div className="sort-btn-new">
                    {placeHolderForStatus(
                      (Object?.values(headerItem)[0] as ReactNode)
                        ?.toString()
                        .toUpperCase(), false
                    )}
                  </div>
                )}
                {!HEADERS_ITEM_WITHOUT_INPUT.includes(
                  Object?.values(headerItem)[0] as string
                ) ? (
                  <>
                    <InputField
                      inputtype="text"
                      className='max-300'
                      inputid={`${Object?.keys(headerItem)[0]}`}
                      placeholder={`${Object?.values(headerItem)[0]}`}
                      inputMethod={(value) => {
                        handleChange(`${Object?.keys(headerItem)[0]}`, value);
                      }}
                      inputRef={register && register()}
                    />
                    {errorMsg[Object?.keys(headerItem)[0]] && (
                      <small className="text-danger text-capitalize pt-2">
                        {errorMsg[Object?.keys(headerItem)[0]]}
                      </small>
                    )}
                  </>
                ) : HEADERS_ITEM_WITH_NUMERIC_FIELD.includes(
                  Object?.values(headerItem)[0] as string
                ) ? (
                  <>
                    <InputField
                      className='max-300'
                      inputtype="text"
                      inputid={`${Object?.keys(headerItem)[0]}`}
                      placeholder={`${Object?.values(headerItem)[0]}`}
                      inputMethod={(value) => {
                        let checker = false;
                        if (value) {
                          checker = numericValueHandler(value);
                        } else {
                          delete errorMsg[Object?.keys(headerItem)[0]];
                          checker = true;
                        }
                        if (checker) {
                          if (errorMsg[Object?.keys(headerItem)[0]]) {
                            delete errorMsg[Object?.keys(headerItem)[0]];
                          }
                          handleChange(`${Object?.keys(headerItem)[0]}`, value);
                        } else {
                          setErrorMsg((prevErrors) => ({
                            ...prevErrors,
                            [Object?.keys(headerItem)[0]]:
                              'Please Do Not Enter any Special character',
                          }));
                        }
                      }}
                      inputRef={register && register()}
                    />
                    {errorMsg[Object?.keys(headerItem)[0]] && (
                      <small className="text-danger text-capitalize pt-2">
                        {errorMsg[Object?.keys(headerItem)[0]]}
                      </small>
                    )}
                  </>
                ) : HEADERS_ITEM_WITH_SELECTED.includes(
                  Object?.values(headerItem)[0] as string
                ) ? (
                  <>
                    <InputFieldColumn
                      className='max-300'
                      inputtype="select"
                      labels={(Object?.values(headerItem)[0] as ReactNode)
                        ?.toString()
                        .toUpperCase()}
                      tableName={tableName}
                      selectItems={selecionOfInputFieldInHeader(
                        `${Object?.values(headerItem)[0]}`,
                        tableName
                      )}
                      inputid={`${Object?.keys(headerItem)[0]}`}
                      placeholder={placeHolderForStatus(
                        `${Object?.values(headerItem)[0]}`, true
                      )}
                      inputMethod={(value) =>
                        handleChange(`${Object?.keys(headerItem)[0]}`, value)
                      }
                      inputRef={register && register()}
                    />
                  </>
                ) : HEADERS_ITEM_WITH_CALENDER.includes(
                  Object?.values(headerItem)[0] as string
                ) ? (
                  <div className='mt-2'>
                    <Input
                      type="date"
                      name={`${Object?.keys(headerItem)[0]}`}
                      max={maxDate()}
                      onChange={(event) =>
                        handleChange(
                          `${Object?.keys(headerItem)[0]}`,
                          event?.target?.value
                        )
                      }
                      innerRef={register && register()}
                      dateFormat="MM/dd/yyyy"
                    />
                  </div>
                ) : null}
              </th>
            ))}
          </tr>
        ) : (
          <tr>
            {tableHeader?.map((headerItem, index) => (
              <th
                key={index}
              >
                <div className={
                  tableHeader?.length - 1 === index &&
                    textCenter ? 'mx-5' : lastRight
                    ? 'text-right' : ''
                }>

                  {headerItem}
                </div>
              </th>
            ))}
          </tr>
        )}
      </thead>
      {props.children}
    </Table >
  );
};

export default TableComponent;
