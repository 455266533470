import { useQuery } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import { AppContext } from "../context";
import { IComingState } from "../Pages/agreements/interfaces";
import { FETCH_CURRENT_USER } from "../Pages/settings/gql";
import { LIST_ROUTES } from "../utils/constant";
import { destroyRouteHistory } from "../utils/helper";
import MainLayout from "./layout/MainLayout";
import { ALL_MODAL_ACCCESS_SETTINGS } from "../Pages/moduleAccess-settings/gql";
import { checkUserFeatureAccess } from "../utils/commonFn";

const PrivateRoute = ({ component: Component, roles, path, ...rest }: any) => {
  const token = window.localStorage.getItem("token");
  const { data, loading: userLoading } = useQuery(FETCH_CURRENT_USER);
  const { data: settingsData, loading } = useQuery(ALL_MODAL_ACCCESS_SETTINGS);
  const { setContextRoles, setContextUser } = useContext(AppContext);
  const location = useLocation<IComingState>()

  const mountEffect = () => {
    setContextUser(data?.me?.user || null);
    const userRole = data?.me?.user?.userRoles;
    if (userRole && userRole.length) {
      const _roles = userRole.map((role: any) => role.role.name);
      setContextRoles(_roles || []);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(mountEffect, [data]);

  // destroying routes history on visiting list
  useEffect(() => {
    if (LIST_ROUTES.includes(location.pathname)) {
      destroyRouteHistory()
    }
  }, [location.pathname])

  return (
    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /signin page

    <Route
      {...rest}
      render={(props) => {
        if (!token) {
          return <Redirect to="/" />;
        } else {
          if (path === '/job-list' && !loading && !userLoading &&
            !checkUserFeatureAccess(data?.me?.user?.id, 'JOB_LIST', settingsData)) {
            return <Redirect to="/un-authorized" />;
          }
          if (data?.me?.user?.userRoles?.length) {
            if (!roles) {
              return (
                <>
                  <MainLayout>
                    <Component {...props} />
                  </MainLayout>
                </>
              );
            }
            // eslint-disable-next-line
            const _userRoles = data?.me?.user.userRoles.map(
              (item: any) => item.role.name
            );
            // eslint-disable-next-line
            const hasAccess = _userRoles?.find((item: any) => {
              if (roles?.includes(item)) {
                return item;
              }
            });

            if (hasAccess) {
              return (
                <>
                  <MainLayout>
                    <Component {...props} />
                  </MainLayout>
                </>
              );
            } else {
              return <Redirect to="/un-authorized" />;
            }
          } else {
            return (
              <MainLayout>
                <Component {...props} />
              </MainLayout>
            );
          }
        }
      }}
    />
  );
};

export default PrivateRoute;
