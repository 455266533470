import { useLazyQuery } from '@apollo/client';
import classnames from 'classnames';
import { useContext, useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import {
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Tooltip,
} from "reactstrap";
import TITLE_ICON from "../../assets/images/card-title.svg";
import EMAIL_ICON from "../../assets/images/email-icon.svg";
import INFO_ICON_1 from "../../assets/images/info-icon-1.svg";
import INFO_ICON_6 from "../../assets/images/location-icon.svg";
import PHONE_ICON from "../../assets/images/phone-icon.svg";
import LINKEDIN_IMG from "../../assets/images/social1.svg";
import ActionLogsAndNotes from "../../components/ActionLogsAndNotes";
import AddNotesModal from "../../components/AddNotesModal";
import GoBack from "../../components/GoBack";
import SocialMediaIcons from "../../components/SocialMediaIcons";
import Loader from "../../components/layout/Loader";
import { AppContext } from "../../context";
import { formatDateUS } from "../../utils/commonFn";
import { ADMIN_ROLE, COMPANY_DETAILS_ROUTE, CONTACTS_ROUTE, CONTACT_DETAIL_ROUTE, EXECUTIVE_ROLE, NO, YES } from "../../utils/constant";
import { pushInHistory } from '../../utils/helper';
import { IComingState } from "../agreements/interfaces";
import CompanyAgreement from "../company/company-components/CompanyAgreementCard";
import "../company/company.scss";
import { GET_NOTES } from "../company/gql";
import ContactHistory from "./contact-components/ContactHistory";
import EmailNoteView from "./contact-components/EmailNotesView";
import { FETCH_CONTACTS_DETAIL } from "./gql";

const ViewContacts = (props: any) => {
  const location = useLocation<IComingState>();
  let history = useHistory();

  const comingState: any = location.state;
  const activeParamTab =
    new URLSearchParams(location.search).get('activeTab') || '1';
  const [activeTab, setActiveTab] = useState(activeParamTab);

  const [contactId, setContactId] = useState("");
  const [emailNoteModalData,] = useState(null);
  const [emailNoteModal, setEmailNoteModal] = useState<boolean>(false);
  const [activityNotes, setActivityNotes] = useState<boolean>(false);
  const [noteData, setNoteData] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const [tooltipOpen1, setTooltipOpen1] = useState(false);
  const toggle1 = () => setTooltipOpen1(!tooltipOpen1);

  const [tooltipOpen2, setTooltipOpen2] = useState(false);
  const toggle2 = () => setTooltipOpen2(!tooltipOpen2);

  const [tooltipOpen3, setTooltipOpen3] = useState(false);
  const toggle3 = () => setTooltipOpen3(!tooltipOpen3);

  const [tooltipOpen4, setTooltipOpen4] = useState(false);
  const toggle4 = () => setTooltipOpen4(!tooltipOpen4);

  const [tooltipOpen5, setTooltipOpen5] = useState(false);
  const toggle5 = () => setTooltipOpen5(!tooltipOpen5);

  const [tooltipOpen6, setTooltipOpen6] = useState(false);
  const toggle6 = () => setTooltipOpen6(!tooltipOpen6);

  const [getCompanyContact, { data, loading }] = useLazyQuery(
    FETCH_CONTACTS_DETAIL
  );

  const { user, userRoles } = useContext(AppContext);

  const [getNotes] = useLazyQuery(GET_NOTES);
  const _getNotes = () => {
    getNotes({
      variables: {
        id: contactId,
        notePayload: { type: 'Contact' },
        page: 1,
        limit: 3,
      },
    });
  };

  useEffect(() => {
    document.title = loading
      ? "Loading..."
      : `Update ${data?.fetchContactDetail.company?.name || ""
      } Contact - RealREPP`;
    // eslint-disable-next-line
  }, [data?.fetchContactDetail, loading]);

  useEffect(() => {
    let id = props.match.params.id;

    if (id) {
      setContactId(id);
      getCompanyContact({ variables: { id } });
    }
    // eslint-disable-next-line
  }, []);

  if (loading) {
    return <Loader />;
  }

  const toggleActivityNotes = () => setActivityNotes(!activityNotes);

  const emailNoteToggle = () => {
    setEmailNoteModal(!emailNoteModal);
  };

  const toggleTab = (tab: string) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      history.push(`${location?.pathname}?activeTab=${tab}`);
    }
  };

  return (
    <div className="admin-tabs team-tabs">
      <GoBack
        url={comingState?.backUrl ?? CONTACTS_ROUTE}
        search={comingState?.searchParameters}
      />

      <div className="primaryHeaderSpacing">
        <div className="mobileResponsiveFlexAlign primaryHeaderSpacing justify-content-between">
          <h5 className="m-0">
            {data?.fetchContactDetail?.firstName + ' '}
            {data?.fetchContactDetail?.lastName || '--'}
          </h5>

          <div>
            {(userRoles?.includes(ADMIN_ROLE) ||
              userRoles?.includes(EXECUTIVE_ROLE) ||
              data?.fetchContactDetail?.company?.ownerAndSharees?.find((item) => item.ownerType === 'SHAREE' && item.owner?.id === user?.id) ||
              user?.id === data?.fetchContactDetail?.company?.owner?.id ||
              !data?.fetchContactDetail?.company?.owner?.id) && (
                <Dropdown
                  isOpen={dropdownOpen}
                  toggle={toggle}
                  className="actionsDropdown"
                >
                  <DropdownToggle caret>Actions</DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem
                      tag={Link}
                      className="cursor-pointer"
                      to={`/update-company-contact/${contactId}`}
                      onClick={() => pushInHistory(comingState?.backUrl ?? `${CONTACT_DETAIL_ROUTE}/${contactId}`, location.search)}
                    >
                      Edit
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              )}
          </div>
        </div>

        <div>
          <div className="applicantDetail">
            <div className="infoCardDivider">
              <a
                href={data?.fetchContactDetail?.linkedInUrl}
                target="_blank"
                className="m-0 d-flex"
                rel="noreferrer"
              >
                <Tooltip
                  placement="top"
                  isOpen={tooltipOpen1}
                  target="TooltipExample1"
                  toggle={toggle1}
                >
                  Linkedin
                </Tooltip>

                <img
                  src={LINKEDIN_IMG}
                  alt="linkedin"
                  width="17px"
                  id="TooltipExample1"
                />
              </a>
            </div>

            <div className="divider"></div>

            <div className="infoCardDivider">
              <Tooltip
                placement="top"
                isOpen={tooltipOpen2}
                target="TooltipExample2"
                toggle={toggle2}
              >
                Company
              </Tooltip>
              <img
                src={INFO_ICON_1}
                alt="company"
                width="17px"
                id="TooltipExample2"
              />

              <Link to={{
                pathname: `${COMPANY_DETAILS_ROUTE}/${data?.fetchContactDetail?.company?.id}`,
                state: {
                  backUrl: `${CONTACT_DETAIL_ROUTE}/${contactId}`
                }
              }}
                onClick={() => pushInHistory(`${CONTACT_DETAIL_ROUTE}/${contactId}`)}
                className="m-0"
              >
                <label className="route-link">{data?.fetchContactDetail?.company?.name}</label>
              </Link>
            </div>

            <div className="divider"></div>

            <div className="infoCardDivider">
              <Tooltip
                placement="top"
                isOpen={tooltipOpen3}
                target="TooltipExample3"
                toggle={toggle3}
              >
                Job Title
              </Tooltip>

              <img
                src={TITLE_ICON}
                alt="company"
                width="20px"
                id="TooltipExample3"
              />
              <label>{data?.fetchContactDetail?.jobTitle || '--'}</label>
            </div>

            <div className="divider"></div>

            <div className="infoCardDivider">
              <Tooltip
                placement="top"
                isOpen={tooltipOpen4}
                target="TooltipExample4"
                toggle={toggle4}
              >
                Location
              </Tooltip>
              <img
                src={INFO_ICON_6}
                alt="address"
                width="17px"
                id="TooltipExample4"
              />
              <label>
                {['US', null, undefined, ''].includes(data?.fetchContactDetail?.companyLocation?.country)
                  ? data?.fetchContactDetail?.companyLocation?.city
                    ? `${data?.fetchContactDetail?.companyLocation?.city}, ${data?.fetchContactDetail?.companyLocation?.state}`
                    : data?.fetchContactDetail?.companyLocation?.state
                  : data?.fetchContactDetail?.companyLocation?.city
                    ? `${data?.fetchContactDetail?.companyLocation?.city}, ${data?.fetchContactDetail?.companyLocation?.country}`
                    : data?.fetchContactDetail?.companyLocation?.country
                }
              </label>
            </div>

            <div className="divider"></div>

            <div className="infoCardDivider">
              <Tooltip
                placement="top"
                isOpen={tooltipOpen5}
                target="TooltipExample5"
                toggle={toggle5}
              >
                Phone
              </Tooltip>

              <img
                src={PHONE_ICON}
                alt="phone"
                width="20px"
                id="TooltipExample5"
              />
              <label>
                <a
                  href={`tel:${data?.fetchContactDetail?.contactPhone?.find(
                    (item) => item?.isPrimary
                  )?.number
                    }`}
                  className="m-0"
                >
                  {(data?.fetchContactDetail?.contactPhone?.length &&
                    data?.fetchContactDetail?.contactPhone?.find(
                      (item) => item?.isPrimary
                    )?.number) ||
                    (data?.fetchContactDetail?.contactPhone?.length &&
                      data?.fetchContactDetail?.contactPhone[0]?.number) ||
                    '--'}
                </a>
              </label>
            </div>

            <div className="divider"></div>

            <div className="infoCardDivider">
              <Tooltip
                placement="top"
                isOpen={tooltipOpen6}
                target="TooltipExample6"
                toggle={toggle6}
              >
                Email
              </Tooltip>

              <img
                src={EMAIL_ICON}
                alt="email"
                width="20px"
                id="TooltipExample6"
              />
              <label>
                <a
                  href={`mailto:${(data?.fetchContactDetail?.contactEmail?.length &&
                    data?.fetchContactDetail?.contactEmail?.find(
                      (item: { isPrimary: boolean }) => item.isPrimary
                    )?.email) ||
                    (data?.fetchContactDetail?.contactEmail?.length &&
                      data?.fetchContactDetail?.contactEmail[0]?.email)
                    }`}
                  className="m-0"
                >
                  {(data?.fetchContactDetail?.contactEmail?.length &&
                    data?.fetchContactDetail?.contactEmail?.find(
                      (item: { isPrimary: boolean }) => item.isPrimary
                    )?.email) ||
                    (data?.fetchContactDetail?.contactEmail?.length &&
                      data?.fetchContactDetail?.contactEmail[0]?.email) ||
                    '--'}
                </a>
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className="CandidateTabsWrapper">
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '1' })}
              onClick={() => {
                toggleTab('1');
              }}
            >
              Basic Info
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '2' })}
              onClick={() => {
                toggleTab('2');
              }}
            >
              Activity
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '4' })}
              onClick={() => {
                toggleTab('4');
              }}
            >
              Agreements
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '5' })}
              onClick={() => {
                toggleTab('5');
              }}
            >
              Company History
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent activeTab={activeTab}>
          <TabPane
            tabId="1"
            className="tab-pane-padding view-contacts-basicInfo"
          >
            <Row>
              <Col lg="6" md="6" sm="12">
                <div className="jobOrderRevamp bg-transparent p-0 m-0">
                  <h5>Basic Info</h5>

                  <div className="d-flex  mb-2 contentGridLabel">
                    <label>First Name</label>
                    <h6>{data?.fetchContactDetail?.firstName}</h6>
                  </div>

                  <div className="d-flex  mb-2 contentGridLabel">
                    <label>Last Name</label>
                    <h6>{data?.fetchContactDetail?.lastName || '--'}</h6>
                  </div>

                  <div className="d-flex  mb-2 contentGridLabel">
                    <label>Job Title</label>
                    <h6>{data?.fetchContactDetail?.jobTitle || '--'}</h6>
                  </div>

                  <div className="d-flex  mb-2 contentGridLabel">
                    <label>Status</label>
                    <h6>{data?.fetchContactDetail?.status || '--'}</h6>
                  </div>

                  <div className="d-flex mb-2 contentGridLabel">
                    <label>Company</label>
                    <h6>
                      <Link to={{
                        pathname: `${COMPANY_DETAILS_ROUTE}/${data?.fetchContactDetail?.company?.id}`,
                        state: {
                          backUrl: `${CONTACT_DETAIL_ROUTE}/${contactId}`
                        }
                      }}
                        onClick={() => pushInHistory(`${CONTACT_DETAIL_ROUTE}/${contactId}`)}
                      >
                        <label className="route-link">{data?.fetchContactDetail?.company?.name}</label>
                      </Link>
                    </h6>
                  </div>

                  <div className="d-flex  mb-2 contentGridLabel">
                    <label>Location</label>
                    <h6>
                      {['US', null, undefined, ''].includes(data?.fetchContactDetail?.companyLocation?.country) ? data?.fetchContactDetail?.companyLocation?.city ? `${data?.fetchContactDetail?.companyLocation?.city}, ${data?.fetchContactDetail?.companyLocation?.state}` : data?.fetchContactDetail?.companyLocation?.state : data?.fetchContactDetail?.companyLocation?.city ? `${data?.fetchContactDetail?.companyLocation?.city}, ${data?.fetchContactDetail?.companyLocation?.country}` : data?.fetchContactDetail?.companyLocation?.country}
                    </h6>
                  </div>

                  <div className="d-flex  mb-2 contentGridLabel">
                    <label>Source</label>
                    <h6>{data?.fetchContactDetail?.source || '--'}</h6>
                  </div>

                  <div className="d-flex  mb-2 contentGridLabel">
                    <label>Last Contacted User</label>
                    <h6>
                      {data?.fetchContactDetail?.lastContactedUser?.fullName ||
                        '--'}
                    </h6>
                  </div>

                  <div className="d-flex  mb-2 contentGridLabel">
                    <label>Last Contacted At</label>
                    <h6>
                      {data?.fetchContactDetail?.lastContactedAt
                        ? formatDateUS(
                          data?.fetchContactDetail?.lastContactedAt
                        )
                        : '--'}
                    </h6>
                  </div>

                  <div className="d-flex  mb-2 contentGridLabel">
                    <label>Preferred Contact</label>
                    <h6>{data?.fetchContactDetail?.preferredContact ? YES : NO}</h6>
                  </div>

                  <SocialMediaIcons
                    facebook={data?.fetchContactDetail?.fbUrl}
                    twitter={data?.fetchContactDetail?.twitterUrl}
                    linkedin={data?.fetchContactDetail?.linkedInUrl}
                    instagram={data?.fetchContactDetail?.instaUrl}
                  />
                </div>
              </Col>
            </Row>

            <div className="p-0 mt-4 contactEmailsCard jobOrderRevamp bg-transparent">
              <Row>
                {!!data?.fetchContactDetail?.contactEmail?.length && (
                  <Col lg="6" md="6" sm="12" xs="12">
                    <h5 className="mb-2">Contact Details</h5>
                    {data?.fetchContactDetail?.contactEmail &&
                      data?.fetchContactDetail?.contactEmail?.map(
                        (item: any, index) => (
                          <div className="d-flex  mb-2 contentGridLabel">
                            {item?.isPrimary && <label>Primary Email</label>}
                            {!item?.isPrimary && <label>Secondary Email</label>}

                            <h6>{item?.email}</h6>
                          </div>
                        )
                      )}
                  </Col>
                )}

                <Col lg="6" md="6" sm="12" xs="12">
                  <h5 className="mb-2">Contact Phone</h5>
                  {!!data?.fetchContactDetail?.contactPhone?.length
                    && data?.fetchContactDetail?.contactPhone?.map(
                      (item, index) => (
                        <div key={index}>
                          <div className="d-flex  mb-2 contentGridLabel">
                            <label>
                              {item?.isPrimary
                                ? "Primary Phone"
                                : "Secondary Phone"}
                            </label>

                            <h6>
                              {item?.number}{" "}
                              <span className="subtitleTextWork">
                                ({item?.phoneType})
                              </span>
                            </h6>
                          </div>
                        </div>
                      )
                    )
                  }
                </Col>
              </Row>
            </div>
          </TabPane>

          <TabPane tabId="2">
            <ActionLogsAndNotes
              option={{
                contactId: data?.fetchContactDetail?.id ?? '',
                contactName: data?.fetchContactDetail?.fullName ?? '',
                companyId: data?.fetchContactDetail.company?.id ?? '',
                companyName: data?.fetchContactDetail.company?.name ?? '',
              }}
              type="Contact"
              recordId={contactId}
              viewDetail={false}
            />
          </TabPane>

          <TabPane tabId="4">
            <CompanyAgreement
              fromContact={true}
              companyDetails={{
                id: data?.fetchContactDetail.company?.id,
                name: data?.fetchContactDetail.company?.name,
              }}
            />
          </TabPane>

          <TabPane tabId="5">
            <ContactHistory contactId={contactId} />
          </TabPane>
        </TabContent>
      </div>

      <AddNotesModal
        toggleActivityNotes={toggleActivityNotes}
        activityNotes={activityNotes}
        noteableId={contactId}
        reFetch={() => _getNotes()}
        type="Contact"
        editData={noteData}
        resetField={setNoteData}
      />

      <EmailNoteView
        modalData={emailNoteModalData}
        modal={emailNoteModal}
        toggle={emailNoteToggle}
      />
    </div>
  );
};

export default ViewContacts;
