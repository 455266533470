import { useMutation } from "@apollo/client";
import React, { useEffect, useState } from "react";
// import Select from "react-select";
import { toast } from "react-toastify";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import { CREATE_ATTACHMENTS } from "../Pages/company/company-components/Attachments";
import { uploadImage } from "../utils/helper";
import CustomButton from "./layout/CustomButton";
import { useForm } from "react-hook-form";

import InputField from "./layout/InputField";
import { bucketFolders } from "../utils/constant";

import CLOSE_ICON from "../assets/images/close-icon.svg";
import DragDropFile from "../Pages/candidate/candidate-components/DragDropFile";

const AttachmentModal = ({
  modal,
  toggle,
  attachmentId,
  attachmentType,
  refetch,
  prevTable,
  fileType = "",
  driveUpload = false,
}) => {
  const { register } = useForm({});
  const [file, setFile] = useState<any>(null);
  const [fileName, setFileName] = useState<any>("");
  const [errorMessage, setErrorMessage] = useState("");
  const [candidateAttachableType, setAttachableType] =
    useState<string>(attachmentType);
  // const [confirmToggle, setConfirmToggle] = useState(false);
  const [uploadLogin, setUploadLogin] = useState(false);

  const [createAttachment] = useMutation(CREATE_ATTACHMENTS);

  const dropDown = [
    { label: "Cover Letter", value: "Cover Letter" },
    { label: "Offer Letter", value: "Offer Letter" },
    { label: "Project List", value: "Project List" },
    { label: "Portfolio", value: "Portfolio" },
    { label: "Other", value: "Other" },
  ];
  const uploadAttachment = async () => {
    try {
      if (
        prevTable === "Attachment" &&
        candidateAttachableType === "Candidate"
      ) {
        setErrorMessage("Please select Document Type!");
        //check if state has been changed
      } else {
        if (file) {
          setUploadLogin(true);
          let response = await uploadImage(
            file,
            bucketFolders.candidate,
            false,
            false
          );
          setUploadLogin(false);
          if (response.success) {
            await createAttachment({
              variables: {
                attachableId: attachmentId,
                attachableType: candidateAttachableType,
                documentUrl: response.source,
                driveUrl: response.driveUrl,
                documentName: response.fileName,
              },
            });
            setFileName("");
            setFile(null);
            toggle();
            toast.success("Successfully Uploaded!");
            refetch();
          }
        } else {
          setErrorMessage("Please select a file!");
        }
      }
    } catch (error) {
      toast.error("Error while uploading!");
      setUploadLogin(false);
    }
  };

  const fileHanlder = (file) => {
    setErrorMessage("");
    if (file) {
      if (file?.size > 30 * 1024 * 1024) {
        setFile(null);
        setFileName("");
        return setErrorMessage("File size it too large (should be 30MB).");
      }
      if (prevTable !== "Attachment") {
        if (
          !(
            file?.type === "application/pdf" ||
            file?.type ===
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
            file?.type === "application/msword"
          )
        ) {
          return setErrorMessage("Please select pdf, doc or docx file");
        }
      }
      if (file) {
        setFileName(file?.name);
      }
      setFile(file);
    }
  };

  useEffect(() => {
    if (candidateAttachableType === "") {
      setAttachableType("Candidate");
    }
  }, [candidateAttachableType]);

  return (
    <Modal isOpen={modal} className="revampDialog revampDialogWidth">
      <div className="modalHeaderCustom d-flex align-items-center justify-content-between">
        <h5 className="m-0">Upload Attachment</h5>

        <Button className="p-0 bg-transparent border-0" onClick={toggle}>
          <img src={CLOSE_ICON} alt="close" />
        </Button>
      </div>

      <ModalBody>
        <div>
          <div className={"mb-3"}>
            {prevTable === "Attachment" && attachmentType === "Candidate" && (
              <InputField
                inputtype="select"
                required={true}
                isRequired={true}
                selectItems={dropDown}
                label="Document Type"
                inputid="attachmentType"
                placeholder="Please select"
                inputRef={register({
                  required: {
                    value: true,
                    message: "Document type is required!",
                  },
                })}
                inputMethod={(item) => {
                  setAttachableType(item);
                }}
                inputValue={candidateAttachableType}
              />
            )}
          </div>
          {/* <div className="user-info upload-btn-wrapper">
            <input
              type="file"
              name="image"
              accept={fileType}
              onChange={async ({ target: { files } }) => {
                setErrorMessage("");
                if (files) {
                  let [file]: any = files;
                  if (file?.size > 25 * 1024 * 1024) {
                    setFile(null);
                    setFileName("");
                    return setErrorMessage(
                      "File size it too large (should be 25MB)."
                    );
                  }
                  if (prevTable !== "Attachment") {
                    if (
                      !(
                        file?.type === "application/pdf" ||
                        file?.type ===
                          "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                        file?.type === "application/msword"
                      )
                    ) {
                      return setErrorMessage(
                        "Please select pdf, doc or docx file"
                      );
                    }
                  }
                  if (file) {
                    setFileName(file?.name);
                  }
                  setFile(file);
                }
              }}
            />

            <Button className="bg-success border-0 px-3">Attach File</Button>
          </div> */}
          <div className={"revampContainer "}>
            <div style={{ position: "relative" }}>
              <DragDropFile
                onUpload={(file) => {
                  fileHanlder(file);
                }}
              />
            </div>
          </div>
          <div className="text-truncate textTruncateWidth mt-2 text-info">
            {fileName}
          </div>
          {errorMessage && <div className="text-danger">{errorMessage}</div>}
        </div>
      </ModalBody>
      <ModalFooter className="mt-3">
        <button
          onClick={() => {
            toggle();
            setFile(null);
            setFileName("");
            setErrorMessage("");
          }}
          type="button"
          className="buttonPrimary"
        >
          Close
        </button>

        <CustomButton
          buttonText="Save"
          buttonColor="primary"
          buttonType="submit"
          styling={{minWidth:'85px', minHeight: '43px'}}
          buttonClick={uploadAttachment}
          loading={uploadLogin}
          disabled={uploadLogin}
        />
      </ModalFooter>
    </Modal>
  );
};

export default AttachmentModal;
