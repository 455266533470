import { useEffect, createContext, ReactNode, useState } from 'react';
import { USERTYPE } from '../Pages/admin/users/User-data-type';
import { ISequenceContextPreviewSetup } from '../Pages/interseller/steps/interfaceSteps';
import { v4 as uuidv4 } from "uuid";

export interface TeachItem {
  id: string;
  content: string;
  stoppedMessage:string|null
}

export interface AppContextProps {
  user: USERTYPE | null;
  userRoles?: Array<string>;
  skillsESAggs?: Array<string>;
  theme?: string;
  privateCandidate?: boolean;
  sequencePreview: ISequenceContextPreviewSetup | null;
  teachList: TeachItem[];
  setContextUser(user: USERTYPE | null): void;
  setContextRoles(roles: string[]): void;
  setContextSkillsESAggs(roles: string[]): void;
  setTheme(theme: string): void;
  setContextSequence(sequence: {}): void;
  setContextPrivateCandidate(privateCandidate:boolean): void;
  addTeach(): void;
  updateTeach(id: string, content: string,stoppedMessage: string|null): void;
  removeTeach(id: string): void;
}

export const AppContext = createContext<AppContextProps>({
  user: null,
  userRoles: [],
  skillsESAggs: [],
  theme: 'light',
  sequencePreview: null,
  teachList: [],
  privateCandidate:false,
  setContextUser: () => {},
  setContextRoles: () => {},
  setContextSkillsESAggs: () => {},
  setTheme: () => {},
  setContextSequence: () => {},
  setContextPrivateCandidate: ()=>{},
  addTeach: () => {},
  updateTeach: () => {},
  removeTeach: () => {},
});

/**
 * Context Provider
 * @param props
 */
const AppContextProvider = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  const [user, setUser] = useState<USERTYPE | null>(null);
  const [userRoles, setUserRoles] = useState<Array<string> | []>([]);
  const [skillsESAggs, setSkillsESAggs] = useState<Array<string> | []>([]);
  const [sequencePreview, setSequencePreview] =
    useState<ISequenceContextPreviewSetup | null>(null);
  const [currentTheme, setCurrentTheme] = useState<string>(() => {
    const persistValue = localStorage.getItem('theme');
    return persistValue !== null ? persistValue : 'light';
  });
  const [privateCandidate, setPrivateCandidate] = useState<boolean>(false)
  const setContextUser = (user: USERTYPE | null) => setUser(user);
  const setContextRoles = (roles: string[] | []) => setUserRoles(roles);

  const setContextSkillsESAggs = (skills: string[] | []) =>
    setSkillsESAggs(skills);

  const setContextSequence = (sequence: ISequenceContextPreviewSetup) => {
    setSequencePreview(sequence);
  };
  const setContextPrivateCandidate = (privateCandidates: boolean) => {
    setPrivateCandidate(privateCandidates);
  };
  
  const [teachList, setTeachList] = useState<TeachItem[]>(() => {
    const savedTeachList = localStorage.getItem("teachList");
    return savedTeachList ? JSON.parse(savedTeachList) : [];
  });


  const addTeach = () => {
    const id = uuidv4();
    const newTeach: TeachItem = {
      id: id,
      content: "",
      stoppedMessage:null
    };
    setTeachList((prevTeachList) => [...prevTeachList, newTeach]);
    updateTeachLocalStorage([...teachList, newTeach]);
  };

  const updateTeach = (id: string, content: string,stoppedMessage:string|null) => {
    const indexToUpdate = teachList.findIndex((item) => item.id === id);
    if (indexToUpdate !== -1) {
      const newTeach = { id, content,stoppedMessage };
      const newTeachList = [...teachList];
      newTeachList[indexToUpdate] = newTeach;
      setTeachList(newTeachList);
    }
    updateTeachLocalStorage([...teachList]);
  };

  const removeTeach = (id: string) => {
    const indexToRemove = teachList.findIndex((item) => item.id === id);
    if (indexToRemove !== -1) {
      const newTeachList = [...teachList];
      newTeachList.splice(indexToRemove, 1);
      setTeachList(newTeachList);
    }
    updateTeachLocalStorage([...teachList]);
  };

  function updateTeachLocalStorage(listTeach: TeachItem[]) {
    localStorage.setItem("teachList", JSON.stringify(listTeach));
  }

  useEffect(() => {
    document.body.classList.add("light-mode");
  }, []);

  const setTheme = (theme: string) => setCurrentTheme(theme);

  useEffect(() => {
    document.body.classList.add('light-mode');
  }, []);

  return (
    <AppContext.Provider
      value={{
        user,
        userRoles,
        skillsESAggs,
        theme: currentTheme,
        sequencePreview,
        privateCandidate,
        teachList,
        setContextUser,
        setContextRoles,
        setContextSkillsESAggs,
        setTheme,
        setContextSequence,
        setContextPrivateCandidate,
        addTeach,
        updateTeach,
        removeTeach,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
