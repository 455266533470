import { useCallback, useContext, useEffect, useState } from "react";
import { AppContext } from "../../context";
import { ROLES } from "../../utils/constant";
import CollapseComponent from '../../components/layout/Collapse';
import PREVIEW_ICON from '../../assets/images/view-icon.svg';
import DOWNLOAD_ICON from '../../assets/images/download.svg';
import EDIT_ICON from "../../assets/images/edit-icon-menu.svg";
import DELETE_ICON from "../../assets/images//delete-icon-menu.svg";
import { Button, Card, CardBody, Modal, Table } from "reactstrap";
import TableComponent from "../../components/layout/TableComponent";
import { REALREPPDOCUMENT_HEADER, RealReppDocumentEnum } from "../../constants";
import UpsertRealReppDocumentModal from "./UpsertRealReppDocumentModal";
import ConfirmPopUp from "../../components/ConfirmPopUp";
import { toast } from "react-toastify";
import { RealReppDocument, RealReppDocumentData } from "../../interfaces";
import NoDataFound from "../../components/layout/NoDataFound";
import { useLazyQuery, useMutation } from "@apollo/client";
import { DELETE_DOCUMENTS, GET_REAL_REPP_DOCUMENTS, GET_SIGNED_URL } from "./gql";
import Loader from "../../components/layout/Loader";
import Preview from "../job-order/job-order-components/document-preview/Preview";


const RealReppDocuments = () => {
  //context
  const { userRoles } = useContext(AppContext);

  // Fetch the list of users using the useQuery hook
  const [fetchDocuments, { loading: listLoading }] = useLazyQuery(GET_REAL_REPP_DOCUMENTS);
  const [getSignedURL] = useLazyQuery(GET_SIGNED_URL);
  const [deleteDoc] = useMutation(DELETE_DOCUMENTS)

  //local State
  const [createModalOpen, setCreateModalOpen] = useState<boolean>(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [realReppDocuments, setRealReppDocuments] = useState<RealReppDocument[]>([])
  const [realReppDocumentObj, setRealReppDocumentObj] = useState<RealReppDocumentData>({
    id: '',
    name: '',
    link: '',
    category: RealReppDocumentEnum.HR_DOCUMENTS,
  });
  const [realReppDocumentId, setRealReppDocumentId] = useState<string>('')
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [viewDocument, setViewDocument] = useState<boolean>()
  const [documentUrl, setDocumentUrl] = useState<string>('')
  const [documentName, setDocumentName] = useState<string>('')


  //hooks
  const fetchAllDocuments = useCallback(async () => {
    const res = await fetchDocuments({ variables: { getRealreppDocuments: {} } });
    if (res) {
      const { data } = res || {}
      const { getRealreppDocumentsByCategory } = data || {}
      const { documents } = getRealreppDocumentsByCategory || {}
      setRealReppDocuments(documents)
    }
  }, [fetchDocuments])

  const SignedUrl = useCallback(async (url) => {
    const { data } = await getSignedURL({ variables: { url } })
    const { getSignedUrl } = data || {};
    const { signedUrl } = getSignedUrl || {};
    return signedUrl;
  }, [getSignedURL])

  useEffect(() => {
    fetchAllDocuments()
  }, [fetchAllDocuments])

  useEffect(() => {
    if (isEdit && realReppDocumentObj) {
      setCreateModalOpen(true)
    }
  }, [isEdit, realReppDocumentObj])

  //methods
  const refetchData = async () => {
    await fetchAllDocuments()
  }

  const updateRealReppDocumentsData = (item: RealReppDocumentData) => {
    setRealReppDocumentObj({ ...item })
  }

  const handleAddNewDocumentClick = () => {
    setIsEdit(false);
    setTimeout(() => {
      setCreateModalOpen(!createModalOpen);
    }, 0);
  };

  const deleteRealReppDocumentData = async () => {
    if (realReppDocumentId) {
      const { data } = await deleteDoc({ variables: { deleteRealreppDocument: { id: realReppDocumentId } } })
      const { deleteRealreppDocuments } = data;
      if (deleteRealreppDocuments?.status) {
        toast.success(deleteRealreppDocuments.message);
        refetchData();
        setDeleteModalOpen(false);
      }
    }
  };

  const selectedPreviewSignedUrl = async (url: string, name: string) => {
    setViewDocument(true)
    setDocumentUrl(await SignedUrl(url))
    setDocumentName(name)
  }

  const selectedDownloadSignedUrl = async (url: string) => {
    window.open(await SignedUrl(url), '_blank');
  }

  const viewDocumentToggle = () => setViewDocument(!viewDocument)
  return (
    <>
      <div className="reports-wrapper">
        <div className='w-100 d-flex justify-content-end align-items-center'>
          <div>
            {userRoles?.includes(ROLES.ADMIN) ||
              userRoles?.includes(ROLES.EXECUTIVE) ? (
              <button
                className="buttonGenericStyle filledButton"
                onClick={handleAddNewDocumentClick}
                disabled={
                  userRoles?.includes(ROLES.ADMIN) ||
                    userRoles?.includes(ROLES.EXECUTIVE)
                    ? false
                    : true
                }
              >
                Add New Documents
              </button>
            ) : ('')}
          </div>
        </div>
        {realReppDocuments?.length > 0 ? realReppDocuments?.map((item, index) => (
          <CollapseComponent title={RealReppDocumentEnum[item?.name]} key={index}>
            <Card className="border-0">

              <CardBody>
                <div className="table-responsive">
                  <Table
                    borderless={true}
                    className="border-0 text-12"
                  >
                    <TableComponent tableHeader={REALREPPDOCUMENT_HEADER}>
                      {listLoading ? (
                        <tbody>
                          <tr>
                            <td colSpan={4}>
                              <Loader loaderClass="sm-height " />
                            </td>
                          </tr>
                        </tbody>
                      ) : (
                        <tbody>
                          {item?.data?.map((value: RealReppDocumentData, index: number) => (
                            <tr key={index} className="border-divider " >
                              <td className="w-35rem p-0 hover-link" >
                                {value.name}
                              </td>
                              <td >
                                <div className="justify-center d-flex align-items-center"
                                  onClick={() => selectedDownloadSignedUrl(value.link)} >
                                  <img
                                    src={DOWNLOAD_ICON}
                                    alt="download"
                                    width={'15px'}
                                    className="mr-2 curser-pointer "
                                  />
                                  <span className="  curser-pointer " > Download</span>
                                </div>
                              </td>
                              <td>
                                <div className="d-flex justify-center">
                                  <Button
                                    onClick={() => selectedPreviewSignedUrl(value.link, value.name)}
                                    className="btn bg-transparent p-0 border-0"
                                    disabled={userRoles?.includes(ROLES.ADMIN) || userRoles?.includes(ROLES.EXECUTIVE) ? false : true}
                                  >
                                    <img src={PREVIEW_ICON} alt="preview" />
                                  </Button>
                                  <Button
                                    onClick={() => {
                                      setIsEdit(true)
                                      updateRealReppDocumentsData({
                                        id: value.id,
                                        name: value?.name,
                                        link: value?.link,
                                        category: item?.name,
                                      } as RealReppDocumentData)
                                    }}
                                    disabled={userRoles?.includes(ROLES.ADMIN) || userRoles?.includes(ROLES.EXECUTIVE) ? false : true}
                                    className="btn bg-transparent p-0 border-0"
                                  >
                                    <img src={EDIT_ICON} alt="edit" />
                                  </Button>
                                  <Button
                                    onClick={() => {
                                      setRealReppDocumentId(value?.id);
                                      setDeleteModalOpen(true);
                                    }}
                                    className="btn bg-transparent p-0 border-0"
                                    disabled={userRoles?.includes(ROLES.ADMIN) || userRoles?.includes(ROLES.EXECUTIVE) ? false : true}

                                  >
                                    <img src={DELETE_ICON} alt="delete" />
                                  </Button>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      )}
                    </TableComponent>
                  </Table>
                </div>
              </CardBody>
            </Card>
          </CollapseComponent>
        ))
          :
          (
            <NoDataFound text="No Documents Found!" />
          )


        }
      </div>

      <UpsertRealReppDocumentModal
        refetchData={refetchData}
        isOpen={createModalOpen}
        toggle={() => setCreateModalOpen(!createModalOpen)}
        modalTitle={isEdit ? "Update Document" : "Add New Document"}
        realReppDocumentObj={isEdit ? realReppDocumentObj :
          {
            id: '',
            name: '',
            link: null,
            category: RealReppDocumentEnum.HR_DOCUMENTS,
          }
        }
        setIsEdit={setIsEdit}
        isEdit={isEdit}
      />
      <Modal
        isOpen={viewDocument}
        toggle={viewDocumentToggle}
        centered={true}
        size="xl"
        className="revampDialog border-0"
      >
        <div className="pdf-viewer">
          <Preview
            documentUrl={documentUrl}
            documentName={`${documentName}.pdf`}
            isDownload={true}
          />
        </div>
      </Modal>

      <ConfirmPopUp
        isOpen={deleteModalOpen}
        toggle={() => setDeleteModalOpen(false)}
        confirmAction={() => deleteRealReppDocumentData()}
        confirmText="Are You Sure You Want To Delete This Document?"
        modalHeading="Delete Document"
      />
    </>
  )
}

export default RealReppDocuments;