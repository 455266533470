import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast, ToastContainer } from 'react-toastify';
import {
  Col,
  Form,
  Row,
  Spinner,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Alert,
} from 'reactstrap';
import GoBack from '../../components/GoBack';
import CustomButton from '../../components/layout/CustomButton';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import classnames from 'classnames';
import '../company/company.scss';
import BasicJobOrderInfo from './job-order-components/BasicJobOrderInfo';
import JobDetails from './job-order-components/JobDetails';
import {
  CREATE_JOB_ORDER,
  FETCH_TEAM_AND_MEMBERS,
  FETCH_TEAM_AND_MEMBERS_MANUAL_USER,
  FINDING_PARALLEL_TEAM_MEMBERS,
  GET_JOB_ORDER,
  GET_JOB_ORDER_STATS,
  GET_SINGLE_ADVERTISED_LOCATION,
  UPDATE_JOB_ORDER,
} from './gql';
import Loader from '../../components/layout/Loader';
import {
  createClonedJobOrderPayload,
  createJobOrderDate,
  createJobOrderPayload,
} from '../../utils/createJobOrderData';
import ConfirmPopUp from '../../components/ConfirmPopUp';
import { checkUserFeatureAccess, compactObject } from '../../utils/commonFn';
import { AppContext } from '../../context';
import { GET_ALL_COMPANY_OWNER_SHAREE, GET_NOTES } from '../company/gql';
import AdvertiseLocationJobOrder from './job-order-components/AdvertiseLocaitonJobOrder';
import JobOrderClientDocument from './job-order-components/JobOrderClientDocument';
import AddNotesModal from '../../components/AddNotesModal';
import { useHistory, useLocation } from 'react-router-dom';
import { IComingState } from '../agreements/interfaces';

import INFO_ICON_1 from '../../assets/images/info-icon-1.svg';
import INFO_ICON_2 from '../../assets/images/info-icon-2.svg';
import INFO_ICON_3 from '../../assets/images/info-icon-3.svg';
import RecruiterAssigned from './job-order-components/RecruiterAssigned';
import { FETCHING_USERS_WITHOUT_ROLES } from '../admin/gql';
import JobApplicantTab from './job-order-components/JobApplicantTab';
import Attachments from '../company/company-components/Attachments';
import SubmissionHistoryCandidate from '../candidate/candidate-components/SubmissionHistoryCandidate';
import AsyncCompanyDropdown from '../../components/AsyncCompanySelect';
import { FETCH_CONTACT_HISTORY_DETAIL } from '../contacts/gql';
import {
  COMPANY_DETAILS_ROUTE,
  UPDATE_JOB_ORDER_ROUTE,
  VIEW_PLACEMENT_MANAGEMENT_ROUTE,
} from '../../utils/constant';
import JobListNotes from '../jobListNotes';
import { ALL_MODAL_ACCCESS_SETTINGS } from '../moduleAccess-settings/gql';

const UpdateJobOrderGoBackLogic = ({
  from,
  comingState,
  placementId,
  state,
}: {
  from: string;
  comingState: any;
  placementId: string;
  state: any;
}) => {
  switch (from) {
    case 'placements-management-view':
      return (
        <GoBack url={`${VIEW_PLACEMENT_MANAGEMENT_ROUTE}/${placementId}`} />
      );
    case 'placement-update':
      return (
        <GoBack
          url={`/candidate-pipelining/placements/update`}
          search={comingState?.searchParameters}
          data={state}
        />
      );

    default:
      return (
        <GoBack
          url={comingState?.backUrl ?? '/job-orders'}
          search={comingState?.searchParameters}
        />
      );
  }
};

const UpdateJobOrder = ({ match }: any) => {
  const {
    errors,
    handleSubmit,
    register,
    watch,
    reset,
    control,
    setValue,
    getValues,
  } = useForm();

  const history = useHistory();
  const location = useLocation<IComingState>();
  const activeParamTab =
    new URLSearchParams(location.search).get('activeTab') || '1';
  const [activeTab, setActiveTab] = useState(activeParamTab);
  const { data: settingsData } = useQuery(ALL_MODAL_ACCCESS_SETTINGS);
  const comingState: any = location.state;
  const [locationOfJob, setlocationOfJob] = useState<string>('');

  const from = new URLSearchParams(location?.search).get('from');
  const placementId = new URLSearchParams(location?.search).get('placementId');
  const [selectedCompanyId, setSelectedCompanyId] = useState<string>('');
  const [selectedCompanyName, setSelectedCompanyName] = useState<string>('');
  const [_updateJobOrder, { loading: waiting }] = useMutation(UPDATE_JOB_ORDER);
  const [confirmToggle, setConfirmToggle] = useState(false);
  const [cloneModal, setCloneModal] = useState(false);
  const [contactWarning, setContactWarning] = useState(false);
  const [jobOrderId, setJobOrderId] = useState('');
  const [recruiterLevel, setRecruiterLevel] = useState(null);
  const [activityNotes, setActivityNotes] = useState<boolean>(false);
  const [noteData, setNoteData] = useState(null);
  const { user, userRoles } = useContext(AppContext);
  const [getJobOrder, { data, loading }] = useLazyQuery(GET_JOB_ORDER);
  const [getStats, { data: stats }] = useLazyQuery(GET_JOB_ORDER_STATS);
  const [getContactHistory, { data: contactHistory }] = useLazyQuery(
    FETCH_CONTACT_HISTORY_DETAIL
  );
  const [ownerOrShareeOfCompany, setOwnerOrShareeOfCompany] = useState('');
  const [lastAdvertisedLoc, setLastAdvertisedLoc] = useState('');
  const [advertiseLocationList, setAdvertisedLocationList] = useState([]);
  const [getAdvertisedLocations, { data: singleAdvertisedLocations }] =
    useLazyQuery(GET_SINGLE_ADVERTISED_LOCATION);

  const [_duplicateJobOrder, { loading: duplicating }] =
    useMutation(CREATE_JOB_ORDER);
  const [getOwnershipSharee, { data: ownershipSharee }] = useLazyQuery(
    GET_ALL_COMPANY_OWNER_SHAREE
  );
  const [fetchTeamMemberManualUser, { data: teamMembersManualUser }] =
    useLazyQuery(FETCH_TEAM_AND_MEMBERS_MANUAL_USER);
  const [fetchTeamMember, { data: teamMembers }] = useLazyQuery(
    FETCH_TEAM_AND_MEMBERS
  );

  const [findingParallelTeamUsers, { data: parallelTeamUsers }] = useLazyQuery(
    FINDING_PARALLEL_TEAM_MEMBERS
  );
  const [getUsers, { data: users }] = useLazyQuery(
    FETCHING_USERS_WITHOUT_ROLES
  );
  const [getNotes] = useLazyQuery(GET_NOTES);
  const _getNotes = () => {
    getNotes({
      variables: {
        id: jobOrderId,
        notePayload: { type: 'Job Order' },
        page: 1,
        limit: 3,
      },
    });
  };

  const updateJobOrder = async (values: any) => {
    values.jobOrderId = jobOrderId;
    console.log("values ", values)
    let jobOrderDetail: any = createJobOrderPayload(values);
    let res = await _updateJobOrder({
      variables: {
        UpdateJobOrderDto: {
          ...jobOrderDetail,
          postToLinkedin: jobOrderDetail.confidentialJob ? false : true
        }
      },
    });
    if (res?.data) {
      toast.success('Successfully Update!');
      getJobOrder({ variables: { id: jobOrderId } });
    }
  };
  const toggleActivityNotes = () => setActivityNotes(!activityNotes);
  const duplicateJobOrder = async () => {
    let values: any = { ...data?.getJobOrder };
    values.status = 'DRAFT';
    values.jobOrderId = data?.getJobOrder?.id;
    values.jobTitle = `(Copied of ${values?.jobTitle})`;
    values.companyId = selectedCompanyId;
    values.isHot = false;
    let jobOrderDetail = createClonedJobOrderPayload(values);
    jobOrderDetail.recruitingList = [];
    let res = await _duplicateJobOrder({
      variables: { CreateJobOrderInput: { ...compactObject(jobOrderDetail) } },
    });
    if (res?.data) {
      toast.success('Successfully Cloned!');
      setConfirmToggle(!confirmToggle);
      setSelectedCompanyId('');
      setCloneModal(!cloneModal);
      history.push(`/update-job-order/${res?.data?.createJobOrder?.id}`);
    }
  };

  const checkEditPermission = () => {
    if (userRoles?.includes('ADMIN') || userRoles?.includes('EXECUTIVE')) {
      return true;
    }
    if (ownershipSharee?.getAllCompanyOwnerAndSharee?.length) {
      const currentUser = ownershipSharee?.getAllCompanyOwnerAndSharee?.find(
        (item: { owner: { id: string } }) => item?.owner?.id === user?.id
      );
      if (currentUser) {
        return true;
      }
    }
    return false;
  };

  const teamPermissionMethod = () => {
    if (userRoles?.includes('ADMIN') || userRoles?.includes('EXECUTIVE')) {
      return true;
    }
    if (teamMembers && teamMembersManualUser) {
      const teamMatched =
        teamMembersManualUser?.widgetTeamMembersManualUser.find(
          (item: any) =>
            item?.team?.id === teamMembers?.widgetTeamMembers[0]?.team?.id
        );
      if (teamMatched) {
        return true;
      }
    } else if (teamMembers) {
      return true;
    } else {
      return false;
    }
  };

  const toggleTab = (tab: string) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      history.push(`${location?.pathname}?activeTab=${tab}`);
    }
  };

  const checkEditPermissionWithoutSharee = () => {
    if (userRoles?.includes('ADMIN') || userRoles?.includes('EXECUTIVE')) {
      return true;
    }
    if (ownershipSharee?.getAllCompanyOwnerAndSharee?.length) {
      const currentUser = ownershipSharee?.getAllCompanyOwnerAndSharee?.find(
        (item: any) =>
          item?.owner?.id === user?.id && item?.ownerType === 'OWNER'
      );
      if (currentUser) return true;
    }
    return false;
  };

  useEffect(() => {
    if (data?.getJobOrder) {
      let preFilledData = createJobOrderDate(data?.getJobOrder);
      setRecruiterLevel(preFilledData?.recruitingLevel);
      reset({
        ...preFilledData,
        allowRemote: preFilledData.allowRemote ? 'true' : 'false',
      });
    }
    // eslint-disable-next-line
  }, [data]);

  //setting title page
  useEffect(() => {
    document.title = loading
      ? 'Loading...'
      : `Update ${data?.getJobOrder?.jobTitle || 'Job'} - RealREPP`;

    // eslint-disable-next-line
  }, [loading]);

  useEffect(() => {
    let id = match.params.id;
    if (id) {
      setJobOrderId(id);
      getJobOrder({ variables: { id } });
      getStats({ variables: { jobOrderId: id } });
      getAdvertisedLocations({ variables: { id: id } });
      getUsers();
    }

    // eslint-disable-next-line
  }, [match.params.id]);

  useEffect(() => {
    fetchTeamMember();
    if (ownershipSharee) {
      const filterOwner = ownershipSharee?.getAllCompanyOwnerAndSharee.find(
        (item: any) => item?.ownerType === 'OWNER'
      );

      const filterCurrentUserAsOwner =
        ownershipSharee?.getAllCompanyOwnerAndSharee.find(
          (item: any) =>
            item?.ownerType === 'OWNER' && item?.owner?.id === user?.id
        );

      const filterCurrentUserAsSharee =
        ownershipSharee?.getAllCompanyOwnerAndSharee.find(
          (item: any) =>
            item?.ownerType === 'SHAREE' && item?.owner?.id === user?.id
        );
      if (filterCurrentUserAsOwner) {
        setOwnerOrShareeOfCompany('OWNER');
      } else if (filterCurrentUserAsSharee) {
        setOwnerOrShareeOfCompany('SHAREE');
      } else {
        setOwnerOrShareeOfCompany('NOT_OWNER_NOT_SHAREE');
      }

      if (filterOwner) {
        fetchTeamMemberManualUser({
          variables: { id: filterOwner?.owner?.id },
        });
      }
    }
    // eslint-disable-next-line
  }, [ownershipSharee]);

  useEffect(() => {
    const companyId = data?.getJobOrder?.company?.id;

    if (companyId) {
      getOwnershipSharee({
        variables: { id: companyId },
      });
      findingParallelTeamUsers({
        variables: { companyId },
      });
    }

    // eslint-disable-next-line
  }, [data?.getJobOrder?.company?.id]);

  useEffect(() => {
    // compulsory companyId because sharee/owner list depends on it.
    if (
      data?.getJobOrder?.company?.id &&
      ownershipSharee?.getAllCompanyOwnerAndSharee &&
      user?.id
    ) {
      const permit = checkEditPermission();

      if (!permit) {
        history.push({
          pathname: '/un-authorized',
        });
      }
    }

    // eslint-disable-next-line
  }, [ownershipSharee?.getAllCompanyOwnerAndSharee, user?.id]);
  useEffect(() => {
    if (singleAdvertisedLocations?.getSingleLocation) {
      setLastAdvertisedLoc(
        ['US', 'CA', null, undefined, ''].includes(
          singleAdvertisedLocations?.getSingleLocation?.country
        )
          ? `${singleAdvertisedLocations?.getSingleLocation?.city}, ${singleAdvertisedLocations?.getSingleLocation?.state}`
          : `${singleAdvertisedLocations?.getSingleLocation?.city}, ${singleAdvertisedLocations?.getSingleLocation?.country}`
      );
    }

    // eslint-disable-next-line
  }, [singleAdvertisedLocations]);

  const changeCompanyContactHandler = (contactId: string) => {
    if (contactId) {
      getContactHistory({ variables: { contactId: contactId } });
    } else {
      setContactWarning(false);
    }
  };

  useEffect(() => {
    if (contactHistory?.getContactHistoryDetail && !contactWarning) {
      setContactWarning(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactHistory?.getContactHistoryDetail]);

  if (loading && !data?.getJobOrder) {
    return <Loader />;
  }

  return (
    <div className="">
      <ToastContainer autoClose={3000} />

      <UpdateJobOrderGoBackLogic
        from={from || ''}
        comingState={comingState || ''}
        placementId={placementId || ''}
        state={comingState?.data}
      />

      <div className="mobileResponsiveFlexAlign justify-content-between primaryHeading">
        <h5 className="m-0">{data?.getJobOrder?.jobTitle || '--'}</h5>

        <button
          type="button"
          className="buttonGenericStyle filledButton mr-2"
          onClick={() => setConfirmToggle(!confirmToggle)}
        >
          {duplicating && <Spinner size="sm" className="mr-2" color="white" />}
          Clone This Job Order
        </button>
      </div>

      {contactWarning && (
        <Alert color="danger">
          Company contact{' '}
          <strong>
            {' '}
            {contactHistory?.getContactHistoryDetail?.contact?.fullName}
          </strong>{' '}
          is currently assigned to client{' '}
          <strong>
            {contactHistory?.getContactHistoryDetail?.newCompany?.name}
          </strong>
        </Alert>
      )}

      <div className="applicantDetail primaryHeaderSpacing">
        <div className="infoCardDivider">
          <img src={INFO_ICON_1} alt="company" />

          {!!data?.getJobOrder?.company?.name ? (
            <label
              className="route-link"
              onClick={() => {
                history.push({
                  pathname: `${COMPANY_DETAILS_ROUTE}/${data?.getJobOrder?.company?.id}`,
                  state: {
                    backUrl: `${UPDATE_JOB_ORDER_ROUTE}/${data?.getJobOrder?.id}`,
                    searchParameters: location.search,
                  },
                });
              }}
            >
              {data?.getJobOrder?.company?.name}
            </label>
          ) : (
            <label>--</label>
          )}
        </div>

        <div className="divider"></div>

        <div className="infoCardDivider">
          <img src={INFO_ICON_2} alt="company" />
          <label>
            {data?.getJobOrder?.companyLocation?.country === 'CA' ? (
              (data?.getJobOrder?.companyLocation?.city || '--') +
              ', ' +
              (data?.getJobOrder?.companyLocation?.state || '--') +
              ', ' +
              (data?.getJobOrder?.companyLocation?.country || '--')
            ) :
              ['US', null, undefined, ''].includes(
                data?.getJobOrder?.companyLocation?.country
              )
                ? (data?.getJobOrder?.companyLocation?.city || '--') +
                ', ' +
                (data?.getJobOrder?.companyLocation?.state || '--')
                : (data?.getJobOrder?.companyLocation?.city || '--') +
                ', ' +
                (data?.getJobOrder?.companyLocation?.country || '--')}
          </label>
        </div>

        <div className="divider"></div>

        <div className="infoCardDivider">
          <img src={INFO_ICON_3} alt="company" />
          <label>{data?.getJobOrder?.workType || '--'}</label>
        </div>
      </div>

      <div className="CandidateTabsWrapper">
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '1' })}
              onClick={() => {
                toggleTab('1');
              }}
            >
              About
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '2' })}
              onClick={() => {
                toggleTab('2');
              }}
            >
              Job Ad Details
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '3' })}
              onClick={() => {
                toggleTab('3');
              }}
            >
              Advertised Location
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '4' })}
              onClick={() => {
                toggleTab('4');
              }}
            >
              Assigned Recruiters
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '5' })}
              onClick={() => {
                toggleTab('5');
              }}
            >
              Documents
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '6' })}
              onClick={() => {
                toggleTab('6');
              }}
            >
              Job Applicants
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '7' })}
              onClick={() => {
                toggleTab('7');
              }}
            >
              Company Documents
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '8' })}
              onClick={() => {
                toggleTab('8');
              }}
            >
              Submission
            </NavLink>
          </NavItem>

          {checkUserFeatureAccess(user?.id, 'JOB_LIST', settingsData) &&
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === '9' })}
                onClick={() => {
                  toggleTab('9');
                }}
              >
                Job List Notes
              </NavLink>
            </NavItem>}
        </Nav>

        <TabContent activeTab={activeTab}>
          <TabPane className="tab-pane-padding" tabId="1">
            <div className="editJobOrderRevamp jobOrderRevamp p-0">
              <h5 className="mb-3">Job Order Widget</h5>

              <div className="d-flex justify-content-between align-items-center labelTextSpacing">
                <label>Candidates in pipeline</label>
                <h6>{stats?.getJobOrderStats?.candidatePipelineCount || 0}</h6>
              </div>

              <div className="d-flex justify-content-between align-items-center labelTextSpacing">
                <label>Days since last activity</label>
                <h6>{stats?.getJobOrderStats?.daySinceLastActivity || 0}</h6>
              </div>

              <div className="d-flex justify-content-between align-items-center labelTextSpacing">
                <label>Job application</label>
                <h6>{stats?.getJobOrderStats?.jobApplicantCount || 0}</h6>
              </div>

              <div className="d-flex justify-content-between align-items-center labelTextSpacing m-0">
                <label>Candidates in candidate pool</label>
                <h6>{stats?.getJobOrderStats?.candidatePoolCount || 0}</h6>
              </div>
            </div>
          </TabPane>

          <TabPane tabId="2" className="tab-pane-padding pb-0">
            <BasicJobOrderInfo
              setRecruiterLevel={setRecruiterLevel}
              fromEdit={true}
              control={control}
              recruiterLevel={recruiterLevel}
              register={register}
              errors={errors}
              location={data?.getJobOrder?.companyLocation}
              _company={data?.getJobOrder?.company}
              _jobOrderId={data?.getJobOrder?.id}
              makeCompanyReadOnly={true}
              defaultRecruiter={ownershipSharee?.getAllCompanyOwnerAndSharee}
              setValue={setValue}
              getValues={getValues}
              assignRecruiter={true}
              // this is for checking if any of the contact is migrated or not
              setContactDontExist={changeCompanyContactHandler}
              setlocationOfJob={setlocationOfJob}
              watch={watch}
            />

            <JobDetails
              jobOrderId={jobOrderId}
              fromEdit={true}
              statusCheck={false}
              register={register}
              watch={watch}
              errors={errors}
              // agreementsList={agreementsList}
              control={control}
              setValue={setValue}
              getValues={getValues}
              lastAdvertisedLocation={lastAdvertisedLoc}
              advertiseLocationList={advertiseLocationList}
              locationOfJob={locationOfJob}
            />
          </TabPane>

          <TabPane tabId="3">
            <AdvertiseLocationJobOrder
              checkEditPermission={checkEditPermission()}
              reFetchJobOrder={() => {
                getJobOrder({ variables: { id: match?.params?.id } });
              }}
              jobOrderId={jobOrderId}
              setAdvertisedLocationList={setAdvertisedLocationList}
            />
          </TabPane>

          <TabPane tabId="4" className="tab-pane-padding">
            <Row>
              <Col md="5" sm={12}>
                <RecruiterAssigned
                  jobOwner={data?.getJobOrder?.user}
                  teamPermission={teamPermissionMethod()}
                  recruiterList={
                    // uncomment these line when job order recruitingLevel will be team
                    // data?.getJobOrder?.recruitingLevel === "TEAM"
                    //   ? recruiters?.getRecruiterListWithoutManager
                    //   :
                    users?.allUsers
                  }
                  ownerOrShareeOfCompany={ownerOrShareeOfCompany}
                  listOfRecruiters={data?.getJobOrder?.recruitingAssigned}
                  recruiterLoading={loading}
                  jobOrderId={data?.getJobOrder?.id}
                  recruitingLevel={data?.getJobOrder?.recruitingLevel}
                  assignRecruiter={true}
                  adminExecutiveOwner={checkEditPermissionWithoutSharee()}
                  watchPermission={checkEditPermission()}
                  parallelTeamUsers={
                    parallelTeamUsers?.findingParallelTeamUsers
                  }
                />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="5">
            <JobOrderClientDocument jobOrderId={jobOrderId} />
          </TabPane>

          <TabPane tabId="6" className="tab-pane-padding">
            <JobApplicantTab jobOrder={data?.getJobOrder} isEdit />
          </TabPane>

          <TabPane tabId="7" className="tab-pane-padding">
            <Attachments
              haveActionPersmissions={!checkEditPermission()}
              companyId={data?.getJobOrder?.company?.id}
              attachableType={'Company'}
            />{' '}
          </TabPane>

          <TabPane tabId="8" className="tab-pane-padding">
            <SubmissionHistoryCandidate
              jobOrderId={data?.getJobOrder?.id}
              location={location}
              isEdit
            />
          </TabPane>

          {checkUserFeatureAccess(user?.id, 'JOB_LIST', settingsData) &&
            <TabPane tabId="9" className="tab-pane-padding">
              <JobListNotes
                jobOrderId={data?.getJobOrder?.id}
              />
            </TabPane>}
        </TabContent>

        {activeTab === '2' && (
          <div className="text-right pt-0 p-4">
            <CustomButton
              buttonText="Update"
              buttonColor="primary"
              buttonType="submit"
              className="big-width"
              loading={waiting}
              buttonClick={handleSubmit(updateJobOrder)}
              disabled={!checkEditPermission()}
            />
          </div>
        )}
      </div>

      <Modal
        isOpen={confirmToggle}
        toggle={() => setConfirmToggle(!confirmToggle)}
        className="revampDialog revampDialogWidth"
      >
        <ModalHeader toggle={() => setConfirmToggle(!confirmToggle)}>
          Clone {data?.getJobOrder?.jobTitle}
        </ModalHeader>
        <Form>
          <ModalBody>
            <Label>Company</Label>
            <AsyncCompanyDropdown
              havingOwner={true}
              width={'100%'}
              value={{
                label: selectedCompanyName,
                value: selectedCompanyId,
              }}
              placeholder="Filter By Company name"
              onChange={(value) => {
                setSelectedCompanyId(value?.value);
                setSelectedCompanyName(value?.label);
              }}
            />
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              onClick={() => setConfirmToggle(!confirmToggle)}
              className="buttonPrimary mr-3"
            >
              Cancel
            </button>
            <CustomButton
              buttonText="Clone"
              disabled={!selectedCompanyId || duplicating}
              buttonColor="primary"
              buttonType="button"
              className="big-width"
              buttonClick={() => setCloneModal(true)}
            />
          </ModalFooter>
        </Form>
      </Modal>

      <AddNotesModal
        toggleActivityNotes={toggleActivityNotes}
        activityNotes={activityNotes}
        noteableId={jobOrderId}
        reFetch={() => _getNotes()}
        type="Job Order"
        editData={noteData}
        resetField={setNoteData}
      />

      <ConfirmPopUp
        confirmText="Are you sure you want to clone this job order?"
        isOpen={cloneModal}
        toggle={() => setCloneModal(!cloneModal)}
        confirmAction={duplicateJobOrder}
        modalHeading="Clone This Job Order"
        btnText="Clone"
        btnColor="primary"
        className="revampDialog revampDialogWidth"
      />
    </div>
  );
};

export default UpdateJobOrder;
