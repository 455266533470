import { useLazyQuery, useMutation } from "@apollo/client";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { Badge, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input, InputGroup, InputGroupAddon, InputGroupText } from "reactstrap";
import DELETE_ICON from "../../assets/images/delete-icon-menu.svg";
import EDIT_ICON from '../../assets/images/edit-icon-menu.svg';
import SEARCH_IMG from '../../assets/images/search.svg';
import ConfirmPopUp from "../../components/ConfirmPopUp";
import GoBack from "../../components/GoBack";
import InputField from "../../components/layout/InputField";
import Loader from "../../components/layout/Loader";
import NoDataFound from "../../components/layout/NoDataFound";
import Pagination from "../../components/layout/Pagination";
import TableComponent from "../../components/layout/TableComponent";
import { ADD_SYNONYM, SYNONYMS } from "../../constants";
import { ISkillDocument } from "../../interfaces";
import { JOB_TITLE_SYNONYMS, PAGE_LIMIT, SETTINGS_ROUTE, SKILL_SYNONYMS, SKILL_SYNONYMS_TABLE_HEADER, SYNONYMS_TABLE_HEADER, synonymsType } from "../../utils/constant";
import DrawerComponent from "./Drawer";
import { DELETE_SYNONYMS, GET_JOB_TITLE_SYNONYMS, GET_SKILL_SYNONYMS } from "./gql";

const Synonyms: FC = () => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(10);
  const [searchParams, setSearchParams] = useState<string>('');
  const prevSearchParams = useRef<string>(""); // Ref to store previous searchParams
  const [addSynonym, setAddSynonym] = useState<boolean>(false);
  const [isEditButton, setIsEditButton] = useState<boolean>(false);
  const [skillsList, setSkillsList] = useState<{ synonyms: ISkillDocument[] }>({ synonyms: [] });
  const [jobTitleList, setJobTitleList] = useState<{ synonyms: ISkillDocument[] }>({ synonyms: [] });
  const [synonymData, setSynonymData] = useState<ISkillDocument>();
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  // dropdown states
  const [categoryDropDown, setCategoryDropdownOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(synonymsType[1]); // Set the second category as the default
  // delete api
  const [deleteSynonyms] = useMutation(DELETE_SYNONYMS);

  // dropdown functions
  const toggleDropdownCategory = () => {
    setCategoryDropdownOpen(!categoryDropDown);
  };

  const handleCategoryChange = (value) => {
    setSelectedCategory(value);
    setCurrentPage(1);
    setLimit(10);
    setSearchParams('');
  };

  // api calls
  const [getSkillSynonyms, { data: skillData, loading: skillSynonymsLoading, refetch: refetchSkillData }] =
    useLazyQuery(GET_SKILL_SYNONYMS);
  const [getJobTitleSynonyms, { data: jobTitleData, loading: jobTitleSynonymsLoading, refetch: refetchJobTitleData }] =
    useLazyQuery(GET_JOB_TITLE_SYNONYMS);

  const fetchSkillList = useCallback(async () => {
    await getSkillSynonyms({
      variables: {
        payload: {
          page: currentPage,
          limit,
          search: searchParams,
        }
      }
    });
  }, [currentPage, getSkillSynonyms, limit, searchParams]);

  const fetchJobTitleList = useCallback(async () => {
    await getJobTitleSynonyms({
      variables: {
        payload: {
          page: currentPage,
          limit,
          search: searchParams,
        }
      }
    });
  }, [currentPage, getJobTitleSynonyms, limit, searchParams]);

  // for calling query
  useEffect(() => {
    // Whenever a new search is initiated (searchParams is a dependency), set currentPage back to 1
    if (prevSearchParams.current !== searchParams) {
      setCurrentPage(1);
    }
    prevSearchParams.current = searchParams; // Update the previous searchParams
    if (selectedCategory?.value === JOB_TITLE_SYNONYMS) {
      fetchJobTitleList();
    } else if (selectedCategory?.value === SKILL_SYNONYMS) {
      fetchSkillList();
    }
  }, [searchParams, selectedCategory, fetchJobTitleList, fetchSkillList, currentPage]);

  // for setting data in state
  useEffect(() => {
    if (!skillSynonymsLoading && skillData) {
      setSkillsList(skillData?.getSkillSynonyms || []);
    }
    if (!jobTitleSynonymsLoading && jobTitleData) {
      setJobTitleList(jobTitleData?.getJobTitleSynonyms || []);
    }
  }, [jobTitleData, jobTitleSynonymsLoading, skillData, skillSynonymsLoading]);

  const onAddSynonym = () => {
    setAddSynonym(!addSynonym);
  };

  const handleDelete = async () => {
    if (selectedCategory?.value === JOB_TITLE_SYNONYMS) {
      const res = await deleteSynonyms({
        variables: {
          deleteSynonymsInput: {
            id: synonymData?.id,
            indexType: 'job-title',
          }
        }
      });

      if (res?.data) {
        toast.success('Synonym key deleted successfully.');
        refetchJobTitleData();
        setDeleteModal(false);
      } else {
        toast.error('Unable to delete synonym key.');
      }

    } else if (selectedCategory?.value === SKILL_SYNONYMS) {
      const res = await deleteSynonyms({
        variables: {
          deleteSynonymsInput: {
            id: synonymData?.id,
            indexType: 'skill',
          }
        }
      });

      if (res?.data) {
        toast.success('Synonym key deleted successfully.');
        refetchSkillData();
        setDeleteModal(false);
      } else {
        toast.error('Unable to delete synonym key.');
      }
    }
  }

  return (
    <>
      <div className="admin-tabs team-tabs">
        <GoBack url={SETTINGS_ROUTE} />

        <div className="primaryHeaderSpacing primaryHeading">
          <h5 className="m-0">{SYNONYMS}</h5>
        </div>

        <div className="search-filter accountingDashboardFilter primaryHeaderSpacing mr-3">
          <div className="d-flex align-items-center gap-10">
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <img src={SEARCH_IMG} alt="search" />
                </InputGroupText>
              </InputGroupAddon>

              <Input
                type="text"
                placeholder="Search by terms"
                value={searchParams}
                onChange={(e) => setSearchParams(e?.target?.value)}
                className="search-by-company"
              />
            </InputGroup>

            <Dropdown isOpen={categoryDropDown} toggle={toggleDropdownCategory} className="actionsDropdown dropdown-width-class">
              <DropdownToggle caret>
                {selectedCategory?.label}
              </DropdownToggle>

              <DropdownMenu>
                {synonymsType?.map(category => (
                  <DropdownItem
                    key={category?.value}
                    onClick={() => { handleCategoryChange(category) }}
                    className="cursor-pointer m-0"
                  >
                    {category?.label}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
          </div>

          <button
            className="buttonGenericStyle filledButton ml-3"
            onClick={() => {
              onAddSynonym();
              setIsEditButton(false);
              setSynonymData(undefined);
            }
            }>
            {ADD_SYNONYM}
          </button>
        </div>

        <div className="mr-3">
          <TableComponent tableHeader={selectedCategory?.value === JOB_TITLE_SYNONYMS ? SYNONYMS_TABLE_HEADER : SKILL_SYNONYMS_TABLE_HEADER}>
            {skillSynonymsLoading || jobTitleSynonymsLoading ? (
              <tbody>
                <tr>
                  <td colSpan={14}>
                    <Loader loaderClass="sm-height" />
                  </td>
                </tr>
              </tbody>
            ) : (
              <tbody className="request-table synonym-table-row">
                {selectedCategory?.value === JOB_TITLE_SYNONYMS ? (
                  jobTitleList?.synonyms?.length ? (
                    jobTitleList?.synonyms?.map((item: ISkillDocument, index: number) => {
                      const { name, value } = item;
                      return (
                        <tr key={index}>
                          <td className="text-truncate max-200">{name}</td>
                          <td className="badge-padding">
                            <div className="d-inline-flex align-items-center flex-wrap gap-10">
                              {value?.map((synonym: string, idx: number) => (
                                <Badge
                                  key={idx}
                                  className="synonym-badge request-badge"
                                >
                                  <span className="text-break text-wrap">{synonym}</span>
                                </Badge>
                              ))}
                            </div>
                          </td>
                          <td>
                            <div className="d-flex gap-20">
                              <button
                                className="iconButtonAction"
                                onClick={() => {
                                  onAddSynonym();
                                  setIsEditButton(true);
                                  setSynonymData(item);
                                }}
                              >
                                <img src={EDIT_ICON} alt="Edit" />
                              </button>

                              <button
                                className="iconButtonAction"
                                onClick={() => {
                                  setDeleteModal(true);
                                  setSynonymData(item);
                                }}
                              >
                                <img src={DELETE_ICON} alt="Edit" />
                              </button>
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td
                        colSpan={SYNONYMS_TABLE_HEADER.length}
                        className="empty-table-td"
                      >
                        <NoDataFound text="No Job Title Synonyms Found!" />
                      </td>
                    </tr>
                  )
                ) : (
                  skillsList?.synonyms?.length ? (
                    skillsList?.synonyms?.map((item: ISkillDocument, index: number) => {
                      const { name, value } = item;
                      return (
                        <tr key={index}>
                          <td className="text-truncate max-200">{name}</td>
                          <td className="badge-padding">
                            <div className="d-inline-flex align-items-center flex-wrap gap-10">
                              {value?.map((synonym: string, idx: number) => (
                                <Badge
                                  key={idx}
                                  className="synonym-badge request-badge"
                                >
                                  <span className="text-break text-wrap">{synonym}</span>
                                </Badge>
                              ))}
                            </div>
                          </td>
                          <td>
                            <div className="d-flex gap-20">
                              <button
                                className="iconButtonAction"
                                onClick={() => {
                                  onAddSynonym();
                                  setIsEditButton(true);
                                  setSynonymData(item);
                                }}
                              >
                                <img src={EDIT_ICON} alt="Edit" />
                              </button>

                              <button
                                className="iconButtonAction"
                                onClick={() => {
                                  setDeleteModal(true);
                                  setSynonymData(item);
                                }}
                              >
                                <img src={DELETE_ICON} alt="Edit" />
                              </button>
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td
                        colSpan={SKILL_SYNONYMS_TABLE_HEADER.length}
                        className="empty-table-td"
                      >
                        <NoDataFound text="No Skill Synonyms Found!" />
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            )}
          </TableComponent>

          {selectedCategory?.value === JOB_TITLE_SYNONYMS && jobTitleData?.getJobTitleSynonyms?.count ? (
            <div className="users-pagination">
              {/* Job Title Synonyms Pagination */}
              <Pagination
                onPageChange={(pageClicked: number) => {
                  setCurrentPage(pageClicked);
                }}
                pageCount={Math.ceil(jobTitleData?.getJobTitleSynonyms?.count / limit)}
                currentPage={currentPage}
              />

              {/* Job Title Synonyms Pagination Display */}
              <div className="d-flex align-items-center">
                <div className="pagination-display paginationItemsSelect">
                  <p className="m-0">
                    {(currentPage - 1) * limit + 1} to{" "}
                    {Math.min(currentPage * limit, jobTitleData?.getJobTitleSynonyms?.count)} of {jobTitleData?.getJobTitleSynonyms?.count}
                  </p>

                  <div className="divider"></div>

                  <label>Display</label>

                  <InputField
                    label=""
                    inputtype="select"
                    selectItems={PAGE_LIMIT.filter((item) => item.value !== limit)}
                    inputid="limit"
                    placeholder={limit.toString()}
                    inputMethod={(value: string) => {
                      setCurrentPage(1);
                      setLimit(parseInt(value));
                    }}
                  />
                </div>
              </div>
            </div>
          ) : null}

          {selectedCategory?.value === SKILL_SYNONYMS && skillData?.getSkillSynonyms?.count ? (
            <div className="users-pagination">
              {/* Skill Synonyms Pagination */}
              <Pagination
                onPageChange={(pageClicked: number) => {
                  setCurrentPage(pageClicked);
                }}
                pageCount={Math.ceil(skillData?.getSkillSynonyms?.count / limit)}
                currentPage={currentPage}
              />

              {/* Skill Synonyms Pagination Display */}
              <div className="d-flex align-items-center">
                <div className="pagination-display paginationItemsSelect">
                  <p className="m-0">
                    {(currentPage - 1) * limit + 1} to{" "}
                    {Math.min(currentPage * limit, skillData?.getSkillSynonyms?.count)} of {skillData?.getSkillSynonyms?.count}
                  </p>

                  <div className="divider"></div>

                  <label>Display</label>

                  <InputField
                    label=""
                    inputtype="select"
                    selectItems={PAGE_LIMIT.filter((item) => item.value !== limit)}
                    inputid="limit"
                    placeholder={limit.toString()}
                    inputMethod={(value) => {
                      setCurrentPage(1);
                      setLimit(parseInt(value));
                    }}
                  />
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>

      <DrawerComponent
        selectedCategory={selectedCategory}
        setSynonymData={setSynonymData}
        synonymData={synonymData}
        isEditButton={isEditButton}
        toggle={onAddSynonym}
        isOpen={addSynonym}
        refetchSkillData={refetchSkillData}
        refetchJobTitleData={refetchJobTitleData}
      />

      <ConfirmPopUp
        confirmText="Are you sure you want to delete this synonym?"
        isOpen={deleteModal}
        toggle={() => setDeleteModal(!deleteModal)}
        confirmAction={handleDelete}
        modalHeading="Delete Action"
        btnText="Delete"
        btnColor="primary"
        className="revampDialog revampDialogWidth"
      />
    </>
  )
}

export default Synonyms;
