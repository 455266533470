// packages block
import { useCallback, useState } from 'react';
import { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
//components block
import ReviewWarnings from './ReviewWarnings'
import GoBack from '../../components/GoBack';
//constants, utils and graphql block
import { SETTINGS_ROUTE } from '../../utils/constant';
import { useLazyQuery } from '@apollo/client';
import { FETACH_ALL_ALERTS_OR_WARNINGS } from './gql';

const AllAlertOrWarnings = ({ location, history }: RouteComponentProps): JSX.Element => {
  //Warnings
  const [fetchAllAlertsOrWarnings, { data: warningData, loading: warningLoading }] = useLazyQuery(
    FETACH_ALL_ALERTS_OR_WARNINGS,
    {
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,
      onError({ message }) {
        console.log('Notifications error =>', message);
      },
    }
  );

  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState<number>(1);


  const warningsHandler = useCallback(async (filter?: { name?: string }) => {
    await fetchAllAlertsOrWarnings({
      variables: {
        getAllSystemAlertsInput: {
          limit,
          page: page,
          filter
        }
      },
    });

  }, [fetchAllAlertsOrWarnings, limit, page])

  useEffect(() => {
    warningsHandler()
  }, [warningsHandler])

  return (
    <div className="admin-tabs team-tabs">
      <GoBack url={SETTINGS_ROUTE} />

      <div className="priamryHeading primaryHeaderSpacing mobileResponsiveFlexAlign justify-content-between">
        <h5 className="m-0">Warnings</h5>
      </div>

      <ReviewWarnings
        notificationHandler={warningsHandler}
        limit={limit}
        setLimit={setLimit}
        setPage={setPage}
        page={page}
        loading={warningLoading}
        data={warningData}
      />

    </div>
  );
};

export default AllAlertOrWarnings;