import { useLazyQuery, useMutation } from "@apollo/client";
import { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { toast } from "react-toastify";

import {
  Button,
  Col,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import ConfirmPopUp from "../../../components/ConfirmPopUp";

import NoDataFound from "../../../components/layout/NoDataFound";
import TableComponent from "../../../components/layout/TableComponent";
import { CandidateEducationList, MonthFull } from "../../../utils/constant";

import "../candidate.scss";
import {
  CREATE_EDUCATION_HISTORY,
  DELETE_EDUCATION_HISTORY,
  GET_EDUCATION_HISTORY,
  UPDATE_EDUCATION_HISTORY,
} from "../gql";
import InputField from "../../../components/layout/InputField";

import EDIT_ICON from "../../../assets/images/edit-icon-menu.svg";
import DELETE_ICON from "../../../assets/images/delete-icon-menu.svg";
import { calculateMonthValue } from "../../../utils/commonFn";
import { AppContext } from "../../../context";
import { customSelectDropDown } from "../../../components/styled/customSelect";

const EDUCATION_HEADER = [
  "School Name",
  "Degree Earned",
  "Field Of Study",
  "Start Date",
  "End Date",
  "ACTIONS",
];

const CandidateEducation = ({ candidateId, addEducation = true }: any) => {
  const { theme } = useContext(AppContext)
  const [yearsList, setYearsList] = useState<Array<any>>([]);
  const [modal, setModal] = useState(false);
  const modalToggle = () => setModal(!modal);
  const [educationId, setEducationId] = useState<string>("");
  const [confirmPopup, setConfirmPopUp] = useState(false);
  const [editEducation, setEditEducation] = useState<any>(null);

  const [_createEducation, { loading }] = useMutation(CREATE_EDUCATION_HISTORY);

  const [_updateEducation, { loading: waiting }] = useMutation(
    UPDATE_EDUCATION_HISTORY
  );
  const [_deleteEducation] = useMutation(DELETE_EDUCATION_HISTORY);

  const [getEducationHistory, { data }] = useLazyQuery(GET_EDUCATION_HISTORY);

  const { errors, handleSubmit, register, control, reset, watch } = useForm();

  const createCandidateEducation = async (values) => {
    values.startMonth = values.startMonth?.value;
    values.endMonth = values.endMonth?.value;
    values.startYear = values.startYear?.value;
    values.endYear = values.endYear?.value;
    values.degreeEarned = values.degreeEarned?.value;

    if (editEducation) {
      values.educationHistoryId = editEducation?.id;
      await _updateEducation({
        variables: { updateEducation: { ...values } },
      });
    } else {
      values.candidateId = candidateId;
      await _createEducation({
        variables: { createEduction: { ...values } },
      });
    }
    toast.success("Successfully Created!");
    modalToggle();
    getEducationList();
  };

  const deleteEducation = async () => {
    let res = await _deleteEducation({ variables: { educationId } });
    if (res.data) {
      toast.success("Successfully Deleted!");
      setConfirmPopUp(false);
      getEducationList();
    }
  };
  const getEducationList = () => {
    if (candidateId) {
      getEducationHistory({ variables: { candidateId } });
    }
  };

  useEffect(() => {
    if (candidateId) {
      getEducationHistory({ variables: { candidateId } });
    }
    // eslint-disable-next-line
  }, [candidateId]);

  const years = () => {
    var currentYear = new Date().getFullYear();
    let yearArr: any = [];
    let startYear = 1980;
    while (startYear <= currentYear) {
      yearArr.push(startYear++);
    }
    setYearsList(yearArr);
  };

  useEffect(() => {
    years();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (editEducation) {
      let values: any = { ...editEducation }; // eslint-disable-next-line
      values.schoolName = values?.schoolName; // eslint-disable-next-line
      values.fieldOfStudy = values?.fieldOfStudy;
      values.startYear = values?.startYear
        ? {
            label: values?.startYear,
            value: values?.startYear,
          }
        : null;
      values.endYear = values?.endYear
        ? {
            label: values?.endYear,
            value: values?.endYear,
          }
        : null;
      values.startMonth = values?.startMonth
        ? {
            label: values?.startMonth,
            value: values?.startMonth,
          }
        : null;
      values.endMonth = values?.endMonth
        ? {
            label: values?.endMonth,
            value: values?.endMonth,
          }
        : null;
      values.degreeEarned =
        CandidateEducationList?.find(
          (item) => item.value === values?.degreeEarned
        ) || null;
      reset({ ...values });
    }
    // eslint-disable-next-line
  }, [editEducation]);

  return (
    <div>
      {addEducation && (
        <div className="pb-3 d-flex justify-content-end">
          <button
            type="button"
            className="buttonGenericStyle filledButton"
            onClick={() => {
              setEditEducation(null);
              reset({});
              modalToggle();
            }}
          >
            Add New Education
          </button>
        </div>
      )}

      <div className="table-responsive">
        <TableComponent tableHeader={EDUCATION_HEADER}>
          <tbody>
            {data?.getEducationHistory?.map(
              (item: {
                id: string;
                schoolName: string;
                degreeEarned: string;
                fieldOfStudy: string;
                startMonth: string;
                startYear: string;
                endMonth: string;
                endYear: string;
              }) => (
                <tr key={item?.id}>
                  <td>{item?.schoolName || "--"}</td>
                  <td>{item?.degreeEarned || "--"}</td>
                  <td>{item?.fieldOfStudy || "--"}</td>

                  <td>
                    {calculateMonthValue(item?.startMonth)}/{item?.startYear}
                  </td>
                  <td>
                    {calculateMonthValue(item?.endMonth)}/{item?.endYear}
                  </td>

                  <td className="pl-1">
                    <div className="d-flex">
                      <Button
                        size="sm"
                        className="border-0 bg-transparent color-default"
                        onClick={() => {
                          setEditEducation(item);
                          modalToggle();
                        }}
                      >
                        <img src={EDIT_ICON} alt="edit" className="m-0" />
                      </Button>
                      <Button
                        size="sm"
                        className="border-0 bg-transparent color-default"
                        onClick={() => {
                          setConfirmPopUp(!confirmPopup);
                          setEducationId(item?.id);
                        }}
                      >
                        <img src={DELETE_ICON} alt="edit" className="m-0" />
                      </Button>
                    </div>
                  </td>
                </tr>
              )
            )}
            {!data?.getEducationHistory?.length && (
              <tr>
                <td colSpan={6} className="empty-table-td">
                  <NoDataFound text="No Candidate Education History Found!" />
                </td>
              </tr>
            )}
          </tbody>
        </TableComponent>
      </div>

      {/* add candidate education input */}
      <Modal
        isOpen={modal}
        toggle={modalToggle}
        className="revampDialog revampDialogWidth"
      >
        <ModalHeader toggle={modalToggle}>
          {editEducation ? "Edit Education History" : "Education History"}
        </ModalHeader>
        <ModalBody>
          <Form>
            <div>
              <div className="user-basic-form">
                <Row>
                  <Col md="6" sm="12">
                    <InputField
                      inputtype="text"
                      isRequired={true}
                      label="School Name"
                      inputid="schoolName"
                      placeholder="School Name"
                      inputRef={register({
                        required: {
                          value: true,
                          message: "School name is required!",
                        },
                      })}
                      errMsg={errors?.schoolName?.message}
                    />
                  </Col>
                  <Col md="6" sm="12">
                    <FormGroup>
                      <Label>
                        Degree Earned <span className="text-danger">*</span>
                      </Label>
                      <Controller
                        name="degreeEarned"
                        as={Select}
                        options={CandidateEducationList}
                        placeholder="Degree Earned"
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: "Degree earned must be selected!",
                          },
                        }}
                        styles={{ ...customSelectDropDown(theme) }}
                      />
                      <small className="text-danger">
                        {errors?.degreeEarned?.message}
                      </small>
                    </FormGroup>
                  </Col>
                  <Col md="12" sm="12">
                    <InputField
                      inputtype="text"
                      isRequired={true}
                      label="Field Of Study"
                      inputid="fieldOfStudy"
                      placeholder="Field Of Study"
                      inputRef={register({
                        required: {
                          value: true,
                          message: "Field of study is required!",
                        },
                      })}
                      errMsg={errors?.fieldOfStudy?.message}
                    />
                  </Col>
                  <Col md="6" sm="12">
                    <FormGroup>
                      <Label>
                        Start Month <span className="text-danger">*</span>
                      </Label>
                      <Controller
                        name="startMonth"
                        as={Select}
                        options={MonthFull?.map((item) => ({
                          label: item,
                          value: item,
                        }))}
                        placeholder="Start Month"
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: "Start month must be selected!",
                          },
                        }}
                        styles={{ ...customSelectDropDown(theme) }}
                      />
                      <small className="text-danger">
                        {errors?.startMonth?.message}
                      </small>
                    </FormGroup>
                  </Col>

                  <Col md="6" sm="12">
                    <FormGroup>
                      <Label>
                        Start Year <span className="text-danger">*</span>
                      </Label>
                      <Controller
                        name="startYear"
                        as={Select}
                        options={yearsList?.map((item) => ({
                          label: `${item}`,
                          value: `${item}`,
                        }))}
                        placeholder="Start Year"
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: "Start year must be selected!",
                          },
                        }}
                        styles={{ ...customSelectDropDown(theme) }}
                      />
                      <small className="text-danger">
                        {errors?.startYear?.message}
                      </small>
                    </FormGroup>
                  </Col>

                  <Col md="6" sm="12">
                    <FormGroup>
                      <Label>
                        End Month <span className="text-danger">*</span>
                      </Label>
                      <Controller
                        name="endMonth"
                        as={Select}
                        options={MonthFull?.map((item) => ({
                          label: item,
                          value: item,
                        }))}
                        placeholder="End Month"
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: "End month must be selected!",
                          },
                        }}
                        styles={{ ...customSelectDropDown(theme) }}
                      />
                      <small className="text-danger">
                        {errors?.endMonth?.message}
                      </small>
                    </FormGroup>
                  </Col>

                  <Col md="6" sm="12">
                    <FormGroup>
                      <Label>
                        End Year <span className="text-danger">*</span>
                      </Label>
                      <Controller
                        name="endYear"
                        as={Select}
                        options={yearsList?.map((item) => ({
                          label: `${item}`,
                          value: `${item}`,
                        }))}
                        placeholder="End Year"
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: "End year must be selected!",
                          },
                          validate: (value) =>
                            value?.value > watch("startYear")?.value ||
                            "End year should be greater than start year.",
                        }}
                        styles={{ ...customSelectDropDown(theme) }}
                      />
                      <small className="text-danger">
                        {errors?.endYear?.message}
                      </small>
                    </FormGroup>
                  </Col>
                </Row>
              </div>
              <div className="d-flex align-items-center justify-content-end py-3">
                <button
                  onClick={() => {
                    modalToggle();
                    reset({});
                    setEditEducation(null);
                  }}
                  type="button"
                  className="buttonPrimary mr-3"
                >
                  Cancel
                </button>
                <Button
                  color="primary"
                  size="lg"
                  type="submit"
                  onClick={handleSubmit(createCandidateEducation)}
                  disabled={loading || waiting}
                >
                  {loading ||
                    (waiting && <Spinner size="sm" className="mr-2" />)}
                  {editEducation ? "Update" : "Create"}
                </Button>
              </div>
            </div>
          </Form>
        </ModalBody>
      </Modal>

      <ConfirmPopUp
        confirmText="Are you sure, you want to delete this record?"
        isOpen={confirmPopup}
        toggle={setConfirmPopUp}
        confirmAction={deleteEducation}
        modalHeading="Delete Education"
        btnText="Delete"
        btnColor="primary"
        className="revampDialog"
      />
    </div>
  );
};

export default CandidateEducation;
