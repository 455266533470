export const companyAndAddressErrors = (company) => {
  let err: any = [];

  const filterPrimaryAddress = company?.address?.find(
    (item) => item.isPrimary === true
  );
  // Checking companyContact
  if (!company?.companyContacts?.length) {
    err.push({
      type: "contact",
      message: "Company contact does not exists",
    });
  }

  // Checking other fields of company
  if (!company?.careerPageURL && !company?.hasCareerPageURL) {
    err.push({
      type: "careerPageURL",
      message: "Career page URL does't exist!",
    });
  }
  if (!company?.summary) {
    err.push({
      type: "summary",
      message: "Summary does't exist!",
    });
  }

  if (company?.hasLinkedIn) {
    if (!company?.linkedInUrl) {
      err.push({
        type: "linkedInUrl",
        message:
          "LinkedInUrl does't exist, although hasLinkedIn marked as true",
      });
    }
  }

  if (!(company?.address?.length && filterPrimaryAddress)) {
    err.push({
      type: "streetAddress",
      message: "Company does not have primary address!",
    });
  }

  return err;
};
