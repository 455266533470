import { useContext } from "react";
import { AppContext } from "../context";
import AsyncSelect from "react-select/async";
import { SelectorOptionType } from "../interfaces";

const AsyncGeneralSelect = (props) => {
  const { theme } = useContext(AppContext);

  const promiseOptions = (inputValue: string) =>
    new Promise((resolve) => {
      resolve(props._filter(inputValue, props.specificId));
    });


  const customStyles = {
    control: (styles, provided) => ({
      ...styles,
      width: props.width,
      background: theme === "light" ? provided.background : "#282828",
      borderColor: theme === "light" ? "#ced4da" : "#282828",
      color: theme === "light" ? provided.background : "#d9d9d9",
      fontSize: 12,
      "&:hover": {
        borderColor: theme === "light" ? "#ced4da" : "#282828",
        color: theme === "light" ? provided.background : "#d9d9d9",
      },
    }),
    menu: (provided) => ({
      ...provided,
      background: theme === "light" ? provided.background : "#282828",
      zIndex: props?.zIndex || 999999,
      color: theme === "light" ? provided.background : "#d9d9d9",
      fontSize: 12,
    }),
    option: (provided, state) => ({
      ...provided,
      fontSize: 12,
      background: theme === "light" ? provided.background : "#363636",
      "&:hover": {
        background: theme === "light" ? provided.background : "#474444",
        color: theme === "light" ? provided.background : "#d9d9d9",
      },
    }),
    container: (provided, state) => ({
      ...provided,
      fontSize: 12,
    }),
  };

  return (
    <AsyncSelect
      // cacheOptions
      isClearable
      isSearchable={props?.isSearchable}
      isLoading={props?.isLoading}
      isDisabled={props?.isDisabled || false}
      value={props?.value?.value ? props?.value : null}
      ref={props.ref}
      styles={{ ...customStyles }}
      {...props}
      defaultOptions={props?.options}
      onChange={
        (selected: SelectorOptionType) => {
          if (props.onSelected) {
            if (selected) {
              props.onSelected(selected);
            } else {
              props.onSelected({});
            }
          }
          if (selected) {
            // setSelectValue(selected);
            props.onChange(selected);
          } else {
            props.onChange("");
          }
        }
      }
      loadOptions={promiseOptions}
      placeholder={props?.placeholder}
    />
  )

}

export default AsyncGeneralSelect;