import { gql } from "@apollo/client";

// queries
export const GET_ALL_REPORTS = gql`
  query GetAllReports{
    getAllReports
     {
      reportsTeam {
        id
        name
        category
        link
      }
      reportsRecruiter {
        id
        name
        category
        link
      }
      reportsCompany {
        id
        name
        category
        link
      }
      reportsExecutiveAndHr {
        id
        name
        category
        link
      }
      
    }
  }
`;

// inputs - not required (defined on backend)
export const CreateReportInput = gql`
  input CreateReportInput {
    name: String,
    category: String,
    link: String,
  }
`;

export const UpdateReportInput = gql`
  input UpdateReportInput {
    id: String,
    name: String,
      link: String,
      category: String,
    }
  `;

  // mutations
  export const CREATE_REPORT = gql`
  mutation CreateReport ($input: CreateReportInput!) {
    createReport(createReportInput: $input) {
      report {
      id
      name
      link
      category
    }
    message
    status
  }
}
`;

export const UPDATE_REPORT = gql`
  mutation UpdateReport($input: UpdateReportInput!) {
    updateReport(updateReportInput: $input) {
      message,
      status
    }
  }
`;

export const DELETE_REPORT = gql`
  mutation DeleteReport($input: DeleteReportInput!) {
    deleteReport(deleteReportInput: $input) {
      message,
      status
    }
  }
`;