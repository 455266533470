import { useMutation } from "@apollo/client";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Form,
  Input,
} from "reactstrap";
import GoBack from "../../components/GoBack";
import SocialMediaInfo from "../../components/SocialMediaInfo";
import CustomButton from "../../components/layout/CustomButton";
import { AppContext } from "../../context";
import { EMAIL_PATTERN } from "../../utils/constant";
import { IComingState } from "../agreements/interfaces";
import "../company/company.scss";
import BasicContactInfo from "./contact-components/BasicCotactInfo";
import ContactEmail from "./contact-components/ContactEmail";
import ContactPhone from "./contact-components/ContactPhone";
import { CREATE_COMPANY_CONTACT } from "./gql";

const CreateContact = () => {
  const [isPrivate, setIsPrivate] = useState<boolean>(false);
  const { userRoles } = useContext(AppContext);
  const {
    errors,
    handleSubmit,
    register,
    reset,
    control,
    getValues,
    setValue,
  } = useForm();

  const history = useHistory();
  const location = useLocation<IComingState>();

  const comingState = location.state;

  const [createCompanyContact, { loading }] = useMutation(CREATE_COMPANY_CONTACT);
  const [emailErr, setEmailErr] = useState("");
  const [phoneErr, setPhoneErr] = useState("");
  // const [activeTab, setActiveTab] = useState("1");

  const [customEmailInputs, setCustomEmailInputs] = useState<any>([
    {
      email: "",
      isPrimary: true,
    },
  ]);

  const [customPhoneInputs, setCustomPhoneInputs] = useState<any>([
    {
      phone: "",
      phoneType: "",
      isPrimary: true,
    },
  ]);

  useEffect(() => {
    document.title = `Create Contact - RealREPP`;

    // eslint-disable-next-line
  }, []);

  const createContact = async (values: any) => {
    let emails = customEmailInputs.filter(
      (item: { email: string }) => item.email && item
    );
    const hasInvalidEmails = emails?.some(item => {
      const emailReg = new RegExp(EMAIL_PATTERN);
      return item.email && !emailReg.test(item?.email);
    });
    // If any invalid emails are found, set the email error state
    if (hasInvalidEmails) {
      setEmailErr('Email is not valid!');
      return;
    }
    const notIsPrimary = emails?.find(
      (item: { isPrimary: boolean }) => item.isPrimary
    );
    // if (!emails?.length) {
    //   setEmailErr("Minimum one email is required");
    //   return;
    // } else
    if (emails?.length && !notIsPrimary) {
      setEmailErr("Minimum one email should be primary");
      return;
    }
    values.contactEmails = emails;
    let phones = customPhoneInputs.filter(
      (item: { phone: string }) => item.phone && item
    );
    const notIsPrimaryPhone = phones?.find(
      (item: { isPrimary: boolean }) => item.isPrimary
    );
    // if (!phones?.length) {
    //   setPhoneErr("Minimum one phone is required");
    //   return;
    // }
    if (phones?.length && !notIsPrimaryPhone) {
      setPhoneErr("Minimum one phone should be primary");
      return;
    }
    values.contactPhones = phones;
    values.isPrivate = isPrivate;
    values.companyId = values?.companyId?.value;
    values.locationId = values?.locationId?.value;
    let res = await createCompanyContact({
      variables: { createContactInput: { ...values } },
    });
    if (res?.data) {
      toast.success("Contact successfully created!");
      reset();
      history.push("/contacts");
    } else if (res?.errors && res?.errors?.length > 0) {
      toast.error(res?.errors[0]?.message);
    }
  };

  // const toggleTab = (tab: string) => {
  //   if (activeTab !== tab) {
  //     setActiveTab(tab);
  //   }
  // };

  return (
    <div className="admin-tabs team-tabs">
      <GoBack url="/contacts" search={comingState?.searchParameters} />

      <div className="mobileResponsiveFlexAlign justify-content-between primaryHeaderSpacing">
        <h5 className="m-0">Add Contacts</h5>

        {userRoles?.includes("EXECUTIVE") ? (
          <div className="d-flex align-items-center make-private">
            <span className="mr-3 switchLabel">Make it private?</span>
            <label className="switch-contact">
              <Input
                type="checkbox"
                name="isPrivate"
                checked={isPrivate}
                onChange={() => setIsPrivate(!isPrivate)}
              />
              <span className="slider"></span>
            </label>
          </div>
        ) : null}
      </div>

      <div className="jobOrderRevamp">
        <Form onSubmit={handleSubmit(createContact)} className="add-contacts-form">
          <h5>Basic Info</h5>
          <BasicContactInfo
            control={control}
            register={register}
            errors={errors}
            getValues={getValues}
            reset={reset}
            setValue={setValue}
          />

          <h5>Contact Email</h5>
          <ContactEmail
            setCustomEmailInputs={setCustomEmailInputs}
            customEmailInputs={customEmailInputs}
            emailErr={emailErr}
            setEmailErr={setEmailErr}
          />

          <ContactPhone
            setCustomPhoneInputs={setCustomPhoneInputs}
            customPhoneInputs={customPhoneInputs}
            isRequired={false}
            error={phoneErr}
            setErr={setPhoneErr}
          />

          <h5 className="mt-4">Social Media</h5>
          <SocialMediaInfo
            setValue={setValue}
            register={register}
            errors={errors}
            isLinkedinRequired={false}
            isDisable={false}
          />

          <div className="d-flex align-items-center justify-content-end pt-3">
            <CustomButton
              buttonText="Create Contact"
              buttonColor="primary"
              buttonType="submit"
              className="big-width"
              loading={loading}
              disabled={loading}
            />
          </div>
        </Form>
      </div>
    </div>
  );
};

export default CreateContact;
