import { FC } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { Alert, Label } from 'reactstrap';
import CautionImage from '../../../assets/images/caution.svg';

import './style.scss';

type ALERTCARD = {
  title: string;
  subTitle: string;
  color: string;
  outSideUS?: boolean;
};

const AlertCard: FC<ALERTCARD> = ({ title, subTitle, color, outSideUS }) => {
  return (
    <div className="alert-danger-card">
    {  !outSideUS &&(<Alert color={color}>
        <div className="card-text">
          <img src={CautionImage} alt="caution" className="mr-4" />
          <h6 className="mr-3">{title}</h6>
          <Label>{subTitle}</Label>
        </div>
      </Alert>)}

      {outSideUS && (
        <Alert color={color}>
          <div className="card-text">
            <img src={CautionImage} alt="caution" className="mr-4" />
            <h6 className="mr-3">{title}</h6>
            <Label>{subTitle}</Label>
          </div>
        </Alert>
      )}
    </div>
  );
};

export default AlertCard;
