import { commaRemover, localStringMaker } from "./helper";

export const makeCandidateDate = (values: any) => {
  values.recruiterOwner = values.recruiterOwner?.value;
  values.idealWorkType = values.idealWorkType?.value;
  values.source = values.source?.value;
  values.skillsSet = values.skillsSet?.map((item) => item.label);
  values.sponsored = values.sponsored === "true" ? true : false;
  values.salaryMin = commaRemover(values?.salaryMin);
  values.salaryMax = commaRemover(values?.salaryMax);
  values.country = values.countryCode
  values.state = values.stateCode
  delete values.countryCode;
  delete values.stateCode;
  // delete values.isPrivateCandidate;

  return values;
};

export const preFillCandidateData = (data: any) => {
  if (data) {
    let values = { ...data };
    values.salaryMin = localStringMaker(values?.salaryMin);
    values.salaryMax = localStringMaker(values?.salaryMax);
    values.zipCode = values?.postalCode;
    // values.salaryType = values?.salaryType;
    values.userPhones = values?.phones?.length
      ? values?.phones?.map((item) => ({
        phone: item.phoneNumber,
        isPrimary: item.isPrimary,
        phoneType: item.phoneType,
        phoneId: item.id,
      }))
      : [
        {
          phone: "",
          phoneType: "",
          isPrimary: true,
        },
      ];


    // let state = states?.find(
    //   (item) => item.value === values?.stateCode && item
    // );
    // values.state = state?.value ?? null;
    


    // values.stateCode=values?.stateCode? { label: 'Alaska', value: 'AK' }:null;

    values.recruiterOwner = values?.recruiterOwner
      ? {
        label: values?.recruiterOwner?.fullName,
        value: values?.recruiterOwner?.id,
      }
      : null;
    // values.source = values.source ? { label: values?.source, value: values?.source } : null,
    values.sponsored = `${values.requiresSponsorship}`;
    values.skillsSet = values?.skillsSet?.map((item) => ({
      label: item?.skill.name,
      value: item.skill.id,
    }));
    values.source = values.source
      ? {
        label: values.source,
        value: values.source,
      }
      : null;
    values.idealWorkType = values.idealWorkType
      ? {
        label: values.idealWorkType,
        value: values.idealWorkType,
      }
      : null;
    values.emails = values?.emails?.length
      ? values?.emails?.map((item: any) => ({
        email: item.email,
        isPrimary: item.isPrimary ? item.isPrimary : false,
        emailId: item?.id,
      }))
      : [{ email: "", isPrimary: true, emailId: "" }];
    values.jobTitle = values?.jobTitle
      ? values?.jobTitle
      : values?.recentEmployment?.jobTitle
        ? values?.recentEmployment?.jobTitle
        : null;
    values.companyName = values?.companyName
      ? values?.companyName
      : values?.recentEmployment?.companyName
        ? values?.recentEmployment?.companyName
        : null;

    return values;
  }
};
