import { Table } from "reactstrap";
import { moneyFormatter } from "../../../../utils/helper";
import "../placement.scss";

let arr = [
  { hours: "1", profit: 0, duration: "Hourly" },
  { hours: "40", profit: 0, duration: "Weekly" },
  { hours: "80", profit: 0, duration: "Bi-Weekly" },
];

const RecruiterInfoProfitCalculations = ({
  assignedDuration,
  recruiterPerHourRate,
}) => {
  arr[0].profit = recruiterPerHourRate.toFixed(2);
  arr[1].profit = 40 * recruiterPerHourRate.toFixed(2);
  arr[2].profit = 80 * recruiterPerHourRate.toFixed(2);

  return (
    <div className="recruiter-profit-info-calculations">
      <div className="noteSection light-dark-color">
        <h5 className="shortTextWidth">Recruiter Profit</h5>
      </div>

      <div className="table-responsive">
        <Table className="profitTable">
          <thead>
            <tr>
              <th style={{ width: "30%" }}>Period</th>
              <th style={{ width: "35%" }} className="paddingCell">
                Hours
              </th>
              <th style={{ width: "35%" }}>Profit</th>
            </tr>
          </thead>

          <tbody>
            {arr?.map((item) => {
              return (
                <tr>
                  <td className="colorText">{item?.duration}</td>
                  <td className="paddingCell">{item?.hours}</td>
                  <td>{moneyFormatter(item?.profit)}</td>
                </tr>
              );
            })}

            <tr>
              <td className="colorText">Approx run of assignment</td>
              <td className="paddingCell">{assignedDuration * 40}</td>
              <td>
                {moneyFormatter(assignedDuration * 40 * recruiterPerHourRate)}
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default RecruiterInfoProfitCalculations;
