import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";

type PopProps = {
  confirmText: string;
  isOpen: boolean;
  agreementStatus?: string;
  toggle: Function;
  confirmAction: Function;
  modalHeading: string;
  extraProp?: string | null;
  cancelBtn?: boolean
  btnText?: string;
  btnColor?: string;
  secondaryText?: string;
  className?: string;
  loading?: boolean;
  disabled?: boolean;
};

const ConfirmPopUp = ({
  confirmText,
  confirmAction,
  isOpen,
  agreementStatus,
  toggle,
  modalHeading,
  btnText,
  btnColor,
  cancelBtn = true, // by default the cancel btn will be true but there are some popUps that don't require cancel
  extraProp,
  secondaryText,
  className,
  loading,
  disabled = false,
}: PopProps) => {
  return (
    <div>
      <Modal isOpen={isOpen} toggle={() => toggle()} scrollable className={(agreementStatus === "OUT_FOR_SIGNATURE" || agreementStatus === "RESENT") ? 'customModalAction' : className}>
        <ModalHeader toggle={() => toggle()}>
          <div className="d-flex">
            {modalHeading}
          </div>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col xs="12">
              {(agreementStatus !== "OUT_FOR_SIGNATURE" && agreementStatus !== "RESENT") ? <p>
                {confirmText} <b>{extraProp}</b> {secondaryText}
              </p> : <p>Please void the agreement first</p>}

            </Col>
          </Row>
        </ModalBody>
        {(agreementStatus !== "OUT_FOR_SIGNATURE" && agreementStatus !== "RESENT") && <ModalFooter>
          {cancelBtn &&
            <Button
              onClick={() => toggle()}
              size="sm"
              className="buttonPrimary mr-3"
            >
              Cancel
            </Button>}
          <button
            type="button"
            color={btnColor}
            onClick={() => {
              confirmAction();
            }}
            className="buttonGenericStyle filledButton"
            disabled={disabled}
          >
            {loading && <Spinner size="sm" className="mr-2" />}
            {btnText || "Yes, Delete"}
          </button>
        </ModalFooter>}
      </Modal>
    </div>
  );
};

export default ConfirmPopUp;
