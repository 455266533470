// packages block
import { useLazyQuery, useMutation } from '@apollo/client';
import classnames from 'classnames';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { withRouter } from 'react-router';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import {
  Badge,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Tooltip,
} from 'reactstrap';
// components block
import ActionLogsAndNotes from '../../components/ActionLogsAndNotes';
import AddNotesModal from '../../components/AddNotesModal';
import ConfirmPopUp from '../../components/ConfirmPopUp';
import GoBack from '../../components/GoBack';
import SocialMediaInfo from '../../components/SocialMediaInfo';
import AlertCard from '../../components/layout/Alert';
import CustomButton from '../../components/layout/CustomButton';
import Loader from '../../components/layout/Loader';
import ReferenceListing from '../candidate-pipeline/reference/ReferenceListing';
import AddressInfo from '../company/company-components/AddressInfo';
import Attachments from '../company/company-components/Attachments';
import ContactPhone from '../contacts/contact-components/ContactPhone';
import CandidateBasicInfo from './candidate-components/CandidateBasicInfo';
import CandidateCredential from './candidate-components/CandidateCredential';
import CandidateEducation from './candidate-components/CandidateEducation';
import CandidateEmails from './candidate-components/CandidateEmails';
import CandidateNotesModal from './candidate-components/CandidateNotesModal';
import CandidateOwnershipWriteup from './candidate-components/CandidateOwnershipWriteup';
import CandidateRecruiterModal from './candidate-components/CandidateRecruiterModal';
import CandidateResume from './candidate-components/CandidateResume';
import EmploymentHistory from './candidate-components/EmploymentHistory';
import IdealEmployment from './candidate-components/IdealEmployment';
import SubmissionHistoryCandidate from './candidate-components/SubmissionHistoryCandidate';
// utils, constants and graphql block
import TITLE_ICON from '../../assets/images/card-title.svg';
import INFO_ICON_5 from '../../assets/images/email-icon.svg';
import INFO_ICON_1 from '../../assets/images/info-icon-1.svg';
import INFO_ICON_6 from '../../assets/images/location-icon.svg';
import MODAL_CLOSE_ICON from '../../assets/images/modal-close.svg';
import INFO_ICON_4 from '../../assets/images/phone-icon.svg';
import LINKEDIN_IMG from '../../assets/images/social1.svg';
import { AppContext } from '../../context';
import {
  makeCandidateDate,
  preFillCandidateData,
} from '../../utils/candidateDateResolve';
import {
  CANDIDATES_ROUTE,
  VIEW_CANDIDATE_ROUTE,
  states_candidate_constant,
} from '../../utils/constant';
import {
  countryChecker,
  countryWiseHeaderLocation
} from '../../utils/helper';
import { IComingState } from '../agreements/interfaces';
import '../company/company.scss';
import { CHECK_NON_SOLICIT_COMPANY, GET_NOTES } from '../company/gql';
import CandidateSubmitDrawer from './application-flow/submission/CandidateSubmitDrawer';
import JobApplicationStatus from './candidate-components/JobApplicationStatus/JobApplicationStatus';
import {
  DELETE_CANDIDATE_EMAIL,
  GET_CANDIDATE_DETAILS,
  UPDATE_CANDIDATE,
} from './gql';

const UpdateCandidate = ({ match }: any) => {
  const location = useLocation<IComingState>();
  const comingState: any = location.state;
  let history = useHistory();
  const activeParamTab =
    new URLSearchParams(location.search).get('activeTab') || '1';
  const [activeTab, setActiveTab] = useState(activeParamTab);

  const [confirmToggle, setConfirmToggle] = useState(false);
  const [createApplicant, setCreateApplicant] = useState<boolean>(false);

  const { user, userRoles } = useContext(AppContext);
  const [toggleEmailOrLinkdin, setToggleEmailOrLinkdin] = useState(false);
  const [modal, setModal] = useState(false);
  const [submitPopUp, setSubmitPopUp] = useState<boolean>(false);


  const [emailId, setEmailId] = useState('');
  const [_updateCandidate, { loading: waiting }] =
    useMutation(UPDATE_CANDIDATE);

  const [_deleteEmail] = useMutation(DELETE_CANDIDATE_EMAIL);
  const [noteData, setNoteData] = useState(null);

  const [getCandidate, { data, loading, refetch, called }] = useLazyQuery(
    GET_CANDIDATE_DETAILS,
    { notifyOnNetworkStatusChange: true }
  );

  const [refetchCandidateOwner, setRefetchCandidateOwner] = useState(false);
  const refetchCandidateWriteUp = () => {
    setRefetchCandidateOwner(!refetchCandidateOwner);
  };

  const [getNotes] = useLazyQuery(GET_NOTES);

  const [checkNonSolicitCompanyQuery, { data: nonSolicitWarning }] = useLazyQuery(CHECK_NON_SOLICIT_COMPANY);
  const _getNotes = (type: string) => {
    getNotes({
      variables: {
        id: candidateId,
        notePayload: {
          type: 'Candidate',
          compensationNote: compensationNote,
        },
        page: 1,
        limit: 3,
      },
    });

    if (
      type === 'Phone Call' ||
      type === 'Internal Interview' ||
      type === 'Internal Web Interview' ||
      type === 'Email Sent'
    ) {
      refetchCandidateWriteUp();
    }
  };
  const [recruiterFolder, setRecruiterFolder] = useState<boolean>(false);
  const recruiterFolderToggle = () => setRecruiterFolder(!recruiterFolder);
  const [phoneErr, setPhoneErr] = useState('');
  const [candidateId, setCandidateId] = useState('');
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const [, setVerifyEmailBool] = useState(false);

  const [activityNotes, setActivityNotes] = useState<boolean>(false);
  const [compensationNote, setCompensationNote] = useState<boolean>(false);
  const toggleActivityNotes = () => setActivityNotes(!activityNotes);

  const [tooltipOpen1, setTooltipOpen1] = useState(false);
  const toggle1 = () => setTooltipOpen1(!tooltipOpen1);

  const [tooltipOpen2, setTooltipOpen2] = useState(false);
  const toggle2 = () => setTooltipOpen2(!tooltipOpen2);

  const [tooltipOpen3, setTooltipOpen3] = useState(false);
  const toggle3 = () => setTooltipOpen3(!tooltipOpen3);

  const [tooltipOpen4, setTooltipOpen4] = useState(false);
  const toggle4 = () => setTooltipOpen4(!tooltipOpen4);

  const [tooltipOpen5, setTooltipOpen5] = useState(false);
  const toggle5 = () => setTooltipOpen5(!tooltipOpen5);

  const [tooltipOpen6, setTooltipOpen6] = useState(false);
  const toggle6 = () => setTooltipOpen6(!tooltipOpen6);

  const [jobApply, setJobApply] = useState<boolean>(false);
  const onApplyJob = () => {
    nonSolicitWarning?.checkNonSolicitCompany ? setSubmitPopUp(true) : setJobApply(!jobApply);
  };

  // Function to set the verifyEmailBool state
  const setVerifyEmailStatus = (status: boolean) => {
    setVerifyEmailBool(status);
  };

  const [customPhoneInputs, setCustomPhoneInputs] = useState<any>([
    {
      phone: '',
      phoneType: '',
      isPrimary: true,
    },
  ]);
  const {
    errors,
    handleSubmit,
    register,
    control,
    setValue,
    getValues,
    reset,
    watch,
  } = useForm({
    defaultValues: {
      emails: [{ email: '', isPrimary: true, emailId: '' }],
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'emails',
  });

  const handleRemove = (index: number, domainId: string) => {
    if (domainId) {
      setConfirmToggle(true);
      setEmailId(domainId);
      return;
    } else {
      remove(index);
    }
  };

  const deleteEmail = async () => {
    await _deleteEmail({ variables: { id: emailId } });
    setConfirmToggle(false);
    toast.success('Successfully Deleted!');
    getCandidate({ variables: { id: candidateId } });
  };

  const updateCandidate = async (values: any) => {
    let phone = customPhoneInputs.filter((item: any) => item.phone);
    if (phone?.length) {
      let phoneIsPrimary = phone?.find((item) => item?.isPrimary);
      if (!phoneIsPrimary) {
        setPhoneErr('A phone number must be primary');
        return;
      }
      values.phones = phone;
    } else {
      values.phones = [];
    }

    values.candidateId = candidateId;
    let emailsFil = values?.emails?.filter((item: any) => item.email && item);
    if (emailsFil?.length) {
      let emailIsPrimary = emailsFil?.find((item) => item?.isPrimary);
      if (!emailIsPrimary) {
        emailsFil[0].isPrimary = true;
      }
      values.emails = emailsFil;
    } else {
      values.emails = [];
    }
    let payload = makeCandidateDate(values);
    let res = await _updateCandidate({
      variables: { UpdateCandidateDto: { ...payload } },
    });

    if (res?.data) {
      //this is because we are not getting the new update email address in the email fields on the front-end side so we are doing this
      setValue('emails', [{ email: '', isPrimary: true, emailId: '' }]);
      toast.success('Candidate Successfully Updated!');
      setPhoneErr('');
      refetch && refetch();
    }
  };

  const toggleTab = (tab: string) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      history.push(`${location?.pathname}?activeTab=${tab}`);
    }
  };

  const toggleModal = () => {
    setModal(!modal);
  };

  const createApplicantToggle = () => {
    nonSolicitWarning?.checkNonSolicitCompany ? setSubmitPopUp(true) : setCreateApplicant(!createApplicant);
  };

  const editPermission = (candidate: { recruiterOwner: { id: string } }) => {
    if (
      userRoles?.includes('ADMIN') ||
      userRoles?.includes('EXECUTIVE') ||
      userRoles?.includes('HR')
    ) {
      return true;
    }
    if (user?.id === candidate?.recruiterOwner?.id) {
      return true;
    }
    if (!candidate?.recruiterOwner) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    const id = match.params.id;
    if (id) {
      setCandidateId(id);
      getCandidate({ variables: { id } });
    }
    // eslint-disable-next-line
  }, []);

  const getCandidateDetail = useCallback(async () => {
    if (called && data?.getCandidate) {
      let payload = preFillCandidateData(data?.getCandidate);
      setCustomPhoneInputs(payload?.userPhones);
      reset({ ...payload });
      setValue('isPrivateCandidate', payload?.isPrivateCandidate);
      if (payload?.emails?.length) {
        setToggleEmailOrLinkdin(true);
      } else {
        setToggleEmailOrLinkdin(false);
      }
    }
  }, [data?.getCandidate, reset, setValue, called])

  useEffect(() => {
    if (called && data?.getCandidate) {
      getCandidateDetail()
    }
  }, [called, data?.getCandidate, getCandidateDetail]);

  // this useEffect is used for the checking user is authorized for the update candidate page
  useEffect(() => {
    if (data?.getCandidate && user?.id) {
      let permit = editPermission(data?.getCandidate);
      if (!permit) {
        history.push({
          pathname: '/un-authorized',
        });
      }
    }

    checkNonSolicitCompanyQuery({
      variables: {
        input: data?.getCandidate?.companyName ||
          data?.getCandidate?.recentEmployment?.companyName
      }
    })
    // eslint-disable-next-line
  }, [data?.getCandidate, user]);

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="admin-tabs team-tabs">
      <ToastContainer autoClose={3000} />

      <GoBack
        url={comingState?.backUrl ?? CANDIDATES_ROUTE}
        search={comingState?.searchParameters}
      />

      {!nonSolicitWarning?.checkNonSolicitCompany && data?.getCandidate?.agreementWarning && (
        <AlertCard
          title="Warning!"
          subTitle="Candidate might be working for a client with signed agreement"
          color="danger"
        />
      )}
      {data?.getCandidate?.interviewWarning && (
        <AlertCard
          title="Warning!"
          subTitle="Candidate is currently going through interviews within past 30 days"
          color="danger"
        />
      )}

      {nonSolicitWarning?.checkNonSolicitCompany && (
        <AlertCard
          title="Warning!"
          subTitle={"Candidate might be working with a client with non-solicit agreement!"}
          color="danger"
        />
      )}

      {data?.getCandidate?.placementWarning && (
        <AlertCard
          title="Warning!"
          subTitle="Candidate was placed within past year"
          color="danger"
        />
      )}

      {submitPopUp && (
        <ConfirmPopUp
          confirmText="You cannot submit a candidate from a client with non-solicit agreement."
          isOpen={submitPopUp}
          toggle={setSubmitPopUp}
          confirmAction={() => setSubmitPopUp(!submitPopUp)}
          modalHeading="Submit Action"
          btnText="Okay"
          cancelBtn={false}
          className="revampDialog revampDialogWidth"
        />)}

      {countryChecker(data?.getCandidate?.countryCode) ? null : (
        <AlertCard
          title="Warning!"
          subTitle="Candidate is from outside of the US"
          color="danger"
          outSideUS={true}
        />
      )
      }

      <div className="primaryHeading mobileResponsiveFlex">
        <div className="d-flex align-items-center">
          <h5 className="m-0 mr-2">{data?.getCandidate?.fullName || '--'}</h5>

          <Badge
            color={
              data?.getCandidate?.status === 'ACTIVE'
                ? 'success'
                : data?.getCandidate?.status === 'DO_NOT_CONTACT'
                  ? 'danger'
                  : ''
            }
            className="text-capitalize"
          >
            {data?.getCandidate?.status ?? '--'}
          </Badge>
        </div>

        <Dropdown
          isOpen={dropdownOpen}
          toggle={toggle}
          className="actionsDropdown"
        >
          <DropdownToggle caret>Actions</DropdownToggle>
          <DropdownMenu>
            <DropdownItem
              tag={Link}
              to={
                data?.getCandidate?.resume?.length
                  ? {
                    pathname: `/view/resume/${candidateId}`,
                    search: `?resumeId=${data?.getCandidate?.resume[0]?.id}`,
                  }
                  : {
                    pathname: `/view/resume/${candidateId}`,
                  }
              }
              className="cursor-pointer"
            >
              Resume Preview
            </DropdownItem>

            <DropdownItem onClick={toggleModal}>Activity Notes</DropdownItem>
            <DropdownItem onClick={onApplyJob}>Submit</DropdownItem>
            <DropdownItem onClick={createApplicantToggle}>Apply</DropdownItem>

            <DropdownItem onClick={recruiterFolderToggle}>
              Add To Recruiter Folder
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </div>

      <div className="applicantDetail primaryHeaderSpacing sd">
        {data?.getCandidate?.linkedInUrl && (
          <>
            <div className="infoCardDivider icon-text-wrap">
              <a
                href={
                  data?.getCandidate?.linkedInUrl
                    ? data?.getCandidate?.linkedInUrl
                    : '#'
                }
                target={data?.getCandidate?.linkedInUrl ? '_blank' : ''}
                className="m-0 d-flex"
                rel="noreferrer"
              >
                <Tooltip
                  placement="top"
                  isOpen={tooltipOpen1}
                  target="TooltipExample1"
                  toggle={toggle1}
                >
                  Linkedin
                </Tooltip>
                <img
                  src={LINKEDIN_IMG}
                  alt="linkedin"
                  width="17px"
                  id="TooltipExample1"
                />
              </a>
            </div>
            <div className="divider"></div>
          </>
        )}

        <div className="infoCardDivider icon-text-wrap">
          <Tooltip
            placement="top"
            isOpen={tooltipOpen3}
            target="TooltipExample3"
            toggle={toggle3}
          >
            Job Title
          </Tooltip>

          <img
            src={TITLE_ICON}
            alt="company"
            width="19px"
            id="TooltipExample3"
          />
          <label>
            {data?.getCandidate?.jobTitle ||
              data?.getCandidate?.recentEmployment?.jobTitle ||
              '--'}
          </label>
        </div>
        <div className="divider"></div>

        <div className="infoCardDivider icon-text-wrap">
          <Tooltip
            placement="top"
            isOpen={tooltipOpen2}
            target="TooltipExample2"
            toggle={toggle2}
          >
            Company
          </Tooltip>
          <img
            src={INFO_ICON_1}
            alt="company"
            width="17px"
            id="TooltipExample2"
          />
          <label>
            {data?.getCandidate?.companyName ||
              data?.getCandidate?.recentEmployment?.companyName ||
              '--'}
          </label>
        </div>

        <div className="divider"></div>

        <div className="infoCardDivider icon-text-wrap">
          <Tooltip
            placement="top"
            isOpen={tooltipOpen4}
            target="TooltipExample4"
            toggle={toggle4}
          >
            Address
          </Tooltip>
          <img
            src={INFO_ICON_6}
            alt="address"
            width="17px"
            id="TooltipExample4"
          />
          <label>
            {
              countryWiseHeaderLocation(data?.getCandidate?.countryCode, data?.getCandidate?.city, data?.getCandidate?.stateCode)
            }
          </label>
        </div>

        <div className="divider"></div>

        {data?.getCandidate?.phones?.length ? (
          <>
            <div className="infoCardDivider icon-text-wrap">
              <Tooltip
                placement="top"
                isOpen={tooltipOpen5}
                target="TooltipExample5"
                toggle={toggle5}
              >
                Phone
              </Tooltip>
              <img
                src={INFO_ICON_4}
                alt="phone"
                width="17px"
                id="TooltipExample5"
              />
              <label>
                <div className="d-flex align-items-center">
                  <a
                    href={`tel:${data?.getCandidate?.phones?.length
                      ? data?.getCandidate?.phones?.find(
                        (val) => val?.isPrimary
                      )?.phoneNumber ||
                      data?.getCandidate?.phones[0]?.phoneNumber
                      : '--'
                      }`}
                    className="m-0"
                  >
                    {data?.getCandidate?.phones?.length
                      ? data?.getCandidate?.phones?.find(
                        (val) => val?.isPrimary
                      )?.phoneNumber ||
                      data?.getCandidate?.phones[0]?.phoneNumber
                      : '--'}
                  </a>
                </div>
              </label>
            </div>

            <div className="divider"></div>
          </>
        ) : null}
        <div className="infoCardDivider icon-text-wrap d-flex align-items-center emailLengthWidth">
          <Tooltip
            placement="top"
            isOpen={tooltipOpen6}
            target="TooltipExample6"
            toggle={toggle6}
          >
            Email
          </Tooltip>

          <img
            src={INFO_ICON_5}
            alt="emails"
            width="19px"
            id="TooltipExample6"
          />
          <label>
            <a
              href={`mailto:${data?.getCandidate?.emails?.length
                ? data?.getCandidate?.emails?.find(
                  (item: { isPrimary: boolean }) => item.isPrimary
                )?.email || data?.getCandidate?.emails[0]?.email
                : '--'
                }`}
              className="m-0"
            >
              {(data?.getCandidate?.emails?.length &&
                data?.getCandidate?.emails?.find(
                  (item: { isPrimary: boolean }) => item.isPrimary
                )?.email) ||
                (data?.getCandidate?.emails?.length &&
                  data?.getCandidate?.emails[0]?.email) ||
                '--'}
            </a>
          </label>
        </div>
      </div>

      {/* <BreadcrumbComponent /> */}

      <div className="CandidateTabsWrapper">
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '1' })}
              onClick={() => {
                toggleTab('1');
              }}
            >
              About
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '2' })}
              onClick={() => {
                toggleTab('2');
              }}
            >
              Resume
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '3' })}
              onClick={() => {
                toggleTab('3');
              }}
            >
              Activity
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '4' })}
              onClick={() => {
                toggleTab('4');
              }}
            >
              Candidate Ownership
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '5' })}
              onClick={() => {
                toggleTab('5');
              }}
            >
              Submission
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '6' })}
              onClick={() => {
                toggleTab('6');
              }}
            >
              References
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '7' })}
              onClick={() => {
                toggleTab('7');
              }}
            >
              Documents
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '8' })}
              onClick={() => {
                toggleTab('8');
              }}
            >
              Job App Status
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '10' })}
              onClick={() => {
                toggleTab('10');
              }}
            >
              Employment
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '11' })}
              onClick={() => {
                toggleTab('11');
              }}
            >
              Education
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane className="tab-pane-padding" tabId="1">
            <Row>
              <Col lg="12" md="12" sm={12}>
                <CandidateBasicInfo
                  register={register}
                  errors={errors}
                  getValues={getValues}
                  setValue={setValue}
                  control={control}
                  candidateId={candidateId}
                  candidateSource={{
                    source: data?.getCandidate?.source,
                    allowEdit: data?.getCandidate?.candidateFromDragResume,
                  }}
                  candidate={data?.getCandidate}
                  refetchCandidateWriteUp={refetchCandidateWriteUp}
                  editCandidate={true}
                  watch={watch}
                />
              </Col>
              <Col lg="12" md="12" sm="12" className="mb-2">
                <AddressInfo
                  watch={watch}
                  register={register}
                  errors={errors}
                  headQatar={false}
                  givenStates={states_candidate_constant}
                  setValue={setValue}
                />
              </Col>
              <Col lg="6" md="12" sm="12">
                <CandidateCredential
                  register={register}
                  control={control}
                  errors={errors}
                  candidateSource={{
                    source: data?.getCandidate?.source,
                    allowEdit: data?.getCandidate?.candidateFromDragResume,
                  }}
                />
              </Col>
              <Col lg="6" md="12" sm="12">
                <IdealEmployment
                  register={register}
                  setValue={setValue}
                  errors={errors}
                  control={control}
                  getValues={getValues}
                />
              </Col>
              <Col lg="12" md="12" sm="12">
                <SocialMediaInfo
                  setValue={setValue}
                  register={register}
                  errors={errors}
                  isDisable={false}
                  isLinkedinRequired={!toggleEmailOrLinkdin}
                  linkedinErr="LinkedIn URL would be required if candidate does not have an email"
                />
              </Col>
              <Col lg="6" md="12" sm="12">
                <CandidateEmails
                  getValues={getValues}
                  setValue={setValue}
                  append={append}
                  control={control}
                  fields={fields}
                  register={register}
                  errors={errors}
                  remove={handleRemove}
                  setToggleEmailOrLinkdin={setToggleEmailOrLinkdin}
                  setVerifyEmailStatus={setVerifyEmailStatus}
                />
              </Col>
              <Col lg="6" md="12" sm="12">
                <ContactPhone
                  setCustomPhoneInputs={setCustomPhoneInputs}
                  customPhoneInputs={customPhoneInputs}
                  error={phoneErr}
                  setErr={setPhoneErr}
                  forCandidate={true}
                />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="2">
            <CandidateResume
              refetch={refetch}
              necessaryCandidateInfo={{
                jobTitle: data?.getCandidate?.jobTitle ||
                  data?.getCandidate?.recentEmployment?.jobTitle,
                companyName: data?.getCandidate?.companyName ||
                  data?.getCandidate?.recentEmployment?.companyName,
                phones: data?.getCandidate?.phones?.length ?
                  data?.getCandidate?.phones : null,
                emails: data?.getCandidate?.emails?.length ?
                  data?.getCandidate?.emails : null
              }}
              candidateId={candidateId} candidateName={data?.getCandidate?.fullName} />
          </TabPane>

          <TabPane tabId="3">
            <ActionLogsAndNotes
              option={{
                candidateId: data?.getCandidate?.id ?? '',
                candidateName: data?.getCandidate?.fullName ?? '',
              }}
              type="Candidate"
              recordId={candidateId}
              viewDetail={false}
              isPrivatePermission={
                userRoles?.includes('EXECUTIVE') ||
                userRoles?.includes('HR') ||
                data?.getCandidate?.shareWith?.includes(user?.id)
              }
            />
          </TabPane>

          <TabPane tabId="4" className="tab-pane-padding">
            <CandidateOwnershipWriteup
              candidateId={candidateId}
              refetchCandidateWriteUp={refetchCandidateOwner}
              refetchCandidateDetails={() => refetch()}
            />
          </TabPane>

          <TabPane tabId="5" className="tab-pane-padding">
            <SubmissionHistoryCandidate
              candidateId={candidateId}
              location={location}
              isEdit
            />
          </TabPane>

          <TabPane tabId="6">
            <ReferenceListing
              candidateId={candidateId}
              candidate={data?.getCandidate}
              editReference={false}
            />
          </TabPane>

          <TabPane tabId="7">
            <Attachments companyId={candidateId} attachableType={'Candidate'} />
          </TabPane>

          <TabPane tabId="8" className="tab-pane-padding">
            <JobApplicationStatus candidate={data?.getCandidate} isEdit />
          </TabPane>

          <TabPane tabId="10" className="tab-pane-padding">
            <EmploymentHistory candidateId={candidateId} />
          </TabPane>

          <TabPane tabId="11" className="tab-pane-padding">
            <CandidateEducation candidateId={candidateId} />
          </TabPane>
        </TabContent>

        {activeTab === '1' && (
          <Form onSubmit={handleSubmit(updateCandidate)}>
            <div className="jobOrderRevamp bg-transparent text-right">
              <CustomButton
                buttonText="Update Candidate"
                buttonColor="primary"
                buttonType="submit"
                className="big-width"
                loading={waiting}
                disabled={waiting}
              />
            </div>
          </Form>
        )}
      </div>

      <CandidateRecruiterModal
        isOpen={recruiterFolder}
        toggle={recruiterFolderToggle}
        recordToAdd={[data?.getCandidate?.id]}
        refetch={() => console.log('Refetching candidates')}
        clearRecord={() => console.log('Clearing record')}
      />

      <ConfirmPopUp
        confirmText="Are you sure you want to delete this email?"
        isOpen={confirmToggle}
        toggle={setConfirmToggle}
        confirmAction={deleteEmail}
        modalHeading="Delete Email"
        btnText="Delete"
        btnColor="primary"
      />

      <AddNotesModal
        toggleActivityNotes={toggleActivityNotes}
        activityNotes={activityNotes}
        noteableId={candidateId}
        reFetch={(type) => _getNotes(type)}
        type="Candidate"
        editData={noteData}
        resetField={setNoteData}
        isPrivateCandidate={data?.getCandidate?.isPrivateCandidate}
        setCompensationNote={setCompensationNote}
      />
      {jobApply && (
        <CandidateSubmitDrawer
          toggle={onApplyJob}
          isOpen={jobApply}
          BackURL={`${VIEW_CANDIDATE_ROUTE}/${candidateId}`}
          candidate={data?.getCandidate}
          refetch={refetch}
        />
      )}
      {createApplicant && (
        <CandidateSubmitDrawer
          toggle={createApplicantToggle}
          isOpen={createApplicant}
          candidateId={candidateId}
          applyJob={true}
          candidate={data?.getCandidate}
          refetch={refetch}
        />
      )}

      <div>
        <Modal isOpen={modal} toggle={toggleModal} className="candidateNotes">
          <ModalHeader>
            <h6>Candidate Notes</h6>

            <button onClick={toggleModal} type="button">
              <img src={MODAL_CLOSE_ICON} alt="close" />
            </button>
          </ModalHeader>
          <ModalBody>
            <CandidateNotesModal candidateId={match.params.id} />
          </ModalBody>
        </Modal>
      </div>
    </div>
  );
};

export default withRouter(UpdateCandidate);
