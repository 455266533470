import React from "react";
import { ApolloProvider } from "@apollo/client";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import client from "./apollo";
import AppContextProvider from "./context";

// Pages
import Login from "./Pages/Auth/Login";
import ResetEmail from "./Pages/Auth/ResetEmail";
import PrivateRoute from "./components/PrivateRoute";
import ResetPassword from "./Pages/Auth/ResetPassword";
import ForgotPassword from "./Pages/Auth/ForgotPassword";
import UpdatePasswordSuccess from "./Pages/Auth/UpdatePasswordSuccess";
import Page404 from "./Pages/error";
import privateRoutes from "./utils/routes";

// Style Sheets
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import "../src/styles/styles.scss";
import "../src/styles/dark-theme.scss";
import "react-toastify/dist/ReactToastify.css";
import "react-quill/dist/quill.snow.css"; // ES6

const App = () => {
  return (
    <AppContextProvider>
      <ApolloProvider client={client}>
        <Router>
          <Switch>
            <Route path="/" exact component={Login} />
            <Route path="/forgot-password" exact component={ForgotPassword} />
            <Route
              path="/reset-password/:token"
              exact
              component={ResetPassword}
            />
            <Route path="/reset-email" exact component={ResetEmail} />
            <Route
              path="/reset-password-success"
              exact
              component={UpdatePasswordSuccess}
            />
            {privateRoutes.map((item, index) => {
              return <PrivateRoute key={index} {...item} />;
            })}
            <PrivateRoute path="*" exact component={Page404} />
          </Switch>
        </Router>
      </ApolloProvider>
    </AppContextProvider>
  );
};

export default App;
