import { Badge, Spinner } from 'reactstrap';
import DirectHireIndex from './accounting-components/direct-hire/Index';
import TempHireIndex from './accounting-components/temp-hire/Index';
import GoBack from '../../components/GoBack';
import { handleAccountBillingPrint } from './accounting-components/utils';
import { useParams } from 'react-router';
import { useContext, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import {
  GET_PLACEMENT_BY_ID,
  PLACEMENT_SPLITTING_BY_ID,
} from './gql';
import Loader from '../../components/layout/Loader';
import { useEffect } from 'react';
import { formatDateUS } from '../../utils/commonFn';
import moment from 'moment';
import { ACCOUNTING_APPROVED, ACCOUNTING_INVOICED, DIRECT_HIRE, INVOICE_EMAIL_CONTENT, PAID, VIEW_ACCOUNTING_DASHBOARD_ROUTE } from '../../utils/constant';
import { PLACEMENT_CC_CONTACTS } from '../candidate-pipeline/placements/gql';
import InvoiceEmailModal from '../../components/billing-sheet/invoice-email-modal';
import { AppContext } from '../../context';
import InvoiceStatusTable from '../candidate-pipeline/placements/components/InvoiceStatusTable';

const BillingSheet = () => {
  const { id }: any = useParams();
  const { userRoles } = useContext(AppContext);
  const [placementContacts, { data: placementContactsList }] =
    useLazyQuery(PLACEMENT_CC_CONTACTS);

  const [loadingPrintBillingSheet, setLoadingPrintBillingSheet] =
    useState<boolean>(false);
  const [emailContentModal, setEmailContentModal] = useState<boolean>(false);

  const [
    _getPlacement,
    { data: _getPlacementData, loading: _getPlacementLoading, refetch },
  ] = useLazyQuery(GET_PLACEMENT_BY_ID);

  const [_placementSplit, { data: _placementSplitData }] = useLazyQuery(
    PLACEMENT_SPLITTING_BY_ID
  );

  useEffect(() => {
    document.title = 'Billing Sheet - RealREPP';
    if (id) {
      _getPlacement({ variables: { id } });
      placementContacts({ variables: { placementId: id } });
      _placementSplit({
        variables: {
          placementId: id,
        },
      });
    }

    // eslint-disable-next-line
  }, [id]);

  const modalToggle = () => setEmailContentModal(!emailContentModal)

  const printingDirectHireBillingSheet = async () => {
    setLoadingPrintBillingSheet(true);
    await handleAccountBillingPrint(id);
    setLoadingPrintBillingSheet(false);
  };

  return (
    <div className="admin-tabs team-tabs">
      <GoBack url={`${VIEW_ACCOUNTING_DASHBOARD_ROUTE}/${id}`} />

      <div className="mobileResponsiveFlexDevice mb-2 billingSheetButton">
        <div className="d-flex align-items-center">
          <h5 className="m-0">Billing Sheet</h5>

          <Badge color={'success'} className="text-capitalize ml-3">
            {_getPlacementData?.getPlacementById?.placementType}
          </Badge>
          {_getPlacementData?.getPlacementById?.companyFirstPlacement && (
            <Badge color="info" className="ml-2 text-capitalize">
              First Placement
            </Badge>
          )}
        </div>
        <div>
          <button hidden={!userRoles?.includes("EXECUTIVE")} type="button" className="buttonGenericStyle filledButton mx-4" onClick={modalToggle} >
            {INVOICE_EMAIL_CONTENT}
          </button>

          <button
            type="button"
            disabled={
              loadingPrintBillingSheet ||
              !(_getPlacementData && _placementSplitData)
            }
            className="buttonGenericStyle filledButton"
            onClick={printingDirectHireBillingSheet}
          >
            {loadingPrintBillingSheet && (
              <Spinner size="sm" className="mr-2 ml-0" />
            )}
            Print
          </button>
        </div>
      </div>
      <div>
        {_getPlacementLoading && <Loader />}

        {_getPlacementData?.getPlacementById?.placementType ===
          'DIRECT_HIRE' ? (
          <DirectHireIndex
            billingTypeCardProps={{
              id: _getPlacementData?.getPlacementById?.id,
              candidateId: _getPlacementData?.getPlacementById?.candidateId,
              companyId: _getPlacementData?.getPlacementById?.jobOrderOfPlacement?.company?.id,
              jobId: _getPlacementData?.getPlacementById?.jobOrder?.id,
              candidateName:
                _getPlacementData?.getPlacementById?.candidateFullName,
              placementId: _getPlacementData?.getPlacementById?.placementId,
              invoiceNumber: _getPlacementData?.getPlacementById?.invoiceNumber,
              companyName: _getPlacementData?.getPlacementById?.companyName,
              positionTitle: _getPlacementData?.getPlacementById?.jobTitle,
              location: ['US', null, undefined, ''].includes(_getPlacementData?.getPlacementById?.jobCountryCode) ? `${_getPlacementData?.getPlacementById?.jobStreet}, ${_getPlacementData?.getPlacementById?.jobCity}, ${_getPlacementData?.getPlacementById?.jobStateCode}, ${_getPlacementData?.getPlacementById?.jobPostalCode}` : `${_getPlacementData?.getPlacementById?.jobStreet}, ${_getPlacementData?.getPlacementById?.jobCity}, ${_getPlacementData?.getPlacementById?.jobPostalCode}, ${_getPlacementData?.getPlacementById?.jobCountryCode}`,
              startDate: formatDateUS(
                moment(
                  _getPlacementData?.getPlacementById?.startDateString
                ).format()
              ),
              commissionType:
                _getPlacementData?.getPlacementById?.commissionType,
              salary: _getPlacementData?.getPlacementById?.salaryAmount,
              feeStructured: _getPlacementData?.getPlacementById?.feeStructured,
              adjustmentTotal:
                _getPlacementData?.getPlacementById?.adjustmentTotal,
              adjustmentsReason:
                _getPlacementData?.getPlacementById?.adjustmentsReason,
              totalCommission:
                _getPlacementData?.getPlacementById?.totalCommission,
              netCommission: _getPlacementData?.getPlacementById?.netCommission,
              billingContactInfo: {
                billingContactId: _getPlacementData?.getPlacementById?.placementBillingContact
                  ?.companyContact?.id,
                billingContactName:
                  _getPlacementData?.getPlacementById?.placementBillingContact
                    ?.companyContact?.fullName,
                billingContactTitle:
                  _getPlacementData?.getPlacementById?.placementBillingContact
                    ?.companyContact?.jobTitle,
                email:
                  _getPlacementData?.getPlacementById?.placementBillingContact?.companyContact?.contactEmail?.find(
                    (item) => item?.isPrimary
                  )?.email,
              },
            }}
            candidateSourceProp={{
              source: _getPlacementData?.getPlacementById?.candidate?.source,
            }}
            placementSplit={_placementSplitData?.placementSplitting}
            placementContacts={placementContactsList}

          />
        ) : (
          <TempHireIndex
            tempBillingTypeCardProps={{
              id: _getPlacementData?.getPlacementById?.id,
              candidateId: _getPlacementData?.getPlacementById?.candidateId,
              companyId: _getPlacementData?.getPlacementById?.jobOrderOfPlacement?.company?.id,
              jobId: _getPlacementData?.getPlacementById?.jobOrder?.id,
              adjustmentFee:
                _getPlacementData?.getPlacementById?.adjustmentTotal,
              netCommission: _getPlacementData?.getPlacementById?.netCommission,
              salary: _getPlacementData?.getPlacementById?.salaryAmount,
              placementId: _getPlacementData?.getPlacementById?.placementId,
              candidateName: _getPlacementData?.getPlacementById?.candidateName,
              companyName: _getPlacementData?.getPlacementById?.companyName,
              positionTitle: _getPlacementData?.getPlacementById?.jobTitle,
              location: ['US', null, undefined, ''].includes(_getPlacementData?.getPlacementById?.jobCountryCode) ? `${_getPlacementData?.getPlacementById?.jobStreet}, ${_getPlacementData?.getPlacementById?.jobCity}, ${_getPlacementData?.getPlacementById?.jobStateCode}, ${_getPlacementData?.getPlacementById?.jobPostalCode}` : `${_getPlacementData?.getPlacementById?.jobStreet}, ${_getPlacementData?.getPlacementById?.jobCity}, ${_getPlacementData?.getPlacementById?.jobPostalCode}, ${_getPlacementData?.getPlacementById?.jobCountryCode}`,
              startDate: formatDateUS(
                moment(
                  _getPlacementData?.getPlacementById?.startDateString
                ).format()
              ),
              durationAssignment:
                _getPlacementData?.getPlacementById?.assignmentDuration,
              payRate: _getPlacementData?.getPlacementById?.payRate?.toFixed(2),
              billRate:
                _getPlacementData?.getPlacementById?.billRate?.toFixed(2),
            }}
            placementSplit={_placementSplitData?.placementSplitting}
            placementContacts={placementContactsList}
          />
        )}
      </div>

      {(_getPlacementData?.getPlacementById?.placementStatus === ACCOUNTING_APPROVED
        || _getPlacementData?.getPlacementById?.placementStatus === ACCOUNTING_INVOICED
        || _getPlacementData?.getPlacementById?.placementStatus === PAID)
        && _getPlacementData?.getPlacementById?.placementType === DIRECT_HIRE
        && < InvoiceStatusTable invoiceNumber={_getPlacementData?.getPlacementById?.invoiceNumber}
          refetchPlacement={refetch} placementId={id} />}

      <InvoiceEmailModal isOpen={emailContentModal} toggle={modalToggle}
        refetch={refetch}
        placementId={_getPlacementData?.getPlacementById?.id}
        invoiceNumber={_getPlacementData?.getPlacementById?.invoiceNumber}
        candidateName={_getPlacementData?.getPlacementById?.candidateName}
        companyName={_getPlacementData?.getPlacementById?.companyName}
        jobTitle={_getPlacementData?.getPlacementById?.jobTitle}
        city={_getPlacementData?.getPlacementById?.jobCity}
        state={_getPlacementData?.getPlacementById?.jobStateCode}
      />
    </div>
  );
};

export default BillingSheet;
