import React, { useEffect, useState } from 'react';
import { Badge } from 'reactstrap';
import NoDataFound from '../../components/layout/NoDataFound';
import TableComponent from '../../components/layout/TableComponent';
import { formatDateUS } from '../../utils/commonFn';
import VIEW_ICON from '../../assets/images/view-icon.svg';
import ViewMoreModal from '../../components/ViewMoreModal';
import { GET_SUBMISSIONS_HISTORY } from '../recruiter-folder/gql';
import { useLazyQuery } from '@apollo/client';

const EMPLOYMENT_HEADER = ['NOTE', 'STATUS', 'USER', 'CREATEDAT', 'ACTION'];

export default function SubmissionHistory({ submissionId }) {
  const [viewReason, setViewReason] = useState('');
  const [viewModal, setViewModal] = useState(false);
  const [getSubmissionHistory, { data }] = useLazyQuery(
    GET_SUBMISSIONS_HISTORY
  );

  useEffect(() => {
    if (submissionId) {
      getSubmissionHistory({
        variables: {
          submissionId: submissionId,
          limit: 10,
          page: 1,
        },
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submissionId]);

  return (
    <div
      className="table-responsive mt-4 custom-scroller"
      style={{ maxHeight: '200px' }}
    >
      <div className="pb-3">
        <strong>Submission History</strong>
      </div>
      <TableComponent tableHeader={EMPLOYMENT_HEADER}>
        <tbody>
          <>
            {data?.getSubmissionHistory?.submissionHistory?.length
              ? data?.getSubmissionHistory?.submissionHistory?.map(
                  (item: any) => (
                    <tr>
                      <td>
                        <label className="ownerNotesWidth text-truncate d-block">
                          {item?.note
                            ? item?.note
                                .split(/\s+/)
                                .slice(0, 20)
                                .join(' ')
                                .replace(/<[^>]*>?/gm, ' ')
                            : ''}
                        </label>
                      </td>
                      <td className="cand-status-column">
                        <Badge
                          className="text-capitalize"
                          color={`${
                            item?.status === 'COMPLETED'
                              ? 'success'
                              : item.status === 'DECLINE'
                              ? 'danger'
                              : 'info'
                          }`}
                        >
                          {item?.status === 'DRAFT'
                            ? 'PENDING'
                            : `${item?.status}`}
                        </Badge>
                      </td>
                      <td>
                        <div className="nameText">{item?.user?.fullName}</div>
                      </td>

                      <td>
                        {item.createdAt && formatDateUS(item.createdAt)} -{' '}
                        {item.createdAt &&
                          new Date(item.createdAt).toLocaleTimeString()}
                      </td>
                      <td>
                        <div
                          className="d-flex align-items-start"
                          style={{ width: 110 }}
                        >
                          <span
                            onClick={() => {
                              setViewReason(item?.note);
                              setViewModal(!viewModal);
                            }}
                            className=" iconButtonAction curser-pointer"
                          >
                            <img src={VIEW_ICON} alt="view" width="20px" />
                          </span>
                        </div>
                      </td>
                    </tr>
                  )
                )
              : ''}

            {!data?.getSubmissionHistory?.submissionHistory.length && (
              <tr>
                <td colSpan={5}>
                  <div className="for-position-multiselect">
                    <NoDataFound text="Submission History Not Found!" />
                  </div>
                </td>
              </tr>
            )}
          </>
        </tbody>
      </TableComponent>

      <ViewMoreModal
        toggle={() => setViewModal(!viewModal)}
        isOpen={viewModal}
        data={viewReason}
        heading="Submission History View"
      />
    </div>
  );
}
