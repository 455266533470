import React from "react";
import { useMutation } from "@apollo/client";
import { ModalBody, Form, Modal, ModalHeader } from "reactstrap";
import AssignOwner from "./AssignOwner";
import { useForm } from "react-hook-form";
import { Add_Candidate_Owner } from "../gql";
import { toast } from "react-toastify";

const AddOwnerModal = ({
  ownerModal,
  candidateId,
  setOwnerModal,
  updateUI,
  refetchCandidateDetails
}) => {
  const [addCandidateOwner] = useMutation(Add_Candidate_Owner);
  const { handleSubmit, control } = useForm();
  const addCandidateOwnership = async (values) => {
    if(values?.recruiterOwner?.value){
      const res = await addCandidateOwner({
        variables: {
          AddCandidateOwnerDto: {
            candidateId,
            recruiterOwner: values?.recruiterOwner?.value,
          },
        },
      });
      if (res?.data) {
        setOwnerModal(!ownerModal);
        refetchCandidateDetails()
        updateUI();
        toast.success("Candidate Owner added successfully");
      } else {
        toast.error("An error occured. Please try again later");
      }
    }else{
      toast.error("Please Assign Owner");

    }
  };
  return (
    <Modal
      isOpen={ownerModal}
      toggle={() => setOwnerModal(!ownerModal)}
      className="revampDialog revampDialogWidth"
    >
      <ModalHeader toggle={setOwnerModal}>Assign Owner</ModalHeader>
      <Form>
        <ModalBody>
          <AssignOwner control={control} />
          <div className="text-right pb-3">
            <button
              type="button"
              onClick={() => setOwnerModal(!ownerModal)}
              className="buttonPrimary mr-3"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="buttonGenericStyle filledButton"
              onClick={handleSubmit(addCandidateOwnership)}
            >
              Add Owner
            </button>
          </div>
        </ModalBody>
      </Form>
    </Modal>
  );
};

export default AddOwnerModal;
