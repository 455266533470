import moment from "moment";

export const firstAndLastName = (
  firstName: string | undefined,
  lastName: string | undefined
) => {
  return `${firstName?.charAt(0)}${lastName?.charAt(0)}`;
};

export const weekDays = () => {
  let data = new Date();
  const sevenDaysArr: any = Array(7).keys();
  const finalDays = [...sevenDaysArr].map(() => {
    var newDate = data.setDate(data.getDate() + 1);
    return moment(newDate).format("llll").substring(0, 10);
  });
  return finalDays;
};
