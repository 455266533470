import { FC } from "react"
import { Modal, ModalBody } from "reactstrap"
import { ModalUploadProps } from "../../interfaces"
import UploadForm from "./UploadForm"

const ModalUpload: FC<ModalUploadProps> = ({ isOpen, toggle, refetch, adminImport }) => {
  return (
    <Modal Modal isOpen={isOpen} toggle={toggle} className="revampDialog revampDialogWidth">
      <ModalBody className="pl-4">
        <UploadForm toggle={toggle} refetch={refetch} adminImport={adminImport}>
          {toggle && <button onClick={toggle} className="buttonPrimary mr-3" style={{
            backgroundColor: "red",
            margin: 0
          }}
            type="button">
            Cancel
          </button>}
        </UploadForm>
      </ModalBody>
    </Modal>
  )
}
export default ModalUpload