import { useQuery } from "@apollo/client"
import moment from "moment"
import { FC, useContext, useState } from "react"
import { Controller } from "react-hook-form"
import Select from "react-select"
import { Col, FormGroup, Input, Label, Row } from "reactstrap"
import { AppContext } from "../../../../context"
import { BasicInformationProps } from "../../../../interfaces"
import { GET_RECRUITER_LIST } from "../../../../Pages/job-order/gql"
import { priceHandler } from "../../../../utils/helper"
import { customSelectStyleNew } from "../../../styled/customSelect"

const BasicInformation: FC<BasicInformationProps> = ({ recruiterId, recruiterDeal, getValues, control, setNonRecoverable, setUnderTraining,
  nonRecoverable, underTraining, errors, register, setValue }) => {
  const [mentorsList, setMentorsList] = useState<{ label: string, value: string }[]>([]);
  const { theme } = useContext(AppContext)

  // query and mutations
  const { loading } = useQuery(GET_RECRUITER_LIST, {
    onCompleted: (data) => setMentorsList(data?.getRecruiterList?.filter(({ id }) => id !== recruiterId).map(({ id, fullName }) => { return { label: fullName, value: id } }))
  })

  // if (loading) {
  //   return <Loader />
  // }

  return <Row >

    <Col sm='6' className="mb-3 custom-form-input form-input">
      <Label className="text-14 text-blackele pb-2">Deal Name <span className="text-danger">*</span></Label>
      <Controller
        name="dealName"
        rules={{ required: { value: true, message: "Deal Name is a required field" } }}
        control={control}
        render={(field) => (
          <Input
            {...field}
            defaultValue={recruiterDeal?.dealName}
            innerRef={register}
            placeholder="Enter deal name"
            type="text"
            onChange={(item) => field.onChange(item.target.value)}
          />
        )}
      />
      <small className="text-danger pt-2">
        {errors?.dealName?.message}
      </small>
    </Col>

    <Col sm='6' className="mb-3 custom-form-input form-input">
      <Label className="text-14 text-blackele pb-2">Title <span className="text-danger">*</span></Label>
      <Controller
        name="recruiterTitle"
        rules={{ required: { value: true, message: "Title is a required field" } }}
        control={control}
        render={(field) => (
          <Input
            {...field}
            defaultValue={recruiterDeal?.recruiterTitle}
            innerRef={register}
            placeholder="Enter title"
            type="text"
            onChange={(item) => field.onChange(item.target.value)}
          />
        )}
      />
      <small className="text-danger pt-2">
        {errors?.recruiterTitle?.message}
      </small>
    </Col>

    <Col xs='6' className="mb-4 custom-form-input form-input">
      <Label className="text-14 text-blackele pb-2">Deal Start Date <span className="text-danger">*</span> </Label>
      <Controller
        rules={{ required: { value: true, message: "Deal Start Date is a required field" } }}
        name="dealStartDate"
        control={control}
        render={(field) => (
          <Input
            {...field}
            defaultValue={recruiterDeal?.dealStartDate}
            innerRef={register}
            placeholder="Select Date"
            type="date"
          />
        )}
      />
      <small className="text-danger pt-2">
        {errors?.dealStartDate?.message}
      </small>
    </Col>
    <Col xs='6' className="mb-4 custom-form-input form-input">
      <Label className="text-14 text-blackele pb-2">Deal End Date </Label>
      <Controller
        name="dealEndDate"
        control={control}
        render={(field) => (
          <Input
            {...field}
            defaultValue={recruiterDeal?.dealEndDate}
            innerRef={register}
            placeholder="Select Date"
            type="date"
          />
        )}
      />
      <small className="text-danger pt-2">
        {errors?.dealEndDate?.message}
      </small>
    </Col>

    <Col sm='6' className="mb-3">
      <Label className="text-14 text-blackele pb-2">Assign Mentor</Label>
      <Controller
        name="assignedManagerId"
        defaultValue={recruiterDeal?.assignedManagerId && { value: recruiterDeal?.assignedManagerId, label: recruiterDeal?.assignedManager?.fullName }}
        rules={{ required: { value: false, message: "Assign Mentor is a required field" } }}
        control={control}
        as={Select}
        isLoading={loading}
        isDisabled={loading}
        options={mentorsList}
        onChange={(value) => setValue('assignedManagerId', value)}
        innerRef={register}
        styles={{ ...customSelectStyleNew(theme) }}
        placeholder="Select Mentor"
      />
      <div className="d-flex align-items-end curser-pointer flex-column my-1 px-2"
        onClick={() => setValue("assignedManagerId", null)}>
        <small className="titile-count-message-color" >Clear</small>
      </div>
      <small className="text-danger pt-2">
        {errors?.assignedManagerId?.message}
      </small>
    </Col>

    <Col sm='6' className="mb-3 custom-form-input form-input">
      <Label className="text-14 text-blackele pb-2">Mentor Commission</Label>
      <Controller
        name="mentorCommission"
        defaultValue={recruiterDeal?.mentorCommission}
        rules={{ required: { value: false, message: "Mentor Commission is a required field" } }}
        control={control}
        render={(field) => (
          <Input
            {...field}
            placeholder="Mentor Percentage"
            type="text"
            onChange={({ target: { value } }) => {
              const filteredValue = value.replace(/,/g, '').replace(/[^\d.]/g, "").replace(/^\./, "0.")
              setValue("mentorCommission", filteredValue.slice(0, 4))
            }}
            onFocus={(value) => value.target.value && setValue("mentorCommission", value.target.value.replaceAll(" %", ""))}
            onBlur={(value) => value.target.value && setValue("mentorCommission", value.target.value + " %")}
          />
        )}
      />
      <small className="text-danger pt-2">
        {errors?.mentorCommission?.message}
      </small>
    </Col>

    <Col xs='12' className="mb-4 custom-form-input form-input">
      <FormGroup className=" d-flex align-items-center ml-3 my-1 pl-1">
        <Input
          defaultChecked={Boolean(recruiterDeal?.nonRecoverableDraw)}
          onChange={(value) => setNonRecoverable(value.target.checked)}
          placeholder="Select Date" type="checkbox" />
        <span className="checkboxTextStyle">Non Recoverable Draw</span>
      </FormGroup>
    </Col>

    <Col sm='6' className="mb-3 custom-form-input form-input">
      <Label className="text-14 text-blackele pb-2">Recruiter Draw Percentage <span className="text-danger">*</span></Label>
      <Controller
        name="drawPercentage"
        rules={{ required: { value: true, message: "Recruiter Draw Percentage is a required field" } }}
        control={control}
        render={(field) => (
          <Input
            {...field}
            defaultValue={recruiterDeal?.drawPercentage?.replaceAll(" ", "")}
            innerRef={register}
            placeholder="100%"
            type="text"
            onChange={({ target: { value } }) => {
              const filteredValue = value.replace(/,/g, '').replace(/[^\d.]/g, "").replace(/^\./, "0.")
              setValue("drawPercentage", filteredValue.slice(0, 4))
            }}
            onFocus={(value) => value.target.value && setValue("drawPercentage", value.target.value.replaceAll("%", "").replaceAll(" ", ""))}
            onBlur={(value) => value.target.value && setValue("drawPercentage", value.target.value + "%")}
          />
        )}
      />
      <small className="text-danger pt-2">
        {errors?.drawPercentage?.message}
      </small>
    </Col>

    <Col sm='6' className="mb-3 custom-form-input form-input">
      <Label className="text-14 text-blackele pb-2">Bi-Weekly Draw <span className="text-danger">*</span></Label>
      <Controller
        name="biWeeklySalary"
        control={control}
        rules={{ required: { value: true, message: "Bi-Weekly Draw is a required field" } }}
        render={(field) => (
          <Input
            {...field}
            defaultValue={recruiterDeal?.biWeeklySalary}
            placeholder="Amount"
            type="text"
            innerRef={register}
            onChange={({ target: { value } }) => {
              const filteredValue = value.replace(/,/g, '').replace(/\D/g, "")
              setValue("biWeeklySalary", priceHandler(filteredValue))
            }}
            onFocus={(value) => value.target.value && setValue("biWeeklySalary", value.target.value.replaceAll("$ ", ""))}
            onBlur={(value) => value.target.value && setValue("biWeeklySalary", "$ " + value.target.value)}
          />
        )}
      />
      <small className="text-danger pt-2">
        {errors?.biWeeklySalary?.message}
      </small>
    </Col>

    <Col sm='6' className="mb-3 custom-form-input form-input">
      <Label className="text-14 text-blackele pb-2">Direct Hire Base Rate <span className="text-danger">*</span></Label>
      <Controller
        name="dhBaseRate"
        rules={{ required: { value: true, message: "Direct Hire Base Rate is a required field" } }}
        control={control}
        render={(field) => (
          <Input
            {...field}
            defaultValue={recruiterDeal?.dhBaseRate?.replaceAll(" ", "")}
            placeholder="25 % "
            type="text"
            innerRef={register}
            onChange={({ target: { value } }) => {
              const filteredValue = value.replace(/,/g, '').replace(/[^\d.]/g, "").replace(/^\./, "0.")
              setValue("dhBaseRate", filteredValue.slice(0, 4))
            }}
            onFocus={(value) => value.target.value && setValue("dhBaseRate", value.target.value.replaceAll("%", "").replaceAll(" ", ""))}
            onBlur={(value) => value.target.value && setValue("dhBaseRate", value.target.value + "%")}
          />
        )}
      />
      <small className="text-danger pt-2">
        {errors?.dhBaseRate?.message}
      </small>
    </Col>

    <Col sm='6' className="mb-3 custom-form-input form-input">
      <Label className="text-14 text-blackele pb-2">Contracting Base Rate <span className="text-danger">*</span></Label>
      <Controller
        name="contractingBaseRate"
        rules={{ required: { value: true, message: "Contracting Base Rate is a required field" } }}
        control={control}
        render={(field) => (
          <Input
            {...field}
            defaultValue={recruiterDeal?.contractingBaseRate?.replaceAll(" ", "")}
            placeholder="25 %"
            type="text"
            innerRef={register}
            onChange={({ target: { value } }) => {
              const filteredValue = value.replace(/,/g, '').replace(/[^\d.]/g, "").replace(/^\./, "0.")
              setValue("contractingBaseRate", filteredValue.slice(0, 4))
            }}
            onFocus={(value) => value.target.value && setValue("contractingBaseRate", value.target.value.replaceAll("%", "").replaceAll(" ", ""))}
            onBlur={(value) => value.target.value && setValue("contractingBaseRate", value.target.value + "%")}
          />
        )}
      />
      <small className="text-danger pt-2">
        {errors?.contractingBaseRate?.message}
      </small>
    </Col>

  </Row >
}
export default BasicInformation