import Skeleton from "../Skeleton";
import "./style.scss";

type STATSCARD = {
  number?: number;
  title?: string;
  loading?: any;
};

const StatsCard = ({ number, title, loading }: STATSCARD) => {
  return (
    <div className="d-flex align-items-center justify-content-between w-100 mb-2">
      <label className="w-60 flex-grow-1">{title}</label>
      <h6 className="w-40 flex-grow-1">{loading ? <Skeleton width={40} height={18} /> : number}</h6>
    </div>
  );
};

export default StatsCard;
