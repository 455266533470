import { Table } from "reactstrap";
import { moneyFormatter } from "../../../../utils/helper";

const ClientBillingCredit = ({ netCommission, accountManagers }) => {
  return (
    <div>
      <h5 className="mb-2">Client Billing Credit</h5>

      <div className="dark-table">
        <Table className="transparentTable">
          <thead>
            <tr>
              <th style={{ width: "40%" }}>Name</th>
              <th style={{ width: "30%" }}>Credit%</th>
              <th style={{ width: "30%", textAlign: "right" }} align="right">
                Billing Credit
              </th>
            </tr>
          </thead>

          <tbody>
            {accountManagers?.length > 0 &&
              accountManagers?.map((item) => {
                return (
                  <tr>
                    <td>
                      <h6>{item?.user?.fullName}</h6>
                    </td>
                    <td>
                      <h6>{item?.splitPercentage}%</h6>
                    </td>
                    <td align="right">
                      <h6>
                        {moneyFormatter(
                          (netCommission * 0.5 * item?.splitPercentage) / 100 ||
                          0
                        )}
                      </h6>
                    </td>
                  </tr>
                );
              })}
          </tbody>

          <tfoot>
            <tr>
              <td>
                <span className="valueText">Total Credit:</span> 50%
              </td>
              <td></td>
              <td align="right">
                <span className="valueText">Total Fee: </span>

                {moneyFormatter(netCommission * 0.5 || 0)}
              </td>
            </tr>
          </tfoot>
        </Table>
      </div>
    </div>
  );
};

export default ClientBillingCredit;
