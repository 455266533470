import { useMutation } from "@apollo/client";
import { Controller, useForm } from "react-hook-form";
import ReactQuill from "react-quill";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Col,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import CustomButton from "../../../components/layout/CustomButton";
import { ACCOUNTING_DASHBOARD_ROUTE, reactQuillReg } from "../../../utils/constant";
import { ON_REJECT_PLACEMENT_ACCOUNTS } from "../../candidate-pipeline/placements/gql";
import { CREATE_NOTES } from "../../company/gql";

type PopProps = {
  isOpen: boolean;
  toggle: Function;
  candidateId: string;
  placementId: string;
};

const AccountsPersonReject = ({
  isOpen,
  toggle,
  candidateId,
  placementId,
}: PopProps) => {
  const history = useHistory();
  const [createNotes, { loading }] = useMutation(CREATE_NOTES);
  const [_onRejectPlacement] = useMutation(ON_REJECT_PLACEMENT_ACCOUNTS);

  const { control, errors, handleSubmit } = useForm();

  const rejectPlacement = async (values: any) => {
    const response = await createNotes({
      variables: {
        noteableType: "Candidate",
        noteableId: candidateId,
        category: `Placement - Accounts Rejected`,
        content: values?.content,
      },
    });

    if (response?.data) {
      await _onRejectPlacement({
        variables: {
          id: placementId,
          reason: values?.content,
        },
      });
      history.push(ACCOUNTING_DASHBOARD_ROUTE);
      toast.success(
        "Placement has been Rejected and moved to Pending manager!"
      );
      toggle();
    }
  };

  const customStyles = {
    control: (styles, provided) => ({
      ...styles,

      fontSize: 12,
    }),

    option: (provided, state) => ({
      ...provided,
      fontSize: 12,
    }),

    container: (provided, state) => ({
      ...provided,
      fontSize: 12,
    }),
  };

  return (
    <div>
      <Modal
        isOpen={isOpen}
        toggle={() => toggle()}
        scrollable
        className="for-modal-styling"
      >
        <ModalHeader toggle={() => toggle()}>
          Reject Placement Notes
        </ModalHeader>
        <Form>
          <ModalBody>
            <Row>
              <Col xs="12">
                <Controller
                  control={control}
                  name="content"
                  rules={{ required: "Please add some content!" }}
                  defaultValue={""}
                  render={({ onChange, ref, value = "" }) => (
                    <div className="editor-height">
                      <ReactQuill
                        onChange={(value) => {
                          if (reactQuillReg.test(value)) {
                            onChange("");
                          } else {
                            onChange(value);
                          }
                        }}
                        value={value}
                        ref={ref}
                      />
                    </div>
                  )}
                  styles={customStyles}
                />
                <small className="text-danger pt-2">
                  {errors?.content?.message}
                </small>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <CustomButton
              buttonText="Close"
              buttonColor="default"
              buttonType="button"
              buttonClick={() => toggle()}
            />{" "}
            <CustomButton
              buttonText="Create Note"
              buttonColor="primary"
              buttonType="submit"
              className="big-width"
              loading={loading}
              disabled={loading}
              buttonClick={handleSubmit(rejectPlacement)}
            />
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};

export default AccountsPersonReject;
