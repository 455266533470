import CustomButton from "../../../../components/layout/CustomButton";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  FormGroup,
  Label,
} from "reactstrap";
import { useContext, useState } from "react";
import { SEQUENCE_LISTING_ID_NAME } from "../../sequences/gql";
import { useLazyQuery, useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { MOVE_CONTACTS_TO_SEQUENCE } from "../gql";
import { sequenceOptions } from "../../../../utils/constant";
import { customSelectStyleNew } from "../../../../components/styled/customSelect";
import { AppContext } from "../../../../context";

export default function MoveContacts({
  moveContactsModal,
  moveContactsToggle,
  refetchStats,
  ids,
  reFetchContacts,
  setCheckBoxArray,
  compaignId,
}) {
  const { control, errors, setValue, handleSubmit } = useForm();
  const [sequenceSelection, setSequenceSelection] = useState<boolean>(false);
  const [getSequencesListing, { loading, data }] = useLazyQuery(
    SEQUENCE_LISTING_ID_NAME
  );
  const [moveContactsToSequence] = useMutation(MOVE_CONTACTS_TO_SEQUENCE);
  const { theme } = useContext(AppContext);

  const moveContacts = async (values: any) => {
    const response = await moveContactsToSequence({
      variables: {
        moveContactsToSequenceInput: { compaignId: values?.name?.value, ids },
      },
    });

    if (response?.data) {
      reFetchContacts();
      refetchStats();
      moveContactsToggle();
      setCheckBoxArray([]);
      toast.success(
        `Successfully moved ${ids?.length} to ${values?.name?.label}`
      );
    }
  };

  const getSequences = (option: any) => {
    getSequencesListing({
      variables: {
        query: "",
        businessDevelopmentSequences:
          option?.value === "BUSINESS_DEVELOPMENT" ? true : null,
      },
    });
  };

  const customStyles = {
    control: (styles, provided) => ({
      ...styles,

      fontSize: 12,
    }),

    option: (provided, state) => ({
      ...provided,
      fontSize: 12,
    }),

    container: (provided, state) => ({
      ...provided,
      fontSize: 12,
    }),
  };

  return (
    <div>
      <Modal
        isOpen={moveContactsModal}
        toggle={moveContactsToggle}
        className="revampDialog revampDialogWidth"
      >
        <ModalHeader>Move To Sequence</ModalHeader>

        <ModalBody>
          <p className="mb-2">
            Find a sequence you want to move ({ids?.length}) contacts to.
          </p>

          <FormGroup className="mb-2">
            <Label>Select Sequence Type</Label>
            <Controller
              name="type"
              render={({ onChange }) => (
                <Select
                  isSearchable={true}
                  options={sequenceOptions}
                  isLoading={loading}
                  placeholder="Select sequence type"
                  onChange={(level: any) => {
                    onChange(level);
                    setSequenceSelection(true);
                    getSequences(level);
                    setValue("type", level);
                    // onGetContactDetails(level.value);
                  }}
                  styles={{ ...customSelectStyleNew(theme) }}
                />
              )}
              control={control}
              styles={customStyles}
            />

            <>
              {sequenceSelection && (
                <div className="mt-3">
                  <Label>Select Sequence</Label>
                  <Controller
                    name="name"
                    rules={{
                      required: {
                        value: true,
                        message: "Please select any sequence!",
                      },
                    }}
                    render={({ onChange }) => (
                      <Select
                        isSearchable={true}
                        options={data?.allSequencesIdName?.map((item: any) => {
                          return {
                            label: item?.title,
                            value: item?._id,
                            isDisabled: item?._id === compaignId,
                          };
                        })}
                        isLoading={loading}
                        placeholder="Search sequence to preview and move contacts into it"
                        onChange={(level: any) => {
                          onChange(level);
                          setValue("name", level);
                          // onGetContactDetails(level.value);
                        }}
                        styles={{ ...customSelectStyleNew(theme) }}
                      />
                    )}
                    control={control}
                    styles={customStyles}
                  />
                  <small className="text-danger">{errors?.name?.message}</small>
                </div>
              )}
            </>
          </FormGroup>
        </ModalBody>

        <ModalFooter>
          <button
            onClick={moveContactsToggle}
            className="buttonPrimary mr-3"
            type="button"
          >
            Cancel
          </button>

          <CustomButton
            buttonText={`Move ${ids?.length} Contacts`}
            buttonColor="primary"
            buttonType="button"
            buttonClick={handleSubmit(moveContacts)}
          />
        </ModalFooter>
      </Modal>
    </div>
  );
}
