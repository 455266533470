const WorkSiteLocation = ({ workSiteInfo }) => {
  return (
    <div className="jobOrderRevamp" style={{ minHeight: 195 }}>
      <h5>Work / Job Site Location</h5>
      <div className="d-flex justify-content-between mb-2 contentGridLabel">
        <label>Street Address</label>
        <h6>{workSiteInfo?.street}</h6>
      </div>

      <div className="d-flex justify-content-between mb-2 contentGridLabel">
        <label>City</label>
        <h6>{workSiteInfo?.city}</h6>
      </div>
      {['US',null,undefined,''].includes(workSiteInfo?.country)? (
          <div className="d-flex justify-content-between mb-2 contentGridLabel">
          <label>State</label>
          <h6>{workSiteInfo?.stateCode}</h6>
        </div>
        ) :(
          <div className="d-flex justify-content-between mb-2 contentGridLabel">
          <label>Country</label>
          <h6>{workSiteInfo?.country}</h6>
        </div>
        )}
     

      <div className="d-flex justify-content-between mb-2 contentGridLabel">
        <label>Zip Code</label>
        <h6>{workSiteInfo?.postalCode}</h6>
      </div>
    </div>
  );
};

export default WorkSiteLocation;
