import { gql } from "@apollo/client";
import AsyncSelect from "react-select/async";
import client from "../apollo";
import { toast } from "react-toastify";
import { useContext } from "react";
import { AppContext } from "../context";
let time = 0;

export const FETCH_COMPANIES = gql`
  query FetchAllCompanies($page: Int!, $limit: Int!, $searchText: String,$havingOwner:Boolean) {
    companiesForDropDown(
      payload: { page: $page, limit: $limit }
      filters: { searchText: $searchText }
      havingOwner:$havingOwner
    ) {
      companies {
        id
        name
        createdAt
      }
      count
    }
  }
`;

export const FETCH_COMPANIES_OWNER_BASED = gql`
  query FetchCompanyOfOwner(
    $page: Int!
    $limit: Int!
    $searchText: String
    $ownerIdFlag: Boolean
  ) {
    fetchCompaniesOfCurrentUser(
      payload: { page: $page, limit: $limit }
      filters: { searchText: $searchText, ownerIdFlag: $ownerIdFlag }
    ) {
      id
      name
      createdAt
      owner {
        id
        fullName
      }
    }
  }
`;

const AsyncCompanyDropdown = (props) => {
  const { theme } = useContext(AppContext);
  // const [selectValue, setSelectValue] = useState();
  const _filterCompanies = async (inputValue: string) => {
    try {
      let finalVariablePoint: any = props?.ownerIdFlag
        ? {
          variables: {
            limit: 20,
            page: 1,
            searchText: inputValue,
            ownerIdFlag: props?.ownerIdFlag,
          },
        }
        : {
          variables: {
            limit: 20,
            page: 1,
            havingOwner: props?.havingOwner,
            searchText: inputValue,
          },
        };

      const { data } = await client.query({
        query: props?.ownershareeFlag
          ? FETCH_COMPANIES_OWNER_BASED
          : FETCH_COMPANIES,
        variables: {
          ...finalVariablePoint.variables,
        },
      });

      const companies = !props?.ownershareeFlag
        ? data && data?.companiesForDropDown?.companies
        : data?.fetchCompaniesOfCurrentUser;

      return companies?.length
        ? companies?.map((item: { id: string; name: string }) => ({
          value: item.id,
          label: `${props?.mergeCompany ? `${item?.name} (${item.id})` : item.name
            }`,
        }))
        : [];
    } catch (error) {
      toast.error("Error while loading companies!");
    }
  };

  const promiseOptions = (value: any): any =>
    new Promise((resolve) => {
      if (!value) {
        resolve(_filterCompanies(value));
      } else {
        window.clearTimeout(time);
        time = window.setTimeout(() => {
          resolve(_filterCompanies(value));
        }, 1000);
      }
    });

  const customStyles = {
    control: (styles, provided) => ({
      ...styles,
      width: `${props.width} !important`,
      background: theme === "light" ? provided.background : "#282828",
      borderColor: theme === "light" ? "#ced4da" : "#525252",
      color: theme === "light" ? provided.background : "#d9d9d9",
      fontSize: 12,
      "&:hover": {
        borderColor: theme === "light" ? "#ced4da" : "#525252",
        color: theme === "light" ? provided.background : "#d9d9d9",
      },
    }),
    menu: (provided) => ({
      ...provided,
      background: theme === "light" ? provided.background : "#282828",
      zIndex: props?.zIndex || 999999,
      color: theme === "light" ? provided.background : "#d9d9d9",
    }),
    option: (provided) => ({
      ...provided,
      fontSize: 12,
      background: theme === "light" ? provided.background : "#363636",
      "&:hover": {
        background: theme === "light" ? provided.background : "#474444",
        color: theme === "light" ? provided.background : "#d9d9d9",
      },
    }),
    container: (provided, state) => ({
      ...provided,
      fontSize: 12,
    }),
  };

  return (
    <>
      <AsyncSelect
        cacheOptions
        isDisabled={props?.isDisabled || false}
        value={props?.value?.value ? props?.value : null}
        isSearchable
        isClearable
        // {...(props?.selectedCompany?.value && {
        //   value: {
        //     value: props.selectedCompany.value,
        //     label: props.selectedCompany.label,
        //   },
        // })}
        ref={props.ref}
        styles={{ ...customStyles }}
        defaultOptions
        onChange={(selected: any) => {
          if (props.onSelected) {
            if (selected) {
              props.onSelected(selected);
            } else {
              props.onSelected({});
            }
          }
          if (selected) {
            // setSelectValue(selected);
            props.onChange(selected);
          } else {
            props.onChange("");
          }
        }}
        loadOptions={promiseOptions}
        placeholder={props?.placeholder}
      />
    </>
  );
};

export default AsyncCompanyDropdown;
