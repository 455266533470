import { useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Form, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import CustomButton from "../../../components/layout/CustomButton";
import InputField from "../../../components/layout/InputField";
import { EMAIL_PATTERN } from "../../../utils/constant";
import { SEND_TEST_EMAIL } from "../gql";

export default function TestEmailSignature({ userId, modal, toggle }) {
  const { errors, handleSubmit, register } = useForm();

  const [sendEmailForSignatureTesting, { loading }] =
    useMutation(SEND_TEST_EMAIL);

  const sendingEmailSignature = async (values: any) => {
    const res = await sendEmailForSignatureTesting({
      variables: {
        userId,
        emailTo: values?.email,
      },
    });

    if (res.data) {
      toggle();
      toast.success(`Test email sent to ${values?.email}`);
    }
  };

  return (
    <Modal isOpen={modal} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle}>Email Signature</ModalHeader>
      <Form>
        <ModalBody>
          <InputField
            label="Email"
            isRequired
            inputtype="email"
            placeholder="Email"
            inputid="email"
            inputRef={register({
              required: {
                value: true,
                message: "Email is required!",
              },
              pattern: {
                value: EMAIL_PATTERN,
                message: "Email not valid!",
              },
            })}
            errMsg={errors?.email?.message}
          />
        </ModalBody>
      </Form>

      <ModalFooter className="justify-content-between">
        <div className="d-flex align-items-center justify-content-end w-100">
          <button type="button" onClick={toggle} className="buttonPrimary mr-3">
            Cancel
          </button>

          <CustomButton
            buttonText={"Send email"}
            buttonColor="primary"
            buttonType="submit"
            className="big-width"
            buttonClick={handleSubmit(sendingEmailSignature)}
            loading={loading}
            disabled={loading}
          />
        </div>
      </ModalFooter>
    </Modal>
  );
}
