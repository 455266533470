import { useHistory } from "react-router-dom";
import { CONTACT_DETAIL_ROUTE } from "../../../../utils/constant";

const ClientBillingContact = ({ billingContactInfo }) => {
  const history = useHistory()
  return (
    <div>
      <h5 className="mb-2">Client Billing Contact</h5>

      <div className="d-flex justify-content-between mb-1 contentGridLabel">
        <label>Name</label>
        <h6
          className="anchorColorText route-link"
          onClick={() => {
            history.push({
              pathname: `${CONTACT_DETAIL_ROUTE}/${billingContactInfo?.billingContactId}`,
            });
          }}
        >{billingContactInfo?.billingContactName || '--'}</h6>
      </div>

      <div className="d-flex justify-content-between mb-1 contentGridLabel">
        <label>Title</label>
        <h6>{billingContactInfo?.billingContactTitle || '--'}</h6>
      </div>

      <div className="d-flex justify-content-between mb-1 contentGridLabel">
        <label>Email</label>
        <h6>{billingContactInfo?.email || '--'}</h6>
      </div>
    </div>
  );
};

export default ClientBillingContact;
