import { useMutation } from '@apollo/client';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import CustomButton from '../../../../components/layout/CustomButton';
import ContactEmail from '../../../contacts/contact-components/ContactEmail';
import { CREATE_COMPANY_CONTACT } from '../../../contacts/gql/index';

const CreateContact = ({
  companyId,
  setValue,
  contactList,
  setContactList,
  setNewContactFieldsModal,
  setCustomEmailInputs,
  customEmailInputs,
  jobCCContactIds
}) => {

  const [emailErr, setEmailErr] = useState('');
  const [createCompanyContact, { loading: waiting }] = useMutation(
    CREATE_COMPANY_CONTACT
  );
  const { register, handleSubmit, errors, reset } = useForm();

  const onSubmit = async (values) => {
    let emails = customEmailInputs.filter((item: any) => item.email && item);
    if (!emails?.length || emailErr) {
      if(!emails?.length){
        setEmailErr('Minimum one email is required');
      }else if(emailErr){
        setEmailErr('Email is not valid!');
      }
      return;
    }
    values.contactEmails = emails;
    values.companyId = companyId;
    values.source= "Current Client"
    let createdContactsResponse = await createCompanyContact({
      variables: { createContactInput: { ...values } },
    });
    if (createdContactsResponse?.data?.createContact) {
      setContactList([
        ...contactList,
        {
          label: `${createdContactsResponse?.data?.createContact.firstName} ${createdContactsResponse?.data?.createContact.lastName}`,
          value: createdContactsResponse?.data?.createContact.id,
        },
      ]);
      setNewContactFieldsModal(false);
    
      setCustomEmailInputs([
    {
      email: '',
      isPrimary: true,
    },
  ]);
      reset();
      if(jobCCContactIds?.length){
        setValue('jobCCContactIds',[...jobCCContactIds,   {
          label: `${createdContactsResponse?.data?.createContact.firstName} ${createdContactsResponse?.data?.createContact.lastName}`,
          value: createdContactsResponse?.data?.createContact.id,
        }, ])

      }else{
        setValue('jobCCContactIds',[ {
          label: `${createdContactsResponse?.data?.createContact.firstName} ${createdContactsResponse?.data?.createContact.lastName}`,
          value: createdContactsResponse?.data?.createContact.id,
        }, ])
      }
      toast.success('Contact Added Successfully!');
    } else {
      setNewContactFieldsModal(false);
      reset();
      setCustomEmailInputs([
    {
      email: '',
      isPrimary: true,
    },
  ]);
      toast.success('Something Went Wrong');
    }
  };
  return (
    <Form>
      <FormGroup>
         <Row className="mb-2">
    

          {/* <Col>
            <Label>
              company <span className="text-danger">*</span>
            </Label>
            <Input
              inputtype="text"
              placeholder="company"
              disabled
              innerRef={registerForJobTitle({
                required: {
                  value: true,
                  message: 'Company is required!',
                },
              })}
              name="companyName"
            />
          </Col> */}
        </Row> 
        <Row className="mb-2">
        <Col>
            <Label>
              Job Title <span className="text-danger">*</span>
            </Label>
            <Input
              inputtype="text"
              placeholder="job Title"
              innerRef={register({
                required: {
                  value: true,
                  message: 'Job Title is required!',
                },
              })}
              name="jobTitle"
            />
             <div className="titile-count-message mt-1 d-flex flex-column">
              <small className="text-danger">{errors?.jobTitle?.message}</small>
            </div>
          </Col>
          <Col>
            <Label>
              First Name <span className="text-danger">*</span>
            </Label>
            <Input
              inputtype="text"
              placeholder="First Name"
              innerRef={register({
                required: {
                  value: true,
                  message: 'First Name is required!',
                },
              })}
              name="firstName"
            />
            <div className="titile-count-message mt-1 d-flex flex-column">
              <small className="text-danger">
                {errors?.firstName?.message}
              </small>
            </div>
          </Col>

          <Col>
            <Label>
              Last Name <span className="text-danger">*</span>
            </Label>
            <Input
              inputtype="text"
              placeholder="Last Name"
              innerRef={register({
                required: {
                  value: true,
                  message: 'Last Name is required!',
                },
              })}
              name="lastName"
            />
            <div className="titile-count-message mt-1 d-flex flex-column">
              <small className="text-danger">{errors?.lastName?.message}</small>
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <ContactEmail
              setCustomEmailInputs={setCustomEmailInputs}
              customEmailInputs={customEmailInputs}
              emailErr={emailErr}
              setEmailErr={setEmailErr}
              placement={true}
            />
          </Col>
        </Row>

        <div className="justify-content-end d-flex align-items-start">
          <CustomButton
            buttonText="Create"
            buttonColor="primary"
            buttonType="button"
            className="big-width"
            buttonClick={handleSubmit(onSubmit)}
            loading={waiting}
          />
        </div>
      </FormGroup>
    </Form>
  );
};

export default CreateContact;
