import Select from 'react-select';
import { FC, useContext, useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { AppContext } from '../../context';
import { MODULE_ACCESS_OPTIONS, moducleAcessDTO } from '../../utils/constant';
import { Col, Form, Label, Row } from 'reactstrap';
import { ModuleDrawerProps } from '../../interfaces';
import { ACCESS, MODULE, MODULE_ACCESS, SAVE, USERS } from '../../constants';
import InputField from '../../components/layout/InputField';
import { DrawerHeader, Sidedrawer } from '../../components/layout/Sidedrawer';
import {
  customSelectStyleNew,
} from '../../components/styled/customSelect';
import { useMutation } from '@apollo/client';
import { SAVE_MODAL_ACCESS_SETTINGS } from './gql';
import { toast } from 'react-toastify';
import { addAccessModuleItemToLocalStorage } from '../../utils/commonFn';

const DrawerComponent: FC<ModuleDrawerProps> = ({
  isOpen,
  toggle,
  data,
  refetchSettings,
  users, handleSubmit, register, control, setSelectedAccess, selectedAccess, errors, setValue
}) => {
  const { theme, user } = useContext(AppContext);
  const [saveModalAccessSettings, { loading }] = useMutation(SAVE_MODAL_ACCESS_SETTINGS);
  useEffect(() => {
    setValue('users', data?.userIDName);
    setValue('feature', data?.feature);
    const matchedOption = MODULE_ACCESS_OPTIONS.find(
      (option) => option?.value === data?.type
    );
    setSelectedAccess(matchedOption ? matchedOption : null);
    setValue('type', matchedOption);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  const submitData = async (data) => {
    try {
      const inputData = {
        feature: moducleAcessDTO[data?.feature],
        type: data?.type?.label,
        user: data.users?.map(item => item?.value)
      }

      const settingsResp = await saveModalAccessSettings({
        variables: { settingDetails: inputData }
      })

      if (settingsResp?.data?.SaveModalAccessSettings?.status === 200) {
        addAccessModuleItemToLocalStorage(inputData, user?.id)
        toast.success(`${data?.feature} Settings Save Successfully`);
        toggle();
        refetchSettings();
      }
    }
    catch (error) {
      toast.error("Unable to save settings");
      toggle();
    }
  };

  return (
    <Sidedrawer toggle={toggle} isOpen={isOpen}>

      <div className="mb-4">
        <DrawerHeader
          buttonText={SAVE}
          heading={MODULE_ACCESS}
          prevClick={() => toggle()}
          loading={loading}
          disabled={loading}
          nextClick={handleSubmit(submitData)}
        />
      </div>
      <div className="pb-4">
        <Form>
          <Row className="mb-3">
            <Col md="12">
              <InputField
                inputtype="text"
                label={MODULE}
                readOnly
                placeholder={'Module Name'}
                inputid="feature"
                inputRef={register()}
                style={{ backgroundColor: '#EFEFEF' }}
              />
            </Col>

            <Col md="12">
              <Label>{ACCESS}</Label>
              <Controller
                control={control}
                name="type"
                options={MODULE_ACCESS_OPTIONS?.map((item) => {
                  return {
                    label: item.label,
                    value: item.value,
                  };
                })}
                render={({ onChange, value }) => (
                  <Select
                    value={value}
                    options={MODULE_ACCESS_OPTIONS}
                    isSearchable={false}
                    placeholder="Access"
                    onChange={(target) => {
                      onChange(target);
                      setSelectedAccess(target);
                    }}
                    styles={{ ...customSelectStyleNew(theme) }}
                  />
                )}
              />
            </Col>
          </Row>

          <Row>
            <Col md="12">
              <Label>{USERS}</Label>  {selectedAccess?.label === 'INDIVIDUAL' && (<span className="text-danger">*</span>)}
              <Controller
                control={control}
                name="users"
                rules={{
                  required: {
                    value: selectedAccess?.label === 'INDIVIDUAL',
                    message: 'Atleast one user must be selected!',
                  },
                }}
                options={
                  users?.allActiveUsers?.length &&
                  users?.allActiveUsers?.map((item: any) => ({
                    label: item?.fullName,
                    value: item?.id,
                  }))
                }
                render={({ onChange, value }) => (
                  <Select
                    isMulti
                    value={selectedAccess?.value === 'Global' ? null : value}
                    isDisabled={selectedAccess?.value === 'Global'}
                    options={
                      users?.allActiveUsers?.length &&
                      users?.allActiveUsers?.map((item: any) => ({
                        label: item?.fullName,
                        value: item?.id,
                      }))
                    }
                    isSearchable={true}
                    placeholder="Select Users"
                    onChange={(target) => {
                      onChange(target);
                    }}
                    styles={{ ...customSelectStyleNew(theme) }}
                  />
                )}
              />

              <small className="text-danger">
                {errors?.users?.message}
              </small>
            </Col>
          </Row>

        </Form>
      </div>
    </Sidedrawer>
  );
};

export default DrawerComponent;
