import { gql } from "@apollo/client";

export const GET_MERGE_CONTACTS = gql`
  query GetMergeContacts(
    $page: Int!
    $limit: Int!
    $searchText: String
    $statusSearch: String
    $contactId: String
  ) {
    getMargeContacts(
      filters: {
        searchText: $searchText
        statusSearch: $statusSearch
        contactId: $contactId
      }
      payload: { limit: $limit, page: $page }
    ) {
      mergeContacts {
        id
        mergedAt
        status
        mergeNotes
        createdAt
        sourceContactName
        destinationContactName
        sourceContact {
          id
          fullName
        }
        destinationContact {
          id
          fullName
        }
      }
      count
    }
  }
`;

export const CREATE_MERGE_CONTACT = gql`
  mutation CreateMergeContact(
    $sourceContactId: String!
    $destinationContactId: String!
    $mergeNotes: String
  ) {
    createMergeContact(
      mergeContactInput: {
        mergeNotes: $mergeNotes
        sourceContactId: $sourceContactId
        destinationContactId: $destinationContactId
      }
    ) {
      message
      status
    }
  }
`;

export const DELETE_MERGE_CONTACT = gql`
  mutation DeleteMergeContact($mergeContactId: String!) {
    deleteMergeContact(mergeContactId: $mergeContactId) {
      message
      status
    }
  }
`;

export const MERGE_CONTACT_STATUS_CHANGE = gql`
  mutation MergeContactStatusChange($mergeContactId: String!) {
    mergeContactStatusChange(mergeContactId: $mergeContactId) {
      message
      status
    }
  }
`;
