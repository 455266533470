import { useState } from "react";
import { toast } from "react-toastify";

const useHTTPGPTAXIOS = () => {
  const endpoint = process.env.REACT_APP_CHATGPT_ENDPOINT || "https://api.openai.com/v1/chat/completions"

  const model16k = "gpt-3.5-turbo-16k";
  const model14k = "gpt-3.5-turbo";

  const headers = {
    "Content-Type": "application/json",
    Authorization:  process.env.REACT_APP_CHATGPT_KEY || `Bearer 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`,
  };

  const [chunkMessage, setChunkMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const sendRequest = async (messages = null) => {
    setLoading(true);
    setError(null);

    const body = {
      model: messages[messages?.length - 1]?.content?.length / 4 <= 4096 ? model14k : model16k,
      messages,
      stream: false, // Use regular data fetching, not streaming
    };

    try {
      const response = await fetch(endpoint, {
        method: "POST",
        headers,
        body: JSON.stringify(body),
      });

      if (!response.ok && response.status === 401) {
        toast("Access Token is not valid!");
      }
      const responseData = await response.json();
      const message = responseData.choices
        .map((choice) => choice.message.content || "")
        .join("");
      setChunkMessage(message);
      return message
    } catch (err) {
      setError(err.message || "An error occurred.");
    } finally {
      setLoading(false);
    }
  };

  return {
    post: (body) => sendRequest(body),
    chunkMessage,
    loading,
    error,
  };
};

export default useHTTPGPTAXIOS;
