import { Col, Row } from "reactstrap";
import PreviewStep from "./step-components/PreviewStep";
import StepsListing from "./StepsListing";
import { IStepContainerProps } from "./interfaceSteps";
import "./step.scss";

export default function StepContainer({
  compaignId,
  sequenceSteps,
  refetchSteps,
  stepSubject,
  tabId,
  loading,
  otherSteps,
  emailContactStep,
  setEmailContactStep,
  setEmailTemp,
  emailTemp,
  setPersonzailedStepID,
  personzailedStepID,
  setstepIndexNumber,
  stepIndexNumber,
  contactIdForTab,
  setContactIdForTab
}: IStepContainerProps) {
  return (
    <Row>
      <Col sm={12} className="col-sm-12 col-md-7">
        <StepsListing
          stepLoading={loading}
          otherSteps={otherSteps}
          stepSubject={stepSubject}
          refetchStepsListingStep={refetchSteps}
          compaignId={compaignId}
          sequenceSteps={sequenceSteps}
          setEmailTemp={setEmailTemp}
          setPersonzailedStepID={setPersonzailedStepID}
          setstepIndexNumber={setstepIndexNumber} 
                    
        />
      </Col>

      <Col sm={12} className="col-sm-12 col-md-5">
        <PreviewStep
          tabId={tabId}
          compaignId={compaignId}
          refetchStepsPreviewStep={refetchSteps}
          emailContactStep={emailContactStep}
          setEmailContactStep={setEmailContactStep}
          setEmailTemp={setEmailTemp}
          emailTemp={emailTemp}
          stepLoading={loading}
          personzailedStepID={personzailedStepID}
          setstepIndexNumber={setstepIndexNumber}
          stepIndexNumber={stepIndexNumber}
          setContactIdForTab={setContactIdForTab}
          contactIdForTab={contactIdForTab}
        />
      </Col>
    </Row>
  );
}
