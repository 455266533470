import { useLazyQuery, useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import Loader from "../../components/layout/Loader";
import TableComponent from "../../components/layout/TableComponent";
import { compactObject } from "../../utils/commonFn";
import Pagination from "../../components/layout/Pagination";
import { Input, InputGroup, InputGroupAddon, InputGroupText } from "reactstrap";
import InputField from "../../components/layout/InputField";
import { PAGE_LIMIT, RECRUITER_FOLDERS_ROUTE, RECRUITER_LISTING_HEADER, VIEW_RECRUITER_FOLDER_ROUTE } from "../../utils/constant";
import SEARCH_IMG from "../../assets/images/search.svg";
import NoDataFound from "../../components/layout/NoDataFound";
import { DELETE_RECRUITER_FOLDER, GET_FOLDER_LISTING } from "./gql";
import CreateFolderModal from "./folderComponents/CreateFolderModal";
import { toast } from "react-toastify";
import ConfirmPopUp from "../../components/ConfirmPopUp";
import { getRecruitersFolderParams } from "../../utils/getNoteParams";

import VIEW_ICON from "../../assets/images/view-icon.svg";
import EDIT_ICON from "../../assets/images/edit-icon-menu.svg";
import DELETE_ICON from "../../assets/images/delete-icon-menu.svg";
import { pushInHistory } from "../../utils/helper";

const initialFolderDetail = {
  folderName: "",
  folderId: "",
  isOpen: false,
};
let time = 0;

const FolderListing = ({ location, history }: RouteComponentProps) => {
  const page = new URLSearchParams(location.search).get("currentPage") || "1";
  const _currentPage: number = parseInt(page);
  const [currentPage, setCurrentPage] = useState(_currentPage);
  const [limit, setLimit] = useState(10);
  const [searchParams, setSearchParams] = useState<string>("");
  const [createModal, setCreateModal] = useState<boolean>(false);
  const [folderDetail, setFolderDetail] = useState<any>(initialFolderDetail);
  const createFolderToggle = () => setCreateModal(!createModal);
  const [removeFolder] = useMutation(DELETE_RECRUITER_FOLDER);

  const [getFolderList, { loading, error, data }] =
    useLazyQuery(GET_FOLDER_LISTING);

  const _getFolderList = () => {
    setQueryParams(searchParams);

    if (!searchParams || searchParams?.length > 2) {
      getFolderList({
        variables: {
          limit,
          page: currentPage,
          searchText: searchParams || "",
        },
      });
    }
  };

  const setQueryParams = (searchParams: string) => {
    const criteria = compactObject({
      currentPage,
      searchParams,
      limit,
    });
    const urlQueryParams: any = [];
    if (Object.keys(criteria).length) {
      for (const key in criteria) {
        if (
          Object.prototype.hasOwnProperty.call(criteria, key) &&
          criteria[key]
        ) {
          urlQueryParams.push(`${key}=${criteria[key]}`);
        }
      }
      history.push(`${RECRUITER_FOLDERS_ROUTE}?${urlQueryParams.join("&")}`);
    }
  };

  const searchControl = (value: any) => {
    setQueryParams(value);
    setSearchParams(value);
    setCurrentPage(1);
    setLimit(25);
    if (!value || value?.length > 2) {
      window.clearTimeout(time);
      time = window.setTimeout(() => {
        getFolderList({
          variables: {
            limit,
            page: currentPage,
            searchText: value,
          },
        });
      }, 1000);
    }
  };

  const deleteRecruiterFolder = async () => {
    if (folderDetail?.folderId) {
      let res = await removeFolder({
        variables: { folderId: folderDetail?.folderId },
      });
      if (res?.data) {
        toast.success("Deleted successfully!");
        setFolderDetail(initialFolderDetail);
        _getFolderList();
      }
    }
  };

  useEffect(() => {
    document.title = "RecruiterFolder - RealREPP";

    let params: any = getRecruitersFolderParams(location.search);

    if (params) {
      setCurrentPage(params?.currentPage ? parseInt(params?.currentPage) : 1);
      setSearchParams(params?.searchParams);
      setLimit(params?.limit ? parseInt(params?.limit) : 10);
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    _getFolderList();

    // eslint-disable-next-line
  }, [currentPage, limit]);

  return (
    <div className="admin-tabs team-tabs">
      <div className="primaryHeading">
        <h5 className="m-0">Recruiter Folder List</h5>
      </div>

      <div className="search-filter primaryHeaderSpacing">
        <div>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <img src={SEARCH_IMG} alt="search" />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              type="text"
              placeholder="Search by folder name"
              value={searchParams}
              onChange={(e) => {
                setSearchParams(e.target.value);
                searchControl(e.target.value);
              }}
              className="search-by-company"
            />
          </InputGroup>

          <small className="text-info">
            {searchParams &&
              searchParams.length <= 2 &&
              " Please add more than two characters"}
          </small>
        </div>

        <div className="addFolderButtons">
          <button
            type="button"
            onClick={() => {
              createFolderToggle();
              setFolderDetail(initialFolderDetail);
            }}
            className="buttonGenericStyle filledButton"
          >
            Create Folders
          </button>
        </div>
      </div>

      <div className="table-responsive recruiterFolderList">
        <TableComponent tableHeader={RECRUITER_LISTING_HEADER}>
          {loading && (
            <tbody>
              <tr>
                <td colSpan={11}>
                  <Loader loaderClass="sm-height " />
                </td>
              </tr>
            </tbody>
          )}

          {!loading && !data?.fetchAllFolders?.recruiterFolders.length && (
            <tbody>
              <tr>
                <td colSpan={11} className="empty-table-td">
                  <NoDataFound text="No Recruiter Folder Found." />
                </td>
              </tr>
            </tbody>
          )}

          <tbody>
            {!loading &&
              data &&
              data?.fetchAllFolders &&
              data?.fetchAllFolders?.recruiterFolders?.map(
                (item: any, index: number) => {
                  return (
                    <tr key={index}>
                      <td
                        className="cursor-pointer"
                        onClick={() => {
                          history.push(`${VIEW_RECRUITER_FOLDER_ROUTE}/${item?.id}`);
                          pushInHistory(RECRUITER_FOLDERS_ROUTE)
                        }}
                      >
                        {item.folderName}
                      </td>

                      <td>{item?.currentCount || "--"}</td>

                      <td align="right">
                        <div className="d-flex align-items-center actionIconsGridWidth">
                          <button className="iconButtonAction bg-transparent border-0 p-0 pb-1">
                            <Link
                              onClick={() => pushInHistory(RECRUITER_FOLDERS_ROUTE, location.search)}
                              to={{
                                pathname: `${VIEW_RECRUITER_FOLDER_ROUTE}/${item?.id}`,
                                state: { searchParameters: location.search },
                              }}
                              className=" iconButtonAction"
                            >
                              <img
                                src={VIEW_ICON}
                                alt="view"
                                className="iconStyle"
                              />
                            </Link>
                          </button>

                          <button
                            onClick={() => {
                              createFolderToggle();
                              setFolderDetail({
                                folderName: item?.folderName,
                                folderId: item?.id,
                                isOpen: false,
                              });
                            }}
                            className="iconButtonAction bg-transparent border-0 p-0 pb-1"
                          >
                            <img src={EDIT_ICON} alt="edit" />
                          </button>
                          <button
                            className="iconButtonAction bg-transparent border-0 p-0 pb-1"
                            onClick={() =>
                              setFolderDetail({
                                folderName: item?.folderName,
                                folderId: item?.id,
                                isOpen: true,
                              })
                            }
                          >
                            <img src={DELETE_ICON} alt="delete" />
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                }
              )}
          </tbody>
        </TableComponent>
      </div>
      
      {data?.fetchAllFolders?.recruiterFolders?.length ? (
        <div className="users-pagination">
          <Pagination
            onPageChange={(pageClicked: number) => {
              setCurrentPage(pageClicked);
            }}
            pageCount={Math.ceil(data?.fetchAllFolders?.count / limit)}
            currentPage={currentPage}
          />

          <div className="d-flex align-items-center">
            <div className="pagination-display paginationItemsSelect">
              <p className="m-0">
                {(currentPage - 1) * limit + 1} to{" "}
                {Math.min(currentPage * limit, data?.fetchAllFolders?.count)} of{" "}
                {data?.fetchAllFolders?.count}
              </p>

              <div className="divider"></div>

              <label>Display</label>

              <InputField
                label=""
                inputtype="select"
                selectItems={PAGE_LIMIT.filter((item) => item.value !== limit)}
                inputid="limit"
                placeholder={limit.toString()}
                inputMethod={(value: string) => {
                  setCurrentPage(1);
                  setLimit(parseInt(value));
                }}
              />
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {error?.graphQLErrors[0]?.message}

      {createModal && (
        <CreateFolderModal
          isOpen={createModal}
          toggle={createFolderToggle}
          reFetch={_getFolderList}
          folderName={folderDetail?.folderName}
          folderId={folderDetail?.folderId}
        />
      )}

      <ConfirmPopUp
        confirmText="Are you sure you want to delete"
        extraProp={folderDetail?.folderName}
        secondaryText="folder?"
        isOpen={folderDetail?.isOpen}
        toggle={() =>
          setFolderDetail({
            ...folderDetail,
            isOpen: !folderDetail?.isOpen,
          })
        }
        confirmAction={deleteRecruiterFolder}
        modalHeading="Delete Folder"
        btnText="Delete"
        btnColor="primary"
        className="for-modal-styling"
      />
    </div>
  );
};

export default FolderListing;
