import Select from 'react-select';
import { FC, useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { AppContext } from '../../context';
import { CHATGPTMODULE_OPTIONS, gptTemplateTagsArray } from '../../utils/constant';
import { Button, Col, Form, Label, Row } from 'reactstrap';
import { ChatGptTemplateUpdateDrawerProps, ChatGptTemplateUpdateFormValues } from '../../interfaces';
import { MODULE_ACCESS, SAVE } from '../../constants';
import { DrawerHeader, Sidedrawer } from '../../components/layout/Sidedrawer';
import {
  customSelectStyleNew,
} from '../../components/styled/customSelect';
import { useMutation } from '@apollo/client';
import { SAVE_CHATGPT_TEMPLATES } from './gql';
import { toast } from 'react-toastify';

const DrawerComponent: FC<ChatGptTemplateUpdateDrawerProps> = ({
  isOpen, toggle, data, refetchGPTTemplates
}) => {
  const { register, setValue, handleSubmit, control } =
    useForm<ChatGptTemplateUpdateFormValues>();
  const { theme } = useContext(AppContext);
  const [saveChatgptTemplates, { loading: saveChatgptTemplatesLoading }] = useMutation(SAVE_CHATGPT_TEMPLATES);
  const [copied, setCopy] = useState<boolean>(false);

  const onCopyText = (item: string, index: number) => {
    navigator.clipboard.writeText(item);
    setCopy(true);

    setTimeout(() => {
      setCopy(false);
    }, 1500);
  };
  const submitData = async (data) => {
    const settingsdata = {
      module: data?.module?.value,
      template: data?.template,
    }
    const settingsResp = await saveChatgptTemplates({
      variables: { settingDetails: settingsdata }
    })

    if (settingsResp?.data?.SaveChatgptTemplates?.status === 200) {
      toast.success(`ChatGPT Templates Save Successfully`);
      toggle();
      refetchGPTTemplates();
    }
  };

  useEffect(() => {
    if (isOpen && data) {
      const matchedOption = CHATGPTMODULE_OPTIONS.find(
        (option) => option?.value === data?.module
      );
      setValue('module', matchedOption);
      setValue('template', data?.template);
    }
  }, [data, isOpen, setValue])

  return (
    <Sidedrawer toggle={toggle} isOpen={isOpen}>
      <div className="mb-4">
        <DrawerHeader
          loading={saveChatgptTemplatesLoading}
          disabled={saveChatgptTemplatesLoading}
          buttonText={SAVE}
          heading={MODULE_ACCESS}
          prevClick={() => toggle()}
          nextClick={handleSubmit(submitData)}
        />
      </div>
      <div className="pb-4">
        <Form>
          <Row className="mb-3">
            <Col md="12">
              <Label>Module</Label>
              <Controller
                control={control}
                name="module"
                options={CHATGPTMODULE_OPTIONS?.map((item) => {
                  return {
                    label: item.label,
                    value: item.value,
                  };
                })}
                render={({ onChange, value }) => (
                  <Select
                    value={value}
                    isDisabled={true}
                    options={CHATGPTMODULE_OPTIONS}
                    isSearchable={false}
                    placeholder="Select Module For Template"
                    onChange={(target) => {
                      onChange(target);
                    }}
                    styles={{ ...customSelectStyleNew(theme) }}
                  />
                )}
              />
            </Col>
          </Row>
          <div className="w-100 d-flex flex-wrap mt-2 position-relative">
            {copied && (
              <span className="badge badge-main badge-warning">Copied</span>
            )}
            {gptTemplateTagsArray?.map((item: string, index) => {
              return (
                <span
                  className="steps-tags-template"
                  onClick={() => onCopyText(item, index)}
                  id={`items-${index}`}
                  key={index}
                >
                  {item.split(', ')}
                </span>
              );
            })}
          </div>

          <Row>
            <Col md="12">
              <Col>
                <Row md="12" className='d-flex justify-content-between align-items-center'>
                  <Label>Template</Label>
                  <Button className='btn px-3 mb-2 btn-next'
                    onClick={() => {
                      console.log("cc")
                      console.log(data?.originalTemplate)
                      setValue("template", data?.originalTemplate)
                    }}
                  >Original</Button>
                </Row>
              </Col>
              <textarea
                name="template"
                placeholder="Please add template"
                rows={10}
                className="form-control"
                ref={register()}
              />
            </Col>
          </Row>
        </Form>
      </div>
    </Sidedrawer>
  );
};

export default DrawerComponent;
