export const emailSignatureType = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  companyUrl: "http://www.realrepp.com",
  linkedinURL: "",
  fbUrl: "",
  twitterUrl: "",
  instaUrl: "",
  youtubeUrl: "",
  jobTitle: "",
  mobile: "",
};
