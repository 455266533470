import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Button,
  Row,
  Col,
} from 'reactstrap';
import FeedbackModal from '../../../../components/FeedbackModal';
import { AppContext } from '../../../../context';
import { formatDateUS } from '../../../../utils/commonFn';
import {
  APPLICANT_STATUS, COMPANY_DETAILS_ROUTE, UPDATE_CANDIDATE_ROUTE, VIEW_CANDIDATE_ROUTE, VIEW_JOB_ORDER_ROUTE,
} from '../../../../utils/constant';
import { adminExecAMCheck, moneyFormatter, pushInHistory } from '../../../../utils/helper';
import { CheckJobApplicantComponent } from '../../../job-order/JobApplicantCheckboxList';

const JobApplicationDetail = ({
  data,
  actionHandler,
  actionData,
  setActionData,
  location,
  isEdit = false,
  setAcountManagerPernmission
}) => {
  const history = useHistory();
  const { userRoles, user } = useContext(AppContext);
  const [feedbackModal, setFeedbackModal] = useState(false);
  const [feedbackDetails, setFeedbackDetails] = useState<any>(null);
  const [clearCandidateAction, setClearCandidateAction] = useState<any>(false);
  const [clearClientAction, setClearClientAction] = useState<any>(false);
  const [isUnLockedInterview, setIsUnLockedInterview] = useState<boolean>(false)

  const onEditCheck = (status: string): boolean => {
    if (userRoles?.includes('ADMIN') || userRoles?.includes('EXECUTIVE')) {
      return true;
    } else if (status === 'INTERVIEWED' || status === 'OFFERED') {
      let ownerShare = data?.JobOrder?.company?.ownerAndSharees?.map(
        (item) => item?.owner?.id
      );
      return ownerShare?.includes(user?.id);
    } else {
      return false;
    }
  };
  useEffect(() => {
    setAcountManagerPernmission(adminExecAMCheck(data, user, userRoles))
  }, [data, setAcountManagerPernmission, user, userRoles])

  const adminExecCandidateOwnerCheck = (): boolean => {
    const movedToOffers = data?.interviews?.find(
      (item: { interviewStatus: string }) =>
        item.interviewStatus === 'MOVE_TO_OFFER'
    );
    if (userRoles?.includes('ADMIN') || userRoles?.includes('EXECUTIVE')) {
      return true;
    }
    let candidateOwner = data?.candidate?.recruiterOwner?.id === user?.id;
    return !movedToOffers && candidateOwner;
  };

  const onFirstInterviewDisableHandler = (item: any): boolean => {
    let companyOwnerShareIds = data?.JobOrder?.company?.ownerAndSharees?.map(
      (item) => item?.owner?.id
    );
    let finalBol = true;
    if (item?.submission?.status === 'COMPLETED') {
      if (userRoles?.includes('ADMIN') || userRoles?.includes('EXECUTIVE')) {
        finalBol = false;
      } else if (companyOwnerShareIds?.includes(user?.id)) {
        finalBol = false;
      } else if (item?.candidate?.recruiterOwner?.id === user?.id) {
        finalBol = false;
      }
    }
    return finalBol;
  };

  const getWidth = (stringLen) => {
    let len = stringLen.length;
    return len * 7;
  };
  const nextSchedule = (item: any) => {
    let latest = item?.interviews?.find((item) => !item?.locked);
    if (latest && latest?.interviewRoundIndex === 5) return true;
    return false;
  };

  const pendingClientAction = (item) => {
    let latest = item?.interviews?.find((item) => !item?.locked);
    return (
      latest?.clientInviteFlag ||
      latest?.locked ||
      item?.JobAppStatus === 'MOVE_TO_OFFER' ||
      item?.JobAppStatus === 'CLIENT_DECLINED' ||
      item?.JobAppStatus === 'CANDIDATE_DECLINED' ||
      !adminExecAMCheck(item, user, userRoles)
    );
  };
  const pendingCandidateAction = (item) => {
    let latest = item?.interviews?.find((item) => !item?.locked);

    return (
      latest?.candidateInviteFlag ||
      latest?.locked ||
      item?.JobAppStatus === 'MOVE_TO_OFFER' ||
      item?.JobAppStatus === 'CLIENT_DECLINED' ||
      item?.JobAppStatus === 'CANDIDATE_DECLINED' ||
      !adminExecCandidateOwnerCheck()
    );
  };

  const latestOffer = (offers) => {
    let latestOffer = offers?.find((item) => !item?.locked);
    return `${moneyFormatter(latestOffer?.offeredSalary)}${latestOffer?.offeredSalaryType !== 'ANNUALLY' ? '/hour' : ''
      }`;
  };
  const latestOfferDateTime = (offers) => {
    let latestOffer = offers?.find((item) => !item?.locked);
    return latestOffer?.offerStartDateTime
      ? `${formatDateUS(latestOffer?.offerStartDateTime)}`
      : '--';
  };

  const latestInterviewDateTime = (interviews) => {
    let newInterview = interviews?.find((item) => !item?.locked);

    if (newInterview)
      return `${moment(newInterview?.interviewDateTime)
        .utcOffset(newInterview?.timeZoneId)
        .format('MM/DD/YYYY')},${moment(newInterview?.interviewDateTime)
          .utcOffset(newInterview?.timeZoneId)
          .format('hh:mm A')} ${newInterview?.timeZone}`;
  };

  const pendingInterviewClear = (data) => {
    if (data?.JobAppStatus === "INTERVIEWED") {
      const pendingInterview = data?.interviews?.filter(item => item.InterviewState === "PENDING")
      return pendingInterview.length ? false : true
    }
  }

  useEffect(() => {
    if (data?.JobAppStatus === "INTERVIEWED" && data?.interviews?.length) {
      const lockedInterviews = data?.interviews?.filter((interview) => !interview.locked);
      setIsUnLockedInterview(lockedInterviews.length ? true : false)
    }
  }, [data])

  return (
    <>
      <div className="mb-4 px-3 pt-3 pb-2 job-status-box job-status-content">
        <div className="status-main d-flex flex-md-row flex-column justify-content-md-between align-items-md-center mb-2">
          <div className="status-1 d-flex align-items-start">
            <div className="tableCheckbox mr-3">
              <CheckJobApplicantComponent
                item={data}
                actionData={actionData}
                setActionData={setActionData}
                forInterview={pendingInterviewClear(data)}
              />
            </div>

            <div className="order-md-1 order-2">
              <div className="job-status-wrap d-flex flex-sm-row align-items-sm-center align-items-start mb-2">
                <h6 className="status">Status:</h6>
                <span className="text-capitalize badge badge-info ml-sm-2">
                  {data?.JobAppStatus}
                </span>

                {data?.JobAppStatus === 'SUBMITTED' && data?.submission && (
                  <span
                    className={`text-capitalize badge badge-${data?.submission?.status === 'COMPLETED'
                      ? 'success'
                      : data?.submission?.status === 'DRAFT'
                        ? 'primary'
                        : data?.submission?.status === 'DECLINE' ||
                          data?.submission?.status === 'REJECTED'
                          ? 'danger'
                          : 'primary'
                      } ml-sm-2`}
                  >
                    {data?.submission?.status === 'DRAFT'
                      ? 'PENDING'
                      : data?.submission?.status}
                  </span>
                )}
                {data?.JobAppStatus === 'INTERVIEWED' && (
                  <span className="text-capitalize badge badge-info ml-sm-2">
                    {!pendingInterviewClear(data) ? 'PENDING' : "VERIFIED"}
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="status-3 order-md-2 order-1 actionsDropdown actionsDropdownSpacing dropdown d-flex justify-content-md-end">
            <UncontrolledDropdown className="customMenuDropdown">
              <DropdownToggle caret>Actions</DropdownToggle>
              {(data?.JobAppStatus === 'CANDIDATE_DECLINE' || data?.JobAppStatus === 'CLIENT_DECLINE')
                && (!data?.interviews.length && !data?.offers.length)
                && (data?.submission !== null)
                ?
                (
                  <DropdownMenu right>
                    <DropdownItem
                      className="bg-transparent border-0 color-default btn-sm"
                      onClick={() =>
                        actionHandler(data, 'View Submission Detail')
                      }
                    >
                      View Submission Detail
                    </DropdownItem>
                    <DropdownItem
                      className="bg-transparent border-0 color-default btn-sm"
                      onClick={() =>
                        actionHandler(data, 'Submit_Again')
                      }
                    >
                      Back to Submitted
                    </DropdownItem>
                  </DropdownMenu>
                )
                :
                APPLICANT_STATUS.some(status => status.value === data?.JobAppStatus) ?
                  (<DropdownMenu right>
                    {APPLICANT_STATUS.map(({ label, value }, index) => {
                      return (
                        <>
                          <DropdownItem
                            className="bg-transparent border-0 color-default btn-sm"
                            onClick={() => actionHandler(data, value)}
                          >
                            {label}
                          </DropdownItem>
                          {index === APPLICANT_STATUS.length - 1 && (data.JobAppStatus === 'CLIENT_DECLINE' || data.JobAppStatus === 'CANDIDATE_DECLINE') &&
                            <DropdownItem
                              disabled={onFirstInterviewDisableHandler(data) ||
                                !(data?.JobOrder?.company?.ownerAndSharees.find((value) => value?.owner?.id === user?.id)
                                  || userRoles?.includes("EXECUTIVE"))
                              }
                              className="bg-transparent border-0 color-grey btn-sm"
                              onClick={() =>
                                actionHandler(data, 'Back To Interview')
                              }
                            >
                              Back To Interview
                            </DropdownItem>
                          }
                        </>)
                    })}
                  </DropdownMenu>
                  ) :
                  data?.JobAppStatus === 'SUBMITTED' &&
                    data?.submission?.status !== 'REJECTED' ? (
                    <DropdownMenu right>
                      <DropdownItem
                        className="bg-transparent border-0 color-default btn-sm"
                        onClick={() =>
                          actionHandler(data, 'View Submission Detail')
                        }
                      >
                        View Submission Detail
                      </DropdownItem>

                      <DropdownItem
                        disabled={onFirstInterviewDisableHandler(data)}
                        className="bg-transparent border-0 color-grey btn-sm"
                        onClick={() =>
                          actionHandler(data, 'Schedule First Interview')
                        }
                      >
                        Schedule First Interview
                      </DropdownItem>

                      <DropdownItem
                        disabled={!adminExecAMCheck(data, user, userRoles)}
                        className={`bg-transparent border-0 btn-sm color-default `}
                        onClick={() => actionHandler(data, 'Client Decline')}
                      >
                        Client Decline
                      </DropdownItem>

                      <DropdownItem
                        disabled={!adminExecAMCheck(data, user, userRoles)}
                        className={`bg-transparent border-0  btn-sm color-default`}
                        onClick={() => actionHandler(data, 'Candidate Decline')}
                      >
                        Candidate Decline
                      </DropdownItem>
                    </DropdownMenu>
                  ) : data?.JobAppStatus === 'CANDIDATE_DECLINE' ||
                    data?.JobAppStatus === 'CLIENT_DECLINE' ||
                    (data?.JobAppStatus === 'SUBMITTED' &&
                      data?.submission?.status === 'REJECTED') ? (
                    <DropdownMenu right>
                      <DropdownItem
                        className="bg-transparent border-0 color-default btn-sm"
                        onClick={() =>
                          actionHandler(data, 'View Submission Detail')
                        }
                      >
                        View Submission Detail
                      </DropdownItem>
                      {((data?.JobAppStatus === 'CANDIDATE_DECLINE' || data?.JobAppStatus === 'CLIENT_DECLINE') &&
                        (!data?.interviews.length && !data?.offers.length)) &&
                        (data?.submission !== null) &&
                        //  (data?.submission?.status !== DRAFT) && 
                        (
                          <DropdownItem
                            className="bg-transparent border-0 color-default btn-sm"
                            onClick={() =>
                              actionHandler(data, 'Submit_Again')
                            }
                          >
                            Back to Submitted
                          </DropdownItem>
                        )}
                    </DropdownMenu>
                  ) :

                    data?.JobAppStatus === 'INTERVIEWED' ? (
                      <DropdownMenu>
                        <DropdownItem
                          className="bg-transparent border-0 color-default btn-sm"
                          onClick={() =>
                            actionHandler(data, 'View Interview Details')
                          }
                        >
                          View Interview Details
                        </DropdownItem>
                        <DropdownItem
                          disabled={
                            nextSchedule(data) ||
                            onFirstInterviewDisableHandler(data)
                          }
                          className={`bg-transparent border-0 btn-sm color-default`}
                          onClick={() =>
                            actionHandler(data, 'Schedule Next Interview')
                          }
                        >
                          Schedule Next Interview
                        </DropdownItem>

                        <DropdownItem
                          disabled={!onEditCheck(data?.JobAppStatus)}
                          className={`bg-transparent border-0 btn-sm color-default`}
                          onClick={() => actionHandler(data, 'Convert to Offer')}
                        >
                          Convert to Offer
                        </DropdownItem>

                        <DropdownItem
                          disabled={!onEditCheck(data?.JobAppStatus)}
                          className={`bg-transparent border-0 btn-sm color-default`}
                          onClick={() => actionHandler(data, 'Client Decline')}
                        >
                          Client Decline
                        </DropdownItem>

                        <DropdownItem
                          disabled={!onEditCheck(data?.JobAppStatus)}
                          className={`bg-transparent border-0 btn-sm color-default`}
                          onClick={() => actionHandler(data, 'Candidate Decline')}
                        >
                          Candidate Decline
                        </DropdownItem>

                        <DropdownItem
                          disabled={isUnLockedInterview ? !adminExecAMCheck(data, user, userRoles) : !isUnLockedInterview}
                          className={`bg-transparent border-0 btn-sm color-default`}
                          onClick={() => actionHandler(data, 'Send Client Invite')}
                        >
                          Send Client Invite
                        </DropdownItem>

                        <DropdownItem
                          disabled={isUnLockedInterview ? !adminExecCandidateOwnerCheck() : !isUnLockedInterview}
                          className={`bg-transparent border-0 btn-sm color-default`}
                          onClick={() => actionHandler(data, 'Send Candidate Invite')}
                        >
                          Send Candidate Invite
                        </DropdownItem>

                        {!clearClientAction && !pendingCandidateAction(data) && (
                          <DropdownItem
                            disabled={isUnLockedInterview ? pendingCandidateAction(data) : !isUnLockedInterview}
                            className={`bg-transparent border-0 btn-sm color-default`}
                            onClick={() => {
                              setClearClientAction(true);
                              actionHandler(data, 'Clear Pending Candidate Action');
                            }}
                          >
                            Clear Pending Candidate Action
                          </DropdownItem>

                        )}


                        {!clearCandidateAction && !pendingClientAction(data) && (
                          <DropdownItem
                            disabled={isUnLockedInterview ? pendingClientAction(data) : !isUnLockedInterview}
                            className={`bg-transparent border-0 btn-sm color-default`}
                            onClick={() => {
                              setClearCandidateAction(true);
                              actionHandler(data, 'Clear Pending Client Action');
                            }}
                          >
                            Clear Pending Client Action
                          </DropdownItem>
                        )}
                        <DropdownItem
                          disabled={pendingInterviewClear(data) || !adminExecAMCheck(data, user, userRoles)}
                          className={`bg-transparent border-0 btn-sm color-default`}
                          onClick={() => actionHandler(data, 'Clear Pending Interviews')}
                        >
                          Clear Pending Interviews
                        </DropdownItem>
                      </DropdownMenu>
                    ) : data?.JobAppStatus === 'OFFERED' ? (
                      <DropdownMenu>
                        <DropdownItem
                          className="bg-transparent border-0 color-default btn-sm"
                          onClick={() => actionHandler(data, 'View Offer Details')}
                        >
                          View Offer Details
                        </DropdownItem>

                        <DropdownItem
                          className="bg-transparent border-0 color-default btn-sm"
                          onClick={() => {
                            actionHandler(data, 'Move To Placement')
                          }}
                        >
                          Move To Placement
                        </DropdownItem>

                        <DropdownItem
                          className="bg-transparent border-0 color-default btn-sm"
                          onClick={() => actionHandler(data, 'Update Offer')}
                        >
                          Update Offer
                        </DropdownItem>

                        <DropdownItem
                          className="bg-transparent border-0 color-default btn-sm"
                          onClick={() => actionHandler(data, 'Counter Offer')}
                        >
                          Counter Offer
                        </DropdownItem>

                        <DropdownItem
                          disabled={!onEditCheck(data?.JobAppStatus)}
                          className={`bg-transparent border-0 btn-sm color-default`}
                          onClick={() => actionHandler(data, 'Client Decline')}
                        >
                          Client Decline
                        </DropdownItem>

                        <DropdownItem
                          disabled={!onEditCheck(data?.JobAppStatus)}
                          className={`bg-transparent border-0 btn-sm color-default`}
                          onClick={() => actionHandler(data, 'Candidate Decline')}
                        >
                          Candidate Decline
                        </DropdownItem>
                      </DropdownMenu>
                    ) : (
                      <DropdownMenu>
                        {APPLICANT_STATUS?.map((item, index) => (
                          <DropdownItem
                            key={index}
                            className={`bg-transparent border-0 btn-sm color-default`}
                            onClick={() => actionHandler(data, item?.value)}
                          >
                            {item?.label}
                          </DropdownItem>
                        ))}
                      </DropdownMenu>
                    )}
            </UncontrolledDropdown>
            <Button
              className=" ml-2 default-btn text-light border-sm"
              size="sm"
              onClick={() => {
                setFeedbackDetails({
                  applicantId: data?.id,
                  candidateName: data?.candidate?.fullName,
                  jobTitle: data?.JobOrder?.jobTitle,
                  companyName: data?.JobOrder?.company?.name,
                  location: `${['US', null, undefined, ''].includes(data?.JobOrder?.companyLocation?.country) ? data?.JobOrder?.companyLocation?.city ? data?.JobOrder?.companyLocation?.city + ', ' + data?.JobOrder?.companyLocation?.state : data?.JobOrder?.companyLocation?.state : data?.JobOrder?.companyLocation?.city ? data?.JobOrder?.companyLocation?.city + ', ' + data?.JobOrder?.companyLocation?.country : data?.JobOrder?.companyLocation?.country}`,
                });

                setFeedbackModal(!feedbackModal);
              }}
            >

              Feedback
            </Button>
          </div>
        </div>
        <Row className="ml-lg-2">
          <Col lg={6} xl={4}>
            <div className="job-status-wrap d-flex flex-sm-row flex-column align-items-start mb-3">
              <h6
                className="mb-sm-0 mb-2"
                style={{ minWidth: getWidth('Company Name:') }}
              >
                Company Name:
              </h6>

              <p className="mb-0 ml-sm-2">
                {!!data?.JobOrder?.company?.name ?
                  <span className=' route-link' onClick={() => {
                    history.push({
                      pathname: `${COMPANY_DETAILS_ROUTE}/${data?.JobOrder?.company?.id}`,
                      state: {
                        backUrl: `${!!isEdit ? UPDATE_CANDIDATE_ROUTE : VIEW_CANDIDATE_ROUTE}/${data?.candidate?.id}`,
                        searchParameters: location.search
                      }
                    })

                    pushInHistory(`${!!isEdit ? UPDATE_CANDIDATE_ROUTE : VIEW_CANDIDATE_ROUTE}/${data?.candidate?.id}`, location.search)
                  }
                  }>
                    {data?.JobOrder?.company?.name}
                  </span> : '--'
                }
              </p>
            </div>
          </Col>

          <Col lg={6} xl={4}>
            <div className="job-status-wrap d-flex flex-sm-row flex-column align-items-start mb-3">
              <h6
                className="mb-sm-0 mb-2"
                style={{ minWidth: getWidth('Job Title:') }}
              >
                Job Title:
              </h6>

              <p className="mb-0 ml-sm-2">
                {!!data?.JobOrder?.jobTitle ?
                  <span className='route-link'
                    onClick={() => {
                      history.push({
                        pathname: `${VIEW_JOB_ORDER_ROUTE}/${data?.JobOrder?.id}`,
                        state: {
                          backUrl: `${!!isEdit ? UPDATE_CANDIDATE_ROUTE : VIEW_CANDIDATE_ROUTE}/${data?.candidate?.id}`,
                          searchParameters: location.search
                        }
                      })
                      pushInHistory(`${!!isEdit ? UPDATE_CANDIDATE_ROUTE : VIEW_CANDIDATE_ROUTE}/${data?.candidate?.id}`, location.search)
                    }
                    }>
                    {data?.JobOrder?.jobTitle}
                  </span> : "--"
                }
              </p>
            </div>
          </Col>

          <Col lg={6} xl={4}>
            <div className="job-status-wrap d-flex flex-sm-row flex-column align-items-start mb-3">
              <h6
                className="mb-0"
                style={{ minWidth: getWidth('Actual Location:') }}
              >
                Actual Location:
              </h6>
              <p className="mb-0 ml-sm-2">
                {`${['US', null, undefined, ''].includes(data?.JobOrder?.companyLocation?.country) ? data?.JobOrder?.companyLocation?.city ? data?.JobOrder?.companyLocation?.city + ', ' + data?.JobOrder?.companyLocation?.state : data?.JobOrder?.companyLocation?.state : data?.JobOrder?.companyLocation?.city ? data?.JobOrder?.companyLocation?.city + ', ' + data?.JobOrder?.companyLocation?.country : data?.JobOrder?.companyLocation?.country}`
                }              </p>
            </div>
          </Col>

          {data?.JobAppStatus === 'SUBMITTED' && (
            <>
              <Col lg={6} xl={4}>
                <div className="job-status-wrap d-flex flex-sm-row flex-column align-items-start mb-3">
                  <h6
                    className="mb-0"
                    style={{ minWidth: getWidth('Target Salary:') }}
                  >
                    Target Salary:
                  </h6>
                  <p className="mb-0 ml-sm-2">
                    {data?.submission?.targetSalary || '--'}
                  </p>
                </div>
              </Col>
              <Col lg={6} xl={4}>
                <div className="job-status-wrap d-flex flex-sm-row flex-column align-items-start mb-3">
                  <h6
                    className="mb-0"
                    style={{ minWidth: getWidth('Submitted By:') }}
                  >
                    Submitted By:
                  </h6>
                  <p className="mb-0 ml-sm-2">
                    {data?.submission?.submittedBy?.fullName || '--'}
                  </p>
                </div>
              </Col>
              <Col lg={6} xl={4}>
                <div className="job-status-wrap d-flex flex-sm-row flex-column align-items-start mb-3">
                  <h6
                    className="mb-0"
                    style={{ minWidth: getWidth('Created By:') }}
                  >
                    Created By:
                  </h6>
                  <p className="mb-0 ml-sm-2">
                    {data?.submission?.createdBy?.fullName} -{' '}
                    {data?.submission?.createdAt &&
                      formatDateUS(data?.submission?.createdAt)}{' '}
                    -{' '}
                    {data?.submission?.createdAt &&
                      new Date(
                        data?.submission?.createdAt
                      ).toLocaleTimeString()}
                  </p>
                </div>
              </Col>
            </>
          )}

          {data?.JobAppStatus === 'OFFERED' && (
            <>
              <Col lg={6} xl={4}>
                <div className="job-status-wrap d-flex flex-sm-row flex-column align-items-start mb-3">
                  <h6
                    className="mb-0"
                    style={{ minWidth: getWidth('Proposed Start Date:') }}
                  >
                    Proposed Start Date:
                  </h6>
                  <p className="mb-0 ml-sm-2">
                    {latestOfferDateTime(data?.offers)}
                  </p>
                </div>
              </Col>
              <Col lg={6} xl={4}>
                <div className="job-status-wrap d-flex flex-sm-row flex-column align-items-start mb-3">
                  <h6
                    className="mb-0"
                    style={{ minWidth: getWidth('Offered Salary:') }}
                  >
                    Offered Salary:
                  </h6>
                  <p className="mb-0 ml-sm-2">{latestOffer(data?.offers)}</p>
                </div>
              </Col>
            </>
          )}

          {data?.JobAppStatus === 'INTERVIEWED' && (
            <>
              <Col lg={6} xl={4}>
                <div className="job-status-wrap d-flex flex-sm-row flex-column align-items-start mb-3">
                  <h6
                    className="mb-0"
                  >
                    Latest Interview:
                  </h6>
                  <p className="mb-0 ml-sm-2">
                    Round{' '}
                    {data?.interviews?.find((item) => !item?.locked)
                      ?.interviewRoundIndex || '--'}
                  </p>
                </div>
              </Col>

              <Col lg={6} xl={4}>
                <div className="job-status-wrap d-flex flex-sm-row flex-column align-items-start mb-3">
                  <h6
                    className="mb-0"
                    style={{ minWidth: getWidth('Offered:') }}
                  >
                    Interview Time:
                  </h6>
                  <p className="mb-0 ml-sm-2">
                    {latestInterviewDateTime(data?.interviews)}
                  </p>
                </div>
              </Col>
              <Col lg={6} xl={4}>
                <div className="job-status-wrap d-flex flex-sm-row flex-column align-items-start mb-3">
                  <h6
                    className="mb-sm-0 mb-2"
                    style={{ minWidth: getWidth('Apply Date:') }}
                  >
                    Apply Date:
                  </h6>
                  <p className="mb-0 ml-sm-2">
                    {data?.createdAt ? formatDateUS(data?.createdAt) : '--'}
                  </p>
                </div>
              </Col>
            </>
          )}
        </Row>
      </div>

      {
        feedbackModal && (
          <FeedbackModal
            modal={feedbackModal}
            toggle={() => {
              setFeedbackModal(!feedbackModal);
            }}
            details={feedbackDetails}
          />
        )
      }
    </>
  );
};

export default JobApplicationDetail;