import { useContext } from 'react';
import { Button } from 'reactstrap';
import ConfirmPopUp from '../../../../components/ConfirmPopUp';
import Loader from '../../../../components/layout/Loader';
import NoDataFound from '../../../../components/layout/NoDataFound';
import TableComponent from '../../../../components/layout/TableComponent';
import { TJ_TALK__VIDEO_HEADER } from '../../../../constants';
import { AppContext } from '../../../../context';
import { ROLES } from '../../../../utils/constant';
import EDIT_ICON from   "../../../../assets/images/edit-icon-menu.svg";
import DELETE_ICON from "../../../../assets/images/delete-icon-menu.svg";

const TjTalkVideoLIsting = ({
  listData,
  listLoading,
  setVideoId,
  setIsDeleteModalOpen,
  isDeleteModalOpen,
  removeTjlookUpVideoHandler,
  editTjlookupModal,
  setEditTjlookupModal,
  setVideoObj,
}) => {
  const {  userRoles } = useContext(AppContext);

  let editVideo = (item: { id; videoTitle; videoUrl }) => {
    setVideoObj({
      id: item?.id,
      videoTitle: item?.videoTitle,
      videoUrl: item?.videoUrl,
    });
    setEditTjlookupModal(!editTjlookupModal);
  };
  return (
    <div>
      <TableComponent tableHeader={TJ_TALK__VIDEO_HEADER}>
        {listLoading? (
          <tbody>
            <tr>
              <td colSpan={11}>
                <Loader loaderClass="sm-height " />
              </td>
            </tr>
          </tbody>
        ):(
          <tbody>
          {listData?.tjlookUpvideoList?.count > 0 ? (
            listData?.tjlookUpvideoList?.Tjlookup?.map((item, index) => {
              return (
                <tr key={`${index} indexOf ${item?.videoUrl}`} className="video-table-row">
                  <td>
                    <span className="d-inline-block text-truncate">
                      {item.videoTitle}
                    </span>
                  </td>
                  <td>
                    <Button color="link" onClick={() => window.open(item?.videoUrl)} target="_blank" className="p-0 text-left text-blue-link">
                      <span className="text-start cursor-pointer d-inline-block text-truncate">
                        Video Link
                      </span>
                    </Button>
                  </td>
                  
                  <td>
                    <div className="d-flex justify-center">
                      <Button
                        onClick={() =>
                          editVideo({
                            id: item?.id,
                            videoTitle: item?.videoTitle,
                            videoUrl: item?.videoUrl,
                          })
                        }
                        disabled={userRoles?.includes(ROLES.ADMIN)||userRoles?.includes(ROLES.EXECUTIVE)? false:true }
                        className="btn bg-transparent p-0 border-0"
                      >
                            <img src={EDIT_ICON} alt="edit" />
                      </Button>

                      <Button
                        onClick={() => {
                          setVideoId(item?.id);
                          setIsDeleteModalOpen(true);
                        }}
                        className="btn bg-transparent p-0 border-0"
                        disabled={userRoles?.includes(ROLES.ADMIN)||userRoles?.includes(ROLES.EXECUTIVE)? false:true }

                      >
                      <img src={DELETE_ICON} alt="delete" />
                      </Button>
                    </div>
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td
                colSpan={TJ_TALK__VIDEO_HEADER.length}
                className="empty-table-td"
              >
                <NoDataFound text="No Video Found!" />
              </td>
            </tr>
          )}
        </tbody>
         )}
      </TableComponent>

      <ConfirmPopUp
        isOpen={isDeleteModalOpen}
        toggle={() => setIsDeleteModalOpen(false)}
        confirmAction={() => removeTjlookUpVideoHandler()}
        confirmText="Are You Sure You Want To Delete This Video?"
        modalHeading="Delete Video"
      />
    </div>
  );
};

export default TjTalkVideoLIsting;
