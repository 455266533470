import { useMutation } from '@apollo/client';
import { useState } from 'react';
import { Button, Card, Col, Row } from 'reactstrap';
import { AddOutlinedIcon } from '../../../assets/images/svgs';
import AddAbleFields from '../../../components/AddAbleFields';
import ConfirmPopUp from '../../../components/ConfirmPopUp';
import { EMAIL_PATTERN } from '../../../utils/constant';
import { DELETE_CONTACT_EMAIL } from '../gql';

const ContactEmail = ({
  customEmailInputs,
  setCustomEmailInputs,
  emailErr,
  setEmailErr,
  placement = false,
}: any) => {
  const [deleteEmailToggle, setDeleteEmailToggle] = useState(false);
  const [_emailId, setEmailId] = useState('');
  const [deleteEmail] = useMutation(DELETE_CONTACT_EMAIL);

  const handleRemove = (index: number, emailId: string) => {
    if (emailId) {
      setDeleteEmailToggle(true);
      setEmailId(emailId);
    } else {
      let emailInputs = [...customEmailInputs];
      emailInputs.splice(index, 1);

      // Check if any remaining emails are invalid
      const hasInvalidEmails = emailInputs?.some(item => {
        const emailReg = new RegExp(EMAIL_PATTERN);
        return item.email && !emailReg.test(item?.email);
      });

      // If any invalid emails are found, set the email error state
      if (hasInvalidEmails) {
        setEmailErr('Email is not valid!');
      } else {
        setEmailErr('');
      }

      setCustomEmailInputs([...emailInputs]);
    }
  };

  const deleteEmailInDb = () => {
    deleteEmail({ variables: { id: _emailId } });
    setDeleteEmailToggle(false);
    let _emailInputs = [...customEmailInputs];
    _emailInputs = _emailInputs?.filter((item) => item.emailId !== _emailId);
    setCustomEmailInputs([..._emailInputs]);
  };

  const handleAddMore = () => {
    setCustomEmailInputs([
      ...customEmailInputs,
      {
        email: '',
        isPrimary: false,
        emailId: '',
      },
    ]);
  };

  const [emailErrors, setEmailErrors] = useState<string[]>([]);
  const handleInputChange = (index: number, event: any) => {
    let emails = [...customEmailInputs];
    if (emails.length > 0) {
      setEmailErr("")
    }
    if (event.target.type === "checkbox") {
      for (const item of emails) {
        item.isPrimary = false;
      }
      emails[index][event.target.name] = event.target.checked;
    } else {
      emails[index][event.target.name] = event.target.value;

      // Validate all email inputs
      let errors: string[] = [];
      emails.forEach((item, i) => {
        if (item.email) {
          let emailReg = new RegExp(EMAIL_PATTERN);
          let email = emailReg.test(item.email);
          if (!email) {
            errors[i] = "Email is not valid!";
          } else {
            errors[i] = "";
          }
        } else {
          errors[i] = "";
        }
      });

      setEmailErrors(errors);
    }

    setCustomEmailInputs([...emails]);
  };

  return (
    <Card className="border-0 mb-2">
      <div className="user-basic-form">
        <Row>
          <Col lg="12" sm="12" xs="12">
            {customEmailInputs.map((item: any, index: number) => {
              return (
                <div key={index}>
                  <AddAbleFields
                    index={index}
                    handleRemove={handleRemove}
                    handleChange={handleInputChange}
                    value={item.email}
                    checked={item.isPrimary}
                    recordId={item.emailId}
                    label="Email"
                    inputName="email"
                    isRequired
                  />
                  {emailErr ? <small className="text-danger">{emailErr}</small> :
                    <small className="text-danger">{emailErrors[index]}</small>}

                </div>
              );
            })}
            <div className="mt-3">
              {placement ? (
                <Button
                  onClick={handleAddMore}
                  className="btn bg-transparent p-0 border-0"
                >
                  <AddOutlinedIcon />
                </Button>
              ) : (
                <button
                  onClick={handleAddMore}
                  // disabled={emailButton}
                  className="buttonGenericStyle buttonGenericStyleInner filledButton"
                  type="button"
                >
                  Add New Email
                </button>
              )}
            </div>
          </Col>
        </Row>
      </div>

      <ConfirmPopUp
        confirmText="Are you sure you want to delete this mail?"
        isOpen={deleteEmailToggle}
        toggle={setDeleteEmailToggle}
        confirmAction={deleteEmailInDb}
        modalHeading="Delete Mail"
        btnText="Delete"
        btnColor="primary"
      />
    </Card>
  );

};

export default ContactEmail;
