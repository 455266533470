import { useMutation } from "@apollo/client";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Form,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";
import SocialMediaInfo from "../../../components/SocialMediaInfo";
import { AppContext } from "../../../context";
import BasicContactInfo from "../../contacts/contact-components/BasicCotactInfo";
import ContactEmail from "../../contacts/contact-components/ContactEmail";
import ContactPhone from "../../contacts/contact-components/ContactPhone";
import { CREATE_COMPANY_CONTACT } from "../../contacts/gql";

const CreateContactModalForJob = ({ open, toggle, ...props }: any) => {
  const [isPrivate, setIsPrivate] = useState<boolean>(false);
  const { userRoles } = useContext(AppContext);
  const {
    errors,
    handleSubmit,
    register,
    reset,
    control,
    setValue,
    getValues,
  } = useForm();
  const [createCompanyContact, { loading: waiting }] = useMutation(
    CREATE_COMPANY_CONTACT
  );
  const [emailErr, setEmailErr] = useState("");
  const [customEmailInputs, setCustomEmailInputs] = useState<any>([
    {
      email: "",
      isPrimary: true,
    },
  ]);
  const [customPhoneInputs, setCustomPhoneInputs] = useState<any>([
    {
      phone: "",
      phoneType: "",
      isPrimary: true,
    },
  ]);

  const createContact = async (values: any) => {
    let emails = customEmailInputs.filter((item: any) => item.email && item);
    if (!emails?.length) {
      setEmailErr("Minimum one email is required");
      return;
    }
    values.contactEmails = emails;
    let phones = customPhoneInputs.filter((item: any) => item.phone && item);
    values.contactPhones = phones;
    values.isPrivate = isPrivate;
    values.companyId = values?.companyId?.value;
    values.locationId = values?.locationId?.value;
    let res = await createCompanyContact({
      variables: { createContactInput: { ...values } },
    });
    if (res?.data) {
      toast.success("Contact successfully created!");
      props?.preSelectContact(res?.data?.createContact);
      reset();
      toggle();
      // props?.fetchCompany();
    } else if (res?.errors && res?.errors?.length > 0) {
      toast.error(res?.errors[0]?.message);
    }
  };

  useEffect(() => {
    if (props?.companyId && props?.companyName) {
      const companyId = {
        label: props?.companyName,
        value: props?.companyId,
      };
      reset({ companyId });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.companyId || props?.companyName]);

  return (
    <Modal
      isOpen={open}
      // toggle={toggle}
      size="xl"
      className="revampDialog"
    >
      <Form>
        <div className="modalHeaderCustom d-flex align-items-center justify-content-between pb-2">
          <h4>Add Contacts</h4>
          {userRoles?.includes("EXECUTIVE") ? (
            <div className="d-flex align-items-center make-private">
              <span className="mr-3 switchLabel">Make it private?</span>
              <label className="switch-contact">
                <Input
                  type="checkbox"
                  name="isPrivate"
                  checked={isPrivate}
                  onChange={() => setIsPrivate(!isPrivate)}
                />
                <span className="slider"></span>
              </label>
            </div>
          ) : null}
        </div>

        <ModalBody className="modalBodyScroll candidateInterview">
          <BasicContactInfo
            register={register}
            control={control}
            errors={errors}
            makeCompanyReadOnly={true}
            companyId={props?.companyId}
            companyName={props?.companyName}
            contactModal={true}
            getValues={getValues}
            setValue={setValue}
          />

          <ContactEmail
            setCustomEmailInputs={setCustomEmailInputs}
            customEmailInputs={customEmailInputs}
            emailErr={emailErr}
            setEmailErr={setEmailErr}
          />

          <div>
            <ContactPhone
              setCustomPhoneInputs={setCustomPhoneInputs}
              customPhoneInputs={customPhoneInputs}
              error=""
              setErr={() => {}}
            />
          </div>

          <div>
            <SocialMediaInfo
              setValue={setValue}
              register={register}
              errors={errors}
              isLinkedinRequired={false}
              isDisable={false}
            />
          </div>
        </ModalBody>
        <ModalFooter className="px-4">
          <button onClick={toggle} type="button" className="buttonPrimary mr-3">
            Cancel
          </button>

          <button
            type="submit"
            className="buttonGenericStyle filledButton"
            onClick={handleSubmit(createContact)}
          >
            {waiting && <Spinner size="sm" className="mr-2" />}
            Create Contact
          </button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default withRouter(CreateContactModalForJob);
