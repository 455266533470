import { useQuery } from "@apollo/client";
import { ToastContainer } from "react-toastify";
import Loader from "../../components/layout/Loader";
import TableComponent from "../../components/layout/TableComponent";
import { allAdmin } from "../../utils/constant";
import { FETCH_TEAMS_FOR_EXECUTIVE } from "../admin/gql";
import { GET_EXEC_USERS } from "./gql";
const ORG_CHART_EMPLOYEES = [
  "Exec",
  "Team Name",
  "Manager Name",
  "Team Member",
  "Reports To",
];

const ORG_CHART_ADMINS = ["Admins", "Reports To"];

const AllOrgChart = () => {
  const { data, loading } = useQuery(FETCH_TEAMS_FOR_EXECUTIVE);
  const { data: execUser } = useQuery(GET_EXEC_USERS);

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="admin-tabs team-tabs">
      <ToastContainer autoClose={3000} />

      <div className="primaryHeading primaryHeaderSpacing">
        <h5 className="m-0">Organization Chart</h5>
      </div>

      <div>
        <h5 className="mb-2 secondaryHeadingTheme">All Employees</h5>

        <div className="table-responsive">
          <TableComponent tableHeader={ORG_CHART_EMPLOYEES}>
            <tbody>
              {execUser?.getExecUsers?.length &&
                execUser?.getExecUsers?.map((exec) => (
                  <tr>
                    <td>{exec?.fullName}</td>
                    <td>--</td>
                    <td>--</td>
                    <td>--</td>
                    <td>--</td>
                  </tr>
                ))}
              {data?.teamForExecutive?.length &&
                // eslint-disable-next-line
                data?.teamForExecutive?.map((item: any, index: number) => {
                  const manager = item?.teamUsers?.find(
                    (item) => item?.isManager
                  );
                  if (item?.teamUsers?.length) {
                    let rows = item?.teamUsers?.map((val) => {
                      return (
                        <tr key={index}>
                          <td>--</td>
                          <td>{item?.name}</td>
                          <td>
                            {(val?.isManager && val?.user?.fullName) || "--"}
                          </td>
                          <td>
                            {(!val?.isManager && val?.user?.fullName) || "--"}
                          </td>
                          <td>
                            {(!val?.isManager && manager?.user?.fullName) ||
                              "--"}
                          </td>
                        </tr>
                      );
                    });
                    return rows;
                  }
                })}
            </tbody>
          </TableComponent>
        </div>
      </div>

      <div>
        <h5 className="mb-2 mt-3 secondaryHeadingTheme">All Admins</h5>

        <div className="table-responsive">
          <TableComponent tableHeader={ORG_CHART_ADMINS}>
            <tbody>
              {allAdmin.map((item: any, index: number) => {
                return (
                  <tr key={index}>
                    <td>{item?.admin}</td>
                    <td>{item?.reportsTo}</td>
                  </tr>
                );
              })}
            </tbody>
          </TableComponent>
        </div>
      </div>
    </div>
  );
};

export default AllOrgChart;
