import { FC } from 'react'
import React from 'react'
import { ReplyIcon } from '../assets/images/svgs'


interface REplyButtonInterface{
  link? : string,
  text? : boolean,
  color?:string
}
const ReplyButton:FC<REplyButtonInterface> = ({link, text,color}) => {
  return (
    <a
      href={link || ''}
      target="blank"
      className={`d-flex align-items-center justify-content-center ${text? 'btn-reply-outlined' : ''}`}
    >
      <ReplyIcon color={color} />
      {text&&<span className="ml-2">Reply</span>}
    </a>
  )
}

export default ReplyButton