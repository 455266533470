import { useLazyQuery, useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Button, Card, CardBody, Col, FormGroup, Label, Row } from "reactstrap";
import ConfirmPopUp from "../../../components/ConfirmPopUp";
import GoBack from "../../../components/GoBack";
import InputField from "../../../components/layout/InputField";
import Loader from "../../../components/layout/Loader";
import NoDataFound from "../../../components/layout/NoDataFound";
import Pagination from "../../../components/layout/Pagination";
import { downloadSignedUrlFile, formatDateUS } from "../../../utils/commonFn";
import {
  // APPLICANT_STATUS,
  APPLICANT_STATUS_RESULTS,
  PAGE_LIMIT,
} from "../../../utils/constant";
import "../cloud-search.scss";
import "../../job-order/jobOrder.scss";

import {
  GET_JOB_APPLICANTS,
  GET_JOB_ORDER_LIGHT,
  GET_JOB_ORDER_STATS,
  UPDATE_APPLICANT_UPDATE,
  UPDATE_APPLICANTS_STATUS,
} from "../../job-order/gql/index";
import { CheckJobApplicantComponent } from "../../job-order/JobApplicantCheckboxList";
import { Link } from "react-router-dom";

const SearchResultIndex = ({ match, location }: any) => {
  interface actionInterface {
    userId: String;
    url: String;
  }
  const page = new URLSearchParams(location.search).get("page") || "1";
  const _currentPage: number = parseInt(page);
  const [currentPage, setCurrentPage] = useState(_currentPage);
  const [limit, setLimit] = useState(5);
  const [actionData, setActionData] = useState<actionInterface[]>([]);
  const [statusValue, setStatusValue] = useState<string>();
  const [confirmBox, setConfirmBox] = useState(false);
  const [jobOrderId, setJobOrderId] = useState("");
  const [applicantId] = useState("");
  const [applicantStatus] = useState("");
  // const [currentBulkAction, setCurrentBulkAction] = useState("");
  const [updateApplicantStatus] = useMutation(UPDATE_APPLICANT_UPDATE);
  const [updateApplicantsStatus] = useMutation(UPDATE_APPLICANTS_STATUS);
  const [getJobOrder, { data }] = useLazyQuery(GET_JOB_ORDER_LIGHT);
  const [getStats, { data: stats }] = useLazyQuery(GET_JOB_ORDER_STATS);
  const [getJobApplicants, { data: jobApplicantsList, loading: waiting }] =
    useLazyQuery(GET_JOB_APPLICANTS);

  useEffect(() => {
    let id = match?.params?.id;

    getJobApplicants({
      variables: {
        limit,
        page: currentPage,
        id,
        status: statusValue,
        searchParams: "",
      },
    });

    // eslint-disable-next-line
  }, [statusValue]);

  useEffect(() => {
    let id = match.params.id;
    setJobOrderId(id);
    if (id) {
      getJobOrder({ variables: { id } });
      getStats({ variables: { jobOrderId: id } });
      getJobApplicants({
        variables: {
          limit,
          page: currentPage,
          id,
          status: "All",
          searchParams: "",
        },
      });
    }
    // eslint-disable-next-line
  }, []);

  const getJobOrderApplicants = () => {
    // getJobOrder({ variables: { id: jobOrderId } });
    getJobApplicants({
      variables: {
        limit,
        page: currentPage,
        id: jobOrderId,
        status: "All",
        searchParams: "",
      },
    });
  };

  // const handleBulkAction = async () => {
  //   const applicantIds = actionData.map((data) => {
  //     return data.userId;
  //   });
  //   let res = await updateApplicantsStatus({
  //     variables: { data: { applicantIds, status: currentBulkAction } },
  //   });
  //   if (res?.data) {
  //     setConfirmBox(false);
  //     toast.success("Successfully Updated!");
  //     getJobOrderApplicants();
  //   }
  //   setCurrentBulkAction("");
  //   setActionData([]);
  //   getJobOrderApplicants();
  // };

  const bulkActionHandler = async (status: string) => {
    const applicantIds = actionData.map((data) => {
      return data.userId;
    });
    let res = await updateApplicantsStatus({
      variables: { data: { applicantIds, status } },
    });
    if (res?.data) {
      setConfirmBox(false);
      toast.success("Successfully Updated!");
      getJobOrderApplicants();
    }
    // setCurrentBulkAction("");
    setActionData([]);
    getJobOrderApplicants();
  };

  const changingStatus = async (id: string, status: string) => {
    let res = await updateApplicantsStatus({
      variables: { data: { applicantIds: [id], status } },
    });

    if (res?.data) {
      toast.success(`Successfully changed to, ${status}`);
    }
  };

  const updateJobApplicant = async () => {
    let res = await updateApplicantStatus({
      variables: { applicantId, status: applicantStatus },
    });
    if (res?.data) {
      setConfirmBox(false);
      toast.success("Successfully Updated!");
      getJobOrderApplicants();
    }
  };

  if (waiting) {
    return <Loader />;
  }
  const applicant = data?.getJobOrder;

  // eslint-disable-next-line
  const filteredObj = actionData?.filter((item) => {
    if (item?.url) {
      if (!item?.url?.includes("linkedin")) {
        return item;
      }
    }
  });
  // const filteredEmails = filteredObj.map((item) => {
  //   return item?.url;
  // });

  return (
    <div className="admin-tabs team-tabs">
      <GoBack url="/advance-search-job-candidate" />

      <div className="primaryHeading primaryHeaderSpacing">
        <h5 className="m-0">Advance Search Results</h5>
      </div>

      <Card className="border-0">
        <div className="heading-border">
          <h5>Job Order Widget </h5>
        </div>
        <CardBody className="p-0">
          <div className="cards-flex cards-job-application">
            <div className="job-application-search-grid">
              <h5 className="text-center mt-2">
                {applicant?.company?.name || "--"}
              </h5>
              <label className="w-100 text-center mb-2 text-muted">
                Company Name
              </label>
            </div>
            <div className="job-application-search-grid">
              <h5 className="text-center mt-2">
                {applicant?.jobTitle || "--"}
              </h5>
              <label className="w-100 text-center mb-2 text-muted">
                Job Title
              </label>
            </div>
            <div className="job-application-search-grid">
              <h5 className="text-center mt-2">
                {`${applicant?.companyLocation?.city},
                ${applicant?.companyLocation?.state}`}
              </h5>
              <label className="w-100 text-center mb-2 text-muted">
                Job Ad Location
              </label>
            </div>
            <div className="job-application-search-grid">
              <h5 className="text-center mt-2">
                {applicant?.company?.owner?.fullName || "--"}
              </h5>
              <label className="w-100 text-center mb-2 text-muted">
                Company Owner
              </label>
            </div>
            <div className="job-application-search-grid">
              <h5 className="text-center mt-2">
                {stats?.getJobOrderStats?.jobApplicantCount || 0}
              </h5>
              <label className="w-100 text-center mb-2 text-muted">
                # of Applicants
              </label>
            </div>
          </div>
        </CardBody>
      </Card>

      <Card className="border-0 agreement-details activity-card">
        {actionData?.length ? (
          <div className="heading-border">
            <div className="d-flex align-items-center">
              <h5 className="mr-2">{actionData?.length} Selected</h5>
              <div className="d-flex sticky-action-btns">
                {/* // eslint-disable-next-line */}

                <Button onClick={() => bulkActionHandler("IN_KEEP_LIST")}>
                  Add to keep
                </Button>

                <Button
                  className="ml-2 border-0  m-0 p-0"
                  onClick={() => bulkActionHandler("IN_EXCLUDED_LIST")}
                >
                  Add to excluded
                </Button>

                <a
                  href="#/"
                  className="mx-3 d-block bg-light text-dark p-2 py-1 btn-sm"
                >
                  <h5>Add to Recruiter Folder</h5>
                </a>
              </div>
            </div>
          </div>
        ) : (
          <div className="heading-border">
            <div className="d-flex align-items-center">
              <h5 className="mr-2">Switch To: </h5>

              <InputField
                style={{ minWidth: "max-content" }}
                className="save-changes"
                inputtype="select"
                selectItems={APPLICANT_STATUS_RESULTS}
                inputid="status"
                inputValue={statusValue}
                placeholder="All Results"
                inputMethod={(value) => {
                  setStatusValue(value);
                }}
              />
            </div>
          </div>
        )}

        {jobApplicantsList?.getApplicants?.jobApplicants?.length ? (
          jobApplicantsList?.getApplicants?.jobApplicants?.map(
            (item: any, index: number) => (
              <CardBody key={index} className="border-bottom">
                <Row className="job-applicant-content">
                  <Col lg="6" md="12" className="mb-2">
                    <div>
                      <Label className="ml-3 d-block">
                        <CheckJobApplicantComponent
                          item={item}
                          actionData={actionData}
                          setActionData={setActionData}
                          forInterview={false}
                        />
                        <span className="font-weight-bold ml-2 candidate-name">
                          {" "}
                          {item?.candidate?.fullName}
                        </span>
                      </Label>

                      <Label className="text-muted d-block ml-4">
                        {item?.candidate?.idealJobTitle}
                      </Label>
                      <Label className="text-muted d-block ml-4">
                        {item?.candidate?.city} {item?.candidate?.city && ","}{" "}
                        {item?.candidate?.stateCode}
                      </Label>
                      <div className="ml-4">
                        {item?.candidate?.employmentHistory?.length
                          ? item?.candidate?.employmentHistory
                              ?.filter((val: any) => !val?.endedAt)
                              ?.map((item) => {
                                return (
                                  <span className="d-block my-3">
                                    <span className="">
                                      {item?.jobTitle} {"  "}{" "}
                                      <span className="at-the-rate">@</span>{" "}
                                      <span className="font-weight-bold">
                                        {item?.companyName}
                                      </span>
                                      <span className="ml-2">
                                        (
                                        {item?.startedAt
                                          ? formatDateUS(item?.startedAt)
                                          : "--"}{" "}
                                        {item?.startedAt && "-"}{" "}
                                        {item?.endedAt
                                          ? formatDateUS(item?.endedAt)
                                          : "current"}
                                        )
                                      </span>
                                    </span>
                                  </span>
                                );
                              })
                          : "--"}
                      </div>
                    </div>
                  </Col>
                  <Col lg="6" md="12" className="mb-2">
                    <div className="job-applicant-buttons">
                      <Button
                        onClick={() => changingStatus(item?.id, "IN_KEEP_LIST")}
                        className="border-0 mr-2 d-block bg-light text-dark p-2 py-1 btn-sm"
                      >
                        Add to keep
                      </Button>

                      <Button
                        onClick={() =>
                          changingStatus(item?.id, "IN_EXCLUDED_LIST")
                        }
                        className="border-0 mr-2 d-block bg-light text-dark p-2 py-1 btn-sm"
                      >
                        Add to excluded
                      </Button>

                      <Button className="border-0 mr-2 d-block bg-light text-dark p-2 py-1 btn-sm">
                        Add to Recruiter folder
                      </Button>

                      <Link
                        to={{
                          pathname: `/candidate-submission/${item?.candidate?.id}`,
                          state: {
                            backUrl: `/advance-search-job-candidate/${jobOrderId}/results`,
                            applicantId: item.id,
                            companyId: applicant?.company?.id,
                            jobOrderId: jobOrderId,
                          },
                        }}
                        className="d-block bg-light text-dark p-2 py-1 btn-sm"
                      >
                        Submit
                      </Link>

                      {/* <Select
                          className="w-50"
                          options={APPLICANT_STATUS}
                          onChange={(value: any) => {
                            setConfirmBox(true);
                            setApplicantId(item?.id);
                            setApplicantStatus(value?.value);
                          }}
                          value={
                            item?.JobAppStatus
                              ? APPLICANT_STATUS.find(
                                  (value) => value.value === item?.JobAppStatus
                                )
                              : null
                          }
                        /> */}
                    </div>
                  </Col>

                  <Col md="4">
                    <FormGroup className="ml-4 mb-2">
                      <label>Pipeline Stage</label>
                      <h6>{item?.candidate?.status || "--"}</h6>
                    </FormGroup>

                    <FormGroup className="ml-4 mb-2">
                      <label>Source</label>
                      <h6>{item?.candidate?.source || "--"} </h6>
                    </FormGroup>

                    <FormGroup className="mb-2 ml-4">
                      <label className="d-block">Most Recent Resume</label>
                      {item?.candidate?.resume?.length ? (
                        <h6
                          className="d-block"
                          onClick={() => {
                            downloadSignedUrlFile(
                              item?.candidate?.resume[0]?.documentUrl,
                              item?.candidate?.resume[0]?.documentName
                            );
                          }}
                        >
                          <span className="resume-download-coloring">
                            {item?.candidate?.resume[0]?.documentName}{" "}
                          </span>
                        </h6>
                      ) : (
                        "--"
                      )}
                    </FormGroup>

                    <FormGroup className="mb-2 ml-4">
                      <label className="d-block">Skills</label>
                      <div className="skills-badge">
                        {item?.candidate?.skillsSet?.length
                          ? item?.candidate?.skillsSet?.map((v) => (
                              <span className="p-2 mr-2 mb-2 rounded bg-light">
                                {v?.skill?.name}
                              </span>
                            ))
                          : "--"}
                      </div>
                    </FormGroup>
                  </Col>

                  <Col md="4">
                    <FormGroup className="mb-2 ml-4">
                      <label>Candidate Owner</label>
                      <h6>
                        {item?.candidate?.recruiterOwner?.fullName || "--"}
                      </h6>
                    </FormGroup>

                    <FormGroup className="ml-4 mb-2">
                      <label className="d-block">LinkedIn Url</label>

                      <a
                        href={item?.candidate?.linkedInUrl || "#"}
                        target="blank"
                        className="resume-download-coloring"
                      >
                        {item?.candidate?.linkedInUrl || "--"}
                      </a>
                    </FormGroup>

                    <FormGroup className="mb-2 ml-4">
                      <label>Last Reume Uploaded</label>
                      {item?.candidate?.resume?.length ? (
                        <h6>
                          {item?.candidate?.resume[0]?.createdAt
                            ? formatDateUS(
                                item?.candidate?.resume[0]?.createdAt
                              )
                            : "--"}
                        </h6>
                      ) : (
                        "--"
                      )}
                    </FormGroup>
                  </Col>

                  <Col md="4">
                    <FormGroup className="mb-2 ml-4">
                      <label>Last Updated</label>
                      <h6>
                        {item?.updatedAt ? formatDateUS(item?.updatedAt) : "--"}
                      </h6>
                    </FormGroup>

                    <FormGroup className="ml-4 mb-2 employment-text">
                      <label className="d-block">Recent Past Jobs</label>
                      {item?.candidate?.employmentHistory?.length
                        ? item?.candidate?.employmentHistory?.map((val) => (
                            <span className="d-block my-3">
                              <span className="">
                                {val?.jobTitle} {"  "}{" "}
                                <span className="at-the-rate">@</span>{" "}
                                <span className="font-weight-bold">
                                  {val?.companyName}
                                </span>
                                <span className="ml-2">
                                  (
                                  {val?.startedAt
                                    ? formatDateUS(val?.startedAt)
                                    : "--"}{" "}
                                  {val?.startedAt && "-"}{" "}
                                  {val?.endedAt
                                    ? formatDateUS(val?.endedAt)
                                    : "current"}
                                  )
                                </span>
                              </span>
                            </span>
                          ))
                        : "--"}
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            )
          )
        ) : (
          <NoDataFound text="No Applicant Found!" />
        )}
      </Card>

      {jobApplicantsList?.getApplicants?.jobApplicants?.length ? (
        <div className="users-pagination">
          <Pagination
            onPageChange={(pageClicked: number) => {
              setCurrentPage(pageClicked);
            }}
            pageCount={Math.ceil(
              jobApplicantsList?.getApplicants?.count / limit
            )}
            currentPage={currentPage}
          />

          <div className="d-flex align-items-center">
            <div className="pagination-display paginationItemsSelect">
              <p className="m-0">
                {(currentPage - 1) * limit + 1} to{" "}
                {Math.min(
                  currentPage * limit,
                  jobApplicantsList?.getApplicants?.count
                )}{" "}
                of {jobApplicantsList?.getApplicants?.count}
              </p>

              <div className="divider"></div>

              <label>Display</label>

              <InputField
                label=""
                inputtype="select"
                selectItems={PAGE_LIMIT.filter((item) => item.value !== limit)}
                inputid="limit"
                placeholder={limit.toString()}
                inputMethod={(value) => {
                  setCurrentPage(1);
                  setLimit(parseInt(value));
                }}
              />
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      <ConfirmPopUp
        confirmText="Are you sure you want to change this applicant status?"
        isOpen={confirmBox}
        toggle={setConfirmBox}
        confirmAction={updateJobApplicant}
        modalHeading="Applicant Status"
        btnText="Confirm"
        btnColor="primary"
      />
    </div>
  );
};

export default SearchResultIndex;
