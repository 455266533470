import { useLazyQuery } from "@apollo/client";
import {
  useContext,
  useEffect,
  useState,
} from "react";
import Select from "react-select";
import { Col, Label, Row } from "reactstrap";
import { customSelectStyleNew } from "../../../../../components/styled/customSelect";
import { AppContext } from "../../../../../context";
import { FETCH_CONTACTS_AGAINST_COMPANY } from "../../gql";

export default function ClientBox({
  fromEdit,
  companyId,
  clientInterviewEmailData,
  pipeSelectedClientNames,
  errorListEmail,
  setErrorListEmail,
}) {
  const [selectedNames, setSelectedNames] = useState<Array<any>>([]);
  const { theme } = useContext(AppContext);

  const [
    companyContactsGetter,
    { loading: companyContactsLoading, data: companyContacts },
  ] = useLazyQuery(FETCH_CONTACTS_AGAINST_COMPANY);

  useEffect(() => {
    if (companyId) {
      companyContactsGetter({
        variables: {
          limit: 30,
          page: 1,
          searchText: "",
          statusSearch: "",
          companyId,
        },
      });
    }

    // eslint-disable-next-line
  }, []);

  const onClientNamesLengthChecker = () => {
    pipeSelectedClientNames(selectedNames);
  };

  useEffect(() => {
    if (selectedNames) {
      onClientNamesLengthChecker();
    }
    setErrorListEmail(selectedNames?.filter((item) => !item?.value));
    if (errorListEmail?.length) {
      let exist: any = [];
      for (const item of selectedNames) {
        if (item?.label === item) {
          exist.push(item);
        }
      }
    }

    // eslint-disable-next-line
  }, [selectedNames]);

  useEffect(() => {
    if (fromEdit) {
      if (companyContacts) {
        const filteredArray = companyContacts?.allContacts?.contacts.map(
          (emails: { contactEmail: any[] }) =>
            emails.contactEmail?.find((singleEmail) =>
              clientInterviewEmailData?.toEmails?.includes(singleEmail?.email)
            )
        );

        if (filteredArray?.length) {
          const filteredOptions = filteredArray
            ?.filter((item) => item)
            .map((item) => {
              const value = item.email;

              console.log(
                "companyContacts?.allContacts?.contacts::",
                companyContacts?.allContacts?.contacts
              );

              const label =
                // eslint-disable-next-line array-callback-return
                companyContacts?.allContacts?.contacts?.find((c) => {
                  if (c.contactEmail.find((contactEmail) => contactEmail.email === value))
                    return true;
                })?.fullName || "";

              return { label, value };
            });

          setSelectedNames(filteredOptions);
          pipeSelectedClientNames(filteredArray);
        }
      }
    }

    // eslint-disable-next-line
  }, [companyContacts?.allContacts?.contacts, companyContactsLoading]);

  return (
    <div className="mt-2 pb-2">
      <Row>
        <Col lg="12">
          <Label className="text-14 text-blackele pb-3">
            Select Client Contact <span className="text-danger">*</span>
          </Label>

          <Select
            value={selectedNames}
            isLoading={companyContactsLoading}
            isMulti
            rules={{
              required: {
                value: true,
                message: "Candidate name must be selected!",
              },
            }}
            onChange={(type: any) => {
              setSelectedNames(type);
            }}
            options={companyContacts?.allContacts?.contacts.map(
              (item: { fullName: string; id: string; contactEmail: any }) => {
                return {
                  label: item.fullName,
                  value: item?.contactEmail?.length
                    ? item?.contactEmail?.find(
                        (item: { isPrimary: boolean }) => item.isPrimary
                      )?.email || item?.contactEmail[0]?.email
                    : "",
                };
              }
            )}
            styles={{ ...customSelectStyleNew(theme) }}
          />
          <ul className="ml-3 mt-2">
            {errorListEmail?.map((item: { label: string }) => {
              return (
                <li className="text-danger">
                  <small>{item?.label} don't have an email</small>
                </li>
              );
            })}
          </ul>
        </Col>
      </Row>
    </div>
  );
}
