import { useLazyQuery, useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { withRouter } from 'react-router';
import { toast } from 'react-toastify';
import { Col, Form, Input, Row } from 'reactstrap';
import CustomButton from '../../../../components/layout/CustomButton';
import InputField from '../../../../components/layout/InputField';
import { CLEARBIT_AUTOCOMPLETE, CONTACT_MANUAL_ADD } from '../gql';
import { IContactManualInput } from '../interfaceContact';
import { Label, FormGroup } from 'reactstrap';
import { useEffect, useState } from 'react';
import CompanySuggestions from './CompanySuggestions';

let time = 0;

const AddContactManually = ({
  compaignId,
  reFetchContacts,
  refetchStats,
  addContactToggle,
}: any) => {
  const { handleSubmit, reset, register, errors, setValue, getValues } =
    useForm();

  const [companyName, setCompanyName] = useState<string>('');
  const [companyToggle, setCompanyToggle] = useState<boolean>(false);
  const [searchingText, setSearchingText] = useState<string>('');
  const [createContactManually, { loading: loadingContactManual }] =
    useMutation(CONTACT_MANUAL_ADD);

  const [
    getClearbitSuggestions,
    { loading: loadingClearbitSuggestions, data: dataClearbitSuggestions },
  ] = useLazyQuery(CLEARBIT_AUTOCOMPLETE);

  const createNewContact = async (values: IContactManualInput) => {
    values.company = companyName;
    const response = await createContactManually({
      variables: { compaignId, ...values },
    });

    const data = response?.data?.manuallyContactAdd;

    if (data?.status === 302) {
      toast.error('Contact already exist!');

      reset();
      addContactToggle();
      addContactToggle();
    } else {
      toast.success('Successfully created contact');
      reFetchContacts();
      refetchStats();
      reset();
      addContactToggle();

      window?.location?.reload();
    }
  };

  const getClearbitAutocompleteSuggestions = (query: string) => {
    getClearbitSuggestions({ variables: { query } });
  };

  useEffect(() => {
    if (searchingText && searchingText.length > 2) {
      window?.clearTimeout(time);
      time = window?.setTimeout(() => {
        getClearbitAutocompleteSuggestions(searchingText);
      }, 1000);
    }

    // eslint-disable-next-line
  }, [searchingText]);

  useEffect(() => {
    if (dataClearbitSuggestions?.clearbitAutocompleteForCompanyFinding) {
      setCompanyToggle(true);
    }
  }, [dataClearbitSuggestions?.clearbitAutocompleteForCompanyFinding]);

  const getSelectedCompanyData = (value: any) => {
    setCompanyName(value?.name);
    setValue('company', value?.name);
  };

  return (
    <div>
      <Form onSubmit={handleSubmit(createNewContact)}>
        <Row>
          <Col xl="6" lg="6" sm="12" xs="12">
            <InputField
              inputtype="text"
              isRequired={true}
              label="Name"
              inputid="name"
              placeholder="Contact Name"
              inputRef={register({
                required: {
                  value: true,
                  message: 'Contact name is required!',
                },
              })}
              errMsg={errors?.name?.message}
            />
          </Col>

          <Col xl="6" lg="6" sm="12" xs="12">
            <Label>Company</Label>
            <FormGroup>
              <Input
                inputtype="input"
                inputid="company"
                aria-label="Company"
                placeholder="Please enter company name"
                loading
                value={companyName || getValues()?.company}
                errMsg={errors?.company?.message}
                onChange={({ target }: React.ChangeEvent<HTMLInputElement>) => {
                  setCompanyName('');
                  setSearchingText(target?.value);
                }}
              />
              {companyToggle && searchingText?.length > 2 && (
                <CompanySuggestions
                  type="manual"
                  loading={loadingClearbitSuggestions}
                  companies={
                    dataClearbitSuggestions?.clearbitAutocompleteForCompanyFinding
                  }
                  setCompanyToggle={setCompanyToggle}
                  setSelectedCompany={getSelectedCompanyData}
                />
              )}
            </FormGroup>
          </Col>

          <Col xl="6" lg="6" sm="12" xs="12">
            <InputField
              inputtype="text"
              isRequired={true}
              label="Work Email"
              inputid="email"
              placeholder="Work Email"
              inputRef={register({
                required: {
                  value: true,
                  message: 'Work email is required!',
                },
              })}
              errMsg={errors?.email?.message}
            />
          </Col>

          <Col xl="6" lg="6" sm="12" xs="12">
            <InputField
              inputtype="text"
              label="Website"
              inputid="url"
              placeholder="Website"
              inputRef={register()}
            />
          </Col>

          <Col xl="6" lg="6" sm="12" xs="12">
            <InputField
              inputtype="text"
              label="Public Linkedin Profile Url"
              inputid="profile_url"
              placeholder="Linkedin Profile Url"
              inputRef={register()}
            />
          </Col>

          <Col xl="6" lg="6" sm="12" xs="12">
            <InputField
              inputtype="text"
              label="Location"
              inputid="location"
              placeholder="e.g. 1005 N Gravenstein Highway Sebastopol CA 95472"
              inputRef={register()}
            />
          </Col>

          <Col xl="6" lg="6" sm="12" xs="12">
            <InputField
              inputtype="text"
              label="Title"
              inputid="title"
              placeholder="title"
              inputRef={register()}
            />
          </Col>

          <Col xl="6" lg="6" sm="12" xs="12">
            <InputField
              inputtype="text"
              label="Phone number"
              inputid="phone_number"
              placeholder="Phone number"
              inputRef={register()}
            />
          </Col>
        </Row>

        <div className="d-flex justify-content-end align-items-center mt-2">
          <button
            onClick={addContactToggle}
            className="buttonPrimary mr-3"
            type="button"
          >
            Cancel
          </button>

          <CustomButton
            buttonText="Create Contact"
            buttonType="submit"
            loading={loadingContactManual}
            disabled={loadingContactManual}
          />
        </div>
      </Form>
    </div>
  );
};

export default withRouter(AddContactManually);
