import { Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import SEARCH_IMG from '../../assets/images/search.svg';
import './agreement.scss';
import UnreadNotificationCard from './components/UnreadNotificationCard';
import PastNotificationCard from './components/PastNotificationCard';
import { useCallback, useState } from 'react';
import { useEffect } from 'react';
import GoBack from '../../components/GoBack';
import { RouteComponentProps } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import { FETCH_NEW_NOTIFICATIONS } from './gql';
import { SETTINGS_ROUTE } from '../../utils/constant';

const AllNotifications = ({
  location,
  history,
}: RouteComponentProps): JSX.Element => {
  const [searchInput, setSearchInput] = useState<string>('');
  const [updateRead, setUpdateRead] = useState<boolean>(false);
  const [updateUnread, setUpdateUnread] = useState<boolean>(false);
  const [pendingAction, setPendingAction] = useState<boolean>(false);
  const [fetchAllNewNotifications, { data, loading }] = useLazyQuery(
    FETCH_NEW_NOTIFICATIONS,
    {
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,

      onError({ message }) {
        console.log('Notifications error =>', message);
      },
    }
  );
  const {
    location: { state },
  }: any = history || {};
  const [limit, setLimit] = useState(10);
  const [statusFilter] = useState<string>('');
  const [page, setPage] = useState<number>(1);

  const fetchRead = () => setUpdateRead(!updateRead);
  const fetchUnread = () => setUpdateUnread(!updateUnread);

  const notificationHandler = useCallback(async () => {
    await fetchAllNewNotifications({
      variables: {
        limit,
        page: page,
        searchText: searchInput,
        alertStatus: statusFilter,
      },
    });
  }, [fetchAllNewNotifications, limit, page, searchInput, statusFilter]);

  useEffect(() => {
    document.title = 'Notifications - RealREPP';

    state &&
      state?.pendingAction &&
      setPendingAction(state?.pendingAction as boolean);
    console.log('USE EFFECT WORKING ?');
    notificationHandler();
  }, [notificationHandler, state, state?.pendingAction]);

  return (
    <div className="admin-tabs team-tabs">
      <GoBack url={SETTINGS_ROUTE} />

      <div className="priamryHeading primaryHeaderSpacing mobileResponsiveFlexAlign justify-content-between">
        <h5 className="m-0">Notifications</h5>

        <div className="search-filter">
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <img src={SEARCH_IMG} alt="search" />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              className="search-by-company"
              type="text"
              placeholder="Search"
              value={searchInput}
              onChange={(e) => {
                setSearchInput(e.target.value);
              }}
            />
          </InputGroup>
        </div>
      </div>

      <UnreadNotificationCard
        searchInput={searchInput}
        pendingActions={!pendingAction}
        update={updateRead}
        fetch={fetchUnread}
        notificationHandler={notificationHandler}
        limit={limit}
        setLimit={setLimit}
        setPage={setPage}
        page={page}
        loading={loading}
        data={data}
      />

      <PastNotificationCard
        searchInput={searchInput}
        update={updateUnread}
        fetch={fetchRead}
        limit={limit}
        setLimit={setLimit}
        setPage={setPage}
        page={page}
      />
    </div>
  );
};

export default AllNotifications;
