import { useMutation } from '@apollo/client';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import {
  Button,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  Spinner,
} from 'reactstrap';
import SocialMediaInfo from '../../../components/SocialMediaInfo';
import AddressInfo from '../../company/company-components/AddressInfo';
import BasicInfo from '../../company/company-components/BasicInfo';
import { CREATE_COMPANY } from '../../company/gql';
import CLOSE_ICON from '../../../assets/images/close-icon.svg';

const CreateCompanyModal = ({ open, toggle, preSelectCompany }) => {
  const {
    errors,
    handleSubmit,
    register,
    reset,
    control,
    unregister,
    getValues,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      domains: [{ domain: '', isPrimary: '' }],
    },
  });
  // const [checkDomain] = useMutation(CHECK_DOMAIN_EXIST);

  const onCancel = ()=>{
    toggle()
    setSelectedIndustry([])
    reset()
  }
  const [selectedIndustry, setSelectedIndustry] = useState<Array<any>>([]);
  // const [customDomainInputs, setCustomDomainInputs] = useState<any>([
  //   {
  //     domain: "",
  //     isPrimary: false,
  //   },
  // ]);
  const [_createCompany, { loading: waiting }] = useMutation(CREATE_COMPANY);
  const createCompany = async (values: any) => {
    values.industry =
      selectedIndustry && selectedIndustry?.length
        ? selectedIndustry?.map((item) => item.value)
        : [''];
    delete values['isPrimary'];

    if (values.domain) {
      values.domains = [
        {
          isPrimary: true,
          domain: values?.domain,
        },
      ];
    }
    values.country = values.countryCode;
    values.state = values.stateCode;
    delete values.domain;
    delete values.countryCode;
    delete values.stateCode;
    delete values.domain;

    let resForCreateCompany = await _createCompany({
      variables: { companyInput: { ...values } },
    });
    if (resForCreateCompany?.data?.createCompany) {
      preSelectCompany(resForCreateCompany?.data?.createCompany);
      toast.success('Company successfully created!');
      toggle();
      setSelectedIndustry([])
      reset();
    }else{
      toast.error('Something Went Wrong');
      setSelectedIndustry([])
      toggle();
      reset();
    }
  };
  // const { fields, append, remove } = useFieldArray({
  //   control,
  //   name: "domains",
  // });
  return (
    <Modal
      isOpen={open}
      toggle={toggle}
      size="xl"
      className="revampDialog"
    >
      <div className="modalHeaderCustom d-flex align-items-center justify-content-between">
        <h5>Create New Company</h5>
        <Button
          className="p-0 bg-transparent border-0"
          onClick={() => toggle()}
        >
          <img src={CLOSE_ICON} alt="close" />
        </Button>
      </div>

      <Form>
        <ModalBody>
          <BasicInfo
            getValues={getValues}
            unregister={unregister}
            setValue={setValue}
            control={control}
            register={register}
            errors={errors}
            selectedIndustry={selectedIndustry}
            setSelectedIndustry={setSelectedIndustry}
          />
          <AddressInfo
            register={register}
            errors={errors}
            watch={watch}
            setValue={setValue}
          />
          <SocialMediaInfo
            setValue={setValue}
            isLinkedinRequired={true}
            register={register}
            errors={errors}
            companyAddress={true}
            getValues={getValues}
          />
        </ModalBody>

        <ModalFooter>
          <button onClick={onCancel} className="buttonPrimary mr-3" type="button">
            Cancel
          </button>

          <button
            type="button"
            className="btn btn-primary"
            onClick={handleSubmit(createCompany)}
          >
            {waiting && <Spinner size="sm" className="mr-2" />}
            Save
          </button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default CreateCompanyModal;
