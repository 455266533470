import { useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import {
  Button,
  Col,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import CustomButton from "../../../../components/layout/CustomButton";
import InputField from "../../../../components/layout/InputField";
import { SEQUENCE_STEP_WAIT_DAYS_EDIT } from "../gql";
import {
  IEditStepModelProps,
  ISequenceWaitDaysEditInput,
} from "../interfaceSteps";

const EditStepModel = ({
  _id,
  compaignId,
  isOpen,
  toggle,
  refetchSteps,
  sequenceStepsLengthCheck,
  updateWaitDays,
}: IEditStepModelProps) => {
  const { errors, handleSubmit, register, reset } = useForm({
    mode: "all",
  });
  const [editStepWaitDays, { loading }] = useMutation(
    SEQUENCE_STEP_WAIT_DAYS_EDIT
  );

  const REGEX_FOR_ONLY_DIGITS = new RegExp(/^\d+$/);
  const editStepHandler = async (values: ISequenceWaitDaysEditInput) => {
    if (sequenceStepsLengthCheck && _id) {
      const response = await editStepWaitDays({
        variables: { compaignId, _id, wait_days: parseInt(values?.wait_days) },
      });

      if (response?.data) {
        toast.success(`Wait days updated successfully`);
        toggle();
        reset();
        refetchSteps();
      }
    } else {
      let waitingDay = parseInt(values.wait_days);
      updateWaitDays(waitingDay);
      toggle();
      reset();
    }
  };

  return (
    <div>
      <Modal isOpen={isOpen} toggle={() => toggle()}>
        <ModalHeader toggle={() => toggle()}>Step Setting</ModalHeader>
        <Form onSubmit={handleSubmit(editStepHandler)}>
          <ModalBody>
            <Row>
              <Col xs="12">
                <InputField
                  inputtype="text"
                  label="Step Type"
                  inputid="type"
                  disabled={true}
                  defaultValue="Email"
                  placeholder="Step Type"
                  inputRef={register()}
                />
              </Col>

              <Col xs="12">
                <InputField
                  isRequired={true}
                  inputtype="text"
                  label="Days To Wait"
                  inputid="wait_days"
                  placeholder="Days to wait"
                  min={1}
                  inputRef={register({
                    required: {
                      value: true,
                      message: "Please enter a value greater than 0",
                    },
                    valueAsNumber: true,
                    min: {
                      message: "Please enter a value greater than 0",
                      value: 1,
                    },
                    pattern: {
                      value: REGEX_FOR_ONLY_DIGITS,
                      message: "Please enter a value greater than 0",
                    },
                  })}
                  errMsg={errors?.wait_days?.message}
                />
              </Col>
            </Row>
          </ModalBody>

          <ModalFooter>
            <Button
              onClick={() => toggle()}
              size="sm"
              className="bg-light border-0 text-dark "
            >
              Cancel
            </Button>

            <CustomButton
              buttonText="Save"
              loading={loading}
              disabled={loading}
            />
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};

export default EditStepModel;
