import { FC } from "react"
import { InfoBarProps } from "../../../../interfaces"
import HeaderItems from "./HeaderItems"

const Header: FC<InfoBarProps> = ({ infoBarItems }) => {
  return (
    <div className="applicantDetail primaryHeaderSpacing view-candidate-container">
      {infoBarItems.map(({ label, src, tooltip }, id) => <HeaderItems label={label} src={src}
        divider={id !== infoBarItems.length - 1} tooltip={tooltip} id={id} />)}
    </div>
  )
}
export default Header