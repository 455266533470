import { useMutation, useQuery } from '@apollo/client';
import { FC, useContext } from 'react';
import Select from 'react-select';
import { toast } from 'react-toastify';
import {
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from 'reactstrap';
import { CandidateTJLookupModalInterface } from '../../../interfaces';
import { SEQUENCE_LISTING } from '../../interseller/sequences/gql';
import { CONTACTS_MANUAL_ADD_BULK } from '../../interseller/contacts/gql';
import { Controller, useForm } from 'react-hook-form';
import { customSelectStyleNew } from '../../../components/styled/customSelect';
import { AppContext } from '../../../context';

const CandidateTJLookupModal: FC<CandidateTJLookupModalInterface> = ({
  isOpen,
  toggle,
  recordToAdd,
  title,
}): any => {
  const { handleSubmit, errors, control} = useForm();
  const { data: recruitingSequenceData } = useQuery(SEQUENCE_LISTING, {
    variables: {
      query: '',
      businessDevelopmentSequences: null,
    },
  });

  const [addToSequence, { loading }] = useMutation(
    CONTACTS_MANUAL_ADD_BULK
  );

  const addToSequenceButton = async (item) => {
    const candidatesToAdd = recordToAdd?.map((candidate) => {
      return {
        name: candidate?.fullName,
        email: candidate?.emails[0]?.email,
        company: candidate?.companyName,
        title: candidate?.jobTitle,
        phone_number: candidate?.phones[0]?.phoneNumber,
      };
    });

    let res = await addToSequence({
      variables: {
        compaignId: item?.sequence?.value,
        contactManualInputBulkArr: { contactManualInputBulk: candidatesToAdd },
      },
    });

    if (res?.data?.manuallyContactsAddBulk?.status === 200) {
      toast.success(res?.data?.manuallyContactsAddBulk?.message);
      toggle();
    }
  };

  const closeTJLookupModal = () => {
    toggle();
  };

  const getSequenceOptions = () => {
    return recruitingSequenceData?.allSequences?.sequences?.map((sequence) => {
      return {
        label: sequence?.title,
        value: sequence?.id,
      };
    });
  };

  const { theme } = useContext(AppContext);

  return (
    <>
      <Modal
        isOpen={isOpen}
        toggle={closeTJLookupModal}
        className="revampDialog revampDialogWidth"
      >
        <ModalHeader toggle={toggle}>{title}</ModalHeader>
        <ModalBody>
        <FormGroup>
                  <Label>
                    Select Sequence<span className="text-danger">*</span>
                  </Label>
                  <Controller
                    name="sequence"
                    as={Select}
                    options={getSequenceOptions()}
                    placeholder="sequence"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: 'sequence must be selected!',
                      },
                    }}
                    styles={{ ...customSelectStyleNew(theme) }}
                   />
                   <small className="text-danger">
                    {errors?.sequence?.message}
                  </small>
                </FormGroup>
        </ModalBody>
        <ModalFooter>
          <button
            onClick={() => closeTJLookupModal()}
            type="button"
            className="buttonPrimary"
          >
            Close
          </button>

          <button
            type="button"
            className="buttonGenericStyle filledButton"
            onClick={handleSubmit(addToSequenceButton)}
          >
            {loading && <Spinner size="sm" />} Add
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default CandidateTJLookupModal;
