import { useEffect, useState } from 'react';
import Loader from '../../../components/layout/Loader';
import BillingBoardGraph from './BillingBoardGraph';

export interface RecruiterProps {
  id: string;
  firstName: string;
  lastName: string;
  photo: string;
  startedBillingCredit: number;
  totalBillingCredit: number;
  percentageStartedBilling: number;
  percentageTotalBilling: number;
  percentageTempBilling: number;
  tempBillingCredit: number;
  percentagetotalBillingCreditForBoard: number;
  totalBillingCreditForBoard: number;
  expectedDirectHireAmount: number;
  percentageExpectedDirectHireAmount: number;
  tempStartedBillingCredit:number
  tempTotalBillingCredit:number
  invoicedTotalbillingCredit:number;
  totalBillingAmountfortotalBoard:number;
  expectedTotalCreditAmount:number;
  percentageInvoicedTotalbillingCredit:number;
  percntageExpectedTotalCreditAmount:number;
}

const BillingBoard = ({ data, widget, typeBoard }) => {
  const [boardData, setBoardData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const populateBoardData = (data) => {
    if (typeBoard === 'directHireBilling') {
      const recruiters = data?.getRecruiterBillingCredit;
      const maxCreditForTotalBilling = Math.max(
        ...recruiters?.map((recruiter) => recruiter?.totalBillingCredit)
      );

      const populatedRecruiters: any = [];

      for (let recruiter of recruiters) {
        let expectedDirectHireAmount=recruiter?.totalBillingCredit-recruiter?.startedBillingCredit
        populatedRecruiters.push({
          ...recruiter,
          expectedDirectHireAmount:expectedDirectHireAmount,
          percentageTotalBilling: (recruiter?.totalBillingCredit / maxCreditForTotalBilling) * 100,
          percentageStartedBilling: (recruiter?.startedBillingCredit / recruiter?.totalBillingCredit) * 100,
          percentageExpectedDirectHireAmount: (expectedDirectHireAmount / recruiter?.totalBillingCredit) * 100,
        });
      }
      const sortedRecruiters = populatedRecruiters.sort((a, b) => {
        if (((b.percentageTotalBilling === 0 || !b.percentageTotalBilling) && (a.percentageTotalBilling === 0 || !a.percentageTotalBilling)) ) {
          return a.firstName.localeCompare(b.firstName);
        } else {
          return b.percentageTotalBilling - a.percentageTotalBilling;
        }
      });
      const slicedRecruiters = widget
        ? sortedRecruiters?.slice(0, 5)
        : sortedRecruiters?.slice(0, sortedRecruiters?.length);
      setBoardData(slicedRecruiters);
      setIsLoading(false);
    } else if (typeBoard === 'tempHireBilling') {
      const recruiters = data?.getRecruiterBillingCredit;
      const maxCreditForTotalBilling = Math.max(
        ...recruiters?.map((recruiter) => recruiter?.tempTotalBillingCredit)
      );

      const populatedRecruiters: any = [];

      for (let recruiter of recruiters) {
        populatedRecruiters.push({
          ...recruiter,
          percentageTempBilling:
            (recruiter?.tempStartedBillingCredit / maxCreditForTotalBilling) * 100,
        });
      }
      const sortedRecruiters = populatedRecruiters.sort((a, b) => {
        if ((b?.percentageTempBilling === 0 || !b?.percentageTempBilling) && (a?.percentageTempBilling === 0 || !a?.percentageTempBilling)) {
          return a?.firstName?.localeCompare(b?.firstName);
        } else {
          return b?.percentageTempBilling - a?.percentageTempBilling;
        }
      });

      const slicedRecruiters = widget
        ? sortedRecruiters?.slice(0, 5)
        : sortedRecruiters?.slice(0, sortedRecruiters?.length);

      setBoardData(slicedRecruiters);
      setIsLoading(false);
    } else if (typeBoard === 'totalHireBilling') {
      const recruiters = data?.getRecruiterBillingCredit;
      const maxCreditForTotalBilling = Math.max(
        ...recruiters?.map(
          (recruiter) =>
            recruiter.totalBillingCredit + recruiter.tempTotalBillingCredit
        )
      );
      const populatedRecruiters: any = [];
      for (let recruiter of recruiters) {
       let invoicedTotalbillingCredit=recruiter?.tempStartedBillingCredit+recruiter?.startedBillingCredit
       let totalBillingAmountfortotalBoard= recruiter?.totalBillingCredit + recruiter?.tempTotalBillingCredit
       let expectedTotalCreditAmount=(recruiter?.totalBillingCredit-recruiter?.startedBillingCredit)+(recruiter?.tempTotalBillingCredit - recruiter?.tempTotalBillingCredit)
        populatedRecruiters.push({
          ...recruiter,
          percentagetotalBillingCreditForBoard:
            ((recruiter?.totalBillingCredit + recruiter?.tempTotalBillingCredit) /
              maxCreditForTotalBilling) *
            100,
            invoicedTotalbillingCredit:invoicedTotalbillingCredit,
            totalBillingAmountfortotalBoard:totalBillingAmountfortotalBoard,
            expectedTotalCreditAmount:expectedTotalCreditAmount,
            percentageInvoicedTotalbillingCredit:(invoicedTotalbillingCredit/totalBillingAmountfortotalBoard)*100,
            percntageExpectedTotalCreditAmount:(expectedTotalCreditAmount/totalBillingAmountfortotalBoard)*100
        });
      }
      const sortedRecruiters = populatedRecruiters.sort((a, b) => {
        if (
         ( b.percentagetotalBillingCreditForBoard === 0 ||  !b.percentagetotalBillingCreditForBoard) &&
          (a.percentagetotalBillingCreditForBoard === 0 ||  !a.percentagetotalBillingCreditForBoard)
        ) {
          return a.firstName.localeCompare(b.firstName);
        } else {
          return (
            b.percentagetotalBillingCreditForBoard -
            a.percentagetotalBillingCreditForBoard
          );
        }
      });
      const slicedRecruiters = widget
        ? sortedRecruiters?.slice(0, 5)
        : sortedRecruiters?.slice(0, sortedRecruiters?.length);
      setBoardData(slicedRecruiters);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (data) {
      populateBoardData(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (isLoading) return <Loader />;
  return (
    <div>
      {boardData?.map((recruiter: RecruiterProps, index) => (
        <BillingBoardGraph
          recruiter={recruiter}
          index={index}
          typeBoard={typeBoard}
        />
      ))}
    </div>
  );
};

export default BillingBoard;
