import {
  COUNTRY_STATES_PROVINCE,
  FeeTypeDto,
  JobOrderStatusDto,
  RateTypeDto,
  RecruitingLevelDto,
  workplaceTypesDto,
  WorkTypeDto,
} from "./constant";
import { commaRemover, localStringMaker } from "./helper";

export const createJobOrderDate = (data: any) => {
  if (data) {
    let values = { ...data };
    values.agreementId = values?.agreement && {
      value: values?.agreement?.id,
      label: values?.agreement?.nickName,
    };
    values.degreeId = values?.degree && {
      label: values?.degree?.name,
      value: values?.degree?.id,
    };
    values.status = values?.status && {
      label: JobOrderStatusDto[values?.status],
      value: values?.status,
    };
    values.feeType = values?.feeType && {
      label: FeeTypeDto[values?.feeType],
      value: values?.feeType,
    };
    values.recruitingLevel = values?.recruitingLevel && {
      label: RecruitingLevelDto[values?.recruitingLevel],
      value: values?.recruitingLevel,
    };
    values.rateType = values?.rateType && {
      label: RateTypeDto[values?.rateType],
      value: values?.rateType,
    };
    values.workType = values?.workType && {
      label: WorkTypeDto[values?.workType],
      value: values?.workType,
    };
    values.workplaceTypes = values?.workplaceTypes && {
      label: workplaceTypesDto[values?.workplaceTypes],
      value: values?.workplaceTypes,
    };

    let mainContact = values?.mainContact?.contact;
    let billingContact = values?.billingContact?.contact;
    let remoteContact = values?.remoteContact?.contact;
    let companyLocation = values?.companyLocation;
    let company = values?.company;
    let category = values?.category;
    let state = values?.state;

    values.companyId = company && {
      label: company?.name,
      value: company?.id,
    };
    values.companyId = company && {
      label: company?.name,
      value: company?.id,
    };

    values.companyLocationId = companyLocation && {
      label: ['US', 'CA', null, undefined, ''].includes(companyLocation?.country) ? `${companyLocation?.streetAddress}, ${companyLocation?.city}, ${companyLocation?.state}, ${companyLocation?.zipCode}, ${companyLocation?.country}` : `${companyLocation?.streetAddress}, ${companyLocation?.city}, ${companyLocation?.zipCode}, ${companyLocation?.country}`,
      value: companyLocation?.id,
    };
    values.billingContact = billingContact && {
      label: billingContact?.fullName,
      value: billingContact?.id,
    };
    const filteredState = state ? (COUNTRY_STATES_PROVINCE(companyLocation?.country) as Array<{ label: string; value: string; }>).filter((item) => item?.value === state)
      : null;
    values.state = state && filteredState?.[0] && {
      label: filteredState[0].label,
      value: filteredState[0].value,
    };
    values.mainContact = mainContact && {
      label: mainContact?.fullName,
      value: mainContact?.id,
    };

    values.remoteContact = remoteContact && {
      label: remoteContact?.fullName,
      value: remoteContact?.id,
    };

    values.categoryId = category && {
      label: values?.category?.name,
      value: values?.category?.id,
    };

    values.recruitingList = values?.recruitingAssigned?.map((v) => ({
      label: v?.user?.fullName,
      value: v?.user?.id,
    }));
    values.mustSkills = values?.mustSkills?.map((v) => ({
      label: v?.skill?.name,
      value: v?.skill?.id,
    }));
    values.niceSkills = values?.niceSkills?.map((v) => ({
      label: v?.skill?.name,
      value: v?.skill?.id,
    }));
    values.salaryMax = localStringMaker(values.salaryMax);
    values.salaryMin = localStringMaker(values.salaryMin);
    values.estimatedFee = localStringMaker(values.estimatedFee);
    values.fee = localStringMaker(values.fee);

    return values;
  }
};

export const createJobOrderPayload = (data: any) => {
  if (data) {
    let values = { ...data };
    values.recruitingLevel = "OPEN";

    values.salaryMax = commaRemover(values?.salaryMax);
    values.salaryMin = commaRemover(values?.salaryMin);
    values.fee = commaRemover(values?.fee);
    values.estimatedFee = commaRemover(values?.estimatedFee);

    values.positions = parseInt(values.positions) || 0;
    values.experienceMax = parseInt(values.experienceMax) || 0;
    values.experienceMin = parseInt(values.experienceMin) || 0;
    values.travelPercentage = parseInt(values.travelPercentage) || 0;
    values.allowRemote = values.allowRemote === "true" ? true : false;
    values.niceSkills = values.niceSkills?.length
      ? values.niceSkills?.map((v) => v.label)
      : [];
    values.mustSkills = values.mustSkills?.length
      ? values.mustSkills?.map((v) => v.label)
      : [];
    let recruitersList = values.recruitingList?.length
      ? values.recruitingList?.map((item) => item.value)
      : [];
    values.recruitingList = recruitersList;

    values.categoryId = values?.categoryId?.value;
    values.rateType = values?.rateType?.value;
    values.workType = values?.workType?.value;
    values.degreeId = values?.degreeId?.value;
    values.workplaceTypes = values?.workplaceTypes?.value;
    const newValues = {};
    for (const key in values) {
      if (Object.prototype.hasOwnProperty.call(values, key)) {
        const item = values[key];
        if (item?.value) {
          newValues[key] = item.value;
        } else {
          newValues[key] = item;
        }
      }
    }

    return newValues;
  }
};

export const createClonedJobOrderPayload = (data: any) => {
  if (data) {
    let values = { ...data };
    values.recruitingLevel = "OPEN";
    values.salaryMax = commaRemover(values?.salaryMax?.toString());
    values.salaryMin = commaRemover(values?.salaryMin?.toString());
    values.fee = commaRemover(values?.fee?.toString());
    values.estimatedFee = commaRemover(values?.estimatedFee?.toString());
    values.positions = values.positions || 0;
    values.experienceMax = values.experienceMax || 0;
    values.experienceMin = values.experienceMin || 0;
    values.travelPercentage = values.travelPercentage || 0;
    values.niceSkills = values.niceSkills?.length
      ? values.niceSkills?.map((v: { skill: { name: string } }) => v.skill.name)
      : [];
    values.mustSkills = values.mustSkills?.length
      ? values.mustSkills?.map((v: { skill: { name: string } }) => v.skill.name)
      : [];
    values.categoryId = values?.category?.id;
    values.degreeId = values?.degree?.id;

    // removing extra fields which don't require in the payload
    delete values.user;
    delete values.remoteContact;
    delete values.recruitingAssigned;
    delete values.billingContact;
    delete values.mainContact;
    delete values.companyLocation;
    delete values.id;
    delete values.company;
    delete values.agreement;
    delete values.degree;
    delete values.category;
    delete values.__typename;

    return values;
  }
};
