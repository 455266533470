import AllImports from "./AllImports";

const ImportCSV = () => {

  return (
    <>
      <h5 className="m-0 mb-4">Lookup Imports</h5>
      <AllImports adminImport={false} />
    </>

  )
}

export default ImportCSV;