import { useMutation, useQuery } from "@apollo/client";
import { useContext, useState } from "react";
import ReactQuill from "react-quill";
import { toast } from "react-toastify";
import { Button, Input, Label, Spinner } from "reactstrap";
import { RECRUITER_DEAL_TEMPLATES, recruiterDealTemplatesQuillFormats, recruiterDealTemplatesQuillModules, SAVE } from "../../constants";
import { AppContext } from "../../context";
import { ADMIN_ROUTE, ALL_FIELDS_REQUIRED_MSG, DealType, reactQuillReg, RECRUITER_DEAL_INPUTS_PLACEHOLDERS, RECRUITER_DEAL_TEMPLATES_HEADERS, recruiterDealTemplate, recruiterDealTemplate2 } from "../../utils/constant";
import GoBack from "../GoBack";
import Loader from "../layout/Loader";
import { customSelectStyleNew } from "../styled/customSelect";
import { GET_TEMPLATE_QUERY, SAVE_TEMPLATE_MUTATION } from "./gql";


const AllRecruitersDealTemplates = () => {
  const { theme } = useContext(AppContext)
  const [subject, setSubject] = useState<string>('')
  const [id, setId] = useState<string>('')
  const [compensationTerms, setCompensationTerms] = useState<string>('')
  const [commissionPaid, setCommissionPaid] = useState<string>('')
  const [conditions, setConditions] = useState<string>('')
  const [biweeklySalary, setBiweeklySalary] = useState<string>('')
  const [dhBaseRate, setDhBaseRate] = useState<string>('')
  const [tempBaseRate, setTempBaseRate] = useState<string>('')
  const [body, setBody] = useState<string>('')
  const [recruiterUnderTraining, setRecruiterUnderTraining] = useState<boolean>(false)

  const { loading, refetch, } = useQuery(GET_TEMPLATE_QUERY, {
    variables: { dealType: recruiterUnderTraining ? DealType.UNDER_TRAINING : DealType.TRAINED },
    onCompleted: (data) => {
      if (data?.getRecruiterDealTemplate?.recruiterDealsTemplates) {
        const {
          id, subject, body, commissionPaid, compensationTerms, biweeklySalary, dhBaseRate, tempBaseRate, conditions
        } = data?.getRecruiterDealTemplate?.recruiterDealsTemplates
        setId(id)
        setSubject(subject)
        setBody(body)
        setCompensationTerms(compensationTerms)
        setBiweeklySalary(biweeklySalary)
        setDhBaseRate(dhBaseRate)
        setTempBaseRate(tempBaseRate)
        setCommissionPaid(commissionPaid)
        setConditions(conditions)
      } else {
        setId('')
        initializeConstantValues()
      }
    }
  })
  const [saveTemplate] = useMutation(SAVE_TEMPLATE_MUTATION, { onCompleted: () => refetch() })

  const initializeConstantValues = () => {
    setSubject(recruiterUnderTraining ? recruiterDealTemplate2.subject : recruiterDealTemplate?.subject)
    setBody(recruiterUnderTraining ? recruiterDealTemplate2?.body : recruiterDealTemplate?.body)
    setCompensationTerms(recruiterUnderTraining ? recruiterDealTemplate2?.compensationTerms : recruiterDealTemplate?.compensationTerms)
    setBiweeklySalary(recruiterUnderTraining ? recruiterDealTemplate2?.biweeklySalary : recruiterDealTemplate?.biweeklySalary)
    setDhBaseRate(recruiterUnderTraining ? recruiterDealTemplate2?.dhBaseRate : recruiterDealTemplate?.dhBaseRate)
    setTempBaseRate(recruiterUnderTraining ? recruiterDealTemplate2?.tempBaseRate : recruiterDealTemplate?.tempBaseRate)
    setCommissionPaid(recruiterUnderTraining ? recruiterDealTemplate2?.commissionPaid : recruiterDealTemplate?.commissionPaid)
    setConditions(recruiterUnderTraining ? recruiterDealTemplate2?.conditions : recruiterDealTemplate?.conditions)
  }

  if (loading) {
    return <Loader />
  }

  return (
    <>
      <div className="admin-tabs team-tabs">
        <GoBack url={ADMIN_ROUTE} />

        <div className="primaryHeaderSpacing primaryHeading d-flex justify-content-between align-items-center">
          <h5 className="m-0">{RECRUITER_DEAL_TEMPLATES}</h5>
        </div>

        <div style={{ display: "flex", flexDirection: 'column', gap: 10 }}>
          <div className="d-flex">
            <Label className="mr-3">{RECRUITER_DEAL_TEMPLATES_HEADERS['RECOVERABLE_DRAW']}</Label>
            <label className="switch">
              <Input
                type="checkbox"
                name="mostRecentExperience"
                checked={recruiterUnderTraining}
                onChange={(e: any) => setRecruiterUnderTraining(e.target.checked)}
              />
              <span className="slider"></span>
            </label>
          </div>
          <Label>{RECRUITER_DEAL_TEMPLATES_HEADERS['SUBJECT']}</Label>
          <div className="quill-bg-white">
            <ReactQuill
              modules={recruiterDealTemplatesQuillModules}
              formats={recruiterDealTemplatesQuillFormats}
              value={subject!}
              onChange={(value) => {
                if (reactQuillReg.test(value)) {
                  setSubject('');
                } else {
                  setSubject(value);
                }
              }}
            />
          </div>


          <Label>{RECRUITER_DEAL_TEMPLATES_HEADERS['BODY']}</Label>
          <div className="quill-bg-white">
            <ReactQuill
              modules={recruiterDealTemplatesQuillModules}
              formats={recruiterDealTemplatesQuillFormats}
              value={body!}
              onChange={(value) => {
                if (reactQuillReg.test(value)) {
                  setBody('');
                } else {
                  setBody(value);
                }
              }}
            />
          </div>

          <Label>{RECRUITER_DEAL_TEMPLATES_HEADERS['COMPENSATION_TERMS']}</Label>
          <Input
            value={compensationTerms}
            placeholder={RECRUITER_DEAL_INPUTS_PLACEHOLDERS['COMPENSATION_TERMS']}
            onChange={({ target }) => {
              setCompensationTerms(target?.value);
            }}
            styles={{ ...customSelectStyleNew(theme) }}
          />



          <Label>{RECRUITER_DEAL_TEMPLATES_HEADERS['BIWEEKLY_SALARY']}</Label>
          <Input
            value={biweeklySalary}
            placeholder={RECRUITER_DEAL_INPUTS_PLACEHOLDERS['BIWEEKLY_SALARY']}
            onChange={({ target }) => {
              setBiweeklySalary(target?.value);
            }}
            styles={{ ...customSelectStyleNew(theme) }}
          />



          <Label>{RECRUITER_DEAL_TEMPLATES_HEADERS['DH_BASE_RATE']}</Label>
          <Input
            value={dhBaseRate}
            placeholder={RECRUITER_DEAL_INPUTS_PLACEHOLDERS['DH_BASE_RATE']}
            onChange={({ target }) => {
              setDhBaseRate(target?.value);
            }}
            styles={{ ...customSelectStyleNew(theme) }}
          />



          <Label>{RECRUITER_DEAL_TEMPLATES_HEADERS['TEMP_BASE_RATE']}</Label>
          <Input
            value={tempBaseRate}
            placeholder={RECRUITER_DEAL_INPUTS_PLACEHOLDERS['TEMP_BASE_RATE']}
            onChange={({ target }) => {
              setTempBaseRate(target?.value);
            }}
            styles={{ ...customSelectStyleNew(theme) }}
          />

          <Label>{RECRUITER_DEAL_TEMPLATES_HEADERS['CONDITIONS']}</Label>
          <div className="quill-bg-white">
            <ReactQuill
              modules={recruiterDealTemplatesQuillModules}
              formats={recruiterDealTemplatesQuillFormats}
              value={commissionPaid}
              onChange={(value) => {
                if (reactQuillReg.test(value)) {
                  setCommissionPaid('');
                } else {
                  setCommissionPaid(value);
                }
              }}
            />
          </div>

          <Label>{RECRUITER_DEAL_TEMPLATES_HEADERS['CONDITIONS']}</Label>
          <div className="quill-bg-white">
            <ReactQuill
              modules={recruiterDealTemplatesQuillModules}
              formats={recruiterDealTemplatesQuillFormats}
              value={conditions}
              onChange={(value) => {
                if (reactQuillReg.test(value)) {
                  setConditions('');
                } else {
                  setConditions(value);
                }
              }}
            />
          </div>

        </div>
      </div>
      <div className="d-flex justify-content-end mt-5 px-3">
        <Button className="buttonGenericStyle filledButton" disabled={loading} onClick={() => {
          if (subject && compensationTerms && commissionPaid && body &&
            commissionPaid && conditions && biweeklySalary && dhBaseRate && tempBaseRate) {
            saveTemplate({
              variables: {
                input: {
                  id, subject, body, commissionPaid, compensationTerms, biweeklySalary, dhBaseRate,
                  tempBaseRate, conditions, dealType: recruiterUnderTraining ? DealType.UNDER_TRAINING : DealType.TRAINED
                }
              }
            })
          } else {
            toast.error(ALL_FIELDS_REQUIRED_MSG)
          }
        }}>
          {SAVE}
          {loading && <Spinner size='sm' />}
        </Button>
      </div>
    </>
  )
}
export default AllRecruitersDealTemplates