import { useLazyQuery, useMutation } from '@apollo/client';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Col,
  Spinner,
} from 'reactstrap';
import CustomButton from '../../../components/layout/CustomButton';
import InputField from '../../../components/layout/InputField';
import { USERTYPE } from '../../admin/users/User-data-type';
import Avatar from '../../../assets/images/user-empty.svg';
import {
  CREATE_OWNER,
  DELETE_COMPANY_SHAREE,
  DELETE_COMPANY_SHAREE_OWNER,
  GET_OWNER_SHAREES,
  GET_SHAREE_USERS,
} from '../gql';
import { formatDateUS } from '../../../utils/commonFn';
import { toast } from 'react-toastify';
import DeleteIcon from '../../../assets/images/delete-field.svg';
import ConfirmPopUp from '../../../components/ConfirmPopUp';
import { AppContext } from '../../../context';
import '../../company/company.scss';
import NoDataFound from '../../../components/layout/NoDataFound';

const OwnerShipShare = ({
  sharingId,
  sharingType,
  isView = false,
  company,
  reload,
}: // setEditCompany,
  {
    sharingId: string;
    sharingType: string;
    isView?: boolean;
    company?: any;
    reload?: Function;
    // setEditCompany?: any;
  }) => {
  // const [fetchTeams] = useLazyQuery(FETCH_TEAMS);
  const [confirmToggle, setConfirmToggle] = useState(false);
  const [removedOwnerToggle, setRemovedOwnerToggle] = useState(false);
  const [shareeId, setShareeId] = useState('');
  const [modal, setModal] = useState(false);

  // const { data: userandTeams } = useQuery(FETCHING_USERS);
  const toggle = () => setModal(!modal);
  const [fetchShreeUsers, { data: users, error: shareeErr }] =
    useLazyQuery(GET_SHAREE_USERS);
  const [getOwnerAndSharees, { data, loading }] =
    useLazyQuery(GET_OWNER_SHAREES);
  const [usersList, setUsersList] = useState([{}]);
  const [_createOwner, { loading: makingOwner }] = useMutation(CREATE_OWNER);
  const [deleteSharee, { loading: deleting }] = useMutation(
    DELETE_COMPANY_SHAREE
  );
  const [deleteShareeOwner, { loading: deletingOwner }] = useMutation(
    DELETE_COMPANY_SHAREE_OWNER
  );
  const [shareeInput, setShareeInput] = useState('');
  const [ownerToggle, setOwnerToggle] = useState(false);
  const { errors, handleSubmit, register } = useForm();
  const { user, userRoles } = useContext(AppContext);
  const ownershipDurationDays = [
    { label: '30 Days', value: 30 },
    { label: '60 Days', value: 60 },
    { label: '90 Days', value: 90 },
  ];

  useEffect(() => {
    if (users?.getUserForSharee?.length) {
      const _filteredArray = users?.getUserForSharee
        ?.filter(
          (item: { id: string }) =>
            !Object.keys(
              data?.getShareesAndOwner.find(
                (shareersOwner: { owner: { id: string } }) =>
                  item?.id === shareersOwner?.owner?.id
              ) || {}
            )?.length && item
        )
        .map((user: USERTYPE) => ({
          label: user.fullName,
          value: user.id,
        }));

      setUsersList(_filteredArray);
    }

    // eslint-disable-next-line
  }, [users]);

  const settingDropDownWithTeam = () => {
    return usersList;
  };

  useEffect(() => {
    if (sharingId) {
      fetchShreeUsers({ variables: { companyId: sharingId } });
      getOwnerAndSharees({ variables: { id: sharingId } });
    }

    // eslint-disable-next-line
  }, [sharingId]);

  const createOwner = async (values: any) => {
    values.sharingId = sharingId;
    values.sharingType = sharingType;
    values.ownerType = 'OWNER';
    values.ownershipDuration = Number(values.ownershipDuration);
    let res = await _createOwner({
      variables: { CreateShareeInput: { ...values } },
    });
    if (res?.data) {
      toast.success('Owner successfully created!');
      getOwnerAndSharees({ variables: { id: sharingId } });
      fetchShreeUsers({ variables: { companyId: sharingId } });
      toggle();
      setModal(!modal);
      reload && (await reload());
    }
  };

  const createSharee = async () => {
    if (makingOwner) return;

    let values = {
      sharingId: sharingId,
      sharingType: sharingType,
      ownerType: 'SHAREE',
      ownerId: shareeInput,
    };

    let res = await _createOwner({
      variables: { CreateShareeInput: { ...values } },
    });

    if (res?.data) {
      setShareeInput('');
      toast.success('Sharee successfully added!');
      getOwnerAndSharees({ variables: { id: sharingId } });
      fetchShreeUsers({ variables: { companyId: sharingId } });
    }
  };

  const removeSharee = async () => {
    let res = await deleteSharee({ variables: { id: shareeId } });
    if (res?.data) {
      toast.success('Sharee removed successfully!');
      getOwnerAndSharees({ variables: { id: sharingId } });
      fetchShreeUsers({ variables: { companyId: sharingId } });
      setShareeInput('');
      setConfirmToggle(false);
    }
  };
  const removedShareeOwner = async () => {
    let res = await deleteShareeOwner({ variables: { companyId: sharingId } });
    if (res?.data) {
      toast.success('Owner removed successfully!');
      getOwnerAndSharees({ variables: { id: sharingId } });
      fetchShreeUsers({ variables: { companyId: sharingId } });
      setRemovedOwnerToggle(false);
      setOwnerToggle(false);
      toggle();
      reload && (await reload());
    }
  };

  useEffect(() => {
    if (data?.getShareesAndOwner && data?.getShareesAndOwner.length) {
      let owner = data?.getShareesAndOwner?.find(
        (item: { ownerType: string }) => item.ownerType === 'OWNER'
      );

      if (owner) {
        setOwnerToggle(true);
      }
    }
    // eslint-disable-next-line
  }, [data?.getShareesAndOwner]);

  return (
    <div>
      <Card className="border-0">
        <CardBody className="p-0">
          <div className="user-basic-form">
            <div className="d-flex justify-content-end jobOrderRevamp m-0">
              {!isView &&
                userRoles &&
                (userRoles?.includes('ADMIN') ||
                  userRoles?.includes('EXECUTIVE')) && (
                  <button
                    type="button"
                    className="buttonGenericStyle filledButton m-0"
                    onClick={() => {
                      toggle();
                    }}
                  >
                    {ownerToggle ? 'Change Owner' : 'Add New Owner'}
                  </button>
                )}
            </div>

            <div className="sharee-card-main">
              {/* for showing existing owner and sharee */}
              {!loading && !data?.getShareesAndOwner.length && (
                <div className="for-position-multiselect">
                  <NoDataFound text="No Owner and Sharee Found!" />
                </div>
              )}
              {data?.getShareesAndOwner && data?.getShareesAndOwner.length ? (
                <div className="share-owner-cards justify-content-start flex-wrap">
                  {data?.getShareesAndOwner?.map((item: any, index: number) => (
                    <Card className="sharee-card mx-2" key={index}>
                      <CardBody>
                        <div className="d-flex gap-10 align-items-center" >
                          <img
                            src={
                              item?.owner?.photo ? item?.owner?.photo : Avatar
                            }
                            alt=""
                            width="50"
                            height="50"
                            className="rounded-circle"
                            style={{ objectFit: 'cover' }}
                          />
                          <div className="mx-2">
                            <p className="owner-sharee">
                              <span style={{ fontWeight: 'bold' }}>
                                {item?.ownerType === 'OWNER'
                                  ? 'Owner'
                                  : 'Sharee'}
                                :
                              </span>{' '}
                              <span>
                                {item?.owner?.fullName}
                              </span>
                            </p>
                            <div>
                              {userRoles?.includes('ADMIN') ||
                                userRoles?.includes('EXECUTIVE') ||
                                (item?.owner?.id === user?.id) ? (
                                item?.ownerType === 'OWNER' &&
                                <p>
                                  <span style={{ fontWeight: 'bold' }}>
                                    Expiration:
                                  </span>{' '}
                                  {item?.endedAt && formatDateUS(item?.endedAt)}
                                </p>
                              ) : (
                                <p>
                                  <span style={{ fontWeight: 'bold' }}>
                                    Created:
                                  </span>{' '}
                                  {item?.startedAt &&
                                    formatDateUS(item?.startedAt)}
                                </p>
                              )}
                            </div>
                          </div>

                          {item?.ownerType !== 'OWNER' && !isView && (
                            <div>
                              {userRoles?.includes('ADMIN') ||
                                userRoles?.includes('EXECUTIVE') ||
                                user?.id === company?.owner?.id ? (
                                <Button
                                  className="btn-delete"
                                  onClick={() => {
                                    setShareeId(item?.id);
                                    setConfirmToggle(!confirmToggle);
                                  }}
                                >
                                  <img src={DeleteIcon} alt="" />
                                </Button>
                              ) : null}
                            </div>
                          )}
                        </div>
                      </CardBody>
                    </Card>
                  ))}
                </div>
              ) : (
                ''
              )}

              {ownerToggle && !isView && (
                <div className="add-another">
                  {(company?.owner?.id === user?.id ||
                    (userRoles && userRoles?.includes('ADMIN')) ||
                    userRoles?.includes('EXECUTIVE')) && (
                      <Row>
                        <Col md="4" sm="12" xs="12">
                          <FormGroup className="form-group-error">
                            <h5 className="mb-2">Add Another Sharee</h5>
                            <InputField
                              label=""
                              inputtype="select"
                              selectItems={usersList}
                              inputid="ownerId"
                              placeholder="Sharees"
                              inputMethod={(
                                value: React.SetStateAction<string>
                              ) => {
                                setShareeInput(value);
                              }}
                              inputValue={shareeInput}
                            />
                            <small className="text-danger">
                              {shareeErr?.graphQLErrors[0]?.message}
                            </small>
                          </FormGroup>
                          <Button
                            size="sm"
                            onClick={createSharee}
                            disabled={shareeInput ? false : true}
                          >
                            {makingOwner && <Spinner size="sm" className="mr-2" />}
                            Add sharee
                          </Button>
                        </Col>
                      </Row>
                    )}
                </div>
              )}
            </div>
          </div>
        </CardBody>
      </Card>

      <Modal
        isOpen={modal}
        toggle={toggle}
        className="revampDialog revampDialogWidth"
      >
        <ModalHeader toggle={toggle}>{!ownerToggle ? "Add Company Owner" : "Change Company Owner"}</ModalHeader>

        <Form onSubmit={handleSubmit(createOwner)}>
          <ModalBody>
            <div className="ownership-content">
              {!ownerToggle ? (
                <p>
                  Select a user from the list below to make the owner of this
                  Company.
                </p>
              ) : (
                <>
                  <p>
                    Are you sure you want to change the owner of &nbsp;
                    <b>{company?.name}</b>&nbsp; company? The current owner of
                    the company is&nbsp;{' '}
                    <b>
                      {data?.getShareesAndOwner &&
                        data?.getShareesAndOwner.length
                        ? data?.getShareesAndOwner?.map(
                          (item: any, index) =>
                            item?.ownerType === 'OWNER' &&
                            !isView && (
                              <span
                                style={{ fontWeight: 'bold' }}
                                key={index}
                              >
                                {item?.ownerType === 'OWNER'
                                  ? item?.owner?.fullName
                                  : ''}
                              </span>
                            )
                        )
                        : '-'}
                    </b>
                    .
                  </p>
                  <p>This action will do the following things:</p>

                  <ul>
                    <li>Currently Ownership expiration </li>
                    <li>
                      {' '}
                      Terminate current sharing if any at company/job level{' '}
                    </li>
                    <li>
                      New owner will have full access to any of the
                      job/contact/etc..
                    </li>
                  </ul>
                </>
              )}
            </div>

            <InputField
              label="New Owner"
              inputtype="select"
              selectItems={settingDropDownWithTeam()}
              inputid="ownerId"
              placeholder="Owner"
              inputRef={register({
                required: { value: true, message: 'Must select a owner!' },
              })}
              errMsg={errors?.ownerId?.message}
            />
            {userRoles?.includes('ADMIN') && (
              <InputField
                label="Duration for ownership"
                inputtype="select"
                selectItems={ownershipDurationDays}
                inputid="ownershipDuration"
                placeholder="Duration"
                inputRef={register({
                  required: { value: false, message: 'Must select a owner!' },
                })}
              />
            )}
          </ModalBody>
          <ModalFooter className="justify-content-between">
            {ownerToggle && (
              <div>
                <CustomButton
                  buttonText="Remove Owner"
                  buttonColor="danger"
                  buttonType="button"
                  buttonClick={() => {
                    setRemovedOwnerToggle(!removedOwnerToggle);
                  }}
                />
              </div>
            )}
            <div className="d-flex align-items-center justify-content-end w-100">
              <button
                type="button"
                onClick={toggle}
                className="buttonPrimary mr-3"
              >
                Cancel
              </button>

              <CustomButton
                buttonText={ownerToggle ? 'Update Owner' : 'Make Owner'}
                buttonColor="primary"
                buttonType="submit"
                className="big-width"
                loading={makingOwner}
                disabled={makingOwner}
              />
            </div>
          </ModalFooter>
        </Form>
      </Modal>

      <ConfirmPopUp
        confirmText="Are you sure you want to remove sharee?"
        isOpen={confirmToggle}
        toggle={setConfirmToggle}
        confirmAction={removeSharee}
        modalHeading="Are You Sure?"
        btnText="Yes, Delete Sharee"
        btnColor="primary"
        className="remove-sharee"
        loading={deleting}
        disabled={deleting}
      />

      <ConfirmPopUp
        confirmText="Are you sure you want to remove owner?"
        isOpen={removedOwnerToggle}
        toggle={setRemovedOwnerToggle}
        confirmAction={removedShareeOwner}
        modalHeading="Remove Owner"
        btnText="Confirm"
        btnColor="primary"
        loading={deletingOwner}
        disabled={deletingOwner}
      />
    </div>
  );
};

export default OwnerShipShare;
