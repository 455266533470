import { gql } from "@apollo/client";

export const GET_ALL_EMAIL_TEMPLATES = gql`
query {
  allEmailTemplates {
    emailTemplates {
      id
      module
      updatedAt
      template
      subject
    }
  }
}`

export const UPDATE_EMAIL_TEMPLATE = gql`
mutation($input:UpdateEmailTemplateInput!){
  updateEmailTemplate(input:$input){
    status
    message
  }
}`

export const ADD_EMAIL_TEMPLATE = gql`
mutation($input:CreateEmailTemplateInput!){
  addEmailTemplate(input:$input){
    status
    message
  }
}`