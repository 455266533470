import React, { FC } from "react";
import { Link } from "react-router-dom";

import "./style.scss";

type SECTIONHEADER = {
  title: string;
  titleSpan: string;
  subTitle: string;
  onClick?: () => void;
};

const SectionHeader: FC<SECTIONHEADER> = ({
  title,
  subTitle,
  titleSpan,
  onClick,
}) => {
  return (
    <div className="section-header">
      <h5>
        {title} <span>{titleSpan}</span>
      </h5>

      <Link to="#" onClick={onClick}>
        {subTitle}
      </Link>
    </div>
  );
};

export default SectionHeader;
