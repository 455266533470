import { useLazyQuery } from "@apollo/client";
import moment from "moment";
import { useEffect, useState } from "react";
import { Col, Input, Label, Row } from "reactstrap";
import InputField from "../../../../components/layout/InputField";
import { commaRemover } from "../../../../utils/helper";
import { GET_COMPANY_AGREEMENTS_ON_PLACEMENT } from "../gql";
import ACAInfoCalculations from "./ACAInfoCalculations";
import BasicDetailsStepOne from "./BasicDetailsStepOne";
import ContractPlacementTypeBase from "./ContractPlacementTypeBase";
import DirectHirePlacementTypeBase from "./DirectHirePlacementTypeBase";
import RateInfoCalculations from "./RateInfoCalculation";
import RecruiterInfoProfitCalculations from "./RecruiterInfoProfitCalculations";

export default function CreatePlacementStepOne({
  companyId,
  companyName,
  startDateString,
  watch,
  localSalary,
  salaryType,
  setSalaryType,
  control,
  register,
  errors,
  setPlacementType,
  placementType,
  assignedDuration,
  setAssignedDuration,
  readonly,
  setError,
  setValue,
  estimatedLessPayrollBurdenPercenatge
}) {
  const [endingDate, setEndingDate] = useState<Date>();
  const[estimateValue,setEstimateValue]=useState<number>(0)

  const [
    _getCompanyAgreements,
    { loading: _getCompanyAgreementsLoading, data: _getCompanyAgreementsData },
  ] = useLazyQuery(GET_COMPANY_AGREEMENTS_ON_PLACEMENT);

  useEffect(() => {
    if (companyId) {
      _getCompanyAgreements({ variables: { companyId } });
    }

    // eslint-disable-next-line
  }, [companyId]);

  useEffect(()=>{
    setEstimateValue(estimatedLessPayrollBurdenPercenatge? (estimatedLessPayrollBurdenPercenatge/100) * commaRemover(watch('payRate')):0.1366 * commaRemover(watch('payRate')))
  },[estimatedLessPayrollBurdenPercenatge, watch])
  

  return (
    <div>
      <Row>
        <BasicDetailsStepOne
          readonly={readonly}
          setPlacementType={setPlacementType}
          control={control}
          register={register}
          errors={errors}
        />
      </Row>
      <Row>
        {placementType && placementType?.value === "DIRECT_HIRE" ? (
          <>
            <DirectHirePlacementTypeBase
              readonly={readonly}
              watch={watch}
              _getCompanyAgreementsLoading={_getCompanyAgreementsLoading}
              localSalary={localSalary}
              agreements={
                _getCompanyAgreementsData?.getCompanyAgreementsPlacement
              }
              setSalaryType={setSalaryType}
              control={control}
              salaryType={salaryType}
              register={register}
              errors={errors}
              setValue={setValue}
            />
          </>
        ) : placementType?.value === "CONTRACT" ? (
          <ContractPlacementTypeBase
            readonly={readonly}
            setValue={setValue}
            setError={setError}
            setEndingDate={setEndingDate}
            startDateString={startDateString}
            setAssignedDuration={setAssignedDuration}
            companyId={companyId}
            companyName={companyName}
            watch={watch}
            control={control}
            register={register}
            errors={errors}
          />
        ) : null}
      </Row>

      <Row>
        {placementType && placementType?.value === "CONTRACT" && (
          <Col md="4">
            <Label>Approx End Date </Label>

            <Input
              disabled={true}
              value={
                endingDate === undefined
                  ? moment(startDateString)
                      .add(assignedDuration * 7, "days")
                      .format("YYYY-MM-DD")
                  : moment(endingDate).format("YYYY-MM-DD")
              }
              readOnly={readonly}
              type="date"
              name="endDate"
              innerRef={register({
                required: {
                  value: false,
                  message: "End date is required!",
                },
              })}
            />
            <small className="text-danger">{errors?.endDate?.message}</small>
          </Col>
        )}
      </Row>

      <div className="noteSection">
        <h5>NOTES SECTION</h5>
      </div>

      <Row className="mt-3">
        <Col
          lg="6"
          md="6"
          sm="12"
          className={
            placementType && placementType?.value === "DIRECT_HIRE"
              ? "mt-3"
              : ""
          }
        >
          <InputField
            readOnly={readonly}
            label="Placement Notes"
            inputtype="textarea"
            placeholder="Placement Notes"
            inputid="placementNote"
            inputRef={register()}
          />
        </Col>

        <Col
          lg="6"
          md="6"
          sm="12"
          className={
            placementType && placementType?.value === "DIRECT_HIRE"
              ? "mt-3"
              : ""
          }
        >
          <InputField
            readOnly={readonly}
            label="Notes to Accounting"
            inputtype="textarea"
            placeholder="Notes to Accounting"
            inputid="noteToAccounting"
            inputRef={register()}
          />
        </Col>
      </Row>
      {placementType}

      {placementType && placementType?.value === 'CONTRACT' && (
        <>
          <RateInfoCalculations watch={watch} estimatedLessPayrollBurdenPercenatge={estimatedLessPayrollBurdenPercenatge} />
          <ACAInfoCalculations
            watch={watch}
            assignedDuration={assignedDuration}
          />
          <RecruiterInfoProfitCalculations
            assignedDuration={assignedDuration}
            recruiterPerHourRate={
              commaRemover(watch('billRate')) -
              commaRemover(watch('payRate')) -
              estimateValue-
              0.05 * commaRemover(watch('billRate'))-
              0.15 
            }
          />
        </>
      )}
    </div>
  );
}
