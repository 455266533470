import { useLazyQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { compactObject } from '../../../../utils/commonFn';
import EditPlacementStep from '../components/EditPlacementStep';
import { prefillPlacementData } from '../components/utils';
import {
  GET_SUBMISSION_BY_JOBAPPLICANT,
  PLACEMENT_CONTACT,
  PLACEMENT_SPLITTING,
} from '../gql';
import '../placement.scss';

export interface IAccountManagerSelect {
  label: string;
  value: string;
}
export interface IAccountManagerPercentage {
  accountManager: IAccountManagerSelect;
  percentage: number;
}

export interface IRecruiterSelect {
  label: string;
  value: string;
}
export interface IRecruiterPercentage {
  recruiter: IRecruiterSelect;
  percentage: number;
}

interface IPlacementType {
  label?: string;
  value?: string;
}

const EditPlacement = ({
  location,
  control,
  register,
  errors,
  reset,
  watch,
  setError,
  setValue,
  viewData,
  readonly,
  disableAllFields,
  setEditModal
}: any) => {
  const jobId = new URLSearchParams(location?.search).get('jobId');
  const candidateId = new URLSearchParams(location?.search).get('candidate');
  const jobApplicantId = new URLSearchParams(location?.search).get(
    'jobApplicantId'
  );
  const [endingDate, setEndingDate] = useState<Date>();

  const [startDateString, setStartDateString] = useState('');
  const [localSalary, setLocalSalary] = useState<number>(0);
  const [assignedDuration, setAssignedDuration] = useState<number>(0);
  const [placementType, setPlacementType] = useState<IPlacementType>();

  const [_placementContact, { data: _placementContactData }] =
    useLazyQuery(PLACEMENT_CONTACT);

  const [companyId, setCompanyId] = useState<string>();
  const [salaryType, setSalaryType] = useState<string>();

  const [_getSubmission] = useLazyQuery(
    GET_SUBMISSION_BY_JOBAPPLICANT
  );

  const [_placementSplit] =
    useLazyQuery(PLACEMENT_SPLITTING);

  useEffect(() => {
    document.title = `Edit Placement - RealREPP`;

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (viewData?.id) {
      _placementSplit({ variables: { placementId: viewData?.id } });

      // Getting contacts in case its contract
      if (viewData?.placementType === 'CONTRACT') {
        _placementContact({ variables: { placementId: viewData?.id } });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewData?.id]);

  useEffect(() => {
    // Because we have a time card approver...
    const timeApproverContact = _placementContactData?.placementContact;

    if (timeApproverContact) {
      if (timeApproverContact?.contactType === 'TIME_CARD_APPROVER') {
        setValue('timeCardApprover', {
          label: timeApproverContact?.companyContact?.fullName,
          value: timeApproverContact?.companyContact?.id,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_placementContactData]);

  useEffect(() => {
    if (viewData) {
      console.log("viewData :: ", viewData)
      const data = compactObject(viewData);
      let prefill = prefillPlacementData(data);

      setCompanyId(viewData?.jobOrderOfPlacement?.company?.id);
      setAssignedDuration(viewData?.assignmentDuration);
      setLocalSalary(viewData?.salaryAmount);
      setSalaryType(viewData?.salaryType);
      setStartDateString(viewData?.startDateString);
      setPlacementType({
        label: prefill?.placementType?.label,
        value: prefill.placementType?.value,
      });

      const compactPrefilledValues = compactObject(prefill);

      reset({ ...compactPrefilledValues });
    }

    // eslint-disable-next-line
  }, [viewData]);

  useEffect(() => {
    if (jobApplicantId) {
      _getSubmission({ variables: { jobApplicantId } });
    }

    // eslint-disable-next-line
  }, [jobId, candidateId, jobApplicantId]);

  useEffect(() => {
    if (viewData?.id) {
      _placementSplit({ variables: { placementId: viewData?.id } });

      // Getting contacts in case its contract
      if (viewData?.placementType === 'CONTRACT') {
        _placementContact({ variables: { placementId: viewData?.id } });
      }
    }

    // eslint-disable-next-line
  }, [viewData?.id]);

  return (
    <div className="admin-tabs team-tabs">
      <EditPlacementStep
        disableAllFields={disableAllFields}
        setValue={setValue}
        readonly={readonly}
        setError={setError}
        watch={watch}
        companyId={companyId}
        control={control}
        errors={errors}
        salaryType={salaryType}
        setSalaryType={setSalaryType}
        register={register}
        companyName={viewData?.companyName}
        setPlacementType={setPlacementType}
        placementType={placementType}
        localSalary={localSalary}
        setEndingDate={setEndingDate}
        startDateString={startDateString}
        setAssignedDuration={setAssignedDuration}
        endingDate={endingDate}
        assignedDuration={assignedDuration}
        placementId={viewData?.placementId}
        approval={viewData?.approved}
        statusNote={viewData?.statusNote}
        rejectOrApproveBy={viewData?.rejectOrApproveBy}
        placementEndDate={viewData?.placementEndDate}
        estimatedLessPayrollBurdenPercenatge={viewData?.estimatedLessPayrollBurdenPercenatge}
        setEditModal={setEditModal}
        supervisorPositionCheck={viewData?.supervisorPosition}
      />
    </div>
  );
};

export default EditPlacement;
