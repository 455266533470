// packages block
import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { withRouter } from "react-router-dom";
import { Form, Input, Modal, ModalBody, ModalFooter, Spinner } from "reactstrap";
// components block
import SocialMediaInfo from "../../../components/SocialMediaInfo";
import BasicContactInfo from "../../contacts/contact-components/BasicCotactInfo";
import ContactEmail from "../../contacts/contact-components/ContactEmail";
import ContactPhone from "../../contacts/contact-components/ContactPhone";
// context and graphql block
import { AppContext } from "../../../context";
import { CREATE_COMPANY_CONTACT } from "../../contacts/gql";

const CreateContactModal = ({
  open,
  toggle,
  preSelectContact,
  setEditModal,
  ...props
}: any) => {
  const [isPrivate, setIsPrivate] = useState<boolean>(false);
  const { userRoles } = useContext(AppContext);
  const {
    errors,
    handleSubmit,
    register,
    reset,
    control,
    setValue,
    getValues,
  } = useForm();
  const [createCompanyContact, { loading: waiting }] = useMutation(
    CREATE_COMPANY_CONTACT
  );
  const [emailErr, setEmailErr] = useState("");
  const [customEmailInputs, setCustomEmailInputs] = useState<any>([
    {
      email: "",
      isPrimary: true,
    },
  ]);
  const [customPhoneInputs, setCustomPhoneInputs] = useState<any>([
    {
      phone: "",
      phoneType: "",
      isPrimary: true,
    },
  ]);

  const createContact = async (values: any) => {
    try {
      let emails = customEmailInputs.filter((item: any) => item.email && item);
      if (!emails?.length) {
        setEmailErr("Minimum one email is required");
        return;
      }
      values.contactEmails = emails;
      let phones = customPhoneInputs.filter((item: any) => item.phone && item);
      values.contactPhones = phones;
      values.isPrivate = isPrivate;
      values.companyId = values?.companyId?.value;
      values.locationId = values?.locationId?.value;
      let res = await createCompanyContact({
        variables: { createContactInput: { ...values } },
      });
      if (res?.data) {
        // console.log(preSelectContact)
        preSelectContact(res?.data?.createContact)
        // console.log(preSelectContact())
        toast.success("Contact successfully created!");
        setEditModal && setEditModal(false)
        props.fetchCompany();
        setCustomEmailInputs([{ email: "", isPrimary: true, }])
        setCustomPhoneInputs([{ phone: "", phoneType: "", isPrimary: true, }])
        reset();
        toggle();
        setEditModal && setTimeout(setEditModal(true), 5000);
      }
    } catch (error) {
      console.log(error)
      toast.error("Contact not created");
    }
  };

  useEffect(() => {
    if (props?.companyId && props?.companyName) {
      const companyId = {
        label: props?.companyName,
        value: props?.companyId,
      };
      reset({ companyId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.companyId || props?.companyName]);

  return (
    <Modal
      isOpen={open}
      toggle={() => {
        toggle()
        setCustomEmailInputs([{ email: "", isPrimary: true, }])
        setCustomPhoneInputs([{ phone: "", phoneType: "", isPrimary: true, }])
      }}
      size="xl"
      className="revampDialog"
    >
      <Form>
        <div className="modalHeaderCustom mobileResponsiveFlex">
          <h5>Add Contacts</h5>

          {userRoles?.includes("EXECUTIVE") ? (
            <div className="d-flex align-items-center make-private">
              <span className="mr-3 switchLabel">Make it private?</span>
              <label className="switch-contact">
                <Input
                  type="checkbox"
                  name="isPrivate"
                  checked={isPrivate}
                  onChange={() => setIsPrivate(!isPrivate)}
                />
                <span className="slider"></span>
              </label>
            </div>
          ) : null}
        </div>

        <ModalBody>
          <BasicContactInfo
            register={register}
            control={control}
            errors={errors}
            makeCompanyReadOnly={true}
            companyId={props?.companyId}
            companyName={props?.companyName}
            contactModal={true}
            getValues={getValues}
            setValue={setValue}
          />

          <ContactEmail
            setCustomEmailInputs={setCustomEmailInputs}
            customEmailInputs={customEmailInputs}
            emailErr={emailErr}
            setEmailErr={setEmailErr}
          />

          <div className="mb-4">
            <ContactPhone
              setCustomPhoneInputs={setCustomPhoneInputs}
              customPhoneInputs={customPhoneInputs}
              error=""
              setErr={() => { }}
            />
          </div>

          <SocialMediaInfo
            setValue={setValue}
            register={register}
            errors={errors}
            isLinkedinRequired={false}
            isDisable={false}
          />
        </ModalBody>

        <ModalFooter>
          <button onClick={() => {
            toggle()
            setCustomEmailInputs([{ email: "", isPrimary: true, }])
            setCustomPhoneInputs([{ phone: "", phoneType: "", isPrimary: true, }])
          }} className="buttonPrimary mr-3" type="button">
            Cancel
          </button>

          <button
            type="submit"
            className="btn btn-primary"
            onClick={handleSubmit(createContact)}
            disabled={waiting}
          >
            {waiting && <Spinner size="sm" className="mr-2" />}
            Create Contact
          </button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default withRouter(CreateContactModal);
