import { useState, useEffect } from "react";
import { downloadSignedUrlFile } from "../../../utils/commonFn";
import DownloadImg from '../../../assets/images/download.svg';

const DownloadAndLinkView = ({ attachmentURL, generateSignedUrl, attachmentName }) => {

  const [loading, setLoading] = useState<boolean>();
  const [signedURL, setSignedURL] = useState<string>();
  useEffect(() => {
    const signingUrl = async () => {
      setSignedURL(await generateSignedUrl(attachmentURL))
    }
    signingUrl()
  }, [attachmentURL])
  const onAttachmentDownload = async (url: string, name: string) => {
    setLoading(true);
    await downloadSignedUrlFile(url, name);
    setLoading(false);
  };

  return (
    <div className="d-flex justify-content-between align-items-center mb-0">
      <a
        href={signedURL}
        target="blank"
        className="text-info mr-2"
        title={attachmentName || ''}
      >
        {attachmentName}
      </a>

      <button
        type="button"
        className="btn-outline_prime"
        disabled={
          !(attachmentURL && attachmentName) ||
          loading
        }
        onClick={() => {
          onAttachmentDownload(attachmentURL, attachmentName);
        }}
      >
        {loading && (
          <img
            src={DownloadImg}
            alt="download"
            className={`mr-2 mb-1 ${loading && 'blink_me'
              }`}
          />
        )}
        {!loading ? 'Download' : 'Downloading'}
      </button>
    </div>
  )
}

export default DownloadAndLinkView