import { useContext, useState } from 'react';
import Select from 'react-select';
import { Col, Form, Label, Row } from 'reactstrap';

import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { customSelectStyleNew } from '../../components/styled/customSelect';
import {
  CANCEL,
  MODULE_ACCESS,
  SAVE,
} from '../../constants';
import { AppContext } from '../../context';
import { CHATGPTMODULE_OPTIONS, gptTemplateTagsArray } from '../../utils/constant';
import { SAVE_CHATGPT_TEMPLATES } from './gql';
import CustomButton from '../../components/layout/CustomButton';

const AddModal = ({
  onClose,
  register,
  Controller,
  control,
  handleSubmit,
  refetchGPTTemplates,
  toggle
}) => {
  const { theme } = useContext(AppContext);
  const [saveChatgptTemplates, { loading: saveChatgptTemplatesLoading }] = useMutation(SAVE_CHATGPT_TEMPLATES);
  const [copied, setCopy] = useState<boolean>(false);
  const onCopyText = (item: string, index: number) => {
    navigator.clipboard.writeText(item);
    setCopy(true);

    setTimeout(() => {
      setCopy(false);
    }, 1500);
  };
  const submitData = async (data) => {
    const settingsdata = {
      module: data?.module?.value,
      template: data?.template,
    }
    const settingsResp = await saveChatgptTemplates({
      variables: { settingDetails: settingsdata }
    })

    if (settingsResp?.data?.SaveChatgptTemplates?.status === 200) {
      toast.success(`ChatGPT Templates Save Successfully`);
      toggle();
      refetchGPTTemplates();
    }
  }
  return (
    <div className="p-4">
      <h4 className="mb-4">{MODULE_ACCESS}</h4>

      <Form>
        <Row className="mb-3">
          <Col md="12">
            <Label>Module</Label>
            <Controller
              control={control}
              name="module"
              options={CHATGPTMODULE_OPTIONS?.map((item) => {
                return {
                  label: item.label,
                  value: item.value,
                };
              })}
              render={({ onChange, value }) => (
                <Select
                  value={value}
                  options={CHATGPTMODULE_OPTIONS}
                  isSearchable={false}
                  placeholder="Select Module For Template"
                  onChange={(target) => {
                    onChange(target);
                  }}
                  styles={{ ...customSelectStyleNew(theme) }}
                />
              )}
            />
          </Col>
        </Row>
        <div className="w-100 d-flex flex-wrap mt-2 position-relative">
          {copied && (
            <span className="badge badge-main badge-warning">Copied</span>
          )}
          {gptTemplateTagsArray?.map((item: string, index) => {
            return (
              <span
                className="steps-tags-template"
                onClick={() => onCopyText(item, index)}
                id={`items-${index}`}
                key={index}
              >
                {item.split(', ')}
              </span>
            );
          })}
        </div>
        <Row>
          <Col md="12">
            <Label>template</Label>
            <textarea
              name="template"
              placeholder="Please add template"
              rows={10}
              className="form-control"
              ref={register()}
            />
          </Col>
        </Row>
        <div className="d-flex align-items-center justify-content-end mt-5">
          <button
            type="button"
            className="buttonGenericStyle buttonPrimary"
            onClick={() => onClose()}
          >
            {CANCEL}
          </button>
          <div className="px-3" />
          <CustomButton
            buttonClick={handleSubmit(submitData)}
            className="buttonPrimary filledButton"
            loading={saveChatgptTemplatesLoading}
            disabled={saveChatgptTemplatesLoading}
            buttonText={SAVE}
          />
        </div>
      </Form>
    </div>
  );
};

export default AddModal;
