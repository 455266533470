import React, { useContext, useEffect, useState } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import NoDataFound from '../../components/layout/NoDataFound';
import { DASHBOARD_ROUTE, SETTINGS } from '../../utils/constant';
import { AppContext } from '../../context';
import { useLazyQuery } from '@apollo/client';
import { FETCH_TEAM_AND_MEMBERS, FETCH_USER_WIDGET } from './gql';
import StatsWidget from './StatsWidget';
import TeamWidget from './TeamWidget';
import UnreadNotifications from './UnreadNotifications';
import CompanyReport from '../Reports/CompanyReport';
import INFO_ICON_4 from '../../assets/images/phone-icon.svg';
import INFO_ICON_5 from '../../assets/images/email-icon.svg';
import AvatarImage from '../../assets/images/user-empty.svg';
import './style.scss';
import Loader from '../../components/layout/Loader';
import { Badge } from 'reactstrap';

const Main = (props: RouteComponentProps): JSX.Element => {
  const { user } = useContext(AppContext);
  const [userWidgetList, setUserWidgetList] = useState<
    Array<{ rank: number | null; subkey: string | null }>
  >([]);

  const [fetchTeamMember, { data: TeamMembers }] = useLazyQuery(
    FETCH_TEAM_AND_MEMBERS
  );
  const [fetchUserWidgets, { data: widgetsofUser, loading }] =
    useLazyQuery(FETCH_USER_WIDGET);

  useEffect(() => {
    document.title = `Dashboard - RealREPP`;
  }, []);

  useEffect(() => {
    if (
      userWidgetList.filter((item) =>
        item.subkey === 'my_team_recruiter' ? true : false
      )
    ) {
      fetchTeamMember();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userWidgetList]);

  useEffect(() => {
    if (widgetsofUser && widgetsofUser.getUserWidgets) {
      // const widgetKey = widgetsofUser.getUserWidgets.map(
      //   (item: any) => item.widget.subkey,
      // );
      let widgetRank = widgetsofUser.getUserWidgets.map((item: any) => ({
        rank: item?.rank,
        subkey: item?.widget?.subkey,
      }));
      widgetRank = widgetRank.sort(function (a: any, b: any) {
        return a.rank - b.rank;
      });
      setUserWidgetList(widgetRank);
    }
  }, [widgetsofUser]);

  useEffect(() => {
    fetchUserWidgets();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) return <Loader />;

  return (
    <>
      <div className="dashboard-main">
        <div className="d-flex flex-sm-row flex-column  align-items-sm-center justify-content-sm-between primaryHeading">
          <h5 className="m-0 mb-dm-0 mb-2">Welcome {user?.fullName}</h5>

          <Link to={`${DASHBOARD_ROUTE}/customise`} className="link-tag">
            <SETTINGS />
            Customize
          </Link>
        </div>

        <div className="d-flex align-items-center pb-1">
          <div>
            <img
              src={user?.photo || AvatarImage}
              height="50px"
              width="50px"
              alt="Avatar"
              className="dashboard-profile-img"
            />
          </div>
          <div>
            <h6 className="m-0 mr-2 pl-2 light-text">
              {user?.fullName || '--'}{' '}
              <Badge
                color={
                  user?.status === 'ACTIVE'
                    ? 'success'
                    : user?.status === 'DO_NOT_CONTACT'
                    ? 'danger'
                    : ''
                }
                className="text-capitalize"
              >
                {user?.status ?? '--'}
              </Badge>
            </h6>
          </div>
        </div>
        <div className="infoCardDivider p-2 mb-4 mt-2">
          <img
            src={INFO_ICON_4}
            alt="phone"
            width="17px"
            id="TooltipExample5"
          />
          <label>
            <div className="d-flex align-items-center">
              {user?.phone || '--'}
            </div>
          </label>
          <span className="pl-3" style={{ color: '#e5e5e5' }}>
            |
          </span>
          <span className="pl-3">
            <img
              src={INFO_ICON_5}
              alt="emails"
              width="19px"
              id="TooltipExample6"
            />
            <label>{user?.email || '--'}</label>
          </span>
        </div>

        {userWidgetList &&
          // eslint-disable-next-line
          userWidgetList.map((item: any, index: number) => {
            if (item.subkey === 'my_stats_recruiter') {
              return (
                <React.Fragment key={index}>
                  {' '}
                  <StatsWidget />
                </React.Fragment>
              );
            }
            if (item.subkey === 'my_team_recruiter') {
              return (
                <React.Fragment key={index}>
                  <div className="jobOrderRevamp">
                    <TeamWidget teamMembers={TeamMembers?.widgetTeamMembers} />
                  </div>
                </React.Fragment>
              );
            }
            if (item.subkey === 'dummy_executive_widget') {
              return (
                <div key={index}>
                  <div className="jobOrderRevamp">
                    <div className="section-header p-0">
                      <h5 className="mb-2">Dummy Executive widget</h5>
                    </div>
                  </div>
                </div>
              );
            }
            if (item.subkey === 'dummy_manager_widget') {
              return (
                <div key={index}>
                  <div className="jobOrderRevamp">
                    <div className="section-header p-0">
                      <h5 className="mb-2">Dummy Manager widget</h5>
                    </div>
                  </div>
                </div>
              );
            }
            if (item.subkey === 'leaderboard') {
              return (
                <React.Fragment key={index}>
                  <div>
                    <CompanyReport widget={true} />
                  </div>
                </React.Fragment>
              );
            }
            if (item.subkey === 'notification_widget') {
              return (
                <React.Fragment key={index}>
                  <div>
                    <UnreadNotifications {...props} />
                  </div>
                </React.Fragment>
              );
            }
          })}

        {!loading && !userWidgetList?.length && (
          <div className="for-position-multiselect">
            <NoDataFound text="No Active Widgets!" />
          </div>
        )}
      </div>
    </>
  );
};

export default Main;
