// packages block
import {
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useLazyQuery } from '@apollo/client';
import { Link, RouteComponentProps } from 'react-router-dom';
import {
  Badge,
  Button,
 
} from 'reactstrap';
// component block
import Loader from '../../components/layout/Loader';
import Pagination from '../../components/layout/Pagination';
import InputField from '../../components/layout/InputField';
import NoDataFound from '../../components/layout/NoDataFound';
import TableComponent from '../../components/layout/TableComponent';
// utils, constant and graphql block
import { FETCH_COMPANIES } from './gql';
import { AppContext } from '../../context';
import VIEW_ICON from '../../assets/images/view-icon.svg';
import { countryWiseHeaderLocation, getCompanyStatusColor, pushInHistory ,} from '../../utils/helper';
import EDIT_ICON from '../../assets/images/edit-icon-menu.svg';
import { getCompaniesParams } from '../../utils/getNoteParams';
import { compactObject, formatDateUS } from '../../utils/commonFn';
import { AGREEMENTS_ROUTE, COLUMN_INTERFACE_TYPES_For_COMPANY, COMPANIES_ROUTE, COMPANY_DETAILS_ROUTE, COMPANY_HEADER_HEADER, PAGE_LIMIT, UPDATE_COMPANY_ROUTE } from '../../utils/constant';
import './company.scss';
import { useForm } from 'react-hook-form';
import { PhoneType } from '../../interfaces';

let time = 0;

const AllCompanies = ({ location, history }: RouteComponentProps) => {
  const fieldNames = [
    'companySearch',
    'locationSearch',
    'stausSearch',
    'phoneSearch',
    'companyOwnerSearch',
  ];
  const defaultColumnFilters = {
    companySearch: '',
    locationSearch: '',
    companyOwnerSearch:'',
    stausSearch: '',
    phoneSearch:'',
    sorting:{
      columnName: '',
      columnValue: ''
    }
  };
  const page = new URLSearchParams(location.search).get('currentPage') || '1';
  const _currentPage: number = parseInt(page);
  const [currentPage, setCurrentPage] = useState(_currentPage);
  const [errorMsg, setErrorMsg] = useState({});
  const [columnFilters, setColumnFilters] =
  useState<COLUMN_INTERFACE_TYPES_For_COMPANY>(defaultColumnFilters);
  const [limit, setLimit] = useState(10);
  const [myCompaniesFlag, setMyCompaniesFlag] = useState<boolean>(false);
  const [isSetTimeOut, setIsSetTime] = useState<boolean>(true);
  const { register, setValue,control} = useForm();
  const { user, userRoles } = useContext(AppContext);
  const [fetchAllCompanies, { loading, error, data }] = useLazyQuery(FETCH_COMPANIES);

  const setQueryParams = (() => {
    let newColumn: COLUMN_INTERFACE_TYPES_For_COMPANY = Object.assign(
      {},
      columnFilters
    );
    const { sorting, ...rest } = newColumn;

    const criteria = compactObject({
      currentPage,
      myCompaniesFlag,
      limit,
      ...rest,
      ...sorting,
    });

    if (Object.keys(criteria).length) {
      history.push(`${COMPANIES_ROUTE}?${new URLSearchParams(criteria)}`);
    }
  }
  );

  const changeFiled = (fieldName, value) => {
    if (fieldName?.endsWith('Sort')) {
      const columnName = fieldName.replace('Sort', '');
      setIsSetTime(true)
      setColumnFilters((prevFilters) => {
        const { sorting, ...otherFilters } = prevFilters;
        return {
          ...otherFilters,
          sorting: {
            columnName: columnName,
            columnValue: value ? 'ASC' : 'DESC',
          },
        };
      });
      
    } else {
      setCurrentPage(1);
      setLimit(limit);
      setColumnFilters((prevFilters) => ({
        ...prevFilters,
        [fieldName]: value,
      }));
      setIsSetTime(false)
    }
  };

  const checkEditPermission = (company: any) => {
    if (company) {
      if (userRoles?.includes('ADMIN') || userRoles?.includes('EXECUTIVE')) {
        return true;
      }
      if (!company?.ownerAndSharees?.length) {
        return true;
      }
      if (company?.ownerAndSharees?.length) {
        let currentUser = company?.ownerAndSharees?.find(
          (item) => item.owner?.id === user?.id
        );
        if (currentUser) {
          return true;
        }
      }
      if (company?.status === 'DO_NOT_CONTACT') {
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    document.title = 'Companies - RealREPP';
    let params: any = getCompaniesParams(location.search);
    if(params){
      setCurrentPage(params?.currentPage ? parseInt(params?.currentPage) : 1);
      setLimit(params?.limit ? parseInt(params?.limit) : 10);
      setMyCompaniesFlag(params?.myCompaniesFlag === 'true')
   }
      if (Object.entries(params?.columnFilters)?.length) {
        if (Object.entries(params?.columnFilters)?.length === 1) {
          setColumnFilters((prevFilters) => ({
            ...prevFilters,
            [Object.keys(params?.columnFilters)[0]]: Object.values(
              params?.columnFilters
            )[0],
          }));

        } else {
          const updatedFilters = { ...columnFilters };
          Object.entries(params?.columnFilters).forEach(([key, value]) => {
            updatedFilters[key] = value;
          });
          setColumnFilters(updatedFilters);
        }
      }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  const getCompanies = useCallback(async () => {    
    
    if (isSetTimeOut) {
      setQueryParams();
      await fetchAllCompanies({
        variables: {
          limit,
          page: currentPage,
          myCompany: myCompaniesFlag,
          columnFilters: columnFilters,
        },
      });
    } else {
      setQueryParams();
      setLimit(limit);
      window.clearTimeout(time);
      time = window.setTimeout(async () => {
        await fetchAllCompanies({
          variables: {
            limit,
            page: currentPage,
            myCompany: myCompaniesFlag,
            columnFilters: columnFilters,
          },
        });
      }, 1000);
    }


  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columnFilters, currentPage, limit, myCompaniesFlag]);
  
  useEffect(() => {
    getCompanies()
  }, [getCompanies]);
  return (
        <div>
          <div className="primaryHeading">
            <h5>Companies</h5>
          </div>        
          <div className="search-filter primaryHeaderSpacing d-flex justify-content-end">
              <button
                className="buttonGenericStyle filledButton mr-3 mb-md-0 mb-2"
                type="button"
                onClick={() => {
                  history.push({
                    pathname: '/create-company',
                    state: { searchParameters: location.search },
                  });

                  pushInHistory(COMPANIES_ROUTE, location.search)
                }}
              >
                Add New Company
              </button>

              <div className="filter-by">
                <button
                  style={{
                    backgroundColor: `${myCompaniesFlag ? '#f4f3f3' : 'white'}`,
                    color: `${myCompaniesFlag ? '#eaa827' : '#495057'}`,
                    border: `${'1px solid #ced4da'}`
                  }}
                  className="filter-job-order ml-3"
                  onClick={() => {
                    setMyCompaniesFlag(!myCompaniesFlag);
                  }}
                  type="button"
                  disabled={loading}
                >
                  My Companies
                </button>

                <button
                  onClick={() => {
                    setMyCompaniesFlag(false);
                    setColumnFilters(defaultColumnFilters);
                    setIsSetTime(true)
                    fieldNames.forEach((fieldName) => {
                      setValue(fieldName, defaultColumnFilters[fieldName]);
                    });
                    if (columnFilters?.sorting.columnName) {
                      setValue(columnFilters?.sorting.columnName, false);
                    }
                    setErrorMsg({})
                    fetchAllCompanies({
                      variables: {
                        limit,
                        page: currentPage,
                        searchText: '',
                        statusSearch: '',
                        myCompany: false,
                        columnFilters:columnFilters

                      },
                    });
                  }}
                  className="buttonGenericStyle filledButton ml-2"
                  disabled={loading}
                >
                  Clear
                </button>
              </div>
            </div>

          <div className="table-responsive">
            <TableComponent
              advanceSearching={true}
              tableHeaderAdvSeacrh={COMPANY_HEADER_HEADER}
              handleChange={(filedName, value) => changeFiled(filedName, value)}
              columnFilters={columnFilters}
              register={register}
              setValue={setValue}
              control={control}
              tableName="Companies"
              setErrorMsg={setErrorMsg}
              errorMsg={errorMsg}
             >              
              {loading && (
                <tbody>
                  <tr>
                    <td colSpan={12}>
                      <Loader loaderClass="sm-height " />
                    </td>
                  </tr>
                </tbody>
              )}

              {!loading && !data?.allCompanies?.companies?.length && (
                <tbody>
                  <tr>
                    <td colSpan={12} className="empty-table-td">
                      <NoDataFound text="No Company Found!" />
                    </td>
                  </tr>
                </tbody>
              )}

              <tbody className='placement-table'>
                {!loading &&
                  data &&
                  data?.allCompanies &&
                  data?.allCompanies?.companies?.map((item: any, index: number) => {
                    return (
                      <tr key={index}>
                        <td
                          className="route-link text-truncate max-200"
                          onClick={() => {
                            history.push({
                              pathname: `${COMPANY_DETAILS_ROUTE}/${item?.id}`,
                              state: { searchParameters: location.search },
                            });

                            pushInHistory(COMPANIES_ROUTE, location.search)
                          }}
                        >
                          {item.name}
                        </td>
                        <td>
                          {item?.address
                            ? item?.address.map(
                              (address: {
                                isPrimary: boolean;
                                isDeleted: boolean;
                                city: string;
                                state: string;
                                country: string
                              }) =>
                                address.isPrimary &&
                                address.isDeleted === null &&

                                countryWiseHeaderLocation(address?.country,address?.city,address?.state)
                            )
                            : '--'}
                        </td>
                        <td> {item?.companyOwner?.owner?.fullName || '--'} </td>

                        <td className='badge-pading'>
                          <Badge
                            color={getCompanyStatusColor(item.status)}
                            className="text-capitalize"
                          >
                            {item.status}
                          </Badge>
                        </td>   
                        <td>
                          {
                            
                          }
                        {item?.phones?.length ? item?.phones?.map(
                          (phone: PhoneType) => {
                            return phone?.isPrimary && phone?.phoneNumber;
                          }
                        ): '--'}
                      </td>
                        <td>
                          {item.updatedAt ? formatDateUS(item?.updatedAt) : '--'}
                        </td>                     
                        <td>{item.size || '--'}</td>
                        
                        <td>
                          <Button
                            onClick={() => {
                              item?.companyAgreement &&
                                item?.companyAgreement?.length &&
                                history.push(
                                  `${AGREEMENTS_ROUTE}?currentPage=1&companyId=${item.id}&companyName=${item?.name}`
                                );

                              pushInHistory(COMPANIES_ROUTE, location.search)
                            }}
                            className="bg-transparent border-0 linkButton"
                            size="sm"
                            disabled={
                              item?.companyAgreement &&
                              !item?.companyAgreement?.length
                            }
                          >
                            View
                          </Button>
                        </td>
                        <td>
                          <Link
                            onClick={() => pushInHistory(COMPANIES_ROUTE, location.search)}
                            to={{
                              pathname: `${COMPANY_DETAILS_ROUTE}/${item?.id}`,
                              state: { searchParameters: location.search },
                            }}
                            className="m-2 mr-3"
                          >
                            <img src={VIEW_ICON} alt="view" width="21px" />
                          </Link>
                          {checkEditPermission(item) && (
                            <Link
                              onClick={() => pushInHistory(COMPANIES_ROUTE, location.search)}
                              to={{
                                pathname: `${UPDATE_COMPANY_ROUTE}/${item?.id}`,
                                state: { searchParameters: location.search },
                              }}
                            >
                              <img src={EDIT_ICON} alt="edit" />
                            </Link>
                          )}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </TableComponent>
          </div>

          {data?.allCompanies?.companies?.length ? (
            <div className="users-pagination">
              <Pagination
                onPageChange={(pageClicked: number) => {
                  setQueryParams();
                  setIsSetTime(true)
                  setCurrentPage(pageClicked);
                  history.push(`/companies?currentPage=${pageClicked}`);
                }}
                pageCount={Math.ceil(data?.allCompanies?.count / limit)}
                currentPage={currentPage}
              />

              <div className="d-flex align-items-center">
                <div className="pagination-display paginationItemsSelect">
                  <p className="m-0">
                    {(currentPage - 1) * limit + 1} to{' '}
                    {Math.min(currentPage * limit, data?.allCompanies?.count)} of{' '}
                    {data?.allCompanies?.count}
                  </p>

                  <div className="divider"></div>

                  <label>Display</label>

                  <InputField
                    label=""
                    inputtype="select"
                    selectItems={PAGE_LIMIT.filter((item) => item.value !== limit)}
                    inputid="limit"
                    placeholder={limit.toString()}
                    inputMethod={(value) => {
                      setIsSetTime(true)
                      setCurrentPage(1);
                      setLimit(parseInt(value));
                    }}
                  />
                </div>
              </div>
            </div>
          ) : null}
          {error?.graphQLErrors[0]?.message}
        </div>
  );
};

export default AllCompanies;
