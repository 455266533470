import { useLazyQuery, useMutation } from "@apollo/client";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Col, Row, Spinner } from "reactstrap";
import GoBack from "../../components/GoBack";
import { CANDIDATE_SEARCH_DEV, CHATGPT_TEMPLATES, CSV_IMPORT, EMAIL_TEMPLATES, MODULE_ACCESS, RECRUITER_DEAL_TEMPLATES, RESUME_PARSING, SYNONYM_LIST, USER_ACTIVITY_REPORT } from "../../constants";
import { AppContext } from "../../context";
import { ACCOUNTING_DASHBOARD_ROUTE, ADMIN_IMPORT_CSV, CHATGPT_TEMPLATE_ROUTE, EMAIL_TEMPLATES_ROUTE, MODULE_ROUTE, RECRUITER_DEAL_TEMPLATES_ROUTE, ROLES, SETTINGS_ROUTE, SYNONYM_ROUTE, USER_ACTIVITY_REPORT_ROUTE } from "../../utils/constant";
import ResumeParsing from "../resumeParsing";
import {
  GET_ADMIN_STATS,
  PLACEMENT_ADMIN_STATS,
  SYNC_INTERSELLER_USERS_TO_ATS,
  SYNC_INTERSELLER_WEBHOOKS,
} from "./gql";

const StatCard = ({
  loading,
  title,
  rightStatText,
  rightStatCount = 0,
  leftStatText,
  leftStatCount = 0,
  manageLink = "",
  buttonText,
  onClick
}: {
  loading?: boolean;
  title: string;
  rightStatText?: string;
  rightStatCount?: number | string;
  leftStatText?: string;
  leftStatCount?: number | string;
  manageLink?: string;
  buttonText?: string
  onClick?: () => void
}) => (
  <div className="users-card">
    <div className="d-flex">
      <h5>{title}</h5>
      {buttonText ? <h5
        className="anchorColorText route-link" onClick={onClick}>{buttonText}</h5> :
        <Link to={manageLink}>Manage</Link>
      }
    </div>
    <div className="d-flex m-0">
      <h5 className="d-flex m-0 flex-wrap">
        {leftStatText && (
          <>
            <span className="text-success mr-1"> {leftStatText}:</span>
            <strong className="font-weight-bold">
              {loading ? <Spinner size="sm" className="mb-1" /> : leftStatCount}
            </strong>
          </>
        )}
      </h5>
      <h5 className="d-flex m-0 flex-wrap">
        {rightStatText && (
          <>
            <span className="text-warning mr-1"> {rightStatText}: </span>
            <strong className="font-weight-bold">
              {loading ? (
                <Spinner size="sm" className="mb-1" />
              ) : (
                rightStatCount
              )}
            </strong>
          </>
        )}
      </h5>
    </div>
  </div>
);

const IntersellerUserSyncCard = ({
  loading,
  title,
  rightStatText,
  rightStatCount = 0,
  leftStatText,
  leftStatCount = 0,
  manageLink = "",
  adminStatsRefetch,
}: {
  loading?: boolean;
  title: string;
  rightStatText?: string;
  rightStatCount?: number | string;
  leftStatText?: string;
  leftStatCount?: number | string;
  manageLink?: string;
  adminStatsRefetch: Function;
}) => {
  const [syncIntersellerUsers, { loading: syncIntersellerContactsLoading }] =
    useMutation(SYNC_INTERSELLER_USERS_TO_ATS);

  const syncIntersellerUsersToATS = async () => {
    const response = await syncIntersellerUsers({ variables: {} });
    if (response?.data?.syncIntersellerUsersToATS?.status === 200) {
      adminStatsRefetch();
      toast.success("Successfully synced interseller users to ATS");
    } else if (response?.data?.syncIntersellerUsersToATS?.status === 304) {
      toast.warning("Nothing available to sync interseller users to ATS");
    }
  };

  useEffect(() => {
    document.title = "Admin - RealREPP";

    // eslint-disable-next-line
  }, []);

  return (
    <div className="users-card">
      <div className="d-flex">
        <h5>{title}</h5>

        <Link to={manageLink} onClick={syncIntersellerUsersToATS}>
          Sync Users
          {syncIntersellerContactsLoading ? (
            <span>
              <Spinner size="sm" className="mb-1" />
            </span>
          ) : null}
        </Link>
      </div>
      <div className="d-flex m-0">
        <h5 className="d-flex m-0">
          {leftStatText && (
            <>
              <span className="text-success mr-1"> {leftStatText}:</span>
              <strong className="font-weight-bold">
                {loading ? (
                  <Spinner size="sm" className="mb-1" />
                ) : (
                  leftStatCount
                )}
              </strong>
            </>
          )}
        </h5>
        <h5 className="d-flex m-0">
          {rightStatText && (
            <>
              <span className="text-warning mr-1"> {rightStatText}: </span>
              <strong className="font-weight-bold">
                {loading ? (
                  <Spinner size="sm" className="mb-1" />
                ) : (
                  rightStatCount
                )}
              </strong>
            </>
          )}
        </h5>
      </div>
    </div>
  );
};

const IntersellerWebhookCard = ({
  loading,
  title,
  rightStatText,
  rightStatCount = 0,
  leftStatText,
  leftStatCount = 0,
  manageLink = "",
  adminStatsRefetch,
}: {
  loading?: boolean;
  title: string;
  rightStatText?: string;
  rightStatCount?: number | string;
  leftStatText?: string;
  leftStatCount?: number | string;
  manageLink?: string;
  adminStatsRefetch: Function;
}) => {
  const [
    syncIntersellerWebhooks,
    {
      // data: syncIntersellerContactsData,
      loading: syncIntersellerWebhooksLoading,
    },
  ] = useMutation(SYNC_INTERSELLER_WEBHOOKS);

  const syncIntersellerWebhooksHandler = async () => {
    const res = await syncIntersellerWebhooks({ variables: {} });
    if (res?.data) {
      adminStatsRefetch();
      toast.success("Successfully Synced webhooks against users");
    }
  };

  useEffect(() => {
    document.title = "Admin - RealREPP";

    // eslint-disable-next-line
  }, []);

  return (
    <div className="users-card">
      <div className="d-flex">
        <h5>{title}</h5>

        <Link to={manageLink} onClick={syncIntersellerWebhooksHandler}>
          Sync Webhooks
          {syncIntersellerWebhooksLoading ? (
            <span>
              <Spinner size="sm" className="mb-1" />
            </span>
          ) : null}
        </Link>
      </div>
      <div className="d-flex m-0">
        <h5 className="d-flex m-0">
          {leftStatText && (
            <>
              <span className="text-success mr-1"> {leftStatText}:</span>
              <strong className="font-weight-bold">
                {loading ? (
                  <Spinner size="sm" className="mb-1" />
                ) : (
                  leftStatCount
                )}
              </strong>
            </>
          )}
        </h5>
        <h5 className="d-flex m-0">
          {rightStatText && (
            <>
              <span className="text-warning mr-1"> {rightStatText}: </span>
              <strong className="font-weight-bold">
                {loading ? (
                  <Spinner size="sm" className="mb-1" />
                ) : (
                  rightStatCount
                )}
              </strong>
            </>
          )}
        </h5>
      </div>
    </div>
  );
};

const Admin = (): JSX.Element => {
  const [modal, setModal] = useState<boolean>(false)
  const [adminStats, { data, loading }] = useLazyQuery(GET_ADMIN_STATS);
  const { userRoles } = useContext(AppContext);

  const [
    adminPlacementStats,
    { data: adminPlacementStatsData, loading: adminPlacementStatsLoading },
  ] = useLazyQuery(PLACEMENT_ADMIN_STATS);

  useEffect(() => {
    adminPlacementStats({ variables: {} });
    adminStats({ variables: {} });

    // eslint-disable-next-line
  }, []);

  const toggle = () => setModal(!modal)
  return (
    <div className="admin-tabs">
      <GoBack url={SETTINGS_ROUTE} />

      <div className="priamryHeading primaryHeaderSpacing">
        <h5 className="m-0">Admin</h5>
      </div>

      <Row>
        {!!userRoles?.length && userRoles?.includes(ROLES.ADMIN) && (
          <>
            <Col xl="4" lg="6" md="6" xs="12">
              <StatCard
                title="Users"
                manageLink="/admin/users"
                loading={loading}
                leftStatText="Total"
                leftStatCount={data?.adminStats?.users || 0}
              />
            </Col>
            <Col xl="4" lg="6" md="6" xs="12">
              <StatCard
                title="Teams"
                manageLink="/admin/teams"
                leftStatText="Active"
                loading={loading}
                leftStatCount={data?.adminStats?.activeTeams || 0}
                rightStatText="Pending"
                rightStatCount={data?.adminStats?.pendingTeams || 0}
              />
            </Col>
            <Col xl="4" lg="6" md="6" xs="12">
              <StatCard
                title="Merge Companies"
                manageLink="/merge-company"
                leftStatText="Merged"
                loading={loading}
                leftStatCount={data?.adminStats?.mergeCompanies || 0}
                rightStatText="Pending"
                rightStatCount={data?.adminStats?.pendingCompanies || 0}
              />
            </Col>
            <Col xl="4" lg="6" md="6" xs="12">
              <StatCard
                title="Merge Contacts"
                manageLink="/merge-contacts"
                leftStatText="Merged"
                loading={loading}
                leftStatCount={data?.adminStats?.mergedContacts || 0}
                rightStatText="Pending"
                rightStatCount={data?.adminStats?.pendingContacts || 0}
              />
            </Col>{" "}
            <Col xl="4" lg="6" md="6" xs="12">
              <StatCard
                title="Merge Candidates"
                manageLink="/merge-candidate"
                leftStatText="Merged"
                loading={loading}
                leftStatCount={data?.adminStats?.mergeCandidates || 0}
                rightStatText="Pending"
                rightStatCount={data?.adminStats?.pendingCandidates || 0}
              />
            </Col>
            {/* <Col xl="4" lg="6" md="6" xs="12">
              <IntersellerUserSyncCard
                title="Interseller Users"
                manageLink="/admin"
                leftStatText="Already Synced"
                loading={loading}
                leftStatCount={data?.adminStats?.alreadySync || 0}
                rightStatText="Need Sync"
                rightStatCount={data?.adminStats?.needSync || 0}
                adminStatsRefetch={() => {
                  adminStats({ variables: {} });
                }}
              />
            </Col>
            <Col xl="4" lg="6" md="6" xs="12">
              <IntersellerWebhookCard
                title="Interseller Webhook"
                manageLink="/admin"
                leftStatText="Already Synced"
                loading={loading}
                leftStatCount={
                  data?.adminStats?.alreadySyncIntersellerWebhook || 0
                }
                rightStatText="Need Sync"
                rightStatCount={
                  data?.adminStats?.needSyncIntersellerWebhook || 0
                }
                adminStatsRefetch={() => {
                  adminStats({ variables: {} });
                }}
              />
            </Col> */}
          </>
        )}

        {!!userRoles?.length && (userRoles?.includes(ROLES.ACCOUNTING_MANAGER) || userRoles?.includes(ROLES.EXECUTIVE)) && (
          <Col xl="4" lg="6" md="6" xs="12">
            <StatCard
              title="Accounting Dashboard"
              manageLink={ACCOUNTING_DASHBOARD_ROUTE}
              leftStatText="Accounts Approved"
              loading={adminPlacementStatsLoading}
              leftStatCount={
                adminPlacementStatsData?.placementStatsForAdmin?.approved || 0
              }
              rightStatText="Pending Accounts"
              rightStatCount={
                adminPlacementStatsData?.placementStatsForAdmin?.pending || 0
              }
            />
          </Col>
        )}

        {!!userRoles?.length && (userRoles?.includes(ROLES.ADMIN) || userRoles?.includes(ROLES.EXECUTIVE)) && (
          <Col xl="4" lg="6" md="6" xs="12">
            <StatCard
              title={SYNONYM_LIST}
              manageLink={SYNONYM_ROUTE}
            />
          </Col>
        )}


        <Col xl="4" lg="6" md="6" xs="12">
          <StatCard
            title={MODULE_ACCESS}
            manageLink={MODULE_ROUTE}
            loading={loading}
          />
        </Col>

        <Col xl="4" lg="6" md="6" xs="12">
          <StatCard
            title={CHATGPT_TEMPLATES}
            manageLink={CHATGPT_TEMPLATE_ROUTE}
            loading={loading}
          />
        </Col>
        <Col xl="4" lg="6" md="6" xs="12">
          <StatCard
            title={RESUME_PARSING}
            buttonText="Manage"
            onClick={toggle}
            loading={loading}
          />
        </Col>
        <Col xl="4" lg="6" md="6" xs="12">
          <StatCard
            title={CSV_IMPORT}
            manageLink={ADMIN_IMPORT_CSV}
          />
        </Col>

        <Col xl="4" lg="6" md="6" xs="12">
          <StatCard
            title={RECRUITER_DEAL_TEMPLATES}
            manageLink={RECRUITER_DEAL_TEMPLATES_ROUTE}
          />
        </Col>

        {userRoles?.includes("EXECUTIVE") && <>
          <Col xl="4" lg="6" md="6" xs="12">
            <StatCard
              title={EMAIL_TEMPLATES}
              manageLink={EMAIL_TEMPLATES_ROUTE}
            />
          </Col>

          <Col xl="4" lg="6" md="6" xs="12">
            <StatCard
              title={USER_ACTIVITY_REPORT}
              manageLink={USER_ACTIVITY_REPORT_ROUTE}
            />
          </Col>

          <Col xl="4" lg="6" md="6" xs="12">
            <StatCard
              title={CANDIDATE_SEARCH_DEV}
              manageLink={MODULE_ROUTE}
            />
          </Col></>}
      </Row>
      <ResumeParsing
        modal={modal}
        toggle={toggle} />
    </div>
  );
};

export default Admin;
