import { useContext } from 'react';
import { Controller } from 'react-hook-form';
import Select from 'react-select';
import {
  Col,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
} from 'reactstrap';
import InputField from '../../../../components/layout/InputField';
import { AppContext } from '../../../../context';
import { PLACEMENT_COMISSION } from '../../../../utils/constant';
import {
  commaRemover,
  localStringMaker,
  moneyFormatter,
  moneyFieldHandler,
} from '../../../../utils/helper';

export default function DirectHirePlacementTypeBase({
  control,
  _getCompanyAgreementsLoading,
  salaryType,
  register,
  readonly,
  watch,
  agreements,
  localSalary,
  errors,
  setValue,
  setSalaryType,
  disableAllFields,
}: any) {
  const { theme } = useContext(AppContext);

  const onAgreementFilling = ({ value }: { value: string }) => {
    const oneAgreement = agreements?.find((item) => item?.id === value);
    if (
      oneAgreement?.agreementType === 'DIRECT_HIRE' ||
      oneAgreement?.agreementType === 'LEGAL_DIRECT_HIRE' ||
      oneAgreement?.agreementType === 'CUSTOM'
    ) {
      // Resetting the values
      const totalFeeCalculations =
        watch('commissionType')?.value === 'FLAT_FEE'
          ? localStringMaker(oneAgreement?.commissionPercentage)
          : localStringMaker(
            commaRemover(watch('salary')) *
            oneAgreement?.commissionPercentage *
            (1 / 100)
          );

      const netCommission =
        watch('adjustmentAmount') !== '0.00'
          ? localStringMaker(
            commaRemover(totalFeeCalculations) -
            commaRemover(watch('adjustmentAmount'))
          )
          : totalFeeCalculations;

      setValue('commissionType', { label: 'Percentage', value: 'PERCENTAGE' });
      setValue('totalFee', totalFeeCalculations);
      setValue('netCommission', netCommission);
      setValue('payableDays', oneAgreement?.payableDays?.toString());
      setValue('replacementDays', oneAgreement?.replacementDays?.toString());
      setValue(
        'feeStructured',
        localStringMaker(oneAgreement?.commissionPercentage)
      );
    }
  };

  const customStyles = {
    control: (styles, provided) => ({
      ...styles,

      backgroundColor: theme === 'light' ? provided.background : '#282828',
      borderColor: theme === 'light' ? '#ced4da' : '#525252',
      color: theme === 'light' ? provided.backgroundColor : '#d9d9d9',
      fontSize: 12,
      '&:hover': {
        borderColor: theme === 'light' ? '#ced4da' : '#282828',
        color: theme === 'light' ? provided.background : '#d9d9d9',
      },
    }),
    menu: (provided) => ({
      ...provided,
      background: theme === 'light' ? provided.background : '#282828',
      color: theme === 'light' ? provided.background : '#d9d9d9',
    }),
    option: (provided) => ({
      ...provided,
      fontSize: 12,
      background: theme === 'light' ? provided.background : '#363636',
      '&:hover': {
        background: theme === 'light' ? provided.background : '#474444',
        color: theme === 'light' ? provided.background : '#d9d9d9',
      },
    }),
    container: (provided, state) => ({
      ...provided,
      fontSize: 12,
    }),
  };

  const calculateTotalFee = () => {
    let commissionType = watch('commissionType')?.value;
    let salaryType = watch('salaryType');
    let salary = watch('salary');
    let feeStructured = watch('feeStructured');

    let total =
      commissionType === 'FLAT_FEE'
        ? localStringMaker(commaRemover(feeStructured))
        : localStringMaker(
          commaRemover(
            salaryType === 'ANNUALLY'
              ? salary
              : localStringMaker(commaRemover(salary) * 40 * 52)
          ) *
          commaRemover(feeStructured) *
          (1 / 100)
        );

    return total;
  };

  return (
    <>
      <Col lg="4" md="4" sm="12">
        <Label>Salary Type</Label>

        <div className="relocate-allowed">
          <FormGroup check className="p-0">
            <Label className="radio-container radio-font">
              Annually
              <Input
                disabled={disableAllFields || readonly}
                type="radio"
                name="salaryType"
                value="ANNUALLY"
                onChange={({ target: { value } }) => {
                  setSalaryType(value);
                  // setValue("salaryType", { label: "Annually", value });
                }}
                defaultChecked={salaryType === 'ANNUALLY' ? true : false}
                innerRef={register()}
              />
              <span className="checkmark"></span>
            </Label>
          </FormGroup>

          <FormGroup check>
            <Label className="radio-container radio-font">
              Hourly
              <Input
                disabled={disableAllFields || readonly}
                type="radio"
                value="HOURLY"
                onChange={({ target: { value } }) => {
                  setSalaryType(value);
                  // setValue("salaryType", { label: "Hourly", value });
                }}
                defaultChecked={salaryType === 'HOURLY' ? true : false}
                name="salaryType"
                innerRef={register()}
              />
              <span className="checkmark"></span>
            </Label>
          </FormGroup>
        </div>
      </Col>

      <Col
        lg="4"
        md="4"
        sm="12"
        className={`${!readonly && 'dollar-addon-btn'}`}
      >
        <FormGroup>
          <Label>
            Salary <span className="text-danger">*</span>
          </Label>

          <InputGroup>
            <InputGroupAddon
              addonType="prepend"
              className={`${disableAllFields && 'diable-form-group'}`}
            >
              $
            </InputGroupAddon>
            <Input
              readOnly={readonly}
              disabled={disableAllFields}
              placeholder={'Salary'}
              defaultValue={localSalary}
              name="salary"
              type="text"
              onChange={({ target: { value } }: any) => {
                if (value) {
                  moneyFieldHandler(value, setValue, 'salary');
                }
              }}
              innerRef={register({
                required: {
                  value: true,
                  message: "salary is required!",
                },
              })}
              errMsg={errors?.salary?.message}
            />
          </InputGroup>
          <small className="text-danger">{errors?.salary?.message}</small>

          <small className="text-muted ">
            {salaryType === 'HOURLY'
              ? `Estimated annual salary will be ${moneyFormatter(
                commaRemover(watch('salary')) * 40 * 52
              )}`
              : null}
          </small>
        </FormGroup>
      </Col>

      <Col lg="4" md="4" sm="12" className="agreementSelectSpacing">
        <Label>
          Agreement <span className="text-danger">*</span>
        </Label>
        <Controller
          rules={{
            required: {
              value: true,
              message: 'Agreement must be selected!',
            },
          }}
          name="agreement"
          render={({ onChange, name, value, ref }) => (
            <Select
              isDisabled={disableAllFields || readonly}
              isLoading={_getCompanyAgreementsLoading}
              onChange={(e) => {
                onAgreementFilling(e);
                onChange(e);
              }}
              value={value?.value ? value : null}
              name={name}
              ref={ref}
              styles={customStyles}
              options={agreements?.map((item: any) => {
                return {
                  label: item?.nickName,
                  value: item?.id,
                };
              })}
            />
          )}
          control={control}
          styles={customStyles}
        />
        <small className="text-danger">{errors?.agreement?.message}</small>
      </Col>

      <Col lg="4" md="4" sm="12" className="agreementSelectSpacing">
        <Label>
          Commission Type <span className="text-danger">*</span>
        </Label>
        <Controller
          rules={{
            required: {
              value: true,
              message: 'commission Type must be selected!',
            },
          }}
          control={control}
          styles={customStyles}
          name="commissionType"
          defaultValue={{ label: 'Flat Fee', value: 'FLAT_FEE' }}
          render={({ onChange, name, value, ref }) =>


          (
            <Select
              isDisabled={disableAllFields || readonly}
              onChange={(e) => {
                onChange(e);
              }}
              styles={customStyles}
              value={value}
              name={name}
              ref={ref}
              options={PLACEMENT_COMISSION}
            />

          )}


        />
        <small className="text-danger">{errors?.commissionType?.message}</small>
      </Col>

      <Col
        lg="4"
        md="4"
        sm="12"
        className={`${!readonly && 'dollar-addon-btn'}`}
      >
        <div className="agreementSelectSpacing">
          <Label>
            Fee <span className="text-danger">*</span>
          </Label>
          <InputGroup>
            <InputGroupAddon
              addonType="prepend"
              className={`${disableAllFields && 'diable-form-group'}`}
            >
              {watch('commissionType')?.value === 'FLAT_FEE' ? '$' : '%'}
            </InputGroupAddon>
            <Input
              readOnly={readonly}
              disabled={disableAllFields}
              onChange={(e) => {
                if (e) {
                  if (watch('commissionType')?.value === 'FLAT_FEE') {
                    moneyFieldHandler(
                      e?.target?.value,
                      setValue,
                      'feeStructured'
                    );
                  } else {
                    setValue('feeStructured', e?.target?.value);
                  }
                }
              }}
              placeholder={`Fee ${watch('commissionType')?.value === 'FLAT_FEE'
                ? 'in USD'
                : 'in %'
                }`}
              name="feeStructured"
              innerRef={register({
                required: 'Fee is required',
                // pattern: {
                //   value:
                //     comissionType?.value === "PERCENTAGE"
                //       ? new RegExp(/^(?:100(?:[.,]00?)?|\d?\d(?:[.,]\d\d?)?)$/)
                //       : new RegExp(/^\d{1,6}(\.\d{0,2})?$/),
                //   message: "Please enter a valid fee",
                // },
              })}
            />
          </InputGroup>
          <small className="text-danger">
            {errors?.feeStructured?.message}
          </small>
        </div>
      </Col>

      <Col lg="4" md="4" sm="12" className="agreementSelectSpacing">
        <InputField
          readOnly={readonly}
          disabled={disableAllFields}
          min="0"
          label="Replacement Days"
          isRequired
          inputtype="text"
          placeholder="e.g. 100"
          inputid="replacementDays"
          inputRef={register({
            required: {
              value: true,
              message: 'Replacement days is required!',
            },
            minLength: {
              value: 1,
              message: "Length of replacement days can't be less than 1",
            },
            maxLength: {
              value: 3,
              message: "Length of replacement days can't be more than 3",
            },
          })}
          errMsg={errors?.replacementDays?.message}
        />
      </Col>

      <Col lg="4" md="4" sm="12" className="agreementSelectSpacing">
        <InputField
          label="Payable Days"
          isRequired
          disabled={disableAllFields}
          readOnly={readonly}
          inputtype="text"
          placeholder="e.g. 15"
          inputid="payableDays"
          inputRef={register({
            required: {
              value: true,
              message: 'Payable days is required!',
            },
            minLength: {
              value: 1,
              message: "Length of payable days can't be less than 1",
            },
            maxLength: {
              value: 2,
              message: "Length of payable days can't be more than 2",
            },
          })}
          errMsg={errors?.payableDays?.message}
        />
      </Col>

      <Col lg="4" md="4" sm="12">
        <InputField
          disabled={disableAllFields}
          readOnly={readonly}
          label="Adjustment Reason"
          inputtype="text"
          placeholder="Reason for adjustment amount"
          inputid="adjustmentReason"
          inputRef={register({
            required: {
              value: false,
              message: 'Adjustment reason is required!',
            },
          })}
          errMsg={errors?.adjustmentReason?.message}
        />
      </Col>

      <Col
        lg="4"
        md="4"
        sm="12"
        className={`${!readonly && 'dollar-addon-btn'}`}
      >
        <Label>Adjustment Amount</Label>
        <InputGroup>
          <InputGroupAddon addonType="prepend" className={'diable-form-group'}>$</InputGroupAddon>
          <Input
            readOnly={readonly}
            onChange={({ target: { value } }) => {
              if (value) {
                moneyFieldHandler(value, setValue, 'adjustmentAmount');
              }
            }}
            disabled={disableAllFields}
            placeholder={'Adjustment Amount'}
            name="adjustmentAmount"
            innerRef={register({
              required: {
                value: false,
                message: 'Adjustment amount is required!',
              },
            })}
          />
        </InputGroup>
        <small className="text-danger">
          {errors?.adjustmentAmount?.message}
        </small>
      </Col>

      <Col lg="4" md="4" sm="12">
        <Label>Total Fee</Label>
        <InputGroup>
          <InputGroupAddon addonType="prepend" className={'diable-form-group'}>
            $
          </InputGroupAddon>
          <Input
            onChange={(e) => {
              if (e?.target?.value) {
                moneyFieldHandler(e?.target?.value, setValue, 'totalFee');
              }
            }}
            disabled={true}
            value={calculateTotalFee()}
            placeholder={`Total fee`}
            name="totalFee"
            innerRef={register()}
          />
        </InputGroup>
        <small className="text-danger">{errors?.totalFee?.message}</small>
      </Col>

      <Col lg="4" md="4" sm="12">
        <Label>Net Commission Fee</Label>
        <InputGroup>
          <InputGroupAddon addonType="prepend" className={'diable-form-group'}>
            $
          </InputGroupAddon>
          <Input
            disabled={true}
            value={
              watch('adjustmentAmount') !== '0.00'
                ? localStringMaker(
                  commaRemover(calculateTotalFee()) -
                  commaRemover(watch('adjustmentAmount'))
                )
                : calculateTotalFee() !== '0.00'
                  ? localStringMaker(commaRemover(calculateTotalFee()))
                  : '0.00'
            }
            onChange={(e) => {
              if (e?.target?.value) {
                moneyFieldHandler(e?.target?.value, setValue, 'netCommission');
              }
            }}
            placeholder={`Net fee`}
            name="netCommission"
            innerRef={register()}
          />
        </InputGroup>
        <small className="text-danger">{errors?.netCommission?.message}</small>
      </Col>
    </>
  );
}
