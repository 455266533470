import { useQuery } from "@apollo/client";
import { useContext, useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";
import { FormGroup, Input, Label } from "reactstrap";
import InputField from "../../../components/layout/InputField";
import { getOptions } from "../../../utils/commonFn";
// import { CompanySource } from "../../../utils/constant";
import { GET_SKILLS } from "../../job-order/gql";
import { customSelectDropDown, customSelectStyleNew } from "../../../components/styled/customSelect";
import { CompanySource } from "../../../utils/constant";
import { AppContext } from "../../../context";

const CandidateCredential = ({
  register,
  control,
  errors,
  candidateSource,
}: any) => {
  const { theme } = useContext(AppContext);
  const { data: skills } = useQuery(GET_SKILLS);
  const [skillsList, setSkillsList] = useState([]);

  useEffect(() => {
    if (skills?.getSkills?.length) {
      let skillOptions = getOptions({
        data: skills?.getSkills,
      });
      // ;
      setSkillsList(skillOptions);
    }
    // eslint-disable-next-line
  }, [skills]);

  return (
    <div>
      <h5>Candidate Credential </h5>

      <div>
        <div className="mb-3">
          <Label>Requires Sponsorship</Label>
          <div className="relocate-allowed">
            <FormGroup check className="p-0">
              <Label
                className="radio-container"
                style={{ fontSize: "inherit" }}
              >
                Yes
                <Input
                  type="radio"
                  value="true"
                  name="sponsored"
                  innerRef={register()}
                />
                <span className="checkmark"></span>
              </Label>
            </FormGroup>
            <FormGroup check>
              <Label
                className="radio-container"
                style={{ fontSize: "inherit" }}
              >
                No
                <Input
                  type="radio"
                  name="sponsored"
                  value="false"
                  innerRef={register()}
                />
                <span className="checkmark"></span>
              </Label>
            </FormGroup>
          </div>
        </div>

        <InputField
          inputtype="text"
          label="Current Job Title"
          inputid="jobTitle"
          isRequired={false}
          placeholder="Job Title"
          inputRef={register()}
          errMsg={errors?.jobTitle?.message}
        />

        <InputField
          inputtype="text"
          label="Current Company"
          inputid="companyName"
          isRequired={false}
          placeholder="Company"
          inputRef={register()}
          errMsg={errors?.company?.message}
        />

        <InputField
          inputtype="text"
          label="Seniority"
          inputid="seniority"
          isRequired={false}
          placeholder="Seniority"
          inputRef={register()}
          errMsg={errors?.seniority?.message}
        />

        <FormGroup className="my-3">
          <Label>Skills Set</Label>
          <Controller
            name="skillsSet"
            as={CreatableSelect}
            isMulti
            options={skillsList}
            placeholder="Skills"
            control={control}
            styles={{ ...customSelectStyleNew(theme) }}
          />
        </FormGroup>
        {/* this is commenting, if we need to disable the source field then remove the comments it'll work */}
        {/* {candidateSource?.source && candidateSource?.allowEdit ? (
          <> */}
        <Label>Source</Label>
        <Controller
          name="source"
          as={Select}
          options={CompanySource}
          placeholder="Source"
          control={control}
          rules={{
            required: {
              value: true,
              message: "Source must be selected!",
            },
          }}
          styles={{ ...customSelectDropDown(theme) }}
        />
        {/* </>
        ) : (
          <InputField
            inputtype="text"
            label="Source"
            isRequired={true}
            inputid="source"
            value={candidateSource?.source}
            placeholder="Source"
            readOnly={true}
          />
        )} */}
      </div>
    </div>
  );
};

export default CandidateCredential;
