/* eslint-disable jsx-a11y/anchor-is-valid */
import LOGO_IMG from "../../src/assets/images/logo.svg";
import TWITTER_IMG from "../../src/assets/images/twitter.png";
import FACEBOOK_IMG from "../../src/assets/images/facebook.png";
import INSTAGRAM_IMG from "../../src/assets/images/instagram.png";
import YOUTUBE_IMG from "../../src/assets/images/youtube.png";

const EmailSignatureTemp = ({ values }) => {
  return (
    <table className="email-signature" style={{ marginTop: 15, fontSize: "0.875rem", fontFamily: "arial" }}>
      <tbody>
        <tr>
          <td
            style={{
              border: 0,
              padding: "15px 15px 0px",
            }}
          >
            <h6
              style={{
                margin: 0,

                color: "#000000",
                fontSize: 13,
                fontFamily: `"Inter",sans-serif!important`,
              }}
            >
              {values?.firstName && values?.firstName + " " + values?.lastName}
            </h6>
          </td>
        </tr>
        <tr>
          <td
            style={{
              border: 0,
              padding: "5px 15px ",
            }}
          >
            <span
              style={{
                margin: 0,
                fontWeight: 500,
                color: "#000000",
              }}
            >
              {values?.jobTitle}
            </span>
          </td>
        </tr>
        <tr>
          <td style={{ border: 0, padding: "0px 15px" }}>
            {values?.mobile && (
              <>
                <label
                  style={{ color: "#eaa827", fontWeight: 600 }}
                  className="w-auto"
                >
                  D:
                </label>

                <span
                  style={{
                    color: "#000000",

                    padding: "5px",
                    fontFamily: `"Inter",sans-serif!important`,
                  }}
                >
                  {values?.mobile}
                </span>
              </>
            )}
            {
              <>
                {values?.phone && (
                  <>
                    {values?.mobile && values?.phone && " | "}
                    <label style={{ color: "#eaa827" }} className="w-auto">
                      O:
                    </label>
                    <span
                      style={{
                        color: "#000000",
                        padding: "5px",
                        fontFamily: `"Inter",sans-serif!important`,
                      }}
                    >
                      {values?.phone}
                    </span>
                  </>
                )}
              </>
            }
          </td>
        </tr>
        <tr>
          <td style={{ border: 0, padding: "0px 15px" }}>
            <a href="https://realrepp.com/" style={{ color: "#007bff" }}>
              {values?.companyUrl}
            </a>{" "}
            |{" "}
            <a href={`mailto:${values?.email}`} style={{ color: "darkblue" }}>
              {values?.email}
            </a>
          </td>
        </tr>
        {values?.linkedinURL && (
          <tr>
            <td
              style={{
                border: 0,
                padding: "0px 15px 20px 15px",
                fontSize: "12px",
                fontWeight: 500,
              }}
            >
              <span>LinkedIn:</span>{" "}
              <a
                href= {values?.linkedinURL}
                style={{ color: "#007bff" }}
                target="_blank" rel="noreferrer"
              >
               {values?.linkedinURL}
              </a>
            </td>
          </tr>
        )}
        <tr>
          <td style={{ border: 0, padding: "0px 15px" }}>
            <b style={{ fontStyle: "italic", color: "#eaa827" }}>Follow Us:</b>{" "}
            <a
              href="https://www.linkedin.com/company/realrepp"
              style={{ color: "#007bff" }}
              target="_blank" rel="noreferrer"
            >
              www.linkedin.com/company/realrepp
            </a>
          </td>
        </tr>
        <tr>
          <td style={{ border: 0, padding: "15px 15px" }}>
            <img src={LOGO_IMG} alt="logo" width="260px" />
          </td>
        </tr>
        <tr>
          <td style={{ border: 0, padding: "15px 15px" }}>
            {/* {values?.linkedinURL && (
              <a href="#">
                <img
                  src={LINKEDIN_IMG}
                  alt="linkedin"
                  width="30px"
                  style={{ margin: "0px 10px" }}
                />
              </a>
            )} */}
            {values?.twitterUrl && (
              <a href="">
                <img
                  src={TWITTER_IMG}
                  alt="twiiter"
                  width="30px"
                  style={{ margin: "0px 10px" }}
                />
              </a>
            )}
            {values?.fbUrl && (
              <a href="">
                <img
                  src={FACEBOOK_IMG}
                  alt="facebook"
                  width="30px"
                  style={{ margin: "0px 10px" }}
                />
              </a>
            )}
            {values?.instaUrl && (
              <a href="">
                <img
                  src={INSTAGRAM_IMG}
                  alt="instagram"
                  width="30px"
                  style={{ margin: "0px 10px" }}
                />
              </a>
            )}
            {values?.youtubeUrl && (
              <a href="">
                <img
                  src={YOUTUBE_IMG}
                  alt="youtube"
                  width="30px"
                  style={{ margin: "0px 10px" }}
                />
              </a>
            )}
          </td>
        </tr>
        <tr>
          <td style={{ border: 0, padding: "0px 15px 15px" }}>
            <p style={{ color: "#8d8d8d", margin: 0 }}>
              This email and any files transmitted with it are confidential and
              intended solely for the use of the individual or entity to whom
              they are addressed. If you have received this email in error
              please notify the system manager. This message contains
              confidential information and is intended only for the individual
              named. If you are not the named addressee you should not
              disseminate, distribute or copy this e-mail. Please notify the
              sender immediately by e-mail if you have received this e-mail by
              mistake and delete this e-mail from your system. If you are not
              the intended recipient you are notified that disclosing, copying,
              distributing or taking any action in reliance on the contents of
              this information is strictly prohibit
            </p>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default EmailSignatureTemp;
