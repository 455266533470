import { Key, useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { Badge, Button } from 'reactstrap';
import closeIcon from '../assets/images/close.svg';
import { AppContext } from '../context';
import { createMarkup, downloadSignedUrlFile } from '../utils/commonFn';
import { userReplyAccess } from '../utils/helper';
import ReplyButton from './ReplyButton';
import EmailHeader from './layout/EmailHeader';
import SmallLoader from './layout/SmallLoader';

const EmailComponent = ({ fullname, fromAddress, sentDateTime, subject, toAddress }) => {

  const formatDate = (dateTimeString: string) => {
    const options = {
      weekday: 'long' as const,
      month: 'long' as const,
      day: 'numeric' as const,
      year: 'numeric' as const,
      hour12: true,
      hour: '2-digit' as const,
      minute: '2-digit' as const
    };

    const formattedDate = new Date(dateTimeString).toLocaleString('en-US', options);
    return formattedDate;
  };

  return (
    <div style={{ border: 'none', borderTop: 'solid #E1E1E1 1.0pt', padding: '3.0pt 0in 0in 0in' }}>
      <p className="MsoNormal">
        <b>
          <span style={{ fontSize: '11.0pt' }}>From:</span>
        </b>
        <span style={{ fontSize: '11.0pt' }}>
          {fullname || ""} &lt; {fromAddress || ""} &gt;<br />

          <b>Sent:</b>{formatDate(sentDateTime)}<br />
          {toAddress.length && <><b>To:</b>  {toAddress.map((item: string, index: Key) => {
            return `< ${item} > ${index !== (toAddress.length - 1) ? ';' : ''} `;
          })}
            <br /></>}
          <b>Subject:</b> {subject}
        </span>
      </p>
    </div>
  );
};

const ContentViewSidebar = ({
  isOpen,
  toggle,
  content,
  heading,
  emailNotes,
  noteData,
  emailAttachments,
  hasAttachment,
  emailHeader
}: any) => {
  const { user } = useContext(AppContext);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);

  const onOriginalResumeHandler = async (originalUrl: string, resumeFileName: string) => {
    try {
      downloadSignedUrlFile(originalUrl, resumeFileName);
      setIsDownloading(false);
    } catch (e) {
      setIsDownloading(false);
      toast.error("Something Went Wrong");
    }
  };

  return (
    <>
      <div
        onClick={toggle}
        className={`sidebar-shadow ${isOpen ? 'show' : ''}`}
      />

      <div className={`sidebar-content ${isOpen ? 'open' : ''}`}>
        <Button
          type="button"
          className="my-auto bg-transparent border-0 sidebar-close"
          onClick={toggle}
        >
          <img src={closeIcon} className="m-0" alt="" width="13" />
        </Button>

        <h3>{heading}</h3>
        {emailNotes && (
          <div className="d-flex justify-content-between align-items-center mb-1">
            <EmailComponent
              fullname={emailNotes?.taggedUser?.fullName}
              fromAddress={emailNotes?.fromAddress}
              sentDateTime={emailNotes?.sentDateTime}
              subject={emailNotes?.subject}
              toAddress={emailNotes?.toAddress}
            />
            {userReplyAccess(emailNotes, user) && emailNotes?.emailWebLink && (
              <ReplyButton text={true} link={emailNotes?.emailWebLink} />
            )}
          </div>

        )}

        {noteData && (
          <div className="d-flex justify-content-between align-items-center mb-1">
            <div>
              <Badge color="success" className="badge-secondary mr-2">
                Category: {noteData?.category && noteData.category}
              </Badge>
              <Badge color="success" className="badge-secondary mr-2">
                Recruiter: {noteData?.user?.fullName && noteData.user.fullName}
              </Badge>
              <Badge color="success" className="badge-secondary mr-2">
                Candidate: {noteData?.noteableName && noteData.noteableName}
              </Badge>
            </div>
          </div>
        )}

        {heading === 'View Candidate Email Content' && (

          <div className='m-md-2'>
            <EmailHeader ccEmail={emailHeader?.ccEmail} bccEmail={emailHeader?.bccEmail} fromEmail={emailHeader?.fromEmail} subjectEmail={emailHeader?.subjectEmail} toEmail={emailHeader?.toEmail} />
          </div>
        )}

        <div
          className="for-wordfull-break"
          dangerouslySetInnerHTML={createMarkup(content)}
        />
        {hasAttachment && emailAttachments && emailAttachments?.length && (
          <div className="mt-4">
            <span className="font-weight-bold">Attachments</span>
            {isDownloading && (
              <span className="d-inline-flex ml-2">{<SmallLoader />} </span>
            )}
            <ul className="ml-4">
              {emailAttachments.map((attachment) => {
                return (
                  <li
                    className="cursor-pointer text-blue-link"
                    onClick={() => {
                      setIsDownloading(true);
                      onOriginalResumeHandler(
                        attachment?.url,
                        attachment?.fileName
                      );
                    }}
                  >
                    <span>{attachment?.fileName}</span>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </div>
    </>
  );
};

export default ContentViewSidebar;