import { Form, Spinner } from "reactstrap";
import AvatarImage from "../../../../assets/images/user-empty.svg";
import NoCompanyFoundInterseller from "../../../../components/layout/NoDataFound/NoCompanyFoundInterseller";

const CompanySuggestions = ({
  type,
  companies,
  setSelectedCompany,
  loading,
  setCompanyToggle,
}) => {
  return (
    <>
      <div
        className={`${
          type === "lookup"
            ? "multiselect-company-add-lookups toggle mt-2"
            : "multiselect-company-add toggle mt-2"
        } dark-card`}
      >
        {loading && (
          <div className="spinner-center">
            <Spinner size="sm" style={{ color: "#eaa827" }} className="mr-2" />
          </div>
        )}

        <Form>
          {companies &&
            companies.map((company: any, index: number) => (
              <div
                className="cursor-pointer avatar-label"
                onClick={() => {
                  setSelectedCompany({
                    name: company.name,
                    domain: company.domain,
                    logo: company.logo,
                  });
                  setCompanyToggle(false);
                }}
                key={index}
              >
                <div className="avatar">
                  <img src={company.logo || AvatarImage} alt="avatar" />
                </div>
                <label>{company.name}</label>
                <label className="ml-2 text-primary">{company?.domain}</label>
              </div>
            ))}
        </Form>

        {!companies ||
          (!companies.length && (
            <NoCompanyFoundInterseller
              text="No company found!"
              toggle={() => {
                setSelectedCompany({
                  name: "",
                  domain: "",
                  logo: "",
                });
                setCompanyToggle(false);
              }}
            />
          ))}
      </div>
    </>
  );
};

export default CompanySuggestions;
