import {
  ApolloClient,
  ApolloLink,
  DefaultOptions,
  InMemoryCache,
} from "@apollo/client"
import { onError } from "@apollo/client/link/error"
import { createUploadLink } from "apollo-upload-client"
// import { toast } from "react-toastify";

const authMiddleware = new ApolloLink((operation: any, forward: any) => {
  const token = localStorage.getItem("token") || null
  operation.setContext({
    headers: {
      authorization: `Bearer ${token}`,
    },
  })
  return forward(operation)
})

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: "network-only",
    errorPolicy: "all",
    notifyOnNetworkStatusChange: true,
  },
  query: {
    fetchPolicy: "network-only",
    errorPolicy: "all",
    notifyOnNetworkStatusChange: true,
  },
  mutate: {
    errorPolicy: "all",
  },
}

const signOutUser = (forward: Function, operation: any) => {
  localStorage.removeItem("token")
  localStorage.removeItem("previousToken")
  window.location.href = "/"
  return forward(operation)
}

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }: any) => {
    if (graphQLErrors) {
      for (let err of graphQLErrors) {
        if (err?.message === "Forbidden Exception") {
          signOutUser(forward, operation)
        }
        if (err?.message === "Unauthorized!") {
          window.location.href = "/un-authorized"
        }
        if (err?.code === "TokenExpiredError") {
          signOutUser(forward, operation)
        }
        if (err?.message === "invalid token" || err?.message === "invalid signature") {
          signOutUser(forward, operation)
        }
        if (err?.message === "Invalid Input") {
          console.log("Invalid Input Exception:::", graphQLErrors);
          // toast.error(err?.message)
        }
        if (err?.name === "TokenExpiredError" || err?.message === "jwt expired") {
          // alert("Session has been expired, Please login again!");
          // toast.error('Session has been expired, Please login again!');
          localStorage.removeItem("token")
          window.location.href = "/"
          return forward(operation)
        }

        if (err?.code === "INTERNAL_SERVER_ERROR") {
          if (
            err?.message === "Owner and sharee list is empty" ||
            err?.message ===
              "You are selecting recruiter level 'Team' but current user is not a part of any team!" ||
            err?.message ===
              "Currently, you are not a part of any Teams Users" ||
            err?.message === "Ownership not found"
          ) {
            console.log("Resticted Toasts")
          }
          // else {
          //   toast.error(err?.message);
          // }
        }
      }
    }
    if (networkError) {
      console.log(`[Network error]: ${networkError}`)
      // if you would also like to retry automatically on
      // network errors, we recommend that you use
      // apollo-link-retry
    }
  }
)

const client = new ApolloClient({
  cache: new InMemoryCache(),
  connectToDevTools: true,
  link: ApolloLink.from([
    errorLink,
    authMiddleware,
    createUploadLink({
      uri: process.env.REACT_APP_GRAPHQL_URI || "http://localhost:3001/graphql",
    }),
  ]),
  defaultOptions: defaultOptions,
})

export default client
