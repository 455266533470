import { useLazyQuery, useMutation } from "@apollo/client";
import React, { FC, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { toast } from "react-toastify";
import { Card, Label } from "reactstrap";
import ConfirmPopUp from "../../../components/ConfirmPopUp";
import InputField from "../../../components/layout/InputField";
import Loader from "../../../components/layout/Loader";
import NoDataFound from "../../../components/layout/NoDataFound";
import { Pagination } from "../../../components/layout/Pagination";
import TableComponent from "../../../components/layout/TableComponent";
import { CompanyContactOnViewCompanyProps } from "../../../interfaces";
import { COMPANY_CONTACT_HEADER, COMPANY_DETAILS_ROUTE, CONTACT_DETAIL_ROUTE, PAGE_LIMIT } from "../../../utils/constant";
import { pushInHistory } from "../../../utils/helper";
import { IComingState } from "../../agreements/interfaces";
import { GET_COMPANY_CONTACTS, UPDATE_COMPANY_PRIMARY_CONTACT } from "../gql";
import CreateContactModal from "./CreateContactModal";

const CompanyContactOnViewCompany: FC<CompanyContactOnViewCompanyProps> = ({
  companyId,
  companyName,
  haveActionPermissions
}) => {
  // constants and states
  const history = useHistory();
  const location = useLocation<IComingState>()
  const contactPage = parseInt("1");
  const [currentContactPage, setCurrentContactPage] = useState(contactPage);
  const [contactLimit, setContactLimit] = useState(3);
  const [, setEditModal] = useState(true)
  const [contactToggle, setContactToggle] = useState(false);
  const toggle = () => setContactToggle(!contactToggle);
  const [preferredContactModal, setpreferredContactModal] = useState(false);
  const [primaryContactId, setPrimaryContactId] = useState('');

  // api calls
  const [
    getCompanyContacts,
    { data: companyContacts, loading: companyContactsLoading, refetch: refetchCompanyContacts },
  ] = useLazyQuery(GET_COMPANY_CONTACTS);

  const [updatePrimaryContact, { loading: updateContactLoading }] = useMutation(
    UPDATE_COMPANY_PRIMARY_CONTACT
  );

  const updateCompanyPrimaryContact = async () => {
    let res = await updatePrimaryContact({
      variables: {
        updatePrimaryInput: {
          companyId,
          id: primaryContactId,
        },
      },
    });

    if (res?.data?.updatePrimaryContact) {
      setpreferredContactModal(false);
      refetchCompanyContacts();
      toast.success('Preferred contact successfully changed!');
    } else {
      toast.error('Unable to update preferred contact!')
    }
  };

  useEffect(() => {
    if (companyId) {
      getCompanyContacts({
        variables: {
          id: companyId,
          limit: contactLimit,
          page: currentContactPage,
        },
      });
    }
    // eslint-disable-next-line
  }, [companyId, contactLimit, currentContactPage]);

  return (
    <div>
      <Card className="border-0">
        <div className="card-inner-padding d-flex justify-content-end">
          <button
            type="button"
            className="buttonGenericStyle filledButton m-0"
            onClick={toggle}
            disabled={!haveActionPermissions}
          >
            Create Contact
          </button>
        </div>

        {companyContactsLoading ? (
          <Loader loaderClass="sm-height" />
        ) : (
          <div>
            <div className="table-responsive">
              <TableComponent tableHeader={COMPANY_CONTACT_HEADER}>
                <tbody>
                  {companyContacts?.getCompanyContacts?.contacts &&
                    companyContacts?.getCompanyContacts?.contacts?.map(
                      (item: {
                        id: string;
                        fullName: string;
                        jobTitle: string;
                        contactEmail: any[];
                        contactPhone: any[];
                        preferredContact: boolean;
                      }) => (
                        <tr>
                          {!!item?.fullName ?
                            <td
                              className="route-link"
                              onClick={() => {
                                history?.push({
                                  pathname: `${CONTACT_DETAIL_ROUTE}/${item?.id}`,
                                  state: {
                                    backUrl: `${COMPANY_DETAILS_ROUTE}/${companyId}`,
                                    searchParameters: location.search,
                                  },
                                });

                                pushInHistory(`${COMPANY_DETAILS_ROUTE}/${companyId}`, location.search)
                              }}
                            >
                              {item?.fullName}
                            </td> : <td>--</td>
                          }
                          <td>{item?.jobTitle || "--"}</td>
                          <td>
                            {(item?.contactEmail?.length &&
                              item?.contactEmail?.find((item) => item.isPrimary)
                                ?.email) ||
                              "--"}
                          </td>
                          <td>
                            {(item?.contactPhone?.length &&
                              item?.contactPhone?.find((item) => item?.isPrimary)
                                ?.number) ||
                              "--"}
                          </td>
                          <td>
                            {<div className="d-flex items-center" style={{ marginTop: '5px' }}>
                              <Label className="radio-container">
                                Preferred Contact
                                <input
                                  type="checkbox"
                                  name="oldAddress"
                                  checked={item?.preferredContact}
                                  onChange={() => {
                                    setpreferredContactModal(true);
                                    setPrimaryContactId(item?.id);
                                  }}
                                />
                                <span className="checkmark"></span>
                              </Label>
                            </div>}
                          </td>
                        </tr>
                      )
                    )}
                </tbody>

                {!companyContacts?.getCompanyContacts?.contacts?.length && (
                  <tbody>
                    <tr>
                      <td colSpan={5} className="empty-table-td">
                        <div className="for-position-multiselect">
                          <NoDataFound text="Contacts Not Found!" />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                )}
              </TableComponent>
            </div>
          </div>
        )}

        {companyContacts?.getCompanyContacts?.contacts?.length ? (
          <div className="users-pagination">
            <Pagination
              onPageChange={(pageClicked: number) => {
                setCurrentContactPage(pageClicked);
              }}
              pageCount={Math.ceil(
                companyContacts?.getCompanyContacts?.count / contactLimit
              )}
              currentPage={currentContactPage}
            />

            <div className="d-flex align-items-center">
              <div className="pagination-display paginationItemsSelect">
                <p className="m-0">
                  {(currentContactPage - 1) * contactLimit + 1} to{" "}
                  {Math.min(
                    currentContactPage * contactLimit,
                    companyContacts?.getCompanyContacts?.count
                  )}{" "}
                  of {companyContacts?.getCompanyContacts?.count}
                </p>

                <div className="divider"></div>

                <label>Display</label>

                <InputField
                  label=""
                  inputtype="select"
                  selectItems={PAGE_LIMIT.filter(
                    (item) => item.value !== contactLimit
                  )}
                  inputid="limit"
                  placeholder={contactLimit.toString()}
                  inputMethod={(value) => {
                    setCurrentContactPage(1);
                    setContactLimit(parseInt(value));
                  }}
                />
              </div>
            </div>
          </div>
        ) : null}
      </Card>

      <CreateContactModal
        toggle={toggle}
        setEditModal={setEditModal}
        open={contactToggle}
        companyId={companyId}
        companyName={companyName}
        preSelectContact={() => { }}
        fetchCompany={() => {
          getCompanyContacts({
            variables: {
              id: companyId,
              limit: contactLimit,
              page: currentContactPage,
            },
          });
        }}
      />

      <ConfirmPopUp
        confirmText="Are you sure you want to set as preferred contact?"
        isOpen={preferredContactModal}
        toggle={setpreferredContactModal}
        confirmAction={updateCompanyPrimaryContact}
        modalHeading="Set Preferred Contact"
        btnText="Confirm"
        btnColor="primary"
        loading={updateContactLoading}
        disabled={updateContactLoading}
      />
    </div>
  );
};

export default React.memo(CompanyContactOnViewCompany);
