import { useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import Users from '../users/Users';
import CustomButton from '../../../components/layout/CustomButton';
import SEARCH_IMG from '../../../assets/images/search.svg';

import GoBack from '../../../components/GoBack';
import { SYNC_INTERSELLER_USERS_TO_ATS } from '../gql';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import React from 'react';
import { USER_STATUS } from '../../../utils/constant';

const UsersComponent = ({ history }: RouteComponentProps): JSX.Element => {

  const [userStatus, setUserStatus] = useState('ACTIVE');
  const [searchText, setSearchText] = useState<string>('');
  const [syncIntersellerUsers, { loading }] = useMutation(
    SYNC_INTERSELLER_USERS_TO_ATS
  );

  const syncIntersellerUsersToATS = async () => {
    const response = await syncIntersellerUsers({ variables: {} });
    if (response?.data?.syncIntersellerUsersToATS?.status === 200) {
      toast.success('Successfully synced interseller users to ATS');
    } else if (response?.data?.syncIntersellerUsersToATS?.status === 304) {
      toast.warning('Nothing available to sync interseller users to ATS');
    }
  };

  return (
    <div className="admin-tabs">
      <GoBack url="/admin" />

      <div className="primaryHeading">
        <h5 className="m-0">Users</h5>
      </div>

      <div className="mobileResponsiveFlexAlign justify-content-between align-items-start flex-wrap gap-10 primaryHeaderSpacing primaryHeading mb-4">
        <div className="search-filter searchFilterResponsive justify-content-start">
          <div>
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <img src={SEARCH_IMG} alt="search" />
                </InputGroupText>
              </InputGroupAddon>

              <Input
                type="text"
                placeholder="Search User"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                className="search-by-company"
              />
            </InputGroup>

            <small className="text-info">
              {searchText &&
                searchText?.length <= 2 &&
                'Please add more than two characters'}
            </small>
          </div>
          <div>
            <select
              className="form-control pr-4"
              placeholder={'Status'}
              value={userStatus}
              onChange={(event) => {
                setUserStatus(event?.target?.value);
              }}>

              <option value="">{'Status'}</option>
              {USER_STATUS.map(
                (item: { label: string; value: string }, index: number) => (
                  <React.Fragment key={index}>
                    <option value={item?.value}>{item?.label}</option>
                  </React.Fragment>
                )
              )}
            </select>
          </div>
        </div>
        <div className="mobileResponsiveFlexDevice justify-content-end align-items-center flex-wrap buttonSpacing">
          <CustomButton
            buttonText="Sync Interseller Users"
            buttonColor="primary"
            className="big-width mx-1"
            loading={loading}
            disabled={loading}
            buttonClick={syncIntersellerUsersToATS}
          />

          <CustomButton
            buttonText="Add New User"
            buttonColor="primary"
            className="big-width mx-1"
            buttonClick={() => {
              history.push('/admin/add-user');
            }}
          />

          <button
            className="buttonGenericStyle filledButton ml-2 d-block"
            onClick={() => setSearchText('')}
          >
            Clear
          </button>
        </div>
      </div>

      <Users searchText={searchText} userStatus={userStatus} />
    </div >
  );
};

export default UsersComponent;
