import { useContext, useEffect, useState } from 'react';
import stepTimer from '../../../../assets/images/step-timer.svg';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  SEQUENCE_OTHER_STEP_DELETE,
  SEQUENCE_STEP_DELETE,
  SEQUENCE_STEP_UPDATE,
} from '../gql';
import { toast } from 'react-toastify';
import { IStepCardHeaderProps } from '../interfaceSteps';
import ConfirmPopUp from '../../../../components/ConfirmPopUp';
import EditStepModel from './EditStepModel';
import { AppContext } from '../../../../context';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Spinner,
} from 'reactstrap';
import { GET_SEQUENCE } from '../../sequences/gql';
export default function StepCardHeader({
  refetchStepCardHeader,
  compaignId,
  _id,
  title,
  subject,
  _step,
  fromPreview,
  otherStepSubject,
  sequenceStepsLengthCheck,
  updateWaitDays,
  setEmailContactStep,
  contactId,
  stepId,
  personalizedType,
  setEmailTemp,
}: IStepCardHeaderProps) {
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
  let { setContextSequence } = useContext(AppContext);
  const [removeStep, setRemoveStep] = useState<boolean>(false);
  const [removeOtherStep, setRemoveOtherStep] = useState<boolean>(false);
  const [editStep, setEditStep] = useState<boolean>(false);
  const [deleteSequenceStep, { loading }] = useMutation(SEQUENCE_STEP_DELETE);
  const [deleteSequenceOtherStep, { loading: loadingDeleteSequenceOtherStep }] =
    useMutation(SEQUENCE_OTHER_STEP_DELETE);
  const [sequenceStepUpdate] =
    useMutation(SEQUENCE_STEP_UPDATE);
  const [getSequence, { data: sequenceData }] = useLazyQuery(GET_SEQUENCE);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };
  useEffect(() => {
    getSequence({ variables: { compaignId } });
        // eslint-disable-next-line
  }, [compaignId]);

  const deleteStepHandler = async () => {
    let successorOfDeletedThread;
    let subjectForSuccesorOfDeletedThread;
    let deletedThread = sequenceData?.getSequence?.steps?.find(
      (step) => step._id === _id
    );
    if (deletedThread?.subject) {
      subjectForSuccesorOfDeletedThread = deletedThread?.subject;
      let indexNumber = sequenceData?.getSequence?.steps?.findIndex(
        (x) => x.id === _id
      );
      if (indexNumber > -1) {
        successorOfDeletedThread = !sequenceData?.getSequence?.steps?.at(
          indexNumber + 1
        )?.subject
          ? sequenceData?.getSequence?.steps?.at(indexNumber + 1)
          : null;
      }
    }
    if (sequenceStepsLengthCheck && _id) {
      setRemoveStep(false);
      const response = await deleteSequenceStep({
        variables: { compaignId, _id },
      });

      if (response?.data) {
        if (successorOfDeletedThread && subjectForSuccesorOfDeletedThread) {
          await sequenceStepUpdate({
            variables: {
              compaignId,
              _id: successorOfDeletedThread._id,
              subject: subjectForSuccesorOfDeletedThread,
              wait_days: successorOfDeletedThread.wait_days,
              type: successorOfDeletedThread.type,
              body: successorOfDeletedThread.body,
            },
          });
        }

        toast.success(`The selected step has been deleted successfully.`);
        refetchStepCardHeader();
      }
    } else {
      setRemoveStep(false);
      setContextSequence({});

      // toast.error(`Can't remove empty step`);
    }
  };

  const deleteOtherStepHandler = async () => {
    if (sequenceStepsLengthCheck && _id) {
      setRemoveOtherStep(false);
      const response = await deleteSequenceOtherStep({
        variables: { compaignId, _id: _step },
      });

      if (response?.data) {
        toast.success(`${subject} deleted successfully`);
        refetchStepCardHeader();
      }
    } else {
      setRemoveOtherStep(false);
      setContextSequence({});
      // toast.error(`Can't remove empty step`);
    }
  };

  const testingAB = () => {
    const obj = {
      _id,
      abTestStart: true,
    };
    setContextSequence(obj);
  };

  return (
    <div className="step-card-header  py-1">
      <div className="d-flex">
        <img src={stepTimer} alt="step-timer" />
        <h5>{title}</h5>
      </div>
      <div>
        {loading || loadingDeleteSequenceOtherStep ? (
          <h5 className="deleting-subject">
            <Spinner size="sm" style={{ color: '#eaa827' }} className="mr-2" />
            Deleting {subject}{' '}
          </h5>
        ) : (
          <>
            {fromPreview ? (
              <div style={{ display: 'flex' }}>
                {personalizedType  === 'manual' ? (
                  <h5 style={{ marginTop: '2%', marginRight: '20px' }}>
                    Personalized
                  </h5>
                ) : null}
              </div>
            ) : (
              <>
                <Dropdown
                  isOpen={dropdownOpen}
                  toggle={toggleDropdown}
                  className="actions-menu"
                >
                  <DropdownToggle caret className="bg-transparent border-0">
                    Actions
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem
                      style={{ cursor: 'pointer' }}
                      onClick={() => setEditStep(true)}
                    >
                      Edit
                    </DropdownItem>
                    {!_step && (
                      <DropdownItem onClick={testingAB}>
                        Start A/B Test
                      </DropdownItem>
                    )}
                    <DropdownItem onClick={() => setRemoveStep(true)}>
                      Delete
                    </DropdownItem>
                    {_step && (
                      <DropdownItem onClick={() => setRemoveOtherStep(true)}>
                        Delete A/B Test
                      </DropdownItem>
                    )}
                  </DropdownMenu>
                </Dropdown>
                {/* )} */}
              </>
            )}
          </>
        )}

        <ConfirmPopUp
          confirmText={`Are you sure you want to delete ${subject}?`}
          isOpen={removeStep}
          toggle={() => setRemoveStep(!removeStep)}
          confirmAction={deleteStepHandler}
          modalHeading="Delete Action"
          btnText="Delete"
          btnColor="primary"
          className="revampDialog revampDialogWidth"
        />

        <ConfirmPopUp
          confirmText={`Are you sure you want to delete ${otherStepSubject}? as A/B Test`}
          isOpen={removeOtherStep}
          toggle={() => setRemoveOtherStep(!removeOtherStep)}
          confirmAction={deleteOtherStepHandler}
          modalHeading="Delete A/B Step Action"
          btnText="Delete"
          btnColor="primary"
          className="revampDialog revampDialogWidth"
        />

        <EditStepModel
          updateWaitDays={updateWaitDays}
          sequenceStepsLengthCheck={sequenceStepsLengthCheck}
          refetchSteps={refetchStepCardHeader}
          _id={_id}
          compaignId={compaignId}
          isOpen={editStep}
          toggle={() => setEditStep(!editStep)}
        />
      </div>
    </div>
  );
}
