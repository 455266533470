import { useState } from "react";
import useHTTP from "./useHTTP.js";
import { toast } from "react-toastify";

const useHTTPGPT = () => {
  const endpoint = process.env.REACT_APP_CHATGPT_ENDPOINT || "https://api.openai.com/v1/chat/completions";

  const model16k = "gpt-3.5-turbo-16k";
  const model14k = "gpt-3.5-turbo";

  const headers = {
    "Content-Type": "application/json",
    Authorization: process.env.REACT_APP_CHATGPT_KEY || `Bearer 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`,
  };

  const { post, loading, error } = useHTTP({ headers });

  const [chunkMessage, setChunkMessage] = useState(null);
  const [stoppedResult, setStoppedResult] = useState(null);

  const sendRequest = async (messages = null) => {
    const body = {
      model: messages[messages?.length - 1]?.content?.length / 4 <= 4096 ? model14k : model16k,
      messages,
      stream: true,
    };
    const response = await post(endpoint, body);
    if (!response.ok && response.status === 401) {  
      toast("Access Token is not valid!");
    }
    const stream = response.body.getReader();
    const responseMessage = await readStreamPromise(stream, setChunkMessage);
    return responseMessage;
  };

  function readStreamPromise(reader, setChunkMessage) {
    let chunksReceived = [];
    let responseMessage = null;
    const decoder = new TextDecoder("utf-8");

    return new Promise((resolve, reject) => {
      function readStream() {
        reader
          .read()
          .then(({ done, value }) => {
            if (done) {
              setChunkMessage(null);
              resolve(responseMessage);
              return;
            }
            const chunk = decoder.decode(value);
            const chunks = chunk.split("data: ");
            let listChunk = chunks.slice(1).map((x) => {
              try {
                return JSON.parse(x);
              } catch {
                return null;
              }
            });

            chunksReceived = chunksReceived.concat(listChunk);
            const message = chunksReceived
              .map((chunk) => 
                chunk ? chunk.choices[0].delta.content || "" : ""
              )
              .join("");
            responseMessage = {
              id: chunksReceived[0].id,
              role: chunksReceived[0].choices[0].delta.role,
              content: message,
            };

            chunksReceived.forEach(chunk => {
              if (chunk && chunk.choices[0].finish_reason === "stop") {
                setStoppedResult(chunk.choices[0].finish_reason);
                responseMessage.stoppedMessage = chunk.choices[0].finish_reason;
              }
            });
            setChunkMessage(responseMessage);
            readStream();
          })
          .catch((error) => {
            reject(error); // handle any errors that occur during reading
          });
      }
      readStream();
    });
  }

  return {
    post: (body) => sendRequest(body),
    chunkMessage,
    loading,
    error,
    stoppedResult, // Expose the stoppedResult for outside use
  };
};

export default useHTTPGPT;