import { useMutation, useQuery } from "@apollo/client";
import { useContext, useState } from "react";
import { Controller } from "react-hook-form";
import Select from "react-select";
import { FormGroup, Label } from "reactstrap";
import { AppContext } from "../../../context";
import { GET_RECRUITER_LIST } from "../../job-order/gql";
import { REMOVE_OWNER } from "../gql";
import ConfirmPopUp from "../../../components/ConfirmPopUp";
import { toast } from "react-toastify";
import { customSelectDropDown } from "../../../components/styled/customSelect";
const AssignOwner = ({
  control,
  recruiterOwner,
  candidateId,
  updateUI,
}: any) => {
  const { theme } = useContext(AppContext)
  const [confirmToggle, setConfirmToggle] = useState(false);
  const { data: recruiters } = useQuery(GET_RECRUITER_LIST);
  const { userRoles } = useContext(AppContext);
  const [removeOwner] = useMutation(REMOVE_OWNER);

  const removeRecruiterOwner = async () => {
    if (recruiterOwner?.id) {
      const res = await removeOwner({ variables: { candidateId } });
      setConfirmToggle(!confirmToggle);
      if (res?.data) {
        toast.success("Owner Removed Successfully");
        updateUI();
      } else {
        toast.error("An error occured. Please try again later");
      }
    }
  };

  return (
    <div>
      <div>
        <FormGroup>
          <Label>Recruiter Owner</Label>
          <div className="d-flex own-controller-flex">
            <div style={{ flex: 10 }}>
              <Controller
                name="recruiterOwner"
                as={Select}
                options={
                  recruiters?.getRecruiterList?.length &&
                  recruiters?.getRecruiterList?.map((item: any) => ({
                    label: item.fullName,
                    value: item.id,
                  }))
                }
                control={control}
                placeholder="Recruiters Assigned"
                isSearchable={true}
                styles={{ ...customSelectDropDown(theme) }}
              />
            </div>
            {(userRoles?.includes("ADMIN") ||
              userRoles?.includes("EXECUTIVE")) &&
              recruiterOwner && (
                <span
                  className="pl-3"
                  onClick={() => setConfirmToggle(!confirmToggle)}
                >
                  Remove Owner
                </span>
              )}
          </div>
        </FormGroup>
      </div>
      <ConfirmPopUp
        confirmText="Are you sure you want to remove this recruiter?"
        isOpen={confirmToggle}
        toggle={() => {
          setConfirmToggle(!confirmToggle);
        }}
        confirmAction={removeRecruiterOwner}
        modalHeading="Remove Recruiter"
        btnText="Confirm"
        btnColor="primary"
        className="for-modal-styling"
      />
    </div>
  );
};

export default AssignOwner;
