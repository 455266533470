import { Link } from 'react-router-dom';
import INFO_ICON_5 from '../../../assets/images/email-icon.svg';
import Loader from '../../../components/layout/Loader';
import NoDataFound from '../../../components/layout/NoDataFound';
import TableComponent from '../../../components/layout/TableComponent';
import { CompanyExpiryReport } from '../../../interfaces';
import { formatDateUS } from '../../../utils/commonFn';
import { COMPANY_DETAILS_ROUTE, COMPANY_OWNERSHIP_REPORT_HEADER } from '../../../utils/constant';

const Listing = ({ loading, data }) => {
  if (loading) {
    return <Loader loaderClass="sm-height" />;
  }
  return (
    <>
      <div className="table-responsive">
        <TableComponent tableHeader={COMPANY_OWNERSHIP_REPORT_HEADER}>
          {!loading && data?.companyExpiryReports?.length === 0 && (
            <tbody>
              <tr>
                <td colSpan={6} className="empty-table-td">
                  <NoDataFound text="No Data Found!" />
                </td>
              </tr>
            </tbody>
          )}

          <tbody>
            {!loading &&
              data &&
              data?.companyExpiryReports?.length ?
              data?.companyExpiryReports?.map((item: CompanyExpiryReport, index: number) => {
                const contact = item?.contact; // Get the contact object

                // Access contact properties
                const fullName = contact?.fullName;
                // Filter contactEmail and contactPhone arrays for primary items
                const primaryEmail = contact?.contactEmail?.find(email => email?.isPrimary)?.email || '--';
                const primaryPhone = contact?.contactPhone?.find(phone => phone?.isPrimary)?.number || '--';
                return (
                  <tr key={index} className="job-orders-tbody">
                    <td className="route-link text-truncate max-200" >
                      <Link
                        target="_blank"
                        to={{
                          pathname: `${COMPANY_DETAILS_ROUTE}/${item?.companyId}`,
                        }}
                      >
                        {item?.companyName}
                      </Link>
                    </td>
                    <td>{item?.am}</td>
                    <td>{item?.endedAt ? formatDateUS(item?.endedAt) : '--'}</td>
                    <td>{fullName}</td>
                    <td>
                      {primaryEmail !== '--' ? (
                        <a href={`mailto:${primaryEmail}`}>
                          {primaryEmail}
                        </a>
                      ) : (
                        primaryEmail
                      )}
                    </td>
                    <td>{primaryPhone}</td>
                    <td>{item?.note}</td>
                    <td>
                      <button
                        className="iconButtonAction"
                        onClick={() => {
                          const primaryEmail = contact?.contactEmail?.[0]?.email;
                          window.location.href = `mailto:${primaryEmail}`;
                        }}
                      >
                        <img src={INFO_ICON_5} alt="email" width="19px" />
                      </button>
                    </td>
                    <td>{item?.daysToExpiration}</td>
                  </tr>
                );
              }) : ''}
          </tbody>
        </TableComponent>
      </div>
    </>
  );
}

export default Listing;