import { gql } from "@apollo/client";
import AsyncSelect from "react-select/async";
import { useContext } from 'react';
import { AppContext } from "../context";
import client from "../apollo";
import { toast } from "react-toastify";

export const FETCH_MERGED_CONTACTS = gql`
  query FetchAllMergeContacts(
    $page: Int!
    $limit: Int!
    $searchText: String
    $statusSearch: String
  ) {
    getMargeContacts(
      payload: { page: $page, limit: $limit }
      filters: { searchText: $searchText, statusSearch: $statusSearch }
    ) {
      mergeContacts {
        id
        destinationContactName
      }
      count
    }
  }
`;
const AsyncMergeContacts = (props) => {
  const { theme } = useContext(AppContext);
  const _filterCompanies = async (inputValue: string) => {
    try {
      const { data } = await client.query({
        query: FETCH_MERGED_CONTACTS,
        variables: {
          limit: 20,
          page: 1,
          searchText: inputValue,
          statusSearch: null,
        },
      });

      return data && data.getMargeContacts
        ? data?.getMargeContacts?.mergeContacts?.map((item) => ({
            value: item?.destinationContactName,
            label: item?.destinationContactName,
          }))
        : [];
    } catch (error) {
      toast.error("Error while loading contacts!");
    }
  };

  const promiseOptions = (inputValue: any): any =>
    new Promise((resolve) => {
      resolve(_filterCompanies(inputValue));
    });

  const customStyles = {
    control: (styles, provided) => ({
      ...styles,
      width: props.width,
      background: theme === "light" ? provided.background : "#282828",
      borderColor: theme === "light" ? "#ced4da" : "#282828",
      color: theme === "light" ? provided.background : "#d9d9d9",
      fontSize: 12,
      "&:hover": {
        borderColor: theme === "light" ? "#ced4da" : "#282828",
        color: theme === "light" ? provided.background : "#d9d9d9",
      },
    }),
    menu: (provided) => ({
      ...provided,
      background: theme === "light" ? provided.background : "#282828",
      zIndex: props?.zIndex || 999999,
      color: theme === "light" ? provided.background : "#d9d9d9",
      fontSize: 12,
    }),
    option: (provided, state) => ({
      ...provided,
      fontSize: 12,
      background: theme === "light" ? provided.background : "#363636",
      "&:hover": {
        background: theme === "light" ? provided.background : "#474444",
        color: theme === "light" ? provided.background : "#d9d9d9",
      },
    }),
    container: (provided, state) => ({
      ...provided,
      fontSize: 12,
    }),
  };

  return (
    <>
      <AsyncSelect
        cacheOptions
        isClearable
        {...(props.selectedCompany && {
          value: {
            value: props.selectedCompany.value,
            label: props.selectedCompany.label,
          },
        })}
        ref={props.ref}
        styles={{ ...customStyles }}
        defaultOptions
        onChange={(selected: any) => {
          if (props.onSelected) {
            props.onSelected(selected?.value);
          }
          if (selected) {
            props.onChange(selected.value);
          } else {
            props.onChange("");
          }
        }}
        loadOptions={promiseOptions}
        placeholder={props?.placeholder}
      />
    </>
  );
};

export default AsyncMergeContacts;
