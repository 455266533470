import { useLazyQuery } from '@apollo/client';
import { useContext, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import { FormGroup, Label } from 'reactstrap';
import Loader from '../../../../components/layout/Loader';
import { customSelectDropDown } from '../../../../components/styled/customSelect';
import { AppContext } from '../../../../context';
import {
  GET_CONTACT_DETAILS,
  GET_PREVIEW_STEPS,
  SEQUENCE_CONTACTS_LISTING_WITHOUT_CORE_DATA
} from '../../contacts/gql';
import { CONTACT_PERSONALIZED_STEPS } from '../gql/index';
import StepsListing from '../StepsListing';
import ContactViewDetails from './ContactViewDetails';

export default function ContactSelectSection({
  sequenceSteps,
  refetchSteps,
  stepSubject,
  compaignId,
  loading,
  fromPreview,
  emailContactStep,
  setEmailContactStep,
  setEmailTemp,
  emailTemp,
  setContactIdForTab,
  setPersonzailedStepID,
  stepIndexNumber,
  setstepIndexNumber,
  contactIdForTab,
  ...props
}) {
  const { errors, control, setValue } = useForm();
  const { theme } = useContext(AppContext);
  const [
    getContactDetails,
    { loading: loadingContactDetails, data: dataContactDetails },
  ] = useLazyQuery(GET_CONTACT_DETAILS);

  const [
    getPreviewSteps,
    { loading: loadingPreviewSteps, data: dataPreviewSteps },
  ] = useLazyQuery(GET_PREVIEW_STEPS);

  const [contactPersonalizedSteps, { data: dataContactPersonalizedSteps }] =
    useLazyQuery(CONTACT_PERSONALIZED_STEPS);

  const [
    allContactsSequence,
    { loading: loadingContactsSequence, data: dataContactsSequence },
  ] = useLazyQuery(SEQUENCE_CONTACTS_LISTING_WITHOUT_CORE_DATA);

  const onGetContactDetails = (contactId: string) => {
    setContactIdForTab(contactId)
    getContactDetails({ variables: { contactId } });
    contactPersonalizedSteps({ variables: { compaignId, contactId } });
  };

  useEffect(() => {
    if (compaignId) {
      allContactsSequence({ variables: { compaignId } });

      if (!dataPreviewSteps) {
        getPreviewSteps({ variables: { compaignId } });
      }
    }

  }, [allContactsSequence, compaignId, dataPreviewSteps, getPreviewSteps]);

  useEffect(() => {
    if (dataContactDetails) {
      props?.onContactDetailsClick(contactIdForTab);
    }
  }, [contactIdForTab, dataContactDetails, props]);

  useEffect(() => {
    if (contactIdForTab && compaignId) {
      if (!dataContactPersonalizedSteps) {
        contactPersonalizedSteps({ variables: { compaignId, contactId:contactIdForTab } });
      }
    }
  }, [compaignId, contactIdForTab, contactPersonalizedSteps, dataContactPersonalizedSteps]);


  return (
    <div className="contact-selection">
      {loadingPreviewSteps ? (
        <Loader loaderClass="sm-height" />
      ) : (
        <>
          {dataPreviewSteps?.previewSteps?.length ? (
            <>
              {!loadingContactsSequence && (
                <>
                  <FormGroup className="mb-2">
                    <Label>Select Contact</Label>
                    <Controller
                      name="name"
                      render={() => (
                        <Select
                          isSearchable={true}
                          options={dataContactsSequence?.getSequenceContactsWithoutCoreData?.map(
                            (item: any) => {
                              return { label: item?.name, value: item?._id };
                            }
                          )}
                          isLoading={loadingContactsSequence}
                          placeholder="Search contact to preview and personalize…"
                          onChange={(level: any) => {
                            setValue('name', level);
                            onGetContactDetails(level.value);
                          }}
                          styles={{ ...customSelectDropDown(theme) }}
                        />
                      )}
                      control={control}
                    />
                    <small className="text-danger">
                      {errors?.name?.message}
                    </small>
                  </FormGroup>
                
                {
                  contactIdForTab?(
                    <>
                      <ContactViewDetails
                    contactDetailsData={dataContactDetails?.getContactDetails}
                    loading={loadingContactDetails}
                  />
                    </>
                  ):""
                }

                
                  <StepsListing
                    contactEmail={dataContactDetails?.getContactDetails?.email}
                    personalizedSteps={
                      dataContactPersonalizedSteps?.contactPersonalizedSteps
                    }
                    personalizedStepsCheck={contactIdForTab ? true : false}
                    fromPreview={fromPreview}
                    stepSubject={stepSubject}
                    refetchStepsListingStep={refetchSteps}
                    compaignId={compaignId}
                    sequenceSteps={sequenceSteps}
                    emailContactStep={emailContactStep}
                    setEmailContactStep={setEmailContactStep}
                    contactId={contactIdForTab}
                    setEmailTemp={setEmailTemp}
                    emailTemp={emailTemp}
                    setPersonzailedStepID={setPersonzailedStepID}
                    setstepIndexNumber={setstepIndexNumber}
                  />
                </>
              )}
            </>
          ) : (
            <div className="empty-preview-steps">
              <h5>Nothing to preview</h5>
              <p>
                Add steps and contacts to your sequence to preview and customize
                the message
              </p>
            </div>
          )}
        </>
      )}
    </div>
  );
}
