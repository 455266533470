import Select from 'react-select';
import { useContext, useState } from 'react';
import { Form, Row, Col, Label, Button } from 'reactstrap';

import { AppContext } from '../../context';
import { MODULE_ACCESS_OPTIONS, MODULE_OPTIONS } from '../../utils/constant';
import {
  MODULE_ACCESS,
  MODULE,
  ACCESS,
  USERS,
  CANCEL,
  SAVE,
} from '../../constants';
import { customSelectStyleNew } from '../../components/styled/customSelect';
import { SAVE_MODAL_ACCESS_SETTINGS } from './gql';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/client';
import { addAccessModuleItemToLocalStorage } from '../../utils/commonFn';

const AddModal = ({
  onClose,
  register,
  Controller,
  control,
  users,
  errors,
  handleSubmit,
  refetchSettings,
  toggle
}) => {
  const { theme, user } = useContext(AppContext);
  const [selectedAccess, setSelectedAccess] = useState<{ label: string; value: string; } | null>(null);
  const [selectedFeature, setSelectedFeature] = useState<{ label: string; value: string; } | null>(null);
  const [saveModalAccessSettings] = useMutation(SAVE_MODAL_ACCESS_SETTINGS);

  const submitData = async (data) => {

    const settingsdata = {
      feature: selectedFeature?.value,
      type: data?.type?.label,
      user: data.users?.map(item => item?.value)

    }

    const settingsResp = await saveModalAccessSettings({
      variables: { settingDetails: settingsdata }
    })

    if (settingsResp?.data?.SaveModalAccessSettings?.status === 200) {
      addAccessModuleItemToLocalStorage(settingsdata, user?.id)
      toast.success(`Settings Save Successfully`);
      toggle();
      refetchSettings();
    }
  }
    ;

  return (
    <div className="p-4">
      <h4 className="mb-4">{MODULE_ACCESS}</h4>

      <Form>
        <Row className="mb-3">
          <Col md="12">
            <Label>{MODULE}</Label> <span className="text-danger">*</span>
            <Controller
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'Please Select Any Feature',
                },
              }}
              name="feature"
              options={MODULE_OPTIONS?.map((item) => {
                return {
                  label: item.label,
                  value: item.value,
                };
              })}
              render={({ onChange, value }) => (
                <Select
                  value={value}
                  options={MODULE_OPTIONS}
                  isSearchable={false}
                  placeholder="Select Module"
                  onChange={(target) => {
                    setSelectedFeature(target)
                    onChange(target);
                  }}
                  styles={{ ...customSelectStyleNew(theme) }}
                />
              )}
            />
            <small className="text-danger">{errors?.feature?.message}</small>

          </Col>

          <Col md="12">
            <Label>{ACCESS}</Label> <span className="text-danger">*</span>
            <Controller
              control={control}
              name="type"
              rules={{
                required: {
                  value: true,
                  message: 'Please Select Any Access',
                },
              }}
              options={MODULE_ACCESS_OPTIONS?.map((item) => {
                return {
                  label: item.label,
                  value: item.value,
                };
              })}
              render={({ onChange, value }) => (
                <Select
                  value={value}
                  options={MODULE_ACCESS_OPTIONS}
                  isSearchable={false}
                  placeholder="Access"
                  onChange={(target) => {
                    onChange(target);
                    setSelectedAccess(target);
                  }}
                  styles={{ ...customSelectStyleNew(theme) }}
                />
              )}
            />
            <small className="text-danger">{errors?.type?.message}</small>

          </Col>
        </Row>

        <Row>
          <Col md="12">
            <Label>{USERS}</Label>  {selectedAccess?.label === 'INDIVIDUAL' && (<span className="text-danger">*</span>)}
            <Controller
              control={control}
              name="users"
              rules={{
                required: {
                  value: selectedAccess?.label === 'INDIVIDUAL',
                  message: 'Atleast one user must be selected!',
                },
              }}
              options={
                users?.allActiveUsers?.length &&
                users?.allActiveUsers?.map((item: any) => ({
                  label: item?.fullName,
                  value: item?.id,
                }))
              }
              render={({ onChange, value }) => (
                <Select
                  isMulti
                  value={selectedAccess?.label === 'INDIVIDUAL' ? value : null}
                  isDisabled={selectedAccess?.label !== 'INDIVIDUAL'}
                  options={
                    users?.allActiveUsers?.length &&
                    users?.allActiveUsers?.map((item: any) => ({
                      label: item?.fullName,
                      value: item?.id,
                    }))
                  }
                  isSearchable={false}
                  placeholder="Select Users"
                  onChange={(target) => {
                    onChange(target);
                  }}
                  styles={{ ...customSelectStyleNew(theme) }}
                />
              )}
            />

            <small className="text-danger">{errors?.users?.message}</small>
          </Col>
        </Row>
        <div className="d-flex align-items-center justify-content-end mt-5">
          <button
            type="button"
            className="buttonGenericStyle buttonPrimary"
            onClick={() => onClose()}
          >
            {CANCEL}
          </button>
          <div className="px-3" />
          <Button onClick={handleSubmit(submitData)} className="buttonPrimary filledButton">
            {SAVE}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default AddModal;
