import React, { FC, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  AnchorIcon,
  BotIcon,
  NotificationIcon,
  RefreshIcon,
} from '../../../assets/images/svgs';
import './style.scss';
import { Badge, Button, Spinner } from 'reactstrap';
import {
  ACCOUNTING_DASHBOARD_ROUTE,
  AGREEMENTS_ROUTE,
  PLACEMENT_MANAGEMENT_ROUTE,
} from '../../../utils/constant';
import { useMutation, useQuery } from '@apollo/client';
import { CLEAR_OWNERSHIP_EXPIRY_COUNT } from './gql';
import ResumeDrawer from '../../../common/chatGpt/ResumeDrawer';
import useHTTPGPT from '../../../HTTP_Request/useHTTPGPT';
import { v4 as uuidv4 } from "uuid";
import { AppContext } from '../../../context';
import { checkUserFeatureAccess } from '../../../utils/commonFn';
import { ALL_MODAL_ACCCESS_SETTINGS } from '../../../Pages/moduleAccess-settings/gql';

interface NotificationInterface {
  isOpen: boolean;
  toggle: () => void;
  pedingActions: any;
  PendingActionScheduler: any;
  loaderForRecount: Boolean;
  pedingActionOfAllCategories: any;
  loaderForPendingAction: Boolean;
  userId?: string;
  pendingActionFromSockets?: any;
}

const Notification: FC<NotificationInterface> = ({
  isOpen,
  toggle,
  pedingActions,
  PendingActionScheduler,
  loaderForRecount,
  pedingActionOfAllCategories,
  loaderForPendingAction,
  userId,
  pendingActionFromSockets,
}) => {
  const { data: settingsData } = useQuery(ALL_MODAL_ACCCESS_SETTINGS);

  const [total, setTotal] = useState<number>(0);
  const history = useHistory();
  const { user, teachList } = useContext(AppContext);

  const [resumeDrawer, setResumeDrawer] = useState<boolean>(false);

  const handleResumeDrawer = () => {
    setResumeDrawer(!resumeDrawer);
  }

  const divRef = React.useRef<HTMLDivElement>(null);

  const [Clear_Ownership_Expiry_Count] = useMutation(CLEAR_OWNERSHIP_EXPIRY_COUNT);

  let routeHandler = async (category) => {
    toggle();
    if (category === 'Agreement') {
      history.push({
        pathname: AGREEMENTS_ROUTE,
        state: {
          pendingAction: true,
        },
      });
    } else if (category === 'Placement') {
      history.push({
        pathname: PLACEMENT_MANAGEMENT_ROUTE,
        state: {
          pendingAction: true,
        },
      });
    } else if (category === 'Accounting') {
      history.push({
        pathname: ACCOUNTING_DASHBOARD_ROUTE,
        state: {
          pendingAction: true,
        },
      });
    }
    else if (category === 'Interview') {
      history.push({
        pathname: '/job-orders',
        search: 'pendingInterview=true',
        state: {
          // pendingActionInterview: true,
          pendingAction: true,
          pendingActionFor: 'interview',
        },
      });
    } else if (category === 'Submission') {
      history.push({
        pathname: '/job-orders',
        search: 'pendingSubmission=true',
        state: {
          // pendingActionSubmission: true,
          pendingAction: true,
          pendingActionFor: 'submission',
        },
      });

    }
    else if (category === 'Company Ownership Expiring') {
      await Clear_Ownership_Expiry_Count();
      history.push({
        pathname: '/company-ownership-report',
        state: {
          clearCount: true
        },
      });

    }
    else if (category === 'Requests') {
      history.push({
        pathname: '/request',
      });

    }
  };

  const gptAPI = useHTTPGPT();
  const [messages, setMessages] = useState<{ content: string, id: string, role: string }[]>([]);

  useEffect(() => {
    if (user?.id) {
      const storedMessages = localStorage.getItem(`user: ${user?.id}`);
      if (storedMessages?.length) {
        setMessages(JSON.parse(storedMessages));
      }

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (user?.id && messages?.length) {
      localStorage.setItem(`user: ${user?.id}`, JSON.stringify(messages));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages]);
  async function submitChat(content) {
    try {
      const message = {
        id: uuidv4(),
        role: "user",
        content: content,
      };

      let newMessages = [...messages, message];
      setMessages(newMessages);
      const response = await gptAPI.post(
        teachList.map((t) => ({ role: "system", content: t.content }))
          .concat(
            newMessages.slice(-8).map((message) => {
              return {
                role: message.role,
                content: message.content,
              };
            })
          )
      );
      newMessages = [...messages, message, response];
      setMessages(newMessages);
    } catch { }
  }

  const clearChat = () => {
    localStorage.removeItem(`user: ${user?.id}`)
    setMessages([]);
  }

  useEffect(() => {
    if (
      pendingActionFromSockets?.pendingNotifications?.length >= 0 ||
      pedingActions?.pedingActionOfAllCategories?.pendingNotifications
        ?.length >= 0
    ) {
      if (pendingActionFromSockets?.pendingNotifications?.length) {
        if (pendingActionFromSockets?.pendingNotifications?.length > 1) {
          setTotal(
            pendingActionFromSockets?.pendingNotifications?.reduce(
              (previousValue, currentValue) =>
                previousValue + currentValue?.counter, 0
            )
          );
        } else if (
          pendingActionFromSockets?.pendingNotifications?.length === 1
        ) {
          setTotal(pendingActionFromSockets?.pendingNotifications[0].counter);
        } else {
          setTotal(0);
        }
        //-----------------------------------------------------------------------------
      } else if (
        pedingActions?.pedingActionOfAllCategories?.pendingNotifications?.length
      ) {

        if (
          pedingActions?.pedingActionOfAllCategories?.pendingNotifications
            ?.length > 1
        ) {
          setTotal(
            pedingActions?.pedingActionOfAllCategories?.pendingNotifications?.reduce(
              (previousValue, currentValue) =>
                previousValue + currentValue?.counter, 0
            )
          );

        } else if (
          pedingActions?.pedingActionOfAllCategories?.pendingNotifications
            ?.length === 1
        ) {
          setTotal(
            pedingActions?.pedingActionOfAllCategories?.pendingNotifications[0]
              .counter
          );
        } else {
          setTotal(0);
        }
      } else {
        setTotal(0);
      }
    }
  }, [
    pedingActions,
    pedingActions?.pedingActionOfAllCategories,
    pendingActionFromSockets,
    pendingActionFromSockets?.pendingNotifications,
    total,
  ]);
  useEffect(() => {
    document.addEventListener('mousedown', (event) => {
      if (!divRef.current?.contains(event?.target as Node)) {
        isOpen && toggle();
      }
    });
  }, [isOpen, toggle]);

  const recountNotification = async () => {
    let res = await PendingActionScheduler();
    if (res?.data?.PendingActionScheduler?.status === 200) {
      pedingActionOfAllCategories();
    }
  };
  const morethanOneHour = (date: any) => {
    const currentDate: any = new Date();
    const jobPostedDate: any = new Date(date);
    const hours = Math.abs(currentDate - jobPostedDate) / 3600000;
    return hours >= 1 ? true : false;
  };

  const BellIconClick = async () => {
    let lastRecount = {
      Id: userId,
      lastRecountTime: new Date(),
    };

    let getLastRecount = localStorage.getItem('lastRecount');
    let getLastRecountObject = getLastRecount
      ? JSON.parse(getLastRecount)
      : null;
    toggle();
    if (!getLastRecount) {
      localStorage.setItem('lastRecount', JSON.stringify(lastRecount));
      let res = await PendingActionScheduler();
      if (res?.data?.PendingActionScheduler?.status === 200) {
        pedingActionOfAllCategories();
        localStorage.setItem('lastRecount', JSON.stringify(lastRecount));
      }
    } else {
      let moreThanOneHour = morethanOneHour(
        getLastRecountObject?.lastRecountTime
      );
      if (moreThanOneHour && getLastRecountObject?.Id === userId) {
        let res = await PendingActionScheduler();
        if (res?.data?.PendingActionScheduler?.status === 200) {
          pedingActionOfAllCategories();
          localStorage.setItem('lastRecount', JSON.stringify(lastRecount));
        }
      }

      if (getLastRecountObject?.Id !== userId) {
        let res = await PendingActionScheduler();
        if (res?.data?.PendingActionScheduler?.status === 200) {
          pedingActionOfAllCategories();
          localStorage.setItem('lastRecount', JSON.stringify(lastRecount));
        }
      }
    }
  };

  return (
    <>
      {checkUserFeatureAccess(user?.id, 'CHATGPT', settingsData) && (
        <button
          type="button"
          className="chatgpt-icon-btn"
          onClick={handleResumeDrawer}
        >
          <BotIcon />
        </button>
      )}

      <div
        ref={divRef}
        className={`notification-warp fx-width ${!isOpen && 'closed'}`}
      >
        <div
          className={`${total ? 'notification-icon-new' : 'notification-icon'
            }  position-relative`}
          onClick={BellIconClick}
        >
          {!total ? (
            <NotificationIcon />
          ) : (
            <>
              <NotificationIcon />
              <Badge color="" className="notification-sign-new">
                {total > 99 ? (
                  <>
                    99 <sup>+</sup>
                  </>
                ) : (
                  total
                )}
              </Badge>
            </>
          )}
        </div>

        <div className="notification-body">
          <div className="notification-sidebar">
            <div className="notification-sidebar-header">
              <h3 className="font-weight-bold">Alerts</h3>
              <div
                className="mr-3 cursor-pointer"
                onClick={recountNotification}
              >
                {loaderForRecount || loaderForPendingAction ? (
                  <Spinner size="sm" className="mr-2" />
                ) : (
                  <RefreshIcon />
                )}
              </div>
            </div>
            <div
              className={
                loaderForRecount || loaderForPendingAction
                  ? 'notification-sidebar-body opacity-class'
                  : 'notification-sidebar-body'
              }
            >
              <div className="notification-sidebar-list">
                <ul className="list-unstyled border dark-border">
                  {pedingActions?.pedingActionOfAllCategories
                    ?.pendingNotifications?.length === 0 &&
                    pendingActionFromSockets?.pendingNotifications?.length ===
                    0 ? (
                    <div>No Action Is Pending</div>
                  ) : pendingActionFromSockets?.pendingNotifications?.length ? (
                    pendingActionFromSockets?.pendingNotifications?.map(
                      (notification, index) => {
                        return (
                          <li
                            className={`notification-list-wrap ${pendingActionFromSockets?.pendingNotifications -
                              1 !==
                              index
                              ? 'mb-3'
                              : ''
                              }`}
                            key={index}
                          >
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="notification-description">
                                <div className="d-flex align-items-center">
                                  <div className="notification-count mr-2">
                                    {notification.counter}
                                  </div>
                                  <div className="notification-text">
                                    <h6 className="text-black font-weight-bold">
                                      {notification?.category}
                                    </h6>
                                    <span className="text-grey">
                                      There are {notification?.counter} pending
                                      items
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="notification-action">
                                <Button
                                  type="button"
                                  className="bg-transparent anchor-link"
                                  onClick={() => {
                                    routeHandler(notification?.category);
                                  }}
                                >
                                  <AnchorIcon />
                                </Button>
                              </div>
                            </div>
                          </li>
                        );
                      }
                    )
                  ) : pedingActions?.pedingActionOfAllCategories
                    ?.pendingNotifications?.length ? (
                    pedingActions?.pedingActionOfAllCategories?.pendingNotifications?.map(
                      (notification, index) => {
                        return (
                          <li
                            className={`notification-list-wrap ${pedingActions?.pedingActionOfAllCategories
                              ?.pendingNotifications?.length &&
                              pedingActions?.pedingActionOfAllCategories
                                ?.pendingNotifications -
                              1 !==
                              index
                              ? 'mb-3'
                              : ''
                              }`}
                            key={index}
                          >
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="notification-description">
                                <div className="d-flex align-items-center">
                                  <div className="notification-count mr-2">
                                    {notification.counter}
                                  </div>
                                  <div className="notification-text">
                                    <h6 className="text-black font-weight-bold">
                                      {notification?.category}
                                    </h6>
                                    <span className="text-grey">
                                      There are {notification?.counter} pending
                                      items
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="notification-action">
                                <Button
                                  type="button"
                                  className="bg-transparent anchor-link"
                                  onClick={() => {
                                    routeHandler(notification?.category);
                                  }}
                                >
                                  <AnchorIcon />
                                </Button>
                              </div>
                            </div>
                          </li>
                        );
                      }
                    )
                  ) : null}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {resumeDrawer && (
        <ResumeDrawer
          toggle={handleResumeDrawer}
          isOpen={resumeDrawer}
          submitChat={submitChat}
          messages={messages}
          loading={gptAPI.loading}
          chunkMessage={gptAPI.chunkMessage}
          promptMessage={``}
          promptMessageForDisplay={``}
          promptMessageForVerification={''}
          heading={'Chat'}
          clearChat={clearChat}
        />
      )}
    </>
  );
};

export default Notification;
