import { gql } from '@apollo/client';

//query for the fetch all widgets
export const FETCH_WIDGETS = gql`
  query FetchWidgets {
    getAllWidgets {
      name
      id
      description
      role
    }
  }
`;

//query for the fetch user widgets
export const FETCH_USER_WIDGET = gql`
  query FetchUserWidgets {
    getUserWidgets {
      id
      rank
      createdAt
      widget {
        name
        role
        description
        subkey
      }
    }
  }
`;

//query for fetch user team and its all team member
export const FETCH_TEAM_AND_MEMBERS = gql`
  query FetchUserTeamMembers {
    widgetTeamMembers {
      id
      startAt
      endedAt
      team {
        name
      }
      user {
        fullName
        email
        phone
        photo
      }
    }
  }
`;

//query for fetch user stats
export const FETCH_USER_STATS = gql`
  query FetchUserStats($statsDateFilter: StatsDateFilter!) {
    fetchUserStats(statsDateFilter: $statsDateFilter) {
      totalNotes
      uniqueNotes
      totalInterviews
      initialInterviews
      totalSubmissions
      completedSubmissions
      totalOffers
      totalBilling
      totalSignedAgreements
      lookups,
      contacts,
      messages
    }
  }
`;

//query for fetch user billing credit
export const FETCH_USER_BILLING_CREDIT = gql`
  query GetRecruiterBillingCredit ($DateOfRange:DateOfRange!) {
    getRecruiterBillingCredit (DateOfRange:$DateOfRange) {
      id
      firstName
      lastName
      photo
      totalBillingCredit
      startedBillingCredit
      tempTotalBillingCredit
      tempStartedBillingCredit
    }
  }
`;

//Mutation work for the add new user widget
export const ADD_USER_WIDGET = gql`
  mutation AddWidget($widgetId: String!) {
    addUserWidget(widgetId: $widgetId) {
      id
      rank
    }
  }
`;

//Mutation work for the update user widget
export const UPDATE_USER_WIDGET = gql`
  mutation UpdateWidget($payload: [WidgetIdRank!]!) {
    updateWidget(payload: { widgetRank: $payload }) {
      message
      status
    }
  }
`;

//Mutation work for the update user widget
export const REMOVE_USER_WIDGET = gql`
  mutation RemoveWidget($widgetId: String!) {
    removeWidget(widgetId: $widgetId) {
      message
      status
    }
  }
`;
