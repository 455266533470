import { useContext } from "react";
import Select from "react-select";
import { Controller } from "react-hook-form";
import { AppContext } from "../../../context";
import {
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
} from "reactstrap";
import InputField from "../../../components/layout/InputField";
import { customSelectStyleNew } from "../../../components/styled/customSelect";
import { IdealWorkType } from "../../../utils/constant";
import { moneyFieldHandler } from "../../../utils/helper";

const IdealEmployment = ({
  register,
  errors,
  control,
  getValues,
  setValue,
}) => {
  // const [val, setVal] = useState("hourly");
  const { theme } = useContext(AppContext);

  return (
    <div>
      <h5>Ideal Employment</h5>

      <div className="user-basic-form">
        <InputField
          inputtype="text"
          isRequired={false}
          label="Ideal Job Title"
          inputid="idealJobTitle"
          placeholder="Job Title"
          inputRef={register({
            required: {
              value: false,
              message: "Job Title is required!",
            },
          })}
          errMsg={errors?.idealJobTitle?.message}
        />
      </div>

      <FormGroup>
        <Label>Work Type</Label>
        <Controller
          name="idealWorkType"
          as={Select}
          options={IdealWorkType}
          control={control}
          placeholder="Work Type"
          styles={{ ...customSelectStyleNew(theme) }}
        />
      </FormGroup>

      <div>
        <Label>Salary Rate</Label>
        <div className="relocate-allowed">
          <FormGroup check className="p-0">
            <Label className="radio-container" style={{ fontSize: "inherit" }}>
              Annually
              <Input
                type="radio"
                // checked={val === "annually" ? true : false}
                name="salaryType"
                value="ANNUAL"
                innerRef={register()}
                // onClick={() => setVal("annually")}
              />
              <span className="checkmark"></span>
            </Label>
          </FormGroup>
          <FormGroup check>
            <Label className="radio-container" style={{ fontSize: "inherit" }}>
              Hourly
              <Input
                type="radio"
                value="HOURLY"
                name="salaryType"
                // checked={val === "hourly" ? true : false}
                innerRef={register()}
                // onClick={() => setVal("hourly")}
              />
              <span className="checkmark"></span>
            </Label>
          </FormGroup>
        </div>
      </div>

      <div className="dollar-addon-btn d-flex justify-content-between">
        <FormGroup className="mr- 2 w-50">
          <Label>Salary Min</Label>

          <InputGroup>
            <InputGroupAddon addonType="prepend">$</InputGroupAddon>
            <Input
              placeholder="Amount"
              defaultValue={1}
              name="salaryMin"
              // value={getValues()?.salaryMin}
              onChange={(e) => {
                moneyFieldHandler(e?.target?.value, setValue, "salaryMin");
              }}
              // min={val === "hourly" ? 0 : 2000}
              // type="text"
              innerRef={register()}
            />
          </InputGroup>
          <small className="text-danger">{errors?.salaryMin?.message}</small>
        </FormGroup>

        <FormGroup className="ml-2 w-50">
          <Label>Salary Max</Label>
          <InputGroup>
            <InputGroupAddon addonType="prepend">$</InputGroupAddon>
            <Input
              placeholder="Amount"
              // value={getValues()?.salaryMax}
              name="salaryMax"
              onChange={(e) => {
                moneyFieldHandler(e?.target?.value, setValue, "salaryMax");
              }}
              // max={
              //   val === "hourly"
              //     ? Number.MAX_SAFE_INTEGER
              //     : Number.MAX_SAFE_INTEGER
              // }
              // type="text"
              innerRef={register()}
            />
          </InputGroup>
          <small className="text-danger">{errors?.salaryMax?.message}</small>
        </FormGroup>
      </div>
    </div>
  );
};

export default IdealEmployment;
