import { gql } from "@apollo/client";

export const GET_RECRUITER_GOAL_SHEET = gql`
query getRecruiterGoalSheet($createRecruiterGoalSheetDto:CreateRecruiterGoalSheetDto!){
  getRecruiterGoalSheet(createRecruiterGoalSheetDto:$createRecruiterGoalSheetDto){
    goalSheet{
      highlighter
      minExpectationIndex
      startDate
      periodEndDate
      weeks
      days
      expectationTargets
      userActivityDownloadLink
      billingSummary
    }
    message
    status
  }
}`

export const GET_RECRUITER_FULL_NAME = gql`
query getRecruiterFullName($id:String!){
  getRecruiterFullName(id:$id){
    fullName
    message
    status
  }
}`