import {
  Card,
  CardBody,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { createMarkup, formatDateUS } from "../utils/commonFn";
import CustomButton from "./layout/CustomButton";

const EmailNoteViewModal = ({ modalData, toggle, modal }) => {
  return (
    <Modal
      isOpen={modal}
      toggle={toggle}
      scrollable
      // className="for-modal-styling"
      style={{ maxWidth: "1050px", height: "100vh" }}
    >
      <ModalHeader toggle={toggle}>View Email Notes</ModalHeader>
      <ModalBody>
        <Card className="border-0">
          <CardBody className="py-0 px-0" style={{ fontSize: "20px" }}>
            <>
              <div className="candidate-notes-wrapper">
                <div className="candidate-notes">
                  <small className="text-muted d-block">
                    <strong>To Contacts:</strong>{" "}
                    {modalData?.toAddress?.join(",")}
                  </small>

                  <small className="text-muted d-block">
                    <strong>Cc Contacts:</strong>{" "}
                    {modalData?.ccAddress?.join(",")}
                  </small>
                  <small className="text-muted d-block">
                    <strong>Bcc Contacts:</strong>{" "}
                    {modalData?.bccAddress?.join(",")}
                  </small>
                  <small className="text-muted d-block">
                    <strong>From Contacts:</strong>{" "}
                    {modalData?.fromAddress || "--"}
                  </small>
                  <small className="text-muted d-block">
                    <strong>Subject:</strong> {modalData?.subject}
                  </small>

                  <small className="text-muted d-block">
                    <strong>Category:</strong> {modalData?.categories}
                  </small>

                  <small className="text-muted d-block">
                    <strong>
                      Tagged Candidate:{" "}
                      {modalData?.taggedCandidate?.fullName || "--"}
                    </strong>
                  </small>

                  <small className="text-muted d-block">
                    <strong>
                      Tagged Contact:{" "}
                      {modalData?.taggedContact?.fullName || "--"}
                    </strong>
                  </small>
                  <small className="text-muted d-block">
                    <strong>
                      Tagged Company: {modalData?.taggedCompany?.name || "--"}
                    </strong>
                  </small>
                  <small className="text-muted d-block">
                    <strong>
                      Tagged User: {modalData?.taggedUser?.fullName}
                    </strong>
                  </small>

                  <small className="text-muted d-block">
                    <strong>Body: </strong>
                    <div >
                      {modalData ? (
                        <pre
                          className="text-field-value p-0 border-0"
                          dangerouslySetInnerHTML={createMarkup(
                            modalData?.bodyContent
                          )}
                        />
                      ) : (
                        <div>---</div>
                      )}
                    </div>
                  </small>

                  <small className="text-muted d-block">
                    <strong>Sent At: </strong>
                    <span>
                      {modalData?.sentDateTime
                        ? formatDateUS(modalData?.sentDateTime)
                        : "--"}{" "}
                      {modalData?.sentDateTime &&
                        new Date(
                          modalData.sentDateTime
                        ).toLocaleTimeString()}{" "}
                    </span>
                  </small>
                </div>
              </div>
            </>
          </CardBody>
        </Card>
      </ModalBody>
      <ModalFooter>
        <CustomButton
          buttonText="Close"
          buttonColor="primary"
          buttonType="button"
          buttonClick={toggle}
        />{" "}
      </ModalFooter>
    </Modal>
  );
};

export default EmailNoteViewModal;
