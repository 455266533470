import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { COMPANY_DETAILS_ROUTE } from './constant';

export const messageParsing = (value) => {
  try {
    let notify = JSON.parse(value?.alertMsg);
    if (
      value?.notifyableType === 'Agreement' &&
      (value.alertType === 'INFORMING_AGREEMENT_CHECKUP' ||
        value.alertType === 'SEND_AGREEMENT_CHECKUP' ||
        value.alertType === 'CLIENT_CHECKUP_REMINDER')
    ) {
      let companyName = notify?.companyName;
      let msg = notify?.msg?.split('companyName');
      return (
        <span>
          {msg[0]}
          <Link
            className="text-link"
            to={`${COMPANY_DETAILS_ROUTE}/${notify?.companyId}`}
          >
            {companyName}
          </Link>
          {msg[1]}{' '}
        </span>
      );
    } else {
      return notify?.msg;
    }
  } catch (error) {
    return value?.alertMsg;
  }
};

export const renderItem = (
  label: string,
  value: string | number | ReactNode | undefined
) => (
  <>
    <div className="d-flex justify-content-between mb-2 contentGridLabel">
      <label>{label}</label>

      <div className="contentGrid">
        <h6>{value || '--'}</h6>
      </div>
    </div>
  </>
);

let host = window?.location?.host;

export const outlookLink = (
  <a
    rel="noreferrer"
    className="fab fa-instagram ml-1"
    target="_blank"
    href={`https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=ab6b624f-8258-44de-91cb-d602de5fd950&response_type=code&response_mode=query&redirect_uri=${host.includes('localhost')
      ? 'http://localhost:3000/settings'
      : host.includes('stage')
        ? 'https://stage.ats.realrepp.com/settings'
        : host.includes('feature')
          ? 'https://feature.ats.realrepp.com/settings'
          : 'https://ats.realrepp.com/settings'
      }&scope=user.read Mail.ReadWrite Mail.Send offline_access&state=01926`}
  >
    <Button className="bg-transparent text-primary border-0 p-0" size="sm">
      <u>Sign In</u>
    </Button>
  </a>
);

export const outlookAccountingLink = (
  <a
    rel="noreferrer"
    className="fab fa-instagram ml-1"
    target="_blank"
    href={`https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=ab6b624f-8258-44de-91cb-d602de5fd950&response_type=code&response_mode=query&redirect_uri=${host.includes('localhost')
      ? 'http://localhost:3000/settings'
      : host.includes('stage')
        ? 'https://stage.ats.realrepp.com/settings'
        : host.includes('feature')
          ? 'https://feature.ats.realrepp.com/settings'
          : 'https://ats.realrepp.com/settings'
      }&scope=user.read Mail.ReadWrite Mail.Send offline_access&state=00253`}
  >
    <Button className="bg-transparent text-primary border-0 p-0" size="sm">
      <u>Sign In</u>
    </Button>
  </a>
);
