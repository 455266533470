import { useLazyQuery } from "@apollo/client";
import { useContext, useEffect, useState } from "react";
import StepCards from "./step-components/StepCards";
import { SEQUENCE_STEP_STATS } from "./gql/index";
import { Button } from "reactstrap";
import { AppContext } from "../../../context";
import {
  ISequenceStep,
  IStepsListingProps,
  IContactPersonalizedSingleStep,
} from "./interfaceSteps";

export default function StepsListing({
  compaignId,
  sequenceSteps,
  refetchStepsListingStep,
  fromPreview,
  stepSubject,
  personalizedStepsCheck,
  personalizedSteps,
  stepLoading,
  contactEmail,
  otherSteps,
  emailContactStep,
  setEmailContactStep,
  contactId,
  setEmailTemp,
  emailTemp,
  setPersonzailedStepID,
  setstepIndexNumber

}: IStepsListingProps) {
  const [getSequenceStepsStats, { data }] = useLazyQuery(SEQUENCE_STEP_STATS);
  let { sequencePreview, setContextSequence } = useContext(AppContext);
  const [subject, setSubject] = useState<string>("");
  let previousValue;
  const [displayReplyStepCard, setDisplayReplyStepCard] =
    useState<boolean>(false);
  const fetchSequenceStepsStatsHandler = async () => {
    getSequenceStepsStats({ variables: { compaignId } });
  };

  const addStepLocally = () => {
    let lastElement;
    for (let i = sequenceSteps.length - 1; i >= 0; i--) {
      if (sequenceSteps[i].subject) {
        lastElement = sequenceSteps[i].subject;
        break;
      }
    }

    if (lastElement) {
      setDisplayReplyStepCard(true);
      setSubject(lastElement);
    }

    const obj = {
      _id: "",
      subject: lastElement,
      latestSubject: lastElement,
      body: "",
      preview: false,
      wait_days: 2,
      fromPreview: false,
      sequenceStepsLengthCheck: !!sequenceSteps?.length,
      replying: true,
    };
    setContextSequence(obj);
  };

  useEffect(() => {
    fetchSequenceStepsStatsHandler();

    // eslint-disable-next-line
  }, [compaignId]);

  useEffect(() => {
    setDisplayReplyStepCard(false);
    setSubject("");
  }, [stepLoading]);

  useEffect(() => {
    if (!sequencePreview?.wait_days) {
      setDisplayReplyStepCard(false);
      setSubject("");
    }
  }, [sequencePreview]);
  
  return (
    <div className={!fromPreview ? "step-listing" : ""}>
      <>
        {!sequenceSteps?.length ? (
          <>
            {!stepSubject && (
              <StepCards
                stepLoading={stepLoading}
                fromPreview={fromPreview}
                refetchStepCards={refetchStepsListingStep}
                compaignId={compaignId}
                _id=""
                sequenceStepsLengthCheck={!!sequenceSteps?.length}
                wait_days={null}
                subject={""}
                body=""
                sequenceSteps={sequenceSteps}
                latestSubject={previousValue}
                setEmailTemp={setEmailTemp}
                setstepIndexNumber={setstepIndexNumber}

              />
            )}
            {stepSubject && (
              <StepCards
                stepLoading={stepLoading}
                fromPreview={fromPreview}
                refetchStepCards={refetchStepsListingStep}
                compaignId={compaignId}
                _id=""
                sequenceStepsLengthCheck={!!sequenceSteps?.length}
                wait_days={null}
                subject={stepSubject}
                body=""
                sequenceSteps={sequenceSteps}
                latestSubject={previousValue}
                setEmailTemp={setEmailTemp}
                setstepIndexNumber={setstepIndexNumber}

              />
            )}
          </>
        ) : (
          <>
            {personalizedStepsCheck ? (
              <>
                {personalizedSteps?.map(
                  (
                    personalizedSequenceStep: IContactPersonalizedSingleStep,
                    index: number
                  ) => {
                    if(personalizedSequenceStep?.personalizedStepDetails?.subject){
                      previousValue = personalizedSequenceStep?.personalizedStepDetails?.subject;

                    }
                    
                    return (
                      <>
                        <div key={index} className="steps-number-wrapper">
                          <div className="card-number">{index + 1}</div>
                        </div>
                        <div key={`${index}Personzalied ${personalizedSequenceStep?._step}`}>
                          <StepCards
                            contactEmail={contactEmail}
                            scheduledFor={
                              personalizedSequenceStep?.scheduled_for
                            }
                            stepLoading={stepLoading}
                            stepIndex={personalizedSequenceStep?.step}
                            stepId={personalizedSequenceStep?._step}
                            fromPreview={fromPreview}
                            sequenceStepsLengthCheck={!!sequenceSteps?.length}
                            refetchStepCards={refetchStepsListingStep}
                            compaignId={compaignId}
                            _id={personalizedSequenceStep?._step}
                            subject={
                              personalizedSequenceStep?.personalizedStepDetails
                                ?.subject
                            }
                            body={
                              personalizedSequenceStep?.personalizedStepDetails
                                ?.body
                            }
                            sequenceSteps={sequenceSteps}
                            latestSubject={previousValue}
                            setEmailContactStep={setEmailContactStep}
                            emailContactStep={emailContactStep}
                            contactId={contactId}
                            personalizedType={ personalizedSequenceStep?.personalizedStepDetails?.type}
                            setEmailTemp={setEmailTemp}
                            emailTemp={emailTemp}
                            setPersonzailedStepID={setPersonzailedStepID}
                            personalizedSteps={personalizedSteps}
                            setstepIndexNumber={setstepIndexNumber}
                          />
                        </div>
                      </>
                    );
                  }
                )}
              </>
            ) : (
              <>
                {sequenceSteps?.map(
                  (sequenceStep: ISequenceStep, index: number) => {
                    if (sequenceStep.subject) {
                      previousValue = sequenceStep.subject;
                    }
                    return (
                      <>
                        <div key={index} className="steps-number-wrapper">
                          <div className="card-number">{index + 1}</div>
                        </div>
                        <div key={sequenceStep?._id}>
                          <StepCards
                            otherStepSingle={otherSteps?.find(
                              (item) => item._step === sequenceStep?._id
                            )}
                            stepLoading={stepLoading}
                            fromPreview={fromPreview}
                            sequenceStepsLengthCheck={!!sequenceSteps?.length}
                            refetchStepCards={refetchStepsListingStep}
                            compaignId={compaignId}
                            sequenceStepStats={data?.sequenceStepsStats?.stats?.find(
                              (item: any) => item?.key === sequenceStep?.id
                            )}
                            _id={sequenceStep?._id}
                            wait_days={sequenceStep?.wait_days}
                            subject={sequenceStep?.subject}
                            body={sequenceStep?.body}
                            sequenceSteps={sequenceSteps}
                            latestSubject={previousValue}
                            manual={sequenceStep?.manual}
                            stepIndex={index+1}
                            setEmailTemp={setEmailTemp}
                            setstepIndexNumber={setstepIndexNumber}
                          />
                        </div>
                      </>
                    );
                  }
                )}
              </>
            )}
          </>
        )}

        {displayReplyStepCard && (
          <>
            <div className="steps-number-wrapper">
              <div className="card-number">{sequenceSteps?.length + 1}</div>
            </div>
            <StepCards
              fromPreview={fromPreview}
              refetchStepCards={refetchStepsListingStep}
              compaignId={compaignId}
              _id=""
              sequenceStepsLengthCheck={!!sequenceSteps?.length}
              wait_days={2}
              subject={subject}
              body=""
              replying={true}
              sequenceSteps={sequenceSteps}
              setEmailTemp={setEmailTemp}
              setstepIndexNumber={setstepIndexNumber}
            />
          </>
        )}

        {!fromPreview && !stepLoading && (
          <div className="save-step-listing">
            <Button
              disabled={
                !subject && stepSubject && sequenceSteps?.length ? false : true
              }
              onClick={addStepLocally}
              className="mb-3"
            >
              Add Step
            </Button>
          </div>
        )}
      </>
    </div>
  );
}
