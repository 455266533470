import { gql } from "@apollo/client";

export const CREATE_INTERVIEW = gql`
  mutation CreateInterview($createInterviewDTO: CreateInterviewDTO!) {
    createInterview(createInterviewDTO: $createInterviewDTO) {
      id
      interviewType
    }
  }
`;

export const LOCK_INTERVIEW = gql`
  mutation LockInterview($lockInterviewDTO: LockInterviewDTO!) {
    lockInterview(lockInterviewDTO: $lockInterviewDTO) {
      status
    }
  }
`;

export const SEND_INVITE = gql`
  mutation SendInvite($sendInviteDTO: SendInviteDTO!) {
    sendInvite(sendInviteDTO: $sendInviteDTO) {
      id
    }
  }
`;

export const RE_SEND_INVITE = gql`
  mutation ReSendInvite($reSendInviteDTO: ReSendInviteDTO!) {
    reSendInvite(reSendInviteDTO: $reSendInviteDTO) {
      id
    }
  }
`;

export const SEND_INTERVIEW_EMAIL = gql`
  mutation SendInterviewEmail($sendInterviewEmailDTO: SendInterviewEmailDTO!) {
    sendInterviewEmail(sendInterviewEmailDTO: $sendInterviewEmailDTO) {
      message
      status
    }
  }
`;

export const UPDATE_INTERVIEW = gql`
  mutation UpdateInterview($updateInterviewDTO: UpdateInterviewDTO!) {
    updateInterview(updateInterviewDTO: $updateInterviewDTO) {
      message
      status
    }
  }
`;

export const CANDIDATE_EMAILS = gql`
  query CandidateEmails($candidateId: String!) {
    candidateEmails(candidateId: $candidateId) {
      id
      email
      isPrimary
    }
  }
`;

export const COMPANY_CONTACTS = gql`
  query CompanyContacts($companyId: String!) {
    companyContacts(companyId: $companyId) {
      id
      email
      isPrimary
    }
  }
`;

export const FETCH_CONTACTS_AGAINST_COMPANY = gql`
  query FetchAllContacts(
    $page: Int!
    $limit: Int!
    $searchText: String
    $statusSearch: String
    $companyId: String
  ) {
    allContacts(
      payload: { page: $page, limit: $limit }
      filters: {
        searchText: $searchText
        statusSearch: $statusSearch
        companyId: $companyId
      }
    ) {
      contacts {
        id
        fullName
        contactEmail {
          id
          email
          isPrimary
        }
      }
      count
    }
  }
`;

export const CONTACT_EMAILS = gql`
  query ContactEmails($contactId: String!) {
    contactEmails(contactId: $contactId) {
      id
      email
      isPrimary
    }
  }
`;

export const INTERVIEW_EMAIL_ATTACHMENTS = gql`
  query InterviewEmailAttachments($interviewEmailId: String!) {
    interviewEmailAttachments(interviewEmailId: $interviewEmailId) {
      id
      fileName
      pathToFile
    }
  }
`;

export const INTERVIEW_EMAIL_ATTACHMENT_REMOVE = gql`
  mutation InterviewEmailAttachmentRemove($id: String!) {
    interviewEmailAttachmentRemove(id: $id) {
      message
      status
    }
  }
`;

export const ADD_CANDIDATE_EMAIL = gql`
  mutation AddCandidateEmail(
    $candidateId: String!
    $isPrimary: Boolean
    $email: String!
  ) {
    addCandidateEmail(
      addCandidateEmailDTO: {
        candidateId: $candidateId
        isPrimary: $isPrimary
        email: $email
      }
    ) {
      message
      status
    }
  }
`;

export const ADD_CONTACT_EMAIL = gql`
  mutation AddContactEmail(
    $contactId: String!
    $isPrimary: Boolean
    $email: String!
  ) {
    addContactEmail(
      addContactEmailDTO: {
        contactId: $contactId
        isPrimary: $isPrimary
        email: $email
      }
    ) {
      message
      status
    }
  }
`;

export const UPDATE_INTERVIEW_STATUS = gql`
  mutation UpdateInterviewStatus(
    $id: String!
    $candidateId: String!
    $status: String!
  ) {
    updatingInterviewStatus(
      id: $id
      candidateId: $candidateId
      status: $status
    ) {
      message
      status
    }
  }
`;

export const GET_ALL_INTERVIEW_LIST = gql`
  query AllInterviewList(
    $page: Int!
    $limit: Int!
    $searchText: String
    $candidateId: String
    $jobOrderId: String
    $jobApplicantId: String
  ) {
    allInterviewList(
      payload: { page: $page, limit: $limit }
      filters: {
        searchText: $searchText
        candidateId: $candidateId
        jobOrderId: $jobOrderId
        jobApplicantId: $jobApplicantId
      }
    ) {
      interviews {
        locked
        id
        interviewRoundIndex
        clientInviteFlag
        candidateInviteFlag
        interviewStatus
        interviewType
        feedbackScore
        timeZone
        timeZoneId
        location
        details
        interviewDateTime
        jobOrder {
          id
          jobTitle
          company {
            id
            name
          }
        }
        candidate {
          id
          fullName
          emails {
            id
            isPrimary
            email
          }
        }
        jobApplicant {
          id
        }
      }
      count
    }
  }
`;

export const GET_JOB_APPLICANT_LIGHT = gql`
  query JobApplicantDetails($id: String!) {
    jobApplicantDetails(id: $id) {
      id
      candidate {
        id
        fullName
        streetAddress
        city
        stateCode
        address2
        address1
        postalCode
        countryCode
        geoLocation
        jobTitle
        companyName
        candidateStats {
          id
          jobAppCount
          interviewCount
          ownershipExpiration
          submissionCount
          daySinceLastAction
          updatedAt
          lastCheckedInRecruiter {
            id
            fullName
          }
        }
        emails {
          id
          isPrimary
          email
        }
        recruiterOwner {
          id
          fullName
        }
      }
      JobOrder {
        id
        jobTitle
        timezone
        status
        allowRemote
        timezoneId
        dstOffset
        workplaceTypes
        company {
          id
          name
          owner {
            id
            fullName
          }
          ownerAndSharees {
            id
            sharingId
            ownerType
            owner {
              id
              fullName
            }
          }
        }
        companyLocation {
          id
          streetAddress
          city
          state
          zipCode
          country
        }
      }
    }
  }
`;

export const GET_INTERVIEW_DETAIL = gql`
  query GetInterviewDetail($id: String!) {
    interviewDetail(id: $id) {
      id
      interviewRoundIndex
      interviewStatus
      interviewType
      feedbackScore
      timeZone
      timeZoneId
      location
      details
      interviewDateTime
      candidate {
        id
        fullName
        emails {
          id
          isPrimary
          email
        }
      }
      jobApplicant {
        id
      }
      jobOrder {
        id
        jobTitle
        company {
          id
          name
        }
      }
    }
  }
`;

export const SUBMISSION_STATUS_CHANGE = gql`
  mutation UpdateApplicantsStatusSubmission($id: String!, $status: String!) {
    updateApplicantsStatusSubmission(id: $id, status: $status) {
      message
      status
    }
  }
`;

export const DECREMENT_IN_COUNT = gql`
  mutation counterDecrementInPendingAction($category: String!) {
    counterDecrementInPendingAction(category: $category) {
      message
      status
    }
  }
`;

export const UPDATE_INTERVIEW_FLAG = gql`
  mutation updateFlag($UpdateInterviewFlagInput: UpdateInterviewFlagInput!) {
    updateFlag(UpdateInterviewFlagInput: $UpdateInterviewFlagInput) {
      message
      status
    }
  }
`;
