import { gql } from "@apollo/client";

export const CreateNewSequenceInput = gql`
  input CreateNewSequenceInput {
    name: String
  }
`;

export const LaunchPauseSequenceInput = gql`
  input LaunchPauseSequenceInput {
    active: Boolean
    compaignId: String
    paused_until: String
  }
`;

export const CREATE_NEW_SEQUENCE = gql`
  mutation CreateNewSequence($createNewSequenceInput: CreateNewSequenceInput!) {
    createNewSequence(createNewSequenceInput: $createNewSequenceInput) {
      message
      status
    }
  }
`;

export const UPDATE_SEQUENCE = gql`
  mutation UpdateSequence($updateSequenceInput: UpdateSequenceInput!) {
    updateSequence(updateSequenceInput: $updateSequenceInput) {
      message
      status
    }
  }
`;

export const ARCHIVED_SEQUENCES = gql`
  mutation ArchivedSequences($removingSequencesInput: RemovingSequencesInput!) {
    removingSequences(removingSequencesInput: $removingSequencesInput) {
      message
      status
    }
  }
`;

export const LAUNCH_PAUSE_SEQUENCE = gql`
  mutation LaunchPauseSequence(
    $launchPauseSequenceInput: LaunchPauseSequenceInput!
  ) {
    launchPauseSequence(launchPauseSequenceInput: $launchPauseSequenceInput) {
      message
      status
    }
  }
`;

export const GET_SEQUENCE = gql`
  query GetSequence($compaignId: String!) {
    getSequence(compaignId: $compaignId) {
      active
      _id
      created_at
      title
      id
      deleted_at
      paused_until
      shared
      stepSubject
      steps {
        _id
        _step
        body
        id
        manual
        subject
        type
        wait_days
      }
      other_steps {
        _step
        body
        _id
        subject
        type
      }
      user {
        _id
        account_type
        crm_type
        email
        full_name
        id
      }
    }
  }
`;

export const GET_SEQUENCE_STATS = gql`
  query GetSequenceStats($compaignId: String!) {
    getSequenceStats(compaignId: $compaignId) {
      total
      ongoing
      bounced
      messaged
      viewed
      visited
      replied
      booked
      error
    }
  }
`;

export const MESSAGES_IN_QUEUE = gql`
  mutation MessagesInQueue($limit_day: String!, $max_days: Float!) {
    getMessagesInQueue(limit_day: $limit_day, max_days: $max_days) {
      key
    }
  }
`;

export const SEQUENCE_LISTING = gql`
  query GetSequenceList(
    $query: String
    $businessDevelopmentSequences: Boolean
  ) {
    allSequences(
      filter: {
        query: $query
        businessDevelopmentSequences: $businessDevelopmentSequences
      }
    ) {
      sequences {
        active
        deleted_at
        shared
        _id
        title
        created_at
        statsCount {
          contacts_count
          errored_contacts_count
          messaged_contacts_count
          replied_contacts_count
          viewed_contacts_count
        }
        id
      }
      count
    }
  }
`;

export const SEQUENCE_LISTING_ID_NAME = gql`
  query GetSequenceList(
    $query: String
    $businessDevelopmentSequences: Boolean
  ) {
    allSequencesIdName(
      filter: {
        query: $query
        businessDevelopmentSequences: $businessDevelopmentSequences
      }
    ) {
      _id
      title
    }
  }
`;

export const SEQUENCE_LISTING_ID_NAME_FOR_LISTING = gql`
  query GetSequenceList {
    allSequencesIdNameForListing {
      _id
      title
    }
  }
`;

export const SEQUENCE_LISTING_ID_NAME_TEMPLATE = gql`
  query GetSequenceListTemplating(
    $query: String
    $businessDevelopmentSequences: Boolean
  ) {
    allSequencesIdNameTemplate(
      filter: {
        query: $query
        businessDevelopmentSequences: $businessDevelopmentSequences
      }
    ) {
      _id
      title
    }
  }
`;

export const TODAY_SEQUENCE_STATS = gql`
  query TodaySequenceStats($date: String!) {
    sequenceStatsByDate(date: $date) {
      contacts {
        total
      }
      replies {
        total
      }
      booked {
        total
      }
      messages {
        total
      }
    }
  }
`;
