import { useMutation } from '@apollo/client';
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Badge,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from 'reactstrap';
// import ConfirmPopUp from '../../../../components/ConfirmPopUp';
import {
  Sidedrawer,
  DrawerHeader,
} from '../../../../components/layout/Sidedrawer';
import { AppContext } from '../../../../context';
import { createMarkup, formatDateUS } from '../../../../utils/commonFn';
import { offerStatusType, VIEW_PLACEMENT_MANAGEMENT_ROUTE } from '../../../../utils/constant';
import CreateEditOffer from '../../../candidate-pipeline/offers/CreateEditOffer';
import { UPDATE_OFFER_STATUS } from '../../../candidate-pipeline/offers/gql';
import OfferNote from '../../../candidate-pipeline/offers/OfferNote';
import OfferRejectNotes from '../../../candidate-pipeline/offers/RejectNotes';
import ReplacementModal from '../../candidate-components/JobApplicationStatus/ReplacementModal';
import JobOrderDetailWidgetForDrawer from '../../candidate-components/JobOrderDetailWidgetForDrawer';

const OfferViewDrawer = ({
  toggle,
  isOpen,
  applicantData,
  refetch,
  setCandidateFilter,
}) => {
  const history = useHistory();
  const { user, userRoles } = useContext(AppContext);
  const [editOfferToggle, setEditOfferToggle] = useState(false);
  const [OfferPermissions, setOfferPermissions] = useState(false);
  const [latestOffer, setLatestOffer] = useState<any>();
  const [statusOffer, setStatusOffer] = useState<string>('');
  const [rejectNoteModal, setRejectNoteModal] = useState<boolean>(false);
  const [noteDrawer, setNoteDrawer] = useState<boolean>(false);
  // const [placementMoveModal, setPlacementMoveModal] = useState<boolean>(false);
  const [updateOfferStatusMutation] = useMutation(UPDATE_OFFER_STATUS);
  const [replacementModalToggle, setReplacementModalToggle] = useState<boolean>(false);
  const [placementId, setPlacementId] = useState<string>('');

  const checkLatestOffer = () => {
    let latestOffer = applicantData?.offers?.find((item) => !item?.locked);
    setLatestOffer(latestOffer);
  };

  const adminExecCompanyOwnerAndMovedToOffered = () => {
    let localPermit: boolean = false;
    if (userRoles?.includes('ADMIN') || userRoles?.includes('EXECUTIVE')) {
      const movedToPlacement = applicantData?.offers?.find(
        (item: { offerStatus: string }) =>
          item.offerStatus === 'MOVE_TO_PLACEMENT'
      );
      if (movedToPlacement) {
        localPermit = true;
      } else {
        localPermit = false;
      }
    } else {
      // todo need to clean the query for company owner
      const userIsOwner =
        applicantData?.JobOrder?.company?.ownerAndSharees?.map(
          (item) => item?.owner?.id
        );

      if (userIsOwner?.includes(user?.id)) {
        const movedToPlacement = applicantData?.offers?.find(
          (item: { offerStatus: string }) =>
            item.offerStatus === 'MOVE_TO_PLACEMENT'
        );

        if (movedToPlacement) {
          localPermit = true;
        } else {
          localPermit = false;
        }
      }
    }

    setOfferPermissions(localPermit);
  };

  const updateOfferCallback = async (data?: {
    offers?: string;
    id?: string;
    replacementId?: string;
  }, isReplacement: boolean = true) => {
    const response = await updateOfferStatusMutation({
      variables: {
        updatingOfferStatusInput: {
          id: latestOffer?.id,
          jobApplicantId: applicantData?.id,
          status: 'MOVE_TO_PLACEMENT',
          isReplacement,
        },
      },
    });

    if (response?.data) {
      const updatingOfferStatus = response?.data?.updatingOfferStatus;
      // status 202 for replacement workflow (ATS-1011)
      if (updatingOfferStatus?.status === 202) {
        setReplacementModalToggle(!replacementModalToggle);
        setPlacementId(updatingOfferStatus?.placementId);
      } else if (updatingOfferStatus?.status === 400) {
        toast.error('Replacement Days Must be a Number!');
      } else {
        toast.success('Offer status updated successfully');
        // supposing we are receiving an id of placement and view that placement
        if (updatingOfferStatus?.placementId) {
          history.push({
            pathname: `${VIEW_PLACEMENT_MANAGEMENT_ROUTE}/${updatingOfferStatus?.placementId}`,
          });
        }
      }
    }
  };

  const handleAction = (value: string) => {
    switch (value) {
      case 'CANDIDATE_DECLINED':
      case 'CLIENT_DECLINED':
        setStatusOffer(value);
        setRejectNoteModal(true);
        break;
      case 'MOVE_TO_PLACEMENT':
        updateOfferCallback();
        // setPlacementMoveModal(!placementMoveModal);
        break;
      case 'ADD_NOTE':
        setNoteDrawer(!noteDrawer);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (applicantData) {
      checkLatestOffer();
      adminExecCompanyOwnerAndMovedToOffered();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicantData]);

  return (
    <>
      <Sidedrawer toggle={toggle} isOpen={isOpen}>
        <div className="mb-3">
          <DrawerHeader
            prevClick={() => toggle()}
            nextClick={() => {
              setEditOfferToggle(!editOfferToggle);
            }}
            buttonText="Edit"
            heading="Offer Detail"
            disabled={
              OfferPermissions ||
              latestOffer?.locked ||
              latestOffer?.offerStatus === 'MOVE_TO_PLACEMENT'
            }
          >
            {!OfferPermissions && (
              <div className="actionsDropdown actionsDropdownSpacing dropdown">
                <UncontrolledDropdown className="customMenuDropdown">
                  <DropdownToggle caret style={{ minHeight: '40px' }}>
                    Actions
                  </DropdownToggle>

                  <DropdownMenu right>
                    {offerStatusType?.map((item, index) => (
                      <DropdownItem
                        className="bg-transparent border-0 color-default btn-sm"
                        key={index}
                        onClick={() => handleAction(item?.value)}
                      >
                        {item?.label}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            )}
          </DrawerHeader>
        </div>

        <JobOrderDetailWidgetForDrawer applicantData={applicantData} />

        {/* step start from here */}
        {applicantData?.offers?.map((item, index) => (
          <div className="pb-4" key={index}>
            <div className="pb-3">
              <strong>Offer {item?.locked && <Badge>Locked</Badge>}</strong>
            </div>

            <Row className="mb-2">
              <Col xs={4}>
                <h6 className="text-14 text-blackele">Salary Type</h6>
              </Col>

              <Col xs={8}>
                <h5 className="font-weight-normal text-14 text-blackten">
                  {item?.offeredSalaryType || '--'}
                </h5>
              </Col>
            </Row>

            <Row className="mb-2">
              <Col xs={4}>
                <h6 className="text-14 text-blackele">Salary</h6>
              </Col>

              <Col xs={8}>
                <h5 className="font-weight-normal text-14 text-blackten">
                  {item?.offeredSalary || '--'}
                </h5>
              </Col>
            </Row>

            <Row className="mb-2">
              <Col xs={4}>
                <h6 className="text-14 text-blackele">Hire Type</h6>
              </Col>

              <Col xs={8}>
                <h5 className="font-weight-normal text-14 text-blackten">
                  {item?.hireType || '--'}
                </h5>
              </Col>
            </Row>

            <Row className="mb-2">
              <Col xs={4}>
                <h6 className="text-14 text-blackele">Start Date Time</h6>
              </Col>

              <Col xs={8}>
                <h5 className="font-weight-normal text-14 text-blackten">
                  {item?.offerStartDateTime
                    ? `${formatDateUS(item?.offerStartDateTime)} ${new Date(
                      item?.offerStartDateTime
                    ).toLocaleTimeString()}`
                    : '--'}
                </h5>
              </Col>
            </Row>

            <Row className="mb-2">
              <Col xs={4}>
                <h6 className="text-14 text-blackele">Details</h6>
              </Col>

              <Col xs={8}>
                <div
                  className="font-weight-normal text-14 text-blackten"
                  dangerouslySetInnerHTML={createMarkup(item?.details)}
                />
              </Col>
            </Row>
          </div>
        ))}
      </Sidedrawer>

      {editOfferToggle && (
        <CreateEditOffer
          applicantData={applicantData}
          setCandidateFilter={setCandidateFilter}
          counterOffer={false}
          fromUpdate={true}
          latestOffer={latestOffer}
          interviewId={null}
          setOfferToggle={() => {
            setEditOfferToggle(!editOfferToggle);
            toggle();
          }}
          offerToggle={editOfferToggle}
          refetch={refetch}
          parentToggle={() => { }}
        />
      )}

      {/* this pop up is used for the replacement confirmation modal*/}
      {replacementModalToggle && (
        <ReplacementModal
          jobApplicants={false}
          isOpen={replacementModalToggle}
          toggle={() => setReplacementModalToggle(!replacementModalToggle)}
          placementId={placementId}
          updateOfferToPlacement={updateOfferCallback}
        />
      )}

      {/* <ConfirmPopUp
        confirmText="Are you sure you want to make a placement?"
        isOpen={placementMoveModal}
        toggle={() => {
          setPlacementMoveModal(!placementMoveModal);
        }}
        confirmAction={updateOfferCallback}
        modalHeading="Move to Placement"
        btnText="Yes, Move"
        btnColor="primary"
        className="for-modal-styling"
      /> */}

      {statusOffer === 'CANDIDATE_DECLINED' ||
        statusOffer === 'CLIENT_DECLINED' ? (
        <OfferRejectNotes
          modal={rejectNoteModal}
          toggle={() => {
            setRejectNoteModal(!rejectNoteModal);
          }}
          applicantData={applicantData}
          selectedStatus={statusOffer}
          latestOffer={latestOffer}
          refetch={refetch}
        />
      ) : null}

      <OfferNote
        modal={noteDrawer}
        toggle={() => setNoteDrawer(!noteDrawer)}
        offerId={latestOffer?.id}
      />
    </>
  );
};

export default OfferViewDrawer;
