import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import Select from "react-select";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import CustomButton from "../../components/layout/CustomButton";
import { CANDIDATES_LISTING } from "../candidate/gql";
import {
  FETCH_CONTACTS_AGAINST_COMPANY,
  GET_ALL_JOB_ORDERS_AGAINST_COMPANY,
  GET_COMPANIES_BY_NAME_LOCATION,
  GET_USERS_BY_NAME_EMAIL,
} from "./gql";

export default function NoteSearchModal({
  modal,
  toggle,
  currentUnderSelect,
  companies,
  companyLoading,
  pingChildForJobOrders,
  pingChildForContacts,
  pingChildForCandidate,
  pingChildForRecruiter,
  pingChildForCompany,
}) {
  const [
    jobOrdersAgainstCompanyQuery,
    { data: jobOrdersAgainstCompany, loading: jobOrdersAgainstCompanyLoading },
  ] = useLazyQuery(GET_ALL_JOB_ORDERS_AGAINST_COMPANY);

  const [
    contactsAgainstCompanyQuery,
    { data: contactsAgainstCompany, loading: contactsAgainstCompanyLoading },
  ] = useLazyQuery(FETCH_CONTACTS_AGAINST_COMPANY);

  const [searchingCandidateByQuery, { data: searchingCandidate }] =
    useLazyQuery(CANDIDATES_LISTING);

  const [searchingUserByQuery, { data: searchingUser }] = useLazyQuery(
    GET_USERS_BY_NAME_EMAIL
  );

  const [searchingCompanyByQuery, { data: searchingCompany }] = useLazyQuery(
    GET_COMPANIES_BY_NAME_LOCATION
  );

  const [selectedCompany, setSelectedCompany] = useState<any>(null);
  const [selectedJob, setSelectedJob] = useState<any>(null);
  const [selectedContact, setSelectedContact] = useState<any>(null);
  // const [candidateFound, setCandidateFound] = useState<boolean>(false);
  const [candidateName, setCandidateName] = useState<string>("");
  const [candidateEmail, setCandidateEmail] = useState<string>("");
  const [recruiterName, setRecruiterName] = useState<string>("");
  const [recruiterEmail, setRecruiterEmail] = useState<string>("");
  const [companyName, setCompanyName] = useState<string>("");
  const [companyLocationName] = useState<string>("");
  // const [selectedCandidate, setSelectedCandidate] = useState<any>(null);

  useEffect(() => {
    if (currentUnderSelect === "jobs") {
      jobOrdersAgainstCompanyQuery({
        variables: { companyId: selectedCompany?.value, page: 1, limit: 10 },
      });
    } else if (currentUnderSelect === "contacts") {
      contactsAgainstCompanyQuery({
        variables: {
          companyId: selectedCompany?.value,
          limit: 10,
          page: 1,
          searchText: "",
          statusSearch: "",
        },
      });
    }
    // eslint-disable-next-line
  }, [selectedCompany]);

  const searchJobsAgainstCompany = () => {
    pingChildForJobOrders(selectedJob);
    toggle();
  };

  const searchContactAgainstCompany = () => {
    pingChildForContacts(selectedContact);
    toggle();
  };

  const searchingCandidateByQueryCriteria = (name: string, email: string) => {
    searchingCandidateByQuery({
      variables: {
        limit: 10,
        page: 1,
        searchText: name || email,
      },
    });
  };

  const searchingUserByQueryCriteria = (name: string, email: string) => {
    searchingUserByQuery({
      variables: {
        limit: 10,
        page: 1,
        searchText: name || email,
      },
    });
  };

  const searchingCompanyByQueryCriteria = (name: string, email: string) => {
    searchingCompanyByQuery({
      variables: {
        limit: 10,
        page: 1,
        searchText: name || email,
      },
    });
  };

  useEffect(() => {
    if (candidateName?.length > 2 || candidateEmail?.length > 2) {
      searchingCandidateByQueryCriteria(candidateName, candidateEmail);
    }
    // eslint-disable-next-line
  }, [candidateName, candidateEmail]);

  useEffect(() => {
    if (recruiterName?.length > 2 || recruiterEmail?.length > 2) {
      searchingUserByQueryCriteria(recruiterName, recruiterEmail);
    }
    // eslint-disable-next-line
  }, [recruiterName, recruiterEmail]);

  useEffect(() => {
    if (companyName?.length > 2 || companyLocationName?.length > 2) {
      searchingCompanyByQueryCriteria(companyName, companyLocationName);
    }
    // eslint-disable-next-line
  }, [companyName, companyLocationName]);

  const onError = () => {
    alert("ERROR:::");
  };

  const searchCandidate = () => {
    const selectedCandidate = searchingCandidate?.allCandidates?.candidates[0];
    pingChildForCandidate({
      label: selectedCandidate?.fullName,
      value: selectedCandidate?.id,
    });
    toggle();
  };

  const searchUser = () => {
    const selectedRecruiter = searchingUser?.findUserByNameEmail?.users[0];
    pingChildForRecruiter({
      label: selectedRecruiter?.fullName,
      value: selectedRecruiter?.id,
    });
    toggle();
  };

  const searchCompany = () => {
    const selectedCompany =
      searchingCompany?.findCompaniesByNameLocation?.companies[0];

    const companyAddress = selectedCompany?.address?.find(
      (address: any) => address.isPrimary === true
    );

    const label = `${selectedCompany?.name}-${companyAddress?.city},${companyAddress?.state}`;
    if (selectedCompany && label) {
      pingChildForCompany({
        label: label,
        value: selectedCompany?.id,
      });
      toggle();
    }
  };

  const onClearCandidateHandler = () => {
    setCandidateName("");
    setCandidateEmail("");
    searchingCandidateByQuery({
      variables: {
        limit: 0,
        page: 1,
      },
    });
  };

  const onClearUserHandler = () => {
    setRecruiterName("");
    setRecruiterEmail("");
    searchingUserByQuery({
      variables: {
        limit: 0,
        page: 1,
      },
    });
  };

  const onClearCompanyHandler = () => {
    setCompanyName("");
    searchingCompanyByQuery({
      variables: {
        limit: 0,
        page: 1,
      },
    });
  };

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle} scrollable className="for-modal-styling">
        <ModalHeader toggle={toggle}>Select {currentUnderSelect}</ModalHeader>
        <Form>
          <ModalBody>
            {currentUnderSelect === "jobs" ? (
              <>
                <FormGroup>
                  <Label>Companies</Label>
                  <Select
                    isLoading={companyLoading}
                    value={selectedCompany}
                    onChange={(selectedOption: any) => {
                      setSelectedCompany(selectedOption);
                    }}
                    options={companies?.getAllCompanies?.companies?.map(
                      (item: any) => {
                        const address = `${
                          item.address.find(
                            (item: any) => item?.isPrimary === true
                          )?.city
                        },${
                          item.address.find(
                            (item: any) => item?.isPrimary === true
                          )?.state
                        }`;
                        return {
                          label: `${item?.name}-${address}`,
                          value: item.id,
                        };
                      }
                    )}
                  />
                </FormGroup>

                {jobOrdersAgainstCompany?.fetchAllJobOrders?.jobOrders
                  ?.length ? (
                  <FormGroup>
                    <Label>Jobs against {selectedCompany?.label}</Label>
                    <Select
                      isLoading={jobOrdersAgainstCompanyLoading}
                      value={selectedJob}
                      onChange={(selectedOption: any) => {
                        setSelectedJob(selectedOption);
                      }}
                      options={jobOrdersAgainstCompany?.fetchAllJobOrders?.jobOrders?.map(
                        (item: any) => {
                          return {
                            label: `${item?.jobTitle}-${item?.company?.name}`,
                            value: item.id,
                          };
                        }
                      )}
                    />
                  </FormGroup>
                ) : null}
              </>
            ) : currentUnderSelect === "contacts" ? (
              <>
                <FormGroup>
                  <Label>Companies</Label>
                  <Select
                    isLoading={companyLoading}
                    value={selectedCompany}
                    onChange={(selectedOption: any) => {
                      setSelectedCompany(selectedOption);
                    }}
                    options={companies?.getAllCompanies?.companies?.map(
                      (item: any) => {
                        const address = `${
                          item.address.find(
                            (item: any) => item?.isPrimary === true
                          )?.city
                        }/${
                          item.address.find(
                            (item: any) => item?.isPrimary === true
                          )?.state
                        }`;
                        return {
                          label: `${item?.name}-${address}`,
                          value: item.id,
                        };
                      }
                    )}
                  />
                </FormGroup>

                {contactsAgainstCompany?.allContacts?.contacts?.length && (
                  <FormGroup>
                    <Label>Contacts against {selectedCompany?.label}</Label>
                    <Select
                      isLoading={contactsAgainstCompanyLoading}
                      value={selectedContact}
                      onChange={(selectedOption: any) => {
                        setSelectedContact(selectedOption);
                      }}
                      options={contactsAgainstCompany?.allContacts?.contacts?.map(
                        (item: any) => {
                          return {
                            label: `${item?.fullName}-${item?.company?.name}`,
                            value: item.id,
                          };
                        }
                      )}
                    />
                  </FormGroup>
                )}
              </>
            ) : currentUnderSelect === "candidates" ? (
              <>
                <FormGroup>
                  <Row>
                    <Col lg="12">
                      <Label>Candidate Name</Label>
                      <Input
                        type="text"
                        placeholder="Candidate Name"
                        value={candidateName}
                        onChange={(e) => {
                          setCandidateName(e.target.value);
                        }}
                        className="mb-2"
                      />
                    </Col>

                    <Col lg="12">
                      <Label className="mt-3">Candidate Email</Label>
                      <Input
                        type="text"
                        placeholder="Candidate Email"
                        value={candidateEmail}
                        onChange={(e) => {
                          setCandidateEmail(e.target.value);
                        }}
                        className="mb-2"
                      />
                    </Col>

                    {searchingCandidate?.allCandidates?.candidates?.length ? (
                      <span className="ml-3 text-success mt-2 w-100">
                        {searchingCandidate?.allCandidates?.candidates?.length}{" "}
                        candidates found by{" "}
                        {candidateName?.length > 2 && "name:"}{" "}
                        {searchingCandidate &&
                          candidateName?.length > 2 &&
                          candidateName}{" "}
                        {candidateEmail?.length > 2 &&
                          `${
                            candidateName?.length > 2 ? "and" : ""
                          } email:`}{" "}
                        {(searchingCandidate &&
                          candidateEmail?.length > 2 &&
                          candidateEmail) ||
                          ""}
                      </span>
                    ) : searchingCandidate &&
                      (candidateName?.length > 2 ||
                        candidateEmail?.length > 2) ? (
                      <span className="ml-3 text-danger">
                        Candidate not found by name or email
                      </span>
                    ) : null}

                    <Col lg="12">
                      <Button
                        className="float-right bg-transparent border-0 for-link-theme-color m-0 p-0"
                        onClick={onClearCandidateHandler}
                      >
                        Clear
                      </Button>
                    </Col>
                  </Row>
                </FormGroup>
              </>
            ) : currentUnderSelect === "recruiters" ? (
              <>
                <FormGroup>
                  <Row>
                    <Col lg="12">
                      <Label>Recruiter Name</Label>
                      <Input
                        type="text"
                        placeholder="Recruiter Name"
                        value={recruiterName}
                        onChange={(e) => {
                          setRecruiterName(e.target.value);
                        }}
                        className="mb-2"
                      />
                    </Col>

                    <Col lg="12">
                      <Label className="mt-3">Recruiter Email</Label>
                      <Input
                        type="text"
                        placeholder="Recruiter Email"
                        value={recruiterEmail}
                        onChange={(e) => {
                          setRecruiterEmail(e.target.value);
                        }}
                        className="mb-2"
                      />
                    </Col>

                    {searchingUser?.findUserByNameEmail?.users?.length ? (
                      <span className="ml-3 text-success mt-2 w-100">
                        {searchingUser?.findUserByNameEmail?.users?.length}{" "}
                        recruiters found by{" "}
                        {recruiterName?.length > 2 && "name:"}{" "}
                        {searchingUser &&
                          recruiterName?.length > 2 &&
                          recruiterName}{" "}
                        {recruiterEmail?.length > 2 &&
                          `${
                            recruiterName?.length > 2 ? "and" : ""
                          } email:`}{" "}
                        {(searchingUser &&
                          recruiterEmail?.length > 2 &&
                          recruiterEmail) ||
                          ""}
                      </span>
                    ) : searchingUser &&
                      (recruiterName?.length > 2 ||
                        recruiterEmail?.length > 2) ? (
                      <span className="ml-3 text-danger">
                        Recruiter not found by name or email
                      </span>
                    ) : null}

                    <Col lg="12">
                      <Button
                        className="float-right bg-transparent border-0 for-link-theme-color m-0 p-0"
                        onClick={onClearUserHandler}
                      >
                        Clear
                      </Button>
                    </Col>
                  </Row>
                </FormGroup>
              </>
            ) : currentUnderSelect === "companies" ? (
              <>
                <FormGroup>
                  <Row>
                    <Col lg="12">
                      <Label>Company Name</Label>
                      <Input
                        type="text"
                        placeholder="Company Name"
                        value={companyName}
                        onChange={(e) => {
                          setCompanyName(e.target.value);
                        }}
                        className="mb-2"
                      />
                    </Col>

                    {/* <Col lg="12">
                      <Label className="mt-3">Company Location</Label>
                      <Input
                        type="text"
                        placeholder="Company Location"
                        value={companyLocationName}
                        onChange={(e) => {
                          setCompanyLocationName(e.target.value);
                        }}
                        className="mb-2"
                      />
                    </Col> */}

                    {searchingCompany?.findCompaniesByNameLocation?.companies
                      .length && companyName?.length > 2 ? (
                      <span className="ml-3 text-success mt-2 w-100">
                        {
                          searchingCompany?.findCompaniesByNameLocation
                            ?.companies?.length
                        }{" "}
                        companies found by {companyName?.length > 2 && "name:"}{" "}
                        {searchingCompany &&
                          companyName?.length > 2 &&
                          companyName}{" "}
                      </span>
                    ) : searchingCompany && companyName?.length > 2 ? (
                      <span className="ml-3 text-danger">
                        Company not found by name
                      </span>
                    ) : null}

                    <Col lg="12">
                      <Button
                        className="float-right bg-transparent border-0 for-link-theme-color m-0 p-0"
                        onClick={onClearCompanyHandler}
                      >
                        Clear
                      </Button>
                    </Col>
                  </Row>
                </FormGroup>
              </>
            ) : null}
          </ModalBody>
          <ModalFooter>
            <CustomButton
              buttonText="Close"
              buttonColor="default"
              buttonType="button"
              buttonClick={toggle}
            />
            <CustomButton
              buttonText={`Note Search`}
              buttonType="button"
              buttonColor="primary"
              className="save-changes"
              styling={{ height: 35 }}
              buttonClick={
                currentUnderSelect === "jobs"
                  ? searchJobsAgainstCompany
                  : currentUnderSelect === "contacts"
                  ? searchContactAgainstCompany
                  : currentUnderSelect === "candidates"
                  ? searchCandidate
                  : currentUnderSelect === "recruiters"
                  ? searchUser
                  : currentUnderSelect === "companies"
                  ? searchCompany
                  : onError
              }
            />
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
}
