import { FC } from "react";

type LOADER = {
  errorText?: string;
  loaderClass?: string;
};

const SmallLoader: FC<LOADER> = ({ errorText, loaderClass }) => {
  return (
    <div
      className={loaderClass ? `${loaderClass} small-error-loading` : "small-error-loading"}
    >
      {errorText ? errorText : <div className="small-loader"></div>}
    </div>
  );
};
export default SmallLoader;
