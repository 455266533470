/* eslint-disable no-useless-concat */
import { useHistory } from "react-router";
import { useLocation, useParams } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { ParamsType } from "../../interfaces";
import { CANDIDATE_SUBMISSION_ROUTE, VIEW_CANDIDATE_ROUTE } from "../../utils/constant";
import { countryWiseHeaderLocation, pushInHistory } from "../../utils/helper";
import { IComingState } from "../agreements/interfaces";

const SubmissionWidget = ({ stats }) => {
  const history = useHistory();
  const location = useLocation<IComingState>();
  const { id } = useParams<ParamsType>()
  
  return (
    <Row>
      <Col xl="4" lg="6" md="6" sm="12" xs="12">
        <div className="d-flex justify-content-between align-items-center mb-2 contentGridLabel">
          <label>Candidate Name</label>

          <div className="contentGridColumn">
            <h6
              onClick={() => {
                history?.push({ 
                  pathname: `${VIEW_CANDIDATE_ROUTE}/${stats?.id}`,
                  state: {
                    backUrl: `${CANDIDATE_SUBMISSION_ROUTE}/${id}`,
                    searchParameters: location.search
                  }
                });

                pushInHistory(`${CANDIDATE_SUBMISSION_ROUTE}/${id}`, location.search)
              }}
              className="p-0 route-link"
            >
              {stats?.fullName || "--"}
            </h6>
          </div>
        </div>
      </Col>

      <Col xl="4" lg="6" md="6" sm="12" xs="12">
        <div className="d-flex justify-content-between align-items-center mb-2 contentGridLabel">
          <label>Candidate Email</label>
          <div className="contentGridColumn emailLengthWidth">
            <h6 className="textTruncateWidth textTruncateWidthEllipsis">
              {stats?.emails?.find((item) => item?.isPrimary)?.email || "--"}
            </h6>
          </div>
        </div>
      </Col>

      <Col xl="4" lg="6" md="6" sm="12" xs="12">
        <div className="d-flex justify-content-between align-items-center mb-2 contentGridLabel">
          <label>Current Company</label>
          <div className="contentGridColumn emailLengthWidth">
            <h6 className="textTruncateWidth textTruncateWidthEllipsis">
              {stats?.companyName || "--"}
            </h6>
          </div>
        </div>
      </Col>

      <Col xl="4" lg="6" md="6" sm="12" xs="12">
        <div className="d-flex justify-content-between align-items-center mb-2 contentGridLabel">
          <label>Job Title</label>
          <div className="contentGridColumn emailLengthWidth">
            <h6 className="textTruncateWidth textTruncateWidthEllipsis">
              {stats?.jobTitle || "--"}
            </h6>
          </div>
        </div>
      </Col>

      <Col xl="4" lg="6" md="6" sm="12" xs="12">
        <div className="d-flex justify-content-between align-items-center mb-2 contentGridLabel">
          <label>Candidate Owner</label>
          <div className="contentGridColumn">
            <h6>{stats?.recruiterOwner?.fullName || "--"}</h6>
          </div>
        </div>
      </Col>
      {console.log("stats",stats)}
      
      <Col xl="4" lg="6" md="6" sm="12" xs="12">
        <div className="d-flex justify-content-between align-items-center mb-2 contentGridLabel">
          <label>Location</label>
          <div className="contentGridColumn">
            <h6>
              {
                countryWiseHeaderLocation(stats?.countryCode,stats?.city,stats?.stateCode)
              }
            </h6>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default SubmissionWidget;
