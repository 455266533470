import { gql } from "@apollo/client";

export const SEQUENCE_STEP_STATS = gql`
  query SequenceStepsStats($compaignId: String!) {
    sequenceStepsStats(compaignId: $compaignId) {
      stats {
        value {
          manual_unsent
          sent
          viewed
          replied
          visited
          total
        }
        key
      }
    }
  }
`;

export const CONTACT_PERSONALIZED_STEPS = gql`
  query ContactPersonalizedSteps($compaignId: String!, $contactId: String!) {
    contactPersonalizedSteps(compaignId: $compaignId, contactId: $contactId) {
      step
      _step
      fulfilled
      scheduled_for
      personalizedStepDetails {
        type
        sent
        body
        subject
        _id
      }
    }
  }
`;

export const SEQUENCE_STEP_UPDATE = gql`
  mutation SequenceStepUpdate(
    $compaignId: String!
    $subject: String
    $body: String
    $type: String
    $_id: String
    $wait_days: Int
  ) {
    sequenceStepUpdate(
      compaignId: $compaignId
      sequenceStepUpdateInput: {
        subject: $subject
        body: $body
        type: $type
        _id: $_id
        wait_days: $wait_days
      }
    ) {
      message
      status
    }
  }
`;

export const SEQUENCE_STEP_UPDATE_TEST = gql`
  mutation SequenceStepUpdateAbTest(
    $abTest: Boolean
    $compaignId: String!
    $subject: String
    $body: String
    $_id: String
    $_step: String
  ) {
    sequenceStepUpdateAbTest(
      compaignId: $compaignId
      sequenceStepUpdateABTestInput: {
        subject: $subject
        body: $body
        _id: $_id
        _step: $_step
        abTest: $abTest
      }
    ) {
      message
      status
    }
  }
`;
export const REMOVE_PERSONALIZED_STEP = gql`
  mutation RemovePersonalizedStep($contactId: String!, $stepIndex: Float!) {
    removePersonalizedStep(contactId: $contactId, stepIndex: $stepIndex) {
      message
      status
    }
  }
`;

export const SEQUENCE_STEP_CONTACT_PERSONALIZED = gql`
  mutation SequenceStepContactPersonalized(
    $contactId: String!
    $subject: String
    $markdown: String
    $stepIndex: Int
    $immediate: Boolean
  ) {
    sequenceStepContactPersonalized(
      contactId: $contactId
      sequenceStepContactPersonalizedInput: {
        subject: $subject
        markdown: $markdown
        stepIndex: $stepIndex
        immediate: $immediate
      }
    ) {
      message
      status
    }
  }
`;

export const SEND_EMAIL_CONTACT_STEP = gql`
  mutation SendEmailContactStep(
    $contactId: String!
    $email: String
    $step: Float
    $stepId: String
  ) {
    sendEmailContactStep(
      stepEmailContactPreviewInput: {
        contactId: $contactId
        email: $email
        stepId: $stepId
        step: $step
      }
    ) {
      message
      status
    }
  }
`;

export const SEND_EMAIL_TESTING_STEP = gql`
  mutation SendEmailTestingStep(
    $compaignId: String!
    $email: String
    $step: Float
    $stepId: String
  ) {
    sendEmailTestingStep(
      stepEmailTestingPreviewInput: {
        compaignId: $compaignId
        email: $email
        stepId: $stepId
        step: $step
      }
    ) {
      message
      status
    }
  }
`;

export const SEQUENCE_STEP_DELETE = gql`
  mutation SequenceStepDelete($compaignId: String!, $_id: String!) {
    sequenceStepDelete(compaignId: $compaignId, _id: $_id) {
      message
      status
    }
  }
`;

export const SEQUENCE_OTHER_STEP_DELETE = gql`
  mutation SequenceOtherStepDelete($compaignId: String!, $_id: String!) {
    sequenceOtherStepDelete(compaignId: $compaignId, _id: $_id) {
      message
      status
    }
  }
`;

export const SEQUENCE_STEP_WAIT_DAYS_EDIT = gql`
  mutation SequenceStepWaitDaysEdit(
    $compaignId: String!
    $_id: String!
    $wait_days: Float!
  ) {
    sequenceStepWaitDaysEdit(
      compaignId: $compaignId
      _id: $_id
      wait_days: $wait_days
    ) {
      message
      status
    }
  }
`;


export const GET_SIGNATURE = gql`
query getSignature(
    $compaignId: String!
  ) {
    getSignature(
      compaignId: $compaignId
    ) {
      signature_html
      signature
    }
  }
`;