import { Form, Spinner } from "reactstrap";
import NoDataFound from "../../components/layout/NoDataFound";

const EmailLookup = ({ emails, setSelectedEmail }) => {
  return (
    <>
      <div className={"multiselect-company-add w-100 toggle mt-2"}>
        {!emails ? (
          <div className="spinner-center">
            <Spinner size="sm" style={{ color: "#eaa827" }} className="mr-2" />
          </div>
        ) : (
          <Form>
            {emails &&
              emails.map((item: any, index: number) => (
                <div
                  onClick={() => {
                    setSelectedEmail(item.email);
                  }}
                  key={index}
                  style={{ cursor: "pointer" }}
                >
                  <div style={{ lineHeight: "1" }} className="ml-4 my-2">
                    <span className="d-block">{item.fullName}</span>
                    <small className="text-primary">{item?.email}</small>
                  </div>
                </div>
              ))}
          </Form>
        )}
        {!emails || (!emails?.length && <NoDataFound text="No Email Found!" />)}
      </div>
    </>
  );
};

export default EmailLookup;
