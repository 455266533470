import { useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { Input, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import CustomButton from "../../../../components/layout/CustomButton";
import InputField from "../../../../components/layout/InputField";

const SearchModal = ({ modal, toggle, jobId }) => {
  const { errors, handleSubmit, register, reset } = useForm();
  const history = useHistory();
  const [showFilterFields, setShowFilterFields] = useState<boolean>(false);
  const [advanceSearchField, setAdvanceSearchField] = useState<string>("");

  const findSearchResults = (values: any) => {
    alert(JSON.stringify(values));
    reset();
    toggle();
    history.push(`/advance-search-job-candidate/${jobId}/results`);
  };

  return (
    <Modal isOpen={modal} className="for-modal-styling" toggle={toggle} scrollable>
      <ModalHeader>Advance Search</ModalHeader>
      <ModalBody>
        <>
          <div>
            <Input
              inputtype="text"
              isRequired={false}
              value={advanceSearchField}
              onChange={(e) => setAdvanceSearchField(e?.target?.value)}
              placeholder="Advance Searching"
            />

            <CustomButton
              buttonText="Add Filters"
              buttonColor="secondary"
              buttonType="submit"
              className="big-width mb-3 mt-3"
              disabled={advanceSearchField ? true : false}
              buttonClick={() => setShowFilterFields(!showFilterFields)}
            />
          </div>

          {showFilterFields && (
            <div>
              <InputField
                inputtype="text"
                isRequired={true}
                label="Title"
                inputid="title"
                placeholder="Job title"
                inputRef={register({
                  required: {
                    value: true,
                    message: "Title is required!",
                  },
                })}
                errMsg={errors?.title?.message}
              />
              <InputField
                inputtype="text"
                isRequired={true}
                label="Skills"
                inputid="skills"
                placeholder="Skills"
                inputRef={register({
                  required: {
                    value: true,
                    message: "Skills are required!",
                  },
                })}
                errMsg={errors?.skills?.message}
              />
            </div>
          )}
        </>
      </ModalBody>
      <ModalFooter className="mt-3">
        <CustomButton
          buttonText="Close"
          buttonColor="default"
          buttonType="button"
          buttonClick={toggle}
        />
        <CustomButton
          buttonText="Apply"
          buttonColor="primary"
          buttonType="submit"
          className="big-width"
          disabled={advanceSearchField ? true : false}
          buttonClick={handleSubmit(findSearchResults)}
        />
      </ModalFooter>
    </Modal>
  );
};

export default SearchModal;
