import {
  Card,
  CardBody,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import CustomButton from "../../../components/layout/CustomButton";
import { formatDateUS } from "../../../utils/commonFn";

const EmailNoteView = ({ modalData, toggle, modal }) => {
  return (
    <Modal isOpen={modal} toggle={toggle} scrollable className="for-modal-styling">
      <ModalHeader toggle={toggle}>Contact Email Notes View</ModalHeader>
      <ModalBody>
        <Card className="border-0">
          <CardBody className="py-0 px-0">
            <>
              <div className="candidate-notes-wrapper">
                <div className="candidate-notes">
                  <small className="text-muted d-block">
                    <strong>From Contacts:</strong>{" "}
                    {modalData?.fromAddress || "--"}
                  </small>

                  <small className="text-muted d-block">
                    <strong>To Contacts:</strong>{" "}
                    {modalData?.toAddress?.join(",") || "--"}
                  </small>

                  <small className="text-muted d-block">
                    <strong>Cc Contacts:</strong>{" "}
                    {modalData?.ccAddress?.join(",") || "--"}
                  </small>

                  <small className="text-muted d-block">
                    <strong>BCc Contacts:</strong>{" "}
                    {modalData?.bccAddress?.join(",") || "--"}
                  </small>

                  <small className="text-muted d-block">
                    <strong>Subject:</strong> {modalData?.subject || "--"}
                  </small>

                  <small className="text-muted d-block">
                    <strong>Category:</strong> {modalData?.categories || "--"}
                  </small>

                  <small className="text-muted d-block">
                    <strong>Tagged User: </strong>
                    {modalData?.taggedUser?.fullName || "--"}
                  </small>

                  <small className="text-muted d-block">
                    <strong>Body: </strong> {modalData?.body || "--"}
                  </small>

                  <small className="text-muted d-block">
                    <strong>Sent At: </strong>
                    <span>
                      {modalData?.sentDateTime
                        ? formatDateUS(modalData?.sentDateTime)
                        : "--"}{" "}
                      {modalData?.sentDateTime &&
                        new Date(
                          modalData.sentDateTime
                        ).toLocaleTimeString()}{" "}
                    </span>
                  </small>
                </div>
              </div>
            </>
          </CardBody>
        </Card>
      </ModalBody>
      <ModalFooter>
        <CustomButton
          buttonText="Close"
          buttonColor="default"
          buttonType="button"
          buttonClick={toggle}
        />{" "}
      </ModalFooter>
    </Modal>
  );
};

export default EmailNoteView;