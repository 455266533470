import { FC } from "react"
import { Table } from "reactstrap"
import { BILLING_SUMMARY, EXPECTATION_TARGETS } from "../../constants"
import { TableGoalSheetProps } from "../../interfaces"
import { amountPrettifier } from "../../utils/helper"

const TableGoalSheet: FC<TableGoalSheetProps> = ({
  headerText, TopHeaders, highlighter, HeadersWithRows, periodEndDate, minExpectation, ExpectationTargets
}) => {

  return (
    <div className="mt-5">
      <div className="d-flex align-items-center">
        {!minExpectation && <p className="table-label">{headerText}</p>}
      </div>
      <Table className={minExpectation && minExpectation !== 0 ?
        "table-min-expectations" : ""} responsive >
        {(minExpectation && minExpectation !== 0) ?
          <tr className="min-expectations-tr" >
            <td><span className="table-label" >{headerText}</span>
            </td>
            {HeadersWithRows[0]?.map((_, index) => {
              return (
                index === minExpectation ?
                  <td style={{ textAlign: 'center', fontSize: 12 }}>Minimum Expectation</td>
                  :
                  index !== 0 && <td></td>)
            })}
          </tr> : null}

        <thead>
          <tr>
            {TopHeaders && TopHeaders.map(({ first, second }) => {
              return <th>
                <div className="d-flex flex-column align-items-center">
                  <span>{first}</span>
                  <span>{second || (headerText === EXPECTATION_TARGETS && periodEndDate)}</span>
                </div>
              </th>
            })}

          </tr>
          {ExpectationTargets && ExpectationTargets.map((item) => {
            return (
              <tr>
                {item.map((item, index) =>
                  <th>
                    <div className="d-flex flex-column align-items-center py-1">
                      <span>{item}</span>
                    </div>
                  </th>
                )}
              </tr>
            )
          })}
        </thead>
        <tbody>
          {HeadersWithRows.map((item) => {
            return (
              <tr>
                {item.map((item, index) => {
                  return index === 0 ?
                    <th className="header-cell">{item}</th> :
                    <td className={Number(highlighter) === index ? "content-highlight content-cell" : "content-cell"}>{
                      headerText === BILLING_SUMMARY && (index === 2 || index === 3 || index === 4) ?
                        `${amountPrettifier(item)}`
                        : item
                    }</td>
                })}
              </tr>
            )
          })
          }
        </tbody>
      </Table>
    </div >
  )
}
export default TableGoalSheet