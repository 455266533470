import { useLazyQuery, useMutation } from '@apollo/client';
import { useCallback, useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { ADD_NEW_VIDEO } from '../../../constants';
import {
  ADD_TJLOOKUP_VIDEOS,
  REMOVE_TJLOOKUP_VIDEO,
  TJLOOKUP_VIDEOS_LIST,
  UPDATE_TJLOOKUP_VIDEOS,
} from '../gql';
import AddTjLookupVideoModal from './AddNewVideoModal';
import EditTjLookupVideoModal from './EditVideoModal';
import TjTalkVideoLIsting from './talkVideoListing/TjTalkVideoLIsting';

import { Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import SEARCH_IMG from '../../../assets/images/search.svg';
import InputField from '../../../components/layout/InputField';
import Pagination from '../../../components/layout/Pagination';
import { AppContext } from '../../../context';
import { VideoFormType } from '../../../interfaces';
import { PAGE_LIMIT, ROLES } from '../../../utils/constant';

const TjLookupVideo = () => {
  const [tjlookupModal, setTjlookupModal] = useState<boolean>(false);
  const [editTjlookupModal, setEditTjlookupModal] = useState<boolean>(false);
  const [videoId, setVideoId] = useState<string>('');
  const { userRoles } = useContext(AppContext);
  const [videoObj, setVideoObj] = useState<VideoFormType>({
    id: '',
    videoTitle: '',
    videoUrl: '',
  });
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [addTjLookUpVideos, { loading: addVideoLoading}] = useMutation(ADD_TJLOOKUP_VIDEOS);
  const [updateTjLookUpVideos, { loading: updateVideoLoading }] = useMutation(UPDATE_TJLOOKUP_VIDEOS);
  const [removeTjlookUpVideo] = useMutation(REMOVE_TJLOOKUP_VIDEO);
  const [tjlookUpvideoList, { data: listData, loading: listLoading }] =
    useLazyQuery(TJLOOKUP_VIDEOS_LIST);
  const page = parseInt('1');
  const [currentPage, setCurrentPage] = useState(page);
  const [limit, setLimit] = useState(10);
  const [searchParams, setSearchParams] = useState<string>('');

  const removeTjlookUpVideoHandler = async () => {
    if (videoId) {
      let res = await removeTjlookUpVideo({
        variables: { removeTjlookUpVideo: { id: videoId } },
      });
      if (res?.data) {
        toast.success('Video removed successfully!');
        setCurrentPage(1);
        refetchData();
        setIsDeleteModalOpen(false);
      }
    }
  };
  const refetchData = async () => {
    await tjlookUpvideoList({
      variables: {
        limit,
        searchText: searchParams ? searchParams : '',
        page: currentPage,
      },
    });
  };

  let fetchingtjlookUpVideosData = useCallback(async () => {
    if (searchParams === '') {
      await tjlookUpvideoList({
        variables: {
          limit,
          searchText: '',
          page: currentPage,
        },
      });
    }
  }, [currentPage, limit, searchParams, tjlookUpvideoList]);

  useEffect(() => {
    fetchingtjlookUpVideosData();
  }, [fetchingtjlookUpVideosData]);

  const searchControl = async (value: any) => {
    setSearchParams(value);
    if (value?.length > 2) {
      await tjlookUpvideoList({
        variables: {
          limit: 10,
          searchText: value,
          page: 1,
        },
      });
    }
  };

  return (
    <>
      <div className="search-filter searchFilterResponsive">
        <div className="mr-2">
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <img src={SEARCH_IMG} alt="search" />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              type="text"
              placeholder="Search Video Title"
              value={searchParams}
              onChange={(e) => searchControl(e.target.value)}
              className="search-by-company"
            />
          </InputGroup>
          <small className="text-info">
            {searchParams &&
              searchParams.length <= 2 &&
              ' Please add more than two characters'}
          </small>
        </div>
        {userRoles?.includes(ROLES.ADMIN) ||
          userRoles?.includes(ROLES.EXECUTIVE) ? (
          <div className="mobileResponsiveFlex align-items-start">
            <div>
              <button
                className="buttonGenericStyle filledButton"
                onClick={() => setTjlookupModal(!tjlookupModal)}
                disabled={
                  userRoles?.includes(ROLES.ADMIN) ||
                    userRoles?.includes(ROLES.EXECUTIVE)
                    ? false
                    : true
                }
              >
                {ADD_NEW_VIDEO}
              </button>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>

      <div className="table-responsive mt-3 candidates-list-table">
        <TjTalkVideoLIsting
          listData={listData}
          listLoading={listLoading}
          setVideoId={setVideoId}
          setIsDeleteModalOpen={setIsDeleteModalOpen}
          isDeleteModalOpen={isDeleteModalOpen}
          removeTjlookUpVideoHandler={removeTjlookUpVideoHandler}
          setEditTjlookupModal={setEditTjlookupModal}
          editTjlookupModal={editTjlookupModal}
          setVideoObj={setVideoObj}
        />
      </div>

      {listData?.tjlookUpvideoList?.Tjlookup?.length ? (
        <div className="users-pagination">
          <Pagination
            onPageChange={(pageClicked: number) => {
              setCurrentPage(pageClicked);
            }}
            pageCount={Math.ceil(listData?.tjlookUpvideoList?.count / limit)}
            currentPage={currentPage}
          />
          <div className="d-flex align-items-center">
            <div className="pagination-display paginationItemsSelect">
              <p className="m-0">
                {(currentPage - 1) * limit + 1} to{' '}
                {Math.min(
                  currentPage * limit,
                  listData?.tjlookUpvideoList?.count
                )}
                {` of `} {listData?.tjlookUpvideoList?.count}
              </p>

              <div className="divider"></div>

              <label>Display</label>

              <InputField
                label=""
                inputtype="select"
                selectItems={PAGE_LIMIT.filter((item) => item.value !== limit)}
                inputid="limit"
                placeholder={limit.toString()}
                inputMethod={(value) => {
                  setCurrentPage(1);
                  setLimit(parseInt(value));
                }}
              />
            </div>
          </div>
        </div>
      ) : null}
      <AddTjLookupVideoModal
        refetchList={refetchData}
        isOpen={tjlookupModal}
        toggle={() => setTjlookupModal(!tjlookupModal)}
        addTjLookUpVideos={addTjLookUpVideos}
        loading={addVideoLoading}
      />

      {editTjlookupModal && (
        <EditTjLookupVideoModal
          isOpen={editTjlookupModal}
          toggle={() => setEditTjlookupModal(!editTjlookupModal)}
          videoObj={videoObj}
          updateTjLookUpVideos={updateTjLookUpVideos}
          refetchList={refetchData}
          loading={updateVideoLoading}
        />
      )}
    </>
  );
};

export default TjLookupVideo;
