// packages block
import { FC, Key, useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { Button, Card, CardBody } from 'reactstrap';
import { useLazyQuery, useMutation } from '@apollo/client';
// components block
import Loader from '../../../components/layout/Loader';
import InputField from '../../../components/layout/InputField';
import NoDataFound from '../../../components/layout/NoDataFound';
import { Pagination } from '../../../components/layout/Pagination';
import PreviewCandidateSubmitEmail from './PreviewCandidateSubmitEmail';
// graphql, utils and and constants block
import { MARK_AS_UnREAD } from '../gql';
import { FETCH_PAST_NOTIFICATIONS } from '../gql';
import { COMPANY_OWNERSHIP_REPORT_ROUTE, PAGE_LIMIT, REQUESTS_ROUTE, VIEW_ACCOUNTING_DASHBOARD_ROUTE, VIEW_AGREEMENT_ROUTE, VIEW_CANDIDATE_ROUTE, VIEW_JOB_ORDER_ROUTE, VIEW_PLACEMENT_MANAGEMENT_ROUTE } from '../../../utils/constant';
import { formatDateUS } from '../../../utils/commonFn';
import { INotificationCard } from '../../../interfaces';
import { messageParsing } from '../../../utils/Temp-Helper';
import { notificationJsonTruth } from '../../../utils/helper';

const PastNotificationCard: FC<INotificationCard> = ({
  searchInput,
  fetch,
  update,
}): JSX.Element => {
  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState(10);
  const history = useHistory();
  const [statusFilter] = useState<string>('');
  const [previewModal, setPreviewModal] = useState<any>(false);
  const [submissionID] = useState<string>('');
  const [notifyBackId] = useState<string>('');
  const [responseSubmit] = useState<string>('');

  const [fetchAllPastNotifications, { data, loading }] = useLazyQuery(
    FETCH_PAST_NOTIFICATIONS
  );

  const notificationHandler = useCallback(() => {
    fetchAllPastNotifications({
      variables: {
        limit,
        page: page,
        searchText: searchInput,
        alertStatus: statusFilter,
      },
    });
  }, [fetchAllPastNotifications, limit, page, searchInput, statusFilter]);

  const previewHandler = (item: {
    id?: string;
    notifyableType?: string;
    alertType: string;
    alertMsg: any;
    createdAt?: Date | string;
  }) => {
    if (item?.alertType === 'PLACEMENT_REMINDER') {
      const infoExtractor = JSON.parse(item?.alertMsg);
      const placementId = infoExtractor.placementId;

      if (infoExtractor?.to === 'ACCEPT_REJECT_EXECUTIVE') {
        history.push({
          pathname: `${VIEW_ACCOUNTING_DASHBOARD_ROUTE}/${placementId}`,
        });
      } else if (
        infoExtractor?.to === 'ACCEPT_REJECT_MANAGER' ||
        infoExtractor?.to === 'ACCEPT_REJECT_AM' ||
        infoExtractor?.to === 'UPDATE'
      ) {
        history.push({
          pathname: `${VIEW_PLACEMENT_MANAGEMENT_ROUTE}/${placementId}`,
        });
      }
    } else if (
      item?.alertType === 'CLIENT_CHECKUP_REMINDER' &&
      item?.notifyableType === 'JobOrder'
    ) {
      const infoExtractor = JSON.parse(item?.alertMsg);
      const jobOrderId = infoExtractor?.jobOrderId;

      history.push({
        pathname: `${VIEW_JOB_ORDER_ROUTE}/${jobOrderId}`,
      });
    } else if (item?.alertType === 'OWNERSHIP_EXPIRATION') {
      history.push({
        pathname: `${COMPANY_OWNERSHIP_REPORT_ROUTE}`,
      });
    }else if (item?.alertType === 'SEND_AGREEMENT_CHECKUP') {
      const infoExtractor = JSON.parse(item?.alertMsg);
      const agreementId = infoExtractor?.agreementId;

      history.push({
        pathname: `${VIEW_AGREEMENT_ROUTE}/${agreementId}`,
      });
    } else if (item?.alertType === 'INTERVIEW_INVITE_CHECKUP') {
      const infoExtractor = JSON.parse(item?.alertMsg);

      history.push({
        pathname: `${VIEW_CANDIDATE_ROUTE}/${infoExtractor?.candidate}`,
        state: {
          backUrl: `/notifications`,
          landedFrom: `pipeline`,
        },
        search:
          '?' +
          new URLSearchParams({
            applicantId: infoExtractor?.jobApplicantId,
            activeTab: infoExtractor?.activeTab,
          }).toString(),
      });
    } else if (item?.alertType === 'PLACEMENT_CANCELLATION_REQUEST') {
      history.push({
        pathname: `${REQUESTS_ROUTE}`,
      });
    } else {
      const infoExtractor = JSON.parse(item?.alertMsg);

      history.push({
        pathname: `${VIEW_CANDIDATE_ROUTE}/${infoExtractor?.candidateId}`,
        state: {
          backUrl: `/notifications`,
          landedFrom: `pipeline`,
        },
        search:
          '?' +
          new URLSearchParams({
            applicantId: infoExtractor?.applicantId,
            activeTab: infoExtractor?.activeTab,
          }).toString(),
      });
    }
  };

  useEffect(() => {
    if (searchInput) {
      setLimit(10);
      setPage(1);
      notificationHandler();
    } else {
      notificationHandler();
    }
  }, [notificationHandler, searchInput, page, limit, update]);

  const [readNotification] = useMutation(MARK_AS_UnREAD);

  const markAsUnReadHandler = async (id: string) => {
    await readNotification({
      variables: { notificationInput: { id } },
    });

    toast.success('Notification is pushed to unread state');
    notificationHandler();
    fetch();
  };

  return (
    <div>
      <div>
        <Card className="border-0 mt-section-4 mb-0">
          <div className="heading-border">
            <h5>Past Notifications</h5>
          </div>

          {loading ? (
            <Loader />
          ) : (
            <CardBody className="p-0">
              <div className="user-basic-form">
                {data?.allPastNotifications?.notifications?.map(
                  (
                    item: {
                      id: string;
                      notifyableType: string;
                      alertType: string;
                      alertMsg: any | null | undefined;
                      createdAt: string | any;
                    },
                    index: Key
                  ) => {
                    return (
                      <div className="notification-card" key={index}>
                        <div>
                          <span className='message'>
                            {messageParsing(item)}
                          </span>
                          <p>
                            {formatDateUS(item?.createdAt)}

                            {'   '}
                            {new Date(item.createdAt).toLocaleTimeString()}
                          </p>
                        </div>

                        <div>
                          <Button
                            color="secondary"
                            className="mr-2"
                            onClick={() => markAsUnReadHandler(item?.id)}
                          >
                            Mark As Unread
                          </Button>

                          {notificationJsonTruth(
                            item?.notifyableType,
                            item?.alertType
                          ) ? (
                            <Button
                              color="primary"
                              onClick={() => previewHandler(item)}
                            >
                              Manage
                            </Button>
                          ) : null}
                        </div>
                      </div>
                    );
                  }
                )}

                {!loading &&
                  !data?.allPastNotifications?.notifications?.length && (
                    <div className="for-position-multiselect">
                      <NoDataFound text="No past notifications" />
                    </div>
                  )}
              </div>
            </CardBody>
          )}
        </Card>

        {!!data?.allPastNotifications?.notifications?.length && (
          <div className="users-pagination">
            <Pagination
              onPageChange={(pageClicked: number) => {
                setLimit(limit);
                setPage(pageClicked);
              }}
              pageCount={Math.ceil(data?.allPastNotifications?.count / limit)}
              currentPage={page}
            />

            <div className="d-flex align-items-center">
              <div className="pagination-display paginationItemsSelect">
                <p className="m-0">
                  {(page - 1) * limit + 1} to {page * limit} of{' '}
                  {data?.allPastNotifications.count}
                </p>

                <div className="divider"></div>

                <label>Display</label>

                <InputField
                  label=""
                  inputtype="select"
                  selectItems={PAGE_LIMIT.filter(
                    (item) => item.value !== limit
                  )}
                  inputid="limit"
                  placeholder={limit.toString()}
                  inputMethod={(value: string) => {
                    setPage(1);
                    setLimit(parseInt(value));
                  }}
                />
              </div>
            </div>
          </div>
        )}
      </div>

      {previewModal && (
        <PreviewCandidateSubmitEmail
          open={previewModal}
          toggle={setPreviewModal}
          submissionId={submissionID}
          notifyBackId={notifyBackId}
          alertMsg={responseSubmit}
        />
      )}
    </div>
  );
};

export default PastNotificationCard;
