import Select from "react-select";
import { useContext } from "react";
import { AppContext } from "../context";
import { ALL_REPORT_OPTIONS, COMPANY_REPORT_OPTIONS } from "../utils/constant";
import { customSelectStyleNew } from "../components/styled/customSelect";

const AsyncCompanyReportNote = ({ candidate, placeholder, setNotes, notes }) => {
  const { theme } = useContext(AppContext);
  const handleSelectChange = (selectedOption) => {
    setNotes(selectedOption.value)
    candidate && localStorage.setItem('candidateOwnerShipReport-note', selectedOption.value)
  };
  return (
    <div className="">
      <Select
        options={candidate ? ALL_REPORT_OPTIONS : COMPANY_REPORT_OPTIONS}
        value={notes ? ALL_REPORT_OPTIONS.find(option => option.value === notes) : null}
        onChange={handleSelectChange}
        placeholder={placeholder}
        className="w-12rem"
        styles={{ ...customSelectStyleNew(theme) }}
      />
    </div>
  );
};

export default AsyncCompanyReportNote;
