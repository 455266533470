import { FETCHING_NOT_TERMINATED_USERS } from '../admin/gql';
import { useState, useCallback, useEffect } from 'react';
import GoBack from '../../components/GoBack';
import { ADD, MODULE_ACCESS } from '../../constants';
import Loader from '../../components/layout/Loader';
import TableComponent from '../../components/layout/TableComponent';
import {
  MODULE_ACCESS_OPTIONS,
  MODULE_HEADER,
  SETTINGS_ROUTE,
  moducleAcessDTO,
} from '../../utils/constant';
import EDIT_ICON from '../../assets/images/edit-icon-menu.svg';
import DrawerComponent from './Drawer';
import { ALL_MODAL_ACCCESS_SETTINGS } from './gql';
import { useLazyQuery, useQuery } from '@apollo/client';
import { FormValuesModule, IModuleAccessSetting } from '../../interfaces';
import { Controller, useForm } from 'react-hook-form';
import ModalComponent from '../../common/Modal';
import AddModal from './AddModal';

const Module = () => {
  const { data: users } = useQuery(FETCHING_NOT_TERMINATED_USERS);
  const [addModule, setAddModule] = useState<boolean>(false);
  const [data, setData] = useState<IModuleAccessSetting>();
  const [
    allModalAccessSettings,
    { data: settingsData, loading: listLoading, refetch: refetchSettings },
  ] = useLazyQuery(ALL_MODAL_ACCCESS_SETTINGS);
  const { register, setValue, handleSubmit, control, errors } =
    useForm<FormValuesModule>();
  const [selectedAccess, setSelectedAccess] = useState<{
    label: string;
    value: string;
  } | null>(null);
  const [openModal, setOpenModal] = useState<boolean>(false);

  const onAddModule = async (data: IModuleAccessSetting) => {
    setData(data)
    setAddModule(!addModule)
  };

  const fetchingModuleAccessData = useCallback(async () => {
    await allModalAccessSettings();
  }, [allModalAccessSettings]);

  useEffect(() => {
    fetchingModuleAccessData();
  }, [fetchingModuleAccessData]);

  if (listLoading) {
    return <Loader />;
  }
  return (
    <>
      <div className="admin-tabs team-tabs">
        <GoBack url={SETTINGS_ROUTE} />

        <div className="primaryHeaderSpacing primaryHeading d-flex justify-content-between align-items-center">
          <h5 className="m-0">{MODULE_ACCESS}</h5>

          <button
            type="button"
            onClick={() => setOpenModal(!openModal)}
            className="buttonGenericStyle filledButton"
          >
            {ADD}
          </button>
        </div>

        <div className="mr-3">
          <TableComponent tableHeader={MODULE_HEADER}>
            <tbody>
              {settingsData?.AllModalAccessSettings?.modalAccessSettings
                .length &&
                settingsData?.AllModalAccessSettings?.modalAccessSettings.map(
                  (moduleName, index) => {
                    return (
                      <tr>
                        <td>{moducleAcessDTO[moduleName?.feature]}</td>
                        <td>{moduleName?.type}</td>
                        <td className="text-truncate max-200">
                          {moduleName?.updatedAt
                            ? moduleName?.updatedAt
                            : moduleName?.createdAt}
                        </td>
                        <td>
                          <button
                            onClick={async () => {
                              await onAddModule(
                                settingsData?.AllModalAccessSettings
                                  ?.modalAccessSettings[index]
                              );
                            }}
                            className="iconButtonAction"
                          >
                            <img src={EDIT_ICON} alt="Edit" />
                          </button>
                        </td>
                      </tr>
                    );
                  }
                )}
            </tbody>
          </TableComponent>
        </div>
      </div>

      <DrawerComponent
        isOpen={addModule}
        toggle={() => {
          setAddModule(!addModule);
        }}
        data={data!}
        refetchSettings={refetchSettings}
        users={users}
        handleSubmit={handleSubmit}
        register={register}
        control={control}
        selectedAccess={selectedAccess}
        setSelectedAccess={setSelectedAccess}
        errors={errors}
        setValue={setValue}
      />

      <ModalComponent
        size="md"
        isOpen={openModal}
        toggle={() => setOpenModal(false)}
      >
        <AddModal
          onClose={() => setOpenModal(false)}
          register={register}
          Controller={Controller}
          errors={errors}
          control={control}
          users={users}
          handleSubmit={handleSubmit}
          refetchSettings={refetchSettings}
          toggle={() => {
            setOpenModal(false)
          }}
        />
      </ModalComponent>
    </>
  );
};

export default Module;
