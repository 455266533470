import { useContext } from "react";
import { AppContext } from "../../../context";
import editImage from "../../../assets/images/edit.svg";

export default function AgreementEditViewPage({
  agreementStatus,
  agreementUserId,
  agreementId,
}) {
  const { user, userRoles } = useContext(AppContext);

  return (
    <div>
      {userRoles?.includes("RECRUITER") &&
      (agreementStatus === "DRAFT" ||
        agreementStatus === "MANAGER_REJECTED" ||
        agreementStatus === "EXECUTIVE_REJECTED") &&
      user?.id === agreementUserId ? (
        <a
          className="edit-page"
          rel="noreferrer"
          href={`/update-agreement/${agreementId}`}
        >
          <img src={editImage} alt="back" />
          <span>Edit</span>
        </a>
      ) : null}
    </div>
  );
}
