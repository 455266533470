import { useMutation } from "@apollo/client";
import { FC, useContext, useEffect, useState } from "react";
import ReactQuill from "react-quill";
import Select from "react-select";
import { toast } from "react-toastify";
import { Col, Form, Input, Label, Row } from "reactstrap";
import ModalComponent from "../../common/Modal";
import { ADD, CANCEL, EMAIL_TEMPLATES } from "../../constants";
import { AppContext } from "../../context";
import { AddEmailTemplateModalProps } from "../../interfaces";
import { EMAIL_TEMPLATES_OPTIONS, emailTemplatesTagsArray, reactQuillReg } from "../../utils/constant";
import CustomButton from "../layout/CustomButton";
import { customSelectStyleNew } from "../styled/customSelect";
import { ADD_EMAIL_TEMPLATE } from "./gql";

const AddEmailTemplateModal: FC<AddEmailTemplateModalProps> = ({ isOpen, refetch, toggle }) => {

  const { theme } = useContext(AppContext)
  const [copied, setCopy] = useState<boolean>(false);
  const [template, setTemplate] = useState<string | null>();
  const [module, setModule] = useState<{ label: string; value: string } | null>();
  const [subject, setSubject] = useState<string>();
  const [addRecord, { loading }] = useMutation(ADD_EMAIL_TEMPLATE, {
    onCompleted: () => {
      toast.success("added successfully")
      refetch()
      toggle()
    },
    onError: (err) => toast.error(err?.message)
  })

  useEffect(() => {
    if (!isOpen) {
      setTemplate(null)
      setModule(null)
    }
  }, [isOpen])

  const onCopyText = (item: string, index: number) => {
    navigator.clipboard.writeText(item);
    setCopy(true);

    setTimeout(() => {
      setCopy(false);
    }, 1500);
  };

  return (
    <ModalComponent
      size="md" isOpen={isOpen} toggle={toggle}    >
      <div className="p-4">
        <h4 className="mb-4">{EMAIL_TEMPLATES}</h4>
        <Form>
          <Row className="mb-3">
            <Col md="12">
              <Label>Module</Label>
              <Select
                value={module}
                isDisabled={false}
                options={EMAIL_TEMPLATES_OPTIONS}
                isSearchable={false}
                placeholder="Select Module For Template"
                onChange={(target) => {
                  setModule(target!);
                }}
                styles={{ ...customSelectStyleNew(theme) }}
              />
            </Col>
          </Row>
          <div className="w-100 d-flex flex-wrap mt-2 position-relative">
            {(copied &&
              <span className="badge badge-main badge-warning">Copied</span>
            )}
            {emailTemplatesTagsArray?.map((item: string, index) => {
              return (
                <span
                  className="steps-tags-template"
                  onClick={() => onCopyText(item, index)}
                  id={`items-${index}`}
                  key={index}
                >
                  {item.split(', ')}
                </span>
              );
            })}
          </div>
          <Row className="mb-3">
            <Col md="12">
              <Label>Subject</Label>
              <Input
                value={subject}
                placeholder="Subject Template"
                onChange={({ target }) => {
                  setSubject(target?.value);
                }}
                styles={{ ...customSelectStyleNew(theme) }}
              />
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <Label>Template</Label>
              <div className="editor-height">
                <ReactQuill
                  value={template!}
                  onChange={(value) => {
                    if (reactQuillReg.test(value)) {
                      setTemplate(null);
                    } else {
                      setTemplate(value);
                    }
                  }}
                />
              </div>
            </Col>
          </Row>
          <div className="d-flex align-items-center justify-content-end mt-5">
            <button
              type="button"
              className="buttonGenericStyle buttonPrimary"
              onClick={toggle}
            >
              {CANCEL}
            </button>
            <div className="px-3" />
            <CustomButton
              buttonClick={async () => await addRecord({ variables: { input: { module: module?.value, template, subject } } })}
              className="buttonPrimary filledButton"
              loading={loading}
              disabled={loading || !subject || !template || !module}
              buttonText={ADD}
            />
          </div>
        </Form>
      </div >
    </ModalComponent>

  )
}
export default AddEmailTemplateModal