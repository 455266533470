import { FC, ReactNode } from "react";
import { Modal, } from "reactstrap";

interface IModal {
  isOpen: boolean;
  toggle: () => void;
  children: ReactNode;
  size?: string
}

const ModalComponent: FC<IModal> = ({ isOpen, toggle, size, children }) => {
  if (!isOpen) return null;

  return (
    <>
      <Modal isOpen={isOpen} toggle={toggle} size={size}>
        {children}
      </Modal>
    </>
  )
}

export default ModalComponent;
