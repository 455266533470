import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { Button, Col, Form, Input, InputGroup, InputGroupAddon, Label, Row, Spinner } from 'reactstrap';
//layout
import { DrawerHeader, Sidedrawer } from '../../components/layout/Sidedrawer';
// constants , interface
import { SAVE, UPDATE_RECORD } from '../../constants';
import { AddRecordDrawerJobListProps, ICompanyAddress, IJobListUpdate, jobListEditSelectorOptionType } from '../../interfaces';
import { reactQuillReg } from '../../utils/constant';
//component
import ReactQuill from 'react-quill';
import client from '../../apollo';
import AsyncGeneralSelect from '../../components/AsyncGeneralSelect';
import { commaRemover, moneyFieldHandler } from '../../utils/helper';
import CreateAddressModal from '../contacts/contact-components/CreateAddressModal';
//apollo
import { useMutation } from '@apollo/client';
import CompanyOwnerSelect from '../../components/CompanyOwnerSelect';
import { GET_JOB_LIST_FOR_EDIT, UPDATE_JOB_LIST } from './gql';



const AddRecordDrawer: FC<AddRecordDrawerJobListProps> = ({ toggle, isOpen, refetchData }) => {
  //use states
  const [selectJobOrderOptions, setSelectJobOrderOptions] = useState<Array<jobListEditSelectorOptionType>>([]);
  const [modal, setModal] = useState(false);
  const [jobOrderLoading, setJobOrderLoading] = useState(false);
  const [jobOrderId, setId] = useState<string>("")
  const [addressList, setAddressList] = useState<Array<ICompanyAddress>>([]);
  const [companyId, setSelectedCompany] = useState<string>();

  const onDrawerFocusChange = () => {
    toggle()
    setSelectedCompany("")
    setId("")
  }


  //form
  const { control, setValue, getValues, register, handleSubmit, reset, clearErrors, setError, formState: { errors } } = useForm();

  //apollo
  const [updateJobList, { loading }] = useMutation(UPDATE_JOB_LIST)

  const _filterJobOrder = useCallback(async () => {
    try {
      setJobOrderLoading(true)
      const { data: { fetchAllJobList: { jobOrders } } } = await client.query({
        query: GET_JOB_LIST_FOR_EDIT,
        variables: {
          limit: 1,
          page: 1,
          companyId: companyId
        },
      });
      setJobOrderLoading(false)

      jobOrders.length === 0 && setError("jobOrder", {
        message: 'No active job order found for corporate',
      })

      return jobOrders?.map((item: IJobListUpdate) => {
        return ({
          value: item?.id,
          label: item?.jobTitle,
          companyLocation: {
            label: ['US', null, undefined, ''].includes(item?.companyLocation?.country) ? `${item?.companyLocation?.streetAddress}, ${item?.companyLocation?.city}, ${item?.companyLocation?.state}, ${item?.companyLocation?.zipCode}, ${item?.companyLocation?.country}`
              : `${item?.companyLocation?.streetAddress}, ${item?.companyLocation?.city}, ${item?.companyLocation?.zipCode}, ${item?.companyLocation?.country}`,
            value: item?.companyLocation?.id
          },
          addressList: item?.company?.address?.map((v: any) => ({
            label: ['US', null, undefined, ''].includes(v?.country) ? `${v?.streetAddress}, ${v?.city}, ${v?.state}, ${v?.zipCode}, ${v?.country}`
              : `${v?.streetAddress}, ${v?.city}, ${v?.zipCode}, ${v?.country}`,
            value: v.id,
          })),
          salaryMax: item?.salaryMax,
          salaryMin: item?.salaryMin || '0.00',
          jobDescription: reactQuillReg.test(item?.advertisedJobDescription) ? null : item?.advertisedJobDescription,
          urgencyNote: item?.jobListNotes?.urgencyNote,
          pipelineNote: item?.jobListNotes?.pipelineNote
        })
      })
        || [];

    } catch (error) {
      toast.error("Error while loading job titles!");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId])


  // JobOrderOptions
  const optionsOfJobOrder = useCallback(async () => {
    companyId && setSelectJobOrderOptions(await _filterJobOrder())
  }, [_filterJobOrder, companyId])

  // Based on the selected company Id
  useEffect(() => {
    isOpen && optionsOfJobOrder()
  }, [optionsOfJobOrder, isOpen])


  //select styles 
  const customStyles = {
    control: (styles, provided) => ({
      ...styles,
      fontSize: 12,
    }),

    container: (provided, state) => ({
      ...provided,
      fontSize: 12,
    }),

    option: (provided, state) => ({
      ...provided,
      fontSize: 12,
    }),
  };
  // modules for ReactQuill
  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: [2, 3, 4, false] }],
          ["bold", "italic", "underline", "blockquote"],
          [{ color: [] }, { background: [] }],
          [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
          ],
          ["link", "image"],
          ["clean"],
        ],
      },
      clipboard: {
        matchVisual: true,
      },
    }),
    []
  );

  //on form submit
  const onSubmit = async ({ jobOrder: { value }, advertisedJobDescription, companyLocation,
    salaryMax, salaryMin, urgencyNote, pipelineNote, }) => {
    try {
      reset({ jobOrder: null, companyLocation: null });
      const response = await updateJobList({
        variables: {
          jobListUpdateInput: {
            jobOrderId: value, advertisedJobDescription, companyLocationId: companyLocation?.value,
            salaryMin: commaRemover(salaryMin), salaryMax: commaRemover(salaryMax), pipelineNote, urgencyNote
          }
        }
      })
      if (response?.data) {
        toast.success('Successfully updated!');
        onDrawerFocusChange();
        refetchData();
      }
    }
    catch (error) {
      toast.error("Error while updating this record!");
    }
  }

  return (
    <>
      <Sidedrawer toggle={onDrawerFocusChange} isOpen={isOpen}>
        <div className="mb-4">
          <DrawerHeader prevClick={onDrawerFocusChange}
            buttonText="Edit"
            heading={UPDATE_RECORD}
            nextButtonView={false}
          />
        </div>

        <div className="pb-4">
          <Form>
            <Row style={{ display: 'flex', flexDirection: 'column', gap: 25 }}>
              <Col md="12">
                <div className="d-flex justify-content-between">
                  <Label className="d-block mt-0">Company <span className="text-danger"> *</span> </Label>
                </div>
                <CompanyOwnerSelect
                  onChange={(data) => {
                    setSelectedCompany(data?.value?.companyId);
                    reset({ jobOrder: null, companyLocation: null })
                    setId("")
                    setSelectJobOrderOptions([])
                    clearErrors("jobOrder")
                  }}
                />
              </Col>

              <Col md="12">
                <div className="d-flex justify-content-between">
                  <Label className="d-block mt-0">Job Title <span className="text-danger"> *</span> </Label>
                </div>
                <Controller
                  control={control}
                  name="jobOrder"
                  rules={{
                    required: {
                      value: true,
                      message: 'Job Title should be selected!',
                    },
                  }}
                  render={
                    ({ onChange, ref }) => (
                      <>
                        <AsyncGeneralSelect
                          placeholder="Select..."
                          isLoading={jobOrderLoading}
                          isSearchable={true}
                          isDisabled={selectJobOrderOptions.length ? false : true}
                          _filter={_filterJobOrder}
                          options={selectJobOrderOptions}
                          label="jobOrder"
                          ref={ref}
                          value={getValues()?.jobOrder && ({
                            ...getValues()?.jobOrder
                          } || {}
                          )}
                          onChange={({ addressList, label, salaryMin, companyLocation,
                            salaryMax, value, jobDescription, urgencyNote, pipelineNote }: jobListEditSelectorOptionType) => {
                            setId(value)
                            setAddressList(addressList)
                            setValue("companyLocation", companyLocation)
                            setValue("salaryMax", salaryMax)
                            setValue("salaryMin", salaryMin)
                            setValue("advertisedJobDescription", jobDescription)
                            setValue("urgencyNote", urgencyNote)
                            setValue("pipelineNote", pipelineNote)
                            onChange({
                              addressList, label, salaryMin, companyLocation,
                              salaryMax, value, jobDescription, urgencyNote, pipelineNote
                            })
                          }}
                        />
                      </>
                    )
                  }
                />
                {
                  errors?.jobOrder?.message &&
                  <small className="text-danger">
                    {errors?.jobOrder?.message}
                  </small>
                }
              </Col>

              <Col md="12">
                <Label>
                  Actual Job Location <span className="text-danger">*</span>
                </Label>

                <Controller
                  name="companyLocation"
                  rules={{
                    required: {
                      value: true,
                      message: "Must Select Actual job location!",
                    },
                  }}
                  isSearchable={true}
                  as={Select}
                  isDisabled={jobOrderId ? false : true}
                  options={addressList}
                  placeholder="Actual job location"
                  control={control}
                  styles={customStyles}
                />

                <small className="text-danger">
                  {errors?.companyLocationId?.message}
                </small>

                <div className="d-flex justify-content-end">
                  <Button
                    className="bg-transparent border-0 text-info p-0 mt-1"
                    onClick={() => setModal(!modal)}
                    disabled={jobOrderId ? false : true}
                    title={
                      !jobOrderId
                        ? "Kindly select a Job Title for new address!"
                        : ""
                    }
                  >
                    Create New Address
                  </Button>
                </div>
              </Col>

              <Col md="12">
                <Row >
                  <Col>
                    <Label>
                      Advertise Salary Min
                      <span className="text-danger">*</span>
                    </Label>

                    <InputGroup>
                      <InputGroupAddon addonType="prepend">$</InputGroupAddon>
                      <Input
                        autoComplete="off"
                        disabled={jobOrderId ? false : true}
                        name="salaryMin"
                        type="text"
                        onChange={(e) => {
                          moneyFieldHandler(
                            e?.target?.value,
                            setValue,
                            'salaryMin'
                          );
                        }}
                        innerRef={register({
                          required: {
                            value: true,
                            message: 'Min Salary amount is required!',
                          },
                        })}
                      />
                    </InputGroup>
                    <small className="text-danger">
                      {errors?.salaryMin?.message}
                    </small>
                  </Col>

                  <Col md="6">
                    <Label>
                      Advertise Salary Max
                      <span className="text-danger">*</span>
                    </Label>

                    <InputGroup>
                      <InputGroupAddon addonType="prepend">$</InputGroupAddon>
                      <Input
                        name="salaryMax"
                        onChange={(e) => {
                          moneyFieldHandler(
                            e?.target?.value,
                            setValue,
                            'salaryMax'
                          );
                        }}
                        disabled={jobOrderId ? false : true}
                        type="text"
                        innerRef={register({
                          required: {
                            value: true,
                            message: 'Max Salary amount is required!',
                          },
                        })}
                      />
                    </InputGroup>
                    <small className="text-danger">
                      {errors?.salaryMax?.message}
                    </small>
                  </Col>
                </Row>
              </Col>

              <Col md="12">
                <Label>
                  Description
                  <span className="text-danger">*</span>
                </Label>
                <div className="editor-height">
                  <Controller
                    control={control}
                    name="advertisedJobDescription"
                    rules={{
                      required: {
                        value: true,
                        message: 'Add some description content!',
                      }
                    }}
                    render={({ onChange, ref, value }) => (
                      <div className="editor-height">
                        <ReactQuill
                          readOnly={jobOrderId ? false : true}
                          onChange={(value) => {
                            if (reactQuillReg.test(value)) {
                              onChange(null);
                            } else {
                              onChange(value);
                            }
                          }}
                          value={value}
                          ref={ref}
                        />
                        <small className="text-danger pt-2">
                          {errors?.advertisedJobDescription?.message}
                        </small>
                      </div>
                    )}
                    styles={customStyles}
                  />
                </div>
              </Col>

              <Col md="12">
                <Label>
                  Urgency Note
                  <span className="text-danger">*</span>
                </Label>
                <div className="editor-height">
                  <Controller
                    control={control}
                    name="urgencyNote"
                    rules={{
                      required: {
                        value: true,
                        message: 'Add some urgency note content!',
                      },
                    }}
                    defaultValue={null}
                    render={({ onChange, ref, value }) => (
                      <div className="editor-height">
                        <ReactQuill
                          modules={modules}
                          readOnly={jobOrderId ? false : true}
                          onChange={(value) => {
                            if (reactQuillReg.test(value)) {
                              onChange(null);
                            } else {
                              onChange(value);
                            }
                          }}
                          ref={ref}
                          value={value}
                        />
                        <small className="text-danger pt-2">
                          {errors?.urgencyNote?.message}
                        </small>
                      </div>
                    )}
                    styles={customStyles}
                  />
                </div>
              </Col>

              <Col md="12">
                <Label>
                  Pipeline Note
                  <span className="text-danger">*</span>
                </Label>
                <div className="editor-height">
                  <Controller
                    control={control}
                    name="pipelineNote"
                    rules={{
                      required: {
                        value: true,
                        message: 'Add some pipeline note content!',
                      }
                    }}
                    defaultValue={null}
                    render={({ onChange, ref, value }) => (
                      <div className="editor-height">
                        <ReactQuill
                          modules={modules}
                          readOnly={jobOrderId ? false : true}
                          onChange={(value) => {
                            if (reactQuillReg.test(value)) {
                              onChange(null);
                            } else {
                              onChange(value);
                            }
                          }}
                          ref={ref}
                          value={value}
                        />
                        <small className="text-danger pt-2">
                          {errors?.pipelineNote?.message}
                        </small>
                      </div>
                    )}
                    styles={customStyles}
                  />
                </div>
              </Col>
            </Row>
          </Form>
        </div>

        <div className="d-flex">
          <button
            className="buttonGenericStyle filledButton ml-auto"
            disabled={loading}
            onClick={handleSubmit(onSubmit)}
          >
            {(loading) && (
              <Spinner
                size="sm"
                style={{ color: 'white' }}
                className="mr-2"
              />
            )}
            {SAVE}
          </button>
        </div>

        {modal && (
          <CreateAddressModal
            open={modal}
            toggle={() => setModal(!modal)}
            companyId={companyId}
          />
        )}
      </Sidedrawer>

    </>

  )
}

export default AddRecordDrawer;
