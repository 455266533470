import React from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { Alert } from "reactstrap";

import CustomButton from "../../components/layout/CustomButton";
import InputField from "../../components/layout/InputField";
import AuthLayout from "../../components/layout/AuthLayout";

import { DASHBOARD_ROUTE, EMAIL_PATTERN, ROUTE_HISTORY_KEY } from "../../utils/constant";
import { LOGIN } from "./gql";
import { Redirect } from "react-router-dom";

import "./login.scss";

type LoginType = {
  email: string;
  password: string;
};

//todo set default props of the component
const Login = (props: any): JSX.Element => {
  const { register, handleSubmit, errors } = useForm<LoginType>();
  const [userLogin, { error, data, loading }] = useMutation(LOGIN);

  if (data?.login?.access_token) {
    window.localStorage.setItem("token", data.login.access_token);
    data?.login?.acl ? window.localStorage.setItem("sideBarItem", data?.login?.acl) :
      window.localStorage.removeItem("sideBarItem");
    window.localStorage.setItem(ROUTE_HISTORY_KEY, JSON.stringify([]));
    props.history.push(DASHBOARD_ROUTE);
  }

  const formSubmit = async (values: LoginType) => {
    try {
      await userLogin({ variables: values });
      if (error) {
        throw error;
      }
    } catch (error: any) {
      console.log(error?.graphQLErrors);
    }
  };

  return (
    <>
      {window.localStorage.token && <Redirect to={DASHBOARD_ROUTE} />}
      <div className="login-wrapper">
        <AuthLayout title="" subTitle="" isLogin>
          <div>
            <form onSubmit={handleSubmit(formSubmit)}>
              <InputField
                label="Email"
                inputtype="email"
                placeholder="Email Address"
                inputid="email"
                inputRef={register({
                  required: { value: true, message: "Email is required!" },
                  pattern: {
                    value: EMAIL_PATTERN,
                    message: "Email not valid !",
                  },
                })}
                errMsg={errors?.email?.message}
              />

              <InputField
                label="Password"
                inputtype="password"
                placeholder="Password"
                inputid="password"
                inputRef={register({
                  required: { value: true, message: "Password is required!" },
                  minLength: {
                    value: 6,
                    message: "Password length must be 6!",
                  },
                })}
                hasForgotPass
                isPassword
                errMsg={errors?.password?.message}
              />
              {error && (
                <Alert color="danger" className="form-alert">
                  {error?.graphQLErrors[0]?.message}
                </Alert>
              )}

              <div className="form-footer">
                <CustomButton
                  buttonText="Login"
                  disabled={loading}
                  loading={loading}
                />
              </div>
            </form>
          </div>
        </AuthLayout>
      </div>
    </>
  );
};

export default Login;
