import { ChangeEventHandler } from 'react';

interface checkboxProps {
  parentClass: string;
  candidateId?: string;
  selectedCandidates: any;
  allSelected?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
}

function Checkbox({
  parentClass,
  candidateId,
  selectedCandidates,
  allSelected,
  onChange,
}: checkboxProps) {
  const isChecked = () => {
    if (!candidateId) {
      return allSelected;
    }
    return selectedCandidates?.some(
      (candidate) => candidate.id === candidateId
    );
  };

  return (
    <label className={parentClass}>
      <input type="checkbox" onChange={onChange} checked={isChecked()} />
      <span className="checkmark"></span>
    </label>
  );
}

export default Checkbox;
