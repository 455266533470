import { useLazyQuery } from "@apollo/client"
import { FC, useEffect } from "react"
import { GET_RECRUITER_GOAL_SHEET } from "../../Pages/goal-sheet-report/gql"
import { BILLING_SUMMARY, EXPECTATION_TARGETS, WHAT_TO_EXPECT_IF } from "../../constants"
import { GoalSheetProps } from "../../interfaces"
import { BILLING_SUMMARY_TOP_HEADERS, EXPECTATIONS_CONSTANT_TABLE, EXPECTATION_TARGETS_ROWS, EXPECTATION_TARGETS_TOP_HEADERS } from "../../utils/constant"
import Loader from "../layout/Loader"
import TableGoalSheet from "./TableGoalSheet"
import TableStats from "./TableStats"

const GoalSheet: FC<GoalSheetProps> = ({ recruiterId, month, year, setDocumentLink
}) => {
  const [getRecruiterFullNameQuery, { data, loading }] = useLazyQuery(GET_RECRUITER_GOAL_SHEET, {
    onCompleted: async (data) => {
      setDocumentLink(data?.getRecruiterGoalSheet?.goalSheet?.userActivityDownloadLink)
    }
  })

  useEffect(() => {
    if (recruiterId && month && year) {
      getRecruiterFullNameQuery({
        variables: {
          createRecruiterGoalSheetDto: { recruiterId, month, year }
        }
      })
    }
  }, [month, year])

  return (
    <>
      {loading ?
        <Loader />
        :
        <div className="p-5">
          <TableStats startDate={data?.getRecruiterGoalSheet?.goalSheet?.startDate} endDate={data?.getRecruiterGoalSheet?.goalSheet?.periodEndDate} weeks={data?.getRecruiterGoalSheet?.goalSheet?.weeks}
            days={data?.getRecruiterGoalSheet?.goalSheet?.days} earningGoal={EXPECTATIONS_CONSTANT_TABLE[0][data?.getRecruiterGoalSheet?.goalSheet?.highlighter]} />

          <TableGoalSheet periodEndDate={data?.getRecruiterGoalSheet?.goalSheet?.periodEndDate}
            headerText={WHAT_TO_EXPECT_IF} highlighter={data?.getRecruiterGoalSheet?.goalSheet?.highlighter}
            HeadersWithRows={EXPECTATIONS_CONSTANT_TABLE} minExpectation={Number(data?.getRecruiterGoalSheet?.goalSheet?.minExpectationIndex)} />

          <TableGoalSheet periodEndDate={data?.getRecruiterGoalSheet?.goalSheet?.periodEndDate} headerText={EXPECTATION_TARGETS}
            HeadersWithRows={data?.getRecruiterGoalSheet?.goalSheet?.expectationTargets || []}
            TopHeaders={EXPECTATION_TARGETS_TOP_HEADERS} ExpectationTargets={EXPECTATION_TARGETS_ROWS || []} />

          <TableGoalSheet periodEndDate={data?.getRecruiterGoalSheet?.goalSheet?.periodEndDate}
            headerText={BILLING_SUMMARY} HeadersWithRows={[data?.getRecruiterGoalSheet?.goalSheet?.billingSummary || []]}
            TopHeaders={BILLING_SUMMARY_TOP_HEADERS} />

        </div>}
    </>
  )
}
export default GoalSheet

