import { Table } from "reactstrap";
import { moneyFormatter } from "../../../../utils/helper";

const TotalCredit = ({ netCommission, placementSplit }) => {
  return (
    <div>

      <h5 className="mb-2">Total Billing Credit</h5>

      <div className="dark-table">
        <Table className="transparentTable">
          <thead>
            <tr>
              <th style={{ width: "40%" }}>Name</th>
              <th style={{ width: "30%" }}>Credit%</th>
              <th style={{ width: "30%", textAlign: "right" }} align="right">
                Billing Credit
              </th>
            </tr>
          </thead>

          <tbody>
            {placementSplit?.map((item) => {
              return (
                <tr>
                  <td>{item?.user?.fullName}</td>
                  <td>
                    {item?.splitPercentage * 0.5}%
                    {/* simply multiplying by 0.5 because 50% from client billing or candidate billing is taken as 100 in total billing */}
                  </td>
                  <td align="right">
                    {moneyFormatter(
                      (netCommission * 0.5 * item?.splitPercentage) / 100 || 0
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default TotalCredit;
