const CcListOfContacts = ({ ccContactInfo ,index}) => {
    return (
      <div className="jobOrderRevamp">
        <h5>  {`${index}) `}CC Invoice To</h5>
  
        <div className="d-flex justify-content-between mb-2 contentGridLabel">
          <label>First Name</label>
          <h6>{ccContactInfo?.firstName}</h6>
        </div>

  
        <div className="d-flex justify-content-between mb-2 contentGridLabel">
          <label>Last Name</label>
          <h6>{ccContactInfo?.lastName}</h6>
        </div>
  
        <div className="d-flex justify-content-between mb-2 contentGridLabel">
          <label>Street Address</label>
          <h6>{ccContactInfo?.address?.streetAddress}</h6>
        </div>
  
        <div className="d-flex justify-content-between mb-2 contentGridLabel">
          <label>City</label>
          <h6>{ccContactInfo?.address?.city}</h6>
        </div>
  
        {['US',null,undefined,''].includes(ccContactInfo?.address?.country)? (
            <div className="d-flex justify-content-between mb-2 contentGridLabel">
            <label>State</label>
            <h6>{ccContactInfo?.address?.state}</h6>
          </div>
          ) :(
            <div className="d-flex justify-content-between mb-2 contentGridLabel">
            <label>Country</label>
            <h6>{ccContactInfo?.address?.country}</h6>
          </div>
          )}
  
        <div className="d-flex justify-content-between mb-2 contentGridLabel">
          <label>Zip Code</label>
          <h6>{ccContactInfo?.address?.zipCode}</h6>
        </div>
  
        <div className="d-flex justify-content-between mb-2 contentGridLabel">
          <label>Primary Phone</label>
          <h6>{ccContactInfo?.primaryPhone || "--"}</h6>
        </div>
  
        <div className="d-flex justify-content-between contentGridLabel">
          <label>Email Address</label>
          <h6>{ccContactInfo?.email || "--"}</h6>
        </div>
      </div>
    );
  };
  
  export default CcListOfContacts;
  