import { gql } from "@apollo/client";

export const STATUS_ME = gql`
  query userStatsMe {
    userStatsMe {
      message,
      status,
      data{
        timeZone
        limitDay
        limitFrequency
        exclude_holidays
        custom_holidays
        schedule
      }
    }
  }
`;

export const USER_STATUS_UPDATE = gql`
  mutation userStatsUpdate($userStatsInput:userStatsDTO!) {
    userStatsUpdate(userStatsInput:$userStatsInput) {
      message
      status
    }
  }
`;