import {
  Form,
  FormGroup,
  Label,
} from 'reactstrap';
import { Controller, useForm } from 'react-hook-form';
import ReactQuill from 'react-quill';
import CustomButton from '../../components/layout/CustomButton';
import { CREATE_ACTION, CREATE_NOTES } from '../company/gql';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { SUBMISSION_STATUS_CHANGE } from './interviews/gql';
import { JobAppStatusEnum, reactQuillReg } from '../../utils/constant';

import { Sidedrawer, DrawerHeader } from '../../components/layout/Sidedrawer';

export default function ClientCandidateReasonModal({
  modal,
  toggle,
  rejectDetail,
  refetch,
}) {
  const [createNotes, { loading }] = useMutation(CREATE_NOTES);
  const [createActions, { loading: actionLoading }] =
    useMutation(CREATE_ACTION);
  const [updateApplicantsStatusSubmission] = useMutation(
    SUBMISSION_STATUS_CHANGE
  );

  const { control, handleSubmit, errors } = useForm();

  const createNote = async (values: any) => {
    values.noteableId = rejectDetail?.candidate?.id;
    values.noteableType = 'Candidate';
    values.category = `${JobAppStatusEnum[rejectDetail?.JobAppStatus]} - ${rejectDetail?.type
      }`;

    const res = await createNotes({ variables: { ...values } });
    if (res?.data) {
      await onDeclineSubmission();
    }
  };

  const onDeclineSubmission = async () => {
    let forJob = {
      actionableId: rejectDetail?.JobOrder?.id,
      actionableType: 'Job Order',
      content:
        rejectDetail?.type === 'Candidate Decline'
          ? `Candidate ${rejectDetail?.candidate?.fullName} declined on this job.`
          : `Client ${rejectDetail?.getJobOrder?.company?.name} has declined on candidate ${rejectDetail?.candidate?.fullName}`,
    };

    let forCandidate = {
      actionableId: rejectDetail?.candidate?.id,
      actionableType: 'Candidate',
      content:
        rejectDetail?.type === 'Candidate Decline'
          ? `This candidate declined on ${rejectDetail?.getJobOrder?.jobTitle} @ ${rejectDetail?.getJobOrder?.company?.name}`
          : `Client ${rejectDetail?.getJobOrder?.company?.name} (${rejectDetail?.getJobOrder?.jobTitle}) has declined on this candidate.`,
    };

    // For Jobs
    const a = await createActions({
      variables: {
        ...forJob,
      },
    });

    await updateApplicantsStatusSubmission({
      variables: {
        id: rejectDetail?.id,
        status:
          rejectDetail?.type === 'Candidate Decline'
            ? 'CANDIDATE_DECLINE'
            : 'CLIENT_DECLINE',
      },
    });

    // For Candidate
    const b = await createActions({
      variables: {
        ...forCandidate,
      },
    });

    if (a?.data || b.data) {
      toast.success(
        `${rejectDetail?.type} note added`
      );

      toggle();
      refetch();
    }
  };

  const customStyles = {
    control: (styles, provided) => ({
      ...styles,

      fontSize: 12,
    }),

    option: (provided, state) => ({
      ...provided,
      fontSize: 12,
    }),

    container: (provided, state) => ({
      ...provided,
      fontSize: 12,
    }),
  };

  return (
    <Sidedrawer toggle={toggle} isOpen={modal}>
      <div className="mb-3">
        <DrawerHeader
          prevClick={toggle}
          heading={`${rejectDetail?.type} Reason`}
          nextButtonView={false}
        />
      </div>
      <Form>
        <FormGroup>
          <Label className="text-14 text-blackele pb-2">Reason</Label>
          <Controller
            control={control}
            name="content"
            rules={{ required: 'Please add some content!' }}
            defaultValue={''}
            render={({ onChange, ref, value = '' }) => (
              <div className="editor-height">
                <ReactQuill
                  onChange={(value) => {
                    if (reactQuillReg.test(value)) {
                      onChange('');
                    } else {
                      onChange(value);
                    }
                  }}
                  value={value}
                  ref={ref}
                />
              </div>
            )}
            styles={customStyles}
          />
          <small className="text-danger pt-2">{errors?.content?.message}</small>
        </FormGroup>
        <div className="text-right">
          <CustomButton
            buttonText="Decline"
            buttonColor={'primary'}
            disabled={loading || actionLoading}
            loading={loading || actionLoading}
            buttonType="button"
            buttonClick={handleSubmit(createNote)}
          />
        </div>
      </Form>
    </Sidedrawer>
  );
}
