import { useLazyQuery, useMutation } from '@apollo/client';
import { useContext, useEffect, useState } from 'react';
import './jobOrder.scss';
import { toast } from 'react-toastify';
import {
  Button,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Badge,
  Tooltip,
} from 'reactstrap';
import ConfirmPopUp from '../../components/ConfirmPopUp';
import GoBack from '../../components/GoBack';
import InputField from '../../components/layout/InputField';
import Loader from '../../components/layout/Loader';
import NoDataFound from '../../components/layout/NoDataFound';
import Pagination from '../../components/layout/Pagination';
import {
  compactObject,
  downloadSignedUrlFile,
  formatDateUS,
} from '../../utils/commonFn';
import {
  APPLICANT_STATUS,
  JOB_APPLICANTS_HEADER,
  jobApplicantStatusForCandidate,
  PAGE_LIMIT,
  VIEW_CANDIDATE_ROUTE,
  JOB_APPLICANTS_ROUTE,
  COMPANY_DETAILS_ROUTE,
  VIEW_JOB_APPLICANT_ROUTE,
  VIEW_JOB_ORDER_ROUTE,
} from '../../utils/constant';
import CustomButton from '../../components/layout/CustomButton';
import {
  GET_JOB_ORDER_LIGHT,
  GET_JOB_ORDER_STATS,
  UPDATE_APPLICANT_UPDATE,
  UPDATE_APPLICANTS_STATUS,
  GET_JOB_APPLICANTS_INCLUDING_APPLIED,
  GET_JOB_ORDER_MAIN_CONTACT,
} from './gql';
import { CheckJobApplicantComponent } from './JobApplicantCheckboxList';
import { Link, useHistory, useLocation } from 'react-router-dom';
import AddRecruiterFolderModal from '../recruiter-folder/folderComponents/AddRecruiterFolderModal';
import ClientCandidateReasonModal from '../candidate-pipeline/ClientCandidateReasonModal';
import { IComingState } from '../agreements/interfaces';

import TableComponent from '../../components/layout/TableComponent';

import INFO_ICON_1 from '../../assets/images/info-icon-1.svg';
import INFO_ICON_2 from '../../assets/images/info-icon-2.svg';
import INFO_ICON_3 from '../../assets/images/info-icon-3.svg';
import SEARCH_IMG from '../../assets/images/search.svg';
import ACTION_ICON_2 from '../../assets/images/application-icon-2.svg';
import SUBMIT_ICON from '../../assets/images/submit-icon.svg';
import ACTION_ICON_4 from '../../assets/images/application-icon-4.svg';
import AddNotesModal from '../../components/AddNotesModal';
import { GET_NOTES } from '../company/gql';
import VIEW_ICON from '../../assets/images/view-icon.svg';
import ADD_ICON from '../../assets/images/add-icon.svg';
import RecruiterCandidateSubmitModal from '../recruiter-folder/folderComponents/RecruiterCandidateSubmitModal';
import Select from 'react-select';
import { AppContext } from '../../context';
import { pushInHistory } from '../../utils/helper';

let time = 0;

const JobApplicantActions = ({
  item,
  setActionData,
  recruiterFolderToggle,
  applicant,
  jobMainContact,
  setJobApplicantId,
  setActivityNotes,
  candidateId,
}) => {
  const [jobApply, setJobApply] = useState<boolean>(false);

  const [tooltipOpen3, setTooltipOpen3] = useState(false);
  const [tooltipOpen4, setTooltipOpen4] = useState(false);
  const [tooltipOpen5, setTooltipOpen5] = useState(false);
  const [tooltipOpen6, setTooltipOpen6] = useState(false);
  const [tooltipOpen7, setTooltipOpen7] = useState(false);

  const toggle3 = () => setTooltipOpen3(!tooltipOpen3);
  const toggle4 = () => setTooltipOpen4(!tooltipOpen4);
  const toggle5 = () => setTooltipOpen5(!tooltipOpen5);
  const toggle6 = () => setTooltipOpen6(!tooltipOpen6);
  const toggle7 = () => setTooltipOpen7(!tooltipOpen7);

  const singleResumeDownload = (resumeList: any) => {
    const { originalUrl, resumeFileName } = resumeList?.find(
      (item: { originalUrl: string }) => item.originalUrl
    );

    downloadSignedUrlFile(originalUrl, resumeFileName);
  };

  const onApplyJob = () => setJobApply(!jobApply);

  return (
    <div className="d-flex align-items-center justify-space-between flex-wrap gap-10">
      <div
        className="cursor-pointer"
        onClick={() => singleResumeDownload(item?.candidate?.resume)}
      >
        <Tooltip
          placement="top"
          isOpen={tooltipOpen3}
          target={`download-primary-resume${item?.id}`}
          toggle={toggle3}
        >
          Download Primary Resume
        </Tooltip>

        <img
          src={ACTION_ICON_2}
          alt="icon"
          id={`download-primary-resume${item?.id}`}
        />
      </div>

      <div className="cursor-pointer" onClick={onApplyJob}>
        <Tooltip
          placement="top"
          isOpen={tooltipOpen4}
          target={`submit-${item?.id}`}
          toggle={toggle4}
        >
          Submit
        </Tooltip>

        <img
          src={SUBMIT_ICON}
          alt="icon"
          width="16px"
          id={`submit-${item?.id}`}
        />
      </div>

      <div
        className="cursor-pointer"
        onClick={() => {
          setActionData([
            {
              userId: item?.id,
              url:
                item?.candidate?.emails?.find(
                  (item: { isPrimary: boolean }) => item.isPrimary
                )?.email || item?.candidate?.linkedInUrl,
              name: item?.candidate?.fullName,
              jobTitle: item?.candidate?.idealJobTitle,
              employment: item?.candidate?.employmentHistory,
              candidateId: item?.candidate?.id,
            },
          ]);
          recruiterFolderToggle();
        }}
      >
        <Tooltip
          placement="top"
          isOpen={tooltipOpen5}
          target={`add-recruitre-folder-${item?.id}`}
          toggle={toggle5}
        >
          Add to Recruiter Folder
        </Tooltip>

        <img
          src={ACTION_ICON_4}
          alt="icon"
          id={`add-recruitre-folder-${item?.id}`}
        />
      </div>

      <div className="cursor-pointer">
        <Tooltip
          placement="top"
          isOpen={tooltipOpen6}
          target={`view-notes-${item?.id}`}
          toggle={toggle6}
        >
          View Notes
        </Tooltip>

        <Link
          target="_blank"
          to={{
            pathname: `/notes-es?${new URLSearchParams(
              compactObject({
                jobOrderId: applicant?.id,
                jobTitle: applicant?.jobTitle,
                companyId: applicant?.company?.id,
                companyName: applicant?.company?.name,
                candidateId: item.candidate?.id,
                candidateName: item.candidate?.fullName,
                contactId: jobMainContact?.getJobOrderMainContact?.contact?.id,
                contactName:
                  jobMainContact?.getJobOrderMainContact?.contact?.fullName,
              })
            ).toString()}`,
          }}
        >
          <img
            src={VIEW_ICON}
            alt="delete"
            id={`view-notes-${item?.id}`}
            width="21px"
          />
        </Link>
      </div>

      <div
        className="cursor-pointer"
        onClick={() => {
          setJobApplicantId(item?.id);
          setActivityNotes(true);
        }}
      >
        <Tooltip
          placement="top"
          isOpen={tooltipOpen7}
          target={`add-notes-${item?.id}`}
          toggle={toggle7}
        >
          Add Note
        </Tooltip>

        <img
          src={ADD_ICON}
          alt="add"
          width="23px"
          id={`add-notes-${item?.id}`}
        />
      </div>

      <RecruiterCandidateSubmitModal
        BackURL={`${VIEW_CANDIDATE_ROUTE}/${candidateId}`}
        toggle={onApplyJob}
        open={jobApply}
        candidate={candidateId}
      />
    </div>
  );
};

const JobApplicantView = ({ match }: any) => {
  interface actionInterface {
    userId?: String;
    url?: String;
    name?: string;
    jobTitle?: string;
    employment?: Array<any>;
    candidateId?: string;
  }
  const history = useHistory();
  const location = useLocation<IComingState>();

  const comingState = location.state;
  const { theme } = useContext(AppContext);
  const page = new URLSearchParams(location.search).get('page') || '1';
  const _currentPage: number = parseInt(page);
  const [rejectNoteModal, setRejectNoteModal] = useState<boolean>(false);
  const [rejectData] = useState<any>(null);
  const [activityNotes, setActivityNotes] = useState<boolean>(false);
  const [noteData, setNoteData] = useState(null);
  const [currentPage, setCurrentPage] = useState(_currentPage);
  const [limit, setLimit] = useState(10);
  const [actionData, setActionData] = useState<actionInterface[]>([]);
  const [confirmBox, setConfirmBox] = useState(false);
  const [jobOrderId, setJobOrderId] = useState('');
  const [applicantId] = useState('');
  const [applicantStatus] = useState('');
  const [currentBulkAction, setCurrentBulkAction] = useState('');
  const [jobApplicantId, setJobApplicantId] = useState('');
  const [statusFilter, setStatusFilter] = useState<Array<string>>([]);
  const [recruiterFolderModal, setRecruiterFolderModal] = useState(false);
  const recruiterFolderToggle = () =>
    setRecruiterFolderModal(!recruiterFolderModal);
  const [updateApplicantStatus] = useMutation(UPDATE_APPLICANT_UPDATE);
  const [updateApplicantsStatus] = useMutation(UPDATE_APPLICANTS_STATUS);
  const [getJobOrder, { data }] = useLazyQuery(GET_JOB_ORDER_LIGHT);
  const [getStats] = useLazyQuery(GET_JOB_ORDER_STATS);
  const [searchParams, setSearchParams] = useState<string>('');
  const [getJobApplicants, { data: jobApplicantsList, loading: waiting }] =
    useLazyQuery(GET_JOB_APPLICANTS_INCLUDING_APPLIED);
  const [getNotes] = useLazyQuery(GET_NOTES);
  const [getJobMainContactQuery, { data: jobMainContact }] = useLazyQuery(
    GET_JOB_ORDER_MAIN_CONTACT
  );

  const getJobOrderApplicants = () => {
    let filter = statusFilter?.map((item: any) => item?.value);
    getJobMainContactQuery({ variables: { id: jobOrderId } });
    getJobApplicants({
      variables: {
        limit,
        page: currentPage,
        id: jobOrderId,
        status: filter?.length ? filter : 'All',
        searchParams: '',
      },
    });
  };

  const setQueryParams = (searchParams) => {
    const criteria = compactObject({
      currentPage,
      searchParams,
      statusFilter,
    });
    const urlQueryParams: any = [];
    if (Object.keys(criteria).length) {
      for (const key in criteria) {
        if (
          Object.prototype.hasOwnProperty.call(criteria, key) &&
          criteria[key]
        ) {
          urlQueryParams.push(`${key}=${criteria[key]}`);
        }
      }
      history.push(
        `${VIEW_JOB_APPLICANT_ROUTE}/${data?.getJobOrder?.id}?${urlQueryParams.join(
          '&'
        )}`
      );
    }
  };

  const searchControl = (value: string) => {
    setQueryParams(value);
    setSearchParams(value);
    setCurrentPage(1);
    if (!value || value?.length > 2) {
      window.clearTimeout(time);
      time = window.setTimeout(() => {
        if (data?.getJobOrder) {
          getJobApplicants({
            variables: {
              limit,
              page: currentPage,
              id: jobOrderId,
              status: statusFilter,
              searchParams: value,
            },
          });
        }
      }, 1000);
    }
  };

  const handleBulkAction = async () => {
    const applicantIds = actionData.map((data) => {
      return data.userId;
    });

    let res = await updateApplicantsStatus({
      variables: { data: { applicantIds, status: currentBulkAction } },
    });

    if (!!res?.data) {
      setConfirmBox(false);
      toast.success('Successfully Updated!');
      getJobOrderApplicants();
    }
    setCurrentBulkAction('');
    setActionData([]);
    getJobOrderApplicants();
  };

  const updateJobApplicant = async () => {
    let res = await updateApplicantStatus({
      variables: { applicantId, status: applicantStatus },
    });

    if (res?.data) {
      setConfirmBox(false);
      toast.success('Successfully Updated!');
      getJobOrderApplicants();
    }
  };

  useEffect(() => {
    let id = match?.params?.id;
    setJobOrderId(id);
    if (id) {
      document.title = 'Loading...';
      getJobOrder({ variables: { id } });
      getStats({ variables: { jobOrderId: id } });
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (statusFilter || currentPage) getJobOrderApplicants();

    // eslint-disable-next-line
  }, [statusFilter, limit, currentPage]);

  useEffect(() => {
    if (jobOrderId) {
      getJobApplicants({
        variables: {
          limit,
          page: currentPage,
          id: jobOrderId,
          status: 'All',
          searchParams: '',
        },
      });
    }

    // eslint-disable-next-line
  }, [jobOrderId]);

  useEffect(() => {
    if (data?.getJobOrder?.jobTitle)
      document.title = `${data?.getJobOrder?.jobTitle || 'Job'
        } - Applicants -RealREPP`;

    // eslint-disable-next-line
  }, [data?.getJobOrder?.jobTitle]);

  const applicant = data?.getJobOrder;
  // eslint-disable-next-line
  const filteredObj = actionData?.filter((item) => {
    if (item?.url) {
      if (!item?.url?.includes('linkedin')) {
        return item;
      }
    }
  });

  const filteredEmails = filteredObj.map((item) => {
    return item?.url;
  });

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  const [tooltipOpen1, setTooltipOpen1] = useState(false);
  const toggle1 = () => setTooltipOpen1(!tooltipOpen1);

  const [tooltipOpen2, setTooltipOpen2] = useState(false);
  const toggle2 = () => setTooltipOpen2(!tooltipOpen2);

  const toggleActivityNotes = () => {
    setActivityNotes(!activityNotes);
  };

  const customStyles = {
    control: (styles, provided) => ({
      ...styles,
      background: theme === 'light' ? provided.background : '#282828',
      borderColor: theme === 'light' ? '#ced4da' : '#282828',
      color: theme === 'light' ? provided.background : '#d9d9d9',
      fontSize: 12,
      '&:hover': {
        borderColor: theme === 'light' ? '#ced4da' : '#282828',
        color: theme === 'light' ? provided.background : '#d9d9d9',
      },
    }),
    menu: (provided) => ({
      ...provided,
      background: theme === 'light' ? provided.background : '#282828',
      zIndex: 999999,
      color: theme === 'light' ? provided.background : '#d9d9d9',
    }),
    option: (provided) => ({
      ...provided,
      fontSize: 12,
      background: theme === 'light' ? provided.background : '#363636',
      '&:hover': {
        background: theme === 'light' ? provided.background : '#474444',
        color: theme === 'light' ? provided.background : '#d9d9d9',
      },
    }),
    container: (provided, state) => ({
      ...provided,
      fontSize: 12,
      minWidth: 200,
      maxWidth: 'auto',
    }),
  };

  return (
    <div>
      <GoBack url={JOB_APPLICANTS_ROUTE} search={comingState?.searchParameters} />

      <div className="primaryHeading">
        {!!applicant?.jobTitle ?
          <h5 className="m-0 route-link d-inline"
            onClick={() => {
              history.push({
                pathname: `${VIEW_JOB_ORDER_ROUTE}/${applicant?.id}`,
                state: {
                  backUrl: `${VIEW_JOB_APPLICANT_ROUTE}/${match.params.id}`,
                  searchParameters: location.search
                }
              })
              pushInHistory(`${VIEW_JOB_APPLICANT_ROUTE}/${match.params.id}`, location.search)
            }
            }
          >{applicant?.jobTitle}</h5> : "--"
        }
      </div>

      <div className="applicantDetail primaryHeaderSpacing">
        <div className="infoCardDivider">
          <Tooltip
            placement="top"
            isOpen={tooltipOpen}
            target="TooltipExample"
            toggle={toggle}
          >
            Company
          </Tooltip>

          <img src={INFO_ICON_1} alt="company" id="TooltipExample" />
          {applicant?.company?.name ?
            <Link to={{
              pathname: `${COMPANY_DETAILS_ROUTE}/${applicant?.company?.id}`,
              state: {
                backUrl: `${VIEW_JOB_APPLICANT_ROUTE}/${jobOrderId}`
              }
            }}
              onClick={() => pushInHistory(`${VIEW_JOB_APPLICANT_ROUTE}/${jobOrderId}`)}
              className="m-0"
            >
              <label className="route-link">{applicant?.company?.name}</label>
            </Link>
            : <label>--</label>
          }
        </div>

        <div className="divider"></div>

        <div className="infoCardDivider">
          <Tooltip
            placement="top"
            isOpen={tooltipOpen1}
            target="TooltipExample1"
            toggle={toggle1}
          >
            Job Location
          </Tooltip>

          <img src={INFO_ICON_2} alt="company" id="TooltipExample1" />

          <label>
            {['US',null,undefined,''].includes(applicant?.companyLocation?.country)? applicant?.companyLocation?.streetAddress?`${applicant?.companyLocation?.streetAddress}, `:'--' +applicant?.companyLocation?.city?`${applicant?.companyLocation?.city}` : "--" + applicant?.companyLocation?.state?applicant?.companyLocation?.state :"--": applicant?.companyLocation?.streetAddress?`${applicant?.companyLocation?.streetAddress},`:"--" + applicant?.companyLocation?.city?`${applicant?.companyLocation?.city}, `:"--" +  applicant?.companyLocation?.country? applicant?.companyLocation?.country:"--"}
           
          </label>
        </div>

        <div className="divider"></div>

        <div className="infoCardDivider">
          <Tooltip
            placement="top"
            isOpen={tooltipOpen2}
            target="TooltipExample2"
            toggle={toggle2}
          >
            Total Applicant Count
          </Tooltip>

          <img src={INFO_ICON_3} alt="company" id="TooltipExample2" />

          <label>
            {!waiting
              ? jobApplicantsList?.getApplicantsIncludingApplied?.count
              : Loader}
          </label>
        </div>
      </div>

      <div className="search-filter primaryHeaderSpacing">
        <div>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <img src={SEARCH_IMG} alt="search" />
              </InputGroupText>
            </InputGroupAddon>

            <Input
              type="text"
              className="search-by-company"
              placeholder="Search by name or email"
              value={searchParams}
              onChange={(e) => {
                searchControl(e.target.value);
                setSearchParams(e.target.value);
              }}
            />
          </InputGroup>

          <small className="text-info">
            {searchParams &&
              searchParams.length <= 2 &&
              ' Please add more than two characters'}
          </small>
        </div>

        <div className="filter-status">
          <div className="filter-by">
            <div className="pl-sm-3 jobapp-category-dropdown mt-md-0">
              <Select
                isMulti
                isSearchable
                isClearable
                styles={customStyles}
                options={jobApplicantStatusForCandidate}
                value={statusFilter?.length ? statusFilter : null}
                onChange={(val: any) => {
                  setStatusFilter(val);
                  setCurrentPage(1);
                }}
              />
            </div>

            <Button
              className="buttonGenericStyle filledButton ml-3"
              onClick={() => {
                setStatusFilter([]);
                setSearchParams('');
              }}
            >
              Clear
            </Button>
          </div>
        </div>
      </div>

      {actionData?.length > 0 && (
        <div className="sticky-action-container mt-3">
          <div className="d-selected-num bulk_actions_label">Bulk Actions:</div>
          <div className="d-selected-num">{actionData?.length} Selected</div>
          <div className="sticky-action-btns">
            {filteredEmails.length ? (
              <a
                href={
                  filteredEmails?.length
                    ? `mailto:${filteredEmails.join(',')}`
                    : '#'
                }
                className="d-block bg-light text-dark p-2 py-1 btn-sm"
              >
                Send Email
              </a>
            ) : (
              <button
                type="button"
                disabled={true}
                className="d-block p-2 py-1 btn btn-primary"
              >
                Send Email
              </button>
            )}
            <Button
              className="mx-3 d-block px-3 py-1 border-0 buttonGenericStyle filledButton"
              onClick={() => recruiterFolderToggle()}
            >
              Add to Recruiter Folder
            </Button>
          </div>

          <InputField
            style={{ minWidth: 'max-content' }}
            className="save-changes"
            inputtype="select"
            selectItems={APPLICANT_STATUS}
            inputid="currentBulkAction"
            disabled={!!actionData?.length}
            inputValue={currentBulkAction}
            placeholder="Status Update"
            inputMethod={(value) => {
              setCurrentBulkAction(value);
            }}
          />

          <CustomButton
            buttonText="Update"
            buttonType="button"
            buttonColor="primary"
            disabled={!(currentBulkAction && !!actionData?.length)}
            className="save-changes ml-3 mr-2"
            buttonClick={handleBulkAction}
          />
        </div>
      )}

      <div className="table-responsive">
        <TableComponent tableHeader={JOB_APPLICANTS_HEADER}>
          <tbody>
            {!waiting ? (
              jobApplicantsList?.getApplicantsIncludingApplied?.jobApplicants
                ?.length ? (
                jobApplicantsList?.getApplicantsIncludingApplied?.jobApplicants.map(
                  (item: any, index: number) => (
                    <tr key={index}>
                      <td>
                        <div className="tableCheckbox">
                          <CheckJobApplicantComponent
                            item={item}
                            actionData={actionData}
                            setActionData={setActionData}
                            forInterview={false}
                          />
                        </div>
                      </td>

                      <td>
                        <Link onClick={(() => pushInHistory(`${VIEW_JOB_APPLICANT_ROUTE}/${match.params.id}`, location.search))}
                          to={`${VIEW_CANDIDATE_ROUTE}/${item?.candidate?.id}`}>
                          {item?.candidate?.fullName}
                        </Link>
                      </td>

                      <td>
                        {item?.candidate?.jobTitle ||
                          item?.candidate?.recentEmployment?.jobTitle ||
                          '--'}
                      </td>

                      <td>
                        {item?.candidate?.companyName ||
                          item?.candidate?.recentEmployment?.companyName ||
                          '--'}
                      </td>

                      <td>{item?.candidate?.source}</td>
                      <td>
                        {item?.candidate?.recruiterOwner?.fullName || '--'}
                      </td>

                      <td>
                        <Badge color="primary" className="text-capitalize">
                          {item?.JobAppStatus || '--'}
                        </Badge>
                      </td>

                      <td>
                        {item?.createdAt ? formatDateUS(item?.createdAt) : '--'}
                      </td>

                      <td>
                        <JobApplicantActions
                          item={item}
                          setActionData={setActionData}
                          recruiterFolderToggle={recruiterFolderToggle}
                          applicant={applicant}
                          setActivityNotes={setActivityNotes}
                          jobMainContact={jobMainContact}
                          setJobApplicantId={setJobApplicantId}
                          candidateId={item?.candidate?.id}
                        />
                      </td>
                    </tr>
                  )
                )
              ) : (
                <tr>
                  <td colSpan={12} className="empty-table-td">
                    <NoDataFound text="No Applicant Found!" />
                  </td>
                </tr>
              )
            ) : (
              <tr>
                <td colSpan={JOB_APPLICANTS_HEADER.length}>
                  <Loader />
                </td>
              </tr>
            )}
          </tbody>
        </TableComponent>
      </div>

      {!waiting &&
        jobApplicantsList?.getApplicantsIncludingApplied?.jobApplicants
          ?.length ? (
        <div className="users-pagination">
          <Pagination
            onPageChange={(pageClicked: number) => {
              setCurrentPage(pageClicked);
            }}
            pageCount={Math.ceil(
              jobApplicantsList?.getApplicantsIncludingApplied?.count / limit
            )}
            currentPage={currentPage}
          />

          <div className="d-flex align-items-center">
            <div className="pagination-display paginationItemsSelect">
              <p className="m-0">
                {(currentPage - 1) * limit + 1} to{' '}
                {Math.min(
                  currentPage * limit,
                  jobApplicantsList?.getApplicantsIncludingApplied?.count
                )}{' '}
                of {jobApplicantsList?.getApplicantsIncludingApplied?.count}
              </p>

              <div className="divider"></div>
              <label>Display</label>

              <InputField
                label=""
                inputtype="select"
                selectItems={PAGE_LIMIT.filter((item) => item.value !== limit)}
                inputid="limit"
                placeholder={limit.toString()}
                inputMethod={(value: string) => {
                  setQueryParams(searchParams);
                  setCurrentPage(1);
                  setLimit(parseInt(value));
                }}
              />
            </div>
          </div>
        </div>
      ) : (
        ''
      )}

      {recruiterFolderModal && (
        <AddRecruiterFolderModal
          isOpen={recruiterFolderModal}
          toggle={recruiterFolderToggle}
          recordToAdd={actionData}
          refetch={getJobOrderApplicants}
          clearRecord={() => setActionData([])}
        />
      )}

      <ClientCandidateReasonModal
        refetch={() => {
          getJobApplicants({
            variables: {
              limit,
              page: currentPage,
              id: match?.params?.id,
              status: '',
              searchParams,
            },
          });
        }}
        modal={rejectNoteModal}
        toggle={() => {
          setRejectNoteModal(!rejectNoteModal);
        }}
        rejectDetail={rejectData}
      />

      <AddNotesModal
        toggleActivityNotes={toggleActivityNotes}
        activityNotes={activityNotes}
        noteableId={jobApplicantId}
        reFetch={() => {
          getNotes({
            variables: {
              id: jobApplicantId,
              notePayload: {
                type: 'Job Applicant',
              },
              page: 1,
              limit: 3,
            },
          });
        }}
        type="Job Applicant"
        editData={noteData}
        resetField={setNoteData}
      />

      <ConfirmPopUp
        confirmText="Are you sure you want to change this applicant status?"
        isOpen={confirmBox}
        toggle={setConfirmBox}
        confirmAction={updateJobApplicant}
        modalHeading="Applicant Status"
        btnText="Confirm"
        btnColor="primary"
      />
    </div>
  );
};

export default JobApplicantView;
