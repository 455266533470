import { gql } from "@apollo/client";

export const SAVE_TEMPLATE_MUTATION = gql`
mutation($input: SaveRecruiterDealTemplatesDTO!) {
  saveRecruiterDealTemplate(input: $input) {
    message
    status
  }
}`

export const GET_TEMPLATE_QUERY = gql`
query ($dealType:String!){
  getRecruiterDealTemplate (dealType:$dealType){
    recruiterDealsTemplates {
      id
      subject
      body
      compensationTerms
      commissionPaid
      biweeklySalary
      dhBaseRate
      tempBaseRate
      conditions
      dealType
      createdAt
      createdAt
    }
    message
    status
  }
}`
