import { Button, Col, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import CustomButton from "../../layout/CustomButton"
import { FC, useEffect, useState } from "react"
import { InvoiceEmailModalProps } from "../../../interfaces"
import { toast } from "react-toastify"
import { Controller, useForm } from "react-hook-form"
import { INVOICE_EMAIL_CONTENT, INVOICE_NUMBER } from "../../../utils/constant"
import { useMutation } from "@apollo/client"
import { ADD_INVOICE_NUMBER } from "../../../Pages/AccountingDashboard/gql"

const InvoiceEmailModal: FC<InvoiceEmailModalProps> = ({ isOpen, refetch,
  toggle, jobTitle, candidateName, invoiceNumber, placementId, companyName, city, state }) => {

  // useStates and useForm Hook
  const [currentInvoiceNumber, setInvoiceNumber] = useState<string>()
  const { control, setValue, errors, handleSubmit } = useForm<{ invoiceNumber: string }>()

  // apollo mutation
  const [addInvoiceNumberMutation, { loading }] = useMutation(ADD_INVOICE_NUMBER)

  const emailSubject =
    `Invoice #${currentInvoiceNumber || '___'} - ${jobTitle} - ${candidateName}\n\n`;
  const emailBody =
    `Good afternoon!<br><br>We are very excited for you and ${companyName} in the addition of your new Team member!<br><br>Name: ${candidateName}<br>Title: ${jobTitle}<br>Location: ${city}, ${state}<br><br>We appreciate you being a valued client and we look forward to continued success working with you and ${companyName}. Please let us know if there is anything we can do for you moving forward. We hope you have a wonderful rest of the week!<br><br>Attached please find Invoice #${currentInvoiceNumber || '___'} (Please confirm receipt of invoice).`

  // useEffect hook to clear old values when modal closes
  useEffect(() => {
    if (isOpen) {
      setInvoiceNumber(invoiceNumber)
    }
  }, [isOpen, invoiceNumber])

  const addInvoiceNumber = async () => {
    try {
      if (!currentInvoiceNumber) {
        toast.error('Invoice number required!');
      }
      else {
        const res = await addInvoiceNumberMutation({
          variables: {
            placementId,
            invoiceNumber: currentInvoiceNumber
          }
        })
        if (res?.data?.addInvoiceNumber?.status === 201) {
          toast.success('Invoice number saved!');
        }
      }
    }
    catch {
      toast.error('Unable to save invoice number!');
    }
  }

  const copyToClipboard = async () => {
    try {
      const textarea = document.createElement('textarea');
      textarea.value = emailSubject + emailBody.replace(/<br>/g, '\n');;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand('copy');
      document.body.removeChild(textarea);
      toast.success('Text copied to clipboard!');
      toggle()
      refetch()
    }
    catch (error) {
      toast.error('Unable to copy!');
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={() => {
      toggle()
      refetch()
    }} className="for-modal-styling">
      <ModalHeader>{INVOICE_EMAIL_CONTENT}</ModalHeader>
      <ModalBody>
        <div className="approval-content">
          <Col xs="5" className="mb-4 p-0">
            <Label>{INVOICE_NUMBER} <span className="text-danger">*</span></Label>
            <Controller
              name="invoiceNumber"
              defaultValue={currentInvoiceNumber || ""}
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Please Enter the invoice number!"
                }
              }}
              render={(field) => (
                <Input
                  {...field}
                  type="number"
                  placeholder="invoice number"
                  onBlur={addInvoiceNumber}
                  onChange={(e) => {
                    setValue("invoiceNumber", e.target.value.replace(/\D/g, '').slice(0, 8))
                    setInvoiceNumber(e.target.value.replace(/\D/g, '').slice(0, 8))
                  }}
                />
              )}
            />
            <small className="text-danger">
              {errors?.invoiceNumber?.message}
            </small>
          </Col>
          <p>
            {emailSubject}
          </p>
          <p dangerouslySetInnerHTML={{ __html: emailBody }} />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button className="modal-close bg-transparent border-0 text-dark" onClick={() => {
          refetch()
          toggle()
        }}>Cancel</Button>
        <CustomButton buttonType="button" buttonText={"Copy to Clipboard"} buttonColor={"primary"}
          loading={loading}
          buttonClick={handleSubmit(copyToClipboard)} />
      </ModalFooter>
    </Modal>
  )
}
export default InvoiceEmailModal