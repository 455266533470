import { yupResolver } from '@hookform/resolvers/yup';
import { useContext, useEffect, useState } from 'react';
import {
  Controller,
  FormProvider, useForm,
  SubmitHandler
} from 'react-hook-form';
import Select from 'react-select';
import { toast } from 'react-toastify';
import {
  Col,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap';
import CustomButton from '../../components/layout/CustomButton';
import { customSelectStyleNew } from '../../components/styled/customSelect';
import { AppContext } from '../../context';
import { bucketFolders, customStylesReportEditDropdown, realReppDocumentCategory } from '../../utils/constant';
import { addNewDocument, updateDocument } from './api';
import { RealReppDocumentEnum } from '../../constants';
import { updateFileName } from '../../utils/helper';
import { RealReppDocsSchema } from '../../validations';
import { AxiosResponse } from 'axios';

const UpsertRealReppDocumentModal = ({
  toggle,
  isOpen,
  refetchData,
  modalTitle ,
  realReppDocumentObj,
  setIsEdit,
  isEdit
}) => {
  const [loading , setLoading] = useState(false)
  const { theme } = useContext(AppContext);
  const { id , name , link , category } =realReppDocumentObj;
 
  const methods = useForm({
    defaultValues: {  name: name, file: link , category: isEdit ? { label: RealReppDocumentEnum[category], value: category} : realReppDocumentCategory[0] },
    resolver: yupResolver(RealReppDocsSchema(isEdit)),
  });
  const { control, reset, handleSubmit, setValue, formState: { errors } } = methods;

  useEffect(() => {
    if(isEdit && id){
      reset({
        name: name,
        file: null,
        category:  { label: RealReppDocumentEnum[category], value: category}, 
      })
    }
    else{
      reset({
        name: name,
        file: link,
        category:  realReppDocumentCategory[0], 
      })
    }
  },[category, id, isEdit, link, name, reset])

  const submit: SubmitHandler<any> = async (data) => {
    setLoading(true)
    const { category, name, file } = data;
    let updatedData = { id , category: category.value , name };
    
    if (file instanceof File) {
      // Create a new File object with the modified name
      const updatedFile = new File([file], updateFileName(file.name), { type: file.type });
      updatedData["file"] = updatedFile;
    }
  
    updatedData["folderName"]= bucketFolders.documents;
    let response:AxiosResponse | undefined;
    try{
      if (isEdit) {
        response = await updateDocument(updatedData);
      } else {
        response = await addNewDocument({
          name, 
          category : category?.value, 
          file: new File([file], updateFileName(file.name), { type: file.type }), 
          folderName: bucketFolders.documents
        });
      }
      if ([201 , 200].includes(response?.data?.status)) {
        setLoading(false)
        toggle();
        reset();
        refetchData();
        toast.success(`Document ${isEdit ? 'Updated' : 'Saved'} Successfully!`);
      } else {
        setLoading(false)
        toggle();
        reset();
        toast.error(response?.data?.message);
      }
    }
    catch(error){
      toast.error(error)
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className="revampDialog revampDialogWidth"
      centered
      >
      <ModalHeader className="py-3">
        <h4>{modalTitle}</h4>
      </ModalHeader>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(submit)}>
          <ModalBody className="pt-2 pb-2">
            <Row className='mb-3'>
            <Col>
                <Controller
                  name='name'
                  control={control}
                  render={(
                    { onChange, ref, name, value },
                    { invalid }
                  ) => (
                    <div>
                      <Label>
                        Document Name
                        <span className="text-danger"> *</span>
                      </Label>

                      <input
                        name={name}
                        value={value}
                        ref={ref}
                        placeholder="Add Document Name..."
                        onChange={(e) => {
                          onChange(e);
                        }}
                        className="form-control"
                      />
                      {invalid && (<div className="error">{errors?.name?.message}</div>)}
                    </div>
                  )}
                />
              </Col>

              <Col>
                <Controller
                  name='file'
                  control={control}
                  render={(
                    { onChange, ref, name },
                    { invalid }
                  ) => (
                    <div>
                      <Label>
                        Upload Document
                        <span className="text-danger"> *</span>
                      </Label>
                      <input
                        name={name}
                        ref={ref}
                        type="file"
                        accept='.pdf'
                        placeholder={`${isEdit ? `Update` : `Add`} Document pdf...`}
                        onChange={(e :  React.ChangeEvent<HTMLInputElement>) => {
                          const file: File | null = e.target.files?.[0] || null;
                          if (file) {
                            onChange(file);
                          } else {
                            onChange(null);
                          }
                        }}
                        className="form-control"
                      />
                      {invalid && (<div className="error">{
                        !!errors && errors.file && errors.file['message'] ? errors.file['message'] : "custom error"
                      }</div>)}
                    </div>
                  )}
                />
              </Col> 
            </Row>

            <Row>
              <Col>
                <Controller
                  name='category'
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: 'Must Select Category!',
                    },
                  }}
                  placeHolder="Select report category"
                  render={({value}) => (
                    <>
                      <Label>
                        Document Category
                        <span className="text-danger"> *</span>
                      </Label>
                      <Select
                        value={value}
                        options={realReppDocumentCategory}
                        onChange={(object) => {
                          setValue('category', object)
                        }}
                        styles={{ ...customSelectStyleNew(theme) }}
                        />
                    </>
                  )}
                  styles={customStylesReportEditDropdown}
                  />
              </Col>
            </Row>

          </ModalBody>
          <ModalFooter className='p-0'>
            <div className="d-flex justify-content-end align-items-center w-100">
                <button
                  className="btn btn-primary buttonPrimary m-2"
                  onClick={() => {
                    if(isEdit){
                      setIsEdit(!isEdit);
                    }
                    reset();
                    toggle();
                  }}
                  type="button"
                >
                  Cancel
                </button>
                <CustomButton
                  buttonText={ isEdit ? "Update": "Save"}
                  buttonColor="default"
                  buttonType="submit"
                  className="btn-primary py-2 m-3"
                  disabled={loading}
                />
            </div>
          </ModalFooter>
        </form>
      </FormProvider>
    </Modal>
  );
};

export default UpsertRealReppDocumentModal;