import React from 'react'
import { Spinner, Tooltip } from 'reactstrap';

const ContactLstingToolTip = ({ index, setErrorClearModal, setErrorClearId, item, errorClearId,
  errorClearModal, checkErrorContact, checkBelowErrorMessageContact, loadingErrorClearing, clearingErrorsHandler
}: any) => {
  const [activeToolTip, setActiveToolTip] = React.useState<boolean>(false);
  const toggles = () => setActiveToolTip(!activeToolTip);
 

  const displayToolTip = (errorType: string) => {
    switch (errorType) {
      case 'BLOCKED_CONTACT':
        return false
      case 'TEAM_BLOCKED_CONTACT':
        return false
      default:
        return true
    }
  }

  return (
    <div>
      <div id={`manager-${index}`} style={{ width: "10%" }}>
        <div
          className="errors"
          onClick={() => {
            setErrorClearModal(!errorClearModal);
            setErrorClearId(item?.id);
          }}
        >
          {checkErrorContact(item)}
        </div>

        <div>
          {checkBelowErrorMessageContact(item)}
        </div>

      </div>


      {
        displayToolTip(checkErrorContact(item)) &&
        <Tooltip
          placement="top"
          isOpen={activeToolTip}
          target={`manager-${index}`}
          toggle={toggles}
        >
          {item?.all_errors?.length &&
            item?.all_errors?.map(
              (errors, index) => {
                return (
                  <div key={`id-${item?.id}-${index}`}>

                    {errors?.error_message
                      ? errors?.error_message
                      : item?.all_errors?.length > 1
                        ? JSON.stringify(
                          errors?.error
                        )
                        : errors?.error}
                  </div>
                );
              }
            )}
        </Tooltip>
      }

      {item?.id === errorClearId && errorClearModal && (
        <div
          style={{
            width: loadingErrorClearing ? 140 : 98,
          }}
          onClick={clearingErrorsHandler}
          className="error-clearing"
        >
          {loadingErrorClearing && (
            <Spinner
              size="sm"
              className="mr-2"
              style={{ color: "#eaa827" }}
            />
          )}
          CLEAR ERRORS
        </div>
      )}
    </div>
  )
}

export default ContactLstingToolTip