import { FC } from "react";

interface iconColor {
  color?: string;
}

export const NotificationIcon = () => (
  <svg
    width="22"
    height="24"
    viewBox="0 0 22 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 24C11.6653 23.9977 12.3027 23.744 12.7731 23.2941C13.2435 22.8443 13.5089 22.2348 13.5113 21.5986H8.4888C8.49121 22.2348 8.75656 22.8443 9.22699 23.2941C9.69742 23.744 10.3348 23.9977 11 24V24ZM20.0454 17.6469C19.4776 17.1066 19.1575 16.3728 19.1555 15.6069V10.1979C19.1555 8.56594 18.6196 6.97514 17.6232 5.6501C16.6269 4.32507 15.2206 3.33272 13.6027 2.81314C13.3944 2.74868 13.2127 2.62296 13.0836 2.45393C12.9544 2.28489 12.8844 2.08117 12.8835 1.87182V1.87182C12.8905 1.39448 12.704 0.933058 12.3631 0.584272C12.0222 0.235486 11.5534 0.0264074 11.0549 0.000852817C10.8031 -0.00656442 10.5524 0.0344633 10.3175 0.1215C10.0826 0.208537 9.86846 0.339809 9.68768 0.507524C9.50689 0.675239 9.36318 0.875978 9.26508 1.09782C9.16698 1.31967 9.11649 1.5581 9.11661 1.79897V1.87182C9.11569 2.08117 9.04566 2.28489 8.9165 2.45393C8.78735 2.62296 8.60566 2.74868 8.39737 2.81314C6.77951 3.33272 5.37316 4.32507 4.37684 5.6501C3.38052 6.97514 2.84456 8.56594 2.84461 10.1979V15.5981C2.8426 16.3641 2.5225 17.0979 1.95471 17.6381L1.22023 18.3405C0.656084 18.8828 0.337552 19.6155 0.333374 20.3805H21.6667C21.6625 19.6155 21.344 18.8828 20.7798 18.3405L20.0454 17.6469Z"
      fill="white"
    />
  </svg>
);

export const AnchorIcon = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1 2C0.447715 2 1.49012e-08 1.55228 1.49012e-08 1C1.49012e-08 0.447715 0.447715 0 1 0H11C11.5523 0 12 0.447715 12 1V11C12 11.5523 11.5523 12 11 12C10.4477 12 10 11.5523 10 11V3.41421L1.70711 11.7071C1.31658 12.0976 0.683417 12.0976 0.292893 11.7071C-0.0976311 11.3166 -0.0976311 10.6834 0.292893 10.2929L8.58579 2H1Z"
      fill="white"
    />
  </svg>
);

export const AttachFileIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.8646 9.71836L10.2063 17.3767C9.26809 18.3149 7.99562 18.842 6.6688 18.842C5.34198 18.842 4.0695 18.3149 3.1313 17.3767C2.19309 16.4385 1.66602 15.166 1.66602 13.8392C1.66602 12.5124 2.19309 11.2399 3.1313 10.3017L10.7896 2.64336C11.4151 2.01789 12.2634 1.6665 13.148 1.6665C14.0325 1.6665 14.8808 2.01789 15.5063 2.64336C16.1318 3.26883 16.4831 4.11714 16.4831 5.00169C16.4831 5.88624 16.1318 6.73455 15.5063 7.36002L7.83963 15.0184C7.68478 15.1732 7.50095 15.296 7.29862 15.3798C7.0963 15.4636 6.87945 15.5068 6.66046 15.5068C6.44147 15.5068 6.22462 15.4636 6.0223 15.3798C5.81998 15.296 5.63615 15.1732 5.4813 15.0184C5.32645 14.8635 5.20361 14.6797 5.11981 14.4774C5.036 14.275 4.99287 14.0582 4.99287 13.8392C4.99287 13.6202 5.036 13.4034 5.11981 13.201C5.20361 12.9987 5.32645 12.8149 5.4813 12.66L12.5563 5.59336"
      stroke="#EAA827"
      stroke-width="1.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export const DownloadOutlinedIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.87695 12.9102C9.89157 12.9288 9.91024 12.9439 9.93156 12.9543C9.95288 12.9647 9.97629 12.9701 10 12.9701C10.0237 12.9701 10.0471 12.9647 10.0684 12.9543C10.0898 12.9439 10.1084 12.9288 10.123 12.9102L12.3105 10.1426C12.3906 10.041 12.3184 9.89062 12.1875 9.89062H10.7402V3.28125C10.7402 3.19531 10.6699 3.125 10.584 3.125H9.41211C9.32617 3.125 9.25586 3.19531 9.25586 3.28125V9.88867H7.8125C7.68164 9.88867 7.60938 10.0391 7.68945 10.1406L9.87695 12.9102ZM17.1484 12.2266H15.9766C15.8906 12.2266 15.8203 12.2969 15.8203 12.3828V15.3906H4.17969V12.3828C4.17969 12.2969 4.10938 12.2266 4.02344 12.2266H2.85156C2.76562 12.2266 2.69531 12.2969 2.69531 12.3828V16.25C2.69531 16.5957 2.97461 16.875 3.32031 16.875H16.6797C17.0254 16.875 17.3047 16.5957 17.3047 16.25V12.3828C17.3047 12.2969 17.2344 12.2266 17.1484 12.2266Z"
      fill="#EAA827"
    />
  </svg>
);

export const ReplyIcon = ({ color = '#f9b220' }) => <svg width="12" height="12" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fillRule="evenodd" clipRule="evenodd" d="M6.70711 0.292893C7.09763 0.683417 7.09763 1.31658 6.70711 1.70711L3.41421 5H13C14.3261 5 15.5979 5.52678 16.5355 6.46447C17.4732 7.40215 18 8.67392 18 10V17C18 17.5523 17.5523 18 17 18C16.4477 18 16 17.5523 16 17V10C16 9.20435 15.6839 8.44129 15.1213 7.87868C14.5587 7.31607 13.7956 7 13 7H3.41421L6.70711 10.2929C7.09763 10.6834 7.09763 11.3166 6.70711 11.7071C6.31658 12.0976 5.68342 12.0976 5.29289 11.7071L0.292893 6.70711C-0.0976311 6.31658 -0.0976311 5.68342 0.292893 5.29289L5.29289 0.292893C5.68342 -0.0976311 6.31658 -0.0976311 6.70711 0.292893Z" fill={color} />
</svg>

export const RefreshIcon = () => <svg width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fillRule="evenodd" clipRule="evenodd" d="M9.22695 0.39574C10.8497 -0.0736298 12.5648 -0.124285 14.2124 0.2485C15.86 0.621286 17.3863 1.40536 18.6489 2.52757C19.9115 3.64977 20.8692 5.07353 21.4327 6.66599C21.617 7.18664 21.3442 7.75806 20.8236 7.9423C20.3029 8.12654 19.7315 7.85383 19.5473 7.33318C19.0965 6.05921 18.3303 4.92021 17.3202 4.02244C16.3101 3.12468 15.0891 2.49742 13.771 2.19919C12.453 1.90096 11.0808 1.94149 9.78267 2.31698C8.4845 2.69248 7.30261 3.39071 6.34728 4.34652C6.3399 4.35391 6.33239 4.36118 6.32478 4.36834L3.52455 6.99959H7C7.55228 6.99959 8 7.4473 8 7.99959C8 8.55187 7.55228 8.99959 7 8.99959H1C0.447715 8.99959 0 8.55187 0 7.99959V1.99959C0 1.4473 0.447715 0.999587 1 0.999587C1.55228 0.999587 2 1.4473 2 1.99959V5.68773L4.9441 2.92129C6.13626 1.73236 7.60939 0.863619 9.22695 0.39574ZM16 11.9996C16 11.4473 16.4477 10.9996 17 10.9996H23C23.5523 10.9996 24 11.4473 24 11.9996V17.9996C24 18.5519 23.5523 18.9996 23 18.9996C22.4477 18.9996 22 18.5519 22 17.9996V14.3114L19.0559 17.0779C17.8637 18.2668 16.3906 19.1356 14.7731 19.6034C13.1503 20.0728 11.4352 20.1235 9.7876 19.7507C8.14002 19.3779 6.61373 18.5938 5.35114 17.4716C4.08854 16.3494 3.1308 14.9256 2.56728 13.3332C2.38304 12.8125 2.65576 12.2411 3.1764 12.0569C3.69705 11.8726 4.26848 12.1453 4.45272 12.666C4.90353 13.94 5.66972 15.079 6.6798 15.9767C7.68987 16.8745 8.91091 17.5018 10.229 17.8C11.547 18.0982 12.9192 18.0577 14.2173 17.6822C15.5155 17.3067 16.6974 16.6085 17.6527 15.6527C17.6572 15.6482 17.6616 15.6438 17.6662 15.6394C17.6692 15.6366 17.6722 15.6337 17.6752 15.6308L20.4755 12.9996H17C16.4477 12.9996 16 12.5519 16 11.9996Z" fill="#000000" />
</svg>;

export const SearchIcon = () => <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M6 2C3.79086 2 2 3.79086 2 6C2 8.20914 3.79086 10 6 10C8.20914 10 10 8.20914 10 6C10 3.79086 8.20914 2 6 2ZM0 6C0 2.68629 2.68629 0 6 0C9.31371 0 12 2.68629 12 6C12 7.29583 11.5892 8.49572 10.8907 9.47653L15.7071 14.2929C16.0976 14.6834 16.0976 15.3166 15.7071 15.7071C15.3166 16.0976 14.6834 16.0976 14.2929 15.7071L9.47653 10.8907C8.49572 11.5892 7.29583 12 6 12C2.68629 12 0 9.31371 0 6Z" fill="#9CA3AF" />
</svg>;

export const EditIcon = () => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M5.91197 19H5V18.0784L14.06 9.01842L14.9809 9.93935L5.91197 19ZM6.89 20.85C6.8 20.95 6.67 21 6.54 21H3.5C3.22 21 3 20.78 3 20.5V17.46C3 17.32 3.05 17.2 3.15 17.1L14.06 6.19L17.81 9.94L17.8092 9.94078L6.89 20.85ZM15.13 5.12L18.88 8.87L20.71 7.04C21.1 6.65 21.1 6.02 20.71 5.63L18.37 3.29C17.98 2.9 17.35 2.9 16.96 3.29L15.13 5.12Z" fill="#EAA827" />
</svg>;

export const DeleteIcon: FC<iconColor> = ({ color }) => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M6 19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V9C18 7.9 17.1 7 16 7H8C6.9 7 6 7.9 6 9V19ZM9 9H15C15.55 9 16 9.45 16 10V18C16 18.55 15.55 19 15 19H9C8.45 19 8 18.55 8 18V10C8 9.45 8.45 9 9 9ZM15.5 4L14.79 3.29C14.61 3.11 14.35 3 14.09 3H9.91C9.65 3 9.39 3.11 9.21 3.29L8.5 4H6C5.45 4 5 4.45 5 5C5 5.55 5.45 6 6 6H18C18.55 6 19 5.55 19 5C19 4.45 18.55 4 18 4H15.5Z" fill={`${color ?? '#EAA827'}`} />
</svg>;

export const AddOutlinedIcon = () => <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M11 2C6.02944 2 2 6.02944 2 11C2 15.9706 6.02944 20 11 20C15.9706 20 20 15.9706 20 11C20 6.02944 15.9706 2 11 2ZM0 11C0 4.92487 4.92487 0 11 0C17.0751 0 22 4.92487 22 11C22 17.0751 17.0751 22 11 22C4.92487 22 0 17.0751 0 11ZM11 6C11.5523 6 12 6.44772 12 7V10H15C15.5523 10 16 10.4477 16 11C16 11.5523 15.5523 12 15 12H12V15C12 15.5523 11.5523 16 11 16C10.4477 16 10 15.5523 10 15V12H7C6.44772 12 6 11.5523 6 11C6 10.4477 6.44772 10 7 10H10V7C10 6.44772 10.4477 6 11 6Z" fill="#EAA827" />
</svg>

export const DownArrowIcon = () => (
  <svg width="14" height="6" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M0.645917 0.646039C0.692363 0.599476 0.747539 0.562533 0.808284 0.537326C0.869029 0.51212 0.93415 0.499146 0.999917 0.499146C1.06568 0.499146 1.13081 0.51212 1.19155 0.537326C1.2523 0.562533 1.30747 0.599476 1.35392 0.646039L6.99992 6.29304L12.6459 0.646039C12.6924 0.599551 12.7476 0.562675 12.8083 0.537516C12.8691 0.512357 12.9342 0.499407 12.9999 0.499407C13.0657 0.499407 13.1308 0.512357 13.1915 0.537516C13.2522 0.562675 13.3074 0.599551 13.3539 0.646039C13.4004 0.692527 13.4373 0.747716 13.4624 0.808455C13.4876 0.869195 13.5005 0.934295 13.5005 1.00004C13.5005 1.06578 13.4876 1.13088 13.4624 1.19162C13.4373 1.25236 13.4004 1.30755 13.3539 1.35404L7.35392 7.35404C7.30747 7.4006 7.2523 7.43754 7.19155 7.46275C7.1308 7.48796 7.06568 7.50093 6.99992 7.50093C6.93415 7.50093 6.86903 7.48796 6.80828 7.46275C6.74754 7.43754 6.69236 7.4006 6.64592 7.35404L0.645917 1.35404C0.599354 1.30759 0.562411 1.25242 0.537205 1.19167C0.511998 1.13093 0.499023 1.06581 0.499023 1.00004C0.499023 0.934272 0.511998 0.869151 0.537205 0.808405C0.562411 0.74766 0.599354 0.692485 0.645917 0.646039Z" fill="#8e949d" />
  </svg>
)

export const VerticalMenuIcon = () => (
  <svg width="4" height="18" viewBox="0 0 4 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M0 2C0 0.89543 0.89543 0 2 0C3.10457 0 4 0.89543 4 2C4 3.10457 3.10457 4 2 4C0.89543 4 0 3.10457 0 2ZM0 9C0 7.89543 0.89543 7 2 7C3.10457 7 4 7.89543 4 9C4 10.1046 3.10457 11 2 11C0.89543 11 0 10.1046 0 9ZM0 16C0 14.8954 0.89543 14 2 14C3.10457 14 4 14.8954 4 16C4 17.1046 3.10457 18 2 18C0.89543 18 0 17.1046 0 16Z" fill="#5E6278" />
  </svg>
)

export const TickOutlinedIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15 7L7.99998 14L4.99994 11M19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10Z" stroke="#EAA827" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

export const CrossOutlinedIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14 6L6 14M6.00001 6L14 14M19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10Z" stroke="#BE3740" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

export const BotIcon = () => (
  <svg width="32" height="32" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.41124 1.75599L8.50029 1.75C8.83252 1.75 9.10709 1.99688 9.15055 2.3172L9.15654 2.40625L9.15585 3.06163L12.2187 3.06207C13.3061 3.06207 14.1875 3.94351 14.1875 5.03082V8.97234C14.1875 10.0596 13.3061 10.9411 12.2187 10.9411H4.78125C3.69394 10.9411 2.8125 10.0596 2.8125 8.97234V5.03082C2.8125 3.94351 3.69394 3.06207 4.78125 3.06207L7.84335 3.06163L7.84404 2.40625C7.84404 2.07402 8.09092 1.79945 8.41124 1.75599ZM6.53063 5.68707C5.92691 5.68707 5.4375 6.17648 5.4375 6.7802C5.4375 7.38391 5.92691 7.87332 6.53063 7.87332C7.13435 7.87332 7.62376 7.38391 7.62376 6.7802C7.62376 6.17648 7.13435 5.68707 6.53063 5.68707ZM10.4618 5.68707C9.85805 5.68707 9.36864 6.17648 9.36864 6.7802C9.36864 7.38391 9.85805 7.87332 10.4618 7.87332C11.0655 7.87332 11.5549 7.38391 11.5549 6.7802C11.5549 6.17648 11.0655 5.68707 10.4618 5.68707Z" fill="white" />
  </svg>
)

export const MessageIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.6666 1.33301L7.33325 8.66634M14.6666 1.33301L9.99992 14.6663L7.33325 8.66634M14.6666 1.33301L1.33325 5.99967L7.33325 8.66634" stroke="#eaa827" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

export const GptLoaderIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="100px" height="100px" viewBox="0 0 100 100">
    <circle cx="30" cy="50" fill="#eaa827">
      <animate attributeName="r" values="0;5;0" dur="1.2s" repeatCount="indefinite" />
    </circle>
    <circle cx="50" cy="50" fill="#eaa827">
      <animate attributeName="r" values="0;5;0" dur="1.2s" begin="0.4s" repeatCount="indefinite" />
    </circle>
    <circle cx="70" cy="50" fill="#eaa827">
      <animate attributeName="r" values="0;5;0" dur="1.2s" begin="0.8s" repeatCount="indefinite" />
    </circle>
  </svg>
)

export const ClipBoardIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="#eaa827">
    <path fillRule="evenodd" d="M4.75 3A1.75 1.75 0 003 4.75v9.5c0 .966.784 1.75 1.75 1.75h1.5a.75.75 0 000-1.5h-1.5a.25.25 0 01-.25-.25v-9.5a.25.25 0 01.25-.25h9.5a.25.25 0 01.25.25v1.5a.75.75 0 001.5 0v-1.5A1.75 1.75 0 0014.25 3h-9.5zm5 5A1.75 1.75 0 008 9.75v9.5c0 .966.784 1.75 1.75 1.75h9.5A1.75 1.75 0 0021 19.25v-9.5A1.75 1.75 0 0019.25 8h-9.5zM9.5 9.75a.25.25 0 01.25-.25h9.5a.25.25 0 01.25.25v9.5a.25.25 0 01-.25.25h-9.5a.25.25 0 01-.25-.25v-9.5z" />
  </svg>
)
