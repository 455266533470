import { useLazyQuery } from "@apollo/client";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useLocation } from "react-router-dom";
import InputField from "../../components/layout/InputField";
import Loader from "../../components/layout/Loader";
import NoDataFound from "../../components/layout/NoDataFound";
import Pagination from "../../components/layout/Pagination";
import TableComponent from "../../components/layout/TableComponent";
import { JOB_LIST, UPDATE_RECORD } from "../../constants";
import { createMarkup, formatDateUS } from "../../utils/commonFn";
import { COLUMN_INTERFACE_TYPES_FOR_JOB_LIST, COMPANY_DETAILS_ROUTE, JOB_LIST_HEADER, JOB_LIST_ROUTE, JOB_ORDER_ROUTE, PAGE_LIMIT, VIEW_JOB_ORDER_ROUTE } from "../../utils/constant";
import { getAllJobListParams } from "../../utils/getNoteParams";
import { moneyFormatter, pushInHistory } from "../../utils/helper";
import { compactObject } from "../candidate-pipeline/placements/components/utils";
import AddRecordDrawer from "./Drawer";
import { GET_ALL_JOB_LIST } from "./gql";

const defaultColumnFilters = {
  positionSearch: '',
  companySearch: '',
  createdAt: '',
  sorting: {
    columnName: '',
    columnValue: ''
  }
};
let time = 0

const AllJobList = (props) => {
  const history = useHistory();
  const location = useLocation()
  //use states
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(10);
  const [addRecord, setAddRecord] = useState<boolean>(false);
  const [columnFilters, setColumnFilters] =
    useState<COLUMN_INTERFACE_TYPES_FOR_JOB_LIST>(defaultColumnFilters);
  const [errorMsg, setErrorMsg] = useState({});
  const [isSetTimeOut, setIsSetTime] = useState<boolean>(true);

  const { register, setValue, control } = useForm();

  //setParams
  const setQueryParams = () => {

    let newColumn: COLUMN_INTERFACE_TYPES_FOR_JOB_LIST = Object.assign(
      {},
      columnFilters
    );
    const { sorting, ...rest } = newColumn;
    const criteria = compactObject({
      currentPage,
      limit,
      ...sorting,
      ...rest
    });

    history.push(`${JOB_LIST_ROUTE}?${new URLSearchParams(criteria).toString()}`);
  };

  const [fetchAllJobList, { loading: allJobOrderLoading, data, refetch }] =
    useLazyQuery(GET_ALL_JOB_LIST);

  const _fetchAllJobList = useCallback(async () => {
    if (isSetTimeOut) {
      setQueryParams();
      await fetchAllJobList({
        variables: {
          limit,
          page: currentPage,
          columnFilters,
        },
      })
    } else {
      setQueryParams();
      setLimit(limit);
      window.clearTimeout(time);
      time = window.setTimeout(async () => {
        await fetchAllJobList({
          variables: {
            limit,
            page: currentPage,
            columnFilters
          },
        })
      }, 1000);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, columnFilters, limit])

  useEffect(() => {
    _fetchAllJobList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_fetchAllJobList])

  useEffect(() => {
    document.title = 'JobOrders - RealREPP';
    let params: any = getAllJobListParams(location.search);
    if (params) {
      setCurrentPage(params?.currentPage ? parseInt(params?.currentPage) : 1);
      setLimit(params?.limit ? parseInt(params?.limit) : 10);
    }
    if (Object.entries(params?.columnFilters)?.length) {
      if (Object.entries(params?.columnFilters)?.length === 1) {
        setColumnFilters((prevFilters) => ({
          ...prevFilters,
          [Object.keys(params?.columnFilters)[0]]: Object.values(
            params?.columnFilters
          )[0],
        }));
      } else {
        const updatedFilters = { ...columnFilters };
        Object.entries(params?.columnFilters).forEach(([key, value]) => {
          updatedFilters[key] = value;
        });
        setColumnFilters(updatedFilters);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onAddRecord = () => {
    setAddRecord(!addRecord);
  };

  const changeFiled = (fieldName, value) => {
    if (fieldName?.endsWith('Sort')) {
      const columnName = fieldName.replace('Sort', '');
      setIsSetTime(true)
      setColumnFilters((prevFilters) => {
        const { sorting, ...otherFilters } = prevFilters;
        return {
          ...otherFilters,
          sorting: {
            columnName: columnName,
            columnValue: value ? 'ASC' : 'DESC',
          },
        };
      });
    } else {
      setCurrentPage(1);
      setLimit(limit);
      setIsSetTime(false)
      setColumnFilters((prevFilters) => ({
        ...prevFilters,
        [fieldName]: value,
      }));
    }
  };


  const mapTableItems = (item) => {
    return (
      <>
        <td>{item?.company?.companyOwner?.owner?.fullName || "---"}</td>

        <td>
          <div className="line-clamp normal-white-space w-200px">
            {(item?.recruitingAssigned && (
              item?.recruitingAssigned?.map(item => item.user.fullName)
                .filter((name, index, array) => array.indexOf(name) === index)
                .join(', ') || '---')
            )}
          </div>
        </td>

        <td>
          {item?.createdAt
            ? formatDateUS(item.createdAt)
            : "--"}
        </td>

        <td className="route-link text-truncate max-200"
          onClick={() => {
            history.push({
              pathname: `${COMPANY_DETAILS_ROUTE}/${item?.company?.id}`,
              state: {
                backUrl: JOB_ORDER_ROUTE,
                searchParameters: location.search,
              },
            });
            pushInHistory(JOB_ORDER_ROUTE, location.search);
          }}>
          {item?.company?.name || "----"}
        </td>

        <td className="route-link text-truncate max-200"
          onClick={() => {
            history.push({
              pathname: `${VIEW_JOB_ORDER_ROUTE}/${item.id}`,
              state: {
                searchParameters: location.search,
              },
            });
            pushInHistory(JOB_ORDER_ROUTE, location.search);
          }}>

          {item?.jobTitle || "----"}
        </td>

        <td>{
          (item?.advertisedJobDescription?.replace(/<(.|\n)*?>/g, '').trim().length
            && <div className="d-flex">
              <div
                className="line-clamp normal-white-space w-200px"
                dangerouslySetInnerHTML={createMarkup(
                  item?.advertisedJobDescription.replace(/<(.|\n)*?>/g, '').replace(/^\s+/g, '').replace(/^[^\w]+/g, '')
                )}
              />
            </div>) || "----"}</td>

        <td>{
          item?.companyLocation ?
            <>
              {item?.companyLocation?.streetAddress}
              <br />
              {['US', null, undefined, ''].includes(
                item?.companyLocation?.country
              )
                ? `${item?.companyLocation?.city}, ${item?.companyLocation?.state}, ${item?.companyLocation?.zipCode}`
                : `${item?.companyLocation?.city}, ${item?.companyLocation?.country}`}
            </>
            : "----"}</td>

        <td>{moneyFormatter(item?.salaryMin) || "----"} - {moneyFormatter(item?.salaryMax) || "----"}</td>

        <td><div className="ml-4">{item?.jobApplicants?.length || 0}</div></td>

        <td><div className="ml-4">{item?.jobApplicants?.filter(
          ({ JobAppStatus }) => JobAppStatus === "INTERVIEWED")?.length || 0}</div></td>

        <td><div className="ml-3">{item?.jobApplicants?.filter(
          ({ JobAppStatus }) => JobAppStatus === "PLACED")?.length || 0}</div></td>

        <td>{
          (item?.jobListNotes?.urgencyNote &&
            <div className="line-clamp normal-white-space w-150px"
              dangerouslySetInnerHTML={createMarkup(item?.jobListNotes?.urgencyNote)}
            />) || "----"}</td>

        <td>{
          (item?.jobListNotes?.pipelineNote && <div
            className="line-clamp normal-white-space w-150px"
            dangerouslySetInnerHTML={createMarkup(item?.jobListNotes?.pipelineNote
            )}
          />) || "----"}</td>

        <td> YES </td>

        <td >
          <div className="ml-3">{item?.advertisedLocations?.filter(
            ({ postedAt }) => postedAt !== null
          )?.length
            ? 'YES'
            : 'NO' || '--'}
          </div>
        </td>
        <td>
          <div className="ml-3">
            {item?.postToLinkedin ? "YES" : "NO"}
          </div>
        </td>
      </>
    )
  }

  return (
    <>
      <div className="admin-tabs team-tabs">
        <div className="primaryHeaderSpacing primaryHeading d-flex justify-content-between  mr-3">

          <h5 className="m-0">{JOB_LIST}</h5>
          <button
            className="buttonGenericStyle filledButton ml-3"
            onClick={() => {
              onAddRecord();
            }
            }>
            {UPDATE_RECORD}
          </button>
        </div>

        <div className="mr-3">

          <TableComponent
            tableHeaderAdvSeacrh={JOB_LIST_HEADER}
            advanceSearching={true}
            handleChange={(filedName, value) => changeFiled(filedName, value)}
            columnFilters={columnFilters}
            register={register}
            setValue={setValue}
            control={control}
            tableName="JobList"
            setErrorMsg={setErrorMsg}
            errorMsg={errorMsg}
          >
            {allJobOrderLoading ? (
              <tbody>
                <tr>
                  <td colSpan={14}>
                    <Loader loaderClass="sm-height" />
                  </td>
                </tr>
              </tbody>
            ) :
              (
                <tbody className="placement-table">
                  {
                    data?.fetchAllJobList?.jobOrders?.length ? (
                      data?.fetchAllJobList?.jobOrders?.map((item: any, index: number) => {
                        return (
                          <tr key={index} >
                            {mapTableItems(item)}
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td
                          colSpan={JOB_LIST_HEADER.length}
                          className="empty-table-td"
                        >
                          <NoDataFound text="No List Found!" />
                        </td>
                      </tr>
                    )
                  }
                </tbody>
              )}
          </TableComponent>
        </div>
      </div>

      {!!data?.fetchAllJobList?.jobOrders?.length && (
        <div className="users-pagination">
          <Pagination
            onPageChange={(pageClicked: number) => {
              setIsSetTime(true)
              setQueryParams();
              setCurrentPage(pageClicked);
            }}
            pageCount={Math.ceil(data?.fetchAllJobList?.count / limit)}
            currentPage={currentPage}
          />

          <div className="d-flex align-items-center">
            <div className="pagination-display paginationItemsSelect">
              <p className="m-0">
                {(currentPage - 1) * limit + 1} to{' '}
                {Math.min(currentPage * limit, data?.fetchAllJobList?.count)}{' '}
                of {data?.fetchAllJobList?.count}
              </p>

              <div className="divider"></div>

              <label>Display</label>

              <InputField
                label=""
                inputtype="select"
                selectItems={PAGE_LIMIT.filter((item) => item.value !== limit)}
                inputid="limit"
                placeholder={limit.toString()}
                inputMethod={(value: string) => {
                  setIsSetTime(true)
                  setQueryParams();
                  setCurrentPage(1);
                  setLimit(parseInt(value));
                }}
              />
            </div>
          </div>
        </div>
      )}

      <AddRecordDrawer
        toggle={onAddRecord}
        isOpen={addRecord}
        refetchData={refetch}
      />
    </>
  )
}

export default AllJobList;