export interface State {
  page: number,
  limit: number,
  noteData: any,
  noteState: any,
  selectedNote: any,
  noteModal: boolean,
  noteContent: string,
  noteCreator: string,
  currentPage: number,
  statusFilter: string,
  searchParams: string,
  noteCategory: string,
  deleteNoteId: string,
  filterCheck: boolean,
  activityNotes: boolean,
  checkBoxArray: string[],
  modalNoteContent: string,
  recruiterFolder: boolean,
  myCandidatesFlag: boolean,
  confirmNotePopup: boolean,
  privateCandidatesFlag: boolean,
}

export const initialState: State = {
  page: 1,
  limit: 10,
  currentPage: 1,
  noteData: null,
  noteState: null,
  noteContent: '',
  noteCreator: '',
  statusFilter: '',
  selectedNote: {},
  searchParams: '',
  noteCategory: '',
  noteModal: false,
  deleteNoteId: '',
  filterCheck: true,
  checkBoxArray: [],
  activityNotes: false,
  modalNoteContent: '',
  recruiterFolder: false,
  confirmNotePopup: false,
  myCandidatesFlag: false,
  privateCandidatesFlag: false,
}

export enum ActionType {
  SET_PAGE = 'setPage',
  SET_LIMIT = 'setLimit',
  SET_NOTE_DATA = 'setNoteData',
  SET_NOTE_STATE = 'setNoteState',
  SET_NOTE_MODAL = 'setNoteModal',
  SET_CURRENT_PAGE = 'setCurrentPage',
  SET_NOTE_CONTENT = 'setNoteContent',
  SET_NOTE_CREATOR = 'setNoteCreator',
  SET_FILTER_CHECK = 'setFilterCheck',
  SET_STATUS_FILTER = 'setStatusFilter',
  SET_SEARCH_PARAMS = 'setSearchParams',
  SET_NOTE_CATEGORY = 'setNoteCategory',
  SET_SELECTED_NOTE = 'setSelectedNote',
  SET_ACTIVITY_NOTE = 'setActivityNote',
  SET_DELETE_NOTE_ID = 'setDeleteNoteId',
  SET_CHECK_BOX_ARRAY = 'setCheckBoxArray',
  SET_RECRUITER_FOLDER = 'setRecruiterFolder',
  SET_MY_CANDIDATES_FLAG = 'setMyCandidatesFlag',
  SET_MODAL_NOTE_CONTENT = 'setModalNoteContent',
  SET_CONFIRM_NOTE_POPUP = 'setConfirmNotePopup',
  SET_PRIVATE_CANDIDATES_FLAG = 'setPrivateCandidatesFlag',
}

export type Action =
  | { type: ActionType.SET_PAGE, page: number }
  | { type: ActionType.SET_LIMIT, limit: number }
  | { type: ActionType.SET_NOTE_DATA, noteData: any }
  | { type: ActionType.SET_NOTE_STATE, noteState: null }
  | { type: ActionType.SET_NOTE_MODAL, noteModal: boolean }
  | { type: ActionType.SET_SELECTED_NOTE, selectedNote: any }
  | { type: ActionType.SET_CURRENT_PAGE, currentPage: number }
  | { type: ActionType.SET_NOTE_CONTENT, noteContent: string }
  | { type: ActionType.SET_NOTE_CREATOR, noteCreator: string }
  | { type: ActionType.SET_FILTER_CHECK, filterCheck: boolean }
  // | { type: ActionType.SET_STATUS_FILTER, statusFilter: string }
  | { type: ActionType.SET_NOTE_CATEGORY, noteCategory: string }
  // | { type: ActionType.SET_SEARCH_PARAMS, searchParams: string }
  | { type: ActionType.SET_DELETE_NOTE_ID, deleteNoteId: string }
  | { type: ActionType.SET_ACTIVITY_NOTE, activityNote: boolean }
  | { type: ActionType.SET_CHECK_BOX_ARRAY, checkBoxArray: string[] }
  | { type: ActionType.SET_RECRUITER_FOLDER, recruiterFolder: boolean }
  | { type: ActionType.SET_MODAL_NOTE_CONTENT, modalNoteContent: string }
  | { type: ActionType.SET_MY_CANDIDATES_FLAG, myCandidatesFlag: boolean }
  | { type: ActionType.SET_CONFIRM_NOTE_POPUP, confirmNotePopup: boolean }
  | { type: ActionType.SET_PRIVATE_CANDIDATES_FLAG, privateCandidatesFlag: boolean }

export const candidateReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.SET_PAGE:
      return {
        ...state,
        page: action.page
      }

    case ActionType.SET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.currentPage
      }

    case ActionType.SET_LIMIT:
      return {
        ...state,
        limit: action.limit
      }

    case ActionType.SET_SELECTED_NOTE:
      return {
        ...state,
        selectedNote: action.selectedNote
      }

      case ActionType.SET_NOTE_STATE:
        return {
          ...state,
          noteState: action.noteState
        }

    // case ActionType.SET_STATUS_FILTER:
    //   return {
    //     ...state,
    //     statusFilter: action.statusFilter
    //   }

    // case ActionType.SET_SEARCH_PARAMS:
    //   return {
    //     ...state,
    //     searchParams: action.searchParams
    //   }

    case ActionType.SET_NOTE_DATA:
      return {
        ...state,
        noteData: action.noteData
      }

    case ActionType.SET_NOTE_CONTENT:
      return {
        ...state,
        noteContent: action.noteContent
      }

    case ActionType.SET_NOTE_CREATOR:
      return {
        ...state,
        noteCreator: action.noteCreator
      }

    case ActionType.SET_NOTE_CATEGORY:
      return {
        ...state,
        noteCategory: action.noteCategory
      }

    case ActionType.SET_NOTE_MODAL:
      return {
        ...state,
        noteModal: action.noteModal
      }

    case ActionType.SET_DELETE_NOTE_ID:
      return {
        ...state,
        deleteNoteId: action.deleteNoteId
      }

    case ActionType.SET_ACTIVITY_NOTE:
      return {
        ...state,
        activityNotes: action.activityNote
      }

    case ActionType.SET_MODAL_NOTE_CONTENT:
      return {
        ...state,
        modalNoteContent: action.modalNoteContent
      }

    case ActionType.SET_CONFIRM_NOTE_POPUP:
      return {
        ...state,
        confirmNotePopup: action.confirmNotePopup
      }

    case ActionType.SET_RECRUITER_FOLDER:
      return {
        ...state,
        recruiterFolder: action.recruiterFolder
      }

    case ActionType.SET_CHECK_BOX_ARRAY:
      return {
        ...state,
        checkBoxArray: action.checkBoxArray
      }

    case ActionType.SET_FILTER_CHECK:
      return {
        ...state,
        filterCheck: action.filterCheck
      }

    case ActionType.SET_MY_CANDIDATES_FLAG:
      return {
        ...state,
        myCandidatesFlag: action.myCandidatesFlag
      }

    case ActionType.SET_PRIVATE_CANDIDATES_FLAG:
      return {
        ...state,
        privateCandidatesFlag: action.privateCandidatesFlag
      }
  }
};
