import { gql } from '@apollo/client';

export const GET_ES_NOTES = gql`
  query GetESNotes(
    $text: String
    $candidateNotes: String
    $companyNotes: String
    $jobNotes: String
    $recruiterNotes: String
    $contactNotes: String
    $notesCategory: String
    $page: Int!
    $limit: Int!
  ) {
    getESNotes(
      filter: {
        text: $text
        candidateNotes: $candidateNotes
        companyNotes: $companyNotes
        jobNotes: $jobNotes
        recruiterNotes: $recruiterNotes
        contactNotes: $contactNotes
        notesCategory: $notesCategory
      }
      payload: { page: $page, limit: $limit }
    ) {
      notes {
        id
        content
        createdAt
        updatedAt
        noteableType
        modifiedOn
        noteableName
        category
        user {
          id
          fullName
        }
        associations {
          companyId
          companyName
          contactId
          contactName
          jobId
          jobTitle
          candidateId
          candidateName
        }
      }
      count
    }
  }
`;

export const GET_ES_CANDIDATE_NOTES = gql`
  query getESCandidateNotes(
    $text: String
    $candidateNotes: String
    $companyNotes: String
    $jobNotes: String
    $recruiterNotes: String
    $contactNotes: String
    $notesCategory: String
    $page: Int!
    $limit: Int!
  ) {
    getESCandidateNotes(
      filter: {
        text: $text
        candidateNotes: $candidateNotes
        companyNotes: $companyNotes
        jobNotes: $jobNotes
        recruiterNotes: $recruiterNotes
        contactNotes: $contactNotes
        notesCategory: $notesCategory
      }
      payload: { page: $page, limit: $limit }
    ) {
      notes {
        id
        content
        createdAt
        updatedAt
        noteableType
        modifiedOn
        noteableName
        category
        user {
          id
          fullName
        }
        associations {
          companyId
          companyName
          contactId
          contactName
          jobId
          jobTitle
          candidateId
          candidateName
        }
      }
      count
    }
  }
`;

export const GET_ALL_JOBS = gql`
  query GetAllJobs($id: String!, $page: Int!, $limit: Int!) {
    getAllJobs(id: $id, payload: { page: $page, limit: $limit }) {
      jobOrders {
        id
        jobTitle
        company {
          name
        }
      }
      count
    }
  }
`;

export const GET_ALL_CONTACTS = gql`
  query GetAllContact($id: String!, $page: Int!, $limit: Int!) {
    getAllContacts(id: $id, payload: { page: $page, limit: $limit }) {
      contacts {
        id
        fullName
        company {
          id
          name
        }
      }
      count
    }
  }
`;

export const GET_ALL_COMPANIES = gql`
  query GetAllCompanies($id: String!, $page: Int!, $limit: Int!) {
    getAllCompanies(id: $id, payload: { page: $page, limit: $limit }) {
      companies {
        id
        name
        address {
          isPrimary
          isDeleted
          streetAddress
          city
          state
        }
      }
      count
    }
  }
`;

export const GET_ALL_CANDIDATES = gql`
  query GetAllCandidates($id: String!, $page: Int!, $limit: Int!) {
    getAllCandidates(id: $id, payload: { page: $page, limit: $limit }) {
      candidates {
        id
        fullName
        emails {
          id
          email
          isPrimary
        }
      }
      count
    }
  }
`;

export const GET_ALL_USERS = gql`
  query GetAllUsers($id: String!, $page: Int!, $limit: Int!) {
    getAllUsers(id: $id, payload: { page: $page, limit: $limit }) {
      users {
        id
        fullName
      }
      count
    }
  }
`;

export const GET_ALL_JOB_ORDERS_AGAINST_COMPANY = gql`
  query FetchAllJobOrders(
    $page: Int!
    $limit: Int!
    $searchText: String
    $statusSearch: String
    $companyId: String
    $myJobOrder: Boolean
    $onlyMe: Boolean
  ) {
    fetchAllJobOrders(
      payload: { page: $page, limit: $limit }
      filters: {
        searchText: $searchText
        statusSearch: $statusSearch
        companyId: $companyId
        myJobOrder: $myJobOrder
        onlyMe: $onlyMe
      }
    ) {
      jobOrders {
        id
        jobTitle
        company {
          id
          name
        }
      }
      count
    }
  }
`;

export const FETCH_CONTACTS_AGAINST_COMPANY = gql`
  query FetchAllContacts(
    $page: Int!
    $limit: Int!
    $searchText: String
    $statusSearch: String
    $companyId: String
  ) {
    allContacts(
      payload: { page: $page, limit: $limit }
      filters: {
        searchText: $searchText
        statusSearch: $statusSearch
        companyId: $companyId
      }
    ) {
      contacts {
        id
        fullName
        company {
          name
          id
        }
      }
      count
    }
  }
`;

export const GET_USERS_BY_NAME_EMAIL = gql`
  query FindAllUsersByNameEmail(
    $page: Int!
    $limit: Int!
    $searchText: String
  ) {
    findUserByNameEmail(
      payload: { page: $page, limit: $limit }
      filters: { searchText: $searchText }
    ) {
      users {
        id
        fullName
      }
      count
    }
  }
`;

export const GET_COMPANIES_BY_NAME_LOCATION = gql`
  query FindCompaniesByNameLocation(
    $page: Int!
    $limit: Int!
    $searchText: String
  ) {
    findCompaniesByNameLocation(
      payload: { page: $page, limit: $limit }
      filters: { searchText: $searchText }
    ) {
      companies {
        id
        name
        address {
          isPrimary
          isDeleted
          streetAddress
          city
          state
        }
      }
      count
    }
  }
`;
