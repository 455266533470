import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import {
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap';
import * as Yup from 'yup';
import CustomButton from '../../../components/layout/CustomButton';
import InputField from '../../../components/layout/InputField';
import { URL_REGEX } from '../../../utils/constant';

const validationSchema = Yup.object({
  videoTitle: Yup.string().required('Video Title is required!'),
  videoUrl: Yup.string().required('Video Link is required!').matches(URL_REGEX, "Invalid URL"),
});
const EditTjLookupVideoModal = ({
  toggle,
  isOpen,
  videoObj,
  updateTjLookUpVideos,
  refetchList,
  loading,
}) => {
  const { handleSubmit, formState: { errors }, register, reset } = useForm({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (videoObj.id) {
      reset({
        videoTitle: videoObj.videoTitle,
        videoUrl: videoObj.videoUrl,
        id: videoObj.id,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tjookvideoUpdater = async (data) => {
    data.id = videoObj?.id;
    let response = await updateTjLookUpVideos({
      variables: { UpdateTjLookUpVideos: data },
    });
    if (response?.data?.updateTjLookUpVideos?.status === 200) {
      toggle();
      refetchList();
      toast.success('Video Updated Successfully');
    } else {
      toggle();
      toast.error('Video Can Not Updated');
    }
  };
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className="revampDialog revampDialogWidth"
      centered
    >
      <ModalHeader className="py-3">
        <h4>Update Video</h4>
      </ModalHeader>
      <form onSubmit={handleSubmit(tjookvideoUpdater)}>
        <ModalBody className="pt-3">
          <Row>
            <Col lg={10}>
              <Row>
                <Col lg={6}>
                  <InputField
                    inputtype="text"
                    label="Video Title"
                    inputid="videoTitle"
                    isRequired={true}
                    placeholder="Job Title"
                    inputRef={register({
                      required: {
                        value: true,
                        message: 'Video Title is required!',
                      },
                    })}
                    errMsg={errors?.videoTitle?.message}
                  />
                </Col>
                <Col lg={6}>
                  <InputField
                    inputtype="text"
                    label="Video Url"
                    inputid="videoUrl"
                    isRequired={true}
                    placeholder="Video Url"
                    inputRef={register({
                      required: {
                        value: true,
                        message: 'Video Link is required!',
                      },
                    })}
                    errMsg={errors?.videoUrl?.message}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <button className="buttonPrimary mr-2" onClick={toggle} type="button">
            Cancel
          </button>

          <CustomButton
            buttonText="Update"
            buttonColor="default"
            buttonType="submit"
            className="btn-primary py-2"
            disabled={loading}
          />
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default EditTjLookupVideoModal;
