export interface State {
  page: number,
  limit: number,
  contactId: string,
  contactNotes: any,
  currentPage: number,
}

export const initialState: State = {
  page: 1,
  limit: 10,
  contactId: '',
  currentPage: 1,
  contactNotes: {},
}

export enum ActionType {
  SET_PAGE = 'setPage',
  SET_LIMIT = 'setLimit',
  SET_CONTACT_ID = 'setContactId',
  SET_CURRENT_PAGE = 'setCurrentPage',
  SET_CONTACT_NOTES = 'setContactNotes',
}

export type Action =
  | { type: ActionType.SET_PAGE, page: number }
  | { type: ActionType.SET_LIMIT, limit: number }
  | { type: ActionType.SET_CONTACT_ID, contactId: string }
  | { type: ActionType.SET_CONTACT_NOTES, contactNotes: any }
  | { type: ActionType.SET_CURRENT_PAGE, currentPage: number }

export const noteReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.SET_PAGE:
      return {
        ...state,
        page: action.page
      }

    case ActionType.SET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.currentPage
      }

    case ActionType.SET_LIMIT:
      return {
        ...state,
        limit: action.limit
      }

    case ActionType.SET_CONTACT_ID:
      return {
        ...state,
        contactId: action.contactId
      }

    case ActionType.SET_CONTACT_NOTES:
      return {
        ...state,
        contactNotes: action.contactNotes
      }
  }
}
