import { Button, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import CustomButton from "../../components/layout/CustomButton";
import { useForm } from "react-hook-form";
import ResumeDrawer from "../../common/chatGpt/ResumeDrawer";
import { useContext, useState } from "react";
import useHTTPGPT from "../../HTTP_Request/useHTTPGPT";
import { getTemplateByModule, replacePlaceholderWithResume } from "../../utils/helper";
import { useQuery } from "@apollo/client";
import { ALL_CHATGPT_TEMPLATES } from "../chatGPT-templates/gql";
import { v4 as uuidv4 } from "uuid";
import useHTTPGPTAXIOS from "../../HTTP_Request/useHTTPGPTAXIOS";
import { AppContext } from "../../context";
import { toast } from "react-toastify";

const ResumeParsing = ({ modal, toggle }) => {
  const gptAPI = useHTTPGPT();
  const { post } = useHTTPGPTAXIOS();
  const { teachList } = useContext(AppContext);
  const { data: templateData } = useQuery(ALL_CHATGPT_TEMPLATES);
  const { register, errors, getValues, handleSubmit } = useForm<{ resumeContent: string }>()
  const [messages, setMessages] = useState<{ content: string, id: string, role: string, }[]>([]);
  const [resumeDrawer, setResumeDrawer] = useState<boolean>()

  const clearChat = () => {
    setMessages([]);
  }
  async function submitChat(content, fromResumeFormater = false) {
    try {
      const message = {
        id: uuidv4(),
        role: "user",
        content: content,
      };
      if (fromResumeFormater) {
        let newMessages = [message];
        await post(
          teachList.map((t) => ({ role: "system", content: t.content }))
            .concat(
              newMessages.slice(-8).map((message) => {
                return {
                  role: message.role,
                  content: message.content,
                };
              })
            )
        );
      } else {
        let newMessages = [...messages, message];
        setMessages(newMessages);
        const response = await gptAPI.post(
          teachList.map((t) => ({ role: "system", content: t.content }))
            .concat(
              newMessages.slice(-8).map((message) => {
                return {
                  role: message.role,
                  content: message.content,
                };
              })
            )
        );

        newMessages = [...messages, message, response];
        setMessages(newMessages);
      }

    } catch {
      toast.error("Something Went Wrong")
    }
  }

  const handleResumeDrawer = () => setResumeDrawer(!resumeDrawer)
  return (
    <>
      <Modal
        isOpen={modal}
        toggle={toggle}
        scrollable
        className="for-modal-styling"
      >
        <ModalHeader>Resume Parsing From ChatGPT</ModalHeader>
        <ModalBody>
          <div className="approval-content">
            <Label>Add Resume Data</Label>
            <textarea
              ref={register({
                required: {
                  value: true,
                  message: "Please Enter Resume Content"
                }
              })}
              name="resumeContent"
              placeholder="Please add resume content"
              className="form-control h-350"
            />
            {errors?.resumeContent && <p className="text-danger text-sm mb-0 mt-1">{errors?.resumeContent?.message}</p>}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={toggle}
            className="modal-close bg-transparent border-0 text-dark"
          >
            Cancel
          </Button>
          <CustomButton
            buttonType="submit"
            buttonText={"Parse Data"}
            disabled={!!errors.resumeContent?.message}
            buttonColor={"primary"}
            buttonClick={handleSubmit((value) => {
              console.log("resume :: ", value.resumeContent)
              toggle()
              handleResumeDrawer()
            })}
          />
        </ModalFooter>
      </Modal>
      {resumeDrawer && <ResumeDrawer
        toggle={handleResumeDrawer}
        isOpen={resumeDrawer}
        submitChat={submitChat}
        messages={messages}
        loading={gptAPI.loading}
        chunkMessage={gptAPI.chunkMessage}
        promptMessage={
          getTemplateByModule(templateData?.AllChatGptTemplates?.chatGPTtemplates, 'UPDATE_CANDIDATE_FROM_RESUME')
            ?
            `${replacePlaceholderWithResume(getTemplateByModule(templateData?.AllChatGptTemplates?.chatGPTtemplates, 'UPDATE_CANDIDATE_FROM_RESUME'), getValues()?.resumeContent, "{{resume_content}}")}`
            :
            `Hey chatGPT , this is my resume content ${getValues()?.resumeContent} Please extract phone number, email, job title and current company of the candidate and give response in the form of json. The keys of object should be email, phone, jobTitle, companyName If any of the field is missing please return "" instead of returning Undefined or any thing else`
        }
        promptMessageForDisplay={`Provide me phone number, email, job title and current company for this Resume`}
        promptMessageForVerification={
          replacePlaceholderWithResume(getTemplateByModule(templateData?.AllChatGptTemplates?.chatGPTtemplates, 'UPDATE_CANDIDATE_FROM_RESUME'), getValues()?.resumeContent, "{{resume_content}}") ?? `Hey chatGPT , this is my resume content Please extract phone number, email, job title and current company of the candidate and give response in the form of json. The keys of object should be email, phone, jobTitle, companyName If any of the field is missing please return "" instead of returning Undefined or any thing else`
        }
        heading={'Resume Parsing'}
        clearChat={clearChat}
      />}
    </>
  );
}
export default ResumeParsing