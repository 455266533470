// packages block
import { FC } from 'react'
import { toast } from "react-toastify";
import { useForm } from 'react-hook-form';
import { useMutation } from "@apollo/client";
import {
  FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader,
} from "reactstrap";
// components block
import CustomButton from "../../../../components/layout/CustomButton";
// graphql block
import { VOID_AGREEMENT_DOCUSIGN } from "../../gql";

const VoidAgreementModal: FC<any> = ({ envelopeId, isOpen, toggle, fetchAgreement }) => {
  const { register, errors, handleSubmit } = useForm()
  const [voidDocusingAgreement, { loading: voidLoading }] = useMutation(VOID_AGREEMENT_DOCUSIGN);
  const voidAgreement = async ({ voidReason }) => {
    let res = await voidDocusingAgreement({ variables: { envelopeId, voidReason } })

    if (res?.data?.voidEnvelope?.status === 200) {
      toast.success("Voided Successfully");
      fetchAgreement()
      toggle()
    } else {
      let err: any = res?.errors?.length && res?.errors[0]
      toast.error(err?.message)
      toggle();
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className="revampDialog revampDialogWidth"
    >
      <ModalHeader toggle={toggle}>Add Reason For Voiding Envelope</ModalHeader>
      <ModalBody>
        <FormGroup>
          <Input type='textarea' name='voidReason' rows={3}
            innerRef={register({ required: { value: true, message: "Reason Required!" } })}
            placeholder="Add Reason here..."
          />

          <small className='text-danger'>{errors?.voidReason?.message}</small>
        </FormGroup>
      </ModalBody>

      <ModalFooter>
        <button type="button" onClick={() => toggle()} className="buttonPrimary mr-3">
          Cancel
        </button>
        
        <CustomButton
          buttonText="Void"
          buttonColor="primary"
          buttonType="submit"
          className="big-width"
          buttonClick={handleSubmit(voidAgreement)}
          loading={voidLoading}
          disabled={voidLoading}
        />
      </ModalFooter>
    </Modal>
  )
}

export default VoidAgreementModal
