import { useState } from "react";
import {
  Badge,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import CustomButton from "../../../components/layout/CustomButton";
import { createMarkup, formatDateUS } from "../../../utils/commonFn";

export default function SingleNote({
  noteableName,
  category,
  createdAt,
  description,
  userFullName,
  companyName,
  contactName,
  jobName,
  candidateName,
}) {
  const [notesModal, setNotesModal] = useState<boolean>(false);
  const notesToggle = () => setNotesModal(!notesModal);
  return (
    <div>
      <h5 className="d-flex flex-wrap gap-10">
        {category && (
          <Badge color="success" className="badge-secondary mr-2">
            Category: {category}
          </Badge>
        )}
        {companyName && (
          <Badge color="success" className="badge-secondary mr-2">
            Company: {companyName}
          </Badge>
        )}
        {contactName && (
          <Badge color="success" className="badge-secondary mr-2">
            Contact: {contactName}
          </Badge>
        )}
        {userFullName && (
          <Badge color="success" className="badge-secondary mr-2">
            Recruiter: {userFullName}
          </Badge>
        )}
        {jobName && (
          <Badge color="success" className="badge-secondary mr-2">
            Job: {jobName}
          </Badge>
        )}
        {candidateName && (
          <Badge color="success" className="badge-secondary mr-2">
            Candidate: {candidateName}
          </Badge>
        )}
      </h5>
      {noteableName}
      <p
        className="d-block font-weight-bold text-justify"
        dangerouslySetInnerHTML={createMarkup(
          description?.length > 100
            ? `${description?.slice(0, 100)}...`
            : description
        )}
      />

      {description?.length > 100 ? (
        <Button
          className="bg-transparent text-info border-0 p-0 d-flex"
          onClick={() => notesToggle()}
        >
          <small className="for-link-theme-color">See full description</small>
        </Button>
      ) : null}

      <p className="user mt-3">
        {userFullName}{" "}
        <span>
          {new Date(createdAt).toLocaleTimeString()} {formatDateUS(createdAt)}
        </span>
      </p>

      <Modal isOpen={notesModal} toggle={notesToggle} id="agreementModal">
        <ModalHeader toggle={notesToggle}>View Notes</ModalHeader>

  <div>
    <h5 className="d-flex flex-wrap gap-10">
        {category && (
          <Badge color="success" className="badge-secondary mr-2">
            Category: {category}
          </Badge>
        )}
        {companyName && (
          <Badge color="success" className="badge-secondary mr-2">
            Company: {companyName}
          </Badge>
        )}
        {contactName && (
          <Badge color="success" className="badge-secondary mr-2">
            Contact: {contactName}
          </Badge>
        )}
        {userFullName && (
          <Badge color="success" className="badge-secondary mr-2">
            Recruiter: {userFullName}
          </Badge>
        )}
        {jobName && (
          <Badge color="success" className="badge-secondary mr-2">
            Job: {jobName}
          </Badge>
        )}
        {candidateName && (
          <Badge color="success" className="badge-secondary mr-2">
            Candidate: {candidateName}
          </Badge>
        )}
   
     </h5>
  </div>



        <ModalBody>
          <div
            dangerouslySetInnerHTML={createMarkup(description)}
            className="p-3"
            style={{ wordBreak: "break-all" }}
          />
        </ModalBody>
        <ModalFooter>
          <CustomButton
            buttonText="Close"
            buttonColor="default"
            buttonType="button"
            buttonClick={notesToggle}
          />
        </ModalFooter>
      </Modal>
    </div>
  );
}
