import { useLazyQuery, useMutation } from '@apollo/client';
import { useContext, useEffect, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import Select from 'react-select';
import { toast } from 'react-toastify';
import DownloadImg from '../../../../assets/images/download.svg';

import {
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  Row,
  Button,
  Spinner,
} from 'reactstrap';
import CustomButton from '../../../../components/layout/CustomButton';
import {
  Sidedrawer,
  DrawerHeader,
} from '../../../../components/layout/Sidedrawer';
import { AppContext } from '../../../../context';
import {
  downloadSignedUrlFile,
  removingExtension,
  truncateDocumentName,
  urlExtension,
} from '../../../../utils/commonFn';
import { commaRemover, priceHandler } from '../../../../utils/helper';
import {
  CREATE_SUBMISSION,
  GET_CANDIDATE_WRITEUP,
} from '../../../candidate-submission/gql';
import { GET_CANDIDATE_NOTES, GET_OWNER_SHAREES } from '../../../company/gql';
import {
  GET_JOB_ORDER_MAIN_CONTACT,
  GET_JOB_ORDER_SUBMISSION,
} from '../../../job-order/gql';
import { GET_SUBMISSIONS } from '../../../recruiter-folder/gql';
import ResumeUpload from '../../candidate-components/ResumeUpload';
import {
  EDIT_WRITE_UP,
  GET_CANDIDATE_RESUMES,
  GET_SIGNED_URL,
  GET_SUBMISSION_ATTACHMENTS,
} from '../../gql';
import { customSelectDropDown } from '../../../../components/styled/customSelect';
import ConfirmPopUp from '../../../../components/ConfirmPopUp';
import SubmissionReview from '../../../candidate-submission/Pipeline-components/SubmissionReview';
import SubmitModal from '../../../candidate-submission/Pipeline-components/SubmitModal';
import SubmittedWarningsModal from '../../../candidate-submission/Pipeline-components/SubmittedWarningsModal';
import ApplicantNotes from '../../../../components/ApplicantNotes';
import { numbRegex } from '../../../../utils/constant';
import JobOrderDetailWidgetForDrawer from '../../candidate-components/JobOrderDetailWidgetForDrawer';
import AttachmentsUploadModal from '../../candidate-components/AttachmentsUpload';
import DownloadAndLinkView from '../../candidate-components/DownloadAndLinkView';
import { Controller, useForm } from 'react-hook-form';

let time: any = 0;


const SubmissionDetailDrawer = ({
  toggle,
  isOpen,
  jobOrderId,
  candidate,
  applicantId,
  submissionId,
  companyId,
  refetchApplicant,
  backClick,
  setCandidateFilter,
  ...props
}) => {
  const { user, userRoles } = useContext(AppContext);
  const { theme } = useContext(AppContext);
  const [selectedCompany, setSelectedCompany] = useState<''>();
  const [signedUrlGetter] = useMutation(GET_SIGNED_URL);
  const [editWriteupValue, setEditWriteup] = useState<any>('');
  const [writeupId, setWriteupId] = useState<any>(null);
  const [confirmToggle, setConfirmToggle] = useState(false);
  const [manualAlert, setManualAlert] = useState(false);
  const [submitModal, setSubmitModal] = useState<any>(false);
  const [errMsg, setErrMsg] = useState('');
  const [salaryErrMsg, setSalaryErrMsg] = useState('');
  const [unsignedOriginalUrl, setUnsignedOriginalUrl] = useState('');
  const [unsignedOriginalName, setUnsignedOriginalName] = useState('');
  const [, setOriginalResumeUrl] = useState('');
  const [pdfVersionUrl, setPdfVersionUrl] = useState('');
  const [pdfVersionName, setPdfVersionName] = useState('');
  const [pdfVersionId, setPdfVersionId] = useState('');
  const [, setOriginalResumeName] = useState('');
  const [updatePermission, setUpdatePermission] = useState(false);
  const [submissionComplete, setSubmissionComplete] = useState(false);
  const [isRecruiter, setIsRecruiter] = useState(true);
  const [onlyCandidateOwner, setOnlyCandidateOwner] = useState(false);
  const [, setFileStatus] = useState('');
  const [companyOwnerId, setCompanyOwnerId] = useState(null);
  const [writeUpLoading, setWriteUpLoading] = useState(false);
  const [documentDetail, setDocumentDetail] = useState<any>({});
  const [targetSalary, setTargetSalary] = useState<any>('');
  const [targetSalaryError, setTargetSalaryError] = useState<boolean>(false);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [resumeUploadModal, setResumeUploadModal] = useState(false);
  const [submissionErr, setSubmissionErr] = useState<any>({});
  const [attachmentDetail, setAttachmentDetail] = useState<Array<any>>([]);
  const [attachmentUploadModal, setAttachmentUploadModal] = useState(false);
  const [companyOwnerShareIds, setCompanyOwnerShareIds] = useState<Array<any>>(
    []
  );
  const { getValues, setValue, control, handleSubmit } = useForm()
  const [salaryRate, setSalaryRate] = useState<any>({
    salaryType: 'HOURLY',
    salary: '',
  });

  const [getJobOrder, { data: getSingleJobOrder }] =
    useLazyQuery(GET_JOB_ORDER_SUBMISSION);

  const [getSubmissionAttachments, { data: submissionAttachments, refetch, loading }] =
    useLazyQuery(GET_SUBMISSION_ATTACHMENTS);
  const [getJobMainContactQuery] = useLazyQuery(
    GET_JOB_ORDER_MAIN_CONTACT
  );
  const [_getResumes, { data: candidateResumes }] = useLazyQuery(
    GET_CANDIDATE_RESUMES
  );

  const [getCompanyAndOwner, { data: companyOwner }] =
    useLazyQuery(GET_OWNER_SHAREES);

  const [editWriteUp] = useMutation(EDIT_WRITE_UP);
  const [getWriteUp, { data: writeUp }] =
    useLazyQuery(GET_CANDIDATE_WRITEUP);
  const [submissionCreate, { loading: submitting }] =
    useMutation(CREATE_SUBMISSION);
  const [getSubmissionApplicant, { data: submissionReview }] =
    useLazyQuery(GET_SUBMISSIONS);
  const [getCandidateNotes, { data: candidateNotes }] =
    useLazyQuery(GET_CANDIDATE_NOTES);

  const getSubmissionReview = () => {
    getSubmissionApplicant({
      variables: {
        applicantId: applicantId,
      },
    });
  };

  const _getWriteUp = (id: string) => {
    getWriteUp({
      variables: {
        candidateId: id,
        companyOwner: props?.landedFrom === 'mainPage' ? true : false,
      },
    });
  };

  // here we verify current user has ability to submit this candidate
  const submissionErrors = () => {
    let candidateOwner = candidate?.recruiterOwner?.id;
    let candidateEmails = candidate?.emails;
    let candidatePhones = candidate?.phones;
    let candidateAddress =
      candidate?.streetAddress || candidate?.city || candidate?.stateCode;

    let candidateWriteUp = writeUp?.checkCandidateWriteup?.writeupSummary;
    let recruiterAssign =
      getSingleJobOrder?.getJobOrder?.recruitingAssigned?.find(
        (item) => item.user?.id === user?.id
      );
    let companyOwnerHere = companyOwner?.getShareesAndOwner?.find(
      (item) => item?.ownerType === 'OWNER'
    );
    let companyOwnerShares = companyOwner?.getShareesAndOwner?.map(
      (item) => item?.owner?.id
    );

    let candidateNotesExist =
      candidateNotes?.getCandidateNotes?.length &&
      candidateNotes?.getCandidateNotes?.find(
        (item) =>
          item?.category === 'Phone Call' ||
          item?.category === 'Internal Interview'
      );
    let candidateWebInterview =
      candidateNotes?.getCandidateNotes?.length &&
      candidateNotes?.getCandidateNotes?.find(
        (item) => item?.category === 'Internal Web Interview'
      );

    const errs: any = {
      ownerCandidate: false,
      candidateEmails: false,
      candidatePhones: false,
      jobAssign: false,
      summary: false,
      candidateAddress: false,
      noteCategory: false,
      webInterview: false,
      modal: false,
    };
    if (props?.landedFrom !== 'mainPage') {
      //this condition is used if user land from pipeline in this case we are not checking validations
      if (candidateOwner !== user?.id) {
        errs.ownerCandidate = true;
        errs.modal = true;
      }
      if (!companyOwnerShares?.includes(user?.id)) {
        errs.jobAssign = true;
        errs.modal = true;
        if (recruiterAssign) {
          errs.jobAssign = false;
          errs.modal = false;
        }
      }
      if (!recruiterAssign) {
        errs.jobAssign = true;
        errs.modal = true;

        if (companyOwnerShares?.includes(user?.id)) {
          errs.jobAssign = false;
          errs.modal = false;
          setIsRecruiter(false);
        }
      }

      if (
        !candidateEmails?.length ||
        (candidateEmails?.length && !candidateEmails[0]?.email)
      ) {
        errs.candidateEmails = true;
        errs.modal = true;
      }
      if (
        !candidatePhones?.length ||
        (candidatePhones?.length && !candidatePhones[0]?.phoneNumber)
      ) {
        errs.candidatePhones = true;
        errs.modal = true;
      }

      if (!candidateNotesExist) {
        errs.noteCategory = true;
        errs.modal = true;
      }

      if (!candidateWebInterview) {
        errs.webInterview = true;
        errs.modal = true;
      }

      if (!candidateWriteUp) {
        errs.summary = true;
        errs.modal = true;
      }
      if (!candidateAddress) {
        errs.candidateAddress = true;
        errs.modal = true;
      }
      setSubmissionErr(errs);
    }
    if (recruiterAssign && !companyOwnerShares?.includes(user?.id)) {
      setIsRecruiter(true);
    } else {
      setIsRecruiter(false);
    }
    if (
      candidateOwner === user?.id &&
      !companyOwnerShares?.includes(user?.id)
    ) {
      setOnlyCandidateOwner(true);
    } else {
      setOnlyCandidateOwner(false);
    }
    if (companyOwnerHere || companyOwnerShares?.length) {
      setCompanyOwnerId(companyOwnerHere?.owner?.id);
      setCompanyOwnerShareIds(companyOwnerShares);
    }

    if (
      candidateOwner === user?.id ||
      companyOwnerShares?.includes(user?.id) ||
      userRoles?.includes('Executive') ||
      recruiterAssign
    ) {
      setUpdatePermission(true);
    }
  };

  // this is for update the candiadte writeup
  const editOwnerShipWriteup = async () => {
    if (!editWriteupValue) {
      setErrMsg('Write should not be empty!');
      return;
    }
    setWriteUpLoading(true);
    const res = await editWriteUp({
      variables: {
        writeupId,
        writeupSummary: editWriteupValue,
      },
    });
    if (res?.data) {
      setWriteUpLoading(false);
      toast.success('Successfully edited');
    } else {
      toast.error(
        'An error occured in processing your request. Please try again later.'
      );
      setWriteUpLoading(false);
    }
  };

  // this is for checking the submission validation and then submit the candidate
  const submissionValidate = async (values) => {
    // this checko is used for the email submission
    if (
      submissionReview?.getSubmissionWithApplicant?.status === 'COMPLETED' &&
      submissionReview?.getSubmissionWithApplicant?.submitWith === 'ATS'
    ) {
      setSubmissionComplete(!submissionComplete);
      return;
    }
    if (!getValues()?.targetSalary) {
      setTargetSalaryError(true);
      targetRef?.current?.focus();
      return;
    }
    if (!documentDetail?.id && !documentDetail?.url) {
      toast.error('Resume must be selected!');
      return;
    }

    // this condition is used for checking company owner for
    if (!companyOwner?.getShareesAndOwner?.length) {
      setConfirmToggle(!confirmToggle);
      return;
    }
    if (companyOwner?.getShareesAndOwner?.length) {
      let owner = companyOwner?.getShareesAndOwner?.find(
        (item: { ownerType: string }) => item?.ownerType === 'OWNER'
      );
      if (!owner) {
        setConfirmToggle(!confirmToggle);
        return;
      }
    }
    if (!writeUp?.checkCandidateWriteup?.writeupSummary) {
      toast.info('Candidate has not complete summary!');
      return;
    }
    await createSubmission(values);
    if (companyOwnerShareIds?.includes(user?.id)) {
      setSubmitModal(!submitModal);
    } else {
      refetchApplicant();
      setCandidateFilter([{ label: 'Submitted', value: 'Submitted' }]);
      toggle();
    }
  };

  // this is for the target salary validation
  const targetRef: any = useRef(null);

  // here we actully creating the submission after all validation
  const createSubmission = async (values) => {
    let salary: any = commaRemover(values?.salary);
    if (applicantId) {
      let res = await submissionCreate({
        variables: {
          createSubmission: {
            applicantId: applicantId,
            salary:
              salaryRate?.salaryType === 'HOURLY'
                ? parseFloat(salary)
                : parseInt(salary),
            salaryType: salaryRate?.salaryType,

            // Target salary
            targetSalary: values?.targetSalary,
            resumeId: pdfVersionId,
            candidateId: candidate?.id,
            jobOrderId: jobOrderId,
            companyOwnerId,
            submissionSummary: editWriteupValue,
          },
        },
      });
      if (res?.data) {
        res?.data?.createSubmission?.status !== 304 &&
          companyOwnerId !== user?.id &&
          toast.success('Successfully Submitted!');

        res?.data?.createSubmission?.status === 304 &&
          isRecruiter &&
          submissionReview?.getSubmissionWithApplicant?.status !==
          'COMPLETED' &&
          toast.info('Candidate Submission Pending!');
      }
      return res;
    }
    toast.error('Error While Creating Submission!');
  };

  const handleSubmission = (values) => {
    if (
      submissionReview?.getSubmissionWithApplicant?.status === 'COMPLETED' &&
      submissionReview?.getSubmissionWithApplicant?.submitWith === 'MANUAL'
    ) {
      setManualAlert(!manualAlert);
    } else {
      submissionValidate(values);
    }
  };

  const resumeUploadToggle = () => setResumeUploadModal(!resumeUploadModal);
  const attachmentUploadToggle = () => setAttachmentUploadModal(!attachmentUploadModal);

  const onResumeDownload = async () => {
    if (unsignedOriginalUrl && unsignedOriginalName) {
      setDownloadLoading(true);

      await downloadSignedUrlFile(unsignedOriginalUrl, unsignedOriginalName);
      setDownloadLoading(false);
    } else {
      setDownloadLoading(true);
      await downloadSignedUrlFile(pdfVersionUrl, pdfVersionName);
      setDownloadLoading(false);
    }
    // }
  };

  const generateSignedUrlAttachment = async (
    urlToGetSignedUrl: string
  ) => {
    const res = await signedUrlGetter({
      variables: {
        remoteFilePath: urlToGetSignedUrl,
      },
    });

    return await res?.data?.getSignedUrl?.signedUrl
  }

  const generateSignedUrl = async (
    urlToGetSignedUrl: string,
    fileName: string,
    fileId: string
  ) => {
    const res = await signedUrlGetter({
      variables: {
        remoteFilePath: urlToGetSignedUrl,
      },
    });

    if (res?.data?.getSignedUrl?.signedUrl) {
      setDocumentDetail({
        url: res?.data?.getSignedUrl?.signedUrl,
        name: fileName,
        driveUrl: '',
        id: fileId,
      });
      setFileStatus('Original');
    }
  };

  const onOriginalResumeHandler = async (item: {
    originalUrl: string;
    resumeFileName: string;
    id: string;
    pdfVersionUrl: string;
  }) => {
    if (urlExtension(item.originalUrl) === 'pdf') {
      const res = await signedUrlGetter({
        variables: {
          remoteFilePath: item.originalUrl,
        },
      });

      if (res?.data?.getSignedUrl?.signedUrl) {
        setDocumentDetail({
          url: res?.data?.getSignedUrl?.signedUrl,
          name: item?.resumeFileName,
          driveUrl: '',
          id: item.id,
        });
        setFileStatus('Original');
      }
    } else {
      const res = await signedUrlGetter({
        variables: {
          remoteFilePath: item.pdfVersionUrl,
        },
      });

      if (res?.data?.getSignedUrl?.signedUrl) {
        const pdfVersionFileName = removingExtension(item?.resumeFileName);
        setDocumentDetail({
          url: res?.data?.getSignedUrl?.signedUrl,
          name: `${pdfVersionFileName}.pdf`,
          driveUrl: '',
          id: item.id,
        });
        setFileStatus('Original');
      }
    }
  };

  const getResumes = () => {
    _getResumes({ variables: { candidateId: candidate?.id } });
  };

  useEffect(() => {
    if (submissionId) {
      getSubmissionAttachments({
        variables: { submissionId }
      });
    }
    !loading && setAttachmentDetail(submissionAttachments?.getSubmissionAttachments)

  }, [submissionId]);

  useEffect(() => {
    if (candidate) {
      getCandidateNotes({ variables: { candidateId: candidate?.id } });
      getResumes();
    }
    if (companyId) {
      setSelectedCompany(companyId);
      getCompanyAndOwner({ variables: { id: companyId } });
    }
    if (jobOrderId) {
      getJobOrder({ variables: { id: jobOrderId } });

      getJobMainContactQuery({ variables: { id: jobOrderId } });
      // _getWriteUp(candidateId);
    }
    _getWriteUp(candidate?.id);
    // eslint-disable-next-line
  }, [candidate, jobOrderId, companyId]);

  useEffect(() => {
    // this condition is used for job order salary type
    if (getSingleJobOrder?.getJobOrder?.rateType) {
      setSalaryRate({
        ...salaryRate,
        salaryType: getSingleJobOrder?.getJobOrder?.rateType,
      });
    }
    if (submissionReview?.getSubmissionWithApplicant && candidate) {
      let {
        resumeId, resumeUrl, resumeName, targetSalary, salaryType, salary
      } = submissionReview?.getSubmissionWithApplicant;
      let resumeInCandidate = candidate?.resume?.find(
        (item) => item?.id === resumeId
      );

      if (resumeInCandidate) {
        generateSignedUrl(
          resumeInCandidate?.fileType === 'pdf'
            ? resumeInCandidate?.originalUrl
            : resumeInCandidate?.pdfVersionUrl,
          resumeInCandidate?.resumeFileName,
          resumeInCandidate?.id
        );
      }

      setPdfVersionUrl(resumeUrl);
      setPdfVersionName(resumeName);
      setPdfVersionId(resumeId);

      // setTargetSalary(targetSalary);
      setValue('targetSalary', targetSalary)
      setValue('salary', priceHandler(`${salary || ''}`))
      salary && setSalaryRate({
        salaryType: salaryType,
        salary: priceHandler(`${salary}`),
      });
    }

    // eslint-disable-next-line
  }, [
    submissionReview?.getSubmissionWithApplicant,
    candidate,
    getSingleJobOrder?.getJobOrder?.rateType,
  ]);

  useEffect(() => {
    if (
      submissionReview?.getSubmissionWithApplicant ||
      writeUp?.checkCandidateWriteup?.writeupSummary
    ) {
      let submissionSummary =
        submissionReview?.getSubmissionWithApplicant?.submissionSummary;

      setEditWriteup(
        submissionSummary
          ? submissionSummary
          : writeUp?.checkCandidateWriteup?.writeupSummary
      );
      setWriteupId(writeUp?.checkCandidateWriteup?.id);
      setDocumentDetail({
        id: submissionReview?.getSubmissionWithApplicant?.resumeId,
        name: submissionReview?.getSubmissionWithApplicant?.resumeName
      })
    }

    // eslint-disable-next-line
  }, [writeUp, submissionReview?.getSubmissionWithApplicant]);

  useEffect(() => {
    if (!submitModal) {
      getSubmissionReview();
    }

    // eslint-disable-next-line
  }, [submitModal]);

  useEffect(() => {
    if (
      getSingleJobOrder ||
      candidate ||
      writeUp ||
      companyOwner ||
      candidateNotes ||
      user
    ) {
      clearTimeout(time);
      time = setTimeout(() => {
        submissionErrors();
      }, 500);
    }

    // eslint-disable-next-line
  }, [
    getSingleJobOrder,
    candidate,
    writeUp,
    companyOwner,
    candidateNotes,
    user,
  ]);

  return (
    <>
      <Sidedrawer toggle={() => backClick()} isOpen={isOpen}>
        <div className="mb-4">
          <DrawerHeader
            prevClick={() => backClick()}
            heading="Submission Detail"
            nextButtonView={false}
          />
        </div>

        {submissionErr?.modal ? (
          <SubmittedWarningsModal err={submissionErr} backUrl={''} />
        ) : (
          <div className="candidateSubmission">
            <div className="CandidateTabsWrapper">
              <JobOrderDetailWidgetForDrawer
                isTitle={false}
                applicantData={{ JobOrder: getSingleJobOrder?.getJobOrder }}
              />
            </div>

            <Form>
              <div>
                <Row>
                  <Col lg="12" md="12" sm="12" xs="12">
                    <div className="pb-2">
                      <Label className="text-14 text-blackele pb-2">
                        Salary Type
                      </Label>

                      <div className="relocate-allowed">
                        <FormGroup check className="p-0">
                          <Label className="radio-container w-100">
                            Annually
                            <Input
                              type="radio"
                              name="salaryType"
                              value="ANNUAL"
                              onClick={({ target: { value } }: any) =>
                                setSalaryRate({
                                  ...salaryRate,
                                  salaryType: value,
                                })
                              }
                              checked={
                                salaryRate.salaryType === 'ANNUAL'
                                  ? true
                                  : false
                              }
                              disabled={
                                !updatePermission ||
                                submissionReview?.getSubmissionWithApplicant
                                  ?.status === 'COMPLETED'
                              }
                            />
                            <span className="checkmark"></span>
                          </Label>
                        </FormGroup>
                        <FormGroup check>
                          <Label className="radio-container w-100">
                            Hourly
                            <Input
                              type="radio"
                              value="HOURLY"
                              name="salaryType"
                              onClick={({ target: { value } }: any) =>
                                setSalaryRate({
                                  ...salaryRate,
                                  salaryType: value,
                                })
                              }
                              checked={
                                salaryRate.salaryType === 'HOURLY'
                                  ? true
                                  : false
                              }
                              disabled={
                                !updatePermission ||
                                submissionReview?.getSubmissionWithApplicant
                                  ?.status === 'COMPLETED'
                              }
                            />
                            <span className="checkmark"></span>
                          </Label>
                        </FormGroup>
                      </div>
                    </div>
                  </Col>

                  <Col lg="12" md="12" sm="12" xs="12">
                    <div className="pb-3 custom-form-input form-input ">
                      <Label className="text-14 text-blackele pb-3">
                        Current Salary
                      </Label>

                      <InputGroup>
                        <InputGroupAddon addonType="prepend">$</InputGroupAddon>

                        <Controller
                          name='salary'
                          control={control}
                          defaultValue="0"
                          render={(field) => (
                            <Input
                              {...field}
                              placeholder="Amount"
                              readOnly={
                                !updatePermission ||
                                submissionReview?.getSubmissionWithApplicant
                                  ?.status === 'COMPLETED'
                              }
                              type="text"

                              // value={salaryRate?.salary}
                              onChange={({ target: { value } }) => {
                                const filteredValue = value.replace(/,/g, '')
                                if (numbRegex.test(filteredValue) || !value) {
                                  let val = priceHandler(value);
                                  setValue('salary', val)
                                  // setSalaryRate({
                                  //   ...salaryRate,
                                  //   salary: val,
                                  // });
                                  setSalaryErrMsg('');
                                }
                                if (!numbRegex.test(filteredValue)) {
                                  setSalaryErrMsg('Salary not valid!');
                                }
                              }}
                            />
                          )}
                        />
                        {/* <Input
                          placeholder="Amount"
                          readOnly={
                            !updatePermission ||
                            submissionReview?.getSubmissionWithApplicant
                              ?.status === 'COMPLETED'
                          }
                          type="text"
                          value={salaryRate?.salary}
                          defaultValue="0"
                          onChange={({ target: { value } }) => {
                            if (numbRegex.test(value) || !value) {
                              let val = priceHandler(value);
                              setSalaryRate({
                                ...salaryRate,
                                salary: val,
                              });
                              setSalaryErrMsg('');
                            }
                            if (!numbRegex.test(value)) {
                              setSalaryErrMsg('Salary not valid!');
                            }
                          }}
                        /> */}
                      </InputGroup>

                      {salaryErrMsg && (
                        <small
                          className="text-danger d-block mt-2"
                          style={{ height: '2px' }}
                        >
                          {salaryErrMsg || ''}
                        </small>
                      )}
                    </div>
                  </Col>

                  <Col lg="12" md="12" sm="12" xs="12">
                    <div>
                      <Label
                        className={`text-14 text-blackele pb-3 ${salaryErrMsg ? 'mt-2' : ''
                          }`}
                      >
                        Target Salary <span className="text-danger">*</span>
                      </Label>
                    </div>

                    <div className="search-filter">
                      <FormGroup className="w-100 custom-form-input form-input">
                        <Controller
                          name='targetSalary'
                          control={control}
                          defaultValue=""
                          render={(field) => (
                            <Input
                              {...field}
                              name="targetSalary"
                              placeholder="Target Salary"
                              // innerRef={targetRef}
                              readOnly={
                                !updatePermission ||
                                submissionReview?.getSubmissionWithApplicant
                                  ?.status === 'COMPLETED'
                              }
                              // type="textarea"
                              // value={targetSalary}
                              onChange={({ target: { value } }) => {
                                // setTargetSalary(value);
                                setValue('targetSalary', value)
                                if (value) {
                                  setTargetSalaryError(false);
                                } else {
                                  setTargetSalaryError(true);
                                }
                              }}
                            />
                          )} />
                        {/* <Input
                          name="targetSalary"
                          placeholder="Target Salary"
                          innerRef={targetRef}
                          readOnly={
                            !updatePermission ||
                            submissionReview?.getSubmissionWithApplicant
                              ?.status === 'COMPLETED'
                          }
                          // type="textarea"
                          value={targetSalary}
                          defaultValue=""
                          onChange={({ target: { value } }) => {
                            setTargetSalary(value);
                            if (value) {
                              setTargetSalaryError(false);
                            } else {
                              setTargetSalaryError(true);
                            }
                          }}
                        /> */}
                        {targetSalaryError && (
                          <small className="text-danger d-flex">
                            Target Salary is required{' '}
                          </small>
                        )}
                        <small className="text-info">
                          e.g. Evan is seeking a base of $50,000 to $55,000 in
                          his next role.
                        </small>
                      </FormGroup>
                    </div>
                  </Col>
                </Row>
              </div>

              <div className="pb-3">
                {/* <div className="pb-3">
                  <strong className="text-14">About</strong>
                </div> */}
                <div className="CandidateTabsWrapper">
                  <div>
                    <FormGroup>
                      <div className="d-flex justify-content-between align-items-center mb-3">
                        <Label className="text-14  text-blackele pb-3">
                          Candidate Writeup
                        </Label>
                        {updatePermission && editWriteupValue && writeupId && (
                          <Button
                            className="btn-outline-warning px-3"
                            size="sm"
                            onClick={() => editOwnerShipWriteup()}
                            disabled={
                              userRoles?.includes('RECRUITER') &&
                              submissionReview?.getSubmissionWithApplicant
                                ?.status === 'COMPLETED'
                            }
                          >
                            {writeUpLoading && (
                              <Spinner
                                size="sm"
                                style={{ color: '#eaa827' }}
                                className="mr-2"
                              />
                            )}{' '}
                            Save Writeup
                          </Button>
                        )}
                      </div>

                      <div className="editor-height">
                        <ReactQuill
                          readOnly={
                            !updatePermission ||
                            submissionReview?.getSubmissionWithApplicant
                              ?.status === 'COMPLETED'
                          }
                          onChange={(value) => {
                            const reg = new RegExp(
                              /^<p>(<br>|<br\/>|<br\s\/>|\s+|)<\/p>$/gm
                            );
                            if (reg.test(value)) {
                              setEditWriteup(null);
                            } else {
                              setEditWriteup(value);
                            }
                          }}
                          value={editWriteupValue}
                        />
                        <small className="text-danger pt-2">{errMsg}</small>
                      </div>
                    </FormGroup>
                  </div>
                  <div className="mb-3">
                    <div className="d-flex justify-content-between align-items-center mb-3">
                      <Label className="text-14 text-blackele pb-3">Attachments</Label>
                      {updatePermission && (
                        <button
                          type="button" className="btn-outline_prime mr-2"
                          onClick={attachmentUploadToggle}
                          disabled={
                            !updatePermission ||
                            submissionReview?.getSubmissionWithApplicant
                              ?.status === 'COMPLETED'
                          }>
                          Upload
                        </button>
                      )}
                    </div>

                    {attachmentDetail?.map((item, index) => {
                      return <DownloadAndLinkView
                        attachmentName={item?.attachmentName}
                        generateSignedUrl={generateSignedUrlAttachment}
                        attachmentURL={item?.attachmentURL} key={index} />
                    })
                    }

                  </div>
                  <div>
                    <div className="mb-3">
                      {/* <div className="pb-3">
                        <strong className="text-14">Resume</strong>
                      </div> */}

                      <FormGroup>
                        <Label className="text-14 text-blackele pb-3">
                          Select Resume
                        </Label>
                        <Select
                          name="resume"
                          isDisabled={
                            !updatePermission ||
                            submissionReview?.getSubmissionWithApplicant
                              ?.status === 'COMPLETED'
                          }
                          options={candidateResumes?.getCandidateResumes?.map(
                            (item) => ({
                              label: item.resumeFileName,
                              value: {
                                id: item?.id,
                                url: item?.originalUrl,
                                driveUrl: item?.highlightUrl,
                                name: item?.resumeFileName,
                                // scrubUrl: item?.scrubUrl,
                                resumeFileType: item?.resumeFileType,
                                pdfVersionUrl: item?.pdfVersionUrl,
                                originalResumeUrl: item?.originalUrl,
                              },
                            })
                          )}
                          value={
                            documentDetail?.id
                              ? {
                                label: documentDetail?.name,
                                value: { ...documentDetail },
                              }
                              : null
                          }
                          placeholder="Resume"
                          onChange={({ value }: any) => {
                            setUnsignedOriginalUrl(value?.url);
                            setUnsignedOriginalName(value?.name);
                            setOriginalResumeUrl(value?.originalResumeUrl);
                            setPdfVersionUrl(
                              urlExtension(value?.originalResumeUrl) === 'pdf'
                                ? value?.originalResumeUrl
                                : value?.pdfVersionUrl
                            );
                            setPdfVersionName(
                              urlExtension(value?.originalResumeUrl) === 'pdf'
                                ? value?.originalResumeUrl?.split(
                                  'candidates/'
                                )[1]
                                : value?.pdfVersionUrl?.split('candidates/')[1]
                            );
                            setPdfVersionId(value?.id);

                            setOriginalResumeName(value?.name);
                            const objectForOriginalResume = {
                              originalUrl: value?.url,
                              resumeFileName: value?.name,
                              id: value?.id,
                              pdfVersionUrl: value?.pdfVersionUrl,
                            };
                            onOriginalResumeHandler(objectForOriginalResume);
                            setDocumentDetail({ ...value });
                          }}
                          styles={{ ...customSelectDropDown(theme) }}
                        />

                        {!documentDetail?.id && (
                          <small className="text-info">
                            No resume selected
                          </small>
                        )}
                      </FormGroup>

                      <div className="d-flex justify-content-between align-items-center mb-0">
                        <a
                          href={documentDetail?.url}
                          target="blank"
                          className="text-info mr-2"
                          title={documentDetail?.name || ''}
                        >
                          {documentDetail?.name
                            ? truncateDocumentName(documentDetail?.name || '')
                            : ''}
                        </a>

                        <div>
                          {updatePermission && (
                            <button
                              type="button"
                              disabled={
                                !updatePermission ||
                                submissionReview?.getSubmissionWithApplicant
                                  ?.status === 'COMPLETED'
                              }
                              className="btn-outline_prime mr-2"
                              onClick={resumeUploadToggle}
                            >
                              Upload New
                            </button>
                          )}

                          <button
                            type="button"
                            className="btn-outline_prime"
                            disabled={
                              !(documentDetail?.url && documentDetail?.name) ||
                              downloadLoading
                            }
                            onClick={() => {
                              onResumeDownload();
                            }}
                          >
                            {downloadLoading && (
                              <img
                                src={DownloadImg}
                                alt="download"
                                className={`mr-2 mb-1 ${downloadLoading && 'blink_me'
                                  }`}
                              />
                            )}
                            {!downloadLoading ? 'Download' : 'Downloading'}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="text-right pt-3">
                  {updatePermission && (
                    <>
                      <CustomButton
                        buttonText={`${(isRecruiter || onlyCandidateOwner) &&
                          submissionReview?.getSubmissionWithApplicant
                            ?.status !== 'COMPLETED'
                          ? 'Save Submission'
                          : submissionReview?.getSubmissionWithApplicant
                            ?.status === 'COMPLETED'
                            ? 'Preview Mail'
                            : 'Send Candidate'
                          }`}
                        buttonColor="primary"
                        buttonType="button"
                        className="big-width"
                        loading={submitting}
                        buttonClick={handleSubmit(handleSubmission)}
                        disabled={
                          !documentDetail?.id
                            ? true
                            : false &&
                            (submitting ||
                              (isRecruiter &&
                                submissionReview?.getSubmissionWithApplicant
                                  ?.status === 'COMPLETED'))
                        }
                      />
                    </>
                  )}
                </div>
              </div>
            </Form>

            <ApplicantNotes applicantId={applicantId} type="Submission" />
          </div>
        )}
      </Sidedrawer>

      {submitModal && (
        <SubmitModal
          isOpen={submitModal}
          toggle={setSubmitModal}
          companyId={selectedCompany}
          jobOrder={jobOrderId}
          submissionId={submissionId}
          defaultTargetSalary={getValues()?.targetSalary}
          mainContact={
            getSingleJobOrder?.getJobOrder?.mainContact?.contact?.contactEmail
          }
          toggleParent={() => {
            toggle();
            refetchApplicant();
            setCandidateFilter([{ label: 'Submitted', value: 'Submitted' }]);
          }}
          candidateDetail={{
            writeUp: editWriteupValue,
            candidateName: candidate?.fullName,
            resumeId: documentDetail?.id,
            resumeUrl: unsignedOriginalUrl
              ? unsignedOriginalUrl
              : pdfVersionUrl,
            resumeName: unsignedOriginalName
              ? unsignedOriginalName
              : pdfVersionName,
          }}
        />
      )}

      <ConfirmPopUp
        confirmText="Company of this job order has no owner!"
        isOpen={confirmToggle}
        toggle={setConfirmToggle}
        confirmAction={() => setConfirmToggle(!confirmToggle)}
        modalHeading="Message Alert"
        btnText="OK"
        btnColor="primary"
      />
      <ConfirmPopUp
        confirmText="Candidate is submitted manually!"
        isOpen={manualAlert}
        toggle={setManualAlert}
        confirmAction={() => {
          toggle();
        }}
        modalHeading="Message Alert"
        btnText="OK"
        btnColor="primary"
      />
      {submissionComplete && (
        <SubmissionReview
          data={submissionReview?.getSubmissionWithApplicant}
          open={submissionComplete}
          toggle={() => {
            setSubmissionComplete(!submissionComplete);
          }}
        />
      )}

      <ResumeUpload
        toggle={resumeUploadToggle}
        modal={resumeUploadModal}
        candidateId={candidate?.id}
        refetch={() => {
          getResumes();
        }}
        fileType=".doc, .docx,.pdf"
        driveUpload={true}
      />

      <AttachmentsUploadModal
        toggle={attachmentUploadToggle}
        modal={attachmentUploadModal}
        submissionId={submissionId}
        driveUpload={true}
        refetch={refetch}
      />
    </>
  );
};

export default SubmissionDetailDrawer;
