import { gql } from '@apollo/client';

export const GET_CANDIDATE_DETAILS_SUBMIT = gql`
  query GetCandidateDetails($id: String!) {
    getCandidate(id: $id) {
      id
      fullName
      streetAddress
      city
      stateCode
      postalCode
      countryCode
      geoLocation
      jobTitle
      companyName
      candidateStats {
        id
        jobAppCount
        interviewCount
        ownershipExpiration
        submissionCount
        daySinceLastAction
        updatedAt
        lastCheckedInRecruiter {
          id
          fullName
        }
      }
      resume {
        id
        resumeFileName
        originalUrl
        scrubUrl
        pdfVersionUrl
        fileType
        highlightUrl
      }
      emails {
        id
        email
        isPrimary
      }
      phones {
        id
        isPrimary
        phoneNumber
        phoneType
      }
      recruiterOwner {
        id
        fullName
      }
    }
  }
`;

export const GET_ALL_JOB_ORDERS_SUBMIT = gql`
  query FetchAllJobOrders(
    $page: Int!
    $limit: Int!
    $searchText: String
    $statusSearch: String
    $companyId: String
    $myJobOrder: Boolean
    $onlyMe: Boolean
  ) {
    fetchAllJobOrders(
      payload: { page: $page, limit: $limit }
      filters: {
        searchText: $searchText
        statusSearch: $statusSearch
        companyId: $companyId
        myJobOrder: $myJobOrder
        onlyMe: $onlyMe
      }
    ) {
      jobOrders {
        id
        user {
          id
        }
        status
        jobTitle
      }
    }
  }
`;

export const GET_CANDIDATE_WRITEUP = gql`
  query GetCandidateWriteUp($candidateId: String!, $companyOwner: Boolean!) {
    checkCandidateWriteup(
      candidateId: $candidateId
      companyOwner: $companyOwner
    ) {
      id
      writeUpType
      writeupSummary
      endedAt
      createdAt
    }
  }
`;

export const CREATE_SUBMISSION = gql`
  mutation CreateSubmission($createSubmission: CreateSubmissionInput!) {
    createSubmission(createSubmission: $createSubmission) {
      status
      submission {
        id
        status
      }
    }
  }
`;

export const GET_SINGLE_JOB_APPLICANT = gql`
  query GetSingleJobApplicant($candidateId: String!, $jobOrderId: String!) {
    getSingleJobApplicant(candidateId: $candidateId, jobOrderId: $jobOrderId) {
      id
    }
  }
`;

export const GET_COMPANY_EMAILS = gql`
  query GetCompanyEmails($companyId: String!) {
    getCompanyEmails(companyId: $companyId) {
      id
      email
      isPrimary
      createdAt
      fullName
    }
  }
`;
export const CREATE_SUBMISSION_DECLINE = gql`
  mutation CrateSubmissionDecline(
    $submissionHistory: SubmissionStatusHistoryDto!
  ) {
    saveSubmissionHistory(submissionHistory: $submissionHistory) {
      status
      message
    }
  }
`;
export const SUBMISSION_REJECTED = gql`
  mutation CreateSubmissionRejected(
    $rejectSubmissionInput: SubmissionStatusHistoryDto!
  ) {
    rejectSubmission(rejectSubmissionInput: $rejectSubmissionInput) {
      status
      message
    }
  }
`;

export const FETCH_SUBMISSION_EMAIL = gql`
  query fetchSubmissionEmails($submissionId: String!) {
    getSubmission(submissionId: $submissionId) {
      id
      status
      submitWith
      submissionEmail {
        id
        toContacts
        ccContacts
        bccContacts
        subject
        bodyContent
        status
      }
      submissionAttachments {
        id
        attachmentURL
        attachmentType
        attachmentName
      }
    }
  }
`;

// export const CREATE_SUBMISSION_Mail = gql`
//   mutation CreateSubmissionMail($submissionMails: SubmissionMails!) {
//     createSubmissionEmail(submissionMails: $submissionMails) {
//       message
//       status
//     }
//   }
// `;

export const CHANGE_SUBMISSION_STATUS = gql`
  mutation ChangeSubmissionStatusManually(
    $submissionId: String!
    $companyId: String!
    $resumeId: String!
  ) {
    submitManually(
      submissionId: $submissionId
      resumeId: $resumeId
      companyId: $companyId
    ) {
      message
      status
    }
  }
`;

// export const REQUESTION_FOR_CANDIDATE_SUBMISSION = gql`
//   mutation RequestForCandidateSubmission(
//     $managerId: String!
//     $candidateId: String!
//     $companyId: String!
//     $jobId: String!
//     $submissionMails: SubmissionMails!
//     $submissionId: String!
//   ) {
//     requestForCandidateSubmission(
//       managerId: $managerId
//       candidateId: $candidateId
//       companyId: $companyId
//       jobId: $jobId
//       submissionMails: $submissionMails
//       submissionId: $submissionId
//     ) {
//       message
//       status
//     }
//   }
// `;
export const REQUESTION_FOR_CANDIDATE_SUBMISSION = gql`
  mutation RequestForCandidateSubmission(
    $managerId: String!
    $candidateId: String!
    $companyId: String!
    $jobId: String!
    $submissionMails: SubmissionMails!
    $submissionId: String!
  ) {
    requestForCandidateSubmission(
      managerId: $managerId
      candidateId: $candidateId
      companyId: $companyId
      jobId: $jobId
      submissionMails: $submissionMails
      submissionId: $submissionId
    ) {
      message
      status
    }
  }
`;

export const RESPONSE_PERMISSION_SUBMISSION = gql`
  mutation ResponseForCandidateSubmission(
    $ownerID: String!
    $submissionId: String!
    $msg: String!
    $submissionMail: SubmissionMails!
  ) {
    responseForCandidateSubmission(
      ownerID: $ownerID
      submissionId: $submissionId
      msg: $msg
      submissionMails: $submissionMail
    ) {
      message
      status
    }
  }
`;

export const RESPONSE_PERMISSION_SUBMISSION_DENIED = gql`
  mutation ResponseForCandidateSubmissionDenied(
    $ownerID: String!
    $submissionId: String!
    $msg: String!
  ) {
    responseForCandidateSubmissionDenied(
      ownerID: $ownerID
      msg: $msg
      submissionId: $submissionId
    ) {
      message
      status
    }
  }
`;
