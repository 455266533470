// packages block
import { useEffect, useState } from 'react';
import { Badge, Button, Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import { RouteComponentProps } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
// components block
import GoBack from '../../../components/GoBack';
import Loader from '../../../components/layout/Loader';
import ConfirmPopUp from '../../../components/ConfirmPopUp';
import Pagination from '../../../components/layout/Pagination';
import InputField from '../../../components/layout/InputField';
import NoDataFound from '../../../components/layout/NoDataFound';
import TableComponent from '../../../components/layout/TableComponent';
// utils, constants and graphql block
import { DASHBOARD_ROUTE, PAGE_LIMIT, SETTINGS_ROUTE } from '../../../utils/constant';
import { USER_TABLE_HEADER } from '../../../constants';
import { FETCH_USER_IMPERSONATE, LOGIN_AS } from '../gql';
import SEARCH_IMG from "../../../assets/images/search.svg";
import AvatarImage from '../../../assets/images/user-empty.svg';

const LoginAs = ({ history, location }: RouteComponentProps): JSX.Element => {
  const page = new URLSearchParams(location.search).get('currentPage') || '1';
  const _currentPage: number = parseInt(page);
  const [searchParams, setSearchParams] = useState<string>("");
  const [currentPage, setCurrentPage] = useState(_currentPage);
  const [limit, setLimit] = useState(10);

  const { loading, error, data } = useQuery(FETCH_USER_IMPERSONATE, {
    variables: {
      limit: limit,
      page: currentPage,
      searchText: searchParams.length > 2 ? searchParams : ""
    },
  });

  const [loginAs, { data: token, loading: loginLoading }] =
    useMutation(LOGIN_AS);
  const [loginIsOpen, setLoginIsOpen] = useState(false);

  const [persistUser, setPersistUser] = useState<{
    id: string | null;
    fullName: string | null;
  }>({ id: null, fullName: null });

  const loginAsOther = async () => {
    await loginAs({ variables: { id: persistUser.id } });
  };

  const searchControl = (value: string) => {
    setSearchParams(value);
    setCurrentPage(1);
    setLimit(10);
  };

  useEffect(() => {
    const loginAsToken = token?.loginAs?.access_token;
    const previousToken = window.localStorage.token;
    const previousSideBarItem = window.localStorage.sideBarItem

    if (token) {
      if (!window.localStorage.previousToken) {
        window.localStorage.setItem('previousToken', previousToken);
        window.localStorage.setItem('previousSideBarItem', previousSideBarItem);
      }
      token?.loginAs?.acl ? window.localStorage.setItem("sideBarItem", token?.loginAs?.acl) :
        window.localStorage.removeItem("sideBarItem");
      window.localStorage.setItem('token', loginAsToken);
      history.push(DASHBOARD_ROUTE);
      // window.location.reload();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    document.title = 'Impersonate - RealREPP';

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    history.push(`/admin/persist?currentPage=${currentPage}`);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  return (
    <div>
      <div className="admin-tabs">
        <GoBack url={SETTINGS_ROUTE} />


        <div className="search-filter primaryHeaderSpacing">

          <div className="primaryHeading">
            <h5 className="m-0">Impersonate!</h5>
            <p className="primaryHeadingSubtitle m-0">
              You can impersonate as the following users. Please note that when
              you will personate, you will be performing action as them.{' '}
            </p>
          </div>

          <div>
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <img src={SEARCH_IMG} alt="search" />
                </InputGroupText>
              </InputGroupAddon>

              <Input
                type="text"
                placeholder="Search by full name"
                value={searchParams}
                onChange={(e) => {
                  // setSearchParams(e.target.value);
                  searchControl(e.target.value);
                }}
                className="search-by-company"
              />
            </InputGroup>

            <small className="text-info">
              {searchParams &&
                searchParams.length <= 2 &&
                " Please add more than two characters"}
            </small>
          </div>

        </div>

        <div className="table-responsive">
          <TableComponent tableHeader={USER_TABLE_HEADER}>
            {loading && (
              <tbody>
                <tr>
                  <td colSpan={6}>
                    <Loader loaderClass="sm-height " />
                  </td>
                </tr>
              </tbody>
            )}

            <tbody>
              {data?.allImpersonateUsers &&
                data?.allImpersonateUsers?.users?.length ? (
                data?.allImpersonateUsers.users.map(
                  (user: any, index: number) =>
                    <tr key={index}>
                      <td>
                        <div className="avatar-profile">
                          <div className="avatar">
                            <img
                              src={user.photo || AvatarImage}
                              alt="avatar"
                              className="user-img-round"
                            />
                          </div>
                          {user.fullName}
                        </div>
                      </td>

                      <td>
                        <a href={user?.email ? `mailto:${user.email}` : '#'}>
                          {user.email}
                        </a>
                      </td>

                      <td>
                        <a href={user?.phone ? `tel:${user.phone}` : '#'}>
                          {user.phone}
                        </a>
                      </td>

                      <td>
                        {user.userRoles && user.userRoles.length ? (
                          user.userRoles.map(
                            (roleOfuser: any, index: number) =>
                              roleOfuser?.deletedAt === null && (
                                <span
                                  key={`roles_${index}`}
                                  className="text-capitalize"
                                >
                                  {`${roleOfuser.role.name}`.toLowerCase()}{' '}
                                  &nbsp;
                                </span>
                              )
                          )
                        ) : (
                          <span className="text-dark mx-2">--</span>
                        )}
                      </td>
                      <td>
                        <Badge
                          color={
                            user.status === 'ACTIVE'
                              ? 'success'
                              : user.status === 'ON_LEAVE'
                                ? 'warning'
                                : user.status === 'SUSPENDED'
                                  ? 'primary'
                                  : user.status === 'TERMINATED'
                                    ? 'danger'
                                    : user.status === 'NEW_HIRE'
                                      ? 'info'
                                      : ''
                          }
                          className="text-capitalize"
                        >
                          {user.status.toLowerCase()}
                        </Badge>
                      </td>
                      <td>
                        <Button
                          size="sm"
                          className="border-0 font-wegiht-bold bg-transparent link-text-btn"
                          onClick={() => {
                            setLoginIsOpen(!loginIsOpen);
                            setPersistUser({
                              id: user.id,
                              fullName: user.fullName,
                            });
                          }}
                        >
                          Impersonate
                        </Button>
                      </td>
                    </tr>
                )
              ) : (
                <tr>
                  <td colSpan={6} className="empty-table-td">
                    <NoDataFound text="Currently, you are not managing any team member!" />
                  </td>
                </tr>
              )}
            </tbody>
          </TableComponent>

          {error?.graphQLErrors[0]?.message}
        </div>

        <div>
          {!loading && data?.allImpersonateUsers.count ? (
            <div className="users-pagination">
              <Pagination
                onPageChange={(pageClicked: number) => {
                  setCurrentPage(pageClicked);
                  history.push(`/admin/persist?currentPage=${pageClicked}`);
                }}
                pageCount={Math.ceil(data?.allImpersonateUsers?.count / limit)}
                currentPage={currentPage}
              />

              <div className="d-flex align-items-center">
                <div className="pagination-display paginationItemsSelect">
                  <p className="m-0">
                    {(currentPage - 1) * limit + 1} to{' '}
                    {Math.min(
                      currentPage * limit,
                      data?.allImpersonateUsers?.count
                    )}{' '}
                    of {data?.allImpersonateUsers?.count}
                  </p>

                  <div className="divider"></div>

                  <label>Display</label>

                  <InputField
                    label=""
                    inputtype="select"
                    selectItems={PAGE_LIMIT.filter(
                      (item) => item.value !== limit
                    )}
                    inputid="limit"
                    placeholder={limit.toString()}
                    inputMethod={(value: string) => {
                      setCurrentPage(1);
                      setLimit(parseInt(value));
                    }}
                  />
                </div>
              </div>
            </div>
          ) : ''}
        </div>
      </div>
      <ConfirmPopUp
        confirmText="You are about to impersonate as"
        isOpen={loginIsOpen}
        toggle={setLoginIsOpen}
        confirmAction={loginAsOther}
        modalHeading="Impersonate"
        btnText="Proceed"
        extraProp={`${persistUser.fullName}.`}
        btnColor="warning"
        secondaryText="However, you can always revert back to your account through dashboard."
        className="revampDialog revampDialogWidth"
        loading={loginLoading}
        disabled={loginLoading}
      />
    </div>
  );
};

export default LoginAs;
