import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import moment from "moment";
import { useContext } from "react";
import { Controller, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import { Col, Form, Input, Label, Row } from "reactstrap";
import CustomButton from "../../components/layout/CustomButton";
import { customSelectDropDown } from "../../components/styled/customSelect";
import { AppContext } from "../../context";
import { FETCH_USER_DETAIL } from "../admin/gql";
import { GET_RECRUITER_LIST } from "../job-order/gql";
import { ADD_TAB } from "./gql";
type FormType = {
  month: string,
  recruiter: {
    value?: string;
    label?: string;
    message?: string
  }
}

const OpenGoalSheet = () => {
  const history = useHistory()
  const { theme, user } = useContext(AppContext)
  const { data: recruiters } = useQuery(GET_RECRUITER_LIST);
  const [addTabs] = useMutation(ADD_TAB)
  const [getUserDetail, { loading }] = useLazyQuery(FETCH_USER_DETAIL);
  const { handleSubmit, control, errors } = useForm<FormType>()
  return (
    <>
      <div>
        <Form>
          <Row>
            <Col lg="4" md="4" xs="12">
              <Label>Month</Label>
              <Controller
                control={control}
                rules={{ required: "Month is required" }}
                name="month"
                defaultValue={new Date().toISOString().substring(0, 7)}
                render={({ onChange, ref }) => (
                  <Input
                    defaultValue={new Date().toISOString().substring(0, 7)}
                    type="month"
                    ref={ref}
                    onChange={onChange}
                    placeholder="Select Month"
                    max={new Date().toISOString().substring(0, 7)}
                  />
                )}
              />
              <small className="text-danger pt-2">
                {errors?.month?.message}
              </small>
            </Col>

            {recruiters?.getRecruiterList?.length && <Col lg="4" md="4" xs="12">
              <Label>Recruiter</Label>
              <Controller
                name="recruiter"
                as={Select}
                rules={{ required: "Recruiter is required" }}
                options={
                  recruiters?.getRecruiterList?.length &&
                  recruiters?.getRecruiterList?.map((item: any) => ({
                    label: item.fullName,
                    value: item.id,
                  }))
                }
                control={control}
                placeholder="Select Recruiter"
                isSearchable={true}
                styles={{ ...customSelectDropDown(theme) }}
              />
              <small className="text-danger pt-2">
                {errors?.recruiter?.message}
              </small>
            </Col>}
          </Row>

          <div className="pt-4">
            <CustomButton
              buttonColor="primary"
              buttonText="Access Report"
              className="big-width"
              loading={loading}
              buttonClick={handleSubmit(async ({ month, recruiter }) => {
                const userData = getUserDetail({ variables: { userId: recruiter?.value || user?.id } })
                if (!loading && (await userData).data?.findUserById?.earningGoal) {
                  await addTabs({
                    variables: {
                      createGoalSheetTabDto: {
                        month: month.slice(5),
                        year: moment(month).year().toString(),
                        recruiterId: recruiter?.value || user?.id
                      }
                    }, onCompleted: (data) => {
                      localStorage.setItem(`goalSheetTabs`, JSON.stringify(data?.addTab?.tabs))
                      history.push(`goal-sheet-report/${recruiter?.value || user?.id}?month=${Number(month.slice(5))}&year=${Number(month.slice(0, 4))}`)
                    }
                  })
                }
                else {
                  toast.error("Please select your earning goal first")
                }
              })}
            />
          </div>
        </Form>
      </div>
    </>
  );
};

export default OpenGoalSheet;