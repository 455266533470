import {
  Button,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { Controller, useForm } from "react-hook-form";
import CustomButton from "../../../../components/layout/CustomButton";
import ReactQuill from "react-quill";

import CLOSE_ICON from "../../../../assets/images/close-icon.svg";
import { reactQuillReg } from "../../../../utils/constant";

const RejectNotes = ({ modal, toggle, onRejectConfirm, loading }) => {
  const { errors, handleSubmit, control } = useForm();

  const customStyles = {
    control: (styles, provided) => ({
      ...styles,

      fontSize: 12,
    }),

    option: (provided, state) => ({
      ...provided,
      fontSize: 12,
    }),

    container: (provided, state) => ({
      ...provided,
      fontSize: 12,
    }),
  };

  return (
    <div>
      <Modal isOpen={modal} className="revampDialog revampDialogWidth">
        <div className="modalHeaderCustom d-flex align-items-center justify-content-between">
          <h5 className="m-0">Rejection Notes</h5>

          <Button className="p-0 bg-transparent border-0" onClick={toggle}>
            <img src={CLOSE_ICON} alt="close" />
          </Button>
        </div>
        <Form>
          <ModalBody>
            <FormGroup>
              <Label>Reject Reason</Label>
              <Controller
                control={control}
                name="content"
                rules={{ required: "Please add some content!" }}
                defaultValue={""}
                render={({ onChange, ref, value = "" }) => (
                  <div className="editor-height">
                    <ReactQuill
                      onChange={(value) => {
                        if (reactQuillReg.test(value)) {
                          onChange("");
                        } else {
                          onChange(value);
                        }
                      }}
                      value={value}
                      ref={ref}
                    />
                  </div>
                )}
                styles={customStyles}
              />
              <small className="text-danger pt-2">
                {errors?.content?.message}
              </small>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <button className="buttonPrimary" onClick={toggle} type="button">
              Close
            </button>
            <CustomButton
              buttonText="Create Note"
              buttonColor="primary"
              buttonType="submit"
              loading={loading}
              disabled={loading}
              className="big-width"
              buttonClick={handleSubmit(onRejectConfirm)}
            />
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};

export default RejectNotes;
