import AsyncSelect from 'react-select/async';
import { useContext, useEffect } from 'react';
import { AppContext } from '../context';
let time = 0;

const AsyncContactController = (props) => {
  const { theme } = useContext(AppContext);
 
  const promiseOptions = (value?: any): any =>
    new Promise((resolve) => {
      if (!value) {
        resolve(props?.contactData);
      } else {
        window.clearTimeout(time);
        time = window.setTimeout(() => {
          resolve(props?.contactData);
        }, 1000);
      }
    });

  const customStyles = {
    control: (styles, provided) => ({
      ...styles,
      width: `${props.width} !important`,
      background: theme === 'light' ? provided.background : '#282828',
      borderColor: theme === 'light' ? '#ced4da' : '#282828',
      color: theme === 'light' ? provided.background : '#d9d9d9',
      fontSize: 12,
      '&:hover': {
        borderColor: theme === 'light' ? '#ced4da' : '#282828',
        color: theme === 'light' ? provided.background : '#d9d9d9',
      },
    }),
    menu: (provided) => ({
      ...provided,
      background: theme === 'light' ? provided.background : '#282828',
      zIndex: props?.zIndex || 999999,
      color: theme === 'light' ? provided.background : '#d9d9d9',
    }),
    option: (provided) => ({
      ...provided,
      fontSize: 12,
      background: theme === 'light' ? provided.background : '#363636',
      '&:hover': {
        background: theme === 'light' ? provided.background : '#474444',
        color: theme === 'light' ? provided.background : '#d9d9d9',
      },
    }),
    container: (provided, state) => ({
      ...provided,
      fontSize: 12,
    }),
  };

  useEffect(() => {
    if (props?.contactData?.length) {
      promiseOptions();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.contactData]);

  return (
    <>
      {props?.contactData?.length && !props.loading && (
        <AsyncSelect
          // cacheOptions
          isDisabled={props?.isDisabled || false}
          value={props?.value?.value ? props?.value : null}
          isSearchable
          // isClearable
          ref={props.ref}
          styles={{ ...customStyles }}
          defaultOptions
          onChange={(selected: any) => {
            if (props.onSelected) {
              if (selected) {
                props.onSelected(selected);
              } else {
                props.onSelected({});
              }
            }
            if (selected) {
              // setSelectValue(selected);
              props.onChange(selected);
            } else {
              props.onChange('');
            }
          }}
          loadOptions={promiseOptions}
          placeholder={props?.placeholder}
        />
      )}
    </>
  );
};

export default AsyncContactController;
