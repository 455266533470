import { useLazyQuery, useMutation } from "@apollo/client";
import { useCallback, useContext, useEffect, useState } from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import { toast } from "react-toastify";
import TableComponent from "../../components/layout/TableComponent";
import { FETCH_LEGACY_AGREEMENTS } from "./gql";
import Pagination from "../../components/layout/Pagination";
import InputField from "../../components/layout/InputField";
import { AGREEMENT_HEADERS_COLUMN_SEARCH, COLUMN_INTERFACE_TYPES_FOR_AGREEMENTS, COMPANY_DETAILS_ROUTE, CONTACT_DETAIL_ROUTE, LEGACY_AGREEMENTS_ROUTE, PAGE_LIMIT, VIEW_AGREEMENT_ROUTE } from "../../utils/constant";
import { AppContext } from "../../context";
import {
  compactObject, downloadSignedUrlFile, formatDateUS,
} from "../../utils/commonFn";
import Loader from "../../components/layout/Loader";
import NoDataFound from "../../components/layout/NoDataFound";
import ConfirmPopUp from "../../components/ConfirmPopUp";
import { DELETE_AGREEMENT } from "./gql/index";

import VIEW_ICON from "../../assets/images/view-icon.svg";
import EDIT_ICON from "../../assets/images/edit-icon-menu.svg";
import DELETE_ICON from "../../assets/images/delete-icon-menu.svg";
import DOWNLOAD_FILE from "../../assets/images/download-icon.svg";
import AsyncCompanyDropdown from "../../components/AsyncCompanySelect";
import { getAgreementParams } from "../../utils/getNoteParams";
import { pushInHistory } from "../../utils/helper";
import { useForm } from "react-hook-form";
import { Button, Spinner } from "reactstrap";

// Download file button
const DownloadFileBtn = ({
  isAuthorizedPerson,
  documentUri,
  nickName,
}: {
  isAuthorizedPerson?: boolean;
  documentUri: string;
  nickName: string;
}): JSX.Element => {
  const [loading, setLoading] = useState(false);
  return (
    <Button
      disabled={!isAuthorizedPerson}
      onClick={() => {
        setLoading(true)
        downloadSignedUrlFile(documentUri, `${nickName}.pdf`, setLoading)
      }}
      className="bg-transparent border-0 p-0 mr-3"
    >
      {loading && <Spinner size="sm" style={{ color: "#eaa827" }} className="m-0" />}
      {!loading && <img className="m-0" src={DOWNLOAD_FILE} alt="download" />}
    </Button>
  );
};
let time = 0
const LegacyAgreements = ({ location, history }: RouteComponentProps) => {
  const fieldNames = [
    'companySearch',
    'contactSearch',
    'nickNameSearch',
    'typeSearch',
    'statusSearch',
    'accountManagerSearch',
  ];
  const defaultColumnFilters = {
    companySearch: '',
    contactSearch: '',
    nickNameSearch: '',
    typeSearch: '',
    statusSearch: '',
    accountManagerSearch: '',
    sorting: {
      columnName: '',
      columnValue: ''
    }
  };
  const page = new URLSearchParams(location.search).get("currentPage") || "1";
  const _currentPage: number = parseInt(page);
  const [currentPage, setCurrentPage] = useState(_currentPage);
  const [limit, setLimit] = useState(10);
  const [selectedContactId] = useState("");
  const { user, userRoles } = useContext(AppContext);
  const [actionConfirm, setAgreementDeleteConfirm] = useState(false);
  const [actionId, setDeleteActionId] = useState("");
  const [selectedCompanyId, setSelectedCompanyId] = useState('');
  const [selectedCompanyName, setSelectedCompanyName] = useState('');
  const [myCompaniesFlag, setMyCompaniesFlag] = useState<boolean>(false);
  const [columnFilters, setColumnFilters] =
    useState<COLUMN_INTERFACE_TYPES_FOR_AGREEMENTS>(defaultColumnFilters);
  const [errorMsg, setErrorMsg] = useState({});
  const { register, setValue, control } = useForm();
  const [isSetTimeOut, setIsSetTime] = useState<boolean>(true);

  const [fetchAllLegacyAgreements, { loading: agreementLoader, error, data }] =
    useLazyQuery(FETCH_LEGACY_AGREEMENTS);

  const setQueryParams = () => {
    let newColumn: COLUMN_INTERFACE_TYPES_FOR_AGREEMENTS = Object.assign(
      {},
      columnFilters
    );
    const { sorting, ...rest } = newColumn;
    const criteria = compactObject({
      currentPage,
      limit,
      selectedCompanyId,
      selectedCompanyName,
      myCompaniesFlag,
      ...rest,
      ...sorting
    });

    const urlQueryParams: any = [];
    if (Object.keys(criteria).length) {
      for (const key in criteria) {
        if (
          Object.prototype.hasOwnProperty.call(criteria, key) &&
          criteria[key]
        ) {
          urlQueryParams.push(`${key}=${criteria[key]}`);
        }
      }

      history.push(`${LEGACY_AGREEMENTS_ROUTE}?${urlQueryParams.join("&")}`);
    }
  };

  const [_deleteAction] = useMutation(DELETE_AGREEMENT);

  useEffect(() => {
    document.title = "Legacy Agreements - RealREPP";
    let params: any = getAgreementParams(location.search);

    if (params) {
      setMyCompaniesFlag(!!params?.myCompaniesFlag);
      setLimit(params?.limit ? parseInt(params?.limit) : 10);
      setSelectedCompanyId(params?.selectedCompanyId);
      setSelectedCompanyName(params?.selectedCompanyName);
      setCurrentPage(params?.currentPage ? parseInt(params?.currentPage) : 1);
    }
    if (Object.entries(params?.columnFilters)?.length) {
      if (Object.entries(params?.columnFilters)?.length === 1) {
        setColumnFilters((prevFilters) => ({
          ...prevFilters,
          [Object.keys(params?.columnFilters)[0]]: Object.values(
            params?.columnFilters
          )[0],
        }));
      } else {
        const updatedFilters = { ...columnFilters };
        Object.entries(params?.columnFilters).forEach(([key, value]) => {
          updatedFilters[key] = value;
        });
        setColumnFilters(updatedFilters);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const changeFiled = (fieldName, value) => {
    if (fieldName?.endsWith('Sort')) {
      const columnName = fieldName.replace('Sort', '');
      setIsSetTime(true)
      setColumnFilters((prevFilters) => {
        const { sorting, ...otherFilters } = prevFilters;
        return {
          ...otherFilters,
          sorting: {
            columnName: columnName,
            columnValue: value ? 'ASC' : 'DESC',
          },
        };
      });
    } else {
      setCurrentPage(1);
      setLimit(limit);
      setIsSetTime(false)
      setColumnFilters((prevFilters) => ({
        ...prevFilters,
        [fieldName]: value,
      }));
    }
  };

  const fetchAgreements = useCallback(async () => {
    if (isSetTimeOut) {
      setQueryParams();
      fetchAllLegacyAgreements({
        variables: {
          limit,
          page: currentPage,
          companyId: selectedCompanyId,
          companyContactId: selectedContactId,
          myCompanies: myCompaniesFlag,
          columnFilters: columnFilters

        },
      });
    } else {
      setQueryParams();
      setLimit(limit);
      window.clearTimeout(time);
      time = window.setTimeout(async () => {
        fetchAllLegacyAgreements({
          variables: {
            limit,
            page: currentPage,
            companyId: selectedCompanyId,
            companyContactId: selectedContactId,
            myCompanies: myCompaniesFlag,
            columnFilters: columnFilters

          },
        });
      }, 1000);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCompanyId, currentPage, limit, myCompaniesFlag, columnFilters]);


  useEffect(() => {
    fetchAgreements();
    // eslint-disable-next-line
  }, [fetchAgreements]);

  useEffect(() => {
    let params: any = new URLSearchParams(location.search);
    const companyId = params.get("selectedCompanyId");
    const companyName = params.get("selectedCompanyName");
    if (companyId) setSelectedCompanyId(companyId);
    if (companyName) setSelectedCompanyName(companyName);

    // eslint-disable-next-line
  }, [location.search]);

  const confirmAction = async () => {
    const response = await _deleteAction({
      variables: { agreementId: actionId },
    });
    if (response?.data) {
      toast.success("Successfully deleted!");
      setAgreementDeleteConfirm(false);
      fetchAgreements();
    } else {
      setAgreementDeleteConfirm(false);
    }
  };

  return (
    <div className="admin-tabs team-tabs">
      <div className="mobileResponsiveFlexAlign primaryHeaderSpacing">
        <h5 className="m-0">Legacy Agreements </h5>
      </div>

      <div className="search-filter primaryHeaderSpacing">
        <div className="async-dropdown">
          <AsyncCompanyDropdown
            placeholder="Filter By Company"
            value={{
              value: selectedCompanyId,
              label: selectedCompanyName,
            }}
            onChange={(selected) => {
              setQueryParams();
              setSelectedCompanyId(selected?.value);
              setSelectedCompanyName(selected?.label);
              setCurrentPage(1);
              setLimit(10);
            }}
          />
        </div>

        <div className="filter-select">
          <button
            style={{
              backgroundColor: `${myCompaniesFlag ? "#f4f3f3" : "white"}`,
              color: `${myCompaniesFlag ? "#eaa827" : "#495057"}`,
              border: `${"1px solid #ced4da"}`,
              marginRight: `${"1rem"}`,
            }}
            className="filter-job-order ml-3"
            onClick={() => {
              setMyCompaniesFlag(!myCompaniesFlag);
              setCurrentPage(1);
              setLimit(25);
            }}
            type="button"
          >
            My Companies
          </button>

          <button
            onClick={() => {
              history.push("/create-legacy-agreement");
            }}
            type="button"
            className="buttonGenericStyle filledButton mr-3"
          >
            Add New Legacy Agreement
          </button>

          <div className="filter-by">
            <button
              className="buttonGenericStyle filledButton ml-2"
              onClick={() => {
                setColumnFilters(defaultColumnFilters);
                fieldNames.forEach((fieldName) => {
                  setValue(fieldName, defaultColumnFilters[fieldName]);
                });
                if (columnFilters?.sorting.columnName) {
                  setValue(columnFilters?.sorting.columnName, false);
                }
                setErrorMsg({})
                setSelectedCompanyId("");
                setIsSetTime(true)
                setSelectedCompanyName("");
                setMyCompaniesFlag(false);
                fetchAllLegacyAgreements({
                  variables: {
                    limit,
                    page: currentPage,
                    searchText: "",
                    statusSearch: "",
                    companyId: "",
                    companyContactId: "",
                    myCompaniesFlag,
                    columnFilters: columnFilters

                  },
                });
              }}
            >
              Clear
            </button>
          </div>{" "}
        </div>
      </div>

      <div className="table-responsive">
        <TableComponent
          advanceSearching={true}
          tableHeaderAdvSeacrh={AGREEMENT_HEADERS_COLUMN_SEARCH.filter((header) => {
            return !(
              header.accountManagerSearch !== undefined ||
              header.statusSearch !== undefined
            );
          })}
          handleChange={(filedName, value) => changeFiled(filedName, value)}
          columnFilters={columnFilters}
          register={register}
          setValue={setValue}
          control={control}
          tableName="AllAgreements"
          setErrorMsg={setErrorMsg}
          errorMsg={errorMsg}
        >

          {agreementLoader && (
            <tbody>
              <tr>
                <td colSpan={11}>
                  <Loader loaderClass="sm-height " />
                </td>
              </tr>
            </tbody>
          )}

          {!agreementLoader && !data?.allLegacyAgreements?.agreements?.length && (
            <tbody>
              <tr>
                <td colSpan={9} className="empty-table-td">
                  <NoDataFound text="No Legal Agreements Found!" />
                </td>
              </tr>
            </tbody>
          )}

          <tbody className='placement-table'>
            {data &&
              data?.allLegacyAgreements &&
              data?.allLegacyAgreements?.agreements?.map(
                (item: any, index: number) => (
                  <tr key={index}>
                    <td
                      className="route-link"
                      onClick={() => {
                        history.push({
                          pathname: `${COMPANY_DETAILS_ROUTE}/${item?.Company?.id}`,
                          state: {
                            backUrl: LEGACY_AGREEMENTS_ROUTE,
                            searchParameters: location.search
                          },
                        });

                        pushInHistory(LEGACY_AGREEMENTS_ROUTE, location.search)
                      }}
                    >
                      {item?.Company?.name}

                      <div className="subtitleText">
                        {item?.updatedAt ? formatDateUS(item?.updatedAt) : "--"}
                      </div>
                    </td>

                    {!item?.CompanyContact?.firstName && !item?.CompanyContact?.lastName ?
                      <td>--</td> :
                      <td
                        className="route-link"
                        onClick={() => {
                          history.push({
                            pathname: `${CONTACT_DETAIL_ROUTE}/${item?.CompanyContact?.id}`,
                            state: {
                              backUrl: LEGACY_AGREEMENTS_ROUTE,
                              searchParameters: location.search
                            },
                          });

                          pushInHistory(LEGACY_AGREEMENTS_ROUTE, location.search)
                        }}>
                        {item?.CompanyContact?.firstName}{" "}
                        {item?.CompanyContact?.lastName}
                      </td>}

                    <td>{item?.nickName}</td>

                    <td>
                      {item?.agreementType === "LEGAL_DIRECT_HIRE"
                        ? "Legal Direct Hire"
                        : "Legal Contract Hire"}
                    </td>

                    {item?.commissionPercentage ? (
                      <td>{item?.commissionPercentage}%</td>
                    ) : (
                      <td>{"--"}</td>
                    )}
                    <td>
                      {item?.signedAt
                        ? formatDateUS(item?.signedAt)
                        : "--"}
                    </td>
                    <td>
                      <div
                        className="d-flex align-items-center justify-content-start"
                        style={{ width: 150 }}
                      >
                        <DownloadFileBtn
                          isAuthorizedPerson={
                            item?.Reviewer?.id === user?.id ||
                            userRoles?.includes("ADMIN") ||
                            userRoles?.includes("EXECUTIVE")
                          }
                          documentUri={item.documentUri}
                          nickName={item.nickName}
                        />

                        <div className="iconButtonAction mx-2">
                          <Link
                            to={`${VIEW_AGREEMENT_ROUTE}/${item?.id}`}
                          >
                            <img src={VIEW_ICON} alt="view" width="21px" />
                          </Link>
                        </div>
                        <div className="iconButtonAction">
                          <button
                            onClick={() => {
                              setAgreementDeleteConfirm(true);
                              setDeleteActionId(item?.id);
                            }}
                            disabled={true}
                            className="bg-transparent border-0 p-0"
                          >
                            <img src={EDIT_ICON} alt="delete" width="16px" />
                          </button>
                        </div>


                        <div className="iconButtonAction">
                          <div className="d-flex align-items-center">
                            <button
                              onClick={() => {
                                setAgreementDeleteConfirm(true);
                                setDeleteActionId(item?.id);
                              }}
                              disabled={true}
                              className="bg-transparent border-0 p-0"
                            >
                              <img
                                src={DELETE_ICON}
                                alt="delete"
                                width="16px"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                )
              )}
          </tbody>
        </TableComponent>
      </div>

      {!!data?.allLegacyAgreements?.agreements?.length && (
        <div className="users-pagination">
          <Pagination
            key={data?.allLegacyAgreements?.agreements.id}
            onPageChange={(pageClicked: number) => {
              setIsSetTime(true)
              setCurrentPage(pageClicked);
            }}
            pageCount={Math.ceil(data?.allLegacyAgreements?.count / limit)}
            currentPage={currentPage}
          />

          <div className="d-flex align-items-center">
            <div className="pagination-display paginationItemsSelect">
              <p className="m-0">
                {(currentPage - 1) * limit + 1} to{" "}
                {Math.min(
                  currentPage * limit,
                  data?.allLegacyAgreements?.count
                )}{" "}
                of {data?.allLegacyAgreements?.count}
              </p>

              <div className="divider"></div>

              <label>Display</label>

              <InputField
                label=""
                inputtype="select"
                selectItems={PAGE_LIMIT.filter((item) => item.value !== limit)}
                inputid="limit"
                placeholder={limit.toString()}
                inputMethod={(value) => {
                  setIsSetTime(true)
                  setCurrentPage(1);
                  setLimit(parseInt(value));
                }}
              />
            </div>
          </div>
        </div>
      )}

      {error?.graphQLErrors[0]?.message}

      <ConfirmPopUp
        confirmText="Are you sure you want to delete this agreement from agreements list?"
        isOpen={actionConfirm}
        toggle={setAgreementDeleteConfirm}
        confirmAction={confirmAction}
        modalHeading="Delete Action"
        btnText="Delete"
        btnColor="primary"
        className="revampDialog revampDialogWidth"
      />
    </div>
  );
};

export default LegacyAgreements;
