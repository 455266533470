import { useMutation } from '@apollo/client';
import { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Badge, Col, Form, Input, InputGroup, Row } from 'reactstrap';
import InputField from '../../components/layout/InputField';
import { DrawerHeader, Sidedrawer } from '../../components/layout/Sidedrawer';
import { ADD_SYNONYMS, DEPLOY, EDIT_SYNONYMS, SYNONYMS } from '../../constants';
import { DrawerComponentProps, FormValuesSynonym } from '../../interfaces';
import { JOB_TITLE_SYNONYMS, SKILL_SYNONYMS } from '../../utils/constant';
import { ADD_JOB_TITLE_SYNONYMS, UPDATE_JOB_TITLE_SYNONYMS, UPDATE_SKILL_SYNONYMS } from './gql';

const DrawerComponent: FC<DrawerComponentProps> = ({ selectedCategory, toggle, isOpen, synonymData, setSynonymData, isEditButton, refetchSkillData, refetchJobTitleData }) => {
  const { register, handleSubmit, formState: { errors }, reset, setValue } = useForm<FormValuesSynonym>();
  const [badges, setBadges] = useState(synonymData?.value || []);
  const [newBadge, setNewBadge] = useState('');
  const [badgeListEmptyValidation, setBadgeListEmptyValidation] = useState<boolean>(false);
  const [badgeSpecialCharValidation, setBadgeSpecialCharValidation] = useState<boolean>(false);
  const [addJobTitleSynonyms] = useMutation(ADD_JOB_TITLE_SYNONYMS);
  const [updateJobTitleSynonyms] = useMutation(UPDATE_JOB_TITLE_SYNONYMS);
  const [addUpdateSkillSynonyms] = useMutation(UPDATE_SKILL_SYNONYMS);

  // badge state handlers
  const handleAddBadge = () => {
    if (newBadge.trim() !== '') {
      // Check if the new badge input contains only letters, numbers, and spaces
      if (/^[a-zA-Z0-9\s]+$/.test(newBadge)) {
        const updatedBadges = [...badges, newBadge];
        setBadges(updatedBadges);
        setNewBadge('');
        setBadgeSpecialCharValidation(false);
        setBadgeListEmptyValidation(false);
      } else {
        setBadgeSpecialCharValidation(true);
      }
    }
  };

  const handleRemoveBadge = (index) => {
    const updatedBadges = [...badges];
    updatedBadges.splice(index, 1);
    setBadges(updatedBadges);
  };

  useEffect(() => {
    setBadges(synonymData?.value || []);
  }, [synonymData?.value]);

  // useEffect for clearing synonym input fields on drawer close and resetting on drawer open
  useEffect(() => {
    if (!isOpen) {
      setNewBadge('');
      setValue('jobTitle', '');
    } else {
      setValue('jobTitle', synonymData?.name);
    }
  }, [isOpen, setValue, synonymData?.name])

  // useEffect for clearing synonym input field on change of selected category
  useEffect(() => {
    reset();
    setBadges([]);
  }, [reset, selectedCategory.label, setSynonymData])

  // form handler
  const onSubmit = async (data: FormValuesSynonym) => {
    const { jobTitle } = data;
    const synonyms = badges;

    if (selectedCategory?.value === JOB_TITLE_SYNONYMS) {
      if (synonyms?.length === 0) {
        setBadgeListEmptyValidation(true);
        return;
      }

      let res;
      if (!isEditButton) {
        res = await addJobTitleSynonyms({
          variables: {
            updateSynonymsInput: {
              id: synonymData?.id,
              key: jobTitle,
              synonyms: synonyms,
            }
          }
        });

        if (res?.data?.addJobTitleSynonyms) {
          toast.success('Synonyms deployed successfully.');
          refetchJobTitleData();
          toggle();
          reset();
          setBadges([]);
        } else {
          toast.error('Unable to deploy synonyms.');
        }
      } else {
        res = await updateJobTitleSynonyms({
          variables: {
            updateSynonymsInput: {
              id: synonymData?.id,
              key: jobTitle,
              synonyms: synonyms,
            }
          }
        });

        if (res?.data?.updateJobTitleSynonyms) {
          toast.success('Synonyms updated successfully.');
          refetchJobTitleData();
          toggle();
          reset();
          setBadges([]);
        } else {
          toast.error('Unable to update synonyms.');
        }
      }
    } else if (selectedCategory?.value === SKILL_SYNONYMS) {
      if (synonyms?.length === 0) {
        setBadgeListEmptyValidation(true);
        return;
      }
      const res = await addUpdateSkillSynonyms({
        variables: {
          updateSynonymsInput: {
            id: synonymData?.id,
            key: jobTitle,
            synonyms: synonyms,
          }
        }
      });

      if (res?.data) {
        toast.success('Synonyms deployed successfully.');
        refetchSkillData();
        toggle();
        reset();
        setBadges([]);
      } else {
        toast.error('Unable to deploy synonyms.');
      }
    }
  };

  return (
    <Sidedrawer toggle={toggle} isOpen={isOpen}>
      <div className="mb-4">
        <DrawerHeader prevClick={() => toggle()} buttonText="Edit"
          heading={!isEditButton ? ADD_SYNONYMS : EDIT_SYNONYMS}
          nextButtonView={false}
        >
          <button
            className="buttonGenericStyle filledButton ml-3"
            onClick={handleSubmit(onSubmit)}
          >
            {DEPLOY}
          </button>
        </DrawerHeader>
      </div>

      <div className="pb-4">
        <Form>
          <Row>
            <Col md="12">
              <InputField
                inputtype="text"
                label={selectedCategory?.value === SKILL_SYNONYMS ? 'Skill Term' : 'Job Title'}
                inputid="jobTitle"
                placeholder={selectedCategory?.value === SKILL_SYNONYMS ? 'Skill Term' : 'Job Title'}
                defaultValue={synonymData?.name || ""}
                inputRef={register({ required: true })}
                errMsg={errors?.jobTitle && "Required field!"}
              />
            </Col>

            <Col md="12">
              <label>{SYNONYMS}</label>
              <InputGroup className='custom-input-field'>
                <Input value={newBadge} onChange={(e) => setNewBadge(e.target.value)} />
                <button type='button' className="plus-icon" onClick={() => {
                  handleAddBadge();
                }}>
                  +
                </button>
              </InputGroup>
              {badgeListEmptyValidation && (<div className="error">At least one synonym is required!</div>)}
              {badgeSpecialCharValidation && (<div className="error">Special characters are not allowed!</div>)}
            </Col>

            <Col md="12">
              <div className="mt-4 d-inline-flex align-items-center flex-wrap gap-10">
                {badges?.map((synonym: string, index: number) => (
                  <Badge key={index} className="synonym-badge text-left request-badge d-flex align-items-center">
                    <span className="text-break text-wrap">{synonym}</span>
                    <button type="button" className='cross-btn' onClick={() => handleRemoveBadge(index)}>
                      x
                    </button>
                  </Badge>
                ))}
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </Sidedrawer>
  )
}

export default DrawerComponent;
