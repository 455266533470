import { useLazyQuery } from '@apollo/client';
import { useContext, useEffect, useState } from 'react';
import { Badge, Button, Card, CardBody } from 'reactstrap';
import ContentViewSidebar from '../../../components/ContentViewSidebar';
import ReplyButton from '../../../components/ReplyButton';
import EmailHeader from '../../../components/layout/EmailHeader';
import InputField from '../../../components/layout/InputField';
import NoDataFound from '../../../components/layout/NoDataFound';
import Pagination from '../../../components/layout/Pagination';
import { AppContext } from '../../../context';
import { formatDateUS } from '../../../utils/commonFn';
import { PAGE_LIMIT } from '../../../utils/constant';
import { userReplyAccess } from '../../../utils/helper';
import { GET_EMAIL_NOTES } from '../../company/gql';

const CandidateEmailNote = ({ recordId, type }) => {
  const page = parseInt('1');
  const [currentPage, setCurrentPage] = useState(page);
  const [limit, setLimit] = useState(3);
  const [writeUpViewSidebar, setWriteUpViewSidebar] = useState(false);
  const [emailNote, setEmailNote] = useState(null);
  const [writeUpContent, setWriteUpContent] = useState('');
  const [emailHeader, setEmailHeader] = useState<{ bccEmail: string, ccEmail: string, fromEmail: string, subjectEmail: string, toEmail: string }>();
  const [emailAttachments, setEmailAttachments] = useState<{ fileName: string, url: string }[]>();
  const [hasAttachment, setHasAttachment] = useState<boolean>(false);

  const [getEmailNotes, { data }] = useLazyQuery(GET_EMAIL_NOTES);
  const { user } = useContext(AppContext);

  const _getEmailNote = async () => {
    let newType = `${type?.toLowerCase()}Id`;
    await getEmailNotes({
      variables: {
        emailNotesPayload: { [newType]: recordId },
        limit,
        page: currentPage,
      },
    });
  };

  const truncateWords = (string) => {
    let sliced = string.replace(/\s\s+/g, ' ');
    sliced = sliced.split(' ');
    if (sliced.length >= 25) {
      return sliced.slice(0, 25).join(' ') + '...';
    }
    return string;
  };

  useEffect(() => {
    if (recordId) {
      _getEmailNote();
    }
    // eslint-disable-next-line
  }, [recordId, currentPage, limit]);

  return (
    <>
      <Card className="border-0">
        <CardBody className="py-2 mt-2">
          {data?.getEmailNotes?.emailNotes?.map((item) => (
            <div className="pb-3">
              <div>
                <h5 className="d-flex">
                  <Badge color="success" className="badge-secondary mr-2">
                    Category: {item?.categories || '--'}
                  </Badge>
                  <Badge color="success" className="badge-secondary mr-2">
                    Recruiter: {item?.taggedUser?.fullName || '--'}
                  </Badge>
                  <Badge color="success" className="badge-secondary mr-2">
                    Candidate: {item?.taggedCandidate?.fullName || '--'}
                  </Badge>
                </h5>
              </div>

              <h6 className="mt-2">
                <span>
                  {item?.sentDateTime ? formatDateUS(item?.sentDateTime) : '--'}
                  {'  '}
                  {item?.sentDateTime &&
                    new Date(item?.sentDateTime).toLocaleTimeString()}{' '}
                </span>
              </h6>

              <EmailHeader
                toEmail={item?.toAddress?.length ? item?.toAddress?.join(',') : '--'}
                ccEmail={item?.ccAddress?.length ? item?.ccAddress?.join(',') : '--'}
                bccEmail={item?.bccAddress?.length ? item?.bccAddress?.join(',') : '--'}
                fromEmail={item?.fromAddress}
                subjectEmail={item?.subject}
              />

              <small className="text-muted">
                <strong>Body:</strong>
                <span className="ml-2">{truncateWords(item?.bodyPreview)}</span>
                <Button
                  className="bg-transparent text-info border-0 p-0 ml-2"
                  onClick={() => {
                    setWriteUpContent(item?.bodyContent);
                    setEmailAttachments(item?.attachments)
                    setHasAttachment(item?.hasAttachment)
                    setWriteUpViewSidebar(!writeUpViewSidebar);
                    setEmailNote({ ...item });
                    setEmailHeader({
                      toEmail: item?.toAddress?.length ? item?.toAddress?.join(',') : '--',
                      bccEmail: item?.bccAddress?.length ? item?.bccAddress?.join(',') : '--',
                      ccEmail: item?.ccAddress?.length ? item?.ccAddress?.join(',') : '--',
                      fromEmail: item?.fromAddress,
                      subjectEmail: item?.subject ? item?.subject : '--'
                    })
                  }}
                >
                  <small className="for-link-theme-color">View More</small>
                </Button>
              </small>
              <div className="pt-2">
                {userReplyAccess(item, user) && item?.emailWebLink && (
                  <ReplyButton text={true} link={item?.emailWebLink} />
                )}
              </div>
            </div>
          ))}
          {!data?.getEmailNotes?.emailNotes?.length && (
            <div className="empty-table-td">
              <NoDataFound text="No Email Notes Found!" />
            </div>
          )}

          {data?.getEmailNotes?.emailNotes?.length ? (
            <div className="users-pagination">
              <Pagination
                onPageChange={(pageClicked: number) => {
                  setCurrentPage(pageClicked);
                }}
                pageCount={Math.ceil(data?.getEmailNotes?.count / limit)}
                currentPage={currentPage}
              />

              <div className="d-flex align-items-center">
                <div className="pagination-display paginationItemsSelect">
                  <p className="m-0">
                    {(currentPage - 1) * limit + 1} to{' '}
                    {Math.min(currentPage * limit, data?.getEmailNotes?.count)}{' '}
                    of {data?.getEmailNotes?.count}
                  </p>

                  <div className="divider"></div>

                  <label>Display</label>

                  <InputField
                    label=""
                    inputtype="select"
                    selectItems={PAGE_LIMIT.filter(
                      (item) => item.value !== limit
                    )}
                    inputid="limit"
                    placeholder={limit.toString()}
                    inputMethod={(value: string) => {
                      setCurrentPage(1);
                      setLimit(parseInt(value));
                    }}
                  />
                </div>
              </div>
            </div>
          ) : null}
        </CardBody>
      </Card>

      <ContentViewSidebar
        toggle={() => setWriteUpViewSidebar(!writeUpViewSidebar)}
        content={writeUpContent}
        hasAttachment={hasAttachment}
        emailNotes={emailNote}
        emailAttachments={emailAttachments}
        isOpen={writeUpViewSidebar}
        heading={'View Candidate Email Content'}
        emailHeader={emailHeader}
      />
    </>
  );
};

export default CandidateEmailNote;
