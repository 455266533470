import { useContext, useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { FormGroup, Input, Label, Spinner } from 'reactstrap';
import emptyPreview from '../../../../assets/images/step-preview.svg';
import InputField from '../../../../components/layout/InputField';
import { emailTemplateTagsArray } from '../../../../utils/constant';
import { AppContext } from '../../../../context';
import ReactQuill from 'react-quill';
import { createMarkup } from '../../../../utils/commonFn';

// window.location.reload();
export default function PreviewBody({
  testEmail,
  firstStepTestEmail,
  lengthOfSequencePreviewSteps,
  register,
  forTestEmail,
  errors,
  control,
  reset,
  setValue,
  tabId,
  loadingPreviewSteps,
  lengthOfSequenceSteps,
  stepLoading,
  getSignatureData
}: any) {
  const { sequencePreview } = useContext(AppContext);
  const [templateTags, setTemplateTags] = useState<boolean>(false);
  const [copied, setCopy] = useState<boolean>(false);
  const [replying, setReplying] = useState<boolean>(false);
  const { latestSubject } = sequencePreview || {};
  const onCopyText = (item: string, index: number) => {
    navigator.clipboard.writeText(item);
    setCopy(true);

    setTimeout(() => {
      setCopy(false);
    }, 1500);
  };

  useEffect(() => {
    if (sequencePreview) {
      setTemplateTags(false);
      reset({ ...sequencePreview });
      if (lengthOfSequenceSteps > 0) {
        setReplying(!!!sequencePreview?.subject);
      }
      if (sequencePreview?.replying) {
        setReplying(true);
      }
    }
    
    if (!sequencePreview?.fromPreview) {
      if (
        firstStepTestEmail &&
        (!sequencePreview?.preview || !sequencePreview?.fromABPreview) &&
        testEmail &&
        forTestEmail &&
        tabId === '3'
        ) {
          firstStepTestEmail.latestSubject = firstStepTestEmail?.subject;
          reset({ ...firstStepTestEmail });
      }
    }
    // eslint-disable-next-line
  }, [sequencePreview, firstStepTestEmail]);

  useEffect(() => {
    if (replying) {
      setValue('latestSubject', latestSubject);
    }
  }, [latestSubject, replying, setValue, sequencePreview]);

  const customStyles = {
    control: (styles, provided) => ({
      ...styles,

      fontSize: 12,
    }),

    option: (provided, state) => ({
      ...provided,
      fontSize: 12,
    }),

    container: (provided, state) => ({
      ...provided,
      fontSize: 12,
    }),
  };

  return (
    <>
      {(loadingPreviewSteps && !lengthOfSequencePreviewSteps) || stepLoading ? (
        <div className="text-center mt-2 mb-2">
          <Spinner size="large" style={{ color: '#eaa827' }} />
        </div>
      ) : (
        <div className="step-preview-body step-mybody-preview">
          {sequencePreview?.preview ||
          sequencePreview?.fromABPreview ||
          (testEmail &&
            tabId === '3' &&
            (lengthOfSequencePreviewSteps
              ? lengthOfSequencePreviewSteps
              : sequencePreview?.fromPreview)) ? (
            <>
              <div className="d-flex w-100">
                {!sequencePreview?.fromPreview && testEmail && tabId === '2' && (
                  <>
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="radio"
                          name="type"
                          value={'New Thread'}
                          defaultChecked={!replying}
                          innerRef={register()}
                          onClick={() => {
                            setReplying(false);
                            setValue('latestSubject', null);
                          }}
                        />
                        New Thread
                      </Label>
                    </FormGroup>

                    <FormGroup check className="ml-3">
                      <Label check>
                        <Input
                          defaultChecked={replying}
                          type="radio"
                          name="type"
                          value={'Reply'}
                          innerRef={register()}
                          onClick={() => setReplying(true)}
                          disabled={sequencePreview?.stepIndex === 1 ? true: sequencePreview?.sequenceStepsLengthCheck===false?true:false}
                        />
                        Reply
                      </Label>
                    </FormGroup>
                  </>
                )}
              </div>

              <div className="w-100 subject-input">
                <div className="filter-status">
                  <div className="d-flex align-items-center">
                    <span className="mr-2">Show Tags</span>
                    <label className="switch mt-2">
                      <Input
                        type="checkbox"
                        name="tags"
                        innerRef={register()}
                        defaultChecked={false}
                        onClick={() => setTemplateTags(!templateTags)}
                      />
                      <span className="slider" />
                    </label>
                  </div>
                </div>
              </div>

              <div className="w-100 d-flex flex-wrap mt-2 position-relative">
                {copied && (
                  <span className="badge badge-main badge-warning">Copied</span>
                )}
                {templateTags &&
                  emailTemplateTagsArray?.map((item: string, index) => {
                    return (
                      <span
                        className="steps-tags-template"
                        onClick={() => onCopyText(item, index)}
                        id={`items-${index}`}
                        key={index}
                      >
                        {item.split(', ')}
                      </span>
                    );
                  })}
              </div>
              <div className="w-100 subject-input">
                <Label>Subject</Label>
                <InputField
                  disabled={
                    (!sequencePreview?.preview &&
                      testEmail &&
                      forTestEmail &&
                      tabId === '3') ||
                    replying
                  }
                  inputtype="text"
                  inputid="latestSubject"
                  placeholder="Subject"
                  inputRef={register({
                    required: {
                      value: !sequencePreview?.sequenceStepsLengthCheck,
                      message: 'Subject is required for New Thread',
                    },
                  })}
                  errMsg={errors?.latestSubject?.message}
                />
              </div>

              <div className="preview-body-quil lookup-quill-wrap">
                <FormGroup>
                  <Label>Body</Label>
                  <Controller
                    disabled={testEmail}
                    control={control}
                    placeholder="Body"
                    name="body"
                    rules={{
                      required: {
                        value: true,
                        message: 'Need some content in the body!',
                      },
                    }}
                    defaultValue={null}
                    render={({ onChange, ref, value = '' }) => (
                      <div
                        className="editor-height-for-body"
                        id="stepBodyQuill"
                      >
                        <ReactQuill
                          readOnly={
                            !sequencePreview?.preview &&
                            testEmail &&
                            forTestEmail &&
                            tabId === '3'
                          }
                          onChange={(value) => {
                            onChange(value);
                          }}
                          value={value}
                          ref={ref}
                          style={{ color: 'black' }}
                          className="text-dark"
                        />
                      </div>
                    )}
                    styles={customStyles}
                  />
                  <div className='lookup-signature-card' dangerouslySetInnerHTML={createMarkup(getSignatureData?.getSignature?.signature)}/>
                </FormGroup>
              </div>
            </>
          ) : (
            <>
              <img src={emptyPreview} alt="emptyPreview" />
              <h5>Select a step from left to see email preview</h5>
            </>
          )}
        </div>
      )}
    </>
  );
}
