import { gql } from "@apollo/client";

export const GET_ALL_REQUESTS = gql`
  query GetAllRequests($payload: RequestsQueryInput!) {
    getAllRequests(payload: $payload) {
      requests {
        id
        requestType
        note
        link
        status
        requestApprovedBy {
          id
          fullName
        }
        requestCreatedBy {
          id
          fullName
        }
        actionableId
        actionableType
        updatedAt
      }
      count
    }
  }
`;

export const APPROVE_REQUEST = gql`
  mutation ApproveRequest($approveRequestInput: ApproveRequestInput!) {
    approveRequest(approveRequestInput: $approveRequestInput) {
      status
      message
    }
  }
`;

export const REJECT_REQUEST = gql`
  mutation RejectRequest($rejectRequestInput: RejectRequestInput!) {
    rejectRequest(rejectRequestInput: $rejectRequestInput) {
      status
      message
    }
  }
`;