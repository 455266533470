import { useLazyQuery, useMutation } from "@apollo/client";
import { useContext } from "react";
import { Input } from "reactstrap";
import { AppContext } from "../../../context";
import { ENABLE_DISABLE_CONTACT, FETCH_CONTACTS_DETAIL } from "../gql";

interface IIsPrivateContact {
  companyId: string;
  contactId: string;
  isPrivate?: boolean;
}
const IsPrivateContact = ({
  companyId,
  contactId,
  isPrivate,
}: IIsPrivateContact): JSX.Element => {
  const { userRoles } = useContext(AppContext);

  const [_enableDisableContact] = useMutation(ENABLE_DISABLE_CONTACT);

  const [getCompanyContact] = useLazyQuery(FETCH_CONTACTS_DETAIL);

  const enableDisableContact = async (contactId: string) => {
    const response = await _enableDisableContact({
      variables: { contactId },
    });
    if (response.data) {
      getCompanyContact({ variables: { id: companyId } });
    }
  };

  return (
    <div className="d-flex align-items-center make-private">
      {userRoles?.includes("EXECUTIVE") ? (
        <>
          <p className="mr-3 mb-0 switchLabel">Make it private?</p>
          <label className="switch-contact">
            <Input
              type="checkbox"
              name="isFeatured"
              checked={isPrivate}
              onChange={() => enableDisableContact(contactId)}
            />
            <span className="slider"></span>
          </label>
        </>
      ) : null}
    </div>
  );
};

export default IsPrivateContact;
