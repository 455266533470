// packages block
import { FC } from 'react'
// interfaces/types block
import { SkeletonInterface } from '../../../interfaces'
//css block
import './style.scss'
const Skeleton: FC<SkeletonInterface> = ({ width, height }) => {
    const SkeletonStyle = {
        width: `${width}%`,
        height: height
    }
    return (
        <div>
            <span className="skeleton-box" style={{ ...SkeletonStyle }}></span>
        </div>
    )
}
export default Skeleton;