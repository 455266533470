import { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { withRouter } from "react-router";
import { useHistory } from "react-router-dom";
import {
  Button,
  Col,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from "reactstrap";
import Pagination from "../../components/layout/Pagination";
import InputField from "../../components/layout/InputField";
import {
  allNoteCategory,
  categoryEmailNotes,
  // notesCategories,
  PAGE_LIMIT,
  sortingAlphabetical,
} from "../../utils/constant";
import SelectOptionIndex from "./notes-search-components/SelectOptionIndex";
import SEARCH_IMG from "../../assets/images/search.svg";
import "./notes-search.scss";
import SingleNote from "./notes-search-components/SingleNote";
import {
  GET_ALL_CANDIDATES,
  GET_ALL_COMPANIES,
  GET_ALL_CONTACTS,
  GET_ALL_JOBS,
  GET_ALL_USERS,
  GET_ES_NOTES,
} from "./gql";
import { useLazyQuery } from "@apollo/client";
import NoDataFound from "../../components/layout/NoDataFound";
import Loader from "../../components/layout/Loader";
import NoteSearchModal from "./NoteSearchModal";
import { GET_EMAIL_NOTES } from "../company/gql";
import EmailNoteCard from "../../components/EmailNoteCard";
import { getNoteParams } from "../../utils/getNoteParams";
import {
  compactObject,
  noteSearchParams,
  noteSearchParamsDTO,
} from "../../utils/commonFn";
import AsyncJobSelect from "./search-components/AsyncJobSelectNotes";
import AsyncCompanySelect from "./search-components/AsyncCompanySelectNotes";
import AsyncContactSelect from "./search-components/AsyncContactSelectNotes";
import AsyncCandidateSelect from "./search-components/AsyncCandidateSelectNotes";
import AsyncRecruiterSelect from "./search-components/AsyncRecruiterSelectNotes";

let time = 0;

export interface SelectLabelValue {
  label: string;
  value: string;
}

const NotesSearchIndex = ({ location }: RouteComponentProps) => {
  const page = new URLSearchParams(location.search).get("page") || "1";
  const history = useHistory();
  const _currentPage: number = parseInt(page);
  const [currentUnderSelect, setCurrentUnderSelect] = useState<any>();
  const [modal, setModal] = useState<boolean>();
  const [currentPage, setCurrentPage] = useState(_currentPage);
  const [limit, setLimit] = useState(10);
  const [searchParams, setSearchParams] = useState<string>("");
  const [notesCategory, setNotesCategory] = useState<SelectLabelValue>();
  const [noteSortBy, setNoteSortBy] = useState<boolean>(false);

  const [candidateValue, setCandidateValue] = useState<SelectLabelValue>();
  const [contactValue, setContactValue] = useState<SelectLabelValue>();
  const [recruiterValue, setRecruiterValue] = useState<SelectLabelValue>();
  const [compnayValue, setCompnayValue] = useState<SelectLabelValue>();
  const [jobValue, setJobValue] = useState<SelectLabelValue>();

  const [getESNotes, { data: notes, loading }] = useLazyQuery(GET_ES_NOTES);
  const [getCompanies, { data: companies, loading: companyLoading }] = useLazyQuery(GET_ALL_COMPANIES);
  const [getJobOrders] = useLazyQuery(GET_ALL_JOBS);
  const [getCandidates] = useLazyQuery(GET_ALL_CANDIDATES);
  const [getContacts] = useLazyQuery(GET_ALL_CONTACTS);
  const [getUsers] = useLazyQuery(GET_ALL_USERS);

  useEffect(() => {
    getCompanies({ variables: { id: "", limit, page: currentPage } });
    getContacts({ variables: { id: "", limit, page: currentPage } });
    getJobOrders({ variables: { id: "", limit, page: currentPage } });
    getUsers({ variables: { id: "", limit, page: currentPage } });
    getCandidates({ variables: { id: "", limit, page: currentPage } });

    // eslint-disable-next-line
  }, []);

  const setQueryParams = (value, type) => {
    let obj = {
      job: type === "job" ? value : jobValue,
      company: type === "company" ? value : compnayValue,
      contact: type === "contact" ? value : contactValue,
      candidate: type === "candidate" ? value : candidateValue,
      recruiter: type === "recruiters" ? value : recruiterValue,
    };
    let paramsReturn: noteSearchParamsDTO = noteSearchParams(obj);
    let companyName = paramsReturn?.companyName;
    let companyId = paramsReturn?.companyId;
    let jobTitle = type !== "company" ? paramsReturn?.jobTitle : null;
    let jobOrderId = type !== "company" ? paramsReturn?.jobOrderId : null;
    let candidateId = paramsReturn?.candidateId;
    let candidateName = paramsReturn?.candidateName;
    let contactId = type !== "company" ? paramsReturn?.contactId : null;
    let contactName = type !== "company" ? paramsReturn?.contactName : null;
    let recruiterId = paramsReturn?.recruiterId;
    let recruiterName = paramsReturn?.recruiterName;

    const criteria = compactObject({
      currentPage,
      jobTitle,
      jobOrderId,
      companyName,
      companyId,
      candidateId,
      candidateName,
      contactId,
      contactName,
      recruiterId,
      recruiterName,
    });
    history.push(`/notes-es?${new URLSearchParams(criteria).toString()}`);
  };

  const [getEmailNotes, { data: _emailNotes, loading: _emailNotesLoading }] =
    useLazyQuery(GET_EMAIL_NOTES);

  useEffect(() => {
    if (jobValue || candidateValue || contactValue || compnayValue) {
      if (!noteSortBy) {
        getESNotes({
          variables: {
            text: searchParams,
            candidateNotes: candidateValue?.value,
            companyNotes: compnayValue?.value,
            jobNotes: jobValue?.value,
            recruiterNotes: recruiterValue?.value,
            contactNotes: contactValue?.value,
            notesCategory: notesCategory?.label,
            limit,
            page: currentPage,
          },
        });
      }
    }

    // eslint-disable-next-line
  }, [
    limit,
    currentPage,
    jobValue,
    candidateValue,
    contactValue,
    compnayValue,
    recruiterValue,
  ]);

  const searchControl = (value: any) => {
    setSearchParams(value);
    setCurrentPage(1);
    setLimit(25);
    if (!value || value?.length > 2) {
      window.clearTimeout(time);
      time = window.setTimeout(() => {
        if (noteSortBy) {
          getEmailNotes({
            variables: {
              emailNotesPayload: {
                text: searchParams,
                category: notesCategory?.value,
                candidateId: candidateValue?.value,
                contactId: contactValue?.value,
                recruiterId: recruiterValue?.value,
                companyId: compnayValue?.value,
              },
              limit,
              page: currentPage,
            },
          });
        } else {
          getESNotes({
            variables: {
              text: value,

              candidateNotes:
                candidateValue && candidateValue?.value
                  ? candidateValue?.value
                  : "",

              companyNotes:
                compnayValue && compnayValue?.value ? compnayValue?.value : "",

              jobNotes: jobValue && jobValue?.value ? jobValue?.value : "",

              recruiterNotes:
                recruiterValue && recruiterValue?.value
                  ? recruiterValue?.value
                  : "",

              contactNotes:
                contactValue && contactValue?.value ? contactValue?.value : "",

              notesCategory:
                notesCategory && notesCategory?.label
                  ? notesCategory?.label
                  : null,

              limit,
              page: currentPage,
            },
          });
        }
      }, 1000);
    }
  };

  const searchHandler = () => {
    if (noteSortBy) {
      getEmailNotes({
        variables: {
          emailNotesPayload: {
            text: searchParams,
            category: notesCategory?.value,
            candidateId: candidateValue?.value,
            contactId: contactValue?.value,
            recruiterId: recruiterValue?.value,
            companyId: compnayValue?.value,
          },
          limit,
          page: currentPage,
        },
      });
    } else {
      getESNotes({
        variables: {
          text: searchParams,
          candidateNotes:
            candidateValue && candidateValue?.value
              ? candidateValue?.value
              : "",

          companyNotes:
            compnayValue && compnayValue?.value ? compnayValue?.value : "",

          jobNotes: jobValue && jobValue?.value ? jobValue?.value : "",

          recruiterNotes: recruiterValue?.value,

          contactNotes:
            contactValue && contactValue?.value ? contactValue?.value : "",

          notesCategory: notesCategory?.label,

          limit,
          page: currentPage,
        },
      });
    }
  };

  const toggle = () => {
    setModal(!modal);
  };

  const pingChildForJobOrdersHandler = (value) => {
    setJobValue(value);
    if (!noteSortBy) {
      getESNotes({
        variables: {
          text: searchParams,
          candidateNotes:
            candidateValue && candidateValue?.value
              ? candidateValue?.value
              : "",

          companyNotes:
            compnayValue && compnayValue?.value ? compnayValue.value : "",

          jobNotes: value?.value,

          recruiterNotes:
            recruiterValue && recruiterValue?.value
              ? recruiterValue?.value
              : "",

          contactNotes:
            contactValue && contactValue?.value ? contactValue?.value : null,

          notesCategory:
            notesCategory && notesCategory?.label ? notesCategory?.label : null,
          limit,
          page: currentPage,
        },
      });
    }
  };

  const pingChildForContactsHandler = (value) => {
    setContactValue(value);
    if (!noteSortBy) {
      getESNotes({
        variables: {
          text: searchParams,
          candidateNotes:
            candidateValue && candidateValue?.value
              ? candidateValue?.value
              : "",

          companyNotes:
            compnayValue && compnayValue?.value ? compnayValue.value : "",

          jobNotes: jobValue && jobValue?.value ? jobValue?.value : "",

          recruiterNorecruiterNotes:
            recruiterValue && recruiterValue?.value
              ? recruiterValue?.value
              : "",

          contactNotes: value?.value,

          notesCategory:
            notesCategory && notesCategory?.label ? notesCategory?.label : null,
          limit,
          page: currentPage,
        },
      });
    }
  };

  const pingChildForCandidateHandler = (value) => {
    setCandidateValue(value);
    if (!noteSortBy) {
      getESNotes({
        variables: {
          text: searchParams,
          candidateNotes: value?.value,

          companyNotes:
            compnayValue && compnayValue?.value ? compnayValue.value : "",

          jobNotes: jobValue && jobValue?.value ? jobValue?.value : "",

          recruiterNotes:
            recruiterValue && recruiterValue?.value
              ? recruiterValue?.value
              : "",

          contactNotes:
            contactValue && contactValue?.value ? contactValue?.value : null,

          notesCategory:
            notesCategory && notesCategory?.label ? notesCategory?.label : null,
          limit,
          page: currentPage,
        },
      });
    }
  };

  const pingChildForRecruiterHandler = (value) => {
    setRecruiterValue(value);
    if (!noteSortBy) {
      getESNotes({
        variables: {
          text: searchParams,
          candidateNotes:
            candidateValue && candidateValue?.value
              ? candidateValue?.value
              : "",

          companyNotes:
            compnayValue && compnayValue?.value ? compnayValue.value : null,

          jobNotes: jobValue && jobValue?.value ? jobValue?.value : null,

          recruiterNotes: value?.value,

          contactNotes:
            contactValue && contactValue?.value ? contactValue?.value : null,

          notesCategory:
            notesCategory && notesCategory?.label ? notesCategory?.label : null,
          limit,
          page: currentPage,
        },
      });
    }
  };

  const pingChildForCompanyHandler = (value) => {
    // setCompnayValue(value);

    if (!noteSortBy) {
      getESNotes({
        variables: {
          text: searchParams,
          candidateNotes:
            candidateValue && candidateValue?.value
              ? candidateValue?.value
              : "",

          companyNotes: value?.value,

          jobNotes: jobValue && jobValue?.value ? jobValue?.value : null,

          recruiterNotes:
            recruiterValue && recruiterValue?.value
              ? recruiterValue?.value
              : "",

          contactNotes:
            contactValue && contactValue?.value ? contactValue?.value : null,

          notesCategory:
            notesCategory && notesCategory?.label ? notesCategory?.label : null,
          limit,
          page: currentPage,
        },
      });
    }
  };

  useEffect(() => {
    searchHandler();

    // eslint-disable-next-line
  }, [limit, currentPage, noteSortBy]);

  useEffect(() => {
    let {
      companyId,
      companyName,
      jobOrderId,
      jobTitle,
      contactId,
      contactName,
      candidateId,
      candidateName,
      recruiterId,
      recruiterName,
      category,
    } = getNoteParams(location?.search);

    if (companyId && companyName) {
      setCompnayValue({ label: companyName, value: companyId });
    }
    if (jobOrderId && jobTitle) {
      setJobValue({ label: jobTitle, value: jobOrderId });
    }
    if (contactId && contactName) {
      setContactValue({ label: contactName, value: contactId });
    }
    if (recruiterId && recruiterName) {
      setRecruiterValue({ label: recruiterName, value: recruiterId });
    }
    if (candidateId && candidateName) {
      setCandidateValue({ label: candidateName, value: candidateId });
    }
    if (category?.value) {
      setNotesCategory({
        label: category?.label || "",
        value: category?.value || "",
      });
    }

    // eslint-disable-next-line
  }, [location?.search]);

  return (
    <div className="admin-tabs team-tabs">
      <ToastContainer autoClose={3000} />

      <div className="primaryHeading primaryHeaderSpacing mobileResponsiveFlexAlign justify-content-between">
        <h5 className="m-0">{noteSortBy && "Email"} Notes Search</h5>

        <div className="search-filter">
          <div>
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <img src={SEARCH_IMG} alt="search" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                type="text"
                placeholder="Search notes"
                value={searchParams}
                onChange={(e) => {
                  setSearchParams(e.target.value);
                  searchControl(e.target.value);
                }}
                className="search-by-company"
              />
            </InputGroup>

            <small className="text-info">
              {searchParams &&
                searchParams.length <= 2 &&
                " Please add more than two characters"}
            </small>
          </div>

          <div className="d-flex align-items-center ml-3">
            <span className="mr-2 switchLabel"> {"Notes"}</span>
            <label className="switch m-0">
              <Input
                type="checkbox"
                name="noteSortBy"
                checked={noteSortBy}
                onChange={(e) => {
                  setNoteSortBy(e?.target?.checked);
                }}
              />
              <span className="sliderNotes"></span>
            </label>
            <span className="ml-2 switchLabel"> {"Email Notes"}</span>
          </div>
        </div>
      </div>

      <div className="jobOrderRevamp">
        <Row>
          <SelectOptionIndex
            disableSelect={false}
            toggle={toggle}
            onUnderSelect={setCurrentUnderSelect}
            underSelect="notes"
            value={notesCategory?.value ? notesCategory : null}
            defaultValue={notesCategory?.value ? notesCategory : null}
            loading={false}
            setSelectedValue={setNotesCategory}
            options={
              noteSortBy
                ? categoryEmailNotes
                : sortingAlphabetical(allNoteCategory)
            }
            label="Note Category"
          />

          <AsyncCompanySelect
            placeholder="Select..."
            label="Company"
            value={compnayValue?.value ? compnayValue : null}
            onChange={(selected) => {
              setContactValue({ value: "", label: "" });
              setJobValue({ value: "", label: "" });
              setCompnayValue(selected);
              pingChildForCompanyHandler(selected);
              setQueryParams(selected, "company");
            }}
          />

          <AsyncJobSelect
            isDisabled={noteSortBy}
            placeholder="Select..."
            label="Job"
            value={jobValue?.value && !noteSortBy ? jobValue : null}
            companyId={compnayValue?.value ? compnayValue.value : null}
            onChange={(selected) => {
              setJobValue(selected);
              pingChildForJobOrdersHandler(selected);
              setQueryParams(selected, "job");
            }}
          />

          <Col sm="12">
            <div className="primaryHeaderSpacing"></div>
          </Col>

          <AsyncContactSelect
            placeholder="Select..."
            label="Contact"
            value={contactValue?.value ? contactValue : null}
            companyId={compnayValue?.value ? compnayValue.value : null}
            onChange={(selected) => {
              setContactValue(selected);
              pingChildForContactsHandler(selected);
              setQueryParams(selected, "contact");
            }}
          />

          <AsyncRecruiterSelect
            placeholder="Select..."
            label="Recruiter"
            value={recruiterValue?.value ? recruiterValue : null}
            onChange={(selected) => {
              setRecruiterValue(selected);
              pingChildForRecruiterHandler(selected);
              setQueryParams(selected, "recruiters");
            }}
          />

          <Col md="4">
            <AsyncCandidateSelect
              placeholder="Select..."
              label="Candidate"
              value={candidateValue?.value ? candidateValue : null}
              onChange={(selected) => {
                setCandidateValue(selected);
                pingChildForCandidateHandler(selected);
                setQueryParams(selected, "candidate");
              }}
            />
          </Col>

          <Col
            sm="12"
            className="d-flex align-items-center justify-content-end pt-3"
          >
            <button
              type="button"
              className="buttonPrimary mr-3"
              onClick={() => {
                setSearchParams("");
                setNotesCategory({ label: "", value: "" });
                getESNotes({ variables: { limit, page: currentPage } });
                setCompnayValue({ label: "", value: "" });
                setRecruiterValue({ label: "", value: "" });
                setContactValue({ label: "", value: "" });
                setJobValue({ label: "", value: "" });
                setCandidateValue({ label: "", value: "" });
              }}
            >
              Clear
            </button>

            <Button onClick={searchHandler} color="primary">
              Search
            </Button>
          </Col>
        </Row>
      </div>

      {noteSortBy && (
        <div className="mt-2">
          <EmailNoteCard
            emailNotesLoading={_emailNotesLoading}
            data={_emailNotes?.getEmailNotes}
            setCurrentPage={setCurrentPage}
            limit={limit}
            count={_emailNotes?.getEmailNotes?.count}
            currentPage={currentPage}
            setLimit={setLimit}
          />
        </div>
      )}

      <div className="section-notes-search">
        {(loading || _emailNotesLoading) && (
          <div>
            <Loader />
          </div>
        )}

        {!loading && !noteSortBy && notes?.getESNotes?.notes?.length
          ? notes?.getESNotes?.notes?.map((item: any) => (
            <SingleNote
              noteableName={item?.noteableName}
              category={item?.category}
              description={item?.content}
              userFullName={item?.user?.fullName}
              createdAt={item?.createdAt}
              companyName={
                // JSON.parse(JSON.stringify(item?.associations))?.length
                item?.associations?.companyName
                  ? // JSON.parse(JSON.stringify(item?.associations))
                  item?.associations?.companyName
                  : compnayValue?.label?.split("-")[0]
              }
              contactName={
                item?.associations?.contactName
                  ? item?.associations?.contactName
                  : contactValue?.label?.split("-")[0]
              }
              jobName={
                item?.associations?.jobTitle
                  ? item?.associations?.jobTitle
                  : jobValue?.label?.split("-")[0]
              }
              candidateName={
                item?.associations?.candidateName
                  ? item?.associations?.candidateName
                  : candidateValue?.label?.split("-")[0]
              }
            />
          ))
          : ""}
      </div>
      {!loading && !notes?.getESNotes?.notes?.length && !noteSortBy && (
        <NoDataFound text="No Notes Found!" />
      )}

      {!noteSortBy && notes?.getESNotes?.notes?.length ? (
        <div className="users-pagination">
          <Pagination
            onPageChange={(pageClicked: number) => {
              setCurrentPage(pageClicked);
              let {
                companyId,
                companyName,
                jobOrderId,
                jobTitle,
                contactId,
                contactName,
                candidateId,
                candidateName,
              } = getNoteParams(location?.search);
              if (
                companyId ||
                companyName ||
                jobOrderId ||
                jobTitle ||
                contactId ||
                contactName ||
                candidateId ||
                candidateName
              ) {
                history.push(
                  `/notes-es${location?.search}&currentPage=${pageClicked}`
                );
              } else {
                history.push(`/notes-es?currentPage=${pageClicked}`);
              }
            }}
            pageCount={Math.ceil(notes?.getESNotes?.count / limit)}
            currentPage={currentPage}
          />

          <div className="d-flex align-items-center">
            <div className="pagination-display paginationItemsSelect">
              <p className="m-0">
                {(currentPage - 1) * limit + 1} to{" "}
                {Math.min(currentPage * limit, notes?.getESNotes?.count)} of{" "}
                {notes?.getESNotes?.count}
              </p>

              <div className="divider"></div>

              <label>Display</label>

              <InputField
                label=""
                inputtype="select"
                selectItems={PAGE_LIMIT.filter((item) => item.value !== limit)}
                inputid="limit"
                placeholder={limit.toString()}
                inputMethod={(value) => {
                  setCurrentPage(1);
                  setLimit(parseInt(value));
                }}
              />
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      <NoteSearchModal
        companyLoading={companyLoading}
        companies={companies}
        modal={modal}
        toggle={toggle}
        currentUnderSelect={currentUnderSelect}
        // Child functions
        pingChildForJobOrders={pingChildForJobOrdersHandler}
        pingChildForContacts={pingChildForContactsHandler}
        pingChildForCandidate={pingChildForCandidateHandler}
        pingChildForRecruiter={pingChildForRecruiterHandler}
        pingChildForCompany={pingChildForCompanyHandler}
      />
    </div>
  );
};

export default withRouter(NotesSearchIndex);
