import { gql } from "@apollo/client";

export const GET_EXEC_USERS = gql`
query GetExecUser{
getExecUsers{
  id
  fullName
  status
  }
}
`;
