import { useContext, useState } from "react";
import noContactIcon from "../../../../assets/images/nocontacts.svg";
import AddContactModal from "./AddContactModal";
import CSVIndex from "./csv-components/CSVIndex";
import { AppContext } from "../../../../context";

export default function NoContact({
  compaignId,
  refetchStats,
  reFetchContacts,
}) {
  const [addContactModal, setAddContactModal] = useState<boolean>(false);
  const [csvModal, setCSVModal] = useState<boolean>(false);
  const { user } = useContext(AppContext);

  const addContactToggle = () => {
    setAddContactModal(!addContactModal);
  };

  const csvToggle = () => {
    setCSVModal(!csvModal);
  };

  return (
    <div className="no-contact-added">
      <img src={noContactIcon} alt="no-contact-added" />
      <h5>No Contacts Added</h5>
      <p>
        Start adding contacts to the sequence or Import
        <br />
        no-contact-added contacts from a CSV file
      </p>
      <div className="contacts-actions">
        <button
          className="buttonGenericStyle filledButton mr-3"
          onClick={() => setAddContactModal(true)}
          type="button"
        >
          Add New Contact
        </button>

        <button
          className="buttonGenericStyle filledButton m-0"
          disabled={!user?.intersellerCSVPermissions}
          onClick={() => setCSVModal(true)}
          type="button"
        >
          Import CSV
        </button>
      </div>

      <CSVIndex
        modal={csvModal}
        toggle={csvToggle}
        compaignId={compaignId}
        reFetchContacts={reFetchContacts}
      />

      <AddContactModal
        refetchStats={refetchStats}
        addContactModal={addContactModal}
        compaignId={compaignId}
        reFetchContacts={reFetchContacts}
        toggle={addContactToggle}
      />
    </div>
  );
}
