import { FC } from "react"
import { Controller, useForm } from "react-hook-form"
import { Col, Input, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import { OpenNewSheetModalProps } from "../../interfaces"
import { ADD_TAB } from "../settings/gql"
import { useMutation } from "@apollo/client"
import moment from "moment"

const OpenNewSheetModal: FC<OpenNewSheetModalProps> = ({ isOpen, setTabs, tabs, toggle, toggleTab, id }) => {
  const { handleSubmit, control, errors } = useForm<{ month: string }>()
  const [addTabs] = useMutation(ADD_TAB)
  return (
    <Modal isOpen={isOpen} toggle={toggle} className="revampDialog revampDialogWidth">
      <ModalHeader>
        Report
      </ModalHeader>
      <ModalBody >
        <Col xs="12" className="px-0 py-2">
          <h5>Month</h5>
          <Controller
            control={control}
            rules={{ required: "Month is required" }}
            name="month"
            defaultValue={new Date().toISOString().substring(0, 7)}
            render={({ onChange, ref }) => (
              <Input
                defaultValue={new Date().toISOString().substring(0, 7)}
                type="month"
                ref={ref}
                onChange={onChange}
                placeholder="Select Month"
                max={new Date().toISOString().substring(0, 7)}
              />
            )}
          />
          <small className="text-danger pt-2">
            {errors?.month?.message}
          </small>
        </Col>
      </ModalBody>

      <ModalFooter>
        <button
          type="button"
          onClick={toggle}
          className="buttonPrimary mr-3"
        >
          Cancel
        </button>
        <button
          type="button"
          onClick={
            handleSubmit(async ({ month }) => {
              await addTabs({
                variables: {
                  createGoalSheetTabDto: {
                    month: month.slice(5),
                    year: moment(month).year().toString(),
                    recruiterId: id
                  }
                }, onCompleted: (data) => {
                  localStorage.setItem(`goalSheetTabs`, JSON.stringify(data?.addTab?.tabs))
                  setTabs(data?.addTab?.tabs)
                  toggleTab(`${data?.addTab?.tabs.findIndex((value) => value === `${month.slice(5)}-${month.slice(0, 4)}`) + 1}`
                    , Number(month.slice(5)), Number(month.slice(0, 4)))
                  toggle()
                }
              })
            })

          }
          className="btn-primary btn mr-3"
        >
          Open
        </button>
      </ModalFooter>
    </Modal>
  )

}
export default OpenNewSheetModal