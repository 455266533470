import { Table } from "reactstrap";

const TotalCredit = ({ netCommission, placementSplit }) => {
  return (
    <div className="candidtae-bcredit-ssheet">
      <h5 className="mb-2">Total Billing Credit</h5>

      <Table className="transparentTable">
        <thead>
          <tr>
            <th style={{ width: "36%" }}>Name</th>
            <th style={{ width: "64%" }}>Credit%</th>
          </tr>
        </thead>

        <tbody>
          {placementSplit?.length > 0 &&
            placementSplit?.map((item) => {
              return (
                <tr>
                  <td>{item?.user?.fullName}</td>

                  <td>{(item?.splitPercentage * 50) / 100}%</td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    </div>
  );
};

export default TotalCredit;
