import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { ParamsType } from '../../../interfaces';
import { COMPANY_DETAILS_ROUTE, VIEW_CANDIDATE_ROUTE, VIEW_JOB_ORDER_ROUTE, workplaceTypesDto } from '../../../utils/constant';
import { location as locationFn, pushInHistory } from '../../../utils/helper';
import { IComingState } from '../../agreements/interfaces';

const JobOrderDetailWidgetForDrawer = ({ applicantData, isTitle = true }) => {
  const history = useHistory()
  const location = useLocation<IComingState>()
  const { id } = useParams<ParamsType>()
  const isJobOrderRoute = location.pathname.includes(VIEW_JOB_ORDER_ROUTE)
  const backURL = isJobOrderRoute
    ? `${VIEW_JOB_ORDER_ROUTE}/${id}` : `${VIEW_CANDIDATE_ROUTE}/${id}`

  return (
    <div className="pb-3">
      {isTitle && (
        <div className="pb-3">
          <strong>Job Order</strong>
        </div>
      )}

      <Row className="mb-2">
        <Col xs={4}>
          <h6 className="text-14 text-blackele">Job Title</h6>
        </Col>

        <Col xs={8}>
          {!!applicantData?.JobOrder?.jobTitle ?
            <h6 onClick={() => {
              !isJobOrderRoute && history.push({
                pathname: `${VIEW_JOB_ORDER_ROUTE}/${applicantData?.JobOrder?.id}`,
                state: {
                  backUrl: backURL,
                  searchParameters: location.search
                }
              })

              pushInHistory(backURL, location.search)
            }}
              className={`font-weight-normal mb-0  ${!isJobOrderRoute && 'color-blue-text route-link'}`}
            >
              {applicantData?.JobOrder?.jobTitle}
            </h6> : "--"
          }
        </Col>
      </Row>

      <Row className="mb-2">
        <Col xs={4}>
          <h6 className="text-14 text-blackele">Company Name</h6>
        </Col>

        <Col xs={8}>
          {!!applicantData?.JobOrder?.company?.name ?
            <h6
              onClick={() => {
                history.push({
                  pathname: `${COMPANY_DETAILS_ROUTE}/${applicantData?.JobOrder?.company?.id}`,
                  state: {
                    backUrl: backURL,
                    searchParameters: location.search
                  }
                })
                pushInHistory(backURL, location.search)
              }}
              className="font-weight-normal mb-0 color-blue-text route-link"
            >
              {applicantData?.JobOrder?.company?.name}
            </h6> : '--'
          }
        </Col>
      </Row>

      <Row className="mb-2">
        <Col xs={4}>
          <h6 className="text-14 text-blackele">Company Owner</h6>
        </Col>

        <Col xs={8}>
          <h6 className="font-weight-normal mb-0  text-blackten">
            {applicantData?.JobOrder?.company?.companyOwner?.owner?.fullName ||
              '--'}
          </h6>
        </Col>
      </Row>

      <Row className="mb-2">
        <Col xs={4}>
          <h6 className="text-14 text-blackele">Job Location</h6>
        </Col>

        <Col xs={8}>
          <h6 className="font-weight-normal mb-0  text-blackten">
            {locationFn(applicantData?.JobOrder?.companyLocation) || '--'}
          </h6>
        </Col>
      </Row>

      <Row className="mb-2">
        <Col xs={4}>
          <h6 className="text-14 text-blackele">Workplace Type</h6>
        </Col>

        <Col xs={8}>
          <h6 className="font-weight-normal mb-0  text-blackten">
            {applicantData?.JobOrder?.workplaceTypes? workplaceTypesDto[applicantData.JobOrder.workplaceTypes] : '--'}
          </h6>
        </Col>
      </Row>

      <Row className="mb-2">
        <Col xs={4}>
          <h6 className="text-14 text-blackele">Job Status</h6>
        </Col>

        <Col xs={8}>
          <h6 className="font-weight-normal mb-0  text-blackten">
            {applicantData?.JobOrder?.status || '--'}
          </h6>
        </Col>
      </Row>
    </div>
  );
};

export default JobOrderDetailWidgetForDrawer;
