import { useLazyQuery, useMutation } from '@apollo/client';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Form, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import CustomButton from '../../../../components/layout/CustomButton';
import InputField from '../../../../components/layout/InputField';
import { AppContext } from '../../../../context';
import {
  IPreviewStep,
  ISequenceContextPreviewSetup,
  ISequenceStepUpdateInput,
} from '../interfaceSteps';
import PreviewBody from './PreviewBody';
import '../step.scss';
import {
  SEND_EMAIL_CONTACT_STEP,
  SEQUENCE_STEP_CONTACT_PERSONALIZED,
  SEQUENCE_STEP_UPDATE,
  REMOVE_PERSONALIZED_STEP,
  SEND_EMAIL_TESTING_STEP,
  SEQUENCE_STEP_UPDATE_TEST,
  CONTACT_PERSONALIZED_STEPS,
} from '../gql';
import { GET_SEQUENCE } from '../../sequences/gql';
import { GET_PREVIEW_STEPS } from '../../contacts/gql';
import { GET_SIGNATURE } from '../../steps/gql';

export default function PreviewStep({
  compaignId,
  refetchStepsPreviewStep,
  fromPreview,
  contactId,
  tabId,
  emailContactStep,
  setEmailContactStep,
  emailTemp,
  stepLoading,
  personzailedStepID,
  setstepIndexNumber,
  stepIndexNumber,
  contactIdForTab,
  setContactIdForTab,
}: IPreviewStep) {
  const { user, sequencePreview, setContextSequence } = useContext(AppContext);
  const { errors, handleSubmit, register, reset, control, setValue } =
    useForm();

  const [contactEmail, setContactEmail] = useState<string>();
  const [testEmail, setTestEmail] = useState<boolean>();
  const [firstStepTestEmail, setFirstStepTestEmail] = useState<any>();
  const [sequenceStepUpdate, { loading: loadingSequenceStepUpdate }] =
    useMutation(SEQUENCE_STEP_UPDATE);

  const [contactPersonalizedSteps] = useLazyQuery(CONTACT_PERSONALIZED_STEPS);

  const [sequenceStepUpdateAbTest, { loading: loadingSequenceUpdatedAbTest }] =
    useMutation(SEQUENCE_STEP_UPDATE_TEST);

  const [removePersonalizedStep, { loading: loadingRemovingPersonalizedStep }] =
    useMutation(REMOVE_PERSONALIZED_STEP);

  const [
    getPreviewSteps,
    { loading: loadingPreviewSteps, data: dataPreviewSteps },
  ] = useLazyQuery(GET_PREVIEW_STEPS);

  const [sendingEmailContactStep, { loading: loadingSendingEmailContactStep }] =
    useMutation(SEND_EMAIL_CONTACT_STEP);

  const [sendEmailTestingStep, { loading: loadingSendingEmailTesting }] =
    useMutation(SEND_EMAIL_TESTING_STEP);

  const [getSequence, { data: sequenceData }] = useLazyQuery(GET_SEQUENCE);
  const [getSignature, { data: getSignatureData }] =
    useLazyQuery(GET_SIGNATURE);

  const [
    sequenceStepContactPersonalized,
    { loading: sequenceStepContactPersonalizedLoading },
  ] = useMutation(SEQUENCE_STEP_CONTACT_PERSONALIZED);

  const savingAbTest = async (values: ISequenceStepUpdateInput) => {
    let response: any;
    if (sequencePreview?.abTestStart) {
      if (sequencePreview?._step) {
        response = await sequenceStepUpdateAbTest({
          variables: {
            compaignId,
            _step: sequencePreview?._step,
            _id: sequencePreview?._id,
            abTest: sequencePreview?.abTestStart,
            body: values?.body,
            subject: values?.subject,
            type: values?.type,
            wait_days: values?.wait_days,
          },
        });
      } else {
        response = await sequenceStepUpdateAbTest({
          variables: {
            compaignId,
            _step: values?._step,
            _id: sequencePreview?._id,
            abTest: sequencePreview?.abTestStart,
            body: values?.body,
            subject: values?.subject,
            type: values?.type,
            wait_days: values?.wait_days,
          },
        });
      }

      if (response?.data) {
        contactPersonalizedSteps({ variables: { compaignId, contactId } });
        refetchStepsPreviewStep();
        toast.success('Step successfully updated');
        const obj = {
          _id: '',
          subject: '',
          body: '',
          preview: false,
          allowOptions: false,
          firstStep: false,
          fromABPreview: false,
          abTestStart: false,
          onSave: true,
        };
        setContextSequence(obj);
        reset();
      } else {
        toast.error('Error occured while creating A/B Test');
      }
    }
  };

  const savePreviewStep = async (values: any) => {
    setContactIdForTab && setContactIdForTab('');
    if (!sequencePreview?.firstStep) {
      const sequence: ISequenceContextPreviewSetup | null = sequencePreview;
      if (
        sequencePreview?.sequenceStepsLengthCheck &&
        !sequencePreview?.replying
      ) {
        values._id = sequence?._id;
      }
      values.type = 'message';
    }
    values.wait_days = sequencePreview?.wait_days;
    values.subject = values?.latestSubject;

    const response = await sequenceStepUpdate({
      variables: { compaignId, ...values },
    });

    if (response?.data) {
      getPreviewSteps({ variables: { compaignId } });
      if (!sequenceData?.getSequence?.steps?.length) {
        refetchStepsPreviewStep();
      }
      contactPersonalizedSteps({ variables: { compaignId, contactId } });
      const res: any = await getSequence({
        variables: { compaignId },
      });
      res?.data?.getSequence?.steps?.forEach((element, index) => {
        if (element?.id && element?.id === values?._id) {
          const obj = {
            _id: element?.id,
            latestSubject: values?.latestSubject
              ? values?.latestSubject
              : sequencePreview?.latestSubject,
            subject: values?.subject,
            body: element?.body,
            preview: true,
            allowOptions: false,
            firstStep: false,
            onSave: true,
            sequenceStepsLengthCheck: true,
            replying: false,
            wait_days: sequencePreview?.wait_days,
            stepIndex: index + 1,
          };
          setContextSequence(obj);
        }
      });
      if (!values?._id) {
        const obj = {
          _id: res?.data?.getSequence?.steps[
            res?.data?.getSequence?.steps.length - 1
          ]?.id,
          latestSubject: values?.latestSubject
            ? values?.latestSubject
            : sequencePreview?.latestSubject,
          subject: values?.subject,
          body: res?.data?.getSequence?.steps[
            res?.data?.getSequence?.steps.length - 1
          ]?.body,
          preview: true,
          allowOptions: false,
          firstStep: false,
          onSave: true,
          sequenceStepsLengthCheck: true,
          replying: false,
          wait_days: sequencePreview?.wait_days,
          stepIndex: res?.data?.getSequence?.steps?.length,
        };
        setContextSequence(obj);
      }
      refetchStepsPreviewStep();
      reset();
      toast.success('Step successfully updated');
    } else {
      toast.error('Error occured while creating step');
    }
  };

  const savePersonalizedPreviewStep = async (values: any) => {
    delete values.type;
    const response = await sequenceStepContactPersonalized({
      variables: {
        contactId,
        markdown: values.body,
        subject: values.latestSubject,
        stepIndex: stepIndexNumber,
        immediate: false,
      },
    });
    if (response?.data) {
      const res: any = await contactPersonalizedSteps({
        variables: { compaignId, contactId },
      });

      res?.data?.contactPersonalizedSteps?.forEach((element) => {
        if (element?._step === personzailedStepID) {
          const obj = {
            _id: element?._step,
            subject: values.latestSubject,

            latestSubject: values?.latestSubject
              ? values?.latestSubject
              : sequencePreview?.latestSubject,

            body: element?.personalizedStepDetails?.body,
            preview: true,
            allowOptions: false,
            firstStep: false,
            onSave: true,
            fromPreview: true,
          };
          setContextSequence(obj);
        }
      });
      refetchStepsPreviewStep();
      reset();
      toast.success('Step successfully personalized');
    } else {
      toast.error('Error occured while personalizing step');
    }
  };

  const sendEmailContactStep = async () => {
    const response = await sendingEmailContactStep({
      variables: {
        contactId,
        email: contactEmail,
        step:
          emailTemp?.step === 0 ? emailTemp?.step : firstStepTestEmail?.step,
        stepId: emailTemp?.stepId ? emailTemp?.stepId : sequencePreview?.stepId,
      },
    });

    if (response?.data) {
      toast.success(`Email sent to ${contactEmail}`);
      emailContactStepToggle();
    }
  };

  const sendingEmailTestingStep = async (values: any) => {
    const response = await sendEmailTestingStep({
      variables: {
        compaignId,
        email: values?.email,
        step:
          emailTemp?.step === 0 ? emailTemp?.step : firstStepTestEmail?.step,
        stepId: emailTemp?.stepId ? emailTemp?.stepId : firstStepTestEmail?._id,
      },
    });

    if (response?.data) {
      toast.success(`Email sent to ${values?.email}`);
      emailContactStepToggle();
    }
  };

  const clearPersonalizedStep = async () => {
    const response = await removePersonalizedStep({
      variables: { contactId, stepIndex: sequencePreview?.stepIndex },
    });
    if (response?.data) {
      toast.success('Personalized step deleted successfully');
      const obj = {
        _id: sequencePreview?._id,
        subject: sequencePreview?.latestSubject,
        body: sequencePreview?.body,
        preview: true,
        allowOptions: false,
        firstStep: false,
      };
      contactPersonalizedSteps({ variables: { compaignId, contactId } });
      refetchStepsPreviewStep();
      setContextSequence(obj);
      emailContactStepToggle();
    }
  };

  const emailContactStepToggle = () => {
    setEmailContactStep && setEmailContactStep(!sendEmailContactStep);
  };

  const fetchSequenceData = useCallback(async () => {
    getSequence({ variables: { compaignId } });
  }, [compaignId, getSequence]);

  useEffect(() => {
    if (compaignId) {
      fetchSequenceData();
      if (!dataPreviewSteps) {
        getPreviewSteps({ variables: { compaignId } });
      }
    }
  }, [compaignId, dataPreviewSteps, fetchSequenceData, getPreviewSteps]);

  useEffect(() => {
    getSignature({ variables: { compaignId } });
  }, [compaignId, getSignature]);

  useEffect(() => {
    if (sequenceData) {
      const { getSequence } = sequenceData;
      const [stepZero] = getSequence?.steps;
      const obj = {
        _id: stepZero?.id,
        subject: stepZero?.subject,
        body: stepZero?.body,
        step: 0,
        forTestEmail: true,
      };
      setTestEmail(true);
      setFirstStepTestEmail(obj);
    }
  }, [sequenceData]);

  return (
    <div className="step-preview" style={{ position: 'sticky', top: '0' }}>
      <Form>
        <div className="preview-header flex-column align-items-start">
          <h4 className="mb-2">Preview</h4>

          <div className="d-flex">
            {sequencePreview?.preview &&
            tabId === '3' &&
            fromPreview &&
            dataPreviewSteps?.previewSteps?.length ? (
              <div>
                <div>
                  <CustomButton
                    loading={loadingRemovingPersonalizedStep}
                    disabled={
                      !contactId ||
                      sequencePreview.personalizedType !== 'manual'
                        ? true
                        : loadingRemovingPersonalizedStep
                    }
                    buttonType="button"
                    buttonText="Clear personalized email"
                    className="text-xs"
                    buttonClick={clearPersonalizedStep}
                  />

                  <CustomButton
                    loading={loadingSendingEmailContactStep}
                    disabled={
                      !contactId ? true : loadingSendingEmailContactStep
                    }
                    buttonType="button"
                    className="ml-2 text-xs "
                    buttonText="Send Test Email"
                    buttonClick={() =>
                      setEmailContactStep && setEmailContactStep(true)
                    }
                  />
                </div>
                <div style={{ marginTop: '2%' }}>
                  <CustomButton
                    loading={sequenceStepContactPersonalizedLoading}
                    disabled={
                      !contactId ? true : sequenceStepContactPersonalizedLoading
                    }
                    buttonType="submit"
                    buttonText="Save"
                    className="ml-1 text-xs"
                    buttonClick={handleSubmit(savePersonalizedPreviewStep)}
                  />
                </div>
              </div>
            ) : testEmail &&
              firstStepTestEmail?.forTestEmail &&
              tabId === '3' &&
              dataPreviewSteps?.previewSteps?.length ? (
              <CustomButton
                loading={loadingSendingEmailContactStep}
                disabled={!contactId ? true : !testEmail}
                buttonType="button"
                className="ml-0"
                buttonText="Send Test Email"
                buttonClick={() =>
                  setEmailContactStep && setEmailContactStep(true)
                }
              />
            ) : null}

            {sequencePreview?.abTestStart && sequencePreview?.fromABPreview ? (
              <CustomButton
                loading={loadingSequenceUpdatedAbTest}
                disabled={loadingSequenceUpdatedAbTest}
                buttonType="submit"
                buttonText="Save AB Test"
                className="ml-0"
                buttonColor="secondary"
                buttonClick={handleSubmit(savingAbTest)}
              />
            ) : null}
            {sequencePreview?.preview && tabId === '2' ? (
              <CustomButton
                loading={loadingSequenceStepUpdate}
                disabled={loadingSequenceStepUpdate}
                buttonType="submit"
                buttonText="Save"
                className="ml-0"
                buttonColor="secondary"
                buttonClick={handleSubmit(savePreviewStep)}
              />
            ) : null}
          </div>
        </div>

        <PreviewBody
          lengthOfSequencePreviewSteps={dataPreviewSteps?.previewSteps?.length}
          tabId={tabId}
          loadingPreviewSteps={loadingPreviewSteps}
          forTestEmail={firstStepTestEmail?.forTestEmail}
          firstStepTestEmail={firstStepTestEmail}
          testEmail={testEmail}
          register={register}
          errors={errors}
          control={control}
          reset={reset}
          setValue={setValue}
          lengthOfSequenceSteps={sequenceData?.getSequence?.steps?.length}
          stepLoading={stepLoading}
          getSignatureData={getSignatureData}
        />
      </Form>

      {emailContactStep && (
        <Modal isOpen={emailContactStep} className="for-modal-styling">
          <ModalHeader>Send Test Email</ModalHeader>
          <ModalBody>
            <div className="">
              <Form>
                <InputField
                  inputtype="text"
                  required={true}
                  inputRef={register({
                    required: {
                      value: true,
                      message: 'Must Select email!',
                    },
                  })}
                  defaultValue={user?.email}
                  inputid="email"
                  errMsg={errors?.email?.message}
                  placeholder="Please enter an email to test step"
                  label="Email"
                  onChange={({ target }) => setContactEmail(target.value)}
                />
              </Form>
            </div>
          </ModalBody>
          <ModalFooter className="mt-3">
            <CustomButton
              buttonText="Close"
              buttonColor="default"
              buttonType="button"
              buttonClick={emailContactStepToggle}
            />

            {contactId && sequencePreview?.preview ? (
              <CustomButton
                buttonText="Send Test Email"
                buttonColor="primary"
                buttonType="submit"
                className="big-width"
                buttonClick={handleSubmit(sendEmailContactStep)}
                loading={loadingSendingEmailContactStep}
                disabled={loadingSendingEmailContactStep}
              />
            ) : testEmail && firstStepTestEmail?.forTestEmail ? (
              <CustomButton
                buttonText="Send Test Email"
                buttonColor="primary"
                buttonType="submit"
                className="big-width"
                loading={loadingSendingEmailTesting}
                buttonClick={handleSubmit(sendingEmailTestingStep)}
                disabled={!testEmail}
              />
            ) : (
              <CustomButton
                buttonText="Send Test Email"
                buttonColor="primary"
                buttonType="submit"
                className="big-width"
                buttonClick={handleSubmit(sendEmailContactStep)}
                disabled={!!!contactId}
              />
            )}
          </ModalFooter>
        </Modal>
      )}
    </div>
  );
}
