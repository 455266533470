import { useState, useCallback, useEffect } from 'react';
import GoBack from '../../components/GoBack';
import { ADD, CHATGPT_TEMPLATES } from '../../constants';
import Loader from '../../components/layout/Loader';
import TableComponent from '../../components/layout/TableComponent';
import {
  MODULE_HEADER_CHATGPT,
  SETTINGS_ROUTE,
  chatGPtDTO,
} from '../../utils/constant';
import EDIT_ICON from '../../assets/images/edit-icon-menu.svg';
import DrawerComponent from './Drawer';
import { ALL_CHATGPT_TEMPLATES } from './gql';
import { useLazyQuery } from '@apollo/client';
import { FormValuesModule, IChatGPTTemplateSetting } from '../../interfaces';
import { Controller, useForm } from 'react-hook-form';
import ModalComponent from '../../common/Modal';
import AddModal from './AddModal';

const ChatGPT_Templates = () => {
  const [addModule, setAddModule] = useState<boolean>(false);
  const [allChatGptTemplates, { data: templatesData, loading: listLoading, refetch: refetchGPTTemplates },
  ] = useLazyQuery(ALL_CHATGPT_TEMPLATES);
  const { register, handleSubmit, control } =
    useForm<FormValuesModule>();
  const [data, setData] = useState<IChatGPTTemplateSetting>()
  const [openModal, setOpenModal] = useState<boolean>(false);

  const fetchingTemplatesData = useCallback(async () => {
    await allChatGptTemplates();
  }, [allChatGptTemplates]);

  useEffect(() => {
    fetchingTemplatesData();
  }, [fetchingTemplatesData]);

  if (listLoading) {
    return <Loader />;
  }
  return (
    <>
      <div className="admin-tabs team-tabs">
        <GoBack url={SETTINGS_ROUTE} />

        <div className="primaryHeaderSpacing primaryHeading d-flex justify-content-between align-items-center">
          <h5 className="m-0">{CHATGPT_TEMPLATES}</h5>

          <button
            type="button"
            onClick={() => setOpenModal(!openModal)}
            className="buttonGenericStyle filledButton"
          >
            {ADD}
          </button>
        </div>

        <div className="mr-3">
          {console.log(templatesData?.AllChatGptTemplates?.chatGPTtemplates)}
          <TableComponent tableHeader={MODULE_HEADER_CHATGPT}>
            <tbody>
              {templatesData?.AllChatGptTemplates?.chatGPTtemplates?.length &&
                templatesData?.AllChatGptTemplates?.chatGPTtemplates.map(
                  (moduleName, index) => {
                    return (
                      <tr>
                        <td>{chatGPtDTO[moduleName?.module]}</td>
                        <td className='text-truncate max-800'>{moduleName?.template}</td>
                        <td className="text-truncate max-200">
                          {moduleName?.updatedAt
                            ? moduleName?.updatedAt
                            : moduleName?.createdAt}
                        </td>
                        <td>
                          <button
                            onClick={async () => {
                              setData(templatesData?.AllChatGptTemplates?.chatGPTtemplates[index])
                              setAddModule(!addModule)
                            }}
                            className="iconButtonAction"
                          >
                            <img src={EDIT_ICON} alt="Edit" />
                          </button>
                        </td>
                      </tr>
                    );
                  }
                )}
            </tbody>
          </TableComponent>
        </div>
      </div>

      <DrawerComponent
        isOpen={addModule}
        toggle={() => {
          setAddModule(!addModule);
        }}
        data={data}
        refetchGPTTemplates={refetchGPTTemplates}
      />

      <ModalComponent
        size="md"
        isOpen={openModal}
        toggle={() => setOpenModal(false)}
      >
        <AddModal
          onClose={() => setOpenModal(false)}
          register={register}
          Controller={Controller}
          control={control}
          handleSubmit={handleSubmit}
          refetchGPTTemplates={refetchGPTTemplates}
          toggle={() => {
            setOpenModal(false)
          }}
        />
      </ModalComponent>
    </>
  );
};

export default ChatGPT_Templates;
