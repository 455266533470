import {
  Button,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import CustomButton from "../../../../components/layout/CustomButton";

export default function ManagerAcceptModalEscalation({
  modal,
  toggle,
  setNote,
  managerAcceptProtocolLoading,
  confirmAction,
  onClosed,
}) {
  return (
    <Modal
      isOpen={modal}
      toggle={toggle}
      scrollable
      className="for-modal-styling"
      onClosed={onClosed}
    >
      <ModalHeader>Approve - Manager</ModalHeader>
      <ModalBody>
        <div className="approval-content">
          <p>
            Are you sure you want to send this agreement for executive's
            approval?
          </p>
          <p>
            Approving this agreement will results in sending it to executive for
            approval.
          </p>
        </div>

        <div>
          <Label>Add text</Label>
          <textarea
            name="sourceNote"
            onChange={(e) => setNote(e.target.value)}
            placeholder="Please add notes"
            rows={2}
            className="form-control h-150"
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          onClick={onClosed}
          className="modal-close bg-transparent border-0 text-dark"
        >
          Cancel
        </Button>
        <CustomButton
          buttonType="button"
          buttonText={"Approve"}
          buttonColor={"success"}
          buttonClick={confirmAction}
          loading={managerAcceptProtocolLoading}
        />
      </ModalFooter>
    </Modal>
  );
}
