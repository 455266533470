import { gql } from "@apollo/client";

// this api get entries from merge candidate table
export const GET_MERGE_CANDIDATES = gql`
  query GetMergeCandidates(
    $page: Int!
    $limit: Int!
    $searchText: String
    $statusSearch: String
    $candidateId: String
  ) {
    getMergeCandidates(
      payload: { page: $page, limit: $limit }
      filters: {
        searchText: $searchText
        statusSearch: $statusSearch
        candidateId: $candidateId
      }
    ) {
      mergeCandidates {
        id
        mergedAt
        processedAt
        mergeNotes
        sourceCandidateName
        destinationCandidateName
        status
        createdAt
        sourceCandidateId
        destinationCandidateId
      }
      count
    }
  }
`;

// this api create entry in merge candidate table
export const CREATE_MERGE_CANDIDATE = gql`
  mutation CreateMergeCandidate(
    $sourceCandidateId: String!
    $destinationCandidateId: String!
    $mergeNotes: String
  ) {
    createMergeCandidate(
      mergeCandidateInput: {
        mergeNotes: $mergeNotes
        sourceCandidateId: $sourceCandidateId
        destinationCandidateId: $destinationCandidateId
      }
    ) {
      message
      status
    }
  }
`;

// this api delete entry from merge candidate table
export const DELETE_MERGE_CANDIDATE = gql`
  mutation DeleteMergeCandidate($mergeCandidateId: String!) {
    deleteMergeCandidate(mergeCandidateId: $mergeCandidateId) {
      message
      status
    }
  }
`;

export const MERGE_CANDIDATE = gql`
  mutation MergeCandidate($mergeCandidateId: String!) {
    mergeCandidate(mergeCandidateId: $mergeCandidateId) {
      message
      status
    }
  }
`;

export const GET_PLACEMENTS_BY_CANDIDATE = gql`
  query getPlacementsByCandidate($candidateId: String!) {
    getPlacementsByCandidate(candidateId: $candidateId) {
      id
    }
  }
`;
