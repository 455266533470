import { useLazyQuery } from "@apollo/client";
import { useContext, useEffect, useState } from "react";
import { APPLY_DATE_RANGE_CONTACTS_MEETINGS } from "../gql/index";
import { contactsMeetingsAddedToday } from "../../../../utils/constant";
import Loader from "../../../../components/layout/Loader";
import TableComponent from "../../../../components/layout/TableComponent";
import NoDataFound from "../../../../components/layout/NoDataFound";
import SEARCH_IMG from "../../../../assets/images/search.svg";
import Arrow_Image from "../../../../assets/images/arrow.svg";
import CustomButton from "../../../../components/layout/CustomButton";
import InputField from "../../../../components/layout/InputField";
import { SEQUENCE_LISTING_ID_NAME_FOR_LISTING } from "../../sequences/gql";
import { useForm } from "react-hook-form";
import GoBack from "../../../../components/GoBack";
import {
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormGroup,
  Spinner,
  Button,
} from "reactstrap";
import { compactObject } from "../../../../utils/commonFn";
import { useLocation } from "react-router-dom";
import Select from "react-select";
import { AppContext } from "../../../../context";

const SEQUENCE_CONTACTS = ["Sequence Name", "Contacts Added"];

let time = 0;

export default function ContactsListingToday({ refetchStats }) {
  const { theme } = useContext(AppContext)
  const location: any = useLocation();
  const [sequenceId, setSequenceId] = useState<string>();
  const [type, setType] = useState<string>();
  const [startedAt, setStartedAt] = useState<string>("");
  const [endedAt, setEndedAt] = useState<string>("");
  const { errors, handleSubmit, register, reset } = useForm();
  const [searchParams, setSearchParams] = useState<string>("");
  const [dateRangeModal, setDateRangeModal] = useState<boolean>(false);
  useState<boolean>(false);


  const [
    getSequencesListing,
    { loading: loadingSequencesListing, data: dataSequencesListing },
  ] = useLazyQuery(SEQUENCE_LISTING_ID_NAME_FOR_LISTING);

  const [
    applyDateRangeContactsMeetings,
    {
      loading: loadingApplyDateRangeContactsMeetings,
      data: dataApplyDateRangeContactsMeetings,
    },
  ] = useLazyQuery(APPLY_DATE_RANGE_CONTACTS_MEETINGS);

  useEffect(() => {
    getSequencesListing({
      variables: {},
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const applyDateRange = (values: any) => {
    const startDateInString = Date.parse(values?.startedAt);
    let startedAtDate = new Date(startDateInString);
    startedAtDate.setHours(0, 0, 0, 0);

    const endDateInString = Date.parse(values?.endedAt);
    let endedAtDate = new Date(endDateInString);
    endedAtDate.setHours(23, 59, 59, 999);

    applyDateRangeContactsMeetings({
      variables: {
        type: values?.type,
        startedAt: startedAtDate,
        endedAt: endedAtDate,
      },
    });

    if (dataApplyDateRangeContactsMeetings) {
      reset();
      setType("");
      setStartedAt("");
      setEndedAt("");
      setDateRangeModal(false);
    }
  };

  const setQueryParams = (searchParams: string) => {
    const criteria = compactObject({
      searchParams,
    });

    const urlQueryParams: Array<any> = [];
    if (Object.keys(criteria).length) {
      for (const key in criteria) {
        if (
          Object.prototype.hasOwnProperty.call(criteria, key) &&
          criteria[key]
        ) {
          urlQueryParams.push(`${key}=${criteria[key]}`);
        }
      }
    }
  };

  const searchControl = (value: string) => {
    setSearchParams(value);
    setQueryParams(value);
    if (!value || value?.length > 2) {
      window.clearTimeout(time);
      time = window.setTimeout(() => {
        let start = new Date();
        start.setHours(0, 0, 0, 0);

        let end = new Date();
        end.setHours(23, 59, 59, 999);

        applyDateRangeContactsMeetings({
          variables: {
            sequenceName: value,
            type: location?.state?.from,
            startedAt: start.toUTCString(),
            endedAt: end.toUTCString(),
          },
        });
      }, 1000);
    }
  };

  useEffect(() => {
    let start = new Date();
    start.setHours(0, 0, 0, 0);

    let end = new Date();
    end.setHours(23, 59, 59, 999);

    applyDateRangeContactsMeetings({
      variables: {
        type: location?.state?.from,
        startedAt: start.toUTCString(),
        endedAt: end.toUTCString(),
      },
    });

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // const startDateInString = Date.parse(startedAt);
    // let startedAtDate = new Date(startDateInString);
    // startedAtDate.setHours(0, 0, 0, 0);

    // const endDateInString = Date.parse(endedAt);
    // let endedAtDate = new Date(endDateInString);
    // endedAtDate.setHours(23, 59, 59, 999);

    if (sequenceId) {
      applyDateRangeContactsMeetings({
        variables: {
          type,
          startedAt: "",
          endedAt: "",
          compaignId: sequenceId,
        },
      });
    }

    // eslint-disable-next-line
  }, [sequenceId]);

  const checkingLengthForTodayReport = (): number => {
    return dataApplyDateRangeContactsMeetings?.applyDateRangeContactsMeetings?.filter(
      ({ _id: id1 }) =>
        location?.state?.sequencesArray?.some(({ _id: id2 }) => id2 === id1)
    )?.length;
  };

  const filterTodayReport = () => {
    return dataApplyDateRangeContactsMeetings?.applyDateRangeContactsMeetings?.filter(
      ({ _id: id1 }) =>
        location?.state?.sequencesArray?.some(({ _id: id2 }) => id2 === id1)
    );
  };

  const onClearData = () => {
    setSearchParams("");
    setSequenceId("");
    reset();
    let start = new Date();
    start.setHours(0, 0, 0, 0);

    let end = new Date();
    end.setHours(23, 59, 59, 999);

    applyDateRangeContactsMeetings({
      variables: {
        type: location?.state?.from,
        startedAt: start.toUTCString(),
        endedAt: end.toUTCString(),
      },
    });
  };
  const customStyles = {
    control: (styles, provided) => ({
      ...styles,       
      background: theme === "light" ? provided.background : "#282828",
      borderColor: theme === "light" ? "#ced4da" : "#525252",
      color: theme === "light" ? provided.background : "#d9d9d9",
      fontSize: 12,
      "&:hover": {
       
        color: theme === "light" ? provided.background : "#d9d9d9",
      },
    }),
    menu: (provided) => ({
      ...provided,
      background: theme === "light" ? provided.background : "#282828",
      color: theme === "light" ? provided.background : "#d9d9d9",
    }),
    option: (provided) => ({
      ...provided,
      fontSize: 12,
      background: theme === "light" ? provided.background : "#363636",
      "&:hover": {
        background: theme === "light" ? provided.background : "#474444",
        color: theme === "light" ? provided.background : "#d9d9d9",
      },
    }),
   }
  return (
    <>
      <>
        <div className="admin-tabs team-tabs">
          <GoBack url="/sequences" />

          <div className="primaryHeading primaryHeaderSpacing mobileResponsiveFlexAlign justify-content-between">
            <h5 className="m-0">
              {location?.state?.from === "created" ? "Contacts" : "Meetings"}{" "}
              Added Today
            </h5>

            <div className="search-filter">
              <div>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <img src={SEARCH_IMG} alt="search" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    type="text"
                    placeholder="Search for a sequence(Today)"
                    value={searchParams}
                    onChange={(e) => {
                      setSearchParams(e.target.value);
                      searchControl(e.target.value);
                    }}
                    className="search-by-company"
                  />
                </InputGroup>

                <small className="text-info">
                  {searchParams &&
                    searchParams.length <= 2 &&
                    "Please add more than two characters"}
                </small>
              </div>

              <div className="filter-status">
                <div className="filter-by mt-xl-0 mt-2 ">
                  <div className="dateRangeButton">
                    <button
                      onClick={() => setDateRangeModal(!dateRangeModal)}
                      className="buttonGenericStyle filledButton ml-xl-3 mr-md-2 mb-sm-0 mb-2"
                      type="button"
                    >
                      Date Range
                    </button>
                  </div>

                  <button
                    className="buttonGenericStyle filledButton ml-1 mr-2 mb-sm-0 mb-2" 
                    onClick={onClearData}
                  >
                    Clear
                  </button>

                  {dateRangeModal && (
                    <>
                      <div className="date-range-today-contacts">
                        <div className="mobileResponsiveFlexDevice">
                          <FormGroup>
                            <label>From</label>
                            <Input
                              placeholder="From"
                              type="date"
                              name="startedAt"
                              innerRef={register({
                                required: {
                                  value: true,
                                  message: "Start date is required!",
                                },
                              })}
                              onChange={(e) => {
                                setStartedAt(e?.target?.value);
                              }}
                            />
                            <small className="text-danger">
                              {errors?.startedAt?.message}
                            </small>
                          </FormGroup>

                          <img src={Arrow_Image} alt="arrow" />

                          <FormGroup>
                            <label>To</label>
                            <Input
                              placeholder="To"
                              type="date"
                              name="endedAt"
                              innerRef={register({
                                required: {
                                  value: true,
                                  message: "Ended date is required!",
                                },
                              })}
                              onChange={(e) => setEndedAt(e?.target?.value)}
                            />
                            <small className="text-danger">
                              {errors?.endedAt?.message}
                            </small>
                          </FormGroup>
                        </div>

                        <div className="text-right mt-3">
                          <Button
                            className="button-close mr-2"
                            type="button"
                            color="primary"
                            size="small"
                            onClick={() => setDateRangeModal(false)}
                          >
                            Cancel
                          </Button>
                          <CustomButton
                            buttonText="Apply"
                            buttonColor="secondary"
                            loading={loadingApplyDateRangeContactsMeetings}
                            disabled={loadingApplyDateRangeContactsMeetings}
                            buttonType="button"
                            className="buttonPrimary"
                            buttonClick={handleSubmit(applyDateRange)}
                          />
                        </div>
                      </div>
                    </>
                  )}

                  {loadingSequencesListing ? (
                    <Spinner
                      size="sm"
                      style={{ color: "#eaa827" }}
                      className="mr-2"
                    />
                  ) : (
                    <>
                      {startedAt && endedAt && type ? null : (
                        <InputField
                          loading={loadingSequencesListing}
                          inputtype="select"
                          selectItems={
                            dataSequencesListing?.allSequencesIdNameForListing
                              ?.length
                              ? dataSequencesListing?.allSequencesIdNameForListing?.map(
                                  (item: any) => {
                                    return {
                                      label:
                                        item?.title.length > 25
                                          ? `${item?.title.substring(0, 25)}...`
                                          : item?.title,
                                      value: item?._id,
                                    };
                                  }
                                )
                              : []
                          }
                          inputValue={sequenceId}
                          inputid="sequence"
                          placeholder={"Select Sequence"}
                          inputMethod={(value: string) => {
                            setSequenceId(value);
                          }}
                        />
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="section-header section-contact-add  px-2">
            <Select
              styles={{...customStyles}}
              options={contactsMeetingsAddedToday}
              name="type"
              defaultValue={contactsMeetingsAddedToday.filter(
                (item) => item.value === location?.state?.from
              )}
              placeholder={`Report type`}
              inputRef={register({
                required: {
                  value: true,
                  message: "Record type must be selected!",
                },
              })}
              onChange={(value: any) => {
                setType(value.value);
              }}
              errMsg={errors?.type?.message}
            />
          </div>

          <div className="table-responsive">
            <TableComponent tableHeader={SEQUENCE_CONTACTS}>
              {!loadingApplyDateRangeContactsMeetings &&
              !dataApplyDateRangeContactsMeetings?.applyDateRangeContactsMeetings?.filter(
                ({ _id: id1 }) =>
                  location?.state?.sequencesArray?.some(
                    ({ _id: id2 }) => id2 === id1
                  )
              )?.length ? (
                <tbody>
                  <tr>
                    <td colSpan={14} className="empty-table-td">
                      <NoDataFound
                        text={`No ${
                          location?.state?.from === "created"
                            ? "Contacts"
                            : "Meetings"
                        } Found!`}
                      />
                    </td>
                  </tr>
                </tbody>
              ) : null}

              {loadingApplyDateRangeContactsMeetings ? (
                <tbody>
                  <tr>
                    <td colSpan={3}>
                      <Loader loaderClass="sm-height" />
                    </td>
                  </tr>
                </tbody>
              ) : (
                <tbody>
                  {checkingLengthForTodayReport()
                    ? filterTodayReport()?.map((item: any, index: number) => {
                        return (
                          <tr key={index} className="job-orders-tbody">
                            <td>
                              <div>
                                {item?.title.length > 25
                                  ? `${item?.title.substring(0, 25)}...`
                                  : item?.title}
                              </div>
                            </td>
                            <td>{item?.contactCount || "--"}</td>
                          </tr>
                        );
                      })
                    : null}
                </tbody>
              )}
            </TableComponent>
          </div>
        </div>
      </>
    </>
  );
}
