import { useMutation } from "@apollo/client";
import { useState } from "react";
import { toast } from "react-toastify";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import CustomButton from "../../../components/layout/CustomButton";
import { bucketFolders, MANUAL_RESUME_FILE_SIZE } from "../../../utils/constant";
import {
  updateFileName, uploadFile,
} from "../../../utils/helper";
import { CREATE_SUBMISSION_ATTACHMENT } from "../gql";

const AttachmentsUploadModal = ({
  modal,
  toggle,
  submissionId,
  fileType = "",
  driveUpload = false,
  refetch
}) => {
  const [file, setFile] = useState<any>(null);
  const [fileName, setFileName] = useState<any>("");
  const [errorMessage, setErrorMessage] = useState("");
  const [uploadLogin, setUploadLogin] = useState(false);
  const [createAttachment] = useMutation(CREATE_SUBMISSION_ATTACHMENT);
  const allowedFileTypes = [
    "application/pdf",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/msword",
    "application/vnd.oasis.opendocument.text",
    "image/jpeg",
    "image/jpg",
    "application/vnd.ms-excel",
    "image/png",
    "text/csv",
    "text/plain",
  ];
  const upload = async () => {
    try {
      if (file) {
        setUploadLogin(true);
        let response = await uploadFile(
          new File([file], updateFileName(file.name), { type: file.type }),
          bucketFolders.candidate,
          driveUpload, "attachment"
        );
        setUploadLogin(false);
        if (response?.status === 200) {
          console.log("response.success ", response)
          const { fileName, source } = response;
          await createAttachment({
            variables: {
              createSubmissionAttachmentInput: {
                attachmentURL: source,
                attachmentType: "OTHER",
                attachmentName: fileName,
                submissionId
              },
            },
          });
          setFileName("");
          setFile(null);
          toggle();
          toast.success("Successfully Uploaded!");
          refetch();
        }
      } else {
        setErrorMessage("Please select a file!");
      }
    } catch (error) {
      toast.error("Error while uploading!");
      setUploadLogin(false);
    }
  };

  return (
    <Modal toggle={() => {
      setFileName("")
      toggle()
    }} isOpen={modal} className="revampDialog revampDialogWidth">
      <ModalHeader>Upload Attachment</ModalHeader>
      <ModalBody>
        <div>
          <div className="user-info upload-btn-wrapper">
            <input
              type="file"
              name="image"
              accept={fileType}
              onChange={async ({ target: { files } }) => {
                setErrorMessage("");
                if (files) {
                  let [file]: any = files;

                  if (file.size > MANUAL_RESUME_FILE_SIZE) {
                    return setErrorMessage(
                      "File size is too large (should be 10MB)."
                    );
                  }
                  console.log(file?.type)
                  if (!allowedFileTypes.includes(file?.type)) {
                    return setErrorMessage(
                      "file format not supported"
                    );
                  }
                  if (file) {
                    setFileName(file?.name);
                  }
                  setFile(file);
                }
              }}
            />
            <Button className="bg-success border-0 px-3">Attach File</Button>
          </div>
          <div className="mt-2 text-info text-truncate textTruncateWidthFileName">
            {fileName}
          </div>
          {errorMessage && <div className="text-danger">{errorMessage}</div>}
        </div>
      </ModalBody>
      <ModalFooter className="mt-3">
        <button type="button" onClick={() => {
          setFileName("")
          toggle()
        }} className="buttonPrimary mr-3">
          Close
        </button>

        <CustomButton
          buttonText="Save"
          buttonColor="primary"
          buttonType="submit"
          className="big-width"
          buttonClick={upload}
          loading={uploadLogin}
          disabled={uploadLogin}
        />
      </ModalFooter>
    </Modal>
  );
};

export default AttachmentsUploadModal;
