import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";

import CustomButton from "../../../components/layout/CustomButton";

import TeamImage from "../../../assets/images/team.svg";

import TableComponent from "../../../components/layout/TableComponent";
import avatarSvg from "../../../assets/images/user-empty.svg";

import "./style.scss";
import { FETCH_TEAM_DETAIL } from "../gql";
import { useLazyQuery } from "@apollo/client";
import { formatDateUS } from "../../../utils/commonFn";
import GoBack from "../../../components/GoBack";


const TABLE_HEADER = ["Name", "Job Title", "Started At", "Status"];

const ViewTeam = ({ ...props }): JSX.Element => {
  const [modal, setModal] = useState(false);
  const [fetchTeamDetail, { data: teamDetail }] =
    useLazyQuery(FETCH_TEAM_DETAIL);
  const [teamId, setTeamId] = useState<string | null>(null);

  const toggle = () => setModal(!modal);

  useEffect(() => {
    const id = props.match.params.id;
    fetchTeamDetail({ variables: { id } });
    setTeamId(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="admin-tabs team-tabs">
      <GoBack url="/admin/teams" />

      <div className="primaryHeading mobileResponsiveFlexAlign justify-content-between">
        <h5 className="m-0">{teamDetail?.findTeamById?.name}</h5>
        <Link to={`/admin/teams/edit/${teamId}`} className="m-0">
          <button
            className="buttonGenericStyle filledButton"
            color="primary"
            type="button"
          >
            Edit
          </button>
        </Link>
      </div>

      <div className="jobOrderRevamp mt-2">
        <div>
          <div className="user-avatar mb-2">
            <img
              src={teamDetail?.findTeamById?.logoUrl || TeamImage}
              alt="avatar"
            />
          </div>

          <Row className="mt-4">
            <Col lg={4} md={6} sm={12} xs={12}>
              <div className="d-flex justify-content-between mb-2 contentGridLabel">
                <label>Team Name</label>
                <h6>{teamDetail?.findTeamById?.name}</h6>
              </div>
            </Col>

            <Col lg={4} md={6} sm={12} xs={12}>
              <div className="d-flex justify-content-between mb-2 contentGridLabel">
                <label>Manager</label>
                <h6>
                  {teamDetail?.findTeamById?.teamUsers?.length
                    ? teamDetail?.findTeamById?.teamUsers?.map(
                        (teamUser: any, index) =>
                          teamUser.isManager && (
                            <h6 key={index}>{teamUser?.user?.fullName}</h6>
                          )
                      )
                    : "N/A"}
                </h6>
              </div>
            </Col>

            <Col lg={4} md={6} sm={12} xs={12}>
              <div className="d-flex justify-content-between mb-2 contentGridLabel">
                <label>Status</label>
                <h6
                  className={`${
                    teamDetail?.findTeamById?.activatedAt
                      ? "text-success"
                      : "text-warning"
                  }`}
                >
                  {teamDetail?.findTeamById?.activatedAt ? "Active" : "Pending"}
                </h6>
              </div>
            </Col>

            <Col lg={4} md={6} sm={12} xs={12}>
              <div className="d-flex justify-content-between mb-2 contentGridLabel">
                <label>Activated At</label>
                <h6>
                  {teamDetail?.findTeamById?.activatedAt
                    ? formatDateUS(teamDetail?.findTeamById?.activatedAt)
                    : "--"}
                </h6>
              </div>
            </Col>

            <Col lg={4} md={6} sm={12} xs={12}>
              <div className="d-flex justify-content-between align-items-center mb-2 contentGridLabel">
                <label>Creator</label>
                <h6>{teamDetail?.findTeamById?.creator?.fullName || "N/A"}</h6>
              </div>
            </Col>
          </Row>
        </div>

        <div className="table-responsive mt-3">
          <TableComponent tableHeader={TABLE_HEADER}>
            <tbody>
              {teamDetail?.findTeamById?.teamUsers &&
                teamDetail?.findTeamById?.teamUsers.map(
                  (member: any, index: number) => (
                    <tr key={index}>
                      <td>
                        <div className="avatar-profile">
                          <div className="avatar avatar-team">
                            <img
                              src={member?.user?.photo || avatarSvg}
                              alt="team"
                            />
                          </div>
                          {member?.user?.fullName}
                        </div>
                      </td>
                      <td>{member.user?.jobTitle}</td>
                      <td>
                        {member?.startAt ? formatDateUS(member?.startAt) : "--"}
                      </td>
                      <td className="text-capitalize">
                        {member.user?.status.toLowerCase()}
                      </td>
                    </tr>
                  )
                )}
            </tbody>
          </TableComponent>
        </div>
      </div>

      <Modal isOpen={modal} toggle={toggle} scrollable className="for-modal-styling">
        <ModalHeader toggle={toggle}>Add Members</ModalHeader>
        <ModalBody>
          <Row>
            <Col xs="12" className="for-position-multiselect"></Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <CustomButton
            buttonText="Cancel"
            buttonColor="default"
            buttonClick={toggle}
          />{" "}
          <CustomButton
            buttonText="Confirm"
            buttonColor="primary"
            buttonClick={toggle}
            className="big-width"
          />
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ViewTeam;
