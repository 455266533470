import { useMutation } from "@apollo/client";
import { FC, useContext, useEffect, useMemo, useRef, useState } from "react";
import ReactQuill, { Quill } from "react-quill";
import ImageResize from "quill-image-resize-module-react";
import Select from 'react-select';
import { toast } from "react-toastify";
import { Col, Form, Input, Label, Row, Spinner } from "reactstrap";
import { EMAIL_TEMPLATES, SAVE } from "../../constants";
import { AppContext } from "../../context";
import { EmailTemplateEditDrawerProps } from "../../interfaces";
import { EMAIL_TEMPLATES_OPTIONS, emailTemplatesTagsArray, reactQuillReg } from "../../utils/constant";
import { DrawerHeader, Sidedrawer } from "../layout/Sidedrawer";
import { customSelectStyleNew } from "../styled/customSelect";
import { UPDATE_EMAIL_TEMPLATE } from "./gql";
import { uploadImage } from "../../utils/helper";
import Loader from "../layout/Loader";

ReactQuill.Quill.register("modules/imageResize", ImageResize);
const EmailTemplateEditDrawer: FC<EmailTemplateEditDrawerProps> = ({ toggle, isOpen, editRecord, refetch }) => {
  const { theme } = useContext(AppContext)
  const [copied, setCopy] = useState<boolean>(false);
  const [templateContent, setTemplateContent] = useState<string>('');
  const [moduleContent, setModuleContent] = useState<{ label: string; value: string }>();
  const [subject, setSubject] = useState<string>();
  const quillRef = useRef<ReactQuill>(null);
  const [uploadingImage, setUploadingImage] = useState<boolean>(false)

  const [updateRecord, { loading }] = useMutation(UPDATE_EMAIL_TEMPLATE, {
    onCompleted: () => {
      toast.success("updated successfully")
      refetch()
      toggle()
    },
    onError: (err) => toast.error(err?.message)
  })

  useEffect(() => {
    setModuleContent({ value: editRecord?.module!, label: editRecord?.module! })
    setTemplateContent(editRecord?.template!)
    setSubject(editRecord?.subject!)
  }, [isOpen])

  const onCopyText = (item: string, index: number) => {
    navigator.clipboard.writeText(item);
    setCopy(true);

    setTimeout(() => {
      setCopy(false);
    }, 1500);
  };

  const modules = useMemo(() => ({
    toolbar: {
      container: [
        [{ font: [] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        ["link", "image"],
      ],
    },
    clipboard: {
      matchVisual: false,
    },
    imageResize: {
      modules: ['Resize', 'DisplaySize', 'Toolbar']
    }
  }), []);

  return (
    <Sidedrawer toggle={toggle} isOpen={isOpen}>
      <div className="mb-4">
        <DrawerHeader
          loading={loading}
          disabled={loading}
          buttonText={SAVE}
          heading={EMAIL_TEMPLATES}
          prevClick={() => toggle()}
          nextClick={async () => {
            await updateRecord({
              variables: {
                input: {
                  id: editRecord!.id,
                  module: moduleContent?.value,
                  template: templateContent, subject
                }
              }
            })
            console.log("values :: ", moduleContent?.value, editRecord?.id, templateContent)
          }}
        />
      </div>
      <div className="pb-4">
        <Form>
          <Row className="mb-3">
            <Col md="12">
              <Label>Module</Label>
              <Select
                value={moduleContent}
                isDisabled={false}
                options={EMAIL_TEMPLATES_OPTIONS}
                isSearchable={false}
                placeholder="Select Module For Template"
                onChange={(target) => {
                  setModuleContent(target!);
                }}
                styles={{ ...customSelectStyleNew(theme) }}
              />
            </Col>
          </Row>
          <div className="w-100 d-flex flex-wrap mt-2 position-relative">
            {(copied &&
              <span className="badge badge-main badge-warning">Copied</span>
            )}
            {emailTemplatesTagsArray?.map((item: string, index) => {
              return (
                <span
                  className="steps-tags-template"
                  onClick={() => onCopyText(item, index)}
                  id={`items-${index}`}
                  key={index}
                >
                  {item.split(', ')}
                </span>
              );
            })}
          </div>

          <Row className="mb-3">
            <Col md="12">
              <Label>Subject</Label>
              <Input
                value={subject}
                placeholder="Subject Template"
                onChange={({ target }) => {
                  setSubject(target?.value);
                }}
                styles={{ ...customSelectStyleNew(theme) }}
              />
            </Col>
          </Row>

          <Row>
            <Col md="12">
              <Col>
                <Row md="12" >
                  <div>
                    <Label>Template </Label>
                    {uploadingImage && <Spinner size="sm" style={{ color: "#eaa827" }} />}
                  </div>
                </Row>
              </Col>


              <div className="editor-height">
                <ReactQuill
                  value={templateContent}
                  ref={quillRef}
                  modules={modules}
                  onChange={(value) => {
                    if (reactQuillReg.test(value)) {
                      setTemplateContent('');
                    } else {
                      setTemplateContent(value);
                    }
                  }}
                />
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </Sidedrawer>
  )
}
export default EmailTemplateEditDrawer