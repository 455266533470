import { useState, useEffect } from "react";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import {
  INSTAGRAM_HANDLE_REGEX,
  TWITTER_URL_REGEX,
  URL_REGEX,
} from "../utils/constant";
import InputField from "./layout/InputField";

const SocialMediaInfo = ({
  register,
  errors,
  isLinkedinRequired,
  companyAddress = false,
  isDisable,
  setValue,
  nonSolicitStatus,
  recordId,
  linkedinErr = "",
}: any) => {
  const [linkedinRequire, setLinkedinRequire] = useState(isLinkedinRequired);
  const [isRequired, setIsRequired] = useState<boolean>(false);

  useEffect(() => {
    setIsRequired(isLinkedinRequired);
  }, [isLinkedinRequired]);
  const [nonSolicit, setNonSolicit] = useState<boolean>(nonSolicitStatus)

  return (
    <div>
      <div className="user-basic-form">
        <Row>
          <Col md="6" sm="12">
            <InputField
              inputtype="text"
              label="Twitter Url"
              inputid="twitterUrl"
              placeholder="Twitter Url"
              inputRef={register({
                required: {
                  value: false,
                  message: "Twitter URL is required!",
                },
                pattern: {
                  value: TWITTER_URL_REGEX,
                  message: "Twitter URL is not valid!",
                },
              })}
              errMsg={errors?.twitterUrl?.message}
            />
          </Col>
          <Col md="6" sm="12">
            <InputField
              inputtype="text"
              label="Facebook"
              inputid="fbUrl"
              placeholder="Facebook URL"
              inputRef={register({
                required: { value: false },
                pattern: {
                  value: URL_REGEX,
                  message: "Facebook URL is not valid!",
                },
              })}
              errMsg={errors?.fbUrl?.message}
            />
          </Col>
          <Col md="6" sm="12">
            <div className="linkedin-form-container">
              <InputField
                inputtype="text"
                isRequired={isRequired}
                label="LinkedIn"
                inputid="linkedInUrl"
                placeholder="Linkedin URL"
                disabled={isDisable ?? !linkedinRequire}
                inputRef={register({
                  required: { value: false },
                  pattern: {
                    value: URL_REGEX,
                    message: "LinkedIn URL is not valid!",
                  },
                })}
                errMsg={
                  errors?.linkedInUrl?.message
                }
              />
              {companyAddress && (
                <FormGroup className="ml-3 pl-1">
                  <Label>
                    <Input
                      type="checkbox"
                      innerRef={register()}
                      name="hasLinkedIn"
                      onChange={(e) => {
                        if (e.target.checked) {
                          setValue("linkedInUrl", null);
                        }
                        setLinkedinRequire(!linkedinRequire);
                        setIsRequired(!isRequired);
                      }}
                    // disabled={linkedinRequire}
                    />
                    <span className="checkboxTextStyle pl-lg-0 pl-2">
                      Has No Linkedin Url
                    </span>
                  </Label>
                </FormGroup>
              )}
            </div>
          </Col>
          <Col md="6" sm="12">
            <InputField
              inputtype="text"
              label="Instagram"
              inputid="instaUrl"
              placeholder="@instagram"
              inputRef={register({
                required: {
                  value: false,
                  message: "Instagram handle is required!",
                },
                pattern: {
                  value: INSTAGRAM_HANDLE_REGEX,
                  message: "Instagram handle is not valid!",
                },
              })}
              errMsg={errors?.instaUrl?.message}
            />
          </Col>

          {recordId && (
            <Col lg="4" sm="12" xs="12">
              <FormGroup>
                <Label for="preferredContact">Non-Solicit</Label>
                <div className="d-flex items-center" style={{ marginTop: '5px' }}>
                  <Label className="radio-container">
                    Non-Solicit
                    <input
                      type="checkbox"
                      checked={nonSolicit}
                      ref={register()}
                      name="nonSolicit"
                      onChange={(e) => {
                        setNonSolicit(e.target.checked);
                      }}
                    //       className="mt-1"
                    />
                    <span className="checkmark"></span>
                  </Label>
                </div>
              </FormGroup>
            </Col>)}
        </Row>
      </div>
    </div>
  );
};

export default SocialMediaInfo;
