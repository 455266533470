import { useLazyQuery } from "@apollo/client";
import { useEffect, useState, useContext } from "react";
import {
  Badge,
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Tooltip,
  UncontrolledDropdown,
} from "reactstrap";
import { useHistory } from "react-router";
import GoBack from "../../components/GoBack";
import InputField from "../../components/layout/InputField";
import Loader from "../../components/layout/Loader";
import NoDataFound from "../../components/layout/NoDataFound";
import Pagination from "../../components/layout/Pagination";
import { PAGE_LIMIT, VIEW_CANDIDATE_ROUTE } from "../../utils/constant";
import {
  GET_JOB_APPLICANTS_PIPELINE,
  GET_JOB_ORDER_LIGHT_ON_PIPELINE,
  GET_SINGLE_JOB_APPLICANT_ON_INTERVIEW,
} from "../job-order/gql";
import PipelineWidget from "./PipelineWidget";
import TableComponent from "../../components/layout/TableComponent";
import SEARCH_IMG from "../../assets/images/search.svg";
import { compactObject, formatDateUS } from "../../utils/commonFn";
import { AppContext } from "../../context";
import { useLocation } from "react-router-dom";
import InterviewScheduleModal from "./interviews/InterviewScheduleModal";
import ClientCandidateReasonModal from "./ClientCandidateReasonModal";
import { IComingState } from "../agreements/interfaces";

import classnames from "classnames";

import ACTION_ICON_5 from "../../assets/images/application-icon-5.svg";
import { GET_CANDIDATE_SUMMARY } from "../candidate/gql";
import { countryWiseHeaderLocation } from "../../utils/helper";

const PIPELINE_LISTING_HEADER = [
  "Candidate Name",
  "Current Employer",
  "Candidate Location",
  "Owner",
  "Job App Status",
  "Note",
  "Actions",
];

let time = 0;

export interface ISingleCandidateData {
  name: string;
  jobLocation: string;
  company: string;
  companyId: string;
  jobTitle: string;
  candidateId: string;
  jobApplicantId: string;
  jobOrderId: string;
  jobTimezone: string;
  jobTimezoneId: string;
  jobDstOffset: number;
  workplaceTypes:string
}

export interface IOfferEdit {
  id: string;
  jobApplicantId: string;
  offeredSalary: string;
  hireType: string;
  offeredSalaryType: string;
  offerStartDateTime: string;
  details: string;
}

const CurrentEmployer = ({ item }) => {
  const [tooltipOpen1, setTooltipOpen1] = useState(false);
  const toggle1 = () => setTooltipOpen1(!tooltipOpen1);

  let jobTitle =
    item?.candidate?.jobTitle ||
    item?.candidate?.recentEmployment?.jobTitle ||
    "--";
  let companyName =
    item?.candidate?.companyName ||
    item?.candidate?.recentEmployment?.companyName ||
    "--";

  let currentEmployerText = `${jobTitle} ${
    jobTitle ? "at" : ""
  } ${companyName}`;

  return (
    <>
      <Tooltip
        placement="top"
        isOpen={tooltipOpen1}
        target={`TooltipExample${item?.id}`}
        toggle={toggle1}
      >
        {currentEmployerText}
      </Tooltip>

      <div
        className="textTruncateWidth text-truncate cursor-pointer"
        id={`TooltipExample${item?.id}`}
      >
        {currentEmployerText}
      </div>
    </>
  );
};

const PipelineDetail = ({ match }: any) => {
  const location = useLocation<IComingState>();
  const comingState = location.state;
  const [firstInterview, setFirstInterview] = useState<boolean>(false);
  const page = new URLSearchParams(location.search).get("page") || "1";

  const [offerModal, setOfferModal] = useState<boolean>(false);
  const _currentPage: number = parseInt(page);
  const [jobApplicantId, setJobApplicantId] = useState<string>("");
  const { user, userRoles } = useContext(AppContext);
  const [getSingleJobApplicantQuery, { data: singleCandidateData }] =
    useLazyQuery(GET_SINGLE_JOB_APPLICANT_ON_INTERVIEW);
  const [getCandidateSummary, { data: dataSummary }] = useLazyQuery(
    GET_CANDIDATE_SUMMARY
  );
  const [
    accountManagerAndCandidateRecruiterSame,
    setAccountManagerAndCandidateRecruiterSame,
  ] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState(_currentPage);
  const [limit, setLimit] = useState(10);
  const [applicantData, setApplicantData] = useState<ISingleCandidateData>();

  const [candidateId, setCandidateId] = useState<string>("");
  const [searchParams, setSearchParams] = useState<string>("");
  const [rejectDetail, setRejectDetail] = useState<any>(null);

  const history = useHistory();

  const [jobOrderId, setJobOrderId] = useState("");
  const [notesModal, setNotesModal] = useState(false);

  const [companyId, setCompanyId] = useState("");
  const [, setCompanyOwnerId] = useState("");
  const [companyOwnerShareIds, setCompanyOwnerShareIds] = useState<any>([]);
  const [interviewModal, setInterviewModal] = useState<boolean>(false);
  const [candidateFilter, setCandidateFilter] = useState("All");

  const [getJobOrder, { data }] = useLazyQuery(GET_JOB_ORDER_LIGHT_ON_PIPELINE);

  const [getJobApplicants, { data: jobApplicantsList, loading }] = useLazyQuery(
    GET_JOB_APPLICANTS_PIPELINE
  );

  const onCandidateViewDetails = (item: any) => {
    history.push({
      pathname: `/candidate-submission/${item?.candidate?.id}`,
      search:
        "?" +
        new URLSearchParams({
          applicantId: item.id,
          companyId: companyId,
          jobOrderId,
        }).toString(),
      state: {
        backUrl: `/candidate-pipeline/view/${match?.params?.id}`,
        landedFrom: `pipeline`,
      },
    });
  };

  const offerModalToggle = () => {
    setOfferModal(!offerModal);
  };

  const onScheduleFirstInterview = async (id: string) => {
    getSingleJobApplicantQuery({ variables: { id } });
  };

  useEffect(() => {
    if (data?.getJobOrder) {
      let company = data?.getJobOrder?.company;
      let companyOwnerId: any = company?.ownerAndSharees?.find(
        (item) => item?.ownerType === "OWNER"
      );
      let companyOwnerShareIds = company?.ownerAndSharees?.map(
        (item: any) => item?.owner?.id
      );

      setCompanyId(data?.getJobOrder?.company?.id);
      setCompanyOwnerId(companyOwnerId);
      setCompanyOwnerShareIds(companyOwnerShareIds);
    }
    // eslint-disable-next-line
  }, [data?.getJobOrder]);

  useEffect(() => {
    let id = match.params.id;
    let filter = new URLSearchParams(location.search).get("candidateFilter");
    if (filter) {
      setCandidateFilter(filter);
    }
    setJobOrderId(id);
    if (id) {
      getJobOrder({ variables: { id } });
      getJobApplicants({
        variables: {
          limit,
          page: currentPage,
          id,
          status: filter || candidateFilter,
          searchParams,
        },
      });
    }

    // eslint-disable-next-line
  }, [candidateFilter, limit, currentPage]);

  const setQueryParams = (searchParams: string) => {
    const criteria = compactObject({
      currentPage,
      searchParams,
    });
    const urlQueryParams: any = [];
    if (Object.keys(criteria).length) {
      for (const key in criteria) {
        if (
          Object.prototype.hasOwnProperty.call(criteria, key) &&
          criteria[key]
        ) {
          urlQueryParams.push(`${key}=${criteria[key]}`);
        }
      }
    }
  };

  const searchControl = (value: string) => {
    setQueryParams(value);
    setSearchParams(value);
    setCurrentPage(1);
    setLimit(25);
    if (!value || value?.length > 2) {
      window.clearTimeout(time);
      time = window.setTimeout(() => {
        getJobApplicants({
          variables: {
            limit,
            page: currentPage,
            id: jobOrderId,
            status: candidateFilter,
            searchParams: value,
          },
        });
      }, 1000);
    }
  };

  const onOfferClickDetail = (item: any) => {
    history.push({
      pathname: `/candidate-pipelining/offers/${item?.id}`,
      search: `jobId=${match?.params?.id}&candidate=${item?.candidate?.id}`,
    });
  };

  const onMakeOffer = (item: any) => {
    offerModalToggle();
  };

  const interviewModalToggle = () => {
    setInterviewModal(!interviewModal);
  };

  const onInterviewViewDetails = (item: any) => {
    history.push({
      pathname: `/candidate-pipelining/interview/details`,
      search: `jobId=${match?.params?.id}&candidate=${item?.candidate?.id}&jobApplicantId=${item?.id}`,
    });
  };

  const onDetailAction = (item: any, nestedButton: string) => {
    setJobApplicantId(item?.id);
    setCandidateId(item?.candidate?.id);

    if (nestedButton === "Schedule First Interview") {
      setFirstInterview(true);
    }

    switch (item?.JobAppStatus) {
      case "SUBMITTED":
        switch (nestedButton) {
          case "Submission Detail":
            onCandidateViewDetails(item);
            break;

          case "Schedule First Interview":
            interviewModalToggle();
            getCandidateSummary({ variables: { id: item?.candidate?.id } });
            onScheduleFirstInterview(item?.id);
            break;

          case "Client Decline":
            setRejectDetail({
              ...item,
              ...data,
              type: nestedButton,
            });
            setNotesModal(!notesModal);
            // onDeclineSubmission(item, "Client Decline");
            break;

          case "Candidate Decline":
            setRejectDetail({
              ...item,
              ...data,
              type: nestedButton,
            });
            setNotesModal(!notesModal);
            // onDeclineSubmission(item, "Candidate Decline");
            break;
        }
        break;

      case "INTERVIEWED":
        switch (nestedButton) {
          case "Interview Details":
            onInterviewViewDetails(item);
            break;

          // case "Schedule Next Interview":
          //   onScheduleNextInterviewDetails(item);
          //   break;

          case "Make Offer":
            onMakeOffer(item);
            break;

          case "Client Decline":
            setRejectDetail({
              ...item,
              ...data,
              type: nestedButton,
            });
            setNotesModal(!notesModal);
            // onDeclineSubmission(item, "Client Decline");
            break;

          case "Candidate Decline":
            setRejectDetail({
              ...item,
              ...data,
              type: nestedButton,
            });
            setNotesModal(!notesModal);
            // onDeclineSubmission(item, "Candidate Decline");
            break;
        }
        break;

      case "OFFERED":
        switch (nestedButton) {
          case "Offer Detail":
            onOfferClickDetail(item);
            break;

          // case "Counter Offer":
          //   onCounterOffer(item);
          //   break;

          // case "Make Placement":
          //   onMakePlacement(item);
          //   break;

          case "Client Decline":
            setRejectDetail({
              ...item,
              ...data,
              type: nestedButton,
            });
            setNotesModal(!notesModal);
            // onDeclineSubmission(item, "Client Decline");
            break;

          case "Candidate Decline":
            setRejectDetail({
              ...item,
              ...data,
              type: nestedButton,
            });
            setNotesModal(!notesModal);
            // onDeclineSubmission(item, "Candidate Decline");
            break;
        }
        break;
    }
  };

  const onDeclineViewDetails = (item: any) => {
    onCandidateViewDetails(item);
  };

  useEffect(() => {
    const candidateOwner =
      dataSummary?.getCandidateSummary?.recruitingOwner?.id === user?.id;

    const userIsOwner =
      singleCandidateData?.getSingleJobApplicantOnInterview?.JobOrder?.company
        ?.owner?.id === user?.id;

    if (candidateOwner === userIsOwner) {
      setAccountManagerAndCandidateRecruiterSame(true);
    }

    // eslint-disable-next-line
  }, [dataSummary?.getCandidateSummary]);

  useEffect(() => {
    const actualJobLocation =
      `${singleCandidateData?.getSingleJobApplicantOnInterview?.JobOrder?.companyLocation?.streetAddress}, ${singleCandidateData?.getSingleJobApplicantOnInterview?.JobOrder?.companyLocation?.city}, ${singleCandidateData?.getSingleJobApplicantOnInterview?.JobOrder?.companyLocation?.state}, ${singleCandidateData?.getSingleJobApplicantOnInterview?.JobOrder?.companyLocation?.zipCode}, ${singleCandidateData?.getSingleJobApplicantOnInterview?.JobOrder?.companyLocation?.country}` ||
      "";

    if (singleCandidateData) {
      setApplicantData({
        jobTimezone:
          singleCandidateData?.getSingleJobApplicantOnInterview?.JobOrder
            ?.timezone,
        jobTimezoneId:
          singleCandidateData?.getSingleJobApplicantOnInterview?.JobOrder
            ?.timezoneId,
        jobDstOffset:
          singleCandidateData?.getSingleJobApplicantOnInterview?.JobOrder
            ?.dstOffset,
        jobOrderId:
          singleCandidateData?.getSingleJobApplicantOnInterview?.JobOrder?.id,
        candidateId,
        companyId:
          singleCandidateData?.getSingleJobApplicantOnInterview?.JobOrder
            ?.company?.id,
        jobApplicantId,
        company:
          singleCandidateData?.getSingleJobApplicantOnInterview?.JobOrder
            ?.company?.name || "",
        jobTitle:
          singleCandidateData?.getSingleJobApplicantOnInterview?.JobOrder
            ?.jobTitle || "",
        name:
          singleCandidateData?.getSingleJobApplicantOnInterview?.candidate
            ?.fullName || "",
        jobLocation: actualJobLocation,
        workplaceTypes:singleCandidateData?.JobOrder?.workplaceTypes?singleCandidateData?.JobOrder?.workplaceTypes:""
      });
    }

    // eslint-disable-next-line
  }, [singleCandidateData]);

  const onEditCheck = (
    status: string
    // placementStatus?: string,
    // createdBy?: string
  ): boolean => {
    if (userRoles?.includes("ADMIN") || userRoles?.includes("EXECUTIVE")) {
      return false;
    } else {
      let finalBol = false;
      if (status === "INTERVIEWED" || status === "OFFERED") {
        let ownerShare = data?.getJobOrder?.company?.ownerAndSharees?.map(
          (item) => item?.owner?.id
        );

        finalBol = !ownerShare?.includes(user?.id);
      }

      return finalBol;
    }
  };

  const onFirstInterviewDisableHandler = (item: any): boolean => {
    let finalBol = true;
    if (item?.submission?.status === "COMPLETED") {
      if (userRoles?.includes("ADMIN") || userRoles?.includes("EXECUTIVE")) {
        finalBol = false;
      } else if (companyOwnerShareIds?.includes(user?.id)) {
        finalBol = false;
      }
    }
    return finalBol;
  };

  const adminExecAMCheck = (): boolean => {
    return companyOwnerShareIds?.includes(user?.id) ||
      userRoles?.includes("ADMIN") ||
      userRoles?.includes("EXECUTIVE")
      ? true
      : false;
  };

  const [activeTab, setActiveTab] = useState("1");

  const toggleTab = (tab: string) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  return (
    <div className="admin-tabs team-tabs">
      <GoBack
        url="/candidate-pipeline"
        search={comingState?.searchParameters}
      />

      <div className="primaryHeading mobileResponsiveFlexAlign justify-content-between">
        <h5 className="m-0">Candidate Pipeline Detail</h5>

        <div className="search-filter">
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <img src={SEARCH_IMG} alt="search" />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              type="text"
              placeholder="Search candidate name "
              value={searchParams}
              onChange={(e) => {
                setSearchParams(e.target.value);
                searchControl(e.target.value);
              }}
              className="search-by-company"
            />
          </InputGroup>
        </div>
      </div>

      <PipelineWidget data={data?.getJobOrder} />

      <div className="CandidateTabsWrapper mb-0">
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "1" })}
              onClick={() => {
                toggleTab("1");
                setCandidateFilter("All");
                history.push(
                  `/candidate-pipeline/view/${jobOrderId}?candidateFilter=All`
                );
              }}
            >
              <div className="d-flex align-items-center">
                All{" "}
              </div>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "2" })}
              onClick={() => {
                toggleTab("2");
                setCandidateFilter("Submitted");
                history.push(
                  `/candidate-pipeline/view/${jobOrderId}?candidateFilter=Submitted`
                );
              }}
            >
              <div className="d-flex align-items-center">
                Submissions{" "}
              </div>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "3" })}
              onClick={() => {
                toggleTab("3");
                setCandidateFilter("Interviewed");
                history.push(
                  `/candidate-pipeline/view/${jobOrderId}?candidateFilter=Interviewed`
                );
              }}
            >
              <div className="d-flex align-items-center">
                Interviews{" "}
              </div>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "4" })}
              onClick={() => {
                toggleTab("4");
                setCandidateFilter("Offered");
                history.push(
                  `/candidate-pipeline/view/${jobOrderId}?candidateFilter=Offered`
                );
              }}
            >
              <div className="d-flex align-items-center">
                Offers{" "}
              </div>
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId={activeTab}>
            <div className="candidatePipelineTable">
              <TableComponent tableHeader={PIPELINE_LISTING_HEADER}>
                {loading && (
                  <tbody>
                    <tr>
                      <td colSpan={14}>
                        <Loader loaderClass="sm-height " />
                      </td>
                    </tr>
                  </tbody>
                )}
                {!loading &&
                  !jobApplicantsList?.getApplicants?.jobApplicants?.length && (
                    <tbody>
                      <tr>
                        <td colSpan={14} className="empty-table-td">
                          <NoDataFound text="No Pipeline Details Found!" />
                        </td>
                      </tr>
                    </tbody>
                  )}
                <tbody>
                  {jobApplicantsList?.getApplicants?.jobApplicants?.length
                    ? jobApplicantsList?.getApplicants?.jobApplicants?.map(
                        (item: any, index: number) => {
                          return (
                            <tr key={index} className="job-orders-tbody">
                              <td>
                                <div
                                  onClick={() => {
                                    history.push({
                                      pathname: `${VIEW_CANDIDATE_ROUTE}/${item?.candidate?.id}`,
                                      state: {
                                        backUrl: `/candidate-pipeline/view/${jobOrderId}`,
                                      },
                                    });
                                  }}
                                  className="curser-pointer"
                                >
                                  {item?.candidate?.fullName}
                                  {item?.candidate?.isPrivateCandidate && (
                                    <small className="text-success ml-2">
                                      (Private)
                                    </small>
                                  )}{" "}
                                </div>
                                <div className="subtitleText ellipsisText">
                                  {item?.candidate?.emails?.find(
                                    (email) => email?.isPrimary
                                  )?.email || "--"}
                                </div>
                              </td>

                              <td>
                                <CurrentEmployer item={item} />
                              </td>

                              <td>
                                <div className="ellipsisText">
                               { countryWiseHeaderLocation(item?.candidate?.countryCode,item?.candidate?.city,item?.candidate?.stateCode)}
                                </div>
                              </td>

                              <td>
                                <div className="ellipsisText">
                                  {item?.candidate?.recruiterOwner?.fullName ||
                                    "--"}
                                </div>
                              </td>

                              <td>
                                {item?.JobAppStatus === "SUBMITTED"
                                  ? "SUBMISSION"
                                  : item?.JobAppStatus === "INTERVIEWED"
                                  ? "INTERVIEWS"
                                  : item?.JobAppStatus === "OFFERED"
                                  ? "OFFER"
                                  : item?.JobAppStatus}
                                {item?.JobAppStatus === "SUBMITTED" &&
                                  item?.submission && (
                                    <Badge
                                      className="ml-1"
                                      color={`${
                                        item?.submission?.status === "COMPLETED"
                                          ? "success"
                                          : item?.submission?.status ===
                                            "DECLINE"
                                          ? "danger"
                                          : "info"
                                      }`}
                                    >
                                      {item?.submission?.status === "DRAFT"
                                        ? "PENDING"
                                        : `${item?.submission?.status}`}
                                    </Badge>
                                  )}
                              </td>

                              <td>
                                <div
                                  className="ellipsisText text-wrap"
                                  id="NoteTime"
                                >
                                  {(item?.sentTime &&
                                    `${
                                      item?.submission?.submitWith === "ATS"
                                        ? "Sent at"
                                        : "Manual submitted on"
                                    } (${formatDateUS(
                                      item?.sentTime
                                    )}-${new Date(
                                      item?.sentTime
                                    ).toLocaleTimeString()})`) ||
                                    "--"}
                                </div>
                              </td>

                              <td>
                                <div className="d-flex align-items-center dropdownPositionRelative">
                                  {item?.JobAppStatus === "SUBMITTED" ? (
                                    <>
                                      <UncontrolledDropdown className="customMenuDropdown">
                                        <DropdownToggle className="menuButton">
                                          <img src={ACTION_ICON_5} alt="icon" />
                                        </DropdownToggle>
                                        <DropdownMenu>
                                          <DropdownItem
                                            className="bg-transparent border-0 color-default btn-sm"
                                            onClick={() =>
                                              onDetailAction(
                                                item,
                                                "Submission Detail"
                                              )
                                            }
                                          >
                                            Submission Detail
                                          </DropdownItem>
                                          <DropdownItem
                                            disabled={onFirstInterviewDisableHandler(
                                              item
                                            )}
                                            className={
                                              onFirstInterviewDisableHandler(
                                                item
                                              )
                                                ? "bg-transparent border-0 color-grey btn-sm"
                                                : "bg-transparent border-0 color-default btn-sm"
                                            }
                                            onClick={() =>
                                              onDetailAction(
                                                item,
                                                "Schedule First Interview"
                                              )
                                            }
                                          >
                                            Schedule First Interview
                                          </DropdownItem>

                                          <DropdownItem
                                            disabled={!adminExecAMCheck()}
                                            className={`bg-transparent border-0 btn-sm ${
                                              adminExecAMCheck()
                                                ? "color-default"
                                                : "color-grey"
                                            }`}
                                            onClick={() =>
                                              onDetailAction(
                                                item,
                                                "Client Decline"
                                              )
                                            }
                                          >
                                            Client Decline
                                          </DropdownItem>

                                          <DropdownItem
                                            disabled={!adminExecAMCheck()}
                                            className={`bg-transparent border-0  btn-sm ${
                                              adminExecAMCheck()
                                                ? "color-default"
                                                : "color-grey"
                                            }`}
                                            onClick={() =>
                                              onDetailAction(
                                                item,
                                                "Candidate Decline"
                                              )
                                            }
                                          >
                                            Candidate Decline
                                          </DropdownItem>
                                        </DropdownMenu>
                                      </UncontrolledDropdown>
                                    </>
                                  ) : item?.JobAppStatus ===
                                      "CANDIDATE_DECLINE" ||
                                    item?.JobAppStatus === "CLIENT_DECLINE" ? (
                                    <Button
                                      onClick={() => onDeclineViewDetails(item)}
                                      className="bg-transparent border-0 color-default btn-sm"
                                    >
                                      Submission Detail
                                    </Button>
                                  ) : item?.JobAppStatus === "INTERVIEWED" ? (
                                    <>
                                      <UncontrolledDropdown className="customMenuDropdown">
                                        <DropdownToggle className="menuButton">
                                          <img src={ACTION_ICON_5} alt="icon" />
                                        </DropdownToggle>

                                        <DropdownMenu>
                                          <DropdownItem
                                            className="bg-transparent border-0 color-default btn-sm"
                                            onClick={() =>
                                              onDetailAction(
                                                item,
                                                "Interview Details"
                                              )
                                            }
                                          >
                                            Interview Details
                                          </DropdownItem>

                                          <DropdownItem
                                            disabled={onEditCheck(
                                              item?.JobAppStatus
                                            )}
                                            className={`bg-transparent border-0 btn-sm ${
                                              onEditCheck(item?.JobAppStatus)
                                                ? "color-grey"
                                                : "color-default"
                                            }`}
                                            onClick={() =>
                                              onDetailAction(item, "Make Offer")
                                            }
                                          >
                                            Make Offer
                                          </DropdownItem>

                                          <DropdownItem
                                            disabled={onEditCheck(
                                              item?.JobAppStatus
                                            )}
                                            className={`bg-transparent border-0 btn-sm ${
                                              onEditCheck(item?.JobAppStatus)
                                                ? "color-grey"
                                                : "color-default"
                                            }`}
                                            onClick={() =>
                                              onDetailAction(
                                                item,
                                                "Client Decline"
                                              )
                                            }
                                          >
                                            Client Decline
                                          </DropdownItem>

                                          <DropdownItem
                                            disabled={onEditCheck(
                                              item?.JobAppStatus
                                            )}
                                            className={`bg-transparent border-0 btn-sm ${
                                              onEditCheck(item?.JobAppStatus)
                                                ? "color-grey"
                                                : "color-default"
                                            }`}
                                            onClick={() =>
                                              onDetailAction(
                                                item,
                                                "Candidate Decline"
                                              )
                                            }
                                          >
                                            Candidate Decline
                                          </DropdownItem>
                                        </DropdownMenu>
                                      </UncontrolledDropdown>
                                    </>
                                  ) : item?.JobAppStatus === "OFFERED" ? (
                                    <>
                                      <UncontrolledDropdown className="customMenuDropdown">
                                        <DropdownToggle className="menuButton">
                                          <img src={ACTION_ICON_5} alt="icon" />
                                        </DropdownToggle>
                                        <DropdownMenu>
                                          <DropdownItem
                                            className="bg-transparent border-0 color-default btn-sm"
                                            onClick={() =>
                                              onDetailAction(
                                                item,
                                                "Offer Detail"
                                              )
                                            }
                                          >
                                            Offer Detail
                                          </DropdownItem>

                                          <DropdownItem
                                            disabled={onEditCheck(
                                              item?.JobAppStatus
                                            )}
                                            className={`bg-transparent border-0 btn-sm ${
                                              onEditCheck(item?.JobAppStatus)
                                                ? "color-grey"
                                                : "color-default"
                                            }`}
                                            onClick={() =>
                                              onDetailAction(
                                                item,
                                                "Client Decline"
                                              )
                                            }
                                          >
                                            Client Decline
                                          </DropdownItem>

                                          <DropdownItem
                                            disabled={onEditCheck(
                                              item?.JobAppStatus
                                            )}
                                            className={`bg-transparent border-0 btn-sm ${
                                              onEditCheck(item?.JobAppStatus)
                                                ? "color-grey"
                                                : "color-default"
                                            }`}
                                            onClick={() =>
                                              onDetailAction(
                                                item,
                                                "Candidate Decline"
                                              )
                                            }
                                          >
                                            Candidate Decline
                                          </DropdownItem>
                                        </DropdownMenu>
                                      </UncontrolledDropdown>
                                    </>
                                  ) : null}
                                </div>
                              </td>
                            </tr>
                          );
                        }
                      )
                    : ""}
                </tbody>
              </TableComponent>
            </div>
          </TabPane>
        </TabContent>
      </div>

      {applicantData && interviewModal && (
        <InterviewScheduleModal
          accountManagerAndCandidateRecruiterSame={
            accountManagerAndCandidateRecruiterSame
          }
          setCandidateFilter={setCandidateFilter}
          firstInterview={firstInterview}
          from="pipeline-details"
          candidateId={candidateId}
          interviewScheduleModal={interviewModal}
          applicantData={applicantData}
          interviewScheduleModalToggle={interviewModalToggle}
          reFetchInterviews={() => {}}
          closeModel={() => setInterviewModal(false)}
        />
      )}

      <ClientCandidateReasonModal
        refetch={() => {
          getJobApplicants({
            variables: {
              limit,
              page: currentPage,
              id: match?.params?.id,
              status: candidateFilter,
              searchParams,
            },
          });
        }}
        modal={notesModal}
        toggle={() => {
          setNotesModal(!notesModal);
        }}
        rejectDetail={rejectDetail}
      />

      {jobApplicantsList?.getApplicants?.jobApplicants?.length ? (
        <div className="users-pagination">
          <Pagination
            onPageChange={(pageClicked: number) => {
              setCurrentPage(pageClicked);
            }}
            pageCount={Math.ceil(
              jobApplicantsList?.getApplicants?.count / limit
            )}
            currentPage={currentPage}
          />

          <div className="d-flex align-items-center">
            <div className="pagination-display paginationItemsSelect">
              <p className="m-0">
                {(currentPage - 1) * limit + 1} to{" "}
                {Math.min(
                  currentPage * limit,
                  jobApplicantsList?.getApplicants?.count
                )}{" "}
                of {jobApplicantsList?.getApplicants?.count}
              </p>

              <div className="divider"></div>

              <label>Display</label>

              <InputField
                label=""
                inputtype="select"
                selectItems={PAGE_LIMIT.filter((item) => item.value !== limit)}
                inputid="limit"
                placeholder={limit.toString()}
                inputMethod={(value: string) => {
                  setQueryParams(searchParams);
                  setCurrentPage(1);
                  setLimit(parseInt(value));
                }}
              />
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default PipelineDetail;
