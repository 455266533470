import Select from "react-select";
import { useContext } from "react";
import { AppContext } from "../context";
import { useQuery } from "@apollo/client";
import { FETCHING_NOT_TERMINATED_USERS } from "../Pages/admin/gql";
import { customSelectStyleNew } from "../components/styled/customSelect";

const UserDropdown = ({ candidate = false, placeholder, setUserId, userId }) => {
  const { theme } = useContext(AppContext);
  // Fetch the list of users using the useQuery hook
  const { data: users } = useQuery(FETCHING_NOT_TERMINATED_USERS);

  // Event handler for selecting a user
  const handleSelectChange = (selectedUser) => {
    setUserId(selectedUser?.value)
    candidate && localStorage.setItem('candidateOwnerShipReport-userId', selectedUser?.value)
  };

  // Map the user data to options for the dropdown
  // eslint-disable-next-line array-callback-return
  const userOptions = users?.allActiveUsers
    .filter((user) => user?.userRoles.some((user) => user?.role?.name === "RECRUITER"))
    .map((user) => ({
      value: user.id,
      label: user.fullName,
    }));



  // Render the Select component with the userOptions and event handlers
  return (
    <Select
      options={userOptions}
      value={userId ? userOptions?.find(option => option.value === userId) : null}
      onChange={handleSelectChange}
      placeholder={placeholder}
      className="w-12rem"
      styles={{ ...customSelectStyleNew(theme) }}
    />
  );
};

export default UserDropdown;



