import { FC } from "react"
import { TableStatsProps } from "../../interfaces"
import { DAYS, EARNING_GOAL, PERIOD_END_DATE, START_DATE, WEEKS } from "../../constants"
import { Col, Row } from "reactstrap"

const TableStats: FC<TableStatsProps> = ({
  startDate, endDate, weeks, days, earningGoal
}) => {

  return (
    <Row className="d-flex">
      <Col xl="" md="3" sm='6' xs="12" className="flexBoxes">
        <strong>{startDate}</strong>
        <span className="text-greylight">{START_DATE}</span>
      </Col>

      <Col xl="" md="3" sm='6' xs="12" className="flexBoxes">
        <strong>{endDate}</strong>
        <span className="text-greylight">{PERIOD_END_DATE}</span>
      </Col>

      <Col xl="" md="3" sm='6' xs="12" className="flexBoxes">
        <strong>{weeks}</strong>
        <span className="text-greylight">{WEEKS}</span>
      </Col>

      <Col xl="" md="3" sm='6' xs="12" className="flexBoxes">
        <strong>{days}</strong>
        <span className="text-greylight">{DAYS}</span>
      </Col>

      <Col xl="" md="3" sm='6' xs="12" className="flexBoxes">
        <strong>{earningGoal}</strong>
        <span className="text-greylight">{EARNING_GOAL}</span>
      </Col>
    </Row>)
}
export default TableStats