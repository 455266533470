import React, { useState } from "react";
import dragIcon from "../../../assets/images/drag.png";

import "../candidate.scss";
const DragDropFile = ({ onUpload }) => {
  const [dragging, setDragging] = useState(false);
  const drop: any = React.useRef(null);

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { files } = e.dataTransfer;
    let [file]: any = files;
   
    if (file) {
      onUpload(file);
    }
  };
  const handleSelect = (e) => {
    const { files } = e?.target;
    if(files?.length){
      
      let [file]: any = files;
      if (file) {
        onUpload(file);
      }
    }
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setDragging(false);
  };

  React.useEffect(() => {
    drop?.current?.addEventListener("dragover", handleDragOver);
    drop?.current?.addEventListener("drop", handleDrop);
    drop?.current?.addEventListener("drop", handleSelect);
    drop?.current?.addEventListener("dragenter", handleDragEnter);
    drop?.current?.addEventListener("dragleave", handleDragLeave);

    return () => {
      drop?.current?.removeEventListener("dragover", handleDragOver);
      drop?.current?.removeEventListener("drop", handleDrop);
      drop?.current?.removeEventListener("drop", handleSelect);
      drop?.current?.removeEventListener("dragenter", handleDragEnter);
      // eslint-disable-next-line react-hooks/exhaustive-deps
      drop?.current?.removeEventListener("dragleave", handleDragLeave);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <label
      className={`custom-file-upload`}
      style={{ backgroundColor: dragging ? "#e4dada" : "transparent" }}
      ref={drop}
    >
      <input
        type="file"
        name="candidateResume"
        onChange={(e) => {
          handleSelect(e);
        }}
      />
      <div>
        <div className="d-flex justify-content-center align-items-center mb-2">
          <img src={dragIcon} alt="" width={40} className="d-flex" />
        </div>
        <span
          className={`d-flex text-success : info
          `}
        >
          Custom Upload
        </span>
      </div>
    </label>
  );
};

export default DragDropFile;
