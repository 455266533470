import { Controller, useForm } from "react-hook-form";
import {
  Form,
  FormGroup,
  Label,
} from "reactstrap";
import Select from "react-select";
import { offerCategoryNotes, reactQuillReg } from "../../../utils/constant";
import ReactQuill from "react-quill";
import { toast } from "react-toastify";
import { useMutation } from "@apollo/client";
import { CREATE_NOTES } from "../../company/gql";
import {
  DrawerHeader,
  Sidedrawer,
} from "../../../components/layout/Sidedrawer";
import { useContext } from "react";
import { AppContext } from "../../../context";
import { customSelectDropDownMin } from "../../../components/styled/customSelect";

export default function OfferNote({ modal, toggle, offerId }) {
  const { errors, handleSubmit, control, reset } = useForm();
  const [createNotes] = useMutation(CREATE_NOTES);
  const { theme } = useContext(AppContext);

  const createNote = async (values: any) => {
    values.noteableType = "Offer";
    values.noteableId = offerId;
    values.category = values?.category?.value;
    await createNotes({ variables: { ...values } });
    toast.success("Successfully created!");
    reset();
    toggle();
  };

  const customStyles = {
    control: (styles, provided) => ({
      ...styles,

      fontSize: 12,
    }),

    option: (provided, state) => ({
      ...provided,
      fontSize: 12,
    }),

    container: (provided, state) => ({
      ...provided,
      fontSize: 12,
    }),
  };

  return (
    <Sidedrawer toggle={() => {}} isOpen={modal}>
      <div className="mb-4">
        <DrawerHeader
          prevClick={() => toggle()}
          nextClick={handleSubmit(createNote)}
          buttonText="Create"
          heading="Create New Note"
        />
      </div>

      <Form>
        <FormGroup>
          <Label className="pb-3 custom-form-input form-input">
            Category <span className="text-danger">*</span>
          </Label>
          <Controller
            name="category"
            as={Select}
            options={offerCategoryNotes}
            placeholder="Category"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Category must be selected!",
              },
            }}
            styles={{ ...customSelectDropDownMin(theme, 49) }}
          />
          <small className="text-danger">{errors?.category?.message}</small>
        </FormGroup>
        <FormGroup>
          <Label className="pb-3 custom-form-input form-input">
            Note Detail
          </Label>
          <Controller
            control={control}
            name="content"
            rules={{ required: "Please add some content!" }}
            defaultValue={""}
            render={({ onChange, ref, value = "" }) => (
              <div className="editor-height">
                <ReactQuill
                  onChange={(value) => {
                    if (reactQuillReg.test(value)) {
                      onChange("");
                    } else {
                      onChange(value);
                    }
                  }}
                  value={value}
                  ref={ref}
                />
              </div>
            )}
            styles={customStyles}
          />
          <small className="text-danger pt-2">{errors?.content?.message}</small>
        </FormGroup>
      </Form>
    </Sidedrawer>
  );
}
