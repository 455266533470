import { useLazyQuery, useMutation } from '@apollo/client';
import moment from 'moment';
import { RouteComponentProps } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import Select from 'react-select';
import {
  Badge, DropdownItem, DropdownMenu, DropdownToggle, Input, InputGroup, InputGroupAddon, Dropdown,
  InputGroupText, Modal, ModalBody, Col, Form, FormGroup, Label, ModalFooter, Row, Tooltip,
} from 'reactstrap';
import SEARCH_IMG from '../../assets/images/search.svg';
import InputField from '../../components/layout/InputField';
import Loader from '../../components/layout/Loader';
import NoDataFound from '../../components/layout/NoDataFound';
import Pagination from '../../components/layout/Pagination';
import TableComponent from '../../components/layout/TableComponent';
import { compactObject, formatDateUS } from '../../utils/commonFn';
import {
  ACCOUNTING_DASHBOARD_ROUTE, ACCOUNTING_DASHBOARD_HEADER,
  PAGE_LIMIT, VIEW_CANDIDATE_ROUTE, VIEW_JOB_ORDER_ROUTE, VIEW_ACCOUNTING_DASHBOARD_ROUTE,
  COMPANY_DETAILS_ROUTE, paymentType, STATUS_CODE, COLUMN_INTERFACE_TYPES_For_ACCOUNTING_DASHBOARD, DtoForPlacement, DIRECT_HIRE, ACCOUNTING_INVOICED, PAID,
} from '../../utils/constant';
import { getAccountingDashboardListParams } from '../../utils/getNoteParams';
import {
  defaultDtaeForCalender, maxDate, moneyFormatter, pushInHistory,
} from '../../utils/helper';
import { GET_ALL_PLACMENTS_ACCOUNTING_DASHBOARD, PAID_AT_UPDATE } from './gql';

import MENU_ICON_1 from '../../assets/images/menu-icon-1.svg';
import MENU_ICON_2 from '../../assets/images/menu-icon-2.svg';
import MENU_ICON_3 from '../../assets/images/menu-icon-3.svg';
import MENU_ICON_4 from '../../assets/images/menu-icon-4.svg';
import MoldalForDirectHire from '../candidate-pipeline/placements/view/MoldalForDirectHire';
import { customSelectStyleNew } from '../../components/styled/customSelect';
import { AppContext } from '../../context';
import CustomButton from '../../components/layout/CustomButton';
import { toast } from 'react-toastify';
import { DownArrowIcon } from '../../assets/images/svgs'
import InvoiceStatusDrawer from './InvoiceStatusDrawer';
import InvoiceAttachmentModal from '../../components/invoice-attachments/InvoiceAttachmentModal';
import ConfirmPopUp from '../../components/ConfirmPopUp';


let time = 0;

const AccountingPlacementManagement = ({
  location,
  history,
}: RouteComponentProps) => {
  const defaultColumnFilters = {
    candidateSearch: '',
    jobTitleSearch: '',
    netFeeSearch: null,
    IdSearch: null,
    statusSearch: '',
    placementTypeSearch: '',
    startDateSearch: '',
    placeDateSearch: '',
    paidAtSearch: '',
    accountManagerSearch: '',
    RecruiterSearch: '',
    workLocationSearch: '',
    sorting: {
      columnName: '',
      columnValue: '',
    },
  };
  const fieldNames = [
    'candidateSearch',
    'jobTitleSearch',
    'netFeeSearch',
    'IdSearch',
    'statusSearch',
    'placementTypeSearch',
    'paidAtSearch',
    'startDateSearch',
    'placeDateSearch',
    'accountManagerSearch',
    'RecruiterSearch',
    'workLocationSearch',
  ];
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [columnFilters, setColumnFilters] =
    useState<COLUMN_INTERFACE_TYPES_For_ACCOUNTING_DASHBOARD>(defaultColumnFilters);
  const dropdownRef = useRef(null);
  const { theme } = useContext(AppContext);
  const page = new URLSearchParams(location.search).get('currentPage') || '1';
  const _currentPage: number = parseInt(page);
  const [currentPage, setCurrentPage] = useState(_currentPage);
  const [limit, setLimit] = useState(10);
  const [searchParams, setSearchParams] = useState<string>('');
  const [endDateModal, setEndDateModal] = useState<boolean>(false);
  const [currentDateTimeLocal, setCurrentDateTimeLocal] = useState<string>('');
  const [placementId, setPlacementId] = useState<string>('');
  const [placementStatus, setPlacementStatus] = useState<string>('');
  const [invoiceNumber, setInvoiceNumber] = useState<string>('');
  const [errorMsg, setErrorMsg] = useState({});
  const [isSetTimeOut, setIsSetTime] = useState<boolean>(true);
  const [invoiceDrawerStatus, setInvoiceDrawerStatus] = useState<boolean>(false);
  const [invoiceAttachmentModal, setInvoiceAttachmentModal] = useState<boolean>(false);

  const [myPendingActionsflag, setMyPendingActionsFlag] =
    useState<boolean>(false);
  const [modal, setModal] = useState(false);
  const [paidUpdateDetail] = useMutation(PAID_AT_UPDATE);
  const toggle = () => setModal(!modal);
  const toggleInvoiceStatus = () => setInvoiceDrawerStatus(!invoiceDrawerStatus);
  const toggleInvoiceAttachment = () => setInvoiceAttachmentModal(!invoiceAttachmentModal);
  const toggleDropdown = (index) => {
    if (activeDropdown === index) {
      setActiveDropdown(null);
      setDropdownOpen(false); // close dropdown
    } else {
      setActiveDropdown(index);
      setDropdownOpen(true); // open dropdown
    }
  };

  let {
    location: { state },
  }: any = history || {};
  const [getAllPlacementList, { data, loading, refetch }] = useLazyQuery(
    GET_ALL_PLACMENTS_ACCOUNTING_DASHBOARD
  );
  const { register, errors, setValue, control, watch, handleSubmit } =
    useForm();
  const [activeToolTip, setActiveToolTip] = useState<boolean>(false);

  const [ids, setId] = useState<boolean>(false);
  const isOpenToolTip = (tId) => {
    if (tId === ids) {
      return activeToolTip;
    }
  };
  const toggles = (id) => {
    setId(id);
    setActiveToolTip(!activeToolTip);
  };

  const setQueryParams = (searchParams: string) => {
    let newColumn: COLUMN_INTERFACE_TYPES_For_ACCOUNTING_DASHBOARD = Object.assign(
      {},
      columnFilters
    );
    const { sorting, ...rest } = newColumn;

    const criteria = compactObject({
      currentPage,
      searchParams,
      limit,
      myPendingActionsflag,
      ...rest,
      ...sorting,
    });

    history.push(
      `${ACCOUNTING_DASHBOARD_ROUTE}?${new URLSearchParams(
        criteria
      ).toString()}`
    );
  };
  const customStyles = {
    control: (styles, provided) => ({
      ...styles,

      fontSize: 12,
    }),

    option: (provided, state) => ({
      ...provided,
      fontSize: 12,
    }),

    container: (provided, state) => ({
      ...provided,
      fontSize: 12,
    }),
  };

  const updatePaymentDetails = async (values) => {
    const paidUpdated = await paidUpdateDetail({
      variables: {
        paidUpdateDetails: {
          placementId: placementId,
          paymentType: values?.paymentType?.value,
          paidAt: values?.paidAt,
        },
      },
    });
    if (paidUpdated?.data?.paidUpdateDetails?.status === STATUS_CODE.CODE_200) {
      toggle();
      getPlacements();
      toast.success('Payment Details Updated Successfully!');
    } else {
      toggle();
      getPlacements();
      toast.error('Something Wrong!');
    }
  };


  const getPlacements = useCallback(async () => {
    setLimit(limit);

    if (columnFilters?.netFeeSearch) {
      columnFilters.netFeeSearch = Number(columnFilters.netFeeSearch);
    }
    if (columnFilters?.IdSearch) {
      columnFilters.IdSearch = Number(columnFilters.IdSearch);
    }

    if (columnFilters?.startDateSearch) {
      const startDate = new Date(columnFilters?.startDateSearch); // create a new Date object from the startDateSearch string
      columnFilters.startDateSearch = startDate.toISOString().substring(0, 10); // extract the "YYYY-MM-DD" part of the ISO string
    }

    if (columnFilters?.placeDateSearch) {
      const placeDate = new Date(columnFilters?.placeDateSearch); // create a new Date object from the startDateSearch string
      columnFilters.placeDateSearch = placeDate.toISOString().substring(0, 10); // extract the "YYYY-MM-DD" part of the ISO string
    }
    if (columnFilters?.paidAtSearch) {
      const paidAtDate = new Date(columnFilters?.paidAtSearch); // create a new Date object from the startDateSearch string
      columnFilters.paidAtSearch = paidAtDate.toISOString().substring(0, 10); // extract the "YYYY-MM-DD" part of the ISO string
    }

    if (isSetTimeOut) {
      setQueryParams(searchParams);
      getAllPlacementList({
        variables: {
          page: currentPage,
          limit,
          searchText: searchParams,
          myPendingActions: myPendingActionsflag,
          columnFilters: columnFilters,
        },
      });
    } else {
      setQueryParams(searchParams);
      window.clearTimeout(time);
      time = window.setTimeout(async () => {
        getAllPlacementList({
          variables: {
            page: currentPage,
            limit,
            searchText: searchParams,
            myPendingActions: myPendingActionsflag,
            columnFilters: columnFilters,
          },
        });
      }, 1000);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentPage,
    limit,
    myPendingActionsflag,
    state?.pendingAction,
    columnFilters,
    searchParams
  ]);


  useEffect(() => {
    getPlacements();
    // eslint-disable-next-line
  }, [getPlacements]);

  useEffect(() => {
    document.title = 'Accounting Dashboard - RealREPP';

    let params: any = getAccountingDashboardListParams(location.search);

    if (params) {
      setCurrentPage(params?.currentPage ? parseInt(params?.currentPage) : 1);
      setSearchParams(params?.searchParams);
      setLimit(params?.limit ? parseInt(params?.limit) : 10);
      state &&
        state?.pendingAction &&
        setMyPendingActionsFlag(state?.pendingAction as boolean);
      if (Object.entries(params?.columnFilters)?.length) {
        if (Object.entries(params?.columnFilters)?.length === 1) {
          setColumnFilters((prevFilters) => ({
            ...prevFilters,
            [Object.keys(params?.columnFilters)[0]]: Object.values(
              params?.columnFilters
            )[0],
          }));
        } else {
          const updatedFilters = { ...columnFilters };
          Object.entries(params?.columnFilters).forEach(([key, value]) => {
            updatedFilters[key] = value;
          });
          setColumnFilters(updatedFilters);
        }
      }
    }

    // eslint-disable-next-line
  }, [state?.pendingAction]);

  const changeFiled = (fieldName, value) => {
    if (fieldName?.endsWith('Sort')) {
      const columnName = fieldName.replace('Sort', '');
      setIsSetTime(true)
      setColumnFilters((prevFilters) => {
        const { sorting, ...otherFilters } = prevFilters;
        return {
          ...otherFilters,
          sorting: {
            columnName: columnName,
            columnValue: value ? 'ASC' : 'DESC',
          },
        };
      });
    } else {
      if ((fieldName === 'startDateSearch' || 'placeDateSearch' || 'paidAtSearch') && !value) {
        value = null;
      }
      setCurrentPage(1);
      setLimit(limit);
      setIsSetTime(false)
      setColumnFilters((prevFilters) => ({
        ...prevFilters,
        [fieldName]: value,
      }));
    }
  };

  // const preDefinedData = async (placmentID) => {
  //   const placement =
  //     await data?.allPlacementListAccountingDashboard?.placements.find(
  //       (placement) => placement?.id === placmentID
  //     );
  //   const selectedType = paymentType.find(
  //     (type) => type?.value === placement?.paymentType
  //   );
  //   const paidDate = new Date(placement?.paidAt);
  //   const inputDate = moment(paidDate).format('YYYY-MM-DD');
  //   setValue('paymentType', selectedType);
  //   setValue('paidAt', inputDate);
  // };

  useEffect(() => {
    setCurrentDateTimeLocal(defaultDtaeForCalender());

    // eslint-disable-next-line
  }, []);
  return (
    <div className="admin-tabs team-tabs">
      <div className="primaryHeaderSpacing primaryHeading">
        <h5 className="m-0">Accounting Dashboard</h5>
      </div>

      <div className="search-filter accountingDashboardFilter primaryHeaderSpacing">
        <div>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <img src={SEARCH_IMG} alt="search" />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              type="text"
              placeholder="Search by Company Name"
              value={searchParams}
              onChange={(e) => {
                setSearchParams(e.target.value);
                setIsSetTime(false)

              }}
              className="search-by-company"
            />
          </InputGroup>
        </div>

        <div className="filter-status">
          <div className="filter-by">
            <div>
              <button
                style={{
                  backgroundColor: `${myPendingActionsflag ? '#f4f3f3' : 'white'
                    }`,
                  color: `${myPendingActionsflag ? '#eaa827' : '#495057'}`,
                  border: `${'1px solid #ced4da'}`,
                  marginRight: `${'1rem'}`,
                }}
                className="filter-job-order mr-3 w-100"
                onClick={() => {
                  setMyPendingActionsFlag(!myPendingActionsflag);
                  setQueryParams(searchParams);
                  setCurrentPage(1);
                  setLimit(25);
                }}
                type="button"
              >
                My Pending Actions
              </button>
            </div>
            <button
              className="buttonGenericStyle filledButton ml-3"
              onClick={() => {
                if (columnFilters?.sorting?.columnName) {
                  setValue(columnFilters.sorting.columnName, false);
                }
                setSearchParams('');
                setIsSetTime(true)
                setMyPendingActionsFlag(false);
                setColumnFilters(defaultColumnFilters);
                setErrorMsg({});
                fieldNames.forEach((fieldName) => {
                  setValue(fieldName, defaultColumnFilters[fieldName]);
                });
              }}
            >
              Clear
            </button>
          </div>
        </div>
      </div>

      <div
        className="tablePlacementResponsive accountDashboard-Table candidatePipelineTable minimize-pading-from-table"
      >
        <TableComponent
          advanceSearching={true}
          tableHeaderAdvSeacrh={ACCOUNTING_DASHBOARD_HEADER}
          handleChange={(filedName, value) => changeFiled(filedName, value)}
          columnFilters={columnFilters}
          register={register}
          setValue={setValue}
          control={control}
          tableName="Accounting"
          setErrorMsg={setErrorMsg}
          errorMsg={errorMsg}
        >
          {loading && (
            <tbody>
              <tr>
                <td colSpan={13}>
                  <Loader loaderClass="sm-height " />
                </td>
              </tr>
            </tbody>
          )}
          {!loading &&
            !data?.allPlacementListAccountingDashboard?.placements?.length && (
              <tbody>
                <tr>
                  <td colSpan={13} className="empty-table-td">
                    <NoDataFound text="No Placement List Found!" />
                  </td>
                </tr>
              </tbody>
            )}
          <tbody>
            {!loading &&
              data &&
              data?.allPlacementListAccountingDashboard &&
              data?.allPlacementListAccountingDashboard?.placements?.map(
                (item: any, index: number) => {
                  console.log("placement : ", item)
                  const mostRecentActivityReport = item?.invoiceAttachment?.length ? item.invoiceAttachment.reduce((latest, record) =>
                    record.updatedAt > latest.updatedAt ? record : latest
                  )?.activityReport : '';
                  return (
                    <tr key={index}>
                      <td>
                        <div className=' p-0 ml-2 d-flex align-items-center'>
                          {item?.placementId}
                          <span className='ml-1'>
                            <Dropdown
                              isOpen={activeDropdown === index && dropdownOpen}
                              toggle={() => toggleDropdown(index)}
                              innerRef={dropdownRef}
                            >
                              <DropdownToggle className="menuButton dark-btn">
                                <DownArrowIcon />
                              </DropdownToggle>
                              <DropdownMenu
                                modifiers={{
                                  setMaxHeight: {
                                    enabled: true,
                                    fn: (data) => {
                                      return {
                                        ...data,
                                        styles: {
                                          ...data.styles,
                                          overflow: "auto",
                                          maxHeight: "100vh",
                                        },
                                      };
                                    },
                                  },
                                }}
                                container={document.body}
                                target={() => dropdownRef.current}
                                isOpen={dropdownOpen}
                                toggle={toggle}
                              >
                                <DropdownItem
                                  className="tableMenuItem"
                                  onClick={() => {
                                    history.push({
                                      pathname: `${VIEW_ACCOUNTING_DASHBOARD_ROUTE}/${item.id}`,
                                      state: {
                                        searchParameters: location.search,
                                      },
                                    });
                                  }}
                                >
                                  <img src={MENU_ICON_1} alt="icon" />
                                  View Details
                                </DropdownItem>

                                {(item?.placementStatus ===
                                  'ACCOUNTING_APPROVED' ||
                                  item?.placementStatus ===
                                  'ACCOUNTING_INVOICED' ||
                                  item?.placementStatus ===
                                  'PAID') && (
                                    <>
                                      <DropdownItem
                                        disabled={false}
                                        className="tableMenuItem"
                                        onClick={() => {
                                          history.push({
                                            pathname: `${VIEW_ACCOUNTING_DASHBOARD_ROUTE}/${item?.id}/billingsheet`,
                                          });
                                        }}
                                      >
                                        <img src={MENU_ICON_2} alt="icon" />
                                        Billing Sheet
                                      </DropdownItem>
                                      {item?.placementType === DIRECT_HIRE &&
                                        <>
                                          <DropdownItem
                                            className="tableMenuItem"
                                            onClick={() => {
                                              setPlacementId(item?.id)
                                              setInvoiceNumber(item?.invoiceNumber)
                                              toggleInvoiceStatus()
                                            }}
                                          >
                                            <img src={MENU_ICON_3} alt="icon" />
                                            Invoice Status
                                          </DropdownItem>
                                          {/* <DropdownItem
                                            className="tableMenuItem"
                                            onClick={() => {
                                              setPlacementId(item?.id)
                                              setPlacementStatus(item?.placementStatus)
                                              toggleInvoiceAttachment()
                                            }}
                                          >
                                            <img src={MENU_ICON_3} alt="icon" />
                                            Add Attachment
                                          </DropdownItem> */}
                                        </>
                                      }
                                      {item?.placementType === 'CONTRACT' && (
                                        <DropdownItem
                                          className="tableMenuItem"
                                          onClick={() => {
                                            history.push(
                                              `${VIEW_ACCOUNTING_DASHBOARD_ROUTE}/${item?.id}/newassignment`
                                            );
                                          }}
                                        >
                                          <img src={MENU_ICON_2} alt="icon" />
                                          New Assignment
                                        </DropdownItem>
                                      )}

                                      {/* <DropdownItem
                                        className="tableMenuItem"
                                        onClick={() => {
                                          item?.isPaid && preDefinedData(item?.id);
                                          setPlacementId(item?.id);
                                          toggle();
                                        }}
                                      >
                                        <img src={MENU_ICON_4} alt="icon" />
                                        Paid Info
                                      </DropdownItem> */}
                                    </>
                                  )}
                                <DropdownItem
                                  className="tableMenuItem"
                                  onClick={() => { }}
                                >
                                  <img src={MENU_ICON_3} alt="icon" />
                                  Create Invoice
                                </DropdownItem>
                                <DropdownItem
                                  className="tableMenuItem"
                                  onClick={() => { }}
                                >
                                  <img src={MENU_ICON_4} alt="icon" />
                                  Pass Due
                                </DropdownItem>

                                {(item?.placementStatus ===
                                  'ACCOUNTING_APPROVED' ||
                                  item?.placementStatus ===
                                  ACCOUNTING_INVOICED ||
                                  item?.placementStatus ===
                                  PAID ||
                                  item?.placementStatus ===
                                  'PENDING_ACCOUNTING') && (
                                    <DropdownItem
                                      disabled={false}
                                      className="tableMenuItem"
                                      onClick={() => {
                                        setEndDateModal(true);
                                        setPlacementId(item.id);
                                      }}
                                    >
                                      <img src={MENU_ICON_2} alt="icon" />
                                      Cancel
                                    </DropdownItem>
                                  )}
                              </DropdownMenu>
                            </Dropdown>

                          </span>

                        </div>
                        <div className="subtitleText" style={{ color: '#2e95b4e6' }}>{mostRecentActivityReport && `(${moment(mostRecentActivityReport).format('MMM-YY').toUpperCase()})`}</div>

                      </td>

                      <td className="cursor-pointer">
                        <div className="d-flex align-items-center">
                          <div
                            className="anchorColorText route-link text-truncate max-200"
                            onClick={() => {
                              history.push({
                                pathname: `${VIEW_CANDIDATE_ROUTE}/${item?.candidateId}`,
                                state: {
                                  backUrl: ACCOUNTING_DASHBOARD_ROUTE,
                                  searchParameters: location.search,
                                },
                              });

                              pushInHistory(
                                ACCOUNTING_DASHBOARD_ROUTE,
                                location.search
                              );
                            }}
                          >
                            {item?.candidateName}
                          </div>
                        </div>
                      </td>

                      <td>
                        {!!item?.jobTitle ? (
                          <div
                            className="route-link text-truncate max-200"
                            onClick={() => {
                              history.push({
                                pathname: `${VIEW_JOB_ORDER_ROUTE}/${item?.jobOrderOfPlacement?.id}`,
                                state: {
                                  backUrl: ACCOUNTING_DASHBOARD_ROUTE,
                                  searchParameters: location.search,
                                },
                              });
                              pushInHistory(
                                ACCOUNTING_DASHBOARD_ROUTE,
                                location.search
                              );
                            }}
                          >
                            {item?.jobTitle}
                          </div>
                        ) : (
                          '--'
                        )}
                        <div
                          className="route-link subtitleText text-truncate max-200"
                          onClick={() => {
                            history.push({
                              pathname: `${COMPANY_DETAILS_ROUTE}/${item?.jobOrderOfPlacement?.companyId}`,
                              state: {
                                backUrl: ACCOUNTING_DASHBOARD_ROUTE,
                                searchParameters: location.search,
                              },
                            });

                            pushInHistory(
                              ACCOUNTING_DASHBOARD_ROUTE,
                              location.search
                            );
                          }}
                        >
                          {item?.companyName}
                        </div>
                      </td>

                      <td>{moneyFormatter(item?.netCommission || 0)}</td>

                      <td>
                        {item?.startDateString
                          ? formatDateUS(moment(item?.startDateString).format())
                          : '--'}
                      </td>
                      <td>
                        {item?.createdAt ? formatDateUS(item?.createdAt) : '--'}
                      </td>
                      <td>{item?.placementType}</td>

                      <td className='badge-column'>
                        <Badge
                          color={
                            item?.placementStatus === 'ACCOUNTING_APPROVED' ||
                              item?.placementStatus === 'ACCOUNTING_CONFIRMED' ||
                              item?.placementStatus === 'PAID' ||
                              item?.placementStatus === 'ACCOUNTING_INVOICED'
                              ? 'success'
                              : item?.placementStatus === 'PROSPECT'
                                ? 'warning'
                                : item?.placementStatus === 'DRAFT' ||
                                  item?.placementStatus ===
                                  'ASSIGNMENT_COMPLETED' ||
                                  item?.placementStatus === 'ASSIGNMENT_CONVERTED'
                                  ? 'primary'
                                  : item?.placementStatus === 'REJECTED_MANAGER' ||
                                    item?.placementStatus === 'REJECTED_AM' ||
                                    item?.placementStatus ===
                                    'REJECTED_ACCOUNTING' ||
                                    item?.placementStatus ===
                                    'REJECTED_RECRUITER' ||
                                    item?.placementStatus ===
                                    'PLACEMENT_CANCELLED' ||
                                    item?.placementStatus === 'ASSIGNMENT_CANCELLED'
                                    ? 'danger'
                                    : item?.placementStatus === 'PENDING_MANAGER' ||
                                      item?.placementStatus === 'PENDING_AM' ||
                                      item?.placementStatus === 'PENDING_RECRUITER' ||
                                      item?.placementStatus === 'PENDING_ACCOUNTING'
                                      ? 'info'
                                      : 'primary'
                          }
                          className="text-capitalize"
                        >
                          {item?.placementStatus ? DtoForPlacement[item?.placementStatus] : '--'}
                        </Badge>
                      </td>
                      {
                        item?.isPaid && (
                          <Tooltip
                            placement="top"
                            isOpen={isOpenToolTip(item.id)}
                            target={`tooltip-${item.id}`}
                            toggle={(e) => {
                              toggles(item.id);
                            }}
                          >
                            <p>
                              {item.paymentType}
                            </p>
                          </Tooltip>

                        )
                      }

                      {item?.isPaid ? (
                        <td id={`tooltip-${item.id}`}>{item?.paidAtString}</td>
                      ) : (
                        <td>--</td>
                      )}

                      <td>{item?.accountManager?.fullName || '--'}</td>
                      <td>{item?.recruiter?.fullName || '--'}</td>
                      <td className="text-truncate max-200">
                        {['US', null, undefined, ''].includes(
                          item?.jobCountryCode
                        )
                          ? item?.jobCity
                            ? item?.jobCity + `, ` + item?.jobStateCode
                            : item?.jobStateCode
                          : item?.jobCity
                            ? item?.jobCity + `, ` + item?.jobCountryCode
                            : item?.jobCountryCode}
                      </td>
                    </tr>
                  );
                }
              )}
          </tbody>
        </TableComponent>
      </div>

      {data?.allPlacementListAccountingDashboard?.placements.length ? (
        <div className="users-pagination">
          <Pagination
            onPageChange={(pageClicked: number) => {
              setIsSetTime(true)
              setQueryParams(searchParams);
              setCurrentPage(pageClicked);
            }}
            pageCount={Math.ceil(
              data?.allPlacementListAccountingDashboard?.count / limit
            )}
            currentPage={currentPage}
          />

          <div className="d-flex align-items-center">
            <div className="pagination-display paginationItemsSelect">
              <p className="m-0">
                {(currentPage - 1) * limit + 1} to{' '}
                {Math.min(
                  currentPage * limit,
                  data?.allPlacementListAccountingDashboard?.count
                )}{' '}
                of {data?.allPlacementListAccountingDashboard?.count}
              </p>

              <div className="divider"></div>

              <label>Display</label>

              <InputField
                label=""
                inputtype="select"
                selectItems={PAGE_LIMIT}
                inputid="limit"
                placeholder={limit.toString()}
                inputMethod={(value: string) => {
                  setIsSetTime(true)
                  setQueryParams(searchParams);
                  setCurrentPage(1);
                  setLimit(parseInt(value));
                }}
              />
            </div>
          </div>
        </div>
      ) : null}
      {/* {error?.graphQLErrors[0]?.message} */}

      <MoldalForDirectHire
        endDateModal={endDateModal}
        setEndDateModal={setEndDateModal}
        placementId={placementId}
        refetch={refetch}
        AccountingCancel={true}
        history={history}
      />

      <Modal
        isOpen={modal}
        toggle={toggle}
        className="revampDialog revampDialogWidth"
      >
        <div className="d-flex justify-content-between px-4 py-3">
          <h5>Update Payment Details</h5>
          <div></div>
        </div>

        <Form>
          <ModalBody>
            <Row>
              <Col>
                <FormGroup>
                  <Label className="text-14 text-blackele">
                    Paid At <span className="text-danger">*</span>
                  </Label>
                  <Input
                    defaultValue={currentDateTimeLocal}
                    max={maxDate()}
                    type="date"
                    name="paidAt"
                    innerRef={register({
                      required: {
                        value: true,
                        message: 'Paid at  is required!',
                      },
                    })}
                  />
                  <small className="text-danger">
                    {errors?.paidAt?.message}
                  </small>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label>
                    Payment Type <span className="text-danger">*</span>
                  </Label>
                  <Controller
                    name="paymentType"
                    rules={{
                      required: {
                        value: true,
                        message: 'Must Select paymentType Types!',
                      },
                    }}
                    placeHolder="Select paymentType Type"
                    render={() => (
                      <Select
                        options={paymentType}
                        value={watch()?.paymentType}
                        onChange={(object) => {
                          setValue('paymentType', object);
                        }}
                        styles={{ ...customSelectStyleNew(theme) }}
                      />
                    )}
                    control={control}
                    styles={customStyles}
                  />

                  <small className="text-danger">
                    {errors?.paymentType?.message}
                  </small>
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>

          <ModalFooter>
            <button
              type="button"
              onClick={toggle}
              className="buttonPrimary mr-3"
            >
              Cancel
            </button>
            <CustomButton
              buttonText="Update"
              buttonColor="primary"
              buttonType="submit"
              className="big-width"
              buttonClick={handleSubmit(updatePaymentDetails)}
              loading={loading}
              disabled={loading}
            />
          </ModalFooter>
        </Form>
      </Modal>

      <InvoiceStatusDrawer
        invoiceNumber={invoiceNumber}
        placementId={placementId!}
        isOpen={invoiceDrawerStatus}
        isEditButton={false}
        refetchData={refetch}
        toggle={toggleInvoiceStatus}
      />

      <InvoiceAttachmentModal isOpen={invoiceAttachmentModal} toggle={toggleInvoiceAttachment} placementId={placementId!} placementStatus={placementStatus} />
    </div>
  );
};

export default AccountingPlacementManagement;
