import { Link, RouteComponentProps } from 'react-router-dom';
import Loader from '../../components/layout/Loader';
import TableComponent from '../../components/layout/TableComponent';
import Pagination from '../../components/layout/Pagination';
import {
  Badge,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap';
import InputField from '../../components/layout/InputField';
import {
  COLUMN_INTERFACE_TYPES_For_PLACEMENT,
  PAGE_LIMIT,
  PLACEMENT_MANAGEMENT_ROUTE,
  PLACEMENT_MANAGEMENT_HEADER,
  VIEW_CANDIDATE_ROUTE,
  VIEW_JOB_ORDER_ROUTE,
  VIEW_PLACEMENT_MANAGEMENT_ROUTE,
  COMPANY_DETAILS_ROUTE,
  DtoForPlacement,
} from '../../utils/constant';
import SEARCH_IMG from '../../assets/images/search.svg';
import NoDataFound from '../../components/layout/NoDataFound';
import { compactObject, formatDateUS } from '../../utils/commonFn';
import { useCallback, useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { GET_ALL_PLACMENTS_MANAGEMENT_DASHBOARD } from './gql';
import moment from 'moment';
import { getPlacementManagementParams } from '../../utils/getNoteParams';
import {
  countryWiseHeaderLocation,
  moneyFormatter,
  pushInHistory,
} from '../../utils/helper';

import './placement.scss';
import { useForm } from 'react-hook-form';

let time = 0;

const PlacementManagement = ({ location, history }: RouteComponentProps) => {
  const page = new URLSearchParams(location.search).get('currentPage') || '1';
  const { register, setValue, control } = useForm();
  const _currentPage: number = parseInt(page);
  const [currentPage, setCurrentPage] = useState(_currentPage);
  const [limit, setLimit] = useState<number>(10);
  const fieldNames = [
    'candidateSearch',
    'jobTitleSearch',
    'netFeeSearch',
    'statusSearch',
    'placementTypeSearch',
    'startDateSearch',
    'placeDateSearch',
    'accountManagerSearch',
    'RecruiterSearch',
    'workLocationSearch',
    'IdSearch'
  ];
  const defaultColumnFilters = {
    candidateSearch: '',
    jobTitleSearch: '',
    netFeeSearch: null,
    IdSearch: null,
    statusSearch: '',
    placementTypeSearch: '',
    startDateSearch: '',
    placeDateSearch: '',
    accountManagerSearch: '',
    RecruiterSearch: '',
    workLocationSearch: '',
    sorting: {
      columnName: '',
      columnValue: '',
    },
  };
  const [columnFilters, setColumnFilters] =
    useState<COLUMN_INTERFACE_TYPES_For_PLACEMENT>(defaultColumnFilters);
  const [isSetTimeOut, setIsSetTime] = useState<boolean>(true);


  const [errorMsg, setErrorMsg] = useState({});

  const [searchParams, setSearchParams] = useState<string>('');
  const [getAllPlacementList, { data, loading }] = useLazyQuery(
    GET_ALL_PLACMENTS_MANAGEMENT_DASHBOARD
  );
  const [myPendingActionsFlag, setMyPendingActionsFlag] =
    useState<boolean>(false);

  let {
    location: { state },
  }: any = history || {};

  const setQueryParams = (searchParams: string) => {
    let newColumn: COLUMN_INTERFACE_TYPES_For_PLACEMENT = Object.assign(
      {},
      columnFilters
    );
    const { sorting, ...rest } = newColumn;

    const criteria = compactObject({
      currentPage,
      searchParams,
      limit,
      myPendingActionsFlag,
      ...rest,
      ...sorting,
    });
    const urlQueryParams: any = [];
    if (Object.keys(criteria).length) {
      for (const key in criteria) {
        if (
          Object.prototype.hasOwnProperty.call(criteria, key) &&
          criteria[key]
        ) {
          urlQueryParams.push(`${key}=${criteria[key]}`);
        }
      }
      history.push(`${PLACEMENT_MANAGEMENT_ROUTE}?${urlQueryParams.join('&')}`);
    }
  };

  useEffect(() => {
    document.title = 'Placement Management - RealREPP';

    let params: any = getPlacementManagementParams(location.search);
    if (params) {
      setCurrentPage(params?.currentPage ? parseInt(params?.currentPage) : 1);
      setSearchParams(params?.searchParams);
      setLimit(params?.limit ? parseInt(params?.limit) : 10);
      state &&
        state?.pendingAction &&
        setMyPendingActionsFlag(state?.pendingAction as boolean);
    }

    if (Object.entries(params?.columnFilters)?.length) {
      if (Object.entries(params?.columnFilters)?.length === 1) {
        setColumnFilters((prevFilters) => ({
          ...prevFilters,
          [Object.keys(params?.columnFilters)[0]]: Object.values(
            params?.columnFilters
          )[0],
        }));
      } else {
        const updatedFilters = { ...columnFilters };
        Object.entries(params?.columnFilters).forEach(([key, value]) => {
          updatedFilters[key] = value;
        });
        setColumnFilters(updatedFilters);
      }
    }

    // eslint-disable-next-line
  }, [state?.pendingAction]);
  const changeFiled = (fieldName, value) => {
    if (fieldName?.endsWith('Sort')) {
      const columnName = fieldName.replace('Sort', '');
      setIsSetTime(true)
      setColumnFilters((prevFilters) => {
        const { sorting, ...otherFilters } = prevFilters;
        return {
          ...otherFilters,
          sorting: {
            columnName: columnName,
            columnValue: value ? 'ASC' : 'DESC',
          },
        };
      });
    } else {
      if ((fieldName === 'startDateSearch' || 'placeDateSearch') && !value) {
        value = null;
      }
      setCurrentPage(1);
      setLimit(limit);
      setIsSetTime(false)
      setColumnFilters((prevFilters) => ({
        ...prevFilters,
        [fieldName]: value,
      }));
    }
  };
  const getPlacements = useCallback(async () => {
    setLimit(limit);
    if (columnFilters?.netFeeSearch) {
      columnFilters.netFeeSearch = Number(columnFilters.netFeeSearch);
    }
    if (columnFilters?.IdSearch) {
      columnFilters.IdSearch = Number(columnFilters.IdSearch);
    }

    if (columnFilters?.startDateSearch) {
      const startDate = new Date(columnFilters?.startDateSearch); // create a new Date object from the startDateSearch string
      columnFilters.startDateSearch = startDate.toISOString().substring(0, 10); // extract the "YYYY-MM-DD" part of the ISO string
    }

    if (columnFilters?.placeDateSearch) {
      const placeDate = new Date(columnFilters?.placeDateSearch); // create a new Date object from the startDateSearch string
      columnFilters.placeDateSearch = placeDate.toISOString().substring(0, 10); // extract the "YYYY-MM-DD" part of the ISO string
    }

    if (isSetTimeOut) {
      setQueryParams(searchParams);
      getAllPlacementList({
        variables: {
          page: currentPage,
          limit,
          searchText: searchParams,
          myPendingActions: myPendingActionsFlag,
          columnFilters: columnFilters,
        },
      });
    } else {
      setQueryParams(searchParams);
      window.clearTimeout(time);
      time = window.setTimeout(async () => {
        getAllPlacementList({
          variables: {
            page: currentPage,
            limit,
            searchText: searchParams,
            myPendingActions: myPendingActionsFlag,
            columnFilters: columnFilters,
          },
        });
      }, 1000);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentPage,
    myPendingActionsFlag,
    state?.pendingAction,
    limit,
    columnFilters,
    searchParams,
  ]);


  useEffect(() => {
    getPlacements();
    // eslint-disable-next-line
  }, [getPlacements]);

  return (
    <div className="admin-tabs team-tabs">
      <div className="primaryHeading">
        <h5 className="m-0">Placement Management</h5>
      </div>

      <div className="search-filter primaryHeaderSpacing">
        <div>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <img src={SEARCH_IMG} alt="search" />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              type="text"
              placeholder="Search by Company"
              value={searchParams}
              onChange={(e) => {
                setSearchParams(e.target.value);
                setIsSetTime(false)
              }}
              className="search-by-company"
            />
          </InputGroup>
        </div>
        <div className="filter-status">
          <div className="filter-by">
            <div>
              <button
                style={{
                  backgroundColor: `${myPendingActionsFlag ? '#f4f3f3' : 'white'
                    }`,
                  color: `${myPendingActionsFlag ? '#eaa827' : '#495057'}`,
                  border: `${'1px solid #ced4da'}`,
                  marginRight: `${'1rem'}`,
                }}
                className="filter-job-order mr-3"
                onClick={() => {
                  setMyPendingActionsFlag(!myPendingActionsFlag);
                  setQueryParams(searchParams);
                  setCurrentPage(1);
                  setLimit(25);
                }}
                type="button"
              >
                My Pending Actions
              </button>
            </div>

            <button
              className="buttonGenericStyle filledButton ml-3"
              onClick={() => {
                if (columnFilters?.sorting.columnName) {
                  setValue(columnFilters?.sorting.columnName, false);
                }
                setSearchParams('');
                setIsSetTime(true)
                setMyPendingActionsFlag(false);
                setErrorMsg({});
                setColumnFilters(defaultColumnFilters);
                fieldNames.forEach((fieldName) => {
                  setValue(fieldName, defaultColumnFilters[fieldName]);
                });
              }}
            >
              Clear
            </button>
          </div>
        </div>
      </div>

      <div className="candidatePipelineTable minimize-pading-from-table">
        <TableComponent
          advanceSearching={true}
          tableHeaderAdvSeacrh={PLACEMENT_MANAGEMENT_HEADER}
          handleChange={(filedName, value) => changeFiled(filedName, value)}
          columnFilters={columnFilters}
          register={register}
          setValue={setValue}
          control={control}
          tableName="Placement"
          setErrorMsg={setErrorMsg}
          errorMsg={errorMsg}

        >
          {loading && (
            <tbody>
              <tr>
                <td colSpan={12}>
                  <Loader loaderClass="sm-height " />
                </td>
              </tr>
            </tbody>
          )}

          {!loading &&
            !data?.allPlacementListManagementPage?.placements?.length && (
              <tbody>
                <tr>
                  <td colSpan={12} className="empty-table-td">
                    <NoDataFound text="No Placement List Found!" />
                  </td>
                </tr>
              </tbody>
            )}
          <tbody>
            {!loading &&
              data &&
              data?.allPlacementListManagementPage &&
              data?.allPlacementListManagementPage?.placements?.map(
                (item: any, index: number) => {
                  const mostRecentActivityReport = item?.invoiceAttachment?.length ? item.invoiceAttachment.reduce((latest, record) =>
                    record.updatedAt > latest.updatedAt ? record : latest
                  )?.activityReport : '';

                  return (
                    <tr key={index}>
                      <td className="cursor-pointer">
                        <div
                          onClick={() => {
                            history.push({
                              pathname: `${VIEW_PLACEMENT_MANAGEMENT_ROUTE}/${item?.id}`,
                              state: {
                                backUrl: PLACEMENT_MANAGEMENT_ROUTE,
                                searchParameters: location.search,
                              },
                            });

                            pushInHistory(
                              PLACEMENT_MANAGEMENT_ROUTE,
                              location?.search
                            );
                          }}
                          className="anchorColorText route-link ml-2"
                        >
                          {item?.placementId}
                          {(item?.placementType === "DIRECT_HIRE" && item?.isPaid) ? <span className='green-dot' /> : <span></span>}
                        </div>
                        <div className="subtitleText" style={{ color: '#2e95b4e6' }}>{mostRecentActivityReport && `(${moment(mostRecentActivityReport).format('MMM-YY').toUpperCase()})`}</div>
                      </td>

                      <td className="cursor-pointer">
                        <div className="d-flex align-items-center">
                          <div
                            className="anchorColorText route-link"
                            onClick={() => {
                              history.push({
                                pathname: `${VIEW_CANDIDATE_ROUTE}/${item?.candidate?.id}`,
                                state: {
                                  backUrl: PLACEMENT_MANAGEMENT_ROUTE,
                                  searchParameters: location.search,
                                },
                              });

                              pushInHistory(
                                PLACEMENT_MANAGEMENT_ROUTE,
                                location?.search
                              );
                            }}
                          >
                            <div className="d-flex align-items-center">
                              <p className="d-inline-block text-truncate max-100 mb-0">
                                {item?.candidateName}
                              </p>

                              <span>
                                {item?.candidate?.isPrivateCandidate && (
                                  <small className="text-success ml-2">
                                    (Private)
                                  </small>
                                )}{' '}
                              </span>
                            </div>
                          </div>
                        </div>
                      </td>

                      <td>
                        {!!item?.jobTitle ? (
                          <div
                            className="route-link text-truncate max-200"
                            onClick={() => {
                              history.push({
                                pathname: `${VIEW_JOB_ORDER_ROUTE}/${item?.jobOrder?.id}`,
                                state: {
                                  backUrl: PLACEMENT_MANAGEMENT_ROUTE,
                                  searchParameters: location.search,
                                },
                              });
                              pushInHistory(
                                PLACEMENT_MANAGEMENT_ROUTE,
                                location.search
                              );
                            }}
                          >
                            {item?.jobTitle}
                          </div>
                        ) : (
                          '--'
                        )}
                        <div className="subtitleText">
                          {item?.companyName ? (
                            <div
                              className="route-link text-truncate max-200"
                              onClick={() => {
                                history.push({
                                  pathname: `${COMPANY_DETAILS_ROUTE}/${item?.jobOrder?.company?.id}`,
                                  state: {
                                    backUrl: PLACEMENT_MANAGEMENT_ROUTE,
                                    searchParameters: location.search,
                                  },
                                });

                                pushInHistory(
                                  PLACEMENT_MANAGEMENT_ROUTE,
                                  location.search
                                );
                              }}
                            >
                              {item?.companyName}
                            </div>
                          ) : (
                            '--'
                          )}
                        </div>
                      </td>

                      <td>
                        {item?.startDateString
                          ? formatDateUS(moment(item?.startDateString).format())
                          : '--'}
                      </td>
                      <td>
                        {item?.createdAt ? formatDateUS(item?.createdAt) : '--'}
                      </td>
                      <td>{moneyFormatter(item?.netCommission)}</td>
                      <td className='badge-column'>
                        <Badge
                          color={
                            item?.placementStatus === 'ACCOUNTING_APPROVED' ||
                              item?.placementStatus === 'PAID' ||
                              item?.placementStatus === 'ACCOUNTING_CONFIRMED' ||
                              item?.placementStatus === 'ACCOUNTING_INVOICED'
                              ? 'success'
                              : item?.placementStatus === 'PROSPECT'
                                ? 'warning'
                                : item?.placementStatus === 'DRAFT' ||
                                  item?.placementStatus ===
                                  'ASSIGNMENT_COMPLETED' ||
                                  item?.placementStatus === 'ASSIGNMENT_CONVERTED'
                                  ? 'primary'
                                  : item?.placementStatus === 'REJECTED_MANAGER' ||
                                    item?.placementStatus === 'REJECTED_AM' ||
                                    item?.placementStatus ===
                                    'REJECTED_ACCOUNTING' ||
                                    item?.placementStatus ===
                                    'REJECTED_RECRUITER' ||
                                    item?.placementStatus ===
                                    'PLACEMENT_CANCELLED' ||
                                    item?.placementStatus === 'ASSIGNMENT_CANCELLED'
                                    ? 'danger'
                                    : item?.placementStatus === 'PENDING_MANAGER' ||
                                      item?.placementStatus === 'PENDING_AM' ||
                                      item?.placementStatus === 'PENDING_RECRUITER' ||
                                      item?.placementStatus === 'PENDING_ACCOUNTING'
                                      ? 'info'
                                      : 'primary'
                          }
                          className="text-capitalize"
                        >
                          {item?.placementStatus ? DtoForPlacement[item?.placementStatus] : '--'}

                        </Badge>
                      </td>

                      <td>{item?.placementType}</td>
                      <td>{item?.accountManager?.fullName ?? '--'}</td>
                      <td>{item?.recruiter?.fullName || '--'}</td>
                      <td className="text-truncate max-200">
                        {countryWiseHeaderLocation(
                          item?.jobCountryCode,
                          item?.jobCity,
                          item?.jobStateCode
                        )}
                      </td>
                      <td >
                        <Link
                          to={{
                            pathname: `${VIEW_PLACEMENT_MANAGEMENT_ROUTE}/${item?.id}`,
                            state: { searchParameters: location.search },
                          }}
                          onClick={() =>
                            pushInHistory(
                              PLACEMENT_MANAGEMENT_ROUTE,
                              location.search
                            )
                          }
                        >
                          View Details
                        </Link>
                      </td>
                    </tr>
                  );
                }
              )}
          </tbody>
        </TableComponent>
      </div>

      {data?.allPlacementListManagementPage?.placements.length ? (
        <div className="users-pagination">
          <Pagination
            onPageChange={(pageClicked: number) => {
              setIsSetTime(true)
              setQueryParams(searchParams);
              setCurrentPage(pageClicked);
            }}
            pageCount={Math.ceil(
              data?.allPlacementListManagementPage?.count / limit
            )}
            currentPage={currentPage}
          />

          <div className="d-flex align-items-center">
            <div className="pagination-display paginationItemsSelect">
              <p className="m-0">
                {(currentPage - 1) * limit + 1} to{' '}
                {Math.min(
                  currentPage * limit,
                  data?.allPlacementListManagementPage?.count
                )}{' '}
                of {data?.allPlacementListManagementPage?.count}
              </p>

              <div className="divider"></div>

              <label>Display</label>

              <InputField
                label=""
                inputtype="select"
                selectItems={PAGE_LIMIT.filter((item) => item.value !== limit)}
                inputid="limit"
                placeholder={limit.toString()}
                inputMethod={(value: string) => {
                  setIsSetTime(true)
                  setQueryParams(searchParams);
                  setCurrentPage(1);
                  setLimit(parseInt(value));
                }}
              />
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default PlacementManagement;
