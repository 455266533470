import { useLazyQuery } from '@apollo/client';
import { Controller } from 'react-hook-form';
import { useContext, useEffect, useState } from 'react';
import {
  Button,
  Col,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
} from 'reactstrap';
import { FETCH_CONTACTS_FOR_TIMECARD } from '../gql';
import InputField from '../../../../components/layout/InputField';
import CreateContactModal from '../../../company/company-components/CreateContactModal';
import moment from 'moment';
import {
  commaRemover,
  localStringMaker,
  moneyFieldHandler,
} from '../../../../utils/helper';
import { AppContext } from '../../../../context';
import { customSelectDropDown } from '../../../../components/styled/customSelect';
import AsyncContactController from '../../../../components/AsyncContactController';

export default function ContractPlacementTypeBase({
  companyId,
  companyName,
  control,
  readonly,
  startDateString,
  setEndingDate,
  register,
  watch,
  setAssignedDuration,
  setValue,
  errors,
  setError,
  disableAllFields,
  setEditModal
}: any) {
  const { theme } = useContext(AppContext);
  const [createContactModal, setCreateContactModal] = useState<boolean>(false);

  const [_getCompanyContacts, { data: notes,loading }] = useLazyQuery(
    FETCH_CONTACTS_FOR_TIMECARD
  );
  const [refetchContacts, setRefetchContacts] = useState('');
  const getContacts = async () => {
    await _getCompanyContacts({
      variables: {
        page: 1,
        limit: 30,
        searchText: '',
        statusSearch: '',
        companyId,
      },
    });
  };
  useEffect(() => {
    if (companyId) {
      getContacts();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId, refetchContacts]);


  const createContactToggle = () => {
    setCreateContactModal(!createContactModal);
  };

  return (
    <>
      <Col lg="4" md="4" sm="12" className="displayNoneHeading">
        <Label>
          Assignment Duration (weeks) <span className="text-danger">*</span>
        </Label>
        <InputField
          disabled={disableAllFields}
          readOnly={readonly}
          isRequired
          inputtype="text"
          placeholder="Assignment duration"
          onChange={(e) => {
            setAssignedDuration(e.target.value);
            setEndingDate(
              moment(startDateString)
                .add(parseInt(e?.target?.value) * 7, 'days')
                .format('YYYY-MM-DD')
            );
          }}
          inputid="assignedDuration"
          inputRef={register({
            required: {
              value: true,
              message: 'Assignment duration is required!',
            },
            maxLength: {
              value: 2,
              message:
                "Length of Assignment duration can't be more than 2 digits",
            },
          })}
        />
        <small className="text-danger">
          {errors?.assignedDuration?.message}
        </small>
      </Col>

      <Col
        lg="4"
        md="4"
        sm="12"
        className={`${!readonly && 'dollar-addon-btn'}`}
      >
        <FormGroup className="agreementSelectSpacing">
          <Label>
            Less Employee Pay Rate <span className="text-danger">*</span>
          </Label>

          <InputGroup>
            <InputGroupAddon
              addonType="prepend"
              className={disableAllFields ? 'diable-form-group' : ''}
            >
              $
            </InputGroupAddon>
            <Input
              readOnly={readonly}
              disabled={disableAllFields}
              placeholder={'/hour'}
              name="payRate"
              onChange={(e) => {
                // if (checkingIsNaN(e.target.value)) {
                moneyFieldHandler(e?.target?.value, setValue, 'payRate');
                // } else {
                //   setError("payRate", {
                //     type: "manual",
                //     message: "Dont Forget Your Username Should Be Cool!",
                //   });
                // }
              }}
              innerRef={register({
                required: 'Pay rate is required',
              })}
            />
          </InputGroup>

          <small className="text-danger">{errors?.payRate?.message}</small>
        </FormGroup>
      </Col>

      <Col
        lg="4"
        md="4"
        sm="12"
        className={`${!readonly && 'dollar-addon-btn'}`}
      >
        <FormGroup className="agreementSelectSpacing">
          <Label>
            Company Bill Rate <span className="text-danger">*</span>
          </Label>

          <InputGroup>
            <InputGroupAddon
              addonType="prepend"
              className={disableAllFields ? 'diable-form-group' : ''}
            >
              $
            </InputGroupAddon>
            <Input
              readOnly={readonly}
              disabled={disableAllFields}
              placeholder={'/hour'}
              name="billRate"
              onChange={(e) => {
                moneyFieldHandler(e?.target?.value, setValue, 'billRate');
                moneyFieldHandler(
                  localStringMaker(commaRemover(watch('billRate')) * 1.5),
                  setValue,
                  'overtimeBillRate'
                );
              }}
              innerRef={register({
                required: 'Bill rate is required',
                // pattern: {
                //   value: new RegExp(/^\d{1,3}(\.\d{0,2})?$/),
                //   message: "Please enter a valid bill rate e.g. 15.00",
                // },
              })}
              errMsg={errors?.billRate?.message}
            />
          </InputGroup>

          <small className="text-danger">{errors?.billRate?.message}</small>
        </FormGroup>
      </Col>

      <Col lg="4" md="4" sm="12" className="">
        <FormGroup>
          <Label>
            Markup Percentage <span className="text-danger">*</span>
          </Label>

          <InputGroup>
            <InputGroupAddon
              addonType="prepend"
              className={'diable-form-group'}
            >
              %
            </InputGroupAddon>
            <Input
              readOnly
              disabled={disableAllFields}
              placeholder="(Company Bill Rate - Pay Rate) / Less Employee Pay Rate"
              name="markupPercentage"
              value={Number(
                ((commaRemover(watch('billRate')) -
                  commaRemover(watch('payRate'))) /
                  commaRemover(watch('payRate'))) *
                  100
              ).toFixed(2)}
              type="number"
              innerRef={register()}
            />
          </InputGroup>
        </FormGroup>
      </Col>

    <Col lg="4" md="4" sm="12" className="agreementSelectSpacing">
        <Label>
          Timecard Approver <span className="text-danger">*</span>
        </Label>
        <Controller
          rules={{
            required: {
              value: true,
              message: "Time card Approver must be selected!",
            },
          }}
          name="timeCardApprover"
          render={({ onChange, name, value, ref}) => (
            <AsyncContactController
            contactData={notes?.allContacts?.contacts?.map((item) => ({
              value: item?.id,
              label: item?.fullName,
            }))}
            loading= {loading}
              companyId={companyId}
              width={"100%"}
              ownershareeFlag={true}
              placeholder={`Search Contacts of ${companyName}`}
              value={watch("timeCardApprover")}
              isDisabled={companyId ? false : true}
              onChange={(target: { label: string; value: string }) => {
                  setValue('timeCardApprover', target);
                  setError('timeCardApprover', undefined); // Clear the error message
              }}
            />
          )}
          control={control}
          styles={{ ...customSelectDropDown(theme) }}
        />
        <small className="text-danger m-0">
          {errors?.timeCardApprover?.message}
        </small>
        <h6>
          <Button
            disabled={readonly || disableAllFields}
            size="sm"
            onClick={createContactToggle}
            className="bg-transparent border-0 ml-0 p-0"
            style={{ color: "#0e79b6", fontSize: "12px" }}
          >
            Add Contact
          </Button>
        </h6>
      </Col>

      <Col
        lg="4"
        md="4"
        sm="12"
        className={`${!readonly && 'dollar-addon-btn'}`}
      >
        <FormGroup className="agreementSelectSpacing">
          <Label>
            Overtime Bill Rate
            <span className="text-danger">*</span>
          </Label>

          <InputGroup>
            <InputGroupAddon
              addonType="prepend"
              className={disableAllFields ? 'diable-form-group' : ''}
            >
              $
            </InputGroupAddon>
            <Input
              readOnly={readonly}
              disabled={disableAllFields}
              placeholder={'/hour'}
              // value={localStringMaker(commaRemover(watch("billRate")) * 1.5)}
              onChange={(e) => {
                moneyFieldHandler(
                  e?.target?.value,
                  setValue,
                  'overtimeBillRate'
                );
              }}
              type="text"
              name="overtimeBillRate"
              innerRef={register({
                required: 'Overtime bill rate is required',
              })}
              errMsg={errors?.overtimeBillRate?.message}
            />
          </InputGroup>

          <small className="text-danger">
            {errors?.overtimeBillRate?.message}
          </small>
        </FormGroup>
      </Col>

      <CreateContactModal
        toggle={createContactToggle}
        setEditModal= {setEditModal}
        open={createContactModal}
        companyId={companyId}
        setRefectContact={setRefetchContacts}
        companyName={companyName}
        preSelectContact={() => {}}
        fetchCompany={() => {
          _getCompanyContacts({
            variables: {
              page: 1,
              limit: 20,
              searchText: "",
              statusSearch: "",
              companyId,
            },
          });
        }}
      />
    </>
  );
}
