import { useContext, useEffect, useState } from "react";
import { Button, Input, Spinner } from "reactstrap";
import { AppContext } from "../../../context";

import "./style.scss";

interface MultiselectPropType {
  isToggle: boolean;
  setUserRoles: Function;
  userRoles: any;
  updateAddRoles: Function;
  userId: string;
  loading?: boolean;
  setIsSelectToggle: Function;
}

const MultiSelect = ({
  isToggle,
  setUserRoles,
  userRoles,
  updateAddRoles,
  userId,
  loading,
  setIsSelectToggle,
}: MultiselectPropType) => {
  const { userRoles: CotextUserRoles } = useContext(AppContext);
  const [roles, setRoles] = useState([
    { value: "ADMIN", label: "Admin" },
    { value: "RECRUITER", label: "Recruiter" },
    { value: "ACCOUNTING_MANAGER", label: "Accounting Manager" },
    { value: "HR", label: "HR" },
    { value: "ACCOUNTING_COORDINATOR", label: "Accounting Coordinator" },

  ]);

  useEffect(() => {
    if (CotextUserRoles?.includes("EXECUTIVE")) {
      setRoles([
        ...roles.filter((item) => item.value !== "EXECUTIVE" && item.value !== "TEST_ROLE"),
        {
          value: "EXECUTIVE",
          label: "Executive",
        },
        {
          value: "TEST_ROLE",
          label: "Test Role",
        }
      ]);
    }
    // eslint-disable-next-line
  }, [CotextUserRoles]);

  return (
    <div
      className={isToggle ? "multiselect toggle" : "multiselect"}
      style={{
        overflowY: "hidden",
        paddingTop: "15px",
        paddingBottom: "15px",
        height: "auto",
      }}
    >
      {roles.map((item, index) => {
        return (
          <div className="checkbox-label" key={item.label}>
            <Input
              type="checkbox"
              id={`selectCheck-${index}`}
              checked={userRoles.includes(item.value)}
              onChange={(e) => {
                setUserRoles([...userRoles, item.value]);
                if (!e.target.checked) {
                  let newRoles =
                    userRoles &&
                    userRoles.filter((role: string) => role !== item.value);
                  setUserRoles(newRoles);
                }
              }}
            />
            <label htmlFor={`selectCheck-${index}`}>{item.label}</label>
          </div>
        );
      })}
      {userId && (
        <div className="multiselect-actions">
          <Button
            onClick={() => setIsSelectToggle(false)}
            size="sm"
            className=" bg-transparent text-dark mx-2"
          >
            Cancel
          </Button>
          <Button
            size="sm"
            className="px-3"
            disabled={loading}
            onClick={() => updateAddRoles()}
          >
            {loading ? <Spinner size="sm"></Spinner> : "Save"}
          </Button>
        </div>
      )}
    </div>
  );
};

export default MultiSelect;
