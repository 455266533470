import {
  Button,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import CustomButton from "../../../../components/layout/CustomButton";

export default function RecruiterModalEscalation({
  modal,
  toggle,
  setNote,
  recruiterProtocolLoading,
  confirmAction,
  onClosed,
  noteValue,
  setNoteValue
}) {
  return (
    <Modal
      isOpen={modal}
      toggle={toggle}
      scrollable
      className="for-modal-styling"
      onClosed={onClosed}
    >
      <ModalHeader>Send for Manager Approval</ModalHeader>
      <ModalBody>
        <div className="approval-content">
          <p>
            Are you sure you want to send this agreement for your team manager's
            approval?
          </p>
          <p>
            Sending it to manager for approval will results in awaiting for
            managers
          </p>
        </div>

        <div className="approval-content">
          <Label>Add text</Label>
          <textarea
            name="sourceNote"
            onChange={
              (e) => {
                setNote(e.target.value);
                e.target.value.trim().length > 0 ? 
                setNoteValue(false) : setNoteValue(true)
              }
            }
            placeholder="Please add notes"
            rows={2}
            className="form-control h-150"
          />
          {noteValue&&<p className="text-danger text-sm mb-0 mt-1">Note is required</p>}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          onClick={onClosed}
          className="modal-close bg-transparent border-0 text-dark"
        >
          Cancel
        </Button>
        <CustomButton
          buttonType="button"
          buttonText={"Send for manager approval"}
          buttonColor={"secondary"}
          buttonClick={confirmAction}
          loading={recruiterProtocolLoading}
        />
      </ModalFooter>
    </Modal>
  );
}
