import { useLazyQuery, useMutation } from "@apollo/client";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

import {
  Nav,
  NavItem,
  NavLink,
  TabPane,
  TabContent,
} from "reactstrap";
import GoBack from "../../components/GoBack";
import CustomButton from "../../components/layout/CustomButton";

import SocialMediaInfo from "../../components/SocialMediaInfo";
import { FETCH_CONTACTS_DETAIL, UPDATE_CONTACT } from "./gql";
import BasicContactInfo from "./contact-components/BasicCotactInfo";
import ContactEmail from "./contact-components/ContactEmail";
import ContactPhone from "./contact-components/ContactPhone";

import ActionLogsAndNotes from "../../components/ActionLogsAndNotes";
import Loader from "../../components/layout/Loader";
import IsPrivateContact from "./contact-components/IsPrivateContact";
import AddNotesModal from "../../components/AddNotesModal";
import { GET_NOTES, UPDATE_COMPANY_PRIMARY_CONTACT } from "../company/gql";
import { useHistory, useLocation } from "react-router-dom";
import { IComingState } from "../agreements/interfaces";
import classnames from "classnames";
import { AppContext } from "../../context";
import { CONTACTS_ROUTE, EMAIL_PATTERN } from "../../utils/constant";

const UpdateContact = (props: any) => {
  const {
    errors,
    handleSubmit,
    setValue,
    register,
    getValues,
    reset,
    control,
  } = useForm();

  const location = useLocation<IComingState>();
  const [isPreferred, setIsPreferred] = useState<boolean>();
  const activeParamTab =
    new URLSearchParams(location.search).get("activeTab") || "1";
  const [activeTab, setActiveTab] = useState(activeParamTab);
  const { user, userRoles } = useContext(AppContext);

  const comingState: any = location.state;
  let history = useHistory();

  const [activityNotes, setActivityNotes] = useState<boolean>(false);

  const [contactId, setContactId] = useState(null);
  const [emailErr, setEmailErr] = useState("");
  const [phoneErr, setPhoneErr] = useState("");

  const [updateContact, { loading: updateLoading }] =
    useMutation(UPDATE_CONTACT);

  const [updatePrimaryContact] = useMutation(UPDATE_COMPANY_PRIMARY_CONTACT);

  const [customEmailInputs, setCustomEmailInputs] = useState<any>([
    {
      email: "",
      isPrimary: true,
      emailId: "",
    },
  ]);
  const [customPhoneInputs, setCustomPhoneInputs] = useState<any>([
    {
      phone: "",
      phoneType: "",
      isPrimary: true,
      phoneId: "",
    },
  ]);

  const [getCompanyContact, { data, loading }] = useLazyQuery(
    FETCH_CONTACTS_DETAIL
  );

  const fetchContactDetail = () => {
    getCompanyContact({ variables: { id: contactId } });
  };

  const [getNotes] = useLazyQuery(GET_NOTES);
  const _getNotes = () => {
    getNotes({
      variables: {
        id: contactId,
        notePayload: { type: "Contact" },
        page: 1,
        limit: 3,
      },
    });
  };

  useEffect(() => {
    if (data) {
      let values = { ...data?.fetchContactDetail };
      let location = values?.companyLocation;
      let company = values?.company;
      values.companyId = {
        label: values?.company?.name,
        value: values?.company?.id,
      };
      values.locationId = location
        ? {
          label: ['US', null, undefined, ''].includes(location?.country) ? `${location?.streetAddress}, ${location?.city}, ${location?.state}, ${location?.zipCode}, ${location?.country}` : `${location?.streetAddress}, ${location?.city}, ${location?.zipCode}, ${location?.country}`,
          value: location?.id,
        }
        : null;
      values.companyId = {
        label: company?.name,
        value: company?.id,
      };
      let contactEmails = values?.contactEmail?.map((item: any) => ({
        email: item.email,
        isPrimary: item.isPrimary,
        emailId: item?.id,
      }));
      let contactPhones = values?.contactPhone?.map((item: any) => ({
        phone: item?.number,
        isPrimary: item?.isPrimary,
        phoneId: item?.id,
        phoneType: item?.phoneType,
      }));
      if (contactEmails && contactEmails.length) {
        setCustomEmailInputs([...contactEmails]);
      } else {
        setCustomEmailInputs([
          {
            email: "",
            isPrimary: false,
            emailId: "",
          },
        ]);
      }
      if (contactPhones && contactPhones.length) {
        setCustomPhoneInputs([...contactPhones]);
      } else {
        setCustomPhoneInputs([
          {
            phone: "",
            phoneType: "",
            isPrimary: true,
            phoneId: "",
          },
        ]);
      }
      setIsPreferred(values?.preferredContact);
      reset({ ...values });
    }
    // eslint-disable-next-line
  }, [data]);

  //setting title page
  useEffect(() => {
    document.title = loading
      ? "Loading..."
      : `Update ${data?.fetchContactDetail.company?.name || ""
      } Contact - RealREPP`;

    if (data?.fetchContactDetail && user) {
      const permit = checkEditPermission(data?.fetchContactDetail);

      if (!permit) {
        history.push({
          pathname: "/un-authorized",
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.fetchContactDetail, user, loading]);

  const checkEditPermission = (contact: {
    company: {
      owner: { id: string }, ownerAndSharees: {
        id: string,
        owner: { id: string },
        ownerType: string
      }[]
    };
  }) => {
    if (contact) {
      console.log("user?.id :: ", user?.id)
      console.log("contact?.company :: ", contact?.company)
      if (contact?.company?.ownerAndSharees?.length) {
        // eslint-disable-next-line
        let currentUser = contact?.company?.ownerAndSharees?.find((item) => {
          if (item.ownerType === 'SHAREE' && item.owner?.id === user?.id)
            return item;
        });
        if (currentUser) {
          return true;
        }
      }
      if (
        userRoles?.includes("ADMIN") ||
        userRoles?.includes("EXECUTIVE") ||
        contact?.company?.owner?.id === user?.id ||
        !contact?.company?.owner
      ) {
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    let id = props.match.params.id;
    if (id) {
      setContactId(id);
      getCompanyContact({ variables: { id } });
    }
    // eslint-disable-next-line
  }, []);

  const _updateContact = async (values: any) => {
    const preferredContact = values?.preferredContact
    delete values?.preferredContact;
    let emails = customEmailInputs.filter(
      (item: { email: string }) => item.email && item
    );
    const hasInvalidEmails = emails?.some(item => {
      const emailReg = new RegExp(EMAIL_PATTERN);
      return item.email && !emailReg.test(item?.email);
    });
    // If any invalid emails are found, set the email error state
    if (hasInvalidEmails) {
      setEmailErr('Email is not valid!');
      return;
    }
    const notIsPrimary = emails?.find(
      (item: { isPrimary: boolean }) => item.isPrimary
    );

    if (emails?.length && !notIsPrimary) {
      setEmailErr("Minimum one email should be primary");
      return;
    }
    values.contactEmails = emails;
    let phones = customPhoneInputs.filter(
      (item: { phone: string }) => item.phone && item
    );
    const notIsPrimaryPhone = phones?.find(
      (item: { isPrimary: boolean }) => item.isPrimary
    );
    if (phones?.length && !notIsPrimaryPhone) {
      setPhoneErr("Minimum one phone should be primary");
      return;
    }

    values.contactPhones = phones;
    values.companyId = values?.companyId?.value;
    values.locationId = values?.locationId?.value;
    values.contactId = contactId;
    let res = await updateContact({
      variables: { UpdateContactInput: { ...values } },
    });

    // call to update preferred contact
    if (preferredContact !== isPreferred) {
      let res = await updatePrimaryContact({
        variables: {
          updatePrimaryInput: {
            isPreferred,
            companyId: values?.companyId,
            id: contactId,
          },
        },
      });

      if (res?.data?.updatePrimaryContact) {
        toast.success('Preferred contact updated!');
      } else {
        toast.error('Unable to update preferred contact!')
      }
    }

    if (res?.data) {
      toast.success("Contact updated successfully!");
      fetchContactDetail();
      setEmailErr("");
      setPhoneErr("");
    } else {
      toast.error("Something went wrong!");
    }
  };

  const toggleActivityNotes = () => {
    setActivityNotes(!activityNotes);
  };

  const toggleTab = (tab: string) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      history.push(`${location?.pathname}?activeTab=${tab}`);
    }
  };

  if (loading) {
    return <Loader />;
  }
  return (
    <div className="admin-tabs team-tabs">
      <GoBack
        url={comingState?.backUrl ?? CONTACTS_ROUTE}
        search={comingState?.searchParameters}
      />

      <div className="mobileResponsiveFlexAlign justify-content-between primaryHeaderSpacing">
        <h5 className="m-0">Edit Company Contact</h5>

        <IsPrivateContact
          isPrivate={data?.fetchContactDetail?.isPrivate}
          companyId={props.match.params.id}
          contactId={data?.fetchContactDetail?.id}
        />
      </div>

      <div className="CandidateTabsWrapper">
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "1" })}
              onClick={() => {
                toggleTab("1");
              }}
            >
              Basic Info
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "2" })}
              onClick={() => {
                toggleTab("2");
              }}
            >
              Activity
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent activeTab={activeTab}>
          <TabPane className="tab-pane-padding" tabId="1">
            <h5>Basic Info</h5>
            <BasicContactInfo
              register={register}
              getValues={getValues}
              setValue={setValue}
              makeCompanyReadOnly={true}
              reset={reset}
              errors={errors}
              control={control}
              isEditable={true}
              isPreferredContact={isPreferred}
              {...props}
              fetchContactDetail={fetchContactDetail}
            />

            <h5>Contact Email</h5>
            <ContactEmail
              setCustomEmailInputs={setCustomEmailInputs}
              customEmailInputs={customEmailInputs}
              emailErr={emailErr}
              setEmailErr={setEmailErr}
            />

            <div className="mt-5">
              <ContactPhone
                setCustomPhoneInputs={setCustomPhoneInputs}
                customPhoneInputs={customPhoneInputs}
                error={phoneErr}
                isRequired={false}
                setErr={setPhoneErr}
              />
            </div>
            <div className="mt-5">
              <h5>Social Media</h5>
              <SocialMediaInfo
                setValue={setValue}
                register={register}
                errors={errors}
                isLinkedinRequired={false}
                isDisable={false}
              />
            </div>

            <div className="justify-content-end d-flex">
              <CustomButton
                buttonText="Update Company Contact"
                buttonColor="primary"
                buttonType="submit"
                className="big-width"
                buttonClick={handleSubmit(_updateContact)}
                loading={updateLoading}
                disabled={updateLoading}
              />
            </div>
          </TabPane>

          <TabPane tabId="2">
            <ActionLogsAndNotes
              option={{
                contactId: data?.fetchContactDetail?.id ?? "",
                contactName: data?.fetchContactDetail?.fullName ?? "",
                companyId: data?.fetchContactDetail.company?.id ?? "",
                companyName: data?.fetchContactDetail.company?.name ?? "",
              }}
              type="Contact"
              recordId={data?.fetchContactDetail?.id}
            />
          </TabPane>
        </TabContent>
      </div>

      <AddNotesModal
        toggleActivityNotes={toggleActivityNotes}
        activityNotes={activityNotes}
        noteableId={contactId}
        reFetch={() => _getNotes()}
        type="Contact"
        editData={null}
        resetField={() => { }}
      />
    </div>
  );
};

export default UpdateContact;
