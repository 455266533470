import { useState } from 'react';
import DownloadImg from '../../../assets/images/download.svg';

import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Row,
} from 'reactstrap';
import { createMarkup, downloadSignedUrlFile } from '../../../utils/commonFn';
import {
  Sidedrawer,
  DrawerHeader,
} from '../../../components/layout/Sidedrawer';

const SubmissionReview = ({ data, open, toggle }) => {
  const [downloading, setDownloading] = useState(false);
  return (
    <div>
      <Sidedrawer toggle={toggle} isOpen={open}>
        <div className="mb-4">
          <DrawerHeader
            prevClick={() => toggle()}
            heading="Submission Email"
            nextButtonView={false}
          />
        </div>

        <div className="pb-2">
          <small>
            Status:<strong> {data?.status}</strong>{' '}
          </small>
        </div>

        <Row>
          <Col xs="12" className="my-2">
            <div className="d-flex">
              <h6 className="mb-0 mr-2 font-weight-bold"> To:</h6>
              {data?.submissionEmail?.toContacts?.map((mail) => (
                <Badge className="mx-1">{mail}</Badge>
              ))}
            </div>
          </Col>
          {data?.submissionEmail?.ccContacts?.length ? (
            <Col xs="12" className="my-2">
              <div className="d-flex">
                <h6 className="mb-0 mr-2 font-weight-bold"> Cc:</h6>
                {data?.submissionEmail?.ccContacts?.map((mail) => (
                  <Badge className="mx-1">{mail}</Badge>
                ))}
              </div>
            </Col>
          ) : (
            ''
          )}
          {data?.submissionEmail?.bccContacts?.length ? (
            <Col xs="12" className="my-2">
              <div className="d-flex">
                <h6 className="mb-0 mr-2 font-weight-bold"> Bcc:</h6>
                {data?.submissionEmail?.bccContacts?.map((mail) => (
                  <Badge className="mx-1">{mail}</Badge>
                ))}
              </div>
            </Col>
          ) : (
            ''
          )}

          <Col xs="12" className="my-2">
            <div className="d-flex">
              <h6 className="my-auto mr-2 font-weight-bold"> Subject:</h6>
              <div>{data?.submissionEmail?.subject}</div>
            </div>
          </Col>
          <Col xs="12" className="my-2">
            <div className="">
              <h6 className="mb-0  mb-2 font-weight-bold"> Body:</h6>
              <Card className="border-0 shadow-sm">
                <CardBody
                  className="scrolled"
                  style={{
                    maxHeight: '30em',
                    overflow: 'hidden',
                    overflowY: 'scroll',
                  }}
                >
                  <div
                    className="d-block ml-3 text-justify"
                    dangerouslySetInnerHTML={createMarkup(
                      data?.submissionEmail?.bodyContent
                    )}
                  />
                </CardBody>
              </Card>
            </div>
          </Col>
          <Col xs="12" className="my-2">
            <div className="">
              <h6 className="mb-0 mr-2 mb-2 font-weight-bold">Attachments:</h6>
              {data?.submissionAttachments?.map((item) => (
                <Button
                  className="mx-1 px-2 curser-pointer mb-2"
                  size="sm"
                  disabled={downloading}
                  onClick={async () => {
                    // if (item?.attachmentType === "RESUME") {
                    setDownloading(true);
                    await downloadSignedUrlFile(
                      item.attachmentURL,
                      item?.attachmentName
                    );
                    setDownloading(false);
                    // } else {
                    //   setDownloading(true);

                    //   await downloadSignedUrlFile(
                    //     item?.attachmentURL,
                    //     item?.attachmentName
                    //   );
                    //   setDownloading(false);
                    // }
                  }}
                >
                  <img
                    src={DownloadImg}
                    alt="download"
                    className={`mr-2 mb-1`}
                  />
                  <small className="text-truncate fileNameWidth">
                    {item?.attachmentName}
                  </small>
                </Button>
              ))}
            </div>
          </Col>
        </Row>
      </Sidedrawer>
    </div>
  );
};

export default SubmissionReview;
