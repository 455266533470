import { useMutation } from "@apollo/client";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Col, Form, Row } from "reactstrap";
import CustomButton from "../../../../components/layout/CustomButton";
import InputField from "../../../../components/layout/InputField";
import { UPDATE_MANUAL_CONTACT } from "../gql";
import "../contacts.scss";
import {
  IEditContactManuallyProps,
  IUpdateContactManualInput,
} from "../interfaceContact";
import { toast } from "react-toastify";

const EditContactManually = ({
  contactId,
  contactDetailsData,
  setEditContactManually,
  refetchContactDetails,
}: IEditContactManuallyProps) => {
  const { register, reset, handleSubmit } = useForm();

  const [updateContactManually, { loading }] = useMutation(
    UPDATE_MANUAL_CONTACT
  );

  useEffect(() => {
    if (contactDetailsData) {
      reset({ ...contactDetailsData });
    }

    // eslint-disable-next-line
  }, [contactDetailsData]);

  const editManualContact = async (values: IUpdateContactManualInput) => {
    const response = await updateContactManually({
      variables: { contactId, ...values },
    });

    if (response?.data?.updateManualContact?.status === 200) {
      setEditContactManually(false);
      refetchContactDetails();
    } else if (response?.data?.updateManualContact?.message === 'INVALID_EMAIL') {
      toast.error("Invalid Email.");
    } else {
      toast.error(response?.data?.updateManualContact?.message);
    }
  };

  return (
    <div>
      <h5 className="mb-3">Update Contact</h5>
      <Form onSubmit={handleSubmit(editManualContact)}>
        <Row>
          <Col xl="6" lg="6" sm="12" xs="12">
            <InputField
              inputtype="text"
              label="Name"
              inputid="name"
              placeholder="Contact Name"
              inputRef={register()}
            />
          </Col>

          <Col xl="6" lg="6" sm="12" xs="12">
            <InputField
              inputtype="text"
              label="Company"
              inputid="company"
              placeholder="Company Name"
              inputRef={register()}
            />
          </Col>

          <Col xl="6" lg="6" sm="12" xs="12">
            <InputField
              inputtype="email"
              label="Work Email"
              inputid="email"
              placeholder="Work Email"
              inputRef={register()}
            />
          </Col>

          <Col xl="6" lg="6" sm="12" xs="12">
            <InputField
              inputtype="text"
              label="Website"
              inputid="url"
              placeholder="Website"
              inputRef={register()}
            />
          </Col>

          <Col xl="6" lg="6" sm="12" xs="12">
            <InputField
              inputtype="text"
              label="Location"
              inputid="location"
              placeholder="Location"
              inputRef={register()}
            />
          </Col>

          <Col xl="6" lg="6" sm="12" xs="12">
            <InputField
              inputtype="text"
              label="Title"
              inputid="title"
              placeholder="title"
              inputRef={register()}
            />
          </Col>

          <Col xl="6" lg="6" sm="12" xs="12">
            <InputField
              inputtype="text"
              label="Phone number"
              inputid="phone_number"
              placeholder="Phone number"
              inputRef={register()}
            />
          </Col>
        </Row>

        <div className="edit-contact-manually-footer pt-3">
          <button
            className="buttonPrimary mr-3"
            onClick={() => setEditContactManually(false)}
            type="button"
          >
            Cancel
          </button>

          <CustomButton
            buttonText="Update Contact"
            loading={loading}
            disabled={loading}
          />
        </div>
      </Form>
    </div>
  );
};

export default EditContactManually;
