import { useEffect, useState } from "react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";

import {
  Badge,
  Button,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
} from "reactstrap";

import TeamImage from "../../../assets/images/user-empty.svg";
import AvatarImage from "../../../assets/images/user-empty.svg";
import EmptyImage from "../../../assets/images/team.svg";
import CLOSE_ICON from "../../../assets/images/close-icon.svg";

import "./style.scss";
import InputField from "../../../components/layout/InputField";
import CustomButton from "../../../components/layout/CustomButton";
import TableComponent from "../../../components/layout/TableComponent";
import { useLazyQuery, useMutation } from "@apollo/client";
import { CREATE_NEW_TEAM, FETCH_TEAMS } from "../gql";
import { useForm } from "react-hook-form";
import Loader from "../../../components/layout/Loader";
import UploadImage from "../../../components/layout/UploadImage.tsx";
import { formatDateUS } from "../../../utils/commonFn";
import { uploadImage } from "../../../utils/helper";
import { toast } from "react-toastify";
import GoBack from "../../../components/GoBack";
import { bucketFolders } from "../../../utils/constant";

import VIEW_ICON from "../../../assets/images/view-icon.svg";
import EDIT_ICON from "../../../assets/images/edit-icon-menu.svg";

const TABLE_HEADER = ["Name", "Manager", "Members", "STATUS", "Actions"];

const TeamComponent = ({ history }: RouteComponentProps): JSX.Element => {
  const [fetchTeam, { data, loading }] = useLazyQuery(FETCH_TEAMS);
  const { errors, handleSubmit, register, reset } = useForm();
  const [createNewTeam, { error, loading: createTeamLoading }] =
    useMutation(CREATE_NEW_TEAM);
  const [logo, setLogo] = useState<any>();
  const [previewURL, setPreviewURL] = useState<any>();

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const createTeam = async (values: { name: string; logoUrl?: string }) => {
    try {
      if (logo) {
        let response = await uploadImage(
          logo,
          bucketFolders.team,
          false,
          false,
          true
        );
        if (!response.success && !response?.ok) {
          toast.error(response?.message || "Error while uploading image");
          setLogo(null);
          setPreviewURL(null);
          return;
        }
        if (response.success) {
          Object.assign(values, { logoUrl: response.source });
        }
      }
      const res = await createNewTeam({ variables: values });
      if (res.data) {
        const id = res?.data?.createTeam?.id;
        toggle();
        reset();
        history.push(`/admin/teams/edit/${id}`);
        fetchTeam();
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchTeam();
  }, [fetchTeam]);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <div className="admin-tabs team-tabs">
        <GoBack url="/admin" />

        <div className="primaryHeading mobileResponsiveFlexAlign justify-content-between">
          <div>
            <h5 className="m-0">Teams</h5>
            <p className="primaryHeadingSubtitle m-0">
              Keep your team organized and efficient by ensuring every user has
              access to the right assets.
            </p>
          </div>

          <button
            type="button"
            onClick={toggle}
            className="buttonGenericStyle filledButton"
          >
            Add New Team
          </button>
        </div>

        <div className="table-responsive">
          <TableComponent tableHeader={TABLE_HEADER}>
            <tbody>
              {data?.getAllTeams &&
                /* eslint-disable-next-line */
                data.getAllTeams.map((team: any, index: number) => {
                  // eslint-disable-next-line
                  return (
                    <tr key={index}>
                      <td>
                        <div className="avatar-profile">
                          <div className="avatar avatar-team">
                            <img src={team?.logoUrl || EmptyImage} alt="team" />
                          </div>
                          {team.name}
                        </div>
                      </td>
                      <td>
                        <div className="avatar-profile">
                          {team.teamUsers?.length
                            ? team.teamUsers?.map(
                                (teamUser: any) =>
                                  teamUser.isManager && (
                                    <>
                                      <div className="avatar">
                                        <img
                                          src={
                                            teamUser?.user?.photo || AvatarImage
                                          }
                                          alt="avatar"
                                        />
                                      </div>
                                      {teamUser?.user?.fullName}
                                    </>
                                  )
                              )
                            : "--"}
                        </div>
                      </td>
                      <td>{team.teamUsers?.length}</td>
                      <td>
                        <Badge
                          color={
                            !team.archivedAt
                              ? "secondary"
                              : team.activatedAt
                              ? "primary"
                              : ""
                          }
                        >
                          {team.archivedAt
                            ? `Deleted ${formatDateUS(team.archivedAt)} `
                            : team.activatedAt
                            ? "Active"
                            : "Pending"}
                        </Badge>
                      </td>
                      <td>
                        <Link
                          to={`/admin/view-teams/${team.id}`}
                          className="mr-3"
                        >
                          <img src={VIEW_ICON} alt="view" width="21px" />
                        </Link>

                        <Link to={`/admin/teams/edit/${team.id}`}>
                          <img src={EDIT_ICON} alt="edit" />
                        </Link>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </TableComponent>
        </div>
        <Modal
          isOpen={modal}
          toggle={toggle}
          className="revampDialog revampDialogWidth"
        >
          <div className="modalHeaderCustom d-flex align-items-center justify-content-between">
            <h5 className="m-0">Add New Team</h5>

            <Button className="p-0 bg-transparent border-0" onClick={toggle}>
              <img src={CLOSE_ICON} alt="close" />
            </Button>
          </div>

          <Form onSubmit={handleSubmit(createTeam)}>
            <ModalBody>
              <UploadImage
                setImage={setLogo}
                setPreviewURL={setPreviewURL}
                src={previewURL || TeamImage}
              />
              <InputField
                label="Name"
                inputtype="text"
                placeholder="Team Name"
                inputid="name"
                inputRef={register({
                  required: { value: true, message: "Name is required!" },
                })}
                errMsg={errors?.name?.message}
              />
              {error?.graphQLErrors[0]?.message && (
                <div className="error">{error?.graphQLErrors[0]?.message}</div>
              )}
            </ModalBody>
            <ModalFooter>
              <button
                type="button"
                onClick={() => {
                  setLogo(null);
                  setPreviewURL(null);
                  toggle();
                }}
                className="buttonPrimary mr-3"
              >
                Cancel
              </button>

              <CustomButton
                buttonText="Add team"
                buttonColor="primary"
                className="big-width"
                disabled={createTeamLoading}
                loading={createTeamLoading}
              />
            </ModalFooter>
          </Form>
        </Modal>
      </div>
    </>
  );
};

export default withRouter(TeamComponent);
