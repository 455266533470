import { useLazyQuery, useMutation } from "@apollo/client"
import { FC, useEffect, useState } from "react"
import { toast } from "react-toastify"
import { Button } from "reactstrap"
import DELETE_ICON from "../../../../../assets/images/delete-icon-menu.svg"
import EDIT_ICON from "../../../../../assets/images/edit-icon-menu.svg"
import { ADD } from "../../../../../constants"
import { CommissionBonusProps, ICommissionOverwrites } from "../../../../../interfaces"
import ConfirmPopUp from "../../../../ConfirmPopUp"
import Loader from "../../../../layout/Loader"
import NoDataFound from "../../../../layout/NoDataFound"
import TableComponent from "../../../../layout/TableComponent"
import { DELETE_COMMISSION_OVERWRITE, GET_ALL_COMMISSION_OVERWRITE } from "../../gql"
import CommissionOverwriteDrawer from "./CommissionOverwriteDrawer"

const CommissionOverwrite: FC<CommissionBonusProps> = ({ recruiterDealId }) => {
  const [commissionOverwrites, setCommissionOverwrites] = useState<ICommissionOverwrites[]>();
  const [commissionOverwrite, setCommissionOverwrite] = useState<ICommissionOverwrites>();
  const [id, setId] = useState<string>();
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [deletePopUp, setDeletePopUp] = useState<boolean>(false);
  const [drawerIsEdit, setDrawerIsEdit] = useState<boolean>(false);

  //mutations and queries
  const [getCommissionOverwrites, { refetch, loading }] = useLazyQuery(GET_ALL_COMMISSION_OVERWRITE, {
    onCompleted: (val) => setCommissionOverwrites(val?.getAllCommissionOverwrites?.commissionOverwrites)
  })

  const [deleteCommission, { loading: deleting }] = useMutation(DELETE_COMMISSION_OVERWRITE, {
    onCompleted: (val) => {
      toast.success("Commission Overwrite Deleted Successfully")
      refetch()
      setDeletePopUp(false)
    },
    onError: (val) => toast.success("Unable to Delete Commission Overwrite")
  })
  useEffect(() => {
    getCommissionOverwrites({ variables: { recruiterDealId } })
  }, [])

  useEffect(() => {
    if (drawerIsEdit) {
      setDrawerOpen(true)
    }
  }, [drawerIsEdit])
  const toggle = () => {
    setDrawerOpen(!drawerOpen)
    setDrawerIsEdit(false)
    refetch()
  }

  return <>
    <div className="d-flex align-items-center justify-content-between mt-4">
      <div className="fw-700 fs-14 my-4">{"Commission Overwrite"}</div>
      <button className="buttonGenericStyle filledButton ml-3" onClick={() => {
        setCommissionOverwrite(undefined)
        setDrawerOpen(true)
      }}>
        {ADD}
      </button>
    </div>

    <TableComponent tableHeader={['Overwrite Type', 'Valid Days', 'Percentage', 'Note', 'Actions']}>
      <tbody>
        {!loading && !!commissionOverwrites?.length && commissionOverwrites.map((item) => <tr>
          <td >{item?.type}</td>
          <td >{item?.validDays || '--'}</td>
          <td >{item?.percentage?.replaceAll(" ", "")}</td>
          <td >{item?.note.length > 30 ? item?.note.slice(0, 30) + '...' : item?.note}</td>
          <td >
            <Button className="bg-transparent border-0 p-0">
              <img className="mr-3" src={EDIT_ICON} onClick={() => {
                setDrawerIsEdit(true)
                setCommissionOverwrite(item)
              }
              } alt="edit" />
            </Button>

            <Button className="bg-transparent border-0 p-0">
              <img src={DELETE_ICON} onClick={() => {
                setId(item?.id)
                setDeletePopUp(true)
              }} alt="delete" />
            </Button >
          </td>
        </tr>
        )}
        {!loading && !commissionOverwrites?.length &&
          <td colSpan={5} className="empty-table-td">
            <NoDataFound text="No Commission Overwrite Found!" />
          </td>}
        {!!loading && <td colSpan={5} className="empty-table-td">
          <Loader loaderClass="xs-height " />
        </td>}
      </tbody>
    </TableComponent >
    <CommissionOverwriteDrawer isEdit={drawerIsEdit} isOpen={drawerOpen} recruiterDealId={recruiterDealId}
      toggle={toggle} commissionOverwrite={commissionOverwrite} />

    <ConfirmPopUp
      isOpen={deletePopUp}
      toggle={() => setDeletePopUp(!deletePopUp)}
      confirmAction={() => deleteCommission({ variables: { id } })}
      confirmText="Are you sure you want to delete this commission overwrite?"
      modalHeading="Delete Commission Overwrite"
      loading={deleting}
      disabled={deleting}
    />
  </>
}
export default CommissionOverwrite