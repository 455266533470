import { useContext } from "react";
import { Button } from "reactstrap";
import { AppContext } from "../../../context";
import deleteIcon from "../../../assets/images/delete-field.svg";
import { EXECUTIVE_ROLE, SIGNED } from "../../../utils/constant";

export default function AgreementRemoveAction({
  agreementStatus,
  confirmingRemove,
  settingRemoveAgreementId,
}) {
  const { userRoles } = useContext(AppContext);

  const ableToRemove = (): boolean => {
    if (
      userRoles?.includes(EXECUTIVE_ROLE)
      && !(agreementStatus === SIGNED)
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div>
      {ableToRemove() ? (
        <Button
          onClick={() => {
            confirmingRemove();
            settingRemoveAgreementId();
          }}
          className="my-auto bg-transparent border-0 w-auto d-flex justify-content-center align-items-center"
        >
          <img src={deleteIcon} alt="" width="20" height="20" />
        </Button>
      ) : null}
    </div>
  );
}
