import { useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import { toast } from "react-toastify";
import { Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from "reactstrap";
import InputField from "../../../components/layout/InputField";
import { URL_REGEX } from "../../../utils/constant";
import { UPDATE_COMPANY, UPDATE_COMPANY_PRIMARY_ADDRESS } from "../../company/gql";
import CreateAddressModal from "../../contacts/contact-components/CreateAddressModal";

const EditCompanyInfoModal = ({ isOpen, toggle, companyId, companyName, careerPageURL, status, linkedInUrl,
  companyLocation, refetchCompany, refetchAddress, addressList, companyAddressLoading }) => {
  const [modal, setModal] = useState(false);
  const [hasCarerPage, setHasCarerPage] = useState<boolean>(false)
  const [hasLinkedInUrl, setHasLinkedInUrl] = useState<boolean>(false)

  const { control, setValue, register, handleSubmit, formState: { errors } } = useForm();

  //mutation and queries
  const [updateAddress, { loading: addressUpdateLoading }] = useMutation(UPDATE_COMPANY_PRIMARY_ADDRESS)
  const [updateCompanyMutation, { loading: companyUpdateLoading }] = useMutation(UPDATE_COMPANY)

  //functions
  const updatePrimaryAddress = async (id) => {
    try {
      await updateAddress({
        variables: {
          updatePrimaryInput: {
            id,
            companyId
          }
        }
      })
    } catch (error) {
      toast.error("Error while updating company address")
    }
  }

  const updateCompany = async (data) => {
    try {
      const { hasLinkedIn, ...rest } = data
      await updateCompanyMutation({
        variables: {
          UpdateCompanyInput: {
            companyId, hasLinkedIn: !hasLinkedIn, status,
            ...rest
          }
        }
      })
    } catch (error) {
      toast.error("Error while updating company record")
    }
  }

  const onSubmit = async (data) => {
    try {
      const { companyLocation, ...rest } = data
      await updatePrimaryAddress(data?.companyLocation?.value)
      await updateCompany(rest)
      refetchCompany()
      refetchAddress()
      toast.success("Company updated successfully")
      toggle()
    } catch {
      toast.error("Error while updating company")
    }
  }

  const preSelectAddress = (location) => {
    refetchAddress()//refetch address list on adding new address
    setValue('companyLocation', {
      label: ['US', null, undefined, ''].includes(location?.country) ? `${location?.streetAddress}, ${location?.city}, ${location?.state}, ${location?.zipCode}, ${location?.country}` : `${location?.streetAddress}, ${location?.city}, ${location?.zipCode}, ${location?.country}`,
      value: location?.id,
    });
  };
  //select styles 
  const customStyles = {
    control: (styles, provided) => ({
      ...styles,
      fontSize: 12,
    }),

    container: (provided, state) => ({
      ...provided,
      fontSize: 12,
    }),

    option: (provided, state) => ({
      ...provided,
      fontSize: 12,
    }),
  };

  useEffect(() => {
    if (isOpen) {
      setHasCarerPage(!careerPageURL)
      setHasLinkedInUrl(!linkedInUrl)
    }
  }, [isOpen, addressList, careerPageURL, linkedInUrl])


  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className="revampDialog revampDialogWidth"
      centered
    >
      <ModalHeader className="pt-3">
        <div className="d-flex justify-content-between py-3">
          <h5>Update Company Details</h5>
        </div>
      </ModalHeader>
      <ModalBody className="pt-2 pb-2">
        <div className="pb-4">
          <Form>
            <Row>
              <Col sm="6" >
                <InputField
                  inputtype="text"
                  isRequired={true}
                  label="Name"
                  defaultValue={companyName}
                  inputid="name"
                  placeholder="Name"
                  maxLength={50}
                  inputRef={register({
                    required: {
                      value: true,
                      message: 'Name is required!',
                    },
                  })}
                  errMsg={errors?.name?.message}
                />
              </Col>

              <Col sm="6" >
                <Label >
                  Company Location <span className="error">*</span>
                </Label>
                <Controller
                  name="companyLocation"
                  defaultValue={companyLocation ? {
                    label:
                      ['US', null, undefined, ''].includes(companyLocation?.country) ?
                        `${companyLocation?.streetAddress}, ${companyLocation?.city}, ${companyLocation?.state}, ${companyLocation?.zipCode}, ${companyLocation?.country}` :
                        `${companyLocation?.streetAddress} ${companyLocation?.city}, ${companyLocation?.zipCode}, ${companyLocation?.country}`,
                    value: companyLocation?.id
                  } : null}
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: "Must Select Actual job location!",
                    },
                  }}
                  render={(field) => (
                    <Select
                      inputId="companyLocation"
                      {...field}
                      innerRef={register({
                        required: {
                          value: true,
                          message: "Must Select Actual job location!",
                        },
                      })}
                      isLoading={companyAddressLoading}
                      isDisabled={companyAddressLoading}
                      isSearchable={true}
                      options={addressList}
                      placeholder="location"
                      styles={customStyles}
                    />
                  )}
                />

                <small className="error">
                  {errors?.companyLocation?.message}
                </small>

                <div className="d-flex justify-content-end">
                  <Button
                    className="bg-transparent border-0 text-info p-0 mt-1"
                    onClick={() => setModal(!modal)}
                    disabled={companyId ? false : true}
                    title={
                      !companyId
                        ? "Kindly select a company for new address!"
                        : ""
                    }
                  >
                    Create New Address
                  </Button>
                </div>
              </Col>
            </Row>

            <Row>
              <Col sm="6">
                <Label>
                  Career Page URL{' '}
                  {!hasCarerPage && <span className="text-danger">*</span>}{' '}
                </Label>
                <Input
                  type="text"
                  name="careerPageURL"
                  placeholder="Career Page URL"
                  innerRef={register({
                    required: {
                      value: hasCarerPage ? false : true,
                      message: 'Career page URL is required!',
                    },
                    pattern: {
                      value: URL_REGEX,
                      message: 'Career page URL is not valid!',
                    },
                  })}
                  defaultChecked={true}
                  defaultValue={careerPageURL}
                  disabled={hasCarerPage}
                />

                {!hasCarerPage && (
                  <small className="error">
                    {errors?.careerPageURL?.message}
                  </small>
                )}

                <FormGroup className="ml-3 mb-1 mt-1 pl-1">
                  <Label className="m-0 w-100">
                    <Input
                      defaultChecked={hasCarerPage}

                      type="checkbox"
                      innerRef={register()}
                      name="hasCareerPageURL"
                      onChange={(e) => {
                        setValue('careerPageURL', null);
                        setHasCarerPage(e.target.checked);
                      }}
                      className="mt-1"
                    />
                    <span className="checkboxTextStyle w-100 pl-lg-0 pl-2">
                      Has No Career Page Url
                    </span>
                  </Label>
                </FormGroup>
              </Col>

              <Col sm="6">
                <Label>
                  LinkedIn URL{' '}
                  {!hasLinkedInUrl && <span className="text-danger">*</span>}{' '}
                </Label>
                <Input
                  type="text"
                  name="linkedInUrl"
                  placeholder="LinkedIn URL"
                  innerRef={register({
                    required: {
                      value: hasLinkedInUrl ? false : true,
                      message: 'LinkedIn URL is required!',
                    },
                    pattern: {
                      value: URL_REGEX,
                      message: 'LinkedIn URL is not valid!',
                    },
                  })}
                  defaultChecked={true}
                  defaultValue={linkedInUrl}
                  disabled={hasLinkedInUrl}
                />

                {!hasLinkedInUrl && (
                  <small className="error">
                    {errors?.linkedInUrl?.message}
                  </small>
                )}

                <FormGroup className="ml-3 mb-1 mt-1 pl-1">
                  <Label className="m-0 w-100">
                    <Input
                      defaultChecked={hasLinkedInUrl}
                      type="checkbox"
                      innerRef={register()}
                      name="hasLinkedIn"
                      onChange={(e) => {
                        setValue('linkedInUrl', null);
                        setHasLinkedInUrl(e.target.checked);
                      }}
                      className="mt-1"
                    />
                    <span className="checkboxTextStyle w-100 pl-lg-0 pl-2">
                      Has No LinkedIn Url
                    </span>
                  </Label>
                </FormGroup>
              </Col>

            </Row>
          </Form>
        </div>
      </ModalBody>

      <ModalFooter>
        <button
          type="button"
          onClick={toggle}
          className="buttonPrimary mr-3"
        >
          Cancel
        </button>
        <button
          className="btn btn-primary"
          type="submit"
          onClick={handleSubmit(onSubmit)}
        >
          {(companyUpdateLoading || addressUpdateLoading) && <Spinner size="sm" className="mr-2" />}
          Save
        </button>
      </ModalFooter>

      {modal && (
        <CreateAddressModal
          open={modal}
          toggle={() => setModal(!modal)}
          companyId={companyId}
          preSelect={preSelectAddress}
        />
      )}
    </Modal>
  )
}

export default EditCompanyInfoModal