import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

import AuthLayout from "../../components/layout/AuthLayout";

const ResetEmail = (): JSX.Element => {
  return (
    <AuthLayout
      title="Password Reset Email Sent"
      subTitle="Please check your email for instructions on how to reset your password."
      isThankYou
      isLoginPages
    >
      <Link className="link-btn" to="/">
        Back To Login?
      </Link>
    </AuthLayout>
  );
};

export default withRouter(ResetEmail);
