import {
  AlignmentType,
    Document,
    HeadingLevel,
    Paragraph,
    TabStopPosition,
    TabStopType,
    TextRun
  } from "docx";


  // Chatgpt Template
  export class DocumentCreator {
    // tslint:disable-next-line: typedef
    public create([resumeJSON]): Document {
      const document = new Document({
        sections: [
          {
            properties: {
              page: {
                  margin: {
                      top: `5mm`,
                      
                  },
              },
          },
            children: [
              new Paragraph({
                tabStops: [
                    {
                      type: TabStopType.RIGHT,
                      position: TabStopPosition.MAX
                    } 
                  ],
                  children: [
                    new TextRun({
                        text: resumeJSON?.name,
                        bold:true,
                        size:28,
                        font:'Times New Roman'
                     }),
                    new TextRun({
                        text: `\tPlease Contact RealREPP at [PHONE_NUMBER]`,
                        bold:true,
                        color: "AAAAAA",
                        size:16,
                        font:'Times New Roman',
                        italics: true, // Apply italic formatting

                      }),
                  ],
              }),
              new Paragraph(""),
              this.createHeading("Technical Skills"),
              ...this.createSkillSection(resumeJSON?.technicalSkills),
              // ...resumeJSON?.technicalSkills?.map(skills => {
              //     const arr: Paragraph[] = [];
  
              //     const bulletPoints = this.splitParagraphIntoBullets(
              //      skills
              //     );
              //     bulletPoints.forEach(bulletPoint => {
              //       arr.push(this.createBullet(bulletPoint));
              //     });
              //     return arr;
              //   })
              //   .reduce((prev, curr) => prev.concat(curr), []),
              this.createHeading("Personal Skills"),
              ...resumeJSON?.personalSkills?.map(skills => {
                  const arr: Paragraph[] = [];
  
                  const bulletPoints = this.splitParagraphIntoBullets(
                   skills
                  );
                  bulletPoints.forEach(bulletPoint => {
                    arr.push(this.createBullet(bulletPoint));
                  });
                  return arr;
                })
                .reduce((prev, curr) => prev.concat(curr), []),
                new Paragraph(""),
              this.createHeading("Experience"),
              ...resumeJSON?.experience?.map((position,index) => {
                  const arr: Paragraph[] = [];
                  arr.push(
                    this.createExperinceHeader(
                      position?.company,
                      position?.duration
                    )
                  );
                  arr.push(this.createRoleText(position?.position));
  
                  position?.responsibilities.forEach(bulletPoint => {
                    arr.push(this.createBullet(bulletPoint));
                  });

                  if(resumeJSON?.position?.length-1 !==index){
                    arr.push(new Paragraph(""));
                  }
  
                  return arr;
                })
                .reduce((prev, curr) => prev.concat(curr), []),
              this.createHeading("Education"),
              ...resumeJSON?.education?.map((education,index) => {
                const arr: Paragraph[] = [];

                arr.push(
                  this.createInstitutionHeader(
                    education?.degree,
                    education?.year
                  )
                );
                arr.push(new Paragraph({
                  text:education?.university
                }));
                
                arr.push(new Paragraph({
                  text:education?.major
                }));
                
                if(resumeJSON?.experience?.length-1 !==index){
                  arr.push(new Paragraph(""));
                }
                return arr;
              })
              .reduce((prev, curr) => prev.concat(curr), []),
              this.createHeading("Achievements"),
              ...resumeJSON?.achievements?.map((achievement) => {
                const arr: Paragraph[] = [];

                arr.push(
                  this.createAchievements(
                    achievement?.title,
                    achievement?.year
                  )
                );
                return arr;
              })
              .reduce((prev, curr) => prev.concat(curr), []),
            ]
          }
        ]
      });
  
      return document;
    }
  

  
    public createHeading(text: string): Paragraph {
    
      const headingRun = new TextRun({
        text: text,
        bold: true,
        size: 22,
        font: "Times New Roman",
        color: "000000", // Set color to black in HEX format

    });

    // Create the heading paragraph and add the text run
    const paragraph = new Paragraph({
        children: [headingRun],
        heading: HeadingLevel.HEADING_1,
        thematicBreak: true,
    });

    return paragraph;
    }
    

  
    public createInstitutionHeader(
      institutionName: string,
      dateText: string
    ): Paragraph {
      return new Paragraph({
        tabStops: [
          {
            type: TabStopType.RIGHT,
            position: TabStopPosition.MAX
          }
        ],
        children: [
          new TextRun({
            text: institutionName,
            bold: true
          }),
          new TextRun({
            text: `\t${dateText?dateText:''}`,
            bold: true
          })
        ]
      });
    }
    public createExperinceHeader(
      institutionName: string,
      dateText: string
    ): Paragraph {
      return new Paragraph({
        tabStops: [
          {
            type: TabStopType.RIGHT,
            position: TabStopPosition.MAX
          }
        ],
        children: [
          new TextRun({
            text: institutionName,
          }),
          new TextRun({
            text: `\t${dateText}`,
          })
        ]
      });
    }
  
    public createRoleText(roleText: string): Paragraph {
      return new Paragraph({
        children: [
          new TextRun({
            text: roleText,
            bold:true
          })
        ]
      });
    }
  
 
    public createBullet(text: string): Paragraph {
      return new Paragraph({
        text: text,
        bullet: {
          level: 0,

        }
      });
    }

    public createAchievements(text,year): Paragraph {
      return new Paragraph({
        text: year? `${text} - ${year}`:text ,
        bullet: {
          level: 0
        }
      });
    }
  

    public splitParagraphIntoBullets(text: string): string[] {
      return text.split("\n\n");
    }

    public createBulletListSkills(skillList: string[]): Paragraph{
      const bulletText = skillList.map((skill) => "\u2022 " + skill).join("   "); 
      console.log(bulletText)
      return new Paragraph({
        text: bulletText,
        alignment:  AlignmentType.LEFT
      });

    }

    public createSkillSection(skillList: string[]): Paragraph[] {
      const skillParagraphs: Paragraph[] = [];
    
      if (!skillList || skillList.length === 0) {
        return skillParagraphs;
      }
    
      const columnWidth = 40; // Adjust this value as needed
    
      if (skillList.length > 3) {
        for (let index = 0; index < skillList.length; index += 2) {
          const firstSkill = skillList[index] || '';
          const secondSkill = skillList[index + 1] || '';
    
          const skillsText = `• ${this.padString(firstSkill, columnWidth)}\t\t• ${secondSkill}`;
          skillParagraphs.push(new Paragraph(skillsText));
        }
      } else {
        for (const skill of skillList) {
          skillParagraphs.push(new Paragraph(`• ${skill}`));
        }
      }
    
      return skillParagraphs;
    }
    
    private padString(str, length) {
      return str.padEnd(length, ' ');
    }
 
  }
  