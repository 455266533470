import { useState } from "react";
import FileResizer from "react-image-file-resizer";
import { Link } from "react-router-dom";
import "./styles.scss";

const isFileImage = (file: File): boolean => {
  return file && file["type"].split("/")[0] === "image";
};

const resizeFile = (file) =>
  new Promise<Blob>((resolve) => {
    FileResizer.imageFileResizer(
      file,
      180,
      180,
      "JPEG" || "PNG",
      100,
      0,
      (uri) => {
        resolve(uri as Blob);
      },
      "file",
      180,
      180,
    );
  });

const UploadImage = ({
  src,
  setImage,
  setPreviewURL,
}: {
  src: string;
  setImage: Function;
  setPreviewURL: Function;
}) => {
  const [errorMessage, setErrorMessage] = useState("");
  return (
    <>
      <div className="user-info upload-btn-wrapper">
        <div className="user-avatar my-2">
          <img src={src} alt="avatar" />
        </div>
        <input
          type="file"
          name="image"
          onChange={async ({ target: { files } }) => {
            setErrorMessage("");
            if (files) {
              let [file]: any = files;
              if (!isFileImage(file)) {
                return setErrorMessage("File should be image!");
              }
              if (file.size > 5 * 1024 * 1024) {
                return setErrorMessage(
                  "File size it too large (should be 5MB)."
                );
              }
              let image: Blob = await resizeFile(file);
              setImage(image);
              let previewURL: string = window.URL.createObjectURL(image);
              setPreviewURL(previewURL);
            }
          }}
        />
        <Link to="#">Upload Image</Link>
      </div>
      {errorMessage && <div className="text-danger mx-4">{errorMessage}</div>}
    </>
  );
};

export default UploadImage;
