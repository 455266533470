import { useMutation } from "@apollo/client";
import { useState } from "react";
import { toast } from "react-toastify";
import { Input } from "reactstrap";
import ConfirmPopUp from "../../../components/ConfirmPopUp";
import { POST_ONE_JOB_ORDER } from "../gql";

const PostUnpost = ({
  _locationId,
  reFetchJobOrder,
  _postedAt,
  _city,
  _state,
  checkEditPermission,
}) => {
  const [postModal, setPostModal] = useState(false);
  const [postOneJobOrder] = useMutation(POST_ONE_JOB_ORDER);

  const updatePostedAt = async () => {
    if (_locationId) {
      const updateJobBulk = await postOneJobOrder({
        variables: { id: _locationId },
      });
      if (updateJobBulk?.data) {
        // // if we unpost a job, then _postedAt will still have value "Unpost". That's why I comment out if condition
        // if (!_postedAt) {
        //   reFetchJobOrder();
        // }
        reFetchJobOrder();

        toast.success("Job Order updated");
      }
      setPostModal(false);
    } else {
      toast.error(
        "To post job. It should have at least one advertise location. Please add city and state."
      );
      setPostModal(false);
    }
  };

  return (
    <div className="d-flex align-items-center pt-0">
      {/* <span className="color-default bg-transparent border-0">
          {_city}, {_state}
        </span> */}
      <span className="mr-1 text-warning font-weight-bold">Unpost</span>
      <label className="switch m-0">
        <Input
          disabled={!checkEditPermission}
          defaultChecked={!!_postedAt}
          type="checkbox"
          name="isPostedAt"
          value={_locationId}
          onChange={() => setPostModal(true)}
        />
        <span className="slider"></span>
      </label>
      <span className="ml-1 text-success font-weight-bold">Post</span>

      <ConfirmPopUp
        confirmText={`Are you sure you want to ${
          _postedAt ? "unpost" : "post"
        } this job?`}
        isOpen={postModal}
        toggle={setPostModal}
        confirmAction={updatePostedAt}
        modalHeading={`${_postedAt ? "Unpost" : "Post"} This Job?`}
        btnText="Confirm"
        btnColor="primary"
      />
    </div>
  );
};

export default PostUnpost;
