import { useContext, useEffect, useState } from "react";
import { Button, Card, CardBody, FormGroup } from "reactstrap";
import Select from "react-select";
import {
  GET_JOB_ORDER,
  UPDATE_RECRUITERS_ASSIGNED,
  REMOVE_SINGLE_RECRUITER,
} from "../gql";
import { useLazyQuery, useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { AppContext } from "../../../context";
import deleteIcon from "../../../assets/images/delete-field.svg";

import ConfirmPopUp from "../../../components/ConfirmPopUp";
import { customSelectDropDown } from "../../../components/styled/customSelect";

export default function RecruiterAssigned({
  recruitingLevel,
  jobOrderId,
  parallelTeamUsers,
  teamPermission,
  listOfRecruiters,
  adminExecutiveOwner,
  recruiterList,
  watchPermission,
  jobOwner,
  ownerOrShareeOfCompany,
  ...props
}) {
  const { theme } = useContext(AppContext);
  const [recruitersAssigned, setRecruitersAssigned] = useState<any>([]);
  const [allDelete, setAllDelete] = useState(false);
  const { user, userRoles } = useContext(AppContext);
  const [singleRecruiterValue, setSingleRecruiterValue] = useState<string>();
  const [recruiterName, setRecruiterName] = useState<string>();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [recruitersOption, setRecruitersOption] = useState<Array<any>>();

  const [_updateRecruiterAssigned] = useMutation(UPDATE_RECRUITERS_ASSIGNED);
  const [_removeSingleRecruiter, { loading: deleteLoading }] = useMutation(
    REMOVE_SINGLE_RECRUITER
  );
  const [getJobOrder] = useLazyQuery(GET_JOB_ORDER);

  const updateRecruiterAssignedHandler = async () => {
    const operation = await _updateRecruiterAssigned({
      variables: {
        list: {
          recruitersList: recruitersAssigned?.map((item: any) => item.value),
        },
        jobOrderId: jobOrderId,
      },
    });

    if (operation?.errors) {
      toast.error(operation?.errors[0]?.message);
    } else {
      getJobOrder({ variables: { id: jobOrderId } });
      setAllDelete(false);
      setRecruitersAssigned([]);
      if (allDelete) {
        toast.success("Successfully removed all assigned recruiters");
      } else {
        toast.success("Successfully updated");
      }
    }
  };

  const removeSingleRecruiterAssigned = async () => {
    const operation = await _removeSingleRecruiter({
      variables: {
        recruiterId: singleRecruiterValue,
        jobOrderId: jobOrderId,
      },
    });
    if (operation) {
      toast.success("Successfully removed a recruiter");
      setOpenModal(false);
    }
    getJobOrder({ variables: { id: jobOrderId } });
  };

  useEffect(() => {
    // let list = recruiterList?.filter((item: any) => item?.id !== jobOwner?.id);
    if (recruiterList?.length) {
      let newList: any = [];
      for (const item of recruiterList) {
        let user = listOfRecruiters?.find(
          (recruiter: any) =>
            recruiter?.user?.id === item?.id || item?.id === jobOwner?.id
        );
        if (!user) {
          newList.push(item);
        }
      }

      setRecruitersOption(newList);
    }

    // eslint-disable-next-line
  }, [recruiterList, listOfRecruiters]);

  const findingDisabling = (item): boolean => {
    let finalBoolean = false;
    let key = recruitingLevel;
    if (jobOwner?.id === item?.user?.id) {
      finalBoolean = true;
    }
    // if (item?.user?.id === user?.id) {
    //   return (finalBoolean = true);
    // }
    if (!(userRoles?.includes("ADMIN") || userRoles?.includes("EXECUTIVE"))) {
      switch (key) {
        case "OPEN":
          if (
            ownerOrShareeOfCompany === "NOT_OWNER_NOT_SHAREE" &&
            item?.user?.id !== user?.id
          ) {
            finalBoolean = true;
          }
          break;

        case "TEAM":
          if (
            ownerOrShareeOfCompany === "SHAREE" ||
            ownerOrShareeOfCompany === "OWNER" ||
            !(parallelTeamUsers && item?.user?.id !== user?.id)
          ) {
            finalBoolean = false;
          } else {
            finalBoolean = true;
          }
          break;

        case "PRIVATE":
          if (!adminExecutiveOwner) {
            finalBoolean = true;
          }
          break;

        default:
          break;
      }
    }
    if (
      userRoles?.includes("ADMIN") ||
      (userRoles?.includes("EXECUTIVE") && !userRoles?.includes("RECRUITER"))
    ) {
      finalBoolean = false;
    }
    return finalBoolean;
  };

  return (
    <>
      {props?.assignRecruiter && (
        <Card className="border-0" id="recruiterPermissionTooltip">
          <CardBody className="p-0">
            <div className="user-basic-form">
              <div>
                <div>
                  {listOfRecruiters?.length || jobOwner ? (
                    <div className="d-flex flex-wrap">
                      <>
                        {/* {jobOwner && (
                            <div className="d-flex align-items-center">
                              <p
                                className="p-2 mr-3 rounded bg-light"
                                style={{ fontSize: 14 }}
                              >
                                {jobOwner?.fullName}
                                <Button
                                  disabled={true}
                                  className="color-red bg-transparent border-0"
                                >
                                  <img
                                    style={{ marginRight: 0 }}
                                    src={deleteIcon}
                                    alt=""
                                    width="20"
                                    height="20"
                                  />
                                </Button>
                              </p>
                            </div>
                          )} */}
                      </>
                      {listOfRecruiters?.map((item: any) => (
                        <div className="d-flex align-items-center">
                          <p
                            className="p-2 mr-2 mb-3 rounded bg-light"
                            style={{ fontSize: 14 }}
                          >
                            {item?.user?.fullName}
                            <Button
                              onClick={() => {
                                setSingleRecruiterValue(item?.user?.id);
                                setRecruiterName(item?.user?.fullName);
                                setOpenModal(true);
                              }}
                              disabled={findingDisabling(item)}
                              className="color-red bg-transparent border-0"
                            >
                              <img
                                style={{ marginRight: 0 }}
                                src={deleteIcon}
                                alt=""
                                width="20"
                                height="20"
                              />
                            </Button>
                          </p>
                        </div>
                      ))}
                    </div>
                  ) : null}

                  {recruitingLevel === "OPEN" ? (
                    <div>
                      <FormGroup className="m-0">
                        <Select
                          options={
                            watchPermission
                              ? recruitersOption?.map((item: any) => ({
                                  label: item?.fullName,
                                  value: item?.id,
                                }))
                              : [
                                  {
                                    label: user?.fullName,
                                    value: user?.id,
                                  },
                                ]
                          }
                          onChange={(value: any) => {
                            setRecruitersAssigned([...value]);
                          }}
                          value={recruitersAssigned}
                          placeholder="Select Recruiter"
                          isSearchable={true}
                          isMulti
                          styles={{ ...customSelectDropDown(theme) }}
                        />
                      </FormGroup>
                    </div>
                  ) : recruitingLevel === "TEAM" ? (
                    <div>
                      <FormGroup className="m-0">
                        <Select
                          options={
                            watchPermission && teamPermission
                              ? recruitersOption?.map((item: any) => ({
                                  label: item?.fullName,
                                  value: item?.id,
                                }))
                              : !teamPermission &&
                                ownerOrShareeOfCompany === "SHAREE"
                              ? parallelTeamUsers
                                  ?.filter(
                                    (item: any) => item?.id !== jobOwner?.id
                                  )
                                  ?.map((item: any) => ({
                                    label: item?.fullName,
                                    value: item?.id,
                                  }))
                              : [
                                  {
                                    label: user?.fullName,
                                    value: user?.id,
                                  },
                                ]
                          }
                          onChange={(value: any) => {
                            setRecruitersAssigned([...value]);
                          }}
                          value={recruitersAssigned}
                          isDisabled={
                            watchPermission &&
                            ownerOrShareeOfCompany === "SHAREE"
                              ? false
                              : !teamPermission
                          }
                          placeholder="Select..."
                          isSearchable={true}
                          isMulti
                        />
                        {!parallelTeamUsers?.length &&
                          (ownerOrShareeOfCompany === "OWNER" ||
                            ownerOrShareeOfCompany === "SHAREE") && (
                            <small className="text-danger pt-2">
                              You are not in any team yet
                            </small>
                          )}
                      </FormGroup>
                    </div>
                  ) : (
                    <div>
                      <FormGroup className="m-0">
                        <Select
                          options={recruitersOption?.map((item: any) => ({
                            label: item?.fullName,
                            value: item?.id,
                          }))}
                          onChange={(value: any) => {
                            setRecruitersAssigned([...value]);
                          }}
                          isDisabled={
                            recruitingLevel === "PRIVATE"
                              ? !adminExecutiveOwner
                              : false
                          }
                          value={recruitersAssigned}
                          placeholder="Select..."
                          isSearchable={true}
                          isMulti
                        />
                      </FormGroup>
                    </div>
                  )}
                </div>

                <div className="d-flex justify-content-end jobOrderRevamp px-0">
                  {recruitersAssigned?.length ? (
                    <button
                      className="buttonGenericStyle filledButton"
                      disabled={
                        recruitingLevel === "TEAM"
                          ? watchPermission &&
                            ownerOrShareeOfCompany === "SHAREE"
                            ? false
                            : !teamPermission
                          : recruitingLevel === "PRIVATE"
                          ? !adminExecutiveOwner
                          : false
                      }
                      onClick={updateRecruiterAssignedHandler}
                      type="button"
                    >
                      Add {recruitersAssigned?.length} Recruiters
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="buttonGenericStyle filledButton"
                      disabled={true}
                    >
                      Add {recruitersAssigned?.length > 0} Recruiters
                    </button>
                  )}
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
      )}

      <ConfirmPopUp
        confirmText={`Are you sure you want to delete ${recruiterName} from recruiter assigned list?`}
        isOpen={openModal}
        toggle={() => setOpenModal(!openModal)}
        confirmAction={removeSingleRecruiterAssigned}
        modalHeading={`Removing ${recruiterName}`}
        btnText="Confirm"
        btnColor="primary"
        loading={deleteLoading}
        disabled={deleteLoading}
      />
    </>
  );
}
