import { useHistory, useParams } from "react-router";
import { useLocation } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";
import { ParamsType } from "../../../interfaces";
import { CANDIDATE_SUBMISSION_ROUTE, COMPANY_DETAILS_ROUTE, VIEW_JOB_ORDER_ROUTE, workplaceTypesDto } from "../../../utils/constant";
import { pushInHistory } from "../../../utils/helper";
import { IComingState } from "../../agreements/interfaces";

const SubmissionJobOrderWidget = ({ jobOrder: stats }) => {
  const history = useHistory();
  const location = useLocation<IComingState>();
  const { id } = useParams<ParamsType>()

  return (
    <Row>
      <Col xl="4" lg="6" md="6" sm="12" xs="12">
        <div className="d-flex justify-content-between align-items-center mb-2 contentGridLabel">
          <label>Job Title </label>
          <div className="contentGridColumn">
            <Button
              onClick={() => {
                history?.push(`${VIEW_JOB_ORDER_ROUTE}r/${stats?.id}`);
              }}
              className="bg-transparent border-0 text-dark textButton p-0"
            >
              {stats?.jobTitle || "--"}
            </Button>
          </div>
        </div>
      </Col>

      <Col xl="4" lg="6" md="6" sm="12" xs="12">
        <div className="d-flex justify-content-between align-items-baseline mb-2 contentGridLabel">
          <label>Company Name</label>

          <div className="contentGridColumn">
            {!!stats?.company?.name ?
              <h6
                onClick={() => {
                  history?.push({
                    pathname: `${COMPANY_DETAILS_ROUTE}/${stats?.company?.id}`,
                    state: {
                      backUrl: `${CANDIDATE_SUBMISSION_ROUTE}/${id}`,
                      searchParameters: location.search
                    }
                  });

                  pushInHistory(`${CANDIDATE_SUBMISSION_ROUTE}/${id}`)
                }}
                className="p-0 route-link"
              >
                {stats?.company?.name}
              </h6> : "--"
            }
          </div>
        </div>
      </Col>

      <Col xl="4" lg="6" md="6" sm="12" xs="12">
        <div className="d-flex justify-content-between align-items-center mb-2 contentGridLabel">
          <label>Company Owner</label>
          <div className="contentGridColumn">
            <h6>{stats?.company?.owner?.fullName || "--"}</h6>
          </div>
        </div>
      </Col>

      <Col xl="4" lg="6" md="6" sm="12" xs="12">
        <div className="d-flex justify-content-between align-items-center mb-2 contentGridLabel">
          <label>Job Location</label>
          <div className="contentGridColumn">
            <h6>
              {stats?.companyLocation?.city ||
                "--" +
                `${stats?.companyLocation?.city ? "," : ""}` +
                (stats?.companyLocation?.state || "") ||
                "--"}
            </h6>
          </div>
        </div>
      </Col>

      <Col xl="4" lg="6" md="6" sm="12" xs="12">
        <div className="d-flex justify-content-between align-items-start mb-2 contentGridLabel">
          <label>Workplace Type</label>
          <div className="contentGridColumn">
            <h6>{stats?.allowRemote ? "Yes" : "No"}</h6>
            {stats?.workplaceTypes? workplaceTypesDto[stats.workplaceTypes] : '--'}

          </div>
        </div>
      </Col>

      <Col xl="4" lg="6" md="6" sm="12" xs="12">
        <div className="d-flex justify-content-between align-items-center mb-2 contentGridLabel">
          <label>Job Status</label>
          <div className="contentGridColumn">
            <h6>{stats?.status || "--"}</h6>
          </div>
        </div>
      </Col>
      {/* <div className="d-flex justify-content-between align-items-center mb-2 contentGridLabel">
        <label># of candidates in pipeline</label>
        <h6>{stats?.jobOrderSummary?.candidatePipelineCount || 0}</h6>
      </div>
      <div className="d-flex justify-content-between align-items-center mb-2 contentGridLabel">
        <label># of days since last activity</label>
        <h6>{stats?.jobOrderSummary?.daySinceLastActivity || 0}</h6>
      </div>
      <div className="d-flex justify-content-between align-items-center mb-2 contentGridLabel">
        <label># of job application</label>
        <h6>{stats?.jobOrderSummary?.jobApplicantCount || 0}</h6>
      </div>
      <div className="d-flex justify-content-between align-items-center mb-2 contentGridLabel">
        <label># of candidates in candidate pool</label>
        <h6>{stats?.jobOrderSummary?.candidatePoolCount || 0}</h6>
      </div> */}
    </Row>
  );
};

export default SubmissionJobOrderWidget;
