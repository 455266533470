import { useLazyQuery, useMutation } from "@apollo/client";
import { useContext, useEffect, useState } from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import Loader from "../../../components/layout/Loader";
import TableComponent from "../../../components/layout/TableComponent";
import { compactObject } from "../../../utils/commonFn";
import Pagination from "../../../components/layout/Pagination";
import {
  Button,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Badge,
} from "reactstrap";
import InputField from "../../../components/layout/InputField";
import { JobOrderStatusInApplicant, JOB_APPLICANTS_ROUTE, JOB_ORDER_ROUTE, PAGE_LIMIT, VIEW_JOB_ORDER_ROUTE } from "../../../utils/constant";
import SEARCH_IMG from "../../../assets/images/search.svg";
import "../../company/company.scss";
import NoDataFound from "../../../components/layout/NoDataFound";
import {
  GET_ALL_JOB_ORDERS,
  HOT_JOB_ORDER,
  POST_JOB_ORDER,
  UNHOT_JOB_ORDER,
  UNPOST_JOB_ORDER,
} from "../../job-order/gql/index";

import AsyncCompanyDropdown from "../../../components/AsyncCompanySelect";
import CustomButton from "../../../components/layout/CustomButton";
import { AppContext } from "../../../context";
import SearchModal from "./Components/SearchModal";
import { pushInHistory } from "../../../utils/helper";

const ACTIVE_WIDGETS_HEADER = [
  "Title",
  "Company",
  "Company Owner",
  "Location",
  "Status",
  "Main Contact",
  "Work Type",
  "Total Applicants",
  "Allow Remote",
  "Action",
];

let time = 0;

const JobListing = ({ location, history }: RouteComponentProps) => {
  const page = new URLSearchParams(location.search).get("currentPage") || "1";
  const _currentPage: number = parseInt(page);
  const [currentBulkAction, setCurrentBulkAction] = useState("");
  const [currentPage, setCurrentPage] = useState(_currentPage);
  const [limit, setLimit] = useState(10);
  const [searchParams, setSearchParams] = useState<string>("");
  const [statusFilter, setStatusFilter] = useState<string>("");
  const [filterCheck, setFilterCheck] = useState<boolean>(false);
  const [selectedCompanyId, setSelectedCompanyId] = useState<string>("");
  const [checkBoxArray, setCheckBoxArray] = useState<Array<string>>([]);
  const { user, userRoles } = useContext(AppContext);
  const [searchToggle, setSearchToggle] = useState<boolean>(false);
  const [jobId, setJobId] = useState<string>();
  const [postJobOrder] = useMutation(POST_JOB_ORDER);
  const [unPostJobOrder] = useMutation(UNPOST_JOB_ORDER);
  const [hotJobOrder] = useMutation(HOT_JOB_ORDER);
  const [unHotJobOrder] = useMutation(UNHOT_JOB_ORDER);

  const [fetchAllJobOrders, { loading, error, data }] =
    useLazyQuery(GET_ALL_JOB_ORDERS);

  const fetchCompanies = () => {
    // setQueryParams({ currentPage, searchParams, statusFilter });
    if (!searchParams || searchParams?.length > 2) {
      fetchAllJobOrders({
        variables: {
          limit,
          page: currentPage,
          searchText: searchParams,
          statusSearch: statusFilter,
          companyId: selectedCompanyId,
          myJobOrder: filterCheck,
        },
      });
    }
  };

  const handleEveryBulkAction = () => {
    if (currentBulkAction === "POST") {
      postJobOrderHandler();
    } else if (currentBulkAction === "UNPOST") {
      unpostJobOrderHandler();
    } else if (currentBulkAction === "HOT") {
      hotJobOrderHandler();
    } else {
      unhotJobOrderHandler();
    }
    setCheckBoxArray([]);
    setCurrentBulkAction("");
  };

  const postJobOrderHandler = async () => {
    const updateJobBulk = await postJobOrder({
      variables: { ids: { bulkJobs: checkBoxArray } },
    });

    if (updateJobBulk?.data) {
      toast.success("Bulk Job order updated");
      fetchCompanies();
    }
  };

  const unpostJobOrderHandler = async () => {
    const updateJobBulk = await unPostJobOrder({
      variables: { ids: { bulkJobs: checkBoxArray } },
    });

    if (updateJobBulk?.data) {
      toast.success("Bulk Job order updated");
      fetchCompanies();
    }
  };

  const hotJobOrderHandler = async () => {
    const updateJobBulk = await hotJobOrder({
      variables: { ids: { bulkJobs: checkBoxArray } },
    });

    if (updateJobBulk?.data) {
      toast.success("Bulk Job order updated");
      fetchCompanies();
    }
  };

  const unhotJobOrderHandler = async () => {
    const updateJobBulk = await unHotJobOrder({
      variables: { ids: { bulkJobs: checkBoxArray } },
    });

    if (updateJobBulk?.data) {
      toast.success("Bulk Job order updated");
      fetchCompanies();
    }
  };

  const setQueryParams = (searchParams: any) => {
    const criteria = compactObject({
      currentPage,
      searchParams,
      statusFilter,
    });
    const urlQueryParams: any = [];
    if (Object.keys(criteria).length) {
      for (const key in criteria) {
        if (
          Object.prototype.hasOwnProperty.call(criteria, key) &&
          criteria[key]
        ) {
          urlQueryParams.push(`${key}=${criteria[key]}`);
        }
      }
      history.push(`/advance-search-job-candidate?${urlQueryParams.join("&")}`);
    }
  };

  const searchControl = (value: any) => {
    setQueryParams(value);
    setSearchParams(value);
    setCurrentPage(1);
    setLimit(25);
    if (!value || value?.length > 2) {
      window.clearTimeout(time);
      time = window.setTimeout(() => {
        fetchAllJobOrders({
          variables: {
            limit,
            page: currentPage,
            searchText: value,
            statusSearch: statusFilter,
            companyId: selectedCompanyId,
            myJobOrder: filterCheck,
          },
        });
      }, 1000);
    }
  };

  useEffect(() => {
    if (data?.fetchAllJobOrders?.jobOrders?.length) {
      data?.fetchAllJobOrders?.jobOrders
        ?.filter((item) => item?.isHotJob === true && `${item.id}`)
        .map((val) => val?.id);
    }

    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    fetchCompanies();
    // eslint-disable-next-line
  }, [statusFilter, currentPage, limit, selectedCompanyId, filterCheck]);

  const handleCheckBoxes = (value: string) => {
    const currentIndex = checkBoxArray.indexOf(value);
    const newChecked = [...checkBoxArray];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setCheckBoxArray(newChecked);
  };

  const checkEditPermission = (company: any) => {
    if (company) {
      if (userRoles?.includes("ADMIN") || userRoles?.includes("EXECUTIVE")) {
        return true;
      }
      if (company?.owner?.id === user?.id) {
        return true;
      }
      if (company?.ownerAndSharees?.length) {
        // eslint-disable-next-line
        let currentUser = company?.ownerAndSharees?.find((item) => {
          if (item.ownerType === "SHAREE" && item.owner?.id === user?.id)
            return item;
        });
        if (currentUser) {
          return true;
        } else {
          return false;
        }
      }
      if (company?.status === "DO_NOT_CONTACT") {
        return true;
      }
    }
    return false;
  };

  const searchToggleHandler = () => {
    setSearchToggle(!searchToggle);
  };

  return (
    <div className="admin-tabs team-tabs">
      <ToastContainer autoClose={3000} />

      <div className="primaryHeading primaryHeaderSpacing mobileResponsiveFlexAlign justify-content-between">
        <h5 className="m-0">Jobs Advance Search</h5>

        <div className="search-filter">
          <div>
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <img src={SEARCH_IMG} alt="search" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                type="text"
                placeholder="Search by job title."
                value={searchParams}
                onChange={(e) => {
                  searchControl(e.target.value);
                }}
                className="search-by-company"
              />
            </InputGroup>

            <small className="text-info">
              {searchParams &&
                searchParams.length <= 2 &&
                " Please add more than two characters"}
            </small>
          </div>

          <div className="filter-status">
            <div className="mr-3">
              <AsyncCompanyDropdown
                width={"15rem"}
                placeholder="Filter By Company name"
                onChange={(value) => {
                  setSelectedCompanyId(value?.value);
                  setCurrentPage(1);
                  setLimit(25);
                }}
              />
            </div>

            <div className="filter-by">
              <InputField
                inputtype="select"
                selectItems={JobOrderStatusInApplicant}
                inputid="status"
                placeholder="Status"
                inputMethod={(value) => {
                  setStatusFilter(value);
                  setCurrentPage(1);
                  setLimit(25);
                }}
                inputValue={statusFilter}
              />
              <button
                style={{
                  backgroundColor: `${filterCheck ? "#f4f3f3" : "white"}`,
                  color: `${filterCheck ? "#eaa827" : "#495057"}`,
                  border: `${"1px solid #ced4da"}`,
                }}
                className="filter-job-order"
                type="button"
                onClick={() => setFilterCheck(!filterCheck)}
              >
                My Job Orders
              </button>

              <button
                className="buttonGenericStyle filledButton ml-3"
                onClick={() => {
                  setFilterCheck(false);
                  setSearchParams("");
                  setStatusFilter("");
                  fetchAllJobOrders({
                    variables: {
                      limit,
                      page: currentPage,
                      searchText: "",
                      statusSearch: "",
                      myJobOrder: false,
                      companyId: selectedCompanyId,
                    },
                  });
                }}
              >
                Clear
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="section-header">
        <h5 className="m-0">Jobs</h5>
      </div>
      <div className="mr-2">
        {statusFilter === "MY_JOB_ORDERS" && (
          <CustomButton
            buttonText={`Update ${currentBulkAction === "POST"
                ? "Post Job"
                : currentBulkAction === "UNPOST"
                  ? "Un Post Job"
                  : currentBulkAction === "HOT"
                    ? "Mark as Hot Job"
                    : currentBulkAction === "UNHOT"
                      ? "No Longer Hot Job"
                      : ""
              }`}
            buttonType="button"
            buttonColor="primary"
            disabled={checkBoxArray?.length && currentBulkAction ? false : true}
            className="save-changes"
            buttonClick={handleEveryBulkAction}
          />
        )}
      </div>

      <div className="table-responsive">
        <TableComponent tableHeader={ACTIVE_WIDGETS_HEADER}>
          {loading && (
            <tbody>
              <tr>
                <td colSpan={14}>
                  <Loader loaderClass="sm-height " />
                </td>
              </tr>
            </tbody>
          )}
          {!loading && !data?.fetchAllJobOrders?.jobOrders?.length && (
            <tbody>
              <tr>
                <td colSpan={14} className="empty-table-td">
                  <NoDataFound text="No Job Found!" />
                </td>
              </tr>
            </tbody>
          )}
          <tbody>
            {data &&
              data?.fetchAllJobOrders &&
              data?.fetchAllJobOrders?.jobOrders?.map(
                (item: any, index: number) => {
                  return (
                    <tr key={index} className="job-orders-tbody">
                      {statusFilter === "MY_JOB_ORDERS" && (
                        <td>
                          <Input
                            style={{
                              margin: "-2px 0px 0px 10px",
                              position: "inherit",
                            }}
                            bsSize="sm"
                            type="checkbox"
                            // value={item?.id}
                            disabled={
                              statusFilter === "MY_JOB_ORDERS" &&
                                (currentBulkAction === "POST" ||
                                  currentBulkAction === "UNPOST") &&
                                !item?.advertisedLocations?.length
                                ? true
                                : false
                            }
                            onChange={() => handleCheckBoxes(item?.id)}
                          />
                        </td>
                      )}

                      <td
                        className="text-info cursor-pointer"
                        onClick={() => {
                          history.push(`${VIEW_JOB_ORDER_ROUTE}/${item?.id}`);
                        }}
                      >
                        {item.jobTitle}
                      </td>

                      <td>{item?.company?.name || "--"}</td>
                      <td>{item?.company?.owner?.fullName || "--"}</td>
                      <td>
                        {item?.companyLocation?.city &&
                          item?.companyLocation?.state
                          ? item?.companyLocation?.city +
                          ", " +
                          item?.companyLocation?.state
                          : "--"}
                        <div className="subtitleText">
                          {item?.allowRemote && "Remote"}
                        </div>
                      </td>
                      <td>
                        <Badge
                          color={
                            item.status === "ACTIVE"
                              ? "success"
                              : item.status === "CLOSE"
                                ? "warning"
                                : item.status === "DRAFT"
                                  ? "primary"
                                  : ""
                          }
                          className="text-capitalize"
                        >
                          {item.status}
                        </Badge>
                      </td>
                      <td>{item?.mainContact?.contact?.fullName || "--"}</td>
                      <td>{item?.workType || "--"}</td>

                      <td>{item?.totalApplications} </td>

                      <td>{item?.allowRemote ? "yes" : "no" || "--"}</td>

                      <td>
                        <div className="d-flex align-items-center">
                          <div>
                            <Link
                              onClick={() => pushInHistory(JOB_ORDER_ROUTE, location.search)}
                              to={`${VIEW_JOB_ORDER_ROUTE}/${item?.id}`}
                              className="m-2"
                            >
                              View
                            </Link>
                            {checkEditPermission(item?.company) ||
                              item?.company?.ownerAndSharees?.filter(
                                (item: any) => item?.owner?.id === user?.id
                              ).length ? (
                              <Link 
                              onClick={() => pushInHistory(JOB_ORDER_ROUTE, location.search)}
                              to={`/update-job-order/${item?.id}`}>
                                Edit
                              </Link>
                            ) : null}

                            <Button
                              className="bg-transparent border-0 color-default"
                              size="sm"
                              onClick={() => {
                                searchToggleHandler();
                                setJobId(item?.id);
                              }}
                            >
                              Candidates search filters
                            </Button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                }
              )}
          </tbody>
        </TableComponent>
      </div>
      {data?.fetchAllJobOrders?.jobOrders?.length ? (
        <div className="users-pagination">
          <Pagination
            onPageChange={(pageClicked: number) => {
              setCurrentPage(pageClicked);
              history.push(`${JOB_APPLICANTS_ROUTE}?currentPage=${pageClicked}`);
            }}
            pageCount={Math.ceil(data?.fetchAllJobOrders?.count / limit)}
            currentPage={currentPage}
          />

          <div className="d-flex align-items-center">
            <div className="pagination-display paginationItemsSelect">
              <p className="m-0">
                {(currentPage - 1) * limit + 1} to{" "}
                {Math.min(currentPage * limit, data?.fetchAllJobOrders?.count)}{" "}
                of {data?.fetchAllJobOrders?.count}
              </p>

              <div className="divider"></div>

              <label>Display</label>

              <InputField
                label=""
                inputtype="select"
                selectItems={PAGE_LIMIT.filter((item) => item.value !== limit)}
                inputid="limit"
                placeholder={limit.toString()}
                inputMethod={(value) => {
                  setCurrentPage(1);
                  setLimit(parseInt(value));
                }}
              />
            </div>
          </div>

          <SearchModal
            modal={searchToggle}
            toggle={searchToggleHandler}
            jobId={jobId}
          />
        </div>
      ) : null}
      {error?.graphQLErrors[0]?.message}
    </div>
  );
};

export default JobListing;
