import { useLazyQuery, useMutation } from "@apollo/client";
import { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { toast } from "react-toastify";

import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import ConfirmPopUp from "../../../components/ConfirmPopUp";

import NoDataFound from "../../../components/layout/NoDataFound";
import TableComponent from "../../../components/layout/TableComponent";
import { formatDateUS } from "../../../utils/commonFn";
import { IdealWorkType } from "../../../utils/constant";

import "../candidate.scss";
import {
  CREATE_EMPLOYMENT_HISTORY,
  GET_EMPLOYMENT_HISTORY,
  UPDATE_EMPLOYMENT_RECENT,
} from "../gql";
import EditEmploymentHistory from "./EditEmpHistory";
import DeleteEmpHistory from "./DeleteEmpHistory";
import ViewDesciption from "./ViewDescriptionEmpHistory";

import CLOSE_ICON from "../../../assets/images/close-icon.svg";
import { customSelectDropDown } from "../../../components/styled/customSelect";
import { AppContext } from "../../../context";

const EMPLOYMENT_HEADER = [
  "Employer Name",
  "Job Title",
  "Work Type",
  "Start Date",
  "End Date",
  "RECENT",
  "ACTIONS",
];

const EmploymentHistory = ({ candidateId, addEmployment = true }: any) => {
  // states and constants
  const [modal, setModal] = useState(false);
  const modalToggle = () => {
    refetch()
    setModal(!modal);
  }
  const [checked, setChecked] = useState(false);
  const [confirmPopup, setConfirmPopUp] = useState(false);
  const [employmentId, setEmploymentId] = useState("");
  const [recentChecked, setRecentChecked] = useState(null);

  // api calls
  const [_createEmployment, { loading }] = useMutation(CREATE_EMPLOYMENT_HISTORY);
  const [updateEmploymentRecent, { loading: recentEmploymentUpdateLoading }] = useMutation(UPDATE_EMPLOYMENT_RECENT);
  const [getEmployments, { data, loading: employmentsLoading, refetch }] = useLazyQuery(GET_EMPLOYMENT_HISTORY);

  // form methods
  const { errors, handleSubmit, register, control } = useForm();

  const { theme } = useContext(AppContext)

  const createEmployHistory = async (values) => {
    values.candidateId = candidateId;
    values.workType = values?.workType?.value;
    if (checked) {
      values.endedAt = null;
    }
    if (!checked && !values.endedAt) {
      toast.error("End date is required");
      return;
    }
    let res = await _createEmployment({ variables: { ...values } });
    if (res?.data) {
      toast.success("Employment History Successfully Created!");
      modalToggle();
      setChecked(false);
      getEmployments({ variables: { id: candidateId } });
    } else if (res?.errors) {
      toast.error(res?.errors[0]?.message);
    }
  };

  const updateRecentChecked = async () => {
    const res = await updateEmploymentRecent({
      variables: { employmentId, candidateId, recentChecked },
    });
    if (res?.errors) {
      toast.error(res?.errors[0]?.message);
    }
    setConfirmPopUp(false);
    getEmployments({ variables: { id: candidateId } });
  };

  const updateUI = () => {
    if (candidateId) {
      getEmployments({ variables: { id: candidateId } });
    }
  };

  useEffect(() => {
    if (candidateId) {
      getEmployments({ variables: { id: candidateId } });
    }
    // eslint-disable-next-line
  }, [candidateId]);

  return (
    <div>
      {addEmployment && (
        <div className="d-flex justify-content-end pb-3">
          <button
            className="buttonGenericStyle filledButton"
            type="button"
            onClick={modalToggle}
          >
            Add New Employment History
          </button>
        </div>
      )}

      <div className="table-responsive">
        <TableComponent tableHeader={EMPLOYMENT_HEADER}>
          <tbody>
            {!employmentsLoading && data?.getEmploymentHistory?.map((item) => (
              <tr key={item?.id}>
                <td>{item?.companyName}</td>
                <td>{item?.jobTitle}</td>
                <td>{item?.workType}</td>
                <td>
                  {item?.startedAt ? formatDateUS(item?.startedAt) : "--"}
                </td>
                <td>{item?.endedAt ? formatDateUS(item?.endedAt) : "--"}</td>
                <td className="pb-2">
                  <label className="switch">
                    <Input
                      type="checkbox"
                      name="mostRecentExperience"
                      defaultChecked={item?.mostRecentExperience}
                      onChange={(e: any) => {
                        setConfirmPopUp(!confirmPopup);
                        setEmploymentId(item.id);
                        setRecentChecked(e.target.checked);
                      }}
                    />
                    <span className="slider"></span>
                  </label>
                </td>
                <td className="pl-1">
                  <div className="d-flex">
                    <EditEmploymentHistory
                      candidateId={candidateId}
                      updateUI={updateUI}
                      item={item}
                    />
                    <ViewDesciption
                      candidateId={candidateId}
                      updateUI={updateUI}
                      item={item}
                    />
                    <DeleteEmpHistory id={item?.id} updateUI={updateUI} />
                  </div>
                </td>
              </tr>
            ))}
            {!data?.getEmploymentHistory?.length && (
              <tr>
                <td colSpan={7} className="empty-table-td">
                  <NoDataFound text="No employment history found!" />
                </td>
              </tr>
            )}
          </tbody>
        </TableComponent>
      </div>

      <Modal
        isOpen={modal}
        toggle={modalToggle}
        className="revampDialog revampDialogWidth"
      >
        <ModalHeader toggle={modalToggle}>
          Employment History
          <Button
            className="p-0 bg-transparent border-0"
            onClick={() => {
              modalToggle();
              setChecked(false);
            }}
          >
            <img src={CLOSE_ICON} alt="close" />
          </Button>
        </ModalHeader>
        <ModalBody>
          <Form>
            <div>
              <Row>
                <Col md="6" sm="12" xs="12">
                  <FormGroup>
                    <Label>
                      Company Name<span className="text-danger">*</span>
                    </Label>
                    <Input
                      type="text"
                      name="companyName"
                      placeholder="Company Name"
                      innerRef={register({
                        required: {
                          value: true,
                          message: "Company name is required!",
                        },
                      })}
                    />
                    <small className="text-danger">
                      {errors?.companyName?.message}
                    </small>
                  </FormGroup>
                </Col>
                <Col md="6" sm="12" xs="12">
                  <FormGroup>
                    <Label>
                      Job Title<span className="text-danger">*</span>
                    </Label>
                    <Input
                      type="text"
                      name="jobTitle"
                      placeholder="Job Title"
                      innerRef={register({
                        required: {
                          value: true,
                          message: "Job title is required!",
                        },
                      })}
                    />
                    <small className="text-danger">
                      {errors?.jobTitle?.message}
                    </small>
                  </FormGroup>
                </Col>
                <Col md="6" sm="12" xs="12">
                  <FormGroup>
                    <Label>
                      Work Type<span className="text-danger">*</span>
                    </Label>
                    <Controller
                      name="workType"
                      as={Select}
                      rules={{ required: "Please select work type!" }}
                      options={IdealWorkType}
                      control={control}
                      placeholder="Work Type"
                      styles={{ ...customSelectDropDown(theme) }}
                    />
                    <small className="text-danger">
                      {errors?.workType?.message}
                    </small>
                  </FormGroup>
                </Col>
                <Col md="6" sm="12" xs="12">
                  <FormGroup>
                    <Label>
                      Start Date<span className="text-danger">*</span>
                    </Label>
                    <Input
                      type="date"
                      name="startedAt"
                      innerRef={register({
                        required: {
                          value: true,
                          message: "Start date is required!",
                        },
                      })}
                    />
                    <small className="text-danger">
                      {errors?.startedAt?.message}
                    </small>
                  </FormGroup>
                </Col>
                <Col md="6" sm="12" xs="12">
                  <FormGroup>
                    <Label> End Date </Label>
                    <Input
                      disabled={checked}
                      type="date"
                      name="endedAt"
                      innerRef={register()}
                    />
                  </FormGroup>
                </Col>
                <Col md="6" sm="12" xs="12">
                  <FormGroup className="currentlyWorkingCheckbox">
                    <Input
                      className="mb-3 mr-2"
                      style={{ position: "static", marginLeft: "0.1rem" }}
                      type="checkbox"
                      onChange={() => setChecked(!checked)}
                      name="currentlyWorking"
                      innerRef={register()}
                    />
                    <Label htmlFor="currentlyWorking">Currently Working</Label>
                  </FormGroup>
                </Col>

                <Col md="12" sm="12" xs="12">
                  <FormGroup>
                    <Label>Description</Label>
                    <textarea
                      className="form-control"
                      style={{ minHeight: "150px" }}
                      // rows={4}
                      id="description"
                      ref={register({ required: false })}
                      name="description"
                      // defaultValue={item?.description}
                      placeholder={"No description Found"}
                    ></textarea>
                  </FormGroup>
                </Col>
              </Row>

              <div className="d-flex align-items-center justify-content-end pt-3">
                <button
                  onClick={() => {
                    modalToggle();
                    setChecked(false);
                  }}
                  type="button"
                  className="buttonPrimary mr-3"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  color="success"
                  className="btn btn-primary"
                  onClick={handleSubmit(createEmployHistory)}
                >
                  {loading && <Spinner size="sm" className="mr-2" />}
                  Create
                </button>
              </div>
            </div>
          </Form>
        </ModalBody>
      </Modal>

      <ConfirmPopUp
        confirmText={`Are you sure you want to ${!recentChecked ? "remove" : "set"
          } it ${!recentChecked ? "from" : "as"} most Recent?`}
        isOpen={confirmPopup}
        toggle={() => {
          refetch()
          setConfirmPopUp(!confirmPopup);
        }}
        disabled={recentEmploymentUpdateLoading}
        loading={recentEmploymentUpdateLoading}
        confirmAction={updateRecentChecked}
        modalHeading="Most Recent Employment"
        btnText="Confirm"
        btnColor="primary"
      />
    </div>
  );
};

export default EmploymentHistory;
