import CreateAccountManager from "./CreateAccountManager";

export default function CreatePlacementStepTwo({
  accountManagers,
  accountManagerListChange,
  accountManagerList,
  errors,
  readonly,
  defaultPercentage,
  defaultOwner,
}) {
  return (
    <div>
      <CreateAccountManager
        readonly={readonly}
        defaultPercentage={defaultPercentage}
        defaultOwner={defaultOwner}
        accountManagerList={accountManagerList}
        accountManagerListChange={accountManagerListChange}
        accountManagers={accountManagers}
        errors={errors}
      />
    </div>
  );
}
