import { useLazyQuery, useMutation } from "@apollo/client";
import { useContext, useEffect, useState } from "react";
import Select from "react-select";
import { Button, Col, Input, Label, Row, FormGroup } from "reactstrap";
import { ADD_CANDIDATE_EMAIL, CANDIDATE_EMAILS } from "../../gql";
import { toast } from "react-toastify";
import { EMAIL_PATTERN } from "../../../../../utils/constant";
import { AppContext } from "../../../../../context";
import { customSelectDropDown } from "../../../../../components/styled/customSelect";

export default function CandidateBox({
  candidateName,
  candidateId,
  pipeSelectedCandidateEmails,
}) {
  const { theme } = useContext(AppContext)
  const [selectedEmails, setSelectedEmails] = useState<Array<any>>();
  const [isPrimary, setIsPrimary] = useState<boolean>(false);
  const [emailErrMsg, setEmailErrMsg] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [addEmail, setAddEmail] = useState<boolean>(false);
  const [addCandidateEmailSetter] = useMutation(ADD_CANDIDATE_EMAIL);

  const [
    candidateEmailsGetter,
    { loading: candidateEmailsLoading, data: candidateAllEmails },
  ] = useLazyQuery(CANDIDATE_EMAILS);

  const emailCallback = () => {
    if (candidateId) {
      candidateEmailsGetter({ variables: { candidateId } });
    }
  };

  useEffect(() => {
    emailCallback();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (candidateAllEmails) {
      const filteredArray = candidateAllEmails?.candidateEmails
        .filter((item: { isPrimary: boolean }) => item?.isPrimary)
        .map((item: { email: string; id: string }) => {
          return {
            label: item.email,
            value: item.id,
          };
        });
      setSelectedEmails(filteredArray);
      pipeSelectedCandidateEmails(filteredArray);
    }

    // eslint-disable-next-line
  }, [candidateAllEmails?.candidateEmails]);

  useEffect(() => {
    if (selectedEmails) {
      sendSelectedEmailsToParent();
    }

    // eslint-disable-next-line
  }, [selectedEmails]);

  const sendSelectedEmailsToParent = () => {
    pipeSelectedCandidateEmails(selectedEmails);
  };

  const addEmailToggle = () => {
    setAddEmail(!addEmail);
  };

  const addCandidateEmail = async () => {
    const emailAddResponse = await addCandidateEmailSetter({
      variables: { candidateId, isPrimary, email },
    });

    if (emailAddResponse?.data) {
      emailCallback();
      toast.success(`Email against ${candidateName} added successfully`);
    }

    if (emailAddResponse?.errors) {
      setEmailErrMsg(`Email already exists against other candidate!`);
    }
  };

  const setAndCheckMail = (email: string) => {
    setEmail(email);
    if (email) {
      const mailRegex = new RegExp(EMAIL_PATTERN);
      const test = mailRegex.test(email);
      if (test) {
        setEmailErrMsg("");
      } else {
        setEmailErrMsg("Email is not valid!");
      }
    }
  };

  return (
    <div className="mt-2 pb-2">
      <Row>
        <Col lg="12">
          <Label>Candidate Emails</Label>

          <Select
            value={selectedEmails}
            isLoading={candidateEmailsLoading}
            isMulti
            rules={{
              required: {
                value: true,
                message: "Invite Type must be selected!",
              },
            }}
            styles = {{ ...customSelectDropDown(theme) }}
            onChange={(type: any) => {
              setSelectedEmails(type);
            }}
            options={candidateAllEmails?.candidateEmails?.map((item) => {
              return {
                label: item?.email,
                value: item?.id,
              };
            })}
          />
        </Col>

        <Col lg="12">
          <button
            onClick={addEmailToggle}
            className="mt-2 buttonGenericStyle filledButton buttonHeight"
            type="button"
          >
            Add new email
          </button>
        </Col>

        {addEmail && (
          <>
            <Col lg="8">
              <div className="mt-3">
                {/* <Label>Email</Label> */}
                <div className="d-flex align-items-center">
                  <Input
                    name="email"
                    placeholder="Please add candidate email"
                    value={email}
                    onChange={(e) => {
                      setAndCheckMail(e?.target.value);
                    }}
                  />
                </div>
                {email && <span className="text-danger">{emailErrMsg}</span>}
              </div>
            </Col>

            <Col lg="4" className="mt-3 addNewEmailPrimary">
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    name="isPrimary"
                    value={"Is Primary"}
                    onChange={(e) => {
                      setIsPrimary(e.target.checked);
                    }}
                    // style={{ position: "absolute !important" }}
                  />
                  <div className="pt-1">Is Primary</div>
                </Label>
              </FormGroup>
            </Col>

            <Col lg="12">
              <Button
                disabled={!email}
                onClick={addCandidateEmail}
                className="p-0 mt-2 color-default bg-transparent border-0"
              >
                Add
              </Button>
            </Col>
          </>
        )}
      </Row>
    </div>
  );
}
