// packages block
import { useCallback, useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import { Card, CardBody, Modal } from "reactstrap";
// components block
import Loader from "../../../components/layout/Loader";
import InputField from "../../../components/layout/InputField";
import NoDataFound from "../../../components/layout/NoDataFound";
import { Pagination } from "../../../components/layout/Pagination";
// utils and graphql block
import { GET_COMPANY_AGREEMENTS } from "../gql";
import { AGREEMENTS_ROUTE, COMPANY_DETAILS_ROUTE, CONTACT_DETAIL_ROUTE, PAGE_LIMIT, SIGNED, VIEW_AGREEMENT_ROUTE } from "../../../utils/constant";
import { formatDateUS } from "../../../utils/commonFn";
import { IComingState } from "../../agreements/interfaces";
import { ParamsType } from "../../../interfaces";
import { pushInHistory } from "../../../utils/helper";
import { GET_SIGNED_URL } from "../../settings/gql";
import Preview from "../../job-order/job-order-components/document-preview/Preview";

const CompanyAgreement = ({ companyDetails, fromContact }: any) => {
  const page = parseInt("1");
  const [currentPage, setCurrentPage] = useState(page);
  const [viewDocument, setViewDocument] = useState<boolean>()
  const [documentUrl, setDocumentUrl] = useState<string>('')
  const [documentName, setDocumentName] = useState<string>('')

  const [limit, setLimit] = useState(6);
  const location = useLocation<IComingState>();
  const { id } = useParams<ParamsType>();

  const [getCompanyAgreements, { data: companyAgreements, loading }] =
    useLazyQuery(GET_COMPANY_AGREEMENTS);
  const [getSignedURL] = useLazyQuery(GET_SIGNED_URL);

  const SignedUrl = useCallback(async (url) => {
    const { data } = await getSignedURL({ variables: { url } })
    const { getSignedUrl } = data || {};
    const { signedUrl } = getSignedUrl || {};
    return signedUrl;
  }, [getSignedURL])


  useEffect(() => {
    if (companyDetails?.id) {
      getCompanyAgreements({
        variables: { id: companyDetails?.id, limit, page: currentPage },
      });
    }
    // eslint-disable-next-line
  }, [companyDetails?.id, limit, currentPage]);

  const previewSignedDocumnet = async (url: string, name: string) => {
    setViewDocument(true)
    setDocumentUrl(await SignedUrl(url))
    setDocumentName(name)
  }

  return (
    <>
      <Card className="border-0 address-card">
        <div className="user-basic-form">
          <div className="resumeHeader mobileResponsiveFlex">
            <h5 className="m-0">
              {fromContact
                ? "List of Agreement Under Contacts"
                : "Company Agreements"}
            </h5>
            <Link
              onClick={() => pushInHistory(`${!!fromContact ? CONTACT_DETAIL_ROUTE : COMPANY_DETAILS_ROUTE}/${id}`)}
              to={`${AGREEMENTS_ROUTE}?companyId=${companyDetails?.id}&companyName=${companyDetails?.name}`}
            >
              <button
                className="buttonGenericStyle filledButton m-0"
                type="button"
              >
                View all agreements
              </button>
            </Link>
          </div>
        </div>

        <div className="resumeHeader pt-0">
          {loading ? (
            <Loader loaderClass="sm-height" />
          ) : (
            <div>
              {companyAgreements?.getCompanyAgreements?.agreements?.length ? (
                companyAgreements?.getCompanyAgreements?.agreements?.map(
                  (item: {
                    id: string;
                    nickName: string;
                    status: string;
                    signedAt: string | Date;
                    agreementType: string;
                    documentUri: string
                  }) => (

                    <Card className="mb-3 dark-border">
                      <CardBody>

                        <div>
                          <div>
                            <Link to={{
                              pathname: `${VIEW_AGREEMENT_ROUTE}/${item.id}`,
                              state: {
                                backUrl: `${!!fromContact ? CONTACT_DETAIL_ROUTE : COMPANY_DETAILS_ROUTE}/${id}`,
                                searchParameters: location.search
                              }
                            }}
                              onClick={() => pushInHistory(`${!!fromContact ? CONTACT_DETAIL_ROUTE : COMPANY_DETAILS_ROUTE}/${id}`)}
                            >
                              <h6 className="route-link mt-1">{item?.nickName}</h6>
                            </Link>

                            <label>
                              Status: <span>{item?.status}</span>
                            </label>
                          </div>
                          <div>
                            <label>
                              Type: <span>{item?.agreementType}</span>
                            </label>
                          </div>
                          <div className="m-0">
                            <label className="mr-2">Signed At: </label>
                            <label> {item?.signedAt
                              ? formatDateUS(item?.signedAt)
                              : "--"}</label>
                          </div>
                          {item.status === SIGNED &&
                            <h6 onClick={() => previewSignedDocumnet(item.documentUri, item.nickName)}
                              className="route-link mt-1">Click To View</h6>}
                        </div>
                      </CardBody>
                    </Card>
                  )
                )
              ) : (
                <div className="for-position-multiselect">
                  <NoDataFound text="No Agreement Found!" />
                </div>
              )}

              {companyAgreements?.getCompanyAgreements?.agreements?.length ? (
                <div className="users-pagination">
                  <Pagination
                    onPageChange={(pageClicked: number) => {
                      setCurrentPage(pageClicked);
                    }}
                    pageCount={Math.ceil(
                      companyAgreements?.getCompanyAgreements?.count / limit
                    )}
                    currentPage={currentPage}
                  />

                  <div className="d-flex align-items-center">
                    <div className="pagination-display paginationItemsSelect">
                      <p className="m-0">
                        {(currentPage - 1) * limit + 1} to{" "}
                        {Math.min(
                          currentPage * limit,
                          companyAgreements?.getCompanyAgreements?.count
                        )}{" "}
                        of {companyAgreements?.getCompanyAgreements?.count}
                      </p>

                      <div className="divider"></div>

                      <label>Display</label>

                      <InputField
                        label=""
                        inputtype="select"
                        selectItems={PAGE_LIMIT.filter(
                          (item) => item.value !== limit
                        )}
                        inputid="limit"
                        placeholder={limit.toString()}
                        inputMethod={(value) => {
                          setCurrentPage(1);
                          setLimit(parseInt(value));
                        }}
                      />
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          )}
        </div>
      </Card >

      <Modal
        isOpen={viewDocument}
        toggle={() => setViewDocument(!viewDocument)}
        centered={true}
        size="xl"
        className="revampDialog border-0"
      >
        <div className="pdf-viewer">
          <Preview
            documentUrl={documentUrl}
            documentName={`${documentName + " Signed Document"}.pdf`}
            isDownload={true}
          />
        </div>
      </Modal>
    </>
  );
};

export default CompanyAgreement;
