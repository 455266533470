import { useMutation, useQuery } from "@apollo/client";
import { useContext, useEffect, useState } from "react";
import Select from "react-select";
import { Col, Form, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { customSelectDropDown } from "../../components/styled/customSelect";
import { AppContext } from "../../context";
import { FETCHING_NOT_TERMINATED_USERS } from "../admin/gql";
import { Controller, useForm } from "react-hook-form";
import DragDropFile from "../candidate/candidate-components/DragDropFile";
import { bucketFolders } from "../../utils/constant";
import { uploadFile, updateFileName } from "../../utils/helper";
import CustomButton from "../../components/layout/CustomButton";
import { CREATE_REPORT, UPDATE_REPORT } from "./gql";
import moment from "moment";
import { toast } from "react-toastify";

const AddActivityReportModal = ({ isOpen, toggle, refetch, edit, activityReport }) => {
  const { theme } = useContext(AppContext)
  const { data } = useQuery(FETCHING_NOT_TERMINATED_USERS);
  const [createReport, { loading: creating }] = useMutation(CREATE_REPORT)
  const [updateReport, { loading: updating }] = useMutation(UPDATE_REPORT)
  const { handleSubmit, control, errors, reset } = useForm()
  const [uploading, setUploading] = useState<boolean>(false)
  const [file, setFile] = useState<File | undefined>()
  const [fileName, setFileName] = useState<string>()

  useEffect(() => {
    if (edit) {
      setFile(undefined)
      reset({
        userId: { value: activityReport?.userId, label: activityReport?.fullName },
        month: moment().month(parseInt(activityReport?.month) - 1).year(activityReport?.year).toISOString().substring(0, 7)
      })
      console.log("documentLink :: ", activityReport?.documentLink.match(/\/([^\/]+)$/)[1].split('-')[1])
      setFileName(activityReport?.documentLink.match(/\/([^\/]+)$/)[1].split('-')[1])
    } else {
      reset({})
      setFileName('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edit, isOpen])

  const onSubmit = async ({ userId: { value: userId }, month }) => {
    let upload
    if (file) {
      setUploading(true)
      upload = await uploadFile(
        new File([file!], updateFileName(file!.name), { type: file!.type }),
        bucketFolders.csv,
        false, "attachment"
      )
      setUploading(false)
    }
    if (upload?.status === 200 || edit) {
      if (edit) {
        const updateActivityReport = await updateReport({
          variables: {
            input: {
              id: activityReport?.id,
              userId,
              month: (moment(month).month() + 1).toString(),
              year: moment(month).year().toString(),
              documentLink: upload?.source || activityReport?.documentLink,
              documentName: fileName
            }
          }
        })
        if (updateActivityReport?.data?.updateUserActivityReport?.status === 201) {
          toast.success("updated successfully")
          refetch()
          toggle()
          return
        }
      } else {
        const createActivityReport = await createReport({
          variables: {
            input: {
              userId, documentLink: upload?.source,
              month: (moment(month).month() + 1).toString(),
              year: moment(month).year().toString(),
              documentName: fileName
            }
          }
        })
        if (createActivityReport?.data?.createUserActivityReport?.status === 201) {
          toast.success("created successfully")
          refetch()
          toggle()
          return
        }
      }
      toast.error("something went wrong")
    }
  }
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className="revampDialog revampDialogWidth"
      centered
    >
      <ModalHeader className="pt-3">
        <div className="d-flex justify-content-between py-3">
          <h5>{edit ? "Edit" : "Add New"} Report</h5>
        </div>
      </ModalHeader>
      <ModalBody className="pt-2 pb-2">
        <div className="pb-4">
          <Form>
            <Row className="mb-3">
              <Col>
                <Label className="font-weight-bold">User <span className="error">*</span> </Label>
                <Controller
                  name="userId"
                  as={Select}
                  rules={{ required: "User is required" }}
                  options={
                    data?.allActiveUsers?.length &&
                    data?.allActiveUsers?.map((item: any) => ({
                      label: `${item.firstName} ${item.lastName}`,
                      value: item.id,
                    }))
                  }
                  control={control}
                  placeholder="Select Recruiter"
                  isSearchable={true}
                  styles={{ ...customSelectDropDown(theme) }}
                />
                <small className="text-danger">
                  {errors?.userId?.message}
                </small>
              </Col>

              <Col>
                <Label className="font-weight-bold">Month <span className="error">*</span> </Label>
                <Controller
                  name="month"
                  rules={{
                    required: "Month is Required"
                  }}
                  defaultValue={new Date().toISOString().substring(0, 7)}
                  control={control}
                  render={(field) => (
                    <Input
                      {...field}
                      type="month"
                      max={new Date().toISOString().substring(0, 7)}
                    />
                  )}
                />
                <small className="text-danger">
                  {errors?.month?.message}
                </small>
              </Col>
            </Row>

            <Row>
              <Col>
                <Label className="font-weight-bold">Upload File <span className="error">*</span> </Label>
                <Row>
                  <Label className="m-3 font-weight-normal">{fileName && `File Name: ${fileName}`}</Label>
                </Row>
                <Controller
                  control={control} name="file"
                  render={
                    ({ onChange }) => (
                      <Col style={{ backgroundColor: "#F7FDFF" }}>
                        <DragDropFile onUpload={(file: File) => {
                          onChange(file.name)
                          setFile(file)
                          setFileName(file.name)
                        }} />
                      </Col>)

                  }
                />
              </Col>
            </Row>
          </Form>
        </div>
      </ModalBody>

      <ModalFooter>
        <button
          type="button"
          onClick={toggle}
          className="buttonPrimary mr-3"
        >
          Cancel
        </button>
        <CustomButton
          className="" disabled={!(file || fileName)} loading={uploading || creating || updating}
          buttonClick={handleSubmit(onSubmit)} buttonText="Save"
        />

      </ModalFooter>


    </Modal>
  )
}
export default AddActivityReportModal