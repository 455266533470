import axios, { AxiosError, AxiosResponse } from "axios";
import { AddNewRealReppDocument, RealReppDocumentData, UpdateRealReppDocument } from "../../interfaces";

export const addNewDocument = async (args: AddNewRealReppDocument): Promise< AxiosResponse<RealReppDocumentData> | undefined> => {
  try {
    const { name, category, file, driveUpload=false, folderName, convertPdf = false, publicBucket = false } = args || {};
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL || "http://localhost:3001"
    const formData = new FormData();

    formData.append('file', file, file.name);
    formData.append('driveUpload', `${driveUpload}`);
    formData.append('folderName', `${folderName}`);
    formData.append('convertpdf', `${convertPdf}`);
    formData.append('publicbucket', `${publicBucket}`);
    formData.append('name', `${name}`);
    formData.append('category', `${category}`);

    const response:AxiosResponse<RealReppDocumentData> = await axios.post(
      `${apiBaseUrl}/realreppDocuments/create`,
      formData, // Pass the FormData object as the request body
    ) || {};
    return response;
  }
  catch (error) {
    const axiosError = error as AxiosError;
    return axiosError?.response
    
  }


};

export const updateDocument = async (args: UpdateRealReppDocument): Promise< AxiosResponse<RealReppDocumentData> | undefined> => {
  try {
    const { id, name, category, file, driveUpload = false, folderName, convertPdf = false, publicBucket =false } = args || {};
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL || "http://localhost:3001"
    const formData = new FormData();

    file && formData.append('file', file, file.name);
    file && formData.append('driveUpload', `${driveUpload}`);
    file && formData.append('folderName', `${folderName}`);
    file && formData.append('convertpdf', `${convertPdf}`);
    file && formData.append('publicbucket', `${publicBucket}`);
    id && formData.append('id', `${id}`);
    name && formData.append('name', `${name}`);
    category && formData.append('category', `${category}`);
    
    const response:AxiosResponse<RealReppDocumentData> =  await axios.put(
      `${apiBaseUrl}/realreppDocuments/update`,
      formData, // Pass the FormData object as the request body
    ) || {};
    return response || undefined;
  }
  catch (error) {
    const axiosError = error as AxiosError;
    return axiosError?.response
  }
};