import { useLazyQuery, useMutation } from '@apollo/client';
import { useContext, useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import { toast } from 'react-toastify';
import {
  Badge,
  Button,
  Card,
  CardBody,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from 'reactstrap';
import ConfirmPopUp from '../../../components/ConfirmPopUp';
import CustomButton from '../../../components/layout/CustomButton';
import { AppContext } from '../../../context';
import { reactQuillReg } from '../../../utils/constant';
import { outlookLink } from '../../../utils/Temp-Helper';
import EmailLookup from '../../candidate-submission/EmailLookup';
import {
  // CREATE_SUBMISSION_Mail,
  FETCH_SUBMISSION_EMAIL,
  RESPONSE_PERMISSION_SUBMISSION,
} from '../../candidate-submission/gql';
import SubmissionReview from '../../candidate-submission/Pipeline-components/SubmissionReview';
import { DELETE_SUBMISSION_ATTACHMENT } from '../gql';

const PreviewCandidateSubmitEmail = ({
  open,
  toggle,
  submissionId,
  notifyBackId,
  alertMsg,
}) => {
  //constants
  const [deleteAttachment] = useMutation(DELETE_SUBMISSION_ATTACHMENT);
  const [errMsg] = useState('');
  const { user } = useContext(AppContext);
  const [mailContact, setMailContact] = useState<any>({
    toContacts: [],
    ccContacts: [],
    bccContacts: [],
  });
  const [otherMails, setOtherMails] = useState({ Cc: false, Bcc: false });
  const [subject, setSubject] = useState<any>();
  const [bodyContent, setBodyContent] = useState<any>();
  const [loadingBodyContent, setLoadingBodyContent] = useState<boolean>(true);
  const [submissionComplete, setSubmissionComplete] = useState<boolean>(false);

  const [isSelectToggle, setIsSelectToggle] = useState<boolean>(false);
  const [searchingText, setSearchingText] = useState<string>('');
  const [attachment, setAttachment] = useState({ name: '', id: '' });

  const [confirmPop, setConfirmPop] = useState<boolean>(false);

  // Queries
  const [fetchEmailDetails, { data }] = useLazyQuery(FETCH_SUBMISSION_EMAIL);

  const [responsePermissionSubmission] = useMutation(
    RESPONSE_PERMISSION_SUBMISSION
  );

  // Handlers
  const notificationHandler = async () => {
    await fetchEmailDetails({
      variables: {
        submissionId,
      },
    });
  };

  const getSelectedEmail = (value: any) => {
    const mails = mailContact?.toContacts.filter((mail) => mail !== value);
    setMailContact({
      ...mailContact,
      toContacts: [...mails, value],
    });
    setIsSelectToggle(false);
    setSearchingText('');
  };

  const submitCandidateHandler = async () => {
    const str = JSON.stringify({
      msg: alertMsg,
      submissionId: `${submissionId}`,
    });

    const data = mailContact;
    data.bodyContent = bodyContent;
    data.subject = subject;
    data.submissionId = submissionId;

    responsePermissionSubmission({
      variables: {
        ownerID: notifyBackId,
        submissionId,
        msg: str,
        submissionMail: data,
      },
    });

    toast.success('Candidate has been submitted successfully!');
    toggle();
  };

  const cancelButtonHandler = () => {
    toggle();
  };

  const removeAttachment = () => {
    //mutation runs here to delete from db
    deleteAttachment({
      variables: { attachmentId: attachment.id },
    });
    setConfirmPop(!confirmPop);
    notificationHandler();
  };

  useEffect(() => {
    if (data) {
      setSubject(data?.getSubmission?.submissionEmail?.subject);
      setBodyContent(data?.getSubmission?.submissionEmail?.bodyContent);
      setMailContact({
        toContacts: data?.getSubmission?.submissionEmail?.toContacts,
        ccContacts: data?.getSubmission?.submissionEmail?.ccContacts,
        bccContacts: data?.getSubmission?.submissionEmail?.bccContacts,
      });
      setSubmissionComplete(
        data?.getSubmission?.submissionEmail?.status === 'SENT'
      );
    }
  }, [data]);

  useEffect(() => {
    if (mailContact) console.log(mailContact);
    // eslint-disable-next-line
  }, [subject, bodyContent, mailContact, mailContact]);

  useEffect(() => {
    if (bodyContent) {
      setLoadingBodyContent(false);
    }
  }, [bodyContent]);

  useEffect(() => {
    if (submissionId) {
      notificationHandler();
    }

    // eslint-disable-next-line
  }, [submissionId]);
  return (
    <>
      <div>
        {data?.getSubmission?.status !== 'COMPLETED' && (
          <Modal
            isOpen={open}
            toggle={() => toggle()}
            className="for-modal-styling"
          >
            <ModalHeader>Candidate Submission</ModalHeader>
            <ModalBody>
              <div>
                <>
                  <div id="submittedEmail">
                    <Form>
                      <Card className="border-0">
                        <CardBody className="p-0">
                          {user?.outLookToken ? (
                            <div className="dollar-addon-btn">
                              <small>Email</small>
                              <div
                                className="border border-bottom-0"
                                style={{ borderColor: '#e5e9f8' }}
                              >
                                <InputGroup className="shadow-none">
                                  <InputGroupAddon
                                    addonType="prepend"
                                    className="styleCss"
                                  >
                                    To
                                  </InputGroupAddon>

                                  {mailContact.toContacts && (
                                    <div style={{ width: '74%' }} className="">
                                      <div className="d-flex">
                                        {data?.getSubmission?.submissionEmail?.toContacts?.map(
                                          (item) => (
                                            <div className="position-relative  d-flex align-items-center">
                                              <Badge
                                                color="light"
                                                className="mx-1 my-auto text-truncate textTruncateWidth"
                                                style={{
                                                  padding: '5px 20px 5px 5px ',
                                                }}
                                              >
                                                {item}
                                              </Badge>
                                              <span
                                                className="forMailRemove text-dark"
                                                onClick={() => {
                                                  let toContacts = [
                                                    ...mailContact.toContacts,
                                                  ];
                                                  let remove =
                                                    toContacts.filter(
                                                      (val) => val !== item
                                                    );
                                                  setMailContact({
                                                    ...mailContact,
                                                    toContacts: [...remove],
                                                  });
                                                }}
                                              >
                                                x
                                              </span>
                                            </div>
                                          )
                                        )}
                                        <Input
                                          style={{
                                            border: '0px',
                                          }}
                                          autoComplete="off"
                                          name="mailTo"
                                          onClick={() => {
                                            setIsSelectToggle(true);
                                          }}
                                          onChange={({
                                            target,
                                          }: React.ChangeEvent<HTMLInputElement>) => {
                                            setSearchingText(target.value);
                                          }}
                                          value={searchingText}
                                        />
                                      </div>
                                    </div>
                                  )}
                                  {/* end here */}

                                  <InputGroupAddon
                                    addonType="prepend"
                                    className="styleCss"
                                  >
                                    <div className="input-group-text">
                                      <Button
                                        className="bg-transparent border-0 text-muted btn-sm p-0 mx-2 otherMails"
                                        type="button"
                                        onClick={() =>
                                          setOtherMails({
                                            ...otherMails,
                                            Cc: !otherMails.Cc,
                                          })
                                        }
                                      >
                                        Cc
                                      </Button>
                                      <Button
                                        className="bg-transparent border-0 text-muted btn-sm p-0 otherMails"
                                        type="button"
                                        onClick={() =>
                                          setOtherMails({
                                            ...otherMails,
                                            Bcc: !otherMails.Bcc,
                                          })
                                        }
                                      >
                                        Bcc
                                      </Button>
                                    </div>
                                  </InputGroupAddon>
                                </InputGroup>
                              </div>
                              {isSelectToggle && searchingText.length > 2 && (
                                <EmailLookup
                                  emails={data?.getCompanyEmails.filter(
                                    (item: any) => {
                                      const matchingRegex = new RegExp(
                                        `^${searchingText}`,
                                        'i'
                                      );
                                      if (
                                        item.email.match(matchingRegex) ||
                                        item?.fullName.match(matchingRegex)
                                      ) {
                                        return item;
                                      } else {
                                        // eslint-disable-next-line
                                        return;
                                      }
                                    }
                                  )}
                                  setSelectedEmail={getSelectedEmail}
                                />
                              )}
                              {otherMails?.Cc && (
                                <div
                                  className="border border-bottom-0"
                                  style={{ borderColor: '#e5e9f8' }}
                                >
                                  <InputGroup className="shadow-none border-bottom-0 rounded-0">
                                    <InputGroupAddon
                                      addonType="prepend"
                                      className="styleCss"
                                    >
                                      Cc
                                    </InputGroupAddon>
                                    <div style={{ width: '74%' }} className="">
                                      <div className="d-flex">
                                        {data?.getSubmission?.submissionEmail?.ccContacts?.map(
                                          (item) => (
                                            <div className="position-relative d-flex">
                                              <Badge
                                                color="light"
                                                className="mx-1 my-auto text-truncate textTruncateWidth"
                                                style={{
                                                  padding: '5px 20px 5px 5px ',
                                                }}
                                              >
                                                {item}
                                              </Badge>
                                              <span
                                                className="forMailRemove text-dark"
                                                onClick={() => {
                                                  let ccContacts = [
                                                    ...mailContact.ccContacts,
                                                  ];
                                                  let remove =
                                                    ccContacts.filter(
                                                      (val) => val !== item
                                                    );
                                                  setMailContact({
                                                    ...mailContact,
                                                    ccContacts: [...remove],
                                                  });
                                                }}
                                              >
                                                x
                                              </span>
                                            </div>
                                          )
                                        )}
                                        <Input
                                          style={{
                                            border: '0px',
                                          }}
                                          autoComplete="off"
                                          name="ccContacts"
                                          // onKeyUp={(e: any) => mailSelected(e)}
                                          // onBlur={(e) => checkMail(e)}
                                        />
                                      </div>
                                    </div>
                                  </InputGroup>
                                </div>
                              )}
                              {otherMails.Bcc && (
                                <div
                                  className="border border-bottom-0"
                                  style={{ borderColor: '#e5e9f8' }}
                                >
                                  <InputGroup className="shadow-none">
                                    <InputGroupAddon
                                      addonType="prepend"
                                      className="styleCss"
                                    >
                                      Bcc
                                    </InputGroupAddon>
                                    <div style={{ width: '74%' }} className="">
                                      <div className="d-flex">
                                        {data?.getSubmission?.submissionEmail?.bccContacts?.map(
                                          (item) => (
                                            <div className="position-relative  d-flex">
                                              <Badge
                                                color="light"
                                                className="mx-1 my-auto text-truncate textTruncateWidth"
                                                style={{
                                                  padding: '5px 20px 5px 5px ',
                                                }}
                                              >
                                                {item}
                                              </Badge>
                                              <span
                                                className="forMailRemove text-dark"
                                                onClick={() => {
                                                  let bccContacts = [
                                                    ...mailContact.bccContacts,
                                                  ];
                                                  let remove =
                                                    bccContacts.filter(
                                                      (val) => val !== item
                                                    );
                                                  setMailContact({
                                                    ...mailContact,
                                                    bccContacts: [...remove],
                                                  });
                                                }}
                                              >
                                                x
                                              </span>
                                            </div>
                                          )
                                        )}
                                        <Input
                                          autoComplete="off"
                                          name="bccContacts"
                                          // onKeyUp={(e: any) => mailSelected(e)}
                                          // onBlur={(e) => checkMail(e)}
                                          className="border-0"
                                        />
                                      </div>
                                    </div>
                                  </InputGroup>
                                </div>
                              )}
                              <div
                                className="border border-bottom-0"
                                style={{ borderColor: '#e5e9f8' }}
                              >
                                <InputGroup className="shadow-none">
                                  <InputGroupAddon
                                    addonType="prepend"
                                    className="styleCss"
                                  >
                                    Subject
                                  </InputGroupAddon>
                                  <Input
                                    autoComplete="off"
                                    className="border-0"
                                    name="subject"
                                    value={subject}
                                    onChange={({ target: { value } }) => {
                                      setSubject(value);
                                    }}
                                  />
                                </InputGroup>
                              </div>
                              <div>
                                {loadingBodyContent ? (
                                  <Spinner size="sm" className="mb-1" />
                                ) : (
                                  <div className="editor-height">
                                    <ReactQuill
                                      defaultValue={bodyContent || ''}
                                      onChange={(value) => {
                                        if (reactQuillReg.test(value)) {
                                          setBodyContent(null);
                                        } else {
                                          setBodyContent(value);
                                        }
                                      }}
                                    />
                                  </div>
                                )}
                              </div>
                              <div>
                                {data?.getSubmission?.submissionAttachments
                                  .length > 0 &&
                                  data?.getSubmission?.submissionAttachments.map(
                                    (item) => (
                                      <div className="position-relative d-flex align-items-center px-2 py-1 justify-content-between">
                                        <Badge
                                          color="light"
                                          style={{
                                            padding: '5px 10px',
                                          }}
                                        >
                                          {item.attachmentType}
                                        </Badge>
                                        <span
                                          className="text-dark"
                                          onClick={() => {
                                            setAttachment({
                                              name: item?.attachmentType,
                                              id: item?.id,
                                            });
                                            setConfirmPop(!confirmPop);
                                          }}
                                        >
                                          x
                                        </span>
                                      </div>
                                    )
                                  )}
                              </div>
                            </div>
                          ) : (
                            <div className="d-flex px-3 py-5 border">
                              <span>
                                Signin with your <strong>Outlook </strong>{' '}
                                account first!
                              </span>
                              {outlookLink}
                            </div>
                          )}
                          <small className="text-danger">{errMsg}</small>
                        </CardBody>
                      </Card>
                    </Form>
                  </div>
                </>
              </div>
            </ModalBody>
            <ModalFooter>
              <CustomButton
                buttonText="Cancel"
                buttonColor="secondary"
                buttonType="submit"
                className="big-width"
                buttonClick={cancelButtonHandler}
              />
              <CustomButton
                buttonText="Submit"
                buttonColor="primary"
                buttonType="submit"
                className="big-width"
                disabled={
                  data?.getSubmission?.submissionEmail?.status === 'SENT'
                }
                buttonClick={submitCandidateHandler}
              />
            </ModalFooter>
          </Modal>
        )}
        <ConfirmPopUp
          confirmText="Are you sure you want to Delete document"
          isOpen={confirmPop}
          toggle={setConfirmPop}
          confirmAction={removeAttachment}
          modalHeading="Make Manager"
          btnText="Delete"
          btnColor="primary"
          extraProp={`${attachment?.name} ?`}
          className="for-modal-styling"
        />
        {data?.getSubmission?.status === 'COMPLETED' && (
          <SubmissionReview
            data={data?.getSubmission}
            open={submissionComplete}
            toggle={toggle}
          />
        )}
      </div>
    </>
  );
};

export default PreviewCandidateSubmitEmail;
