import { useLazyQuery, useMutation } from "@apollo/client";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { RouteComponentProps } from "react-router";
import { toast, ToastContainer } from "react-toastify";
import { Input, InputGroup, InputGroupAddon, InputGroupText } from "reactstrap";
import SEARCH_IMG from "../../../assets/images/search.svg";
import CustomButton from "../../../components/layout/CustomButton";
import Loader from "../../../components/layout/Loader";
import NoDataFound from "../../../components/layout/NoDataFound";
import TableComponent from "../../../components/layout/TableComponent";
import { AppContext } from "../../../context";
import { compactObject } from "../../../utils/commonFn";
import CreateSequenceModal from "./CreateSequence";
import Cards from "./sequence-components/Cards";
import {
  ARCHIVED_SEQUENCES,
  SEQUENCE_LISTING,
  TODAY_SEQUENCE_STATS,
  UPDATE_SEQUENCE,
} from "./gql";

const ALL_SEQUENCES_HEADER = [
  "Name",
  "Contacts Count",
  "Open Rate",
  "Reply Rate",
  "Active",
];

let time = 0;

const AllSequences = ({ history }: RouteComponentProps) => {
  const [deActiveTypeCheckLoading, setDeActiveTypeCheckLoading] =
    useState<boolean>(false);
  const [activeTypeCheckLoading, setActiveTypeCheckLoading] =
    useState<boolean>(false);

  const [checkBoxArray, setCheckBoxArray] = useState<Array<string>>([]);
  const { register } = useForm();
  const { user } = useContext(AppContext);

  const [createSequenceModal, setCreateSequenceModal] =
    useState<boolean>(false);

  const [businessDevelopmentSequences, setBusinessDevelopmentSequences] =
    useState<boolean>(true);

  const [query, setQuery] = useState<string>("");

  const [getSequencesListing, { loading, data }] =
    useLazyQuery(SEQUENCE_LISTING);

  const [
    getSequenceTodayStats,
    { data: sequenceTodayStatsData, loading: sequenceTodayStatsLoading },
  ] = useLazyQuery(TODAY_SEQUENCE_STATS);

  const [updateSequence, { loading: updateSequenceLoading }] =
    useMutation(UPDATE_SEQUENCE);

  const [removeSequences, { loading: removeSequencesLoading }] =
    useMutation(ARCHIVED_SEQUENCES);

  const createSequenceModalToggle = () => {
    setCreateSequenceModal(!createSequenceModal);
  };

  const fetchSequences = () => {

    setQueryParams(query);
    if (!query || query?.length > 2) {
      getSequencesListing({
        variables: {
          query: query,
          businessDevelopmentSequences: businessDevelopmentSequences
            ? businessDevelopmentSequences
            : null,
        },
      });
    }
  };

  const setQueryParams = (query: string) => {
    const criteria = compactObject({
      query,
    });

    const urlQueryParams: Array<any> = [];
    if (Object.keys(criteria).length) {
      for (const key in criteria) {
        if (
          Object.prototype.hasOwnProperty.call(criteria, key) &&
          criteria[key]
        ) {
          urlQueryParams.push(`${key}=${criteria[key]}`);
        }
      }
      history.push(`/sequences/?${urlQueryParams.join("&")}`);
    }
  };

  const activeSequence = async (item: any) => {
    const resultOfRequest = await updateSequence({
      variables: {
        updateSequenceInput: {
          status: true,
          ids: [item?.id],
        },
      },
    });

    if (resultOfRequest?.errors) {
      toast.error(
        "Launching a campaign requries that you have at least one step and that all messages have a subject line"
      );
    } else if (resultOfRequest?.data) {
      setCheckBoxArray([]);
      toast.success("Sequence successfully activated!");
      fetchSequences();
    }
  };

  const deActivateOneSequence = async (item: any) => {
    const resultOfRequest = await updateSequence({
      variables: {
        updateSequenceInput: {
          status: false,
          ids: [item?.id],
        },
      },
    });

    if (resultOfRequest?.errors) {
      toast.error(
        "Launching a campaign requries that you have at least one step and that all messages have a subject line"
      );
    } else if (resultOfRequest?.data) {
      setCheckBoxArray([]);
      toast.success("Sequence successfully de-activated!");
      fetchSequences();
    }
  };

  const archivedSequencesInBulk = async () => {
    const resultOfRequest = await removeSequences({
      variables: {
        removingSequencesInput: {
          ids: checkBoxArray,
        },
      },
    });
    if (resultOfRequest?.data) {
      setCheckBoxArray([]);
      toast.success("Sequence successfully archived!");
      fetchSequences();
    }
    setCheckBoxArray([]);

  };

  const searchControl = (value: string) => {
    setQuery(value);
    setQueryParams(value);
    if (!value || value?.length > 2) {
      window.clearTimeout(time);
      time = window.setTimeout(() => {
        getSequencesListing({
          variables: {
            query: value,
            businessDevelopmentSequences: businessDevelopmentSequences
              ? businessDevelopmentSequences
              : null,
          },
        });
      }, 1000);
    }
  };

  const handleCheckBoxes = (value: string) => {
    const currentIndex = checkBoxArray.indexOf(value);
    const newChecked = [...checkBoxArray];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setCheckBoxArray(newChecked);
  };

  const activeSequencesInBulk = async () => {
    setActiveTypeCheckLoading(true);
    const resultOfRequest = await updateSequence({
      variables: {
        updateSequenceInput: {
          status: true,
          ids: checkBoxArray,
        },
      },
    });

    if (resultOfRequest?.errors) {
      toast.error(
        "Launching a campaign requries that you have at least one step and that all messages have a subject line"
      );
    } else if (resultOfRequest?.data) {
      setCheckBoxArray([]);
      toast.success("Sequence successfully activated!");
      fetchSequences();
    }
    setActiveTypeCheckLoading(false);
  };

  const deActiveSequencesInBulk = async () => {
    setDeActiveTypeCheckLoading(true);
    const resultOfRequest = await updateSequence({
      variables: {
        updateSequenceInput: {
          status: false,
          ids: checkBoxArray,
        },
      },
    });

    if (resultOfRequest?.errors) {
      toast.error(
        "Launching a campaign requries that you have at least one step and that all messages have a subject line"
      );
    } else if (resultOfRequest?.data) {
      setCheckBoxArray([]);
      toast.success("Sequence successfully de-activated!");
      fetchSequences();
    }
    setDeActiveTypeCheckLoading(false);
  };

  const refetchingSequencesOnCreation = (value: boolean) => {
    getSequencesListing({
      variables: {
        query: query,
        businessDevelopmentSequences: value ? value : null,
      },
    });
  };

  useEffect(() => {
    fetchSequences();

    // eslint-disable-next-line
  }, [businessDevelopmentSequences]);

  useEffect(() => {
    document.title = "Sequences - RealREPP";
    getSequenceTodayStats({ variables: { date: new Date().toDateString() } });
    setCheckBoxArray([]);

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const historyLocally: any = history;

    if (historyLocally?.location?.state) {
      setBusinessDevelopmentSequences(
        historyLocally?.location?.state?.includes("BD") ? true : false
      );

      getSequencesListing({
        variables: {
          query: query,
          businessDevelopmentSequences:
            historyLocally?.location?.state?.includes("BD") ? true : null,
        },
      });

      history.push({ pathname: "/sequences", state: "" });
    }

    // eslint-disable-next-line
  }, [history]);

  return (
    <div className="admin-tabs team-tabs">
      <ToastContainer autoClose={3000} />

      <div className="primaryHeading primaryHeaderSpacing mobileResponsiveFlexAlign justify-content-between">
        <h5 className="m-0">Sequences </h5>
      </div>

      <div className="primaryHeading primaryHeaderSpacing mobileResponsiveFlexAlign justify-content-between">
        <div className="search-filter filterSequence">
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <img src={SEARCH_IMG} alt="search" />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              type="text"
              placeholder="Search for a sequence"
              value={query}
              onChange={(e) => {
                setQuery(e.target.value);
                searchControl(e.target.value);
              }}
              className="search-by-company"
            />
          </InputGroup>

          <small className="text-info">
            {query &&
              query.length <= 2 &&
              "Please add more than two characters"}
          </small>
        </div>

        <div className="filter-status sequenceSwitch">
          <span className="mr-2 switchLabel d-flex align-items-center">
            <span className="mr-1 text-warning font-weight-bold">
              Recruiting Sequence
            </span>
            <label className="switch m-0">
              <Input
                type="checkbox"
                name="businessDevelopment"
                innerRef={register()}
                checked={businessDevelopmentSequences}
                onClick={() =>
                  setBusinessDevelopmentSequences(!businessDevelopmentSequences)
                }
              />
              <span className="slider" />
            </label>
            <span className="ml-1 text-success font-weight-bold">
              Business Sequence
            </span>
          </span>
        </div>
      </div>

      <div className="sequence-cards">
        <Cards
          sequences={data?.allSequences?.sequences}
          loading={sequenceTodayStatsLoading}
          type="messages"
          count={sequenceTodayStatsData?.sequenceStatsByDate?.messages?.total}
          name="Message Schedule"
        />
        <Cards
          sequences={data?.allSequences?.sequences}
          loading={sequenceTodayStatsLoading}
          type="contacts"
          count={sequenceTodayStatsData?.sequenceStatsByDate?.contacts?.total}
          name="Contacts Added Today"
        />
        <Cards
          sequences={data?.allSequences?.sequences}
          loading={sequenceTodayStatsLoading}
          type="meetings"
          count={sequenceTodayStatsData?.sequenceStatsByDate?.booked?.total}
          name="Meetings Booked Today"
        />
      </div>

      <div className="section-header px-2">
        <>
          <div className="contacts-actions-main">
            {!!checkBoxArray && !!checkBoxArray.length ? (
              <>
                <div className="contacts-actions">
                  <h5 className="m-0 mr-3">{checkBoxArray.length} Selected</h5>

                  <CustomButton
                    buttonText={`Activate sequences`}
                    buttonColor="primary"
                    loading={activeTypeCheckLoading && updateSequenceLoading}
                    buttonType="button"
                    className="big-width gray-button mr-2"
                    buttonClick={() => activeSequencesInBulk()}
                  />

                  <CustomButton
                    loading={deActiveTypeCheckLoading && updateSequenceLoading}
                    buttonText={`De-activate sequences`}
                    buttonColor="primary"
                    buttonType="button"
                    className="big-width gray-button mr-2"
                    buttonClick={() => deActiveSequencesInBulk()}
                  />

                  <CustomButton
                    loading={removeSequencesLoading}
                    buttonText={`Archived sequences`}
                    buttonColor="danger"
                    buttonType="button"
                    className="big-width gray-button mr-2 mb-lg-0 mb-2"
                    buttonClick={() => archivedSequencesInBulk()}
                  />
                </div>
              </>
            ) : null}
          </div>
        </>
        <CustomButton
          buttonText="Create New Sequence"
          buttonType="button"
          disabled={user?.intersellerUserId ? false : true}
          buttonColor="primary"
          className="save-changes"
          buttonClick={() => setCreateSequenceModal(true)}
        />
      </div>

      <div className="table-responsive">
        <TableComponent tableHeader={ALL_SEQUENCES_HEADER}>
          {loading && (
            <tbody>
              <tr>
                <td colSpan={14}>
                  <Loader loaderClass="sm-height " />
                </td>
              </tr>
            </tbody>
          )}

          {!loading && !data?.allSequences?.sequences?.length && (
            <tbody>
              <tr>
                <td colSpan={14} className="empty-table-td">
                  <NoDataFound text="No Sequence Found!" />
                </td>
              </tr>
            </tbody>
          )}
          <tbody>
            {!!data?.allSequences?.sequences?.length &&
              data?.allSequences?.sequences
                ?.map((item: any, index: number) => {
                  return (
                    <tr key={index} className="job-orders-tbody">
                      <td>
                        <div className="d-flex">
                          <Input
                            style={{
                              marginRight: 10,
                              marginTop: 0,
                              marginLeft: 0,
                              position: "inherit",
                            }}
                            bsSize="sm"
                            type="checkbox"
                            checked={checkBoxArray.includes(item?.id || '')}
                            defaultChecked={false}

                            onChange={() => handleCheckBoxes(item?.id)}
                          />

                          <div
                            style={{ cursor: "pointer" }}
                            className="text-info mt-2"
                            onClick={() => {
                              history.push(`/sequences/${item?.id}`);
                            }}
                          >
                            {item?.title}
                          </div>
                        </div>
                      </td>

                      <td>{item?.statsCount?.contacts_count || "--"}</td>
                      <td>{item?.statsCount?.viewed_contacts_count || "--"}</td>
                      <td>
                        {item?.statsCount?.replied_contacts_count || "--"}
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <label className="switch">
                            <Input
                              type="checkbox"
                              name="status"
                              value={item?.active}
                              defaultChecked={item?.active}
                              onClick={() =>
                                item?.active
                                  ? deActivateOneSequence(item)
                                  : activeSequence(item)
                              }
                            />
                            <span className="slider" />
                          </label>
                        </div>
                      </td>
                    </tr>
                  );
                })
                .reverse()}
          </tbody>
        </TableComponent>
      </div>

      <CreateSequenceModal
        setBusinessDevelopmentSequences={setBusinessDevelopmentSequences}
        refetch={refetchingSequencesOnCreation}
        open={createSequenceModal}
        toggle={createSequenceModalToggle}
      />
    </div>
  );
};

export default AllSequences;
