import { Spinner } from "reactstrap";
import Avatar from "react-avatar";
import "../../contacts/contacts.scss";

export default function ContactViewDetails({ contactDetailsData, loading }) {
  return (
    <>
      {loading ? (
        <div className="mt-2 text-center">
          <Spinner size="large" style={{ color: "#eaa827" }} className="mr-2" />
        </div>
      ) : (
        <>
          {contactDetailsData && (
            <div className="">
              <div className="contact-dialog-header">
                <div className="contact-info">
                  <div className="contact-avatar">
                    <Avatar
                      email={contactDetailsData?.email}
                      name={contactDetailsData?.name}
                    />
                  </div>

                  <div>
                    <h5>{contactDetailsData?.name} </h5>
                    <p>{contactDetailsData?.email}</p>
                    <p>{contactDetailsData?.company}</p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}
