import { useLazyQuery, useMutation } from '@apollo/client';
import { useCallback, useContext, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { toast } from 'react-toastify';
import GoBack from '../../components/GoBack';
import CustomButton from '../../components/layout/CustomButton';
import Loader from '../../components/layout/Loader';
import TableComponent from '../../components/layout/TableComponent';
import { compactObject, formatDateUS } from '../../utils/commonFn';
import Pagination from '../../components/layout/Pagination';
import {
  Badge,
  Button,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  Tooltip,
} from 'reactstrap';
import InputField from '../../components/layout/InputField';
import deleteIcon from '../../assets/images/delete-field.svg';

import { MergeCandidateType, PAGE_LIMIT, VIEW_CANDIDATE_ROUTE } from '../../utils/constant';
import {
  CREATE_MERGE_CANDIDATE,
  GET_MERGE_CANDIDATES,
  GET_PLACEMENTS_BY_CANDIDATE,
  MERGE_CANDIDATE,
} from './gql';
import { Controller, useForm } from 'react-hook-form';
import NoDataFound from '../../components/layout/NoDataFound';
import ConfirmPopUp from '../../components/ConfirmPopUp';
import { DELETE_MERGE_CANDIDATE } from './gql/index';
import { AppContext } from '../../context';

import CLOSE_ICON from '../../assets/images/close-icon.svg';
import AsyncMergeCandidates from '../../components/AsyncMergeCandidates';
import AsyncCandidateSelect from '../NotesSearch/search-components/AsyncCandidateSelectNotes';

const MERGE_CANDIDATE_HEADER = [
  'Source Candidate Name',
  'Destination Candidate Name',
  'Time Inserted',
  'Time Merged',
  'Status',
  'Note',
  'Action',
];

const MergeCandidates = ({ location, history }: RouteComponentProps) => {
  const page = new URLSearchParams(location.search).get('currentPage') || '1';
  const _currentPage: number = parseInt(page);
  const [currentPage, setCurrentPage] = useState(_currentPage);
  const { userRoles } = useContext(AppContext);
  const [limit, setLimit] = useState(10);
  const [searchParams, setSearchParams] = useState<string>('');
  const [statusFilter, setStatusFilter] = useState<string>('');
  const [selectedCandidateId, setSelectedCandidateId] = useState<string>('');
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const {
    errors,
    handleSubmit,
    register,
    watch,
    control,
    getValues,
    setValue,
    reset
  } = useForm();
  const [actionConfirm, setCandidateDeleteConfirm] = useState(false);
  const [actionId, setDeleteActionId] = useState('');
  const [candidateId, setCandidateId] = useState('');

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle1 = () => setTooltipOpen(!tooltipOpen);
  const [placementsData, setPlacementsData] = useState([]);

  const [mergeActionConfirm, setCandidateMergeConfirm] = useState(false);
  const [mergeActionId, setMergeActionId] = useState('');

  const [
    getPlacementsByCandidate,
    { data: placements, loading: placementLoading },
  ] = useLazyQuery(GET_PLACEMENTS_BY_CANDIDATE);

  const [fetchMergeCandidates, { data, loading, error }] =
    useLazyQuery(GET_MERGE_CANDIDATES);

  const [_deleteAction, { loading: deleteLoading }] = useMutation(
    DELETE_MERGE_CANDIDATE
  );

  const [_mergeAction, { loading: mergeLoading }] =
    useMutation(MERGE_CANDIDATE);

  const [_createMergeCandidate, { loading: waiting, error: createError }] =
    useMutation(CREATE_MERGE_CANDIDATE);

  const _fetchMergeCandidates = () => {
    try {
      setQueryParams();
      fetchMergeCandidates({
        variables: {
          limit,
          page: currentPage,
          searchText: searchParams,
          statusSearch: statusFilter,
          candidateId: selectedCandidateId,
        },
      });
    } catch (error) {}
  };

  const createMergeCandidate = async (values: any) => {
    values.sourceCandidateId = values.sourceCandidateId?.value;
    values.destinationCandidateId = values.destinationCandidateId?.value;
    let res = await _createMergeCandidate({ variables: { ...values } });
    if (res?.data) {
      toast.success('Request successfully created!');
      toggle();
      _fetchMergeCandidates();
    }
    if (createError && createError?.graphQLErrors) {
      toast.error(createError?.graphQLErrors[0]?.message);
      toggle();
    }
  };

  const setQueryParams = () => {
    const criteria = compactObject({
      currentPage,
      searchParams,
      statusFilter,
    });
    const urlQueryParams: any = [];
    if (Object.keys(criteria).length) {
      for (const key in criteria) {
        if (
          Object.prototype.hasOwnProperty.call(criteria, key) &&
          criteria[key]
        ) {
          urlQueryParams.push(`${key}=${criteria[key]}`);
        }
      }
      history.push(`/merge-candidate?${urlQueryParams.join('&')}`);
    }
  };

  const confirmAction = async () => {
    const response = await _deleteAction({
      variables: { mergeCandidateId: actionId },
    });
    if (response.data) {
      toast.success('Successfully deleted!');
      setCandidateDeleteConfirm(false);
      _fetchMergeCandidates();
    } else {
      setCandidateDeleteConfirm(false);
    }
  };

  const mergeCandidateConfirmation = async () => {
    const response = await _mergeAction({
      variables: { mergeCandidateId: mergeActionId },
    });
    if (response.data) {
      if (response?.data?.mergeCandidate?.status === 200) {
        toast.success('Successfully merged');
      } else {
        toast.error('Something went wrong!');
      }
      setCandidateMergeConfirm(false);
      _fetchMergeCandidates();
    } else {
      setCandidateMergeConfirm(false);
    }
  };

  const customStyles = {
    control: (styles, provided) => ({
      ...styles,

      fontSize: 12,
    }),

    option: (provided, state) => ({
      ...provided,
      fontSize: 12,
    }),

    container: (provided, state) => ({
      ...provided,
      fontSize: 12,
    }),
  };

  const _fetchPlacementByCompanies = useCallback(
    async (id: string) => {
      try {
        id &&
          getPlacementsByCandidate({
            variables: {
              candidateId: id,
            },
          });

        setPlacementsData(placements);
      } catch (error) {}
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getPlacementsByCandidate]
  );

  useEffect(() => {
    candidateId && _fetchPlacementByCompanies(candidateId);
  }, [_fetchPlacementByCompanies, candidateId]);

  useEffect(() => {
    placements &&
      !placementLoading &&
      setPlacementsData(placements.getPlacementsByCandidate);
  }, [placementLoading, placements]);

  useEffect(() => {
    _fetchMergeCandidates();
    // eslint-disable-next-line
  }, [statusFilter, selectedCandidateId, currentPage, limit]);

  return (
    <div className="admin-tabs team-tabs">
      <GoBack url="/admin" />

      <div className="primaryHeading primaryHeaderSpacing mobileResponsiveFlexAlign justify-content-between">
        <h5 className="m-0">Merge Candidates</h5>

        <div className="search-filter">
          <div className="filter-select d-flex">
            <button
              className="buttonGenericStyle filledButton mr-3"
              onClick={() => {
                toggle();
              }}
              type="button"
            >
              Merge Candidates
            </button>

            <div className="filter-by">
              <div className="mr-4">
                <AsyncMergeCandidates
                  placeholder="Destination Candidate"
                  width={'20rem'}
                  onChange={(value) => {
                    setSelectedCandidateId(value);
                    setCurrentPage(1);
                    setLimit(25);
                  }}
                />
              </div>

              <InputField
                inputtype="select"
                selectItems={MergeCandidateType}
                inputid="status"
                placeholder="Status"
                inputMethod={(value) => {
                  setStatusFilter(value);
                  setCurrentPage(1);
                  setLimit(25);
                }}
                inputValue={statusFilter}
              />

              <button
                className="buttonGenericStyle filledButton ml-3"
                onClick={() => {
                  setSearchParams('');
                  setStatusFilter('');
                  setSelectedCandidateId('');
                }}
              >
                Clear
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="table-responsive">
        <TableComponent tableHeader={MERGE_CANDIDATE_HEADER}>
          {loading && (
            <tbody>
              <tr>
                <td colSpan={7}>
                  <Loader loaderClass="sm-height " />
                </td>
              </tr>
            </tbody>
          )}
          {!loading && !data?.getMergeCandidates?.mergeCandidates?.length && (
            <tbody>
              <tr>
                <td colSpan={7} className="empty-table-td">
                  <NoDataFound text="No Merge Candidate Found!" />
                </td>
              </tr>
            </tbody>
          )}
          <tbody>
            {!loading &&
              data?.getMergeCandidates?.mergeCandidates?.map(
                (item: any, index: number) => (
                  <tr key={index}>
                    <td>
                      {item?.sourceCandidateId && (
                        <div>
                          {item?.status === 'MERGED' ? (
                            <div className="text-danger">
                              {item.sourceCandidateName}
                            </div>
                          ) : (
                            <div
                              className="text-info cursor-pointer"
                              onClick={() => {
                                history.push(
                                  `${VIEW_CANDIDATE_ROUTE}/${item?.sourceCandidateId}`
                                );
                              }}
                            >
                              {item.sourceCandidateName}
                            </div>
                          )}
                        </div>
                      )}
                    </td>
                    <td>
                      <div
                        className="text-info cursor-pointer"
                        onClick={() => {
                          history.push(
                            `${VIEW_CANDIDATE_ROUTE}/${item?.destinationCandidateId}`
                          );
                        }}
                      >
                        {item.destinationCandidateName}
                      </div>
                    </td>
                    <td>
                      {' '}
                      {item?.createdAt
                        ? formatDateUS(item?.createdAt)
                        : '--'}{' '}
                      {item?.createdAt
                        ? new Date(item?.createdAt).toLocaleTimeString()
                        : '--'}
                    </td>{' '}
                    {item.status === 'PENDING' ? (
                      <td>{'--'}</td>
                    ) : (
                      <td>
                        {' '}
                        {item?.mergedAt
                          ? formatDateUS(item?.mergedAt)
                          : '--'}{' '}
                        {item?.mergedAt
                          ? new Date(item?.mergedAt).toLocaleTimeString()
                          : '--'}
                      </td>
                    )}
                    <td>
                      <Badge
                        color={
                          item.status === 'MERGED'
                            ? 'success'
                            : item.status === 'PENDING'
                            ? 'info'
                            : item.status === 'ERROR'
                            ? 'danger'
                            : ''
                        }
                        className="text-capitalize"
                      >
                        {item.status}
                      </Badge>
                    </td>
                    <td>
                      <div
                        className="text-ellipsis"
                        title={item.mergeNotes || '--'}
                      >
                        {' '}
                        {item.mergeNotes || '--'}
                      </div>
                    </td>
                    <td className="d-flex align-items-center">
                      {item.status === 'PENDING' &&
                      (userRoles?.includes('ADMIN') ||
                        userRoles?.includes('EXECUTIVE')) ? (
                        <Button
                          onClick={() => {
                            setCandidateMergeConfirm(true);
                            setMergeActionId(item?.id);
                          }}
                          className="bg-transparent border-0 for-link-theme-color"
                        >
                          Merge
                        </Button>
                      ) : (
                        <Button
                          className="bg-transparent border-0 for-link-theme-color"
                          disabled={true}
                        >
                          Merge
                        </Button>
                      )}

                      {item.status === 'PENDING' &&
                      (userRoles?.includes('ADMIN') ||
                        userRoles?.includes('EXECUTIVE')) ? (
                        <Button
                          onClick={() => {
                            setCandidateDeleteConfirm(true);
                            setDeleteActionId(item?.id);
                          }}
                          className="my-auto bg-transparent border-0 w-auto d-flex justify-content-center align-items-center"
                        >
                          <img src={deleteIcon} alt="" width="20" height="20" />
                        </Button>
                      ) : (
                        <Button
                          onClick={() => {
                            setCandidateDeleteConfirm(true);
                            setDeleteActionId(item?.id);
                          }}
                          disabled={true}
                          className="my-auto bg-transparent border-0 w-auto d-flex justify-content-center align-items-center"
                        >
                          <img src={deleteIcon} alt="" width="20" height="20" />
                        </Button>
                      )}
                    </td>
                  </tr>
                )
              )}
          </tbody>
        </TableComponent>
      </div>

      {data?.getMergeCandidates?.mergeCandidates.length > 0 && (
        <div className="users-pagination">
          <Pagination
            onPageChange={(pageClicked: number) => {
              setCurrentPage(pageClicked);
              history.push(`/merge-candidate?currentPage=${pageClicked}`);
            }}
            pageCount={Math.ceil(data?.getMergeCandidates?.count / limit)}
            currentPage={currentPage}
          />

          <div className="d-flex align-items-center">
            <div className="pagination-display paginationItemsSelect">
              <p className="m-0">
                {(currentPage - 1) * limit + 1} to{' '}
                {Math.min(currentPage * limit, data?.getMergeCandidates?.count)}{' '}
                of {data?.getMergeCandidates?.count}
              </p>

              <div className="divider"></div>

              <label>Display</label>

              <InputField
                label=""
                inputtype="select"
                selectItems={PAGE_LIMIT.filter((item) => item.value !== limit)}
                inputid="limit"
                placeholder={limit.toString()}
                inputMethod={(value) => {
                  setCurrentPage(1);
                  setLimit(parseInt(value));
                }}
              />
            </div>
          </div>
        </div>
      )}
      {error?.graphQLErrors[0]?.message}

      <Modal
        isOpen={modal}
        toggle={toggle}
        className="revampDialog"
        id="merge-candidates"
      >
        <div className="modalHeaderCustom d-flex align-items-center justify-content-between">
          <h5 className="m-0">Merge Candidates</h5>

          <Button
            className="p-0 bg-transparent border-0"
            onClick={() => {
              toggle();
              setPlacementsData([]);
              setCandidateId('');
            }}
          >
            <img src={CLOSE_ICON} alt="close" />
          </Button>
        </div>

        <Form onSubmit={handleSubmit(createMergeCandidate)}>
          <ModalBody>
            <Label>
              Source Candidate<span className="text-danger">*</span>
              {!placementLoading && !!placementsData?.length && (
                <>
                  <span
                    id="TooltipExample4"
                    style={{
                      marginLeft: '5px',
                      fontWeight: 'bold',
                      fontSize: '15px',
                      color: 'red',
                    }}
                  >
                    !
                  </span>

                  <Tooltip
                    placement="top"
                    isOpen={tooltipOpen}
                    target="TooltipExample4"
                    toggle={toggle1}
                  >
                    Placements Found
                  </Tooltip>
                </>
              )}
            </Label>
            <Controller
              control={control}
              defaultValue={''}
              rules={{
                required: {
                  value: true,
                  message: 'Source candidate is required!',
                },
              }}
              name="sourceCandidateId"
              render={({ onChange }) => (
                <AsyncCandidateSelect
                  mergeCandidate={true}
                  placeholder="Source Candidate name"
                  value={getValues()?.sourceCandidateId}
                  onChange={(value) => {
                    setValue('sourceCandidateId', value);
                    setCandidateId(value.value);
                    reset(error)
                  }}
                />
              )}
              styles={customStyles}
            />
            <small className="text-danger pt-2 d-block">
              {errors?.sourceCandidateId?.message}
            </small>
          
            <Controller
              control={control}
              defaultValue={''}
              rules={{
                required: {
                  value: true,
                  message: 'Destination candidate is required!',
                },
                validate: (vl) => {
                  const { value } = watch('sourceCandidateId');
                  if (value === vl?.value) {
                    return 'Selected candidates should be different!';
                  }
                },
              }}
              name="destinationCandidateId"
              render={({ onChange }) => (
                <AsyncCandidateSelect
                 label={`Destination Candidate`}
                 required={true}
                  placeholder="Destination Candidate name"
                  value={getValues()?.destinationCandidateId}
                  onChange={(value) => {
                    setValue('destinationCandidateId', value);
                    reset(error)

                  }}
                  mergeCandidate={true}
                />
              )}
              styles={customStyles}
            />
            <small className="text-danger pt-2">
              {errors?.destinationCandidateId?.message}
            </small>
            <FormGroup className="mt-2">
              <Label>Merge Notes</Label>
              <textarea
                name="mergeNotes"
                ref={register({ required: false })}
                placeholder="Merge notes..."
                rows={3}
                className="w-100 form-control"
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <div>
              <button
                onClick={() => {
                  toggle();
                  setPlacementsData([]);
                  setCandidateId('');
                }}
                className="buttonPrimary mr-3"
                type="button"
              >
                Cancel
              </button>
              <CustomButton
                buttonText="Merge Candidates"
                buttonColor="primary"
                buttonType="submit"
                className="big-width"
                loading={waiting}
                disabled={waiting}
              />
            </div>
          </ModalFooter>
        </Form>
      </Modal>

      <ConfirmPopUp
        confirmText="Are you sure you want to delete this candidate from merged candidates list?"
        isOpen={actionConfirm}
        toggle={setCandidateDeleteConfirm}
        confirmAction={confirmAction}
        modalHeading="Delete action"
        btnText="Delete"
        btnColor="primary"
        disabled={deleteLoading}
        loading={deleteLoading}
        className="revampDialog revampDialogWidth"
      />

      <ConfirmPopUp
        confirmText="Are you sure you want to merge this candidate from merged candidates list?"
        isOpen={mergeActionConfirm}
        toggle={setCandidateMergeConfirm}
        confirmAction={mergeCandidateConfirmation}
        modalHeading="Merge action"
        btnText="Confirm"
        loading={mergeLoading}
        disabled={mergeLoading}
        btnColor="primary"
        className="revampDialog revampDialogWidth"
      />
    </div>
  );
};

export default MergeCandidates;
