import { compactObject } from "../Pages/candidate-pipeline/placements/components/utils";

export const getNoteParams = (urlParams) => {
  const companyId = new URLSearchParams(urlParams).get("companyId");
  const companyName = new URLSearchParams(urlParams).get("companyName");

  const candidateId = new URLSearchParams(urlParams).get("candidateId");
  const candidateName = new URLSearchParams(urlParams).get("candidateName");
  const jobOrderId = new URLSearchParams(urlParams).get("jobOrderId");
  const jobTitle = new URLSearchParams(urlParams).get("jobTitle");

  const contactId = new URLSearchParams(urlParams).get("contactId");
  const contactName = new URLSearchParams(urlParams).get("contactName");

  const recruiterId = new URLSearchParams(urlParams).get("recruiterId");
  const recruiterName = new URLSearchParams(urlParams).get("recruiterName");

  const label = new URLSearchParams(urlParams).get("categoryLabel");
  const value = new URLSearchParams(urlParams).get("categoryValue");

  return {
    companyId,
    companyName,
    candidateId,
    candidateName,
    jobOrderId,
    jobTitle,
    contactId,
    contactName,
    recruiterId,
    recruiterName,
    category: { label, value },
  };
};

export const getAgreementParams = (urlParams) => {
  const params = new URLSearchParams(urlParams)
  const currentPage = params?.get("currentPage");
  const searchParams = params?.get("searchParams");
  const limit = params?.get("limit");
  const selectedCompanyId = params?.get(
    "selectedCompanyId"
  );
  const selectedCompanyName = params?.get(
    "selectedCompanyName"
  );
  const myCompaniesFlag = params?.get("myCompaniesFlag")

  return compactObject({
    currentPage,
    searchParams,
    limit,
    selectedCompanyId,
    selectedCompanyName,
    myCompaniesFlag,
    columnFilters: compactObject({
      companySearch: new URLSearchParams(urlParams).get("companySearch"),
      contactSearch: new URLSearchParams(urlParams).get("contactSearch"),
      nickNameSearch: new URLSearchParams(urlParams).get("nickNameSearch"),
      typeSearch: new URLSearchParams(urlParams).get("typeSearch"),
      statusSearch: new URLSearchParams(urlParams).get("statusSearch"),
      accountManagerSearch: new URLSearchParams(urlParams).get("accountManagerSearch"),

      // Sort
      sorting: {
        columnName: new URLSearchParams(urlParams).get("columnName"),
        columnValue: new URLSearchParams(urlParams).get("columnValue"),
      }

    })
  });
};

export const getCompaniesParams = (urlParams) => {
  const currentPage = new URLSearchParams(urlParams).get("currentPage");
  const searchParams = new URLSearchParams(urlParams).get("searchParams");
  const myCompaniesFlag = new URLSearchParams(urlParams).get("myCompaniesFlag");
  const limit = new URLSearchParams(urlParams).get("limit");
  const statusFilter = new URLSearchParams(urlParams).get("statusFilter");

  return compactObject({
    currentPage,
    searchParams,
    limit,
    statusFilter,
    myCompaniesFlag,
    columnFilters: compactObject({
      companySearch: new URLSearchParams(urlParams).get("companySearch"),
      locationSearch: new URLSearchParams(urlParams).get("locationSearch"),
      stausSearch: new URLSearchParams(urlParams).get("stausSearch"),
      phoneSearch: new URLSearchParams(urlParams).get("phoneSearch"),
      companyOwnerSearch: new URLSearchParams(urlParams).get("companyOwnerSearch"),

      // Sort
      sorting: {
        columnName: new URLSearchParams(urlParams).get("columnName"),
        columnValue: new URLSearchParams(urlParams).get("columnValue"),
      }

    })
  });
};

export const getContactsParams = (urlParams) => {
  const currentPage = new URLSearchParams(urlParams).get("currentPage");
  const limit = new URLSearchParams(urlParams).get("limit");

  return compactObject({
    currentPage,
    limit,
    columnFilters: compactObject({
      fullNameSearch: new URLSearchParams(urlParams).get("fullNameSearch"),
      titleSearch: new URLSearchParams(urlParams).get("titleSearch"),
      emailSearch: new URLSearchParams(urlParams).get("emailSearch"),
      contactStatusSearch: new URLSearchParams(urlParams).get("contactStatusSearch"),
      phoneSearch: new URLSearchParams(urlParams).get("phoneSearch"),
      companyNameSearch: new URLSearchParams(urlParams).get("companyNameSearch"),

      // Sort
      sorting: {
        columnName: new URLSearchParams(urlParams).get("columnName"),
        columnValue: new URLSearchParams(urlParams).get("columnValue"),
      }

    })
  });
};

export const getAllJobOrdersParams = (urlParams) => {
  const currentPage = new URLSearchParams(urlParams).get("currentPage");
  const limit = new URLSearchParams(urlParams).get("limit");
  const myJobOrderFlag = new URLSearchParams(urlParams).get("myJobOrderFlag");
  const selectedCompanyId = new URLSearchParams(urlParams).get(
    "selectedCompanyId"
  );
  const selectedCompanyName = new URLSearchParams(urlParams).get(
    "selectedCompanyName"
  );


  return compactObject({
    currentPage,
    limit,
    myJobOrderFlag,
    selectedCompanyId,
    selectedCompanyName,
    columnFilters: compactObject({
      companySearch: new URLSearchParams(urlParams).get("companySearch"),
      jobTitleSearch: new URLSearchParams(urlParams).get("jobTitleSearch"),
      companyownerSearch: new URLSearchParams(urlParams).get("companyownerSearch"),
      locationSearch: new URLSearchParams(urlParams).get("locationSearch"),
      // workTypeSearch: new URLSearchParams(urlParams).get("workTypeSearch"),
      postedSearch: new URLSearchParams(urlParams).get("postedSearch"),
      statusSearch: new URLSearchParams(urlParams).get("statusSearch"),
      mainContactSearch: new URLSearchParams(urlParams).get("mainContactSearch"),
      // Sort
      sorting: {
        columnName: new URLSearchParams(urlParams).get("columnName"),
        columnValue: new URLSearchParams(urlParams).get("columnValue"),
      }
    })
  });
};

export const getAllJobListParams = (urlParams) => {
  const currentPage = new URLSearchParams(urlParams).get("currentPage");
  const limit = new URLSearchParams(urlParams).get("limit");

  return compactObject({
    currentPage,
    limit,
    columnFilters: compactObject({
      companySearch: new URLSearchParams(urlParams).get("companySearch"),
      positionSearch: new URLSearchParams(urlParams).get("positionSearch"),
      recruiter: new URLSearchParams(urlParams).get("recruiter"),

      sorting: {
        columnName: new URLSearchParams(urlParams).get("columnName"),
        columnValue: new URLSearchParams(urlParams).get("columnValue"),
      }
    })
  });
};


export const getCreateAgreementParams = (urlParams) => {
  const selectedCompanyId = new URLSearchParams(urlParams).get(
    "selectedCompanyId"
  );
  const selectedCompanyName = new URLSearchParams(urlParams).get(
    "selectedCompanyName"
  );

  const selectedContactId = new URLSearchParams(urlParams).get(
    "selectedContactId"
  );

  const selectedContactName = new URLSearchParams(urlParams).get(
    "selectedContactName"
  );

  return compactObject({
    selectedContactId,
    selectedContactName,
    selectedCompanyId,
    selectedCompanyName,
  });
};

export const getJobApplicantsParams = (urlParams) => {
  const currentPage = new URLSearchParams(urlParams).get("currentPage");
  const searchParams = new URLSearchParams(urlParams).get("searchParams");
  const limit = new URLSearchParams(urlParams).get("limit");
  const statusFilter = new URLSearchParams(urlParams).get("statusFilter");
  const selectedCompanyId = new URLSearchParams(urlParams).get(
    "selectedCompanyId"
  );
  const filterCheck = new URLSearchParams(urlParams).get("filterCheck");
  const selectedCompanyName = new URLSearchParams(urlParams).get(
    "selectedCompanyName"
  );

  return compactObject({
    currentPage,
    searchParams,
    limit,
    statusFilter,
    selectedCompanyId,
    filterCheck,
    selectedCompanyName,
  });
};

export const getCandidatePipelineParams = (urlParams) => {
  const currentPage = new URLSearchParams(urlParams).get("currentPage");
  const searchParams = new URLSearchParams(urlParams).get("searchParams");
  const limit = new URLSearchParams(urlParams).get("limit");
  const statusFilter = new URLSearchParams(urlParams).get("statusFilter");
  const filterCheck = new URLSearchParams(urlParams).get("filterCheck");
  const selectedCompanyId = new URLSearchParams(urlParams).get(
    "selectedCompanyId"
  );
  const selectedCompanyName = new URLSearchParams(urlParams).get(
    "selectedCompanyName"
  );

  return compactObject({
    currentPage,
    searchParams,
    limit,
    statusFilter,
    selectedCompanyId,
    selectedCompanyName,
    filterCheck,
  });
};

export const getCandidatesParams = (urlParams) => {
  const currentPage = new URLSearchParams(urlParams).get("currentPage");
  const limit = new URLSearchParams(urlParams).get("limit");
  const myCandidatesFlag = new URLSearchParams(urlParams).get("myCandidatesFlag");

  return compactObject({
    currentPage,
    limit,
    myCandidatesFlag,
    columnFilters: compactObject({
      nameSearch: new URLSearchParams(urlParams).get("nameSearch"),
      jobTitleSearch: new URLSearchParams(urlParams).get("jobTitleSearch"),
      emailSearch: new URLSearchParams(urlParams).get("emailSearch"),
      phoneSearch: new URLSearchParams(urlParams).get("phoneSearch"),
      locationSearch: new URLSearchParams(urlParams).get("locationSearch"),
      candidateStatusSearch: new URLSearchParams(urlParams).get("candidateStatusSearch"),
      // Sort
      sorting: {
        columnName: new URLSearchParams(urlParams).get("columnName"),
        columnValue: new URLSearchParams(urlParams).get("columnValue"),
      }

    })
  });
};

export const getPrivateCandidatesParams = (urlParams) => {
  const currentPage = new URLSearchParams(urlParams).get("currentPage");
  const searchParams = new URLSearchParams(urlParams).get("searchParams");
  const limit = new URLSearchParams(urlParams).get("limit");
  const statusFilter = new URLSearchParams(urlParams).get("statusFilter");

  return compactObject({
    currentPage,
    searchParams,
    limit,
    statusFilter,
  });
};

export const getPlacementManagementParams = (urlParams) => {
  const currentPage = new URLSearchParams(urlParams).get("currentPage");
  const searchParams = new URLSearchParams(urlParams).get("searchParams");
  const limit = new URLSearchParams(urlParams).get("limit");

  return compactObject({
    currentPage,
    limit,
    searchParams,
    columnFilters: compactObject({
      candidateSearch: new URLSearchParams(urlParams).get("candidateSearch"),
      jobTitleSearch: new URLSearchParams(urlParams).get("jobTitleSearch"),
      netFeeSearch: new URLSearchParams(urlParams).get("netFeeSearch"),
      IdSearch: new URLSearchParams(urlParams).get("IdSearch"),
      statusSearch: new URLSearchParams(urlParams).get("statusSearch"),
      placementTypeSearch: new URLSearchParams(urlParams).get("placementTypeSearch"),
      startDateSearch: new URLSearchParams(urlParams).get("startDateSearch"),
      placeDateSearch: new URLSearchParams(urlParams).get("placeDateSearch"),
      accountManagerSearch: new URLSearchParams(urlParams).get("accountManagerSearch"),
      RecruiterSearch: new URLSearchParams(urlParams).get("RecruiterSearch"),
      workLocationSearch: new URLSearchParams(urlParams).get("workLocationSearch"),
      // Sort
      sorting: {
        columnName: new URLSearchParams(urlParams).get("columnName"),
        columnValue: new URLSearchParams(urlParams).get("columnValue"),
      }

    })
  });
};

export const getAccountingDashboardListParams = (urlParams) => {
  const currentPage = new URLSearchParams(urlParams).get("currentPage");
  const searchParams = new URLSearchParams(urlParams).get("searchParams");
  const limit = new URLSearchParams(urlParams).get("limit");

  return compactObject({
    currentPage,
    searchParams,
    limit,
    columnFilters: compactObject({
      candidateSearch: new URLSearchParams(urlParams).get("candidateSearch"),
      jobTitleSearch: new URLSearchParams(urlParams).get("jobTitleSearch"),
      netFeeSearch: new URLSearchParams(urlParams).get("netFeeSearch"),
      IdSearch: new URLSearchParams(urlParams).get("IdSearch"),
      statusSearch: new URLSearchParams(urlParams).get("statusSearch"),
      placementTypeSearch: new URLSearchParams(urlParams).get("placementTypeSearch"),
      startDateSearch: new URLSearchParams(urlParams).get("startDateSearch"),
      placeDateSearch: new URLSearchParams(urlParams).get("placeDateSearch"),
      accountManagerSearch: new URLSearchParams(urlParams).get("accountManagerSearch"),
      RecruiterSearch: new URLSearchParams(urlParams).get("RecruiterSearch"),
      workLocationSearch: new URLSearchParams(urlParams).get("workLocationSearch"),
      paidAtSearch: new URLSearchParams(urlParams).get("paidAtSearch"),
      // Sort
      sorting: {
        columnName: new URLSearchParams(urlParams).get("columnName"),
        columnValue: new URLSearchParams(urlParams).get("columnValue"),
      }

    })
  });
};

export const getRecruitersFolderParams = (urlParams) => {
  const currentPage = new URLSearchParams(urlParams).get("currentPage");
  const searchParams = new URLSearchParams(urlParams).get("searchParams");
  const limit = new URLSearchParams(urlParams).get("limit");

  return compactObject({
    currentPage,
    searchParams,
    limit,
  });
};
