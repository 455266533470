import { pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import Loader from "../../../../components/layout/Loader";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Preview = ({
  documentUrl,
  documentName,
  isDownload = true,
  ...props
}) => {
  // const [numPages, setNumPages] = useState<number>(0);

  // function onDocumentLoadSuccess({ numPages }) {
  //   setNumPages(numPages);
  // }

  return (
    <div>
      <div className="pdf-heading ">
        <div>
          <h4 className="text-truncate">
            {documentName?.split(".").slice(0, -1).join(".")}
          </h4>
          {props?.fileStatus && (
            <small className="color-default d-block font-weight-bold mt-1">
              {props?.fileStatus}
            </small>
          )}
        </div>
      </div>

      {documentUrl ? (
        <>
          {/* <div
            className="scrolled justify-content-center align-items-center d-flex"
            style={{ overflowY: "scroll" }}
          >
            <Document
              file={{
                url: documentUrl,
              }}
              loading={<Loader />}
              onLoadSuccess={onDocumentLoadSuccess}
            >
              {Array.from(new Array(numPages), (el, index) => (
                <Page
                  key={`page_${index + 1}`}
                  pageNumber={index + 1}
                  width={1000}
                  loading={<Loader />}
                />
              ))}
            </Document>
          </div> */}

          <div>
            <iframe
              src={documentUrl}
              title="xyz"
              width="1000"
              height="1000"
              style={{
                width: "100%",
                height: "45em",
                display: "block",
                border: "1px solid #aaa",
              }}
              frameBorder="0"
              allowFullScreen
            ></iframe>
          </div>
        </>
      ) : (
        <Loader loaderClass="sm-height " />
      )}
    </div>
  );
};

export default Preview;
