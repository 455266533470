import { Badge } from "reactstrap";
import { AWAITING_EXECUTIVE_APPROVAL, AWAITING_MANAGER_APPROVAL, CLIENT_PREVIEW, CLIENT_REJECTED, DRAFT, EXECUTIVE_APPROVED, EXECUTIVE_REJECTED, MANAGER_REJECTED, OUT_FOR_SIGNATURE, PENDING_EXECUTIVE_APPROVAL, RESENT, SIGNED, UNDELIVERABLE, VOIDED } from "../../../utils/constant";

export default function HeaderAgreementStatus({ agreementStatus }) {
  return (
    <div>
      {agreementStatus === DRAFT ? (
        <Badge>Draft</Badge>
      ) : agreementStatus === AWAITING_MANAGER_APPROVAL ? (
        <Badge color="success">Awaiting Manager Approval</Badge>
      ) : agreementStatus === MANAGER_REJECTED ? (
        <Badge color="danger">Manager Rejected</Badge>
      ) : agreementStatus === AWAITING_EXECUTIVE_APPROVAL ? (
        <Badge color="success">Awaiting Executive Approval</Badge>
      ) : agreementStatus === PENDING_EXECUTIVE_APPROVAL ? (
        <Badge color="success">Pending Executive Approval</Badge>
      ) : agreementStatus === EXECUTIVE_APPROVED ? (
        <Badge color="success">Executive Approved</Badge>
      ) : agreementStatus === EXECUTIVE_REJECTED ? (
        <Badge color="danger">Executive Rejected</Badge>
      ) : agreementStatus === OUT_FOR_SIGNATURE ? (
        <Badge color="warning">Out For Signature</Badge>
      ) : agreementStatus === SIGNED ? (
        <Badge color="success">Signed</Badge>
      ) : agreementStatus === CLIENT_REJECTED ? (
        <Badge color="danger">Client Rejected</Badge>
      ) : agreementStatus === VOIDED ? (
        <Badge color="danger">Voided</Badge>
      ) : agreementStatus === RESENT ? (
        <Badge color="warning">Resent</Badge>
      ) : agreementStatus === UNDELIVERABLE ? (
        <Badge color="danger">Undeliverable</Badge>
      ) : agreementStatus === CLIENT_PREVIEW ? (
        <Badge>Client Preview</Badge>
      ) : null}
    </div>
  );
}
