import { useLazyQuery, useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import {
  Button,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import deleteIcon from '../assets/images/delete-field.svg';
import ConfirmPopUp from '../components/ConfirmPopUp';
import CustomButton from '../components/layout/CustomButton';
import {
  CREATE_ACTION,
  DELETE_ACTION,
  GET_ACTIONS,
} from '../Pages/company/gql';
import { formatDateUS } from '../utils/commonFn';
import NoDataFound from './layout/NoDataFound';
import { PAGE_LIMIT } from '../utils/constant';
import Pagination from './layout/Pagination';
import Loader from './layout/Loader';
import ItemSummary from '../Pages/candidate/candidate-components/ItemSummary';
import TableComponent from './layout/TableComponent';
import InputField from './layout/InputField';

const EMPLOYMENT_HEADER = ['NOTE', 'CREATED BY', 'CREATED  ON', 'ACTIONS'];

const ActionsLogs = ({
  haveActionPersmissions,
  actionableId,
  actionableType,
}: {
  haveActionPersmissions?: boolean;
  actionableId: string;
  actionableType: string;
}) => {
  const page = parseInt('1');
  const [currentPage, setCurrentPage] = useState(page);
  const [limit, setLimit] = useState(10);

  // const { userRoles } = useContext(AppContext);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const { handleSubmit, reset, register } = useForm();
  const [getNotes, { data: notes, loading }] = useLazyQuery(GET_ACTIONS);
  const [createActions, { loading: createActionLoading }] = useMutation(CREATE_ACTION);
  const [_deleteAction] = useMutation(DELETE_ACTION);
  const [actionConfirm, setNoteConfirm] = useState(false);
  const [actionId, setDeleteActionId] = useState('');

  const createNote = async (values: any) => {
    values.actionableId = actionableId;
    values.actionableType = actionableType;
    await createActions({ variables: { ...values } });
    toast.success('Successfully created!');
    getNotes({ variables: { id: actionableId, limit, page: currentPage } });
    reset();
    toggle();
  };

  const deleteNote = async () => {
    await _deleteAction({ variables: { actionId: actionId } });
    toast.success('Successfully deleted!');
    getNotes({ variables: { id: actionableId, limit, page: currentPage } });
    if (notes?.getActions?.actionLogs?.length === 1 && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
    setNoteConfirm(false);
  };

  useEffect(() => {
    if (actionableId) {
      getNotes({ variables: { id: actionableId, limit, page: currentPage } });
    }

    // eslint-disable-next-line
  }, [actionableId, limit, currentPage]);

  return (
    <div>
      <div className="text-right actionLogTableRecamp">
        <button
          type="button"
          className="buttonGenericStyle filledButton"
          onClick={() => {
            toggle();
          }}
        >
          Add New Action
        </button>
      </div>
      {/*LogChange*/}
      <div className="table-responsive">
        <TableComponent tableHeader={EMPLOYMENT_HEADER}>
          {loading && (
            <tbody>
              <tr>
                <td colSpan={12}>
                  <Loader loaderClass="sm-height " />
                </td>
              </tr>
            </tbody>
          )}
          <tbody>
            {!loading && (
              <>
                {notes?.getActions?.actionLogs &&
                  notes?.getActions?.actionLogs.map((item: any) => (
                    <tr>
                      <td>
                        <div className="d-flex align-items-center jobOrdersCandidateNotes">
                          <ItemSummary item={item} from={'Actions'} />
                        </div>
                      </td>

                      <td>
                        <div className="nameText">{item?.user?.fullName}</div>
                      </td>

                      <td>
                        {item.createdAt && formatDateUS(item.createdAt)} -{' '}
                        {item.createdAt &&
                          new Date(item.createdAt).toLocaleTimeString()}
                      </td>

                      <td>
                        {/* {userRoles &&
                        userRoles.length &&
                        userRoles.includes(ROLES.ADMIN) ? ( */}
                        <Button
                          disabled={haveActionPersmissions}
                          onClick={() => {
                            setNoteConfirm(true);
                            setDeleteActionId(item.id);
                          }}
                          className="bg-transparent border-0 d-flex justify-content-center align-items-center"
                        >
                          <img src={deleteIcon} alt="" width="20" height="20" />
                        </Button>
                        {/* ) : (
                          <div className="ml-3 my-auto bg-transparent border-0 w-auto d-flex justify-content-center align-items-center"></div>
                        )} */}
                      </td>
                    </tr>
                  ))}

                {!loading && !notes?.getActions?.actionLogs?.length && (
                  <tr>
                    <td colSpan={5}>
                      <div className="for-position-multiselect">
                        <NoDataFound text="Action Logs Not Found!" />
                      </div>
                    </td>
                  </tr>
                )}
              </>
            )}
          </tbody>
        </TableComponent>

        {notes?.getActions?.actionLogs?.length ? (
          <div className="users-pagination">
            <Pagination
              key={notes?.getActions?.actionLogs?.id}
              onPageChange={(pageClicked: number) => {
                setCurrentPage(pageClicked);
              }}
              pageCount={Math.ceil(notes?.getActions?.count / limit)}
              currentPage={currentPage}
            />

            <div className="d-flex align-items-center">
              <div className="pagination-display paginationItemsSelect">
                <p className="m-0">
                  {(currentPage - 1) * limit + 1} to{' '}
                  {Math.min(currentPage * limit, notes?.getActions?.count)} of{' '}
                  {notes?.getActions?.count}
                </p>

                <div className="divider"></div>

                <label>Display</label>

                <InputField
                  label=""
                  inputtype="select"
                  selectItems={PAGE_LIMIT}
                  inputid="limit"
                  placeholder={limit !== 10 ? limit.toString() : 'Set Limit'}
                  inputMethod={(value) => {
                    setCurrentPage(1);
                    setLimit(parseInt(value));
                  }}
                />
              </div>
            </div>
          </div>
        ) : null}
      </div>

      <Modal
        isOpen={modal}
        toggle={toggle}
        className="revampDialog revampDialogWidth"
      >
        <ModalHeader toggle={toggle}>Add Action Log</ModalHeader>
        <Form>
          <ModalBody>
            <FormGroup>
              <Label>Action Details</Label>
              <input
                name="content"
                ref={register({
                  required: {
                    value: true,
                    message: 'Enter some action detail!',
                  },
                })}
                placeholder="Add detail..."
                // rows={3}
                className="w-100 form-control"
              />
              {/* <Controller
                control={control}
                name="content"
                rules={{ required: "Content is required!" }}
                defaultValue={""}
                render={({ onChange, ref }) => (
                  <div className="editor-height">
                    <ReactQuill
                      onChange={(value) => {
                        onChange(value);
                      }}
                      ref={ref}
                    />
                    <small className="text-danger pt-2">
                      {errors?.content?.message}
                    </small>
                  </div>
                )}
              /> */}
            </FormGroup>
            {/* <InputField
              label="Linked With"
              inputtype="select"
              selectItems={LinkedWith}
              inputid="actionableType"
              placeholder="Linked With"
              inputRef={register({ required: false })}
              inputValue={actionableType}
            />
            <InputField
              label="Company"
              inputtype="select"
              selectItems={companiesList}
              inputid="actionableId"
              placeholder="Company"
              inputRef={register({ required: false })}
              inputValue={actionableId}
            /> */}
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              onClick={toggle}
              className="buttonPrimary mr-3"
            >
              Cancel
            </button>
            <CustomButton
              buttonText="Add Action Log"
              buttonColor="primary"
              buttonType="submit"
              loading={createActionLoading}
              disabled={createActionLoading}
              className="big-width"
              buttonClick={handleSubmit(createNote)}
            />
          </ModalFooter>
        </Form>
      </Modal>

      <ConfirmPopUp
        confirmText="Are you sure you want to delete this action?"
        isOpen={actionConfirm}
        toggle={setNoteConfirm}
        confirmAction={deleteNote}
        modalHeading="Delete Action"
        btnText="Delete"
        btnColor="primary"
        className="revampDialog revampDialogWidth"
      />
    </div>
  );
};

export default ActionsLogs;
