import { useState } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import LookupContact from "./contacts-components/LookupContact";
import AddContactManually from "./contacts-components/AddContactManually";
import "./contacts.scss";

const AddContact = ({
  compaignId,
  reFetchContacts,
  refetchStats,
  addContactToggle,
}) => {
  const [activeTab, setActiveTab] = useState("1");

  const toggle = (tab: string) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <div className="CandidateTabsWrapper mb-0">
      <Nav tabs className="p-0 mb-3">
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "1" })}
            onClick={() => {
              toggle("1");
            }}
          >
            Look Up Contact
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "2" })}
            onClick={() => {
              toggle("2");
            }}
          >
            Add Manually
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <LookupContact
            addContactToggle={addContactToggle}
            compaignId={compaignId}
            refetchStats={refetchStats}
            reFetchContacts={reFetchContacts}
          />
        </TabPane>
        <TabPane tabId="2">
          <AddContactManually
            addContactToggle={addContactToggle}
            compaignId={compaignId}
            refetchStats={refetchStats}
            reFetchContacts={reFetchContacts}
          />
        </TabPane>
      </TabContent>
    </div>
  );
};

export default AddContact;
