import { useMutation } from '@apollo/client';
import { useState, useContext } from 'react';
import { AppContext } from "../context";
import ReactQuill from 'react-quill';
import Select from 'react-select';
import { toast } from 'react-toastify';
import {
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import { CREATE_NOTES } from '../Pages/company/gql';
import { feedBackCategory } from '../utils/constant';
import CustomButton from './layout/CustomButton';

const FeedbackModal = ({ modal, toggle, details, }, props) => {
  const { theme } = useContext(AppContext);
  const [feedbackDetail, setFeedbackDetail] = useState({
    category: '',
    feedback: '',
  });
  const [feedbackErrs, setFeedbackErrs] = useState({
    categoryErr: '',
    feedbackErr: '',
  });
  const [_createNotes, { loading }] = useMutation(CREATE_NOTES);

  const createNotes = async () => {
    if (!feedbackDetail?.category) {
      setFeedbackErrs({
        ...feedbackErrs,
        categoryErr: 'Category must be selected!',
      });
      return;
    }
    if (!feedbackDetail?.feedback) {
      setFeedbackErrs({
        ...feedbackErrs,
        feedbackErr: 'Feedback detail must be added!',
      });
      return;
    }
    if (details?.applicantId) {
      let payload = {
        noteableType: 'Feedback',
        noteableId: details?.applicantId,
        content: feedbackDetail?.feedback,
        category: feedbackDetail?.category,
      };
      let res = await _createNotes({ variables: { ...payload } });

      if (res?.errors) {
        toast.error(res?.errors[0]?.message);
      }
      if (res?.data) {
        toast.success('Successfully Added!');
        setFeedbackErrs({
          categoryErr: '',
          feedbackErr: '',
        });
        setFeedbackDetail({
          category: '',
          feedback: '',
        });
        toggle();
      }
    } else {
      toast.error('Applicant details missing!');
    }
  };

  const customStyles = {
    control: (styles, provided) => ({
      ...styles,
      width: `${props.width} !important`,
      background: theme === "light" ? provided.background : "#282828",
      borderColor: theme === "light" ? "#ced4da" : "#282828",
      color: theme === "light" ? provided.background : "#d9d9d9",
      fontSize: 12,
      "&:hover": {
        borderColor: theme === "light" ? "#ced4da" : "#282828",
        color: theme === "light" ? provided.background : "#d9d9d9",
      },
    }),
    menu: (provided) => ({
      ...provided,
      background: theme === "light" ? provided.background : "#282828",
      zIndex: props?.zIndex || 999999,
      color: theme === "light" ? provided.background : "#d9d9d9",
    }),
    option: (provided) => ({
      ...provided,
      fontSize: 12,
      background: theme === "light" ? provided.background : "#363636",
      "&:hover": {
        background: theme === "light" ? provided.background : "#474444",
        color: theme === "light" ? provided.background : "#d9d9d9",
      },
    }),
    container: (provided, state) => ({
      ...provided,
      fontSize: 12,
    }),
  };

  return (
    <Modal
      isOpen={modal}
      className="revampDialog revampDialogWidth"
    >
      <ModalHeader toggle={toggle} className="mb-2">
        Feedback
      </ModalHeader>

      <ModalBody>
        <div className="mb-4">
          <div className="d-flex mb-1 ">
            <small className="font-weight-bold text-muted mr-2">
              Candidate Name :{' '}
            </small>
            <small className="light-text">{details?.candidateName}</small>
          </div>
          <div className="d-flex mb-1 ">
            <small className="font-weight-bold text-muted mr-2">
              Job Order :{' '}
            </small>
            <small className="light-text">{details?.jobTitle}</small>
          </div>
          <div className="d-flex mb-1 ">
            <small className="font-weight-bold text-muted mr-2">
              Company Name :{' '}
            </small>
            <small className="light-text">{details?.companyName}</small>
          </div>
          <div className="d-flex mb-1 ">
            <small className="font-weight-bold text-muted mr-2">
              Location :{' '}
            </small>
            <small className="light-text">{details?.location}</small>
          </div>
        </div>
        <FormGroup>
          <Label>
            Category <span className="text-danger">*</span>
          </Label>
          <Select
            isSearchable
            value={
              feedbackDetail?.category
                ? {
                  label: feedbackDetail.category,
                  value: feedbackDetail.category,
                }
                : null
            }
            name="industry"
            options={feedBackCategory}
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={(value: any) => {
              setFeedbackDetail({ ...feedbackDetail, category: value?.value });
              setFeedbackErrs({ ...feedbackErrs, categoryErr: '' });
            }}
          // styles={{ ...customSelectDropDown(theme) }}
          styles={{ ...customStyles }}
          />
          <small className="text-danger">{feedbackErrs?.categoryErr}</small>
        </FormGroup>

        <FormGroup>
          <Label>
            Feedback Detail <span className="text-danger">*</span>
          </Label>

          <div className="editor-height">
            <ReactQuill
              onChange={(value: any) => {
                const reg = new RegExp(
                  /^<p>(<br>|<br\/>|<br\s\/>|\s+|)<\/p>$/gm
                );

                if (reg.test(value)) {
                  setFeedbackDetail({ ...feedbackDetail, feedback: '' });
                  setFeedbackErrs({
                    ...feedbackErrs,
                    feedbackErr: 'Feed detail must be required!',
                  });
                } else {
                  setFeedbackDetail({ ...feedbackDetail, feedback: value });
                  setFeedbackErrs({
                    ...feedbackErrs,
                    feedbackErr: '',
                  });
                }
              }}
              value={feedbackDetail?.feedback}
            // ref={ref}
            />
          </div>
          <small className="text-danger">{feedbackErrs?.feedbackErr}</small>
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <button type="button" onClick={toggle} className="buttonPrimary mr-3">
          Cancel
        </button>
        <CustomButton
          buttonText="Save"
          buttonColor="primary"
          buttonType="submit"
          className="big-width"
          buttonClick={createNotes}
          loading={loading}
          disabled={loading}
        />
      </ModalFooter>
    </Modal>
  );
};

export default FeedbackModal;
