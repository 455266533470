import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import CustomButton from "../../../components/layout/CustomButton";
import AvatarImage from "../../../assets/images/user-empty.svg";
import { FETCHING_NOT_TERMINATED_USERS, FETCH_TEAMS } from "../../admin/gql";
import CloseImage from "../../../assets/images/close.svg";
import {
  CREATE_CANDIDATE_SHAREE,
  DELETE_CANDIDATE_SHAREE,
  GET_CANDIDATE_SHAREE,
} from "../gql";
import ConfirmPopUp from "../../../components/ConfirmPopUp";
import { toast } from "react-toastify";
import MultiSelectMembers from "../../../components/layout/MultiSelectMembers";
import { USERTYPE } from "../../admin/users/User-data-type";

const ShareCandidate = ({
  modal,
  toggle,
  candidateId
}) => {
  const [isSelectToggle, setIsSelectToggle] = useState<boolean>(false);
  const [selectedMember, setSelectedMemeber] = useState<Array<USERTYPE>>([]);
  const [dropDownMenu, setDropDownMenu] = useState<[USERTYPE]>();
  const [searchingText, setSearchingText] = useState<string>("");
  const [fetchTeam] = useLazyQuery(FETCH_TEAMS);
  const [confirmToggle, setConfirmToggle] = useState(false);
  const [getSharee, { data: getShareeData }] =
    useLazyQuery(GET_CANDIDATE_SHAREE);
  const { data: users } = useQuery(FETCHING_NOT_TERMINATED_USERS);

  const getCandidateSharee = () => {
    getSharee({ variables: { candidateId } });
  };

  const [createNewSharee] = useMutation(CREATE_CANDIDATE_SHAREE);
  const [deleteSharee, { loading }] = useMutation(DELETE_CANDIDATE_SHAREE);

  const removeSharee = async () => {
    let res = await deleteSharee({ variables: { candidateId } });
    if (res?.data) {
      toast.success("Sharee removed successfully!");
      setConfirmToggle(false);
      toggle();
    }
  };

  const newDropdownobject = () => {
    // Make hashtable of ids in B
    var bIds = {};
    selectedMember.forEach(function (obj: USERTYPE) {
      bIds[obj.id] = obj;
    });
    // Return all elements in A, unless in B
    setDropDownMenu(
      users?.allActiveUsers?.filter(function (obj) {
        return !(obj.id in bIds);
      })
    );
  };

  const createSharee = async () => {
    let shareeId = selectedMember.map((item) => item?.id);
    let res = await createNewSharee({
      variables: { createCandidateShareeDto: { candidateId, shareeId } },
    });
    if (res.data) {
      toast.success("Sharee Created!");
    }
    toggle();
  };
  const addOrRemoveMember = (user: USERTYPE) => {
    setSelectedMemeber([...selectedMember, user]);
    let value: any =
      selectedMember &&
      selectedMember.find((member: any) => member.id === user.id);
    if (value) {
      let newUser = selectedMember.filter((item: any) => item.id !== value?.id);
      setSelectedMemeber(newUser);
    }
    setIsSelectToggle(false);
    setSearchingText("");
  };

  useEffect(() => {
    fetchTeam();
    getCandidateSharee();
    // eslint-disable-next-line
  }, [fetchTeam]);

  useEffect(() => {
    if (getShareeData?.getCandidateSharee?.candidateShareeWith?.length) {
      setSelectedMemeber(
        getShareeData?.getCandidateSharee?.candidateShareeWith
      );
    }
  }, [getShareeData]);

  useEffect(() => {
    if (users) newDropdownobject();

    // eslint-disable-next-line
  }, [users, selectedMember]);

  return (
    <div>
      <Modal
        isOpen={modal}
        toggle={toggle}
        className="revampDialog revampDialogWidth"
      >
        <ModalHeader toggle={toggle}>Share Private Candidate</ModalHeader>

        <ModalBody>
          {getShareeData?.getCandidateSharee?.candidateShareeWithshareWith ? (
            <FormGroup>
              <h5>Share Candidate</h5>
              <div className="avatar-profile mt-3">
                <div className="avatar">
                  <img
                    src={
                      getShareeData?.getCandidateSharee
                        ?.candidateShareeWithshareWith?.photo || AvatarImage
                    }
                    alt="avatar"
                  />
                </div>
                {
                  getShareeData?.getCandidateSharee
                    ?.candidateShareeWithshareWith?.fullName
                }
              </div>
              <div className="d-flex justify-content-between mx-2 mt-2  align-items-center ">
                <div>
                  <p className="mt-3">
                    Do you want to Delete this Candidate Sharee?{" "}
                  </p>
                </div>
                <Button
                  className="btn-delete"
                  onClick={() => {
                    setConfirmToggle(!confirmToggle);
                  }}
                >
                  Remove
                </Button>
              </div>
            </FormGroup>
          ) : (
            <FormGroup>
              <Row>
                <Col xs="12" className="for-position-multiselect">
                  <label>Name</label>
                  <Input
                    inputtype="input"
                    inputid="name"
                    placeholder="Select Member"
                    loading
                    inputclass="roles"
                    onClick={() => {
                      setIsSelectToggle(!isSelectToggle);
                    }}
                    onChange={({
                      target,
                    }: React.ChangeEvent<HTMLInputElement>) => {
                      setSearchingText(target.value);
                    }}
                  />

                  <MultiSelectMembers
                    isToggle={!!searchingText || isSelectToggle}
                    users={
                      dropDownMenu?.length
                        ? dropDownMenu?.filter((item: any) => {
                            const matchingRegex = new RegExp(
                              `^${searchingText}`,
                              "i"
                            );
                            if (item.fullName.match(matchingRegex)) {
                              return item;
                            } else {
                              // eslint-disable-next-line
                              return;
                            }
                          })
                        : users?.allActiveUsers?.filter((item: any) => {
                            const matchingRegex = new RegExp(
                              `^${searchingText}`,
                              "i"
                            );
                            if (item.fullName.match(matchingRegex)) {
                              return item;
                            } else {
                              // eslint-disable-next-line
                              return;
                            }
                          })
                    }
                    setSelectedMember={addOrRemoveMember}
                    selectedMember={selectedMember}
                  />

                  <div className="select-members mt-2">
                    {selectedMember &&
                      selectedMember.map((member: any, index: number) => (
                        <Card key={index} className="mt-2 mr-2">
                          <div className="avatar-label">
                            <div className="d-flex align-items-center">
                              <div className="avatar">
                                <img
                                  src={member?.photo || AvatarImage}
                                  alt="avatar"
                                />
                              </div>
                              <label>{member?.fullName}</label>
                            </div>

                            <Button
                              className="bg-transparent border-0 p-0 btn-close"
                              onClick={() => {
                                addOrRemoveMember(member);
                              }}
                            >
                              <img src={CloseImage} alt="avatar" />
                            </Button>
                          </div>
                        </Card>
                      ))}
                  </div>
                </Col>
              </Row>
            </FormGroup>
          )}
        </ModalBody>

        <ModalFooter>
          <button onClick={toggle} type="button" className="buttonPrimary mr-3">
            Close
          </button>
          <CustomButton
            buttonText="Update Candidate Sharee"
            buttonColor="primary"
            buttonType="submit"
            className="big-width"
            buttonClick={createSharee}
          />
        </ModalFooter>
      </Modal>

      <ConfirmPopUp
        confirmText="Are you sure you want to remove sharee?"
        isOpen={confirmToggle}
        toggle={setConfirmToggle}
        confirmAction={removeSharee}
        modalHeading="Are You Sure?"
        btnText="Yes, Delete Sharee"
        btnColor="primary"
        className="remove-sharee"
        loading={loading}
        disabled={loading}
      />
    </div>
  );
};

export default ShareCandidate;
