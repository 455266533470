import { useLazyQuery, useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import {
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalBody,
  Row,
  Col,
  FormGroup,
} from "reactstrap";
import Loader from "../../../../components/layout/Loader";
import ConfirmPopUp from "../../../../components/ConfirmPopUp";
import TableComponent from "../../../../components/layout/TableComponent";
import { compactObject } from "../../../../utils/commonFn";
import {
  CLEARING_ERRORS_CONTACT,
  REMOVE_CONTACTS_FROM_SEQUENCE,
  RESUME_CONTACTS_FROM_SEQUENCE,
  SEQUENCE_CONTACTS_LISTING,
  FIXED_EMAIL_ADDRESS,
  SET_REPLIED,
  SET_PAUSED,
  UNSUBCRIBE_CONTACT,
  CONTACT_BLOCKED,
  REMOVE_BOOKED,
  SET_SENTIMENT,
  RESUME_EMAILS,
} from "../gql/index";
import SEARCH_IMG from "../../../../assets/images/search.svg";
import NoContact from "./NoContact";
import CustomButton from "../../../../components/layout/CustomButton";
import AddContactModal from "./AddContactModal";
import ContactViewModal from "./ContactViewModal";
import Pagination from "../../../../components/layout/Pagination";
import InputField from "../../../../components/layout/InputField";
import {
  intersellerContactsFilterForCard,
  intersellerContactsFilters,
  PAGE_LIMIT,
  OPTIONS,
  EMAIL_PATTERN,
  BlockOptions,
  sentimentOptions,
} from "../../../../utils/constant";
import MoveContacts from "./MoveContacts";
import CopyContacts from "./CopyContacts";
import PauseContacts from "./PauseContacts";
import CSVIndex from "./csv-components/CSVIndex";
import moment from "moment";
import { useForm } from "react-hook-form";
import ContactLstingToolTip from "./ContactLstingToolTip";

const SEQUENCE_CONTACTS = ["Name", "Status", "Company"];

let time = 0;

export default function ContactsListing({
  compaignId,
  refetchStats,
  labelName,
  ...props
}) {
  const [errorClearModal, setErrorClearModal] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [dateModalIsOpen, setDateModalIsOpen] = useState<boolean>(false);
  const [confirmationModalIsOpen, setConfirmationModalIsOpen] =
    useState<boolean>(false);
  const [blockListModalIsOpen, setBlockListModalIsOpen] =
    useState<boolean>(false);
  const [sentimentListModalIsOpen, setSentimentListModalIsOpe] =
    useState<boolean>(false);

  const [errorClearId, setErrorClearId] = useState<boolean>(false);

  const [csvModal, setCSVModal] = useState<boolean>(false);
  const [checkBoxArray, setCheckBoxArray] = useState<Array<string>>([]);
  const [moveContactsModal, setMoveContactsModal] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [minDate, setMinDate] = useState<string>("");
  const [maxDate, setMaxDate] = useState<string>("");
  const [date, setDate] = useState<string>("");

  const [copyContactsModal, setCopyContactsModal] = useState<boolean>(false);
  const [pauseContactsModal, setPauseContactsModal] = useState<boolean>(false);
  const [addContactModal, setAddContactModal] = useState<boolean>(false);
  const [contactViewModal, setContactViewModal] = useState<boolean>(false);
  const [contactId, setContactId] = useState<string>("");
  const page = new URLSearchParams(props?.location?.search).get("page") || "1";
  const _currentPage: number = parseInt(page);
  const [searchParams, setSearchParams] = useState<string>("");
  const [currentPage, setCurrentPage] = useState(_currentPage);
  const [limit, setLimit] = useState(10);
  const [statusFilter, setStatusFilter] = useState<string>("");
  const [blocktypeList, setBlocktypeList] = useState<string>("");
  const [sentimenrTypeList, setSentimenrTypeList] = useState<string>("");

  const [resumeContactsModal, setResumeContactsModal] =
    useState<boolean>(false);
  const [removeContactsModal, setRemoveContactsModal] =
    useState<boolean>(false);
  const { register, errors, handleSubmit } = useForm();

  const [clearingErrors, { loading: loadingErrorClearing }] = useMutation(
    CLEARING_ERRORS_CONTACT
  );

  const [resumeContactsFromSequence] = useMutation(
    RESUME_CONTACTS_FROM_SEQUENCE
  );
  const [unsubscribeContacts] = useMutation(UNSUBCRIBE_CONTACT);

  const [fixContactEmail] = useMutation(FIXED_EMAIL_ADDRESS);

  const [pauseContact] = useMutation(SET_PAUSED);

  const [setReplied] = useMutation(SET_REPLIED);
  const [removedBooked] = useMutation(REMOVE_BOOKED);
  const [setSentiment] = useMutation(SET_SENTIMENT);
  const [ResumeEmails] = useMutation(RESUME_EMAILS)
  const [blockContact] = useMutation(CONTACT_BLOCKED);
  const [removeContactsFromSequence] = useMutation(
    REMOVE_CONTACTS_FROM_SEQUENCE
  );

  const [allContactsSequence, { loading, data }] = useLazyQuery(
    SEQUENCE_CONTACTS_LISTING
  );

  const toggle = () => {
    setAddContactModal(!addContactModal);
  };

  const contactViewToggle = () => {
    setContactViewModal(!contactViewModal);
  };

  const toogle1 = () => {
    setIsOpen(!isOpen);
  };

  const toogle2 = () => {
    setDateModalIsOpen(!dateModalIsOpen);
  };

  const toogle3 = () => {
    setConfirmationModalIsOpen(!confirmationModalIsOpen);
  };
  const toogle4 = () => {
    setBlockListModalIsOpen(!blockListModalIsOpen);
  };

  const toogle5 = () => {
    setSentimentListModalIsOpe(!sentimentListModalIsOpen);
  };
  const fetchSequenceContacts = () => {
    setQueryParams({ currentPage, searchParams });
    if (!searchParams || searchParams?.length > 2) {
      allContactsSequence({
        variables: {
          limit,
          page: currentPage,
          searchText: searchParams,
          compaignId,
          statusFilter,
        },
      });
    }
  };

  const setQueryParams = (searchParams) => {
    const criteria = compactObject({
      currentPage,
      searchParams,
    });
    const urlQueryParams: any = [];
    if (Object.keys(criteria).length) {
      for (const key in criteria) {
        if (
          Object.prototype.hasOwnProperty.call(criteria, key) &&
          criteria[key]
        ) {
          urlQueryParams.push(`${key}=${criteria[key]}`);
        }
      }
      // history.push(`/sequence/?${urlQueryParams.join("&")}`);
    }
  };
  const apiHandler = async (contactID, label) => {
    if (label === "Fix Email") {
      toogle1();
      setContactId(contactID);
    } else if (label === "Set Replied" || label === "Set Booked") {
      let response = await setReplied({
        variables: {
          contactId: contactID,
          booked: label === "Set Replied" ? false : true,
        },
      });
      if (response?.data?.setReplied?.status) {
        label === "Set Replied"
          ? toast.success("Contact Marked As Replied")
          : toast.success("Contact booked");
        allContactsSequence({
          variables: {
            limit,
            compaignId,
            page: currentPage,
            searchText: searchParams,
            statusFilter,
          },
        });
        refetchStats();
      } else {
        label === "Set Replied"
          ? toast.error("Contact does not marked as a reply")
          : toast.success("Contact does not booked");
        allContactsSequence({
          variables: {
            limit,
            compaignId,
            page: currentPage,
            searchText: searchParams,
            statusFilter,
          },
        });
        refetchStats();
      }
    } else if (label === "Pause Contact") {
      setContactId(contactID);
      toogle2();
    } else if (label === "Unsubscribe") {
      setContactId(contactID);
      toogle3();
    } else if (label.match(/Block/gi)) {
      setContactId(contactID);
      toogle4();
    } else if (label === "Remove Booked" || label === "Remove Reply") {
      let response = await removedBooked({
        variables: {
          contactId: contactID,
        },
      });
      if (response?.data?.removedBooked?.status) {
        label === "Remove Booked"
          ? toast.success("Contact removed as marked")
          : toast.success("Contact removed as replied");
        allContactsSequence({
          variables: {
            limit,
            compaignId,
            page: currentPage,
            searchText: searchParams,
            statusFilter,
          },
        });
        refetchStats();
      } else {
        label === "Remove Booked"
          ? toast.error("Contact does not removed as a marked")
          : toast.error("Contact does not removed as a reply");
        allContactsSequence({
          variables: {
            limit,
            compaignId,
            page: currentPage,
            searchText: searchParams,
            statusFilter,
          },
        });
        refetchStats();
      }
    } else if (label === "Assign Sentiment") {
      setContactId(contactID);
      toogle5();
    } else if (label === "Resume Emails") {
      let response = await ResumeEmails({
        variables: {
          ResumeEmailType: {
            contactId: contactID,
            delay_until: null,
            domain_paused: false
          }
        }
      })
      if (response?.data?.ResumeEmails?.status) {
        toast.success("Contact updated")
        allContactsSequence({
          variables: {
            limit,
            compaignId,
            page: currentPage,
            searchText: searchParams,
            statusFilter,
          },
        });
        refetchStats();
      } else {
        toast.error("Contact does not update")
        allContactsSequence({
          variables: {
            limit,
            compaignId,
            page: currentPage,
            searchText: searchParams,
            statusFilter,
          },
        });
        refetchStats();
      }
    }
  };

  const isChecked = (id: string) => checkBoxArray.includes(id)

  const fixEmailCall = async () => {
    let response = await fixContactEmail({
      variables: {
        compaignId: compaignId,
        contactId: contactId,
        email: email,
      },
    });
    if (response?.data?.fixContactEmail?.status) {
      setIsOpen(!isOpen);
      toast.success("Contact Email Updated");
      allContactsSequence({
        variables: {
          limit,
          compaignId,
          page: currentPage,
          searchText: searchParams,
          statusFilter,
        },
      });
      refetchStats();
    } else {
      setIsOpen(!isOpen);
      toast.error("Contact does not update");
      allContactsSequence({
        variables: {
          limit,
          compaignId,
          page: currentPage,
          searchText: searchParams,
          statusFilter,
        },
      });
      refetchStats();
    }
  };

  const blockList = async () => {
    let response = await blockContact({
      variables: {
        contactId: contactId,
        team: blocktypeList === "Personal_block_list" ? true : false,
      },
    });

    if (response?.data?.blockContact?.status) {
      setBlockListModalIsOpen(!blockListModalIsOpen);
      toast.success("Contact Blocked");
      allContactsSequence({
        variables: {
          limit,
          compaignId,
          page: currentPage,
          searchText: searchParams,
          statusFilter,
        },
      });
      refetchStats();
    } else {
      setBlockListModalIsOpen(!blockListModalIsOpen);
      toast.error("Contact does not blocked");
      allContactsSequence({
        variables: {
          limit,
          compaignId,
          page: currentPage,
          searchText: searchParams,
          statusFilter,
        },
      });
      refetchStats();
    }
  };

  const sentimentList = async () => {
    let response = await setSentiment({
      variables: {
        contactId: contactId,
        sentiment: sentimenrTypeList,
      },
    });

    if (response?.data?.setSentiment?.status) {
      setSentimentListModalIsOpe(!sentimentListModalIsOpen);
      toast.success("Sentiment Assign!");
      allContactsSequence({
        variables: {
          limit,
          compaignId,
          page: currentPage,
          searchText: searchParams,
          statusFilter,
        },
      });
      refetchStats();
    } else {
      setSentimentListModalIsOpe(!sentimentListModalIsOpen);
      toast.error("Sentiment does not assign");
      allContactsSequence({
        variables: {
          limit,
          compaignId,
          page: currentPage,
          searchText: searchParams,
          statusFilter,
        },
      });
      refetchStats();
    }
  };

  const searchControl = (value: any) => {
    setQueryParams(value);
    setSearchParams(value);
    setCurrentPage(1);
    setLimit(25);
    if (!value || value?.length > 2) {
      window.clearTimeout(time);
      time = window.setTimeout(() => {
        allContactsSequence({
          variables: {
            limit,
            compaignId,
            page: currentPage,
            searchText: value,
            statusFilter,
          },
        });
      }, 1000);
    }
  };

  useEffect(() => {
    fetchSequenceContacts();

    // eslint-disable-next-line
  }, [currentPage, limit, compaignId, statusFilter]);

  const handleCheckBoxes = (value: string) => {
    const currentIndex = checkBoxArray.indexOf(value);
    const newChecked = [...checkBoxArray];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setCheckBoxArray(newChecked);
  };

  const resumeContactsToggle = () => {
    setResumeContactsModal(!resumeContactsToggle);
  };

  const removeContactsToggle = () => {
    setRemoveContactsModal(!removeContactsModal);
  };

  const moveContactsToggle = () => {
    setMoveContactsModal(!moveContactsModal);
  };

  const copyContactsToggle = () => {
    setCopyContactsModal(!copyContactsModal);
  };

  const pauseContactsToggle = () => {
    setPauseContactsModal(!pauseContactsModal);
  };

  const removeContacts = async () => {
    const response = await removeContactsFromSequence({
      variables: { removeContactsFromSequenceInput: { ids: checkBoxArray } },
    });
    if (response?.data) {
      allContactsSequence({
        variables: {
          limit,
          compaignId,
          page: currentPage,
          searchText: searchParams,
          statusFilter,
        },
      });
      refetchStats();
      setCheckBoxArray([]);
      removeContactsToggle();
      toast.success("Successfully removed contacts");
    }
  };

  const pauseContacts = async () => {
    const response = await resumeContactsFromSequence({
      variables: {
        resumeContactsFromSequenceInput: {
          data: {
            delay_until: null,
            delayed: false,
            delayed_indefinitely: false,
          },
          ids: checkBoxArray,
        },
      },
    });

    if (response?.data) {
      allContactsSequence({
        variables: {
          limit,
          page: currentPage,
          searchText: searchParams,
          compaignId,
          statusFilter,
        },
      });
      refetchStats();
      resumeContactsToggle();
      setCheckBoxArray([]);
      toast.success(`Successfully resumed ${checkBoxArray?.length} contacts`);
    }
  };

  const csvToggle = () => {
    setCSVModal(!csvModal);
  };

  const clearingErrorsHandler = async () => {
    const response = await clearingErrors({
      variables: { contactId: errorClearId },
    });
    if (response?.data) {
      allContactsSequence({
        variables: {
          limit,
          compaignId,
          page: currentPage,
          searchText: searchParams,
          statusFilter,
        },
      });
      toast.success("Successfully removed errors");
    }
  };

  const checkStateContact = (item: any): string => {
    return item?.delayed === true
      ? "PAUSED"
      : !item?.state
        ? "ADDED"
        : item?.state?.toUpperCase();
  };

  const checkErrorContact = (item: any): string => {
    return item?.all_errors?.length > 1
      ? `${item?.all_errors?.length} Errors`
      : item?.error === "DUPLICATE_CAMPAIGN"
        ? "DUPLICATE"
        : item?.error;
  };

  const checkBelowErrorMessageContact = (item: any): string => {
    return item?.error === "DUPLICATE_CAMPAIGN"
      ? "IN DIFFERENT SEQUENCE"
      : "CONTACT NEEDS ATTENTION";
  };

  const checkDelayOrNot = (item: any) => {
    return (
      <div className="subtitleText">
        {item?.delayed && item?.delayed_indefinitely
          ? "INDEFINITLY"
          : item?.delayed && !item?.delayed_indefinitely
            ? `Until ${moment(item?.delay_until).format("L")}`
            : item?.state === "replied" && item?.sentiment
              ? `${item?.sentiment},${moment(item?.updated_at)
                .fromNow()
                .toLocaleUpperCase()}`
              : moment(item?.updated_at).fromNow().toLocaleUpperCase()}
      </div>
    );
  };

  useEffect(() => {
    if (labelName) {
      if (
        intersellerContactsFilterForCard.find(
          (item) => item.label === labelName
        )
      ) {
        allContactsSequence({
          variables: {
            limit,
            compaignId,
            page: currentPage,
            searchText: searchParams,
            statusFilter: intersellerContactsFilterForCard.find(
              (item) => item.label === labelName
            )?.value,
          },
        });
      }
    }
  }, [
    allContactsSequence,
    compaignId,
    currentPage,
    labelName,
    limit,
    searchParams,
  ]);
  useEffect(() => {
    let minDate = moment(new Date()).add(1, "day").format("YYYY-MM-DD");
    setMinDate(minDate);
    let maxDate = moment(new Date()).add(6, "day").format("YYYY-MM-DD");
    setMaxDate(maxDate);
  }, []);

  const unsubscribeContact = async () => {
    let response = await unsubscribeContacts({
      variables: {
        contactId: contactId,
      },
    });
    if (response?.data?.unsubscribeContacts?.status) {
      setConfirmationModalIsOpen(!confirmationModalIsOpen);
      toast.success("Contact updated");
      allContactsSequence({
        variables: {
          limit,
          compaignId,
          page: currentPage,
          searchText: searchParams,
          statusFilter,
        },
      });
      refetchStats();
    } else {
      setConfirmationModalIsOpen(!confirmationModalIsOpen);
      toast.error("Contact does not update");
      allContactsSequence({
        variables: {
          limit,
          compaignId,
          page: currentPage,
          searchText: searchParams,
          statusFilter,
        },
      });
      refetchStats();
    }
  };

  const pauseMessages = async () => {
    let response = await pauseContact({
      variables: {
        contactId: contactId,
        pauseDate: date,
      },
    });

    if (response?.data?.pauseContact?.status) {
      setDateModalIsOpen(!dateModalIsOpen);
      toast.success("Contact updated");
      allContactsSequence({
        variables: {
          limit,
          compaignId,
          page: currentPage,
          searchText: searchParams,
          statusFilter,
        },
      });
      refetchStats();
    } else {
      setDateModalIsOpen(!dateModalIsOpen);
      toast.error("Contact does not update");
      allContactsSequence({
        variables: {
          limit,
          compaignId,
          page: currentPage,
          searchText: searchParams,
          statusFilter,
        },
      });
      refetchStats();
    }
  };

  return (
    <>
      <div className="admin-tabs team-tabs mt-5">
        <ToastContainer autoClose={3000} />

        <div className="search-filter search-filter-sequence">
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <img src={SEARCH_IMG} alt="search" />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              type="text"
              placeholder="Search for a contact"
              value={searchParams}
              onChange={(e) => {
                setSearchParams(e.target.value);
                searchControl(e.target.value);
                if (e.target.value?.length > 2) {
                  setCheckBoxArray([])
                }
              }}
              className="search-by-company"
            />
          </InputGroup>

          <div className="filter-status">
            <div className="filter-by">
              <InputField
                inputtype="select"
                selectItems={intersellerContactsFilters}
                inputid="status"
                placeholder="Status"
                inputMethod={(value) => {
                  setStatusFilter(value);
                  setCurrentPage(1);
                  setLimit(25);
                }}
                inputValue={statusFilter}
              />
            </div>
          </div>
        </div>

        <div className="mt-2 justify-content-between align-items-center d-flex">
          <small className="text-info">
            {searchParams &&
              searchParams.length <= 2 &&
              "Please add more than two characters"}
          </small>
        </div>

        <div className="section-header mt-2">
          <div className="d-flex flex-column  contacts-mobile">
            <h5 className="m-0 mb-xl-0  pb-2">Contacts</h5>

            {checkBoxArray?.length ? (
              <>
                <div className="contacts-actions flex-xl-nowrap flex-wrap justify-content-start">
                  <h5 className="m-0 mx-3 mb-2 mb-xl-2">
                    {checkBoxArray?.length} Selected
                  </h5>

                  <CustomButton
                    buttonText="Move"
                    buttonColor="primary"
                    buttonType="button"
                    className="big-width gray-button mr-2 m-0 mb-2 mb-xl-2"
                    buttonClick={() => setMoveContactsModal(true)}
                  />

                  <CustomButton
                    buttonText="Copy"
                    buttonColor="primary"
                    buttonType="button"
                    className="big-width gray-button mr-2 m-0 mb-2 mb-xl-2"
                    buttonClick={() => setCopyContactsModal(true)}
                  />

                  <CustomButton
                    buttonText="Pause"
                    buttonColor="primary"
                    buttonType="button"
                    className="big-width gray-button mr-2 m-0 mb-2 mb-xl-2"
                    buttonClick={() => setPauseContactsModal(true)}
                  />

                  <CustomButton
                    buttonText="Delete"
                    buttonColor="primary"
                    buttonType="button"
                    className="big-width gray-button mr-2 mb-2 mb-xl-2"
                    buttonClick={() => setRemoveContactsModal(true)}
                  />

                  <CustomButton
                    buttonText="Resume"
                    buttonColor="primary"
                    buttonType="button"
                    className="big-width gray-button mr-2  mb-2 mx-xl-0"
                    buttonClick={() => setResumeContactsModal(true)}
                  />
                </div>
              </>
            ) : null}
          </div>

          <div>
            <button
              className="buttonGenericStyle outlinedButtonDark mr-3"
              onClick={() => setAddContactModal(true)}
              type="button"
            >
              Add New Contact
            </button>

            <button
              className="buttonGenericStyle filledButton m-0"
              // disabled={!user?.intersellerCSVPermissions}
              onClick={() => setCSVModal(true)}
              type="button"
            >
              Import CSV
            </button>
          </div>
        </div>

        <div className="table-responsive table-responsive-container">
          <TableComponent tableHeader={SEQUENCE_CONTACTS}>
            {loading && (
              <tbody>
                <tr>
                  <td colSpan={14}>
                    <Loader loaderClass="sm-height " />
                  </td>
                </tr>
              </tbody>
            )}

            {!loading && !data?.getSequenceContacts?.contacts?.length && (
              <tbody>
                <tr>
                  <td colSpan={14} className="empty-table-td">
                    <>
                      {!loading &&
                        !data?.getSequenceContacts?.contacts?.length && (
                          <NoContact
                            refetchStats={refetchStats}
                            compaignId={compaignId}
                            reFetchContacts={() =>
                              allContactsSequence({
                                variables: {
                                  limit,
                                  page: currentPage,
                                  searchText: searchParams,
                                  compaignId,
                                  statusFilter,
                                },
                              })
                            }
                          />
                        )}
                    </>
                  </td>
                </tr>
              </tbody>
            )}

            <tbody>
              {data &&
                data?.getSequenceContacts &&
                data?.getSequenceContacts?.contacts?.map(
                  (item: any, index: number) => {
                    return (
                      <tr key={index} className="job-orders-tbody">
                        <td>
                          <div className="d-flex align-items-start">
                            <Input
                              bsSize="sm"
                              type="checkbox"
                              checked={isChecked(item.id)}
                              onChange={() => handleCheckBoxes(item?.id)}
                            />

                            <div className="d-flex flex-column ml-2 pt-1">
                              <div
                                style={{ cursor: "pointer", paddingTop: '0.25rem' }}
                                className="text-info ml-0 font-weight-bold"
                                onClick={() => {
                                  setContactViewModal(true);
                                  setContactId(item?.id);
                                }}
                              >
                                {item?.name}
                              </div>

                              <div className="text-14 pt-1">
                                {item?.email}
                              </div>
                            </div>

                          </div>
                        </td>

                        <td>
                          <div className="status-contact-badge">
                            {!!item?.all_errors?.length ? (
                              <ContactLstingToolTip index={index} setErrorClearModal={setErrorClearModal} setErrorClearId={setErrorClearId}
                                item={item} errorClearId={errorClearId} errorClearModal={errorClearModal} checkErrorContact={checkErrorContact}
                                checkBelowErrorMessageContact={checkBelowErrorMessageContact} loadingErrorClearing={loadingErrorClearing} clearingErrorsHandler={clearingErrorsHandler} />
                            ) : (
                              <div>
                                {item.state === "bounced" ||
                                  item.state === "messaged" ||
                                  item.state === "replied" ||
                                  item.state === "booked" ||
                                  item.delayed ||
                                  !item.state ? (
                                  <div className="contactlist-dropdown">
                                    <div className="actionsDropdown  actionsDropdownSpacing dropdown">
                                      <UncontrolledDropdown className="customMenuDropdown">
                                        <DropdownToggle
                                          style={{
                                            fontSize: 12,
                                            fontWeight: "bolder",
                                          }}
                                          caret
                                          className={`text-default ${item?.state === "error" ||
                                            item.state === "bounced"
                                            ? "text-danger"
                                            : !item?.state &&
                                              item?.delayed === true &&
                                              item?.delayed_indefinitely ===
                                              true
                                              ? "text-yellow"
                                              : item?.state === "replied" ||
                                                item?.state === "booked"
                                                ? "text-lightGreen"
                                                : !item?.state ||
                                                  item.state === "messaged"
                                                  ? "text-lightBlue"
                                                  : "text-indigo"
                                            }`}
                                        >
                                          {checkStateContact(item)}
                                        </DropdownToggle>
                                        <DropdownMenu right>
                                          {OPTIONS[
                                            item?.state
                                              ? item.state
                                              : item?.delayed
                                                ? "pause"
                                                : "added"
                                          ].map((element) => {
                                            if (element?.label === "Block") {
                                              let IndexOfemail =
                                                item?.email.indexOf("@");
                                              let email_extention =
                                                item?.email.slice(
                                                  IndexOfemail + 1,
                                                  item?.email?.length
                                                );
                                              element.label = `Block ${email_extention}`;
                                            }
                                            return (
                                              <>
                                                <DropdownItem
                                                  onClick={() =>
                                                    apiHandler(
                                                      item.id,
                                                      element.label
                                                    )
                                                  }
                                                >
                                                  {element?.label}
                                                </DropdownItem>
                                              </>
                                            );
                                          })}
                                        </DropdownMenu>
                                      </UncontrolledDropdown>
                                    </div>
                                  </div>
                                ) : null}
                                {item.state !== "bounced" &&
                                  item.state !== "messaged" &&
                                  item.state !== null &&
                                  item.state !== "booked" &&
                                  item.state !== "replied" &&
                                  !item.delayed ? (
                                  <div
                                    style={{
                                      fontSize: 12,
                                      fontWeight: "bolder",
                                      color: `${item?.state === "error"
                                        ? "red"
                                        : !item?.state &&
                                          item?.delayed === true &&
                                          item?.delayed_indefinitely === true
                                          ? "#F6D234"
                                          : item?.state === "replied"
                                            ? "#1FC866"
                                            : !item?.state
                                              ? "#4D83E1"
                                              : "indigo"
                                        }`,
                                    }}
                                  >
                                    {checkStateContact(item)}
                                  </div>
                                ) : null}
                                {checkDelayOrNot(item)}
                              </div>
                            )}
                          </div>
                        </td>

                        <td>
                          <div className="company">{item?.company || "--"}</div>
                          <div>{item?.title || "--"}</div>
                        </td>
                      </tr>
                    );
                  }
                )}
            </tbody>
          </TableComponent>
        </div>
      </div>

      {data?.getSequenceContacts?.contacts?.length ? (
        <div className="users-pagination">
          <Pagination
            onPageChange={(pageClicked: number) => {
              setCurrentPage(pageClicked);
            }}
            pageCount={Math.ceil(data?.getSequenceContacts?.count / limit)}
            currentPage={currentPage}
          />

          <div className="d-flex align-items-center">
            <div className="pagination-display paginationItemsSelect">
              <p className="m-0">
                {(currentPage - 1) * limit + 1} to{" "}
                {Math.min(
                  currentPage * limit,
                  data?.getSequenceContacts?.count
                )}{" "}
                of {data?.getSequenceContacts?.count}
              </p>

              <div className="divider"></div>

              <label>Display</label>

              <InputField
                label=""
                inputtype="select"
                selectItems={PAGE_LIMIT.filter((item) => item.value !== limit)}
                inputid="limit"
                placeholder={limit.toString()}
                inputMethod={(value) => {
                  setCurrentPage(1);
                  setLimit(parseInt(value));
                }}
              />
            </div>
          </div>
        </div>
      ) : null}

      <ContactViewModal
        compaignId={compaignId}
        refetchStats={refetchStats}
        contactViewModal={contactViewModal}
        reFetchContacts={() =>
          allContactsSequence({
            variables: {
              limit,
              page: currentPage,
              searchText: searchParams,
              compaignId,
              statusFilter,
            },
          })
        }
        contactId={contactId}
        contactViewToggle={contactViewToggle}
      />

      <MoveContacts
        compaignId={compaignId}
        ids={checkBoxArray}
        moveContactsToggle={moveContactsToggle}
        moveContactsModal={moveContactsModal}
        setCheckBoxArray={setCheckBoxArray}
        reFetchContacts={() =>
          allContactsSequence({
            variables: {
              limit,
              page: currentPage,
              searchText: searchParams,
              compaignId,
              statusFilter,
            },
          })
        }
        refetchStats={refetchStats}
      />

      <CopyContacts
        compaignId={compaignId}
        ids={checkBoxArray}
        setCheckBoxArray={setCheckBoxArray}
        copyContactsToggle={copyContactsToggle}
        copyContactsModal={copyContactsModal}
        reFetchContacts={() =>
          allContactsSequence({
            variables: {
              limit,
              page: currentPage,
              searchText: searchParams,
              compaignId,
              statusFilter,
            },
          })
        }
        refetchStats={refetchStats}
      />

      <PauseContacts
        ids={checkBoxArray}
        setCheckBoxArray={setCheckBoxArray}
        pauseContactsToggle={pauseContactsToggle}
        pauseContactsModal={pauseContactsModal}
        reFetchContacts={() =>
          allContactsSequence({
            variables: {
              limit,
              page: currentPage,
              searchText: searchParams,
              compaignId,
              statusFilter,
            },
          })
        }
        refetchStats={refetchStats}
      />

      <ConfirmPopUp
        confirmText="Are you sure you want to delete contacts?"
        isOpen={removeContactsModal}
        toggle={removeContactsToggle}
        confirmAction={removeContacts}
        modalHeading="Delete Action"
        btnText="Delete"
        btnColor="primary"
        className="revampDialog revampDialogWidth"
      />

      <ConfirmPopUp
        confirmText="Are you sure you want to resume selected contacts?"
        isOpen={resumeContactsModal}
        toggle={resumeContactsToggle}
        confirmAction={pauseContacts}
        modalHeading="Resume Contacts"
        btnText="Resume"
        className="revampDialog revampDialogWidth"
      />

      <AddContactModal
        addContactModal={addContactModal}
        compaignId={compaignId}
        refetchStats={refetchStats}
        reFetchContacts={() =>
          allContactsSequence({
            variables: {
              limit,
              page: currentPage,
              searchText: searchParams,
              compaignId,
              statusFilter,
            },
          })
        }
        toggle={toggle}
      />

      <CSVIndex
        modal={csvModal}
        toggle={csvToggle}
        compaignId={compaignId}
        reFetchContacts={() => {
          allContactsSequence({
            variables: {
              limit,
              page: currentPage,
              searchText: searchParams,
              compaignId,
              statusFilter,
            },
          });
        }}
      />

      {/* ----------------------------------------------------- */}
      <Modal isOpen={isOpen} toggle={toogle1}>
        <ModalBody>
          <div className="fixEmail-Modal px-4 py-3">
            <h4 className="text-center">Fix Email</h4>
            <p className="text-center mb-0 py-3">
              Enter the correct email address for Realrepp to create a copy of
              the contact with correct email.
            </p>
            <div className="fixModalForm">
              <form>
                <Row>
                  <Col lg="12" md="12" sm="12">
                    <InputField
                      label="Email"
                      inputtype="email"
                      placeholder="Email Address"
                      inputid="email"
                      inputRef={register({
                        required: {
                          value: true,
                          message: "Email is required!",
                        },
                        pattern: {
                          value: EMAIL_PATTERN,
                          message: "Email not valid!",
                        },
                      })}
                      inputMethod={(value: string) => {
                        setEmail(value);
                      }}
                      errMsg={errors?.email?.message}
                    />
                  </Col>
                </Row>
              </form>

              <div className="pt-3 text-right">
                <button
                  onClick={toogle1}
                  className="buttonPrimary mr-3"
                  type="button"
                >
                  Cancel
                </button>

                <CustomButton
                  buttonText="Create"
                  loading={loading}
                  disabled={loading}
                  buttonType="submit"
                  className="border-0"
                  buttonClick={handleSubmit(fixEmailCall)}
                />
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
      {/* ------------------------------------------------------------- */}

      <Modal isOpen={dateModalIsOpen} toggle={toogle2}>
        <ModalBody>
          <div className="fixEmail-Modal px-4 py-3">
            <h4 className="text-center">Pause Messages</h4>
            <p className="text-center mb-0 py-3">
              Pause messages indefinitely or until a specified date
            </p>

            <div className="fixModalForm">
              <form>
                <Row>
                  <Col lg="12" md="12" sm="12">
                    <FormGroup>
                      <Input
                        type="date"
                        id="Test_Date"
                        min={minDate}
                        max={maxDate}
                        onChange={(e) => {
                          // setAddressReq(e.target.checked);
                          setDate(e.target.value);
                        }}
                      />
                    </FormGroup>
                    <p className="text-center mb-0 py-3">
                      If you did not select any date then the pause duration
                      will be Indefinately
                    </p>
                  </Col>
                </Row>
              </form>

              <div className="pt-3 text-right">
                <button
                  onClick={toogle2}
                  className="buttonPrimary mr-3"
                  type="button"
                >
                  Cancel
                </button>

                <CustomButton
                  buttonText={"Pause"}
                  loading={loading}
                  disabled={loading}
                  buttonType="submit"
                  className="border-0"
                  buttonClick={handleSubmit(pauseMessages)}
                />
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>

      <Modal isOpen={confirmationModalIsOpen} toggle={toogle3}>
        <ModalBody>
          <div className="fixEmail-Modal px-4 py-3">
            <h4 className="text-center">Unsubscribe Contact</h4>
            <p className="text-center mb-0 py-3">
              Are you sure you want to permanently unsubscribe from all
              sequences?
            </p>
            <div className="fixModalForm">
              <div className="pt-3 text-right">
                <button
                  onClick={toogle3}
                  className="buttonPrimary mr-3"
                  type="button"
                >
                  Cancel
                </button>

                <CustomButton
                  buttonText={"Confirm"}
                  loading={loading}
                  disabled={loading}
                  buttonType="submit"
                  className="border-0"
                  buttonClick={handleSubmit(unsubscribeContact)}
                />
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>

      <Modal isOpen={blockListModalIsOpen} toggle={toogle4}>
        <ModalBody>
          <div className="fixEmail-Modal px-4 py-3">
            <h4 className="text-center">Block Contact</h4>
            <p className="text-center mb-0 py-3">
              This will add to your blocklist and prevent sending any messages
              going forward.
            </p>
            <div className="fixModalForm">
              <Row>
                <Col lg="12" md="12" sm="12">
                  <FormGroup>
                    <InputField
                      inputtype="select"
                      isRequired={true}
                      selectItems={BlockOptions}
                      inputid="block"
                      placeholder="Select Type of Block"
                      inputRef={register({
                        required: {
                          value: true,
                          message: "Block type is required!",
                        },
                      })}
                      inputMethod={(value) => {
                        setBlocktypeList(value);
                      }}
                      inputValue={blocktypeList}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <div className="pt-3 text-right">
                <button
                  onClick={toogle4}
                  className="buttonPrimary mr-3"
                  type="button"
                >
                  Cancel
                </button>

                <CustomButton
                  buttonText={"Confirm"}
                  loading={loading}
                  disabled={loading}
                  buttonType="submit"
                  className="border-0"
                  buttonClick={handleSubmit(blockList)}
                />
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>

      <Modal isOpen={sentimentListModalIsOpen} toggle={toogle5}>
        <ModalBody>
          <div className="fixEmail-Modal px-4 py-3">
            <h4 className="text-center">Assign Sentiment</h4>
            <p className="text-center mb-0 py-3">
              Assign a category to a contact that best fits the sentiment.
            </p>
            <div className="fixModalForm">
              <Row>
                <Col lg="12" md="12" sm="12">
                  <FormGroup>
                    <InputField
                      inputtype="select"
                      required
                      selectItems={sentimentOptions}
                      inputid="block"
                      placeholder="Select..."
                      inputMethod={(value) => {
                        setSentimenrTypeList(value);
                      }}
                      inputValue={sentimenrTypeList}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <div className="pt-3 text-right">
                <button
                  onClick={toogle5}
                  className="buttonPrimary mr-3"
                  type="button"
                >
                  Cancel
                </button>

                <CustomButton
                  buttonText={"Confirm"}
                  loading={loading}
                  disabled={loading}
                  buttonType="submit"
                  className="border-0"
                  buttonClick={handleSubmit(sentimentList)}
                />
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
}
