import { FC } from 'react';
import { Button, Spinner } from 'reactstrap';
import { BACK_ICON } from '../../../utils/constant';
import './style.scss';

interface SidedrawerInterface {
  isOpen: boolean;
  toggle: () => void;
  heading?: string;
}

export const DrawerHeader = ({
  prevClick = () => { },
  nextClick = () => { },
  heading,
  disabled = false,
  buttonText = 'Next',
  loading = false,
  nextButtonView = true,
  prevButtonView = true,
  children,
}: any) => {
  return (
    <div className="sidedrawer-header">
      <div className="d-flex align-items-center justify-content-between flex-wrap gap-10 w-100">
        <div className="d-flex align-items-center flex-wrap">
          {prevButtonView && (
            <Button
              type="button"
              className="my-auto bg-transparent p-0 border-0 dark-theme-icon"
              onClick={prevClick}
            >
              <BACK_ICON />
            </Button>
          )}

          <h4
            className="mb-0 text-black font-weight-bold"
            style={{ maxWidth: '275px', fontSize: '20px' }}
          >
            {heading}
          </h4>
        </div>

        <div className="d-flex align-items-baseline flex-wrap">
          <div className="mr-2">{children}</div>
          {nextButtonView && (
            <Button
              type="button"
              className="btn px-3 py-2 btn-next"
              onClick={nextClick}
              disabled={disabled}
              size="sm"
            >
              {loading && <Spinner size="sm" className="mr-2" />}
              {buttonText}
            </Button>
          )}
        </div>
      </div>

      <div className="d-flex justify-content-between align-items-center flex-wrap">
        <div className="d-flex align-items-center">
          {/* {prevButtonView && (
            <Button
              type="button"
              className="my-auto bg-transparent p-0 mr-3 border-0"
              onClick={prevClick}
            >
              <img src={MoveBackIcon} className="m-0" alt="" width="20" />
            </Button>
          )} */}
          {/* <h4
            className="mb-0 text-black font-weight-bold"
            style={{ maxWidth: '275px', fontSize: '20px' }}
          >
            {heading}
          </h4> */}
        </div>

        {/* <div className="d-flex align-items-center mt-2">
          <div className="mr-2">{children}</div>
          {nextButtonView && (
            <Button
              type="button"
              className="btn px-3  py-2 btn-next"
              onClick={nextClick}
              disabled={disabled}
              size="sm"
            >
              {loading && <Spinner size="sm" className="mr-2" />}
              {buttonText}
            </Button>
          )}
        </div> */}
      </div>
    </div>
  );
};

export const Sidedrawer: FC<SidedrawerInterface> = ({
  isOpen,
  toggle = () => { },
  children,
}: any) => {
  return (
    <>
      <div
        onClick={toggle}
        className={`sidedrawer-shadow ${isOpen ? 'show' : ''}`}
      ></div>
      <div className={`sidedrawer-content ${isOpen ? 'open' : ''}`}>
        <div className="sidedrawer-body">{isOpen && children}</div>
      </div>
    </>
  );
};
