import { gql } from "@apollo/client";
import AsyncSelect from "react-select/async";
import { toast } from "react-toastify";
import { useContext } from "react";
import { AppContext } from "../../../context";
import client from "../../../apollo";
import { Col, Label } from "reactstrap";
import { customSelectDropDown } from "../../../components/styled/customSelect";
let time = 0;

export const FETCH_COMPANIES = gql`
  query FetchAllCompanies($page: Int!, $limit: Int!, $searchText: String) {
    companiesForDropDown(
      payload: { page: $page, limit: $limit }
      filters: { searchText: $searchText }
    ) {
      companies {
        id
        name
        createdAt
        address {
          city
          state
          isPrimary
          country
        }
      }
      count
    }
  }
`;

const AsyncCompanySelect = (props) => {
  const { theme } = useContext(AppContext);
  const _filterCompanies = async (inputValue: string) => {
    try {
      const { data } = await client.query({
        query: FETCH_COMPANIES,
        variables: {
          limit: 20,
          page: 1,
          searchText: inputValue,
        },
      });
      return data && data.companiesForDropDown
        ? data?.companiesForDropDown?.companies?.map((item) => {
          const companyAddress = item?.address?.find(
            (address: any) => address.isPrimary === true
          );
          return {
            value: item.id,
            label: `${['US', 'CA', null, undefined, ''].includes(companyAddress?.country) ? companyAddress?.city && companyAddress?.state ? `${item?.name}-${companyAddress?.city}, ${companyAddress?.state}` : item.name : companyAddress?.city && companyAddress?.country ? `${item?.name}-${companyAddress?.city}, ${companyAddress?.country}` : item?.name}`


          };
        })
        : [];
    } catch (error) {
      toast.error("Error while loading companies!");
    }
  };

  const promiseOptions = (value: any): any =>
    new Promise((resolve) => {
      if (!value) {
        resolve(_filterCompanies(value));
      } else window.clearTimeout(time);
      time = window.setTimeout(() => {
        resolve(_filterCompanies(value));
      }, 1000);
    });

  return (
    <Col xl="4" md="6" sm="12">
      <Label>{props?.label}</Label>
      <AsyncSelect
        cacheOptions
        isDisabled={props?.isDisabled || false}
        value={props?.value}
        // defaultValue={props?.defaultValue}
        isSearchable
        styles={{ ...customSelectDropDown(theme) }}
        isClearable
        // {...(props?.selectedCompany?.value && {
        //   value: {
        //     value: props.selectedCompany.value,
        //     label: props.selectedCompany.label,
        //   },
        // })}
        ref={props.ref}
        defaultOptions
        onChange={(selected: any) => {
          if (props.onSelected) {
            if (selected) {
              props.onSelected(selected);
            } else {
              props.onSelected({});
            }
          }
          if (selected) {
            // setSelectValue(selected);
            props.onChange(selected);
          } else {
            props.onChange("");
          }
        }}
        loadOptions={promiseOptions}
        placeholder={props?.placeholder}
      />
    </Col>
  );
};

export default AsyncCompanySelect;
