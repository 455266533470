import { useQuery } from "@apollo/client";
import moment from "moment";
import { FC, useEffect, useState } from "react";
import { Input } from "reactstrap";
import AVATAR_SVG from '../../../../assets/images/user-empty.svg';
import { MENTORS_COMMISSION_HEADER } from "../../../../constants";
import { IMentorCommission, MentorCommissionsProps } from "../../../../interfaces";
import { getClassNameForTableBase } from "../../../../utils/helper";
import NoDataFound from "../../../layout/NoDataFound";
import { GET_ALL_MENTOR_COMMISSION_OVERWRITE } from "../gql";

const MentorCommissions: FC<MentorCommissionsProps> = () => {
  const [collapse, setCollapse] = useState({});
  const [activeCommissionToggle, setActiveCommissionToggle] = useState<boolean>(true)
  const [mentorCommissions, setMentorCommissions] = useState<IMentorCommission[]>()

  //Query
  const { data, loading } = useQuery<{ getAllMentorCommissions: { mentorCommission: IMentorCommission[] } }>(GET_ALL_MENTOR_COMMISSION_OVERWRITE, {
    variables: { status: 'active' },
  })

  useEffect(() => {
    if (activeCommissionToggle && data)
      setMentorCommissions(data?.getAllMentorCommissions?.mentorCommission?.filter(({ status }) => status === 'active'))
    else if (!activeCommissionToggle && data)
      setMentorCommissions(data?.getAllMentorCommissions?.mentorCommission?.filter(({ status }) => status !== 'active'))

  }, [activeCommissionToggle, data])

  const toggleCollapse = (index: number) => {
    setCollapse({
      [index]: !collapse[index]
    });
  };


  return <>
    {!loading && data?.getAllMentorCommissions?.mentorCommission?.length ? <>
      <div className="d-flex justify-content-between align-items-center my-2">
        <h6 className="mt-5 px-2">Mentor's Commission</h6>
        <div className="d-flex mt-5 gap-14">
          <span>Active</span>
          <label className="switch m-0">
            <Input
              defaultChecked={activeCommissionToggle}
              type="checkbox"
              onChange={(e) => setActiveCommissionToggle(e.currentTarget.checked)}
            />
            <span className="slider"></span>
          </label>
        </div>
      </div>
      {!mentorCommissions?.length ? <NoDataFound /> :
        <div className="custom-table">
          <table className="" style={{
            width: "100%",
            borderSpacing: 10,
            borderCollapse: "separate",
          }}>
            <thead className="table-header py-2">
              <tr className="table-row" style={{ borderRadius: 6, boxShadow: "#F7F8F9 0px 0px 0px 1px" }}>
                {MENTORS_COMMISSION_HEADER.map((key) => (
                  <th key={key} className="table-header-cell-mentor table-header">
                    {key.toLocaleUpperCase()}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="table-body">
              {mentorCommissions?.map((item, index) => {
                return <>
                  <tr key={index} onClick={() => {
                    if (collapse[index])
                      toggleCollapse(index)
                  }}
                    className={getClassNameForTableBase(false, index === 0, index === mentorCommissions.length - 1)}>
                    <td className="table-cell d-flex align-items-center">
                      <div className="deal-name-text">
                        <div className="avatar-profile">
                          <div className="avatar avatar-team">
                            <img src={item?.recruiterDeal?.recruiter?.photo || AVATAR_SVG} alt="team" />
                          </div>
                          {item?.recruiterDeal?.recruiter?.fullName}
                        </div>
                      </div>
                    </td>
                    <td className="table-cell d-flex align-items-center">
                      {item?.recruiterDeal?.recruiterTitle}
                    </td>
                    <td className="table-cell d-flex align-items-center">
                      {moment(item?.createdAt!).format("MM/DD/YYYY")}
                    </td>
                    <td className="table-cell d-flex align-items-center">
                      {
                        item?.status === 'active' ?
                          item?.recruiterDeal?.dealEndDate ? moment(item?.recruiterDeal?.dealEndDate!).format("MM/DD/YYYY") : '--' :
                          item?.updatedAt ? moment(item?.updatedAt!).format("MM/DD/YYYY") : '--'
                      }
                    </td>

                    <td className="table-cell d-flex align-items-center">
                      {item?.percentage}
                    </td>
                  </tr>
                </>
              })}
            </tbody >
          </table >
        </div >}

    </> : ''
    }
  </>
}
export default MentorCommissions