import { gql } from "@apollo/client";

// auth mutaions
export const LOGIN = gql`
  mutation UserLogin($email: String!, $password: String!) {
    login(loginUser: { email: $email, password: $password }) {
      access_token
      acl
    }
  }
`;

// [Mutation] for forgot password
export const FORGOT_PASSWORD = gql`
  mutation ForgotPassword($email: String!) {
    forgotPassword(email: $email) {
      message
      status
    }
  }
`;

//[Mutation] for reset password
export const RESET_PASSWORD = gql`
  mutation ResetPassword($password: String!, $userKey: String!) {
    updatePassword(payload: { password: $password, userKey: $userKey }) {
      message
      status
    }
  }
`;
