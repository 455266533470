import { useLazyQuery } from "@apollo/client";
import { useEffect } from "react";
import { Spinner } from "reactstrap";
import { formatDateUS } from "../../../../utils/commonFn";
import { CONTACT_PERSONALIZED_STEPS } from "../../steps/gql";

export default function ContactViewDetails({
  compaignId,
  contactId,
  contactDetailsData,
}) {
  const [
    contactPersonalizedSteps,
    {
      loading: loadingContactPersonalizedSteps,
      data: dataContactPersonalizedSteps,
    },
  ] = useLazyQuery(CONTACT_PERSONALIZED_STEPS);

  useEffect(() => {
    if (contactId && compaignId) {
      contactPersonalizedSteps({ variables: { compaignId, contactId } });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactId]);

  return (
    <div className="contact-dialog-body">
      <div className="jobOrderRevamp bg-transparent p-0">
        <div className="d-flex justify-content-between mb-2">
          <label className="font-weight-bold">Company</label>
          <h6 className="mt-2">{contactDetailsData?.company}</h6>
        </div>

        <div className="d-flex justify-content-between mb-2">
          <label className="font-weight-bold">Job Title</label>
          <h6 className="mt-2">{contactDetailsData?.title || "--"}</h6>
        </div>

        <div className="d-flex justify-content-between mb-2">
          <label className="font-weight-bold">Last Updated</label>
          <h6 className="mt-2">
            {formatDateUS(contactDetailsData?.last_action_at)}
          </h6>
        </div>

        <div className="d-flex justify-content-between align-items-center mb-2">
          <label className="font-weight-bold">Steps</label>

          <div className="contentGrid">
            <div className="steps-pipeline">
              {loadingContactPersonalizedSteps ? (
                <Spinner
                  size="sm"
                  style={{ color: "#eaa827" }}
                  className="mr-2"
                />
              ) : (
                <>
                  {dataContactPersonalizedSteps?.contactPersonalizedSteps?.map(
                    (item: any) => {
                      return (
                        <>
                          <div className="steps-badge">{item?.step}</div>
                        </>
                      );
                    }
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
