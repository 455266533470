import ContactsCard from "./contacts-components/ContactsCard";
import ContactsListing from "./contacts-components/ContactsListing";
import { GET_SEQUENCE_STATS } from "../sequences/gql";
import { useLazyQuery } from "@apollo/client";
import { useEffect } from "react";
import "./contacts.scss";
import { useState } from "react";

export default function SequenceContacts({ compaignId }) {
  const [getSequenceStats, { data, loading }] =
    useLazyQuery(GET_SEQUENCE_STATS);
const [labelName,setLabel] =useState<string>("")

  const fetchSequenceStats = async () => {
    getSequenceStats({ variables: { compaignId } });
  };

  useEffect(() => {
    fetchSequenceStats();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [compaignId]);

  return (
    <>
      <div className="contacts-wrapper">
        <ContactsCard data={data} loading={loading} setLabel={setLabel}/>
      </div>

      <ContactsListing
        compaignId={compaignId}
        refetchStats={() => getSequenceStats({ variables: { compaignId } })}
        labelName={labelName}
      />
    </>
  );
}
