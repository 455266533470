export const webInterviewTemplateFunc = (): string => {
  return `<p>Congratulations on your web interview for the ______________ role with _____________!</br></br>
  
<strong>Date: </strong></br>
<strong>Time: </strong></br></br>
     
<strong>Interview Schedule: </strong></br>
AM - AM</br></br>
     
<strong>Web Details: </strong></br></br>
     
<strong>You will be speaking with:</strong>  (Client Contact)</br></br>
     
<strong>Company Website: </strong> (Company Website)</br></br>
     
<strong>This is what I wrote about you when I submitted you: </strong> (Candidate Writeup)</br></br>
     
<span style="background-color: rgb(255, 255, 0); color: rgb(0, 114, 198);">Please confirm receipt of this email and let me know when works best for you for a quick interview prep call!! 😊</span></p>`;
};
