import { Col, Label, Row } from "reactstrap";
import { moneyFormatter } from "../../../../utils/helper";
import "./new-assignment.scss";

const AcaAffordableCareAct = ({ accaAffordableInfo }) => {
  return (
    <div className="jobOrderRevamp">
      <h5>{"Less ACA Affordable Care Act"}</h5>

      <Row>
        <Col lg="6" md="6" sm="12" xs="12">
          <div className="d-flex justify-content-between mb-2 contentGridLabel">
            <Label>Estimate</Label>
            <h6>{`Approx ${moneyFormatter(0.00)}/hr`}</h6>
          </div>

          <div className="d-flex justify-content-between mb-2 contentGridLabel">
            <Label>ACA Cost/hr</Label>
            <h6 className="">({moneyFormatter(0.00)})</h6>
          </div>

          <div className="d-flex justify-content-between mb-2 contentGridLabel">
            <Label>Estimate Hours</Label>
            <h6>{accaAffordableInfo?.assignmentDuration * 40 || "--"}</h6>
          </div>
        </Col>

        <Col lg="6" md="6" sm="12" xs="12">
          <div className="d-flex justify-content-between mb-2 contentGridLabel">
            <Label>Estimate Cost Run of Assignment</Label>
            <h6 className="">{moneyFormatter(0.00)}</h6>
          </div>

          <div className="d-flex justify-content-between mb-2 contentGridLabel">
            <Label>
              Less P20 Fee{" "}
              <span className="link-text-btn">(5% of bill rate) </span>
            </Label>

            <h6>
              {/* 5% of {moneyFormatter(accaAffordableInfo?.billRate)}/hour{" "} */}
              ({moneyFormatter(accaAffordableInfo?.peopleTwoFee)})
            </h6>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default AcaAffordableCareAct;
