// packages block
import { useLazyQuery, useMutation } from "@apollo/client";
import  { FC, useState, useContext } from "react";
import { useEffect } from "react";
import Select from "react-select";
import { toast } from "react-toastify";
import { Button, FormGroup, Label, Modal,ModalBody, ModalFooter, ModalHeader, Spinner,
} from "reactstrap";

// components block
import CreateFolderModal from "../../recruiter-folder/folderComponents/CreateFolderModal";

import { CandidateRecruiterModalInterface } from "../../../interfaces";
import { ADD_TO_RECRUITER_FOLDER, GET_FOLDER_LISTING, MOVE_CANDIDATE_IN_FOLDER,
} from "../../recruiter-folder/gql";
import { AppContext } from "../../../context";
import { customSelectStyleNew } from "../../../components/styled/customSelect";

// graphql, constants, context, interfaces/types, reducer, svgs and utils block

const CandidateRecruiterModal:FC<CandidateRecruiterModalInterface> = ({
  isOpen, toggle, recordToAdd, refetch, clearRecord,  preFolder = null, title = "Add To Recruiter Folder",
}): any => {
  const { theme } = useContext(AppContext);
  const [folderId, setFolderId] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [createModal, setCreateModal] = useState(false);
  const createModalToggle = () => setCreateModal(!createModal);
  const [getFolderList, { loading, data }] = useLazyQuery(GET_FOLDER_LISTING);
  const [addToRecruiterFolder, { loading: waiting }] = useMutation(
    ADD_TO_RECRUITER_FOLDER
  );
  const [moveToRecruiterFolder, { loading: moveLoading }] = useMutation(
    MOVE_CANDIDATE_IN_FOLDER
  );

  const closeRecruiterFolderModal = () =>{
    toggle()
    setFolderId("")
  }

  const _getFolderList = () => {
    getFolderList({
      variables: {
        limit: 50,
        page: 1,
        searchText: "",
      },
    });
  };

  const addToFolder = async () => {
    if (folderId) {
      setErrMsg("");
      let recruiterFolder = { folderId, preFolder, candidateIds: recordToAdd };
      let res = await addToRecruiterFolder({ variables: { recruiterFolder } });
      if (res?.data?.addToRecruiterFolder?.candidate?.length) {
        let candidate = res?.data?.addToRecruiterFolder?.candidate?.map(
          (item) => item?.fullName
        );
        setErrMsg(
          `Candidate ${candidate?.join(", ")} are already exist in this folder!`
        );
      } else {
        toast.success("Updated successfully!");
        setFolderId("");
        toggle();
        clearRecord();
        refetch();
      }
    } else {
      setErrMsg("Must select the folder!");
    }
  };
  const moveToFolder = async () => {
    if (folderId && preFolder) {
      setErrMsg("");
      let candidateIds = recordToAdd?.map((item) => item?.candidateId);
      let moveCandidateInput = {
        moveFolderId: folderId,
        currentFolderId: preFolder,
        candidateIds,
      };
      let res = await moveToRecruiterFolder({
        variables: { moveCandidateInput },
      });
      if (res?.data?.moveCandidate?.candidate?.length) {
        let candidate = res?.data?.moveCandidate?.candidate?.map(
          (item) => item?.fullName
        );
        setErrMsg(
          `Candidate ${candidate?.join(", ")} are already exist in this folder!`
        );
      } else {
        toast.success("Moved successfully!");
        setFolderId("");
        clearRecord();
        toggle();
        refetch();
      }
    } else {
      setErrMsg("Must select the folder!");
    }
  };

  useEffect(() => {
    _getFolderList();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    setErrMsg("");
    // eslint-disable-next-line
  }, [isOpen]);

  const getFolderOptions = () => {
    if (data?.fetchAllFolders?.recruiterFolders?.length) {
      let options = [];
      if (preFolder) {
        options = data?.fetchAllFolders?.recruiterFolders
          ?.filter((item) => item?.id !== preFolder)
          ?.map((item) => ({
            label: item?.folderName,
            value: item?.id,
          }));
      } else {
        options = data?.fetchAllFolders?.recruiterFolders?.map((item) => ({
          label: item?.folderName,
          value: item?.id,
        }));
      }
      return options;
    }
  };

  useEffect(() => {
    getFolderOptions();
    // eslint-disable-next-line
  }, [data?.fetchAllFolders?.recruiterFolders]);

  return (
    <>
      <Modal
        isOpen={isOpen}
        toggle={closeRecruiterFolderModal}
        className="revampDialog revampDialogWidth"
      >
        <ModalHeader toggle={toggle}>{title}</ModalHeader>
        <ModalBody>
          <FormGroup>
            <div className="d-flex justify-content-between">
              <Label>Select Folder</Label>
              <Button
                className="border-0 bg-transparent text-info btn-sm"
                onClick={createModalToggle}
              >
                Create new folder
              </Button>
            </div>
            <Select
              isSearchable
              name="industry"
              options={getFolderOptions()}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(item: any) =>{
                setFolderId(item?.value)
                !!item?.value ? setErrMsg("") : setErrMsg("Must select the folder!");
              }}
              styles={{ ...customSelectStyleNew(theme) }}
            />
            <small className="text-danger">{errMsg}</small>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <button
            onClick={() => {
              closeRecruiterFolderModal()
            }}
            type="button"
            className="buttonPrimary"
          >
            Close
          </button>

          <button
            type="button"
            className="buttonGenericStyle filledButton"
            onClick={() => {
              preFolder ? moveToFolder() : addToFolder();
            }}
          >
            {loading ||
              waiting ||
              (moveLoading && <Spinner size="sm" className="mr-2" />)}
            {preFolder
              ? "Move"
              : title === "Copy To Folder"
              ? "Copy"
              : " Add"}
          </button>
        </ModalFooter>
      </Modal>
      <CreateFolderModal
        isOpen={createModal}
        toggle={createModalToggle}
        folderId={null}
        folderName=""
        reFetch={() => _getFolderList()}
      />
    </>
  );
};

export default CandidateRecruiterModal;
