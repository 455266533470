import { useMutation } from "@apollo/client";
import { useState } from "react";
import { toast } from "react-toastify";
import {
  Alert,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import AsyncCompanyDropdown from "../../../components/AsyncCompanySelect";
import CustomButton from "../../../components/layout/CustomButton";
import { CHANGE_CONTACT_COMPANY } from "../gql";

const ChangeContactCompanyModal = ({
  toggle,
  isOpen,
  fetchContactDetail,
  contactId,
}) => {
  const [companyId, setCompanyId] = useState<any>(null);
  const [locationId] = useState(null);
  const [error, setError] = useState<string>('');

  const [_changeContactCompany, { loading }] = useMutation(
    CHANGE_CONTACT_COMPANY
  );

  const changeContactCompany = async () => {
    if (companyId) {
      let res = await _changeContactCompany({
        variables: {
          changeContactCompany: {
            companyId: companyId?.value,
            locationId,
            contactId,
          },
        },
      });
      if (res.data?.changeContactCompany?.status === 200) {
        toast.success("Company changed successfully");
        fetchContactDetail();
      }
    } else {
      setError('No company selected!')
    }
  };

  const onCancelClick = () => {
    toggle();
    setError('')
  }

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className="revampDialog revampDialogWidth"
    >
      <ModalHeader toggle={toggle}>Change Contact Company</ModalHeader>

      <ModalBody>
        <FormGroup>
          <Label>Change Company</Label>
          <AsyncCompanyDropdown
            width={"100%"}
            ownershareeFlag={true}
            placeholder="Filter By Company name"
            value={companyId}
            // isDisabled={makeCompanyReadOnly}
            onChange={(value) => {
              if (value) {
                setCompanyId(value);
                setError('')
              }
            }}
          // ownerIdFlag={true}
          />
        </FormGroup>
        {error && <Alert>{error}</Alert>}
      </ModalBody>
      <ModalFooter>
        <button type="button" onClick={onCancelClick} className="buttonPrimary mr-3">
          Cancel
        </button>
        <CustomButton
          buttonText="Change"
          buttonColor="primary"
          buttonType="submit"
          className="big-width"
          buttonClick={changeContactCompany}
          loading={loading}
          disabled={loading}
        />
      </ModalFooter>
    </Modal>
  );
};

export default ChangeContactCompanyModal;
