import { EditScheduleInterface, Hour } from '../../../interfaces';
import { FC, useEffect, useState } from 'react';
import { Button, FormGroup, Input, Label } from 'reactstrap';
import {
  CANCEL,
  EDITING_SCHEDULE,
  EDITING_SCHEDULE_DESC,
  SAVE,
  WEEKDAYS,
  WEEK_HOURS,
} from '../../../constants';
import { combineArrays } from '../../../utils/commonFn';

const EditSchedule: FC<EditScheduleInterface> = ({
  weekDay,
  selectedHours,
  setSelectHours,
  onClose,
}) => {
  const { hours, id } = weekDay || {};
  const [selectedDayIndex, setSelectedDayIndex] = useState<number[]>([]);
  const [selectHourstemp, setSelectHourTemp] = useState<Hour[]>([]);
  const [selectedDays, setSelectedDays] = useState<number[]>([id]);
  const [removeHourstemp, setRemoveHourTemp] = useState<Hour[]>([]);

  const handleDaySelect = (value: number) => {
    const clickedHour = hours.find((hour) => hour.value === value);
    const isSelected = selectedDayIndex.includes(value);

    if (isSelected) {
      setSelectedDayIndex((prevIndexes) =>
        prevIndexes.filter((i) => i !== value)
      );
      removeSelectedHour(clickedHour);
    } else {
      setSelectedDayIndex((prevIndexes) => [...prevIndexes, value]);
      addSelectedHour(clickedHour);
    }
  };

  const addSelectedHour = (hour: Hour) => {
    if (!selectHourstemp.some((selectedHour) => selectedHour.value === hour.value)) {
      setSelectHourTemp((prevHours) => [...prevHours, hour]);
    }
    if (removeHourstemp.some((removedHour) => removedHour.value === hour.value)) {
      setRemoveHourTemp((prevHours) =>
        prevHours.filter((removedHour) => removedHour.value !== hour.value)
      );
    }
  };

  const removeSelectedHour = (hour: Hour) => {
    if (
      !removeHourstemp.some((removedHour) => removedHour.value === hour.value)
    ) {
      setRemoveHourTemp((prevHours) => [...prevHours, hour]);
    }
  };

  const getSelectedHours=(id, selectedHours)=> {
    const selectedDay = WEEK_HOURS?.find(day => day?.id === id);
    if (selectedDay) {
      const selectedHourObjects:Hour[] = [];
      selectedHours?.forEach(hourValue => {
        const selectedHour= selectedDay?.hours?.find(hour => hour?.value === hourValue?.value);
        if (selectedHour) {
          selectedHourObjects.push(selectedHour);
        }
      });
      return selectedHourObjects;
    } else {
      return []; // Day not found, return an empty array
    }
  }


  const handleSaveSelectedDiv = () => {
    const mergedArray = combineArrays(selectHourstemp, selectedHours);
    const finalArray:Hour[] = mergedArray.filter(
      (hour) => !removeHourstemp?.some((removedHour) => removedHour?.value === hour?.value)
    );
      if (selectedDays?.length > 1) {
      const selectedHourOfSelectedDay: Hour[] = getSelectedHours(id, finalArray);
      const matchedDays = WEEK_HOURS.filter((day) => selectedDays?.includes(day?.id)); 
        const matchedHours: Hour[] = [];
          for (const day of matchedDays) {
              for (const hour of selectedHourOfSelectedDay) {
                const matchedHour = day?.hours?.find((h) => h?.hr === hour?.hr);
                if (matchedHour) {
                  matchedHours.push(matchedHour);
                }
              }
            }
            setSelectHours(prevSelectHours => {            
              const uniqueMatchedHours = matchedHours?.filter(matchedHour => !prevSelectHours.some(prevHour => prevHour?.value === matchedHour?.value));
              const allSelectedHours = [...prevSelectHours, ...uniqueMatchedHours];
              if (removeHourstemp?.length) {
                const matchedHoursValues = matchedDays?.flatMap(day =>day.hours.map(hour => hour?.value));
                const filteredHours = allSelectedHours?.filter(hour =>  removeHourstemp.some(removedHour => removedHour?.hr === hour?.hr) &&
                  matchedHoursValues.includes(hour?.value)
                );
                const remainingHours = allSelectedHours.filter(hour =>!filteredHours.some(filteredHour => filteredHour?.value === hour?.value));
                const matchedHoursBydays = WEEK_HOURS?.find(day => day?.id === id)?.hours
                const filteredMatchedDay = matchedHoursBydays?.filter(day => !remainingHours?.some(hour => hour?.value === day?.value));
                const selectedDaysHours = WEEK_HOURS?.filter(day => selectedDays?.includes(day?.id))?.flatMap(matchedDay => matchedDay?.hours);
                const remainingSelectedDaysHours = selectedDaysHours?.filter(hour =>filteredMatchedDay?.some(filteredHour => filteredHour?.hr === hour?.hr));
                return remainingHours?.filter(hour => !remainingSelectedDaysHours?.some(filteredHour => filteredHour?.value === hour?.value));
              } else {
                const matchedHoursBydays = WEEK_HOURS?.find(day => day?.id === id)?.hours
                const filteredMatchedDay = matchedHoursBydays?.filter(day => !allSelectedHours?.some(hour => hour?.value === day?.value));
                const selectedDaysHours = WEEK_HOURS.filter(day => selectedDays.includes(day.id)).flatMap(matchedDay => matchedDay?.hours);
                const remainingSelectedDaysHours = selectedDaysHours.filter(hour =>filteredMatchedDay?.some(filteredHour => filteredHour?.hr === hour?.hr));
                return allSelectedHours.filter(hour => !remainingSelectedDaysHours?.some(filteredHour => filteredHour?.value === hour?.value));
              }
            
            });
          
    } else {
      setSelectHours(finalArray);    
    }
  
    onClose();
  };
  

  const selectedDaysHandler = (id) => {
    setSelectedDays((prevSelectedDays) => {
      const isSelected = prevSelectedDays.includes(id);
      if (isSelected) {
        return prevSelectedDays.filter((day) => day !== id);
      } else {
        return [...prevSelectedDays, id];
      }
    });
  };
  
  useEffect(() => {
    const indexes = selectedHours?.map((hr) => hr.value);
    setSelectedDayIndex(indexes);
  }, [selectedHours]);

  return (
    <div className="px-2 py-5">
      <h4 className="mb-2 text-center">{EDITING_SCHEDULE}</h4>
      <p className="text-muted text-center mb-2">{EDITING_SCHEDULE_DESC}</p>
      <div className="mx-auto my-5 d-flex flex-wrap schedule-main overflow-hidden">
        {hours.map((myHours: Hour, index: number) => {
          const isLastDiv = index === hours.length - 1;
          const borderStyle = isLastDiv ? 'none' : '1px solid #e7e8eb';
          const backgroundColor = selectedDayIndex.includes(myHours.value)
            ? '#eaa827'
            : 'transparent';
          return (
            
            <div
              key={index}
              onClick={() => handleDaySelect(myHours.value)}
              style={{ borderRight: borderStyle, backgroundColor }}
              className="flex-equal cursor-pointer d-inline-flex justify-content-start align-align-items-center"
            >
              <p className="text-white text-sm vert-text mb-0">{myHours?.hr}</p>
            </div>
          );
        })}
      </div>

      <div className="w-100 text-center mx-auto mb-4">
        {WEEKDAYS.map((day, index) => (
          <div
            key={index}
            className="d-inline-flex align-items-center justify-content-center px-2 py-1 m-2 rounded day-main"
          >
            <FormGroup className="ml-3 mb-1 mt-1 pl-1">
              <Label className="m-0 w-100 d-flex align-items-center">
                <Input type="checkbox" name="day" checked={selectedDays.includes(index+1)} onChange={()=>selectedDaysHandler(index+1)} />
                <span className="checkboxTextStyle w-100 pl-lg-0 pl-2">
                  {day[0]}
                </span>
              </Label>
            </FormGroup>
          </div>
        ))}
      </div> 
      <hr />

      <div className="d-flex align-items-center justify-content-center mt-5">
        <button
          type="button"
          className="buttonGenericStyle buttonPrimary"
          onClick={() => onClose()}
        >
          {CANCEL}
        </button>
        <div className="px-3" />
        <Button
          onClick={handleSaveSelectedDiv}
          className="buttonPrimary filledButton"
        >
          {SAVE}
        </Button>
      </div>
    </div>
  );
};

export default EditSchedule;