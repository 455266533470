import AsyncSelect from "react-select/async";
import { toast } from "react-toastify";
import { useContext } from "react";
import { AppContext } from "../../../context";
import client from "../../../apollo";
import { Col, Label } from "reactstrap";
import { GET_ALL_JOB_ORDERS } from "../../job-order/gql";
import { customSelectDropDown } from "../../../components/styled/customSelect";
let time = 0;

const AsyncJobSelect = (props) => {
  const { theme } = useContext(AppContext);
  const _filterJobs = async (inputValue: string) => {
    try {
      const { data } = await client.query({
        query: GET_ALL_JOB_ORDERS,
        variables: {
          limit: 20,
          page: 1,
          searchText: inputValue,

          statusSearch: null,
          companyId: props?.companyId ?? "",
          myJobOrder: null,
        },
      });

      return data && data?.fetchAllJobOrders?.jobOrders
        ? data.fetchAllJobOrders.jobOrders.map((item) => ({
            value: item.id,
            label: `${
              item?.company?.name
                ? `${item.jobTitle}-${item?.company?.name}`
                : item.jobTitle
            }`,
          }))
        : [];
    } catch (error) {
      toast.error("Error while loading jobs!");
    }
  };

  
  const promiseOptions = (value: any): any =>
    new Promise((resolve) => {
      if (!value) {
        resolve(_filterJobs(value));
      } else window.clearTimeout(time);
      time = window.setTimeout(() => {
        resolve(_filterJobs(value));
      }, 1000);
    });

  return (
    <Col xl="4" md="6" sm="12">
      <Label>{props?.label}</Label>
      <AsyncSelect
        cacheOptions
        isDisabled={props?.isDisabled || false}
        value={props?.value}
        // defaultValue={props?.defaultValue}
        isSearchable
        styles={{ ...customSelectDropDown(theme) }}
        isClearable
        ref={props.ref}
        defaultOptions
        onChange={(selected: any) => {
          if (props.onSelected) {
            if (selected) {
              props.onSelected(selected);
            } else {
              props.onSelected({});
            }
          }
          if (selected) {
            // setSelectValue(selected);
            props.onChange(selected);
          } else {
            props.onChange("");
          }
        }}
        loadOptions={promiseOptions}
        placeholder={props?.placeholder}
      />
    </Col>
  );
};

export default AsyncJobSelect;
