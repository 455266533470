import Select from "react-select";
import deleteIcon from "../../../../assets/images/delete-field.svg";
import EDIT_ICON from "../../../../assets/images/edit-icon-pencil.svg";

import {
  Button,
  Col,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  Row,
  Table,
} from "reactstrap";
import { useState, useEffect, useContext } from "react";
import { IRecruiterPercentage } from "../create/IndexUpdatePlacement";
import { ZERO_TO_HUNDRED } from "../../../../utils/constant";
import { AppContext } from "../../../../context";
import { customSelectDropDown } from "../../../../components/styled/customSelect";
import { moneyFormatter } from "../../../../utils/helper";

export default function RecruiterSplit({
  recruiters,
  errors,
  recruiterListChange,
  recruiterList,
  defaultCandidateOwner,
  editPermitForRecruiter,
  defaultPercentage,
  netCommission,
}) {
  const { theme } = useContext(AppContext);
  const [recruiter, setRecruiter] = useState<any>();
  const [comingRecruiters, setComingRecruiters] = useState<any>(recruiters);
  const [percentageError, setPercentageError] = useState<any>();
  const [edit, setEdit] = useState<boolean>(false);
  const [recruiterPercentage, setRecruiterPercentage] =
    useState<any>(defaultPercentage);

  const onPercentageChange = (percentage: string) => {
    setRecruiterPercentage(parseFloat(percentage));
    if (percentage) {
      const mailRegex = new RegExp(ZERO_TO_HUNDRED);
      const test = mailRegex.test(percentage);
      if (test) {
        setPercentageError("");
      } else {
        setPercentageError("Percentage is not valid!");
      }
    }
  };

  const onDeleteItem = (item: IRecruiterPercentage) => {
    let objectToAdd: any = {};

    objectToAdd.id = item?.recruiter?.value;
    objectToAdd.fullName = item?.recruiter?.label;

    let cloneArr = [...comingRecruiters, { ...objectToAdd }];

    setComingRecruiters(cloneArr);
    setEdit(false);
    recruiterListChange(item);
    setRecruiter("");
    setRecruiterPercentage("");
  };

  useEffect(() => {
    if (!recruiter) {
      setComingRecruiters(
        comingRecruiters?.filter(
          (item: { id: string }) => item.id !== defaultCandidateOwner?.value
        )
      );

      setRecruiter(defaultCandidateOwner);
    }
    // eslint-disable-next-line
  }, []);

  const onEditItem = (item: IRecruiterPercentage) => {
    setRecruiter({
      label: item?.recruiter?.label,
      value: item?.recruiter?.value,
    });
    onPercentageChange(item?.percentage?.toString());
  };

  const editCurrentSplit = () => {
    const index = recruiterList?.findIndex(
      (element: { recruiter: { value: string } }) =>
        element?.recruiter?.value === recruiter?.value
    );

    recruiterList?.splice(index, 1);

    recruiterListChange({
      recruiter: recruiter,
      percentage: recruiterPercentage,
    });

    setEdit(false);
  };

  return (
    <div>
      <Row>
        <Col md="5">
          <Label>
            Recruiter Name <span className="text-danger">*</span>
          </Label>
          <Select
            isDisabled={editPermitForRecruiter}
            value={recruiter}
            name="recruiterName"
            options={comingRecruiters?.map(
              (item: { fullName: string; id: string }) => {
                return {
                  label: item?.fullName,
                  value: item?.id,
                };
              }
            )}
            onChange={setRecruiter}
            styles={{ ...customSelectDropDown(theme) }}
          />
        </Col>

        <Col md="5">
          <Label>
            Recruiter Percentage <span className="text-danger">*</span>
          </Label>
          <InputGroup className="m-0">
            <InputGroupAddon addonType="prepend">%</InputGroupAddon>
            <Input
              readOnly={editPermitForRecruiter}
              placeholder="Recruiter Percentage"
              name="recruiterPercentage"
              type="number"
              value={`${recruiterPercentage}`}
              onChange={(e) => onPercentageChange(e.target.value)}
            />
          </InputGroup>
          <small className="text-danger">
            {errors?.recruiterPercentage?.message || percentageError}
          </small>
        </Col>

        <Col md="2" className="pt-4">
          {edit ? (
            <button
              type="button"
              disabled={
                !(recruiter && recruiterPercentage) || editPermitForRecruiter
              }
              onClick={() => {
                setRecruiter("");
                setRecruiterPercentage("");
                editCurrentSplit();
              }}
              className="darkButton darkButtonSpace"
            >
              Edit
            </button>
          ) : (
            <button
              disabled={
                editPermitForRecruiter ||
                percentageError ||
                !(recruiter && recruiterPercentage) ||
                recruiterList?.find(
                  (item: IRecruiterPercentage) =>
                    item?.recruiter?.value === recruiter?.value
                )
                  ? true
                  : false
              }
              onClick={() => {
                !recruiterList?.find(
                  (item: IRecruiterPercentage) =>
                    item?.recruiter?.value === recruiter?.value
                ) &&
                  recruiterListChange({
                    recruiter: recruiter,
                    percentage: recruiterPercentage,
                  });

                setRecruiter("");
                setComingRecruiters(
                  comingRecruiters?.filter(
                    (item: { id: string }) => item.id !== recruiter?.value
                  )
                );
                setRecruiterPercentage("");
              }}
              type="button"
              className="darkButton darkButtonSpace mb-2"
            >
              Add
            </button>
          )}
        </Col>
      </Row>

      <div className="mt-3">
        {!!recruiterList?.length && (
          <Row className="account-manager-splitTable pt-md-0 pt-3">
            <Col md="12">
              <div className="table-responsive dark-table">
                <Table
                  className="transparentTable border-1"
                  style={{ borderCollapse: "separate" }}
                >
                  <thead>
                    <tr>
                      <th style={{ width: "40%" }}>Name</th>
                      <th style={{ width: "20%" }}>Credit %</th>
                      <th style={{ width: "20%" }}>Billing Credit</th>
                      <th style={{ width: "40%" }} align="right"></th>
                    </tr>
                  </thead>
                  {recruiterList?.map(
                    (item: IRecruiterPercentage, index: number) => {
                      return (
                        <tbody>
                          <tr>
                            <td>{item?.recruiter?.label}</td>
                            <td className="colorBlue">{item?.percentage} %</td>
                            <td className="colorBlue">
                              <td className="colorBlue">
                                {moneyFormatter(
                                  (netCommission * 0.5 * item?.percentage) /
                                    100 || 0
                                )}
                              </td>
                            </td>
                            <td align="right">
                              <div>
                                <Button
                                  disabled={editPermitForRecruiter}
                                  onClick={() => {
                                    setEdit(true);
                                    onEditItem(item);
                                  }}
                                  className="my-auto bg-transparent border-0 w-auto"
                                >
                                  <img
                                    src={EDIT_ICON}
                                    alt="Edit"
                                    className="m-0"
                                    width="16"
                                  />
                                </Button>
                                <Button
                                  disabled={editPermitForRecruiter}
                                  onClick={() => onDeleteItem(item)}
                                  className="my-auto bg-transparent border-0 w-auto"
                                >
                                  <img
                                    src={deleteIcon}
                                    alt=""
                                    className="m-0"
                                    width="20"
                                    height="20"
                                  />
                                </Button>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      );
                    }
                  )}
                </Table>
              </div>
            </Col>
          </Row>
        )}
      </div>
    </div>
  );
}
