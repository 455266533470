import { useContext, useEffect, useState } from 'react';
import Loader from '../../../../components/layout/Loader';
import { AppContext } from '../../../../context';
import { ISequenceOtherStep, ISequenceStepPropsCard } from '../interfaceSteps';
import StepCardBody from './StepCardBody';
import StepCardFooter from './StepCardFooter';
import StepCardHeader from './StepCardHeader';

export default function StepCards({
  sequenceStepStats,
  subject,
  _id,
  body,
  wait_days,
  allowOptions,
  compaignId,
  refetchStepCards,
  fromPreview,
  sequenceStepsLengthCheck,
  stepId,
  stepIndex,
  replying,
  stepLoading,
  scheduledFor,
  contactEmail,
  otherStepSingle,
  sequenceSteps,
  latestSubject,
  manual,
  emailContactStep,
  setEmailContactStep,
  contactId,
  personalizedType,
  setEmailTemp,
  emailTemp,
  setPersonzailedStepID,
  personalizedSteps,
  setstepIndexNumber

}: ISequenceStepPropsCard) {
  const [waitingDaysChildData, setWaitingDaysChildData] = useState<number>(NaN);
  const [otherStepData, setOtherStepData] = useState<ISequenceOtherStep>();
  let { sequencePreview, setContextSequence } = useContext(AppContext);

  const setPreviewContextData = () => {
    setPersonzailedStepID && setPersonzailedStepID(stepId)
    setstepIndexNumber &&   setstepIndexNumber(stepIndex)
    let lastElement;
    // this loop is fetching last  new thread subject for reply
    if (!personalizedSteps){
      for (let i = 0; i < sequenceSteps?.length; i++) {
        if (sequenceSteps[i]?._id === _id) {
          for (let k = i; k >= 0; k--) {
            if (sequenceSteps[k]?.subject) {
              lastElement = sequenceSteps[k].subject;
              break;
            }
          }
        }
      }
   
      if (!_id && replying) {
        for (let i = sequenceSteps?.length - 1; i >= 0; i--) {
          if (sequenceSteps[i]?.subject) {
            lastElement = sequenceSteps[i]?.subject;
            break;
          }
        }
      }
    } else if(personalizedSteps){
      
      for (let i = 0; i < personalizedSteps?.length; i++) {
        if (personalizedSteps[i]?._step === _id) {
          for (let k = i; k >= 0; k--) {
            if (personalizedSteps[k]?.personalizedStepDetails?.subject) {
              lastElement = personalizedSteps[k]?.personalizedStepDetails?.subject;
              break;
            }
          }
        }
      }
   
      if (!_id && replying) {
        for (let i = personalizedSteps?.length - 1; i >= 0; i--) {
          if (personalizedSteps[i]?.personalizedStepDetails?.subject) {
            lastElement = personalizedSteps[i]?.personalizedStepDetails?.subject;
            break;
          }
        }
      }
    }


    const obj = {
      _id,
      subject,
      latestSubject:
        sequenceStepsLengthCheck === false
          ? null
          : subject && _id && !replying
          ? lastElement
          : `RE: ${lastElement}`,
      body,
      preview: true,
      allowOptions,
      wait_days: waitingDaysChildData || wait_days,
      stepIndex,
      sequenceStepsLengthCheck,
      stepId,
      fromPreview,
      replying,
      contactEmail,
      fromABPreview: false,
      personalizedType,
    };
    setContextSequence(obj);
    setEmailTemp && setEmailTemp({
      step: null,
      stepId: null
    });
    };

  const setClonePreviewContextData = () => {
    let clone = { ...sequencePreview };
    if (clone?.abTestStart) {
      clone = {};
      setContextSequence(clone);
      if (!Object.keys(clone)?.length) {
        const obj = {
          _id,
          subject,
          body,
          clone: true,
          abTestStart: true,
          fromABPreview: true,
        };
        setContextSequence(obj);
      }
    }
  };

  const setCloneFullPreviewContextData = () => {
    let clone = { ...sequencePreview };
    clone = {};
    setContextSequence(clone);
    const obj = {
      _id: otherStepData?._id,
      subject: otherStepData?.subject,
      body: otherStepData?.body,
      clone: true,
      abTestStart: true,
      _step: otherStepData?._step,
      fromABPreview: true,
    };
    setContextSequence(obj);
  };

  const updateWaitDays = (childData: number) => {
    setWaitingDaysChildData(childData);
  };

  const handlingTitle = (): string => {
    if (scheduledFor) {
      return `Sends approximately on ${new Date(scheduledFor)
        .toString()
        .substring(0, 21)}`;
    } else {
      return sequenceStepsLengthCheck && wait_days !=null && waitingDaysChildData
        ? `Automatically sends email ${waitingDaysChildData} days after`
        : sequenceStepsLengthCheck && wait_days !=null
        ? `Automatically sends email ${wait_days+1} days after`
        : !sequenceStepsLengthCheck && waitingDaysChildData
        ? `Automatically sends email ${waitingDaysChildData} days after`
        : 'Automatically sends email 10 minutes after contact is added';
    }
  };


  useEffect(() => {
    if (otherStepSingle) {
      setOtherStepData(otherStepSingle);
    }
  }, [otherStepSingle]);

  return (
    <>
      {stepLoading ? (
        <Loader loaderClass="sm-height " />
      ) : (
        <div
          className={`step-cards ${
            (sequencePreview?._id === _id || sequencePreview?._step === _id) &&
            'active'
          }`}
        >
          <StepCardHeader
            otherStepSubject={otherStepData?.subject}
            updateWaitDays={updateWaitDays}
            fromPreview={fromPreview}
            body={body}
            _step={otherStepData?._id}
            subject={subject}
            sequenceStepsLengthCheck={sequenceStepsLengthCheck}
            refetchStepCardHeader={refetchStepCards}
            compaignId={compaignId}
            replying={replying}
            _id={_id}
            title={handlingTitle()}
            manual={manual}
            emailContactStep={emailContactStep}
            setEmailContactStep={setEmailContactStep}
            contactId={contactId}
            stepId={stepId}
            personalizedType={personalizedType}
            setEmailTemp={setEmailTemp}
            emailTemp={emailTemp}
          />

          <div className="step-body">
            <StepCardBody
              count={sequenceStepStats?.value?.sent || '--'}
              name="Sent"
            />
            <StepCardBody
              count={sequenceStepStats?.value?.viewed || '--'}
              name="Opened"
            />
            <StepCardBody
              count={sequenceStepStats?.value?.visited || '--'}
              name="Clicked"
            />
            <StepCardBody
              count={sequenceStepStats?.value?.replied || '--'}
              name="Replied"
            />
          </div>

          <div onClick={setPreviewContextData}>
            <StepCardFooter
              sequenceStepsLengthCheck={sequenceStepsLengthCheck}
              stepLoading={stepLoading}
              _id={_id}
              _step={_id}
              subject={subject}
              body={body}
              replying={replying}
              latestSubject={latestSubject}
            />
          </div>

          {otherStepData ? ( 
            <div className="mt-3" onClick={setCloneFullPreviewContextData}>
              <StepCardFooter
                sequenceStepsLengthCheck={sequenceStepsLengthCheck}
                _id={otherStepData?._id}
                _step={otherStepData?._step}
                subject={otherStepData?.subject}
                body={otherStepData?.body}
                latestSubject={latestSubject}
              />
            </div>
          ) : (
            sequencePreview?.abTestStart &&
            sequencePreview?._id === _id && (
              <div className="mt-3" onClick={setClonePreviewContextData}>
                <StepCardFooter
                  _step={_id}
                  sequenceStepsLengthCheck={sequenceStepsLengthCheck}
                  stepLoading={stepLoading}
                  _id={_id}
                  subject={subject}
                  body={body}
                  replying={replying}
                  latestSubject={latestSubject}
                />
              </div>
            )
          )}
        </div>
      )}
    </>
  );
}
