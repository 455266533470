import { FC } from "react";
import { Form } from "reactstrap";

import AvatarImage from "../../../assets/images/user-empty.svg";
import { USERTYPE } from "../../../Pages/admin/users/User-data-type";
import NoDataFound from "../NoDataFound";

import "./style.scss";

interface MultiselectMembersPropType {
  isToggle: boolean;
  users?: [USERTYPE];
  setSelectedMember: Function;
  selectedMember: any;
}

const MultiSelectMembers: FC<MultiselectMembersPropType> = ({
  isToggle,
  users,
  setSelectedMember,
}) => {
  return (
    <div className={isToggle ? "multiselect toggle" : "multiselect"}>
      <Form>
        {users &&
          users.map((user: any, index) => (
            <div
              className="avatar-label"
              onClick={() =>
                setSelectedMember({
                  id: user.id,
                  fullName: user.fullName,
                  photo: user.photo,
                })
              }
              key={index}
            >
              <div className="avatar">
                <img src={user.photo || AvatarImage} alt="avatar" />
              </div>
              <label>{user.fullName}</label>
              <label className="ml-2">
                {user?.userTeamName ? `(${user?.userTeamName})` : ""}
              </label>
            </div>
          ))}
      </Form>
      {!users || (!users.length && <NoDataFound />)}
    </div>
  );
};

export default MultiSelectMembers;
