import axios from "axios";
import fileDownload from "js-file-download";
import moment from "moment";
import { toast } from "react-toastify";

export const formatTime = (dateStr: Date | string): string => {
  let time = new Date(dateStr);
  return `${time.toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  })}`;
};

export const formatDateUS = (dateStr: Date | string): string => {
  let date = new Date(dateStr);
  let month = (date.getMonth() + 1).toString().padStart(2, '0');
  let day = date.getDate().toString().padStart(2, '0');
  let year = date.getFullYear();
  return `${month}/${day}/${year}`;
};

/**
 * return date in us formate with moment
 * @param dateStr date or string
 */
// this function handle date with moment library
export const formatDateUSWithMoment = (dateStr: Date | string): string => {
  return moment(dateStr).utcOffset(0).format('MM/DD/YYYY');
};

/**
 * remove falsy [key]:[value] from the object and return a new object
 * @param obj any
 */
export const compactObject = (obj: any) => {
  let newObj = {};
  Object.keys(obj).forEach((key) => {
    if (obj[key]) {
      newObj = { ...newObj, [key]: obj[key] };
    }
  });
  return newObj;
};

/**
 * @author Suleman Khalid
 * @param param0
 * @returns array[options]
 */
export const getOptions = ({ data, valueKey = "id", labelKey = "name" }) => {
  return (
    data?.map((item) => {
      return {
        value: item[valueKey],
        label: item[labelKey],
      };
    }) || []
  );
};

export const getOptionsValues = (data) => {
  if (data?.length) return data?.map((item) => item.value) || [];
};

export const createMarkup = (tempCommon) => {
  return { __html: tempCommon };
};

// this function is used for the download the file

export const downloadPublicFiles = async (url: string, fileName: string) => {
  await fetch(url)
    .then(async (resp) => {
      const blob = await resp.blob();

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      toast.success("Your file has downloaded!"); // or you know, something with better UX...
    })
    .catch(() => {
      toast.error("Problem downloading signed url files (public files)");
    });
};

export const calculateMonthValue = (month: string): string => {
  let monthInNumber: string;
  switch (month) {
    case "January":
      monthInNumber = "01";
      break;

    case "February":
      monthInNumber = "02";
      break;

    case "March":
      monthInNumber = "03";
      break;

    case "April":
      monthInNumber = "04";
      break;

    case "May":
      monthInNumber = "05";
      break;

    case "June":
      monthInNumber = "06";
      break;

    case "July":
      monthInNumber = "07";
      break;

    case "August":
      monthInNumber = "08";
      break;

    case "September":
      monthInNumber = "09";
      break;

    case "October":
      monthInNumber = "10";
      break;

    case "November":
      monthInNumber = "11";
      break;

    case "December":
      monthInNumber = "12";
      break;

    default:
      monthInNumber = "01";
      break;
  }

  return monthInNumber;
};

export const downloadSignedUrlFile = async (url: string, name: string, setLoading?: (value: React.SetStateAction<boolean>) => void) => {
  try {
    const apiBaseURL =
      process.env.REACT_APP_API_BASE_URL || "http://localhost:3001";
    const response = await axios.post(
      `${apiBaseURL}/candidates/downloadSignedUrlFile`,
      {
        url,
      },
      {
        responseType: "blob",
      }
    );

    const file = new Blob([response.data]);
    const fileURL = URL.createObjectURL(file);
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = fileURL;
    a.download = name;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    setLoading && setLoading(false)
  } catch (error) {
    toast.error("Error while downloading file.");
    setLoading && setLoading(false)
  }
};

export const urlExtension = (URL: string) => {
  return URL.split(".").pop();
};

export const truncateDocumentName = (name: string) => {
  if (name?.length > 20) {
    let ext = name?.substring(name?.lastIndexOf(".") - 1, name?.length) || name;
    let docName = name.slice(0, 20).concat("...");
    return `${docName}${ext}`;
  }
  return name;
};

export const handleHtmlToPdfDownload = async (html: string, name: string, setDownloadingWriteUps?: React.Dispatch<React.SetStateAction<boolean>>) => {
  try {
    if (html) {
      const apiBaseURL =
        process.env.REACT_APP_API_BASE_URL || "http://localhost:3001";
      const response = await axios.post(
        `${apiBaseURL}/file-upload/candidate/summary`,
        { html },
        {
          responseType: "blob",
        }
      );
      fileDownload(response.data, `${name}.pdf`);
    }
  } catch (error) {
    setDownloadingWriteUps && setDownloadingWriteUps(false)
    toast.error("Error while downloading file..");
  }
};

export const removingExtension = (str: string) => {
  return str.split(".").slice(0, -1).join(".");
};

export const handleSubmissionMail = async (payload: any) => {
  try {
    const formData = new FormData();
    const { file, ..._payload } = payload;
    for (var key in _payload) {
      if (_payload.hasOwnProperty(key)) {
        console.log(key + " -> " + _payload[key]);
        formData.append(key, _payload[key]);
      }
    }
    for (const item of file) {
      formData.append("files", item, item.name);
    }
    const apiBaseURL =
      process.env.REACT_APP_API_BASE_URL || "http://localhost:3001";
    let response: any = await fetch(
      `${apiBaseURL}/file-upload/submission/email`,
      {
        method: "POST",
        body: formData,
        headers: {
          Authorization: "Bearer " + localStorage.token,
        },
      }
    );
    response = await response.json();
    return response;
  } catch (error) {
    throw error;
  }
};

export const noteSearchParams = (data: any) => {
  let obj = {
    jobOrderId: data?.job?.value,
    jobTitle: data?.job?.label,
    companyId: data?.company?.value,
    companyName: data?.company?.label,
    candidateId: data?.candidate?.value,
    candidateName: data?.candidate?.label,
    contactId: data?.contact?.value,
    contactName: data?.contact?.label,
    recruiterId: data?.recruiter?.value,
    recruiterName: data?.recruiter?.label,
  };
  return obj;
};

export interface noteSearchParamsDTO {
  jobOrderId: string;
  jobTitle: string;
  companyId: string;
  companyName: string;
  candidateId: string;
  candidateName: string;
  contactId: string;
  contactName: string;
  recruiterId: string;
  recruiterName: string;
}

export const combineArrays = <T extends { value: any }>(
  array1: T[],
  array2: T[]
): T[] => {
  const combinedArray = array1?.concat(array2);
  const uniqueArray = Array.from(new Set(combinedArray?.map((item) => item?.value)))
    ?.map((value) => combinedArray?.find((item) => item?.value === value))
    ?.filter((item): item is T => item !== undefined);
  return uniqueArray;
};

export const findSequences = (inputArray: number[]): number[][] => {
  const result: number[][] = [];
  let subArray: number[] = [];

  for (let i = 0; i < inputArray.length; i++) {
    const currentNumber = inputArray[i];
    const previousNumber = inputArray[i - 1];

    if (!previousNumber || currentNumber - previousNumber === 1) {
      // Add the current number to the subarray
      subArray.push(currentNumber);
    } else {
      // Add the subarray range to the result and start a new subarray
      if (subArray.length > 1) {
        result.push([subArray[0], subArray[subArray.length - 1]]);
      } else {
        result.push([subArray[0]]);
      }
      subArray = [currentNumber];
    }

    // Check if the current number is a multiple of 24
    if (currentNumber % 24 === 0) {
      // Add the subarray range to the result and start a new subarray
      if (subArray.length > 1) {
        result.push([subArray[0], currentNumber]);
      } else {
        result.push([subArray[0]]);
      }
      subArray = [];
    }
  }

  // Add the remaining subarray range to the result
  if (subArray.length > 0) {
    if (subArray.length > 1) {
      result.push([subArray[0], subArray[subArray.length - 1]]);
    } else {
      result.push([subArray[0]]);
    }
  }

  return result.filter((element) => JSON.stringify(element) !== JSON.stringify([undefined]));;
}

export const checkUserFeatureAccess = (userId, featureName, settingsData) => {
  const modalAccessSettings = settingsData?.AllModalAccessSettings?.modalAccessSettings;
  const matchingSetting = modalAccessSettings?.find(setting => setting?.feature === featureName);
  if (!matchingSetting) {
    return false; // Feature not found in settings
  }

  if (matchingSetting?.type === "Global") {
    return true; // Global access
  }

  if (matchingSetting?.type === "Individual") {
    if (matchingSetting?.usersArray.length && matchingSetting?.usersArray?.includes(userId)) {
      return true; // Individual access for the provided user ID
    }
  }
  return false; // No matching access found
}

export const addAccessModuleItemToLocalStorage = (data, userId) => {
  if (data.feature === "Job List") {
    if (data.type === "GLOBAL" ||
      (data.type === "INDIVIDUAL" &&
        data?.user.length && data.user?.includes(userId))
    ) {
      window.localStorage.removeItem("sideBarItem")
    }
    else { window.localStorage.setItem("sideBarItem", "Job List") }
  }
}