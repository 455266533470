import React, { useState, InputHTMLAttributes } from "react";
import { Link } from "react-router-dom";
import { FormGroup, Label, Input, Spinner } from "reactstrap";

import PASSWORD_HIDE from "../../../assets/images/hide-password.svg";
import PASSWORD_SHOW from "../../../assets/images/password.svg";
import { widthForDropDown } from "../../../utils/helper";

import "../InputField/style.scss";

interface InputFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  inputtype?: any;
  inputclass?: string;
  placeholder: string;
  inputid: string;
  isPassword?: boolean;
  hasForgotPass?: boolean;
  inputRef?: any;
  errMsg?: string;
  selectItems?: any;
  isRequired?: boolean;
  loading?: boolean;
  inputValue?: string | number;
  inputMethod?: any;
  disable?: boolean;
  isSidebarField?: boolean;
  className?: string
  tableName?: string
  labels?: string
}

const InputFieldColumn = ({
  label,
  inputtype,
  inputclass,
  placeholder,
  inputid,
  isPassword,
  hasForgotPass,
  inputRef,
  errMsg,
  selectItems,
  isRequired,
  loading,
  inputValue,
  inputMethod,
  disable = false,
  isSidebarField,
  className,
  tableName,
  labels,
  ...rest
}: InputFieldProps) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  /////////////



  return (
    <FormGroup className={`${className && className}`}>
      <div className="form-group-heading">
        {
          !isSidebarField && <Label>
            {label} {isRequired && <span className="text-danger">*</span>}
          </Label>
        }
        {hasForgotPass && <Link to="/forgot-password">Forgot password?</Link>}
      </div>

      {inputtype !== "select" && (
        <div
          className={`${isPassword ? "for-icon-placeholder" : ""} ${loading && "input-group"
            }`}
        >
          <Input
            type={
              inputtype === "password"
                ? showPassword
                  ? "text"
                  : "password"
                : inputtype || "text"
            }
            className={`${inputclass} with-spinner`}
            placeholder={placeholder}
            id={inputid}
            name={inputid}
            disabled={disable}
            onChange={(e: any) => {
              if (inputMethod) {
                inputMethod(e.target.value);
              }
            }}
            innerRef={inputRef}
            {...rest}
          />

          {isPassword && (
            <button
              type="button"
              className={showPassword ? "icon hide" : "icon show"}
              onClick={() => setShowPassword(!showPassword)}
            >
              <img
                src={showPassword ? PASSWORD_HIDE : PASSWORD_SHOW}
                alt="show"
              />
            </button>
          )}

          {loading && (
            <div className="input-group-append">
              <Spinner size="sm" className="spinner" />
            </div>
          )}
        </div>
      )}

      {errMsg && inputtype !== "select" && (
        <div className="error">{errMsg}</div>
      )}

      {selectItems && (
        <>
          <select
            className="form-control"
            ref={inputRef}
            name={inputid}
            placeholder={placeholder}
            value={inputValue}
            disabled={disable}
            onChange={(event) => {
              if (inputMethod) {
                inputMethod(event?.target?.value);
              }
            }}
            style={widthForDropDown(labels??'',tableName??"")}
          >
            <option value="">{placeholder}</option>
            {selectItems.map(
              (item: { label: string; value: string }, index: number) => (
                <React.Fragment key={index}>
                  <option value={item?.value}>{item?.label}</option>
                </React.Fragment>
              )
            )}
          </select>

          {/* /////////////////////////// */}
          {/* <select
            className="form-control"
            onChange={initResize}
            value={selectedOption}
            style={{ width: dynamicSize }}
          >
            <option value="rizwan">Rizwan</option>
            <option value="awaisoption2">Awais Option 2</option>
            <option value="option3">Option 3</option>
          </select> */}
        </>
      )}
      {errMsg && inputtype === "select" && (
        <div className="error">{errMsg}</div>
      )}
    </FormGroup>
  );
};

export default InputFieldColumn;
























