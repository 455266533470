export interface ICreateInterviewSelect {
  label: string;
  value: number;
}

export interface ICreateInterviewLabelVal {
  label: string;
  value: string;
}

export interface InviteType extends ICreateInterviewSelect {}

export interface ICreateInterview {
  jobApplicantId: string;
  interviewRoundIndex: ICreateInterviewSelect;
  interviewType: ICreateInterviewLabelVal;
  timeZone: ICreateInterviewLabelVal;
  interviewDateTime?: string;
  location?: string;
  details?: string;
}

export interface ICreateInterviewDTO {
  jobApplicantId: string;
  interviewRoundIndex: number;
  interviewType: string;
  interviewDateTime?: string;
  timeZone?: string;
  timeZoneId?: string;
  location?: string;
  details?: string;
}

export interface IUpdateInterviewDTO {
  id?: string;
  jobApplicantId?: string;
  interviewRoundIndex?: number;
  interviewType?: string;
  timeZone?: string;
  timeZoneId?: string;
  interviewDateTime?: string;
  location?: string;
  details?: string;
}

export interface IStaticFiles {
  fileName?: string | undefined;
  url?: string;
  checked?: boolean;
}

export const staticFilesForInterview: IStaticFiles[] = [
  {
    fileName: "28 Most Common Interview questions",
    url: "https://storage.googleapis.com/ats-realrepp-stage-public/documents/28%20Interview%20Questions.pdf",
    checked: false,
  },
  {
    fileName: "9 Questions You Might Ask In a Job Interview",
    url: "https://storage.googleapis.com/ats-realrepp-stage-public/documents/9%20Questions%20You%20Might%20Ask%20In%20a%20Job%20Interview.pdf",
    checked: false,
  },
];

export const staticFilesForInterviewInitialState: IStaticFiles[] = [
  {
    fileName: "28 Most Common Interview questions",
    url: "https://storage.googleapis.com/ats-realrepp-stage-public/documents/28%20Interview%20Questions.pdf",
    checked: true,
  },
  {
    fileName: "9 Questions You Might Ask In a Job Interview",
    url: "https://storage.googleapis.com/ats-realrepp-stage-public/documents/9%20Questions%20You%20Might%20Ask%20In%20a%20Job%20Interview.pdf",
    checked: true,
  },
];

export interface IAdditionalFileAttachment {
  fileName: string;
  url: string;
  checked: boolean;
}
