// packages block
import { useState, useEffect } from "react";
import { Form } from "reactstrap";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { toast, ToastContainer } from "react-toastify";
import { useHistory, useLocation } from "react-router-dom";
// components block
import GoBack from "../../components/GoBack";
import JobDetails from "./job-order-components/JobDetails";
import CustomButton from "../../components/layout/CustomButton";
import BasicJobOrderInfo from "./job-order-components/BasicJobOrderInfo";
// context, utils, and graphql block
import { CREATE_JOB_ORDER } from "./gql";
import { IComingState } from "../agreements/interfaces";
import { createJobOrderPayload } from "../../utils/createJobOrderData";
import "../company/company.scss";

const CreateJobOrder = () => {
  const history = useHistory();
  const location = useLocation<IComingState>();
  const comingState = location.state;
  const [agreementsList, setAgreementList] = useState<Array<any>>([]);
  const [locationOfJob, setlocationOfJob] = useState<string>('');

  const {
    errors, handleSubmit, register, reset, watch, control, getValues, setValue } = useForm();

  const [createJobOrder, { loading: waiting }] = useMutation(CREATE_JOB_ORDER);

  const createNewJobOrder = async (values: any) => {
    let jobOrderDetail: any = createJobOrderPayload(values);

    jobOrderDetail.recruitingList = [
      ...jobOrderDetail.recruitingList,
    ];

    jobOrderDetail.city = jobOrderDetail.city.trim();
    let res = await createJobOrder({
      variables: {
        CreateJobOrderInput: {
          ...jobOrderDetail,
          postToLinkedin: jobOrderDetail.confidentialJob ? false : true
        },
      },
    });

    if (res?.data) {
      toast.success("Job Order Successfully Created!");
      reset();
      history.push(`/update-job-order/${res?.data?.createJobOrder?.id}`);
    } else {
      toast.error('Something went wrong.');
    }
  };

  useEffect(() => {
    document.title = "Create JobOrder - RealREPP";
  }, []);

  return (
    <div className="admin-tabs team-tabs">
      <ToastContainer autoClose={3000} />
      <GoBack url="/job-orders" search={comingState?.searchParameters} />

      <div className="mobileResponsiveFlexAlign primaryHeaderSpacing">
        <h5 className="m-0">Add Job Order</h5>
      </div>

      <div className="revampContainer">
        <Form onSubmit={handleSubmit(createNewJobOrder)}>
          <BasicJobOrderInfo
            control={control}
            register={register}
            errors={errors}
            setAgreementList={setAgreementList}
            setValue={setValue}
            getValues={getValues}
            agreementsList={agreementsList}
            watch={watch}
            setLocation={setlocationOfJob}
          />

          <JobDetails
            register={register}
            errors={errors}
            statusCheck={true}
            watch={watch}
            agreementsList={agreementsList}
            control={control}
            setValue={setValue}
            getValues={getValues}
            locationOfJob={locationOfJob}
          />

          <div className="text-right pt-4">
            <CustomButton
              buttonText="Add Job Order"
              buttonColor="primary"
              buttonType="submit"
              className="big-width"
              loading={waiting}
              disabled={waiting}
            />
          </div>
        </Form>
      </div>
    </div>
  );
};

export default CreateJobOrder;
