export const csvFileUploading = async (file: File | any) => {
  try {
    const formData = new FormData();
    formData.append("file", file);
    const apiBaseURL =
      process.env.REACT_APP_API_BASE_URL || "http://localhost:3001";
    let response: any = await fetch(`${apiBaseURL}/interseller/uploadingCSV`, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: "Bearer " + localStorage.token,
      },
    });
    response = await response.json();
    return response;
  } catch (error) {
    throw error;
  }
};
