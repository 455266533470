import React from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { Link, RouteComponentProps } from "react-router-dom";
import { Form, Alert } from "reactstrap";

import CustomButton from "../../components/layout/CustomButton";
import InputField from "../../components/layout/InputField";
import AuthLayout from "../../components/layout/AuthLayout";

import { FORGOT_PASSWORD } from "./gql";
import { EMAIL_PATTERN } from "../../utils/constant";

type ForgotType = {
  email: string;
};

const ForgotPassword = ({ history }: RouteComponentProps) => {
  const { register, handleSubmit, errors } = useForm<ForgotType>();
  const [forgotPassword, { error, data, loading }] = useMutation(
    FORGOT_PASSWORD
  );

  if (data?.login?.access_token) {
    window.localStorage.setItem("token", data.login.access_token);
  }

  const formSubmit = async (values: ForgotType) => {
    try {
      await forgotPassword({ variables: values });
      if (!error?.graphQLErrors[0]?.message) {
        history.push("/reset-email");
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <AuthLayout
      title="Reset Password"
      subTitle="Enter the email address associated with your account and we'll send you a link to reset your password."
      isLoginPages
    >
      <div>
        <Form onSubmit={handleSubmit(formSubmit)}>
          <InputField
            label="Email"
            inputtype="email"
            placeholder="Email"
            inputid="email"
            inputRef={register({
              required: { value: true, message: "Email is required!" },
              pattern: { value: EMAIL_PATTERN, message: "Email not valid !" },
            })}
            errMsg={errors?.email?.message}
          />

          {error && (
            <Alert color="danger" className="form-alert">
              {error?.graphQLErrors[0]?.message}
            </Alert>
          )}

          <div className="form-footer">
            <CustomButton
              buttonText="Reset Password"
              loading={loading}
              disabled={loading}
            />
          </div>

          <Link className="link-btn" to="/">
            Back To Login?
          </Link>
        </Form>
      </div>
    </AuthLayout>
  );
};

export default ForgotPassword;
