import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../../context';
import { IStepCardFooter } from '../interfaceSteps';
export default function StepCardFooter({
  stepLoading,
  body,
  subject,
  _id,
  sequenceStepsLengthCheck,
  latestSubject,
}: IStepCardFooter) {
  let { sequencePreview } = useContext(AppContext);
  const [bodyp, setBodyP] = useState('');
  useEffect(() => {
    let temp = body.replace(/<ol>/g, '<p>');
    temp = temp.replace(/<ul>/g, '<p>');
    temp = temp.replace(/<li>/g, ' ');
    temp = temp.replace(/<br>/g, ' ');
    temp = temp.replace(/<\/ol>/g, '</p>');
    temp = temp.replace(/<\/ul>/g, '</p>');
    temp = temp.replace(/<\/li>/g, ' ');
    var strippedHtml = temp?.replace(/<[^>]+>/g, '');
    strippedHtml = strippedHtml?.substring(0, 30).concat(' ...');
    setBodyP(strippedHtml);
  }, [body, _id]);

  return (
    <>
      {!stepLoading && (
        <div
          className="step-card-footer py-2"
          style={{
            backgroundColor: `${
              _id === sequencePreview?._id ? '#DBE2F4' : 'transparent'
            } `,
          }}
        >
          <div
            className={`${_id === sequencePreview?._id ? 'active-circle' : ''}`}
          />
          <div id="stepBodyQuill">
            <h5>
              {!sequenceStepsLengthCheck
                ? 'Click here to edit the email template'
                : !_id && sequenceStepsLengthCheck
                ? `RE: ${subject}`
                : !subject
                ? `RE: ${latestSubject}`
                : sequenceStepsLengthCheck
                ? subject
                : 'Click here to edit the email template'}
            </h5>
            <span className="stepBodyQuill__text">{bodyp}</span>
          </div>
        </div>
      )}
    </>
  );
}
