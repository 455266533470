import CreateRecruiter from "./CreateRecruiter";

export default function CreatePlacementStepThree({
  recruiters,
  recruiterListChange,
  recruiterList,
  errors,
  defaultPercentage,
  defaultCandidateOwner,
  readonlyStepThree,
}) {
  return (
    <div>
      <CreateRecruiter
        readonlyStepThree={readonlyStepThree}
        defaultPercentage={defaultPercentage}
        defaultCandidateOwner={defaultCandidateOwner}
        recruiterList={recruiterList}
        recruiterListChange={recruiterListChange}
        recruiters={recruiters}
        errors={errors}
      />
    </div>
  );
}
