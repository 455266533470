import { gql } from '@apollo/client';

export const GET_ALL_JOB_LIST = gql`
query FetchAllJobList(
  $page: Int!
  $limit: Int!
  $columnFilters: JobListColumnFilter
  ) {
  fetchAllJobList(
    payload: { page: $page, limit: $limit }
    columnFilters:$columnFilters
    ) {
    jobOrders {
      id
      postToLinkedin
      jobApplicants{ JobAppStatus }
      jobTitle
      status
      createdAt
      positions
      allowRemote
      estimatedFee
      feeType
      isHotJob
      showSalary
      newApplications
      totalApplications
      workplaceTypes
      advertisedJobDescription
      recruitingAssigned{
      id
      user{
        fullName
      }
    }
      category {
        id
        name
      }
      advertisedLocations {
        id
        postedAt
      }
      workType
      rateType
      salaryMin
      salaryMax
      recruitingLevel
      updatedAt
      companyLocation {
        streetAddress
        state
        city
        country
        zipCode
      }
      mainContact {
        id
        contact {
          id
          fullName
        }
      }
    jobListNotes{
      id
      urgencyNote
      pipelineNote
    }
      company {
        id
        name
        owner {
          id
          fullName
        }
        companyOwner {
          owner {
            id
            fullName
          }
        }
      }
    }
    count
  }
}
`;

export const GET_JOB_LIST_FOR_EDIT = gql`
query fetchJobListforEdit($page: Int! $limit: Int! $companyId:String ) {
  fetchAllJobList( payload: { page: $page, limit: $limit } 
    companyId:$companyId
  ) {
    jobOrders {
      id
      jobTitle
      salaryMax
      salaryMin
      advertisedJobDescription
      company{
        id
      	address{
          id
        	streetAddress
        	state
       		city
        	country
          zipCode
      	}
      }
      companyLocation {
        id
        streetAddress
        state
        city
        country
        zipCode
      }
    	jobListNotes{
        id
        urgencyNote
        pipelineNote
      }
    }
    count
  }
}`
export const UPDATE_JOB_LIST = gql`
mutation updateJobList($jobListUpdateInput: UpdateJobListInput!) {
  updateJobList(updateJobListInput:$jobListUpdateInput) {
   message 
   status
  }
}
`