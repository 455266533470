import LINKEDIN_IMG from "../assets/images/social1.svg";
import FACEBOOK_IMG from "../assets/images/social2.svg";
import TWITTER_IMG from "../assets/images/social3.svg";
import INSTAGRAM_IMG from "../assets/images/social4.png";
import { instagramHandlerToLink } from "../utils/helper";

export default function SocialMediaIcons({
  facebook,
  instagram,
  linkedin,
  twitter,
}) {
  return (
    <div className="d-flex socialMedia mb-2 contentGridLabel">
      <label>Social</label>

      <div className="contentGrid">
        <div className="d-flex">
          {linkedin && (
            <a href={linkedin} rel="noreferrer" target="_blank">
              <img src={LINKEDIN_IMG} alt="logo" width="16px" />
            </a>
          )}

          {twitter && (
            <a href={twitter} rel="noreferrer" target="_blank" className="ml-2">
              <img src={TWITTER_IMG} alt="logo" width="16px" />
            </a>
          )}

          {facebook && (
            <a
              href={facebook}
              rel="noreferrer"
              target="_blank"
              className="ml-2"
            >
              <img src={FACEBOOK_IMG} alt="logo" width="10px" />
            </a>
          )}

          {instagram && (
            <a
              href={instagramHandlerToLink(instagram)}
              rel="noreferrer"
              target="_blank"
              className="ml-2"
            >
              <img src={INSTAGRAM_IMG} alt="logo" width="16px" />
            </a>
          )}
        </div>
      </div>
    </div>
  );
}
