import { useMutation } from "@apollo/client";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Col, Form, Row } from "reactstrap";

import CustomButton from "../../components/layout/CustomButton";
import InputField from "../../components/layout/InputField";
import { UPDATE_USER_PASSWORD } from "./gql";

import "./style.scss";

const UpdatePasswordComponent = () => {
  const [updatePassword, { data, error, loading }] =
    useMutation(UPDATE_USER_PASSWORD);
  const { register, handleSubmit, errors, watch, reset } = useForm();
  const passwordUpdate = async (value: {
    oldPassword: string;
    newPassword: string;
    confirmPassword: string;
  }) => {
    try {
      await updatePassword({
        variables: {
          oldPassword: value.oldPassword,
          newPassword: value.newPassword,
        },
      });
      reset();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (error?.graphQLErrors?.length) {
      toast.error(error?.graphQLErrors[0]?.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error?.graphQLErrors?.length]);

  useEffect(() => {
    if (data?.updateUserPassword?.message) {
      toast.success(data?.updateUserPassword?.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.updateUserPassword?.message]);

  return (
    <>
      <div>
        <Form onSubmit={handleSubmit(passwordUpdate)}>
          <Row>
            <Col lg="4" md="4" xs="12">
              <InputField
                label="Current Password"
                inputtype="password"
                placeholder="Current Password"
                inputid="oldPassword"
                isPassword
                isRequired
                inputRef={register({
                  required: {
                    value: true,
                    message: "Current password is required!",
                  },
                  minLength: {
                    value: 6,
                    message: "Current password length must be 6!",
                  },
                })}
                errMsg={errors?.oldPassword?.message}
              />
            </Col>
            <Col lg="4" md="4" xs="12">
              <InputField
                label="New Password"
                inputtype="password"
                placeholder="New Password"
                inputid="newPassword"
                isRequired
                isPassword
                inputRef={register({
                  required: {
                    value: true,
                    message: "Password is required!",
                  },
                  minLength: {
                    value: 6,
                    message: "Password length must be 6!",
                  },
                })}
                errMsg={errors?.newPassword?.message}
              />
            </Col>
            <Col lg="4" md="4" xs="12">
              <InputField
                label="Confirm Password"
                inputtype="password"
                isRequired
                placeholder="Confirm Password"
                inputid="confirmPassword"
                isPassword
                inputRef={register({
                  validate: (value) =>
                    value === watch("newPassword") ||
                    "Password or confirm password didn't match!",
                })}
                errMsg={errors?.confirmPassword?.message}
              />
            </Col>
          </Row>

          <div className="form-actions pt-4">
            <CustomButton
              buttonColor="primary"
              buttonText="Update Password"
              className="big-width"
              disabled={loading}
              loading={loading}
            />
          </div>
        </Form>
      </div>
    </>
  );
};

export default UpdatePasswordComponent;
