import { gql } from '@apollo/client';

export const GET_SKILL_SYNONYMS = gql`
  query GetSkillSynonyms ($payload: SynonymsQueryInput!) {
    getSkillSynonyms (payload: $payload) {
      synonyms {
        id
        name
        value
      }
      count
    }
  }
`;

export const GET_JOB_TITLE_SYNONYMS = gql`
  query GetJobTitleSynonyms ($payload: SynonymsQueryInput!) {
    getJobTitleSynonyms (payload: $payload) {
      synonyms {
        id
        name
        value
      }
      count
    }
  }
`;

export const ADD_JOB_TITLE_SYNONYMS = gql`
  mutation AddJobTitleSynonyms($updateSynonymsInput: UpdateSynonymsInput!) {
    addJobTitleSynonyms(updateSynonymsInput: $updateSynonymsInput) {
      status
      message
    }
  }
`;


export const UPDATE_JOB_TITLE_SYNONYMS = gql`
  mutation UpdateJobTitleSynonyms($updateSynonymsInput: UpdateSynonymsInput!) {
    updateJobTitleSynonyms(updateSynonymsInput: $updateSynonymsInput) {
      status
      message
    }
  }
`;

export const UPDATE_SKILL_SYNONYMS = gql`
  mutation UpdateSkillSynonyms($updateSynonymsInput: UpdateSynonymsInput!) {
    addUpdateSkillSynonyms(updateSynonymsInput: $updateSynonymsInput) {
      status
      message
    }
  }
`;

export const DELETE_SYNONYMS = gql`
  mutation DeleteSynonyms($deleteSynonymsInput: DeleteSynonymsInput!) {
    deleteSynonyms(deleteSynonymsInput: $deleteSynonymsInput) {
      status
      message
    }
  }
  `;
