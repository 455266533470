import AsyncSelect from "react-select/async";
import { toast } from "react-toastify";
import { useContext } from "react";
import { AppContext } from "../../../context";
import client from "../../../apollo";
import { Col, Label } from "reactstrap";
import { FETCH_CONTACTS_AGAINST_COMPANY } from "../gql";
import { customSelectDropDown } from "../../../components/styled/customSelect";
let time = 0;

const AsyncContactSelect = (props) => {
  const { theme } = useContext(AppContext);
  const _filterContacts = async (inputValue: string) => {
    try {
      const { data } = await client.query({
        query: FETCH_CONTACTS_AGAINST_COMPANY,
        variables: {
          limit: 20,
          page: 1,
          searchText: inputValue,
          companyId: props?.companyId ?? "",
          statusSearch: "",
        },
      });

      return data && data?.allContacts?.contacts
        ? data.allContacts?.contacts.map((item) => ({
            value: item.id,
            label: `${
              item?.company?.name
                ? `${item.fullName}-${item?.company?.name}`
                : item.fullName
            }`,
          }))
        : [];
    } catch (error) {
      toast.error("Error while loading contacts!");
    }
  };
 

  const promiseOptions = (value: any): any =>
    new Promise((resolve) => {
      if (!value) {
        resolve(_filterContacts(value));
      } else window.clearTimeout(time);
      time = window.setTimeout(() => {
        resolve(_filterContacts(value));
      }, 1000);
    });

  return (
    <Col xl="4" md="6" sm="12">
      <Label>{props?.label}</Label>
      <AsyncSelect
        cacheOptions
        isDisabled={props?.isDisabled || false}
        value={props?.value}
        // defaultValue={props?.defaultValue}
        isSearchable
        styles={{ ...customSelectDropDown(theme) }}
        isClearable
        ref={props.ref}
        defaultOptions
        onChange={(selected: any) => {
          if (props.onSelected) {
            if (selected) {
              props.onSelected(selected);
            } else {
              props.onSelected({});
            }
          }
          if (selected) {
            // setSelectValue(selected);
            props.onChange(selected);
          } else {
            props.onChange("");
          }
        }}
        loadOptions={promiseOptions}
        placeholder={props?.placeholder}
      />
    </Col>
  );
};

export default AsyncContactSelect;
