import { useMutation } from '@apollo/client';
import { Controller, useForm } from 'react-hook-form';
import ReactQuill from 'react-quill';
import { toast } from 'react-toastify';
import {
  Form,
  FormGroup,
  Label,
} from 'reactstrap';
import CustomButton from '../../../components/layout/CustomButton';
import { CREATE_ACTION, CREATE_NOTES } from '../../company/gql';
import { SUBMISSION_STATUS_CHANGE, UPDATE_INTERVIEW_STATUS } from './gql';

import { useHistory } from 'react-router-dom';
import {
  DrawerHeader,
  Sidedrawer,
} from '../../../components/layout/Sidedrawer';
import { reactQuillReg } from '../../../utils/constant';

export default function RejectNotes({
  modal,
  toggle,
  selectedStatus,
  refetch,
  applicantData,
  interviewId,
}) {
  const { control, errors, handleSubmit } = useForm();

  const [createActions] = useMutation(CREATE_ACTION);
  const [createNotes, { loading }] = useMutation(CREATE_NOTES);

  const [updateApplicantsStatusSubmission] = useMutation(
    SUBMISSION_STATUS_CHANGE
  );
  const [updateInterviewStausMutation] = useMutation(UPDATE_INTERVIEW_STATUS);
  const history = useHistory();

  const onCreateNote = async (values: any) => {
    const response = await createNotes({
      variables: {
        noteableType: 'Candidate',
        noteableId: applicantData?.candidate?.id,
        category: `Interview - ${selectedStatus?.label}`,
        content: values?.content,
      },
    });

    if (response?.data) {
      updateOfferCallback();
    }
  };

  const updateOfferCallback = async () => {
    let forJob = {
      actionableId: applicantData?.JobOrder?.id,
      actionableType: 'Job Order',
      content:
        selectedStatus?.label === 'Candidate Declined'
          ? `Candidate ${applicantData?.candidate?.fullName} declined on this job.`
          : `Client ${applicantData?.JobOrder?.company?.name} has declined on candidate ${applicantData?.candidate?.fullName}`,
    };

    let forCandidate = {
      actionableId: applicantData?.candidate?.id,
      actionableType: 'Candidate',
      content:
        selectedStatus?.label === 'Candidate Declined'
          ? `This candidate declined on ${applicantData?.JobOrder?.jobTitle} @ ${applicantData?.JobOrder?.company?.name}`
          : `Client ${applicantData?.JobOrder?.company?.name} (${applicantData?.JobOrder?.jobTitle}) has declined on this candidate.`,
    };

    // For Jobs
    const a = await createActions({
      variables: {
        ...forJob,
      },
    });

    // For Candidate
    const b = await createActions({
      variables: {
        ...forCandidate,
      },
    });

    // Updating the job applicant's status
    await updateApplicantsStatusSubmission({
      variables: {
        id: applicantData?.id,
        status:
          selectedStatus?.value === 'CANDIDATE_DECLINED'
            ? 'CANDIDATE_DECLINE'
            : 'CLIENT_DECLINE',
      },
    });

    // Updating the interview's status'
    await updateInterviewStausMutation({
      variables: {
        id: interviewId,
        candidateId: applicantData?.candidate?.id,
        status: selectedStatus?.value,
      },
    });

    if (a?.data || b.data) {
      toast.success(
        `${selectedStatus?.label} note added, interview status updated and action logs are created!`
      );
      toggle();
      history.push({
        pathname: `/candidate-pipeline`,
      });
    }
  };

  const customStyles = {
    control: (styles, provided) => ({
      ...styles,

      fontSize: 12,
    }),

    option: (provided, state) => ({
      ...provided,
      fontSize: 12,
    }),

    container: (provided, state) => ({
      ...provided,
      fontSize: 12,
    }),
  };

  return (
    <Sidedrawer toggle={toggle} isOpen={modal}>
      <div className="mb-3">
        <DrawerHeader
          prevClick={() => toggle()}
          heading="Rejection Notes"
          nextButtonView={false}
        />
      </div>
      <Form>
        <FormGroup>
          <Label>Reject Reason</Label>
          <Controller
            control={control}
            name="content"
            rules={{ required: 'Please add some content!' }}
            defaultValue={''}
            render={({ onChange, ref, value = '' }) => (
              <div className="editor-height">
                <ReactQuill
                  onChange={(value) => {
                    if (reactQuillReg.test(value)) {
                      onChange('');
                    } else {
                      onChange(value);
                    }
                  }}
                  value={value}
                  ref={ref}
                />
              </div>
            )}
            styles={customStyles}
          />
          <small className="text-danger pt-2">{errors?.content?.message}</small>
        </FormGroup>
        <div className="text-right">
          <CustomButton
            disabled={loading}
            buttonText="Reject"
            buttonColor={'primary'}
            loading={loading}
            buttonType="button"
            buttonClick={handleSubmit(onCreateNote)}
          />
        </div>
      </Form>
    </Sidedrawer>
  );
}
