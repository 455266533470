import { gql } from "@apollo/client";


//query for microsoft account login
export const DOCUSIGN_LOGIN = gql`
  mutation DocusignLogin($code: String!) {
    docusignToken(docusignCode:$code){
        message,
        status
      }
  }
`;