// packages block
import { FC, Key, useEffect } from 'react';
import { Card, CardBody } from 'reactstrap';
//components block
import Loader from '../../components/layout/Loader';
import InputField from '../../components/layout/InputField';
import NoDataFound from '../../components/layout/NoDataFound';
// constants, utils and graphql block
import { formatDateUS } from '../../utils/commonFn';
import { IWarningCard } from '../../interfaces';
import { Pagination } from '../../components/layout/Pagination';
import { PAGE_LIMIT } from '../../utils/constant';

const ReviewWarnings: FC<IWarningCard> = ({
  searchInput,
  notificationHandler,
  page,
  setPage,
  setLimit,
  limit,
  loading,
  data
}): JSX.Element => {

  const messageFormat = (name: string, expirationData: string | Date): string => {
    if (!(expirationData instanceof Date)) {
      return `Warning, ${name} would be expired on ${ formatDateUS(new Date(parseInt(expirationData, 10)))} - ${new Date(parseInt(expirationData, 10)).toLocaleTimeString()}`
    }
    return `$Warning, ${name} would be expired on ${expirationData} `;
  }

  useEffect(() => {
    if (searchInput) {
      setLimit(5);
      setPage(1);
      notificationHandler && notificationHandler();
    } else {
      notificationHandler && notificationHandler();
    }
  }, [searchInput, page, limit, notificationHandler, setLimit, setPage]);

  return (
    <>
      <div>
        <div>
          <Card className="border-0 mt-section-4 mb-0">
            {loading ? (
              <Loader />
            ) : (
              <CardBody className="p-0">
                <div className="user-basic-form">
                  {data?.getAllSystemAlerts?.systemAlerts?.map(
                    (
                      item: {
                        id: string;
                        name: string;
                        message: string;
                        expirationDate: string;
                        createdAt: string;
                      },
                      index: Key
                    ) => {
                      return (
                        <div className="notification-card" key={index}>
                          <div>
                            <span className='message'>
                              {messageFormat(item.name, item.expirationDate)}
                            </span>
                          </div>
                        </div>
                      );
                    }
                  )}
                  {!loading &&
                    !data?.getAllSystemAlerts?.systemAlerts?.length && (
                      <div className="for-position-multiselect">
                        <NoDataFound text="No Warning Found" />
                      </div>
                    )}
                </div>
              </CardBody>
            )}
          </Card>

          {!!data?.getAllSystemAlerts?.systemAlerts?.length && (
            <div className="users-pagination">
              <Pagination
                onPageChange={(pageClicked: number) => {
                  setLimit(limit);
                  setPage(pageClicked);
                }}
                pageCount={Math.ceil(
                  data?.getAllSystemAlerts?.count / limit
                )}
                currentPage={page}
              />

              <div className="d-flex align-items-center">
                <div className="pagination-display paginationItemsSelect">
                  <p className="m-0">
                    {(page - 1) * limit + 1} to {page * limit} of{' '}
                    {data?.getAllSystemAlerts?.count}
                  </p>

                  <div className="divider"></div>

                  <label>Display</label>

                  <InputField
                    label=""
                    inputtype="select"
                    selectItems={PAGE_LIMIT.filter(
                      (item) => item.value !== limit
                    )}
                    inputid="limit"
                    placeholder={limit.toString()}
                    inputMethod={(value: string) => {
                      setPage(1);
                      setLimit(parseInt(value));
                    }}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ReviewWarnings;
