import { useState } from 'react'
import { Tooltip } from 'reactstrap';
import VIEW_ICON from "../../assets/images/view-icon.svg";
import SHARE_ICON from "../../assets/images/share-icon.svg";
import DELETE_ICON from "../../assets/images/delete-icon-menu.svg";
import { VIEW_CANDIDATE_ROUTE } from '../../utils/constant';
import { pushInHistory } from '../../utils/helper';

const PrivateCandidateListingActions = ({
  history, location, item, setCandidateId,
  toggle, setConfirmToggle, confirmToggle }) => {
  const [tooltipOpen1, setTooltipOpen1] = useState(false);
  const toggle1 = () => setTooltipOpen1(!tooltipOpen1);

  const [tooltipOpen2, setTooltipOpen2] = useState(false);
  const toggle2 = () => setTooltipOpen2(!tooltipOpen2);

  const [tooltipOpen3, setTooltipOpen3] = useState(false);
  const toggle3 = () => setTooltipOpen3(!tooltipOpen3);
  return (
    <>
      <div
        onClick={() => {
          history.push({
            pathname: `${VIEW_CANDIDATE_ROUTE}/${item?.id}`,
            state: {
              searchParameters: location.search,
              from: "private-candidates",
            },
          });

          pushInHistory("private-candidates", location.search)
        }}
      >
        <Tooltip
          placement="top"
          isOpen={tooltipOpen1}
          target={`viewPrivateCandidate-${item.id}`}
          toggle={toggle1}
        >
          View
        </Tooltip>
        <img
          src={VIEW_ICON}
          alt="view"
          id={`viewPrivateCandidate-${item.id}`}
        />
      </div>
      <div
        onClick={() => {
          setCandidateId(item?.id);
          toggle();
        }}
        className="mx-3"
      >
        <Tooltip
          placement="top"
          isOpen={tooltipOpen2}
          target={`sharePrivateCandidate-${item.id}`}
          toggle={toggle2}
        >
          Share
        </Tooltip>
        <img
          src={SHARE_ICON}
          alt="share"
          width="25px"
          id={`sharePrivateCandidate-${item.id}`}
        />
      </div>

      <div
        onClick={() => {
          setCandidateId(item?.id);
          setConfirmToggle(!confirmToggle);
        }}
      >
        <Tooltip
          placement="top"
          isOpen={tooltipOpen3}
          target={`discardPrivateCandidate-${item.id}`}
          toggle={toggle3}
        >
          Discard
        </Tooltip>
        <img
          src={DELETE_ICON}
          alt="discard"
          width="19px"
          id={`discardPrivateCandidate-${item.id}`}
        />
      </div>
    </>
  )
}

export default PrivateCandidateListingActions
