import CandidateCalendarInviteBasicInfo from "./CandidateCalendarInviteBasicInfo";
import CandidateCalendarInviteDetailedInfo from "./CandidateCalendarInviteDetailedInfo";

export default function CandidateClendarInviteCombined({
  register,
  errors,
  control,
  calendarPrefilledValues,
  candidateInterviewEmailData,
  setPipeSelectedFiles,
  reset,
  fromEdit,
  candidateName,
  fromInterviewDetailCard,
  candidateId,
  onAdditionalFileAttachmentsHandler,
  additionalFileAttached,
  setAdditionalFileAttachments,
  watch,
  setValue,
}) {
  return (
    <div>
      <CandidateCalendarInviteBasicInfo
        fromInterviewDetailCard={fromInterviewDetailCard}
        calendarPrefilledValues={calendarPrefilledValues}
        candidateInterviewEmailData={candidateInterviewEmailData}
        register={register}
        reset={reset}
        errors={errors}
        control={control}
        fromEdit={fromEdit}
        watch={watch}
        setValue={setValue}
      />

      <CandidateCalendarInviteDetailedInfo
        setAdditionalFileAttachments={setAdditionalFileAttachments}
        onAdditionalFileAttachmentsHandler={onAdditionalFileAttachmentsHandler}
        candidateId={candidateId}
        candidateName={candidateName}
        fromEdit={fromEdit}
        candidateInterviewEmailData={candidateInterviewEmailData}
        setPipeSelectedFiles={setPipeSelectedFiles}
        additionalFileAttached={additionalFileAttached}
      />
    </div>
  );
}
