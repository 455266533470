// packages block
import {
  Button, Col, Form, Input, Label, Modal, ModalBody, ModalFooter,
  ModalHeader, Row,
} from "reactstrap";
// component block
import CustomButton from "../../../../components/layout/CustomButton";
// utils block
import { CUSTOM, DIRECT_HIRE } from "../../../../utils/constant";
import { bodyDefaultData } from "../../../../utils/helper";

export default function ExecutiveAcceptModalEscalation({
  modal,
  toggle,
  executiveAcceptProtocolLoading,
  confirmAction,
  onClosed,
  getValues,
  companyName,
  companyContactName,
  agreementType,
  register,
  errors,
  setValue,
}) {
  const type = agreementType === DIRECT_HIRE ? "Direct Hire" : "Temp";
  let agreementName = agreementType === CUSTOM ? "Agreement" : `${type} Agreement`;

  const ExecutiveApprovePreTemplate = () => (
    <Form>
      <Row>
        <Col md="12" sm="12">
          <Label>Subject</Label>
          <Input
            type="text"
            defaultValue={
              getValues()?.subject
                ? getValues()?.subject
                : `${agreementName} - ${companyName} & RealREPP`
            }
            name="subject"
            onChange={(e) => {
              setValue("subject", e.target.value);
            }}
            placeholder="Email Subject"
            innerRef={register({
              required: {
                value: false,
                message: "Email subject is required!",
              },
            })}
            errMsg={errors?.subject?.message}
          />
        </Col>

        <Col md="12" sm="12" className="mt-3">
          <Label>Body</Label>

          <Input
            type="textarea"
            className="email-body"
            name="body"
            onChange={(e) => {
              setValue("body", e.target.value);
            }}
            defaultValue={
              getValues()?.body ? getValues()?.body : bodyDefaultData(companyContactName, companyName, type)
            }
            placeholder="Email Body"
            innerRef={register({
              required: {
                value: false,
                message: "Email body is required!",
              },
            })}
            errMsg={errors?.body?.message}
          />
        </Col>
      </Row>
    </Form>
  );

  return (
    <Modal
      isOpen={modal}
      toggle={toggle}
      className="for-modal-styling"
      onClosed={onClosed}
      scrollable
    >
      <ModalHeader>Approve - Executive</ModalHeader>
      <ModalBody>
        <div className="approval-content">
          <p>
            Are you sure you want to send this agreement for client's signature?
          </p>
          <p>
            Approve the agreement will results in sending out for executive’s
            then client’s signature.
          </p>
        </div>

        <div>
          <ExecutiveApprovePreTemplate />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          onClick={onClosed}
          className="modal-close bg-transparent border-0 text-dark"
        >
          Cancel
        </Button>
        <CustomButton
          buttonType="button"
          buttonText={"Approve"}
          buttonColor={"success"}
          buttonClick={confirmAction}
          loading={executiveAcceptProtocolLoading}
        />
      </ModalFooter>
    </Modal>
  );
}
