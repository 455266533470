import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Loader from "../../components/layout/Loader";
import TableComponent from "../../components/layout/TableComponent";
import { compactObject } from "../../utils/commonFn";
import Pagination from "../../components/layout/Pagination";
import {
  Badge,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import InputField from "../../components/layout/InputField";
import { COMPANY_DETAILS_ROUTE, JobOrderStatusInApplicant, JOB_APPLICANTS_ROUTE, JOB_APPLICANT_WIDGETS_HEADER, PAGE_LIMIT, VIEW_JOB_APPLICANT_ROUTE, workplaceTypesDto } from "../../utils/constant";
import SEARCH_IMG from "../../assets/images/search.svg";
import "../company/company.scss";
import NoDataFound from "../../components/layout/NoDataFound";
import { GET_JOB_ORDER_APPLICANTS_LISTS } from "./gql";

import AsyncCompanyDropdown from "../../components/AsyncCompanySelect";
import { getJobApplicantsParams } from "../../utils/getNoteParams";
import VIEW_ICON from "../../assets/images/view-icon.svg";
import { pushInHistory } from "../../utils/helper";

let time = 0;

const JobApplicants = ({ location, history }: RouteComponentProps) => {
  const page = new URLSearchParams(location.search).get("currentPage") || "1";
  const _currentPage: number = parseInt(page);
  const [currentPage, setCurrentPage] = useState(_currentPage);
  const [limit, setLimit] = useState(10);
  const [searchParams, setSearchParams] = useState<string>("");
  const [statusFilter, setStatusFilter] = useState<string>("ACTIVE");
  const [filterCheck, setFilterCheck] = useState<boolean>(true);
  const [selectedCompanyId, setSelectedCompanyId] = useState<string>("");
  const [selectedCompanyName, setSelectedCompanyName] = useState<string>("");

  const [fetchJobOrderApplicants, { loading, error, data }] = useLazyQuery(
    GET_JOB_ORDER_APPLICANTS_LISTS
  );

  useEffect(() => {
    let params: any = getJobApplicantsParams(location.search);

    if (params) {
      setSelectedCompanyId(params.selectedCompanyId);
      setSelectedCompanyName(params.selectedCompanyName);
      setFilterCheck(params?.filterCheck === "true" ? true : false);
      setCurrentPage(params?.currentPage ? parseInt(params?.currentPage) : 1);
      setStatusFilter(params?.statusFilter);
      setSearchParams(params?.searchParams);
      setLimit(params?.limit ? parseInt(params?.limit) : 10);
    }

    // eslint-disable-next-line
  }, []);

  const fetchJobApplicantsAgainstJobOrder = () => {
    setQueryParams(searchParams);

    if (!searchParams || searchParams?.length > 2) {
      fetchJobOrderApplicants({
        variables: {
          limit,
          page: currentPage,
          searchText: searchParams,
          statusSearch: statusFilter === 'default' ? '' : statusFilter,
          companyId: selectedCompanyId,
          myJobOrder: filterCheck,
        },
      });
    }
  };

  const setQueryParams = (searchParams: any) => {
    const criteria = compactObject({
      currentPage,
      searchParams,
      limit,
      selectedCompanyId,
      selectedCompanyName,
      filterCheck,
      statusFilter,
    });
    const urlQueryParams: any = [];
    if (Object.keys(criteria).length) {
      for (const key in criteria) {
        if (
          Object.prototype.hasOwnProperty.call(criteria, key) &&
          criteria[key]
        ) {
          urlQueryParams.push(`${key}=${criteria[key]}`);
        }
      }
      history.push(`${JOB_APPLICANTS_ROUTE}?${urlQueryParams.join("&")}`);
    }
  };

  const searchControl = (value: any) => {
    setQueryParams(value);
    setSearchParams(value);
    setCurrentPage(1);
    setLimit(25);
    if (!value || value?.length > 2) {
      window.clearTimeout(time);
      time = window.setTimeout(() => {
        fetchJobOrderApplicants({
          variables: {
            limit,
            page: currentPage,
            searchText: value,
            statusSearch: statusFilter === 'default' ? '' : statusFilter,
            companyId: selectedCompanyId,
            myJobOrder: filterCheck,
          },
        });
      }, 1000);
    }
  };

  useEffect(() => {
    if (data?.fetchJobOrderApplicants?.jobOrders?.length) {
      data?.fetchJobOrderApplicants?.jobOrders
        ?.filter((item) => item?.isHotJob === true && `${item.id}`)
        .map((val) => val?.id);
    }
    document.title = "JobApplicants - RealREPP";

    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    fetchJobApplicantsAgainstJobOrder();

    // eslint-disable-next-line
  }, [statusFilter, currentPage, limit, selectedCompanyId, filterCheck]);

  return (
    <div className="admin-tabs team-tabs">
      <ToastContainer autoClose={3000} />

      <div className="primaryHeading">
        <h5>Job Applicants</h5>
      </div>

      <div className="search-filter primaryHeaderSpacing">
        <div>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <img src={SEARCH_IMG} alt="search" />
              </InputGroupText>
            </InputGroupAddon>

            <Input
              type="text"
              placeholder="Search by job title"
              value={searchParams}
              onChange={(e) => {
                setSearchParams(e.target.value);
                searchControl(e.target.value);
              }}
              className="search-by-company"
            />
          </InputGroup>

          <small className="text-info">
            {searchParams &&
              searchParams.length <= 2 &&
              " Please add more than two characters"}
          </small>
        </div>

        <div className="filter-status">
          <div className="mr-4">
            <AsyncCompanyDropdown
              width={"15rem"}
              value={{
                label: selectedCompanyName,
                value: selectedCompanyId,
              }}
              placeholder="Filter By Company name"
              onChange={(value) => {
                setQueryParams(searchParams);
                setSelectedCompanyId(value?.value);
                setSelectedCompanyName(value?.label);
                setCurrentPage(1);
                setLimit(25);
              }}
            />
          </div>

          <div className="filter-by ">
            <InputField
              inputtype="select"
              selectItems={JobOrderStatusInApplicant}
              inputid="status"
              placeholder="Status"
              inputMethod={(value) => {
                setQueryParams(searchParams);
                setStatusFilter(value);
                setCurrentPage(1);
                setLimit(25);
              }}
              inputValue={statusFilter}
            />

            <button
              style={{
                backgroundColor: `${filterCheck ? "#f4f3f3" : "white"}`,
                color: `${filterCheck ? "#eaa827" : "#495057"}`,
                border: `${"1px solid #ced4da"}`,
              }}
              className="filter-job-order ml-3"
              type="button"
              onClick={() => {
                setQueryParams(searchParams);
                setFilterCheck(!filterCheck);
                setCurrentPage(1);
              }}
            >
              My Job Orders
            </button>

            <button
              className="buttonGenericStyle filledButton"
              onClick={() => {
                setFilterCheck(false);
                setSearchParams("");
                setSelectedCompanyId("");
                setSelectedCompanyName("");
                setStatusFilter("");
                fetchJobOrderApplicants({
                  variables: {
                    limit,
                    page: currentPage,
                    searchText: "",
                    statusSearch: "",
                    myJobOrder: false,
                    companyId: selectedCompanyId,
                  },
                });
              }}
            >
              Clear
            </button>
          </div>
        </div>
      </div>

      <div className="table-responsive">
        <TableComponent tableHeader={JOB_APPLICANT_WIDGETS_HEADER}>
          {loading && (
            <tbody>
              <tr>
                <td colSpan={14}>
                  <Loader loaderClass="sm-height " />
                </td>
              </tr>
            </tbody>
          )}

          {!loading && !data?.fetchJobOrderApplicants?.jobOrders.length && (
            <tbody>
              <tr>
                <td colSpan={8} className="empty-table-td">
                  <NoDataFound text="No Applicant Found!" />
                </td>
              </tr>
            </tbody>
          )}

          <tbody>
            {!loading &&
              data &&
              data?.fetchJobOrderApplicants &&
              data?.fetchJobOrderApplicants?.jobOrders.map(
                (item: any, index: number) => {
                  return (
                    <tr key={index} className="job-orders-tbody">
                      <td
                        className="route-link"
                        onClick={() => {
                          history.push(`${VIEW_JOB_APPLICANT_ROUTE}/${item?.id}`);
                        }}
                      >
                        {item.jobTitle}
                      </td>
                      <td className="route-link text-truncate max-200"
                        onClick={() => {
                          history.push({
                            pathname: `${COMPANY_DETAILS_ROUTE}/${item?.company?.id}`,
                            state: {
                              backUrl: JOB_APPLICANTS_ROUTE
                            }
                          });

                          pushInHistory(JOB_APPLICANTS_ROUTE, location.search)
                        }}>{item?.company?.name || "--"}</td>
                      <td>{item?.company?.owner?.fullName}</td>
                    
                      <td>
                        {
                        ['US',null,undefined,''].includes(item?.companyLocation?.country) ?item?.companyLocation?.city? item?.companyLocation?.city + `, ` + item?.companyLocation?.state:item?.companyLocation?.state:item?.companyLocation?.city? item?.companyLocation?.city + `, ` + item?.companyLocation?.country:item?.companyLocation?.country  
                        } 
                        <div className="subtitleText">
                        {item?.workplaceTypes?workplaceTypesDto[item.workplaceTypes]: '--'}
                        </div>
                      </td>
                      <td>{item?.workType || "--"}</td>
                      <td>
                        <div className="d-flex align-items-center">
                          {item?.totalApplications}{" "}
                          <div className="dividerDot"></div>{" "}
                          <div className="newApplicationText">
                            {item?.newApplications || "--"} New
                          </div>
                        </div>
                      </td>

                      <td>
                        <Badge
                          color={
                            item?.status === "DRAFT"
                              ? "primary"
                              : item?.status === "LOST_TO_COMPETITOR" ||
                                item?.status === "HOLD" ||
                                item?.status === "CLOSE"
                                ? "warning"
                                : item?.status === "CANCELLED"
                                  ? "danger"
                                  : item?.status === "FILLED_BY_US" ||
                                    item?.status === "FILLED_BY_CLIENT" ||
                                    item?.status === "ACTIVE"
                                    ? "success"
                                    : "info"
                          }
                          className="text-capitalize"
                        >
                          {item?.status}
                        </Badge>
                      </td>
                      <td
                        onClick={() => {
                          history.push(`${VIEW_JOB_APPLICANT_ROUTE}/${item?.id}`);
                        }}
                      >
                        <div style={{ width: 50 }}>
                          <Link
                            onClick={ ()=> pushInHistory(JOB_APPLICANTS_ROUTE, location.search)}
                            to={{
                              pathname: `${VIEW_JOB_APPLICANT_ROUTE}/${item?.id}`,
                              state: { searchParameters: location.search },
                            }}
                          >
                            <img src={VIEW_ICON} alt="view" width="21px" />
                          </Link>
                        </div>
                      </td>
                    </tr>
                  );
                }
              )}
          </tbody>
        </TableComponent>
      </div>
      
      {data?.fetchJobOrderApplicants?.jobOrders?.length ? (
        <div className="users-pagination">
          <Pagination
            onPageChange={(pageClicked: number) => {
              setQueryParams(searchParams);
              setCurrentPage(pageClicked);
            }}
            pageCount={Math.ceil(data?.fetchJobOrderApplicants?.count / limit)}
            currentPage={currentPage}
          />

          <div className="d-flex align-items-center">
            <div className="pagination-display paginationItemsSelect">
              <p className="m-0">
                {(currentPage - 1) * limit + 1} to{" "}
                {Math.min(
                  currentPage * limit,
                  data?.fetchJobOrderApplicants?.count
                )}{" "}
                of {data?.fetchJobOrderApplicants?.count}
              </p>

              <div className="divider"></div>

              <label>Display</label>

              <InputField
                label=""
                inputtype="select"
                selectItems={PAGE_LIMIT.filter((item) => item.value !== limit)}
                inputid="limit"
                placeholder={limit.toString()}
                inputMethod={(value) => {
                  setQueryParams(searchParams);
                  setCurrentPage(1);
                  setLimit(parseInt(value));
                }}
              />
            </div>
          </div>
        </div>
      ) : null}
      {error?.graphQLErrors[0]?.message}
    </div>
  );
};

export default JobApplicants;
