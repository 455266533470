import React from "react";
import { Col, Row } from "reactstrap";
import { URL_REGEX } from "../utils/constant";
import InputField from "./layout/InputField";

const EmailSignatureInput = ({
  register,
  errors,
  setDetail,
  signatureDetail,
}) => {
  return (
    <Row>
      <Col lg="4" md="6" sm="12" xs="12">
        <InputField
          label="Company Url"
          isRequired={true}
          inputtype="text"
          placeholder="Company Url"
          defaultValue={signatureDetail?.companyUrl}
          inputid="companyUrl"
          inputRef={register({
            required: {
              value: {
                value: true,
                message: "CompanyUrl is required!",
              },
            },
            pattern: {
              value: URL_REGEX,
              message: "Company URL is not valid!",
            },
          })}
          inputMethod={(value) =>
            setDetail({ ...signatureDetail, companyUrl: value })
          }
          errMsg={errors?.companyUrl?.message}
        />
      </Col>
      <Col lg="4" md="6" sm="12" xs="12">
        <InputField
          inputtype="text"
          label="Facebook"
          inputid="fbUrl"
          placeholder="Facebook URL"
          inputRef={register({
            required: { value: false },
            pattern: {
              value: URL_REGEX,
              message: "Facebook URL is not valid!",
            },
          })}
          inputMethod={(value) =>
            setDetail({ ...signatureDetail, fbUrl: value })
          }
          errMsg={errors?.fbUrl?.message}
        />
      </Col>
      <Col lg="4" md="6" sm="12" xs="12">
        <InputField
          inputtype="text"
          label="Instagram"
          inputid="instaUrl"
          placeholder="@instagram"
          inputRef={register({
            required: { value: false },
          })}
          inputMethod={(value) =>
            setDetail({ ...signatureDetail, instaUrl: value })
          }
          errMsg={errors?.instaUrl?.message}
        />
      </Col>
      <Col lg="4" md="6" sm="12" xs="12">
        <InputField
          inputtype="text"
          label="Twitter"
          inputid="twitterUrl"
          placeholder="@twitter"
          inputRef={register({
            required: { value: false },
          })}
          inputMethod={(value) =>
            setDetail({ ...signatureDetail, twitterUrl: value })
          }
          errMsg={errors?.twitterUrl?.message}
        />
      </Col>
      <Col lg="4" md="6" sm="12" xs="12">
        <InputField
          inputtype="text"
          label="YouTube"
          inputid="youtubeUrl"
          placeholder="YouTube Url"
          inputRef={register({
            required: { value: false },
            pattern: {
              value: URL_REGEX,
              message: "YouTube URL is not valid!",
            },
          })}
          inputMethod={(value) =>
            setDetail({ ...signatureDetail, youtubeUrl: value })
          }
          errMsg={errors?.youtubeUrl?.message}
        />
      </Col>
    </Row>
  );
};

export default EmailSignatureInput;
