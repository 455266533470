import { Controller } from 'react-hook-form';
import { useContext, useEffect } from 'react';
import ReactQuill from 'react-quill';
import { Col, Input, Label, Row } from 'reactstrap';
import InputField from '../../../../../components/layout/InputField';
import moment from 'moment';
import {
  candidateInviteTemplates,
  reactQuillReg,
} from '../../../../../utils/constant';
import Select from 'react-select';
import { webInterviewTemplateFunc } from './constants-candidate-invite';
import { customSelectStyleNew } from '../../../../../components/styled/customSelect';
import { AppContext } from '../../../../../context';

export default function CandidateCalendarInviteBasicInfo({
  candidateInterviewEmailData,
  register,
  errors,
  control,
  fromEdit,
  calendarPrefilledValues,
  fromInterviewDetailCard,
  reset,
  watch,
  setValue,
}) {
  // useEffect(() => {
  //   if (candidateInterviewEmailData || calendarPrefilledValues) {
  //     const values: any = {};
  //     if (fromEdit && candidateInterviewEmailData) {
  //       values.subject = candidateInterviewEmailData?.subject;
  //       values.inviteTime = moment(
  //         candidateInterviewEmailData?.interviewEmailDateTime
  //       )
  //         .utcOffset(candidateInterviewEmailData?.timeZoneId)
  //         .format("YYYY-MM-DDTHH:mm");
  //
  //     } else if (!fromInterviewDetailCard && !fromEdit) {
  //       values.inviteTime = moment(calendarPrefilledValues?.interviewDateTime)
  //         .utcOffset(calendarPrefilledValues?.timeZoneId)
  //         .format("YYYY-MM-DDTHH:mm");
  //
  //     } else {
  //       const now = new Date();
  //       const offset = now.getTimezoneOffset() * 60000;
  //       const adjustedDate = new Date(now.getTime() - offset);

  //       const inputDate = moment(adjustedDate).format("YYYY-MM-DDT12:00");

  //       values.inviteTime = inputDate;
  //
  //     }

  //     reset({ ...values });
  //   }

  //   // eslint-disable-next-line
  // }, []);

  // useEffect(() => {
  //   if (candidateInterviewEmailData || calendarPrefilledValues) {
  //
  //     const values: any = {};
  //     values.subject = candidateInterviewEmailData?.subject;
  //     values.inviteTime = moment(
  //       candidateInterviewEmailData?.interviewEmailDateTime ||
  //         calendarPrefilledValues?.interviewDateTime
  //     )
  //       // .utcOffset(
  //       //   candidateInterviewEmailData?.timeZoneId ||
  //       //     calendarPrefilledValues?.timeZoneId
  //       // )
  //       .format("YYYY-MM-DDTHH:mm");
  //     reset({ ...values });
  //   }

  //   // eslint-disable-next-line
  // }, []);

  const { theme } = useContext(AppContext);

  useEffect(() => {
    if (candidateInterviewEmailData || calendarPrefilledValues) {
      const { interviewDateTime, time } = calendarPrefilledValues;
      const date = moment(`${interviewDateTime} ${time}`).format(
        'YYYY-MM-DDTHH:mm'
      );
      const values: any = {};
      values.subject = candidateInterviewEmailData?.subject;

      values.inviteTime =fromEdit? moment(candidateInterviewEmailData?.interviewEmailDateTime)
      .utcOffset(
        calendarPrefilledValues?.timeZoneId ||
          candidateInterviewEmailData?.timeZoneId
      )
     .format('YYYY-MM-DDTHH:mm')
  : date;
 
      // values.inviteTime = candidateInterviewEmailData?.interviewEmailDateTime
      //   ? moment(candidateInterviewEmailData?.interviewEmailDateTime)
      //       .utcOffset(
      //         calendarPrefilledValues?.timeZoneId ||
      //           candidateInterviewEmailData?.timeZoneId
      //       )
      //       .format('YYYY-MM-DDTHH:mm')
      //   : date;

      reset({ ...values });
    }

  }, [candidateInterviewEmailData, calendarPrefilledValues, fromEdit, reset]);
  const customStyles = {
    control: (styles, provided) => ({
      ...styles,

      fontSize: 12,
    }),

    option: (provided, state) => ({
      ...provided,
      fontSize: 12,
    }),

    container: (provided, state) => ({
      ...provided,
      fontSize: 12,
    }),
  };

  const onTemplateHandler = (value: string) => {
    switch (value) {
      case 'WEB_INTERVIEW_TEMPLATE':
        const webInterviewTemplate = webInterviewTemplateFunc();
        setValue('details', webInterviewTemplate);
        break;
    }
  };

  return (
    <div>
      <Row className="mt-2">
        <Col
          lg="6"
          md="6"
          sm="12"
          xs="12"
          className="custom-form-input form-input"
        >
          <Label>
            Subject<span className="text-danger">*</span>
          </Label>
          <Input
            name="subject"
            defaultValue={`${!fromEdit ? 'Candidate Invite' : ''}`}
            innerRef={register({
              required: {
                value: true,
                message: 'Subject is required!',
              },
            })}
          />
          <small className="text-danger">{errors?.subject?.message}</small>
        </Col>

        <Col
          lg="6"
          md="6"
          sm="12"
          xs="12"
          className="custom-form-input form-input"
        >
          <Label>
            Interview Date/Time<span className="text-danger">*</span>
          </Label>
          <Input
            disabled={true}
            type="datetime-local"
            name="inviteTime"
            min={new Date().toISOString().substring(0, 19)}
            innerRef={register({
              required: {
                value: true,
                message: 'Invite Date Time is required!',
              },
            })}
          />
          <small className="text-danger">{errors?.inviteTime?.message}</small>
        </Col>
      </Row>

      <Row className="mt-3">
        <Col
          lg="6"
          md="6"
          sm="12"
          xs="12"
          className="custom-form-input form-input"
        >
          <InputField
            disabled={true}
            inputtype="text"
            required={true}
            label="Interview Timezone"
            inputid="timeZone"
            defaultValue={
              fromEdit
                ? candidateInterviewEmailData?.timeZone
                : calendarPrefilledValues?.timeZone
            }
            placeholder="Timezone"
            inputRef={register({
              required: { value: true, message: 'Timezone is required' },
            })}
            errMsg={errors?.timeZone?.message}
          />
        </Col>

        <Col
          lg="6"
          md="6"
          sm="12"
          xs="12"
          className="custom-form-input form-input"
        >
          <InputField
            inputtype="text"
            isRequired
            label="Location"
            inputid="location"
            defaultValue={
              fromEdit
                ? candidateInterviewEmailData?.location
                : calendarPrefilledValues?.location
            }
            placeholder="location"
            inputRef={register({
              required: { value: true, message: 'Location is required' },
            })}
            errMsg={errors?.location?.message}
          />
        </Col>

        <Col
          lg="12"
          md="12"
          sm="12"
          xs="12"
          className="custom-form-input form-input"
        >
          <Label>Invite Template</Label>

          <Controller
            control={control}
            rules={{
              required: {
                value: false,
                message: 'Invite template must be selected!',
              },
            }}
            name="candidateInviteTemplate"
            options={candidateInviteTemplates?.map((item) => {
              return {
                label: item.label,
                value: item.value,
              };
            })}
            render={({ onChange, value }) => (
              <Select
                value={value}
                options={candidateInviteTemplates}
                isSearchable={false}
                placeholder="Invite Template"
                onChange={(target) => {
                  onChange(target);
                  onTemplateHandler(target?.value);
                }}
                styles={{ ...customSelectStyleNew(theme) }}
              />
            )}
            styles={customStyles}
          />
          <small className="text-danger">
            {errors?.candidateInviteTemplate?.message}
          </small>
        </Col>

        <Col lg="12" className="mt-2">
          <Label>
            Details <span className="text-danger">*</span>
          </Label>
          <Controller
            control={control}
            name="details"
            rules={{
              required: {
                value: true,
                message: 'Add some detail content!',
              },
            }}
            defaultValue={
              fromEdit
                ? candidateInterviewEmailData?.body || null
                : calendarPrefilledValues?.details || null
            }
            render={({ onChange, ref, value }) => (
              <div className="editor-height w-100">
                <ReactQuill
                  onChange={(value) => {
                    if (reactQuillReg.test(value)) {
                      onChange(null);
                    } else {
                      onChange(value);
                    }
                  }}
                  ref={ref}
                  value={value}
                />
                <small className="text-danger pt-2">
                  {errors?.details?.message}
                </small>
              </div>
            )}
            styles={customStyles}
          />
        </Col>
      </Row>
    </div>
  );
}
