import { FC } from "react";
import { Button, Spinner } from "reactstrap";

import "./style.scss";

type CustomButton = {
  buttonClick?: () => void;
  buttonText?: string;
  buttonColor?: string;
  styling?: object;
  disabled?: boolean;
  buttonType?: "submit" | "button" | "reset" | undefined;
  className?: string;
  loading?: boolean;
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
const CustomButton: FC<CustomButton> = ({
  buttonText,
  buttonClick,
  buttonColor,
  disabled,
  buttonType,
  className,
  styling,
  loading,
}) => {
  return (
    <Button
      style={styling}
      onClick={buttonClick}
      color={buttonColor || "primary"}
      disabled={disabled}
      type={buttonType || "submit"}
      className={className}
    >
      {loading && (
        <Spinner size="sm" style={{ color: "#eaa827" }} className="mr-2" />
      )}
      {buttonText}
    </Button>
  );
};

export default CustomButton;
