import { useContext, useEffect, useState } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import SequenceBasic from './SequenceBasic';
import { GET_SEQUENCE } from '../gql';
import { useLazyQuery } from '@apollo/client';
import SequenceContacts from '../../contacts/SequenceContacts';
import StepContainer from '../../steps/StepContainer';
import ContactStepPreview from '../../steps/ContactStepPreview';
import { AppContext } from '../../../../context';
import { useHistory } from 'react-router-dom';
import '../sequence.scss';
import { CONTACT_PERSONALIZED_STEPS } from '../../steps/gql';

const SequenceTabs = ({ match }) => {
  const { setContextSequence } = useContext(AppContext);

  const history = useHistory();
  const [emailContactStep, setEmailContactStep] = useState<boolean>(false);
  const [emailTemp, setEmailTemp] = useState<{
    step: Number;
    stepId: string;
  }>();
  const [contactPersonalizedSteps, { data: dataContactPersonalizedSteps }] =
    useLazyQuery(CONTACT_PERSONALIZED_STEPS);

  const [getSequence, { loading, data: sequenceData }] =
    useLazyQuery(GET_SEQUENCE);
  let {
    location: { state },
  }: any = history || {};
  const [activeTab, setActiveTab] = useState(
    state && state?.search ? state?.search : '1'
  );

  const [compaignId, setCompanyId] = useState<string>('');
  const [stepIndexNumber, setstepIndexNumber] = useState();

  const [personzailedStepID, setPersonzailedStepID] = useState<string>('');

  const [contactIdForTab, setContactIdForTab] = useState<string>();

  const toggle = (tab: string) => {
    if (activeTab !== tab) {
      setActiveTab(tab);

      if (tab !== '3') {
        const obj = {
          _id: '',
          subject: '',
          body: '',
          preview: false,
          allowOptions: false,
          firstStep: false,
        };
        setContextSequence(obj);
      } else {
  
          const obj = {
            _id: sequenceData?.getSequence?.steps[0]?.id,
            subject: sequenceData?.getSequence?.steps[0]?.subject,
            body: sequenceData?.getSequence?.steps[0]?.body,
            preview: true,
            allowOptions: false,
            firstStep: true,
          };
          setContextSequence(obj);       
      }
    }
  };

  const fetchSequenceData = async () => {
    getSequence({ variables: { compaignId } });
  };

  useEffect(() => {
    const { id } = match?.params;
    setCompanyId(id);

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    
    if(!!compaignId && !!contactIdForTab){
      let contactId=contactIdForTab
      contactPersonalizedSteps({ variables: { compaignId, contactId } });  
}
    // eslint-disable-next-line
  }, [compaignId, contactIdForTab]);

  useEffect(()=>{
    if(!!dataContactPersonalizedSteps?.contactPersonalizedSteps){
      setEmailTemp({
        step: 0,
        stepId: dataContactPersonalizedSteps?.contactPersonalizedSteps[0]?._step,
      });
    }
  },[dataContactPersonalizedSteps?.contactPersonalizedSteps])

  useEffect(() => {
    fetchSequenceData();

    // eslint-disable-next-line
  }, [compaignId]);

  useEffect(() => {
    const { id } = match?.params;
    history.push(
      `/sequences/${id}?tab=${
        activeTab === '1' ? 'contacts' : activeTab === '2' ? 'steps' : 'preview'
      }`,
      {
        search: activeTab,
      }
    );
    // eslint-disable-next-line
  }, [activeTab]);

  return (
    <div>
      <SequenceBasic
        compaignId={compaignId}
        loading={loading}
        sequence={sequenceData?.getSequence}
        reFetch={() => getSequence({ variables: { compaignId } })}
      />

      <div className="CandidateTabsWrapper">
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '1' })}
              onClick={() => {
                toggle('1');
              }}
            >
              Contacts
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '2' })}
              onClick={() => {
                toggle('2');
              }}
            >
              Steps
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '3' })}
              onClick={() => {
                toggle('3');
              }}
            >
              Preview
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent activeTab={activeTab}>
          <TabPane tabId="1" className="tab-pane-padding">
            <SequenceContacts compaignId={compaignId} />
          </TabPane>

          <TabPane tabId="2" className="tab-pane-padding">
            <StepContainer
              tabId={activeTab}
              stepSubject={sequenceData?.getSequence?.stepSubject}
              compaignId={compaignId}
              refetchSteps={() => getSequence({ variables: { compaignId } })}
              sequenceSteps={sequenceData?.getSequence?.steps}
              loading={loading}
              otherSteps={sequenceData?.getSequence?.other_steps}
              emailContactStep={emailContactStep}
              setEmailContactStep={setEmailContactStep}
              setEmailTemp={setEmailTemp}
              emailTemp={emailTemp}
              setPersonzailedStepID={setPersonzailedStepID}
              personzailedStepID={personzailedStepID}
              setstepIndexNumber={setstepIndexNumber}
              stepIndexNumber={stepIndexNumber}
              setContactIdForTab={setContactIdForTab}
              contactIdForTab={contactIdForTab}


            />
          </TabPane>

          <TabPane tabId="3" className="tab-pane-padding">
            <ContactStepPreview
              tabId={activeTab}
              stepSubject={sequenceData?.getSequence?.stepSubject}
              compaignId={compaignId}
              refetchSteps={() => getSequence({ variables: { compaignId } })}
              sequenceSteps={sequenceData?.getSequence?.steps}
              loading={loading}
              emailContactStep={emailContactStep}
              setEmailContactStep={setEmailContactStep}
              setEmailTemp={setEmailTemp}
              emailTemp={emailTemp}
              setContactIdForTab={setContactIdForTab}
              contactIdForTab={contactIdForTab}
              setPersonzailedStepID={setPersonzailedStepID}
              personzailedStepID={personzailedStepID}
              setstepIndexNumber={setstepIndexNumber}
              stepIndexNumber={stepIndexNumber}
            
            />
          </TabPane>
        </TabContent>
      </div>
    </div>
  );
};

export default SequenceTabs;
