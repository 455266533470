import { useEffect } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import InputField from "../../../components/layout/InputField";
import { countries, COUNTRY_STATES_PROVINCE } from "../../../utils/constant";

const AdvertiseLocationInfo = ({ register, errors, jobOrderId, watch, setValue }: any) => {
  const { country } = watch();
  useEffect(() => {
    if (!['US', null, undefined, ''].includes(country)) {
      setValue('state', undefined)
    }
  }, [country, setValue])
  useEffect(() => {
    setValue('country', 'US')

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <Card className='border-0'>
      <CardBody className=''>
        <div className='user-basic-form'>
          <div className=''>
            {jobOrderId ? <h5>New Address</h5> : null}

            <Row>
              <Col>
                <InputField
                  inputtype='text'
                  label="City"
                  isRequired
                  inputid='city'
                  placeholder='City'
                  inputRef={register({
                    required: {
                      value: jobOrderId ? true : false,
                      message: "City is required!",
                    },
                  })}
                  errMsg={errors?.city?.message}
                />
              </Col>
              <Col>
                <InputField
                  inputtype='select'
                  disable={!['US', 'CA', null, undefined, ''].includes(country)}
                  selectItems={COUNTRY_STATES_PROVINCE(country)}
                  label={country === 'CA' ? 'Province' : 'State'}
                  isRequired={['US', 'CA', null, undefined, ''].includes(country)}
                  inputid='state'
                  placeholder='Please select any state'
                  inputRef={register({
                    required: {
                      value: ['US', 'CA', null, undefined, ''].includes(country),
                      message: "State is required!",
                    },
                  })}
                  errMsg={errors?.state?.message}
                />
              </Col>
              <Col>
                <InputField
                  label="Country"
                  inputtype="select"
                  selectItems={countries}
                  isRequired
                  inputid="country"
                  placeholder="Please select any state"
                  inputRef={register({
                    required: {
                      value: jobOrderId ? true : false,
                      message: "Coountry is required!",
                    },
                  })}
                  errMsg={errors?.country?.message}
                />
              </Col>
            </Row>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default AdvertiseLocationInfo;
