import { useMutation } from "@apollo/client";
import { useState } from "react";
import { toast } from "react-toastify";
import GoBack from "../../../../components/GoBack";
import CustomButton from "../../../../components/layout/CustomButton";
import { LAUNCH_PAUSE_SEQUENCE } from "../gql";
import { useForm } from "react-hook-form";
import { IActivateSequenceInput, ISequence } from "../interfaceSequence";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  Spinner,
} from "reactstrap";

const SequenceBasic = ({
  compaignId,
  loading,
  sequence,
  reFetch,
}: ISequence) => {
  const [scheduleFor, setScheduleFor] = useState<string>("");
  const { handleSubmit, errors, register, reset } = useForm();
  const [modalToggle, setModalToggle] = useState<boolean>(false);

  const [
    launchPauseSequence,
    { loading: loadingLaunchPauseSequence, error: errorLaunchPauseSequence },
  ] = useMutation(LAUNCH_PAUSE_SEQUENCE);

  const toggleSequenceLaunchPause = () => {
    setScheduleFor("");
    setModalToggle(!modalToggle);
  };

  const onChangeScheduleFor = (event) => {
    setScheduleFor(event?.target.value);
  };

  const activateSequence = async (values: IActivateSequenceInput) => {
    const resultOfRequest = await launchPauseSequence({
      variables: {
        launchPauseSequenceInput: {
          active: true,
          compaignId,
          paused_until: values.paused_until,
        },
      },
    });

    if (errorLaunchPauseSequence) {
      setModalToggle(false);
      reset();
      toast.error(
        "Launching a campaign requries that you have at least one step and that all messages have a subject line"
      );
    } else if (resultOfRequest?.data) {
      setModalToggle(false);
      reset();
      setScheduleFor("");
      reFetch();
      if (!loading) {
        toast.success(
          `Sequence successfully ${!sequence?.active ? "launched" : "paused"}`
        );
      }
    }
  };

  const pauseSequence = async () => {
    const resultOfRequest = await launchPauseSequence({
      variables: {
        launchPauseSequenceInput: {
          active: false,
          compaignId,
        },
      },
    });

    if (errorLaunchPauseSequence) {
      setModalToggle(false);
      toast.error(
        "Launching a campaign requries that you have at least one step and that all messages have a subject line"
      );
    } else if (resultOfRequest?.data) {
      setModalToggle(false);
      setScheduleFor("");
      reFetch();
      if (!loading) {
        toast.success(
          `Sequence successfully ${!sequence?.active ? "launched" : "paused"}`
        );
      }
    }
  };

  return (
    <div className="admin-tabs team-tabs">
      <GoBack url="/sequences" data={sequence?.title} />

      <div className="primaryHeading primaryHeaderSpacing mobileResponsiveFlexAlign justify-content-between">
        {loading ? (
          <Spinner size="sm" style={{ color: "#eaa827" }} className="mr-2" />
        ) : (
          <h5 className="m-0">
            {sequence?.title}
          </h5>
        )}
        {loading ? (
          <Spinner size="sm" style={{ color: "#eaa827" }} className="mr-2" />
        ) : (
          <CustomButton
            buttonText={`${!sequence?.active ? "Launch" : "Pause"} Sequence`}
            buttonType="button"
            buttonColor="primary"
            disabled={loadingLaunchPauseSequence}
            className="save-changes"
            loading={loadingLaunchPauseSequence}
            buttonClick={() =>
              !sequence?.active ? setModalToggle(true) : pauseSequence()
            }
          />
        )}
      </div>

      <Modal isOpen={modalToggle} className="revampDialog">
        <ModalHeader>
          {!sequence?.active ? "Launch" : "Pause"} {sequence?.title}
        </ModalHeader>
        <ModalBody>
          <p className="launch-pause-paragraph">
            {!sequence?.active ? "Launch" : "Pause"} {sequence?.title} now or
            schedule a specifc time for launch
          </p>

          <div className="mt-2">
            <Row>
              <Col md={12} sm={12}>
                <div
                  onClick={onChangeScheduleFor}
                  className="d-flex w-100 labelLineHeight"
                >
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="radio"
                        name="schedule"
                        value={"LAUNCH_NOW"}
                        defaultChecked={true}
                      />
                      Launch Now
                    </Label>
                  </FormGroup>
                  <FormGroup check className="ml-3">
                    <Label check>
                      <Input
                        type="radio"
                        name="schedule"
                        onChange={({ target }) => setScheduleFor(target.value)}
                        value={"LAUNCH_LATER"}
                      />
                      Schedule For Later
                    </Label>
                  </FormGroup>
                </div>
              </Col>

              {scheduleFor === "LAUNCH_LATER" ? (
                <Col md={12} sm={12}>
                  <div className="mt-3">
                    <FormGroup>
                      <Label>
                        Launch Date
                        {scheduleFor === "LAUNCH_LATER" && (
                          <span className="text-danger">*</span>
                        )}
                      </Label>
                      <Input
                        min={new Date().toISOString().substring(0, 10)}
                        placeholder="Select Date"
                        name="paused_until"
                        type="datetime-local"
                        innerRef={register({
                          required: {
                            value: true,
                            message: "Schedule date must be selected",
                          },
                        })}
                      />
                      <small className="text-danger">
                        {errors?.paused_until?.message}
                      </small>
                    </FormGroup>
                  </div>
                </Col>
              ) : null}
            </Row>
          </div>
        </ModalBody>
        <ModalFooter className="mt-3">
          <button
            className="buttonPrimary mr-3"
            onClick={toggleSequenceLaunchPause}
            type="button"
          >
            Cancel
          </button>

          <CustomButton
            buttonText={`${!sequence?.active ? "Launch" : "Pause"} ${
              sequence?.title
            }`}
            buttonColor="primary"
            buttonType="submit"
            className="big-width"
            buttonClick={handleSubmit(activateSequence)}
            loading={loadingLaunchPauseSequence}
            disabled={loadingLaunchPauseSequence}
          />
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default SequenceBasic;
