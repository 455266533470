import { useLazyQuery, useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import {
  Badge,
  Button,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Spinner,
  Tooltip,
} from "reactstrap";
import SEARCH_IMG from "../../assets/images/search.svg";
import ConfirmPopUp from "../../components/ConfirmPopUp";
import GoBack from "../../components/GoBack";
import CustomButton from "../../components/layout/CustomButton";
import InputField from "../../components/layout/InputField";
import Loader from "../../components/layout/Loader";
import { useHistory, useLocation } from "react-router-dom";
import NoDataFound from "../../components/layout/NoDataFound";
import Pagination from "../../components/layout/Pagination";
import TableComponent from "../../components/layout/TableComponent";
import { compactObject } from "../../utils/commonFn";
import { PAGE_LIMIT, RECRUITER_FOLDERS_ROUTE, VIEW_CANDIDATE_ROUTE, VIEW_RECRUITER_FOLDER_HEADER, VIEW_RECRUITER_FOLDER_ROUTE } from "../../utils/constant";
import AddRecruiterFolderModal from "./folderComponents/AddRecruiterFolderModal";
import RecruiterCandidateSubmitModal from "./folderComponents/RecruiterCandidateSubmitModal";
import { GET_FOLDER_DETAIL, REMOVE_CANDIDATE_IN_FOLDER } from "./gql";
import { IComingState } from "../agreements/interfaces";
import SUBMIT_ICON from "../../assets/images/check-yellow.svg";
import APPLY_ICON from "../../assets/images/applying.png";
import DELETE_ICON from "../../assets/images/delete-icon-menu.svg";
import { pushInHistory } from "../../utils/helper";
import { ADD_TO_TJ_LOOKUP } from "../../constants";
import CandidateTJLookupModal from "../candidate/candidate-components/CandidateTJLookupModal";
import { CHECK_NON_SOLICIT_COMPANY } from "../company/gql";

const RecruiterFolderActions = ({
  item,
  recruiterDetailModelToggle,
  setOnCandidateViewDetails,
  createApplicantToggle,
  setFolderDetailIds,
  setConfirmPop,
  confirmPop,
  companyName
}) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  const [tooltipOpen1, setTooltipOpen1] = useState(false);
  const toggle1 = () => setTooltipOpen1(!tooltipOpen1);

  const [tooltipOpen2, setTooltipOpen2] = useState(false);
  const toggle2 = () => setTooltipOpen2(!tooltipOpen2);
  const [submitPopUp, setSubmitPopUp] = useState<boolean>(false);


  const [checkNonSolicitCompanyQuery, { data: nonSolicitWarning }] = useLazyQuery(CHECK_NON_SOLICIT_COMPANY);

  useEffect(() => {
    checkNonSolicitCompanyQuery({ variables: { input: companyName } })
    // eslint-disable-next-line 
  }, [])

  return (
    <div className="d-flex align-items-center actionIconsGridWidth">
      <Button
        className="bg-transparent border-0 color-default btn-sm p-0 iconButton"
        onClick={() => {
          recruiterDetailModelToggle();
          setOnCandidateViewDetails(item?.candidate?.id);
          // nonSolicitWarning?.checkNonSolicitCompany ? setSubmitPopUp(true) :
          //   createApplicantToggle() || setOnCandidateViewDetails(item?.candidate?.id)
        }}
      >
        {submitPopUp && (
          <ConfirmPopUp
            confirmText="You cannot submit a candidate from a client with non-solicit agreement."
            isOpen={submitPopUp}
            toggle={setSubmitPopUp}
            confirmAction={() => setSubmitPopUp(!submitPopUp)}
            modalHeading="Submit Action"
            btnText="Okay"
            cancelBtn={false}
            className="revampDialog revampDialogWidth"
          />)}

        <Tooltip
          placement="top"
          isOpen={tooltipOpen1}
          target={`submit-${item?.id}`}
          toggle={toggle1}
        >
          Submit
        </Tooltip>

        <img src={SUBMIT_ICON} alt="submit" id={`submit-${item?.id}`} />
      </Button>

      <Button
        className="bg-transparent border-0 color-default btn-sm p-0 iconButton"
        onClick={() => {
          nonSolicitWarning?.checkNonSolicitCompany ? setSubmitPopUp(true) :
            createApplicantToggle() || setOnCandidateViewDetails(item?.candidate?.id);
        }}
      >
        <Tooltip
          placement="top"
          isOpen={tooltipOpen2}
          target={`apply-${item?.id}`}
          toggle={toggle2}
        >
          Apply
        </Tooltip>

        <img
          src={APPLY_ICON}
          alt="apply"
          id={`apply-${item?.id}`}
          width="20"
          height="20"
        />
      </Button>

      <Button
        className="bg-transparent border-0 color-default btn-sm p-0 iconButton"
        onClick={() => {
          setFolderDetailIds([{ folderDetailId: item?.id }]);
          setConfirmPop(!confirmPop);
        }}
      >
        <Tooltip
          placement="top"
          isOpen={tooltipOpen}
          target={`delete-${item?.id}`}
          toggle={toggle}
        >
          Delete
        </Tooltip>

        <img src={DELETE_ICON} alt="delete" id={`delete-${item?.id}`} />
      </Button>
    </div>
  );
};

let time = 0;

const FolderDetail = ({ match }: any) => {
  const history = useHistory();
  const location = useLocation<IComingState>();

  const comingState = location.state;

  const page = new URLSearchParams(location.search).get("currentPage") || "1";
  const _currentPage: number = parseInt(page);
  const [currentPage, setCurrentPage] = useState(_currentPage);
  const [limit, setLimit] = useState(10);
  const [searchParams, setSearchParams] = useState<string>("");
  const [recruiterDetailModel, setRecruiterDetailModel] =
    useState<boolean>(false);
  const [createApplicant, setCreateApplicant] = useState<boolean>(false);
  const [folderId, setFolderId] = useState<string>("");
  const [query, setQuery] = useState<string>("");
  const [confirmPop, setConfirmPop] = useState(false);
  const [folderDetailIds, setFolderDetailIds] = useState<Array<any>>([]);

  const [copyToFolderModal, setCopyToFolderModal] = useState(false);
  const copyModalToggle = () => setCopyToFolderModal(!copyToFolderModal);

  const [moveToFolderModal, setMoveToFolderModal] = useState(false);
  const moveModalToggle = () => setMoveToFolderModal(!moveToFolderModal);

  const [onCandidateViewDetails, setOnCandidateViewDetails] =
    useState<string>();
  const [getFolderDetail, { loading, data, error }] =
    useLazyQuery(GET_FOLDER_DETAIL);
  const [removeCandidate] = useMutation(REMOVE_CANDIDATE_IN_FOLDER);
  const [TJLookupModal, setTJLookupModal] = useState<boolean>(false);
  const TJLookupModalToggle = () => setTJLookupModal(!TJLookupModal);
  const [selectedCandidates, setSelectedCandidates] = useState<any[]>([]);

  useEffect(() => {
    document.title = loading
      ? "Loading"
      : data?.fetchAllFolderDetails?.currentFolder?.folderName +
      " - Recruiter Folder";

    // eslint-disable-next-line
  }, [data]);

  const fetchFolderDetail = (id: string) => {
    // setQueryParams({ currentPage, searchParams });
    //this object is used for the search and pagination
    const folderDetailInput = {
      payload: { limit, page: currentPage },
      searchText: searchParams,
      folderId: id,
    };
    if (!searchParams || searchParams?.length > 2) {
      getFolderDetail({
        variables: { folderDetailInput },
      });
    }
  };

  const setQueryParams = ({ currentPage, searchParams }) => {
    const criteria = compactObject({
      currentPage,
      searchParams,
    });
    const urlQueryParams: any = [];
    if (Object.keys(criteria).length) {
      for (const key in criteria) {
        if (
          Object.prototype.hasOwnProperty.call(criteria, key) &&
          criteria[key]
        ) {
          urlQueryParams.push(`${key}=${criteria[key]}`);
        }
      }
      history.push(
        `${VIEW_RECRUITER_FOLDER_ROUTE}/${folderId}?${urlQueryParams.join("&")}`
      );
    }
  };

  const recruiterDetailModelToggle = () =>
    setRecruiterDetailModel(!recruiterDetailModel);

  const createApplicantToggle = () => {
    setCreateApplicant(!createApplicant);
  };

  const deleteCandidate = async () => {
    if (folderDetailIds?.length) {
      const folderIds = folderDetailIds?.map((item) => item?.folderDetailId);
      let res = await removeCandidate({
        variables: {
          removeCandidateInput: {
            folderDetailIds: [...folderIds],
            folderId,
          },
        },
      });
      if (res?.data) {
        toast.success("Deleted successfully!");
        setFolderDetailIds([]);
        getFolderDetail({
          variables: {
            folderDetailInput: {
              payload: { limit, page: currentPage },
              searchText: searchParams,
              folderId,
            },
          },
        });
        setConfirmPop(false);
      } else {
        toast.success("Problem while remove candidate!");
      }
    } else {
      toast.info("Must select the candidate!");
    }
  };

  const searchControl = (value: any) => {
    //this object is used for the search and pagination
    const folderDetailInput = {
      payload: { limit, page: currentPage },
      searchText: value,
      folderId,
    };
    setQueryParams(value);
    setSearchParams(value);
    setCurrentPage(1);
    setLimit(25);
    if (!value || value?.length > 2) {
      window.clearTimeout(time);
      time = window.setTimeout(() => {
        getFolderDetail({
          variables: {
            folderDetailInput,
          },
        });
        setSelectedCandidates([]);
        setFolderDetailIds([]);
      }, 1000);
    }
  };

  const handleCheckBoxes = (value: any) => {
    const currentIndex = folderDetailIds.findIndex(
      (val) => val?.folderDetailId === value.folderDetailId
    );
    const newChecked = [...folderDetailIds];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setFolderDetailIds(newChecked);
  };

  const manageCheckBoxes = (item) => {
    const { emails, fullName, id, company, jobTitle, phones } = item;
    const isSelected = selectedCandidates.some(
      (candidate) => candidate.id === id
    );
    if (isSelected) {
      // Remove candidate from selectedCandidates
      const updatedCandidates = selectedCandidates.filter(
        (candidate) => candidate.id !== id
      );
      setSelectedCandidates(updatedCandidates);
    } else {
      // Add candidate to selectedCandidates
      const updatedCandidates = [
        ...selectedCandidates,
        { id, emails, fullName, company, jobTitle, phones }
      ];
      setSelectedCandidates(updatedCandidates);
    }
  };

  useEffect(() => {
    let folderId = match.params.id;
    if (folderId) {
      setFolderId(folderId);
      fetchFolderDetail(folderId);
    }
    // eslint-disable-next-line
  }, [currentPage, limit]);

  return (
    <div className="admin-tabs team-tabs">
      <GoBack url={RECRUITER_FOLDERS_ROUTE} search={comingState?.searchParameters} />

      <ToastContainer autoClose={3000} />

      <div className="primaryHeading primaryHeaderSpacing mobileResponsiveFlexAlign justify-content-between">
        {loading ? (
          <div className="text-center mt-2">
            <Spinner size="sm" style={{ color: '#eaa827' }} />
          </div>
        ) : (
          <h5 className="m-0 d-flex">
            <div className="textTruncateRecruiter textTruncateWidthEllipsis">
              {data?.fetchAllFolderDetails?.currentFolder?.folderName + ' '}
            </div>
            {data?.fetchAllFolderDetails?.currentFolder ? (
              <span className="text-muted">- {'Recruiter Folder'}</span>
            ) : (
              ''
            )}
          </h5>
        )}

        <div className="search-filter">
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <img src={SEARCH_IMG} alt="search" />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              type="text"
              placeholder="Search by Candidate Name, Email"
              value={query}
              onChange={(e) => {
                setQuery(e.target.value);
                searchControl(e.target.value);
              }}
              className="search-by-company"
            />
          </InputGroup>

          <small className="text-info">
            {query &&
              query.length <= 2 &&
              'Please add more than two characters'}
          </small>
        </div>
      </div>

      <div>
        <div className="section-header m-0 p-0 pb-2">
          <div className="d-flex align-items-center">
            {folderDetailIds?.length >= 1 ? (
              <>
                <div className="contacts-actions">
                  <h5 className="m-0 mr-3">
                    {folderDetailIds?.length} Selected
                  </h5>

                  <CustomButton
                    buttonText="Delete"
                    buttonColor="primary"
                    // loading={activeTypeCheckLoading && updateSequenceLoading}
                    buttonType="button"
                    className=" gray-button mr-2 m-0"
                    styling={{ minWidth: 'auto' }}
                    buttonClick={() => setConfirmPop(!confirmPop)}
                  />

                  <CustomButton
                    // loading={deActiveTypeCheckLoading && updateSequenceLoading}
                    buttonText="Copy"
                    buttonColor="primary"
                    buttonType="button"
                    styling={{ minWidth: 'auto' }}
                    className="big-width gray-button mr-2 m-0"
                    buttonClick={() => copyModalToggle()}
                  />

                  <CustomButton
                    // loading={removeSequencesLoading}
                    buttonText="Move"
                    buttonColor="primary"
                    buttonType="button"
                    styling={{ minWidth: 'auto' }}
                    className=" gray-button mr-2 m-0"
                    buttonClick={() => moveModalToggle()}
                  />
                </div>

                {/* <CustomButton
                  buttonText={ADD_TO_TJ_LOOKUP}
                  buttonColor="primary"
                  buttonType="button"
                  className="py-2"
                  buttonClick={() => selectedCandidates?.length && setTJLookupModal(true)}
                /> */}
              </>
            ) : null}
          </div>
        </div>

        <div className="table-responsive">
          <TableComponent tableHeader={VIEW_RECRUITER_FOLDER_HEADER}>
            {loading && (
              <tbody>
                <tr>
                  <td colSpan={14}>
                    <Loader loaderClass="sm-height " />
                  </td>
                </tr>
              </tbody>
            )}

            {!loading &&
              !data?.fetchAllFolderDetails?.recruiterFolders?.length && (
                <tbody>
                  <tr>
                    <td colSpan={14} className="empty-table-td">
                      <NoDataFound text="This folder is empty" />
                    </td>
                  </tr>
                </tbody>
              )}

            <tbody>
              {data && data?.fetchAllFolderDetails?.recruiterFolders?.length
                ? data?.fetchAllFolderDetails?.recruiterFolders?.map(
                  (item: any, index: number) => {
                    return (
                      <tr key={index} className="job-orders-tbody">
                        <td className="d-flex cursor-pointer">
                          <Input
                            style={{
                              marginRight: 10,
                              marginTop: 0,
                              marginLeft: 0,
                              position: 'inherit',
                            }}
                            bsSize="sm"
                            type="checkbox"
                            checked={selectedCandidates.some(candidate => candidate.id === item?.candidate?.id)} // Set the checked state based on selectedCandidates
                            onChange={() => {
                              manageCheckBoxes(item?.candidate);
                              handleCheckBoxes({
                                folderDetailId: item?.id,
                                name: item?.candidate?.fullName,
                                employment:
                                  item?.candidate?.employmentHistory,
                                jobTitle: item?.candidate?.idealJobTitle,
                                candidateId: item?.candidate?.id,
                              })
                            }
                            }
                          />

                          <div
                            className="mt-2 cursor-pointer text-info"
                            onClick={() => {
                              history.push({
                                pathname: `${VIEW_CANDIDATE_ROUTE}/${item?.candidate?.id}`,
                                state: {
                                  backUrl: `${VIEW_RECRUITER_FOLDER_ROUTE}/${match.params.id}`,
                                  searchParameters: location.search,
                                },
                              });

                              pushInHistory(
                                `${VIEW_RECRUITER_FOLDER_ROUTE}/${match.params.id}`,
                                location.search
                              );
                            }}
                          >
                            {item?.candidate?.fullName}
                          </div>
                        </td>
                        <td>
                          {item?.candidate?.emails?.find(
                            (item) => item?.isPrimary
                          )?.email || '--'}
                        </td>
                        <td className="cand-status-column">
                          <Badge
                            color={
                              item?.candidate?.status === 'ACTIVE'
                                ? 'success'
                                : item.status === 'DO_NOT_CONTACT'
                                  ? 'danger'
                                  : ''
                            }
                            className="text-capitalize"
                          >
                            {item?.candidate?.status ?? '--'}
                          </Badge>
                        </td>
                        <td>
                          {item?.candidate?.recruiterOwner?.fullName || '--'}
                        </td>
                        <td>
                          {item?.candidate?.jobTitle ||
                            item?.candidate?.jobTitle?.employmentHistory?.find(
                              (val) => val?.mostRecentExperience
                            )?.jobTitle ||
                            (item?.candidate?.employmentHistory?.length &&
                              item?.candidate?.employmentHistory[0]
                                ?.jobTitle) ||
                            '--'}
                        </td>
                        <td>
                          {item?.candidate?.companyName ||
                            item.candidate?.recentEmployment?.companyName ||
                            '--'}
                        </td>
                        <td>
                          <RecruiterFolderActions
                            item={item}
                            recruiterDetailModelToggle={
                              recruiterDetailModelToggle
                            }
                            setOnCandidateViewDetails={
                              setOnCandidateViewDetails
                            }
                            companyName={item?.candidate?.companyName ||
                              item.candidate?.recentEmployment?.companyName}
                            createApplicantToggle={createApplicantToggle}
                            setFolderDetailIds={setFolderDetailIds}
                            setConfirmPop={setConfirmPop}
                            confirmPop={confirmPop}
                          />
                        </td>
                      </tr>
                    );
                  }
                )
                : ''}
            </tbody>
          </TableComponent>
        </div>
      </div>

      {data?.fetchAllFolderDetails?.recruiterFolders?.length ? (
        <div className="users-pagination">
          <Pagination
            onPageChange={(pageClicked: number) => {
              setCurrentPage(pageClicked);
              history.push(
                `${VIEW_RECRUITER_FOLDER_ROUTE}/${match.params.id}?currentPage=${pageClicked}`
              );
            }}
            pageCount={Math.ceil(data?.fetchAllFolderDetails?.count / limit)}
            currentPage={currentPage}
          />

          <div className="d-flex align-items-center">
            <div className="pagination-display paginationItemsSelect">
              <p className="m-0">
                {(currentPage - 1) * limit + 1} to{' '}
                {Math.min(
                  currentPage * limit,
                  data?.fetchAllFolderDetails?.count
                )}{' '}
                of {data?.fetchAllFolderDetails?.count}
              </p>

              <div className="divider"></div>

              <label>Display</label>

              <InputField
                label=""
                inputtype="select"
                selectItems={PAGE_LIMIT}
                inputid="limit"
                placeholder={limit !== 10 ? limit.toString() : 'Set Limit'}
                inputMethod={(value) => {
                  setCurrentPage(1);
                  setLimit(parseInt(value));
                }}
              />
            </div>
          </div>
        </div>
      ) : null}
      {error?.graphQLErrors[0]?.message}

      {copyToFolderModal && (
        <AddRecruiterFolderModal
          recordToAdd={folderDetailIds}
          isOpen={copyToFolderModal}
          refetch={() => searchControl('')}
          toggle={copyModalToggle}
          clearRecord={() => setFolderDetailIds([])}
          modalFor="Copy To Folder"
        />
      )}

      <RecruiterCandidateSubmitModal
        BackURL={`${VIEW_RECRUITER_FOLDER_ROUTE}/${folderId}`}
        toggle={recruiterDetailModelToggle}
        open={recruiterDetailModel}
        candidate={onCandidateViewDetails}
      />

      <RecruiterCandidateSubmitModal
        BackURL={`${VIEW_CANDIDATE_ROUTE}/${onCandidateViewDetails}`}
        toggle={createApplicantToggle}
        applyJob={true}
        open={createApplicant}
        candidate={onCandidateViewDetails}
      />

      {moveToFolderModal && (
        <AddRecruiterFolderModal
          recordToAdd={folderDetailIds}
          isOpen={moveToFolderModal}
          refetch={() => searchControl('')}
          toggle={moveModalToggle}
          clearRecord={() => setFolderDetailIds([])}
          preFolder={data?.fetchAllFolderDetails?.currentFolder?.id}
          modalFor="Move To Folder"
        />
      )}

      <ConfirmPopUp
        confirmText="Are you sure you want to remove candidate?"
        isOpen={confirmPop}
        toggle={() => {
          setConfirmPop(!confirmPop);
          setFolderDetailIds([]);
          setSelectedCandidates([]);
        }}
        confirmAction={deleteCandidate}
        modalHeading="Remove Candidate"
        btnText="Remove"
        btnColor="primary"
        className="for-modal-styling"
      />

      <CandidateTJLookupModal
        isOpen={TJLookupModal}
        toggle={TJLookupModalToggle}
        recordToAdd={selectedCandidates}
        title="Add to Recruiting Sequence"
      />
    </div>
  );
};

export default FolderDetail;
