import AsyncSelect from "react-select/async";
import { toast } from "react-toastify";
import { useContext } from "react";
import { AppContext } from "../../../context";
import client from "../../../apollo";
import { Label } from "reactstrap";
import { CANDIDATES_LISTING } from "../../candidate/gql";
import { customSelectDropDown } from "../../../components/styled/customSelect";
let time = 0;

const AsyncCandidateSelect = (props) => {
  const { theme } = useContext(AppContext);
  const _filterCandidate = async (inputValue: string) => {
    try {
      const { data } = await client.query({
        query: CANDIDATES_LISTING,
        variables: {
          limit: 20,
          page: 1,
          searchText: inputValue,
        },
      });

      return data && data?.allCandidates?.candidates
        ? data.allCandidates?.candidates.map((item) => {
            const primaryMail = item?.emails?.find(
              (emails: any) => emails.isPrimary === true
            );
            return {
              value: item.id,
              label: `${
                primaryMail && primaryMail?.email
                  ? `${item?.fullName}-${primaryMail?.email}`
                  : item?.fullName
              }`,
            };
          })
        : [];
    } catch (error) {
      toast.error("Error while loading candidates!");
    }
  };

  const promiseOptions = (value: any): any =>
    new Promise((resolve) => {
      if (!value) {
        resolve(_filterCandidate(value));
      } else window.clearTimeout(time);
      time = window.setTimeout(() => {
        resolve(_filterCandidate(value));
      }, 1000);
    });

  return (
    <>
      <Label>{props?.label}{props?.required && (
        <span className="text-danger" style={{fontSize:"12px"}}>*</span>
      )}</Label> 
      <AsyncSelect
        cacheOptions
        isDisabled={props?.isDisabled || false}
        value={props?.value}
        // defaultValue={props?.defaultValue}
        isSearchable
        styles={{ ...customSelectDropDown(theme) }}
        isClearable
        ref={props.ref}
        defaultOptions
        onChange={(selected: any) => {
          if (props.onSelected) {
            if (selected) {
              props.onSelected(selected);
            } else {
              props.onSelected({});
            }
          }
          if (selected) {
            // setSelectValue(selected);
            props.onChange(selected);
          } else {
            props.onChange("");
          }
        }}
        loadOptions={promiseOptions}
        placeholder={props?.placeholder}
      />
    </>
  );
};

export default AsyncCandidateSelect;
