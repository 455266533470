import { Link } from 'react-router-dom';
import INFO_ICON_5 from '../../../assets/images/email-icon.svg';
import Loader from '../../../components/layout/Loader';
import NoDataFound from '../../../components/layout/NoDataFound';
import TableComponent from '../../../components/layout/TableComponent';
import { CandidateExpiryReport } from '../../../interfaces';
import { formatDateUS } from '../../../utils/commonFn';
import { CANDIDATE_OWNERSHIP_REPORT_HEADER, VIEW_CANDIDATE_ROUTE } from '../../../utils/constant';

const Listing = ({ loading, data, note }) => {
  if (loading) {
    return <Loader loaderClass="sm-height" />;
  }
  return (
    <>
      <div className="table-responsive">
        <TableComponent tableHeader={CANDIDATE_OWNERSHIP_REPORT_HEADER}>
          {!loading && data?.companyExpiryReports?.length === 0 && (
            <tbody>
              <tr>
                <td colSpan={6} className="empty-table-td">
                  <NoDataFound text="No Data Found!" />
                </td>
              </tr>
            </tbody>
          )}

          <tbody>
            {!loading &&
              data?.length ?
              data?.map(({
                daysToExpiration, expirationDate,
                primaryEmail, primaryPhone, candidateId, recentCompany, recentEmployment, recruiter, name
              }: CandidateExpiryReport, index: number) => {
                return (
                  <tr key={index} className="job-orders-tbody">
                    <td className="route-link text-truncate max-200" >
                      <Link
                        target="_blank"
                        to={{
                          pathname: `${VIEW_CANDIDATE_ROUTE}/${candidateId}`,
                        }}
                      >
                        {name}
                      </Link>
                    </td>
                    <td>{recruiter}</td>
                    <td>{expirationDate ? formatDateUS(expirationDate) : '--'}</td>
                    <td>
                      <div className="oneLineClampText">
                        {recentEmployment || '--'}
                      </div>

                      <div className="subtitleText">
                        {recentCompany || '--'}
                      </div>
                    </td>
                    <td>
                      {primaryEmail !== '--' ? (
                        <a href={`mailto:${primaryEmail}`}>
                          {primaryEmail}
                        </a>
                      ) : (
                        primaryEmail
                      )}
                    </td>
                    <td>{primaryPhone || '--'}</td>
                    <td>{note}</td>
                    <td>
                      <button
                        className="iconButtonAction"
                        onClick={() => {
                          window.location.href = `mailto:${primaryEmail}`;
                        }}
                      >
                        <img src={INFO_ICON_5} alt="email" width="19px" />
                      </button>
                    </td>
                    <td>{daysToExpiration || '--'}</td>
                  </tr>
                );
              }) : ''}
          </tbody>
        </TableComponent>
      </div>
    </>
  );
}

export default Listing;