import { useContext, useEffect, useState } from "react";
import { Button, Form } from "reactstrap";
import { AppContext } from "../../../context";
import DeleteIcon from "../../../assets/images/delete-field.svg";
import Eclipse_Img from "../../../assets/images/text-ellipse.svg";
import "../candidate.scss";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  DELETE_CANDIDATE_RESUME,
  GET_CANDIDATE_RESUMES,
  GET_CANDIDATE_RESUMES_CONTENT,
  GET_CANDIDATE_SUMMARY,
  UPDATE_CANDIDATE,
  VERIFY_EMAIL,
} from "../gql";
import { checkUserFeatureAccess, downloadSignedUrlFile, formatDateUS } from "../../../utils/commonFn";
import NoDataFound from "../../../components/layout/NoDataFound";
import { useLocation, withRouter } from "react-router";
import { toast } from "react-toastify";
import ConfirmPopUp from "../../../components/ConfirmPopUp";
import ResumeUpload from "./ResumeUpload";
import { ROLES, VIEW_CANDIDATE_ROUTE } from "../../../utils/constant";
import { getTemplateByModule, pushInHistory, replacePlaceholderWithResume } from "../../../utils/helper";
import { IComingState } from "../../agreements/interfaces";
import ChatgptButton from "../../../common/chatGpt/ChatgptButton";
import ResumeDrawer from "../../../common/chatGpt/ResumeDrawer";
import useHTTPGPT from "../../../HTTP_Request/useHTTPGPT.js";
import { v4 as uuidv4 } from "uuid";
import Loader from "../../../components/layout/Loader";
import useHTTPGPTAXIOS from "../../../HTTP_Request/useHTTPGPTAXIOS";
import { saveAs } from 'file-saver'
import CustomButton from "../../../components/layout/CustomButton";
import ModalComponent from "../../../common/Modal";
import { CANCEL } from "../../../constants";
import { useForm } from "react-hook-form";
import { ALL_MODAL_ACCCESS_SETTINGS } from "../../moduleAccess-settings/gql";
import { DocumentCreator } from "../../../utils/resumeGenerator";
import { Packer } from "docx";
import { ALL_CHATGPT_TEMPLATES } from "../../chatGPT-templates/gql";

const CandidateResume = ({
  refetch,
  candidateId,
  uploadResume = true,
  history,
  necessaryCandidateInfo,
  candidateName
}: any) => {
  const { data: templateData } = useQuery(ALL_CHATGPT_TEMPLATES);
  const { data: settingsData } = useQuery(ALL_MODAL_ACCCESS_SETTINGS);
  const { userRoles, user, teachList } = useContext(AppContext);
  const { handleSubmit, reset, register, errors } = useForm();
  const location = useLocation<IComingState>()
  const [modal, setModal] = useState(false);
  const [loadingGPT, setLoadingGPT] = useState<boolean>(false);
  const [emailVerification, setEmailVerification] = useState<boolean>(false);
  const [resumeIndex, setResumeIndex] = useState<number>(0);
  const toggle = () => setModal(!modal);
  const [confirmPop, setConfirmPop] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [resume, setResume] = useState({ resumeId: "", name: "" });
  const [_removeResume, { loading }] = useMutation(DELETE_CANDIDATE_RESUME);
  const [_getResumes, { data }] = useLazyQuery(GET_CANDIDATE_RESUMES);
  const [getResumesContent, { data: resumeContent, loading: resumeContentLoading }] = useLazyQuery(GET_CANDIDATE_RESUMES_CONTENT);
  const [updateCandidate, { loading: updateCandidateLoading }] = useMutation(UPDATE_CANDIDATE)
  const [resumeDrawer, setResumeDrawer] = useState<boolean>(false);



  const handleResumeDrawer = async (index, id, call = false) => {
    if (call) {
      await getResumesContent({ variables: { candidateId, resumeId: id } });
    }
    setResumeIndex(index)
    setResumeDrawer(!resumeDrawer);
  }


  const updateCandidateInfo = async (id, index, call = false) => {
    await getResumesContent({
      variables: { candidateId, resumeId: id }, onCompleted(data) {
        submitChat(
          getTemplateByModule(templateData?.AllChatGptTemplates?.chatGPTtemplates, 'UPDATE_CANDIDATE_FROM_RESUME')
            ?
            `${replacePlaceholderWithResume(getTemplateByModule(templateData?.AllChatGptTemplates?.chatGPTtemplates, 'UPDATE_CANDIDATE_FROM_RESUME'), data?.getCandidateResumesContent?.textContent, "{{resume_content}}")}`
            :
            `Hey chatGPT , this is my resume content ${data?.getCandidateResumesContent?.textContent} Please extract phone number, email, job title and current company of the candidate and give response in the form of json. The keys of object should be email, phone, jobTitle, companyName If any of the field is missing please return "" instead of returning Undefined or any thing else`,
          false, true
        )
      },
    });
    setResumeIndex(index)
  }

  const checkForUpdateInfoFromResume = () => {
    return necessaryCandidateInfo?.jobTitle && necessaryCandidateInfo?.companyName && necessaryCandidateInfo?.phones?.length && necessaryCandidateInfo?.emails?.length
  }
  const getResumes = () => {
    _getResumes({ variables: { candidateId } });
  };

  const [getCandidateSummary, { data: dataSummary }] = useLazyQuery(
    GET_CANDIDATE_SUMMARY
  );
  const [verifyEmail, { data: verifyEmailData, loading: verifyEmailLoading }] = useMutation(VERIFY_EMAIL);


  const deleteCandidateResume = async () => {
    let res = await _removeResume({
      variables: { resumeId: resume?.resumeId },
    });
    if (res?.data) {
      toast.success("Resume Successfully Deleted!");
      setConfirmPop(false);
      getResumes();
    }
  };

  useEffect(() => {
    if (candidateId) {
      getResumes();
      getCandidateSummary({ variables: { id: candidateId } });
    }
    // eslint-disable-next-line
  }, [candidateId]);

  const onOriginalResumeHandler = async ({
    originalUrl,
    resumeFileName,
  }: {
    originalUrl: string;
    resumeFileName: string;
  }) => {
    downloadSignedUrlFile(originalUrl, resumeFileName);
  };

  const isUserCandidateOwnerOrAdminExec = () => {
    if (
      user?.id === dataSummary?.getCandidateSummary?.recruitingOwner?.id ||
      userRoles?.includes(ROLES.ADMIN) ||
      userRoles?.includes(ROLES.EXECUTIVE)
    ) {
      return false;

    }

    return true;
  };
  const gptAPI = useHTTPGPT();
  const { post, chunkMessage } = useHTTPGPTAXIOS();
  const [messages, setMessages] = useState<{ content: string, id: string, role: string, }[]>([]);

  useEffect(() => {
    if (candidateId) {
      const storedMessages = localStorage.getItem(`candidateResume: ${resumeIndex}-${candidateId}`);
      if (storedMessages?.length) {
        setMessages(JSON.parse(storedMessages));
      } else {
        setMessages([])
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resumeIndex]);

  useEffect(() => {
    if (messages?.length && candidateId) {
      localStorage.setItem(`candidateResume: ${resumeIndex}-${candidateId}`, JSON.stringify(messages));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages]);

  async function submitChat(content, fromResumeFormater = false, updateCandidateInfo = false) {
    try {
      const message = {
        id: uuidv4(),
        role: "user",
        content: content,
      };
      if (fromResumeFormater) {
        let newMessages = [message];
        await post(
          teachList.map((t) => ({ role: "system", content: t.content }))
            .concat(
              newMessages.slice(-8).map((message) => {
                return {
                  role: message.role,
                  content: message.content,
                };
              })
            )
        );
      } else {
        let newMessages = [...messages, message];
        setMessages(newMessages);
        updateCandidateInfo && setLoadingGPT(true)
        const response = await gptAPI.post(
          teachList.map((t) => ({ role: "system", content: t.content }))
            .concat(
              newMessages.slice(-8).map((message) => {
                return {
                  role: message.role,
                  content: message.content,
                };
              })
            )
        );
        if (response && updateCandidateInfo) {
          const { jobTitle, companyName, phone, email } = JSON.parse(response.content)
          if (email !== necessaryCandidateInfo?.emails?.find((item) => item?.isPrimary)?.email) {
            await verifyEmail({
              variables: { email },
            }).then((res) => {
              if (res?.data?.verifyEmailExist?.exist) {
                toast.error(res?.data?.verifyEmailExist?.message)
                return
              }
            })
          }
          await updateCandidate({
            variables: {
              UpdateCandidateDto: {
                updatesFromResume: true,
                candidateId,
                jobTitle: necessaryCandidateInfo?.jobTitle || jobTitle,
                companyName: necessaryCandidateInfo?.companyName || companyName,
                phones: necessaryCandidateInfo?.phones?.length ?
                  necessaryCandidateInfo?.phones?.map(({ phoneNumber, isPrimary, id, phoneType }) => {
                    return {
                      phone: phoneNumber,
                      isPrimary,
                      phoneId: id,
                      phoneType
                    }
                  }) : {
                    phone,
                    isPrimary: true,
                    phoneType: 'WORK'
                  },
                emails: necessaryCandidateInfo?.emails?.length ?
                  necessaryCandidateInfo?.emails?.map(({ email, isPrimary, id }) => {
                    return {
                      email,
                      isPrimary,
                      emailId: id
                    }
                  }) : {
                    email,
                    isPrimary: true
                  }
              }
            }
          })
          refetch()
          toast.success("Updated Successfully")
          setLoadingGPT(false)
        }
        newMessages = [...messages, message, response];
        setMessages(newMessages);
      }

    } catch {
      updateCandidateInfo && setLoadingGPT(false)
      toast.error("Something Went Wrong")
    }
  }
  const handleDownload = async (resumeJSON) => {
    try {
      let requestBody = null;
      if (typeof resumeJSON === 'string') {
        requestBody = JSON.parse(resumeJSON);
      } else {
        requestBody = resumeJSON;
      }
      const documentCreator = new DocumentCreator();
      const doc = documentCreator.create([requestBody]);

      Packer.toBlob(doc).then(blob => {
        saveAs(blob, `${candidateName}_Reformat.docx`);
        toast.success('Resume Downloaded successfully');
        reset()
      });
    } catch (error) {
      toast.error('Error while downloading file.Please Try Again');
      reset()
    }
  };


  useEffect(() => {
    if (loadingGPT) {
      handleDownload(chunkMessage);
      setLoadingGPT(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chunkMessage])

  const resumeFormatterModal = () => {
    setOpenModal(true);
  }

  const resumeFormatterDownload = async (values) => {
    setLoadingGPT(true);
    setOpenModal(false);
    await submitChat(
      `Giving the following resume: ${values?.resume} reformat this information into a JSON document with proper structure. JSON keys should be : name ,experience(array of object, and in object it should be like company,duration(should be string),position,responsibilities (it should be array)),technicalSkills (should be arrar),personalSkills (it should be array),achievements(should be in array of object,object like title and year  ),education (should be in array of object and in object details of eductaion, like degree,major,university,year and please make sure JSON should be correct, if you not found any of the entity in resume then just keep null but if array entity not found then should be empty array, dont insert dummy data)`,
      true
    );
  };

  const clearChat = () => {
    localStorage.removeItem(`candidateResume: ${resumeIndex}-${candidateId}`)
    setMessages([]);
  }
  if (resumeContentLoading || loadingGPT || updateCandidateLoading) {
    return <Loader />
  }
  return (
    <div>
      <div className="d-flex align-items-center justify-content-end resumeHeader gap-10">
        {uploadResume && (
          <CustomButton
            buttonClick={toggle}
            buttonType="button"
            buttonColor="primary"
            buttonText="Upload New"
          />
        )}
        {
          checkUserFeatureAccess(user?.id, 'RESUME_REFORMATTER', settingsData) && (
            <CustomButton
              buttonClick={resumeFormatterModal}
              buttonType="button"
              buttonColor="primary"
              buttonText="Reformat Resume"
              loading={loadingGPT}
              disabled={loadingGPT}
            />
          )
        }
      </div>

      <div className="resumeHolder">
        <div>
          {data?.getCandidateResumes?.map((item, index) => (
            <div
              className={`candidate-resume-grid d-flex align-items-center justify-between ${index === 0 && 'active'
                }`}
              key={index}
            >
              <div className="resume-details">
                <h6 className="d-flex align-items-center flex-wrap">
                  <span className="text-truncate max-300">
                    {item.resumeFileName}
                  </span>
                  {index === 0 ? (
                    <>
                      <img src={Eclipse_Img} alt="delete" />
                      <span>Recent</span>
                    </>
                  ) : null}
                </h6>
                <h5 className="m-0">
                  Uploaded By{' '}
                  {item?.createdAt ? formatDateUS(item.createdAt) : '--'}
                </h5>
              </div>

              <div className="d-flex align-items-center flex-wrap gap-10">
                {!!data?.getCandidateResumes?.length && (
                  <>
                    {checkUserFeatureAccess(user?.id, 'CHATGPT', settingsData) && (
                      <ChatgptButton
                        title="Actions"
                        menu={['Resume Summary', 'Update Candidate Info']}
                        onClick={(clickIndex) => {
                          if (clickIndex === 0) {
                            handleResumeDrawer(index, item?.id, true)
                          }
                          else if (!!checkForUpdateInfoFromResume()) {
                            toast.warning("Candidate Info Already Updated")
                          } else {
                            updateCandidateInfo(item?.id, index, true)
                          }
                        }}
                      />
                    )}
                  </>
                )}
                <Button
                  className="border-0 text-dark px-2 mx-2 resume-preview"
                  size="md"
                  style={{ minWidth: '120px' }}
                  onClick={() => {
                    history.push({
                      pathname: `/view/resume/${candidateId}`,
                      search: `resumeId=${item?.id}`,
                      state: {
                        backUrl: `${VIEW_CANDIDATE_ROUTE}/${candidateId}`,
                        searchParameters: location.search,
                      },
                    });

                    pushInHistory(
                      `${VIEW_CANDIDATE_ROUTE}/${candidateId}`,
                      location.search
                    );
                  }}
                >
                  Preview
                </Button>
                <Button
                  className="border-0 mx-2 bg-success px-2 text-white"
                  size="md"
                  style={{ minWidth: '120px' }}
                  onClick={() => onOriginalResumeHandler(item)}
                >
                  Download
                </Button>
                <Button
                  disabled={isUserCandidateOwnerOrAdminExec()}
                  className="bg-transparent border-0"
                  onClick={() => {
                    setResume({
                      resumeId: item?.id,
                      name: item?.resumeFileName,
                    });
                    setConfirmPop(!confirmPop);
                  }}
                >
                  <img
                    src={DeleteIcon}
                    alt=""
                    width="20"
                    height="20"
                    className="m-0"
                  />
                </Button>
              </div>
            </div>
          ))}

          {!data?.getCandidateResumes?.length && (
            <div className="empty-table-td empty-table-bg">
              <NoDataFound text="No Resume Found!" />
            </div>
          )}
        </div>
      </div>

      <ResumeUpload
        toggle={toggle}
        modal={modal}
        candidateId={candidateId}
        refetch={getResumes}
        fileType=".doc, .docx,.pdf"
        driveUpload={true}
      />

      <ConfirmPopUp
        confirmText="Are you sure you want to delete"
        extraProp={resume?.name}
        secondaryText="Resume?"
        isOpen={confirmPop}
        loading={loading}
        toggle={() => {
          setConfirmPop(!confirmPop);
        }}
        confirmAction={deleteCandidateResume}
        modalHeading="Delete Resume"
        btnText="Delete"
        btnColor="primary"
      />

      {resumeDrawer && (
        <ResumeDrawer
          toggle={handleResumeDrawer}
          isOpen={resumeDrawer}
          submitChat={submitChat}
          messages={messages}
          loading={gptAPI.loading}
          chunkMessage={gptAPI.chunkMessage}
          promptMessage={
            getTemplateByModule(templateData?.AllChatGptTemplates?.chatGPTtemplates, 'RESUME_SUMMARY')
              ?
              `${replacePlaceholderWithResume(getTemplateByModule(templateData?.AllChatGptTemplates?.chatGPTtemplates, 'RESUME_SUMMARY'), resumeContent?.getCandidateResumesContent?.textContent, "{{resume_summary}}")}`
              :
              `Please Act As A recruiter, And Provide Me Detailed Summary For This Candidate, Here is resume Details: ${resumeContent?.getCandidateResumesContent?.textContent}`
          }
          promptMessageForDisplay={`Provide Me Summary For This Candidate`}
          promptMessageForVerification={
            replacePlaceholderWithResume(getTemplateByModule(templateData?.AllChatGptTemplates?.chatGPTtemplates, 'RESUME_SUMMARY'), resumeContent?.getCandidateResumesContent?.textContent, "{{resume_summary}}") ?? 'Please Act As A recruiter, And Provide Me Detailed Summary For This Candidate, Here is resume Details:'
          }
          heading={'Resume Summary'}
          clearChat={clearChat}
        />
      )}

      <ModalComponent
        size="lg"
        isOpen={openModal}
        toggle={() => setOpenModal(false)}
      >
        <Form>
          <div className="p-3">
            <h5 className="mb-4">Reformat Resume</h5>

            <textarea
              ref={register({
                required: {
                  value: true,
                  message: 'Enter Resume Details',
                },
              })}
              className="form-control chat-textarea"
              style={{ minHeight: '300px', maxHeight: '600px' }}
              name="resume"
              placeholder={'Paste Resume here...'}
            />
            <small className="text-danger pt-2">
              {errors?.resume?.message}
            </small>
            <div className="d-flex align-items-center justify-content-end mt-4">
              <button
                type="button"
                className="buttonGenericStyle buttonPrimary"
                onClick={() => setOpenModal(false)}
              >
                {CANCEL}
              </button>
              <div className="px-2" />
              <CustomButton
                buttonText="Download Resume"
                buttonColor="primary"
                buttonType="submit"
                className="big-width"
                buttonClick={handleSubmit(resumeFormatterDownload)}
              />
            </div>
          </div>
        </Form>
      </ModalComponent>
    </div>
  );
};

export default withRouter(CandidateResume);

