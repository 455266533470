import { useState } from "react";

import NotesListing from "./NotesListing";
import AddNotesModal from "../../../components/AddNotesModal";
import { useLazyQuery } from "@apollo/client";
import { GET_NOTES } from "../../company/gql";

const PreviewDocumentNotes = ({ noteableId }: any) => {
  const [_getNotes] = useLazyQuery(GET_NOTES);

  const page = parseInt("1");
  const [currentPage] = useState(page);

  const [limit] = useState(3);

  return (
    <div>
      <AddNotesModal
        noteableId={noteableId}
        modalTitle="Client Notes"
        reFetch={() =>
          _getNotes({ variables: { id: noteableId, limit, page: currentPage } })
        }
        noteableType="Client"
        resetField={() => {}}
      />

      <NotesListing noteableId={noteableId} />
    </div>
  );
};

export default PreviewDocumentNotes;
