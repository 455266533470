import { useLazyQuery, useMutation } from "@apollo/client";
import { useContext, useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { toast } from "react-toastify";
import GoBack from "../../components/GoBack";
import CustomButton from "../../components/layout/CustomButton";
import Loader from "../../components/layout/Loader";
import TableComponent from "../../components/layout/TableComponent";
import { compactObject, formatDateUS } from "../../utils/commonFn";
import Pagination from "../../components/layout/Pagination";
import {
  Badge,
  Button,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter
} from "reactstrap";
import InputField from "../../components/layout/InputField";
import deleteIcon from "../../assets/images/delete-field.svg";

import { MergeCompanyType, PAGE_LIMIT } from "../../utils/constant";
import { Controller, useForm } from "react-hook-form";
import NoDataFound from "../../components/layout/NoDataFound";
import {
  CREATE_MERGE_CONTACT,
  GET_MERGE_CONTACTS,
  MERGE_CONTACT_STATUS_CHANGE,
} from "./gql";
import AsyncContactDropdown from "../../components/AsyncContactSelect";
import AsyncMergeContact from "../../components/AsyncMergeContact";
import ConfirmPopUp from "../../components/ConfirmPopUp";
import { DELETE_MERGE_CONTACT } from "./gql/index";
import { AppContext } from "../../context";

import CLOSE_ICON from "../../assets/images/close-icon.svg";
import AsyncCompanyDropdown from "../../components/AsyncCompanySelect";

const MERGE_CONTACT_HEADER = [
  "Source Contact Name",
  "Destination Contact Name",
  "Time Inserted",
  "Time Merged",
  "Status",
  "Note",
  "Action",
];

const MergeContacts = ({ location, history }: RouteComponentProps) => {
  const page = new URLSearchParams(location.search).get("currentPage") || "1";
  const _currentPage: number = parseInt(page);
  const [currentPage, setCurrentPage] = useState(_currentPage);
  const [limit, setLimit] = useState(10);
  const [searchParams, setSearchParams] = useState<string>("");
  const [statusFilter, setStatusFilter] = useState<string>("");
  const [selectedContactId, setSelectedContactId] = useState<string>("");
  const [modal, setModal] = useState(false);
  const toggle = () => {
    setModal(!modal);
    setCompanyId("");
  };
  const {
    errors,
    handleSubmit,
    register,
    watch,
    control,
    getValues,
    setValue,
  } = useForm();
  const [actionConfirm, setContactDeleteConfirm] = useState(false);
  const [actionId, setDeleteActionId] = useState("");
  const [mergeActionConfirm, setContactMergeConfirm] = useState(false);
  const [mergeActionId, setMergeActionId] = useState("");
  const [companyId, setCompanyId] = useState("");

  const { userRoles } = useContext(AppContext);

  const [fetchMergeContacts, { data, loading, error }] =
    useLazyQuery(GET_MERGE_CONTACTS);
  const [_deleteAction] = useMutation(DELETE_MERGE_CONTACT);

  const [_mergeAction] = useMutation(MERGE_CONTACT_STATUS_CHANGE);

  const [_createMergeContact, { loading: waiting, error: createError }] =
    useMutation(CREATE_MERGE_CONTACT);

  const _fetchMergeContacts = () => {
    try {
      setQueryParams();
      fetchMergeContacts({
        variables: {
          limit,
          page: currentPage,
          searchText: searchParams,
          statusSearch: statusFilter,
          contactId: selectedContactId,
        },
      });
    } catch (error) {}
  };

  const createMergeContact = async (values: any) => {
    values.destinationContactId = values?.destinationContactId?.value;
    values.sourceContactId = values?.sourceContactId?.value;

    let res = await _createMergeContact({ variables: { ...values } });
    if (res?.data) {
      toast.success("Request successfully created!");
      toggle();
      _fetchMergeContacts();
    }
    if (createError && createError?.graphQLErrors) {
      toast.error(createError?.graphQLErrors[0]?.message);
      toggle();
    }
  };

  const setQueryParams = () => {
    const criteria = compactObject({
      currentPage,
      searchParams,
      statusFilter,
    });
    const urlQueryParams: any = [];
    if (Object.keys(criteria).length) {
      for (const key in criteria) {
        if (
          Object.prototype.hasOwnProperty.call(criteria, key) &&
          criteria[key]
        ) {
          urlQueryParams.push(`${key}=${criteria[key]}`);
        }
      }
      history.push(`/merge-contacts?${urlQueryParams.join("&")}`);
    }
  };

  useEffect(() => {
    _fetchMergeContacts();
    // eslint-disable-next-line
  }, [statusFilter, selectedContactId, currentPage, limit]);

  const confirmAction = async () => {
    const response = await _deleteAction({
      variables: { mergeContactId: actionId },
    });
    if (response?.data) {
      toast.success("Successfully deleted!");
      setContactDeleteConfirm(false);
      _fetchMergeContacts();
    } else {
      setContactDeleteConfirm(false);
      // toast.error(mergeContactError?.message);
    }
  };

  const confirmMergeAction = async () => {
    const response = await _mergeAction({
      variables: { mergeContactId: mergeActionId },
    });
    if (response?.data) {
      toast.success("Successfully merged!");
      setContactMergeConfirm(false);
      _fetchMergeContacts();
    } else {
      setContactMergeConfirm(false);
      // toast.error(mergeContactError?.message);
    }
  };

  const customStyles = {
    control: (styles, provided) => ({
      ...styles,

      fontSize: 12,
    }),

    option: (provided, state) => ({
      ...provided,
      fontSize: 12,
    }),

    container: (provided, state) => ({
      ...provided,
      fontSize: 12,
    }),
  };

  return (
    <div className="admin-tabs team-tabs">
      <GoBack url="/admin" />

      <div className="primaryHeading primaryHeaderSpacing mobileResponsiveFlexAlign justify-content-between">
        <h5 className="m-0">Merge Contacts</h5>

        <div className="filter-select justify-content-end d-flex">
          <button
            onClick={() => {
              toggle();
            }}
            type="button"
            className="buttonGenericStyle filledButton mr-3"
          >
            Merge Contacts
          </button>

          <div className="filter-by">
            <div className="mr-4">
              <AsyncMergeContact
                placeholder="Destination Contact"
                width={"20rem"}
                onChange={(value) => {
                  setSelectedContactId(value);
                  setCurrentPage(1);
                  setLimit(25);
                }}
              />
            </div>

            <InputField
              inputtype="select"
              selectItems={MergeCompanyType}
              inputid="status"
              placeholder="Status"
              inputMethod={(value) => {
                setStatusFilter(value);
                setCurrentPage(1);
                setLimit(25);
              }}
              inputValue={statusFilter}
            />

            <button
              className="buttonGenericStyle filledButton ml-3"
              onClick={() => {
                setSearchParams("");
                setStatusFilter("");
                setSelectedContactId("");
              }}
            >
              Clear
            </button>
          </div>
        </div>
      </div>

      <div className="search-filter">
        {/* <InputGroup>
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <img src={SEARCH_IMG} alt="search" />
            </InputGroupText>
          </InputGroupAddon>
          <Input
            type="text"
            placeholder="Search by company name."
            value={searchParams}
            onChange={(e) => {
              setQueryParams();
              setSearchParams(e.target.value);
              window.clearTimeout(time);
              time = window.setTimeout(() => {
                fetchMergeCompanies({
                  variables: {
                    limit,
                    page: currentPage,
                    searchText: e.target.value,
                    statusSearch: statusFilter,
                  },
                });
              }, 1000);
            }}
            className="search-by-company"
          />
        </InputGroup> */}
      </div>

      <div className="table-responsive">
        <TableComponent tableHeader={MERGE_CONTACT_HEADER}>
          {loading && (
            <tbody>
              <tr>
                <td colSpan={7}>
                  <Loader loaderClass="sm-height " />
                </td>
              </tr>
            </tbody>
          )}
          {!loading && !data?.getMargeContacts?.mergeContacts?.length && (
            <tbody>
              <tr>
                <td colSpan={7} className="empty-table-td">
                  <NoDataFound text="No Merge Contacts Found!" />
                </td>
              </tr>
            </tbody>
          )}
          <tbody>
            {!loading &&
              data?.getMargeContacts?.mergeContacts?.map(
                (item: any, index: number) => (
                  <tr key={index}>
                    <td>{item?.sourceContactName}</td>
                    <td>{item?.destinationContactName}</td>
                    <td>
                      {" "}
                      {item?.createdAt
                        ? formatDateUS(item?.createdAt)
                        : "--"}{" "}
                      {item?.createdAt
                        ? new Date(item?.createdAt).toLocaleTimeString()
                        : "--"}
                    </td>{" "}
                    {item.status === "PENDING" ? (
                      <td>{"--"}</td>
                    ) : (
                      <td>
                        {" "}
                        {item?.mergedAt
                          ? formatDateUS(item?.mergedAt)
                          : "--"}{" "}
                        {item?.mergedAt
                          ? new Date(item?.mergedAt).toLocaleTimeString()
                          : "--"}
                      </td>
                    )}
                    <td>
                      <Badge
                        color={
                          item.status === "MERGED"
                            ? "success"
                            : item.status === "PENDING"
                            ? "info"
                            : item.status === "ERROR"
                            ? "danger"
                            : ""
                        }
                        className="text-capitalize"
                      >
                        {item.status}
                      </Badge>
                    </td>
                    <td>
                      <div
                        className="text-ellipsis"
                        title={item.mergeNotes || "--"}
                      >
                        {" "}
                        {item.mergeNotes || "--"}
                      </div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        {item.status === "PENDING" &&
                        (userRoles?.includes("ADMIN") ||
                          userRoles?.includes("EXECUTIVE")) ? (
                          <Button
                            onClick={() => {
                              setContactMergeConfirm(true);
                              setMergeActionId(item?.id);
                            }}
                            className="bg-transparent border-0 for-link-theme-color"
                          >
                            Merge
                          </Button>
                        ) : (
                          <Button
                            className="bg-transparent border-0 for-link-theme-color"
                            disabled={true}
                          >
                            Merge
                          </Button>
                        )}

                        {item.status === "PENDING" &&
                        (userRoles?.includes("ADMIN") ||
                          userRoles?.includes("EXECUTIVE")) ? (
                          <Button
                            onClick={() => {
                              setContactDeleteConfirm(true);
                              setDeleteActionId(item?.id);
                            }}
                            className="ml-3 bg-transparent border-0 w-auto d-flex justify-content-center align-items-center"
                          >
                            <img
                              src={deleteIcon}
                              alt=""
                              width="20"
                              height="20"
                              className="m-0"
                            />
                          </Button>
                        ) : (
                          <Button
                            onClick={() => {
                              setContactDeleteConfirm(true);
                              setDeleteActionId(item?.id);
                            }}
                            disabled={true}
                            className="ml-3 bg-transparent border-0 w-auto d-flex justify-content-center align-items-center"
                          >
                            <img
                              src={deleteIcon}
                              alt=""
                              width="20"
                              height="20"
                              className="m-0"
                            />
                          </Button>
                        )}
                      </div>
                    </td>
                  </tr>
                )
              )}
          </tbody>
        </TableComponent>
      </div>
      {data?.getMargeContacts?.mergeContacts &&
      data?.getMargeContacts?.mergeContacts?.length ? (
        <div className="users-pagination">
          <Pagination
            onPageChange={(pageClicked: number) => {
              setCurrentPage(pageClicked);
              history.push(`/merge-contact?currentPage=${pageClicked}`);
            }}
            pageCount={Math.ceil(data?.getMargeContacts?.count / limit)}
            currentPage={currentPage}
          />

          <div className="d-flex align-items-center">
            <div className="pagination-display paginationItemsSelect">
              <p className="m-0">
                {(currentPage - 1) * limit + 1} to{" "}
                {Math.min(currentPage * limit, data?.getMargeContacts?.count)}{" "}
                of {data?.getMargeContacts?.count}
              </p>

              <div className="divider"></div>

              <label>Display</label>

              <InputField
                label=""
                inputtype="select"
                selectItems={PAGE_LIMIT.filter((item) => item.value !== limit)}
                inputid="limit"
                placeholder={limit.toString()}
                inputMethod={(value) => {
                  setCurrentPage(1);
                  setLimit(parseInt(value));
                }}
              />
            </div>
          </div>
        </div>
      ) : null}
      {error?.graphQLErrors[0]?.message}

      <Modal
        isOpen={modal}
        toggle={toggle}
        className="revampDialog"
        id="merge-companies"
      >
        <div className="modalHeaderCustom d-flex align-items-center justify-content-between">
          <h5 className="m-0">Merge Contacts</h5>

          <Button className="p-0 bg-transparent border-0" onClick={toggle}>
            <img src={CLOSE_ICON} alt="close" />
          </Button>
        </div>

        <Form onSubmit={handleSubmit(createMergeContact)}>
          <ModalBody>
            <Label>Company</Label>
            <Controller
              control={control}
              defaultValue={""}
              name="companyId"
              render={({ onChange }) => (
                <AsyncCompanyDropdown
                  mergeContact={true}
                  value={getValues()?.companyId}
                  onChange={(value) => {
                    setValue("companyId", value);
                    setCompanyId(value.value);
                  }}
                />
              )}
              styles={customStyles}
            />
            <Label>Source Contact</Label>
            <Controller
              control={control}
              defaultValue={""}
              rules={{
                required: "Source contact is required!",
              }}
              name="sourceContactId"
              render={(props) => (
                <AsyncContactDropdown
                  {...props}
                  mergeCompany={true}
                  companyId={companyId}
                  value={getValues()?.sourceContactId}
                />
              )}
              styles={customStyles}
            />
            <small className="text-danger pt-2">
              {errors?.sourceContactId?.message}
            </small>

            <Label className="d-block mt-2">Destination Contact</Label>
            <Controller
              control={control}
              defaultValue={""}
              rules={{
                required: "Destination contact is required!",
                validate: (value) => {
                  const prevCompany = watch("sourceContactId");
                  if (prevCompany === value) {
                    return "Selected contact should be different!";
                  }
                },
              }}
              name="destinationContactId"
              render={(props) => (
                <AsyncContactDropdown
                  {...props}
                  mergeCompany={true}
                  companyId={companyId}
                  value={getValues()?.destinationContactId}
                />
              )}
              styles={customStyles}
            />
            <small className="text-danger pt-2">
              {errors?.destinationContactId?.message}
            </small>
            <FormGroup className="mt-2">
              <Label>Merge Notes</Label>
              <textarea
                name="mergeNotes"
                ref={register({ required: false })}
                placeholder="Merge notes..."
                rows={4}
                className="w-100 form-control"
              />
            </FormGroup>

            <small className="text-danger pt-2">
              {createError?.graphQLErrors[0]?.message}
            </small>
          </ModalBody>
          <ModalFooter>
            <div>
              <button
                onClick={toggle}
                className="buttonPrimary mr-3"
                type="button"
              >
                Cancel
              </button>
              <CustomButton
                buttonText="Merge Contacts"
                buttonColor="primary"
                buttonType="submit"
                className="big-width"
                loading={waiting}
                disabled={waiting}
              />
            </div>
          </ModalFooter>
        </Form>
      </Modal>

      <ConfirmPopUp
        confirmText="Are you sure you want to delete this contact from merged contact list?"
        isOpen={actionConfirm}
        toggle={setContactDeleteConfirm}
        confirmAction={confirmAction}
        modalHeading="Delete Action"
        btnText="Delete"
        btnColor="primary"
        className="revampDialog revampDialogWidth"
      />

      <ConfirmPopUp
        confirmText="Are you sure you want to merge this contact from merged contact list?"
        isOpen={mergeActionConfirm}
        toggle={setContactMergeConfirm}
        confirmAction={confirmMergeAction}
        modalHeading="Merge action"
        btnText="Confirm"
        btnColor="primary"
        className="revampDialog revampDialogWidth"
      />
    </div>
  );
};

export default MergeContacts;
