// packages block
import { useContext, useEffect, useState } from "react";
import Select from "react-select";
import { withRouter } from "react-router";
import { Controller } from "react-hook-form";
import { useLazyQuery, useQuery } from "@apollo/client";
import {
  Button, Card, Col, FormGroup, Input, InputGroup, InputGroupAddon, Label, Row,
} from "reactstrap";
// components block
import AsyncCompanyDropdown from "../../../components/AsyncCompanySelect";
import CreateAddressModal from "../../contacts/contact-components/CreateAddressModal";
import CreateContactModalForJob from "../../company/company-components/CreateContactModalForJob";
// utils, constants and graphql block
import { AppContext } from "../../../context";
import { FeeType } from "../../../utils/constant";
import { moneyFieldHandler } from "../../../utils/helper";
import {
  FETCHING_USERS_WITHOUT_ROLES
} from "../../admin/gql";
import {
  FETCH_All_JOB_ORDER_COMPANIES, GET_RECRUITER_LIST_WITHOUT_MANAGER,
} from "../gql";
import { SelectorOptionType } from "../../../interfaces";

const BasicJobOrderInfo = ({
  register, errors, _company, location, control, getValues, fromEdit,
  setValue, match, watch, setLocation,
  ...props
}: any) => {
  const { theme } = useContext(AppContext);
  const [getCompanies, { data, loading }] = useLazyQuery(FETCH_All_JOB_ORDER_COMPANIES);
  const { companyLocationId } = watch()
  const [defaultRecruiter, setDefaultRecruiter] = useState<any>();
  const [addressList, setAddressList] = useState<Array<any>>([]);
  const [contactsLists, setContactList] = useState<Array<any>>([]);
  const [companyId, setCompanyId] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [feeType, setFeeType] = useState("");
  const [recruiterList, setRecruiterList] = useState([]);
  const { user } = useContext(AppContext);
  const { userRoles } = useContext(AppContext);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [contactToggle, setContactToggle] = useState(false);
  const createContactToggle = () => setContactToggle(!contactToggle);
  const [agreementsList, setAgreementList] = useState<Array<any>>([]);
  const [signedAgreementsWithDetail, setSignedAgreementsWithDetail] = useState<
    Array<any>
  >([]);
  const { data: recruiters } = useQuery(GET_RECRUITER_LIST_WITHOUT_MANAGER);
  const { data: users } = useQuery(FETCHING_USERS_WITHOUT_ROLES);

  const [titleMsg, setTitleMsg] = useState<any>({
    msg: "You can enter up to 55 characters",
    charLength: 0,
  });

  const [advertisedTitleMsg, setAdvertisedTitleMsg] = useState<any>({
    msg: "You can enter up to 55 characters",
    charLength: 0,
  });
  useEffect(() => {
    if (companyLocationId) {
      setLocation && setLocation(companyLocationId?.label)
    }
  }, [companyLocationId, setLocation])
  useEffect(() => {
    if (data) {
      const companyList: Array<Object> = [];
      data?.companiesForDropDown?.companies &&
        data?.companiesForDropDown?.companies?.forEach((company: any) => {
          // If user is executive or admin
          if (
            userRoles?.includes("EXECUTIVE") ||
            userRoles?.includes("ADMIN")
          ) {
            companyList.push({
              label: company?.name,
              value: company.id,
            });
          } else {
            // If user is owner or sharee
            company?.ownerAndSharees?.forEach((el) => {
              if (el?.owner?.id === user?.id) {
                companyList.push({
                  label: company?.name,
                  value: company.id,
                });
              }
            });
          }
        });
      // setCompaniesList(companyList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, userRoles]);

  const fetchCompanies = () => {
    getCompanies({
      variables: {
        limit: 20,
        page: 1,
        searchText: companyName,
      },
    });
  };

  const changeCompany = (value: any) => {
    setCompanyId(value);
    let companySelected = data?.companiesForDropDown?.companies?.find(
      (item: any) => item.id === value
    );
    if (companySelected && companySelected?.address?.length) {

      let addressList = companySelected?.address?.map((v: any) => ({
        label: ['US', 'CA', null, undefined, ''].includes(v?.country) ? `${v?.streetAddress}, ${v?.city}, ${v?.state}, ${v?.zipCode}, ${v?.country}` : `${v?.streetAddress}, ${v?.city}, ${v?.zipCode}, ${v?.country}`,
        value: v.id,
      }));

      let [ownerList] = companySelected?.ownerAndSharees
        ?.filter((item) => item?.ownerType === "OWNER")
        .map((v: any) => ({
          id: v?.owner?.id,
        }));
      addressList.sort(function (a, b) {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      });
      setAddressList(addressList);
      setDefaultRecruiter(ownerList);
    } else {
      // If we select a company with an address and after that select another company with no address then the other company can use the address of the previously selected company
      setAddressList([]);
      setDefaultRecruiter([]);
    }
    if (companySelected && companySelected?.companyContacts?.length) {
      let contactsList = companySelected?.companyContacts?.map((v: any) => ({
        label: v.fullName,
        value: v.id,
      }));
      contactsList.sort(function (a, b) {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      });
      // changeContactHandler(companySelected?.companyContacts);
      setContactList(contactsList);
    } else {
      // If we select a company with some contact and after that select another company with no contact then the other company can use the contacts of the previously selected company
      setContactList([]);
    }
    if (companySelected && companySelected?.companyAgreement?.length) {
      let agreementsList = companySelected?.companyAgreement
        ?.filter((v: any) => v.status === "SIGNED")
        ?.map((x) => ({ label: x.nickName, value: x.id }));
      setAgreementList([...agreementsList]);
      let signedAgreementsWithDetails =
        companySelected?.companyAgreement?.filter(
          (v: any) => v.status === "SIGNED"
        );
      setSignedAgreementsWithDetail(signedAgreementsWithDetails);
    } else {
      setAgreementList([]);
    }
  };

  const changeContactHandler = () => {
    let companyId = getValues("companyId")?.value;
    let companySelected = data?.companiesForDropDown?.companies?.find(
      (item: any) => item.id === companyId
    );
    let mainContact = getValues("mainContact")?.value;
    let billingContact = getValues("billingContact")?.value;
    let remoteContact = getValues("remoteContact")?.value;
    let allJobContact = [mainContact, billingContact, remoteContact];
    let companyContact: any = companySelected?.companyContacts?.length
      ? companySelected?.companyContacts?.map((item) => item.id)
      : [];
    let contactDontExist: any = [];
    for (let prvContact of allJobContact) {
      if (!companyContact?.includes(prvContact)) {
        contactDontExist.push(prvContact);
      }
    }
    fromEdit &&
      props?.setContactDontExist(
        contactDontExist?.length ? contactDontExist[0] : null
      );
  };

  useEffect(() => {
    if (data?.companiesForDropDown?.companies?.length) {
      changeContactHandler();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.companiesForDropDown?.companies]);

  useEffect(() => {
    if (_company || location) {
      changeCompany(_company?.id);
      setCompanyName(_company?.name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_company, location]);

  useEffect(() => {
    if (companyName) {
      getCompanies({
        variables: {
          limit: 20,
          page: 1,
          searchText: companyName,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyName]);

  useEffect(() => {
    if (
      companyId &&
      data?.companiesForDropDown?.companies?.length && !loading &&
      companyName
    ) {
      let company = data?.companiesForDropDown?.companies?.find(
        (item) => item.id === companyId
      );
      if (company) {
        changeCompany(company?.id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId, companyName, loading]);

  useEffect(() => {
    if (getValues()?.feeType?.value) {
      setFeeType(getValues()?.feeType?.value);
    }

    // eslint-disable-next-line
  }, [getValues()?.feeType?.value]);

  useEffect(() => {
    setRecruiterList(recruiters?.getRecruiterListWithoutManager);
    setRecruiterList(users?.allUsers);
  }, [recruiters, users]);

  const onAgreementChange = async (event: SelectorOptionType) => {
    if (event !== null) {
      // setAgreementId(event.value);
      let foundTheDirectHireAgreement = signedAgreementsWithDetail.find(
        (item) =>
          item?.id === event?.value &&
          (item?.agreementType === "DIRECT_HIRE" ||
            item?.agreementType === "LEGAL_DIRECT_HIRE")
      );

      if (foundTheDirectHireAgreement) {
        setFeeType("PERCENTAGE");
        setValue("feeType", { label: "Percentage", value: "PERCENTAGE" });
        setValue("fee", foundTheDirectHireAgreement?.commissionPercentage);
      } else {
        setFeeType("FIXED_FEE");
        setValue("feeType", { label: "Fixed Fee", value: "FIXED_FEE" });
      }
      // fetchOneAgreement({ variables: { agreementId: event?.value } });
    }
  };

  const preSelectAddress = (location) => {
    setValue("companyLocationId", {
      label: ['US', 'CA', null, undefined, ''].includes(location?.country) ? `${location?.streetAddress}, ${location?.city}, ${location?.state}, ${location?.zipCode}, ${location?.country}` : `${location?.streetAddress}, ${location?.city}, ${location?.zipCode}, ${location?.country}`,
      value: location?.id,
    });
  };

  const preSelectContact = (contact: {
    firstName: string;
    lastName: string;
    id: string;
  }) => {
    setValue("mainContact", {
      label: contact?.firstName + " " + contact?.lastName,
      value: contact?.id,
    });
    setValue("remoteContact", {
      label: contact?.firstName + " " + contact?.lastName,
      value: contact?.id,
    });
    setValue("billingContact", {
      label: contact?.firstName + " " + contact?.lastName,
      value: contact?.id,
    });
    fetchCompanies();
  };

  const customStyles = {
    control: (styles, provided) => ({
      ...styles,
      background: theme === "light" ? provided.background : "#282828",
      borderColor: theme === "light" ? "#ced4da" : "#282828",
      color: theme === "light" ? provided.background : "#d9d9d9",
      fontSize: 12,
      "&:hover": {
        borderColor: theme === "light" ? "#ced4da" : "#282828",
        color: theme === "light" ? provided.background : "#d9d9d9",
      },
    }),
    menu: (provided) => ({
      ...provided,
      background: theme === "light" ? provided.background : "#282828",
      zIndex: props?.zIndex || 999999,
      color: theme === "light" ? provided.background : "#d9d9d9",
    }),
    option: (provided, state) => ({
      ...provided,
      fontSize: 12,
      background: theme === "light" ? provided.background : "#363636",
      "&:hover": {
        background: theme === "light" ? provided.background : "#474444",
        color: theme === "light" ? provided.background : "#d9d9d9",
      },
    }),

    container: (provided, state) => ({
      ...provided,
      fontSize: 12,
    }),
  };

  return (
    <div>
      <Card className="border-0 mb-2">
        <div className="d-flex align-items-center justify-content-between pb-3">
          <h5>Basic Info</h5>

          <div className="d-flex align-items-center">
            <div className="d-flex align-items-center">
              <span className="mr-2 switchLabel">Hot Job</span>
              <label className="switch m-0">
                <Input type="checkbox" name="isHotJob" innerRef={register()} />
                <span className="slider"></span>
              </label>
            </div>

            {/* <div className="d-flex align-items-center ml-4">
              <span className="mr-2 switchLabel">Post To LinkedIn</span>
              <label className="switch m-0">
                <Input type="checkbox" name="postToLinkedin" innerRef={register()} />
                <span className="slider"></span>
              </label>
            </div> */}

            <div className="d-flex align-items-center ml-4">
              <span className="mr-2 switchLabel">Confidential Job</span>
              <label className="switch m-0">
                <Input type="checkbox" name="confidentialJob" innerRef={register()} />
                <span className="slider"></span>
              </label>
            </div>
          </div>
        </div>

        <div>
          <div className="user-basic-form">
            <Row>
              <Col xl="4" lg="6" md="12" sm="12" xs="12">
                <Label>
                  Job Title <span className="text-danger">*</span>
                </Label>
                <Input
                  inputtype="text"
                  placeholder="job Title"
                  innerRef={register({
                    required: {
                      value: true,
                      message: "Job title is required!",
                    },
                  })}
                  name="jobTitle"
                  onChange={({ target: { value } }) => {
                    if (value?.length === 0) {
                      setTitleMsg({
                        msg: "You can enter up to 55 characters",
                        charLength: value?.length,
                      });
                    }
                    if (value?.length > 0) {
                      setTitleMsg({
                        msg: `Current length: ${value?.length}`,
                        charLength: value?.length,
                      });
                    }
                  }}
                />
                <div className="titile-count-message mt-1">
                  <small className="text-danger">
                    {errors?.jobTitle?.message}
                  </small>

                  <small
                    className={`${titleMsg?.charLength > 55
                      ? "text-danger"
                      : "titile-count-message-color"
                      } `}
                  >
                    {titleMsg?.msg}
                  </small>
                </div>
              </Col>

              {/* ADVERTISED JOB TITLE START */}

              <Col xl="4" lg="6" md="12" sm="12" xs="12">
                <Label>
                  Advertised Job Title <span className="text-danger">*</span>
                </Label>
                <Input
                  inputtype="text"
                  placeholder="Advertised job Title"
                  innerRef={register({
                    required: {
                      value: true,
                      message: "Advertised Job title is required!",
                    },
                  })}
                  name="advertisedJobTitle"
                  onChange={({ target: { value } }) => {
                    if (value?.length === 0) {
                      setAdvertisedTitleMsg({
                        msg: "You can enter up to 55 characters",
                        charLength: value?.length,
                      });
                    }
                    if (value?.length > 0) {
                      setAdvertisedTitleMsg({
                        msg: `Current length: ${value?.length}`,
                        charLength: value?.length,
                      });
                    }
                  }}
                />
                <div className="titile-count-message mt-1">
                  <small className="text-danger">
                    {errors?.advertisedJobTitle?.message}
                  </small>

                  <small
                    className={`${advertisedTitleMsg?.charLength > 55
                      ? "text-danger"
                      : "titile-count-message-color"
                      } `}
                  >
                    {advertisedTitleMsg?.msg}
                  </small>
                </div>
              </Col>

              {/* ADVERTISED JOB TITLE END */}

              <Col xl="4" lg="6" md="12" sm="12" xs="12">
                <FormGroup>
                  <Label>
                    Company <span className="text-danger">*</span>
                  </Label>
                  <Controller
                    name="companyId"
                    rules={{
                      required: {
                        value: true,
                        message: "Company must be selected!",
                      },
                    }}
                    render={() => (
                      <AsyncCompanyDropdown
                        width={"100%"}
                        ownershareeFlag={true}
                        placeholder="Filter By Company name"
                        value={getValues()?.companyId}
                        isDisabled={match?.params?.id ? true : false}
                        onChange={(value) => {
                          if (value) {
                            setValue("companyLocationId", "");
                            setValue("mainContact", "");
                            setValue("remoteContact", "");
                            setValue("billingContact", "");
                            changeCompany(value?.value);
                            setCompanyName(value?.label);
                          }
                          setValue("companyId", value);
                        }}
                      />
                    )}
                    control={control}
                    styles={customStyles}
                  />
                  <small className="text-danger">
                    {errors?.companyId?.message}
                  </small>
                </FormGroup>
              </Col>

              <Col xl="4" lg="6" md="12" sm="12" xs="12">
                <FormGroup className="m-0">
                  <Label>
                    Main contact <span className="text-danger">*</span>
                  </Label>

                  <Controller
                    name="mainContact"
                    rules={{
                      required: {
                        value: true,
                        message: "Must Select Main Contact!",
                      },
                    }}
                    as={Select}
                    isDisabled={companyId ? false : true}
                    options={contactsLists}
                    placeholder="Main Contact"
                    control={control}
                    isSearchable={true}
                    styles={customStyles}
                  />

                  <small className="text-danger">
                    {errors?.mainContact?.message}
                  </small>
                </FormGroup>

                <div className="d-flex justify-content-end">
                  <Button
                    className="bg-transparent border-0 text-info p-0 mt-1"
                    onClick={createContactToggle}
                    disabled={companyId ? false : true}
                    title={
                      !companyId
                        ? "Kindly select a company for new address!"
                        : ""
                    }
                  >
                    Create New Contact
                  </Button>
                </div>
              </Col>

              <Col xl="4" lg="6" md="12" sm="12" xs="12">
                <FormGroup className="mb-0">
                  <Label>
                    Actual Job Location <span className="text-danger">*</span>
                  </Label>

                  <Controller
                    name="companyLocationId"
                    rules={{
                      required: {
                        value: true,
                        message: "Must Select Actual job location!",
                      },
                    }}
                    isSearchable={true}
                    as={Select}
                    isDisabled={companyId ? false : true}
                    options={addressList}
                    placeholder="Actual job location"
                    control={control}
                    styles={customStyles}
                  />

                  <small className="text-danger">
                    {errors?.companyLocationId?.message}
                  </small>
                </FormGroup>

                <div className="d-flex justify-content-end">
                  <Button
                    className="bg-transparent border-0 text-info p-0 mt-1"
                    onClick={toggle}
                    disabled={companyId ? false : true}
                    title={
                      !companyId
                        ? "Kindly select a company for new address!"
                        : ""
                    }
                  >
                    Create New Address
                  </Button>
                </div>
              </Col>

              <Col xl="4" lg="6" md="12" sm="12" xs="12">
                <FormGroup className="mb-0">
                  <Label>
                    Billing Contact <span className="text-danger">*</span>
                  </Label>

                  <Controller
                    name="billingContact"
                    rules={{
                      required: {
                        value: true,
                        message: "Must Select Billing Contact!",
                      },
                    }}
                    as={Select}
                    isDisabled={companyId ? false : true}
                    options={contactsLists}
                    placeholder="Billing Contact"
                    control={control}
                    isSearchable={true}
                    styles={customStyles}
                  />

                  <small className="text-danger">
                    {errors?.billingContact?.message}
                  </small>
                </FormGroup>
              </Col>
              <Col xl="4" lg="6" md="12" sm="12" xs="12">
                <FormGroup>
                  <Label>
                    Report to Contact <span className="text-danger">*</span>
                  </Label>

                  <Controller
                    name="remoteContact"
                    rules={{
                      required: {
                        value: true,
                        message: "Must Select Report to Contact!",
                      },
                    }}
                    as={Select}
                    isDisabled={companyId ? false : true}
                    options={contactsLists}
                    placeholder="Report to Contact"
                    control={control}
                    isSearchable={true}
                    styles={customStyles}
                  />

                  <small className="text-danger">
                    {errors?.remoteContact?.message}
                  </small>
                </FormGroup>
              </Col>

              {match?.params?.id && (
                <Col xl="4" lg="6" md="12" sm="12" xs="12">
                  <FormGroup>
                    <Label>Additional Recruiter Assigned </Label>

                    <Controller
                      name="recruitingList"
                      as={Select}
                      isMulti
                      options={
                        recruiterList?.length &&
                        recruiterList
                          ?.filter(
                            (item: any) => item?.id !== defaultRecruiter?.id
                          )
                          .map((item: any) => ({
                            label: item?.fullName,
                            value: item?.id,
                          }))
                      }
                      control={control}
                      placeholder="Recruiters Assigned"
                      styles={customStyles}
                    />
                  </FormGroup>
                </Col>
              )}

              <Col xl="4" lg="6" md="12" sm="12" xs="12">
                <FormGroup>
                  <Label>Active Agreements</Label>
                  <Controller
                    name="agreementId"
                    render={({ onChange, value }) => (
                      <Select
                        isSearchable={false}
                        isClearable={true}
                        value={value}
                        noOptionsMessage={() => "No Signed Agreement"}
                        options={agreementsList}
                        placeholder="Active Agreements"
                        onChange={(event: SelectorOptionType) => {
                          onChange(event);
                          onAgreementChange(event);
                        }}
                        styles={customStyles}
                      />
                    )}
                    control={control}
                    styles={customStyles}
                  />
                </FormGroup>
              </Col>

              <Col xl="4" lg="6" md="12" sm="12" xs="12">
                <FormGroup>
                  <Label>Fee Type</Label>
                  <Controller
                    name="feeType"
                    render={({ onChange, value }) => (
                      <Select
                        // value={fromEdit && getValues()?.feeType}
                        value={value}
                        options={FeeType}
                        isSearchable={false}
                        placeholder="Fee Type"
                        onChange={(value: any) => {
                          onChange(value);
                          setFeeType(value?.value);
                        }}
                        styles={customStyles}
                      />
                    )}
                    control={control}
                    styles={customStyles}
                  />
                </FormGroup>
              </Col>

              <Col
                xl="4"
                lg="6"
                md="12"
                sm="12"
                xs="12"
                className="dollar-addon-btn"
              >
                <FormGroup>
                  <label>Fee</label>
                  <InputGroup>
                    <InputGroupAddon
                      addonType="prepend"
                      className="bg-transparent"
                    >
                      {feeType === "PERCENTAGE" ? "%" : "$"}
                    </InputGroupAddon>
                    <Input
                      type="text"
                      name="fee"
                      defaultValue="0"
                      onChange={(e) => {
                        moneyFieldHandler(e?.target?.value, setValue, "fee");
                      }}
                      placeholder="Fee"
                      // {
                      //   pattern: {
                      //     value: /^\d*(\.\d+)?$/,
                      //     message: "Fee must be number!",
                      //   },
                      // }
                      innerRef={register()}
                    />
                  </InputGroup>
                  <small className="text-danger">{errors?.fee?.message}</small>
                </FormGroup>
              </Col>

              <Col
                xl="4"
                lg="6"
                md="12"
                sm="12"
                xs="12"
                className="dollar-addon-btn"
              >
                <FormGroup>
                  <label>Estimated Fee</label>
                  <InputGroup>
                    <InputGroupAddon
                      addonType="prepend"
                      className="bg-transparent"
                    >
                      $
                    </InputGroupAddon>
                    <Input
                      type="text"
                      name="estimatedFee"
                      placeholder="Estimated Fee"
                      onChange={(e) => {
                        moneyFieldHandler(
                          e?.target?.value,
                          setValue,
                          "estimatedFee"
                        );
                      }}
                      innerRef={register()}
                    />
                  </InputGroup>
                  <small className="text-danger">
                    {errors?.estimatedFee?.message}
                  </small>
                </FormGroup>
              </Col>

              <Col xl="4" lg="6" md="12" sm="12" xs="12">
                <FormGroup>
                  <Label>
                    No. Of Positions <span className="text-danger">*</span>
                  </Label>
                  <Controller
                    name="positions"
                    rules={{
                      required: {
                        value: true,
                        message: "Must select Number of  Positions!",
                      },
                      pattern: {
                        value: /^\d*(\.\d+)?$/,
                        message: "Positions must be number!",
                      },
                    }}
                    render={() => (
                      <Input
                        defaultValue={1}
                        placeholder="No. Of Positions"
                        name="positions"
                        step="1"
                        min={0}
                        type="text"
                        innerRef={register()}
                      />
                    )}
                    control={control}
                    styles={customStyles}
                  />
                  <small className="text-danger">
                    {errors?.positions?.message}
                  </small>
                </FormGroup>
              </Col>
            </Row>
          </div>
        </div>
      </Card>

      {modal && (
        <CreateAddressModal
          open={modal}
          toggle={toggle}
          companyId={companyId}
          preSelect={preSelectAddress}
        />
      )}
      {contactToggle && (
        <CreateContactModalForJob
          toggle={createContactToggle}
          open={contactToggle}
          companyId={companyId}
          companyName={companyName}
          // fetchCompany={fetchCompanies}
          preSelectContact={preSelectContact}
        />
      )}
    </div>
  );
};

export default withRouter(BasicJobOrderInfo);
