import { Fragment, useContext, useEffect, useState } from "react";
import TableComponent from "../../components/layout/TableComponent";

import AvatarImage from "../../assets/images/user-empty.svg";

import {
  FETCH_USER_TEAM_REPORT,
  FETCH_MANAGER_TEAM,
  FETCH_TEAMS_FOR_EXECUTIVE,
} from "../admin/gql";
import { useLazyQuery } from "@apollo/client";
import Loader from "../../components/layout/Loader";
import { AppContext } from "../../context";
import NoDataFound from "../../components/layout/NoDataFound";
import { formatDateUS } from "../../utils/commonFn";

const TABLE_HEADER = [
  "MEMBER",
  "jobTitle",
  "EMAIL",
  "PHONE NO.",
  "JOINING DATE",
];

const TeamsReport = (): JSX.Element => {
  const [fetchTeam, { data, loading }] = useLazyQuery(
    FETCH_TEAMS_FOR_EXECUTIVE
  );
  const [TeamsOfReports, { data: teamReposrt, loading: teamLoading }] =
    useLazyQuery(FETCH_USER_TEAM_REPORT);
  const [managerTeam, { data: managerTeamMember, loading: memberLoading }] =
    useLazyQuery(FETCH_MANAGER_TEAM);
  const [teamforReport, setTeamforReport] = useState<Array<any>>([]);
  const { userRoles } = useContext(AppContext);

  //setting title page
  useEffect(() => {
    document.title = `${data?.team?.name || "Team"} - Report - RealREPP`;
  }, [data?.team]);

  useEffect(() => {
    setTeamforReport([]);
    if (userRoles?.includes("EXECUTIVE")) {
      fetchTeam(data?.getAllTeams);
    } else if (userRoles?.includes("MANAGER")) {
      managerTeam();
    } else {
      TeamsOfReports();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userRoles]);

  useEffect(() => {
    setTeamforReport([]);
    if (userRoles?.includes("EXECUTIVE")) {
      data?.teamForExecutive && setTeamforReport(data?.teamForExecutive);
    } else if (userRoles?.includes("MANAGER")) {
      managerTeamMember?.managerTeamMember &&
        setTeamforReport([managerTeamMember?.managerTeamMember]);
    } else {
      teamReposrt?.userTeamMembers &&
        setTeamforReport([teamReposrt?.userTeamMembers]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, teamReposrt, managerTeamMember]);

  if (loading || memberLoading || teamLoading) {
    return <Loader />;
  }
  return (
    <div className="teams-report-main">
      <div className="primaryHeading">
        <h5 className="m-0">Teams Report</h5>
      </div>

      {!teamforReport?.length && (
        <div className="for-position-multiselect mt-3">
          <NoDataFound text="Currently, you are not mananging any team!" />
        </div>
      )}
      {teamforReport && teamforReport?.length
        ? teamforReport.map((team: any, index: number) => {
            return (
              <Fragment key={index}>
                {team?.teamUsers?.length ? (
                  <>
                    <div className="secondaryHeadingTheme">
                      <h5 className="mt-3">{team?.name}</h5>
                    </div>

                    <div className="table-responsive">
                      <TableComponent tableHeader={TABLE_HEADER}>
                        <tbody>
                          {team?.teamUsers && team?.teamUsers.length
                            ? team.teamUsers.map(
                                (item: any, index: number) =>
                                  !item.endedAt && (
                                    <tr key={index}>
                                      <td>
                                        <div className="avatar-profile">
                                          <div className="avatar">
                                            <img
                                              src={
                                                item?.user?.photo || AvatarImage
                                              }
                                              alt="avatar"
                                            />
                                          </div>

                                          {item?.user?.fullName}
                                        </div>
                                      </td>
                                      <td>{item.user?.jobTitle}</td>
                                      <td>
                                        <a href={`mailto:${item?.user?.email}`}>
                                          {item?.user?.email}
                                        </a>
                                      </td>
                                      <td>
                                        <a href={`tel:${item?.user?.phone}`}>
                                          {item?.user?.phone}
                                        </a>
                                      </td>
                                      <td>{formatDateUS(item?.startAt)}</td>
                                    </tr>
                                  )
                              )
                            : ""}
                        </tbody>
                      </TableComponent>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </Fragment>
            );
          })
        : ""}
    </div>
  );
};

export default TeamsReport;
