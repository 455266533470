import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import AddContact from '../AddContact';

export default function AddContactModal({
  compaignId,
  reFetchContacts,
  toggle,
  addContactModal,
  refetchStats,
}) {
  return (
    <div>
      <Modal
        isOpen={addContactModal}
        toggle={toggle}
        className="revampDialog revampDialogWidth"
      >
        <ModalHeader toggle={toggle} className="dark-card">Add New Contact</ModalHeader>
        <ModalBody className="pb-4 dark-card">
          <AddContact
            addContactToggle={toggle}
            compaignId={compaignId}
            refetchStats={refetchStats}
            reFetchContacts={reFetchContacts}
          />
        </ModalBody>
      </Modal>
    </div>
  );
}
