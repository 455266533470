import AsyncSelect from "react-select/async";
import { toast } from "react-toastify";
import { useContext } from "react";
import { AppContext } from "../../../context";
import client from "../../../apollo";
import { Col, Label } from "reactstrap";
import { GET_USERS_BY_NAME_EMAIL } from "../gql";
import { customSelectDropDown } from "../../../components/styled/customSelect";
let time = 0;

const AsyncRecruiterSelect = (props) => {
  const { theme } = useContext(AppContext);
  const _filterJRecruiters = async (inputValue: string) => {
    try {
      const { data } = await client.query({
        query: GET_USERS_BY_NAME_EMAIL,
        variables: {
          limit: 20,
          page: 1,
          searchText: inputValue,
        },
      });

      return data && data?.findUserByNameEmail?.users
        ? data.findUserByNameEmail.users.map((item) => ({
            value: item.id,
            label: item.fullName,
          }))
        : [];
    } catch (error) {
      toast.error("Error while loading recruiter!");
    }
  };
 
  const promiseOptions = (value: any): any =>
    new Promise((resolve) => {
      if (!value) {
        resolve(_filterJRecruiters(value));
      } else window.clearTimeout(time);
      time = window.setTimeout(() => {
        resolve(_filterJRecruiters(value));
      }, 1000);
    });

  return (
    <Col xl="4" md="6" sm="12">
      <Label>{props?.label}</Label>
      <AsyncSelect
        cacheOptions
        isDisabled={props?.isDisabled || false}
        value={props?.value}
        // defaultValue={props?.defaultValue}
        isSearchable
        styles={{ ...customSelectDropDown(theme) }}
        isClearable
        ref={props.ref}
        defaultOptions
        onChange={(selected: any) => {
          if (props.onSelected) {
            if (selected) {
              props.onSelected(selected);
            } else {
              props.onSelected({});
            }
          }
          if (selected) {
            // setSelectValue(selected);
            props.onChange(selected);
          } else {
            props.onChange("");
          }
        }}
        loadOptions={promiseOptions}
        placeholder={props?.placeholder}
      />
    </Col>
  );
};

export default AsyncRecruiterSelect;
