import { useLazyQuery } from "@apollo/client";
import ImageResize from "quill-image-resize-module-react";
import { useCallback, useContext, useMemo, useRef, useState } from "react";
import ReactQuill from "react-quill";
import Select from "react-select/creatable";
import { Badge, Button, Form, Input, InputGroup, InputGroupAddon, Modal, Spinner } from "reactstrap";
import { AttachFileIcon } from "../assets/images/svgs";
import { AppContext } from "../context";
import Preview from "../Pages/job-order/job-order-components/document-preview/Preview";
import { GET_SIGNED_URL } from "../Pages/settings/gql";
import { bucketFolders, EMAIL_PATTERN, reactQuillReg } from "../utils/constant";
import { uploadFile, uploadImage } from "../utils/helper";
import { customSelectDropDownForEmail } from "./styled/customSelect";


ReactQuill.Quill.register("modules/imageResize", ImageResize);
const EmailDraft = ({ selectedFiles, setSelectedFiles, fileName, source, setNewMailContact, newMailContact,
  mailContact, setMailContact, bodyContent, setBodyContent, subject, setSubject, companyContacts }) => {
  const { theme } = useContext(AppContext)
  const [getSignedURL] = useLazyQuery(GET_SIGNED_URL);
  const [preSelectCC, setPreSelectCC] = useState<{ value: string, label: string }[]>(
    [...mailContact?.ccContacts?.map((value) => { return { value, label: value } }), ...companyContacts])
  const [preSelectBCC, setPreSelectBCC] = useState<{ value: string, label: string }[]>(
    [...mailContact?.bccContacts?.map((value) => { return { value, label: value } }), ...companyContacts])
  const [preSelectTo, setPreSelectTo] = useState<{ value: string, label: string }[]>(
    [...mailContact?.to?.map((value) => { return { value, label: value } }), ...companyContacts])
  const [errMsg, setErrMsg] = useState('');
  const [fileErr, setFileErr] = useState('');
  const [uploading, setUploading] = useState<boolean>()
  const [viewDocument, setViewDocument] = useState<boolean>()
  const [documentUrl, setDocumentUrl] = useState<string>()
  const [documentName, setDocumentName] = useState<string>()
  const [uploadingImage, setUploadingImage] = useState<boolean>(false)

  const quillRef = useRef<ReactQuill>(null);

  const modules = useMemo(() => ({
    toolbar: {
      container: [
        [{ font: [] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        ["link", "image"],
      ],
    },
    clipboard: {
      matchVisual: false,
    },
    imageResize: {
      modules: ['Resize', 'DisplaySize', 'Toolbar']
    }
  }), []);

  const mailSelected = (value: string, name: string) => {
    console.log("e :: ", value)
    const mailRegex = new RegExp(EMAIL_PATTERN);
    const test = mailRegex.test(value);
    if (test) {
      let mails: string[];
      if (value === 'johnnyrenaudo@realrepp.com')
        mails = [...mailContact[name], value]
      else
        mails = [value, ...mailContact[name]];
      switch (name) {
        case 'ccContacts':
          setPreSelectCC([...preSelectCC, { value, label: value }].filter((item, index, self) =>
            index === self.findIndex((e) => e.value === item.value)
          ))
          break

        case 'bccContacts':
          setPreSelectBCC([...preSelectBCC, { value, label: value }].filter((item, index, self) =>
            index === self.findIndex((e) => e.value === item.value)
          ))
          break

        case 'to':
          setPreSelectTo([...preSelectTo, { value, label: value }].filter((item, index, self) =>
            index === self.findIndex((e) => e.value === item.value)
          ))
          break
      }
      setMailContact({ ...mailContact, [name]: mails });
      setNewMailContact({ ...newMailContact, [name]: [...newMailContact[name], value] });
      value = '';
      setErrMsg('');
    } else {
      console.log("ssss")
      setErrMsg('Email is not valid!');
      return false
    }
    return true
  };

  const SignedUrl = useCallback(async (url) => {
    const { data } = await getSignedURL({ variables: { url } })
    const { getSignedUrl } = data || {};
    const { signedUrl } = getSignedUrl || {};
    return signedUrl;
  }, [getSignedURL])

  const checkMail = (e) => {
    if (e.target.value) {
      const mailRegex = new RegExp(EMAIL_PATTERN);
      const test = mailRegex.test(e.target.value);
      if (test) {
        let mails = [...mailContact[e.target.name]];
        mails.push(e.target.value);
        setMailContact({ ...mailContact, [e.target.name]: mails });
        e.target.value = '';
        setErrMsg('');
      } else {
        console.log("yyyy")
        setErrMsg('Email is not valid!');
      }
    }
  };

  const removeAttachment = (indexOf: number) => {
    let files = [...selectedFiles];
    files = files.filter((item, index) => index !== indexOf);
    setSelectedFiles(files);
  };

  return (
    <>
      <Form>
        <div className="to-header">
          <InputGroup className="shadow-none">
            <InputGroupAddon addonType="prepend" className="styleCss">To</InputGroupAddon>
            <div style={{ width: '85%' }} className="">
              <div className="d-flex">
                <Select
                  name="to"
                  value={mailContact.to?.map((item) => { return { value: item, label: item } })}
                  isMulti
                  onChange={(value) => {
                    if (value.length === mailContact.to.length + 1) {
                      mailSelected(value[value.length - 1]?.value, 'to')
                    } else {
                      setMailContact({
                        ...mailContact,
                        to: value?.map(({ value }) => value),
                      });
                    }
                  }}
                  styles={{ ...customSelectDropDownForEmail(theme) }}
                  options={preSelectTo}
                />
              </div>
            </div>
          </InputGroup>
        </div>
        <div className="border border-bottom-0"
          style={{ borderColor: '#e5e9f8' }}>
          <InputGroup className="shadow-none border-bottom-0 rounded-0">
            <InputGroupAddon addonType="prepend" className="styleCss" >
              Cc
            </InputGroupAddon>
            <div style={{ width: '85%' }} className="">
              <div className="d-flex">
                <Select
                  name="ccContacts"
                  value={mailContact.ccContacts?.map((item) => { return { value: item, label: item } })}
                  isMulti
                  onChange={(value) => {
                    if (value.length === mailContact.ccContacts.length + 1) {
                      mailSelected(value[value.length - 1]?.value, 'ccContacts')
                    } else {
                      setMailContact({
                        ...mailContact,
                        ccContacts: value?.map(({ value }) => value),
                      });
                    }
                  }}
                  onCreateOption={(inputValue) => {
                    mailSelected(inputValue, 'ccContacts')
                  }}
                  styles={{ ...customSelectDropDownForEmail(theme) }}
                  options={preSelectCC}
                />
              </div>

            </div>
          </InputGroup>
        </div>

        <div
          className="border border-bottom-0"
          style={{ borderColor: '#e5e9f8' }}
        >
          <InputGroup className="shadow-none">
            <InputGroupAddon
              addonType="prepend"
              className="styleCss"
            >
              Bcc
            </InputGroupAddon>
            <div style={{ width: '85%' }} className="">
              <div className="d-flex">
                <Select
                  name="bccContacts"
                  value={mailContact.bccContacts?.map((item) => { return { value: item, label: item } })}
                  isMulti
                  onChange={(value) => {
                    if (value.length === mailContact.bccContacts.length + 1) {
                      mailSelected(value[value.length - 1]?.value, 'bccContacts')
                    } else {
                      setMailContact({
                        ...mailContact,
                        bccContacts: value?.map(({ value }) => value),
                      });
                    }
                  }}
                  styles={{ ...customSelectDropDownForEmail(theme) }}
                  options={preSelectBCC}
                />
              </div>
            </div>
          </InputGroup>
        </div>

        <div className="to-subject">
          <InputGroup className="shadow-none">
            <InputGroupAddon addonType="prepend" className="styleCss">
              Subject
            </InputGroupAddon>
            <Input
              autoComplete="off"
              className="border-0"
              name="subject"
              value={subject!}
              onChange={({ target: { value } }) => {
                setSubject(value);
              }}
            />
          </InputGroup>
        </div>
        <div>
          <div className="editor-height">
            <ReactQuill
              value={bodyContent || ''}
              ref={quillRef}
              modules={modules}
              onChange={(value) => {
                if (reactQuillReg.test(value)) {
                  setBodyContent('');
                } else {
                  setBodyContent(value);
                }
              }}
            />
          </div>
          {uploadingImage && <small style={{ color: "#eaa827" }}>uploading Image <Spinner size="sm" /></small>}

          <div className="user-info upload-btn-wrapper mt-3">
            <Input
              type="file"
              // accept=".doc, .docx,.pdf"
              onChange={async ({ target }) => {
                setFileErr('');
                setUploading(true)
                const { fileName, source } = await uploadFile(
                  new File([target.files![0]!], target.files![0]!.name, { type: target.files![0]!.type }),
                  bucketFolders.invoiceAttachments,
                  false, "attachment"
                )
                setUploading(false)

                setSelectedFiles([...selectedFiles, { source, fileName }]);
              }}
            />

            <Button
              type="button"
              disabled={uploading}
              className="btn-outline_prime border-none bg-transparent px-0"
            >
              <AttachFileIcon /> Attach File
              {uploading && (
                <Spinner size="sm" style={{ color: "#eaa827" }} className="mr-2" />
              )}
            </Button>
          </div>
          <small className="d-block text-info">{fileErr}</small>

          <div className="">
            <Badge
              color="light"
              className="mx-1 my-auto text-truncate text-14 font-weight-normal textTruncateWidth text-myWarning badge-submission-light"
              style={{
                padding: '5px 20px 5px 5px',
                cursor: 'pointer',
              }}
              onClick={async () => {
                setViewDocument(true)
                setDocumentUrl(await SignedUrl(source))
                setDocumentName(fileName)
              }}
            >
              {fileName}
            </Badge>
            {[...selectedFiles]?.map(({ source, fileName }, index) => (
              <div>
                <div className="d-flex pt-2 mb-2  upload-document-list">
                  <div
                    className="position-relative  d-flex"
                    key={index}
                  >
                    <Badge
                      color="light"
                      className="mx-1 my-auto text-truncate text-14 font-weight-normal textTruncateWidth text-myWarning badge-submission-light"
                      style={{
                        padding: '5px 20px 5px 5px',
                        cursor: 'pointer',
                      }}
                      onClick={async () => {
                        setViewDocument(true)
                        setDocumentUrl(await SignedUrl(source))
                        setDocumentName(fileName)
                      }}
                    >
                      {fileName}
                    </Badge>
                    <span
                      className="forMailRemove text-myWarning"
                      style={{ top: '-2px' }}
                      onClick={() => removeAttachment(index)}
                    >
                      x
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <small className="text-danger">{errMsg}</small>
      </Form>

      <Modal
        isOpen={viewDocument}
        toggle={() => setViewDocument(!viewDocument)}
        centered={true}
        size="xl"
        className="revampDialog border-0"
      >
        <div className="pdf-viewer">
          <Preview
            documentUrl={documentUrl}
            documentName={`${documentName}.pdf`}
            isDownload={true}
          />
        </div>
      </Modal>
    </>
  )
}
export default EmailDraft