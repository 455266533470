export const uploadingInterviewAttachmentsFiles = async (
  interviewEmailId: string,
  files: File[]
) => {
  try {
    const formData = new FormData();

    // Appending files
    for (const item of files) {
      formData.append("files", item, item.name);
    }

    // Appending interviewEmailId
    formData.append("interviewEmailId", interviewEmailId);

    const apiBaseURL =
      process.env.REACT_APP_API_BASE_URL || "http://localhost:3001";
    let response: any = await fetch(
      `${apiBaseURL}/candidate-pipelining-interview/files-uploading`,
      {
        method: "POST",
        body: formData,
        headers: {
          Authorization: "Bearer " + localStorage.token,
        },
      }
    );
    response = await response.json();
    return response;
  } catch (error) {
    throw error;
  }
};

// export const convertTZ = (date: Date | string, tzString: string) => {
//   return new Date(date).toLocaleString("en-US", { timeZone: tzString });
// };
