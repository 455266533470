import {
  Form,
} from 'reactstrap';
import './client-calendar.scss';
import CustomButton from '../../../../../components/layout/CustomButton';
import { useForm } from 'react-hook-form';
import { useLazyQuery, useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { useState, useContext, useEffect } from 'react';
import { RE_SEND_INVITE, SEND_INTERVIEW_EMAIL, SEND_INVITE } from '../../gql';
import { uploadingInterviewAttachmentsFiles } from '../utils';
import ClientClendarInviteCombined from './ClientClendarInviteCombined';
import ClientBox from './ClientBox';
import { AppContext } from '../../../../../context';
import OutlookLogin from '../../OutlookLogin';
import { IAdditionalFileAttachment } from '../../interview-interfaces';

import { FETCH_COMPANY_OWNER_DETAILS } from '../../../../company/gql';
import {
  Sidedrawer,
  DrawerHeader,
} from '../../../../../components/layout/Sidedrawer';
import moment from 'moment';

export interface ISingleCandidateData {
  name: string;
  jobLocation: string;
  company: string;
  jobTitle: string;
}

export default function ClientCalendarInviteModal({
  interviewId,
  clientCalendarInviteModal,
  type,
  reFetchInterviewEmail,
  from,
  skipIt,
  jobApplicantId,
  candidateId,
  clientDefaultInviteFromInterview,
  fromEdit,
  jobId,
  clientInterviewEmailData,
  companyId,
  clientCalendarInviteModalToggle,
  reFetchInterviews,
  name,
}) {
  const { register, errors, control, handleSubmit, reset, watch, setValue } =
    useForm();

  const [errorListEmail, setErrorListEmail] = useState<any>([]);
  const [selectedClientEmails, setSelectedClientEmails] = useState<Array<any>>(
    []
  );
  const [ownerAndShares, setOwnerAndShares] = useState<Array<any>>([]);
  const [, setCompanyOwner] = useState<any>();
  const [attachments, setAttachments] = useState<any>([]);
  const [disableInviteBtn, setDisableInviteBtn] = useState<boolean>(false);
  const [additionalFileAttachments, setAdditionalFileAttachments] = useState<
    Array<IAdditionalFileAttachment>
  >([]);
  const { user } = useContext(AppContext);

  const [_sendInvite] = useMutation(SEND_INVITE);
  const [_reSendInvite] = useMutation(RE_SEND_INVITE);

  const [getCompanyDetail, { data }] = useLazyQuery(
    FETCH_COMPANY_OWNER_DETAILS
  );

  const [_sendInterviewEmail] = useMutation(SEND_INTERVIEW_EMAIL);

  const onSendInvite = async (values: any) => {
    const dateTime = `${clientDefaultInviteFromInterview?.interviewDateTime} ${clientDefaultInviteFromInterview?.time}`
    const formatedDateAndTime= moment(dateTime).format(
      `YYYY-MM-DDTHH:mm:ss${clientDefaultInviteFromInterview?.timeZoneId}`
    );
    
    let finalObject: any = {
      interviewId,
      subject: values?.subject,
      body: values?.details,
      location: values?.location,
      isToClient: true,
      interviewEmailDateTime:formatedDateAndTime
    };

    if (selectedClientEmails?.length) {
      let emailValid = selectedClientEmails?.filter((item) => !item?.value);
      if (emailValid?.length) {
        setErrorListEmail(emailValid);
        return;
      }

      finalObject = {
        ...finalObject,
        includeCandidateResume: true,
        includeCandidateWriteup: true,
        selectedClientEmails: selectedClientEmails?.map((item) => item?.value),
      };
    } else {
      setErrorListEmail([]);
    }

    setDisableInviteBtn(true);
    const sendingInviteResponse = await _sendInvite({
      variables: {
        sendInviteDTO: finalObject,
      },
    });

    if (sendingInviteResponse?.data) {
      let filesResponse: any;

      if (attachments?.length) {
        filesResponse = await uploadingInterviewAttachmentsFiles(
          sendingInviteResponse?.data?.sendInvite?.id,
          attachments
        );
      }

      if (attachments?.length ? filesResponse : true) {
        const sendEmailAttachmentsResponse = await _sendInterviewEmail({
          variables: {
            sendInterviewEmailDTO: {
              candidateId,
              additionalFiles: additionalFileAttachments,
              interviewEmailId: sendingInviteResponse?.data?.sendInvite?.id,
              jobOrderId: jobId,
              fromUserId: user?.id,
            },
          },
        });

        if (sendEmailAttachmentsResponse) {
          setDisableInviteBtn(false);
          reFetchInterviews();
          reFetchInterviewEmail();
          clientCalendarInviteModalToggle();
          toast.success(`Invite sent for client ${name}`);
          clientCalendarInviteModalToggle();
        }
      }
    }
  };

  useEffect(() => {
    setErrorListEmail([]);
  }, []);

  useEffect(() => {
    if (companyId) {
      getCompanyDetail({ variables: { companyId } });
    }

    // eslint-disable-next-line
  }, [companyId]);

  useEffect(() => {
    if (data?.getCompany) {
      let owner = data?.getCompany?.ownerAndSharees?.find(
        (item) => item?.ownerType === 'OWNER'
      );
      let ownerAndSharee = data?.getCompany?.ownerAndSharees?.map(
        (item) => item?.owner?.id
      );
      setCompanyOwner(owner?.owner);
      setOwnerAndShares(ownerAndSharee);
    }
  }, [data?.getCompany]);

  const onReSendInvite = async (values: any) => {
    let finalObject: any = {
      interviewId,
      interviewEmailId: clientInterviewEmailData?.id,
      subject: values?.subject,
      body: values?.details,
      location: values?.location,
      isToClient: true,
      interviewEmailDateTime: clientInterviewEmailData?.interviewEmailDateTime,
    };

    if (selectedClientEmails?.length) {
      let emailValid = selectedClientEmails?.filter((item) => !item?.value);
      if (emailValid?.length) {
        setErrorListEmail(emailValid);
        return;
      }
      finalObject = {
        ...finalObject,
        includeCandidateResume: true,
        includeCandidateWriteup: true,
        selectedClientEmails: selectedClientEmails?.map((item) => item?.value),
      };
    } else {
      setErrorListEmail([]);
    }

    setDisableInviteBtn(true);
    const sendingInviteResponse = await _reSendInvite({
      variables: {
        reSendInviteDTO: finalObject,
      },
    });

    if (sendingInviteResponse?.data) {
      let filesResponse: any;

      if (attachments.length) {
        filesResponse = await uploadingInterviewAttachmentsFiles(
          sendingInviteResponse?.data?.reSendInvite?.id,
          attachments
        );
      }

      if (attachments?.length ? filesResponse : true) {
        const sendEmailAttachmentsResponse = await _sendInterviewEmail({
          variables: {
            sendInterviewEmailDTO: {
              candidateId,
              additionalFiles: additionalFileAttachments,
              interviewEmailId: sendingInviteResponse?.data?.reSendInvite?.id,
              jobOrderId: jobId,
              fromUserId: user?.id,
            },
          },
        });

        if (sendEmailAttachmentsResponse) {
          setDisableInviteBtn(false);
          reFetchInterviews();
          reFetchInterviewEmail();
          clientCalendarInviteModalToggle();
          toast.success(`Invite resent for client ${name}`);
        }
      }
    }
  };

  const pipeSelectedClientNames = (data: any) => {
    setSelectedClientEmails(data);
  };

  const recievePipeSelectedFiles = (data: any) => {
    setAttachments(data);
  };

  const onAdditionalFileAttachmentsHandler = (
    comingAdditionalFile: IAdditionalFileAttachment
  ) => {
    const currentIndex = additionalFileAttachments?.findIndex(
      (item) => item.fileName === comingAdditionalFile.fileName
    );
    const newChecked = [...additionalFileAttachments];

    if (currentIndex === -1) {
      newChecked.push(comingAdditionalFile);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setAdditionalFileAttachments(newChecked);
  };

  return (
    <div>
      <Sidedrawer toggle={() => {}} isOpen={clientCalendarInviteModal}>
        <div className="mb-3">
          <DrawerHeader
            prevClick={clientCalendarInviteModalToggle}
            heading={
              fromEdit
                ? 'Update Client Calendar Invite'
                : 'Client Calendar Invite'
            }
            nextButtonView={false}
          />
        </div>

        {!user?.outLookToken && (
          <div className="modalHeaderCustom py-2 mb-2">
            <OutlookLogin jobOrderId={jobId} />
          </div>
        )}

        <Form>
          <div className="jobOrderRevamp p-0 bg-transparent m-0">
            <div className="d-flex justify-content-between mb-2 contentGridLabel">
              <label>Interview Type</label>
              <h6>{type}</h6>
            </div>

            <div className="d-flex justify-content-between mb-2 contentGridLabel">
              <label>Candidate Name</label>
              <h6>{name}</h6>
            </div>

            <div className="d-flex justify-content-between mb-2 contentGridLabel">
              <label>From Name</label>
              <h6>{user?.fullName || '--'}</h6>
            </div>

            <div className="d-flex justify-content-between mb-2 contentGridLabel">
              <label>From Email</label>
              <h6>{user?.email || '--'}</h6>
            </div>
          </div>

          <ClientBox
            errorListEmail={errorListEmail}
            setErrorListEmail={setErrorListEmail}
            fromEdit={fromEdit}
            clientInterviewEmailData={clientInterviewEmailData}
            companyId={companyId}
            pipeSelectedClientNames={pipeSelectedClientNames}
          />

          <ClientClendarInviteCombined
            setAdditionalFileAttachments={setAdditionalFileAttachments}
            onAdditionalFileAttachmentsHandler={
              onAdditionalFileAttachmentsHandler
            }
            additionalFileAttached={clientInterviewEmailData?.additionalFiles}
            candidateId={candidateId}
            candidateName={name}
            reset={reset}
            watch={watch}
            setValue={setValue}
            clientDefaultInviteFromInterview={clientDefaultInviteFromInterview}
            jobApplicantId={jobApplicantId}
            clientInterviewEmailData={clientInterviewEmailData}
            fromEdit={fromEdit}
            setPipeSelectedFiles={recievePipeSelectedFiles}
            register={register}
            errors={errors}
            control={control}
          />
          <div className="text-right">
            {!user?.outLookToken ? (
              <CustomButton
                disabled={!user?.outLookToken}
                buttonText={`${fromEdit ? 'Resend' : 'Send'} Invite`}
                buttonColor={'primary'}
                loading={disableInviteBtn}
                buttonType="button"
              />
            ) : (
              <CustomButton
                disabled={
                  !(
                    selectedClientEmails?.length &&
                    ownerAndShares?.includes(user?.id) &&
                    !errorListEmail?.length
                  ) || disableInviteBtn
                }
                buttonText={`${fromEdit ? 'Resend' : 'Send'} Invite`}
                buttonColor={'primary'}
                loading={disableInviteBtn}
                buttonType="button"
                buttonClick={
                  fromEdit
                    ? handleSubmit(onReSendInvite)
                    : handleSubmit(onSendInvite)
                }
              />
            )}
          </div>
        </Form>
      </Sidedrawer>
    </div>
  );
}
