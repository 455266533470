import { FC, useEffect } from "react";
import { Link } from "react-router-dom";
import { Container } from "reactstrap";
import unAuthorizedIcon from "../assets/images/unauthorized.png";

const UnAuthorized: FC = () => {
  useEffect(() => {
    document.title = "UnAuthorized (401) - RealREPP";

    // eslint-disable-next-line
  }, []);

  return (
    <div className="page-not-found">
      <div className="inner">
        <Container>
          <img
            src={unAuthorizedIcon}
            alt="Unauthorized Person"
            width="200"
            height="200"
          />

          <h1 className="">You are not an authorized person</h1>

          <p>
            Uh ho! We can't seem to find the page you are looking for. Lets go
            back where you will meet us.
          </p>

          <Link to="/">Go To Homepage</Link>
        </Container>
      </div>
    </div>
  );
};
export default UnAuthorized;
