// packages block
import { useContext, useEffect, useState } from "react";
import Select from "react-select";
import { toast } from "react-toastify";
import { useMutation } from "@apollo/client";
import { Controller, useForm } from "react-hook-form";
import { Badge, Button, Form, Label, Modal, ModalBody, ModalFooter } from "reactstrap";
// components block
import InputField from "../../../components/layout/InputField";
// constant and graphql block
import { ADD_NEW_CANDIDATE_REFERENCE, UPDATE_CANDIDATE_REFERENCE } from "./gql";
import { EMAIL_PATTERN, PHONE_PATTERN, REFERENCE_STATUS } from "../../../utils/constant";

import CLOSE_ICON from "../../../assets/images/close-icon.svg";
import { AppContext } from "../../../context";
import { customSelectDropDown } from "../../../components/styled/customSelect";

const AddReferenceModal = ({
  isOpen,
  toggle,
  refetch,
  candidateId,
  referenceId,
  modalHeader,
  referenceData,
}) => {
  const { theme } = useContext(AppContext)
  const { errors, handleSubmit, register, reset, control, getValues } =
    useForm();
  const [errMsg] = useState("");
  const [_createReference] = useMutation(ADD_NEW_CANDIDATE_REFERENCE);
  const [updateReference] = useMutation(UPDATE_CANDIDATE_REFERENCE);

  const addOrUpdateReference = async (values: any) => {
    values.status = values?.status?.value;

    if (!referenceData?.id) {
      values.candidate = candidateId;
      let res = await _createReference({
        variables: { createReferenceDTO: values },
      });
      if (res?.data) {
        toast.success("Reference Successfully Created!");
        refetch();
        toggle();
      }
    } else {
      let res = await updateReference({
        variables: {
          referenceId: referenceData?.id,
          createReferenceDTO: values,
        },
      });
      if (res?.data) {
        toast.success("Reference Successfully Updated!");
        refetch();
        toggle();
      }
    }
  };

  useEffect(() => {
    if (referenceData) {
      let values = { ...referenceData };
      values.status = {
        value: values?.status,
        label: values?.status,
      };
      reset({ ...values });
    }

    // eslint-disable-next-line
  }, [referenceData]);

  const customStyles = {
    control: (styles, provided) => ({
      ...styles,

      fontSize: 12,
    }),

    option: (provided, state) => ({
      ...provided,
      fontSize: 12,
    }),

    container: (provided, state) => ({
      ...provided,
      fontSize: 12,
    }),
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className="revampDialog revampDialogWidth add-reference-dialog"
    >
      <div className="modalHeaderCustom d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          <h5 className="m-0">{modalHeader}</h5>

          <div className="ml-2">
            {referenceData?.status && (
              <Badge
                color={
                  referenceData?.status === "COMPLETED"
                    ? "success"
                    : referenceData?.status === "PENDING"
                      ? "warning"
                      : ""
                }
                className="text-capitalize"
              >
                {referenceData?.status ?? "--"}
              </Badge>
            )}
          </div>
        </div>

        <Button
          className="p-0 bg-transparent border-0"
          onClick={() => toggle()}
        >
          <img src={CLOSE_ICON} alt="close" />
        </Button>
      </div>
      <Form onSubmit={handleSubmit(addOrUpdateReference)}>
        <ModalBody className="modalBodyScroll candidateInterview">
          <InputField
            label="Reference Name"
            required={true}
            inputtype="text"
            isRequired={true}
            inputid="referenceName"
            placeholder={"Jessica Hasson"}
            inputRef={register({
              required: {
                value: true,
                message: "Reference Name is required!",
              },
            })}
            errMsg={errors?.referenceName?.message}
          />

          <Label>
            Status <span className="text-danger">*</span>
          </Label>
          <Controller
            name="status"
            render={({ onChange }) => (
              <Select
                className="mb-2"
                options={REFERENCE_STATUS}
                onChange={(value) => {
                  onChange(value);
                }}
                value={getValues()?.status}
                placeholder="Status"
                styles={{ ...customSelectDropDown(theme) }}
              />
            )}
            control={control}
            styles={customStyles}
          />

          <InputField
            inputtype="text"
            isRequired={false}
            label="Phone Number"
            inputid="phoneNumber"
            placeholder={"(207)555-0119"}
            inputRef={register({
              required: {
                value: false,
                message: "Phone number not valid!",
              },
              pattern: {
                value: PHONE_PATTERN,
                message: "Phone Number is not valid!",
              },
            })}
            errMsg={errors?.phoneNumber?.message}
          />

          <InputField
            label="Relationship to Candidate"
            inputtype="text"
            inputid="relationshipToCandidate"
            placeholder={"Brother"}
            inputRef={register({ required: false })}
          />

          <InputField
            inputtype="text"
            isRequired={false}
            label="Reference Email"
            inputid="email"
            placeholder="someone@example.com"
            inputRef={register({
              required: {
                value: false,
                message: "Email is not valid!",
              },
              pattern: {
                value: EMAIL_PATTERN,
                message: "Email is not valid!",
              },
            })}
            errMsg={errors?.email?.message}
          />

          <div className="mb-2">
            <Label>Part of Interview Process to Hire</Label>
            <textarea
              className="form-control"
              rows={4}
              name="partOfInterviewProcesstoHire"
              id="partOfInterviewProcesstoHire"
              placeholder="Notes ..."
              ref={register({ required: false })}
            ></textarea>
          </div>

          <div className="mb-2">
            <Label>Main Job Duties/Responsibilities</Label>
            <textarea
              className="form-control"
              rows={4}
              id="mainDuty"
              name="mainDuty"
              placeholder="Notes ..."
              ref={register({ required: false })}
            ></textarea>
          </div>

          <div className="mb-2">
            <Label>Candidate Strength</Label>
            <textarea
              className="form-control"
              rows={4}
              id="candidateStrength"
              ref={register({ required: false })}
              name="candidateStrength"
              placeholder="Notes ..."
            ></textarea>
          </div>

          <div className="mb-2">
            <Label>Improvement</Label>
            <textarea
              className="form-control"
              rows={4}
              id="improvement"
              name="improvement"
              placeholder="Notes ..."
              ref={register({ required: false })}
            ></textarea>
          </div>

          <div className="mb-2">
            <Label>Computer Program/Software</Label>
            <textarea
              className="form-control"
              rows={4}
              id="softwareUsed"
              name="softwareUsed"
              placeholder="Notes ..."
              ref={register({ required: false })}
            ></textarea>
          </div>

          <div className="mb-2">
            <Label>Personality</Label>
            <textarea
              className="form-control"
              rows={4}
              id="personality"
              name="personality"
              placeholder="Notes ..."
              ref={register({ required: false })}
            ></textarea>
          </div>

          <div className="mb-2">
            <Label>Eligibility for Rehire</Label>
            <textarea
              className="form-control"
              rows={4}
              id="eligibleForRehire"
              name="eligibleForRehire"
              ref={register({ required: false })}
              placeholder="Notes ..."
            ></textarea>
          </div>

          <div>
            <Label>Additional Comments</Label>
            <textarea
              className="form-control"
              rows={4}
              id="additionalComments"
              name="additionalComments"
              ref={register({ required: false })}
              placeholder="Notes ..."
            ></textarea>
            <small className="text-danger">{errMsg}</small>
          </div>
        </ModalBody>

        <ModalFooter className="pt-3">
          <div className="d-flex align-items-center">
            <button
              type="button"
              onClick={() => {
                toggle();
              }}
              className="buttonPrimary mr-3"
            >
              Cancel
            </button>

            <button type="submit" className="buttonGenericStyle filledButton">
              {referenceId ? "Update" : "Add"}
            </button>
          </div>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default AddReferenceModal;
