import { useMutation } from "@apollo/client";
import { useContext } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import CustomButton from "../../../components/layout/CustomButton";
import { AppContext } from "../../../context";
import { CREATE_NEW_SEQUENCE } from "./gql";
import { ICreateSequence } from "./interfaceSequence";
import AddSequenceBasic from "./sequence-components/AddSequenceBasic";
import {
  Button,
  Form,
  Label,
  Modal,
  ModalBody,
  ModalFooter
} from "reactstrap";

import CLOSE_ICON from "../../../assets/images/close-icon.svg";

const CreateSequenceModal = ({
  refetch,
  open,
  toggle,
  setBusinessDevelopmentSequences,
}) => {
  const { errors, handleSubmit, register, control, getValues, setValue } =
    useForm();

  const [addNewSequence, { loading }] = useMutation(CREATE_NEW_SEQUENCE);
  const { user } = useContext(AppContext);

  const createNewSequence = async (values: ICreateSequence) => {
    const sequenceType = values?.sequenceType;
    const sequenceName = values?.name;
    const clone_campaign_id = values?.clone_compaign_id?.value;

    values.name =
      sequenceType.value === "BUSINESS_DEVELOPMENT"
        ? `${user?.namePrefix}-BD-${sequenceName ? sequenceName : ""}`
        : `${user?.namePrefix}-Recruiting-${sequenceName ? sequenceName : ""}`;

    const resultOfRequest = await addNewSequence({
      variables: {
        createNewSequenceInput: { name: values?.name, clone_campaign_id },
      },
    });

    if (resultOfRequest?.data) {
      setBusinessDevelopmentSequences(
        sequenceType.value === "BUSINESS_DEVELOPMENT" ? true : false
      );
      toast.success(
        `${values?.name} successfully added!, and will show up in a few minutes.`,
        { autoClose: 10000 }
      );
      refetch(sequenceType.value === "BUSINESS_DEVELOPMENT" ? true : false);
      toggle();
    }
  };

  return (
    <Modal
      isOpen={open}
      toggle={toggle}
      className="revampDialog revampDialogWidth"
    >
      <div className="modalHeaderCustom d-flex align-items-center justify-content-between">
        <h5 className="m-0">Create New Sequence</h5>

        <Button className="p-0 bg-transparent border-0" onClick={toggle}>
          <img src={CLOSE_ICON} alt="close" />
        </Button>
      </div>

      <ModalBody>
        <Label>Build a new sequence to engage your prospects</Label>

        <Form onSubmit={handleSubmit(createNewSequence)}>
          <AddSequenceBasic
            control={control}
            register={register}
            errors={errors}
            setValue={setValue}
            getValues={getValues}
          />

          <ModalFooter className="p-0 pt-2">
            <button
              onClick={toggle}
              className="buttonPrimary mr-3"
              type="button"
            >
              Cancel
            </button>

            <CustomButton
              buttonText="Create"
              loading={loading}
              disabled={loading}
              buttonType="submit"
              className="border-0 px-5"
            />
          </ModalFooter>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default CreateSequenceModal;
