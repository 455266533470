import { useLazyQuery, useMutation } from "@apollo/client";
import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  Button,
  Card,
  CardBody,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import CustomButton from "../../../components/layout/CustomButton";
import Loader from "../../../components/layout/Loader";
import { AppContext } from "../../../context";
import { createMarkup, formatDateUS } from "../../../utils/commonFn";
import { PAGE_LIMIT, ROLES } from "../../../utils/constant";
import { DELETE_NOTE, GET_NOTES } from "../../company/gql";
import deleteIcon from "../../../assets/images/delete-field.svg";
import NoDataFound from "../../../components/layout/NoDataFound";
import { Pagination } from "../../../components/layout/Pagination";
import ConfirmPopUp from "../../../components/ConfirmPopUp";
import InputField from "../../../components/layout/InputField";

export default function NotesListing({ noteableId }) {
  const page = parseInt("1");
  const [currentPage, setCurrentPage] = useState(page);
  const { userRoles } = useContext(AppContext);

  const [limit, setLimit] = useState(3);
  const [deleteNoteId, setDeleteNoteId] = useState<string>();

  const [notesModal, setNotesModal] = useState<boolean>(false);
  const [notesContent, setNoteContent] = useState<boolean>();
  const [noteConfirm, setNoteConfirm] = useState<boolean>(false);

  const [getNotes, { data: notes, loading }] = useLazyQuery(GET_NOTES);
  const [_deleteNote] = useMutation(DELETE_NOTE);

  useEffect(() => {
    if (noteableId) {
      getNotes({ variables: { id: noteableId, limit, page: currentPage } });
    }
    // eslint-disable-next-line
  }, [noteableId, limit, currentPage]);

  const deleteNote = async () => {
    await _deleteNote({ variables: { noteId: deleteNoteId } });
    toast.success("Successfully deleted!");
    getNotes({ variables: { id: noteableId, limit, page: currentPage } });
    setNoteConfirm(false);
  };

  return (
    <Card className="agreement-details activity-card mt-0">
      <div className="section-header">
        <h5 className="m-0">Client Notes</h5>
      </div>

      <CardBody>
        <Row>
          <Col md="12" sm="12">
            {loading && <Loader loaderClass="sm-height " />}

            <div>
              {notes?.getNotes.notes &&
                notes?.getNotes.notes.map((item: any) => (
                  <div className="notes-flex mb-1">
                    <div>
                      <small className="text-muted">{item?.category}</small>
                      <div className="d-flex">
                        <div
                          className="line-clamp"
                          dangerouslySetInnerHTML={createMarkup(item?.content)}
                        />
                      </div>
                      {item?.content?.length > 50 && (
                        <Button
                          className="bg-transparent text-info border-0 p-0 for-link-theme-color"
                          style={{ marginTop: "auto" }}
                          onClick={() => {
                            setNoteContent(item?.content);
                            setNotesModal(!notesModal);
                          }}
                        >
                          <small className="for-link-theme-color">
                            View Full Note
                          </small>
                        </Button>
                      )}
                      <div className="added-by">
                        <small>
                          {item.createdAt && formatDateUS(item.createdAt)}{" "}
                        </small>
                        <small className="mr-2">
                          {item.createdAt &&
                            new Date(item.createdAt).toLocaleTimeString()}
                        </small>
                        <small>{item?.user?.fullName}</small>
                      </div>
                    </div>
                    <div>
                      {userRoles &&
                        userRoles.length &&
                        userRoles.includes(ROLES.ADMIN) ? (
                        <Button
                          onClick={() => {
                            setNoteConfirm(true);
                            setDeleteNoteId(item.id);
                          }}
                          className="my-auto bg-transparent border-0 w-auto d-flex justify-content-center align-items-center"
                        >
                          <img src={deleteIcon} alt="" width="20" height="20" />
                        </Button>
                      ) : (
                        <div></div>
                      )}
                    </div>
                  </div>
                ))}

              {!loading && !notes?.getNotes?.notes.length && (
                <div className="for-position-multiselect">
                  <NoDataFound text="Notes Not Found!" />
                </div>
              )}
            </div>

            {notes?.getNotes?.notes?.length ? (
              <div className="users-pagination">
                <Pagination
                  onPageChange={(pageClicked: number) => {
                    setCurrentPage(pageClicked);
                  }}
                  pageCount={Math.ceil(notes?.getNotes?.count / limit)}
                  currentPage={currentPage}
                />

                <div className="d-flex align-items-center">
                  <div className="pagination-display paginationItemsSelect">
                    <p className="m-0">
                      {(currentPage - 1) * limit + 1} to{" "}
                      {Math.min(currentPage * limit, notes?.getNotes?.count)} of{" "}
                      {notes?.getNotes?.count}
                    </p>

                    <div className="divider"></div>

                    <label>Display</label>

                    <InputField
                      label=""
                      inputtype="select"
                      selectItems={PAGE_LIMIT.filter(
                        (item) => item.value !== limit
                      )}
                      inputid="limit"
                      placeholder={limit.toString()}
                      inputMethod={(value) => {
                        setCurrentPage(1);
                        setLimit(parseInt(value));
                      }}
                    />
                  </div>
                </div>
              </div>
            ) : null}
          </Col>
        </Row>
      </CardBody>

      <Modal
        isOpen={notesModal}
        toggle={() => setNotesModal(!notesModal)}
        id="noteModal"
      >
        <ModalHeader toggle={() => setNotesModal(!notesModal)}>
          View Note
        </ModalHeader>
        <ModalBody>
          <div
            dangerouslySetInnerHTML={createMarkup(notesContent)}
          />
        </ModalBody>
        <ModalFooter>
          <CustomButton
            buttonText="Close"
            buttonColor="default"
            buttonType="button"
            buttonClick={() => setNotesModal(!notesModal)}
            className="btn-primary"
          />
        </ModalFooter>
      </Modal>

      <ConfirmPopUp
        confirmText="Are you sure you want to delete this note?"
        isOpen={noteConfirm}
        toggle={setNoteConfirm}
        confirmAction={deleteNote}
        modalHeading="Delete Note"
        btnText="Delete"
        btnColor="primary"
        className="for-modal-styling"
      />
    </Card>
  );
}
