import { useLazyQuery, useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "reactstrap";
import CustomButton from "../../../components/layout/CustomButton";
import NoDataFound from "../../../components/layout/NoDataFound";
import { Pagination } from "../../../components/layout/Pagination";
import TableComponent from "../../../components/layout/TableComponent";

import { toast } from "react-toastify";
import DeleteIcon from "../../../assets/images/delete-field.svg";
import ConfirmPopUp from "../../../components/ConfirmPopUp";
import Loader from "../../../components/layout/Loader";
import { downloadSignedUrlFile, formatDateUS } from "../../../utils/commonFn";
import { bucketFolders, PAGE_LIMIT } from "../../../utils/constant";
import { uploadImage } from "../../../utils/helper";
import {
  CREATE_ATTACHMENTS,
  REMOVE_ATTACHMENT
} from "../../company/company-components/Attachments";
import { GET_CLIENT_DOCUMENTS } from "../gql/index";
import Preview from "./document-preview/Preview";

import InputField from "../../../components/layout/InputField";
import DragDropFile from "../../candidate/candidate-components/DragDropFile";
import AttachmentDocument from "../../company/company-components/AttachmentDocument";

const JobOrderCandidateDocument = ({
  jobOrderId,
}: {
  jobOrderId: string | null;
}) => {
  const [file, setFile] = useState<any>(null);
  const [fileName, setFileName] = useState<any>("");
  const [errorMessage, setErrorMessage] = useState("");
  const [confirmToggle, setConfirmToggle] = useState(false);
  const [signedUrl] = useState<string>();
  const [signedName] = useState<string>();
  const [clientDocumentId, setClientDocumentId] = useState("");
  const [uploadLogin, setUploadLogin] = useState(false);
  const [modal, setModal] = useState(false);
  const page = parseInt("1");
  const [currentPage,setCurrentPage] = useState(page);
  const [limit, setLimit] = useState(3);

  const toggle = () => {
    setModal(!modal);
    setFile(null);
    setFileName("");
  };

  const [getJobOrderCandidateDocument, { data, loading, }] =
    useLazyQuery(GET_CLIENT_DOCUMENTS);
  const [createAttachment] = useMutation(CREATE_ATTACHMENTS);

  const [_removeAttachment] = useMutation(REMOVE_ATTACHMENT);

  const removeCandidateDocumentHandler = async () => {
    let res = await _removeAttachment({
      variables: { attachmentId: clientDocumentId },
    });
    if (res?.data) {
      toast.success("Attachment removed successfully!");
      getJobOrderCandidateDocument({
        variables: { jobOrderId, limit, page: 1 },
      });
      setCurrentPage(1)
      setConfirmToggle(false);
    }
  };

  const uploadCandidateDocument = async () => {
    try {
      if (file) {
        setUploadLogin(true);
        let response = await uploadImage(file, bucketFolders.job, false, false);
        setUploadLogin(false);
        if (!response?.success && !response?.ok) {
          toast.error(response?.message || "Error while uploading image");
          return;
        }

        if (response?.success) {
          await createAttachment({
            variables: {
              attachableId: jobOrderId,
              attachableType: "JobOrder",
              documentUrl: response?.source,
              documentName: response?.fileName,
              driveUrl: response?.driveUrl,
            },
          });
          setFileName("");
          setFile(null);
          toggle();
          toast.success("Successfully Uploaded!");
          getJobOrderCandidateDocument({
            variables: { jobOrderId, limit, page: currentPage },
          });
        }
      } else {
        setErrorMessage("Please select a file!");
      }
    } catch (error) {
      setUploadLogin(false);
    }
  };

  const fileHanlder = (file: any) => {
    setErrorMessage("");
    if (file) {
      if (file?.size > 30 * 1024 * 1024) {
        setFile(null);
        setFileName("");
        return setErrorMessage("File size it too large (should be 30MB).");
      }
      if (
        !(
          file?.type === "application/pdf" ||
          file?.type ===
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
          file?.type === "application/msword"
        )
      ) {
        return setErrorMessage("Please select pdf, doc or docx file");
      }
      if (file) {
        setFileName(file?.name);
      }
      setFile(file);
    }
  };

  useEffect(() => {
    if (jobOrderId) {
      getJobOrderCandidateDocument({
        variables: { jobOrderId, limit, page: currentPage },
      });
    }
    // eslint-disable-next-line
  }, [jobOrderId, limit, currentPage]);

  const onDownloadAttachment = async (
    unsignedOriginalUrl: string,
    name: string
  ) => {
    downloadSignedUrlFile(unsignedOriginalUrl, name);
  };

  return (
    <div>
      <Card className="border-0">
        <CardBody className="p-0">
          <div className="user-basic-form">
            <div className="jobOrderRevamp m-0 d-flex justify-content-end">
              <button
                className="buttonGenericStyle filledButton m-0"
                onClick={toggle}
                type="button"
              >
                Upload document
              </button>
            </div>
            <div className="table-responsive">
              {loading ? (
                <Loader loaderClass="sm-height" />
              ) : (
                <TableComponent
                  tableHeader={["Name", "Uploaded By", "Uploaded At", "Action"]}
                >
                  {!loading &&
                    !data?.getClientDocuments?.clientDocuments?.length && (
                      <tbody>
                        <tr>
                          <td colSpan={4} className="p-0">
                            <div className="for-position-multiselect">
                              <NoDataFound text="No Document Found!" />
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    )}

                  <tbody>
                    {data?.getClientDocuments?.clientDocuments &&
                      data?.getClientDocuments?.clientDocuments.map(
                        (item: any) => (
                          <tr>
                            <td className="cursor-pointer">
                              <AttachmentDocument
                                onDownloadAttachment={onDownloadAttachment}
                                item={item}
                              />
                            </td>

                            <td>{item?.user?.fullName || "--"}</td>
                            <td>
                              {item?.createdAt
                                ? formatDateUS(item?.createdAt)
                                : "--"}
                            </td>

                            <td>
                              <Button
                                className="btn-delete bg-transparent border-0"
                                onClick={() => {
                                  setClientDocumentId(item?.id);
                                  setConfirmToggle(!confirmToggle);
                                }}
                              >
                                <img src={DeleteIcon} alt="" />
                              </Button>
                            </td>
                          </tr>
                        )
                      )}
                  </tbody>
                </TableComponent>
              )}
              
              {data?.getClientDocuments?.clientDocuments?.length ? (
          <div className="users-pagination">
            <Pagination
              onPageChange={(pageClicked: number) => {
                setCurrentPage(pageClicked);
              }}
              pageCount={Math.ceil(data?.getClientDocuments?.count / limit)}
              currentPage={currentPage}
            />

            <div className="d-flex align-items-center">
              <div className="pagination-display paginationItemsSelect">
                <p className="m-0">
                  {(currentPage - 1) * limit + 1} to{" "}
                  {Math.min(currentPage * limit, data?.getClientDocuments?.count)}{" "}
                  of {data?.getClientDocuments?.count}
                </p>

                <div className="divider"></div>

                <label>Display</label>

                <InputField
                  label=""
                  inputtype="select"
                  selectItems={PAGE_LIMIT.filter(
                    (item) => item.value !== limit
                  )}
                  inputid="limit"
                  placeholder={limit.toString()}
                  inputMethod={(value) => {
                    setCurrentPage(1);
                    setLimit(parseInt(value));
                  }}
                />
              </div>
            </div>
          </div>
        ) : null}


            </div>
          </div>
        </CardBody>
      </Card>
      <Modal isOpen={modal} className="revampDialog revampDialogWidth">
        <ModalHeader>Upload Client JobOrder Document</ModalHeader>
        <ModalBody>
          <div>
            <div className={"revampContainer "}>
              <div style={{ position: "relative" }}>
                <DragDropFile
                  onUpload={(file) => {
                    fileHanlder(file);
                  }}
                />
              </div>
            </div>
            <div className="mt-2 text-info text-truncate textTruncateWidth">
              {fileName}
            </div>
            {errorMessage && <div className="text-danger">{errorMessage}</div>}
          </div>
        </ModalBody>
        <ModalFooter className="mt-3">
          <button
            type="button"
            onClick={() => {
              toggle();
              setFile(null);
              setFileName("");
              setErrorMessage("");
            }}
            className="buttonPrimary mr-3"
          >
            Close
          </button>

          <CustomButton
            buttonText="Save"
            buttonColor="primary"
            buttonType="submit"
            className="big-width"
            buttonClick={uploadCandidateDocument}
            loading={uploadLogin}
            disabled={uploadLogin}
          />
        </ModalFooter>
      </Modal>

      {signedUrl && signedName && (
        <Preview
          documentUrl={signedUrl}
          documentName={data?.attachment?.documentName}
        />
      )}

      <ConfirmPopUp
        confirmText="Are you sure you want to remove this attachment?"
        isOpen={confirmToggle}
        toggle={setConfirmToggle}
        confirmAction={removeCandidateDocumentHandler}
        modalHeading="Remove Attachment"
        btnText="Confirm"
        btnColor="primary"
      />
    </div>
  );
};

export default JobOrderCandidateDocument;
