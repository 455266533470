import { useContext, useState } from "react";
import { AppContext } from "../../../../../context";
import CustomButton from "../../../../../components/layout/CustomButton";
import scvFileIcon from "../../../../../assets/images/files-csv-index.svg";
import Dropzone from "react-dropzone";
import { csvFileUploading } from "../../csv-file-uploading";
import Select from "react-select";
import { useMutation } from "@apollo/client";
import { CSV_MAP_FIELDS, CSV_MAP_FIELDS_USING_LOOKUP } from "../../gql";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Col,
} from "reactstrap";
import {
  csvMapFields,
  lookupEmailOptions,
} from "../../../../../utils/constant";
import "../../contacts.scss";
import { customSelectStyleNew } from "../../../../../components/styled/customSelect";

const CSVIndex = ({ compaignId, modal, toggle, reFetchContacts }) => {
  const { theme } = useContext(AppContext);
  const [fileName, setFileName] = useState<string>();
  const [lastStep, setLastStep] = useState<boolean>(false);
  const [lookupType, setLookupType] = useState<string>("");
  const [file, setFile] = useState<any>();
  const [csvId, setCSVId] = useState<string>();
  const [csvLoading, setCsvLoading] = useState<boolean>();
  const [currentIndex, setCurrentIndex] = useState<number>(1);
  const [csvColumns, setCsvColumns] = useState<any>();
  const [selectVal, setSelectVal] = useState<any>();

  const [mapFieldsCSVToInterseller, { loading }] = useMutation(CSV_MAP_FIELDS);
  const [
    mapFieldsCSVToIntersellerUsingLookups,
    { loading: mapFieldsCSVToIntersellerUsingLookupsLoading },
  ] = useMutation(CSV_MAP_FIELDS_USING_LOOKUP);

  const handleDrop = (acceptedFiles: File[]) => {
    const fileAtIndexZero: any = acceptedFiles[0];
    setFile(fileAtIndexZero);
    setFileName(fileAtIndexZero?.name);
  };

  const uploadingCSVFileToInterseller = async () => {
    setCsvLoading(true);
    const response = await csvFileUploading(file);

    const newSelectVal = response?.columns?.map((column) => {
      return { label: column?.header, value: column?.index };
    });

    setCSVId(response?._id);
    setSelectVal(newSelectVal);
    setCsvColumns(response?.columns?.filter((item: any) => item.header !== ""));
    setCsvLoading(false);
  };

  const settingIndexForMapping = (
    replaceIt: string,
    withIt: number,
    field: any,
    index: number
  ) => {
    let arr = [...csvColumns];
    let item = arr?.find((item) => item?.header === replaceIt);
    item.index = withIt;
    let newSelectVal = [...selectVal];
    newSelectVal = newSelectVal?.map((el, ind) => {
      if (ind === index) {
        return field;
      } else if (
        ind !== index &&
        el &&
        el.label === field?.label &&
        el.value === field?.value
      ) {
        return { label: "Please select a column", value: undefined };
      }
      return el;
    });

    setSelectVal(newSelectVal);
    setCsvColumns(arr);
  };

  const goPrevious = () => {
    setCurrentIndex(currentIndex - 1);
  };

  const goNext = async () => {
    if (currentIndex === 1 && file) {
      await uploadingCSVFileToInterseller();
    }
  
    if (currentIndex === 2) {
      const missingEmailColumns = csvColumns?.filter(
        (item) => item.header === "email" || item.header === "Email"
      ).filter(item => item.missing);
  
      if (missingEmailColumns.length === 0) {
        const obj = {};
  
        csvColumns?.forEach((item) => {
          const key = item.header;
          const value = item.index;
          obj[key] = value;
        });
  
        const response = await mapFieldsCSVToInterseller({
          variables: {
            compaignId,
            csvId,
            lookup: false,
            mapping: JSON.stringify(obj),
          },
        });
  
        if (response?.data) {
          setCurrentIndex(currentIndex + 1);
        }
      }
    }
  
    if (currentIndex === 3) {
      const missingEmailColumns = csvColumns?.filter(
        (item) => item.header === "email" || item.header === "Email"
      ).filter(item => item.missing);
  
      const obj = {};
      csvColumns?.forEach((item) => {
        const key = item.header;
        const value = item.index;
        obj[key] = value;
      });
  
      if (lookupType && lookupType !== "DONT_LOOKUP" && missingEmailColumns.length > 0) {
        const response = await mapFieldsCSVToIntersellerUsingLookups({
          variables: {
            compaignId,
            csvId,
            lookupType,
            mapping: JSON.stringify(obj),
          },
        });
  
        if (response?.data) {
          setLastStep(true);
        }
      } else {
        setLastStep(true);
      }
    }
  
    if (lastStep) {
      setCurrentIndex(1);
      reFetchContacts();
      setFileName('')
      toggle();
    } else {
      setCurrentIndex(currentIndex + 1);
    }
  };
  
  const buttonTextHandler = (): string => {
    let buttonText: string = "";
    if (currentIndex === 1) {
      buttonText = "Next Step";
    }

    if (currentIndex === 2) {
      if (
        !csvColumns?.filter(
          (item) => item.header === "email" || item.header === "Email"
        ).length
      ) {
        buttonText = "Email is required";
      }
    }

    if (
      currentIndex === 2 &&
      csvColumns?.filter(
        (item) => item.header === "email" || item.header === "Email"
      ).length
    ) {
      buttonText = "Next Step";
    }

    if (currentIndex === 3) {
      if (
        lookupType &&
        lookupType !== "DONT_LOOKUP" &&
        csvColumns?.filter(
          (item) =>
            (item.header === "email" || item.header === "Email") && item.missing
        ).length
      ) {
        buttonText = "Lookup and Import contacts!";
      } else if (
        currentIndex === 3 &&
        lookupType &&
        lookupType === "DONT_LOOKUP"
      ) {
        buttonText = "Don't import and close";
      } else {
        buttonText = "Import contacts!";
      }
    }

    if (lastStep) {
      buttonText = "Okay";
    }
    return buttonText;
  };

  const buttonDisabledHandler = (): boolean => {
    let buttonDisabled: boolean = false;
    if (currentIndex === 1) {
      if (file) {
        buttonDisabled = false;
      } else {
        buttonDisabled = true;
      }
    }

    if (currentIndex === 2) {
      if (
        !csvColumns?.filter(
          (item) => item.header === "email" || item.header === "Email"
        ).length
      ) {
        buttonDisabled = true;
      } else {
        buttonDisabled = false;
      }
    }

    return buttonDisabled;
  };

  const Step1 = () => {
    return (
      <>
        <h5 className="csv-index-h5">Start by uploading your CSV file,</h5>
        <p className="file-name-csv-index">
          {fileName ? <>{`File Name: ${fileName}`}</> : null}
        </p>

        <Dropzone onDrop={handleDrop} accept=".csv">
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps({ className: "dropzone" })}>
              <input {...getInputProps()} />

              <div className="file-upload-csv-index text-center pt-3">
                <img
                  src={scvFileIcon}
                  alt="csv-file-upload-icon"
                  className="my-5"
                />
                <p>Drag or upload your file here</p>
              </div>
            </div>
          )}
        </Dropzone>
      </>
    );
  };

  const Step2 = () => {
    return (
      <>
        <div className="d-flex justify-content-between step2-header">
          <h5>CSV Column With Sample</h5>
          <h5>Sequence Field</h5>
        </div>
        <div className="step2-style-index-csv scrolled">
          {csvColumns?.length &&
            csvColumns?.map((item, index) => {
              return (
                <>
                  <Row className="py-3">
                    <Col md={6}>
                      <h5>{item.header}</h5>
                      <p>{item.samples?.join(", ")}</p>
                    </Col>

                    <Col md={6}>
                      <Select
                        isSearchable={false}
                        defaultValue={[
                          { label: item?.header, value: item?.index },
                        ]}
                        value={
                          selectVal?.[index] || {
                            label: item?.header,
                            value: item?.index,
                          }
                        }
                        options={csvMapFields}
                        placeholder="Field Name"
                        onChange={(selectField: any) => {
                          settingIndexForMapping(
                            selectField?.label,
                            item?.index,
                            selectField,
                            index
                          );
                        }}
                        styles={{ ...customSelectStyleNew(theme) }}
                      />
                    </Col>
                  </Row>
                </>
              );
            })}
        </div>
      </>
    );
  };

  const StepEmailMissing = () => {
    return (
      <div className="step-email-missing">
        <h5>LOOKUP 2 MISSING EMAILS</h5>
        <p>
          Find missing emails in your import either using name, company name,
          company website or profle URL.
        </p>
        <Select
          name="index"
          value={
            lookupType
              ? lookupEmailOptions?.find((item) => item?.value === lookupType)
              : null
          }
          isSearchable={false}
          defaultValue={lookupEmailOptions[0]}
          options={lookupEmailOptions}
          placeholder="Looking up email"
          onChange={(selectField: any) => {
            setLookupType(selectField?.value);
          }}
          styles= {{ ...customSelectStyleNew(theme) }}
        />
      </div>
    );
  };

  const LastStep = () => {
    return (
      <div className="import-started-csv light-dark">
        <h5>Import Started</h5>
        <p>Contacts are being added to Sequence.</p>
      </div>
    );
  };

  return (
    <Modal
      isOpen={modal}
      toggle={() => toggle()}
      className="revampDialog revampDialogWidth import-csv-contacts"
    >
      <ModalHeader toggle={toggle}>
        <div className="d-flex align-items-baseline">
          <div className="d-flex align-items-center flex-wrap gap-10">
            <h5 className="m-0">Import CSV</h5>
            <span className="span-csv-index">
              Step {currentIndex} of{" "}
              {csvColumns?.filter(
                (item) =>
                  (item.header === "email" || item.header === "Email") &&
                  item.missing
              ).length
                ? "3"
                : "4"}
            </span>
            <div className="span-csv-link">
              <a
                href="https://storage.googleapis.com/realrepp-assets/static-files/CSV_SAMPLE_FOR_CONTACTS_ATS.csv"
                download
              >
                Download CSV Sample
              </a>
            </div>
          </div>
        </div>
      </ModalHeader>
      <ModalBody>
        {lastStep && currentIndex !== 1 ? (
          <LastStep />
        ) : (
          <>
            {currentIndex === 1 ? (
              <Step1 />
            ) : currentIndex === 2 ? (
              <Step2 />
            ) : csvColumns?.filter(
                (item) =>
                  (item.header === "email" || item.header === "Email") &&
                  item.missing
              ) ? (
              <StepEmailMissing />
            ) : null}
          </>
        )}
      </ModalBody>

      <ModalFooter>
        <button
          className="buttonGenericStyle buttonPrimary mr-3"
          onClick={()=>{toggle();setFileName('')}}
          type="button"
        >
          Cancel
        </button>

        {currentIndex === 2 && (
          <CustomButton
            buttonText="Previous Step"
            buttonColor="primary"
            buttonType="submit"
            className="big-width"
            buttonClick={goPrevious}
          />
        )}

        <CustomButton
          buttonText={buttonTextHandler()}
          buttonColor="primary"
          buttonType="submit"
          className="big-width"
          disabled={buttonDisabledHandler()}
          loading={
            currentIndex === 1
              ? csvLoading
              : currentIndex === 2
              ? loading
              : currentIndex === 3
              ? mapFieldsCSVToIntersellerUsingLookupsLoading
              : loading
          }
          buttonClick={goNext}
        />
      </ModalFooter>
    </Modal>
  );
};

export default CSVIndex;
