import { useEffect, useState } from "react";
import {
  intersellerContactsFilterForCard
} from "../../../../utils/constant";
import "../contacts.scss";
import ContactStatsCard from "./ContactStatsCard";

export default function ContactsCard({ data, loading, setLabel }) {
  const [selected, setSelected] = useState<string>("");

  useEffect(() => {
    setLabel(selected);
  }, [selected, setLabel]);

  const dataSet = Object.assign({}, data?.getSequenceStats);

  return (
    <div className="contacts-card-flex">
      {intersellerContactsFilterForCard.map((item) => {
        return (
          <ContactStatsCard
            selected={selected}
            handleSelect={setSelected}
            title={
              dataSet[item?.value]
                ? dataSet[item?.value]
                : dataSet[item?.value] === 0
                ? 0
                : "--"
            }
            loading={loading}
            label={item?.label}
          />
        );
      })}
    </div>
  );
}
