import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import {
  Badge,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import SEARCH_IMG from "../../assets/images/search.svg";
import InputField from "../../components/layout/InputField";
import Loader from "../../components/layout/Loader";
import NoDataFound from "../../components/layout/NoDataFound";
import Pagination from "../../components/layout/Pagination";
import TableComponent from "../../components/layout/TableComponent";
import { compactObject } from "../../utils/commonFn";
import { PAGE_LIMIT } from "../../utils/constant";
import "../company/company.scss";

import AsyncCompanyDropdown from "../../components/AsyncCompanySelect";
import { getCandidatePipelineParams } from "../../utils/getNoteParams";
import { GET_PIPELINE_LISTING } from "../job-order/gql";

const PIPELINE_LISTING_HEADER = [
  "Job Title",
  "Company Name",
  "Company Owner",
  "Location",
  "Work Type",
  "Submissions",
  "Interviews",
  "Offers",
  // "Placement",
  "Action",
];

let time = 0;

const PipelineListing = ({ location, history }: RouteComponentProps) => {

  let {
    location: { state },
  }: any = history || {};

  const page = new URLSearchParams(location.search).get("currentPage") || "1";
  const _currentPage: number = parseInt(page);
  const [currentPage, setCurrentPage] = useState(_currentPage);
  const [limit, setLimit] = useState(10);
  const [searchParams, setSearchParams] = useState<string>("");
  const [statusFilter, setStatusFilter] = useState<string>("ACTIVE");
  const [filterCheck, setFilterCheck] = useState<boolean>(state?.pendingAction ? state?.pendingAction as boolean : false);
  const [selectedCompanyId, setSelectedCompanyId] = useState<string>("");
  const [selectedCompanyName, setSelectedCompanyName] = useState<string>("");
  const [, setMyPendingActions] = useState<boolean>(false);

  const [jobOrderCandidatePipeline, { loading, error, data }] =
    useLazyQuery(GET_PIPELINE_LISTING);

  const jobOrderCandidatePipelineFetching = () => {
    setQueryParams(searchParams);

    if (!searchParams || searchParams?.length > 2) {
      jobOrderCandidatePipeline({
        variables: {
          limit,
          page: currentPage,
          searchText: searchParams,
          statusSearch: statusFilter,
          companyId: selectedCompanyId,
          myJobOrder: filterCheck,
        },
      });
    }
  };

  const setQueryParams = (searchParams: string) => {
    const criteria = compactObject({
      currentPage,
      searchParams,
      limit,
      selectedCompanyId,
      selectedCompanyName,
      filterCheck,
      statusFilter,
    });
    const urlQueryParams: any = [];
    if (Object.keys(criteria).length) {
      for (const key in criteria) {
        if (
          Object.prototype.hasOwnProperty.call(criteria, key) &&
          criteria[key]
        ) {
          urlQueryParams.push(`${key}=${criteria[key]}`);
        }
      }
      history.push(`/candidate-pipeline?${urlQueryParams.join("&")}`);
    }
  };

  const searchControl = (value: any) => {
    setQueryParams(value);
    setSearchParams(value);
    setCurrentPage(1);
    setLimit(25);
    if (!value || value?.length > 2) {
      window.clearTimeout(time);
      time = window.setTimeout(() => {
        jobOrderCandidatePipeline({
          variables: {
            limit,
            page: currentPage,
            searchText: value,
            statusSearch: statusFilter,
            companyId: selectedCompanyId,
            myJobOrder: filterCheck,
          },
        });
      }, 1000);
    }
  };

  useEffect(() => {
    document.title = "CandidatePipeline - RealREPP";

    let params: any = getCandidatePipelineParams(location.search);
    if (Object.keys(params).length) {
      setSelectedCompanyId(params.selectedCompanyId);
      setSelectedCompanyName(params.selectedCompanyName);
      setFilterCheck(params?.filterCheck === "true" ? true : false);
      setCurrentPage(params?.currentPage ? parseInt(params?.currentPage) : 1);
      setStatusFilter(params?.statusFilter);
      setSearchParams(params?.searchParams);
      setLimit(params?.limit ? parseInt(params?.limit) : 10);

    }
    if (state) {
      state && state?.pendingAction && setMyPendingActions(state?.pendingAction as boolean);
      console.log("state", state);
    }

    // eslint-disable-next-line
  }, [state, state?.pendingAction]);

  useEffect(() => {
    if (data?.jobOrderCandidatePipeline?.jobOrders?.length) {
      data?.jobOrderCandidatePipeline?.jobOrders
        ?.filter((item) => item?.isHotJob === true && `${item.id}`)
        .map((val) => val?.id);
    }

    // eslint-disable-next-lines
  }, [data]);

  useEffect(() => {
    jobOrderCandidatePipelineFetching();

    // eslint-disable-next-line
  }, [statusFilter, currentPage, limit, filterCheck, selectedCompanyId, state?.pendingAction]);

  return (

    <div className="admin-tabs team-tabs">
      <ToastContainer autoClose={3000} />

      <div className="primaryHeading">
        <h5 className="m-0">Candidate Pipeline</h5>
      </div>

      <div className="search-filter primaryHeaderSpacing">
        <div>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <img src={SEARCH_IMG} alt="search" />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              type="text"
              placeholder="Search by job title"
              value={searchParams}
              onChange={(e) => {
                setSearchParams(e.target.value);
                searchControl(e.target.value);
              }}
              className="search-by-company"
            />
          </InputGroup>

          <small className="text-info">
            {searchParams &&
              searchParams.length <= 2 &&
              " Please add more than two characters"}
          </small>
        </div>

        <div className="filter-status">
          {/* <button
            style={{
              backgroundColor: `${myPendingActions ? "#f4f3f3" : "white"}`,
              color: `${myPendingActions ? "#eaa827" : "#495057"}`,
              border: `${"1px solid #ced4da"}`,
              marginRight: `${"1rem"}`,
            }}
            className="filter-job-order mr-3"
            onClick={() => {
              setMyPendingActions(!myPendingActions);
              statusChangeOnClick();
              setQueryParams(searchParams);
              setCurrentPage(1);
              setLimit(25);
            }}
            type="button"
          >
            Pending Submission
          </button> */}

          <div className="mr-4">
            <AsyncCompanyDropdown
              width={"15rem"}
              value={{
                label: selectedCompanyName,
                value: selectedCompanyId,
              }}
              placeholder="Filter By Company name"
              onChange={(value) => {
                setQueryParams(searchParams);
                setSelectedCompanyId(value?.value);
                setSelectedCompanyName(value?.label);
                setCurrentPage(1);
                setLimit(25);
              }}
            />
          </div>
          <div className="filter-by">
            <button
              style={{
                backgroundColor: `${filterCheck ? "#f4f3f3" : "white"}`,
                color: `${filterCheck ? "#eaa827" : "#495057"}`,
                border: `${"1px solid #ced4da"}`,
                // marginRight: `${"2rem"}`,
              }}
              className="filter-job-order"
              onClick={() => {
                setFilterCheck(!filterCheck);
                setCurrentPage(1);
              }}
              type="button"
            >
              My Job Orders
            </button>

            <button
              className="buttonGenericStyle filledButton ml-3"
              onClick={() => {
                setSearchParams("");
                setStatusFilter("");
                setSelectedCompanyId("");
                setSelectedCompanyName("");
                setFilterCheck(false);
                jobOrderCandidatePipeline({
                  variables: {
                    limit,
                    page: currentPage,
                    searchText: "",
                    statusSearch: "",
                    companyId: "",
                  },
                });
              }}
            >
              Clear
            </button>
          </div>
        </div>
      </div>

      <div className="table-responsive">
        <TableComponent tableHeader={PIPELINE_LISTING_HEADER}>
          {loading && (
            <tbody>
              <tr>
                <td colSpan={14}>
                  <Loader loaderClass="sm-height " />
                </td>
              </tr>
            </tbody>
          )}

          <tbody>
            {!loading &&
              data &&
              data?.jobOrderCandidatePipeline &&
              data?.jobOrderCandidatePipeline?.jobOrders?.map(
                (item: any, index: number) => {
                  return (
                    <tr key={index} className="job-orders-tbody">
                      <td
                        className="cursor-pointer"
                        onClick={() => {
                          history.push(`/candidate-pipeline/view/${item?.id}`);
                        }}
                      >

                        {item.jobTitle}{" "}
                        {item?.submittedPendingCount > 0 && (
                          <Badge >{item?.submittedPendingCount}</Badge>
                        )}
                        {item?.submittedDeclinedCount > 0 && (
                          <Badge style={{ backgroundColor: "#E89785" }}>{item?.submittedDeclinedCount}</Badge>
                        )}
                      </td>

                      <td>{item?.company?.name || "--"}</td>
                      <td>{item?.company?.owner?.fullName || "--"}</td>
                      <td>
                        {item?.companyLocation?.city &&
                          item?.companyLocation?.state
                          ? item?.companyLocation?.city +
                          ", " +
                          item?.companyLocation?.state
                          : "--"}
                        <div className="subtitleText">
                          {item?.allowRemote && "Remote"}
                        </div>
                      </td>
                      <td>{item?.workType || "--"}</td>
                      <td>{item?.submittedCount || "--"}</td>
                      <td>{item?.interviewsCount || "--"}</td>
                      <td>{item?.offersCount || "--"}</td>
                      {/* <td>{item?.placedCount || "--"}</td> */}

                      <td>
                        <div className="d-flex align-items-center">
                          <div>
                            <Link
                              to={{
                                pathname: `/candidate-pipeline/view/${item?.id}`,
                                state: { searchParameters: location.search },
                              }}
                              className="mr-2"
                            >
                              View Details
                            </Link>
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                }
              )}
          </tbody>
          {!loading && !data?.jobOrderCandidatePipeline?.jobOrders?.length && (
            <tbody>
              <tr>
                <td colSpan={14} className="empty-table-td">
                  <NoDataFound text="No Job Order Found!" />
                </td>
              </tr>
            </tbody>
          )}
        </TableComponent>
      </div>
      {data?.jobOrderCandidatePipeline?.jobOrders?.length ? (
        <div className="users-pagination">
          <Pagination
            onPageChange={(pageClicked: number) => {
              setQueryParams(searchParams);
              setCurrentPage(pageClicked);
            }}
            pageCount={Math.ceil(
              data?.jobOrderCandidatePipeline?.count / limit
            )}
            currentPage={currentPage}
          />

          <div className="d-flex align-items-center">
            <div className="pagination-display paginationItemsSelect">
              <p className="m-0">
                {(currentPage - 1) * limit + 1} to{" "}
                {Math.min(
                  currentPage * limit,
                  data?.jobOrderCandidatePipeline?.count
                )}{" "}
                of {data?.jobOrderCandidatePipeline?.count}
              </p>

              <div className="divider"></div>

              <label>Display</label>

              <InputField
                label=""
                inputtype="select"
                selectItems={PAGE_LIMIT.filter((item) => item.value !== limit)}
                inputid="limit"
                placeholder={limit.toString()}
                inputMethod={(value: string) => {
                  setQueryParams(searchParams);
                  setCurrentPage(1);
                  setLimit(parseInt(value));
                }}
              />
            </div>
          </div>
        </div>
      ) : null}
      {error?.graphQLErrors[0]?.message}
    </div>
  );
};

export default PipelineListing;
