import { FC } from "react";

import EmptyBox from "../../../assets/images/empty.svg";

import "./style.scss";

type NODATAFOUND = {
  text?: string;
};

const NoDataFound: FC<NODATAFOUND> = ({ text="No Record Found!" }) => {
  return (
    <div className="no-data-found">
      <img src={EmptyBox} alt="empty" />
      {text}
    </div>
  );
};

export default NoDataFound;
