import CustomButton from '../../../../components/layout/CustomButton';
import { useForm } from 'react-hook-form';
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  FormGroup,
  Label,
  Row,
  Col,
  Input,
} from 'reactstrap';
import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { PAUSE_CONTACTS_FROM_SEQUENCE } from '../gql';

export default function PauseContacts({
  pauseContactsModal,
  pauseContactsToggle,
  refetchStats,
  ids,
  reFetchContacts,
  setCheckBoxArray,
}) {
  const [pausedTill, setPausedTill] = useState<string>('');
  const { errors, register, handleSubmit } = useForm();
  const [pauseContactsFromSequence] = useMutation(PAUSE_CONTACTS_FROM_SEQUENCE);

  const pauseContacts = async (values: any) => {
    const response = await pauseContactsFromSequence({
      variables: {
        pauseContactsFromSequenceInput: {
          data: {
            delay_until_boolean: values?.pause === 'PAUSE_NOW' ? true : false,
            delay_until_string:
              values?.pause === 'PAUSE_LATER' ? values?.delay_until : '',
            delayed: true,
            delayed_indefinitely:
              values?.pause === 'PAUSE_LATER' ? false : true,
          },
          ids,
        },
      },
    });

    if (response?.data) {
      reFetchContacts();
      refetchStats();
      pauseContactsToggle();
      setCheckBoxArray([]);
      toast.success(`Successfully paused ${ids?.length} contacts`);
    }
  };

  const onChangeScheduleFor = (event) => {
    setPausedTill(event?.target.value);
  };

  return (
    <div>
      <Modal
        isOpen={pauseContactsModal}
        toggle={pauseContactsToggle}
        className="revampDialog"
      >
        <ModalHeader>Pause Contacts</ModalHeader>
        <ModalBody>
          <p className="mb-2"> Choose contacts to pause</p>
          <Row>
            <Col md={12} sm={12}>
              <div onClick={onChangeScheduleFor} className="d-flex w-100">
                <FormGroup check>
                  <div className="mb-1 pl-1 form-group">
                    <label className="m-0 w-100">
                      <Input
                        type="radio"
                        name="pause"
                        value={'PAUSE_NOW'}
                        defaultChecked={true}
                        innerRef={register()}
                        className="mt-1 form-check-input"
                      />
                      <span className="checkboxTextStyle w-100 pl-lg-0 pl-2">
                        Pause Now
                      </span>
                    </label>
                  </div>
                </FormGroup>
                <FormGroup check className="ml-3">
                  <div className="mb-1 pl-1 form-group">
                    <label className="m-0 w-100">
                      <Input
                        type="radio"
                        name="pause"
                        innerRef={register()}
                        onChange={({ target }) => setPausedTill(target.value)}
                        value={'PAUSE_LATER'}
                        className="mt-1 form-check-input"
                      />
                      <span className="checkboxTextStyle w-100 pl-lg-0 pl-2">
                        Schedule For Later
                      </span>
                    </label>
                  </div>
                </FormGroup>
              </div>
            </Col>

            {pausedTill === 'PAUSE_LATER' ? (
              <Col md={12} sm={12}>
                <div className="mt-3">
                  <FormGroup>
                    <Label>
                      Pause Date
                      {pausedTill === 'PAUSE_LATER' && (
                        <span className="text-danger">*</span>
                      )}
                    </Label>
                    <Input
                      placeholder="Select Date"
                      name="delay_until"
                      type="date"
                      innerRef={register({
                        required: {
                          value: true,
                          message: 'Pause date must be selected',
                        },
                      })}
                    />
                    <small className="text-danger">
                      {errors?.delay_until?.message}
                    </small>
                  </FormGroup>
                </div>
              </Col>
            ) : null}
          </Row>
        </ModalBody>

        <ModalFooter>
          <button
            onClick={pauseContactsToggle}
            className="buttonPrimary mr-3"
            type="button"
          >
            Close
          </button>

          <CustomButton
            buttonText={`Pause ${ids?.length} Contacts`}
            buttonColor="primary"
            buttonType="button"
            buttonClick={handleSubmit(pauseContacts)}
          />
        </ModalFooter>
      </Modal>
    </div>
  );
}
