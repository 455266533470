import { gql } from "@apollo/client";

export const GET_CANDIDATE_REFERENCES = gql`
  query AllReferenceList(
    $page: Int!
    $limit: Int!
    $searchText: String
    $candidateId: String
  ) {
    allReferenceList(
      payload: { page: $page, limit: $limit }
      filters: { searchText: $searchText, candidateId: $candidateId }
    ) {
      references {
        id
        candidate {
          id
          firstName
          lastName
        }
        referenceName
        phoneNumber
        email
        relationshipToCandidate
        partOfInterviewProcesstoHire
        mainDuty
        candidateStrength
        improvement
        softwareUsed
        personality
        eligibleForRehire
        additionalComments
        status
        completedAt
        updatedBy {
          id
          firstName
          lastName
        }
        createdBy {
          id
          firstName
          lastName
        }
        createdAt
        updatedAt
      }
      count
    }
  }
`;

export const GET_REFERENCE_FROM_RID = gql`
  query GetReference($referenceId: String!) {
    getReference(referenceId: $referenceId) {
      id
      candidate {
        id
        firstName
        lastName
      }
      referenceName
      phoneNumber
      email
      relationshipToCandidate
      partOfInterviewProcesstoHire
      mainDuty
      candidateStrength
      improvement
      softwareUsed
      personality
      eligibleForRehire
      additionalComments
      status
      completedAt
      updatedBy {
        id
        firstName
        lastName
      }
      createdBy {
        id
        firstName
        lastName
      }
      createdAt
      updatedAt
    }
  }
`;

export const DELETE_CANDIDATE_REFERENCE = gql`
  mutation DeleteReference($referenceId: String!) {
    deleteCandidateReference(referenceId: $referenceId) {
      message
      status
    }
  }
`;

export const ADD_NEW_CANDIDATE_REFERENCE = gql`
  mutation AddReference($createReferenceDTO: CreateReferenceDTO!) {
    createReference(createReferenceDTO: $createReferenceDTO) {
      message
      status
    }
  }
`;

export const UPDATE_CANDIDATE_REFERENCE = gql`
  mutation UpdateReference(
    $referenceId: String!
    $createReferenceDTO: CreateReferenceDTO!
  ) {
    updateReference(
      referenceId: $referenceId
      createReferenceDTO: $createReferenceDTO
    ) {
      message
      status
    }
  }
`;
