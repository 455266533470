
import { Spinner } from "reactstrap";
import "../contacts.scss";

export default function ContactStatsCard({ title, label, loading, handleSelect, selected }) {
 const handlerClick = () => handleSelect(label);
  return (
    <>
      <div className={`contact-card contact-card-new mt-4 ${selected === label ? 'active' : ''}`} onClick={handlerClick}>
        {loading ? (
          <div>
            <Spinner size='sm' style={{ color: "#eaa827" }} className='mr-2' />
          </div>
        ) : (
          <h5>{title}</h5>
        )}
        <label  className={ selected === label ? 'active' :""}>{label}</label>
      </div>
    </>
  );
}
