const ClientCompanyDetails = ({ timecardApprover }) => {

  return (
    <div className="jobOrderRevamp">
      <h5>Time Card Approver</h5>

      <div className="d-flex justify-content-between mb-2 contentGridLabel">
        <label>First Name</label>
        <h6>{timecardApprover?.firstName || "--"}</h6>
      </div>

      <div className="d-flex justify-content-between mb-2 contentGridLabel">
        <label>MI</label>
        <h6>{timecardApprover?.firstName || "--"}</h6>
      </div>

      <div className="d-flex justify-content-between mb-2 contentGridLabel">
        <label>Last Name</label>
        <h6>{timecardApprover?.lastName || "--"}</h6>
      </div>

      <div className="d-flex justify-content-between mb-2 contentGridLabel">
        <label>Street Address</label>
        <h6>{timecardApprover?.streetAddress || "--"}</h6>
      </div>

      <div className="d-flex justify-content-between mb-2 contentGridLabel">
        <label>City</label>
        <h6>{timecardApprover?.city || "--"}</h6>
      </div>

      <div className="d-flex justify-content-between mb-2 contentGridLabel">
      {['US',null,undefined,''].includes(timecardApprover?.country)? (
        <>
        <label>State</label>
        <h6>{timecardApprover?.state || "--"}</h6>
        </>

      ):(
        <>
        <label>Country</label>
        <h6>{timecardApprover?.country || "--"}</h6>
        </>
      )
      
      
      }
      </div>

      <div className="d-flex justify-content-between mb-2 contentGridLabel">
        <label>Zip Code</label>
        <h6>{timecardApprover?.zipCode || "--"}</h6>
      </div>

      <div className="d-flex justify-content-between mb-2 contentGridLabel">
        <label>Primary Phone</label>
        <h6>{timecardApprover?.primaryPhone || "--"}</h6>
      </div>

      {/* <div className="d-flex justify-content-between mb-2 contentGridLabel">
        <label>Cell Phone</label>
        <h6>{timecardApprover?.cellPhone || "--"}</h6>
      </div> */}

      <div className="d-flex justify-content-between contentGridLabel">
        <label>Email Address</label>
        <h6>{timecardApprover?.emailAddress || "--"}</h6>
      </div>

      {/* <div className="d-flex justify-content-between mb-2 contentGridLabel">
          <label>Street Address</label>
          <h6>{companyInfo?.address?.streetAddress}</h6>
        </div>
        
        <div className="d-flex justify-content-between mb-2 contentGridLabel">
          <label>City</label>
          <h6>{companyInfo?.address?.city}</h6>
        </div>
        
        <div className="d-flex justify-content-between mb-2 contentGridLabel">
          <label>State</label>
          <h6>{companyInfo?.address?.state}</h6>
        </div>
        
        <div className="d-flex justify-content-between mb-2 contentGridLabel">
          <label>Zip Code</label>
          <h6>{companyInfo?.address?.zipCode}</h6>
        </div>
        
        <div className="d-flex justify-content-between mb-2 contentGridLabel">
          <label>Direct Work Phone</label>
          <h6>{companyInfo?.workPhone || "--"}</h6>
        </div>
        
        <div className="d-flex justify-content-between contentGridLabel">
          <label>Cell Phone</label>
          <h6>{companyInfo?.mobilePhone || "--"}</h6>
        </div>
         */}
    </div>
  );
};

export default ClientCompanyDetails;
