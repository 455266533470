import { useMutation } from "@apollo/client";
import { useState } from "react";
import ConfirmPopUp from "../../../../components/ConfirmPopUp";
import { REMOVE_CONTACT, SEND_NOW_CONTACT_STEP } from "../gql";
import { toast } from "react-toastify";
import Avatar from "react-avatar";
import "../contacts.scss";
import {
  Badge,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";

import EDIT_ICON from "../../../../assets/images/edit-icon-menu.svg";
import SEND_ICON from "../../../../assets/images/send.svg";
import Delete_Img from "../../../../assets/images/candidate-delete.svg";
import ACTION_ICON_5 from "../../../../assets/images/application-icon-5.svg";

export default function ContactViewHeader({
  contactDetailsData,
  setEditContactManually,
  reFetchContacts,
  refetchStats,
  contactViewToggle,
}) {
  const [, setDropdownOpen] = useState<boolean>(false);
  const [contactRemoveModal, setContactRemoveModal] = useState<boolean>(false);
  const [sendNowContact, setSendNowContact] = useState<boolean>(false);
  const [removeContact, { loading }] = useMutation(REMOVE_CONTACT);
  const [sendNowContactStep] = useMutation(SEND_NOW_CONTACT_STEP);

  const removeContactHandler = async () => {
    if (contactDetailsData?._id) {
      const response = await removeContact({
        variables: { contactId: contactDetailsData?._id },
      });

      if (response?.data) {
        contactViewToggle();
        setDropdownOpen(false);
        refetchStats();
        reFetchContacts();
        toast.success(`${contactDetailsData?.name} deleted successfully`);
      }
    }
  };

  const sendNowContactStepHandler = async () => {
    const response = await sendNowContactStep({
      variables: { contactId: contactDetailsData?._id },
    });

    if (response?.data) {
      setSendNowContact(false);
      toast.success(
        `${contactDetailsData?.name} sent now to step ${
          contactDetailsData?.next_step + 1
        } successfully`
      );
    }
  };

  return (
    <>
      <div className="contact-dialog-header">
        <div className="contact-info">
          <div className="contact-avatar">
            <Avatar
              email={contactDetailsData?.email}
              name={contactDetailsData?.name}
            />
          </div>

          <div>
            <h5>{contactDetailsData?.name} </h5>
            <p>{contactDetailsData?.email}</p>
            <Badge
              color={
                contactDetailsData?.state === "error"
                  ? "danger"
                  : contactDetailsData?.state === "messaged"
                  ? "warning"
                  : contactDetailsData?.state === "paused"
                  ? "secondary"
                  : contactDetailsData?.state === "replied"
                  ? "success"
                  : contactDetailsData?.state === "opened"
                  ? "success"
                  : contactDetailsData?.state === null
                  ? "warning"
                  : ""
              }
              className="text-capitalize"
            >
              {contactDetailsData?.state === "error"
                ? "Error"
                : contactDetailsData?.state === "messaged"
                ? "Messaged"
                : contactDetailsData?.state === "paused"
                ? "Paused"
                : contactDetailsData?.state === "replied"
                ? "Replied"
                : contactDetailsData?.state === "opened"
                ? "Opened"
                : contactDetailsData?.state === null
                ? "Added"
                : ""}
            </Badge>
          </div>
        </div>

        <div>
          <UncontrolledDropdown className="customMenuDropdown">
            <DropdownToggle className="menuButton">
              <img src={ACTION_ICON_5} alt="icon" />
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem
                onClick={() => setEditContactManually(true)}
                style={{ cursor: "pointer" }}
              >
                <img src={EDIT_ICON} alt="edit" />
                Edit
              </DropdownItem>
              <DropdownItem onClick={() => setSendNowContact(true)}>
                <img src={SEND_ICON} alt="send" width="20px" />
                Send Now
              </DropdownItem>
              <DropdownItem
                onClick={() => setContactRemoveModal(true)}
                disabled={loading}
              >
                <img src={Delete_Img} alt="delete" />
                Delete
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      </div>

      <ConfirmPopUp
        confirmText="Are you sure you want to delete contact?"
        isOpen={contactRemoveModal}
        toggle={setContactRemoveModal}
        confirmAction={removeContactHandler}
        modalHeading="Delete action"
        btnText="Confirm"
        btnColor="primary"
        className="revampDialog revampDialogWidth"
      />

      <ConfirmPopUp
        confirmText={`Are you sure you want to send ${contactDetailsData?.name} now?`}
        isOpen={sendNowContact}
        toggle={() => setSendNowContact(!sendNowContact)}
        confirmAction={sendNowContactStepHandler}
        modalHeading="Send now action"
        btnText="Send Now"
        btnColor="primary"
        className="revampDialog revampDialogWidth"
      />
    </>
  );
}
