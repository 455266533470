import { useLazyQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import classnames from 'classnames';
import { Link, useHistory, useLocation } from 'react-router-dom';
import {
  Button,
  FormGroup,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap';
import GoBack from '../../components/GoBack';
import Loader from '../../components/layout/Loader';
import { FETCH_USER_BILLING_CREDIT } from '../dashboard/gql';
import './style.scss';
import { useForm } from 'react-hook-form';
import { IComingState } from '../agreements/interfaces';
import BillingBoard from './BillingBoard/BillingBoard';

const CompanyReport = ({ widget }: { widget: boolean }) => {
  const [startDate, setStartDate] = useState<any>(
    `${new Date().getFullYear()}-01-01`
  );
  const [endDate, setEndDate] = useState<any>(
    `${new Date().getFullYear()}-12-31`
  );
  const [getRecruiterBillingCredit, { data, loading }] = useLazyQuery(
    FETCH_USER_BILLING_CREDIT
  );
  const location = useLocation<IComingState>();
  const history = useHistory();

  const activeParamTab =
    new URLSearchParams(location.search).get('activeTab') || '1';
  const [activeTab, setActiveTab] = useState(activeParamTab);
  const toggleTab = (tab: string) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      history.push(`${location?.pathname}?activeTab=${tab}`);
    }
  };

  const { handleSubmit } = useForm();

  useEffect(() => {
    getRecruiterBillingCredit({
      variables: {
        DateOfRange: { statsStartDate: startDate, statsEndDate: endDate },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getRecruiterBillingCredit]);

  const populatRecruiterBillingCredit = async () => {
    await getRecruiterBillingCredit({
      variables: {
        DateOfRange: { statsStartDate: startDate, statsEndDate: endDate },
      },
    });
  };

  if (loading) return <Loader />;

  return (
    <div>
      {!widget && (
        <div>
          <GoBack />
        </div>
      )}

      <div className="d-flex justify-content-between flex-wrap h-100 align-items-center p-3">
        <h5>Billing Credit Leaderboard</h5>

        <div
          className="d-flex align-items-center flex-wrap"
          style={{ gap: '10px' }}
        >
          <div className="d-flex align-items-center">
            <FormGroup className="mb-0 d-flex align-items-center">
              <Label className="mr-2 mb-0" style={{ whiteSpace: 'nowrap' }}>
                Start Date :
              </Label>
              <Input
                defaultValue={startDate}
                type="date"
                name="statsStartDate"
                onChange={(e) => setStartDate(e.target.value)}
              />
            </FormGroup>
          </div>
          <div className="d-flex align-items-center align-items-center">
            <FormGroup className="mb-0 d-flex align-items-center">
              <Label className="mr-2 mb-0" style={{ whiteSpace: 'nowrap' }}>
                End Date :
              </Label>
              <Input
                defaultValue={endDate}
                type="date"
                name="statsEndDate"
                onChange={(e) => setEndDate(e.target.value)}
              />
            </FormGroup>
          </div>
          <Button
            type="button"
            className="buttonGenericStyle filledButton m-1"
            size="lg"
            onClick={handleSubmit(populatRecruiterBillingCredit)}
          >
            Get Leaderboard
          </Button>
        </div>

        {widget && (
          <Link to="/leaderboard">
            <Button
              type="button"
              className="buttonGenericStyle filledButton m-1"
            >
              View Details
            </Button>
          </Link>
        )}
      </div>

      <div className="CandidateTabsWrapper">
        <Nav tabs className="billing-tabs">
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '1' })}
              onClick={() => {
                toggleTab('1');
              }}
            >
              Total Billing Credit
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '2' })}
              onClick={() => {
                toggleTab('2');
              }}
            >
              Direct Hire Billing Credit
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '3' })}
              onClick={() => {
                toggleTab('3');
              }}
            >
              Temp Billing Credit
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <BillingBoard
              data={data}
              widget={widget}
              typeBoard={'totalHireBilling'}
            />
          </TabPane>
          <TabPane tabId="2">
            <BillingBoard
              data={data}
              widget={widget}
              typeBoard={'directHireBilling'}
            />
          </TabPane>
          <TabPane tabId="3">
            <BillingBoard
              data={data}
              widget={widget}
              typeBoard={'tempHireBilling'}
            />
          </TabPane>
        </TabContent>
      </div>
    </div>
  );
};

export default CompanyReport;
