import { Table } from "reactstrap";
import { commaRemover, localStringMaker, moneyFormatter } from "../../../../utils/helper";
import "../placement.scss";

const ACAInfoCalculations = ({ watch, assignedDuration }) => {
  return (
    <div className="aca-info-calculations">
      <div className="noteSection light-dark-color">
        <h5>Less ACA Affordable Care Act</h5>
      </div>

      <div className="table-responsive">
        <Table className="careActTable">
          <thead>
            <tr>
              <th>Estimate</th>
              <th>ACA Cost/hr</th>
              <th>Est. Hours</th>
              <th>Est. Cost Run of Assignment</th>
              <th>
                <div className="text-left">
                  Less P20 Fee
                  <div>(5% of bill rate)</div>
                </div>
              </th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td>{"Approx $0.00/hr"}</td>
              <td>({"$0.00"})</td>
              <td>{assignedDuration * 40 || "--"}</td>
              <td>{"$0.00"}</td>
              <td align="left">
                5% of ${localStringMaker(commaRemover(watch("billRate")) || 0)}
                /hour = $ (
                {moneyFormatter(0.05 * (commaRemover(watch("billRate")) || 0))}
                /hour)
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default ACAInfoCalculations;
