import { useContext, useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import Select from 'react-select';
import { Button, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import InputField from '../../../components/layout/InputField';
import { AppContext } from '../../../context';
import { CompanySource } from '../../../utils/constant';

import '../../company/company.scss';
import ShareCandidate from './ShareCandidate';
import { customSelectDropDown } from '../../../components/styled/customSelect';
import { useMutation } from '@apollo/client';
import { UPDATE_PRIVATE_CANDIDATE_STATUS } from '../gql';
import ConfirmPopUp from '../../../components/ConfirmPopUp';
import { toast } from 'react-toastify';

const CandidateBasicInfo = ({
  register,
  errors,
  unregister,
  value,
  setValue,
  getValues,
  control,
  editCandidate,
  candidate,
  refetch,
  watch,
  ...props
}: any) => {
  const { isPrivateCandidate } = watch()
  const { theme, setContextPrivateCandidate, privateCandidate } = useContext(AppContext);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [isSelectToggle, setIsSelectToggle] = useState<boolean>(false);
  const { userRoles } = useContext(AppContext);
  const [postModal, setPostModal] = useState(false);
  const [privateCandidateStatus] = useMutation(UPDATE_PRIVATE_CANDIDATE_STATUS);
  const [shareeButon, setShareeButton] = useState<boolean>(isPrivateCandidate);

  useEffect(() => {
    setShareeButton(isPrivateCandidate)
  }, [isPrivateCandidate])

  const updatePrivateField = async () => {
    const updatePrivate = await privateCandidateStatus({
      variables: {
        UpdateCandidateDto: {
          candidateId: props.candidateId,
          isPrivateCandidate: isPrivateCandidate,
          firstName: candidate?.firstName,
          lastName: candidate?.lastName,
        },
      },
    });

    if (updatePrivate?.data?.updatePrivateCandidateStatus?.status === 200) {
      setPostModal(false);
      setContextPrivateCandidate(isPrivateCandidate);
      setShareeButton(isPrivateCandidate)
      persistPrivateOptionForUpdate()
      toast.success('Candidate is updated');
    } else {
      toast.success("Candidate can't be update");
      setPostModal(false);
    }
  };

  const persistPrivateOption = () => {
    if (privateCandidate) {
      localStorage.removeItem('privateOption');
    } else {
      localStorage.setItem('privateOption', 'selected');
    }
  };
  const persistPrivateOptionForUpdate = () => {
    if (isPrivateCandidate) {
      localStorage.setItem('privateOption', 'selected');

    } else {
      localStorage.removeItem('privateOption');

    }
  };
  const toogleCancelButton = () => {
    setPostModal(false)
    setValue('isPrivateCandidate', !isPrivateCandidate)
  }

  useEffect(() => {
    if (privateCandidate && !editCandidate) {
      setContextPrivateCandidate(privateCandidate);
      setValue('isPrivateCandidate', privateCandidate);
    }
  }, [editCandidate, privateCandidate, setContextPrivateCandidate, setValue]);
  
  useEffect(() => {
    const privateOption = localStorage.getItem('privateOption');
    const isPrivate = privateOption === 'selected';
    setContextPrivateCandidate(isPrivate);
  }, [editCandidate, setContextPrivateCandidate, setValue]);

  return (
    <div>
      <div
        onClick={() => {
          if (isSelectToggle) {
            setIsSelectToggle(!isSelectToggle);
          }
        }}
      >
        <div className="d-flex flex-column">
          <h5 className="m-0 order-2 mt-2">Basic Info</h5>
          {(userRoles?.includes('ADMIN') ||
            userRoles?.includes('EXECUTIVE') ||
            userRoles?.includes('HR')) && (
              <div className="w-auto privateCandidate justify-content-start flex-wrap">
                {editCandidate ? (
                  <div className="d-flex align-items-center">
                    <h6 className="mr-2 my-auto switchLabel">
                      Private Candidate
                    </h6>
                    <label className="switch m-0">
                      <Input
                        type="checkbox"
                        name="isPrivateCandidate"
                        innerRef={register()}
                        {...register}
                        onChange={() => {
                          setPostModal(true);
                        }}
                      />
                      <span className="slider"></span>
                    </label>
                  </div>
                ) : (
                  <div className="d-flex align-items-center">
                    <h6 className="mr-2 my-auto switchLabel">
                      Private Candidate
                    </h6>
                    <label className="switch m-0">
                      <Input
                        type="checkbox"
                        name="isPrivateCandidate"
                        innerRef={register()}
                        {...register}
                        onChange={() => {
                          setContextPrivateCandidate(!isPrivateCandidate);
                          setShareeButton(!isPrivateCandidate)
                          persistPrivateOption()
                        }}
                      />
                      <span className="slider"></span>
                    </label>
                  </div>
                )}

                {shareeButon && (
                  <Button
                    className="color-default bg-transparent border-0"
                    onClick={() => {
                      toggle();
                    }}
                  >
                    Update Candidate Share
                  </Button>
                )}
              </div>
            )}
        </div>

        <div className="user-basic-form">
          <Row>
            <Col md="6" sm="12">
              <InputField
                inputtype="text"
                isRequired={true}
                label="First Name"
                inputid="firstName"
                placeholder="First Name"
                inputRef={register({
                  required: {
                    value: true,
                    message: 'First name is required!',
                  },
                })}
                errMsg={errors?.firstName?.message}
              />
            </Col>
            <Col md="6" sm="12">
              <InputField
                inputtype="text"
                label="Middle Name"
                inputid="middleName"
                placeholder="Middle Name"
                inputRef={register()}
                errMsg={errors?.middleName?.message}
              />
            </Col>
            <Col md="6" sm="12">
              <InputField
                inputtype="text"
                isRequired={true}
                label="Last Name"
                inputid="lastName"
                placeholder="Last Name"
                inputRef={register({
                  required: {
                    value: true,
                    message: 'Last name is required!',
                  },
                })}
                errMsg={errors?.lastName?.message}
              />
            </Col>
            <Col md="6" sm="12">
              {props?.candidateId ? null : (
                <FormGroup>
                  <Label>
                    Source<span className="text-danger">*</span>
                  </Label>
                  <Controller
                    name="source"
                    as={Select}
                    options={CompanySource}
                    placeholder="Source"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: 'Source must be selected!',
                      },
                    }}
                    styles={{ ...customSelectDropDown(theme) }}
                  />
                  <small className="text-danger">
                    {errors?.source?.message}
                  </small>
                </FormGroup>
              )}
            </Col>
          </Row>
        </div>
      </div>

      {modal && (
        <ShareCandidate
          modal={modal}
          toggle={toggle}
          candidateId={props?.candidateId}
        // refetchCandidateWriteUp={props?.refetchCandidateWriteUp}
        />
      )}

      <ConfirmPopUp
        confirmText={`Are you sure you want to change the status of candidate?`}
        isOpen={postModal}
        toggle={toogleCancelButton}
        confirmAction={updatePrivateField}
        modalHeading={'Candidate Status'}
        btnText="Confirm"
        btnColor="primary"
      />
    </div>
  );
};

export default CandidateBasicInfo;