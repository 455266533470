import { useLazyQuery, useMutation } from '@apollo/client';
import { SetStateAction, useCallback, useContext, useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import {
  Badge,
  Button,
  Input,

} from 'reactstrap';
import DELETE_ICON from '../../assets/images/delete-icon-menu.svg';
import InputField from '../../components/layout/InputField';
import Loader from '../../components/layout/Loader';
import Pagination from '../../components/layout/Pagination';
import TableComponent from '../../components/layout/TableComponent';
import { compactObject } from '../../utils/commonFn';
import {
  COLUMN_INTERFACE_TYPES_FOR_JOB_ORDER,
  COMPANY_DETAILS_ROUTE,
  CONTACT_DETAIL_ROUTE,
  CREATE_JOB_ORDER_ROUTE,
  JobOrderBulkActions,
  JobOrderStatus,
  JOB_ORDER_ROUTE,
  JOB_ORDER_WIDGETS_HEADERS_SEARCHING,
  PAGE_LIMIT,
  VIEW_JOB_ORDER_ROUTE,
  workplaceTypesDto,
  WorkTypeDto,
} from '../../utils/constant';

import NoDataFound from '../../components/layout/NoDataFound';
import '../company/company.scss';
import {
  GET_ALL_JOB_ORDERS,
  HOT_JOB_ORDER,
  POST_JOB_ORDER,
  REMOVE_JOB_ORDER,
  UNHOT_JOB_ORDER,
  UNPOST_JOB_ORDER,
  UPDATE_STATUS_WITH_LOCATION,
} from './gql';

import AsyncCompanyDropdown from '../../components/AsyncCompanySelect';
import { AppContext } from '../../context';
import { getAllJobOrdersParams } from '../../utils/getNoteParams';

import EDIT_ICON from '../../assets/images/edit-icon-menu.svg';
import VIEW_ICON from '../../assets/images/view-icon.svg';
import ConfirmPopUp from '../../components/ConfirmPopUp';
import { pushInHistory } from '../../utils/helper';
import { useForm } from 'react-hook-form';

let time = 0;
const AllJobOrders = () => {
  const location = useLocation();
  const history = useHistory();
  const fieldNames = [
    'jobTitleSearch',
    'companySearch',
    'companyownerSearch',
    'locationSearch',
    'postedSearch',
    'statusSearch',
    'mainContactSearch',
  ];

  const defaultColumnFilters = {
    jobTitleSearch: '',
    companySearch: '',
    companyownerSearch: '',
    locationSearch: '',
    postedSearch: '',
    statusSearch: '',
    mainContactSearch: '',
    sorting: {
      columnName: '',
      columnValue: '',
    },
  };

  const [columnFilters, setColumnFilters] =
    useState<COLUMN_INTERFACE_TYPES_FOR_JOB_ORDER>(defaultColumnFilters);
  const [currentPage, setCurrentPage] = useState(1);
  const [errorMsg, setErrorMsg] = useState({});
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [currentJobOrderId, setCurrentJobOrderId] = useState<string>('');
  const [limit, setLimit] = useState(10);
  const { register, setValue, control } = useForm();
  const [myJobOrderToggle, setMyJobOrderToggle] = useState<boolean>(false);
  const [myJobOrderFlag, setMyJobOrderFlag] = useState<boolean>(false);
  const [selectedCompanyId, setSelectedCompanyId] = useState<string>('');
  const [selectedCompanyName, setSelectedCompanyName] = useState<string>('');
  const [currentBulkAction, setCurrentBulkAction] = useState('');
  const [checkBoxArray, setCheckBoxArray] = useState<Array<string>>([]);
  const [statusCurrentBulkAction, setStatusCurrentBulkAction] = useState('');
  const { user, userRoles } = useContext(AppContext);
  const [postJobOrder] = useMutation(POST_JOB_ORDER);
  const [unPostJobOrder] = useMutation(UNPOST_JOB_ORDER);
  const [fetchAllJobOrders, { loading, error, data }] =
    useLazyQuery(GET_ALL_JOB_ORDERS);
  const [hotJobOrder] = useMutation(HOT_JOB_ORDER);
  const [unHotJobOrder] = useMutation(UNHOT_JOB_ORDER);
  const [removeJobOrder] = useMutation(REMOVE_JOB_ORDER);
  const [updateStatusBulkJobsAndLocations] = useMutation(
    UPDATE_STATUS_WITH_LOCATION
  );
  const [hotJobOrdersFlag, setHotJobOrdersFlag] = useState<boolean>(false);
  const [myPendingActionsInterviewFlag, setMyPendingActionsInterviewFlag] =
    useState<boolean>(false);
  const [myPendingActionsSubmissionFlag, setMyPendingActionsSubmissionFlag] =
    useState<boolean>(false);
  const [isSetTimeOut, setIsSetTime] = useState<boolean>(true);


  const setQueryParams = () => {
    let newColumn: COLUMN_INTERFACE_TYPES_FOR_JOB_ORDER = Object.assign(
      {},
      columnFilters
    );
    const { sorting, ...rest } = newColumn;
    const criteria = compactObject({
      currentPage,
      selectedCompanyId,
      selectedCompanyName,
      myJobOrderFlag,
      myPendingActionsInterviewFlag,
      myPendingActionsSubmissionFlag,
      ...rest,
      ...sorting,
    });

    history.push(`/job-orders?${new URLSearchParams(criteria).toString()}`);
  };

  const handleCheckBoxes = (value: string) => {
    const currentIndex = checkBoxArray.indexOf(value);
    const newChecked = [...checkBoxArray];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setCheckBoxArray(newChecked);
  };

  const changeFiled = (fieldName, value) => {
    if (fieldName?.endsWith('Sort')) {
      const columnName = fieldName.replace('Sort', '');
      setIsSetTime(true)
      setColumnFilters((prevFilters) => {
        const { sorting, ...otherFilters } = prevFilters;
        return {
          ...otherFilters,
          sorting: {
            columnName: columnName,
            columnValue: value ? 'ASC' : 'DESC',
          },
        };
      });
    } else {
      if ((fieldName === 'resumeUpdatedSearch') && !value) {
        value = null;
      }
      setCurrentPage(1);
      setLimit(limit);
      setIsSetTime(false)
      setColumnFilters((prevFilters) => ({
        ...prevFilters,
        [fieldName]: value,
      }));
    }
  };
  const postJobOrderHandler = async () => {
    const updateJobBulk = await postJobOrder({
      variables: { ids: { bulkJobs: checkBoxArray } },
    });

    if (updateJobBulk?.data) {
      toast.success('Bulk Job order updated');
      _fetchAllJobOrders();
    }
  };

  const unpostJobOrderHandler = async () => {
    const updateJobBulk = await unPostJobOrder({
      variables: { ids: { bulkJobs: checkBoxArray } },
    });

    if (updateJobBulk?.data) {
      toast.success('Bulk Job order updated');
      _fetchAllJobOrders();
    }
  };

  const hotJobOrderHandler = async () => {
    const updateJobBulk = await hotJobOrder({
      variables: { ids: { bulkJobs: checkBoxArray } },
    });

    if (updateJobBulk?.data) {
      toast.success('Bulk Job order updated');
      _fetchAllJobOrders();
    }
  };

  const unhotJobOrderHandler = async () => {
    const updateJobBulk = await unHotJobOrder({
      variables: { ids: { bulkJobs: checkBoxArray } },
    });

    if (updateJobBulk?.data) {
      toast.success('Bulk Job order updated');
      _fetchAllJobOrders();
    }
  };

  const handleEveryBulkAction = () => {
    if (currentBulkAction === 'POST') {
      postJobOrderHandler();
    } else if (currentBulkAction === 'UNPOST') {
      unpostJobOrderHandler();
    } else if (currentBulkAction === 'HOT') {
      hotJobOrderHandler();
    } else if (currentBulkAction === 'UNHOT') {
      unhotJobOrderHandler();
    } else if (statusCurrentBulkAction) {
      handleStatusBulkAction();
    }
    setCheckBoxArray([]);
    setCurrentBulkAction('');
  };

  const handleStatusBulkAction = async () => {
    const res = await updateStatusBulkJobsAndLocations({
      variables: {
        bulkJobOrderUpdateStatus: {
          bulkIdJobs: checkBoxArray,
          toStatus: statusCurrentBulkAction,
        },
      },
    });
    if (res.data) {
      _fetchAllJobOrders();
      setStatusCurrentBulkAction('');
    }
  };



  const addBulkActionColumn = (columns) => {
    var newArray = columns.slice();
    newArray.unshift();
    return newArray;
  }
  const removingJobOrder = async () => {
    const response = await removeJobOrder({
      variables: { id: currentJobOrderId },
    });

    if (response?.data) {
      setDeleteModal(false);
      fetchAllJobOrders({
        variables: {
          limit,
          page: currentPage,
          myJobOrder: myJobOrderFlag,
          companyId: selectedCompanyId,
          myPendingInterview: myPendingActionsInterviewFlag,
          myPendingSubmission: myPendingActionsSubmissionFlag,
        },
      });
    }
  };
  useEffect(() => {
    document.title = 'JobOrders - RealREPP';
    let params: any = getAllJobOrdersParams(location.search);
    if (params) {
      setCurrentPage(params?.currentPage ? parseInt(params?.currentPage) : 1);
      setLimit(params?.limit ? parseInt(params?.limit) : 10);
      setMyJobOrderFlag(params?.myJobOrderFlag === 'true' ? true : false);
      setSelectedCompanyId(params?.selectedCompanyId)
      setSelectedCompanyName(params?.selectedCompanyName)
    }
    if (Object.entries(params?.columnFilters)?.length) {
      if (Object.entries(params?.columnFilters)?.length === 1) {
        setColumnFilters((prevFilters) => ({
          ...prevFilters,
          [Object.keys(params?.columnFilters)[0]]: Object.values(
            params?.columnFilters
          )[0],
        }));
      } else {
        const updatedFilters = { ...columnFilters };
        Object.entries(params?.columnFilters).forEach(([key, value]) => {
          updatedFilters[key] = value;
        });
        setColumnFilters(updatedFilters);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (data?.fetchAllJobOrders?.jobOrders?.length) {
      data?.fetchAllJobOrders?.jobOrders
        ?.filter((item) => item?.isHotJob === true && `${item.id}`)
        .map((val) => val?.id);
    }

    const state: any = location?.state;

    state?.pendingActionFor === 'interview' &&
      setMyPendingActionsInterviewFlag(true);
    setLimit(limit);

    state?.pendingActionFor === 'submission' &&
      setMyPendingActionsSubmissionFlag(true);
    setLimit(limit);

    // eslint-disable-next-line
  }, [data, location?.state]);

  const checkEditPermission = (data: any) => {
    if (userRoles?.includes('ADMIN') || userRoles?.includes('EXECUTIVE')) {
      return true;
    }

    let company = data?.company;
    if (company) {
      if (company?.ownerAndSharees?.length) {
        let currentUser = company?.ownerAndSharees?.find(
          (item) => item.owner?.id === user?.id
        );
        if (currentUser) {
          return true;
        }
      }
      if (company?.companyOwner?.owner?.id === user?.id) {
        return true;
      }

      if (company?.status === 'DO_NOT_CONTACT') {
        return true;
      }
    }

    return false;
  };

  const handleMyJobOrders = () => {
    setMyPendingActionsInterviewFlag(false);
    setMyPendingActionsSubmissionFlag(false);
    setMyJobOrderToggle(!myJobOrderToggle);
    setMyJobOrderFlag(!myJobOrderFlag);
  }

  const _fetchAllJobOrders = useCallback(async () => {

    if (isSetTimeOut) {
      setQueryParams();
      fetchAllJobOrders({
        variables: {
          limit,
          page: currentPage,
          myJobOrder: myJobOrderFlag,
          companyId: selectedCompanyId,
          myPendingInterview: myPendingActionsInterviewFlag,
          myPendingSubmission: myPendingActionsSubmissionFlag,
          isHotJob: hotJobOrdersFlag,
          columnFilters: columnFilters
        },
      });
    } else {
      setQueryParams();
      setLimit(limit);
      window.clearTimeout(time);
      time = window.setTimeout(async () => {
        fetchAllJobOrders({
          variables: {
            limit,
            page: currentPage,
            myJobOrder: myJobOrderFlag,
            companyId: selectedCompanyId,
            myPendingInterview: myPendingActionsInterviewFlag,
            myPendingSubmission: myPendingActionsSubmissionFlag,
            isHotJob: hotJobOrdersFlag,
            columnFilters: columnFilters
          },
        });
      }, 1000);
    }


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myJobOrderFlag,
    currentPage,
    limit,
    selectedCompanyId,
    myPendingActionsInterviewFlag,
    myPendingActionsSubmissionFlag,
    hotJobOrdersFlag,
    columnFilters]);

  useEffect(() => {
    _fetchAllJobOrders();
    // eslint-disable-next-line
  }, [_fetchAllJobOrders]);

  useEffect(() => {
    if (myJobOrderToggle) {
      setMyJobOrderFlag(true);
    }
    // eslint-disable-next-line
  }, [myJobOrderToggle]);

  return (
    <div className="admin-tabs team-tabs">
      <ToastContainer autoClose={3000} />

      <div className="mobileResponsiveFlexAlign primaryHeaderSpacing primaryHeadng">
        <h5 className="m-0">Job Orders</h5>
      </div>

      <div className="search-filter primaryHeaderSpacing">
        <div className="filter-status filterJobOrder all-job-orders">
          <button
            type="button"
            onClick={() => {
              history.push({
                pathname: CREATE_JOB_ORDER_ROUTE,
                state: { searchParameters: location.search },
              });
            }}
            className="buttonGenericStyle filledButton mr-2 mb-2"
          >
            Add Job Order
          </button>
        </div>
      </div>

      <div className="search-filter primaryHeaderSpacing">
        <div className="filter-status filterJobOrder justify-content-between w-100 flex-wrap">
          <div className="d-flex align-items-center flex-wrap mb-2">
            <button
              className={`btn-job-filters btn-job-order ${myJobOrderFlag && 'active'
                }`}
              onClick={handleMyJobOrders}
              type="button"
              disabled={loading}
            >
              My Job Orders
            </button>

            <button
              className={`btn-job-filters btn-pending-submission ${myPendingActionsSubmissionFlag && 'active'
                }`}
              onClick={() => {
                setMyPendingActionsSubmissionFlag(
                  !myPendingActionsSubmissionFlag
                );
                setMyPendingActionsInterviewFlag(false);
                setMyJobOrderFlag(false);
                setCurrentPage(1);
                setLimit(25);
              }}
              type="button"
            >
              My Pending Submissions
            </button>

            <button
              className={`btn-job-filters btn-pending-reviews ${myPendingActionsInterviewFlag && 'active'
                }`} style={{ borderRadius: 0 }}
              onClick={() => {
                setMyPendingActionsInterviewFlag(
                  !myPendingActionsInterviewFlag
                );
                setMyPendingActionsSubmissionFlag(false);
                setMyJobOrderFlag(false);
                setCurrentPage(1);
                setLimit(25);
              }}
              type="button"
            >
              My Pending Interviews
            </button>

            <button
              className={`btn-job-filters btn-pending-reviews ${hotJobOrdersFlag && 'active'
                }`}
              onClick={() => {
                setHotJobOrdersFlag(!hotJobOrdersFlag)
                setMyPendingActionsInterviewFlag(false)
                setMyPendingActionsSubmissionFlag(false);
                setMyJobOrderFlag(false);
                setCurrentPage(1);
                setLimit(25);
              }}
              type="button"
            >
              Hot JobOrders
            </button>
          </div>

          <div className="d-flex align-items-center flex-wrap mb-2 gap-10">
            <div className="mr-2">
              <AsyncCompanyDropdown
                width={'12rem'}
                value={{
                  value: selectedCompanyId,
                  label: selectedCompanyName,
                }}
                placeholder="Filter By Company"
                onChange={(value) => {
                  setQueryParams();
                  setSelectedCompanyId(value?.value);
                  setSelectedCompanyName(value?.label);
                  setCurrentPage(1);
                  setLimit(25);
                }}
                isDisabled={
                  myPendingActionsInterviewFlag ||
                  myPendingActionsSubmissionFlag
                }
              />
            </div>

            <button
              className="buttonGenericStyle fillGreyButton ml-md-2 mt-3 mt-md-0"
              onClick={() => {
                fieldNames.forEach((fieldName) => {
                  setValue(fieldName, defaultColumnFilters[fieldName]);
                });
                if (columnFilters?.sorting.columnName) {
                  setValue(columnFilters?.sorting.columnName, false);
                }
                setColumnFilters(defaultColumnFilters);
                setErrorMsg({});
                setSelectedCompanyId('');
                setSelectedCompanyName('');
                setMyJobOrderToggle(false);
                setMyJobOrderFlag(false);
                setMyPendingActionsInterviewFlag(false);
                setIsSetTime(true)
                setMyPendingActionsSubmissionFlag(false);
                fetchAllJobOrders({
                  variables: {
                    limit,
                    page: currentPage,
                    searchText: '',
                    statusSearch: '',
                    companyId: '',
                    myJobOrder: false,
                    cityName: '',
                    myPendingActionsFor: '',
                    myPendingActionsInterviewFlag,
                    myPendingActionsSubmissionFlag,
                  },
                });
              }}
            >
              Clear
            </button>
          </div>
        </div>
      </div>

      {myJobOrderFlag && (
        <div className="section-header justify-content-end m-0 pt-0 jobOrderLonger">
          <div className="mobileResponsiveFlex">
            <div className="bulk-actions mr-2">
              <div className="d-flex justify-content-between">
                <div className="statusBulkActions">
                  <InputField
                    className="save-changes"
                    inputtype="select"
                    selectItems={JobOrderStatus?.filter(
                      (item) =>
                        item.value !== 'DRAFT' && item.value !== 'MY_JOB_ORDERS'
                    )}
                    inputid="statusCurrentBulkAction"
                    disabled={checkBoxArray?.length ? true : false}
                    inputValue={statusCurrentBulkAction}
                    placeholder="Status Bulk Actions"
                    inputMethod={(value: SetStateAction<string>) => {
                      setCurrentBulkAction('');
                      setStatusCurrentBulkAction(value);
                    }}
                  />
                </div>

                <div className="statusBulkActions">
                  <InputField
                    className="save-changes"
                    inputtype="select"
                    selectItems={JobOrderBulkActions}
                    inputid="currentBulkAction"
                    disabled={checkBoxArray?.length ? true : false}
                    inputValue={currentBulkAction}
                    placeholder="Bulk Actions"
                    inputMethod={(value: SetStateAction<string>) => {
                      setStatusCurrentBulkAction('');
                      setCurrentBulkAction(value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div>
              {myJobOrderFlag && (
                <button
                  type="button"
                  disabled={
                    checkBoxArray?.length &&
                      (currentBulkAction || statusCurrentBulkAction)
                      ? false
                      : true
                  }
                  className="buttonGenericStyle filledButton"
                  onClick={handleEveryBulkAction}
                >
                  {`Update ${currentBulkAction === 'POST'
                    ? 'Post Job'
                    : currentBulkAction === 'UNPOST'
                      ? 'Un Post Job'
                      : currentBulkAction === 'HOT'
                        ? 'Mark as Hot Job'
                        : currentBulkAction === 'UNHOT'
                          ? 'No Longer Hot Job'
                          : statusCurrentBulkAction
                            ? 'Status'
                            : ''
                    }`}
                </button>
              )}
            </div>
          </div>
        </div>
      )}

      <div className={`table-responsive mt-3 candidates-list-table table-custom-width ${myJobOrderFlag ? 'candidateTable' : ''}`}>
        <TableComponent
          advanceSearching={true}
          tableHeaderAdvSeacrh={
            myJobOrderFlag
              ? addBulkActionColumn(JOB_ORDER_WIDGETS_HEADERS_SEARCHING)
              : JOB_ORDER_WIDGETS_HEADERS_SEARCHING
          }
          handleChange={(filedName, value) => changeFiled(filedName, value)}
          columnFilters={columnFilters}
          register={register}
          setValue={setValue}
          control={control}
          tableName="JobOrder"
          setErrorMsg={setErrorMsg}
          errorMsg={errorMsg}
        >
          {loading && (
            <tbody>
              <tr>
                <td colSpan={14}>
                  <Loader loaderClass="sm-height " />
                </td>
              </tr>
            </tbody>
          )}

          <tbody className="placement-table">
            {!loading &&
              data &&
              data?.fetchAllJobOrders &&
              data?.fetchAllJobOrders?.jobOrders?.map((item: any, index: number) => {
                return (
                  <tr key={index} className="job-orders-tbody">
                    <td className="route-link">
                      {myJobOrderFlag ? (
                        <div className="d-flex align-items-center">
                          <Input
                            bsSize="sm"
                            type="checkbox"
                            disabled={
                              myJobOrderFlag &&
                                (currentBulkAction === "POST" ||
                                  currentBulkAction === "UNPOST") &&
                                !item?.advertisedLocations?.length
                                ? true
                                : false
                            }
                            onChange={() => handleCheckBoxes(item?.id)}
                          />
                          <div className="ml-3" style={{ marginTop: "15px" }}>
                            <div className="d-flex">
                              <div
                                onClick={() => {
                                  history.push({
                                    pathname: `${VIEW_JOB_ORDER_ROUTE}/${item.id}`,
                                    state: {
                                      searchParameters: location.search,
                                    },
                                  });
                                  pushInHistory(JOB_ORDER_ROUTE, location.search);
                                }}
                              >
                                {item.jobTitle}
                              </div>
                              {item?.isHotJob && (
                                <div className="hotBadge d-flex align-items-center justify-content-center">
                                  Hot
                                </div>
                              )}
                            </div>
                            <div className="subtitleText">
                              {item?.workType
                                ? WorkTypeDto[item?.workType]
                                : "--"}
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div>
                          <div className="d-flex align-items-center">
                            <div
                              onClick={() => {
                                history.push({
                                  pathname: `${VIEW_JOB_ORDER_ROUTE}/${item.id}`,
                                  state: {
                                    searchParameters: location.search,
                                  },
                                });
                                pushInHistory(JOB_ORDER_ROUTE, location.search);
                              }}
                            >
                              {item.jobTitle}
                            </div>
                            {item?.isHotJob && (
                              <div className="hotBadge d-flex align-items-center justify-content-center">
                                Hot
                              </div>
                            )}
                          </div>
                          <div className="subtitleText">
                            {item?.workType ? WorkTypeDto[item?.workType] : "--"}
                          </div>
                        </div>
                      )}
                    </td>

                    {!!item?.company?.name ? (
                      <td
                        className="route-link text-truncate max-200"
                        onClick={() => {
                          history.push({
                            pathname: `${COMPANY_DETAILS_ROUTE}/${item?.company?.id}`,
                            state: {
                              backUrl: JOB_ORDER_ROUTE,
                              searchParameters: location.search,
                            },
                          });

                          pushInHistory(JOB_ORDER_ROUTE, location.search);
                        }}
                      >
                        {item?.company?.name}
                      </td>
                    ) : (
                      <td>--</td>
                    )}

                    <td>{item?.company?.companyOwner?.owner?.fullName || "--"}</td>
                    <td>
                      {item?.companyLocation?.country === 'CA' ? (
                        (item?.companyLocation?.city || '--') +
                        ', ' +
                        (item?.companyLocation?.state || '--') +
                        ', ' +
                        (item?.companyLocation?.country || '--')
                      ) :
                        ["US", null, undefined, ""].includes(
                          item?.companyLocation?.country
                        )
                          ? item?.companyLocation?.city
                            ? `${item?.companyLocation?.city}, ${item?.companyLocation?.state}`
                            : item?.companyLocation?.state
                          : item?.companyLocation?.city
                            ? `${item?.companyLocation?.city}, ${item?.companyLocation?.country}`
                            : item?.companyLocation?.country}
                      <div className="subtitleText">
                        {item?.workplaceTypes
                          ? workplaceTypesDto[item.workplaceTypes]
                          : "--"}
                      </div>
                    </td>

                    <td>
                      {item?.advertisedLocations?.filter(
                        (item: any) => item?.postedAt !== null
                      )?.length
                        ? "YES"
                        : "NO" || '--'}
                    </td>

                    <td className='badge-pading'>
                      <Badge
                        color={
                          item.status === 'ACTIVE'
                            ? 'success'
                            : item.status === 'DRAFT'
                              ? 'primary'
                              : 'warning'
                        }
                        className="text-capitalize"
                      >
                        {JobOrderStatus.find(({ value }) => value === item?.status)?.label}
                      </Badge>
                    </td>

                    {!!item?.mainContact?.contact?.fullName ? (
                      <td
                        className="route-link"
                        onClick={() => {
                          history.push({
                            pathname: `${CONTACT_DETAIL_ROUTE}/${item?.mainContact?.contact?.id}`,
                            state: {
                              backUrl: JOB_ORDER_ROUTE,
                              searchParameters: location.search,
                            },
                          });

                          pushInHistory(JOB_ORDER_ROUTE, location.search);
                        }}
                      >
                        {item?.mainContact?.contact?.fullName}
                      </td>
                    ) : (
                      <td>--</td>
                    )}

                    <td>
                      <div
                        className="d-flex align-items-start"
                        style={{ width: 110 }}
                      >
                        <Link
                          to={{
                            pathname: `${VIEW_JOB_ORDER_ROUTE}/${item?.id}`,
                            state: { searchParameters: location.search },
                          }}
                          onClick={() =>
                            pushInHistory(JOB_ORDER_ROUTE, location.search)
                          }
                          className=" iconButtonAction"
                        >
                          <img src={VIEW_ICON} alt="view" width="20px" />
                        </Link>

                        {checkEditPermission(item) && (
                          <Button
                            className="iconButtonAction bg-transparent border-0 p-0 pb-1"
                            onClick={() => {
                              history?.push({
                                pathname: `/update-job-order/${item?.id}`,
                                state: { searchParameters: location.search },
                              });

                              pushInHistory(JOB_ORDER_ROUTE, location.search);
                            }}
                          >
                            <img src={EDIT_ICON} alt="edit" width="16px" />
                          </Button>
                        )}

                        {checkEditPermission(item) &&
                          item.status === 'DRAFT' && (
                            <Button
                              className="iconButtonAction bg-transparent border-0 p-0 pb-1"
                              onClick={() => {
                                setCurrentJobOrderId(item?.id);
                                setDeleteModal(true);
                              }}
                            >
                              <img src={DELETE_ICON} alt="delete" />
                            </Button>
                          )}
                      </div>
                    </td>
                  </tr>
                )
              })
            }
          </tbody>

          {!loading && !data?.fetchAllJobOrders?.jobOrders?.length && (
            <tbody>
              <tr>
                <td colSpan={14} className="empty-table-td">
                  <NoDataFound text="No Job Order Found!" />
                </td>
              </tr>
            </tbody>
          )}
        </TableComponent>
      </div>

      {!!data?.fetchAllJobOrders?.jobOrders?.length && (
        <div className="users-pagination">
          <Pagination
            onPageChange={(pageClicked: number) => {
              setIsSetTime(true)
              setQueryParams();
              setCurrentPage(pageClicked);
            }}
            pageCount={Math.ceil(data?.fetchAllJobOrders?.count / limit)}
            currentPage={currentPage}
          />

          <div className="d-flex align-items-center">
            <div className="pagination-display paginationItemsSelect">
              <p className="m-0">
                {(currentPage - 1) * limit + 1} to{' '}
                {Math.min(currentPage * limit, data?.fetchAllJobOrders?.count)}{' '}
                of {data?.fetchAllJobOrders?.count}
              </p>

              <div className="divider"></div>

              <label>Display</label>

              <InputField
                label=""
                inputtype="select"
                selectItems={PAGE_LIMIT.filter((item) => item.value !== limit)}
                inputid="limit"
                placeholder={limit.toString()}
                inputMethod={(value: string) => {
                  setIsSetTime(true)
                  setQueryParams();
                  setCurrentPage(1);
                  setLimit(parseInt(value));
                }}
              />
            </div>
          </div>
        </div>
      )}

      {error?.graphQLErrors[0]?.message}

      <ConfirmPopUp
        confirmText="Are you sure you want to delete this action?"
        isOpen={deleteModal}
        toggle={() => {
          setDeleteModal(!deleteModal);
        }}
        confirmAction={removingJobOrder}
        modalHeading="Delete Action"
        btnText="Delete"
        btnColor="primary"
        className="revampDialog revampDialogWidth"
      />
    </div>
  );
};

export default AllJobOrders;
