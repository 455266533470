import { useMutation } from '@apollo/client';
import { Controller, useForm } from 'react-hook-form';
import ReactQuill from 'react-quill';
import { toast } from 'react-toastify';
import {
  Form,
  FormGroup,
  Label,
} from 'reactstrap';
import { CREATE_ACTION, CREATE_NOTES } from '../../company/gql';
import { SUBMISSION_STATUS_CHANGE } from '../interviews/gql';
import { UPDATE_OFFER_STATUS } from './gql';

import {
  DrawerHeader,
  Sidedrawer,
} from '../../../components/layout/Sidedrawer';
import { reactQuillReg } from '../../../utils/constant';

export enum OfferedStatus {
  CLIENT_DECLINED = 'Client Declined',
  CANDIDATE_DECLINED = 'Candidate Declined',
}

export default function OfferRejectNotes({
  modal,
  toggle,
  applicantData,
  latestOffer,
  selectedStatus,
  refetch,
}) {
  const { control, errors, handleSubmit } = useForm();

  const [updateApplicantsStatusSubmission] = useMutation(
    SUBMISSION_STATUS_CHANGE
  );

  const [createNotes] = useMutation(CREATE_NOTES);
  const [createActions] = useMutation(CREATE_ACTION);

  const [updateOfferStatusMutation] = useMutation(UPDATE_OFFER_STATUS);

  const onCreateNote = async (values: any) => {
    const response = await createNotes({
      variables: {
        noteableType: 'Candidate',
        noteableId: applicantData?.candidate?.id,
        category: `Offer - ${OfferedStatus[selectedStatus]}`,
        content: values?.content,
      },
    });

    // Update offer logs
    if (response?.data) {
      updateOfferCallback();
    }
  };

  const updateOfferCallback = async () => {
    let forJob = {
      actionableId: applicantData?.JobOrder?.id,
      actionableType: 'Job Order',
      content:
        selectedStatus === 'CANDIDATE_DECLINED'
          ? `Candidate ${applicantData?.candidate?.fullName} declined on this job.`
          : `Client ${applicantData?.JobOrder?.company?.name} has declined on candidate ${applicantData?.candidate?.fullName}`,
    };

    let forCandidate = {
      actionableId: applicantData?.candidate?.id,
      actionableType: 'Candidate',
      content:
        selectedStatus === 'CANDIDATE_DECLINED'
          ? `This candidate declined on ${applicantData?.JobOrder?.jobTitle} @ ${applicantData?.JobOrder?.company?.name}`
          : `Client ${applicantData?.JobOrder?.company?.name} (${applicantData?.JobOrder?.jobTitle}) has declined on this candidate.`,
    };

    // For Jobs
    const a = await createActions({
      variables: {
        ...forJob,
      },
    });

    // For Candidate
    const b = await createActions({
      variables: {
        ...forCandidate,
      },
    });

    // Updating the job applicant's status
    await updateApplicantsStatusSubmission({
      variables: {
        id: applicantData?.id,
        status:
          selectedStatus === 'CANDIDATE_DECLINED'
            ? 'CANDIDATE_DECLINE'
            : 'CLIENT_DECLINE',
      },
    });

    await updateOfferStatusMutation({
      variables: {
        updatingOfferStatusInput: {
          id: latestOffer?.id,
          jobApplicantId: applicantData?.id,
          status: selectedStatus,
        },
      },
    });

    if (a?.data || b.data) {
      toast.success(
        `${OfferedStatus[selectedStatus]} note added, offer status updated and action logs are created!`
      );

      toggle();
      refetch();
    }
  };

  const customStyles = {
    control: (styles, provided) => ({
      ...styles,

      fontSize: 12,
    }),

    option: (provided, state) => ({
      ...provided,
      fontSize: 12,
    }),

    container: (provided, state) => ({
      ...provided,
      fontSize: 12,
    }),
  };

  return (
    <Sidedrawer toggle={toggle} isOpen={modal}>
      <div className="mb-3">
        <DrawerHeader
          prevClick={() => toggle()}
          nextClick={handleSubmit(onCreateNote)}
          buttonText="Reject"
          heading="Rejection Notes"
        />
      </div>
      <Form>
        <FormGroup>
          <Label>Reject Reason</Label>
          <Controller
            control={control}
            name="content"
            rules={{ required: 'Please add some content!' }}
            defaultValue={''}
            render={({ onChange, ref, value = '' }) => (
              <div className="editor-height">
                <ReactQuill
                  onChange={(value) => {
                    if (reactQuillReg.test(value)) {
                      onChange('');
                    } else {
                      onChange(value);
                    }
                  }}
                  value={value}
                  ref={ref}
                />
              </div>
            )}
            styles={customStyles}
          />
          <small className="text-danger pt-2">{errors?.content?.message}</small>
        </FormGroup>
      </Form>
    </Sidedrawer>
  );
}
