import { useLazyQuery } from "@apollo/client";
import { useContext, useState } from "react";
import { Controller } from "react-hook-form";
import Select from "react-select";
import { Col, FormGroup, Label, Row } from "reactstrap";
import InputField from "../../../../components/layout/InputField";
import { AppContext } from "../../../../context";
import { sequenceOptions } from "../../../../utils/constant";
import { SEQUENCE_LISTING_ID_NAME_TEMPLATE } from "../gql";
import { customSelectDropDown } from '../../../../components/styled/customSelect'
import "../sequence.scss";

const AddSequenceBasic = ({
  control,
  register,
  errors,
  setValue,
  getValues,
}) => {
  const { theme } = useContext(AppContext)
  const [sequenceName, setSequenceName] = useState<string>();
  const [sequenceType, setSequenceType] = useState<string>();

  const { user } = useContext(AppContext);

  const [getSequencesListing, { loading, data }] = useLazyQuery(
    SEQUENCE_LISTING_ID_NAME_TEMPLATE
  );

  const getSequences = (type: string) => {
    getSequencesListing({
      variables: {
        query: "",
        businessDevelopmentSequences:
          type === "BUSINESS_DEVELOPMENT" ? true : null,
      },
    });
  };

  const customStyles = {
    control: (styles, provided) => ({
      ...styles,

      fontSize: 12,
    }),

    option: (provided, state) => ({
      ...provided,
      fontSize: 12,
    }),

    container: (provided, state) => ({
      ...provided,
      fontSize: 12,
    }),
  };

  return (
    <div className="user-basic-form">
      <Row>
        <Col className="mb-3">
          <FormGroup className="mb-0">
            <Label>
              Sequence Type <span className="text-danger">*</span>
            </Label>
            <Controller
              name="sequenceType"
              rules={{
                required: {
                  value: true,
                  message: "Must select type of sequence",
                },
              }}
              render={({ onChange }) => (
                <Select
                  options={sequenceOptions}
                  value={getValues()?.sequenceType}
                  placeHolder="Sequence Type"
                  styles={{ ...customSelectDropDown(theme)  }}
                  onChange={(target) => {
                    onChange(target);
                    if (target?.value) {
                      setSequenceType(target?.value);
                      getSequences(target?.value);
                    }
                    setValue("sequenceType", target);
                  }}
                />
              )}
              control={control}
              styles={customStyles}
            />

            <small className="text-danger">
              {errors?.sequenceType?.message}
            </small>
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col>
          <InputField
            inputtype="text"
            isRequired={true}
            label="Sequence Name"
            inputid="name"
            placeholder="Type sequence name"
            onChange={({ target }) => setSequenceName(target.value)}
            inputRef={register({
              required: {
                value: true,
                message: "Sequence name is required!",
              },
            })}
            errMsg={errors?.name?.message}
          />
          <small className="sequence-name-suggestions">
            Choose a name for your sequence that best describes it. We recommend
            having very specific sequences with three identifiable
            characteristics (e.g. Engineers, NYC, Interseller).
          </small>
        </Col>
      </Row>

      <Row>
        <Col>
          <FormGroup className="mb-2 mt-2">
            <Label>Copy Steps From</Label>
            <Controller
              name="clone_compaign_id"
              rules={{
                required: {
                  value: false,
                  message: "Please select any sequence!",
                },
              }}
              render={({ onChange }) => (
                <Select
                  isSearchable={true}
                  options={data?.allSequencesIdNameTemplate?.map(
                    (item: any) => {
                      return {
                        label: item?.title,
                        value: item?._id,
                      };
                    }
                  )}
                  isLoading={loading}
                  placeholder="Select sequence to copy steps from"
                  onChange={(level: any) => {
                    onChange(level);
                    setValue("clone_compaign_id", level);
                  }}
                  styles={{ ...customSelectDropDown(theme) }}
                />
              )}
              control={control}
              styles={customStyles}
            />
          </FormGroup>
        </Col>
      </Row>

      <Row className="sequence-name">
        <Col>
          <InputField
            inputtype="text"
            label="Sequence Name"
            inputid="ex_name"
            value={
              sequenceName
                ? sequenceType === "BUSINESS_DEVELOPMENT"
                  ? `${user?.namePrefix}-BD-${sequenceName ? sequenceName : ""}`
                  : `${user?.namePrefix}-Recruiting-${
                      sequenceName ? sequenceName : ""
                    }`
                : ""
            }
            disabled={true}
            placeholder="Type sequence name"
          />
        </Col>
      </Row>
    </div>
  );
};

export default AddSequenceBasic;
