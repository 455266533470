import { useContext, useState } from "react";
import { Badge, Button, Card, CardBody } from "reactstrap";
import { downloadSignedUrlFile, formatDateUS } from "../utils/commonFn";
import NoDataFound from "./layout/NoDataFound";
import Pagination from "./layout/Pagination";
import EmailNoteViewModal from "./EmailNoteViewModal";
import Loader from "./layout/Loader";
import InputField from "./layout/InputField";
import { EmailCategoryEnum, PAGE_LIMIT } from "../utils/constant";
import DownloadImg from "../assets/images/download.svg";
import ViewMoreModal from "./ViewMoreModal";
import { AppContext } from "../context";
import { userReplyAccess } from "../utils/helper";
import ReplyButton from "./ReplyButton";

const EmailNoteCard = ({
  data,
  setCurrentPage,
  limit,
  currentPage,
  count,
  emailNotesLoading,
  setLimit,
}) => {
  const [modal, setModal] = useState<boolean>(false);
  const [modalData] = useState<any>();
  const [downloadLoading, setDownloadLoading] = useState<any>(false);
  const { user } = useContext(AppContext);

  const toggle = () => setModal(!modal);

  const [emailModal, setEmailModal] = useState(false);
  const [mailContent, setMailContent] = useState("");
  const mailContentToggle = () => setEmailModal(!emailModal);


  return (
    <>
      {emailNotesLoading ? (
        <Loader />
      ) : (
        <>
          <Card className="border-0">
            <CardBody className="py-2" style={{ fontSize: "20px" }}>
              {!emailNotesLoading && data?.emailNotes?.length
                ? data?.emailNotes?.map((item) => (
                  <div className="candidate-notes-wrapper d-flex justify-content-between flex-wrap">
                    <div className="candidate-notes">
                      <Badge
                        className="px-3 py-2 rounded mb-2"
                        color={`${item?.categories === "EMAIL_SENT"
                          ? "success"
                          : "info"
                          }`}
                      >
                        {EmailCategoryEnum[item?.categories]}
                      </Badge>
                      {item?.isPrivate && (
                        <Badge
                          color="warning"
                          className="text-capitalize ml-2 font-weight-bold"
                        >
                          Private
                        </Badge>
                      )}
                      <small className="text-muted d-block">
                        <strong>From Contacts:</strong>{" "}
                        {item?.fromAddress || "--"}
                      </small>
                      <small className="text-muted d-block">
                        <strong>To Contacts:</strong>{" "}
                        {item?.toAddress?.join(",")}
                      </small>

                      <small className="text-muted d-block">
                        <strong>Cc Contacts:</strong>{" "}
                        {item?.ccAddress?.join(",")}
                      </small>
                      <small className="text-muted d-block">
                        <strong>BCc Contacts:</strong>{" "}
                        {item?.bccAddress?.join(",")}
                      </small>

                      <small className="text-muted d-block">
                        <strong>Subject:</strong> {item?.subject}
                      </small>

                      <small className="text-muted d-block">
                        <strong>
                          Tagged Candidate:{" "}
                          {item?.taggedCandidate?.fullName || "--"}
                        </strong>
                      </small>

                      <small className="text-muted d-block">
                        <strong>
                          Tagged Contact:{" "}
                          {item?.taggedContact?.fullName || "--"}
                        </strong>
                      </small>

                      <small className="text-muted d-block">
                        <strong>
                          Tagged Company: {item?.taggedCompany?.name || "--"}
                        </strong>
                      </small>

                      <small className="text-muted d-block">
                        <strong>
                          Tagged User: {item?.taggedUser?.fullName}
                        </strong>
                      </small>

                      <small className="text-muted d-flex align-items-center flex-wrap">
                        <strong>Body: </strong>
                        <div className="d-inline-block mr-2 clamp-text">
                          {item?.bodyPreview}{" "}
                        </div>
                        <Button
                          className="bg-transparent text-info border-0 p-0"
                          onClick={() => {
                            setMailContent(item?.bodyContent);
                            mailContentToggle();
                          }}
                        >
                          <small className="for-link-theme-color">
                            View More
                          </small>
                        </Button>
                      </small>

                      <small className="text-muted d-block">
                        <strong>Sent At: </strong>
                        {item?.sentDateTime
                          ? formatDateUS(item?.sentDateTime)
                          : "--"}{" "}
                        {item?.sentDateTime &&
                          new Date(
                            item?.sentDateTime
                          ).toLocaleTimeString()}{" "}
                      </small>
                      {userReplyAccess(item, user) && item?.emailWebLink && (
                        <div className="my-1">
                            <ReplyButton text={true} link={item?.emailWebLink} />
                        </div>
                      )}
                      {item?.attachments?.length &&
                        item?.attachments?.map((val, index: number) => {
                          return (
                            <Button
                              size="sm"
                              className="font-weight-bold m-1"
                              disabled={downloadLoading}
                              onClick={async () => {
                                setDownloadLoading(true);
                                await downloadSignedUrlFile(
                                  val?.url,
                                  val?.fileName
                                );
                                setDownloadLoading(false);
                              }}
                            >
                              <img
                                src={DownloadImg}
                                alt="download"
                                className="mr-2 mb-1"
                              />
                              {val?.fileName}
                            </Button>
                          );
                        })}
                    </div>
                  </div>
                ))
                : ""}
              {!emailNotesLoading && !data?.emailNotes?.length && (
                <div className="empty-table-td">
                  <NoDataFound text="No Email Notes Found!" />
                </div>
              )}
              {!emailNotesLoading && data?.emailNotes?.length ? (
                <div className="users-pagination">
                  <Pagination
                    onPageChange={(pageClicked: number) => {
                      setCurrentPage(pageClicked);
                    }}
                    pageCount={Math.ceil(count / limit)}
                    currentPage={currentPage}
                  />

                  <div className="d-flex align-items-center">
                    <div className="pagination-display paginationItemsSelect">
                      <p className="m-0">
                        {(currentPage - 1) * limit + 1} to{" "}
                        {Math.min(currentPage * limit, count)} of of {count}
                      </p>

                      <div className="divider"></div>

                      <label>Display</label>

                      <InputField
                        label=""
                        inputtype="select"
                        selectItems={PAGE_LIMIT.filter(
                          (item) => item.value !== limit
                        )}
                        inputid="limit"
                        placeholder={limit.toString()}
                        inputMethod={(value) => {
                          setCurrentPage(1);
                          setLimit(parseInt(value));
                        }}
                      />
                    </div>
                  </div>
                </div>
              ) : null}
            </CardBody>
          </Card>

          <ViewMoreModal
            toggle={mailContentToggle}
            isOpen={emailModal}
            data={mailContent}
            heading="Email Content"
          />
          <EmailNoteViewModal
            modalData={modalData}
            modal={modal}
            toggle={toggle}
          />
        </>
      )}
    </>
  );
};

export default EmailNoteCard;
