import { Button, Col, Modal, Row } from 'reactstrap';
import { downloadSignedUrlFile, formatDateUS } from '../utils/commonFn';

import NoDataFound from './layout/NoDataFound';
import { Sidedrawer, DrawerHeader } from './layout/Sidedrawer';
import Preview from '../Pages/job-order/job-order-components/document-preview/Preview';
import { FC, useContext, useState } from 'react';
import { GET_SIGNED_URL } from '../Pages/candidate/gql';
import { useMutation } from '@apollo/client';
import { CandidateDetailDrawerProps, ParamsType } from '../interfaces';
import LINKEDIN_ICON from './../assets/images/linkedin.png';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { AppContext } from '../context';
import { UPDATE_CANDIDATE_ROUTE, CANDIDATES_ROUTE, UPDATE_JOB_ORDER_ROUTE, VIEW_CANDIDATE_ROUTE, VIEW_JOB_ORDER_ROUTE } from '../utils/constant';
import { IComingState } from '../Pages/agreements/interfaces';
import { countryWiseDetailedlocation, pushInHistory } from '../utils/helper';
import AlertCard from './layout/Alert';

const CandidateDetailDrawer: FC<CandidateDetailDrawerProps> = ({
  toggle,
  isOpen,
  data,
  isEdit,
  isEditable = true,
}) => {
  const { user, userRoles } = useContext(AppContext);
  const { id } = useParams<ParamsType>()
  const [isPreview, setIsPreview] = useState<boolean>(false);
  const [resumeData, setResumeData] = useState<any>(null);
  const [signedUrlGetter] = useMutation(GET_SIGNED_URL);
  const history = useHistory();
  const location = useLocation<IComingState>()

  const handleResumePreview = async (resume: any) => {
    const response = await signedUrlGetter({
      variables: {
        remoteFilePath: resume?.pdfVersionUrl ? resume?.pdfVersionUrl : resume?.originalUrl,
      },
    });

    if (response?.data?.getSignedUrl?.signedUrl) {
      setResumeData({
        url: response?.data?.getSignedUrl?.signedUrl,
        name: resume?.resumeFileName,
        driveUrl: '',
        id: resume.id,
      });
      setIsPreview(true);
    }
  };

  const onOriginalResumeHandler = async ({
    originalUrl,
    resumeFileName,
  }: {
    originalUrl: string;
    resumeFileName: string;
  }) => {
    downloadSignedUrlFile(originalUrl, resumeFileName);
  };

  const canEdit = () => {
    if (
      userRoles?.includes('ADMIN') ||
      userRoles?.includes('EXECUTIVE') ||
      userRoles?.includes('HR')
    ) {
      return true;
    } else if (user?.id === data?.id) {
      return true;
    } else if (data?.recruiterOwner === null) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <Sidedrawer toggle={toggle} isOpen={isOpen}>
        <div className="mb-4">
          <DrawerHeader prevClick={() => toggle()} buttonText="Edit"
            nextClick={() => {
              history.push({
                pathname: `${UPDATE_CANDIDATE_ROUTE}/${data?.id}`,
                state: {
                  backUrl: `${isEdit ? UPDATE_JOB_ORDER_ROUTE : VIEW_JOB_ORDER_ROUTE}/${id}`,
                  searchParameters: location.search
                }
              })

              pushInHistory(`${isEdit ? UPDATE_JOB_ORDER_ROUTE : VIEW_JOB_ORDER_ROUTE}/${id}`, location.search)
            }}
            heading="Candidate Detail"
            nextButtonView={isEditable}
            disabled={!canEdit()}
          >
            <a href={`${VIEW_CANDIDATE_ROUTE}/${data?.id}`} target='_blank' rel="noreferrer">
              <Button
                type="button"
                className="btn px-3  py-2 btn-next"
                onClick={() => {
                  history.push({
                    // pathname: `${VIEW_CANDIDATE_ROUTE}/${data?.id}`,
                    // state: {
                    //   backUrl: `${VIEW_CANDIDATE_ROUTE}/${data?.id}`,
                    //   searchParameters: location.search
                    // }
                  })

                  pushInHistory(`${CANDIDATES_ROUTE}/`, location.search)
                }}
                size="sm"
              >
                View
              </Button>
            </a>
          </DrawerHeader>
        </div>
        {['US', null, undefined, ''].includes(data?.countryCode) ? null : (
          <AlertCard
            title="Warning!"
            subTitle="Candidate is from outside of the US"
            color="danger"
            outSideUS={true}
          />
        )
        }
        <div className="pb-4">

          <Row className="mb-3">
            <Col xs={4}>
              <h6 className="text-14 text-blackele">Name</h6>
            </Col>
            <Col xs={8}>
              <h5 className="font-weight-normal mb-0  text-blackten">
                {data?.fullName || '--'}
              </h5>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col xs={4}>
              <h6 className="text-14 text-blackele">Current Job title</h6>
            </Col>
            <Col xs={8}>
              <h5 className="font-weight-normal mb-0  text-blackten">
                {data?.jobTitle || '--'}
              </h5>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col xs={4}>
              <h6 className="text-14 text-blackele">Current Company</h6>
            </Col>
            <Col xs={8}>
              <h5 className="font-weight-normal mb-0  text-blackten">
                {data?.companyName || '--'}
              </h5>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col xs={4}>
              <h6 className="text-14 text-blackele">Primary Phone number</h6>
            </Col>
            <Col xs={8}>
              <h5 className="font-weight-normal mb-0  text-blackten">
                {data?.phones?.filter((phone) => phone.isPrimary)[0]
                  ?.phoneNumber || '--'}
              </h5>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col xs={4}>
              <h6 className="text-14 text-blackele">Primary Email</h6>
            </Col>

            <Col xs={8}>
              <h5 className="font-weight-normal mb-0  text-blackten">
                {data?.emails?.filter((email) => email.isPrimary)[0]?.email ||
                  '--'}
              </h5>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col xs={4}>
              <h6 className="text-14 text-blackele">Address</h6>
            </Col>
            <Col xs={8}>
              <h5 className="font-weight-normal mb-0  text-blackten">
                {
                  countryWiseDetailedlocation(data?.countryCode, data?.city, data?.stateCode, data?.streetAddress, data?.postalCode, data?.candidateAddressOne, data?.candidateAddressTwo)
                }

              </h5>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col xs={4}>
              <h6 className="text-14 text-blackele">Status</h6>
            </Col>

            <Col xs={8}>
              <h5 className="font-weight-normal mb-0  text-blackten">
                {data?.status || '--'}
              </h5>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col xs={4}>
              <h6 className="text-14 text-blackele">LinkedIn Profile</h6>
            </Col>
            <Col xs={8}>
              <h5 className="font-weight-normal mb-0  text-blackten">
                {!!data?.linkedInUrl ? (
                  <a href={data?.linkedInUrl} rel="noreferrer" target="_blank">
                    <img src={LINKEDIN_ICON} alt="view" width="21px" />
                  </a>
                ) : (
                  '--'
                )}
              </h5>
            </Col>
          </Row>
          {!isEditable && (
            <Row>
              <Col xs={4}>
                <h6 className="text-14 text-blackele">Skills</h6>
              </Col>
              <Col xs={8}>
                <div className="skillsSection skillsSectionDetailDrawer">
                  <div className="d-flex flex-wrap">
                    {data?.skillsSet?.length
                      ? data?.skillsSet?.map((v) => (
                        <span className="skillBadge">{v?.skill?.name}</span>
                      ))
                      : '--'}
                  </div>
                </div>
              </Col>
            </Row>
          )}
        </div>

        <div className="pb-4">
          <div className="pb-3">
            <strong>Resume</strong>
          </div>

          <div>
            <div>
              {data?.resume?.map((item, index) => (
                <div
                  className={`candidate-drawer-resume-grid ${index === 0 && 'active'
                    }`}
                  key={index}
                >
                  <div className="resume-details">
                    <h6>
                      <span className="">{item.resumeFileName}</span>
                    </h6>
                    <h5 className="m-0">
                      Uploaded By{' '}
                      {item?.createdAt ? formatDateUS(item.createdAt) : '--'}
                    </h5>
                  </div>

                  <div className="flex-none">
                    <Button
                      className="border-0 text-dark px-2 resume-preview"
                      size="sm"
                      onClick={() => {
                        handleResumePreview(item);
                      }}
                    >
                      Preview
                    </Button>

                    <Button
                      className="border-0 btn mx-3 bg-success px-2 btn-sm text-white"
                      onClick={() => onOriginalResumeHandler(item)}
                    >
                      Download
                    </Button>
                  </div>
                </div>
              ))}

              {!data?.resume?.length && (
                <div className="empty-table-td">
                  <NoDataFound text="No Resume Found!" />
                </div>
              )}
            </div>
          </div>
        </div>
      </Sidedrawer>

      <Modal
        isOpen={isPreview}
        toggle={() => setIsPreview(false)}
        size='xl'
        className="revampDialog"
      >
        <div className="pdf-viewer">
          <Preview
            documentName={resumeData?.name}
            documentUrl={resumeData?.url}
            isDownload={false}
            fileStatus={'Original'}
          />
        </div>
      </Modal>
    </>
  );
};

export default CandidateDetailDrawer;
