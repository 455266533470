import { useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Button, Form, Spinner } from "reactstrap";
import { ADD_NEW_ADVERTISED_LOCATION } from "../gql";
import AdvertiseLocationInfo from "./AdvertiseLocationInfo";
import "../../company/company.scss";
// import { convertToLowerCase } from "../../../utils/constant";

const AddNewAdvertiseLocation = ({
  jobOrderId,
  setAddNewAddressToggle,
  ...props
}: any) => {
  const [addNewAdvertisedLocation, { loading }] = useMutation(
    ADD_NEW_ADVERTISED_LOCATION
  );

  const { errors, handleSubmit, register, reset,watch,setValue } = useForm();

  const createAdvertisedLocationHandler = async (address: any) => {
    address.jobOrderId = jobOrderId;
    address.city = address.city.trim();
    const resultOfRequest = await addNewAdvertisedLocation({
      variables: { advertisedLocationInput: { ...address } },
    });
    setAddNewAddressToggle(false);
    if (resultOfRequest?.data) {
      toast.success("Advertised location successfully added!");
    }
    if (resultOfRequest?.errors) {
      toast.warn("Invalid Address");
    }
    props.refetchAddress();
    reset();
  };

  return (
    <Form>
      <div>
        <AdvertiseLocationInfo {...{ register, errors, jobOrderId,watch,setValue }} />
        <div
          className="address-action"
          style={{ paddingTop: 25, paddingBottom: 25 }}
        >
          <Button
            onClick={() => {
              setAddNewAddressToggle(false);
            }}
            type="button"
            className="bg-transparent border-0 text-dark mr-3"
          >
            Cancel
          </Button>
          <button
            type="submit"
            className="buttonGenericStyle filledButton"
            onClick={handleSubmit(createAdvertisedLocationHandler)}
          >
            {loading && <Spinner size="sm" className="mr-2" />}
            Save
          </button>
        </div>
      </div>
    </Form>
  );
};

export default AddNewAdvertiseLocation;
