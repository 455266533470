import { gql } from "@apollo/client";
import AsyncSelect from "react-select/async";
import client from "../apollo";
import { toast } from "react-toastify";
import { AppContext } from '../context'
import { useContext } from "react";

const FETCH_MERGE_COMPANIES = gql`
  query FetchMergeCompanies(
    $page: Int!
    $limit: Int!
    $searchText: String
    $statusSearch: String
  ) {
    getMargeCompanies(
      payload: { page: $page, limit: $limit }
      filters: { searchText: $searchText, statusSearch: $statusSearch }
    ) {
      mergeCompanies {
        id
        destinationCompanyName
      }
    }
  }
`;

const AsyncMergeCompanies = (props) => {
  const { theme }  = useContext(AppContext)
  const _filterCompanies = async (inputValue: string) => {
    try {
      const { data } = await client.query({
        query: FETCH_MERGE_COMPANIES,
        variables: {
          limit: 50,
          page: 1,
          searchText: inputValue,
          statusSearch: null,
        },
      });

      return data && data.getMargeCompanies
        ? data?.getMargeCompanies?.mergeCompanies?.map((item) => ({
            value: item?.destinationCompanyName,
            label: item?.destinationCompanyName,
          }))
        : [];
    } catch (error) {
      toast.error("Error while loading merge companies!");
    }
  };

  const promiseOptions = (inputValue: any): any =>
    new Promise((resolve) => {
      resolve(_filterCompanies(inputValue));
    });

  const customStyles = {
    control: (styles, provided) => ({
      ...styles,       
      background: theme === "light" ? provided.background : "#282828",
      borderColor: theme === "light" ? "#ced4da" : "#525252",
      color: theme === "light" ? provided.background : "#d9d9d9",
      width: props.width,
      fontSize: 12,
      "&:hover": {
        borderColor: theme === "light" ? "#ced4da" : "#282828",
        color: theme === "light" ? provided.background : "#d9d9d9",
        
      },
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: props?.zIndex || 999999,
      fontSize: 12,
      background: theme === "light" ? provided.background : "#282828",
      color: theme === "light" ? provided.background : "#d9d9d9",
      
    }),
    option: (provided) => ({
      ...provided,
      fontSize: 12,
      borderColor: theme === "light" ? "#ced4da" : "#525252",
      background: theme === "light" ? provided.background : "#363636",
      "&:hover": {
        background: theme === "light" ? provided.background : "#474444",
        color: theme === "light" ? provided.background : "#d9d9d9",
      },
    }),
    container: (provided, state) => ({
      ...provided,
      fontSize: 12,
    }),
  };

  return (
    <>
      <AsyncSelect
        cacheOptions
        isClearable
        {...(props?.selectedCompany?.value && {
          value: {
            value: props.selectedCompany.value,
            label: props.selectedCompany.label,
          },
        })}
        ref={props.ref}
        styles={{ ...customStyles }}
        defaultOptions
        onChange={(selected: any) => {
          if (props.onSelected) {
            props.onSelected(selected?.value);
          }
          if (selected) {
            props.onChange(selected?.value);
          } else {
            props.onChange("");
          }
        }}
        loadOptions={promiseOptions}
        placeholder={props?.placeholder}
      />
    </>
  );
};

export default AsyncMergeCompanies;
