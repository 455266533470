import { Col, Form, Input, Label, Row } from 'reactstrap';
import { useEffect } from 'react';
import Select from 'react-select';
import { Controller, useForm } from 'react-hook-form';
import {
  interviewRound,
  timeZoneArray,
  interviewType,
  reactQuillReg,
} from '../../../utils/constant';
import { useMutation } from '@apollo/client';
import InputField from '../../../components/layout/InputField';
import { CREATE_INTERVIEW } from './gql';
import { toast } from 'react-toastify';
import { ICreateInterview, ICreateInterviewDTO } from './interview-interfaces';
import OutlookLogin from './OutlookLogin';
import { useContext, useState } from 'react';
import { AppContext } from '../../../context';
import {
  NOTIFY_CANDIDATE_OWNER_FOR_INVITE,
  UPDATE_JOB_APPLICANT_STATUS_TO_INTERVIEW,
} from '../../job-order/gql';
import moment from 'moment-timezone';
import ReactQuill from 'react-quill';
import { customSelectStyleNew } from '../../../components/styled/customSelect';
import {
  Sidedrawer,
  DrawerHeader,
} from '../../../components/layout/Sidedrawer';
import CustomButton from '../../../components/layout/CustomButton';

export default function InterviewScheduleModal({
  applicantData,
  backToInterview,
  interviewScheduleModal,
  firstInterview,
  setCandidateFilter,
  interviewScheduleModalToggle,
  refetch,
  refetchCandidateOwner,
  closeModel
}: any) {

  const { theme } = useContext(AppContext);
  const [currentDateTimeLocal, setCurrentDateTimeLocal] = useState<string>('');
  const [candidateOwnerIsCompanyOwner, setCandidateOwnerIsCompanyOwner] =
    useState<boolean>(false);

  const { user } = useContext(AppContext);
  const { register, errors, control, handleSubmit } = useForm();
  const [_createInterview, { loading: _createInterviewLoading }] =
    useMutation(CREATE_INTERVIEW);
  const [, setLatestInterviewId] = useState<string>();
  const [, setLatestInterviewType] = useState<string>();

  const [_updateJobApplicantStatusInterview] = useMutation(
    UPDATE_JOB_APPLICANT_STATUS_TO_INTERVIEW
  );

  const [notifyCandidateOwner] = useMutation(NOTIFY_CANDIDATE_OWNER_FOR_INVITE);

  useEffect(() => {
    const now = new Date();
    const offset = now.getTimezoneOffset() * 60000;
    const adjustedDate = new Date(now.getTime() - offset);
    const inputDate = moment(adjustedDate).format('YYYY-MM-DDT12:00');
    setCurrentDateTimeLocal(inputDate);

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (applicantData) {
      const companyOwner = applicantData?.JobOrder?.company?.owner?.id;
      const candidateOwner = applicantData?.candidate?.recruiterOwner?.id;
      const currentUser = user?.id
      if (
        currentUser === companyOwner &&
        currentUser === candidateOwner &&
        companyOwner === candidateOwner
      ) {
        setCandidateOwnerIsCompanyOwner(true);
      }
    }

    // eslint-disable-next-line
  }, [applicantData]);

  const onInterviewClickHandler = async (values: ICreateInterview) => {
    let inputDate = moment(values?.interviewDateTime).format(
      `YYYY-MM-DDTHH:mm:ss${values?.timeZone?.value}`
    );
    values.interviewDateTime = inputDate;
    const obj: ICreateInterviewDTO = {
      interviewType: values?.interviewType?.value,
      jobApplicantId: applicantData?.jobApplicantId,
      interviewDateTime: values.interviewDateTime,
      timeZone: values?.timeZone?.label,
      timeZoneId: values?.timeZone?.value,
      details: values?.details || '',
      location: values?.location || '',
      interviewRoundIndex: firstInterview
        ? 1
        : values?.interviewRoundIndex?.value,
    };
    const interviewCreateResponse = await _createInterview({
      variables: {
        createInterviewDTO: obj,
      },
    });

    if (interviewCreateResponse?.data) {
      setLatestInterviewId(interviewCreateResponse?.data?.createInterview?.id);

      setLatestInterviewType(
        interviewCreateResponse?.data?.createInterview?.interviewType
      );

      const scheduledDate = moment(values?.interviewDateTime)
        .utcOffset(values?.timeZone?.value)
        .format('MM/DD/YYYY hh:mm A');

      toast.success(
        `Interview is scheduled at ${scheduledDate} ${values?.timeZone?.label}`
      );

      if (firstInterview) {
        onInterviewStatusChange();
      }

      if (backToInterview) {
        onInterviewStatusChange();
      }

      // this condition is used for sending notification if candidate owner is different from company owner
      if (!candidateOwnerIsCompanyOwner) {
        // generating a notification to candidate owner
        await notifyCandidateOwner({
          variables: { jobApplicantId: applicantData?.jobApplicantId },
        });
      }
      closeModel();
      setCandidateFilter([{ label: 'Interviewed', value: 'Interviewed' }]);
      refetchCandidateOwner();
      refetch();
      interviewScheduleModalToggle();
    } else if (interviewCreateResponse?.errors) {
      if (interviewCreateResponse?.errors[0]?.message === "Invalid Input") toast.warn("Job Order Has No Company Owner!")
    }
  };

  const onInterviewStatusChange = async () => {
    const res = await _updateJobApplicantStatusInterview({
      variables: { id: applicantData?.jobApplicantId },
    });

    if (res?.data) {
      toast.success('Submission status set to Interviewed');
    }
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      fontSize: 12,
    }),

    container: (provided, state) => ({
      ...provided,
      fontSize: 12,
    }),
  };

  return (
    <div>
      <Sidedrawer toggle={() => { }} isOpen={interviewScheduleModal}>
        <div className="pb-4">
          <DrawerHeader
            prevClick={interviewScheduleModalToggle}
            heading="Schedule Interview"
            nextButtonView={false}
          />
        </div>

        <div className="pb-4">
          {!user?.outLookToken && (
            <div className="modalHeaderCustom">
              <OutlookLogin jobOrderId={applicantData?.jobOrderId} />
            </div>
          )}
        </div>

        <Form>
          <div className="jobOrderRevamp p-0">
            <Row className="mb-4">
              <Col xs={6}>
                <h6 className="font-weight-bold text-14 text-blackten">
                  Candidate
                </h6>
              </Col>
              <Col xs={6}>
                <h6 className="font-weight-normal text-14 text-blackten">
                  {applicantData?.name || '--'}
                </h6>
              </Col>
            </Row>

            <Row className="mb-4">
              <Col xs={6}>
                <h6 className="font-weight-bold text-14 text-blackten">
                  Company
                </h6>
              </Col>
              <Col xs={6}>
                <h6 className="font-weight-normaltext-14 text-blackten">
                  {applicantData?.company || '--'}
                </h6>
              </Col>
            </Row>

            <Row className="mb-4">
              <Col xs={6}>
                <h6 className="font-weight-bold text-14 text-blackten">
                  Job Title
                </h6>
              </Col>
              <Col xs={6}>
                <h6 className="font-weight-normaltext-14 text-blackten">
                  {applicantData?.jobTitle || '--'}
                </h6>
              </Col>
            </Row>

            <Row className="mb-4">
              <Col xs={6}>
                <h6 className="font-weight-bold text-14 text-blackten">
                  Job Location
                </h6>
              </Col>
              <Col xs={6}>
                <h6 className="font-weight-normal text-14 text-blackten">
                  {applicantData?.jobLocation || '--'}
                </h6>
              </Col>
            </Row>
            <Row className="mb-4">
              <Col xs={6}>
                <h6 className="font-weight-bold text-14 text-blackten">Workplace Type</h6>
              </Col>
              <Col xs={6}>
                <h6 className="font-weight-normal text-14 text-blackten">
                  {applicantData?.workplaceTypes ? applicantData?.workplaceTypes : '--'}
                </h6>
              </Col>
            </Row>

          </div>

          <Row>
            <Col lg="6" md="6" sm="12" xs="12" className="pb-3">
              <Label className="text-14 text-blackele pb-3">
                Interview Type
                <span className="text-danger">*</span>
              </Label>
              <Controller
                control={control}
                name="interviewType"
                rules={{
                  required: {
                    value: true,
                    message: 'Interview Type must be selected!',
                  },
                }}
                options={interviewType}
                as={Select}
                styles={{ ...customSelectStyleNew(theme) }}
              />
              <small className="text-danger">
                {errors?.interviewType?.message}
              </small>
            </Col>

            <Col lg="6" md="6" sm="12" xs="12" className="pb-3">
              <Label className="text-14 text-blackele pb-3">
                Interview Round <span className="text-danger">*</span>
              </Label>

              <Controller
                control={control}
                isDisabled={firstInterview ? true : false}
                rules={{
                  required: {
                    value: true,
                    message: 'Interview Round must be selected!',
                  },
                }}
                name="interviewRoundIndex"
                defaultValue={firstInterview && { label: 'First', value: 1 }}
                options={interviewRound?.map((item) => {
                  return {
                    label: item.label,
                    value: item.value,
                  };
                })}
                as={Select}
                styles={{ ...customSelectStyleNew(theme) }}
              />
              <small className="text-danger">
                {errors?.interviewRoundIndex?.message}
              </small>
            </Col>

            <Col lg="6" md="6" sm="12" xs="12" className="pb-3 ">
              <Label className="text-14 text-blackele pb-3">
                Timezone <span className="text-danger">*</span>
              </Label>
              <Controller
                control={control}
                name="timeZone"
                rules={{
                  required: {
                    value: true,
                    message: 'Timezone must be selected!',
                  },
                }}
                defaultValue={timeZoneArray["US"]?.find(
                  (item) => item?.label === applicantData?.jobTimezone
                )}
                options={timeZoneArray["US"]}
                as={Select}
                styles={{ ...customSelectStyleNew(theme) }}
              />

              <small className="text-danger">{errors?.timeZone?.message}</small>
            </Col>

            <Col
              lg="6"
              md="6"
              sm="12"
              xs="12"
              className="pb-3 custom-form-input form-input"
            >
              <Label className="text-14 text-blackele pb-3">
                Date &amp; Time<span className="text-danger">*</span>
              </Label>
              <Input
                defaultValue={currentDateTimeLocal}
                step="2"
                min={new Date().toISOString().substring(0, 19)}
                type="datetime-local"
                name="interviewDateTime"
                innerRef={register({
                  required: {
                    value: true,
                    message: 'Interview Date Time is required!',
                  },
                })}
              />
              <small className="text-danger">
                {errors?.interviewDateTime?.message}
              </small>
            </Col>

            <Col sm="12" xs="12" className="pb-3 custom-form-input form-input">
              <Label className="text-14 text-blackele pb-3">Location</Label>
              <InputField
                inputtype="text"
                inputid="location"
                placeholder="location"
                inputRef={register({
                  required: { value: false, message: 'Location is required' },
                })}
              />
            </Col>

            <Col lg="12" className="pb-3">
              <Label className="text-14 text-blackele pb-3">
                Details<span className="text-danger">*</span>
              </Label>

              <Controller
                control={control}
                name="details"
                rules={{
                  required: {
                    value: true,
                    message: 'Add some detail content!',
                  },
                }}
                defaultValue={null}
                render={({ onChange, ref, value }) => (
                  <div className="editor-height w-100">
                    <ReactQuill
                      onChange={(value) => {
                        if (reactQuillReg.test(value)) {
                          onChange(null);
                        } else {
                          onChange(value);
                        }
                      }}
                      ref={ref}
                      value={value}
                    />
                    <small className="text-danger pt-2">
                      {errors?.details?.message}
                    </small>
                  </div>
                )}
                styles={customStyles}
              />
            </Col>
          </Row>
          <div className="d-flex justify-content-end align-items-center">
            <CustomButton
              buttonText={'Schedule'}
              loading={_createInterviewLoading}
              // disabled={user?.outLookToken ? false : true}
              buttonColor={'primary'}
              buttonType="submit"
              buttonClick={handleSubmit(onInterviewClickHandler)}
            />
          </div>
        </Form>
      </Sidedrawer>
    </div>
  );
}