// packages block
import { FC, Key, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { Button, Card, CardBody } from 'reactstrap';
//components block
import Loader from '../../../components/layout/Loader';
import { useMutation } from '@apollo/client';
import InputField from '../../../components/layout/InputField';
import NoDataFound from '../../../components/layout/NoDataFound';
// constants, utils and graphql block
import { formatDateUS } from '../../../utils/commonFn';
import { INotificationCard } from '../../../interfaces';
import { messageParsing } from '../../../utils/Temp-Helper';
import { notificationJsonTruth } from '../../../utils/helper';
import { MARK_AS_READ } from '../gql';
import { Pagination } from '../../../components/layout/Pagination';
import PreviewCandidateSubmitEmail from './PreviewCandidateSubmitEmail';
import {
  COMPANY_OWNERSHIP_REPORT_ROUTE,
  PAGE_LIMIT, REQUESTS_ROUTE, VIEW_ACCOUNTING_DASHBOARD_ROUTE, VIEW_AGREEMENT_ROUTE, VIEW_CANDIDATE_ROUTE,
  VIEW_JOB_ORDER_ROUTE, VIEW_PLACEMENT_MANAGEMENT_ROUTE
} from '../../../utils/constant';

const UnreadNotificationCard: FC<INotificationCard> = ({
  searchInput,
  fetch,
  pendingActions,
  update,
  notificationHandler,
  page,
  setPage,
  setLimit,
  limit,
  loading,
  data
}): JSX.Element => {
  const history = useHistory();
  const [previewModal, setPreviewModal] = useState<any>(false);
  const [notifyBackId] = useState<string>('');
  const [responseSubmit] = useState<string>('');
  const [submissionID] = useState<string>();

  const [readNotification] = useMutation(MARK_AS_READ);

  const previewHandler = (item: {
    id: string;
    notifyableType?: string;
    alertType: string;
    alertMsg: any;
    createdAt?: Date | string;
  }) => {
    markAsReadHandler(item?.id); 
    if (item?.alertType === 'PLACEMENT_REMINDER') {
      const infoExtractor = JSON.parse(item?.alertMsg);
      const placementId = infoExtractor.placementId;

      if (infoExtractor?.to === 'ACCEPT_REJECT_EXECUTIVE') {
        history.push({
          pathname: `${VIEW_ACCOUNTING_DASHBOARD_ROUTE}/${placementId}`,
        });
      } else if (
        infoExtractor?.to === 'ACCEPT_REJECT_MANAGER' ||
        infoExtractor?.to === 'ACCEPT_REJECT_AM' ||
        infoExtractor?.to === 'UPDATE'
      ) {
        history.push({
          pathname: `${VIEW_PLACEMENT_MANAGEMENT_ROUTE}/${placementId}`,
        });
      }
    } else if (
      item?.alertType === 'CLIENT_CHECKUP_REMINDER' &&
      item?.notifyableType === 'JobOrder'
    ) {
      const infoExtractor = JSON.parse(item?.alertMsg);
      const jobOrderId = infoExtractor?.jobOrderId;

      history.push({
        pathname: `${VIEW_JOB_ORDER_ROUTE}/${jobOrderId}`,
      });
    } else if (item?.alertType === 'SEND_AGREEMENT_CHECKUP') {
      const infoExtractor = JSON.parse(item?.alertMsg);
      const agreementId = infoExtractor?.agreementId;

      history.push({
        pathname: `${VIEW_AGREEMENT_ROUTE}/${agreementId}`,
      });
    } else if (item?.alertType === 'INFORMING_AGREEMENT_CHECKUP') {
      const infoExtractor = JSON.parse(item?.alertMsg);
      const agreementId = infoExtractor?.agreementId;

      history.push({
        pathname: `${VIEW_AGREEMENT_ROUTE}/${agreementId}`,
      });
    } else if (item?.alertType === 'INTERVIEW_INVITE_CHECKUP') {
      const infoExtractor = JSON.parse(item?.alertMsg);

      history.push({
        pathname: `${VIEW_CANDIDATE_ROUTE}/${infoExtractor?.candidate}`,
        state: {
          backUrl: `/notifications`,
          landedFrom: `pipeline`,
        },
        search:
          '?' +
          new URLSearchParams({
            applicantId: infoExtractor?.jobApplicantId,
            activeTab: infoExtractor?.activeTab,
          }).toString(),
      });
    } else if (item?.alertType === 'OWNERSHIP_EXPIRATION') {
      history.push({
        pathname: `${COMPANY_OWNERSHIP_REPORT_ROUTE}`,
      });
    } else if (item?.alertType === 'PLACEMENT_CANCELLATION_REQUEST') {
      history.push({
        pathname: `${REQUESTS_ROUTE}`,
      });
    } else {
      const infoExtractor = JSON.parse(item?.alertMsg);
      history.push({
        pathname: `${VIEW_CANDIDATE_ROUTE}/${infoExtractor?.candidateId}`,
        state: {
          backUrl: `/notifications`,
          landedFrom: `pipeline`,
        },
        search:
          '?' +
          new URLSearchParams({
            applicantId: infoExtractor?.applicantId,
            activeTab: infoExtractor?.activeTab,
          }).toString(),
      });
    }
  };

  const markAsReadHandler = async (id: string) => {
    await readNotification({
      variables: { notificationInput: { id } },
    });
    toast.success('Notification is pushed to read state');
    notificationHandler && notificationHandler();
    fetch();
  };

  useEffect(() => {
    if (searchInput) {
      setLimit(5);
      setPage(1);
      notificationHandler && notificationHandler();
    } else {
      notificationHandler && notificationHandler();
    }
  }, [searchInput, pendingActions, update, page, limit, notificationHandler, setLimit, setPage]);

  return (
    <>
      {!!data?.allNewNotifications?.notifications?.length && (
        <div>
          <div>
            <Card className="border-0 mt-section-4 mb-0">
              <div className="heading-border">
                <h5>Unread Notifications</h5>
              </div>

              {loading ? (
                <Loader />
              ) : (
                <CardBody className="p-0">
                  <div className="user-basic-form">
                    {data?.allNewNotifications?.notifications?.map(
                      (
                        item: {
                          id: string;
                          notifyableType: string;
                          alertType: string;
                          alertMsg: any | null | undefined;
                          createdAt: string | any;
                        },
                        index: Key
                      ) => {
                        return (
                          <div className="notification-card" key={index}>
                            <div>
                              <span className='message'>
                                {messageParsing(item)}
                              </span>
                              <p>
                                {formatDateUS(item?.createdAt)} {' '}
                                {new Date(item.createdAt).toLocaleTimeString()}
                              </p>
                            </div>
                            <div>
                              <Button
                                color="secondary"
                                className="mr-2"
                                onClick={() => markAsReadHandler(item?.id)}
                              >
                                Mark As Read
                              </Button>

                              {notificationJsonTruth(
                                item?.notifyableType,
                                item?.alertType
                              ) ? (
                                <Button
                                  color="primary"
                                  onClick={() => previewHandler(item)}
                                >
                                  Manage
                                </Button>
                              ) : null}
                            </div>
                          </div>
                        );
                      }
                    )}

                    {!loading &&
                      !data?.allNewNotifications?.notifications?.length && (
                        <div className="for-position-multiselect">
                          <NoDataFound text="No unread notifications" />
                        </div>
                      )}
                  </div>
                </CardBody>
              )}
            </Card>

            {!!data?.allNewNotifications?.notifications?.length && (
              <div className="users-pagination">
                <Pagination
                  onPageChange={(pageClicked: number) => {
                    setLimit(limit);
                    setPage(pageClicked);
                  }}
                  pageCount={Math.ceil(
                    data?.allNewNotifications?.count / limit
                  )}
                  currentPage={page}
                />

                <div className="d-flex align-items-center">
                  <div className="pagination-display paginationItemsSelect">
                    <p className="m-0">
                      {(page - 1) * limit + 1} to {page * limit} of{' '}
                      {data?.allNewNotifications?.count}
                    </p>

                    <div className="divider"></div>

                    <label>Display</label>

                    <InputField
                      label=""
                      inputtype="select"
                      selectItems={PAGE_LIMIT.filter(
                        (item) => item.value !== limit
                      )}
                      inputid="limit"
                      placeholder={limit.toString()}
                      inputMethod={(value: string) => {
                        setPage(1);
                        setLimit(parseInt(value));
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>

          {previewModal && (
            <PreviewCandidateSubmitEmail
              open={previewModal}
              toggle={setPreviewModal}
              submissionId={submissionID}
              notifyBackId={notifyBackId}
              alertMsg={responseSubmit}
            />
          )}
        </div>
      )}
    </>
  );
};

export default UnreadNotificationCard;
