import { Col, Row } from "reactstrap";
import CcListOfContacts from "../../CcListOfContacts";
import BillingType from "./BillingType";
import CandidateBillingCredit from "./CandidateBillingCredit";
import ClientBillingCredit from "./ClientBillingCredit";
import TotalCredit from "./TotalBilingCredit";

const TempHireIndex = ({ tempBillingTypeCardProps, placementSplit,placementContacts }) => {
  return (
    <div>
      <div className="jobOrderRevamp">
        <Row>
          <Col lg="6" md="6" sm="12" xs="12">
            <BillingType billingTypeCardProps={tempBillingTypeCardProps} />
          </Col>
        </Row>
      </div>

      <Row>
        <Col lg="6" md="6" sm="12" xs="12">
          <div className="jobOrderRevamp">
            <CandidateBillingCredit
              netCommission={
                tempBillingTypeCardProps?.getPlacementById?.netCommission
              }
              recruiters={placementSplit?.filter(
                (item) => item.splitType === "RECRUITING"
              )}
            />
          </div>
        </Col>

        <Col lg="6" md="6" sm="12" xs="12">
          <div className="jobOrderRevamp">
            <ClientBillingCredit
              netCommission={
                tempBillingTypeCardProps?.getPlacementById?.netCommission
              }
              accountManagers={placementSplit?.filter(
                (item) => item.splitType === "ACCOUNT_MANAGER"
              )}
            />
          </div>
        </Col>

        <Col lg="6" md="6" sm="12" xs="12">
          <div className="jobOrderRevamp">
            <TotalCredit
              netCommission={
                tempBillingTypeCardProps?.getPlacementById?.netCommission
              }
              placementSplit={placementSplit}
            />
          </div>
        </Col>
        {placementContacts?.placementCCContact?.length ?  placementContacts?.placementCCContact?.map((ccContact,index)=>{
                return(
                  <Col lg="6" md="12" sm="12" xs="12">
                  <CcListOfContacts
                  index={ index+1}
                  ccContactInfo={{
                    firstName: ccContact?.companyContact?.firstName,
                    lastName: ccContact?.companyContact?.lastName,
                    address:
                      ccContact?.companyContact?.companyLocation,
                    primaryPhone:
                      ccContact?.companyContact?.contactPhone
                        ?.length &&
                      ccContact?.companyContact?.contactPhone?.find(
                        (item: { isPrimary: boolean }) =>
                          item?.isPrimary
                      )?.number,
                    email:
                      ccContact?.companyContact?.contactEmail?.find(
                        (item) => item?.isPrimary
                      )?.email,
                  }}
                /> 
              </Col>
                )
              }):''}

      </Row>
    </div>
  );
};

export default TempHireIndex;
