import { FC } from "react";

import LogoImage from "../../../assets/images/logo.svg";
import LoginImage from "../../../assets/images/login.svg";
import VerifyImage from "../../../assets/images/verified.svg";
import BACKGROUND_IMG from "../../../assets/images/background.jpeg";

import "./style.scss";

type AuthLayout = {
  title: string;
  subTitle: string;
  children: JSX.Element;
  isThankYou?: boolean;
  isLogin?: boolean;
  isLoginPages?: boolean;
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
const AuthLayout: FC<AuthLayout> = ({
  title,
  subTitle,
  children,
  isThankYou,
  isLogin,
  isLoginPages,
}) => {
  return (
    <div className="main-section" style={{ backgroundImage: `url(${BACKGROUND_IMG})`, backgroundSize: 'cover' }}>
      <div className="user-form "  >
        <div className="user-form-container Card-layout " style={{ backgroundColor: 'rgba(255, 255, 255, 0.8)' }} >
          <div className="logo-header"  >
            {isLoginPages && <img src={LogoImage} alt="logo" />}

            {isLogin && <img src={LoginImage} alt="logo" />}
          </div>

          <div className="user-form-inner bg-transparent" style={{ boxShadow: 'none' }} >
            {isThankYou && (
              <img className="verify-check" src={VerifyImage} alt="verify" />
            )}

            <h2>{title}</h2>
            <h6>{subTitle}</h6>

            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;

{/* <div className="main-section" style={{ backgroundImage: `url(${BACKGROUND_IMG})`, backgroundSize: 'cover' }}>
  <div className="user-form "  >
    <div className="Card-layout" style={{ backgroundColor: 'rgba(255, 255, 255, 0.8)', width: '70%' }} >
      <div className="logo-header "  >
        {isLoginPages && <img src={LogoImage} alt="logo" />}

        {isLogin && <img src={LoginImage} alt="logo" />}
      </div>

      <div className="user-form-inner bg-transparent" >
        {isThankYou && (
          <img className="verify-check" src={VerifyImage} alt="verify" />
        )}

        <h2>{title}</h2>
        <h6>{subTitle}</h6>

        {children}
      </div>
    </div>
  </div>
</div> */}