import { gql } from "@apollo/client";

export const FETCH_PAST_NOTIFICATIONS = gql`
  query FetchAllNotifications(
    $page: Int!
    $limit: Int!
    $searchText: String
    $alertStatus: String
  ) {
    allPastNotifications(
      payload: { page: $page, limit: $limit }
      filters: { searchText: $searchText, alertStatus: $alertStatus }
    ) {
      notifications {
        id
        alertMsg
        alertType
        notifyableId
        alertStatus
        notifyableType
        displayedAt
        createdAt
        dismissedAt
        updatedAt
        readAt
        User {
          id
          firstName
          phone
          lastName
        }
      }
      count
    }
  }
`;

export const DELETE_SUBMISSION_ATTACHMENT = gql`
  mutation DeleteSubmissionAttachment($attachmentId: String!) {
    deleteSubmissionAttachment(attachmentId: $attachmentId) {
      message
      status
    }
  }
`;



export const PENDING_ACTIONS_CATEGORIES = gql`
  query pedingActionOfAllCategories{
    pedingActionOfAllCategories{
      pendingNotifications{
        id
        category
        counter
      }
      count
    }
  }
`;


export const FETCH_NEW_NOTIFICATIONS = gql`
  query FetchAllNewNotifications(
    $page: Int!
    $limit: Int!
    $searchText: String
    $alertStatus: String
    $popUpDismissal: Boolean
  ) {
    allNewNotifications(
      payload: { page: $page, limit: $limit }
      filters: {
        searchText: $searchText
        alertStatus: $alertStatus
        popUpDismissal: $popUpDismissal
      }
    ) {
      notifications {
        id
        alertMsg
        alertType
        notifyableId
        alertStatus
        notifyableType
        displayedAt
        createdAt
        dismissedAt
        updatedAt
        readAt
        User {
          id
          firstName
          phone
          lastName
        }
      }
      count
    }
  }
`;

//Mutations goes here

export const NotificationsInput = gql`
  input NotificationInput {
    alertMsg: String
    alertStatus: String
    alertType: String
    notifyableId: AgreementStatus
    agreementType: AgreementType
    notifyableType: Int
    displayedAt: String
    dismissedAt: String
    readAt: String
    userId: String
  }
`;

export const UpdateNotificationInput = gql`
  input UpdateNotificationInput {
    id: String
  }
`;

export const MARK_AS_READ = gql`
  mutation MarkAsRead($notificationInput: UpdateNotificationInput!) {
    markAsRead(notificationInput: $notificationInput) {
      message
      status
    }
  }
`;

export const MARK_AS_UnREAD = gql`
  mutation MarkAsUnRead($notificationInput: UpdateNotificationInput!) {
    markAsUnRead(notificationInput: $notificationInput) {
      message
      status
    }
  }
`;

export const DISMISSING_NOTIFICATION = gql`
  mutation DismissingNotification(
    $notificationInput: UpdateNotificationInput!
  ) {
    dismissingNotification(notificationInput: $notificationInput) {
      message
      status
    }
  }
`;
export const DISMISSAL_POPUP_NOTIFICATION = gql`
  mutation DismissalPopUpNotification($notificationId: String!) {
    dismissalPopUpNotification(notificationId: $notificationId) {
      message
      status
    }
  }
`;

export const PENDING_ACTIONS_SCHEDULER = gql`
mutation PendingActionScheduler{
    PendingActionScheduler{
      message
      status
    }
  }
`;
