import React, { useState, useEffect } from 'react';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { useQuery } from '@apollo/client';
import StatsCard from '../../components/layout/StatsCard';
import GoBack from '../../components/GoBack';
import { FETCH_USER_STATS } from '../dashboard/gql';
import { moneyFormatter } from '../../utils/helper';
import './style.scss';
import { DASHBOARD_ROUTE, SETTINGS_ROUTE } from '../../utils/constant';

const MyStats = ({ history, ...props }): JSX.Element => {
  const [stats, setStats] = useState<any>({});
  const [startDate, setStartDate] = useState<any>(`${new Date().getFullYear()}-01-01`);
  const [endDate, setEndDate] = useState<any>(`${new Date().getFullYear()}-12-31`);

  const STATS_DATA = [
    {
      number: stats?.lookups,
      title: 'Total Lookups',
    },
    {
      number: stats?.totalNotes,
      title: 'Total Notes',
    },
    {
      number: stats?.uniqueNotes,
      title: 'Unique Notes',
    },
    {
      number: stats?.totalSubmissions,
      title: 'Total Submissions',
    },
    {
      number: stats?.contacts,
      title: 'Total Contacts',
    },
    {
      number: stats?.completedSubmissions,
      title: 'Completed Submissions',
    },
    {
      number: stats?.totalInterviews,
      title: 'Total Interviews',
    },
    {
      number: stats?.initialInterviews,
      title: 'Initial Interviews',
    },
    {
      number: stats?.messages,
      title: 'Total Messages',
    },
    {
      number: stats?.offers,
      title: 'Offers',
    },
    {
      number: moneyFormatter(stats?.billingCredit),
      title: 'DH Billing Credit',
    },
    {
      number: stats?.totalSignedAgreements,
      title: 'Signed Agreements',
    },
  ];

  const { data, loading } = useQuery(FETCH_USER_STATS, {
    variables: {
      statsDateFilter: {
        startDate,
        endDate,
      },
    },
  });

  useEffect(() => {
    if (data) {
      const updatedStats = {
        totalNotes: data?.fetchUserStats?.totalNotes,
        uniqueNotes: data?.fetchUserStats?.uniqueNotes,
        totalSubmissions: data?.fetchUserStats?.totalSubmissions,
        completedSubmissions: data?.fetchUserStats?.completedSubmissions,
        totalInterviews: data?.fetchUserStats?.totalInterviews,
        initialInterviews: data?.fetchUserStats?.initialInterviews,
        offers: data?.fetchUserStats?.totalOffers,
        billingCredit: data?.fetchUserStats?.totalBilling?.toFixed(2),
        totalSignedAgreements: data?.fetchUserStats?.totalSignedAgreements,
        lookups: data?.fetchUserStats?.lookups ? data?.fetchUserStats?.lookups : 0,
        contacts: data?.fetchUserStats?.contacts ? data?.fetchUserStats?.contacts : 0,
        messages: data?.fetchUserStats?.messages ? data?.fetchUserStats?.messages : 0,
      };
      setStats(updatedStats);
    }
  }, [data]);

  return (
    <div>
      {props.match.params.key === 'widget' &&
        <GoBack
          url={DASHBOARD_ROUTE}
        />}

      <div className="jobOrderRevamp">
        <div className="d-flex justify-content-between h-100 align-items-center">
          <h5> My Recruiter Stats </h5>
          <button
            type="button"
            onClick={() => {
              history.push(SETTINGS_ROUTE);
            }}
            className="buttonGenericStyle filledButton"
          >
            Edit
          </button>
        </div>
        <div>
          <Row>
            <Col lg={3} md={6} sm={12}>
              <FormGroup className="mb-1">
                <Label>Start Date</Label>
                <Input
                  defaultValue={startDate}
                  type="date"
                  name="statsStartDate"
                  onChange={(e) => setStartDate(e.target.value)}
                  max={new Date().toISOString().substring(0, 10)}
                />
              </FormGroup>
            </Col>

            <Col lg={3} md={6} sm={12}>
              <FormGroup className="mb-1">
                <Label>End Date</Label>
                <Input
                  defaultValue={endDate}
                  type="date"
                  name="statsEndDate"
                  max={new Date().toISOString().substring(0, 10)}
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </FormGroup>
            </Col>
          </Row>

          <Row className='mt-3'>
            {STATS_DATA?.map((item, index) => (
              <Col lg={3} md={6} sm={12}>
                <React.Fragment key={index}>
                  <StatsCard
                    number={item.number}
                    title={item.title}
                    loading={loading}
                  />
                </React.Fragment>
              </Col>
            ))}
          </Row>
        </div>
      </div>
    </div>
  );
};

export default MyStats;
