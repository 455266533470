import { Modal, ModalBody, ModalFooter } from "reactstrap";
import ContactViewHeader from "./ContactViewHeader";
import ContactViewDetails from "./ContactViewDetails";
import { GET_CONTACT_DETAILS } from "../gql";
import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import Loader from "../../../../components/layout/Loader";
import EditContactManually from "./EditContactManually";

export default function ContactViewModal({
  contactViewToggle,
  contactViewModal,
  refetchStats,
  contactId,
  reFetchContacts,
  compaignId,
}) {
  const [editContactManually, setEditContactManually] =
    useState<boolean>(false);

  const [getContactDetails, { loading, data }] =
    useLazyQuery(GET_CONTACT_DETAILS);

  useEffect(() => {
    if (contactId && contactViewModal) {
      getContactDetails({ variables: { contactId } });
    }

    // eslint-disable-next-line
  }, [contactId, contactViewModal]);

  return (
    <div>
      <Modal
        isOpen={contactViewModal}
        toggle={contactViewToggle}
        className="revampDialog revampDialogWidth"
      >
        <ModalBody>
          {loading ? (
            <Loader loaderClass="sm-height" />
          ) : (
            <>
              {editContactManually ? (
                <EditContactManually
                  refetchContactDetails={() =>
                    getContactDetails({ variables: { contactId } })
                  }
                  contactId={contactId}
                  setEditContactManually={setEditContactManually}
                  contactDetailsData={data?.getContactDetails}
                />
              ) : (
                <div>
                  <ContactViewHeader
                    setEditContactManually={setEditContactManually}
                    refetchStats={refetchStats}
                    contactDetailsData={data?.getContactDetails}
                    reFetchContacts={reFetchContacts}
                    contactViewToggle={contactViewToggle}
                  />
                  <ContactViewDetails
                    compaignId={compaignId}
                    contactId={contactId}
                    contactDetailsData={data?.getContactDetails}
                  />
                </div>
              )}
            </>
          )}
        </ModalBody>
        {!editContactManually && (
          <ModalFooter>
            <button
              className="buttonPrimary mr-3"
              onClick={contactViewToggle}
              type="button"
            >
              Cancel
            </button>
          </ModalFooter>
        )}
      </Modal>
    </div>
  );
}
