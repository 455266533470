import { gql } from "@apollo/client";

export const ALL_MODAL_ACCCESS_SETTINGS = gql`
  query AllModalAccessSettings{
    AllModalAccessSettings {
      modalAccessSettings {
        id
        updatedAt
        createdAt
        feature
        type
        usersArray
        userIDName{
          label
          value
        }
       
      }
    }
  }
`;

export const SAVE_MODAL_ACCESS_SETTINGS = gql`
  mutation SaveModalAccessSettings($settingDetails: settingDetailsInput!) {
    SaveModalAccessSettings( settingDetails: $settingDetails) {
      message
      status
    }
  }
`;