import { gql } from '@apollo/client';
const companyDetail = `
companies{
  id
  name
  status
  size
  createdAt
  startedAt
  updatedAt
  fbUrl
  instaUrl
  linkedInUrl
  twitterUrl
  careerPageURL
  culture
  summary
  source
  sourceNote
  phones {
    id
    phoneNumber
    isPrimary
  }
  ownerAndSharees{
    id
    ownerType
    owner{
      id
      fullName
    }
  }
  companyOwner{
    owner{
      id
      fullName
    }
  }

  address{
      isPrimary
      isDeleted
      streetAddress
      city
      state
      country
    }
    companyAgreement{
      id
      nickName
    }
  companyStats{
      totalActiveJobOrder
      totalPlacement
      totalActiveAgreement
    }
  }

count
`;

export const FETCH_COMPANIES = gql`
 query FetchAllCompanies($page: Int!, $limit: Int!, $searchText:String, $statusSearch:String, $myCompany: Boolean,$columnFilters: CompanyColumnFilter
  ) {
  allCompanies(
    payload: { page: $page, limit: $limit},
    filters:{searchText:$searchText,statusSearch:$statusSearch,myCompany: $myCompany}
    columnFilters:$columnFilters
  ) {
    ${companyDetail}
  }
 }`;

export const FETCH_All_DropDown_COMPANIES = gql`
  query FetchAllCompanies($page: Int!, $limit: Int!, $searchText: String) {
    companiesForDropDown(
      payload: { page: $page, limit: $limit }
      filters: { searchText: $searchText }
    ) {
      companies {
        id
        name
        address {
          id
          isPrimary
          isDeleted
          streetAddress
          city
          state
          zipCode
          country
        }
        createdAt
      }
      count
    }
  }
`;

export const FETCH_COMPANY_DETAILS = gql`
  query FetchCompanyDetail($companyId: String!) {
    getCompany(companyId: $companyId) {
      id
      name
      status
      size
      createdAt
      startedAt
      updatedAt
      fbUrl
      instaUrl
      linkedInUrl
      twitterUrl
      careerPageURL
      hasLinkedIn
      culture
      summary
      source
      sourceNote
      hasCareerPageURL
      hasCompanyURL
      billBoxTag
      nonSolicit
      phones {
        phoneNumber
        status
        isPrimary
      }
      domain {
        id
        isPrimary
        domainUrl
        domainName
      }
      # companyAgreement {
      #   id
      #   nickName
      #   status
      #   signedAt
      # }
      companyContacts {
        id
        fullName
        #   jobTitle
        #   contactEmail {
        #     id
        #     email
        #     isPrimary
        #   }
      }
      # address {
      #   isPrimary
      #   isDeleted
      #   streetAddress
      #   city
      #   zipCode
      #   country
      #   state
      #   id
      # }
      owner {
        id
        fullName
      }
      ownerAndSharees {
        id
        sharingId
        ownerType
        owner {
          id
          fullName
        }
      }
      # creator {
      #   id
      #   fullName
      # }
      companyIndustry {
        industry {
          id
          name
        }
      }
    }
  }
`;

export const FETCH_COMPANY_OWNER_DETAILS = gql`
  query FetchCompanyOwnerDetail($companyId: String!) {
    getCompany(companyId: $companyId) {
      ownerAndSharees {
        id
        sharingId
        ownerType
        owner {
          id
          fullName
          outLookToken
          photo
          firstName
          email
        }
      }
    }
  }
`;

export const FETCH_COMPANY_MINIMUM_VERSION = gql`
  query FetchCompanyDetail($companyId: String!) {
    getCompany(companyId: $companyId) {
      id
      name
    }
  }
`;

export const FETCH_INDUSTRIES = gql`
  query FetchIndustries {
    allIndustries {
      id
      name
      createdAt
      updatedAt
    }
  }
`;

export const GET_NOTES = gql`
  query GetNotes(
    $id: String!
    $page: Int!
    $limit: Int!
    $notePayload: NotePayload!
  ) {
    getNotes(
      id: $id
      payload: { page: $page, limit: $limit }
      notePayload: $notePayload
    ) {
      notes {
        id
        content
        createdAt
        updatedAt
        modifiedOn
        noteableType
        category
        user {
          id
          fullName
        }
      }
      count
    }
  }
`;

export const GET_EMAIL_NOTES = gql`
  query GetEmailNotes(
    $emailNotesPayload: EmailNotesPayload!
    $page: Int!
    $limit: Int!
  ) {
    getEmailNotes(
      emailNotesPayload: $emailNotesPayload
      payload: { page: $page, limit: $limit }
    ) {
      emailNotes {
        id
        toAddress
        ccAddress
        bccAddress
        categories
        fromAddress
        sentDateTime
        subject
        bodyPreview
        bodyContent
        isPrivate
        emailWebLink
        hasAttachment
        attachments {
          fileName
          url
        }
        taggedCompany {
          id
          name
        }
        taggedContact {
          id
          fullName
        }
        taggedCandidate {
          id
          fullName
        }
        taggedUser {
          id
          fullName
        }
        createdAt
      }
      count
    }
  }
`;

export const CHECK_NON_SOLICIT_COMPANY = gql`
query checkNonSolicitCompany($input:String!){
  checkNonSolicitCompany(companyName:$input)
}
`


export const GET_EMAIL_NOTES_FOR_COMPANY_CONTACT = gql`
  query GetEmailNotesForParticularContact(
    $emailNotesPayloadForParticularContact: EmailNotesPayloadForParticularContact!
    $page: Int!
    $limit: Int!
  ) {
    getEmailNotesForParticularContact(
      emailNotesPayloadForParticularContact: $emailNotesPayloadForParticularContact
      payload: { page: $page, limit: $limit }
    ) {
      emailNotes {
        id
        toAddress
        ccAddress
        bccAddress
        fromAddress
        subject
        isPrivate
        bodyContent
        bodyPreview
        sentDateTime
        categories
        emailType
        emailWebLink
        hasAttachment
        attachments {
          fileName
          url
        }
        taggedUser {
          id
          fullName
        }
        createdAt
      }
      count
    }
  }
`;

export const GET_ACTIONS = gql`
  query GetAction($id: String!, $page: Int!, $limit: Int!) {
    getActions(id: $id, payload: { page: $page, limit: $limit }) {
      actionLogs {
        id
        content
        createdAt
        updatedAt
        actionableType
        user {
          id
          fullName
        }
      }
      count
    }
  }
`;

export const GET_SHAREE_USERS = gql`
  query GetShareeUsers($companyId: String!) {
    getUserForSharee(companyId: $companyId) {
      id
      status
      fullName
    }
  }
`;

export const GET_OWNER_SHAREES = gql`
  query GetOwnerAndSharees($id: String!) {
    getShareesAndOwner(id: $id) {
      id
      sharingType
      sharingId
      ownerType
      endedAt
      owner {
        id
        fullName
        photo
      }
      startedAt
    }
  }
`;

export const GET_COMPANY_STATS = gql`
  query GetCompanyStats($id: String!) {
    getCompanyStats(id: $id) {
      id
      totalPlacement
      totalBilling
      totalActiveAgreement
      totalActiveJobOrder
      totalInterviews
      totalSubmitted
      mostRecentSignedAgreementDate
      lastCheckInAt
      createdAt
      company {
        name
        source
        linkedInUrl
      }
      lastCheckInRecruiter {
        fullName
      }
    }
  }
`;

export const GET_COMPANY_OWNER = gql`
  query GetCompanyOwner($id: String!) {
    getCompanyOwner(id: $id) {
      id
      owner {
        id
        fullName
      }
    }
  }
`;

export const GET_ALL_COMPANY_OWNER_SHAREE = gql`
  query GetAllCompanyOwnerSharee($id: String!) {
    getAllCompanyOwnerAndSharee(id: $id) {
      id
      ownerType
      owner {
        id
        fullName
      }
    }
  }
`;

export const GET_ALL_COMPANY_OWNER_SHAREE_WITH_TYPE = gql`
  query GetAllCompanyOwnerSharee($id: String!) {
    getAllCompanyOwnerAndSharee(id: $id) {
      id
      ownerType
      owner {
        id
        fullName
      }
    }
  }
`;

export const GET_ALL_OWNER_SHAREE = gql`
  query GetAllOwnerSharee($id: String!) {
    getAllShareesOwner(id: $id) {
      id
      owner {
        id
        fullName
      }
    }
  }
`;

//Mutation work for the add new user widget
export const CompanyInput = gql`
  input CompanyInput {
    name: String
    size: String
    industry: String
    status: CompanyStatus
    summary: String
    careerPageURL: String
    twitterUrl: String
    fbUrl: String
    instaUrl: String
    linkedInUrl: String
    source: String
    sourceNote: String
    startedAt: Date
    streetAddress: String
    city: String
    state: String
    zipCode: String
    country: String
    province: String
    domains: [DomainInput]
    phoneNumber: String
    faxNumber: String
  }
`;

// update Company
export const updateCompany = gql`
  input UpdateCompanyInput {
    name: String
    size: String
    industry: [SingleIndustry]
    careerPageURL: String
    twitterUrl: String
    fbUrl: String
    instaUrl: String
    linkedInUrl: String
    source: String
    sourceNote: String
    startedAt: Date
    domains: [DomainInput!]
    phoneNumber: String
    faxNumber: String
    companyId: String!
  }
`;

export const CREATE_COMPANY = gql`
  mutation CreateCompany($companyInput: CompanyInput!) {
    createCompany(companyInput: $companyInput) {
      name
      id
    }
  }
`;

export const UPDATE_COMPANY = gql`
  mutation UpdateCompany($UpdateCompanyInput: UpdateCompanyInput!) {
    updateCompany(companyPayload: $UpdateCompanyInput) {
      status
      message
    }
  }
`;

export const ADD_NEW_COMPANY_ADDRESS = gql`
  mutation AddNewCompanyAddress($addressInput: NewAddressInput!) {
    addNewAddress(newAddress: $addressInput) {
      streetAddress
      city
      zipCode
      country
      state
      id
    }
  }
`;

export const DELETE_COMPANY_DOMAIN = gql`
  mutation DeleteDomain($domainId: String!) {
    deleteDomain(domainId: $domainId) {
      message
      status
    }
  }
`;

export const DELETE_COMPANY_ADDRESS = gql`
  mutation DeleteAddress($addressId: String!) {
    deleteAddress(addressId: $addressId) {
      message
      status
    }
  }
`;

export const DELETE_COMPANY_SHAREE = gql`
  mutation DeleteSharee($id: String!) {
    deleteSharee(id: $id) {
      message
      status
    }
  }
`;

export const DELETE_COMPANY_SHAREE_OWNER = gql`
  mutation DeleteShareeOwner($companyId: String!) {
    removedOwner(companyId: $companyId) {
      message
      status
    }
  }
`;

export const UPDATE_ADDRESS = gql`
  mutation UpdateAddress($updateAddressInput: UpdateAddressInput!) {
    updateAddress(updateAddress :$updateAddressInput) {
      message
      status
    }
  }
`;

export const UPDATE_COMPANY_PRIMARY_ADDRESS = gql`
  mutation UpdateCompanyPrimaryAddress($updatePrimaryInput: UpdatePrimaryInput!) {
    updatePrimaryAddress(updatePrimaryInput: $updatePrimaryInput) {
      message
      status
    }
  }
`;

export const UPDATE_COMPANY_PRIMARY_CONTACT = gql`
  mutation UpdateCompanyPrimaryContact($updatePrimaryInput: UpdatePrimaryInput!) {
    updatePrimaryContact(updatePrimaryInput: $updatePrimaryInput) {
      message
      status
    }
  }
`;

export const CREATE_NOTES = gql`
  mutation CreateNotes(
    $content: String!
    $noteableId: String!
    $noteableType: String!
    $category: String!
    $isCompenstaionNote: Boolean
  ) {
    createNote(
      notesInput: {
        content: $content
        noteableId: $noteableId
        noteableType: $noteableType
        category: $category
        isCompenstaionNote: $isCompenstaionNote
      }
    ) {
      id
      content
      createdAt
      noteableType
    }
  }
`;

export const UPDATE_NOTE = gql`
  mutation UpdateNote($notePayload: UpdateNote!) {
    updateNote(notePayload: $notePayload) {
      message
      status
    }
  }
`;

export const CREATE_ACTION = gql`
  mutation CreateAction(
    $content: String!
    $actionableId: String!
    $actionableType: String!
  ) {
    createActionLog(
      actionLogInput: {
        content: $content
        actionableId: $actionableId
        actionableType: $actionableType
      }
    ) {
      message
      status
    }
  }
`;

export const DELETE_NOTE = gql`
  mutation DeleteNote($noteId: String!) {
    deleteNote(notesId: $noteId) {
      message
      status
    }
  }
`;

export const DELETE_EMAIL_NOTE = gql`
  mutation DeleteEmailNote($noteId: String!) {
    deleteEmailNote(notesId: $noteId) {
      message
      status
    }
  }
`;

export const DELETE_ACTION = gql`
  mutation DeleteAction($actionId: String!) {
    deleteAction(actionId: $actionId) {
      message
      status
    }
  }
`;

// this mutatiom is used for only when an admin add a owner from comapany
export const CREATE_OWNER = gql`
  mutation CreateOwner($CreateShareeInput: CreateShareeInput!) {
    createShareeOwner(createShareeInput: $CreateShareeInput) {
      message
      status
    }
  }
`;

export const CHECK_DOMAIN_EXIST = gql`
  mutation CheckDomain($domain: String!) {
    checkDomain(domain: $domain) {
      isNewDomain
    }
  }
`;

export const GET_COMPANY_ADDRESS = gql`
  query GetCompanyAddress($id: String!, $page: Int!, $limit: Int!) {
    getCompanyAddress(id: $id, payload: { page: $page, limit: $limit }) {
      address {
        isPrimary
        isDeleted
        streetAddress
        city
        zipCode
        country
        state
        id
      }
      count
    }
  }
`;

export const FETCH_COMPANIES_OWNER_BASED = gql`
  query FetchCompanyOfOwner(
    $page: Int!
    $limit: Int!
    $searchText: String
    $ownerIdFlag: Boolean
  ) {
    fetchCompaniesOfCurrentUser(
      payload: { page: $page, limit: $limit }
      filters: { searchText: $searchText, ownerIdFlag: $ownerIdFlag }
    ) {
      id
      name
      summary
      careerPageURL
      culture
      hasLinkedIn
      linkedInUrl
      hasCareerPageURL
      createdAt
      address {
        id
        isPrimary
        streetAddress
        city
        state
        zipCode
        country
      }

      companyContacts {
        id
        isPrivate
        firstName
        fullName
        lastName
        jobTitle
        contactEmail {
          id
          isPrimary
          email
        }
      }
    }
  }
`;

export const GET_COMPANY_CONTACTS = gql`
  query GetCompanyContacts($id: String!, $page: Int!, $limit: Int!) {
    getCompanyContacts(id: $id, payload: { page: $page, limit: $limit }) {
      contacts {
        id
        fullName
        jobTitle
        preferredContact
        contactPhone{
          id
          number
          isPrimary
        }
        contactEmail {
          id
          email
          isPrimary
        }
      }
      count
    }
  }
`;

export const GET_COMPANY_DOMAINS = gql`
  query GetCompanyDomains($id: String!, $page: Int!, $limit: Int!) {
    getCompanyDomains(id: $id, payload: { page: $page, limit: $limit }) {
      domains {
        id
        isPrimary
        domainUrl
        domainName
      }
      count
    }
  }
`;

export const GET_COMPANY_AGREEMENTS = gql`
  query GetCompanyAgreements($id: String!, $page: Int!, $limit: Int!) {
    getCompanyAgreements(id: $id, payload: { page: $page, limit: $limit }) {
      agreements {
        id
        documentUri
        nickName
        status
        agreementType
        signedAt
      }
      count
    }
  }
`;

export const GET_CANDIDATE_NOTES = gql`
  query GetCandidateNotes($candidateId: String!) {
    getCandidateNotes(candidateId: $candidateId) {
      category
    }
  }
`;

export const GET_COMPANY_BY_ID = gql`
  query getCompany($companyId: String!) {
    getCompany(companyId: $companyId) {
      id
      name
      summary
      careerPageURL
      culture
      hasLinkedIn
      linkedInUrl
      hasCareerPageURL
      createdAt
      address {
        id
        isPrimary
        streetAddress
        city
        state
        zipCode
        country
      }

      companyContacts {
        id
        isPrivate
        firstName
        fullName
        lastName
        jobTitle
        contactEmail {
          id
          isPrimary
          email
        }
      }
    }
  }
`;

export const GET_COMPANY_CONTACT_NOTES = gql`
  query getCompanyContactsNotes(
    $companyId: String!
    $page: Int!
    $limit: Int!
    $notePayload: NotePayload!
  ) {
    getCompanyContactsNotes(
      companyId: $companyId
      payload: { page: $page, limit: $limit }
      notePayload: $notePayload
    ) {
      notes {
        id
        content
        createdAt
        updatedAt
        noteableType
        category
        modifiedOn
        contactName
        user {
          id
          fullName
        }
      }
      count
    }
  }
`;

export const GET_JOB_ORDERS_BY_COMPANY_ID = gql`
  query FindJobOrdersForCompany(
    $page: Int!
    $limit: Int!
    $companyId: String
  ) {
    findJobOrdersForCompany(
      payload: { page: $page, limit: $limit }
      filters: { companyId: $companyId }
    ) {
      jobOrders {
        id
        jobTitle
        status
        positions
        allowRemote
        estimatedFee
        feeType
        isHotJob
        showSalary
        newApplications
        totalApplications
        workplaceTypes
        category {
          id
          name
        }
        advertisedLocations {
          id
          postedAt
        }
        workType
        rateType
        salaryMin
        salaryMax
        recruitingLevel
        updatedAt
        companyLocation {
          state
          city
          country
        }
        mainContact {
          id
          contact {
            id
            fullName
          }
        }
        company {
          id
          name
          owner {
            id
            fullName
          }
          companyOwner {
            owner {
              id
              fullName
            }
          }
        }
      }
      count
    }
  }
`;