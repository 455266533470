import { FC, useCallback, useContext, useEffect, useState } from "react";
import { Badge, Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import InputField from "../../components/layout/InputField";
import Pagination from "../../components/layout/Pagination";
import TableComponent from "../../components/layout/TableComponent";
import NotesModal from "./NotesModal";
import { useLazyQuery, useMutation } from "@apollo/client";
import { CrossOutlinedIcon, TickOutlinedIcon } from "../../assets/images/svgs";
import Loader from "../../components/layout/Loader";
import NoDataFound from "../../components/layout/NoDataFound";
import { NOTES, REQUESTS, RequestsStatusEnum, RequestsStatusEnumForColor } from "../../constants";
import { RequestCategory, RequestsType } from "../../interfaces";
import { PAGE_LIMIT, requestsCategory, REQUESTS_TABLE_HEADER, requestsStatus, ROLES } from "../../utils/constant";
import { getRequestColor } from "../../utils/helper";
import { APPROVE_REQUEST, GET_ALL_REQUESTS, REJECT_REQUEST, } from "./gql";
import ConfirmPopUp from "../../components/ConfirmPopUp";
import { toast } from "react-toastify";
import { AppContext } from "../../context";
import { Link } from "react-router-dom";
import { formatDateUS } from "../../utils/commonFn";

const Requests: FC = () => {
  // states
  const { userRoles } = useContext(AppContext);
  const [notesModal, setNotesModal] = useState<boolean>(false);
  const [notesText, setNotesText] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(10);
  // dropdown states
  const [categoryDropDown, setCategoryDropdownOpen] = useState(false);
  const defaultCategory: RequestCategory = { label: 'Placement Cancellation', value: 'PLACEMENT_CANCELLATION' };
  const [selectedCategory, setSelectedCategory] = useState<RequestCategory | null>(defaultCategory);
  const [requestTypeFilter, setRequestTypeFilter] = useState<string>('');
  const [statusDropdown, setStatusDropdown] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState<RequestCategory | null>(null);
  const [requestStatusFilter, setRequestStatusFilter] = useState<string>('');
  // modal states
  const [approveModal, setApproveModal] = useState(false);
  const [rejectModal, setRejectModal] = useState(false);
  const [requestObj, setRequestObj] = useState<RequestsType>();

  // queries and mutations
  const [getAllRequests, { data: listData, loading: listLoading }] =
    useLazyQuery(GET_ALL_REQUESTS);
  const [approveRequest, { loading: approveLoading }] = useMutation(APPROVE_REQUEST);
  const [rejectRequest, { loading: rejectLoading }] = useMutation(REJECT_REQUEST);

  // api methods
  const fetchRequests = useCallback(async () => {
    await getAllRequests({
      variables: {
        payload: {
          page: currentPage,
          limit,
          filter: {
            requestType: requestTypeFilter,
            requestStatus: requestStatusFilter
          }
        },
      },
    });
  }, [currentPage, getAllRequests, limit, requestStatusFilter, requestTypeFilter]);

  const refetchData = async () => {
    await getAllRequests({
      variables: {
        payload: {
          page: currentPage,
          limit,
          filter: {
            requestType: requestTypeFilter,
            requestStatus: requestStatusFilter
          }
        }
      }
    });
  };

  // state methods 
  useEffect(() => {
    fetchRequests();
  }, [fetchRequests]);

  // component methods
  const toggleDropdownCategory = () => {
    setCategoryDropdownOpen(!categoryDropDown);
  };

  const handleCategoryChange = (value) => {
    setSelectedCategory(value);
    setRequestTypeFilter(value?.label);
    setCurrentPage(1);
    setLimit(10);
  };

  const toggleDropdownStatus = () => {
    setStatusDropdown(!statusDropdown);
  };

  const handleStatusChange = (value) => {
    setSelectedStatus(value);
    setRequestStatusFilter(value?.label);
    setCurrentPage(1);
    setLimit(10);
  };

  const setRequestData = (item: RequestsType) => {
    setRequestObj({
      id: item?.id,
      requestType: item?.requestType,
      note: item?.note,
      link: item?.link,
      status: item?.status,
      requestCreatedBy: item?.requestCreatedBy,
      requestApprovedBy: item?.requestApprovedBy,
      actionableId: item?.actionableId,
      actionableType: item?.actionableType,
      updatedAt: item?.updatedAt,
    });
  };

  const requestApproveHandler = async () => {
    const res = await approveRequest({
      variables: {
        approveRequestInput: {
          id: requestObj?.id,
          actionableType: requestObj?.actionableType,
          actionableId: requestObj?.actionableId,
        }
      }
    });

    if (res?.data) {
      toast.success('Request approved successfully.');
      setApproveModal(false);
      refetchData();
      window.location.reload()
    } else {
      toast.error('Unable to approve request.');
      setApproveModal(false);
    }
  }

  const requestRejectHandler = async () => {
    const res = await rejectRequest({
      variables: {
        rejectRequestInput: {
          id: requestObj?.id,
          actionableType: requestObj?.actionableType,
          actionableId: requestObj?.actionableId,
        }
      }
    });

    if (res?.data) {
      toast.success('Request rejected.');
      setRejectModal(false);
      refetchData();
      window.location.reload()
    } else {
      toast.error('Unable to reject request.');
      setRejectModal(false);
    }
  }

  return (
    <>
      <div className="mobileResponsiveFlexAlign primaryHeaderSpacing primaryHeadng">
        <h5 className="m-0">{REQUESTS}</h5>
      </div>

      <div className="d-flex align-items-center flex-wrap mb-2">
        <Dropdown isOpen={categoryDropDown} toggle={toggleDropdownCategory} className="actionsDropdown dropdown-width-class">
          <DropdownToggle caret>
            {selectedCategory ? selectedCategory.label : 'Category'}
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem
              onClick={() => handleCategoryChange(null)} // For retrieving all items
              className="cursor-pointer m-0"
            >
              Category
            </DropdownItem>
            {requestsCategory.map(category => (
              <DropdownItem
                key={category.value}
                onClick={() => handleCategoryChange(category)}
                className="cursor-pointer m-0"
              >
                {category.label}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </Dropdown>

        <div className="mx-3" />
        <Dropdown isOpen={statusDropdown} toggle={toggleDropdownStatus} className="actionsDropdown dropdown-width-class">
          <DropdownToggle caret>
            {selectedStatus ? selectedStatus.label : 'Status'}
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem
              onClick={() => handleStatusChange(null)} // For retrieving all items
              className="cursor-pointer m-0"
            >
              Status
            </DropdownItem>
            {requestsStatus.map(status => (
              <DropdownItem
                key={status.value}
                onClick={() => handleStatusChange(status)}
                className="cursor-pointer m-0"
              >
                {status.label}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </Dropdown>
      </div>

      <TableComponent tableHeader={REQUESTS_TABLE_HEADER}>
        {listLoading ? (
          <tbody>
            <tr>
              <td colSpan={14}>
                <Loader loaderClass="sm-height" />
              </td>
            </tr>
          </tbody>) : (
          <tbody className="request-table">
            {listData?.getAllRequests?.requests?.length ? (
              listData?.getAllRequests?.requests?.map((item: RequestsType, index: number) => {
                const { requestType, note, link, status, requestCreatedBy, requestApprovedBy, updatedAt } = item;

                return (
                  <tr key={`${index} indexOf ${item}`}>
                    {/* user */}
                    <td>
                      <span className="d-inline-block text-truncate">
                        {requestCreatedBy?.fullName}
                      </span>
                    </td>

                    {/* request type */}
                    <td>
                      <div className="d-inline-block text-truncate">
                        {requestType}
                      </div>
                    </td>

                    {/* note */}
                    <td
                      className="route-link text-truncate max-200"
                      onClick={() => {
                        setNotesModal(true);
                        setNotesText(note);
                      }}
                    >
                      {note}
                    </td>

                    {/* link */}
                    <td
                      className="text-blue-link"
                    >
                      <Link to={link} target="_blank">
                        Request Link
                      </Link>
                    </td>


                    {/* status */}
                    <td className="badge-padding">
                      <Badge
                        color={getRequestColor(RequestsStatusEnumForColor[status])}
                        className="text-capitalize request-badge"
                      >
                        {status}
                      </Badge>
                    </td>

                    {/* request approved or rejected by user's name */}
                    <td>
                      <span className="d-inline-block text-truncate">
                        {status === RequestsStatusEnum.PENDING ? '--' : requestApprovedBy?.fullName}
                      </span>
                    </td>

                    {/* last updated at */}
                    <td>
                      <span className="d-inline-block text-truncate">
                        {formatDateUS(updatedAt)}
                      </span>
                    </td>

                    <td>
                      <div className="d-flex align-items-start">
                        <Button
                          onClick={() => {
                            setRequestData(item);
                            setApproveModal(true);
                          }}
                          className="iconButtonAction bg-transparent border-0 p-0 pb-1"
                          disabled={
                            (status !== RequestsStatusEnum.PENDING) ||
                            !userRoles?.includes(ROLES.EXECUTIVE)
                          }
                        >
                          <TickOutlinedIcon />
                        </Button>

                        <Button
                          onClick={() => {
                            setRequestData(item);
                            setRejectModal(true);
                          }}
                          className="iconButtonAction bg-transparent border-0 p-0 pb-1"
                          disabled={
                            (status !== RequestsStatusEnum.PENDING) ||
                            !userRoles?.includes(ROLES.EXECUTIVE)
                          }
                        >
                          <CrossOutlinedIcon />
                        </Button>
                      </div>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td
                  colSpan={REQUESTS_TABLE_HEADER.length}
                  className="empty-table-td"
                >
                  <NoDataFound text="No Requests Found!" />
                </td>
              </tr>
            )}
          </tbody>
        )}
      </TableComponent>

      {listData?.getAllRequests?.requests?.length ? (
        <div className="users-pagination">
          <Pagination
            onPageChange={(pageClicked: number) => {
              setCurrentPage(pageClicked);
            }}
            pageCount={Math.ceil(listData?.getAllRequests?.count / limit)}
            currentPage={currentPage}
          />

          <div className="d-flex align-items-center">
            <div className="pagination-display paginationItemsSelect">
              <p className="m-0">
                {(currentPage - 1) * limit + 1} to{" "}
                {Math.min(
                  currentPage * limit,
                  listData?.getAllRequests?.count
                )}{" "}
                of {listData?.getAllRequests?.count}
              </p>

              <div className="divider"></div>

              <label>Display</label>

              <InputField
                label=""
                inputtype="select"
                selectItems={PAGE_LIMIT.filter((item) => item.value !== limit)}
                inputid="limit"
                placeholder={limit.toString()}
                inputMethod={(value) => {
                  setCurrentPage(1);
                  setLimit(parseInt(value));
                }}
              />
            </div>
          </div>
        </div>
      ) : null}

      <ConfirmPopUp
        confirmText="Are you sure you want to approve this request?"
        isOpen={approveModal}
        toggle={setApproveModal}
        confirmAction={requestApproveHandler}
        modalHeading="Approve Request"
        btnText="Approve"
        btnColor="primary"
        loading={approveLoading}
        disabled={approveLoading}
        className="revampDialog revampDialogWidth"
      />

      <ConfirmPopUp
        confirmText="Are you sure you want to reject this request?"
        isOpen={rejectModal}
        toggle={setRejectModal}
        confirmAction={requestRejectHandler}
        modalHeading="Reject Request"
        btnText="Reject"
        btnColor="primary"
        loading={rejectLoading}
        disabled={rejectLoading}
        className="revampDialog revampDialogWidth"
      />

      <NotesModal
        isOpen={notesModal}
        toggle={() => setNotesModal(!notesModal)}
        notesText={notesText}
        modalHeading={NOTES}
      />
    </>
  )
}

export default Requests;
