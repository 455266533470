import Select from "react-select";
import deleteIcon from "../../../../assets/images/delete-field.svg";
import {
  Button,
  Col,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  Row,
  Table,
} from "reactstrap";
import { useState, useEffect, useContext } from "react";
import { IAccountManagerPercentage } from "../create/IndexUpdatePlacement";
import EDIT_ICON from "../../../../assets/images/edit-icon-pencil.svg";
import { ZERO_TO_HUNDRED } from "../../../../utils/constant";
import { AppContext } from "../../../../context";
import { customSelectDropDown } from "../../../../components/styled/customSelect";
import { moneyFormatter } from "../../../../utils/helper";

export default function AccountManagerSplit({
  accountManagers,
  errors,
  defaultPercentage,
  editPermitForAM,
  accountManagerListChange,
  accountManagerList,
  defaultOwner,
  netCommission,
}) {
  const { theme } = useContext(AppContext);

  const [accountManager, setAccountManager] = useState<any>();
  const [comingAccountManagers, setComingAccountManagers] =
    useState<any>(accountManagers);
  const [edit, setEdit] = useState<boolean>(false);
  const [percentageError, setPercentageError] = useState<any>();
  const [, setCurrentAccountManagerSplit] =
    useState<any>();
  const [accountManagerPercentage, setAccountManagerPercentage] =
    useState<any>(defaultPercentage);

  const onPercentageChange = (percentage: string) => {
    setAccountManagerPercentage(parseFloat(percentage));
    if (percentage) {
      const mailRegex = new RegExp(ZERO_TO_HUNDRED);
      const test = mailRegex.test(percentage);
      if (test) {
        setPercentageError("");
      } else {
        setPercentageError("Percentage is not valid!");
      }
    }
  };

  const onDeleteItem = (item: IAccountManagerPercentage) => {
    let objectToAdd: any = {};

    objectToAdd.id = item?.accountManager?.value;
    objectToAdd.fullName = item?.accountManager?.label;

    let cloneArr = [...comingAccountManagers, { ...objectToAdd }];

    setComingAccountManagers(cloneArr);
    setEdit(false);
    accountManagerListChange(item);
    setAccountManager("");
    setAccountManagerPercentage("");
  };

  const onEditItem = (item: IAccountManagerPercentage) => {
    setAccountManager({
      label: item?.accountManager?.label,
      value: item?.accountManager?.value,
    });
    onPercentageChange(item?.percentage?.toString());
  };

  useEffect(() => {
    if (!accountManager) {
      setComingAccountManagers(
        comingAccountManagers?.filter((item) => item.id !== defaultOwner?.value)
      );
      setAccountManager(defaultOwner);
    }

    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (accountManagerList?.length) {
      let managerSplit: any = [];
      for (const manger of accountManagerList) {
        managerSplit.push({
          user: {
            fullName: manger?.accountManager?.label,
            id: manger?.accountManager?.value,
          },
          splitPercentage: manger?.percentage,
        });
      }
      setCurrentAccountManagerSplit(managerSplit);
    }

    // eslint-disable-next-line
  }, [accountManagerList]);

  const editCurrentSplit = () => {
    const index = accountManagerList?.findIndex(
      (element: { accountManager: { value: string } }) =>
        element?.accountManager?.value === accountManager?.value
    );

    accountManagerList?.splice(index, 1);

    accountManagerListChange({
      accountManager: accountManager,
      percentage: accountManagerPercentage,
    });

    setEdit(false);
  };

  return (
    <form>
      <div className="noteSection mt-0 light-dark-color">
        <h5>Account Manager Splits</h5>
      </div>

      <Row className="account-manager-splits">
        <Col md="5">
          <Label>
            Account Manager Name <span className="text-danger">*</span>
          </Label>
          <Select
            isDisabled={editPermitForAM}
            value={accountManager}
            name="accountManagerName"
            options={comingAccountManagers?.map(
              (item: { fullName: string; id: string }) => {
                return {
                  label: item?.fullName,
                  value: item?.id,
                };
              }
            )}
            onChange={setAccountManager}
            styles={{ ...customSelectDropDown(theme) }}
          />
        </Col>

        <Col md="5">
          <Label>
            Account Manager Percentage
            <span className="text-danger">*</span>
          </Label>

          <InputGroup className="m-0">
            <InputGroupAddon addonType="prepend">%</InputGroupAddon>
            <Input
              readOnly={editPermitForAM}
              placeholder="Account Manager Percentage"
              name="accountManagerPercentage"
              type="number"
              value={`${accountManagerPercentage}`}
              onChange={(e) => onPercentageChange(e.target.value)}
            />
          </InputGroup>

          <small className="text-danger mb-3 d-block">
            {errors?.accountManagerPercentage?.message || percentageError}
          </small>
        </Col>

        <Col md="2" className="pt-4">
          {edit ? (
            <button
              disabled={
                !(accountManager && accountManagerPercentage) || editPermitForAM
              }
              onClick={() => {
                setAccountManager("");
                setAccountManagerPercentage("");
                editCurrentSplit();
              }}
              className="darkButton darkButtonSpace"
              type="button"
            >
              Edit
            </button>
          ) : (
            <button
              disabled={
                editPermitForAM ||
                !(accountManager && accountManagerPercentage) ||
                accountManagerList?.find(
                  (item: IAccountManagerPercentage) =>
                    item?.accountManager?.value === accountManager?.value
                )
                  ? true
                  : false
              }
              onClick={() => {
                !accountManagerList?.find(
                  (item: IAccountManagerPercentage) =>
                    item?.accountManager?.value === accountManager?.value
                ) &&
                  accountManagerListChange({
                    accountManager: accountManager,
                    percentage: accountManagerPercentage,
                  });

                setAccountManager("");
                setComingAccountManagers(
                  comingAccountManagers?.filter(
                    (item: { id: string }) => item.id !== accountManager?.value
                  )
                );
                setAccountManagerPercentage("");
              }}
              type="button"
              className="darkButton darkButtonSpace"
            >
              Add
            </button>
          )}
        </Col>
      </Row>

      <div className="mt-3">
        {!!accountManagerList?.length && (
          <Row className="account-manager-splitTable pt-md-0 pt-3">
            <Col md="12">
              <div className="table-responsive dark-table">
                <Table
                  className="transparentTable border-1"
                  style={{ borderCollapse: "separate" }}
                >
                  <thead>
                    <tr>
                      <th style={{ width: "40%" }}>Name</th>
                      <th style={{ width: "20%" }}>Credit %</th>
                      <th style={{ width: "20%" }}>Billing Credit</th>
                      <th style={{ width: "40%" }} align="right"></th>
                    </tr>
                  </thead>
                  {accountManagerList?.map(
                    (item: IAccountManagerPercentage, index: number) => {
                      return (
                        <tbody>
                          <tr>
                            <td>{item?.accountManager?.label}</td>
                            <td className="colorBlue">{item?.percentage}%</td>
                            <td className="colorBlue">
                              {moneyFormatter(
                                (netCommission * 0.5 * item?.percentage) /
                                  100 || 0
                              )}
                            </td>
                            <td align="right">
                              <Button
                                disabled={editPermitForAM}
                                onClick={() => {
                                  setEdit(true);
                                  onEditItem(item);
                                }}
                                className="my-auto bg-transparent border-0 w-auto"
                              >
                                <img
                                  src={EDIT_ICON}
                                  alt="Edit"
                                  className="m-0"
                                  width="16"
                                />
                              </Button>
                              <Button
                                disabled={editPermitForAM}
                                onClick={() => onDeleteItem(item)}
                                className="my-auto bg-transparent border-0 w-auto img-btn"
                              >
                                <img
                                  src={deleteIcon}
                                  alt=""
                                  width="20"
                                  height="20"
                                />
                              </Button>
                            </td>
                          </tr>
                        </tbody>
                      );
                    }
                  )}
                </Table>
              </div>
            </Col>
          </Row>
        )}
      </div>
    </form>
  );
}
