import { gql } from "@apollo/client";

export const FETCH_CONTACTS = gql`
  query FetchAllContacts(
    $page: Int!
    $limit: Int!
    $searchText: String
    $statusSearch: String
    $companyId: String
  ) {
    allContacts(
      payload: { page: $page, limit: $limit }
      filters: {
        searchText: $searchText
        statusSearch: $statusSearch
        companyId: $companyId
      }
      columnFilters:{
        fullNameSearch:$searchText
      }
    ) {
      contacts {
        id
        fullName
        createdAt
      }
      count
    }
  }
`;