import { useLazyQuery, useMutation } from '@apollo/client';
import { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import {
  Col,
  Collapse,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
} from 'reactstrap';
import CustomButton from '../../../../components/layout/CustomButton';
import { customSelectStyleNew } from '../../../../components/styled/customSelect';
import { AppContext } from '../../../../context';
import {
  COMPANY_DETAILS_ROUTE,
  CONTACT_DETAIL_ROUTE,
  PLACEMENT_CANCELLED,
  PLACEMENT_ENDED,
  VIEW_ACCOUNTING_DASHBOARD_ROUTE,
  VIEW_JOB_ORDER_ROUTE,
  VIEW_PLACEMENT_MANAGEMENT_ROUTE,
  workplaceTypes,
  WorkType,
} from '../../../../utils/constant';
import { pushInHistory } from '../../../../utils/helper';
import { IComingState } from '../../../agreements/interfaces';
import {
  GET_JOB_ORDER_DETAIL_LIGHT,
  UPDATE_JOB_ORDER_OF_PLACEMENT,
} from '../gql';
import CreateContact from './CreateContact';

const JobOrderWidgetDetail = ({
  fromWhichPlacement,
  placement,
  candidateId,
  jobApplicantId,
  id,
  pipeCompanyIdToParent,
  placementAccountsStatus,
  from,
  widgetPermissions,
  watch: watcher,
  accountManager,
  placementStatus,
  refetchPlacement,
  ...props
}: any) => {
  const { theme } = useContext(AppContext);
  const history = useHistory();
  const location = useLocation<IComingState>();
  const [titleMsg, setTitleMsg] = useState<any>({
    msg: 'You can enter up to 55 characters',
    charLength: 0,
  });
  const [companyId, setCompanyId] = useState(null);
  const [addressList, setAddressList] = useState<Array<any>>([]);
  const [contactsLists, setContactList] = useState<Array<any>>([]);
  const [customEmailInputs, setCustomEmailInputs] = useState<any>([
    {
      email: '',
      isPrimary: true,
    },
  ]);
  const [modal, setModal] = useState(false);
  const [newContactFieldsModal, setNewContactFieldsModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [getJobOrder, { data }] = useLazyQuery(GET_JOB_ORDER_DETAIL_LIGHT);
  const [updateJobOrder, { loading }] = useMutation(
    UPDATE_JOB_ORDER_OF_PLACEMENT
  );
  const backURL = location.pathname.includes(VIEW_ACCOUNTING_DASHBOARD_ROUTE)
    ? `${VIEW_ACCOUNTING_DASHBOARD_ROUTE}/${placement?.id}`
    : `${VIEW_PLACEMENT_MANAGEMENT_ROUTE}/${placement?.id}`;

  const {
    register,
    handleSubmit,
    errors,
    setValue,
    getValues,
    control,
    watch,
    reset,
  } = useForm();
  const { jobCCContactIds } = watch();

  useEffect(() => {
    if (id) {
      getJobOrder({ variables: { id } });
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (data?.getJobOrder?.company?.id) {
      if (from === 'create') {
        pipeCompanyIdToParent(data?.getJobOrder?.company?.id);
      }
    }
    // eslint-disable-next-line
  }, [data?.getJobOrder?.company?.id]);
  const onCancel = () => {
    setNewContactFieldsModal(false);
    toggle();
    setCustomEmailInputs([
      {
        email: '',
        isPrimary: true,
      },
    ]);
  };
  const updatePlacementJobOrder = async (values) => {
    values.jobMainContactId = values?.jobMainContactId?.value;
    values.jobBillingContactId = values?.jobBillingContactId?.value;
    values.jobReportToContactId = values?.jobReportToContactId?.value;
    values.jobAllowRemote = values?.jobAllowRemote === 'true';
    values.jobWorkType = values?.jobWorkType?.value;
    values.jobworkplaceTypes = values?.workplaceTypes?.value;
    values.jobCCContactIds = values?.jobCCContactIds?.map((obj) => obj.value);
    const {
      jobCity,
      jobCountryCode,
      jobStateCode,
      jobPostalCode,
      jobCompanyLocationId,
      jobStreet,
    } = values?.jobLocation?.value;

    values.jobCity = jobCity;
    values.jobCountryCode = jobCountryCode;
    values.jobStateCode = jobStateCode;
    values.jobPostalCode = jobPostalCode;
    values.jobCompanyLocationId = jobCompanyLocationId;
    values.jobStreet = jobStreet;
    delete values.jobLocation;
    delete values.workplaceTypes;
    let res = await updateJobOrder({
      variables: {
        jobOrderInput: {
          ...values,
          placementId: placement?.id,
          jobOrderId: id,
        },
      },
    });
    if (res?.data?.updatePlacementJobOrderInfo?.status === 200) {
      toast.success('Successfully Update!');
      refetchPlacement();
      toggle();
    }
  };

  // this method is used for finding all the contacts of job order still exist again the company or not
  useEffect(() => {
    if (data?.getJobOrder) {
      createContactAndAddressOptions(data?.getJobOrder?.company);

      let mainContact = placement?.placementMainContact?.companyContact?.id;
      let billingContact =
        placement?.placementBillingContact?.companyContact?.id;
      let remoteContact =
        placement?.placementReportToContact?.companyContact?.id;
      let ccContacts = placement?.placementCcInvoiceTo?.map(
        (companyContact) => companyContact?.companyContact?.id
      );
      let allContacts = [
        mainContact,
        remoteContact,
        billingContact,
        ...(ccContacts ?? []),
      ];
      let companyContacts = data?.getJobOrder?.company?.companyContacts?.map(
        (item) => item.id
      );

      let existingContacts: any = [];
      for (let contact of allContacts) {
        if (!companyContacts.includes(contact)) {
          existingContacts.push(contact);
        }
      }
      if (existingContacts.length && props?.contactChangeHandler) {
        props?.contactChangeHandler(existingContacts[0]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.getJobOrder]);

  const onclickModal = () => {
    setNewContactFieldsModal(!newContactFieldsModal);
    if (customEmailInputs?.length === 0) {
      setCustomEmailInputs([
        {
          email: '',
          isPrimary: true,
        },
      ]);
    }
  };
  // this function is used for making contacts options and address option on the behalf of the job order company
  const createContactAndAddressOptions = (companySelected) => {
    setCompanyId(companySelected?.id);

    if (companySelected && companySelected?.address?.length) {
      let addressList = companySelected?.address?.map((v: any) => ({
        label: ['US', null, undefined, ''].includes(v?.country)
          ? `${v?.streetAddress}, ${v?.city}, ${v?.state}, ${v?.zipCode}, ${v?.country}`
          : `${v?.streetAddress}, ${v?.city}, ${v?.zipCode}, ${v?.country}`,
        value: {
          jobCity: v?.city,
          jobStateCode: v?.state,
          jobStreet: v?.streetAddress,
          jobCountryCode: v?.country,
          jobPostalCode: v?.zipCode,
          jobCompanyLocationId: v.id,
        },
      }));

      addressList.sort(function (a, b) {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      });
      setAddressList(addressList);
    } else {
      // If we select a company with an address and after that select another company with no address then the other company can use the address of the previously selected company
      setAddressList([]);
    }
    if (companySelected && companySelected?.companyContacts?.length) {
      let contactsList = companySelected?.companyContacts?.map((v: any) => ({
        label: v.fullName,
        value: v.id,
      }));
      contactsList.sort((a, b) => {
        const labelA = a.label.toUpperCase(); // ignore upper and lowercase
        const labelB = b.label.toUpperCase(); // ignore upper and lowercase
        if (labelA < labelB) {
          return -1;
        }
        if (labelA > labelB) {
          return 1;
        }
        // labels must be equal
        return 0;
      });

      setContactList(contactsList);
    } else {
      // If we select a company with some contact and after that select another company with no contact then the other company can use the contacts of the previously selected company
      setContactList([]);
    }
  };

  // in this useEffect we are prefill the job order data which is saved in the placement
  useEffect(() => {
    if (modal) {

      if (!!placement && !!data?.getJobOrder) {
        let mainContact = placement?.placementMainContact?.companyContact;
        let billingContact = placement?.placementBillingContact?.companyContact;
        let remoteContact = placement?.placementReportToContact?.companyContact;
        let ccContacts = placement?.placementCcInvoiceTo?.map(
          (companyContact) => companyContact?.companyContact
        );
        let ccContactObj = ccContacts.length
          ? ccContacts?.map((ccContacts) => {
            return {
              label: ccContacts?.fullName,
              value: ccContacts?.id,
            };
          })
          : null;
        let jobAllowRemote = `${placement?.jobAllowRemote}`;
        let workplaceType = workplaceTypes?.find(
          (item) => item?.label === placement?.jobworkplaceTypes
        );
        let jobLocation = {
          label: ['US', null, undefined, ''].includes(placement?.jobCountryCode)
            ? `${placement?.jobStreet}, ${placement?.jobCity}, ${placement?.jobStateCode}, ${placement?.jobPostalCode}, ${placement?.jobCountryCode}`
            : `${placement?.jobStreet}, ${placement?.jobCity}, ${placement?.jobPostalCode}, ${placement?.jobCountryCode}`,
          value: {
            jobCity: placement?.jobCity,
            jobStateCode: placement?.jobStateCode,
            jobStreet: placement?.jobStreet,
            jobCountryCode: placement?.jobCountryCode,
            jobPostalCode: placement?.jobPostalCode,
            jobCompanyLocationId: placement?.jobCompanyLocationId,
          },
        };
        let myJobWorkType
        if (placement?.jobWorkType) {
          myJobWorkType = WorkType?.find(
            (item) => {
              return (item?.label).toLowerCase() ===
                (placement?.jobWorkType).toLowerCase()
            }
          );
        }
        reset({
          ...placement,
          jobAllowRemote,
          jobLocation,
          jobMainContactId: mainContact
            ? { label: mainContact?.fullName, value: mainContact?.id }
            : null,
          jobBillingContactId: billingContact
            ? {
              label: billingContact?.fullName,
              value: billingContact?.id,
            }
            : null,
          jobReportToContactId: remoteContact
            ? {
              label: remoteContact?.fullName,
              value: remoteContact?.id,
            }
            : null,
          jobCCContactIds: ccContactObj ?? null,
          jobWorkType: myJobWorkType ?? null,
          workplaceTypes: workplaceType ?? null,
        });
      }

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [placement, data?.getJobOrder, modal]);

  const customStyles = {
    control: (styles, provided) => ({
      ...styles,

      fontSize: 12,
    }),

    option: (provided, state) => ({
      ...provided,
      fontSize: 12,
    }),

    container: (provided, state) => ({
      ...provided,
      fontSize: 12,
    }),
  };

  useEffect(() => {
    if (!jobCCContactIds || jobCCContactIds.length === 0) {
      setValue('jobCCContactIds', []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div className="d-flex align-items-center justify-content-between mb-2">
        <h5 className="m-0">Job Info</h5>
        {placementAccountsStatus && placementStatus !== PLACEMENT_ENDED && placementStatus !== PLACEMENT_CANCELLED ? (
          <button
            type="button"
            onClick={toggle}
            disabled={widgetPermissions}
            className="buttonGenericStyle outlinedButtonDark"
          >
            Edit
          </button>
        ) : null}
      </div>

      <div>
        <div className="d-flex justify-content-between mb-2 contentGridLabel">
          <label>Company Name</label>

          {!!placement?.companyName ? (
            <h6
              className="anchorColorText route-link"
              onClick={() => {
                history.push({
                  pathname: `${COMPANY_DETAILS_ROUTE}/${placement?.jobOrderOfPlacement?.company?.id}`,
                  state: {
                    backUrl: backURL,
                    searchParameters: location.search,
                  },
                });

                pushInHistory(backURL);
              }}
            >
              {placement?.companyName}
            </h6>
          ) : (
            <h6>--</h6>
          )}
        </div>

        <div className="d-flex justify-content-between mb-2 contentGridLabel">
          <label>Job Title</label>
          {!!placement?.jobTitle ? (
            <h6
              className="anchorColorText route-link"
              onClick={() => {
                history.push({
                  pathname: `${VIEW_JOB_ORDER_ROUTE}/${placement?.jobOrderOfPlacement?.id}`,
                  state: {
                    backUrl: backURL,
                    searchParameters: location.search,
                  },
                });

                pushInHistory(backURL);
              }}
            >
              {placement?.jobTitle}
            </h6>
          ) : (
            '--'
          )}
        </div>
        <div className="d-flex justify-content-between mb-2 contentGridLabel">
          <label>Job Location</label>
          <h6>
            {['US', null, undefined, ''].includes(placement?.jobCountryCode)
              ? `${placement?.jobStreet || '--'}, ${placement?.jobCity || '--'
              }, ${placement?.jobStateCode || '--'}, ${placement?.jobPostalCode || '--'
              }`
              : `${placement?.jobStreet || '--'}, ${placement?.jobCity || '--'
              }, ${placement?.jobPostalCode || '--'}, ${placement?.jobCountryCode || '--'
              }`}
          </h6>
        </div>

        <div className="d-flex justify-content-between mb-2 contentGridLabel">
          <label>Workplace Type</label>
          <h6>{placement?.jobworkplaceTypes}</h6>
        </div>

        <div className="d-flex justify-content-between mb-2 contentGridLabel">
          <label>Main Contact</label>

          {!!placement?.placementContacts?.length ? (
            <h6
              className="route-link"
              onClick={() => {
                history.push({
                  pathname: `${CONTACT_DETAIL_ROUTE}/${placement?.placementMainContact?.companyContact?.id}`,
                  state: {
                    backUrl: backURL,
                    searchParameters: location.search,
                  },
                });

                pushInHistory(backURL);
              }}
            >
              {placement?.placementContacts.find(({ contactType }) => contactType === "MAIN")?.companyContact?.fullName}
            </h6>
          ) : (
            <h6>--</h6>
          )}
        </div>

        <div className="d-flex justify-content-between mb-2 contentGridLabel">
          <label>Billing Contact</label>

          {!!placement?.placementContacts?.length ? (
            <h6
              className="route-link"
              onClick={() => {
                history.push({
                  pathname: `${CONTACT_DETAIL_ROUTE}/${placement?.placementBillingContact?.companyContact?.id}`,
                  state: {
                    backUrl: backURL,
                    searchParameters: location.search,
                  },
                });

                pushInHistory(backURL);
              }}
            >
              {placement?.placementContacts.find(({ contactType }) => contactType === "BILLING")?.companyContact?.fullName ||
                '--'}
            </h6>
          ) : (
            <h6>--</h6>
          )}
        </div>

        <div className="d-flex justify-content-between mb-2 contentGridLabel">
          <label>Report To Contact</label>

          {!!placement?.placementContacts?.length ? (
            <h6
              className="route-link"
              onClick={() => {
                history.push({
                  pathname: `${CONTACT_DETAIL_ROUTE}/${placement?.placementReportToContact?.companyContact?.id}`,
                  state: {
                    backUrl: backURL,
                    searchParameters: location.search,
                  },
                });
                pushInHistory(backURL);
              }}
            >
              {placement?.placementContacts.find(({ contactType }) => contactType === "REPORT_TO")?.companyContact?.fullName ||
                '--'}
            </h6>
          ) : (
            <h6>--</h6>
          )}
        </div>

        <div className="d-flex justify-content-between mb-2 contentGridLabel">
          <label>CC Invoice To</label>
          {placement?.placementCcInvoiceTo.length ? (
            <div className="contact-list-ul">
              {placement.placementCcInvoiceTo.map((contact, index) => {
                const isLastContact =
                  index === placement.placementCcInvoiceTo.length - 1;
                return (
                  <p
                    key={contact.companyContact?.id}
                    className="route-link contact-list-li"
                    onClick={() => {
                      history.push({
                        pathname: `${CONTACT_DETAIL_ROUTE}/${contact?.companyContact?.id}`,
                        state: {
                          backUrl: backURL,
                          searchParameters: location.search,
                        },
                      });
                      pushInHistory(backURL);
                    }}
                  >
                    {contact.companyContact?.fullName || '--'}
                    {isLastContact ? '' : ', '}
                  </p>
                );
              })}
            </div>
          ) : (
            <h6>--</h6>
          )}
        </div>

        <div className="d-flex justify-content-between mb-2 contentGridLabel">
          <label>Work Type</label>
          <h6>{placement?.jobWorkType || '--'}</h6>
        </div>

        <div className="d-flex justify-content-between mb-2 contentGridLabel">
          <label>Company Owner</label>
          <h6>{accountManager || '--'}</h6>
        </div>
      </div>

      <Modal
        isOpen={modal}
        toggle={toggle}
        className="revampDialog revampDialogWidth"
      >
        <div className="d-flex justify-content-between px-4 py-3">
          <h5>Update Placement Job</h5>
          <div>
            <div className="d-flex align-items-center">
              <h6 className="mr-2 my-auto switchLabel1">Update For Job</h6>
              <label className="switch m-0">
                <Input
                  type="checkbox"
                  name="updateForJobOrder"
                  innerRef={register()}
                />
                <span className="slider"></span>
              </label>
            </div>
          </div>
        </div>

        <Form>
          <ModalBody>
            <Row>
              <Col>
                <Label>
                  Job Title <span className="text-danger">*</span>
                </Label>
                <Input
                  inputtype="text"
                  placeholder="job Title"
                  innerRef={register({
                    required: {
                      value: true,
                      message: 'Job title is required!',
                    },
                  })}
                  name="jobTitle"
                  onChange={({ target: { value } }) => {
                    if (value?.length === 0) {
                      setTitleMsg({
                        msg: 'You can enter up to 55 characters',
                        charLength: value?.length,
                      });
                    }
                    if (value?.length > 0) {
                      setTitleMsg({
                        msg: `Current length: ${value?.length}`,
                        charLength: value?.length,
                      });
                    }
                  }}
                />
                <div className="titile-count-message mt-1 d-flex flex-column">
                  <small className="text-danger">
                    {errors?.jobTitle?.message}
                  </small>

                  <small
                    className={`${titleMsg?.charLength > 55
                      ? 'text-danger'
                      : 'titile-count-message-color'
                      } `}
                  >
                    {titleMsg?.msg}
                  </small>
                </div>
              </Col>

              <Col>
                <FormGroup>
                  <Label>
                    Workplace Type
                    {watch('status')?.value === 'ACTIVE' && (
                      <span className="text-danger">*</span>
                    )}
                  </Label>
                  <Controller
                    name="workplaceTypes"
                    rules={{
                      required: {
                        value:
                          watch('status')?.value === 'ACTIVE' ? true : false,
                        message: 'Must Select Workplace Types!',
                      },
                    }}
                    placeHolder="Select Workplace Type"
                    render={() => (
                      <Select
                        options={workplaceTypes}
                        value={watch()?.workplaceTypes}
                        onChange={(object) => {
                          setValue('workplaceTypes', object);
                        }}
                        styles={{ ...customSelectStyleNew(theme) }}
                      />
                    )}
                    control={control}
                    styles={customStyles}
                  />

                  <small className="text-danger">
                    {errors?.workType?.message}
                  </small>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label>
                    Main contact <span className="text-danger">*</span>
                  </Label>

                  <Controller
                    name="jobMainContactId"
                    rules={{
                      required: {
                        value: true,
                        message: 'Must Select Main Contact!',
                      },
                    }}
                    as={Select}
                    isDisabled={companyId ? false : true}
                    options={contactsLists}
                    placeholder="Main Contact"
                    control={control}
                    isSearchable={true}
                    styles={{ ...customSelectStyleNew(theme) }}
                  />

                  <small className="text-danger">
                    {errors?.mainContact?.message}
                  </small>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label>
                    Report to Contact <span className="text-danger">*</span>
                  </Label>

                  <Controller
                    name="jobReportToContactId"
                    rules={{
                      required: {
                        value: true,
                        message: 'Must Select Report to Contact!',
                      },
                    }}
                    as={Select}
                    isDisabled={companyId ? false : true}
                    options={contactsLists}
                    placeholder="Report to Contact"
                    control={control}
                    isSearchable={true}
                    styles={{ ...customSelectStyleNew(theme) }}
                  />

                  <small className="text-danger">
                    {errors?.remoteContact?.message}
                  </small>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col>
                <FormGroup>
                  <Label>
                    Billing Contact <span className="text-danger">*</span>
                  </Label>

                  <Controller
                    name="jobBillingContactId"
                    rules={{
                      required: {
                        value: true,
                        message: 'Must Select Billing Contact!',
                      },
                    }}
                    as={Select}
                    isDisabled={companyId ? false : true}
                    options={contactsLists}
                    placeholder="Billing Contact"
                    control={control}
                    styles={{ ...customSelectStyleNew(theme) }}
                  />

                  <small className="text-danger">
                    {errors?.billingContact?.message}
                  </small>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className="mb-1">
                  <Label>CC Invoice To</Label>

                  <Controller
                    name="jobCCContactIds"
                    rules={{
                      required: {
                        value: false,
                        message: 'Must Select  CC Invoice To!',
                      },
                    }}
                    as={Select}
                    isDisabled={companyId ? false : true}
                    options={contactsLists}
                    placeholder="CC Invoice To"
                    control={control}
                    isMulti
                    styles={{
                      ...customSelectStyleNew(theme),
                      display: 'inline-flex',
                    }}
                  />

                  <small className="text-danger">
                    {errors?.jobCCContactIds?.message}
                  </small>
                </FormGroup>
                <div className="d-flex align-items-start">
                  <button
                    type="button"
                    className="bg-transparent border-0 text-info px-0 pt-0 btn btn-secondary"
                    onClick={onclickModal}
                  >
                    Create new contact
                  </button>
                </div>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <Collapse isOpen={newContactFieldsModal} border-0>
                  <div className="box-shadow mt-2 px-3 pt-1 pb-1">
                    <CreateContact
                      companyId={placement?.jobOrder?.company?.id}
                      setValue={setValue}
                      setContactList={setContactList}
                      contactList={contactsLists}
                      setNewContactFieldsModal={setNewContactFieldsModal}
                      setCustomEmailInputs={setCustomEmailInputs}
                      customEmailInputs={customEmailInputs}
                      jobCCContactIds={jobCCContactIds}
                    />
                  </div>
                </Collapse>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label>
                    Actual Job Location <span className="text-danger">*</span>
                  </Label>

                  <Controller
                    name="jobLocation"
                    rules={{
                      required: {
                        value: true,
                        message: 'Must Select Actual job location!',
                      },
                    }}
                    isSearchable={true}
                    as={Select}
                    isDisabled={companyId ? false : true}
                    options={addressList}
                    placeholder="Actual job location"
                    control={control}
                    styles={{ ...customSelectStyleNew(theme) }}
                  />

                  <small className="text-danger">
                    {errors?.companyLocationId?.message}
                  </small>
                </FormGroup>
              </Col>

              <Col>
                <FormGroup>
                  <Label>
                    Work Type
                    {watch('status')?.value === 'ACTIVE' && (
                      <span className="text-danger">*</span>
                    )}
                  </Label>

                  <Controller
                    name="jobWorkType"
                    rules={{
                      required: {
                        value:
                          watch('status')?.value === 'ACTIVE' ? true : false,
                        message: 'Must Select workType!',
                      },
                    }}
                    defaultValue={{
                      label: 'Direct Hire',
                      value: 'direct hire',
                    }}
                    placeHolder="Work Type"
                    render={() => (
                      <Select
                        options={WorkType}
                        value={getValues()?.jobWorkType}
                        onChange={(object) => {
                          setValue('jobWorkType', object);
                        }}
                        styles={{ ...customSelectStyleNew(theme) }}
                      />
                    )}
                    control={control}
                    styles={customStyles}
                  />

                  <small className="text-danger">
                    {errors?.workType?.message}
                  </small>
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>

          <ModalFooter>
            <button
              type="button"
              onClick={onCancel}
              className="buttonPrimary mr-3"
            >
              Cancel
            </button>
            <CustomButton
              buttonText="Update"
              buttonColor="primary"
              buttonType="submit"
              className="big-width"
              buttonClick={handleSubmit(updatePlacementJobOrder)}
              loading={loading}
              disabled={loading}
            />
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};

export default JobOrderWidgetDetail;
