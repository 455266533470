import { useMutation } from "@apollo/client"
import { FC } from "react"
import { useForm } from "react-hook-form"
import { toast } from "react-toastify"
import { SAVE } from "../../../../../constants"
import { CommissionOverwriteDrawerProps } from "../../../../../interfaces"
import { COMMISSION_OVERWRITE_DRAWER_HEADING, CREATE, UPDATE } from "../../../../../utils/constant"
import { DrawerHeader, Sidedrawer } from "../../../../layout/Sidedrawer"
import { CREATE_COMMISSION_OVERWRITE, UPDATE_COMMISSION_OVERWRITE } from "../../gql"
import CommissionOverwriteForm from "./Form"

const CommissionOverwriteDrawer: FC<CommissionOverwriteDrawerProps> = ({ isOpen, toggle, recruiterDealId, isEdit, commissionOverwrite }) => {
  const { control, register, setValue, getValues, handleSubmit, errors } = useForm()

  const [createCommissionMutation, { loading }] = useMutation(CREATE_COMMISSION_OVERWRITE, {
    onCompleted: () => {
      toast.success("Commission Overwrite Added Successfully")
      toggle()
    },
    onError: () => toast.error("Unable To Add Commission Overwrite")
  })

  const [updateCommissionMutation, { loading: updating }] = useMutation(UPDATE_COMMISSION_OVERWRITE, {
    onCompleted: () => {
      toast.success("Commission Overwrite Updated Successfully")
      toggle()
    },
    onError: () => toast.error("Unable To Update Commission Overwrite")
  })

  const submitForm = async (values) => {
    const { type, ...rest } = values
    if (isEdit) {
      await updateCommissionMutation({ variables: { input: { id: commissionOverwrite?.id, type: type?.value, ...rest } } })
    } else {
      await createCommissionMutation({ variables: { input: { type: type?.value, recruiterDealId, ...rest } } })
    }
  }

  return <Sidedrawer isOpen={isOpen} toggle={toggle}>
    <DrawerHeader
      buttonText={SAVE} disabled={loading || updating} loading={loading || updating} prevClick={toggle} heading={(isEdit ? UPDATE : CREATE) + COMMISSION_OVERWRITE_DRAWER_HEADING}
      nextClick={handleSubmit(submitForm)} />
    <CommissionOverwriteForm getValues={getValues} commissionOverwrite={commissionOverwrite} control={control} errors={errors} register={register} setValue={setValue} />
  </Sidedrawer>
}
export default CommissionOverwriteDrawer