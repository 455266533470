import { Table } from "reactstrap";

const ClientBillingCredit = ({ netCommission, accountManagers }) => {
  return (
    <div className="candidtae-bcredit-ssheet">
      <h5 className="mb-2">Client Billing Credit</h5>

      <Table className="transparentTable">
        <thead>
          <tr>
            <th style={{ width: "36%" }}>Name</th>
            <th style={{ width: "64%" }}>Credit%</th>
          </tr>
        </thead>

        <tbody>
          {accountManagers &&
            accountManagers?.length > 0 &&
            accountManagers?.map((item) => (
              <tr>
                <td>{item?.user?.fullName}</td>

                <td>{item?.splitPercentage}%</td>
              </tr>
            ))}
        </tbody>
      </Table>
    </div>
  );
};

export default ClientBillingCredit;
