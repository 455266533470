import { gql } from '@apollo/client';

export const SEQUENCE_CONTACTS_LISTING = gql`
  query FetchSequenceContacts(
    $page: Int!
    $limit: Int!
    $searchText: String
    $compaignId: String
    $statusFilter: String
  ) {
    getSequenceContacts(
      payload: { page: $page, limit: $limit }
      filters: {
        searchText: $searchText
        compaignId: $compaignId
        statusFilter: $statusFilter
      }
    ) {
      contacts {
        all_errors {
          _campaign
          date
          error
          error_message
        }
        updated_at
        id
        delayed_indefinitely
        sentiment
        delay_until
        delayed
        error
        name
        _campaign
        company
        email
        _id
        title
        created_at
        state
      }
      count
    }
  }
`;

export const SEQUENCE_CONTACTS_LISTING_WITHOUT_CORE_DATA = gql`
  query FetchSequenceContactsWithoutCoreData($compaignId: String!) {
    getSequenceContactsWithoutCoreData(compaignId: $compaignId) {
      _id
      name
    }
  }
`;

export const APPLY_DATE_RANGE_CONTACTS_MEETINGS = gql`
  query ApplyDateRangeContactsMeetings(
    $type: String
    $startedAt: String
    $endedAt: String
    $compaignId: String
    $sequenceName: String
  ) {
    applyDateRangeContactsMeetings(
      applyDateRangeInput: {
        type: $type
        startedAt: $startedAt
        endedAt: $endedAt
        compaignId: $compaignId
        sequenceName: $sequenceName
      }
    ) {
      _id
      _user
      title
      contactCount
    }
  }
`;

export const UPDATE_MANUAL_CONTACT = gql`
  mutation UpdateManualContact(
    $contactId: String!
    $name: String
    $title: String
    $location: String
    $company: String
    $email: String
    $url: String
    $phone_number: String
  ) {
    updateManualContact(
      contactId: $contactId
      updateContactManualInput: {
        name: $name
        title: $title
        company: $company
        email: $email
        url: $url
        phone_number: $phone_number
        location: $location
      }
    ) {
      message
      status
    }
  }
`;

export const GET_CONTACT_DETAILS = gql`
  query GetContactDetails($contactId: String!) {
    getContactDetails(contactId: $contactId) {
      name
      _id
      email
      company
      title
      phone_number
      location
      all_errors {
        _campaign
        date
        error
        error_message
      }
      step
      url
      state
      next_step
      last_action_at
    }
  }
`;

export const GET_PREVIEW_STEPS = gql`
  query GetPreviewSteps($compaignId: String!) {
    previewSteps(compaignId: $compaignId) {
      type
      _step
      step
      object {
        subject
        markdown
        fulfilled
        body
        id
      }
    }
  }
`;

export const CLEARBIT_AUTOCOMPLETE = gql`
  query ClearbitAutocompleteForCompanyFinding($query: String!) {
    clearbitAutocompleteForCompanyFinding(query: $query) {
      name
      logo
      domain
    }
  }
`;

export const CONTACT_LOOKUP = gql`
  query ContactLookup($name: String, $query: String) {
    contactLookup(contactLookupInput: { name: $name, query: $query }) {
      email
      handle
    }
  }
`;

export const CSV_MAP_FIELDS = gql`
  mutation CsvMapFields(
    $compaignId: String!
    $csvId: String!
    $lookup: Boolean!
    $mapping: String!
  ) {
    mapFieldsCSVToInterseller(
      compaignId: $compaignId
      csvId: $csvId
      lookup: $lookup
      mapping: $mapping
    ) {
      message
      status
    }
  }
`;

export const CSV_MAP_FIELDS_USING_LOOKUP = gql`
  mutation CsvMapFieldsUsingLookups(
    $compaignId: String!
    $csvId: String!
    $mapping: String!
    $lookupType: String!
  ) {
    mapFieldsCSVToIntersellerUsingLookups(
      compaignId: $compaignId
      csvId: $csvId
      mapping: $mapping
      lookupType: $lookupType
    ) {
      message
      status
    }
  }
`;

export const REMOVE_CONTACT = gql`
  mutation RemoveContact($contactId: String!) {
    removeContact(contactId: $contactId) {
      message
      status
    }
  }
`;

export const SEND_NOW_CONTACT_STEP = gql`
  mutation SendNowContactStep($contactId: String!) {
    sendNowContactStep(contactId: $contactId) {
      message
      status
    }
  }
`;

export const CONTACT_MANUAL_ADD = gql`
  mutation ContactManualAdd(
    $compaignId: String!
    $name: String
    $email: String
    $url: String
    $company: String
    $location: String
    $title: String
    $phone_number: String
    $profile_url: String
  ) {
    manuallyContactAdd(
      compaignId: $compaignId
      contactManualInput: {
        name: $name
        email: $email
        url: $url
        company: $company
        location: $location
        title: $title
        phone_number: $phone_number
        profile_url: $profile_url
      }
    ) {
      message
      status
    }
  }
`;

export const CONTACTS_MANUAL_ADD_BULK = gql`
  mutation ContactsManualAddBulk(
    $compaignId: String!
    $contactManualInputBulkArr: ContactManualInputBulkArr!
  ) {
    manuallyContactsAddBulk(
      compaignId: $compaignId
      contactManualInputBulkArr: $contactManualInputBulkArr
    ) {
      message
      status
    }
  }
`;

export const CONTACT_LOOKUP_ADD = gql`
  mutation ContactLookupAdd(
    $compaignId: String!
    $name: String
    $email: String
    $url: String
    $company: String
    $location: String
    $title: String
    $phone_number: String
  ) {
    lookupContactAdd(
      compaignId: $compaignId
      contactLookupAddInput: {
        name: $name
        email: $email
        url: $url
        company: $company
        location: $location
        title: $title
        phone_number: $phone_number
      }
    ) {
      message
      status
    }
  }
`;

export const MoveContactsToSequenceInput = gql`
  input MoveContactsToSequenceInput {
    compaignId: String!
    ids: [String!]
  }
`;

export const MOVE_CONTACTS_TO_SEQUENCE = gql`
  mutation MoveContactsToSequence(
    $moveContactsToSequenceInput: MoveContactsToSequenceInput!
  ) {
    moveContactsToSequence(
      moveContactsToSequenceInput: $moveContactsToSequenceInput
    ) {
      message
      status
    }
  }
`;

export const IPauseContactsData = gql`
  input IPauseContactsData {
    delay_until_boolean: Boolean
    delay_until_string: String
    delayed: Boolean!
    delayed_indefinitely: Boolean!
  }
`;

export const PauseContactsFromSequenceInput = gql`
  input PauseContactsFromSequenceInput {
    data: IPauseContactsData!
    ids: [String!]
  }
`;

export const PAUSE_CONTACTS_FROM_SEQUENCE = gql`
  mutation PauseContactsFromSequence(
    $pauseContactsFromSequenceInput: PauseContactsFromSequenceInput!
  ) {
    pauseContactsFromSequence(
      pauseContactsFromSequenceInput: $pauseContactsFromSequenceInput
    ) {
      message
      status
    }
  }
`;

export const IResumeContactsData = gql`
  input IResumeContactsData {
    delay_until: null
    delayed: Boolean!
    delayed_indefinitely: Boolean!
  }
`;

export const ResumeContactsFromSequenceInput = gql`
  input ResumeContactsFromSequenceInput {
    data: IResumeContactsData!
    ids: [String!]
  }
`;

export const RESUME_CONTACTS_FROM_SEQUENCE = gql`
  mutation ResumeContactsFromSequence(
    $resumeContactsFromSequenceInput: ResumeContactsFromSequenceInput!
  ) {
    resumeContactsFromSequence(
      resumeContactsFromSequenceInput: $resumeContactsFromSequenceInput
    ) {
      message
      status
    }
  }
`;

export const CLEARING_ERRORS_CONTACT = gql`
  mutation ClearingErrorsContact($contactId: String!) {
    clearingErrorsContact(contactId: $contactId) {
      message
      status
    }
  }
`;

export const RemoveContactsFromSequenceInput = gql`
  input RemoveContactsFromSequenceInput {
    ids: [String!]
  }
`;

export const REMOVE_CONTACTS_FROM_SEQUENCE = gql`
  mutation RemoveContactsFromSequence(
    $removeContactsFromSequenceInput: RemoveContactsFromSequenceInput!
  ) {
    removeContactsFromSequence(
      removeContactsFromSequenceInput: $removeContactsFromSequenceInput
    ) {
      message
      status
    }
  }
`;

export const CopyContactsToSequenceInput = gql`
  input CopyContactsToSequenceInput {
    compaignId: String!
    ids: [String!]
  }
`;

export const COPY_CONTACTS_TO_SEQUENCE = gql`
  mutation CopyContactsToSequence(
    $copyContactsToSequenceInput: CopyContactsToSequenceInput!
  ) {
    copyContactsToSequence(
      copyContactsToSequenceInput: $copyContactsToSequenceInput
    ) {
      message
      status
    }
  }
`;

export const FIXED_EMAIL_ADDRESS = gql`
  mutation fixContactEmail(
    $compaignId: String!
    $contactId: String!
    $email: String!
  ) {
    fixContactEmail(
      compaignId: $compaignId
      contactId: $contactId
      email: $email
    ) {
      message
      status
    }
  }
`;

export const SET_REPLIED = gql`
  mutation setReplied($contactId: String!, $booked: Boolean!) {
    setReplied(contactId: $contactId, booked: $booked) {
      message
      status
    }
  }
`;

export const SET_PAUSED = gql`
  mutation pauseContact($contactId: String!, $pauseDate: String!) {
    pauseContact(contactId: $contactId, pauseDate: $pauseDate) {
      message
      status
    }
  }
`;

export const UNSUBCRIBE_CONTACT = gql`
  mutation unsubscribeContacts($contactId: String!) {
    unsubscribeContacts(contactId: $contactId) {
      message
      status
    }
  }
`;

export const CONTACT_BLOCKED = gql`
  mutation blockContact($contactId: String!, $team: Boolean!) {
    blockContact(contactId: $contactId, team: $team) {
      message
      status
    }
  }
`;

export const REMOVE_BOOKED = gql`
  mutation removedBooked($contactId: String!) {
    removedBooked(contactId: $contactId) {
      message
      status
    }
  }
`;

export const SET_SENTIMENT = gql`
  mutation setSentiment($contactId: String!, $sentiment: String!) {
    setSentiment(contactId: $contactId, sentiment: $sentiment) {
      message
      status
    }
  }
`;

export const RESUME_EMAILS = gql`
  mutation ResumeEmails($ResumeEmailType: ResumeEmailType!) {
    ResumeEmails(ResumeEmailType: $ResumeEmailType) {
      message
      status
    }
  }
`;
