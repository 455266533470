import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import '../placement.scss';

export default function CreatePlacementStepper({
  currentStep,
  setCurrentStep,
  readonly,
  neverVisited,
  stepOneResetValues,
}) {
  return (
    <Breadcrumb listTag="div" tag="nav" className="stepper-grid">
      <BreadcrumbItem
        className={`stepper-item ${currentStep === 1 ? 'active-step' : ''}`}
        onClick={() => {
          if (!readonly && !neverVisited) {
            stepOneResetValues();
          }
          setCurrentStep(1);
        }}
      >
        Placement Details
      </BreadcrumbItem>
      <BreadcrumbItem
        className={`stepper-item ${currentStep === 2 ? 'active-step' : ''}`}
        onClick={() => {
          setCurrentStep(2);
        }}
      >
        Account Manager Splits
      </BreadcrumbItem>
      <BreadcrumbItem
        className={`stepper-item ${currentStep === 3 ? 'active-step' : ''}`}
        onClick={() => {
          setCurrentStep(3);
        }}
      >
        Recruiter Splits
      </BreadcrumbItem>
    </Breadcrumb>
  );
}
