import Select from "react-select";
import deleteIcon from "../../../../assets/images/delete-field.svg";
import {
  Button,
  Col,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  Row,
} from "reactstrap";
import { useState, useEffect } from "react";
import { IAccountManagerPercentage } from "../create/IndexUpdatePlacement";
import editImage from "../../../../assets/images/edit.svg";
import { ZERO_TO_HUNDRED } from "../../../../utils/constant";

export default function CreateAccountManager({
  accountManagers,
  errors,
  defaultPercentage,
  readonly,
  accountManagerListChange,
  accountManagerList,
  defaultOwner,
}) {
  const [accountManager, setAccountManager] = useState<any>();
  const [comingAccountManagers, setComingAccountManagers] =
    useState<any>(accountManagers);
  const [edit, setEdit] = useState<boolean>(false);
  const [percentageError, setPercentageError] = useState<any>();
  const [accountManagerPercentage, setAccountManagerPercentage] =
    useState<any>(defaultPercentage);

  const onPercentageChange = (percentage: string) => {
    setAccountManagerPercentage(parseFloat(percentage));
    if (percentage) {
      const mailRegex = new RegExp(ZERO_TO_HUNDRED);
      const test = mailRegex.test(percentage);
      if (test) {
        setPercentageError("");
      } else {
        setPercentageError("Percentage is not valid!");
      }
    }
  };

  const onDeleteItem = (item: any) => {
    let objectToAdd: any = {};

    objectToAdd.id = item?.accountManager?.value;
    objectToAdd.fullName = item?.accountManager?.label;

    let cloneArr = [...comingAccountManagers, { ...objectToAdd }];

    setComingAccountManagers(cloneArr);
    setEdit(false);
    accountManagerListChange(item);
    setAccountManager("");
    setAccountManagerPercentage("");
  };

  const onEditItem = (item: any) => {
    setAccountManager({
      label: item?.accountManager?.label,
      value: item?.accountManager?.value,
    });
    onPercentageChange(item?.percentage);
  };

  useEffect(() => {
    if (!accountManager) {
      setComingAccountManagers(
        comingAccountManagers?.filter((item) => item.id !== defaultOwner?.value)
      );
      setAccountManager(defaultOwner);
    }

    // eslint-disable-next-line
  }, [accountManager, defaultOwner]);

  const editCurrentSplit = () => {
    const index = accountManagerList?.findIndex(
      (element) => element?.accountManager?.value === accountManager?.value
    );

    accountManagerList?.splice(index, 1);

    accountManagerListChange({
      accountManager: accountManager,
      percentage: accountManagerPercentage,
    });

    setEdit(false);
  };

  return (
    <form>
      <Row>
        <Col md="6">
          <Label>
            Account Manager Name <span className="text-danger">*</span>
          </Label>
          <Select
            isDisabled={readonly}
            value={accountManager}
            name="accountManagerName"
            options={comingAccountManagers?.map((item: any) => {
              return {
                label: item?.fullName,
                value: item?.id,
              };
            })}
            onChange={setAccountManager}
          />
        </Col>

        <Col md="6">
          <Label>
            Account Manager Percentage
            <span className="text-danger">*</span>
          </Label>

          <InputGroup>
            <InputGroupAddon addonType="prepend">%</InputGroupAddon>
            <Input
              readOnly={readonly}
              placeholder="Account Manager Percentage"
              name="accountManagerPercentage"
              type="number"
              value={`${accountManagerPercentage}`}
              onChange={(e) => onPercentageChange(e.target.value)}
            />
          </InputGroup>

          <small className="text-danger">
            {errors?.accountManagerPercentage?.message || percentageError}
          </small>
        </Col>

        <Col md="12" className="pt-4 float-right">
          {edit ? (
            <Button
              disabled={
                !(accountManager && accountManagerPercentage) || readonly
              }
              onClick={() => {
                setAccountManager("");
                setAccountManagerPercentage("");
                editCurrentSplit();
              }}
            >
              Edit
            </Button>
          ) : (
            <Button
              disabled={
                readonly ||
                !(accountManager && accountManagerPercentage) ||
                accountManagerList?.find(
                  (item: IAccountManagerPercentage) =>
                    item?.accountManager?.value === accountManager?.value
                )
                  ? true
                  : false
              }
              onClick={() => {
                !accountManagerList?.find(
                  (item: IAccountManagerPercentage) =>
                    item?.accountManager?.value === accountManager?.value
                ) &&
                  accountManagerListChange({
                    accountManager: accountManager,
                    percentage: accountManagerPercentage,
                  });

                setAccountManager("");
                setComingAccountManagers(
                  comingAccountManagers?.filter(
                    (item) => item.id !== accountManager?.value
                  )
                );
                setAccountManagerPercentage("");
              }}
            >
              Add
            </Button>
          )}
        </Col>
      </Row>

      <div className="d-flex mt-3">
        {accountManagerList?.map(
          (item: IAccountManagerPercentage, index: number) => {
            return (
              <Row>
                <Col md="12">
                  <Label>
                    {index + 1}
                    {")"}
                  </Label>{" "}
                  <Label> {item?.accountManager?.label}</Label>
                  <Label className="percentage-am">{item?.percentage}%</Label>
                  <Button
                    disabled={readonly}
                    onClick={() => {
                      setEdit(true);
                      onEditItem(item);
                    }}
                    className="my-auto bg-transparent border-0 w-auto"
                  >
                    <img
                      src={editImage}
                      alt="Edit"
                      className="m-0"
                      width="16"
                    />
                  </Button>
                  <Button
                    disabled={readonly}
                    onClick={() => onDeleteItem(item)}
                    className="my-auto bg-transparent border-0 w-auto"
                  >
                    <img src={deleteIcon} alt="" width="20" height="20" />
                  </Button>
                </Col>
              </Row>
            );
          }
        )}
      </div>
    </form>
  );
}
