import { useEffect, useRef, useState } from "react";
import ContentViewModal from "../../../components/ContentViewModal";
import { createMarkup } from "../../../utils/commonFn";
import { countWordsInString, trimStringUptoWords } from "../../../utils/helper";

const ItemSummary = ({ item, from }) => {
  const [initialCallModal, setInitialCallModal] = useState(false);
  const [, setHeight] = useState<Number | undefined>();
  const ref = useRef(null as null | HTMLDivElement);

  useEffect(() => {
    setTimeout(() => {
      setHeight(ref?.current?.offsetHeight);
    }, 1);
  });

  return (
    <div>
      {item?.content && countWordsInString(item?.content, 4) ? (
        <>
          <div
            className="companyTextWidth"
            dangerouslySetInnerHTML={createMarkup(
              trimStringUptoWords(item?.content.replace(/<[^>]*>?/gm, ""), 4)
            )}
          />
        </>
      ) : (
        <div
          className="companyTextWidth"
          dangerouslySetInnerHTML={createMarkup(
            item?.content?.replace(/<[^>]*>?/gm, "")
          )}
        />
      )}

      <ContentViewModal
        toggle={() => setInitialCallModal(!initialCallModal)}
        heading={item?.writeUpType || "Content"}
        content={item?.writeupSummary || item?.content}
        isOpen={initialCallModal}
      />
    </div>
  );
};

export default ItemSummary;
