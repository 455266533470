// packages block
import { FC, useCallback, useContext, useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { Badge, Button, Spinner } from "reactstrap";
import FileDownload from "js-file-download";
import { useLazyQuery, useMutation } from "@apollo/client";
import { Link, RouteComponentProps } from "react-router-dom";
// components block
import Loader from "../../components/layout/Loader";
import ConfirmPopUp from "../../components/ConfirmPopUp";
import Pagination from "../../components/layout/Pagination";
import InputField from "../../components/layout/InputField";
import NoDataFound from "../../components/layout/NoDataFound";
import TableComponent from "../../components/layout/TableComponent";
import AsyncCompanyDropdown from "../../components/AsyncCompanySelect";
// utils, graphql and constants block
import { AppContext } from "../../context";
import { DownloadFileBtnProps } from "../../interfaces";
import VIEW_ICON from "../../assets/images/view-icon.svg";
import EDIT_ICON from "../../assets/images/edit-icon-menu.svg";
import { getAgreementParams } from "../../utils/getNoteParams";
import DOWNLOAD_FILE from "../../assets/images/download-icon.svg";
import DELETE_ICON from "../../assets/images/delete-icon-menu.svg";
import { AGREEMENTS_ROUTE, AgreementTypeDTO, AGREEMENT_HEADERS_COLUMN_SEARCH, COLUMN_INTERFACE_TYPES_FOR_AGREEMENTS, COMPANY_DETAILS_ROUTE, CONTACT_DETAIL_ROUTE, LEGACY_AGREEMENTS_ROUTE, PAGE_LIMIT, VIEW_AGREEMENT_ROUTE, ADMIN_ROLE, EXECUTIVE_ROLE, DIRECT_HIRE, OUT_FOR_SIGNATURE, SIGNED, DRAFT, MANAGER_REJECTED, VOIDED, LEGAL_CONTRACT, LEGAL_DIRECT_HIRE, EXECUTIVE_REJECTED, EXECUTIVE_APPROVED, AWAITING_EXECUTIVE_APPROVAL, AWAITING_MANAGER_APPROVAL, RESENT, CLIENT_PREVIEW, CUSTOM } from "../../utils/constant";
import { formatValue, getAgreementColor, moneyFormatter, pushInHistory } from "../../utils/helper";
import { DELETE_AGREEMENT_EXECUTIVE, FETCH_AGREEMENTS } from "./gql";
import { compactObject, downloadSignedUrlFile, formatDateUS, formatDateUSWithMoment } from "../../utils/commonFn";
import { useForm } from "react-hook-form";

const DownloadFileBtn: FC<DownloadFileBtnProps> = ({ url, id, status, nickName, agreementType }): JSX.Element => {
  const [loading, setLoading] = useState(false);

  const handleDownload = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL || "http://localhost:3001"
        }${AGREEMENTS_ROUTE}/downloadDocument/${id}`,
        {
          responseType: "blob",
        }
      );
      FileDownload(response.data, `${nickName}.pdf`);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("Error while downloading file..");
    }
  };

  return (
    <Button
      disabled={(agreementType !== DIRECT_HIRE) && !(status === OUT_FOR_SIGNATURE || status === SIGNED)}
      onClick={() => {
        setLoading(true);
        (status === SIGNED || status === OUT_FOR_SIGNATURE) && url
          ?
          downloadSignedUrlFile(url, `${nickName}.pdf`, setLoading)
          : handleDownload()
      }
      }
      className="bg-transparent border-0 p-0 mr-3"
    >
      {loading && <Spinner size="sm" style={{ color: "#eaa827" }} className="m-0" />}
      {!loading && <img className="m-0" src={DOWNLOAD_FILE} alt="download" />}
    </Button>
  );
};

let time = 0;
const AllAgreements = ({ location, history }: RouteComponentProps) => {
  const fieldNames = [
    'companySearch',
    'contactSearch',
    'nickNameSearch',
    'typeSearch',
    'statusSearch',
    'accountManagerSearch',
  ];
  const defaultColumnFilters = {
    companySearch: '',
    contactSearch: '',
    nickNameSearch: '',
    typeSearch: '',
    statusSearch: '',
    accountManagerSearch: '',
    sorting: {
      columnName: '',
      columnValue: ''
    }
  };
  const page =
    new URLSearchParams(location.search || "").get("currentPage") || "1";
  const _currentPage: number = parseInt(page);
  const [currentPage, setCurrentPage] = useState(_currentPage);
  const [limit, setLimit] = useState(10);
  const [selectedContactId, setSelectedContactId] = useState("");
  const { user, userRoles } = useContext(AppContext);
  const [actionConfirm, setAgreementDeleteConfirm] = useState(false);
  const [actionId, setDeleteActionId] = useState("");
  const [deleteActionStatus, setDeleteActionStatus] = useState("");
  const [selectedCompanyName, setSelectedCompanyName] = useState("");
  const [selectedCompanyId, setSelectedCompanyId] = useState("");
  const [myCompaniesFlag, setMyCompaniesFlag] = useState<boolean>(false);
  const [myPendingActionsFlag, setMyPendingActionsFlag] = useState<boolean>(false);
  const [isSetTimeOut, setIsSetTime] = useState<boolean>(true);
  const [columnFilters, setColumnFilters] =
    useState<COLUMN_INTERFACE_TYPES_FOR_AGREEMENTS>(defaultColumnFilters);
  const [errorMsg, setErrorMsg] = useState({});
  const { register, setValue, control } = useForm();

  const [_deleteAction, { loading: deleteLoading }] = useMutation(
    DELETE_AGREEMENT_EXECUTIVE
  );
  const [fetchAllAgreements, { loading: agreementLoader, error, data }] =
    useLazyQuery(FETCH_AGREEMENTS);
  let {
    location: { state },
  }: any = history || {};


  const fetchAgreements = useCallback(async () => {
    if (isSetTimeOut) {
      setQueryParams();
      fetchAllAgreements({
        variables: {
          limit,
          page: currentPage,
          companyId: selectedCompanyId,
          companyContactId: selectedContactId,
          myCompanies: myCompaniesFlag,
          myPendingActions: myPendingActionsFlag,
          columnFilters: columnFilters

        },
      });
    } else {
      setQueryParams();
      setLimit(limit);
      window.clearTimeout(time);
      time = window.setTimeout(async () => {
        fetchAllAgreements({
          variables: {
            limit,
            page: currentPage,
            companyId: selectedCompanyId,
            companyContactId: selectedContactId,
            myCompanies: myCompaniesFlag,
            myPendingActions: myPendingActionsFlag,
            columnFilters: columnFilters

          },
        });
      }, 1000);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCompanyId,
    currentPage,
    limit,
    myCompaniesFlag,
    myPendingActionsFlag,
    state?.pendingAction,
    columnFilters]);

  const setQueryParams = () => {
    let newColumn: COLUMN_INTERFACE_TYPES_FOR_AGREEMENTS = Object.assign(
      {},
      columnFilters
    );
    const { sorting, ...rest } = newColumn;

    const criteria = compactObject({
      currentPage,
      limit,
      selectedCompanyId,
      selectedCompanyName,
      myCompaniesFlag,
      myPendingActionsFlag,
      ...rest,
      ...sorting
    });

    const urlQueryParams: any = [];
    if (Object.keys(criteria).length) {
      for (const key in criteria) {
        if (
          Object.prototype.hasOwnProperty.call(criteria, key) &&
          criteria[key]
        ) {
          urlQueryParams.push(`${key}=${criteria[key]}`);
        }
      }
      history.push(`${AGREEMENTS_ROUTE}?${urlQueryParams.join("&")}`);
    }
  };

  useEffect(() => {
    document.title = "Agreements - RealREPP";
    let params: any = getAgreementParams(location.search);

    if (params) {
      setSelectedCompanyId(params?.selectedCompanyId);
      setSelectedCompanyName(params?.selectedCompanyName);
      setCurrentPage(params?.currentPage ? parseInt(params?.currentPage) : 1);
      setLimit(params?.limit ? parseInt(params?.limit) : 10);
      setMyCompaniesFlag(!!params?.myCompaniesFlag);
      state &&
        state?.pendingAction &&
        setMyPendingActionsFlag(state?.pendingAction as boolean);
    }
    if (Object.entries(params?.columnFilters)?.length) {
      if (Object.entries(params?.columnFilters)?.length === 1) {
        setColumnFilters((prevFilters) => ({
          ...prevFilters,
          [Object.keys(params?.columnFilters)[0]]: Object.values(
            params?.columnFilters
          )[0],
        }));
      } else {
        const updatedFilters = { ...columnFilters };
        Object.entries(params?.columnFilters).forEach(([key, value]) => {
          updatedFilters[key] = value;
        });
        setColumnFilters(updatedFilters);
      }
    }
    // eslint-disable-next-line
  }, [state?.pendingAction]);

  useEffect(() => {
    fetchAgreements();

    // eslint-disable-next-line
  }, [fetchAgreements]);

  const changeFiled = (fieldName, value) => {
    console.log("fieldName", fieldName)
    if (fieldName?.endsWith('Sort')) {
      const columnName = fieldName.replace('Sort', '');
      setIsSetTime(true)
      setColumnFilters((prevFilters) => {
        const { sorting, ...otherFilters } = prevFilters;
        return {
          ...otherFilters,
          sorting: {
            columnName: columnName,
            columnValue: value ? 'ASC' : 'DESC',
          },
        };
      });
    } else {
      setCurrentPage(1);
      setLimit(limit);
      setIsSetTime(false)
      setColumnFilters((prevFilters) => ({
        ...prevFilters,
        [fieldName]: value,
      }));
    }
  };

  useEffect(() => {
    let params: any = new URLSearchParams(location.search);
    const companyId = params.get("companyId");
    const companyName = params.get("companyName");
    const contactId = params.get("contactId");
    if (contactId) setSelectedContactId(contactId);
    if (companyId) setSelectedCompanyId(companyId);
    if (companyName) setSelectedCompanyName(companyName);

    // eslint-disable-next-line
  }, [location.search]);

  const confirmAction = async () => {
    const response = await _deleteAction({
      variables: { agreementId: actionId },
    });
    if (response?.data?.deleteAgreementExecutive?.status === 200) {
      toast.success("Successfully deleted!");
      setAgreementDeleteConfirm(false);
      fetchAgreements();
    } else if (response?.data?.deleteAgreementExecutive?.status === 500) {
      toast.warn("Unable to delete agreement as it is either associated to a placement or job order");
      setAgreementDeleteConfirm(false);
    } else {
      setAgreementDeleteConfirm(false);
    }
  };

  const getNickname = (item) => {
    const date = new Date();
    let agreementDate = `${date.getFullYear()}${date.getMonth() < 10 ? `0${date.getMonth() + 1}` : date.getMonth()
      }${date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()}`;
    const agreementTypeStr = item?.agreementType;
    const agreementTypeStrLower = agreementTypeStr?.toLowerCase();
    const agreementTypeStrSplit = agreementTypeStrLower?.split("_");
    for (let i = 0; i < agreementTypeStrSplit?.length; i++) {
      agreementTypeStrSplit[i] =
        agreementTypeStrSplit[i]?.charAt(0)?.toUpperCase() +
        agreementTypeStrSplit[i]?.slice(1);
    }
    const finalAgreementTypeStr = agreementTypeStrSplit?.join("");
    const companyName = item.Company.name;

    return `${finalAgreementTypeStr} Agreement-${companyName}-${agreementDate}`;
  };

  return (
    <div className="admin-tabs team-tabs">
      <div className="mobileResponsiveFlexAlign justify-content-between primaryHeaderSpacing">
        <h5 className="m-0">Agreements</h5>
      </div>

      <div className="search-filter addAgreementFlex">
        <div className="async-dropdown">
          <AsyncCompanyDropdown
            placeholder="Filter By Company"
            value={{
              value: selectedCompanyId,
              label: selectedCompanyName,
            }}
            onChange={(selected) => {
              setQueryParams();
              setSelectedCompanyId(selected?.value);
              setSelectedCompanyName(selected?.label);
              setCurrentPage(1);
              setLimit(25);
            }}
          />
        </div>
        <div>
        </div>

        <div className="filter-select addAgreementFlex my-2">
          <div className="doubleButtonStyle my-2 d-flex flex-wrap">
            <div>
              <button
                style={{
                  backgroundColor: `${myCompaniesFlag ? "#f4f3f3" : "white"}`,
                  color: `${myCompaniesFlag ? "#eaa827" : "#495057"}`,
                  border: `${"1px solid #ced4da"}`,
                }}
                className="filter-job-order mr-2"
                onClick={() => {
                  setMyCompaniesFlag(!myCompaniesFlag);
                  setQueryParams();
                  setCurrentPage(1);
                  setLimit(25);
                }}
                type="button"
              >
                My Companies
              </button>
            </div>

            <div>
              <button
                style={{
                  backgroundColor: `${myPendingActionsFlag ? "#f4f3f3" : "white"
                    }`,
                  color: `${myPendingActionsFlag ? "#eaa827" : "#495057"}`,
                  border: `${"1px solid #ced4da"}`,
                }}
                className="filter-job-order mr-2"
                onClick={() => {
                  setMyPendingActionsFlag(!myPendingActionsFlag);
                  setQueryParams();
                  setCurrentPage(1);
                  setLimit(25);
                }}
                type="button"
              >
                My Pending Actions
              </button>
            </div>
          </div>

          <div className="doubleButtonStyle my-2 d-flex flex-wrap">
            {(userRoles?.includes(ADMIN_ROLE) ||
              userRoles?.includes(EXECUTIVE_ROLE)) && (
                <button
                  type="button"
                  onClick={() => {
                    history.push(LEGACY_AGREEMENTS_ROUTE);
                  }}
                  className="buttonGenericStyle filledButton mr-2"
                >
                  Legacy Agreement
                </button>
              )}

            <button
              type="button"
              onClick={() => {
                history.push({
                  pathname: "/create-agreement",
                  state: { searchParameters: location.search },
                });
              }}
              className="buttonGenericStyle filledButton mr-2"
            >
              Add New Agreement
            </button>
          </div>

          <div className="filter-by my-2 doubleButtonStyle d-flex flex-wrap">
            <button
              className="buttonGenericStyle filledButton ml-2"
              onClick={() => {
                setColumnFilters(defaultColumnFilters);
                fieldNames.forEach((fieldName) => {
                  setValue(fieldName, defaultColumnFilters[fieldName]);
                });
                if (columnFilters?.sorting.columnName) {
                  setValue(columnFilters?.sorting.columnName, false);
                }
                setErrorMsg({})
                setIsSetTime(true)
                setSelectedCompanyId("");
                setSelectedCompanyName("");
                setMyCompaniesFlag(false);
                setMyPendingActionsFlag(false);
                fetchAllAgreements({
                  variables: {
                    limit,
                    page: currentPage,
                    searchText: "",
                    statusSearch: "",
                    companyId: "",
                    companyContactId: "",
                    myCompaniesFlag,
                    myPendingActionsFlag,
                    columnFilters: columnFilters

                  },
                });
              }}
            >
              Clear
            </button>
          </div>
        </div>
      </div>

      <div className="table-responsive mt-3 tablePlacementResponsive">
        <TableComponent
          advanceSearching={true}
          tableHeaderAdvSeacrh={AGREEMENT_HEADERS_COLUMN_SEARCH}
          handleChange={(filedName, value) => changeFiled(filedName, value)}
          columnFilters={columnFilters}
          register={register}
          setValue={setValue}
          control={control}
          tableName="AllAgreements"
          setErrorMsg={setErrorMsg}
          errorMsg={errorMsg}
        >
          {!agreementLoader && !data?.allAgreements?.agreements?.length && (
            <tbody>
              <tr>
                <td colSpan={9} className="empty-table-td">
                  <NoDataFound text="No Agreement Found!" />
                </td>
              </tr>
            </tbody>
          )}

          {agreementLoader ? (
            <tbody>
              <tr>
                <td colSpan={11}>
                  <Loader loaderClass="sm-height " />
                </td>
              </tr>
            </tbody>
          ) :
            <tbody className='placement-table'>
              {data &&
                data?.allAgreements &&
                data?.allAgreements?.agreements?.map(
                  (item: any, index: number) => (
                    <tr key={index}>
                      <td
                        className="route-link text-truncate max-200"
                        onClick={() => {
                          history.push({
                            pathname: `${COMPANY_DETAILS_ROUTE}/${item?.Company?.id}`,
                            state: {
                              backUrl: AGREEMENTS_ROUTE,
                              searchParameters: location.search
                            },
                          });

                          pushInHistory(AGREEMENTS_ROUTE, location.search)
                        }}
                      >
                        {item?.Company?.name}

                        <div className="subtitleText">
                          {item?.updatedAt ? formatDateUS(item?.updatedAt) : "--"}
                        </div>
                      </td>

                      {!!item?.CompanyContact?.fullName ?
                        <td
                          className="route-link"
                          onClick={() => {
                            history.push({
                              pathname: `${CONTACT_DETAIL_ROUTE}/${item?.CompanyContact?.id}`,
                              state: {
                                backUrl: AGREEMENTS_ROUTE,
                                searchParameters: location.search
                              },
                            });

                            pushInHistory(AGREEMENTS_ROUTE, location.search)
                          }}>
                          {item?.CompanyContact?.fullName}
                        </td> :
                        <td>--</td>
                      }

                      <td>{item?.nickName}</td>

                      <td>{item?.agreementType ? AgreementTypeDTO[item?.agreementType] : "--"}</td>

                      <td>
                        {item?.Company?.companyOwner?.owner?.fullName || "--"}
                      </td>

                      <td className='badge-pading'>
                        <Badge
                          color={getAgreementColor(item.status)}
                          className="text-capitalize"
                        >
                          {formatValue(item.status)}
                        </Badge>
                      </td>
                      {((item?.commissionPercentage && item?.agreementType === CUSTOM) || item?.agreementType === LEGAL_DIRECT_HIRE) ? (
                        <td>{item?.hasFlatFee ? moneyFormatter(item?.commissionPercentage) : item?.commissionPercentage + '%'}</td>
                      ) : (item?.commissionPercentage && item?.agreementType === DIRECT_HIRE) ? (
                        <td>{item?.commissionPercentage}%</td>
                      ) : <td>{item?.commissionPercentage}</td>}
                      <td>
                        {item?.signedAt
                          ?formatDateUSWithMoment(item?.signedAt)
                          : "--"}
                      </td>

                      <td>
                        <div
                          className="d-flex align-items-center"
                          style={{ width: 150 }}
                        >
                          <DownloadFileBtn
                            id={item?.id}
                            status={item?.status}
                            nickName={getNickname(item)}
                            agreementType={item?.agreementType}
                            url={item?.documentUri ? item?.documentUri : ""}
                          />

                          <Link
                            to={{
                              pathname: `${VIEW_AGREEMENT_ROUTE}/${item?.id}`,
                              state: { searchParameters: location.search },
                            }}
                            onClick={() => pushInHistory(AGREEMENTS_ROUTE, location.search)}
                          >
                            <div>
                              <img src={VIEW_ICON} alt="view" width="21px" />
                            </div>
                          </Link>

                          <Link to={AGREEMENTS_ROUTE} className="mx-3">
                            {(user?.id === item?.User?.id &&
                              (item?.status === DRAFT ||
                                item?.status === CLIENT_PREVIEW ||
                                item?.status === MANAGER_REJECTED ||
                                item?.status === EXECUTIVE_REJECTED) &&
                              !(
                                item?.status === SIGNED ||
                                item?.status === OUT_FOR_SIGNATURE
                              )) ||
                              (userRoles?.includes(EXECUTIVE_ROLE) &&
                                !(
                                  item?.status === SIGNED || item?.status === VOIDED
                                  || (item?.status === OUT_FOR_SIGNATURE && item?.agreementType === DIRECT_HIRE)
                                )) ? (
                              <>
                                {!(
                                  item?.agreementType === LEGAL_CONTRACT ||
                                  item?.agreementType === LEGAL_DIRECT_HIRE
                                ) && (
                                    <Link
                                      onClick={() => pushInHistory(AGREEMENTS_ROUTE, location.search)}
                                      to={{
                                        pathname: `update-agreement/${item?.id}`,
                                        state: {
                                          searchParameters: location.search,
                                        },
                                      }}
                                    >
                                      <img src={EDIT_ICON} alt="edit" />
                                    </Link>
                                  )}
                                {(item?.agreementType === LEGAL_CONTRACT ||
                                  item?.agreementType ===
                                  LEGAL_DIRECT_HIRE) && (
                                    <Link
                                      onClick={() => pushInHistory(AGREEMENTS_ROUTE, location.search)}
                                      to={AGREEMENTS_ROUTE}
                                      style={{
                                        cursor: "not-allowed",
                                        color: "#d4d4d4",
                                        opacity: 0.6,
                                      }}
                                    >
                                      <img src={EDIT_ICON} alt="edit" />
                                    </Link>
                                  )}
                              </>
                            ) : (
                              <Link
                                onClick={() => pushInHistory(AGREEMENTS_ROUTE, location.search)}
                                to={AGREEMENTS_ROUTE}
                                style={{
                                  color: "#d4d4d4",
                                  cursor: "not-allowed",
                                  opacity: 0.6,
                                }}
                              >
                                <img src={EDIT_ICON} alt="edit" />
                              </Link>
                            )}
                          </Link>

                          <div>
                            <div className="d-flex align-items-center">
                              {(userRoles?.includes(EXECUTIVE_ROLE)) &&
                                (item?.status === DRAFT ||
                                  item?.status === CLIENT_PREVIEW ||
                                  item?.status === MANAGER_REJECTED ||
                                  item?.status === EXECUTIVE_REJECTED ||
                                  // item?.status === SIGNED ||
                                  item?.status === OUT_FOR_SIGNATURE ||
                                  item?.status === EXECUTIVE_APPROVED ||
                                  item?.status === VOIDED ||
                                  item?.status === AWAITING_EXECUTIVE_APPROVAL ||
                                  item?.status === AWAITING_MANAGER_APPROVAL ||
                                  item?.status === RESENT)
                                ? (
                                  <Button
                                    onClick={() => {
                                      setAgreementDeleteConfirm(true);
                                      setDeleteActionId(item?.id);
                                      setDeleteActionStatus(item?.status);
                                    }}
                                    className="bg-transparent border-0 p-0"
                                  >
                                    <img src={DELETE_ICON} alt="delete" />
                                  </Button>
                                ) : (
                                  <Button
                                    onClick={() => {
                                      setAgreementDeleteConfirm(false);
                                    }}
                                    disabled={true}
                                    className="bg-transparent border-0 p-0"
                                  >
                                    <img src={DELETE_ICON} alt="delete" />
                                  </Button>
                                )}
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  )
                )}
            </tbody>}
        </TableComponent>
      </div>

      {data?.allAgreements?.agreements?.length && !agreementLoader ? (
        <div className="users-pagination">
          <Pagination
            key={data?.allAgreements?.agreements.id}
            onPageChange={(pageClicked: number) => {
              setIsSetTime(true)
              setQueryParams();
              setCurrentPage(pageClicked);
            }}
            pageCount={Math.ceil(data?.allAgreements?.count / limit)}
            currentPage={currentPage}
          />

          <div className="d-flex align-items-center">
            <div className="pagination-display paginationItemsSelect">
              <p className="m-0">
                {(currentPage - 1) * limit + 1} to{" "}
                {Math.min(currentPage * limit, data?.allAgreements?.count)} of{" "}
                {data?.allAgreements?.count}
              </p>

              <div className="divider"></div>

              <label>Display</label>

              <InputField
                label=""
                inputtype="select"
                selectItems={PAGE_LIMIT.filter((item) => item.value !== limit)}
                inputid="limit"
                placeholder={limit.toString()}
                inputMethod={(value) => {
                  setIsSetTime(true)
                  setQueryParams();
                  setCurrentPage(1);
                  setLimit(parseInt(value));
                }}
              />
            </div>
          </div>
        </div>
      ) : null}

      {error?.graphQLErrors[0]?.message}

      <ConfirmPopUp
        confirmText="Are you sure you want to delete this agreement from agreements list?"
        isOpen={actionConfirm}
        toggle={setAgreementDeleteConfirm}
        agreementStatus={deleteActionStatus}
        confirmAction={confirmAction}
        modalHeading="Delete Action"
        btnText="Delete"
        loading={deleteLoading}
        disabled={deleteLoading}
        btnColor="primary"
        className="revampDialog revampDialogWidth"
      />
    </div>
  );
};

export default AllAgreements;