import { useLazyQuery, useMutation } from "@apollo/client";
import { SetStateAction, useContext, useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import Loader from "../../components/layout/Loader";
import TableComponent from "../../components/layout/TableComponent";
import { compactObject } from "../../utils/commonFn";
import Pagination from "../../components/layout/Pagination";
import {
  Badge,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import InputField from "../../components/layout/InputField";
import { PAGE_LIMIT, RECRUITER_FOLDERS_ROUTE, ROLES, VIEW_CANDIDATE_ROUTE } from "../../utils/constant";
import SEARCH_IMG from "../../assets/images/search.svg";
import NoDataFound from "../../components/layout/NoDataFound";
import { AppContext } from "../../context";
import { formatDateUS } from "../../utils/commonFn";
import { CANDIDATES_PRIVATE_LISTING, REMOVE_PRIVATE_CANDIDATE } from "./gql";
import CandidateRecruiterModal from "../candidate/candidate-components/CandidateRecruiterModal";
import ShareCandidate from "../candidate/candidate-components/ShareCandidate";
import ConfirmPopUp from "../../components/ConfirmPopUp";
import { toast } from "react-toastify";
import Page404 from "../error";
import GoBack from "../../components/GoBack";
import { getPrivateCandidatesParams } from "../../utils/getNoteParams";
import PrivateCandidateListingActions from "./PrivateCandidateListingActions";
import { pushInHistory } from "../../utils/helper";

 

const ACTIVE_WIDGETS_HEADER = [
  "",
  "Name",
  "Job Title",
  "Company",
  "Location",
  "Owner",
  "Status",
  "Source",
  "resume Uploaded At",
  "Actions",
];

let time = 0;

const PrivateListingCandidate = ({
  location,
  history,
}: RouteComponentProps) => {
  const [modal, setModal] = useState(false);
  const [candidateId, setCandidateId] = useState<string>();
  const [confirmToggle, setConfirmToggle] = useState(false);
  const toggle = () => setModal(!modal);
  const page = new URLSearchParams(location.search).get("currentPage") || "1";
  const _currentPage: number = parseInt(page);
  const [currentPage, setCurrentPage] = useState(_currentPage);
  const [limit, setLimit] = useState(10);
  const [searchParams, setSearchParams] = useState<string>("");
  const [statusFilter, setStatusFilter] = useState<string>("");
  const [recruiterFolder, setRecruiterFolder] = useState<boolean>(false);
  const [checkBoxArray, setCheckBoxArray] = useState<Array<string>>([]);
  const { userRoles } = useContext(AppContext);
  const [getCandidatesList, { loading, error, data }] = useLazyQuery(
    CANDIDATES_PRIVATE_LISTING
  );
  const [removePrivateCandidate] = useMutation(REMOVE_PRIVATE_CANDIDATE);
  

  const _getCandidatesList = () => {
    setQueryParams(searchParams);

    if (!searchParams || searchParams?.length > 2) {
      getCandidatesList({
        variables: {
          limit,
          page: currentPage,
          searchText: searchParams || "",
          statusSearch: statusFilter,
        },
      });
    }
  };

  const setQueryParams = (searchParams: string) => {
    const criteria = compactObject({
      currentPage,
      searchParams,
      limit,
      statusFilter,
    });
    const urlQueryParams: any = [];
    if (Object.keys(criteria).length) {
      for (const key in criteria) {
        if (
          Object.prototype.hasOwnProperty.call(criteria, key) &&
          criteria[key]
        ) {
          urlQueryParams.push(`${key}=${criteria[key]}`);
        }
      }
      history.push(`/private-candidates?${urlQueryParams.join("&")}`);
    }
  };

  const searchControl = (value: any) => {
    setQueryParams(value);
    setSearchParams(value);
    setCurrentPage(1);
    setLimit(25);
    if (!value || value?.length > 2) {
      window.clearTimeout(time);
      time = window.setTimeout(() => {
        getCandidatesList({
          variables: {
            limit,
            page: currentPage,
            searchText: value,
            statusSearch: statusFilter,
          },
        });
      }, 1000);
    }
  };

  useEffect(() => {
    document.title = "Private Candidates - RealREPP";

    let params: any = getPrivateCandidatesParams(location.search);

    if (params) {
      setCurrentPage(params?.currentPage ? parseInt(params?.currentPage) : 1);
      setStatusFilter(params?.statusFilter);
      setSearchParams(params?.searchParams);
      setLimit(params?.limit ? parseInt(params?.limit) : 10);
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    _getCandidatesList();

    // eslint-disable-next-line
  }, [statusFilter, currentPage, limit]);

  const handleCheckBoxes = (value: string) => {
    const currentIndex = checkBoxArray.indexOf(value);
    const newChecked = [...checkBoxArray];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setCheckBoxArray(newChecked);
  };

  const onRecruiterFolderToogle = () => {
    setRecruiterFolder(!recruiterFolder);
  };

  const removeCandidate = async () => {
    let res = await removePrivateCandidate({ variables: { candidateId } });
    if (res?.data) {
      //refetchCandidateWriteUp();
      toast.success("Candidate removed successfully!");
      setConfirmToggle(false);
      _getCandidatesList();
    }
  };

  if (loading) {
    return <Loader></Loader>;
  }

  return (
    <div>
      <GoBack url={RECRUITER_FOLDERS_ROUTE} />
      {userRoles?.includes(ROLES.EXECUTIVE) ||
      userRoles?.includes(ROLES.ADMIN) || userRoles?.includes(ROLES.HR) ? (
        <div className="admin-tabs team-tabs">
          <div className="primaryHeading">
            <h5 className="m-0">Private Candidates</h5>
          </div>

          <div className="search-filter primaryHeaderSpacing">
            <div>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <img src={SEARCH_IMG} alt="search" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  type="text"
                  placeholder="Search"
                  value={searchParams}
                  onChange={(e) => {
                    setSearchParams(e.target.value);
                    searchControl(e.target.value);
                  }}
                  className="search-by-company"
                />
              </InputGroup>

              <small className="text-info">
                {searchParams &&
                  searchParams.length <= 2 &&
                  " Please add more than two characters"}
              </small>
            </div>

            <div className="filter-status">
              <div className="filter-by">
                <InputField
                  inputtype="select"
                  selectItems={[
                    { label: "Active", value: "ACTIVE" },
                    { label: "Do Not Contact", value: "DO_NOT_CONTACT" },
                  ]}
                  inputid="status"
                  placeholder="Status"
                  inputMethod={(value: SetStateAction<string>) => {
                    setQueryParams(searchParams);
                    setStatusFilter(value);
                    setCurrentPage(1);
                    setLimit(25);
                  }}
                  inputValue={statusFilter}
                />

                <button
                  className="buttonGenericStyle filledButton ml-3"
                  onClick={() => {
                    setSearchParams("");
                    setStatusFilter("");
                    getCandidatesList({
                      variables: {
                        limit,
                        page: currentPage,
                        searchText: "",
                        statusSearch: "",
                      },
                    });
                  }}
                >
                  Clear
                </button>
              </div>
            </div>
          </div>

          {checkBoxArray?.length ? (
            <div className="w-100 d-flex justify-content-end py-4">
              <>
                <button
                  type="button"
                  onClick={onRecruiterFolderToogle}
                  className="buttonGenericStyle filledButton"
                >
                  Add To Recruiter Folder
                </button>
              </>

            </div>
          ) : null}

          <div className="table-responsive tableResponsiveInitial">
            <TableComponent tableHeader={ACTIVE_WIDGETS_HEADER}>
              {loading && (
                <tbody>
                  <tr>
                    <td colSpan={11}>
                      <Loader loaderClass="sm-height " />
                    </td>
                  </tr>
                </tbody>
              )}
              {!loading && !data?.allPrivateCandidates?.candidates?.length && (
                <tbody>
                  <tr>
                    <td colSpan={11} className="empty-table-td">
                      <NoDataFound text="No Candidate Found!" />
                    </td>
                  </tr>
                </tbody>
              )}
              <tbody>
                {data &&
                  data?.allPrivateCandidates &&
                  // eslint-disable-next-line
                  data?.allPrivateCandidates?.candidates?.map(
                    (item: any, index: number) => {
                      return (
                        <tr key={index}>
                          <td className="cursor-pointer">
                            <Input
                              bsSize="sm"
                              className="m-0 mt-2"
                              type="checkbox"
                              style={{ position: "initial" }}
                              onChange={() => handleCheckBoxes(item?.id)}
                            />
                          </td>

                          <td>
                            <div className="d-flex align-items-center">
                              <div
                                onClick={() => {
                                  history.push(`${VIEW_CANDIDATE_ROUTE}/${item?.id}`);
                                  pushInHistory("/private-candidates", location.search)
                                }}
                              >
                                {item.fullName}
                                {item.isPrivateCandidate && (
                                  <small className="text-success ml-2">
                                    (Private)
                                  </small>
                                )}{" "}
                              </div>
                            </div>

                            <div className="subtitleText">
                              {item?.emails?.length
                                ? item?.emails?.map(
                                    (email) => email.isPrimary && email.email
                                  )
                                : "--"}
                            </div>
                          </td>

                          <td>
                            {item?.jobTitle ||
                              item?.jobTitle?.employmentHistory?.find(
                                (val) => val?.mostRecentExperience
                              )?.jobTitle ||
                              (item?.employmentHistory?.length &&
                                item?.employmentHistory[0]?.jobTitle) ||
                              "--"}
                          </td>

                          <td>
                            {item?.companyName ||
                              (item?.employmentHistory?.length &&
                                item?.employmentHistory?.map(
                                  (val) =>
                                    val.mostRecentExperience && val.companyName
                                )) ||
                              "--"}
                          </td>

                          <td>
                            {item?.city || "--"} {item?.city && ", "}{" "}
                            {item.stateCode || "--"}
                          </td>

                          <td>{item?.recruiterOwner?.fullName ?? "--"}</td>
                          <td className="cand-status-column">
                            <Badge
                              color={
                                item.status === "ACTIVE"
                                  ? "success"
                                  : item.status === "DO_NOT_CONTACT"
                                  ? "danger"
                                  : ""
                              }
                              className="text-capitalize"
                            >
                              {item.status ?? "--"}
                            </Badge>
                          </td>
                          <td>{item?.source ?? "--"}</td>
                          <td>
                            {item?.resume?.length
                              ? formatDateUS(item?.resume[0]?.createdAt)
                              : "--"}
                          </td>

                          <td>
                            <div
                              className="d-flex align-items-center justify-content-start"
                              style={{ width: 150 }}
                            >
                               <PrivateCandidateListingActions 
                                   history={history} location={location} item={item}  setCandidateId={setCandidateId} 
                                   toggle={toggle} setConfirmToggle={setConfirmToggle} confirmToggle ={confirmToggle }                             
                               
                               />  
                            </div>
                          </td>
                        </tr>
                      );
                    }
                  )}
              </tbody>
            </TableComponent>

            {recruiterFolder && checkBoxArray?.length && (
              <CandidateRecruiterModal
                isOpen={recruiterFolder}
                toggle={onRecruiterFolderToogle}
                recordToAdd={checkBoxArray}
                refetch={() => _getCandidatesList()}
                clearRecord={() => setCheckBoxArray([])}
              />
            )}
          </div>

          {data?.allPrivateCandidates?.candidates?.length ? (
            <div className="users-pagination">
              <Pagination
                onPageChange={(pageClicked: number) => {
                  setQueryParams(searchParams);
                  setCurrentPage(pageClicked);
                }}
                pageCount={Math.ceil(data?.allPrivateCandidates?.count / limit)}
                currentPage={currentPage}
              />

              <div className="d-flex align-items-center">
                <div className="pagination-display paginationItemsSelect">
                  <p className="m-0">
                    {(currentPage - 1) * limit + 1} to
                    {Math.min(
                      currentPage * limit,
                      data?.allPrivateCandidates?.count
                    )}{" "}
                    of {data?.allPrivateCandidates?.count}
                  </p>

                  <div className="divider"></div>

                  <label>Display</label>

                  <InputField
                    label=""
                    inputtype="select"
                    selectItems={PAGE_LIMIT}
                    inputid="limit"
                    placeholder={limit !== 10 ? limit.toString() : "Set Limit"}
                    inputMethod={(value: string) => {
                      setQueryParams(searchParams);
                      setCurrentPage(1);
                      setLimit(parseInt(value));
                    }}
                  />
                </div>
              </div>
            </div>
          ) : null}

          {error?.graphQLErrors[0]?.message}
          {modal && (
            <ShareCandidate
              modal={modal}
              toggle={toggle}
              candidateId={candidateId}
              //   refetchCandidateWriteUp={true}
            />
          )}
          <ConfirmPopUp
            confirmText="Are you sure you want to remove candidate?"
            isOpen={confirmToggle}
            toggle={setConfirmToggle}
            confirmAction={removeCandidate}
            modalHeading="Remove Candidate?"
            btnText="Yes, Remove Candidate"
            btnColor="primary"
            className="remove-sharee revampDialog revampDialogWidth"
            loading={loading}
            disabled={loading}
          />
        </div>
      ) : (
        <Page404></Page404>
      )}
    </div>
  );
};

export default PrivateListingCandidate;
