import { useLazyQuery } from '@apollo/client';
import { useEffect, useState, useContext } from 'react';
import { Button, Card, Col, FormGroup, Label, Row } from 'reactstrap';
import InputField from '../../../components/layout/InputField';
import { CompanySource, contactStatus } from '../../../utils/constant';
import { FETCH_All_DropDown_COMPANIES } from '../../company/gql';
import CreateAddressModal from './CreateAddressModal';
import CreateCompanyModal from './CreateCompanyModal';
import { AppContext } from '../../../context';
import '../../company/company.scss';
import { Controller } from 'react-hook-form';
import AsyncCompanyDropdown from '../../../components/AsyncCompanySelect';
import Select from 'react-select';
import { customSelectDropDown } from '../../../components/styled/customSelect';
import ChangeContactCompanyModal from './ChangeContactCompanyModal';

const BasicContactInfo = ({
  register,
  errors,
  setValue,
  _company,
  location,
  control,
  makeCompanyReadOnly = false,
  isPreferredContact,
  ...props
}: any) => {
  const { theme } = useContext(AppContext);
  const [getCompanies, { data }] = useLazyQuery(FETCH_All_DropDown_COMPANIES);
  const [addressList, setAddressList] = useState<Array<any>>([]);
  const [companyId, setCompanyId] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [companyModal, setCompanyModal] = useState(false);
  const [changeCompanyPop, setChangeCompanyPop] = useState(false);
  const [isPreferred, setIsPreferred] = useState(isPreferredContact);
  const companyToggle = () => setCompanyModal(!companyModal);

  const changeCompany = (value: any) => {
    setCompanyId(value);
    let companyAddress = data?.companiesForDropDown?.companies?.find(
      (item: any) => item?.id === value
    );
    if (companyAddress && companyAddress?.address) {
      let addressList = companyAddress?.address.map((v: any) => ({
        label: ['US', null, undefined, ''].includes(v?.country) ? `${v?.streetAddress}, ${v?.city}, ${v?.state}, ${v?.zipCode}, ${v?.country}` : `${v?.streetAddress}, ${v?.city}, ${v?.zipCode}, ${v?.country}`,
        value: v.id,
      }));

      setAddressList(addressList);
    }
  };

  useEffect(() => {
    if (props?.getValues()?.companyId) {
      let company = props?.getValues()?.companyId;

      setCompanyId(company?.value);
      setCompanyName(company?.label);
      changeCompany(company?.value);
      setIsPreferred(isPreferredContact);
    }
    // eslint-disable-next-line
  }, [props?.getValues()?.companyId]);

  useEffect(() => {
    CompanySource.sort(function (a, b) {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [CompanySource]);

  const fetchCompanies = () => {
    getCompanies({
      variables: {
        limit: 20,
        page: 1,
        searchText: companyName,
      },
    });
  };

  useEffect(() => {
    fetchCompanies();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      companyId &&
      data?.companiesForDropDown?.companies?.length &&
      companyName
    ) {
      let company = data?.companiesForDropDown?.companies?.find(
        (item) => item.id === companyId
      );

      if (company) {
        changeCompany(company?.id);
      } else {
        getCompanies({
          variables: {
            limit: 20,
            page: 1,
            searchText: companyName,
          },
        });
      }
    }
    // eslint-disable-next-line
  }, [companyId, companyName, data]);

  const preSelectAddress = (location) => {
    setValue('locationId', {
      label: ['US', null, undefined, ''].includes(location?.country) ? `${location?.streetAddress}, ${location?.city}, ${location?.state}, ${location?.zipCode}, ${location?.country}` : `${location?.streetAddress}, ${location?.city}, ${location?.zipCode}, ${location?.country}`,
      value: location?.id,
    });
  };

  const preSelectCompany = (company) => {
    setValue('companyId', {
      label: company?.name,
      value: company?.id,
    });
    changeCompany(company?.id);
    setCompanyName(company?.name);
    setAddressList([]);
    setValue('locationId', null);
  };

  return (
    <div>
      <Card className="border-0 mb-2">
        <div className="user-basic-form">
          <Row>
            <Col lg="4" sm="12" xs="12">
              <InputField
                inputtype="text"
                isRequired={true}
                label="First Name"
                inputid="firstName"
                placeholder="First Name"
                inputRef={register({
                  required: {
                    value: true,
                    message: 'First name is required!',
                  },
                })}
                errMsg={errors?.firstName?.message}
              />
            </Col>

            <Col lg="4" sm="12" xs="12">
              <InputField
                inputtype="text"
                isRequired={true}
                label="Last Name"
                inputid="lastName"
                placeholder="Last Name"
                inputRef={register({
                  required: {
                    value: true,
                    message: 'Last name is required!',
                  },
                })}
                errMsg={errors?.lastName?.message}
              />
            </Col>

            <Col lg="4" sm="12" xs="12">
              <InputField
                inputtype="text"
                isRequired={true}
                label="Job Title"
                inputid="jobTitle"
                placeholder="job Title"
                inputRef={register({
                  required: {
                    value: true,
                    message: 'Job title is required!',
                  },
                })}
                errMsg={errors?.jobTitle?.message}
              />
            </Col>
            {props?.isEditable && (
              <Col lg="4" sm="12" xs="12">
                <InputField
                  label="Status"
                  inputtype="select"
                  selectItems={contactStatus}
                  inputid="status"
                  placeholder="Status"
                  inputRef={register({ required: false })}
                />
              </Col>
            )}
            <Col lg="4" sm="12" xs="12">
              {props?.isEditable ? (
                <InputField
                  label="Source"
                  isRequired={true}
                  readOnly
                  inputtype="text"
                  // selectItems={CompanySource}
                  inputid="source"
                  placeholder="Source"
                  inputRef={register({
                    required: { value: true, message: 'Source is required!' },
                  })}
                  errMsg={errors?.source?.message}
                />
              ) : (
                <InputField
                  label="Source"
                  isRequired={true}
                  inputtype="select"
                  selectItems={CompanySource}
                  inputid="source"
                  placeholder="Source"
                  inputRef={register({
                    required: { value: true, message: 'Source is required!' },
                  })}
                  errMsg={errors?.source?.message}
                />
              )}
            </Col>

            <Col lg="4" sm="12" xs="12">
              <Label>
                Company <span className="text-danger">*</span>
              </Label>
              <Controller
                name="companyId"
                rules={{
                  required: {
                    value: true,
                    message: 'Please select a company!',
                  },
                }}
                render={({ onChange }) => (
                  <AsyncCompanyDropdown
                    width={'100%'}
                    ownershareeFlag={true}
                    placeholder="Filter By Company name"
                    value={props?.getValues()?.companyId}
                    isDisabled={makeCompanyReadOnly}
                    onChange={(value) => {
                      if (value) {
                        setCompanyName(value?.label);
                        changeCompany(value?.value);
                        setValue('locationId', null);
                      }
                      onChange(value);
                    }}
                    ownerIdFlag={true}
                  />
                )}
                control={control}
                styles={{ ...customSelectDropDown(theme) }}
              />

              <div className="d-flex justify-content-between mt-2">
                <small className="text-danger">
                  {errors?.companyId?.message}
                </small>
                {!makeCompanyReadOnly && (
                  <Button
                    type="button"
                    onClick={companyToggle}
                    className="bg-transparent border-0 text-info p-0"
                  >
                    Create New Company
                  </Button>
                )}
                {props?.isEditable && (
                  <Button
                    type="button"
                    onClick={() => setChangeCompanyPop(!changeCompanyPop)}
                    className="bg-transparent border-0 text-info p-0"
                  >
                    Change Company
                  </Button>
                )}
              </div>
            </Col>

            <Col lg="4" sm="12" xs="12" className="create-new-company">
              <FormGroup>
                <Label>Location</Label>
                <Controller
                  name="locationId"
                  as={Select}
                  rules={register({
                    required: {
                      value: true,
                      message: 'Must select location!',
                    },
                  })}
                  options={addressList}
                  control={control}
                  placeholder="Location"
                  isSearchable={true}
                  styles={{ ...customSelectDropDown(theme) }}
                />
                <small className="text-danger pt-2">
                  {errors?.locationId?.message}
                </small>
              </FormGroup>
              <div className="d-flex justify-content-end mt-2">
                <Button
                  className="bg-transparent border-0 text-info p-0"
                  onClick={toggle}
                  type="button"
                  disabled={companyId ? false : true}
                  title={
                    !companyId ? 'Kindly select a company for new address!' : ''
                  }
                >
                  Create New Address
                </Button>
              </div>
            </Col>

            <Col lg="4" sm="12" xs="12">
              <FormGroup>
                <Label for="preferredContact">Preferred Contact</Label>
                <div className="d-flex items-center" style={{ marginTop: '5px' }}>
                  <Label className="radio-container">
                    Preferred Contact
                    <input
                      type="checkbox"
                      id="preferredContact"
                      name="preferredContact"
                      ref={register()}
                      checked={isPreferred}
                      onChange={(e) => {
                        setIsPreferred(e.target.checked);
                      }}
                    />
                    <span className="checkmark"></span>
                  </Label>
                </div>
              </FormGroup>
            </Col>
          </Row>
        </div>
      </Card>

      <CreateAddressModal
        open={modal}
        toggle={toggle}
        companyId={companyId}
        preSelect={preSelectAddress}
      />
      <CreateCompanyModal
        open={companyModal}
        toggle={companyToggle}
        preSelectCompany={preSelectCompany}
      />

      <ChangeContactCompanyModal
        toggle={() => setChangeCompanyPop(!changeCompanyPop)}
        isOpen={changeCompanyPop}
        fetchContactDetail={props?.fetchContactDetail}
        contactId={props?.match?.params?.id}
      />
    </div>
  );
};

export default BasicContactInfo;
