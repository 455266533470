import { useMutation } from '@apollo/client';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import {
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  Row,
} from 'reactstrap';
import CustomButton from '../../../components/layout/CustomButton';
import InputField from '../../../components/layout/InputField';
import {
  moneyFieldHandler,
  commaRemover,
  localStringMaker,
} from '../../../utils/helper';
import {
  SUBMISSION_STATUS_CHANGE,
  UPDATE_INTERVIEW_STATUS,
} from '../interviews/gql';
import { CREATE_OFFER, UPDATE_OFFER } from './gql';
import {
  CreateOfferDTO,
  CreateOfferInput,
  UpdateOfferDTO,
} from './offerInterface';
import {
  Sidedrawer,
  DrawerHeader,
} from '../../../components/layout/Sidedrawer';
import moment from 'moment';

export default function CreateEditOffer({
  offerToggle,
  fromUpdate,
  counterOffer,
  latestOffer,
  interviewId,
  setCandidateFilter,
  setOfferToggle,
  applicantData,
  refetch,
  parentToggle,
}) {
  const { errors, handleSubmit, register, reset, setValue } = useForm();
  const [_addOffer, { loading: _addOfferLoading }] = useMutation(CREATE_OFFER);
  const [updateApplicantsStatusSubmission] = useMutation(
    SUBMISSION_STATUS_CHANGE
  );
  const [updateInterviewStausMutation] = useMutation(UPDATE_INTERVIEW_STATUS);

  const [_updateOffer, { loading: _updateOfferLoading }] =
    useMutation(UPDATE_OFFER);

  const addOffer = async (values: CreateOfferInput) => {
    const offeredAmount = commaRemover(values.offeredSalary);
    const obj: CreateOfferDTO = {
      jobApplicantId: applicantData?.id,
      counterOffer,
      offeredSalary: offeredAmount,
      hireType: values.hireType,
      offeredSalaryType:
        values.offeredSalaryType === 'ANNUAL' ? 'ANNUALLY' : 'HOURLY',
      offerStartDateTime: values.offerStartDateTime,
      offerStartDateTimeString: values.offerStartDateTime,
      details: values.details,
    };
    const offerSaving = await _addOffer({
      variables: {
        createOfferDTO: obj,
      },
    });

    if (offerSaving) {
      if (!counterOffer) {
        // Updating the interview's status to MOVE_TO_OFFER
        if (interviewId) {
          await updateInterviewStausMutation({
            variables: {
              id: interviewId,
              candidateId: applicantData?.candidate?.id,
              status: 'MOVE_TO_OFFER',
            },
          });
        }

        await updateApplicantsStatusSubmission({
          variables: { id: applicantData?.id, status: 'OFFERED' },
        });
      }
      setOfferToggle();
      setCandidateFilter([{ label: 'Offered', value: 'Offered' }]);
      refetch();
      parentToggle();
      toast.success(
        `${
          counterOffer
            ? 'Offer is added successfully'
            : 'Offer is added successfully, also the applicant is moved to offered too'
        }`
      );
    }
  };

  const editOffer = async (values: CreateOfferInput) => {
    const offeredAmount = commaRemover(values.offeredSalary);

    const obj: UpdateOfferDTO = {
      jobApplicantId: applicantData?.id,
      offerId: latestOffer?.id,
      offeredSalary: offeredAmount,
      hireType: values.hireType,
      offeredSalaryType:
        values.offeredSalaryType === 'ANNUAL' ? 'ANNUALLY' : 'HOURLY',
      offerStartDateTime: values.offerStartDateTime,
      offerStartDateTimeString: values.offerStartDateTime,
      details: values.details,
    };

    const res = await _updateOffer({
      variables: {
        updateOfferDTO: obj,
      },
    });

    if (res?.data?.updateOffer?.status === 200) {
      setCandidateFilter([{ label: 'Offered', value: 'Offered' }]);
      refetch();
      toast.success('Offer is updated successfully');
      setOfferToggle();
    }
  };

  const editOrAddOffer = async (values: CreateOfferInput) => {
    if (!fromUpdate) {
      addOffer(values);
    } else {
      editOffer(values);
    }
  };

  useEffect(() => {
    if (fromUpdate) {
      let offerValue = {
        jobApplicantId: applicantData?.id,
        offeredSalary: localStringMaker(latestOffer?.offeredSalary),
        hireType: latestOffer?.hireType,
        offeredSalaryType:
          latestOffer?.offeredSalaryType === 'ANNUALLY' ? 'ANNUAL' : 'HOURLY',
        offerStartDateTime: moment(latestOffer?.dateString).format(
          'YYYY-MM-DD'
        ),
        details: latestOffer?.details,
      };
      reset({ ...offerValue });
    } else {
      let offerValue = {
        offeredSalaryType: applicantData?.JobOrder?.rateType,
        hireType: applicantData?.JobOrder?.workType,
      };
      reset({ ...offerValue });
    }

    // eslint-disable-next-line
  }, [fromUpdate, applicantData]);

  return (
    <Sidedrawer toggle={() => {}} isOpen={offerToggle}>
      <div className="mb-4">
        <DrawerHeader
          prevClick={setOfferToggle}
          heading={
            fromUpdate
              ? 'Update Offer'
              : counterOffer
              ? 'Create Counter Offer'
              : 'Add Offer'
          }
          nextButtonView={false}
        />
      </div>
      <Form onSubmit={handleSubmit(editOrAddOffer)}>
        <Row>
          <Col md="12" className="pb-3">
            <Label className="text-14 text-blackele pb-3">Salary Type</Label>
            <div className="relocate-allowed">
              <FormGroup check className="p-0">
                <Label className="radio-container radio-font">
                  Annually
                  <Input
                    type="radio"
                    name="offeredSalaryType"
                    value="ANNUAL"
                    innerRef={register()}
                  />
                  <span className="checkmark"></span>
                </Label>
              </FormGroup>

              <FormGroup check>
                <Label className="radio-container radio-font">
                  Hourly
                  <Input
                    type="radio"
                    value="HOURLY"
                    name="offeredSalaryType"
                    innerRef={register()}
                  />
                  <span className="checkmark"></span>
                </Label>
              </FormGroup>
            </div>
          </Col>

          <Col
            md="12"
            className="dollar-addon-btn pb-3  custom-form-input form-input "
          >
            <FormGroup>
              <Label className="text-14 text-blackele pb-3">
                Salary Amount
                <span className="text-danger">*</span>
              </Label>

              <InputGroup>
                <InputGroupAddon addonType="prepend" className='dark-theme-border'>$</InputGroupAddon>
                <Input
                  onChange={(e) => {
                    const reg = new RegExp(
                      // eslint-disable-next-line no-useless-escape
                      /^[1-9]\d*(\,\d{0,9})*(\.\d{0,9})?$/
                    );
                    if (reg.test(e?.target?.value)) {
                      moneyFieldHandler(
                        e?.target?.value,
                        setValue,
                        'offeredSalary'
                      );
                    }
                  }}
                  // value={getValues()?.offeredSalary}
                  name="offeredSalary"
                  autoComplete="off"
                  type="text"
                  innerRef={register({
                    required: {
                      value: true,
                      message: 'Salary amount is required!',
                    },
                    pattern: {
                      // eslint-disable-next-line no-useless-escape
                      value: /^[1-9]\d*(\,\d{0,9})*(\.\d{0,9})?$/,
                      message: 'Invalid Salary Amount',
                    },
                  })}
                  errMsg={errors?.offeredSalary?.message}
                  className="pl-2"
                />
              </InputGroup>

              <small className="text-danger">
                {errors?.offeredSalary?.message}
              </small>
            </FormGroup>
          </Col>

          <Col md="12" className="pb-3 ">
            <Label className="text-14 text-blackele pb-3">Hire Type</Label>
            <div className="relocate-allowed">
              <FormGroup check className="p-0">
                <Label className="radio-container radio-font">
                  Direct Hire
                  <Input
                    type="radio"
                    name="hireType"
                    value="DIRECT_HIRE"
                    innerRef={register()}
                  />
                  <span className="checkmark"></span>
                </Label>
              </FormGroup>

              <FormGroup check>
                <Label className="radio-container radio-font">
                  Temp
                  <Input
                    type="radio"
                    value="TEMP"
                    name="hireType"
                    innerRef={register()}
                  />
                  <span className="checkmark"></span>
                </Label>
              </FormGroup>
            </div>
          </Col>

          <Col lg="12" className="pb-3  custom-form-input form-input">
            <Label className="text-14 text-blackele pb-3">
              Start Date<span className="text-danger">*</span>
            </Label>
            <Input
              type="date"
              name="offerStartDateTime"
              min={new Date().toISOString().substring(0, 10)}
              innerRef={register({
                required: {
                  value: true,
                  message: 'Start date is required!',
                },
              })}
            />
            <small className="text-danger">
              {errors?.offerStartDateTime?.message}
            </small>
          </Col>

          <Col
            lg="12"
            className="mt-1 removeSpacingFormGroup  custom-form-input form-input"
          >
            <Label className="text-14 text-blackele pb-3">
              Details<span className="text-danger">*</span>
            </Label>

            <InputField
              style={{ height: 90 }}
              inputtype="textarea"
              label="Details"
              inputid="details"
              placeholder="details"
              isRequired={true}
              isSidebarField={true}
              inputRef={register({
                required: { value: true, message: 'Detail is required' },
              })}
              errMsg={errors?.details?.message}
            />
          </Col>
        </Row>
        <div className="d-flex justify-content-end align-items-center mt-3">
          <CustomButton
            buttonColor="primary"
            buttonText={`${fromUpdate ? 'Update' : 'Create'} Offer`}
            className="big-width m-0"
            loading={_updateOfferLoading || _addOfferLoading}
            disabled={_updateOfferLoading || _addOfferLoading}
          />
        </div>
      </Form>
    </Sidedrawer>
  );
}
