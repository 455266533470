import { FC } from 'react';
import { BotIcon } from '../../assets/images/svgs';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { IChatgptButton } from '../../interfaces';

const ChatgptButton: FC<IChatgptButton> = ({ title, menu, onClick }) => {
  return (
    <div className="gpt-btn">
      <UncontrolledDropdown className="customMenuDropdown">
        <DropdownToggle caret>
          <BotIcon />
          {title}
        </DropdownToggle>

        <DropdownMenu right>
          {menu?.length &&
            menu.map((item, index) => (
              <DropdownItem
                key={index}
                className="bg-transparent border-0 color-default btn-sm"
                onClick={() => {
                  onClick(index);
                }}
              >
                {item}
              </DropdownItem>
            ))}
        </DropdownMenu>
      </UncontrolledDropdown>
    </div>
  );
}

export default ChatgptButton;
