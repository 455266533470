import { useLazyQuery, useMutation } from '@apollo/client';
import { Key, useEffect, useState } from 'react';
import { Link, RouteComponentProps, useHistory } from 'react-router-dom';
import { FETCH_NEW_NOTIFICATIONS, MARK_AS_READ } from '../notifications/gql';
import { Button, Card, CardBody } from 'reactstrap';
import { formatDateUS } from '../../utils/commonFn';
import PreviewCandidateSubmitEmail from '../notifications/components/PreviewCandidateSubmitEmail';
import { notificationJsonTruth } from '../../utils/helper';
import { messageParsing } from '../../utils/Temp-Helper';
import {
  COMPANY_OWNERSHIP_REPORT_ROUTE,
  VIEW_ACCOUNTING_DASHBOARD_ROUTE,
  VIEW_AGREEMENT_ROUTE,
  VIEW_CANDIDATE_ROUTE,
  VIEW_JOB_ORDER_ROUTE,
  VIEW_PLACEMENT_MANAGEMENT_ROUTE,
} from '../../utils/constant';

const UnreadNotifications = ({ location }: RouteComponentProps) => {
  const page = new URLSearchParams(location.search).get('page') || '1';
  const _currentPage: number = parseInt(page);
  const [currentPage] = useState(_currentPage);
  const [limit] = useState(10);
  const history = useHistory();
  const [searchParams] = useState<string>('');
  const [statusFilter] = useState<string>('');
  const [previewModal, setPreviewModal] = useState<any>(false);
  const [notifyBackId] = useState<string>('');
  const [responseSubmit] = useState<string>('');
  const [submissionID] = useState<string>('');

  const [
    fetchAllNewNotifications,
    { error: newNotificationsError, data: newNotifications },
  ] = useLazyQuery(FETCH_NEW_NOTIFICATIONS);

  // Mutations
  const [_markAsRead] = useMutation(MARK_AS_READ);

  const fetchNewtNotifications = () => {
    try {
      fetchAllNewNotifications({
        variables: {
          limit,
          page: currentPage,
          searchText: searchParams,
          alertStatus: statusFilter,
        },
      });
    } catch (error) {}
  };

  const previewHandler = (item: {
    notifyableType?: string;
    alertType: string;
    alertMsg: any;
    createdAt?: Date | string;
    id: string;
  }) => {
    markAsReadHandler(item?.id);

    if (item?.alertType === 'PLACEMENT_REMINDER') {
      const infoExtractor = JSON.parse(item?.alertMsg);
      const placementId = infoExtractor.placementId;
      if (infoExtractor?.to === 'ACCEPT_REJECT_EXECUTIVE') {
        history.push({
          pathname: `${VIEW_ACCOUNTING_DASHBOARD_ROUTE}/${placementId}`,
        });
      } else if (
        infoExtractor?.to === 'ACCEPT_REJECT_MANAGER' ||
        infoExtractor?.to === 'ACCEPT_REJECT_AM' ||
        infoExtractor?.to === 'UPDATE'
      ) {
        history.push({
          pathname: `${VIEW_PLACEMENT_MANAGEMENT_ROUTE}/${placementId}`,
        });
      }
    } else if (
      item?.alertType === 'CLIENT_CHECKUP_REMINDER' &&
      item?.notifyableType === 'JobOrder'
    ) {
      const infoExtractor = JSON.parse(item?.alertMsg);
      const jobOrderId = infoExtractor?.jobOrderId;
      history.push({
        pathname: `${VIEW_JOB_ORDER_ROUTE}/${jobOrderId}`,
      });
    } else if (item?.alertType === 'SEND_AGREEMENT_CHECKUP') {
      const infoExtractor = JSON.parse(item?.alertMsg);
      const agreementId = infoExtractor?.agreementId;
      history.push({
        pathname: `${VIEW_AGREEMENT_ROUTE}/${agreementId}`,
      });
    } else if (item?.alertType === 'INFORMING_AGREEMENT_CHECKUP') {
      const infoExtractor = JSON.parse(item?.alertMsg);
      const agreementId = infoExtractor?.agreementId;

      history.push({
        pathname: `${VIEW_AGREEMENT_ROUTE}/${agreementId}`,
      });
    }else if (item?.alertType === 'OWNERSHIP_EXPIRATION') {
      history.push({
        pathname: `${COMPANY_OWNERSHIP_REPORT_ROUTE}`,
      });
    } else if (item?.alertType === 'INTERVIEW_INVITE_CHECKUP') {
      const infoExtractor = JSON.parse(item?.alertMsg);
      const candidateId = infoExtractor.candidate;

      history.push({
        pathname: `${VIEW_CANDIDATE_ROUTE}/${candidateId}`,
        state: {
          backUrl: `/notifications`,
          landedFrom: `pipeline`,
        },
        search:
          '?' +
          new URLSearchParams({
            applicantId: infoExtractor?.jobApplicantId,
            activeTab: infoExtractor?.activeTab,
          }).toString(),
      });
    } else {
      const infoExtractor = JSON.parse(item?.alertMsg);
      history.push({
        pathname: `${VIEW_CANDIDATE_ROUTE}/${infoExtractor?.candidateId}`,
        state: {
          backUrl: `/notifications`,
          landedFrom: `pipeline`,
        },
        search:
          '?' +
          new URLSearchParams({
            applicantId: infoExtractor?.applicantId,
            activeTab: infoExtractor?.activeTab,
          }).toString(),
      });
    }
  };

  const markAsReadHandler = async (id: string) => {
    await _markAsRead({
      variables: { notificationInput: { id } },
    });

    fetchNewtNotifications();
  };

  useEffect(() => {
    fetchNewtNotifications();

    // eslint-disable-next-line
  }, []);

  return (
    <div className="admin-tabs team-tabs">
      {newNotifications?.allNewNotifications?.notifications?.length ? (
        <Card className="border-0 mt-section-4">
          <div className="heading-border">
            <h5>Unread Reminders</h5>

            <Link to="/notifications" className="link-tag">
              View All
            </Link>
          </div>

          <CardBody className="p-0">
            <div className="user-basic-form">
              {newNotifications?.allNewNotifications?.notifications?.map(
                (
                  newNotification: {
                    notifyableType: string;
                    alertType: string;
                    alertMsg: any | null | undefined;
                    createdAt: string | any;
                    id: string;
                  },
                  index: Key
                ) => {
                  return (
                    <div className="notification-card" key={index}>
                      <div>
                        <span className='message'>{messageParsing(newNotification)}</span>
                        <p>
                          {formatDateUS(newNotification.createdAt)}

                          {'   '}
                          {new Date(
                            newNotification.createdAt
                          ).toLocaleTimeString()}
                        </p>
                      </div>
                      <div>
                        <Button
                          color="secondary"
                          className="mr-2"
                          onClick={() => markAsReadHandler(newNotification?.id)}
                        >
                          Mark As Read
                        </Button>

                        {notificationJsonTruth(
                          newNotification?.notifyableType,
                          newNotification?.alertType
                        ) ? (
                          <Button
                            color="primary"
                            onClick={() => previewHandler(newNotification)}
                          >
                            Manage
                          </Button>
                        ) : null}
                      </div>
                    </div>
                  );
                }
              )}

              {newNotifications?.allNewNotifications?.notifications.length ===
              0 ? (
                <div className="notification-card">
                  <p className="m-0">No unread notifications</p>
                </div>
              ) : null}
            </div>
          </CardBody>
        </Card>
      ) : null}
      {previewModal && (
        <PreviewCandidateSubmitEmail
          open={previewModal}
          toggle={setPreviewModal}
          submissionId={submissionID}
          notifyBackId={notifyBackId}
          alertMsg={responseSubmit}
        />
      )}
      {newNotificationsError?.graphQLErrors[0]?.message}
    </div>
  );
};

export default UnreadNotifications;
