import { useContext } from "react";
import Select from "react-select";
import { Col, Label } from "reactstrap";
import { customSelectDropDown } from "../../../components/styled/customSelect";
import { AppContext } from "../../../context";
import "../notes-search.scss";
import { SelectLabelValue } from "../NotesSearchIndex";

export default function SelectOptionIndex({
  label,
  onUnderSelect,
  setSelectedValue,
  value,
  options,
  loading,
  defaultValue,
  toggle,
  underSelect,
  disableSelect,
}) {
  // const history = useHistory();
  // const { id }: any = useParams();
  const { theme } = useContext(AppContext)
  
  

  return (
    <Col xl="4" md="6" sm="12">
      <Label>{label}</Label>
      <Select
        isDisabled={disableSelect}
        isClearable
        isLoading={loading}
        value={value || defaultValue}
        onChange={(selectedOption: SelectLabelValue, triggeredAction) => {
          if (triggeredAction.action === "clear") {
            setSelectedValue({
              label: "",
              value: "",
            });
          } else {
            setSelectedValue(selectedOption);
            // history?.push(
            //   // `/notes-es/${id}?from=${selectedOption?.value?.toLocaleLowerCase()}`
            // );
          }
        }}
        options={options}
        styles={{ ...customSelectDropDown(theme) }}
      />

      {!disableSelect ? (
        <>
          {underSelect === "recruiters" ||
          underSelect === "companies" ||
          underSelect === "contacts" ||
          underSelect === "jobs" ||
          underSelect === "candidates" ? (
            <span
              className="float-right span-notes"
              onClick={() => {
                toggle();
                onUnderSelect(underSelect);
              }}
            >
              {underSelect}
            </span>
          ) : null}
        </>
      ) : null}
    </Col>
  );
}
