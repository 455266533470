import { useMutation } from "@apollo/client";
import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../../components/layout/Loader";
import { SETTINGS_ROUTE } from "../../utils/constant";
import { DOCUSIGN_LOGIN } from "./graphql";
const DocusSign = () => {
  const location = useLocation();
  const history = useHistory();

  const [docusignLogin, { loading }] = useMutation(DOCUSIGN_LOGIN);

  const getAccessToken = async (code) => {
    let res = await docusignLogin({ variables: { code } });

    if (res.data?.docusignToken?.status === 200) {
      toast?.success("Successfully Login!");
      history.push({ pathname: SETTINGS_ROUTE });
    } else {
      toast.error("Error while Siging in!");
    }
  };
  useEffect(() => {
    const code = new URLSearchParams(location?.search).get("code");
    if (code) {
      getAccessToken(code);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return <Loader />;
  }
  return <div>DocuSign</div>;
};
export default DocusSign;
