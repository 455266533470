import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Button,
  Card,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  Spinner,
} from 'reactstrap';

import InputField from '../../../components/layout/InputField';
import CustomButton from '../../../components/layout/CustomButton';
import MultiSelectMembers from '../../../components/layout/MultiSelectMembers';

import TeamImage from '../../../assets/images/team.svg';
import avatarSvg from '../../../assets/images/user-empty.svg';
import AvatarImage from '../../../assets/images/user-empty.svg';
import CloseImage from '../../../assets/images/close.svg';

import './style.scss';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  ACTIVE_DEACTIVE_TEAM,
  ADD_TEAM_MEMBERS,
  FETCHING_NOT_TERMINATED_USERS,
  FETCH_TEAM_DETAIL,
  REMOVE_TEAM_MEMBER,
  SET_TEAM_MANAGER,
  UPDATE_TEAM,
} from '../gql';
import { useForm } from 'react-hook-form';
import TableComponent from '../../../components/layout/TableComponent';
import ConfirmPopUp from '../../../components/ConfirmPopUp';
import Loader from '../../../components/layout/Loader';
import UploadImage from '../../../components/layout/UploadImage.tsx';
import { toast } from 'react-toastify';
import { formatDateUS } from '../../../utils/commonFn';
import { USERTYPE } from '../users/User-data-type';
import { uploadImage } from '../../../utils/helper';
import GoBack from '../../../components/GoBack';
import { bucketFolders } from '../../../utils/constant';

import CLOSE_ICON from '../../../assets/images/close-icon.svg';

const TABLE_HEADER = ['Name', 'Job Title', 'Started At', 'Manager', 'Actions'];

const AddEditTeam = ({ ...props }): JSX.Element => {
  const [isSelectToggle, setIsSelectToggle] = useState<boolean>(false);
  const [listUsers, setListUsers] = useState<any>();
  const [fetchTeamDetail, { data: teamDetail, loading: fetchLoading }] =
    useLazyQuery(FETCH_TEAM_DETAIL);
  const [updateTeamStatus, { loading: activeLoading }] =
    useMutation(ACTIVE_DEACTIVE_TEAM);
  const [updateTeamInfo, { loading: updateLoading }] = useMutation(UPDATE_TEAM);
  const { data: users } = useQuery(FETCHING_NOT_TERMINATED_USERS);
  const { errors, handleSubmit, register, reset } = useForm();
  const [teamId, setTeamId] = useState(null);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [selectedMember, setSelectedMemeber] = useState<Array<any>>([]);
  const [addTeamMembers, { loading }] = useMutation(ADD_TEAM_MEMBERS);
  const [removeTeamMember] = useMutation(REMOVE_TEAM_MEMBER);
  const [removeMember, setRemoveMember] = useState<{
    fullName: string | null;
    id: string | null;
  }>({ fullName: null, id: null });
  const [setTeamManager, { loading: managerLoading }] =
    useMutation(SET_TEAM_MANAGER);
  const [managerConfirm, setManagerConfirm] = useState<boolean>(false);
  const [managerDetail, setManagerId] = useState<{
    fullName: string | null;
    id: string | null;
  }>({ fullName: null, id: null });

  const [modal, setModal] = useState(false);
  const toggle = () => {
    setModal(!modal);
    setSelectedMemeber([]);
  };

  const [modalDelete, setModalDelete] = useState(false);
  const toggleDelete = () => setModalDelete(!modalDelete);
  const [deleteTeamToggle, setDeleteTeamToggle] = useState<boolean>(false);
  const [logo, setLogo] = useState<any>();
  const [previewURL, setPreviewURL] = useState<any>();
  const [searchingText, setSearchingText] = useState<string>('');

  const udpateTeam = async (values: { name: string; logoUrl: string }) => {
    try {
      if (logo) {
        setUploadLoading(true);
        let response = await uploadImage(
          logo,
          bucketFolders.team,
          false,
          false,
          true
        );
        setUploadLoading(false);
        if (!response.success && !response?.ok) {
          toast.error(response?.message || 'Error while uploading image');
          setLogo(null);
          setPreviewURL(null);
          return;
        }
        if (response.success) {
          Object.assign(values, { logoUrl: response.source });
        }
      }
      await updateTeamInfo({ variables: { ...values, id: teamId } });
      toast.success('Updated successfully!');
    } catch (error: any) {
      setUploadLoading(false);
      toast.error(error.message || 'Error while updating team details.');
    }
  };

  const removeTeamMembers = async () => {
    try {
      await removeTeamMember({
        variables: {
          userId: removeMember.id,
          teamId: teamDetail?.findTeamById?.id,
        },
      });
      toast.success('Removed successfully!');
      toggleDelete();
      fetchTeamDetail({ variables: { id: teamId } });
    } catch (error) {
      console.log(error);
    }
  };

  const changeTeamStatus = async (value: string) => {
    try {
      await updateTeamStatus({
        variables: { teamId, status: value },
      });
      if (deleteTeamToggle) {
        setDeleteTeamToggle(false);
        toast.success('Deleted successfully!');
        props.history.push('/admin/teams');
      } else {
        toast.success('Updated successfully!');
        fetchTeamDetail({ variables: { id: teamId } });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const addOrRemoveMember = (user: USERTYPE) => {
    setSelectedMemeber([...selectedMember, user]);
    let value: any =
      selectedMember &&
      selectedMember.find((member: any) => member.id === user.id);
    if (value) {
      let newUser = selectedMember.filter((item: any) => item.id !== value?.id);
      setSelectedMemeber(newUser);
    }
    setIsSelectToggle(false);
    setSearchingText('');
  };

  const updateTeamMembers = async () => {
    try {
      const variables = { teamId, members: selectedMember };
      await addTeamMembers({ variables });
      toast.success('Members added successfully!');
      toggle();
      fetchTeamDetail({ variables: { id: teamId } });
    } catch (error) {
      console.log(error);
    }
  };

  const filterByReference = (arr1: any, arr2: any) => {
    let res = [];
    res = arr1?.filter((el: { id: string }) => {
      return !arr2?.find((element: { user: { id: string } }) => {
        return element.user.id === el.id;
      });
    });
    return res;
  };

  useEffect(() => {
    if (teamDetail?.findTeamById) {
      if (teamDetail?.findTeamById?.teamUsers) {
        const result = filterByReference(
          users?.allActiveUsers,
          teamDetail?.findTeamById?.teamUsers
        );

        setListUsers(result);
      } else {
        setListUsers(users?.allActiveUsers);
      }

      reset({ ...teamDetail?.findTeamById });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamDetail?.findTeamById, users?.allActiveUsers, searchingText, reset]);

  useEffect(() => {
    const id = props.match.params.id;
    setTeamId(id);
    fetchTeamDetail({ variables: { id } });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchTeamDetail, props.match.params.id]);

  const _setTeamManager = async () => {
    try {
      await setTeamManager({
        variables: { teamUserId: managerDetail.id, teamId },
      });
      toast.success('Updated successfully!');
      setManagerConfirm(false);
      fetchTeamDetail({ variables: { id: teamId } });
    } catch (error) {
      console.log(error);
    }
  };

  if (fetchLoading) {
    return <Loader />;
  }

  return (
    <div className="admin-tabs team-tabs">
      <GoBack url="/admin/teams" />

      <div className="primaryHeading">
        <h5 className="m-0">Teams</h5>
      </div>

      <div className="revampContainer primaryHeaderSpacing d-flex justify-content-between align-items-center">
        <UploadImage
          setPreviewURL={setPreviewURL}
          setImage={setLogo}
          src={previewURL || teamDetail?.findTeamById?.logoUrl || TeamImage}
        />

        <div>
          <div className="d-flex">
            {!teamDetail?.findTeamById?.activatedAt ? (
              <button
                className="activateButton buttonGenericStyle mr-3"
                onClick={() => changeTeamStatus('active')}
                disabled={teamDetail?.findTeamById?.activatedAt ? true : false}
              >
                {activeLoading && <Spinner size="sm" className="mr-1" />}
                Activate
              </button>
            ) : (
              ''
            )}

            <button
              type="button"
              onClick={() => {
                setDeleteTeamToggle(true);
              }}
              className="buttonGenericStyle filledButton"
            >
              Delete
            </button>
          </div>

          {!teamDetail?.findTeamById?.archivedAt &&
            teamDetail?.findTeamById?.activatedAt && (
              <small className="d-block text-right mt-1 edit-start-team">
                Started At:{' '}
                {formatDateUS(teamDetail?.findTeamById?.activatedAt)}
              </small>
            )}
        </div>
      </div>

      <div className="jobOrderRevamp">
        <h5 className="mb-3">Basics</h5>
        <Form onSubmit={handleSubmit(udpateTeam)}>
          <Row>
            <Col lg="4" md="4" sm="12" xs="12">
              <InputField
                inputtype="text"
                label="Team Name"
                inputid="name"
                placeholder="Team Name"
                inputRef={register({
                  required: {
                    value: true,
                    message: 'Team name is required!s',
                  },
                })}
                errMsg={errors?.name?.message}
              />
            </Col>
          </Row>

          <div className=" text-right">
            <CustomButton
              buttonText="Update Team"
              buttonColor="primary"
              disabled={updateLoading}
              className="big-width"
              loading={updateLoading || uploadLoading}
            />
          </div>
        </Form>
      </div>

      <div className="jobOrderRevamp">
        <div className="d-flex justify-content-between mb-2 align-items-center">
          <h5 className="m-0">Members</h5>

          <button
            type="button"
            className="buttonGenericStyle filledButton m-0"
            onClick={toggle}
            disabled={teamDetail?.findTeamById?.archivedAt ? true : false}
          >
            Add Member
          </button>
        </div>

        <div className="table-responsive">
          <TableComponent tableHeader={TABLE_HEADER}>
            <tbody>
              {teamDetail?.findTeamById?.teamUsers &&
                teamDetail?.findTeamById?.teamUsers.map(
                  (member: any, index: number) => (
                    <tr key={index}>
                      <td>
                        <div className="avatar-profile">
                          <div className="avatar avatar-team">
                            <img
                              src={member?.user?.photo || avatarSvg}
                              alt="team"
                            />
                          </div>
                          {member?.user?.fullName}
                        </div>
                      </td>
                      <td>{member.user?.jobTitle}</td>
                      <td>
                        {member?.startAt ? formatDateUS(member?.startAt) : '--'}
                      </td>
                      <td>
                        <FormGroup check>
                          <Label
                            check
                            style={{ lineHeight: '2', cursor: 'pointer' }}
                          >
                            <Input
                              type="radio"
                              name="radio1"
                              onClick={() => {
                                setManagerId({
                                  id: member.id,
                                  fullName: member.user.fullName,
                                });
                                setManagerConfirm(!managerConfirm);
                              }}
                              disabled={member?.endedAt}
                              checked={member?.isManager}
                              className="cursor-pointer"
                            />
                            <small>Make Manager</small>
                          </Label>
                        </FormGroup>
                      </td>
                      <td>
                        <Button
                          className="bg-transparent border-0"
                          disabled={member.endedAt}
                          style={{
                            color: '#eaa827',
                            fontSize: '12px',
                          }}
                          size="sm"
                          onClick={() => {
                            setRemoveMember({
                              id: member?.user?.id,
                              fullName: member?.user?.fullName,
                            });
                            toggleDelete();
                          }}
                        >
                          Remove
                        </Button>
                      </td>
                    </tr>
                  )
                )}
            </tbody>
          </TableComponent>
        </div>
      </div>

      <Modal
        isOpen={modal}
        toggle={toggle}
        className="revampDialog revampDialogWidth"
      >
        <div className="modalHeaderCustom d-flex align-items-center justify-content-between">
          <h5 className="m-0">Add Members</h5>

          <Button className="p-0 bg-transparent border-0" onClick={toggle}>
            <img src={CLOSE_ICON} alt="close" />
          </Button>
        </div>

        <ModalBody>
          <Row>
            <Col xs="12" className="for-position-multiselect">
              <label>Name</label>
              <Input
                inputtype="input"
                // inputid="name"
                placeholder="Select Member"
                className="mb-3"
                loading
                // inputclass="roles"
                onClick={() => {
                  setIsSelectToggle(!isSelectToggle);
                }}
                onChange={({ target }: React.ChangeEvent<HTMLInputElement>) => {
                  setSearchingText(target.value);
                }}
              />

              <MultiSelectMembers
                isToggle={!!searchingText || isSelectToggle}
                users={
                  searchingText.length > 0
                    ? listUsers?.filter((item: any) => {
                        const matchingRegex = new RegExp(
                          `^${searchingText}`,
                          'i'
                        );
                        if (item?.fullName.match(matchingRegex)) {
                          return item;
                        } else {
                          // eslint-disable-next-line
                          return;
                        }
                      })
                    : listUsers
                }
                setSelectedMember={addOrRemoveMember}
                selectedMember={selectedMember}
              />
              <div className="select-members">
                {selectedMember &&
                  selectedMember.map((member: any, index: number) => (
                    <Card key={index}>
                      <div className="avatar-label">
                        <div className="d-flex align-items-center">
                          <div className="avatar">
                            <img
                              src={member?.photo || AvatarImage}
                              alt="avatar"
                            />
                          </div>
                          <label>{member?.fullName}</label>
                        </div>

                        <Button
                          className="bg-transparent border-0 p-0 btn-close"
                          onClick={() => {
                            addOrRemoveMember(member);
                          }}
                        >
                          <img src={CloseImage} alt="avatar" />
                        </Button>
                      </div>
                    </Card>
                  ))}
              </div>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <button onClick={toggle} className="buttonPrimary mr-3" type="button">
            Cancel
          </button>

          <CustomButton
            buttonText="Save Member"
            buttonColor="primary"
            buttonClick={updateTeamMembers}
            loading={loading}
            disabled={loading}
            className="big-width"
          />
        </ModalFooter>
      </Modal>

      {/* for confirmation pop up modal */}
      <ConfirmPopUp
        confirmText="Are you sure you want to remove"
        isOpen={modalDelete}
        toggle={toggleDelete}
        confirmAction={removeTeamMembers}
        modalHeading="Delete Member"
        extraProp={`${removeMember?.fullName}?`}
        className="for-modal-styling"
      />
      <ConfirmPopUp
        confirmText="Are you sure you want to delete this team? 
            Please note that once deleted,
            this team will not be accessible. Please confirm."
        isOpen={deleteTeamToggle}
        toggle={setDeleteTeamToggle}
        confirmAction={() => {
          changeTeamStatus('deactive');
        }}
        modalHeading="Delete Team"
        className="for-modal-styling"
      />

      <ConfirmPopUp
        confirmText="Are you sure you want to make"
        isOpen={managerConfirm}
        toggle={setManagerConfirm}
        confirmAction={_setTeamManager}
        modalHeading="Make Manager"
        btnText="Confirm"
        btnColor="primary"
        extraProp={`${managerDetail?.fullName} as Manager?`}
        className="for-modal-styling"
        loading={managerLoading}
        disabled={managerLoading}
      />
    </div>
  );
};

export default withRouter(AddEditTeam);
