// packages block
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Reducer,
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useReducer,
  useState,
} from 'react';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
// packages block
import AddNotesModal from '../../../components/AddNotesModal';
import ContentViewSidebar from '../../../components/ContentViewSidebar';
import InputField from '../../../components/layout/InputField';
import NoDataFound from '../../../components/layout/NoDataFound';
import Pagination from '../../../components/layout/Pagination';
import TableComponent from '../../../components/layout/TableComponent';
import ItemSummary from './ItemSummary';
// graphql, utils, svgs and constants block
import Delete_Img from '../../../assets/images/candidate-delete.svg';
import EDIT_ICON from '../../../assets/images/edit-icon-menu.svg';
import SEARCH_IMG from '../../../assets/images/search.svg';
import VIEW_ICON from '../../../assets/images/viewIcon.svg';
import ConfirmPopUp from '../../../components/ConfirmPopUp';
import Loader from '../../../components/layout/Loader';
import {
  COMPANY_CONTACT_NOTES_HEADER,
  EMPLOYMENT_HEADER,
} from '../../../constants';
import { AppContext } from '../../../context';
import {
  Action,
  ActionType,
  State,
  candidateReducer,
  initialState,
} from '../../../reducers/candidateReducer';
import {
  Action as NoteAction,
  ActionType as NoteActionType,
  State as NoteState,
  initialState as noteInitialState,
  noteReducer,
} from '../../../reducers/noteReducer';
import { formatDateUS } from '../../../utils/commonFn';
import {
  PAGE_LIMIT,
} from '../../../utils/constant';
import { filterNoteCategories, mapEnum } from '../../../utils/helper';
import { GET_ES_CANDIDATE_NOTES } from '../../NotesSearch/gql';
import { ParentRef } from '../../agreements/interfaces';
import {
  DELETE_NOTE,
  GET_COMPANY_CONTACT_NOTES,
  GET_NOTES,
} from '../../company/gql';

let time = 0;

export const CandidateNotesList = forwardRef<ParentRef | undefined, any>((
  { recordId, type, haveActionPermissions, companyContacts, updateNotesRef, width, zIndex },
  ref
): JSX.Element => {
  const { theme } = useContext(AppContext);
  const [category, setCategory] = useState();
  const [state, dispatch] = useReducer<Reducer<State, Action>>(
    candidateReducer,
    initialState
  );
  const [
    {
      contactNotes,
      contactId,
      limit: contactLimit,
      currentPage: contactCurrentPage,
    },
    noteDispatch,
  ] = useReducer<Reducer<NoteState, NoteAction>>(noteReducer, noteInitialState);

  const {
    activityNotes,
    confirmNotePopup,
    deleteNoteId,
    limit,
    modalNoteContent,
    noteCategory,
    noteContent,
    noteCreator,
    noteData,
    noteModal,
    currentPage,
    selectedNote,
    noteState,
  } = state;

  const [getNotes, { data: notesDBData }] = useLazyQuery(GET_NOTES);
  const [getEsNotes, { data: notesESData, loading }] =
    useLazyQuery(GET_ES_CANDIDATE_NOTES);
  const [getCompanyContactNotes, { data: companyContactNotesData }] =
    useLazyQuery(GET_COMPANY_CONTACT_NOTES);

  const toggleActivityNotes = () => {
    activityNotes &&
      dispatch({ type: ActionType.SET_SELECTED_NOTE, selectedNote: {} });
    dispatch({
      type: ActionType.SET_ACTIVITY_NOTE,
      activityNote: !activityNotes,
    });
  };

  const [_deleteNote] = useMutation(DELETE_NOTE);

  const deleteNote = async () => {
    let res = await _deleteNote({ variables: { noteId: deleteNoteId } });
    if (res?.data) {
      toast.success('Successfully deleted!');
      fetchNotes();
      dispatch({
        type: ActionType.SET_CONFIRM_NOTE_POPUP,
        confirmNotePopup: false,
      });
    }
  };

  const noteToggle = () =>
    dispatch({ type: ActionType.SET_NOTE_MODAL, noteModal: !noteModal });

  const fetchNotes = useCallback(async () => {
    window.clearTimeout(time);

    if (type === 'Job Order' || type === 'Candidate') {
      time = window.setTimeout(() => {
        getEsNotes({
          variables: {
            text: noteContent || '',
            candidateNotes: type === 'Candidate' ? recordId : '',
            companyNotes: '',
            jobNotes: type === 'Job Order' ? recordId : '',
            recruiterNotes: noteCreator || '',
            contactNotes: '',
            notesCategory: noteCategory || '',
            limit,
            page: currentPage,
          },
        });
      }, 1000);
    } else {
      if (
        !noteContent ||
        noteContent.length > 2 ||
        !noteCategory ||
        noteCategory.length > 2 ||
        !noteCreator ||
        noteCreator.length > 2
      ) {
        if (type === 'Company') {
          await getCompanyContactNotes({
            variables: {
              companyId: recordId,
              notePayload: {
                type,
                content: noteContent?.length > 2 ? noteContent : '',
                category: noteCategory?.length > 2 ? noteCategory : '',
                contactId: !!contactId ? contactId : '',
                createdBy: noteCreator?.length > 2 ? noteCreator : '',
              },
              limit: contactLimit,
              page: contactCurrentPage,
            },
          });
        } else {
          // time = window.setTimeout(() => {
          await getNotes({
            variables: {
              id: recordId,
              notePayload: {
                type,
                content: noteContent?.length > 2 ? noteContent : '',
                category: noteCategory?.length > 2 ? noteCategory : '',
                createdBy: noteCreator?.length > 2 ? noteCreator : '',
              },
              limit,
              page: currentPage,
            },
          });
          // }, 1000);]
        }
      }
    }
  }, [
    contactCurrentPage,
    contactId,
    contactLimit,
    currentPage,
    getCompanyContactNotes,
    getEsNotes,
    getNotes,
    limit,
    noteCategory,
    noteContent,
    noteCreator,
    recordId,
    type,
  ]);

  const handleEdit = (item: any) => {
    dispatch({ type: ActionType.SET_SELECTED_NOTE, selectedNote: item });
    dispatch({ type: ActionType.SET_ACTIVITY_NOTE, activityNote: true });
  };

  const customStyles = {
    control: (styles, provided) => ({
      ...styles,
      width: `${width} !important`,
      background: theme === 'light' ? provided.background : '#282828',
      borderColor: theme === 'light' ? '#ced4da' : '#282828',
      color: theme === 'light' ? provided.background : '#d9d9d9',
      fontSize: 12,
      '&:hover': {
        borderColor: theme === 'light' ? '#ced4da' : '#282828',
        color: theme === 'light' ? provided.background : '#d9d9d9',
      },
    }),
    menu: (provided) => ({
      ...provided,
      background: theme === 'light' ? provided.background : '#282828',
      zIndex: zIndex || 999999,
      color: theme === 'light' ? provided.background : '#d9d9d9',
    }),
    option: (provided) => ({
      ...provided,
      fontSize: 12,
      background: theme === 'light' ? provided.background : '#363636',
      '&:hover': {
        background: theme === 'light' ? provided.background : '#474444',
        color: theme === 'light' ? provided.background : '#d9d9d9',
      },
    }),
    container: (provided, state) => ({
      ...provided,
      fontSize: 12,
    }),
  };
  useImperativeHandle(updateNotesRef, () => ({
    updateNotes() {
      fetchNotes()
    }
  }));
  useEffect(() => {
    recordId && fetchNotes();
  }, [
    recordId,
    currentPage,
    contactCurrentPage,
    limit,
    contactLimit,
    noteCategory,
    noteContent,
    noteCreator,
    fetchNotes,
  ]);

  useEffect(() => {
    if (!!notesDBData || !!companyContactNotesData) {
      if (type === 'Company') {
        const { getCompanyContactsNotes } = companyContactNotesData || {};

        !!getCompanyContactsNotes &&
          noteDispatch({
            type: NoteActionType.SET_CONTACT_NOTES,
            contactNotes: getCompanyContactsNotes || {},
          });
      } else {
        const { getNotes } = notesDBData || {};

        !!getNotes &&
          dispatch({ type: ActionType.SET_NOTE_DATA, noteData: getNotes });
      }
    } else if (!!notesESData) {
      const { getESCandidateNotes } = notesESData;
      !!getESCandidateNotes &&
        dispatch({ type: ActionType.SET_NOTE_DATA, noteData: getESCandidateNotes });
    }
  }, [companyContactNotesData, noteData, notesDBData, notesESData, type]);

  const renderItem = (item) => (
    <tr>
      <td>{item?.category}</td>
      <td className="rich-text-paragraph">
        <ItemSummary item={item} from={''} />
      </td>

      {type === 'Company' && <td>{item?.contactName}</td>}

      <td>{item?.user?.fullName || '--'}</td>
      <td>
        {item?.modifiedOn ? formatDateUS(item?.modifiedOn) : '--'}
        {'  '}
        {item?.modifiedOn &&
          new Date(item.modifiedOn).toLocaleTimeString()}{' '}
      </td>

      <td align="left">
        <div
          className="d-flex align-items-center justify-content-start"
          style={{ width: 150 }}
        >
          <button
            className="iconButtonAction"
            disabled={haveActionPermissions}
            onClick={() => handleEdit(item)}
          >
            <img src={EDIT_ICON} alt="Edit" />
          </button>

          <button
            onClick={() => {
              dispatch({
                type: ActionType.SET_MODAL_NOTE_CONTENT,
                modalNoteContent: item?.content,
              });
              dispatch({ type: ActionType.SET_NOTE_STATE, noteState: item });
              noteToggle();
            }}
            className="iconButtonAction mx-2"
          >
            <img src={VIEW_ICON} alt="view" width="21px" />
          </button>

          <button
            disabled={haveActionPermissions}
            onClick={() => {
              dispatch({
                type: ActionType.SET_CONFIRM_NOTE_POPUP,
                confirmNotePopup: !confirmNotePopup,
              });
              dispatch({
                type: ActionType.SET_DELETE_NOTE_ID,
                deleteNoteId: item.id,
              });
            }}
            className="iconButtonAction"
          >
            <img src={Delete_Img} alt="delete" width="16px" />
          </button>
        </div>
      </td>
    </tr>
  );

  const renderNotesPagination = () => {
    return (
      <div className="users-pagination">
        <Pagination
          onPageChange={(pageClicked: number) =>
            dispatch({
              type: ActionType.SET_CURRENT_PAGE,
              currentPage: pageClicked,
            })
          }
          pageCount={Math.ceil(noteData?.count / limit)}
          currentPage={currentPage}
        />

        <div className="d-flex align-items-center">
          <div className="pagination-display paginationItemsSelect">
            <p className="m-0">
              {(currentPage - 1) * limit + 1} to{' '}
              {Math.min(currentPage * limit, noteData?.count)} of{' '}
              {noteData?.count}
            </p>

            <div className="divider"></div>

            <label>Display</label>

            <InputField
              label=""
              inputtype="select"
              selectItems={PAGE_LIMIT}
              inputid="limit"
              placeholder={limit !== 10 ? limit.toString() : 'Set Limit'}
              inputMethod={(value) => {
                if (!value) value = limit.toString();
                dispatch({ type: ActionType.SET_CURRENT_PAGE, currentPage: 1 });
                dispatch({
                  type: ActionType.SET_LIMIT,
                  limit: parseInt(value),
                });
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderCompanyContactsPagination = () => {
    return (
      <div className="users-pagination">
        <Pagination
          onPageChange={(pageClicked: number) =>
            noteDispatch({
              type: NoteActionType.SET_CURRENT_PAGE,
              currentPage: pageClicked,
            })
          }
          pageCount={Math.ceil(contactNotes?.count / contactLimit)}
          currentPage={contactCurrentPage}
        />
        <div className="d-flex align-items-center">
          <div className="pagination-display paginationItemsSelect">
            <p className="m-0">
              {(contactCurrentPage - 1) * contactLimit + 1} to{' '}
              {Math.min(contactCurrentPage * contactLimit, contactNotes?.count)}{' '}
              of {contactNotes?.count}
            </p>

            <div className="divider"></div>

            <label>Display</label>

            <InputField
              label=""
              inputtype="select"
              selectItems={PAGE_LIMIT}
              inputid="limit"
              placeholder={
                contactLimit !== 10 ? contactLimit.toString() : 'Set Limit'
              }
              inputMethod={(value) => {
                if (!value) value = contactLimit.toString();
                noteDispatch({
                  type: NoteActionType.SET_CURRENT_PAGE,
                  currentPage: 1,
                });
                noteDispatch({
                  type: NoteActionType.SET_LIMIT,
                  limit: parseInt(value),
                });
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="table-responsive tableResponsiveInitial view-candidate-list-table">
        <TableComponent
          tableHeader={
            type === 'Company'
              ? COMPANY_CONTACT_NOTES_HEADER
              : EMPLOYMENT_HEADER
          }
        >
          <tbody>
            <tr className="searchFilterTable">
              <td>
                <div className="searchFieldWidth">
                  <Select
                    isClearable
                    options={filterNoteCategories(type)}
                    onChange={(event: any) => {
                      dispatch({
                        type: ActionType.SET_NOTE_CATEGORY,
                        noteCategory: event?.value,
                      });
                      setCategory(event);
                    }}
                    placeholder="Category"
                    maxMenuHeight={140}
                    styles={{ ...customStyles }}
                    value={category}
                  />
                </div>
              </td>

              <td>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <img src={SEARCH_IMG} alt="search" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    type="text"
                    placeholder="Note content"
                    value={noteContent}
                    onChange={(e) =>
                      dispatch({
                        type: ActionType.SET_NOTE_CONTENT,
                        noteContent: e.target.value,
                      })
                    }
                    className="search-by-company"
                  />
                </InputGroup>
              </td>

              {type === 'Company' && (
                <td>
                  <div className="searchFieldWidth">
                    <Select
                      isClearable
                      options={mapEnum(companyContacts)}
                      onChange={(event: any) => {
                        noteDispatch({
                          type: NoteActionType.SET_CONTACT_ID,
                          contactId: event?.value,
                        });
                      }}
                      placeholder="Contacts"
                      maxMenuHeight={140}
                      styles={{ ...customStyles }}
                    />
                  </div>
                </td>
              )}

              <td>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <img src={SEARCH_IMG} alt="search" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    type="text"
                    placeholder="Note created by"
                    value={noteCreator}
                    onChange={(e) =>
                      dispatch({
                        type: ActionType.SET_NOTE_CREATOR,
                        noteCreator: e.target.value,
                      })
                    }
                    className="search-by-company"
                  />
                </InputGroup>
              </td>

              <td></td>

              <td></td>
            </tr>

            {type === 'Company' ? (
              <>
                {contactNotes?.notes?.map((item) => renderItem(item))}

                {!contactNotes?.notes?.length && (
                  <tr>
                    <td colSpan={6} className="empty-table-td">
                      <NoDataFound text="No Notes Found!" />
                    </td>
                  </tr>
                )}
              </>
            ) : (
              <>
                {noteData?.notes?.map((item) => renderItem(item))}

                {!loading && !noteData?.notes?.length && (
                  <tr>
                    <td colSpan={6} className="empty-table-td">
                      <NoDataFound text="No Notes Found!" />
                    </td>
                  </tr>
                )}
                {loading && !noteData?.notes?.length && (
                  <tr>
                    <td
                      colSpan={6}
                      className="empty-table-td"
                      style={{ maxHeight: '50vh' }}
                    >
                      <Loader loaderClass="sm-height " />
                    </td>
                  </tr>
                )}
              </>
            )}
          </tbody>
        </TableComponent>

        {type === 'Company'
          ? !!contactNotes?.notes?.length && renderCompanyContactsPagination()
          : !!noteData?.notes?.length && renderNotesPagination()}
      </div>

      <ContentViewSidebar
        noteData={noteState}
        toggle={noteToggle}
        content={modalNoteContent}
        emailNotes={null}
        isOpen={noteModal}
        emailHeader={null}
        heading={'View Candidate Note Content'}
      />

      <AddNotesModal
        type={type}
        noteableId={recordId}
        editData={selectedNote}
        activityNotes={activityNotes}
        reFetch={() => fetchNotes()}
        toggleActivityNotes={toggleActivityNotes}
        resetField={() =>
          dispatch({ type: ActionType.SET_NOTE_DATA, noteData: '' })
        }
      />

      <ConfirmPopUp
        confirmText="Are you sure you want to delete this note?"
        isOpen={confirmNotePopup}
        toggle={() =>
          dispatch({
            type: ActionType.SET_CONFIRM_NOTE_POPUP,
            confirmNotePopup: !confirmNotePopup,
          })
        }
        confirmAction={deleteNote}
        modalHeading="Delete Note"
        btnText="Delete"
        btnColor="primary"
        className="for-modal-styling"
      />
    </>
  );
})
