import { useHistory, withRouter } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";
import { VIEW_JOB_ORDER_ROUTE } from "../../utils/constant";

const PipelineWidget = ({ data }: any) => {
  const history = useHistory();
  return (
    <div className="jobOrderRevamp">
      <h5 className="mb-3">Job Order Widget </h5>

      <Row className="mb-2">
        <Col lg="6" md="6" sm="12" xs="12">

          <div className="d-flex justify-content-between align-items-center mb-2 contentGridLabel">
            <label>Company Name </label>
            <div className="contentGridColumn">
              <Button
                onClick={() => {
                  history?.push({
                    pathname: `/company-details/${data?.company?.id}`,
                    state: { backUrl: `/candidate-pipeline/view/${data?.id}` },
                  });
                }}
                className="bg-transparent border-0 text-dark textButton p-0"
              >
                {data?.company?.name || "--"}
              </Button>
            </div>
          </div>

          <div className="d-flex justify-content-between align-items-center mb-2 contentGridLabel">
            <label>Job Title</label>
            <div className="contentGridColumn">
              <Button
                onClick={() => {
                  history?.push({
                    pathname: `${VIEW_JOB_ORDER_ROUTE}/${data?.id}`,
                    state: { backUrl: `/candidate-pipeline/view/${data?.id}` },
                  });
                }}
                className="bg-transparent border-0 text-dark textButton p-0"
              >
                {data?.jobTitle || "--"}
              </Button>
            </div>
          </div>

          <div className="d-flex justify-content-between contentGridLabel mb-2">
            <label>Job Ad Location</label>
            <h6>
              {data?.companyLocation?.city
                ? `${data?.companyLocation?.city},${data?.companyLocation?.state}`
                : "--"}
            </h6>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default withRouter(PipelineWidget);
