import { useMutation } from "@apollo/client";
import { Button, Col, Input, Label, Row } from "reactstrap";
import deleteIcon from "../../../assets/images/delete-field.svg";
import { EMAIL_PATTERN } from "../../../utils/constant";
import { VERIFY_EMAIL } from "../gql";

const CandidateEmails = ({
  getValues,
  setValue,
  append,
  fields,
  register,
  errors,
  remove,
  setToggleEmailOrLinkdin,
  setVerifyEmailStatus,
}: any) => {
  const [verifyEmail] = useMutation(VERIFY_EMAIL);

  return (
    <div>
      <h5>Contact Emails</h5>

      <div>
        {fields?.map((item, index) => {
          return (
            <Row className="mb-3" key={index}>
              <Col xl="6" lg="6" md="12" xs="12" className="text-left">
                <input
                  type="hidden"
                  name={`emails[${index}].emailId`}
                  ref={register()}
                  defaultValue={item.emailId}
                />
                <Input
                  name={`emails[${index}].email`}
                  placeholder="Email"
                  defaultValue={`${item?.email}`} // make sure to set up defaultValue
                  onChange={({ target: { value } }) => {
                    if (new RegExp(EMAIL_PATTERN)?.test(value)) {
                      setToggleEmailOrLinkdin(true);
                    } else {
                      setToggleEmailOrLinkdin(false);
                    }
                  }}
                  innerRef={register({

                    pattern: {
                      value: EMAIL_PATTERN,
                      message: "Email is not valid!",
                    },
                    validate: async (value) => {
                      if (value && value !== item?.email) {
                        setVerifyEmailStatus(true); // Set the state to true before the API call
                        let { data } = await verifyEmail({
                          variables: { email: value },
                        });

                        if (data?.verifyEmailExist?.exist) {
                          setVerifyEmailStatus(false);
                          return "Email is already there.";
                        } else {
                          setVerifyEmailStatus(false);
                          return true;
                        }
                      }
                    },
                  })}
                />
                <small className="text-danger">
                  {errors.emails && errors?.emails[index]?.email?.message}
                </small>
              </Col>

              <Col
                xl="6"
                lg="6"
                md="12"
                xs="12"
                className="d-flex align-items-end"
              >
                <div className="radio-delete m-0">
                  <Button
                    type="button"
                    disabled={fields.length === 1}
                    onClick={() => {
                      remove(index, item?.emailId);
                    }}
                    className="bg-transparent border-0"
                  >
                    <img
                      src={deleteIcon}
                      alt=""
                      width="20"
                      height="20"
                      className="m-0"
                    />
                  </Button>

                  <div>
                    <Label className="radio-container switchLabel m-0">
                      is Primary
                      <input
                        name={`emails[${index}].isPrimary`}
                        checked={item.isPrimary}
                        // disabled={fields.length === 1}
                        // defaultChecked={fields.length === 1}
                        ref={register()}
                        type="checkbox"
                        onChange={(e) => {
                          let val = [...getValues().emails];
                          if (val?.find((item) => item.isPrimary)) {
                            val = val?.map((item) => {
                              item.isPrimary = false;
                              return item;
                            });
                            val[index].isPrimary = e.target.checked;
                            setValue(`emails`, val);
                          }
                        }}
                      />
                      <span className="checkmark"></span>
                    </Label>
                  </div>
                </div>
              </Col>
            </Row>
          );
        })}
        <small className="text-danger">{errors?.emails?.message}</small>
        <div>
          <button
            type="button"
            onClick={() => {
              append({
                email: "",
                isPrimary: false,
                emailId: "",
              });
            }}
            className="buttonGenericStyle filledButton"
          >
            Add New Email
          </button>
        </div>
      </div>
    </div>
  );
};

export default CandidateEmails;
