// packages block
import { useContext, useEffect, useRef, useState } from 'react';
import Select from 'react-select';
import classnames from 'classnames';
import ReactQuill from 'react-quill';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import {
  Badge,
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
  TabContent,
  TabPane,
} from 'reactstrap';
// component block
import GoBack from '../../components/GoBack';
import SubmissionWidget from './SubmissionWidget';
import Loader from '../../components/layout/Loader';
import ConfirmPopUp from '../../components/ConfirmPopUp';
import SubmitModal from './Pipeline-components/SubmitModal';
import ApplicantNotes from '../../components/ApplicantNotes';
import DeclineSubmissionModal from './DeclineSubmissionModal';
import CustomButton from '../../components/layout/CustomButton';
import SubmissionReview from './Pipeline-components/SubmissionReview';
import ResumeUpload from '../candidate/candidate-components/ResumeUpload';
import { customSelectDropDown } from '../../components/styled/customSelect';
import Preview from '../job-order/job-order-components/document-preview/Preview';
import SubmittedWarningsModal from './Pipeline-components/SubmittedWarningsModal';
import SubmissionJobOrderWidget from './Pipeline-components/SubmissinonJobOrderWidget';
// utils, graphql and constants block
import { AppContext } from '../../context';
import { useLazyQuery, useMutation } from '@apollo/client';
import DownloadImg from '../../assets/images/download.svg';
import { EDIT_WRITE_UP, GET_SIGNED_URL } from '../candidate/gql';
import { commaRemover, priceHandler } from '../../utils/helper';
import { GET_CANDIDATE_NOTES, GET_OWNER_SHAREES } from '../company/gql';
import { GET_SUBMISSIONS_FROM_APPLICANT } from '../recruiter-folder/gql';
import {
  downloadSignedUrlFile,
  removingExtension,
  urlExtension,
} from '../../utils/commonFn';
import {
  CREATE_SUBMISSION,
  GET_CANDIDATE_DETAILS_SUBMIT,
  GET_CANDIDATE_WRITEUP,
} from './gql';
import {
  GET_JOB_ORDER_MAIN_CONTACT,
  GET_JOB_ORDER_SUBMISSION,
} from '../job-order/gql';

let time;
const CandidateSubmission = ({ match, location, history }) => {
  const { user, userRoles } = useContext(AppContext);
  const { theme } = useContext(AppContext);
  const activeTabOf = new URLSearchParams(history?.location.search).get(
    'activeTab'
  );
  const params = new URLSearchParams(location?.search);
  const candidateId = match?.params?.id;
  let companyId: any = params?.get('companyId');
  let jobOrderId = params?.get('jobOrderId');
  let applicantId = params?.get('applicantId');

  const [activeTab, setActiveTab] = useState(activeTabOf ? activeTabOf : '1');
  const [selectedCompany, setSelectedCompany] = useState<''>();
  const [submissionId, setSubmissionId] = useState<string>();
  const [signedUrlGetter] = useMutation(GET_SIGNED_URL);
  const [editWriteupValue, setEditWriteup] = useState<any>('');
  const [writeupId, setWriteupId] = useState<any>(null);
  const [confirmToggle, setConfirmToggle] = useState(false);
  const [manualAlert, setManualAlert] = useState(false);
  const [submitModal, setSubmitModal] = useState<any>(false);
  const [errMsg, setErrMsg] = useState('');
  const [salaryErrMsg, setSalaryErrMsg] = useState('');
  const [unsignedOriginalUrl, setUnsignedOriginalUrl] = useState('');
  const [unsignedOriginalName, setUnsignedOriginalName] = useState('');
  const [, setOriginalResumeUrl] = useState('');
  const [pdfVersionUrl, setPdfVersionUrl] = useState('');
  const [pdfVersionName, setPdfVersionName] = useState('');
  const [pdfVersionId, setPdfVersionId] = useState('');
  const [, setOriginalResumeName] = useState('');
  const [updatePermission, setUpdatePermission] = useState(false);
  const [submissionComplete, setSubmissionComplete] = useState(false);
  const [isRecruiter, setIsRecruiter] = useState(true);
  const [onlyCandidateOwner, setOnlyCandidateOwner] = useState(false);
  const [fileStatus, setFileStatus] = useState('');
  const [companyOwnerId, setCompanyOwnerId] = useState(null);
  const [, setWriteupResume] = useState(false);
  const [writeUpLoading, setWriteUpLoading] = useState(false);
  const [documentDetail, setDocumentDetail] = useState<any>({});
  const [targetSalary, setTargetSalary] = useState<any>();
  const [targetSalaryError, setTargetSalaryError] = useState<boolean>(false);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [declineSub, setDeclineSub] = useState(false);
  const [resumeUploadModal, setResumeUploadModal] = useState(false);
  const [submissionErr, setSubmissionErr] = useState<any>({});
  const [companyOwnerShareIds, setCompanyOwnerShareIds] = useState<
    Array<string>
  >([]);
  const [salaryRate, setSalaryRate] = useState<any>({
    salaryType: 'HOURLY',
    salary: '',
  });

  const [getJobOrder, { data: getSingleJobOrder, loading: jobOrderLoading }] =
    useLazyQuery(GET_JOB_ORDER_SUBMISSION);

  const [getJobMainContactQuery, { data: jobMainContact }] = useLazyQuery(
    GET_JOB_ORDER_MAIN_CONTACT
  );

  const [getCompanyAndOwner, { data: companyOwner, loading: companyLoading }] =
    useLazyQuery(GET_OWNER_SHAREES);

  const [getCandidate, { data, loading }] = useLazyQuery(
    GET_CANDIDATE_DETAILS_SUBMIT
  );

  const [editWriteUp] = useMutation(EDIT_WRITE_UP);
  const [getWriteUp, { data: writeUp, loading: getWriteUpLoading }] =
    useLazyQuery(GET_CANDIDATE_WRITEUP);
  const [submissionCreate, { loading: submitting }] =
    useMutation(CREATE_SUBMISSION);
  const [getSubmissionApplicant, { data: submissionReview, refetch }] =
    useLazyQuery(GET_SUBMISSIONS_FROM_APPLICANT);
  const [
    getCandidateNotes,
    { data: candidateNotes, loading: candidateNotesLoading },
  ] = useLazyQuery(GET_CANDIDATE_NOTES);

  const getSubmissionReview = () => {
    getSubmissionApplicant({
      variables: {
        candidateId,
        jobOrderId,
      },
    });
  };

  const _getWriteUp = (id: string) => {
    getWriteUp({
      variables: {
        candidateId: id,
        companyOwner:
          history?.location?.state?.landedFrom === 'pipeline' ? true : false,
      },
    });
  };

  const submissionErrors = () => {
    let candidateOwner = data?.getCandidate?.recruiterOwner?.id;
    let candidateEmails = data?.getCandidate?.emails;
    let candidatePhones = data?.getCandidate?.phones;
    let candidateAddress =
      data?.getCandidate?.streetAddress ||
      data?.getCandidate?.city ||
      data?.getCandidate?.stateCode;
    // data?.getCandidate?.postalCode ||
    // data?.getCandidate?.countryCode;

    let candidateWriteUp = writeUp?.checkCandidateWriteup?.writeupSummary;
    
    let recruiterAssign =
      getSingleJobOrder?.getJobOrder?.recruitingAssigned?.find(
        (item) => item.user?.id === user?.id
      );
    let companyOwnerHere = companyOwner?.getShareesAndOwner?.find(
      (item) => item?.ownerType === 'OWNER'
    );
    let companyOwnerShares = companyOwner?.getShareesAndOwner?.map(
      (item) => item?.owner?.id
    );

    let candidateNotesExist =
      candidateNotes?.getCandidateNotes?.length &&
      candidateNotes?.getCandidateNotes?.find(
        (item) =>
          item?.category === 'Phone Call' ||
          item?.category === 'Internal Interview'
      );
    let candidateWebInterview =
      candidateNotes?.getCandidateNotes?.length &&
      candidateNotes?.getCandidateNotes?.find(
        (item) => item?.category === 'Internal Web Interview'
      );

    let validate = history?.location?.state?.landedFrom;
    const errs: any = {
      ownerCandidate: false,
      candidateEmails: false,
      candidatePhones: false,
      jobAssign: false,
      summary: false,
      candidateAddress: false,
      noteCategory: false,
      webInterview: false,
      modal: false,
    };

    if (validate === 'candidate') {
      //this condition is used if user land from pipeline in this case we are not checking validations
      if (candidateOwner !== user?.id) {
        console.log('candidateOwner in submission', candidateOwner);
        console.log('user in submission', user?.id);

        errs.ownerCandidate = true;
        errs.modal = true;
      }
      if (!companyOwnerShares?.includes(user?.id)) {
        errs.jobAssign = true;
        errs.modal = true;
        if (recruiterAssign) {
          errs.jobAssign = false;
          errs.modal = false;
        }
      }
      if (!recruiterAssign) {
        console.log(
          'recruiterAssign in submission recruiterAssign',
          recruiterAssign
        );
        console.log('user in submission recruiterAssign', user?.id);
        console.log(
          'candidateOwner in submission recruiterAssign',
          candidateOwner
        );

        errs.jobAssign = true;
        errs.modal = true;

        if (companyOwnerShares?.includes(user?.id)) {
          errs.jobAssign = false;
          errs.modal = false;
          setIsRecruiter(false);
        }
      }

      if (
        !candidateEmails?.length ||
        (candidateEmails?.length && !candidateEmails[0]?.email)
      ) {
        errs.candidateEmails = true;
        errs.modal = true;
      }
      if (
        !candidatePhones?.length ||
        (candidatePhones?.length && !candidatePhones[0]?.phoneNumber)
      ) {
        errs.candidatePhones = true;
        errs.modal = true;
      }

      if (!candidateNotesExist) {
        errs.noteCategory = true;
        errs.modal = true;
      }

      if (!candidateWebInterview) {
        errs.webInterview = true;
        errs.modal = true;
      }

      if (!candidateWriteUp) {
        errs.summary = true;
        errs.modal = true;
      }
      if (!candidateAddress) {
        errs.candidateAddress = true;
        errs.modal = true;
      }
      setSubmissionErr(errs);
    }
    if (recruiterAssign && !companyOwnerShares?.includes(user?.id)) {
      setIsRecruiter(true);
    } else {
      setIsRecruiter(false);
    }
    if (
      candidateOwner === user?.id &&
      !companyOwnerShares?.includes(user?.id)
    ) {
      setOnlyCandidateOwner(true);
    } else {
      setOnlyCandidateOwner(false);
    }
    if (companyOwnerHere || companyOwnerShares?.length) {
      setCompanyOwnerId(companyOwnerHere?.owner?.id);
      setCompanyOwnerShareIds(companyOwnerShares);
    }

    if (
      candidateOwner === user?.id ||
      companyOwnerShares?.includes(user?.id) ||
      userRoles?.includes('Executive') ||
      recruiterAssign
    ) {
      setUpdatePermission(true);
    }
  };

  const editOwnerShipWriteup = async () => {
    if (!editWriteupValue) {
      setErrMsg('Write should not be empty!');
      return;
    }
    setWriteUpLoading(true);
    const res = await editWriteUp({
      variables: {
        writeupId,
        writeupSummary: editWriteupValue,
      },
    });
    if (res?.data) {
      setWriteUpLoading(false);
      toast.success('Successfully edited');
    } else {
      toast.error(
        'An error occured in processing your request. Please try again later.'
      );
      setWriteUpLoading(false);
    }
  };

  const submissionValidate = async () => {
    // this checko is used for the email submission
    if (
      submissionReview?.getSubmissionfromJobApplicant?.status === 'COMPLETED' &&
      submissionReview?.getSubmissionfromJobApplicant?.submitWith === 'ATS'
    ) {
      setSubmissionComplete(!submissionComplete);
      return;
    }
    if (!targetSalary) {
      setTargetSalaryError(true);
      targetRef?.current?.focus();
      return;
    }
    if (!documentDetail?.id && !documentDetail?.url) {
      toast.error('Resume must be selected!');
      return;
    }
    // if (documentDetail?.id && !documentDetail?.scrubUrl) {
    //   toast.info("Please create the scrub copy first!");
    //   return;
    // }
    // this condition is used for checking company owner for
    if (!companyOwner?.getShareesAndOwner?.length) {
      setConfirmToggle(!confirmToggle);
    }
    if (companyOwner?.getShareesAndOwner) {
      let owner = companyOwner?.getShareesAndOwner?.find(
        (item: { ownerType: string }) => item?.ownerType === 'OWNER'
      );
      if (!owner) {
        setConfirmToggle(!confirmToggle);
        return;
      }
    }
    if (!writeUp?.checkCandidateWriteup?.writeupSummary) {
      toast.info('Candidate has not complete summary!');
      return;
    }
    await createSubmission();
    if (!isRecruiter) {
      setSubmitModal(!submitModal);
    } else {
      history?.push(
        `/candidate-pipeline/view/${jobOrderId}?candidateFilter=Submitted`
      );
    }
  };

  // this is for the target salary validation
  const targetRef: any = useRef(null);
  const createSubmission = async () => {
    let salary: any = commaRemover(salaryRate?.salary);
    if (applicantId) {
      let res = await submissionCreate({
        variables: {
          createSubmission: {
            applicantId: applicantId,
            salary:
              salaryRate?.salaryType === 'HOURLY'
                ? parseFloat(salary)
                : parseInt(salary),
            salaryType: salaryRate?.salaryType,

            // Target salary
            targetSalary,
            resumeId: pdfVersionId,
            candidateId: match?.params?.id,
            jobOrderId: jobOrderId,
            companyOwnerId,
            submissionSummary: editWriteupValue,
          },
        },
      });
      if (res?.data) {
        setSubmissionId(res?.data?.createSubmission?.submission?.id);
        res?.data?.createSubmission?.status !== 304 &&
          companyOwnerId !== user?.id &&
          toast.success('Successfully Submitted!');

        res?.data?.createSubmission?.status === 304 &&
          isRecruiter &&
          submissionReview?.getSubmissionfromJobApplicant?.status !==
            'Complete' &&
          toast.info('Candidate Submission Pending!');
      }
      return res;
    }
    toast.error('Error While Creating Submission!');
  };

  const handleSubmission = () => {
    if (
      submissionReview?.getSubmissionfromJobApplicant?.status === 'COMPLETED' &&
      submissionReview?.getSubmissionfromJobApplicant?.submitWith === 'MANUAL'
    ) {
      setManualAlert(!manualAlert);
    } else {
      submissionValidate();
    }
  };

  const toggleTab = (tab: string) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const resumeUploadToggle = () => setResumeUploadModal(!resumeUploadModal);

  const onResumeDownload = async () => {
    if (unsignedOriginalUrl && unsignedOriginalName) {
      setDownloadLoading(true);

      await downloadSignedUrlFile(unsignedOriginalUrl, unsignedOriginalName);
      setDownloadLoading(false);
    } else {
      setDownloadLoading(true);
      await downloadSignedUrlFile(pdfVersionUrl, pdfVersionName);
      setDownloadLoading(false);
    }
    // }
  };

  const generateSignedUrl = async (
    urlToGetSignedUrl: string,
    fileName: string,
    fileId: string
  ) => {
    const res = await signedUrlGetter({
      variables: {
        remoteFilePath: urlToGetSignedUrl,
      },
    });

    if (res?.data?.getSignedUrl?.signedUrl) {
      setDocumentDetail({
        url: res?.data?.getSignedUrl?.signedUrl,
        name: fileName,
        driveUrl: '',
        id: fileId,
      });
      setFileStatus('Original');
    }
  };

  const onOriginalResumeHandler = async (item: {
    originalUrl: string;
    resumeFileName: string;
    id: string;
    pdfVersionUrl: string;
  }) => {
    if (urlExtension(item.originalUrl) === 'pdf') {
      const res = await signedUrlGetter({
        variables: {
          remoteFilePath: item.originalUrl,
        },
      });

      if (res?.data?.getSignedUrl?.signedUrl) {
        setDocumentDetail({
          url: res?.data?.getSignedUrl?.signedUrl,
          name: item?.resumeFileName,
          driveUrl: '',
          id: item.id,
        });
        setFileStatus('Original');
      }
    } else {
      const res = await signedUrlGetter({
        variables: {
          remoteFilePath: item.pdfVersionUrl,
        },
      });

      if (res?.data?.getSignedUrl?.signedUrl) {
        const pdfVersionFileName = removingExtension(item?.resumeFileName);

        setDocumentDetail({
          url: res?.data?.getSignedUrl?.signedUrl,
          name: `${pdfVersionFileName}.pdf`,
          driveUrl: '',
          id: item.id,
        });
        setFileStatus('Original');
      }
    }
  };

  useEffect(() => {
    if (candidateId) {
      getCandidate({ variables: { id: candidateId } });
      getCandidateNotes({ variables: { candidateId } });
    }
    if (companyId) {
      setSelectedCompany(companyId);
      getCompanyAndOwner({ variables: { id: companyId } });
    }
    if (jobOrderId) {
      getJobOrder({ variables: { id: jobOrderId } });

      getJobMainContactQuery({ variables: { id: jobOrderId } });
      // _getWriteUp(candidateId);
    }

    _getWriteUp(candidateId);
    // eslint-disable-next-line
  }, [candidateId, jobOrderId, companyId]);

  useEffect(() => {
    // this condition is used for job order salary type
    if (getSingleJobOrder?.getJobOrder?.rateType) {
      setSalaryRate({
        ...salaryRate,
        salaryType: getSingleJobOrder?.getJobOrder?.rateType,
      });
    }

    if (submissionReview?.getSubmissionfromJobApplicant && data?.getCandidate) {
      let values = submissionReview?.getSubmissionfromJobApplicant;
      let resumeInCandidate = data?.getCandidate?.resume?.find(
        (item) => item?.id === values?.resumeId
      );

      if (resumeInCandidate) {
        generateSignedUrl(
          resumeInCandidate?.fileType === 'pdf'
            ? resumeInCandidate?.originalUrl
            : resumeInCandidate?.pdfVersionUrl,
          resumeInCandidate?.resumeFileName,
          resumeInCandidate?.id
        );
      }

      setPdfVersionUrl(values?.resumeUrl);
      setPdfVersionName(values?.resumeName);
      setPdfVersionId(values?.resumeId);
      setTargetSalary(values?.targetSalary);
      setSalaryRate({
        salaryType: values?.salaryType,
        salary: priceHandler(`${values?.salary}`),
      });
    }

    // eslint-disable-next-line
  }, [
    submissionReview?.getSubmissionfromJobApplicant,
    data?.getCandidate,
    getSingleJobOrder?.getJobOrder?.rateType,
  ]);

  useEffect(() => {
    if (
      submissionReview?.getSubmissionfromJobApplicant ||
      writeUp?.checkCandidateWriteup?.writeupSummary
    ) {
      let submissionSummary =
        submissionReview?.getSubmissionfromJobApplicant?.submissionSummary;

      setEditWriteup(
        submissionSummary
          ? submissionSummary
          : writeUp?.checkCandidateWriteup?.writeupSummary
      );
      setWriteupId(writeUp?.checkCandidateWriteup?.id);
    }

    // eslint-disable-next-line
  }, [writeUp, submissionReview?.getSubmissionfromJobApplicant]);

  useEffect(() => {
    if (!submitModal) {
      getSubmissionReview();
    }

    // eslint-disable-next-line
  }, [submitModal]);

  useEffect(() => {
    if (
      getSingleJobOrder ||
      data ||
      writeUp ||
      companyOwner ||
      candidateNotes ||
      user
    ) {
      clearTimeout(time);
      time = setTimeout(() => {
        submissionErrors();
      }, 500);
    }

    // eslint-disable-next-line
  }, [getSingleJobOrder, data, writeUp, companyOwner, candidateNotes, user]);

  if (
    loading ||
    companyLoading ||
    jobOrderLoading ||
    getWriteUpLoading ||
    candidateNotesLoading ||
    !user
  ) {
    return <Loader />;
  }

  return (
    <div className="candidateSubmission">
      <GoBack
        url={location?.state?.backUrl || ''}
        search={location?.state?.search}
      />

      <div className="primaryHeading primaryHeaderSpacing d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center">
          <h5 className="m-0 mr-1">Candidate Submission</h5>
          {submissionReview?.getSubmissionfromJobApplicant?.status ===
            'DECLINE' && <Badge color="danger">Declined</Badge>}
        </div>
        
        {submissionReview?.getSubmissionfromJobApplicant?.id &&
          submissionReview?.getSubmissionfromJobApplicant?.status !==
            'DECLINE' &&
          submissionReview?.getSubmissionfromJobApplicant?.status === 'DRAFT' &&
          companyOwnerShareIds?.includes(user?.id || '') && (
            <Button
              className="border-0 mr-2"
              color="danger"
              onClick={() => setDeclineSub(!declineSub)}
            >
              Decline Submission
            </Button>
          )}
      </div>

      <div className="jobOrderRevamp candidateSubmission">
        <h5 className="mb-3">Job Order Widget</h5>
        <SubmissionJobOrderWidget jobOrder={getSingleJobOrder?.getJobOrder} />
      </div>
      <div className="jobOrderRevamp">
        <h5 className="mb-3">Candidate Widget</h5>
        <SubmissionWidget stats={data?.getCandidate} />
      </div>

      <Form>
        <div className="jobOrderRevamp ">
          <h5>Steps</h5>

          <div className="search-filter">
            <FormGroup className="w-100 m-0">
              <Row>
                <Col lg="6" md="6" sm="12" xs="12">
                  <Label className="text-muted">Salary Submitted At</Label>

                  <div className="relocate-allowed">
                    <FormGroup check className="p-0">
                      <Label className="radio-container w-100">
                        Annually
                        <Input
                          type="radio"
                          name="salaryType"
                          value="ANNUAL"
                          onClick={({ target: { value } }: any) =>
                            setSalaryRate({
                              ...salaryRate,
                              salaryType: value,
                            })
                          }
                          checked={
                            salaryRate.salaryType === 'ANNUAL' ? true : false
                          }
                          disabled={
                            !updatePermission ||
                            submissionReview?.getSubmissionfromJobApplicant
                              ?.status === 'COMPLETED'
                          }
                        />
                        <span className="checkmark"></span>
                      </Label>
                    </FormGroup>
                    <FormGroup check>
                      <Label className="radio-container w-100">
                        Hourly
                        <Input
                          type="radio"
                          value="HOURLY"
                          name="salaryType"
                          onClick={({ target: { value } }: any) =>
                            setSalaryRate({
                              ...salaryRate,
                              salaryType: value,
                            })
                          }
                          checked={salaryRate.salaryType === 'HOURLY'}
                          disabled={
                            !updatePermission ||
                            submissionReview?.getSubmissionfromJobApplicant
                              ?.status === 'COMPLETED'
                          }
                        />
                        <span className="checkmark"></span>
                      </Label>
                    </FormGroup>
                  </div>

                  <InputGroup>
                    <InputGroupAddon addonType="prepend">$</InputGroupAddon>

                    <Input
                      placeholder="Amount"
                      readOnly={
                        !updatePermission ||
                        submissionReview?.getSubmissionfromJobApplicant
                          ?.status === 'COMPLETED'
                      }
                      type="text"
                      value={salaryRate?.salary || null}
                      defaultValue="0"
                      onChange={({ target: { value } }) => {
                        // eslint-disable-next-line no-useless-escape
                        let numb = /^[1-9]\d*(\,\d{0,9})*(\.\d{0,9})?$/;
                        if (numb.test(value) || !value) {
                          let val = priceHandler(value);
                          setSalaryRate({
                            ...salaryRate,
                            salary: val,
                          });
                          setSalaryErrMsg('');
                          return;
                        }
                        if (!numb.test(value)) {
                          setSalaryErrMsg('Salary not valid!');
                          return;
                        }
                      }}
                    />
                  </InputGroup>

                  <small
                    className="text-danger d-block mt-2"
                    style={{ height: '2px' }}
                  >
                    {salaryErrMsg || ''}
                  </small>
                </Col>

                <Col
                  lg="6"
                  md="6"
                  sm="12"
                  xs="12"
                  style={{
                    position: 'relative',
                    top: 22,
                  }}
                >
                  <div>
                    <Label
                      className={`text-muted ${salaryErrMsg ? 'mt-2' : ''}`}
                    >
                      Target Salary <span className="text-danger">*</span>
                    </Label>
                  </div>

                  <div className="search-filter">
                    <FormGroup className="w-100 pt-3">
                      <Input
                        name="targetSalary"
                        placeholder="Target Salary"
                        innerRef={targetRef}
                        readOnly={
                          !updatePermission ||
                          submissionReview?.getSubmissionfromJobApplicant
                            ?.status === 'COMPLETED'
                        }
                        // type="textarea"
                        value={targetSalary || null}
                        defaultValue=""
                        onChange={({ target: { value } }) => {
                          setTargetSalary(value);
                          if (value) {
                            setTargetSalaryError(false);
                          } else {
                            setTargetSalaryError(true);
                          }
                        }}
                      />
                      {targetSalaryError && (
                        <small className="text-danger d-flex">
                          Target Salary is required{' '}
                        </small>
                      )}
                      <small className="text-info">
                        e.g. Evan is seeking a base of $50,000 to $55,000 in his
                        next role.
                      </small>
                    </FormGroup>
                  </div>
                </Col>
              </Row>
            </FormGroup>
          </div>
        </div>

        <div>
          <div className="CandidateTabsWrapper">
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '1' })}
                  onClick={() => {
                    toggleTab('1');
                    setWriteupResume(false);
                  }}
                >
                  Candidate Writeup
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '2' })}
                  onClick={() => {
                    toggleTab('2');
                    setWriteupResume(true);
                  }}
                >
                  Resumes
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '3' })}
                  onClick={() => {
                    toggleTab('3');
                    setWriteupResume(true);
                  }}
                >
                  Status History
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  tag={Link}
                  className={classnames({ active: activeTab === '4' })}
                  onClick={() => {
                    toggleTab('4');
                  }}
                  target="_blank"
                  to={`/notes-es?${new URLSearchParams({
                    candidateId: data?.getCandidate?.id,
                    candidateName: data?.getCandidate?.fullName,
                    jobOrderId: getSingleJobOrder?.getJobOrder?.id,
                    jobTitle: getSingleJobOrder?.getJobOrder?.jobTitle,
                    categoryLabel: 'Internal Submission Note',
                    categoryValue: 'Internal Submission Note',
                    contactId:
                      jobMainContact?.getJobOrderMainContact?.contact?.id,
                    contactName:
                      jobMainContact?.getJobOrderMainContact?.contact?.fullName,
                    companyId: getSingleJobOrder?.getJobOrder?.company?.id,
                    companyName: getSingleJobOrder?.getJobOrder?.company?.name,
                  })}`}
                >
                  Notes Search
                </NavLink>
              </NavItem>
            </Nav>

            <TabContent activeTab={activeTab}>
              <TabPane className="tab-pane-padding" tabId="1">
                <div>
                  <FormGroup>
                    <div className="d-flex justify-content-between align-items-center mb-3">
                      <Label className="text-muted">Candidate Writeup</Label>
                      {updatePermission && editWriteupValue && writeupId && (
                        <Button
                          className="quill-custom-btn px-4"
                          size="sm"
                          onClick={() => editOwnerShipWriteup()}
                          disabled={
                            userRoles?.includes('RECRUITER') &&
                            submissionReview?.getSubmissionfromJobApplicant
                              ?.status === 'COMPLETED'
                          }
                        >
                          {writeUpLoading && (
                            <Spinner
                              size="sm"
                              style={{ color: '#eaa827' }}
                              className="mr-2"
                            />
                          )}{' '}
                          Save Writeup
                        </Button>
                      )}
                    </div>

                    <div className="editor-height">
                      <ReactQuill
                        readOnly={
                          !updatePermission ||
                          submissionReview?.getSubmissionfromJobApplicant
                            ?.status === 'COMPLETED'
                        }
                        onChange={(value) => {
                          const reg = new RegExp(
                            /^<p>(<br>|<br\/>|<br\s\/>|\s+|)<\/p>$/gm
                          );

                          if (reg.test(value)) {
                            setEditWriteup(null);
                          } else {
                            setEditWriteup(value);
                          }
                        }}
                        value={editWriteupValue || ''}
                      />
                      <small className="text-danger pt-2">{errMsg}</small>
                    </div>
                  </FormGroup>
                </div>
              </TabPane>

              <TabPane tabId="2" className="tab-pane-padding">
                <div>
                  <div className="mobileResponsiveFlex mb-3">
                    <FormGroup className="resumeFormGroup">
                      <Label className="text-muted">Select resume</Label>
                      <Select
                        name="resume"
                        isDisabled={
                          !updatePermission ||
                          submissionReview?.getSubmissionfromJobApplicant
                            ?.status === 'COMPLETED'
                        }
                        options={data?.getCandidate?.resume?.map((item) => ({
                          label: item.resumeFileName,
                          value: {
                            id: item?.id,
                            url: item?.originalUrl,
                            driveUrl: item?.highlightUrl,
                            name: item?.resumeFileName,
                            // scrubUrl: item?.scrubUrl,
                            resumeFileType: item?.resumeFileType,
                            pdfVersionUrl: item?.pdfVersionUrl,
                            originalResumeUrl: item?.originalUrl,
                          },
                        }))}
                        value={
                          documentDetail?.id
                            ? {
                                label: documentDetail?.name,
                                value: { ...documentDetail },
                              }
                            : null
                        }
                        placeholder="Resume"
                        onChange={({ value }: any) => {
                          setUnsignedOriginalUrl(value?.url);
                          setUnsignedOriginalName(value?.name);
                          setOriginalResumeUrl(value?.originalResumeUrl);
                          setPdfVersionUrl(
                            urlExtension(value?.originalResumeUrl) === 'pdf'
                              ? value?.originalResumeUrl
                              : value?.pdfVersionUrl
                          );
                          setPdfVersionName(
                            urlExtension(value?.originalResumeUrl) === 'pdf'
                              ? value?.originalResumeUrl?.split(
                                  'candidates/'
                                )[1]
                              : value?.pdfVersionUrl?.split('candidates/')[1]
                          );
                          setPdfVersionId(value?.id);

                          setOriginalResumeName(value?.name);
                          const objectForOriginalResume = {
                            originalUrl: value?.url,
                            resumeFileName: value?.name,
                            id: value?.id,
                            pdfVersionUrl: value?.pdfVersionUrl,
                          };
                          onOriginalResumeHandler(objectForOriginalResume);
                          setDocumentDetail({ ...value });
                        }}
                        styles={{ ...customSelectDropDown(theme) }}
                      />

                      {!documentDetail?.id && (
                        <small className="text-info">No resume selected</small>
                      )}
                    </FormGroup>

                    <div className="d-flex justify-content-between align-items-center mb-0">
                      <button
                        type="button"
                        disabled={
                          !updatePermission ||
                          submissionReview?.getSubmissionfromJobApplicant
                            ?.status === 'COMPLETED'
                        }
                        className="buttonGenericStyle filledButton mr-3"
                        onClick={resumeUploadToggle}
                      >
                        Upload New
                      </button>

                      <button
                        type="button"
                        className="buttonGenericStyle filledButton"
                        disabled={
                          !(documentDetail?.url && documentDetail?.name) ||
                          downloadLoading
                        }
                        onClick={() => {
                          onResumeDownload();
                        }}
                      >
                        {downloadLoading && (
                          <img
                            src={DownloadImg}
                            alt="download"
                            className={`mr-2 mb-1 ${
                              downloadLoading && 'blink_me'
                            }`}
                          />
                        )}
                        {!downloadLoading ? 'Download' : 'Downloading'}
                      </button>
                    </div>
                  </div>

                  {documentDetail?.id && (
                    <div className="pdf-viewer">
                      <div className="d-flex justify-content-between align-items-center">
                      </div>
                      <div
                        style={{
                          width: '100%',
                          height: '100%',
                        }}
                      >
                        {fileStatus === 'Original' && (
                          <div className="pdf-viewer pdf-viewer-spacing">
                            <Preview
                              documentName={documentDetail?.name}
                              documentUrl={documentDetail?.url}
                              isDownload={false}
                              fileStatus={fileStatus}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </TabPane>

              <TabPane tabId="3" className="tab-pane-padding">
              </TabPane>
            </TabContent>
          </div>

          <ResumeUpload
            toggle={resumeUploadToggle}
            modal={resumeUploadModal}
            candidateId={data?.getCandidate?.id}
            refetch={() => {
              getCandidate({ variables: { id: match?.params?.id } });
            }}
            fileType=".doc, .docx,.pdf"
            driveUpload={true}
          />

          <div>
            <div className="text-right pt-3">
              {updatePermission && (
                <>
                  <CustomButton
                    buttonText="Cancel"
                    buttonColor="default"
                    buttonType="button"
                    className="big-width mr-2"
                    buttonClick={() => {
                      history.push(location?.state?.backUrl);
                    }}
                  />
                  <CustomButton
                    buttonText={`${
                      (isRecruiter || onlyCandidateOwner) &&
                      submissionReview?.getSubmissionfromJobApplicant
                        ?.status !== 'COMPLETED'
                        ? 'Save Submission'
                        : submissionReview?.getSubmissionfromJobApplicant
                            ?.status === 'COMPLETED'
                        ? 'Preview Mail'
                        : 'Send Candidate'
                    }`}
                    buttonColor="primary"
                    buttonType="button"
                    className="big-width"
                    loading={submitting}
                    buttonClick={handleSubmission}
                    disabled={
                      !documentDetail?.id
                        ? true
                        : false &&
                          (submitting ||
                            (isRecruiter &&
                              submissionReview?.getSubmissionfromJobApplicant
                                ?.status === 'COMPLETED'))
                    }
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </Form>

      <ApplicantNotes applicantId={applicantId} type="Submission" />
      {submissionErr?.modal && (
        <SubmittedWarningsModal
          err={submissionErr}
          backUrl={location?.state?.backUrl || ''}
        />
      )}
      
      <SubmitModal
        isOpen={submitModal}
        toggle={setSubmitModal}
        companyId={selectedCompany}
        jobOrder={jobOrderId}
        backUrl={location?.state?.backUrl || ''}
        // createSubmission={createSubmission}
        submissionId={submissionId}
        isRecruiter={isRecruiter}
        // defaultTargetSalary={`${data?.getCandidate?.fullName} is seeking a base of ${targetSalary} in his next role. `}
        defaultTargetSalary={targetSalary}
        mainContact={
          getSingleJobOrder?.getJobOrder?.mainContact?.contact?.contactEmail
        }
        candidateDetail={{
          writeUp: editWriteupValue,
          candidateName: data?.getCandidate?.fullName,
          resumeId: documentDetail?.id,
          resumeUrl: unsignedOriginalUrl ? unsignedOriginalUrl : pdfVersionUrl,
          resumeName: unsignedOriginalName
            ? unsignedOriginalName
            : pdfVersionName,
        }}
      />

      <ConfirmPopUp
        confirmText="Company of this job order has no owner!"
        isOpen={confirmToggle}
        toggle={setConfirmToggle}
        confirmAction={() => setConfirmToggle(!confirmToggle)}
        modalHeading="Message Alert"
        btnText="OK"
        btnColor="primary"
      />
      
      <ConfirmPopUp
        confirmText="Candidate is submitted manually!"
        isOpen={manualAlert}
        toggle={setManualAlert}
        confirmAction={() =>
          history.push(
            `/candidate-pipeline/view/${jobOrderId}?candidateFilter=Submitted`
          )
        }
        modalHeading="Message Alert"
        btnText="OK"
        btnColor="primary"
      />

      <SubmissionReview
        data={submissionReview?.getSubmissionfromJobApplicant}
        open={submissionComplete}
        toggle={() => {
          setSubmissionComplete(!submissionComplete);
        }}
      />

      {declineSub && (
        <DeclineSubmissionModal
          submissionId={submissionReview?.getSubmissionfromJobApplicant?.id}
          applicantId={applicantId}
          refetch={refetch}
          isOpen={declineSub}
          toggle={() => {
            setDeclineSub(!declineSub);
          }}
        />
      )}
    </div>
  );
};

export default withRouter(CandidateSubmission);
