import { yupResolver } from '@hookform/resolvers/yup';
import { useContext, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import { toast } from 'react-toastify';
import {
  Col,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap';
import * as Yup from 'yup';
import CustomButton from '../../components/layout/CustomButton';
import InputField from '../../components/layout/InputField';
import { customSelectStyleNew } from '../../components/styled/customSelect';
import { ReportEnum } from '../../constants';
import { AppContext } from '../../context';
import { customStylesReportEditDropdown, reportCategory, URL_REGEX } from '../../utils/constant';

const validationSchema = Yup.object({
  name: Yup.string().required('Report Name is required!'),
  link: Yup.string().required('Report Link is required!').matches(URL_REGEX, "Invalid URL"),
});

const UpdateReportModal = ({
  toggle,
  isOpen,
  reportObj,
  updateReport,
  refetchData,
}) => {
  const { theme } = useContext(AppContext);
  const { handleSubmit, formState: {errors}, register, reset, control, setValue } = useForm({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (reportObj.id) {
      reset({
        name: reportObj.name,
        link: reportObj.link,
        category: { label: ReportEnum[reportObj?.category], value: reportObj.category}, 
        id: reportObj.id,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateReportHandler = async (data) => {
    data.id = reportObj?.id;
    const { category, ...result } = data;
    const finalInput = {
      category: category?.value,
      ...result,
    }
    let response = await updateReport({
      variables: { input: finalInput },
    });
    if (response?.data?.updateReport?.status === 200) {
      toggle();
      refetchData();
      toast.success('Report Updated Successfully!');
    } else {
      toggle();
      toast.error('Report could not be Updated!');
    }
  };
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className="revampDialog revampDialogWidth"
      centered
    >
      <ModalHeader className="py-3">
        <h4>Update Report</h4>
      </ModalHeader>
      <form onSubmit={handleSubmit(updateReportHandler)}>
        <ModalBody className="pt-3">
          <Row>
            <Col>
              <InputField
                inputtype="text"
                label="Report Name"
                inputid="name"
                isRequired={true}
                placeholder="Update Report Name..."
                inputRef={register({
                  required: {
                    value: true,
                    message: 'Report Name is required!',
                  },
                })}
                errMsg={errors?.name?.message}
              />
            </Col>
            <Col>
              <InputField
                inputtype="text"
                label="Report Link"
                inputid="link"
                isRequired={true}
                placeholder="Update Report Link..."
                inputRef={register({
                  required: {
                    value: true,
                    message: 'Report Link is required!',
                  },
                })}
                errMsg={errors?.link?.message}
              />
            </Col>
          </Row>

          <Row>
            <Col>
              <Controller
                name='category'
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'Must Select Category!',
                  },
                }}
                placeHolder="Select report category"
                render={({value}) => (
                  <>
                    <Label>
                      Report Category
                      <span className="text-danger"> *</span>
                    </Label>
                    <Select
                      value={value}
                      options={reportCategory}
                      onChange={(object) => {
                        setValue('category', object)
                      }}
                      styles={{ ...customSelectStyleNew(theme) }}
                      isDisabled={true}
                    />
                  </>
                )}
                styles={customStylesReportEditDropdown}
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter className='p-0'>
          <div className="d-flex justify-content-end align-items-center w-100">
              <button
                className="btn btn-primary buttonPrimary m-2"
                onClick={() => {
                  reset();
                  toggle();
                }}
                type="button"
              >
                Cancel
              </button>
              <CustomButton
                buttonText="Save"
                buttonColor="default"
                buttonType="submit"
                className="btn-primary py-2 m-3"
              />
          </div>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default UpdateReportModal;
