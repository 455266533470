import { useLazyQuery, useMutation } from '@apollo/client';
import { useCallback, useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
  Button,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap';
import CustomButton from '../../../components/layout/CustomButton';
import InputField from '../../../components/layout/InputField';
import { compactObject } from '../../../utils/commonFn';

import SEARCH_IMG from '../../../assets/images/search.svg';

import moment from 'moment';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import ConfirmPopUp from '../../../components/ConfirmPopUp';
import NoDataFound from '../../../components/layout/NoDataFound';
import Pagination from '../../../components/layout/Pagination';
import { AppContext } from '../../../context';
import {
  APPLICANT_STATUS,
  jobApplicantStatusForCandidate,
  PAGE_LIMIT,
  VIEW_PLACEMENT_MANAGEMENT_ROUTE,
} from '../../../utils/constant';
import ClientCandidateReasonModal from '../../candidate-pipeline/ClientCandidateReasonModal';
import CandidateCalendarInviteModal from '../../candidate-pipeline/interviews/calendar-invite/candidate/CandidateCalendarInviteModal';
import ClientCalendarInviteModal from '../../candidate-pipeline/interviews/calendar-invite/client/ClientCalendarInviteModal';
import {
  DECREMENT_IN_COUNT,
  UPDATE_INTERVIEW_FLAG,
} from '../../candidate-pipeline/interviews/gql';
import InterviewScheduleModal from '../../candidate-pipeline/interviews/InterviewScheduleModal';
import CreateEditOffer from '../../candidate-pipeline/offers/CreateEditOffer';
import { UPDATE_OFFER_STATUS, VERIFY_INTERVIEW } from '../../candidate-pipeline/offers/gql';
import InterviewViewDrawer from '../../candidate/application-flow/interview/InterviewViewDrawer';
import OfferViewDrawer from '../../candidate/application-flow/offers/OfferViewDrawer';
import SubmissionViewDrawer from '../../candidate/application-flow/submission/SubmissionViewDrawer';
import { GET_INTERVIEW_EMAILS } from '../../candidate/gql';
import AddRecruiterFolderModal from '../../recruiter-folder/folderComponents/AddRecruiterFolderModal';
import {
  GET_JOB_APPLICANTS_FOR_JOB,
  UPDATE_APPLICANTS_STATUS,
  UPDATE_APPLICANT_UPDATE,
  UPDATE_JOB_APPLICANT_STATUS_TO_INTERVIEW,
} from '../gql';
import JobApplicantDetailForJob from './JobApplicantDetailForJob';
import Loader from '../../../components/layout/Loader';
import ReplacementModal from '../../candidate/candidate-components/JobApplicationStatus/ReplacementModal';

let time = 0;

const JobApplicantTab = ({
  jobOrder,
  isEdit = false,
}: {
  jobOrder: any;
  isEdit?: boolean;
}) => {
  const _currentPage: number = parseInt('1');
  const [currentPage, setCurrentPage] = useState(_currentPage);
  const [limit, setLimit] = useState(10);
  const { theme } = useContext(AppContext);
  const [actionData, setActionData] = useState<any>([]);
  const [confirmBox, setConfirmBox] = useState(false);
  const [currentBulkAction, setCurrentBulkAction] = useState('');
  const [statusFilter, setStatusFilter] = useState<Array<any>>([]);
  const [recruiterFolderModal, setRecruiterFolderModal] = useState(false);
  const [pendingInterview, setPendingInterview] = useState(false);
  const [pendingSubmission, setPendingSubmission] = useState(false);
  const [acountManagerPernmission, setAcountManagerPernmission] = useState<boolean>(false);

  //job order app history code
  const history = useHistory();
  const [applicantDetail, setApplicantDetail] = useState<any>({});
  const [rejectDetail, setRejectDetail] = useState<any>({});
  const [submissionViewToggle, setSubmissionViewToggle] =
    useState<boolean>(false);
  const [notesModal, setNotesModal] = useState<boolean>(false);
  const [firstInterview, setFirstInterview] = useState<boolean>(false);
  const [backToInterview, setBackToInterview] = useState<boolean>(false);
  const [candidateInterviewEmailData, setCandidateInterviewEmailData] =
    useState({});
  const [clientInterviewEmailData, setClientInterviewEmailData] = useState({});
  const [latestInterviewData, setLatestInterviewData] = useState<any>(null);
  const [interviewScheduleModal, setInterviewScheduleModal] =
    useState<boolean>(false);
  const [interviewDetailToggle, setInterviewDetailToggle] =
    useState<boolean>(false);
  const [calendarInviteModal, setCalendarInviteModal] =
    useState<boolean>(false);
  const [clientCalendarInviteModal, setClientCalendarInviteModal] =
    useState<boolean>(false);
  const [offerModal, setOfferModal] = useState<boolean>(false);
  const [offerDrawerToggle, setOfferDrawerToggle] = useState<boolean>(false);

  // for replacement workflow (ATS-1011)
  const [replacementModalToggle, setReplacementModalToggle] = useState<boolean>(false);
  const [placementId, setPlacementId] = useState<string>('');

  const [editOfferToggle, setEditOfferToggle] = useState<boolean>(false);
  const [counterOfferToggle, setCounterOfferToggle] = useState<boolean>(false);
  const [counterDecrementInPendingAction] = useMutation(DECREMENT_IN_COUNT);
  const [updateFlag] = useMutation(UPDATE_INTERVIEW_FLAG);
  const [latestOffer, setLatestOffer] = useState<any>(null);

  const [getInterviewEmails, { data: getInterviewEmailsData }] =
    useLazyQuery(GET_INTERVIEW_EMAILS);
  const [updateApplicantStatus] = useMutation(UPDATE_APPLICANT_UPDATE);
  const [updateOfferStatusMutation] = useMutation(UPDATE_OFFER_STATUS);

  const [updateApplicantsStatus] = useMutation(UPDATE_APPLICANTS_STATUS);
  const [searchParams, setSearchParams] = useState<string>('');
  const [delecinedCheckBox, setDeclinedCheckBox] = useState<boolean>(false);
  const [verifyInterview] = useMutation(VERIFY_INTERVIEW);

  const [
    getJobApplicantsForJob,
    { data: jobApplicantsList, loading: waiting, refetch },
  ] = useLazyQuery(GET_JOB_APPLICANTS_FOR_JOB);

  const [updateJobApplicantStatusInterview] = useMutation(
    UPDATE_JOB_APPLICANT_STATUS_TO_INTERVIEW
  );

  const getJobOrderApplicants = () => {
    let filter = statusFilter?.map((item) => item.value);

    if (jobOrder?.id) {
      getJobApplicantsForJob({
        variables: {
          limit,
          page: currentPage,
          jobOrderId: jobOrder?.id,
          status: filter?.length ? filter : 'All',
          searchParams: '',
          pendingInterview,
          pendingSubmission,
        },
      });
    }

  };

  const setQueryParams = (searchParams) => {
    const criteria = compactObject({
      currentPage,
      searchParams,
      statusFilter,
    });
    const urlQueryParams: any = [];
    if (Object.keys(criteria).length) {
      for (const key in criteria) {
        if (
          Object.prototype.hasOwnProperty.call(criteria, key) &&
          criteria[key]
        ) {
          urlQueryParams.push(`${key}=${criteria[key]}`);
        }
      }
    }
  };

  const searchControl = (value: string) => {
    setQueryParams(value);
    setSearchParams(value);
    setCurrentPage(1);
    let filter = statusFilter?.map((item) => item.value);
    if (!value || value?.length > 2) {
      window.clearTimeout(time);
      time = window.setTimeout(() => {
        if (jobOrder) {
          getJobApplicantsForJob({
            variables: {
              limit,
              page: currentPage,
              jobOrderId: jobOrder?.id,
              status: filter?.length ? filter : 'All',
              searchParams: value,
              pendingInterview,
              pendingSubmission,
            },
          });
        }
      }, 1000);
    }
  };

  const handleBulkAction = async () => {
    if (currentBulkAction !== 'Clear Pending Interviews') {
      const objectTypeData = actionData?.filter((data) => typeof data !== 'string')
      const applicantIds = objectTypeData.map((data) => {
        return data.userId;
      });
      let res = await updateApplicantsStatus({
        variables: { data: { applicantIds, status: currentBulkAction } },
      });
      if (res?.data) {
        // setConfirmBox(false);
        toast.success('Successfully Updated!');
        searchControl('');
      }
      setCurrentBulkAction('');
      setActionData([]);
      searchControl('');
    } else {
      const pendingInterviewIds: [string] = actionData?.filter((data) => typeof data === 'string')
      let verifiedInterviewResponse = await verifyInterview({
        variables: { verifyInterviewInput: { interviewIds: pendingInterviewIds } },
      });
      if (verifiedInterviewResponse?.data?.verifyInterview?.status === 200) {
        getJobOrderApplicants()
        toast.success('Successfully Updated!');
      }
      setCurrentBulkAction('');
      setActionData([]);
      searchControl('');
    }
  };

  useEffect(() => {
    if (jobOrder) {
      document.title = 'Loading...';
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getJobOrderApplicants();

    // eslint-disable-next-line
  }, [statusFilter, limit, currentPage, pendingInterview, pendingSubmission]);

  useEffect(() => {
    if (jobOrder) {
      getJobApplicantsForJob({
        variables: {
          limit,
          page: currentPage,
          jobOrderId: jobOrder?.id,
          status: 'All',
          searchParams: '',
        },
      });
    }

    // eslint-disable-next-line
  }, [jobOrder]);

  useEffect(() => {
    if (jobOrder?.jobTitle)
      document.title = `${jobOrder?.jobTitle || 'Job'} - Applicants -RealREPP`;

    // eslint-disable-next-line
  }, [jobOrder?.jobTitle]);

  // eslint-disable-next-line array-callback-return
  const filteredObj = actionData?.filter((item) => {
    if (item?.url) {
      if (!item?.url?.includes('linkedin')) {
        return item;
      }
    }
  });

  const filteredEmails = filteredObj.map((item) => {
    return item?.url;
  });

  const customStyles = {
    control: (styles, provided) => ({
      ...styles,
      background: theme === 'light' ? provided.background : '#282828',
      borderColor: theme === 'light' ? '#ced4da' : '#525252',
      color: theme === 'light' ? provided.background : '#d9d9d9',
      fontSize: 12,
      '&:hover': {
        borderColor: theme === 'light' ? '#ced4da' : '#525252',
        color: theme === 'light' ? provided.background : '#d9d9d9',
      },
    }),
    menu: (provided) => ({
      ...provided,
      background: theme === 'light' ? provided.background : '#282828',
      zIndex: 999999,
      color: theme === 'light' ? provided.background : '#d9d9d9',
    }),
    option: (provided) => ({
      ...provided,
      fontSize: 12,
      background: theme === 'light' ? provided.background : '#363636',
      '&:hover': {
        background: theme === 'light' ? provided.background : '#474444',
        color: theme === 'light' ? provided.background : '#d9d9d9',
      },
    }),
    container: (provided, state) => ({
      ...provided,
      fontSize: 12,
      minWidth: 200,
      maxWidth: 'auto',
    }),
  };

  const updateStatusToInterview = async (id: string) => {
    const res = await updateJobApplicantStatusInterview({
      variables: { id },
    });

    if (res?.data) {
      toast.success('The job app status is set to Interviewed');
    }
  };

  const actionHandler = (data, type) => {
    setApplicantDetail({ ...data, JobOrder: jobOrder, type });

    switch (type) {
      case 'View Submission Detail':
        setSubmissionViewToggle(true);
        break;

      case 'Client Decline':
        setRejectDetail({ ...data, type });
        setNotesModal(!notesModal);
        break;

      case 'Candidate Decline':
        setRejectDetail({ ...data, type });
        setNotesModal(!notesModal);
        break;

      case 'Schedule First Interview':
        interviewSpecificDetail({ ...data, type });
        setFirstInterview(true);
        setInterviewScheduleModal(true);
        break;

      case 'View Interview Details':
        interviewSpecificDetail({ ...data, type });
        getLatestInterview({ ...data, type });
        setInterviewDetailToggle(true);
        break;

      case 'Schedule Next Interview':
        interviewSpecificDetail({ ...data, type });
        setFirstInterview(false);
        setInterviewScheduleModal(true);
        break;

      case 'Back To Interview':
        updateStatusToInterview(data?.id)
        refetch()
        break;

      case 'Send Candidate Invite':
        getLatestInterview({ ...data, type });
        setCalendarInviteModal(true);
        break;

      case 'Send Client Invite':
        getLatestInterview({ ...data, type });
        setClientCalendarInviteModal(true);
        break;

      case 'Clear Pending Client Action':
        clientFlag({ data, clientInviteFlag: true });
        break;

      case 'Clear Pending Candidate Action':
        clientFlag({ data, candidateInviteFlag: true });
        break;
      case 'Clear Pending Interviews':
        findPendingInterview({ ...data })
        break;

      case 'Convert to Offer':
        latestOfferFn({ ...data, type });
        getLatestInterview({ ...data, type });
        setOfferModal(!offerModal);
        break;

      case 'View Offer Details':
        setOfferDrawerToggle(true);
        break;

      case 'Move To Placement':
        updateOfferToPlacement({ ...data, JobOrder: jobOrder, type });
        // setPlacementMoveToggle(true);
        break;

      case 'Update Offer':
        latestOfferFn({ ...data, type });
        setCounterOfferToggle(false);
        setEditOfferToggle(!editOfferToggle);
        setOfferModal(!offerModal);
        break;

      case 'Counter Offer':
        latestOfferFn({ ...data, type });
        setEditOfferToggle(false);
        setCounterOfferToggle(true);
        setOfferModal(true);
        break;
      default:
        setConfirmBox(true);
        break;
    }
  };
  const findPendingInterview = (pendingInterview) => {
    let pendingInterviews = pendingInterview?.interviews.filter(interview => interview?.InterviewState === "PENDING").map(pendingInterview => pendingInterview?.id);
    veriyinterviews(pendingInterviews)
  }

  const veriyinterviews = async (pendingInterviewIds) => {
    let verifiedInterviewResponse = await verifyInterview({
      variables: { verifyInterviewInput: { interviewIds: pendingInterviewIds } },
    });
    if (verifiedInterviewResponse?.data?.verifyInterview?.status === 200) {
      refetch()
      setActionData([])
      toast.success('Successfully Updated!');
    }

  }
  const interviewSpecificDetail = (data) => {
    const actualJobLocation = jobOrder?.companyLocation
      ? ['US', null, undefined, ''].includes(jobOrder?.companyLocation.country) ? `${data?.JobOrder?.companyLocation?.streetAddress}, ${jobOrder?.companyLocation?.city}, ${jobOrder?.companyLocation?.state}, ${jobOrder?.companyLocation?.zipCode}` : `${data?.JobOrder?.companyLocation?.streetAddress}, ${jobOrder?.companyLocation?.city}, ${jobOrder?.companyLocation?.zipCode}, ${jobOrder?.companyLocation?.country}`
      : '--';
    setApplicantDetail({
      ...data,
      jobTimezone: jobOrder?.timezone,
      jobTimezoneId: jobOrder?.timezoneId,
      jobDstOffset: jobOrder?.dstOffset,
      jobOrderId: jobOrder?.id,
      candidateId: data?.candidate?.id,
      companyId: jobOrder?.company?.id,
      jobApplicantId: data?.id,
      company: jobOrder?.company?.name || '',
      jobTitle: jobOrder?.jobTitle || '',
      name: data?.candidate?.fullName || '',
      jobLocation: actualJobLocation,
      workplaceTypes: jobOrder?.workplaceTypes
    });
  };

  const getLatestInterview = (data) => {
    let interview =
      data?.interviews?.length && data?.interviews?.find((item) => !item?.locked);
    if (interview) {
      let interviewDate = {
        interviewDateTime: moment(interview?.interviewDateTime)
          .utcOffset(interview?.timeZoneId)
          .format('MM/DD/YYYY'),
        time: moment(interview?.interviewDateTime)
          .utcOffset(interview?.timeZoneId)
          .format('hh:mm A'),
      };
      setLatestInterviewData({ ...interview, ...interviewDate });
    } else {
      setLatestInterviewData(null);
    }
  };

  const latestOfferFn = (data) => {
    let latest = data?.offers?.find((item) => !item?.locked);
    setLatestOffer(latest);
  };

  const clientFlag = async (value) => {
    let interview = value?.data?.interviews?.find((item) => !item?.locked);

    if (interview) {
      const { data } = await counterDecrementInPendingAction({
        variables: { category: 'Interview' },
      });
      let UpdateInterviewFlagInput: any = {};
      if (value?.candidateInviteFlag) {
        UpdateInterviewFlagInput = {
          interviewId: interview?.id,
          candidateInviteFlag: value?.candidateInviteFlag,
        };
      } else if (value?.clientInviteFlag) {
        UpdateInterviewFlagInput = {
          interviewId: interview?.id,
          clientInviteFlag: value?.clientInviteFlag,
        };
      }

      await updateFlag({ variables: { UpdateInterviewFlagInput } });

      if (data) {
        const { status } = data.counterDecrementInPendingAction;
        if (status === 200) {
          toast.success('Updated Successfully!');
          // searchControl('');
        }
      }
    }
  };

  const changeApplicantStatus = async () => {
    let { id, type } = applicantDetail;
    let res = await updateApplicantStatus({
      variables: { applicantId: id, status: type === "Submit_Again" ? "SUBMITTED" : type },
    });
    if (res?.data) {
      setConfirmBox(false);
      toast.success('Successfully Updated!');
      searchControl('');
    }
  };

  // this method is used for the convert the latest offer into offer
  const updateOfferToPlacement = useCallback(async (data?: { offers?: string, id?: string, replacementId?: string }, isReplacement: boolean = true) => {
    const latestOffer = (data?.offers || applicantDetail?.offers)?.find((item) => !item?.locked);
    if (!latestOffer) {
      toast.warn("Placement offer is not locked")
      return
    };
    const response = await updateOfferStatusMutation({
      variables: {
        updatingOfferStatusInput: {
          id: latestOffer?.id,
          jobApplicantId: data?.id || applicantDetail?.id,
          status: 'MOVE_TO_PLACEMENT',
          replacementId: data?.replacementId,
          isReplacement,
        },
      },
    });

    if (response?.data) {
      const updatingOfferStatus = response?.data?.updatingOfferStatus;
      // status 202 for replacement workflow (ATS-1011)
      if (updatingOfferStatus?.status === 202) {
        setReplacementModalToggle(!replacementModalToggle);
        setPlacementId(updatingOfferStatus?.placementId);
      } else if (updatingOfferStatus?.status === 400) {
        toast.error('Replacement Days Must be a Number!');
      } else {
        toast.success('Offer status updated successfully');
        // supposing we are receiving an id of placement and view that placement
        if (updatingOfferStatus?.placementId) {
          history.push({
            pathname: `${VIEW_PLACEMENT_MANAGEMENT_ROUTE}/${updatingOfferStatus?.placementId}`,
          });
        }
      }
    }
  }, [applicantDetail?.offers, applicantDetail?.id, updateOfferStatusMutation, replacementModalToggle, history]);

  useEffect(() => {
    if (getInterviewEmailsData?.getInterviewEmails?.interviewEmails?.length) {
      const clientInterviewEmail =
        getInterviewEmailsData?.getInterviewEmails?.interviewEmails?.find(
          (item: { isToClient: boolean }) => item?.isToClient
        );

      const candidateInterviewEmails =
        getInterviewEmailsData?.getInterviewEmails?.interviewEmails?.find(
          (item: { isToClient: boolean }) => !item?.isToClient
        );

      if (candidateInterviewEmails) {
        setCandidateInterviewEmailData(candidateInterviewEmails);
      }

      if (clientInterviewEmail) {
        let newObj: any = { ...clientInterviewEmail };
        newObj.attachingFromCandidateEmails = false;
        setClientInterviewEmailData(newObj);
      } else {
        let newObj: any = { ...candidateInterviewEmails };
        newObj.attachingFromCandidateEmails = true;
        setClientInterviewEmailData(newObj);
      }
    }

    // eslint-disable-next-line
  }, [getInterviewEmailsData]);

  useEffect(() => {
    if (latestInterviewData) {
      getInterviewEmails({
        variables: { interviewId: latestInterviewData?.id },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [latestInterviewData]);


  useEffect(() => {

    const expectDeclinedApps = jobApplicantStatusForCandidate
      .filter(item => !['Declined', 'Client Decline', 'Candidate Decline'].includes(item.value))
      .map(item => item.value);

    let filter = delecinedCheckBox ? [] : [...expectDeclinedApps]

    if (jobOrder?.id) {
      getJobApplicantsForJob({
        variables: {
          limit,
          page: currentPage,
          jobOrderId: jobOrder?.id,
          status: filter?.length ? filter : 'All',
          searchParams: '',
          pendingInterview,
          pendingSubmission,
        },
      });
    }

  }, [currentPage, delecinedCheckBox, getJobApplicantsForJob, jobOrder?.id, limit, pendingInterview, pendingSubmission])

  return (
    <div>
      <div className="d-flex justify-content-start align-items-start flex-wrap gap-10 primaryHeaderSpacing">
        <div>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <img src={SEARCH_IMG} alt="search" />
              </InputGroupText>
            </InputGroupAddon>

            <Input
              type="text"
              className="search-by-company"
              placeholder="Search by name or email"
              value={searchParams}
              onChange={(e) => {
                searchControl(e.target.value);
                setSearchParams(e.target.value);
              }}
            />
          </InputGroup>

          <small className="text-info">
            {searchParams &&
              searchParams.length <= 2 &&
              ' Please add more than two characters'}
          </small>
        </div>

        <div className="filter-status" style={{ flexWrap: 'wrap' }}>
          {/* <div className="filter-by"> */}
          <div className="jobapp-category-dropdown mt-md-0">
            <Select
              isMulti
              isSearchable
              isClearable
              styles={customStyles}
              options={jobApplicantStatusForCandidate}
              value={statusFilter?.length ? statusFilter : null}
              onChange={(val: any) => {
                setStatusFilter(val);
                setCurrentPage(1);
              }}
              isDisabled={pendingSubmission || pendingInterview}
            />
          </div>

          {/* <div className='mx-3'></div> */}

          <div className="jobapp-category-dropdown mt-md-0 d-flex flex-wrap gap-10">
            <button
              style={{
                backgroundColor: `${pendingSubmission ? '#f4f3f3' : 'white'}`,
                color: `${pendingSubmission ? '#eaa827' : '#495057'}`,
                border: `${'1px solid #ced4da'}`,
              }}
              className="filter-job-order ml-3"
              onClick={() => {
                setPendingSubmission(!pendingSubmission);
                setPendingInterview(false);
              }}
              type="button"
            >
              My Pending Submissions
            </button>

            <button
              style={{
                backgroundColor: `${pendingInterview ? '#f4f3f3' : 'white'}`,
                color: `${pendingInterview ? '#eaa827' : '#495057'}`,
                border: `${'1px solid #ced4da'}`,
              }}
              className="filter-job-order ml-3"
              onClick={() => {
                setPendingInterview(!pendingInterview);
                setPendingSubmission(false);
              }}
              type="button"
            >
              My Pending Interview
            </button>
          </div>

          <button
            className="buttonGenericStyle filledButton ml-sm-3 mt-md-0"
            onClick={() => {
              setStatusFilter([]);
              setSearchParams('');
              setPendingInterview(false);
              setPendingSubmission(false);
            }}
          >
            Clear
          </button>

          <div className=' d-flex align-items-center mx-5 gap-10'>
            <Input
              style={{ height: '20px', width: '20px' }}
              type="checkbox"
              checked={delecinedCheckBox}
              onChange={(e) => {
                setDeclinedCheckBox(e.target.checked)
              }}
            />
            <span className="checkboxTextStyle pl-lg-0 pl-2 ml-2">
              Included Declined Apps
            </span>
          </div>

        </div>
      </div>
      {actionData?.length >= 1 && (
        <div className="sticky-action-container mt-3">
          <div className="d-selected-num bulk_actions_label">Bulk Actions:</div>
          <div className="d-selected-num">{actionData?.length} Selected</div>
          <div className="sticky-action-btns">
            {filteredEmails.length ? (
              <a
                href={
                  filteredEmails?.length
                    ? `mailto:${filteredEmails.join(',')}`
                    : '#'
                }
                className="m-1 d-block bg-light text-dark p-2 py-1 btn-sm"
              >
                Send Email
              </a>
            ) : (
              <button
                type="button"
                disabled={true}
                className="m-1 d-block bg-light text-dark p-2 py-1 btn-sm disabled-btn"
              >
                Send Email
              </button>
            )}
            <Button
              className="m-1 d-block px-3 py-1 border-0 buttonGenericStyle filledButton"
              onClick={() => setRecruiterFolderModal(!recruiterFolderModal)}
            >
              Add to Recruiter Folder
            </Button>

            <Button
              className="m-1 d-block bg-light text-dark px-3 py-1 btn-sm border-0"
              onClick={() => veriyinterviews(actionData)}
              disabled={!acountManagerPernmission}

            >
              Clear Pending Interviews
            </Button>
          </div>

          <InputField
            style={{ minWidth: 'max-content' }}
            className="save-changes"
            inputtype="select"
            selectItems={APPLICANT_STATUS}
            inputid="currentBulkAction"
            disabled={actionData?.length ? true : false}
            inputValue={currentBulkAction}
            placeholder="Status Update"
            inputMethod={(value) => {
              setCurrentBulkAction(value);
            }}
          />

          <CustomButton
            buttonText="Update"
            buttonType="button"
            buttonColor="primary"
            disabled={currentBulkAction && actionData?.length ? false : true}
            className="save-changes ml-3 mr-2"
            buttonClick={handleBulkAction}
          />
        </div>
      )}

      {!waiting &&
        !!jobApplicantsList?.getApplicantsOfJob?.jobApplicants?.length &&
        jobApplicantsList?.getApplicantsOfJob?.jobApplicants.map((item) => {
          return (
            <JobApplicantDetailForJob
              data={{ ...item, JobOrder: jobOrder }}
              actionHandler={actionHandler}
              setActionData={setActionData}
              actionData={actionData}
              isEdit={isEdit}
              setAcountManagerPernmission={setAcountManagerPernmission}
            />
          )
        })}

      {!waiting &&
        !jobApplicantsList?.getApplicantsOfJob?.jobApplicants?.length && (
          <NoDataFound text="No Applicant Found!" />
        )}

      {waiting &&
        !jobApplicantsList?.getApplicantsOfJob?.jobApplicants?.length && (
          <div
            style={{ maxHeight: '50vh' }}
            className="d-flex align-items-center justify-content-center"
          >
            <Loader />
          </div>
        )}

      {jobApplicantsList?.getApplicantsOfJob?.jobApplicants?.length ? (
        <div className="users-pagination">
          <Pagination
            onPageChange={(pageClicked: number) => {
              setCurrentPage(pageClicked);
            }}
            pageCount={Math.ceil(
              jobApplicantsList?.getApplicantsOfJob?.count / limit
            )}
            currentPage={currentPage}
          />

          <div className="d-flex align-items-center">
            <div className="pagination-display paginationItemsSelect">
              <p className="m-0">
                {(currentPage - 1) * limit + 1} to{' '}
                {Math.min(
                  currentPage * limit,
                  jobApplicantsList?.getApplicantsOfJob?.count
                )}{' '}
                of {jobApplicantsList?.getApplicantsOfJob?.count}
              </p>

              <div className="divider"></div>

              <label>Display</label>

              <InputField
                label=""
                inputtype="select"
                selectItems={PAGE_LIMIT.filter((item) => item.value !== limit)}
                inputid="limit"
                placeholder={limit.toString()}
                inputMethod={(value: string) => {
                  setQueryParams(searchParams);
                  setCurrentPage(1);
                  setLimit(parseInt(value));
                }}
              />
            </div>
          </div>
        </div>
      ) : null}

      {recruiterFolderModal && (
        <AddRecruiterFolderModal
          isOpen={recruiterFolderModal}
          toggle={() => setRecruiterFolderModal(!recruiterFolderModal)}
          recordToAdd={actionData}
          refetch={getJobOrderApplicants}
          clearRecord={() => setActionData([])}
        />
      )}



      {/* add modal for the submission action and interview  */}

      {/* submission view drawer  */}
      {submissionViewToggle && (
        <SubmissionViewDrawer
          toggle={() => setSubmissionViewToggle(!submissionViewToggle)}
          isOpen={submissionViewToggle}
          applicantData={applicantDetail}
          setCandidateFilter={setStatusFilter}
          refetch={() => getJobOrderApplicants()}
        />
      )}

      {/* this drawer is used for the schedule interview for the first time and for schedule interview for the next */}
      {applicantDetail && interviewScheduleModal && (
        <InterviewScheduleModal
          accountManagerAndCandidateRecruiterSame={
            applicantDetail?.JobOrder?.company?.companyOwner?.owner?.id ===
            applicantDetail?.candidate?.recruiterOwner?.id
          }
          setCandidateFilter={setStatusFilter}
          firstInterview={firstInterview}
          backToInterview={backToInterview}
          candidateId={applicantDetail?.candidate?.id}
          interviewScheduleModal={interviewScheduleModal}
          applicantData={applicantDetail}
          interviewScheduleModalToggle={() =>
            setInterviewScheduleModal(!interviewScheduleModal)
          }
          refetch={() => getJobOrderApplicants()}
          closeModel={() => setInterviewScheduleModal(false)}
        />
      )}

      {/* this drawer is used for client calander decline  */}
      {notesModal && (
        <ClientCandidateReasonModal
          refetch={() => getJobOrderApplicants()}
          modal={notesModal}
          toggle={() => {
            setNotesModal(!notesModal);
          }}
          rejectDetail={rejectDetail}
        />
      )}

      {/* this drawer is used for the view interview details */}
      {interviewDetailToggle && (
        <InterviewViewDrawer
          toggle={() => setInterviewDetailToggle(!interviewDetailToggle)}
          isOpen={interviewDetailToggle}
          latestInterview={latestInterviewData}
          applicantData={applicantDetail}
          refetch={() => getJobOrderApplicants()}
          setStatusFilter={setStatusFilter}
        />
      )}

      {/* this piece of code is used for sending the candidate calander invite */}
      {calendarInviteModal && (
        <CandidateCalendarInviteModal
          reFetchInterviewEmail={() => {
            getInterviewEmails({
              variables: { interviewId: latestInterviewData?.id },
            });
          }}
          fromInterviewDetailCard={true}
          jobId={applicantDetail?.JobOrder?.id}
          reFetchInterviews={() => getJobOrderApplicants()}
          updateClientCalendarModal={false}
          candidateInterviewEmailData={candidateInterviewEmailData}
          fromEdit={
            getInterviewEmailsData?.getInterviewEmails?.interviewEmails?.find(
              (item: { isToClient: boolean }) => !item?.isToClient
            )
              ? true
              : false
          }
          calendarPrefilledValues={latestInterviewData}
          candidateId={applicantDetail?.candidate?.id}
          name={applicantDetail?.candidate?.fullName}
          type={latestInterviewData?.interviewType}
          interviewId={latestInterviewData?.id}
          calendarInviteModal={calendarInviteModal}
          calendarInviteModalToggle={() =>
            setCalendarInviteModal(!calendarInviteModal)
          }
        />
      )}

      {/* client calander invite drawer serve from here */}
      {clientCalendarInviteModal && (
        <ClientCalendarInviteModal
          reFetchInterviewEmail={() => {
            getInterviewEmails({
              variables: { interviewId: latestInterviewData?.id },
            });
          }}
          skipIt={false}
          from={'interview-card'}
          reFetchInterviews={() => getJobOrderApplicants()}
          jobApplicantId={applicantDetail?.id}
          clientInterviewEmailData={clientInterviewEmailData}
          clientDefaultInviteFromInterview={latestInterviewData}
          fromEdit={
            getInterviewEmailsData?.getInterviewEmails?.interviewEmails?.find(
              (item: { isToClient: boolean }) => item?.isToClient
            )
              ? true
              : false
          }
          jobId={applicantDetail?.JobOrder?.id}
          companyId={applicantDetail?.JobOrder?.company?.id}
          candidateId={applicantDetail?.candidate?.id}
          name={applicantDetail?.candidate?.fullName}
          type={latestInterviewData?.interviewType}
          interviewId={latestInterviewData?.id}
          clientCalendarInviteModal={clientCalendarInviteModal}
          clientCalendarInviteModalToggle={() =>
            setClientCalendarInviteModal(!clientCalendarInviteModal)
          }
        />
      )}

      {/* this is for the create, edit and create counter offer the offer */}
      {offerModal && (
        <CreateEditOffer
          applicantData={applicantDetail}
          setCandidateFilter={setStatusFilter}
          counterOffer={counterOfferToggle}
          fromUpdate={editOfferToggle}
          latestOffer={latestOffer}
          interviewId={latestInterviewData?.id}
          setOfferToggle={() => setOfferModal(!offerModal)}
          offerToggle={offerModal}
          refetch={() => {
            getJobOrderApplicants();
            setPendingInterview(false);
            setPendingSubmission(false);
          }}
          parentToggle={() => { }}
        />
      )}

      {/* this is used for the view offer detail drawer */}
      {/* {offerDrawerToggle && ( */}
      <OfferViewDrawer
        applicantData={applicantDetail}
        toggle={() => setOfferDrawerToggle(!offerDrawerToggle)}
        isOpen={offerDrawerToggle}
        refetch={() => getJobOrderApplicants()}
        setCandidateFilter={setStatusFilter}
      />
      {/* )} */}

      {/* this pop up is used for the replacement confirmation modal*/}
      {replacementModalToggle && (
        <ReplacementModal
          jobApplicants={true}
          isOpen={replacementModalToggle}
          toggle={() => setReplacementModalToggle(!replacementModalToggle)}
          placementId={placementId}
          updateOfferToPlacement={updateOfferToPlacement}
        />
      )}

      {/* this pop up is used for the convert applicant Offer to Placement */}
      {/* <ConfirmPopUp
        confirmText="Are you sure you want to make a placement?"
        isOpen={placementMoveToggle}
        toggle={() => {
          setPlacementMoveToggle(!placementMoveToggle);
        }}
        confirmAction={updateOfferToPlacement}
        modalHeading="Move to Placement"
        btnText="Yes, Move"
        btnColor="primary"
        className="for-modal-styling"
      /> */}

      <ConfirmPopUp
        confirmText="Are you sure you want to change this applicant status?"
        isOpen={confirmBox}
        toggle={setConfirmBox}
        confirmAction={changeApplicantStatus}
        modalHeading="Applicant Status"
        btnText="Confirm"
        btnColor="primary"
      />
    </div>
  );
};

export default JobApplicantTab;
