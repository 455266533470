import { useHistory } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { COMPANY_DETAILS_ROUTE, VIEW_CANDIDATE_ROUTE, VIEW_JOB_ORDER_ROUTE } from "../../../../utils/constant";
import { moneyFormatter } from "../../../../utils/helper";

const BillingType = ({ billingTypeCardProps, candidateSourceProp }) => {
  const history = useHistory();

  return (
    <>
      <div className="d-flex align-items-center mb-3 contentGridlabel">
        <h5 className="m-0">Billing Type</h5>
      </div>

      <Row>
        <Col lg="6" md="12" sm="12" xs="12">
          <div className="d-flex justify-content-between mb-2 contentGridlabel">
            <label>placement ID</label>
            <h6>{billingTypeCardProps?.placementId}</h6>
          </div>

          <div className="d-flex justify-content-between mb-2 contentGridLabel">
            <label>Invoice ID</label>

            <h6>{billingTypeCardProps?.invoiceNumber || "--"}</h6>
          </div>

          <div className="d-flex justify-content-between mb-2 contentGridlabel">
            <label>Candidate</label>
            <h6
              className="anchorColorText route-link"
              onClick={() => {
                history.push({
                  pathname: `${VIEW_CANDIDATE_ROUTE}/${billingTypeCardProps?.candidateId}`,
                });
              }}
            >
              {billingTypeCardProps?.candidateName}
            </h6>
          </div>

          <div className="d-flex justify-content-between mb-2 contentGridlabel">
            <label>Company</label>
            <h6
              className="anchorColorText route-link"
              onClick={() => {
                history.push({
                  pathname: `${COMPANY_DETAILS_ROUTE}/${billingTypeCardProps?.companyId}`
                });
              }}
            >{billingTypeCardProps?.companyName}</h6>
          </div>

          <div className="d-flex justify-content-between mb-2 contentGridlabel">
            <label>Position Title</label>
            <h6
              className="anchorColorText route-link"
              onClick={() => {
                history.push({
                  pathname: `${VIEW_JOB_ORDER_ROUTE}/${billingTypeCardProps?.jobId}`,
                });
              }}
            >{billingTypeCardProps?.positionTitle}</h6>
          </div>

          <div className="d-flex justify-content-between mb-2 contentGridlabel">
            <label>Location</label>
            <h6>{billingTypeCardProps?.location}</h6>
          </div>

          <div className="d-flex justify-content-between mb-2 contentGridlabel">
            <label>Start Date</label>
            <h6>{billingTypeCardProps?.startDate}</h6>
          </div>

          <div className="d-flex justify-content-between mb-2 contentGridlabel">
            <label>Salary</label>
            <h6>{moneyFormatter(billingTypeCardProps?.salary)}</h6>
          </div>
        </Col>

        <Col lg="6" md="12" sm="12" xs="12">
          <div className="d-flex justify-content-between mb-2 contentGridlabel">
            <label>
              Fee{billingTypeCardProps?.commissionType === "PERCENTAGE" && "%"}
            </label>
            {billingTypeCardProps?.commissionType === "PERCENTAGE" ? (
              <h6>{billingTypeCardProps?.feeStructured?.toFixed(2)}%</h6>
            ) : (
              <h6>{moneyFormatter(billingTypeCardProps?.feeStructured)}</h6>
            )}
          </div>

          <div className="d-flex justify-content-between mb-2 contentGridlabel">
            <label>Total Commission </label>{" "}
            <h6>
              {" "}
              {moneyFormatter(billingTypeCardProps?.totalCommission || 0)}
            </h6>
          </div>

          <div className="d-flex justify-content-between mb-2 contentGridlabel">
            <label>Adjustment to Fee</label>
            <h6>
              {moneyFormatter(billingTypeCardProps?.adjustmentTotal || 0)}
            </h6>
          </div>

          <div className="d-flex justify-content-between mb-2 contentGridlabel">
            <label>Adjustment Reason</label>
            <h6>{billingTypeCardProps?.adjustmentsReason || "--"}</h6>
          </div>

          <div className="d-flex justify-content-between mb-2 contentGridlabel">
            <label>Net Fee</label>
            <h6>{moneyFormatter(billingTypeCardProps?.netCommission || 0)}</h6>
          </div>

          <div className="d-flex justify-content-between mb-2 contentGridlabel">
            <label>Candidate Source Via</label>
            <h6>{candidateSourceProp?.source}</h6>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default BillingType;
