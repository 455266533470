import { useLazyQuery } from "@apollo/client";
import moment from "moment";
import { FC, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import Loader from "../../../../components/layout/Loader";
import { ReplacementModalType } from "../../../../interfaces";
import { formatDateUS } from "../../../../utils/commonFn";
import { GET_PLACEMENT_BY_ID } from "../../../AccountingDashboard/gql";

const ReplacementModal: FC<ReplacementModalType> = ({
  isOpen,
  jobApplicants,
  toggle,
  placementId,
  updateOfferToPlacement,
}) => {
  const [fetchPlacement, { data, loading }] = useLazyQuery(GET_PLACEMENT_BY_ID);
  useEffect(() => {
    if (isOpen && placementId) {
      fetchPlacement({
        variables: {
          id: placementId,
        },
      });
    }
  }, [fetchPlacement, isOpen, placementId]);

  // form methods
  const methods = useForm();
  const { register, handleSubmit, watch } = methods;

  const replacementSubmitHandler = handleSubmit(async () => {
    const replacePlacement = watch('replacePlacement');
    //ATS-1252 this was causing issue if the user have not followed the correct workflow 
    //and this leads to data discrepancy so commenting this and will remove it in the future

    // if (replacePlacement) {
    //   localStorage.setItem('isReplacement', placementId);
    // }
    await updateOfferToPlacement({ ...replacePlacement && { replacementId: placementId } }, false)
  })

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
    >
      <ModalHeader>
        <div className="d-flex"> <strong>Confirm Replacement</strong></div>
      </ModalHeader >
      <FormProvider {...methods}>
        <form>
          <ModalBody style={{ maxHeight: '240px' }}>
            <Row>
              <Col xs="12">
                {loading ? (
                  <Loader loaderClass="auto-height" />
                ) : (
                  <div className="mb-4">
                    {data && data?.getPlacementById && (
                      <>
                        <p className="mb-0">
                          <strong>Placement Candidate Name:</strong>
                          <span className="text-sm ml-2">
                            {data?.getPlacementById?.candidateName}
                          </span>
                        </p>

                        <p className="mb-0">
                          <strong>Job Order:</strong>
                          <span className="text-sm ml-2">
                            {data?.getPlacementById?.jobTitle}
                          </span>
                        </p>

                        <p className="mb-0">
                          <strong>Placement Start Date:</strong>
                          <span className="text-sm ml-2">
                            {data?.getPlacementById?.startDateString && formatDateUS(moment(data?.getPlacementById?.startDateString).format())}
                          </span>
                        </p>
                      </>
                    )}
                  </div>
                )}

                <p className="mb-0 ml-4">
                  <span className="text-sm ml-2">
                    <FormGroup>
                      <Label>
                        <Input type="checkbox" name="replacePlacement" innerRef={register()} defaultChecked />
                        Do you want to replace the above cancelled placement?
                      </Label>
                    </FormGroup>
                  </span>
                </p>
              </Col>
            </Row>
          </ModalBody>

          <ModalFooter>
            <div className="d-flex justify-content-end align-items-center w-100">
              <button
                className="btn btn-primary buttonPrimary m-2"
                onClick={() => {
                  toggle();
                }}
                type="button"
              >
                Cancel
              </button>
              <button
                className="btn btn-primary py-2 m-2"
                onClick={replacementSubmitHandler}
                type="button"
              >
                Confirm
              </button>

            </div>
          </ModalFooter>
        </form>
      </FormProvider>
    </Modal>
  );
}

export default ReplacementModal;