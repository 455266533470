// packages block
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import FileDownload from "js-file-download";
import GoBack from "../../components/GoBack";
import { useHistory, useLocation } from "react-router-dom";
import { useLazyQuery, useMutation } from "@apollo/client";
import { Modal, ModalBody, ModalFooter, Badge, ModalHeader } from "reactstrap";
// components block
import Loader from "../../components/layout/Loader";
import ConfirmPopUp from "../../components/ConfirmPopUp";
import InputField from "../../components/layout/InputField";
import Pagination from "../../components/layout/Pagination";
import NoDataFound from "../../components/layout/NoDataFound";
import CustomButton from "../../components/layout/CustomButton";
import SectionHeader from "../../components/layout/SectionHeader";
import TableComponent from "../../components/layout/TableComponent";
import AgreementViewStuff from "./agreement-components/AgreementViewStuff";
import HeaderAgreementStatus from "./agreement-components/HeaderAgreementStatus";
import Preview from "../job-order/job-order-components/document-preview/Preview";
import AgreementEditViewPage from "./agreement-components/AgreementEditViewPage";
import AgreementRemoveAction from "./agreement-components/AgreementRemoveAction";
import AgreementEscalationStatus from "./agreement-components/AgreementEscalationStatus";
import AgreementEscalationModalAction from "./agreement-components/AgreementEscalationModalAction";
// context, utils and graphql block
import { AppContext } from "../../context";
import { IComingState } from "./interfaces";
import { AGREEMENTS_ROUTE, AWAITING_EXECUTIVE_APPROVAL, CANDIDATES_ROUTE, CUSTOM, DRAFT, OUT_FOR_SIGNATURE, PAGE_LIMIT, RESENT, SIGNED, TEMP, VIEW_AGREEMENT_ROUTE } from "../../utils/constant";
import { GET_SIGNED_URL } from "../candidate/gql";
import { AGREEMENT_HISTORY_HEADERS } from "../../constants";
import { downloadSignedUrlFile, formatDateUS } from "../../utils/commonFn";
import { FETCH_ONE_AGREEMENT_HISTORY, FETCH_ONE_AGREEMENT, DELETE_AGREEMENT_EXECUTIVE } from "./gql";
import "./agreement.scss";
import "../../styles/styles.scss";
import { formatValue, getAgreementColor } from "../../utils/helper";

let time = 0;

const ViewAgreement = (props) => {
  const { errors, register, setValue, getValues } = useForm();
  const page = new URLSearchParams(props.location.search).get("currentPage") || "1";
  const history = useHistory();
  const location = useLocation<IComingState>();
  const comingState: any = location.state;
  const _currentPage: number = parseInt(page);
  const [currentPage, setCurrentPage] = useState(_currentPage);
  const [limit, setLimit] = useState(10);
  const { user } = useContext(AppContext);
  const [agreementDocumentModal, setAgreementDocumentModal] = useState(false);
  const [agreementNotesModal, setNotesTableModal] = useState(false);
  const [historyNotes, setHistoryNotes] = useState();
  const [downloading, setDownloading] = useState(false);
  const [viewingDocument, setViewingDocument] = useState(false);
  const [actionConfirm, setAgreementDeleteConfirm] = useState(false);
  const [actionId, setDeleteActionId] = useState("");
  const [tempDocUrl, setTempDocUrl] = useState<string>();
  const [legacySignedUrl, setLegacySignedUrl] = useState<string>();
  const [currentUserAccess, setCurrentUserAccess] = useState<boolean>(false);
  const [isLegacy, setIsLegacy] = useState<boolean>(false);

  const notesTableToggle = () => setNotesTableModal(!agreementNotesModal);

  const [fetchAllAgreementsHistory, { data, loading: historyLoading }] =
    useLazyQuery(FETCH_ONE_AGREEMENT_HISTORY);

  const [signedUrlGetter] = useMutation(GET_SIGNED_URL);

  const [
    fetchOneAgreement,
    { data: fetchOneAgreementData, loading: agreementLoading, refetch },
  ] = useLazyQuery(FETCH_ONE_AGREEMENT);

  const [_deleteAction, { loading: deleteLoading }] = useMutation(DELETE_AGREEMENT_EXECUTIVE);

  const agreementDocumentModalToggle = () =>
    setAgreementDocumentModal(!agreementDocumentModal);

  const fetchAllAgreementsHistoryHandler = () => {
    fetchAllAgreementsHistory({
      variables: {
        limit,
        page: currentPage,
        agreementId: props?.match?.params?.id,
      },
    });
  };

  const fetchOneAgreementHandler = () => {
    fetchOneAgreement({
      variables: {
        limit,
        page: currentPage,
        agreementId: props?.match?.params?.id,
      },
    });
  };

  const updateAgreementData = () => {
    fetchOneAgreementHandler();
    fetchAllAgreementsHistoryHandler();
  }

  useEffect(() => {
    updateAgreementData()
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const NICK_NAME = fetchOneAgreementData?.oneAgreement?.nickName;
    document.title = agreementLoading
      ? "Loading..."
      : NICK_NAME
        ? `${NICK_NAME} - Agreement - RealREPP`
        : "Agreement - RealREPP";
    if (fetchOneAgreementData?.oneAgreement?.Company?.ownerAndSharees?.length && user) {
      let ownerShareeIds = fetchOneAgreementData?.oneAgreement?.Company?.ownerAndSharees?.map(item => item?.owner?.id)
      setCurrentUserAccess(ownerShareeIds?.includes(user?.id))
    }

    setIsLegacy(history.location.pathname.includes('legacy'))
    // eslint-disable-next-line
  }, [fetchOneAgreementData?.oneAgreement, user]);

  useEffect(() => {
    window.clearTimeout(time);
    time = window.setTimeout(function () {
      if (props?.match?.params?.id) {
        fetchAllAgreementsHistoryHandler();
      }
    }, 1000);

    // eslint-disable-next-line
  }, [currentPage, limit]);

  const confirmAction = async () => {
    const response = await _deleteAction({
      variables: { agreementId: actionId },
    });
    if (response?.data?.deleteAgreementExecutive?.status === 200) {
      setAgreementDeleteConfirm(false);
      history.push(AGREEMENTS_ROUTE);
      toast.success("Successfully deleted!");
    } else {
      toast.warn("Unable to delete agreement as it is either associated to a placement or job order");
      setAgreementDeleteConfirm(false);
    }
  };

  const downloadDocument = async ({
    agreementId,
    agreementName,
  }: {
    agreementId: string;
    agreementName: string;
  }) => {
    try {
      setDownloading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL || "http://localhost:3001"
        }${AGREEMENTS_ROUTE}/downloadDocument/${agreementId}`,
        {
          responseType: "blob",
        }
      );
      setDownloading(false);
      FileDownload(response.data, `${agreementName}`);
    } catch (error) {
      setDownloading(false);
      toast.error("Error while downloading file.");
    }
  };

  const framingDocument = async ({
    agreementId,
    isLegacy,
    documentUri,
    docType = false,
  }: {
    agreementId: string;
    isLegacy?: boolean;
    documentUri?: string;
    docType: boolean;
  }) => {
    if ((isLegacy && documentUri) || docType) {
      setViewingDocument(true);
      const res = await signedUrlGetter({
        variables: {
          remoteFilePath: documentUri,
        },
      });

      if (res?.data?.getSignedUrl?.signedUrl) {
        // case for temp or custom agreement
        if (docType) {
          setViewingDocument(false);
          setTempDocUrl(res?.data?.getSignedUrl?.signedUrl);
        } else {
          // case for legacy agreement
          setViewingDocument(false);
          setLegacySignedUrl(res?.data?.getSignedUrl?.signedUrl);
        }
      }
    } else {
      try {
        setViewingDocument(true);
        let response;
        const apiBaseUrl = process.env.REACT_APP_API_BASE_URL || "http://localhost:3001"

        if (docType) {
          response = await axios.post(
            `${apiBaseUrl}${CANDIDATES_ROUTE}/downloadSignedUrlFile`,
            {
              url: documentUri
            },
            {
              responseType: "blob",
            }
          );
        } else {
          response = await axios.get(
            `${apiBaseUrl}${AGREEMENTS_ROUTE}/downloadDocument/${agreementId}`,
            {
              responseType: "blob",
            }
          );
        }
        setViewingDocument(false);
        setTempDocUrl(URL.createObjectURL(response.data));
      } catch (error) {
        setViewingDocument(false);
        toast.error("Error while previewing file.");
      }
    }
  };

  const dateFormatForPdf = () => {
    const date = new Date();
    let agreementDate = `${date.getFullYear()}${date.getMonth() < 10 ? `0${date.getMonth() + 1}` : date.getMonth()
      }${date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()}`
    return agreementDate
  }

  const agreementTypeForPdf = () => {
    const agreementTypeStr = fetchOneAgreementData?.oneAgreement?.agreementType
    const agreementTypeStrLower = agreementTypeStr.toLowerCase()
    const agreementTypeStrSplit = agreementTypeStrLower.split("_")
    for (let i = 0; i < agreementTypeStrSplit.length; i++) {
      agreementTypeStrSplit[i] = agreementTypeStrSplit[i].charAt(0).toUpperCase() + agreementTypeStrSplit[i].slice(1);
    }
    const finalAgreementTypeStr = agreementTypeStrSplit.join("")
    return finalAgreementTypeStr
  }

  const downloadingDocument = () => {
    if (
      (fetchOneAgreementData?.oneAgreement?.status === SIGNED ||
        fetchOneAgreementData?.oneAgreement?.status === OUT_FOR_SIGNATURE ||
        fetchOneAgreementData?.oneAgreement?.status === RESENT)
    ) {
      downloadSignedUrlFile(
        fetchOneAgreementData?.oneAgreement?.documentUri,
        `${agreementTypeForPdf()} Agreement-${fetchOneAgreementData?.oneAgreement?.Company?.name}-${dateFormatForPdf()}.pdf`
      );
    } else {
      downloadDocument({
        agreementId: fetchOneAgreementData?.oneAgreement?.id,
        agreementName: `${agreementTypeForPdf()} Agreement-${fetchOneAgreementData?.oneAgreement?.Company?.name}-${dateFormatForPdf()}.pdf` || "",
      });
    }
  };

  return (
    <>
      <div className="admin-tabs team-tabs">
        {!isLegacy && (<GoBack
          url={comingState?.backUrl ?? AGREEMENTS_ROUTE}
          search={comingState?.searchParameters}
        />)}
        <div className="primaryHeaderSpacing">
          <div className="view-company m-0">
            <div className="mobileResponsiveFlexAlign">
              <h5 className="m-0">
                {agreementLoading
                  ? Loader
                  : !fetchOneAgreementData?.oneAgreement?.isLegacy
                    ? "View Company Agreement"
                    : "View Company Legacy Agreement"}
              </h5>

              {!agreementLoading && <HeaderAgreementStatus
                agreementStatus={fetchOneAgreementData?.oneAgreement?.status}
              />}
            </div>

            <AgreementEditViewPage
              agreementStatus={fetchOneAgreementData?.oneAgreement?.status}
              agreementUserId={fetchOneAgreementData?.oneAgreement?.User?.id}
              agreementId={fetchOneAgreementData?.oneAgreement?.id}
            />
          </div>
        </div>

        {agreementLoading
          ? Loader
          : !fetchOneAgreementData?.oneAgreement?.isLegacy && (
            <div className="escalation-protocol">
              <div className="d-flex forMobileColumn">
                <h5 className="m-0">Escalation Protocol</h5>
                <AgreementEscalationStatus
                  agreementStatus={
                    fetchOneAgreementData?.oneAgreement?.status === DRAFT ? AWAITING_EXECUTIVE_APPROVAL : fetchOneAgreementData?.oneAgreement?.status
                  }
                />
              </div>

              <div className="d-flex ">
                <div className="mr-3">
                  <AgreementEscalationModalAction
                    fetchAgreement={() => updateAgreementData()}
                    agreementStatus={
                      fetchOneAgreementData?.oneAgreement?.status
                    }
                    currentUserAccess={currentUserAccess}
                    contactId={
                      fetchOneAgreementData?.oneAgreement?.CompanyContact?.id
                    }
                    envelopeId={
                      fetchOneAgreementData?.oneAgreement?.envelopeId
                    }
                    currentUserAgreement={
                      user?.id ===
                      fetchOneAgreementData?.oneAgreement?.User?.id
                    }
                    userIsReviewer={
                      user?.id ===
                      fetchOneAgreementData?.oneAgreement?.Reviewer?.id
                    }
                    agreementId={fetchOneAgreementData?.oneAgreement?.id}
                    getValues={getValues}
                    companyContactName={
                      fetchOneAgreementData?.oneAgreement?.CompanyContact
                        ?.fullName
                    }
                    companyName={
                      fetchOneAgreementData?.oneAgreement?.Company?.name
                    }
                    agreementType={
                      fetchOneAgreementData?.oneAgreement?.agreementType
                    }
                    register={register}
                    setValue={setValue}
                    errors={errors}
                  />
                </div>

                <AgreementRemoveAction
                  agreementStatus={
                    fetchOneAgreementData?.oneAgreement?.status
                  }
                  confirmingRemove={() => {
                    setAgreementDeleteConfirm(true);
                  }}
                  settingRemoveAgreementId={() => {
                    setDeleteActionId(
                      fetchOneAgreementData?.oneAgreement?.id
                    );
                  }}
                />
              </div>
            </div>
          )}

        <AgreementViewStuff
          agreementLoading={agreementLoading}
          refetchCompany={refetch}
          company={fetchOneAgreementData?.oneAgreement?.Company}
          companyContact={fetchOneAgreementData?.oneAgreement?.CompanyContact}
          agreement={fetchOneAgreementData?.oneAgreement}
          userIsReviewer={
            user?.id === fetchOneAgreementData?.oneAgreement?.Reviewer?.id
          }

          downloadDocumentAction={downloadingDocument}
          downloading={downloading}
          documentView={viewingDocument}
          viewingDocumentAction={() => {
            const agreementType: boolean = (fetchOneAgreementData?.oneAgreement?.agreementType === CUSTOM ||
              fetchOneAgreementData?.oneAgreement?.agreementType === TEMP) ? true : false;
            framingDocument({
              agreementId: fetchOneAgreementData?.oneAgreement?.id,
              isLegacy: fetchOneAgreementData?.oneAgreement?.isLegacy,
              documentUri: fetchOneAgreementData?.oneAgreement?.documentUri,
              docType: agreementType,
            });
            agreementDocumentModalToggle();
          }}
          agreementType={fetchOneAgreementData?.oneAgreement?.agreementType}
          {...props}
        />

        {agreementLoading
          ? Loader
          : !fetchOneAgreementData?.oneAgreement?.isLegacy && (
            <SectionHeader title="Status History" titleSpan="" subTitle="" />
          )}

        {agreementLoading
          ? Loader
          : !fetchOneAgreementData?.oneAgreement?.isLegacy && (
            <div className="table-responsive">
              <TableComponent tableHeader={AGREEMENT_HISTORY_HEADERS}>
                {historyLoading ? (
                  <tbody>
                    <tr>
                      <td colSpan={11}>
                        <Loader loaderClass="sm-height " />
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody>
                    {data?.agreementHistory?.agreements?.length
                      ? data?.agreementHistory?.agreements?.map(
                        (item: any, index: number) => (
                          <tr key={index}>
                            {item?.Reviewer ? (
                              <td>{item?.Reviewer?.fullName}</td>
                            ) : (
                              <td>[System generated]</td>
                            )}

                            <td>
                              <Badge
                                color={getAgreementColor(item?.oldStatus)}
                                className="text-capitalize"
                              >
                                {formatValue(item?.oldStatus)}
                              </Badge>
                            </td>
                            <td>
                              <Badge
                                color={getAgreementColor(item?.newStatus)}
                                className="text-capitalize"
                              >
                                {formatValue(item?.newStatus)}
                              </Badge>
                            </td>

                            <td>
                              {" "}
                              {item?.createdAt
                                ? formatDateUS(item?.createdAt)
                                : "--"}{" "}
                              {item?.createdAt
                                ? new Date(
                                  item?.createdAt
                                ).toLocaleTimeString()
                                : "--"}
                            </td>
                            <td>
                              {item?.updatedAt
                                ? formatDateUS(item?.updatedAt)
                                : "--"}{" "}
                              {item?.updatedAt
                                ? new Date(
                                  item?.updatedAt
                                ).toLocaleTimeString()
                                : "--"}
                            </td>
                            <td
                              className="td-break-text cursor-pointer"
                              onClick={() => {
                                if (item?.note) {
                                  notesTableToggle();
                                  setHistoryNotes(item?.note);
                                }
                              }}
                            >
                              {item?.note?.length > 20
                                ? `${item?.note?.slice(0, 20)}...`
                                : item?.note || "--"}
                            </td>
                          </tr>
                        )
                      )
                      : ""}
                  </tbody>
                )}
                {!historyLoading &&
                  !data?.agreementHistory?.agreements?.length ? (
                  <tbody>
                    <tr>
                      <td colSpan={7} className="empty-table-td">
                        <NoDataFound text="No agreement status history found!" />
                      </td>
                    </tr>
                  </tbody>
                ) : null}
              </TableComponent>

              {!historyLoading &&
                !data?.agreementHistory?.agreements?.length ? null : (
                <div>
                  {data?.agreementHistory?.agreements && (
                    <div className="users-pagination">
                      <Pagination
                        onPageChange={(pageClicked: number) => {
                          setCurrentPage(pageClicked);
                          history.push(
                            `${VIEW_AGREEMENT_ROUTE}/${props?.match?.params?.id}?currentPage = ${pageClicked} `
                          );
                        }}
                        pageCount={Math.ceil(
                          data?.agreementHistory?.count / limit
                        )}
                        currentPage={currentPage}
                      />

                      <div className="d-flex align-items-center">
                        <div className="pagination-display paginationItemsSelect">
                          <p className="m-0">
                            {(currentPage - 1) * limit + 1} to
                            {Math.min(
                              currentPage * limit,
                              data?.agreementHistory?.count
                            )}{" "}
                            of {data?.agreementHistory?.count}
                          </p>

                          <div className="divider"></div>

                          <label>Display</label>

                          <InputField
                            label=""
                            inputtype="select"
                            selectItems={PAGE_LIMIT.filter(
                              (item) => item.value !== limit
                            )}
                            inputid="limit"
                            placeholder={limit.toString()}
                            inputMethod={(value: string) => {
                              setCurrentPage(1);
                              setLimit(parseInt(value));
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          )}

        <Modal
          isOpen={agreementNotesModal}
          toggle={notesTableToggle}
          id="agreementNotesModal"
          size="xl"
        >
          <ModalHeader toggle={notesTableToggle}>View Notes</ModalHeader>
          <ModalBody>
            <div style={{ height: "300px", overflow: "auto" }}>
              {historyNotes}
            </div>
          </ModalBody>
          <ModalFooter>
            <CustomButton
              buttonText="Close"
              buttonColor="default"
              buttonType="button"
              buttonClick={notesTableToggle}
            />
          </ModalFooter>
        </Modal>

        <ConfirmPopUp
          confirmText="Are you sure you want to delete this agreement from agreements list?"
          isOpen={actionConfirm}
          agreementStatus={fetchOneAgreementData?.oneAgreement?.status}
          toggle={setAgreementDeleteConfirm}
          confirmAction={confirmAction}
          modalHeading="Delete Action"
          btnText="Delete"
          btnColor="primary"
          loading={deleteLoading}
          disabled={deleteLoading}
          className="revampDialog revampDialogWidth"
        />
      </div>

      <Modal
        isOpen={agreementDocumentModal}
        toggle={agreementDocumentModalToggle}
        centered={true}
        size="xl"
        className="revampDialog border-0"
      >
        <div className="pdf-viewer">
          <Preview
            documentUrl={fetchOneAgreementData?.oneAgreement?.isLegacy ? legacySignedUrl : tempDocUrl}
            documentName={`${fetchOneAgreementData?.oneAgreement?.nickName}.pdf`}
            isDownload={true}
          />
        </div>
      </Modal>
    </>
  );
};

export default ViewAgreement;
