import { useLazyQuery } from "@apollo/client";
import { useContext, useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import {
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import SEARCH_IMG from "../../../assets/images/search.svg";
import UserDropdown from "../../../components/AsyncCompanyReportAm";
import AsyncCompanyReportNote from "../../../components/AsyncCompanyReportSelect";
import InputField from "../../../components/layout/InputField";
import Pagination from "../../../components/layout/Pagination";
import { CANDIDATE_OWNERSHIP_REPORT } from "../../../constants";
import { AppContext } from "../../../context";
import { CandidateExpiryReport } from "../../../interfaces";
import { PAGE_LIMIT } from "../../../utils/constant";
import Listing from "./Listing";
import { GET_CANDIDATE_EXPIRY_REPORT } from "../gql";

const CandidateOwnerShipReport = ({ location, history }: RouteComponentProps) => {
  const page = new URLSearchParams(location.search).get('currentPage') || '1';
  const _currentPage: number = parseInt(page);
  const [currentPage, setCurrentPage] = useState(_currentPage);
  const [candidates, setCandidates] = useState<{ candidateExpiryReports: [CandidateExpiryReport] }>();
  const [limit, setLimit] = useState<number>(10);
  const [notes, setNotes] = useState<string>(localStorage.getItem('candidateOwnerShipReport-note') || "Current");
  const [userId, setUserId] = useState<string>(localStorage.getItem('candidateOwnerShipReport-userId')! || "");
  const [search, setSearch] = useState<string>("");
  const [searchParams, setSearchParams] = useState<string>("");
  const { user } = useContext(AppContext);
  const { userRoles } = useContext(AppContext);

  const [getCompanyExpiryReport, { data, loading }] = useLazyQuery(GET_CANDIDATE_EXPIRY_REPORT, {
    // fetchPolicy: 'network-only',
    onCompleted(data) {
      setCandidates(data?.getCandidateExpiryReport?.reports);
    },
  });

  const fetchCompanyExpiryReport = async () => {
    if (searchParams?.length > 2) {
      setCurrentPage(1)
    }
    if (!searchParams || searchParams?.length > 2) {
      await getCompanyExpiryReport({
        variables: {
          payload: {
            id: user?.id,
            page: currentPage,
            limit: limit,
            notes: notes,
            recruiter: !userRoles?.includes('EXECUTIVE') ? user?.id : userId,
            search: search
          }
        }
      });
    }
  }

  useEffect(() => {
    if (user?.id) {
      fetchCompanyExpiryReport();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, limit, notes, user, search, userId]);

  const handleClear = async () => {
    localStorage.removeItem('candidateOwnerShipReport-note')
    localStorage.removeItem('candidateOwnerShipReport-userId')
    setNotes('Current')
    setUserId('')
    setSearch('')
    setSearchParams('')
  }

  useEffect(() => {
    if (window.performance && window.performance.navigation.type === 1) {
      // This code will run on hard refresh
      const url = new URL(window.location.href);
      url.searchParams.delete('currentPage');
      window.history.replaceState({}, '', url.toString());
      setCurrentPage(1);
    }
  }, []);

  return (
    <div className="admin-tabs team-tabs">
      <div className="primaryHeading">
        <h5>{CANDIDATE_OWNERSHIP_REPORT}</h5>
      </div>

      <div className="search-filter primaryHeaderSpacing">
        <div>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <img src={SEARCH_IMG} alt="search" />
              </InputGroupText>
            </InputGroupAddon>

            <Input
              type="text"
              placeholder="Search by candidate name"
              value={search ?? ""}
              onChange={(e) => {
                setSearch(e.target.value)
                setSearchParams(e.target.value)
              }}
              className="search-by-company"
            />
          </InputGroup>

          <small className="text-info">
            {searchParams &&
              searchParams.length <= 2 &&
              " Please add more than two characters"}
          </small>
        </div>

        <div className="filter-status flex-wrap gap-10">
          <div className="mr-4 d-flex items-center gap-10 flex-wrap">
            {userRoles?.includes("EXECUTIVE")
              &&
              <UserDropdown
                candidate={true}
                placeholder="All Users"
                setUserId={setUserId}
                userId={userId}
              />
            }
            <AsyncCompanyReportNote
              candidate={true}
              placeholder="Note"
              setNotes={setNotes}
              notes={notes}
            />
          </div>

          <button
            className="buttonGenericStyle filledButton"
            onClick={handleClear}
          >
            Clear
          </button>
        </div>
      </div>
      <Listing loading={loading} data={candidates} note={notes} />
      <div>
        {data?.getCandidateExpiryReport?.reports?.length ? (
          <div className="users-pagination">
            <Pagination
              onPageChange={(pageClicked: number) => {
                setCurrentPage(pageClicked);
                history.push(`/candidate-ownership-report?currentPage=${pageClicked}`);
              }}
              pageCount={Math.ceil(data?.getCandidateExpiryReport?.count / limit)}
              currentPage={currentPage}
            />
            <div className="d-flex align-items-center">
              <div className="pagination-display paginationItemsSelect">
                <p className="m-0">
                  {(currentPage - 1) * limit + 1} to{' '}
                  {Math.min(currentPage * limit, data?.getCandidateExpiryReport?.count)} of{' '}
                  {data?.getCandidateExpiryReport?.count}
                </p>

                <div className="divider"></div>

                <label>Display</label>

                <InputField
                  label=""
                  inputtype="select"
                  selectItems={PAGE_LIMIT.filter((item) => item.value !== limit)}
                  inputid="limit"
                  placeholder={limit.toString()}
                  inputMethod={(value) => {
                    setCurrentPage(1);
                    setLimit(parseInt(value));
                  }}
                />
              </div>
            </div>
          </div>
        ) : null}
      </div>

    </div>
  )
}

export default CandidateOwnerShipReport;
