import { FC, useContext, useEffect, useState } from 'react';
// packages block
import Select from 'react-select';
import { Button, Input, Label } from 'reactstrap';
// components block
import CustomButton from '../../../components/layout/CustomButton';
// interfaces, graphql, constants block
import {
  AREA_WITHIN_RADIUS,
  CITY,
  CLEAR,
  CUSTOM_SELECT_STYLE,
  FILTERS,
  STATE,
  ZIP,
  RADIUS_OPTIONS,
  COUNTRY,
} from '../../../constants';
import { CandidateSearchFilter } from '../../../interfaces';
import { AppContext } from '../../../context';
import { countries } from '../../../utils/constant';
import { customSelectStyleNew } from '../../../components/styled/customSelect'

const CandidateSearchFilters: FC<CandidateSearchFilter> = ({
  selectedState,
  city,
  zipCode,
  setCity,
  setState,
  setZipCode,
  radius,
  setRadius,
  getCandidatesList,
  clearFilters,
  aggregateResult,
  handleSkills,
  setCurrentPage,
  setStateList,
  stateList,
  selectedCountry,
  setSelectedCountry,
  searchCall,
  setSearchCall,
  handleSearchEnterDown,
  handleSearchEnterUp
}, props) => {
  // window.addEventListener('keypress', enterClicked);
  const [aggregationResult, setAggregationResult] = useState<Array<any>>([]);
  const [seeMoreBtn, setSeeMoreBtn] = useState<boolean>(false);
  const { userRoles, skillsESAggs } = useContext(AppContext);
  const [facets, setFacets] = useState({});

  const handleCheckboxChange = (value) => {
    console.log("changing ", value)
    setFacets((prevFacets) => ({
      ...prevFacets,
      [value]: !prevFacets[value],
    }));
  };

  useEffect(() => {
    return () => {
      window.removeEventListener('keypress', enterClicked);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { theme } = useContext(AppContext);

  function enterClicked(event: any) {
    if (event.key === 'Enter') {
      event.preventDefault();
      getCandidatesList();
    }
  }

  useEffect(() => {
    if (!city && !zipCode) {
      setRadius('')
    }
  }, [city, zipCode])

  useEffect(() => {
    if (aggregateResult?.length) {
      let splitResult = aggregateResult.slice(0, 10);
      setAggregationResult(splitResult);
      setFacets(aggregateResult?.reduce((acc, { key }) => ({ ...acc, [key]: false }), {}))
      skillsESAggs?.map((item) => handleCheckboxChange(item))

    } else {
      setAggregationResult([]);
    }
  }, [aggregateResult]);

  const seeMore = () => {
    if (aggregateResult?.length > 10 && aggregationResult?.length <= 10) {
      let total = aggregateResult?.length;
      let splitResult = aggregateResult.slice(10, total + 1);
      setAggregationResult([...aggregationResult, ...splitResult]);
    } else {
      let splitResult = aggregateResult.slice(0, 10);
      setAggregationResult(splitResult);
    }
  };

  return (
    <div className="canidate-filters-search p-3">
      <h5 className="font-weight-bold text-dark">{FILTERS}</h5>


      <div className="filters filters-by-state pt-3">
        <div className="d-flex align-items-center mb-2 justify-content-between">
          <Label className="mb-0">{COUNTRY}</Label>
          {/* <button
            className="btn p-0"
            onClick={() => {
              setState({ label: '', value: '' });
              setRadius('');
            }}
          >
            {CLEAR}
          </button> */}
        </div>

        <Select
          name="country"
          options={countries}
          onChange={(e) => {
            setSelectedCountry(e?.value)
            setCurrentPage(1);
          }}
          styles={{ ...customSelectStyleNew(theme) }}
        />
      </div>

      <div className="filters filters-by-state pt-3">
        <div className="d-flex align-items-center mb-2 justify-content-between">
          <Label className="mb-0">{STATE}</Label>
          <button
            className="btn p-0"
            onClick={() => {
              setState({ label: '', value: '' });
              setRadius('');
            }}
          >
            {CLEAR}
          </button>
        </div>

        <Select
          name="state"
          isDisabled={!['US', null, undefined, ''].includes(selectedCountry)}
          value={selectedState.value ? selectedState : null}
          options={stateList}
          onChange={(e) => {
            setState(e);
            setCurrentPage(1);
          }}
          styles={{ ...customSelectStyleNew(theme) }}
        />
      </div>

      <div className="filters filters-by-city pt-3">
        <div className="d-flex align-items-center mb-2 justify-content-between">
          <Label className="mb-0">{CITY}</Label>
          <button className="btn p-0" onClick={() => setCity('')}>
            {CLEAR}
          </button>
        </div>

        <Input
          type="text"
          name="city"
          value={city}
          placeholder="City..."
          styles={CUSTOM_SELECT_STYLE}
          onChange={(e) => setCity(e.target.value)}
          onKeyDown={handleSearchEnterDown}
          onKeyUp={handleSearchEnterUp}
        />
      </div>

      <div className="filters filters-by-zip pt-3">
        <div className="d-flex align-items-center mb-2 justify-content-between">
          <Label className="mb-0">{ZIP}</Label>

          <button className="btn p-0" onClick={() => setZipCode('')}>
            {CLEAR}
          </button>
        </div>

        <Input
          type="text"
          name="zipcode"
          value={zipCode}
          placeholder="Zip code or postal code..."
          styles={CUSTOM_SELECT_STYLE}
          onChange={(e) => setZipCode(e.target.value)}
        />
      </div>

      <div className="filters filters-by-zip pt-3">
        <div className="d-flex align-items-center mb-2 justify-content-between">
          <Label className="mb-0">{AREA_WITHIN_RADIUS}</Label>

          <button className="btn p-0 dark-theme-text" onClick={() => setRadius('')}>
            {CLEAR}
          </button>
        </div>

        <Select
          name="areaWithRadius"
          options={RADIUS_OPTIONS(selectedCountry)}
          value={!!radius.value ? radius : null}
          isDisabled={!city && !zipCode && true}
          onChange={(e) => setRadius(e)}
          styles={{ ...customSelectStyleNew(theme) }}
        />
      </div>

      {!!aggregationResult?.length &&
        (userRoles?.includes('ADMIN') || userRoles?.includes('EXECUTIVE')) && (
          <div className="my-4">
            <div className="d-flex align-items-center mb-2 justify-content-between">
              <Label className="mb-2">Filter By Skills</Label>
            </div>

            <div>
              {aggregationResult?.map((item) => (
                <div className=" ml-4 mt-2">
                  <Input
                    type="checkbox"
                    className="checkboxStyle"
                    value={item?.key}
                    checked={facets[item?.key] || false}
                    onChange={(e) => {
                      console.log("before change :: ")
                      handleCheckboxChange(item?.key)
                      handleSkills(e)
                    }}
                  />
                  <div style={{ fontSize: '12px', paddingTop: '3px' }}>
                    {item?.key} ({item?.doc_count})
                  </div>
                </div>
              ))}
              {aggregateResult?.length > 10 && (
                <Button
                  className="border-0 bg-transparent rounded text-primary pl-1"
                  style={{ fontSize: '14px' }}
                  onClick={() => {
                    seeMore();
                    setSeeMoreBtn(!seeMoreBtn);
                  }}
                >
                  {seeMoreBtn ? 'See Less...' : 'See More..'}
                </Button>
              )}
            </div>
          </div>
        )}

      <div className='mt-4 d-flex align-items-center justify-content-around flex-wrap gap-10'>
        <CustomButton
          buttonText={'Search results'}
          buttonColor="primary"
          buttonType="button"
          buttonClick={() => {
            setSearchCall(!searchCall);
            setCurrentPage(1)
          }}
        />

        <CustomButton
          buttonText={'Clear'}
          buttonColor={''}
          buttonType="button"
          buttonClick={() => {
            clearFilters();
            setAggregationResult([]);
          }}
          className="graydark-button py-2"
        />
      </div>
    </div>
  );
};

export default CandidateSearchFilters;
