import { useState } from "react";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { Col, Row } from "reactstrap";
import AddAbleFields from "../../../components/AddAbleFields";
import ConfirmPopUp from "../../../components/ConfirmPopUp";
import { DELETE_CANDIDATE_PHONE } from "../../candidate/gql";
import { DELETE_CONTACT_PHONE } from "../gql";

const ContactPhone = ({
  customPhoneInputs,
  setCustomPhoneInputs,
  error,
  setErr,
  isRequired = false,
  forCandidate = false,
}: any) => {
  const [deletePhoneToggle, setDeletePhoneToggle] = useState(false);
  const [deletePhone] = useMutation(DELETE_CONTACT_PHONE);
  const [_phoneId, setPhoneId] = useState("");
  const [_deleteCandidatePhone] = useMutation(DELETE_CANDIDATE_PHONE);

  const handleRemove = (index: number, phoneId: string) => {
    if (phoneId) {
      setDeletePhoneToggle(true);
      setPhoneId(phoneId);
    } else {
      let _phoneInputs = [...customPhoneInputs];
      _phoneInputs.splice(index, 1);
      setCustomPhoneInputs([..._phoneInputs]);
    }
  };

  const deletePhoneInDb = async () => {
      if (forCandidate) {
        const response = await _deleteCandidatePhone({ variables: { id: _phoneId } });
        if (!!response?.errors?.length) {
          setDeletePhoneToggle(false);
          return toast.error(!!response.errors[0] ? response.errors[0].message : "Phone can't be deleted");
        }
      } else {
         await deletePhone({ variables: { id: _phoneId } });
      }
      setDeletePhoneToggle(false);
        let _phoneInputs = [...customPhoneInputs];
        _phoneInputs = _phoneInputs?.filter((item) => item.phoneId !== _phoneId);
        setCustomPhoneInputs([..._phoneInputs]);
        toast.success("Successfully Deleted!");
    };

  const handleAddMore = () => {
    setCustomPhoneInputs([
      ...customPhoneInputs,
      {
        phone: "",
        phoneType: "",
        isPrimary: false,
        phoneId: "",
      },
    ]);
  };

  const handleInputChange = (index: number, event: any) => {
    let phones = [...customPhoneInputs];
    if (event.target.type === "checkbox") {
      for (const item of phones) {
        item.isPrimary = false;
      }
      phones[index][event.target.name] = event.target.checked;
    } else {
      phones[index][event.target.name] = event.target.value;
    }

    setCustomPhoneInputs([...phones]);
  };
  return (
    <>
      <div className="user-basic-form">
        <Row>
          <Col md="12">
            {customPhoneInputs?.map((item: any, index: number) =>
              <AddAbleFields
                index={index}
                key={index}
                handleRemove={handleRemove}
                handleChange={handleInputChange}
                value={item.phone}
                checked={item.isPrimary}
                isPrimary={!!item.isPrimary}
                recordId={item.phoneId}
                length={customPhoneInputs?.length}
                label="Phone"
                inputName="phone"
                isPhone={true}
                phoneType={item.phoneType}
                isRequired={isRequired}
              />
            )}

            <small className="text-danger">{error}</small>
            <div className="my-3">
              <button
                onClick={handleAddMore}
                className="buttonGenericStyle filledButton buttonHeight"
                type="button"
              >
                Add New Phone
              </button>
            </div>
          </Col>
        </Row>
      </div>

      <ConfirmPopUp
        confirmText="Are you sure you want to delete this phone?"
        isOpen={deletePhoneToggle}
        toggle={setDeletePhoneToggle}
        confirmAction={deletePhoneInDb}
        modalHeading="Delete Phone"
        btnText="Delete"
        btnColor="primary"
      />
    </>
  );
};

export default ContactPhone;
