import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { dracula } from "react-syntax-highlighter/dist/esm/styles/prism";
import RobotImage from '../../assets/images/svgs/robot.svg';
import AvatarImage from '../../assets/images/user-empty.svg';
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context";
import { ClipBoardIcon, GptLoaderIcon } from "../../assets/images/svgs";

const ChatItem = ({ message, id, key, promptMessageForDisplay, promptMessageForVerification, loading ,heading}) => {
  const [copied, setCopy] = useState<boolean>(false);
  const codeBlockRegex = /```([\s\S]*?)```/;
  const strings = message?.content?.split(codeBlockRegex);
  const { user } = useContext(AppContext);
  const [matchedString, setMatchedString] = useState<string>('');

  useEffect(() => {
    if (['3 To 5 Must Have Skills', '3 To 5 Good To Have Skills', '3 To 5 Interview Questions'].includes(heading)) {
      const foundString = ['3 To 5 Must Have Skills', '3 To 5 Good To Have Skills', '3 To 5 Interview Questions'].find(str => message?.content?.includes(str));
      if (foundString) {
        setMatchedString(`Provide Me Based on job description: ${foundString}`);  
      }
    }
  }, [heading, message]);

  const onCopyText = (text) => {
    navigator.clipboard.writeText(text);
    setCopy(true);

    setTimeout(() => {
      setCopy(false);
    }, 1500)
  };

  return (
    <div>
      <div>
        {copied && (
          <span className="badge badge-main badge-warning">Copied</span>
        )}
        {strings.map((str, index) => {
          if (index % 2 === 0) {
            return (
              <div>

                {message?.role === 'assistant' && str ? (
                  <>
                  {message?.stoppedMessage!=="stop"&& <GptLoaderIcon />}
                    <div className="chatgpt-reply">
                      <div className="d-flex align-items-start">
                        <img
                          src={RobotImage}
                          height="24px"
                          width="24px"
                          alt="bot"
                        />

                        <pre className="chatgpt-text pre-text ml-2 mb-0">
                          <>{str}</>
                        </pre>

                        <button
                          onClick={() => onCopyText(str)}
                          className="border-0"
                        >
                          <ClipBoardIcon />
                        </button>
                      </div>
                    </div>
                  </>
                ) : (
                  <div>
                    {message?.role === 'user' && str && (
                      <div className="d-flex align-items-start p-3">
                        <img
                          src={user?.photo || AvatarImage}
                          height="24px"
                          width="24px"
                          alt="Avatar"
                          className="dashboard-profile-img"
                        />
                        {!matchedString && promptMessageForVerification ? (
                          <>
                            {message?.content?.includes(
                              promptMessageForVerification
                            ) ? (
                              <pre className="chatgpt-text pre-text ml-2 mb-0">
                                {promptMessageForDisplay}
                              </pre>
                            ) : (
                              <pre className="chatgpt-text ml-2 mb-0  pre-text">
                                {str}
                              </pre>
                            )}
                          </>
                        ) : (
                          <>
                            {matchedString ? (
                              <pre className="chatgpt-text ml-2 mb-0  pre-text">
                                {matchedString}
                              </pre>
                            ) : (
                              <pre className="chatgpt-text ml-2 mb-0  pre-text">
                                {str}
                              </pre>
                            )}
                          </>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
            );
          } else {
            const code = str.replace(/```/g, '');
            const codeLanguage = code.split('\n')[0] || 'javascript';
            return (
              <div>
                <SyntaxHighlighter
                  language={codeLanguage}
                  style={dracula}
                  showLineNumbers={true}
                  customStyle={{
                    overflow: 'scroll',
                  }}
                >
                  {code}
                </SyntaxHighlighter>
              </div>
            );
          }
        })}
      </div>

      {loading && <GptLoaderIcon />}
    </div>
  );
};
export default ChatItem;
