import { useLazyQuery, useMutation } from "@apollo/client";
import { useContext, useEffect, useState } from "react";
import {
  Card,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import SEARCH_IMG from "../../../assets/images/search.svg";
import Pagination from "../../../components/layout/Pagination";
import { customSelectDropDown } from "../../../components/styled/customSelect";
import { AppContext } from "../../../context";
import { useParams } from "react-router";
import Select from "react-select";
import { toast } from "react-toastify";
import { ReplyIcon } from "../../../assets/images/svgs";
import VIEW_ICON from "../../../assets/images/viewIcon.svg";
import ConfirmPopUp from "../../../components/ConfirmPopUp";
import ContentViewSidebar from "../../../components/ContentViewSidebar";
import ReplyButton from "../../../components/ReplyButton";
import InputField from "../../../components/layout/InputField";
import Loader from "../../../components/layout/Loader";
import NoDataFound from "../../../components/layout/NoDataFound";
import TableComponent from "../../../components/layout/TableComponent";
import { formatDateUS } from "../../../utils/commonFn";
import {
  EMAIL_NOTES_FOR_CONTACTS,
  PAGE_LIMIT,
  emailNotesCategoriesForContact,
} from "../../../utils/constant";
import { userReplyAccess } from "../../../utils/helper";
import {
  DELETE_EMAIL_NOTE,
  GET_EMAIL_NOTES_FOR_COMPANY_CONTACT,
} from "../../company/gql";

let time = 0;

export default function ContactEmailNotes() {
  const [searchParams, setSearchParams] = useState<string>("");
  const { theme, user } = useContext(AppContext);
  const { id, currentPage }: any = useParams();

  const _page = currentPage || "1";
  const _currentPage: number = parseInt(_page);
  const [noteCategory, setNoteCategory] = useState<string>("");
  const [page, setPage] = useState(_currentPage);
  const [limit, setLimit] = useState(10);
  const [confirmNotePopup, setConfirmNotePopUp] = useState(false);
  const [deleteNoteId] = useState(null);
  const [_deleteEmailNote] = useMutation(DELETE_EMAIL_NOTE);
  const [emailNoteContent, setEmailNoteContent] = useState("");
  const [emailNoteViewSidebar, setEmailNoteViewSidebar] = useState(false);
  const [emailNote, setEmailNote] = useState(null);
  const [emailAttachments, setEmailAttachments] = useState<{ fileName: string, url: string }[]>();
  const [hasAttachment, setHasAttachment] = useState<boolean>(false);

  const deleteNote = async () => {
    let res = await _deleteEmailNote({ variables: { noteId: deleteNoteId } });
    if (res?.data) {
      toast.success("Successfully deleted!");
      getEmailNotesForContact();
      setConfirmNotePopUp(false);
    } else {
      toast.error("Something went wrong, email note is not delete!");
      setConfirmNotePopUp(false);
    }
  };

  const [getEmailNotesForContact, { data: getEmailNotesForContactData, loading: getEmailNotesForContactLoading }
  ] = useLazyQuery(GET_EMAIL_NOTES_FOR_COMPANY_CONTACT);

  useEffect(() => {
    if (id) {
      getEmailNotesForContact({
        variables: {
          emailNotesPayloadForParticularContact: {
            text: searchParams,
            contactId: id,
            category: noteCategory,
          },
          limit,
          page: page,
        },
      });
    }
    // eslint-disable-next-line
  }, []);

  const searchControl = (value: any) => {
    setSearchParams(value);
    setPage(1);
    setLimit(25);
    if (!value || value?.length > 2) {
      window.clearTimeout(time);
      time = window.setTimeout(() => {
        getEmailNotesForContact({
          variables: {
            emailNotesPayloadForParticularContact: {
              text: value,
              contactId: id,
              category: noteCategory,
            },
            limit,
            page: page,
          },
        });
      }, 1000);
    }
  };

  const fetchEmailNotesForContact = () => {
    if (!searchParams || searchParams?.length > 2) {
      getEmailNotesForContact({
        variables: {
          emailNotesPayloadForParticularContact: {
            text: searchParams,
            contactId: id,
            category: noteCategory,
          },
          limit,
          page: page,
        },
      });
    }
  };

  useEffect(() => {
    fetchEmailNotesForContact();
    // eslint-disable-next-line
  }, [page, limit, noteCategory]);

  return (
    <div>
      <Card className="border-0">
        <div className="actionLogTableRecamp d-flex justify-content-end">
          <div className="mr-3" style={{ width: 250 }}>
            <Select
              isClearable
              options={emailNotesCategoriesForContact}
              onChange={(event: any) => {
                setNoteCategory(event?.value);
              }}
              styles={{ ...customSelectDropDown(theme) }}
              placeholder="Category"
            />
          </div>

          <div className="search-filter">
            <div>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <img src={SEARCH_IMG} alt="search" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  className="search-by-company"
                  type="text"
                  placeholder="Search"
                  value={searchParams}
                  onChange={(e) => {
                    searchControl(e.target.value);
                    setSearchParams(e.target.value);
                  }}
                />
              </InputGroup>

              <small className="text-info">
                {searchParams &&
                  searchParams?.length <= 2 &&
                  "Please add more than two characters"}
              </small>
            </div>
          </div>
        </div>

        <div>
          <div className="table-responsive">
            <TableComponent tableHeader={EMAIL_NOTES_FOR_CONTACTS}>
              {getEmailNotesForContactLoading && (
                <tbody>
                  <tr>
                    <td colSpan={12}>
                      <Loader loaderClass="sm-height " />
                    </td>
                  </tr>
                </tbody>
              )}
              <tbody>
                {getEmailNotesForContactData?.getEmailNotesForParticularContact
                  ?.emailNotes &&
                  getEmailNotesForContactData?.getEmailNotesForParticularContact?.emailNotes?.map(
                    (item: any, index: number) => (
                      <tr key={index}>
                        <td>{item?.categories || "--"}</td>
                        <td className="">
                          {item.subject?.length > 30
                            ? `${item?.subject?.substring(0, 30)}...`
                            : item?.subject || "--"}
                        </td>
                        <td>{item.taggedUser?.fullName || "--"}</td>
                        <td>
                          {item?.sentDateTime
                            ? formatDateUS(item?.sentDateTime)
                            : "--"}{" "}
                          {item?.sentDateTime &&
                            new Date(item.sentDateTime).toLocaleTimeString()}
                        </td>
                        <td>
                          <div
                            className="d-flex align-items-center justify-content-start"
                            style={{ width: 150 }}
                          >
                            <button
                              className="iconButtonAction mx-2"
                              onClick={() => {
                                setEmailAttachments(item?.attachments);
                                setHasAttachment(item?.hasAttachment);
                                setEmailNoteContent(item?.bodyContent);
                                setEmailNoteViewSidebar(!emailNoteViewSidebar);
                                setEmailNote({ ...item });
                              }}
                            >
                              <img src={VIEW_ICON} alt="view" width="21px" />
                            </button>

                            {userReplyAccess(item, user) &&
                              item?.emailWebLink ? (
                              <ReplyButton
                                text={false}
                                link={item?.emailWebLink}
                              />
                            ) : (
                              <ReplyIcon color="#8e949d" />
                            )}
                          </div>
                        </td>
                      </tr>
                    )
                  )}
              </tbody>

              {!getEmailNotesForContactLoading &&
                !getEmailNotesForContactData?.getEmailNotesForParticularContact
                  ?.emailNotes?.length && (
                  <tbody>
                    <tr>
                      <td colSpan={12} className="empty-table-td">
                        <NoDataFound text="No Email Note Found!" />
                      </td>
                    </tr>
                  </tbody>
                )}
            </TableComponent>
          </div>
          {getEmailNotesForContactData?.getEmailNotesForParticularContact
            ?.emailNotes?.length ? (
            <div className="users-pagination">
              <Pagination
                onPageChange={(pageClicked: number) => {
                  setPage(pageClicked);
                }}
                pageCount={Math.ceil(
                  getEmailNotesForContactData?.getEmailNotesForParticularContact
                    ?.count / limit
                )}
                currentPage={page}
              />

              <div className="d-flex align-items-center">
                <div className="pagination-display paginationItemsSelect">
                  <p className="m-0">
                    {(page - 1) * limit + 1} to{" "}
                    {Math.min(
                      page * limit,
                      getEmailNotesForContactData
                        ?.getEmailNotesForParticularContact?.count
                    )}{" "}
                    of{" "}
                    {
                      getEmailNotesForContactData
                        ?.getEmailNotesForParticularContact?.count
                    }
                  </p>

                  <div className="divider"></div>

                  <label>Display</label>

                  <InputField
                    label=""
                    inputtype="select"
                    selectItems={PAGE_LIMIT.filter(
                      (item) => item.value !== limit
                    )}
                    inputid="limit"
                    placeholder={limit.toString()}
                    inputMethod={(value: string) => {
                      setPage(1);
                      setLimit(parseInt(value));
                    }}
                  />
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </Card>

      <ContentViewSidebar
        toggle={() => setEmailNoteViewSidebar(!emailNoteViewSidebar)}
        content={emailNoteContent}
        hasAttachment={hasAttachment}
        emailAttachments={emailAttachments}
        emailNotes={emailNote}
        isOpen={emailNoteViewSidebar}
        heading={"View Contact Email Content"}
        emailHeader={null}
      />

      <ConfirmPopUp
        confirmText="Are you sure you want to delete this note?"
        isOpen={confirmNotePopup}
        toggle={setConfirmNotePopUp}
        confirmAction={deleteNote}
        modalHeading="Delete Note"
        btnText="Delete"
        btnColor="primary"
        className="for-modal-styling"
      />
    </div>
  );
}
