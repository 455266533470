import { useQuery } from "@apollo/client"
import { useContext } from "react"
import { AppContext } from "../../../context"
import OpenGoalSheet from "../../../Pages/settings/OpenGoalSheet"
import NoDataFound from "../../layout/NoDataFound"
import { GET_ALL_RECRUITER_DEALS_FOR_RECRUITER } from "../recruiters-deal/gql"
import Deals from "../recruiters-deal/list-table"
import MentorCommissions from "../recruiters-deal/mentor-commission-overwrite"
import UserActivityReports from "./user-activity-report"

const RecruiterDetails = () => {
  const { user } = useContext(AppContext)
  const { data: deals, loading: dealsLoading, refetch } = useQuery(GET_ALL_RECRUITER_DEALS_FOR_RECRUITER, { variables: { recruiterId: user?.id } })


  return <>
    <div className="primaryHeading px-2">
      <h6 className="mb-2">Goal Sheet</h6>
      <OpenGoalSheet />
    </div>
    <div className="primaryHeading px-2">
      <h6 className="mb-2">User Activity Report</h6>
      <UserActivityReports />
    </div>
    {dealsLoading && <div className="my-5 loader" />}
    <div className="primaryHeading">
      {!dealsLoading && !!deals?.getRecruiterDealsForRecruiter?.recruiterDeals?.length &&
        <>
          <h6 className="mt-5 px-2 mb-2">Recruiter Deal</h6>
          <Deals povRecruiter
            recruiterDeals={deals?.getRecruiterDealsForRecruiter?.recruiterDeals}
          />
        </>}

      <MentorCommissions povRecruiter
        recruiterDeals={deals?.getRecruiterDealsForRecruiter?.recruiterDeals}
      />
    </div>
  </>
}

export default RecruiterDetails