import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  Button,
  Col,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
} from "reactstrap";
import Select from "react-select";
import {
  FETCHING_USERS_MINIMIZEVERSION,
  FETCH_USER_DETAIL,
  TERMINATE_AND_TRANSFER_USER,
} from "../gql";
import { useContext, useState } from "react";
import { toast } from "react-toastify";
import CustomButton from "../../../components/layout/CustomButton";
import { useHistory } from "react-router";

import CLOSE_ICON from "../../../assets/images/close-icon.svg";
import { AppContext } from "../../../context";
import { customSelectDropDown } from "../../../components/styled/customSelect";

const UserTerminate = ({ isOpen, toggle, fullName, teamName, id }) => {
  const { theme } = useContext(AppContext)
  const [termsAndConditions, setTermsAndConditions] = useState<boolean>(false);
  const [transferTo, setTransferTo] = useState<string>();
  const history = useHistory();
  const { data } = useQuery(FETCHING_USERS_MINIMIZEVERSION);
  const [terminateAndTransfer, { loading: loadingTerminateAndTransfer }] =
    useMutation(TERMINATE_AND_TRANSFER_USER);

  const [getUserDetail, { data: userDetail }] = useLazyQuery(FETCH_USER_DETAIL);

  const getUserDetailsHandler = (_id: string) => {
    getUserDetail({ variables: { userId: _id } });
  };

  const disableTerminateButton = (): boolean => {
    return userDetail?.findUserById && termsAndConditions && id ? false : true;
  };

  const onTerminateAndSave = async () => {
    const response = await terminateAndTransfer({
      variables: { from: id, to: transferTo, termsAndConditions },
    });

    if (response?.data) {
      setTermsAndConditions(false);
      getUserDetailsHandler(id);
      toggle();
      toast.success(
        `Successfully terminated ${termsAndConditions ? "and transfered" : ""}`
      );
      history.push("/admin/users");
    }
  };

  return (
    <div>
      <Modal
        isOpen={isOpen}
        toggle={() => toggle()}
        className="revampDialog revampDialogWidth"
      >
        <div className="modalHeaderCustom d-flex align-items-center justify-content-between">
          <h5 className="m-0">Terminate & Transfer</h5>

          <Button className="p-0 bg-transparent border-0" onClick={toggle}>
            <img src={CLOSE_ICON} alt="close" />
          </Button>
        </div>

        <ModalBody>
          <Row>
            <Col xs="12">
              <p className="recruiter-to-terminate">Recruiter To Terminate</p>
            </Col>
            <Col xs="12">
              <p className="recruiter-to-terminate-name">{fullName}</p>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col xs="12">
              <p className="recruiter-to-terminate">Select New Recruiter</p>
            </Col>
            <Col xs="12">
              <div>
                <Select
                  placeholder="Recruiter to select"
                  isSearchable={true}
                  options={data?.allUsersDropDown
                    ?.filter(
                      (item: any) =>
                        item?.id !== id &&
                        item?.userRoles?.find(
                          (item: any) => item?.role?.name === "RECRUITER"
                        )
                    )
                    ?.map((item: any) => {
                      return {
                        label: item?.fullName,
                        value: item?.id,
                      };
                    })}
                  onChange={(target: any) => {
                    setTransferTo(target.value);
                    getUserDetailsHandler(target?.value);
                  }}
                  styles={{ ...customSelectDropDown(theme) }}
                />
              </div>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col xs="12">
              <div className="conditions-terminate">
                <div>
                  <Input
                    className="checkbox-conditions-terminate"
                    onChange={() => setTermsAndConditions(!termsAndConditions)}
                    type="checkbox"
                    aria-label="Checkbox for following text input"
                  />

                  <h5>
                    By clicking the checkbox I agree to take the following
                    actions:
                  </h5>

                  <ul>
                    <li>Terminate Candidate Ownership</li>
                    <li>
                      Company Ownership will be transferred to
                      <span> {userDetail?.findUserById?.fullName || "--"}</span>
                    </li>
                    <li>
                      Deactivate <span> {teamName || "--"}</span>
                    </li>
                    <li>Revoke Login Access</li>
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <button
            onClick={() => toggle()}
            className="buttonPrimary mr-3"
            type="button"
          >
            Cancel
          </button>

          <CustomButton
            buttonColor="primary"
            buttonText="Terminate"
            disabled={disableTerminateButton()}
            loading={loadingTerminateAndTransfer}
            buttonClick={onTerminateAndSave}
          />
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default UserTerminate;
