export const companyContactAndEmailErrors = (contact, email) => {
  let err: any = [];

  // Checking contactEmail length
  if (!email) {
    err.push({
      type: "contactEmail",
      message: "Contact does not have a primary email",
    });
  }

  // Checking other fields of companyContact
  if (!contact?.firstName) {
    err.push({
      type: "firstName",
      message: "Contact first name does't exist!",
    });
  }
  if (!contact?.lastName) {
    err.push({
      type: "lastName",
      message: "Contact last name does't exist!",
    });
  }
  if (!contact?.jobTitle) {
    err.push({
      type: "jobTitle",
      message: "Contact's job title does't exist!",
    });
  }

  return err;
};
