import moment from "moment"
import { FC } from "react"
import { Modal, ModalBody, ModalHeader, Table } from "reactstrap"
import { InvoiceEmailingDetailModalProps } from "../../interfaces"
import { InvoiceEmailHistory } from "../../Pages/interseller/steps/interfaceSteps"
import NoDataFound from "../layout/NoDataFound"
import TableComponent from "../layout/TableComponent"

const InvoiceEmailingDetailModal: FC<InvoiceEmailingDetailModalProps> = ({ isOpen, emailHistory, toggle }) => {

  return (
    <Modal isOpen={isOpen} toggle={toggle} className="for-modal-styling">
      <ModalHeader>
        Invoice Emailing Details
      </ModalHeader>
      <ModalBody className="pb-5">
        <div style={{ height: 400, overflowY: 'auto' }}>
          <TableComponent tableHeader={["EmailType", "EmailTo", "Date", "Time"]}>
            <tbody>
              {!emailHistory?.length ? (
                <tr>
                  <td colSpan={2} className="empty-table-td">
                    <NoDataFound text="No Invoice Emailing Details Found!" />
                  </td>
                </tr>
              ) : (
                emailHistory?.map(
                  ({ createdAt, emailType, emailTo }: InvoiceEmailHistory, index: number) => (
                    <tr key={index} >
                      <td>{emailType}</td>
                      <td>{emailTo}</td>
                      <td>
                        {moment(Number(createdAt)).format('MM/DD/YYYY')}
                      </td>
                      <td>
                        {moment(Number(createdAt)).format('hh:mm A')}
                      </td>

                    </tr>
                  )
                )
              )}
            </tbody>
          </TableComponent>
        </div>
      </ModalBody>
    </Modal >
  )
}
export default InvoiceEmailingDetailModal