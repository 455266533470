import { useLazyQuery, useMutation } from "@apollo/client";
import classnames from "classnames";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Badge, Nav, NavItem, NavLink, TabContent,
  TabPane, Tooltip
} from "reactstrap";
import LINK_ICON from "../../assets/images/link-icon.svg";
import LINKEDIN_IMG from "../../assets/images/social1.svg";
import USER_ICON from "../../assets/images/user-icon.svg";
import ConfirmPopUp from "../../components/ConfirmPopUp";
import GoBack from "../../components/GoBack";
import CustomButton from "../../components/layout/CustomButton";
import SocialMediaInfo from "../../components/SocialMediaInfo";
import BasicInfo from "./company-components/BasicInfo";
import CompanyAddressCard from "./company-components/CompanyAddressCard";

import ActionLogsAndNotes from '../../components/ActionLogsAndNotes';
import AddNotesModal from '../../components/AddNotesModal';
import AlertCard from "../../components/layout/Alert";
import Loader from '../../components/layout/Loader';
import WidgetCard from "../../components/layout/WidgetCard";
import { AppContext } from '../../context';
import { COMPANIES_ROUTE, JOB_ORDER_ROUTE } from '../../utils/constant';
import { countSummaryWords } from '../../utils/helper';
import { IComingState } from '../agreements/interfaces';
import Attachments from './company-components/Attachments';
import OwnerShipShare from "./company-components/OwnerShipShare";
import './company.scss';
import {
  DELETE_COMPANY_DOMAIN,
  FETCH_COMPANY_DETAILS, GET_COMPANY_STATS,
  GET_NOTES,
  UPDATE_COMPANY
} from "./gql";

const UpdateCompanyGoBackLogic = ({
  from,
  comingState,
  stateData,
}: {
  from: string;
  comingState: any;
  stateData: any;
}) => {
  switch (from) {
    case 'non-legacy-agreements':
      return <GoBack url={'/create-agreement'} data={stateData} />;

    case 'legacy-agreements':
      return <GoBack url={'/create-legacy-agreement'} data={stateData} />;

    default:
      return (
        <GoBack
          url={comingState?.backUrl ?? COMPANIES_ROUTE}
          search={comingState?.searchParameters ?? ''}
        />
      );
  }
};

const UpdateCompany = (props: any) => {
  const {
    errors,
    handleSubmit,
    register,
    control,
    setValue,
    reset,
    getValues,
  } = useForm({
    defaultValues: {
      domains: [{ domain: '', isPrimary: false, companyDomainId: '' }],
    },
  });

  const location = useLocation<IComingState>();

  const comingState = location.state;
  let history = useHistory();
  const activeParamTab =
    new URLSearchParams(location.search).get('activeTab') || '2';

  const [activeTab, setActiveTab] = useState(activeParamTab);
  const [companyId, setCompanyId] = useState('');
  const from = new URLSearchParams(location?.search).get('from');
  const { user, userRoles } = useContext(AppContext);

  const [selectedIndustry, setSelectedIndustry] = useState<Array<any>>([]);
  const [companyStatus, setCompanyStatus] = useState('');
  const [summaryErr, setSummaryErr] = useState<string>('');
  const [confirmToggle, setConfirmToggle] = useState(false);
  const [_domainId] = useState('');
  const [noteData, setNoteData] = useState(null);
  const [activityNotes, setActivityNotes] = useState<boolean>(false);

  const [tooltipOpen1, setTooltipOpen1] = useState(false);
  const toggle1 = () => setTooltipOpen1(!tooltipOpen1);

  const [tooltipOpen2, setTooltipOpen2] = useState(false);
  const toggle2 = () => setTooltipOpen2(!tooltipOpen2);

  const [tooltipOpen3, setTooltipOpen3] = useState(false);
  const toggle3 = () => setTooltipOpen3(!tooltipOpen3);

  const toggleActivityNotes = () => setActivityNotes(!activityNotes);
  const [updateCompany, { loading: waiting }] = useMutation(UPDATE_COMPANY, {
    onError: (error) => {
      toast.error('ERROR');
    },
  });

  const [getCompanyStats, { data: companyStats }] =
    useLazyQuery(GET_COMPANY_STATS);

  const [getCompanyDetail, { data, loading }] = useLazyQuery(
    FETCH_COMPANY_DETAILS
  );

  const [deleteDomain] = useMutation(DELETE_COMPANY_DOMAIN);

  const fetchCompanyDetails = () => {
    getCompanyDetail({ variables: { companyId } });
  };

  const [getNotes] = useLazyQuery(GET_NOTES);
  const _getNotes = () => {
    getNotes({
      variables: {
        id: companyId,
        notePayload: { type: 'Company' },
        page: 1,
        limit: 3,
      },
    });
  };

  const checkEditPermission = (company: any) => {
    if (company) {
      if (userRoles?.includes('ADMIN') || userRoles?.includes('EXECUTIVE')) {
        return true;
      }
      if (!company?.owner) {
        return true;
      }
      if (company?.owner?.id === user?.id) {
        return true;
      }
      if (company?.ownerAndSharees?.length) {
        // eslint-disable-next-line
        let currentUser = company?.ownerAndSharees?.find((item) => {
          if (item.ownerType === 'SHAREE' && item.owner?.id === user?.id)
            return item;
        });
        if (currentUser) {
          return true;
        }
      }
      if (company?.status === 'DO_NOT_CONTACT') {
        return true;
      }
    }
    return false;
  };

  const _updateCompany = async (values: any) => {
    if (
      !values.summary ||
      values?.summary.replace(/<(.|\n)*?>/g, '').trim().length === 0
    ) {
      setSummaryErr('Summary field is required!');
      return;
    } else if (values?.summary && countSummaryWords(values?.summary) < 10) {
      setSummaryErr('Summary must have at least 10 words!');
      return;
    }

    setSummaryErr('');
    values.companyId = companyId;
    values.hasLinkedIn =
      values.hasLinkedIn && !values.linkedInUrl ? false : true;
    values.industry = selectedIndustry?.length
      ? selectedIndustry?.map((item) => item.value)
      : [''];

    const companyDomainIdToUpdate = !values?.hasCompanyURL
      ? data?.getCompany?.domain[0]?.id
      : '';

    values.domains = !values?.hasCompanyURL
      ? [
        {
          isPrimary: true,
          companyDomainId: companyDomainIdToUpdate,
          domain: values?.domain,
        },
      ]
      : [];

    delete values.domain;

    let res = await updateCompany({
      variables: { UpdateCompanyInput: { ...values } },
    });

    if (res?.data?.updateCompany?.status === 400) {
      toast.error(res?.data?.updateCompany?.message);
    } else if (res?.data?.updateCompany?.status === 200) {
      toast.success('Company updated successfully!');
      setTimeout(() => {
        fetchCompanyDetails();
        setValue('domains', [
          {
            domain: '',
            isPrimary: false,
            companyDomainId: '',
          },
        ]);
      }, 800);
    }
  };

  const deleteDomainInDb = () => {
    if (_domainId) {
      deleteDomain({ variables: { domainId: _domainId } });
      setConfirmToggle(false);
      toast.success('Domain successfully deleted!');
      fetchCompanyDetails();
    }
  };

  const toggleTab = (tab: string) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      history.push(`${location?.pathname}?activeTab=${tab}`);
    }
  };

  useEffect(() => {
    if (data?.getCompany) {
      document.title =
        'Update - ' +
        `${data?.getCompany.name || 'Company'} - Company - RealREPP`;

      let values = { ...data?.getCompany };
      let workPhone = data?.getCompany?.phones?.find(
        (phone: any) => phone.status === 'WORK' && phone?.phoneNumber
      );
      let faxPhone = data?.getCompany?.phones?.find(
        (phone: any) => phone.status === 'FAX' && phone.phoneNumber
      );
      values.phoneNumber = workPhone?.phoneNumber || '';
      values.faxNumber = faxPhone?.phoneNumber || '';
      values.domain = data?.getCompany.domain?.length
        ? data?.getCompany?.domain[0]?.domainUrl
        : '';
      const industries = values?.companyIndustry?.map((item) => ({
        label: item?.industry?.name,
        value: item?.industry?.name,
      }));
      values.hasLinkedIn = values?.hasLinkedIn ? false : true;
      setCompanyStatus(values?.status);
      setSelectedIndustry(industries);
      reset({ ...values });
    }
    // eslint-disable-next-line
  }, [data?.getCompany]);

  useEffect(() => {
    if (data?.getCompany && user?.id) {
      const permit = checkEditPermission(data?.getCompany);
      if (!permit) {
        history.push({
          pathname: '/un-authorized',
        });
      }
    }
    // eslint-disable-next-line
  }, [data?.getCompany, user]);

  useEffect(() => {
    let id = props.match.params.id;
    if (id) {
      setCompanyId(id);
      getCompanyDetail({ variables: { companyId: id } });
      getCompanyStats({ variables: { id } });
    }
    // eslint-disable-next-line
  }, []);

  if (loading) {
    return <Loader />;
  }
  return (
    <div className="admin-tabs team-tabs">
      <UpdateCompanyGoBackLogic
        from={from || ''}
        comingState={comingState}
        stateData={location?.state}
      />

      <div className="d-flex align-items-center primaryHeaderSpacing">
        <h5 className="mr-2 mb-0 text-truncate max-200">{data?.getCompany?.name || "--"}</h5>

        <Badge
          color={
            data?.getCompany?.status === 'ACTIVE'
              ? 'success'
              : data?.getCompany?.status === 'PROSPECT'
                ? 'warning'
                : data?.getCompany?.status === 'DRAFT'
                  ? 'primary'
                  : data?.getCompany?.status === 'DO_NOT_CONTACT'
                    ? 'danger'
                    : data?.getCompany?.status === 'ACTIVE_AGREEMENT'
                      ? 'info'
                      : ''
          }
          className="text-capitalize"
        >
          {data?.getCompany?.status}
        </Badge>
      </div>

      <div className="primaryHeaderSpacing">
        <div className="userBadgeInfo">
          <div className="applicantDetail">
            <div className="infoCardDivider">
              <a
                href={data?.getCompany?.linkedInUrl}
                target="_blank"
                className="m-0 d-flex"
                rel="noreferrer"
              >
                <Tooltip
                  placement="top"
                  isOpen={tooltipOpen1}
                  target="TooltipExample1"
                  toggle={toggle1}
                >
                  Linkedin
                </Tooltip>
                <img
                  src={LINKEDIN_IMG}
                  alt="linkedin"
                  width="17px"
                  id="TooltipExample1"
                />
              </a>
            </div>

            <div className="divider"></div>

            <div className="infoCardDivider">
              <Tooltip
                placement="top"
                isOpen={tooltipOpen2}
                target="TooltipExample2"
                toggle={toggle2}
              >
                Company URL
              </Tooltip>
              <img
                src={LINK_ICON}
                alt="company"
                width="20px"
                id="TooltipExample2"
              />
              <label>
                <a
                  href={data?.getCompany?.domain[0]?.domainUrl}
                  target="_blank"
                  rel="noreferrer"
                  className="m-0"
                >
                  {data?.getCompany?.domain[0]?.domainName || '--'}
                </a>
              </label>
            </div>

            <div className="divider"></div>

            <div className="infoCardDivider">
              <Tooltip
                placement="top"
                isOpen={tooltipOpen3}
                target="TooltipExample3"
                toggle={toggle3}
              >
                Company Owner
              </Tooltip>
              <img
                src={USER_ICON}
                alt="address"
                width="17px"
                id="TooltipExample3"
              />
              <label>{data?.getCompany?.owner?.fullName || '--'}</label>
            </div>
          </div>
        </div>
      </div>

      {companyStatus === 'DO_NOT_CONTACT' && (
        <AlertCard
          title="Warning!"
          subTitle="Do not contact this company."
          color="danger"
        />
      )}

      <div className="CandidateTabsWrapper">
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '1' })}
              onClick={() => {
                toggleTab('1');
              }}
            >
              About
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '2' })}
              onClick={() => {
                toggleTab('2');
              }}
            >
              Basic Info
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '3' })}
              onClick={() => {
                toggleTab('3');
              }}
            >
              Owners & Sharee
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '4' })}
              onClick={() => {
                toggleTab('4');
              }}
            >
              Attachment
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '5' })}
              onClick={() => {
                toggleTab('5');
              }}
            >
              Address
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '6' })}
              onClick={() => {
                toggleTab('6');
              }}
            >
              Activities
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              target='_blank'
              className={classnames({ active: activeTab === "7" })}
              onClick={(e) => {
                e.preventDefault();
                window.open(`${JOB_ORDER_ROUTE}?currentPage=1&selectedCompanyId=${companyId}&selectedCompanyName=${data?.getCompany.name}&statusFilter=ACTIVE`, "_blank");

              }}
            >
              Job Orders
            </NavLink>
          </NavItem>
        </Nav>


        <TabContent activeTab={activeTab}>
          <TabPane className="tab-pane-padding" tabId="1">
            <div>
              <h5>Company Widget</h5>
              <WidgetCard
                stats={companyStats?.getCompanyStats}
                sourceNote={data?.getCompany?.sourceNote}
              />
            </div>
          </TabPane>

          <TabPane tabId="2" className="tab-pane-padding">
            <BasicInfo
              {...{
                getValues,
                setValue,
              }}
              control={control}
              register={register}
              errors={errors}
              selectedIndustry={selectedIndustry}
              setSelectedIndustry={setSelectedIndustry}
              recordId={companyId}
              companySource={data?.getCompany?.source}
              companyStatus={data?.getCompany?.status || ''}
              hasCareerPageURL={data?.getCompany?.hasCareerPageURL || false}
              hasCompanyURL={data?.getCompany?.hasCompanyURL || false}
              summaryErr={summaryErr}
              setSummaryErr={setSummaryErr}
            />

            <SocialMediaInfo
              setValue={setValue}
              isLinkedinRequired={data?.getCompany?.hasLinkedIn}
              register={register}
              recordId={companyId}
              nonSolicitStatus={data?.getCompany?.nonSolicit || false}
              errors={errors}
              companyAddress={true}
              getValues={getValues}
            />
          </TabPane>

          <TabPane tabId="3">
            <OwnerShipShare
              reload={fetchCompanyDetails}
              sharingId={companyId}
              sharingType="company"
              company={data?.getCompany}
            />
          </TabPane>

          <TabPane tabId="4">
            <Attachments companyId={companyId} attachableType={'Company'} />
          </TabPane>

          <TabPane tabId="5">
            <CompanyAddressCard
              companyId={companyId}
            />
          </TabPane>

          <TabPane tabId="6">
            <div className="jobOrdersActionLog">
              <ActionLogsAndNotes
                type="Company"
                recordId={companyId}
                option={{
                  companyId: data?.getCompany?.id ?? '',
                  companyName: data?.getCompany?.name ?? '',
                }}
              />
            </div>
          </TabPane>

          <TabPane tabId="7" className="tab-pane-padding">
            {/* <CompanyJobOrders companyId={data?.getCompany?.id} /> */}
          </TabPane>
        </TabContent>

        {(activeTab === '2' || '') && (
          <div className="justify-content-end d-flex tab-pane-padding pt-2 pb-4">
            <CustomButton
              buttonText="Update Company"
              buttonColor="primary"
              buttonType="submit"
              className="big-width"
              buttonClick={handleSubmit(_updateCompany)}
              loading={waiting}
              disabled={waiting}
            />
          </div>
        )}
      </div>

      <AddNotesModal
        toggleActivityNotes={toggleActivityNotes}
        activityNotes={activityNotes}
        noteableId={companyId}
        reFetch={() => _getNotes()}
        type="Company"
        editData={noteData}
        resetField={setNoteData}
      />
      <ConfirmPopUp
        confirmText="Are you sure you want to delete this domain?"
        isOpen={confirmToggle}
        toggle={setConfirmToggle}
        confirmAction={deleteDomainInDb}
        modalHeading="Remove Domain"
        btnText="Delete"
        btnColor="primary"
      />
    </div>
  );
};

export default UpdateCompany;