import { useLazyQuery } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { useContext, useEffect } from 'react';
import { AppContext } from '../../../context';
import { FETCH_CURRENT_USER, OUTLOOK_LOGIN } from '../../settings/gql';
import { outlookLink } from '../../../utils/Temp-Helper';
import { SettingsFormPayload } from '../../../interfaces';

export default function OutlookLogin({ jobOrderId }) {
  const history = useHistory();

  const { setContextUser } = useContext(AppContext);
  const { reset } = useForm<SettingsFormPayload>();

  const [currentUserDetail, { data }] = useLazyQuery(FETCH_CURRENT_USER);

  const [outLookLogin, { data: outLook }] = useLazyQuery(OUTLOOK_LOGIN);

  useEffect(() => {
    if (data?.me?.user) {
      setContextUser(data?.me?.user || null);
      reset({ ...data.me.user });
    }

    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    currentUserDetail();
    let code = new URLSearchParams(history?.location.search).get('code') || '';
    if (code) {
      outLookLogin({ variables: { code } });
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (outLook?.gettingOutLookLogin?.status === 200) {
      currentUserDetail();
      history.push(`/candidate-pipeline/view/${jobOrderId}`);
    }

    // eslint-disable-next-line
  }, [outLook]);

  return (
    <div>
      <p className="m-0">
        Before scheduling interview, and sending calender invites. Kindly
        configure your <strong>Outlook</strong> account, {outlookLink}
      </p>
    </div>
  );
}
