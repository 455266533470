import { gql } from '@apollo/client';

export const GET_JOB_LIST_NOTES = gql`
query GetAllJobListNotes($input: GetAllJobListNotesInput!) {
  getAllJobListNotes(getAllJobListNotesInput: $input) {
    jobListNotes {
      id
    	salaryNote
      pipelineNote
      urgencyNote
      jobOrderId
      createdAt
    }
    count
  }
}
`

export const CREATE_JOB_LIST_NOTES = gql`
mutation CreateJobListNotes($input: CreateJobOrderListNotesInput!) {
  createJobListNotes(createJobListNotesInput: $input) {
    message
    status
    jobListNotes {
      id
      salaryNote
      urgencyNote
      pipelineNote
			createdAt
			updatedAt
    }
  }
}`;

export const CREATE_OR_UPDATE_JOB_LIST_NOTES = gql`
mutation CreateOrUpdateJobListNotes($createOrUpdateJobListNotes: CreateOrUpdateJobListNotes!) {
  createOrUpdateJobListNotes(createOrUpdateJobListNotes: $createOrUpdateJobListNotes) {
    message
    status
    jobListNotes {
      id
      pipelineNote
      salaryNote
      urgencyNote
      createdAt 
			updatedAt
    }
  }
}
`;


export const DELETE_JOB_LIST_NOTES = gql`
mutation DeleteJobListNotes($input: RemoveJobListNotesInput!) {
  deleteJobListNotes(removeHotJobOrdersInput: $input) {
    message
    status
  }
}
`;