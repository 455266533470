import React from "react";
import { Card, CardBody } from "reactstrap";
import { createMarkup } from "../../../../utils/commonFn";

export const RejectionAlert = ({ statusNote, rejectedBy }) => {
  return (
    <Card
      style={{ borderColor: "#fbb4b4", background: "#fff8f8" }}
      className="my-3 dark-card"
    >
      <CardBody>
        <div className="d-flex mb-1">
          <small className="font-weight-bold mr-1">Rejected By : </small>
          <small>{rejectedBy?.fullName}</small>
        </div>
        <div className="d-flex">
          <small className="font-weight-bold d-flex flex-wrap mr-1">
            Reason for Rejection :
          </small>
          <small dangerouslySetInnerHTML={createMarkup(statusNote)} />
        </div>
      </CardBody>
    </Card>
  );
};
