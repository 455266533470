import { gql } from "@apollo/client";

export const GET_MERGE_COMPANIES = gql`
  query GetMergeCompanies(
    $page: Int!
    $limit: Int!
    $searchText: String
    $statusSearch: String
    $companyId: String
  ) {
    getMargeCompanies(
      payload: { page: $page, limit: $limit }
      filters: {
        searchText: $searchText
        statusSearch: $statusSearch
        companyId: $companyId
      }
    ) {
      mergeCompanies {
        id
        mergedAt
        processedAt
        mergeNotes
        sourceCompanyName
        destinationCompanyName
        status
        createdAt
        sourceCompanyId
        destinationCompanyId
      }
      count
    }
  }
`;

export const GET_PLACEMENTS_BY_COMPANY_ID = gql`
  query getPlacementsByCompanyId($companyId: String!) {
    getPlacementsByCompanyId(companyid: $companyId) {
        id
    }
  }
`;

export const CREATE_MERGE_COMPANIES = gql`
  mutation CreateMergeCompanies(
    $sourceCompanyId: String!
    $destinationCompanyId: String!
    $mergeNotes: String
  ) {
    createMergeCompany(
      mergeCompanyInput: {
        mergeNotes: $mergeNotes
        sourceCompanyId: $sourceCompanyId
        destinationCompanyId: $destinationCompanyId
      }
    ) {
      message
      status
    }
  }
`;

export const DELETE_MERGE_COMPANY = gql`
  mutation DeleteMergeCompany($mergeCompanyId: String!) {
    deleteMergeCompany(mergeCompanyId: $mergeCompanyId) {
      message
      status
    }
  }
`;

export const MERGE_COMPANY_STATUS_CHANGE = gql`
  mutation MergeCompanyStatusChange($mergeCompanyId: String!) {
    mergeCompanyStatusChange(mergeCompanyId: $mergeCompanyId) {
      message
      status
    }
  }
`;
