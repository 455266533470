const CandidateWidget = ({ stats }) => {
  return (
    <div className="candidateJobInfo">
      <h5>Candidate Job Info</h5>
      <div className="d-flex justify-content-between mb-2 contentGridLabel">
        <label>Job Applications</label>

        <div className="contentGrid">
          <h6>{stats?.jobAppCount || "0"}</h6>
        </div>
      </div>

      <div className="d-flex justify-content-between mb-2 contentGridLabel">
        <label>Offers</label>

        <div className="contentGrid">
          <h6>{stats?.totalBilling || "0"}</h6>
        </div>
      </div>

      <div className="d-flex justify-content-between mb-2 contentGridLabel">
        <label>Submissions</label>

        <div className="contentGrid">
          <h6>{stats?.submissionCount || "0"}</h6>
        </div>
      </div>

      <div className="d-flex justify-content-between mb-2 contentGridLabel">
        <label>Last checked in recruiter</label>

        <div className="contentGrid">
          <h6>{stats?.lastCheckedInRecruiter?.fullName || "0"}</h6>
        </div>
      </div>

      <div className="d-flex justify-content-between mb-2 contentGridLabel">
        <label>Interviews</label>

        <div className="contentGrid">
          <h6>{stats?.interviewCount || "0"}</h6>
        </div>
      </div>

      <div className="d-flex justify-content-between contentGridLabel">
        <label>Latest checked in time</label>

        <div className="contentGrid">
          <h6>
            {stats?.updatedAt
              ? new Date(stats.updatedAt).toLocaleTimeString()
              : "--"}
          </h6>
        </div>
      </div>
    </div>
  );
};

export default CandidateWidget;
