import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { withRouter } from "react-router-dom";
import { Alert } from "reactstrap";

import CustomButton from "../../components/layout/CustomButton";
import InputField from "../../components/layout/InputField";
import AuthLayout from "../../components/layout/AuthLayout";

import { RESET_PASSWORD } from "./gql";

type ResetType = {
  password: string;
  confirmPassword: string;
};
//todo define default pops type
const ResetPassword = ({ match, history }: any): JSX.Element => {
  const { register, handleSubmit, errors, watch } = useForm<ResetType>();
  const [resetPassword, { error, data }] = useMutation(RESET_PASSWORD);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [userToken, setUserToken] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    let userKey = match.params.token;
    setUserToken(userKey);
    // ;
  }, [match.params.token]);

  useEffect(() => {
    if (data?.updatePassword?.status === 200) {
      setMessage(data.updatePassword.message);
      history.push("/reset-password-success");
    }
  }, [data, history]);

  const formSubmit = (values: ResetType) => {
    // ;
    resetPassword({
      variables: { password: values.password, userKey: userToken },
    });
  };

  return (
    <AuthLayout title="Choose A New Password" subTitle="" isLoginPages>
      <div className="form-height-reset">
        <form onSubmit={handleSubmit(formSubmit)}>
          {message && <div className="error">{message}</div>}

          <InputField
            label="New Password"
            inputtype="password"
            placeholder="Enter New Password"
            inputid="password"
            inputRef={register({
              required: { value: true, message: "Password is required!" },
              minLength: { value: 6, message: "Password length must be 6 !" },
            })}
            isPassword
            errMsg={errors?.password?.message}
          />

          <InputField
            label="Confirm Password"
            inputtype="password"
            placeholder="Confirm Password"
            inputid="confirmPassword"
            isPassword
            inputRef={register({
              validate: (value) =>
                value === watch("password") || "Passwords don't match.",
            })}
            errMsg={errors?.confirmPassword?.message}
          />

          {error && (
            <Alert color="danger" className="form-alert m-0">
              {error?.graphQLErrors[0].message}
            </Alert>
          )}

          <div className="form-footer">
            <CustomButton buttonText="Confirm" />
          </div>
        </form>
      </div>
    </AuthLayout>
  );
};

export default withRouter(ResetPassword);
