import { yupResolver } from '@hookform/resolvers/yup';
import { useContext } from 'react';
import {
  Controller,
  FormProvider, useForm
} from 'react-hook-form';
import Select from 'react-select';
import { toast } from 'react-toastify';
import {
  Col,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap';
import * as Yup from 'yup';
import CustomButton from '../../components/layout/CustomButton';
import { customSelectStyleNew } from '../../components/styled/customSelect';
import { AppContext } from '../../context';
import { customStylesReportEditDropdown, reportCategory, URL_REGEX } from '../../utils/constant';

const validationSchema = Yup.object({
  name: Yup.string().required('Report Name is required!'),
  link: Yup.string().required('Report Link is required!').matches(URL_REGEX, "Invalid URL"),
});

const CreateReportModal = ({
  toggle,
  isOpen,
  createReport,
  refetchData,
}) => {
  const { theme } = useContext(AppContext);
  const methods = useForm({
    defaultValues: {  name: '', link: '' , category: reportCategory[0] },
    resolver: yupResolver(validationSchema),
  });
  const { control, reset, handleSubmit, setValue, formState: { errors } } = methods;

  const createReportHandler = async (data) => {
    const { category, ...result } = data;
    const finalInput = {
      ...result,
      category: category?.value,
    }
    let response = await createReport({
      variables: { input: {...finalInput} },
    });
    if (response?.data) {
      toggle();
      reset();
      refetchData();
      toast.success('Report Saved Successfully!');
    } else {
      toggle();
      reset();
      toast.error('Report could not be Saved!');
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className="revampDialog revampDialogWidth"
      centered
      >
      <ModalHeader className="py-3">
        <h4>Add New Report</h4>
      </ModalHeader>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(createReportHandler)}>
          <ModalBody className="pt-2 pb-2">
            <Row className='mb-3'>
            <Col>
                <Controller
                  name='name'
                  control={control}
                  render={(
                    { onChange, ref, name, value },
                    { invalid }
                  ) => (
                    <div>
                      <Label>
                        Report Name
                        <span className="text-danger"> *</span>
                      </Label>

                      <input
                        name={name}
                        value={value}
                        ref={ref}
                        placeholder="Add Report Name..."
                        onChange={(e) => {
                          onChange(e);
                        }}
                        className="form-control"
                      />
                      {invalid && (<div className="error">{errors?.name?.message}</div>)}
                    </div>
                  )}
                />
              </Col>

              <Col>
                <Controller
                  name='link'
                  control={control}
                  render={(
                    { onChange, ref, value, name },
                    { invalid }
                  ) => (
                    <div>
                      <Label>
                        Report Link
                        <span className="text-danger"> *</span>
                      </Label>
                      <input
                        name={name}
                        value={value}
                        ref={ref}
                        placeholder="Add Report Link..."
                        onChange={(e) => {
                          onChange(e);
                        }}
                        className="form-control"
                      />
                      {invalid && (<div className="error">{errors?.link?.message}{' '}</div>)}
                    </div>
                  )}
                />
              </Col> 
            </Row>

            <Row>
              <Col>
                <Controller
                  name='category'
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: 'Must Select Category!',
                    },
                  }}
                  placeHolder="Select report category"
                  render={({value}) => (
                    <>
                      <Label>
                        Report Category
                        <span className="text-danger"> *</span>
                      </Label>
                      <Select
                        value={value}
                        options={reportCategory}
                        onChange={(object) => {
                          setValue('category', object)
                        }}
                        styles={{ ...customSelectStyleNew(theme) }}
                        />
                    </>
                  )}
                  styles={customStylesReportEditDropdown}
                  />
              </Col>
            </Row>

          </ModalBody>
          <ModalFooter className='p-0'>
            <div className="d-flex justify-content-end align-items-center w-100">
                <button
                  className="btn btn-primary buttonPrimary m-2"
                  onClick={() => {
                    reset();
                    toggle();
                  }}
                  type="button"
                >
                  Cancel
                </button>
                <CustomButton
                  buttonText="Save"
                  buttonColor="default"
                  buttonType="submit"
                  className="btn-primary py-2 m-3"
                />
            </div>
          </ModalFooter>
        </form>
      </FormProvider>
    </Modal>
  );
};

export default CreateReportModal;