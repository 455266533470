import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { useQuery } from '@apollo/client';
import StatsCard from '../../components/layout/StatsCard';
import { FETCH_USER_STATS } from './gql';
import { moneyFormatter } from '../../utils/helper';

const StatsWidget = ({ history }: RouteComponentProps) => {
  const [stats, setStats] = useState<any>({});
  const [startDate, setStartDate] = useState<any>(`${new Date().getFullYear()}-01-01`);
  const [endDate, setEndDate] = useState<any>(`${new Date().getFullYear()}-12-31`);
  const { data, loading } = useQuery(FETCH_USER_STATS, {
    variables: {
      statsDateFilter: {
        startDate,
        endDate,
      },
    },
  });

  useEffect(() => {
    if (data) {
      const updatedStats = {
        totalNotes: data?.fetchUserStats?.totalNotes ? data?.fetchUserStats?.totalNotes : 0,
        uniqueNotes: data?.fetchUserStats?.uniqueNotes ? data?.fetchUserStats?.uniqueNotes : 0,
        totalSubmissions: data?.fetchUserStats?.totalSubmissions ? data?.fetchUserStats?.totalSubmissions : 0,
        completedSubmissions: data?.fetchUserStats?.completedSubmissions ? data?.fetchUserStats?.completedSubmissions : 0,
        totalInterviews: data?.fetchUserStats?.totalInterviews ? data?.fetchUserStats?.totalInterviews : 0,
        initialInterviews: data?.fetchUserStats?.initialInterviews ? data?.fetchUserStats?.initialInterviews : 0,
        offers: data?.fetchUserStats?.totalOffers ? data?.fetchUserStats?.totalOffers : 0,
        billingCredit: data?.fetchUserStats?.totalBilling ? data?.fetchUserStats?.totalBilling?.toFixed(2) : 0,
        totalSignedAgreements: data?.fetchUserStats?.totalSignedAgreements ? data?.fetchUserStats?.totalSignedAgreements : 0,
        lookups: data?.fetchUserStats?.lookups ? data?.fetchUserStats?.lookups : 0,
        contacts: data?.fetchUserStats?.contacts ? data?.fetchUserStats?.contacts : 0,
        messages: data?.fetchUserStats?.messages ? data?.fetchUserStats?.messages : 0,
      };
      setStats(updatedStats);
    }
  }, [data]);

  const STATS_DATA = [
    {
      number: stats?.lookups,
      title: 'Total Lookups',
    },
    {
      number: stats?.totalNotes,
      title: 'Total Notes',
    },
    {
      number: stats?.uniqueNotes,
      title: 'Unique Notes',
    },

    {
      number: stats?.totalSubmissions,
      title: 'Total Submissions',
    },
    {
      number: stats?.contacts,
      title: 'Total Contacts',
    },

    {
      number: stats?.completedSubmissions,
      title: 'Completed Submissions',
    },
    {
      number: stats?.totalInterviews,
      title: 'Total Interviews',
    },
    {
      number: stats?.initialInterviews,
      title: 'Initial Interviews',
    },
    {
      number: stats?.messages,
      title: 'Total Messages',
    },
    {
      number: stats?.offers,
      title: 'Offers',
    },
    {
      number: moneyFormatter(stats?.billingCredit),
      title: 'DH Billing Credit',
    },
    {
      number: stats?.totalSignedAgreements,
      title: 'Signed Agreements',
    },
  ];

  return (
    <div className="jobOrderRevamp">
      <div className="d-flex justify-content-between h-100 align-items-center">
        <h5>My Recruiter Stats</h5>
        <button
          onClick={() => {
            history.push(`/admin/stats/widget`);
          }}
          type="button"
          className="buttonGenericStyle filledButton"
        >
          View Details
        </button>
      </div>

      <div>
        <Row>
          <Col lg={3} md={6} sm={12}>
            <FormGroup className="mb-1">
              <Label>Start Date</Label>
              <Input
                defaultValue={startDate}
                type="date"
                name="statsStartDate"
                onChange={(e) => setStartDate(e.target.value)}
                max={new Date().toISOString().substring(0, 10)}
              />
            </FormGroup>
          </Col>

          <Col lg={3} md={6} sm={12}>
            <FormGroup className="mb-1">
              <Label>End Date</Label>
              <Input
                defaultValue={endDate}
                type="date"
                name="statsEndDate"
                max={new Date().toISOString().substring(0, 10)}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row className='mt-3'>
          {STATS_DATA?.map((item, index) => (
            <Col lg={3} md={6} sm={12}>
              <React.Fragment key={index}>
                <StatsCard
                  number={item.number}
                  title={item.title}
                  loading={loading}
                />
              </React.Fragment>
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
};

export default withRouter(StatsWidget);
