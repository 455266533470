import { FC, useState } from "react";
import { Collapse } from "reactstrap";

import Down from "../../../assets/images/collapse.svg";
import Up from "../../../assets/images/up.svg";

import "./style.scss";

type COLLAPSE = {
  title?: string;
  children: JSX.Element;
};

const CollapseComponent: FC<COLLAPSE> = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(true);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <div className="collapse-wrapper">
      <div onClick={toggle} className="collapse-header">
        <h5>{title}</h5>
        <img src={isOpen ? Down : Up} alt="" />
      </div>
      <Collapse isOpen={isOpen}>{children}</Collapse>
    </div>
  );
};

export default CollapseComponent;
