import { gql } from "@apollo/client";

export const GET_SINGLE_ACTIVITY_REPORT = gql`
query($month:String!,$year:String!){
  getSingleReport(month:$month,year:$year){
    message
    status
    userActivityReport{
      id
      month
      year
      documentLink
      documentName
      userId
    }
  }
}`

export const PRE_LOAD_MONTHS_AND_YEAR = gql`
query {
  getPreLoadedMonthAndYear {
    message
    status
    monthAndYear {
      month
      year
    }
  }
}
`