import { Button, Col, Modal, ModalBody, Row } from "reactstrap";
import CLOSE_ICON from "../../../assets/images/close-icon.svg";
import { formatDateUS } from "../../../utils/commonFn";
import LogoReference from '../../../assets/images/referencepdfLogo.jpg'

const ViewReferenceModal = ({ isOpen, toggle, modalHeader, referenceData }) => {
  const { firstName, lastName } = referenceData?.candidate || '';
  const { relationshipToCandidate, mainDuty, partOfInterviewProcesstoHire,
    candidateStrength, improvement, softwareUsed, personality, eligibleForRehire,
    additionalComments, completedAt, referenceName } = referenceData || ''

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      scrollable={true}
      size="xl"
      className="add-reference-dialog modal-dialog reference-modal-dialog">

      <ModalBody className="py-4 modalBodyScroll">
        <div className="reference-modal">
          <div className="modalHeaderCustom d-flex  justify-content-end">
            <Button
              className="p-0 bg-transparent border-0"
              onClick={() => toggle()}
            >
              <img src={CLOSE_ICON} alt="close" />
            </Button>
          </div>

          <div className="d-flex  justify-content-end">
            <div style={{ maxWidth: '150px' }}>
              <img className="w-100" src={LogoReference} alt="LogoReference" />
            </div>
          </div>

          <div>
            <div className="py-4 px-4 modal__header">
              <h4 className="text-center pb-1 font-weight-bold">
                <span>Professional Reference:</span>
              </h4>

              <div className="pb-3 modal__body">
                <div className="top-section pb-5 mb-2">

                  <Row>
                    <Col md={5} lg={4} className="pl-md-5">
                      <h5 className="text-left pl-md-4 ml-md-4">Candidate:</h5>
                    </Col>
                    <Col md={7} lg={8}>
                      <p>{firstName} {lastName}</p>
                    </Col>
                  </Row>
                  {
                    !!referenceName && referenceName.trim() &&
                    <Row>
                      <Col md={5} lg={4} className="pl-md-5">
                        <h5 className="text-left pl-md-4 ml-md-4">Reference:</h5>
                      </Col>
                      <Col md={7} lg={8}>
                        <p>{referenceName}</p>
                      </Col>
                    </Row>
                  }

                  {
                    !!completedAt && completedAt.trim() &&
                    <Row>
                      <Col md={5} lg={4} className="pl-md-5">
                        <h5 className="text-left pl-md-4 ml-md-4">Date Completed:</h5>
                      </Col>
                      <Col md={7} lg={8}>
                        <p>{formatDateUS(completedAt)}</p>
                      </Col>
                    </Row>
                  }
                </div>

                <div className="bottom-section">
                  {
                    !!relationshipToCandidate && relationshipToCandidate.trim() &&
                    <Row>
                      <Col md={5} lg={4}>
                        <p>Relationship to the candidate:</p>
                      </Col>

                      <Col md={7} lg={8}>
                        <p >{relationshipToCandidate}</p>
                      </Col>
                    </Row>
                  }

                  {
                    !!partOfInterviewProcesstoHire && partOfInterviewProcesstoHire.trim() &&
                    <Row>
                      <Col md={5} lg={4}>
                        <p>Part of interview the process to hire:</p>
                      </Col>
                      <Col md={7} lg={8}>
                        <p >{partOfInterviewProcesstoHire}</p>
                      </Col>
                    </Row>
                  }

                  {
                    mainDuty &&
                    <Row>
                      <Col md={5} lg={4}>
                        <p>Main job duties/responsibilities:</p>
                      </Col>
                      <Col md={7} lg={8}>
                        <p >{mainDuty}</p>
                      </Col>
                    </Row>

                  }

                  {
                    !!candidateStrength && candidateStrength.trim() &&
                    <Row>
                      <Col md={5} lg={4}>
                        <p>Candidate’s Strengths:</p>
                      </Col>
                      <Col md={7} lg={8}>
                        <p >{candidateStrength}</p>
                      </Col>
                    </Row>
                  }

                  {
                    !!improvement && improvement.trim() &&
                    <Row>
                      <Col md={5} lg={4}>
                        <p>Improvement:</p>
                      </Col>
                      <Col md={7} lg={8}>
                        <p >{improvement}</p>
                      </Col>
                    </Row>
                  }

                  {
                    !!softwareUsed && softwareUsed.trim() &&
                    <Row>
                      <Col md={5} lg={4}>
                        <p>Computer Programs/Software:</p>
                      </Col>
                      <Col md={7} lg={8}>
                        <p >{softwareUsed}</p>
                      </Col>
                    </Row>
                  }

                  {
                    !!personality && personality.trim() && <Row>
                      <Col md={5} lg={4}>
                        <p>Personality:</p>
                      </Col>
                      <Col md={7} lg={8}>
                        <p >{personality}</p>
                      </Col>
                    </Row>
                  }

                  {
                    !!eligibleForRehire && eligibleForRehire.trim() &&
                    <Row>
                      <Col md={5} lg={4}>
                        <p>Eligible for rehire:</p>
                      </Col>
                      <Col md={7} lg={8}>
                        <p> {eligibleForRehire}</p>
                      </Col>
                    </Row>
                  }


                  {
                    !!additionalComments && additionalComments.trim() &&
                    <Row>
                      <Col md={5} lg={4}>
                        <p>Additional Comments:</p>
                      </Col>
                      <Col md={7} lg={8}>
                        <p> {additionalComments}</p>
                      </Col>
                    </Row>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ViewReferenceModal;
