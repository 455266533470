import { useLazyQuery, useMutation } from "@apollo/client";
import { useState, useEffect } from "react";
import Dropzone, { FileRejection } from "react-dropzone";
import { Button, Col, Label, Row } from "reactstrap";
import scvFileIcon from "../../../../../assets/images/files-csv-index.svg";
import deleteIcon from "../../../../../assets/images/delete-field.svg";
import {
  INTERVIEW_EMAIL_ATTACHMENTS,
  INTERVIEW_EMAIL_ATTACHMENT_REMOVE,
} from "../../gql";
import DownloadImg from "../../../../../assets/images/download.svg";

import {
  GET_CANDIDATE_SUMMARY_WRITEUP,
  GET_LATEST_CANDIDATE_RESUME,
} from "../../../../candidate/gql";
import {
  downloadSignedUrlFile,
  handleHtmlToPdfDownload,
} from "../../../../../utils/commonFn";
import {
  staticFilesForInterviewInitialState,
} from "../../interview-interfaces";
import ConfirmPopUp from "../../../../../components/ConfirmPopUp";

const MAX_FILE_SIZE = 5242880;

export interface ICreateInterviewSelect {
  label: string;
  value: string;
}

export interface InviteType extends ICreateInterviewSelect {}

export default function ClientCalendarInviteDetailedInfo({
  clientInterviewEmailData,
  setPipeSelectedFiles,
  fromEdit,
  candidateName,
  candidateId,
  onAdditionalFileAttachmentsHandler,
  additionalFileAttached,
  setAdditionalFileAttachments,
}) {
  const [resumeDownload, setResumeLoading] = useState<boolean>(false);
  const [writeupLoading, setWriteupLoading] = useState<boolean>(false);
  const [isFileTooLarge, setIsFileTooLarge] = useState<boolean>(false);
  const [fileDeleteModal, setFileDeleteModal] = useState<boolean>(false);
  const [previousFilesList, setPreviousFilesList] = useState<boolean>(false);
  const [newFilesList, setNewFilesList] = useState<boolean>(false);
  const [fileId, setFileId] = useState<string>("");
  const [fileAlreadyExists, setFileAlreadyExists] = useState<string>("");
  const [files, setFiles] = useState<any>([]);

  const [_getResume, { data: _getResumeData }] = useLazyQuery(
    GET_LATEST_CANDIDATE_RESUME
  );
  const [_getSummaryWriteup, { data: summaryWriteup }] = useLazyQuery(
    GET_CANDIDATE_SUMMARY_WRITEUP
  );

  const [fetchInterviewEmailAttachments, { data }] = useLazyQuery(
    INTERVIEW_EMAIL_ATTACHMENTS
  );

  const [interviewEmailAttachmentRemove] = useMutation(
    INTERVIEW_EMAIL_ATTACHMENT_REMOVE
  );

  useEffect(() => {
    _getResume({ variables: { candidateId } });
    _getSummaryWriteup({ variables: { candidateId } });

    if (fromEdit) {
      if (clientInterviewEmailData?.id) {
        fetchInterviewEmailAttachments({
          variables: { interviewEmailId: clientInterviewEmailData?.id },
        });
      }
    }
    if (!fromEdit) {
      setAdditionalFileAttachments(staticFilesForInterviewInitialState);
    }

    // eslint-disable-next-line
  }, []);

  const handleDrop = (
    acceptedFiles: File[],
    rejectedFiles: FileRejection[]
  ) => {
    const fileAlreadyExists = files?.find(
      (item: any) => item?.name === acceptedFiles[0]?.name
    );
    if (fileAlreadyExists) {
      setFileAlreadyExists(
        `File with name ${acceptedFiles[0]?.name} already exists`
      );
    } else {
      setFileAlreadyExists("");
      if (
        rejectedFiles?.length &&
        rejectedFiles[0]?.file?.size > MAX_FILE_SIZE
      ) {
        setIsFileTooLarge(true);
      } else {
        setFiles((files: any) => files.concat(...acceptedFiles));
      }
    }
  };

  const onDeletePreviousSelectedFile = async () => {
    if (fileId) {
      const removedFile = await interviewEmailAttachmentRemove({
        variables: {
          id: fileId,
        },
      });

      if (removedFile?.data) {
        fetchInterviewEmailAttachments({
          variables: { interviewEmailId: clientInterviewEmailData?.id },
        });
        fileDeleteToggle();
      }
    }
  };

  const onDeleteSelectedFile = (fileName: string) => {
    const currentIndex = files?.indexOf(
      files?.find((item: any) => item.name === fileName)
    );
    const newChecked = [...files];
    newChecked.splice(currentIndex, 1);
    setFiles(newChecked);
    setPipeSelectedFiles(newChecked);
  };

  useEffect(() => {
    if (files?.length) {
      setPipeSelectedFiles(files);
    }

    // eslint-disable-next-line
  }, [files]);

  const onDownloadCandidateResume = async () => {
    setResumeLoading(true);
    await downloadSignedUrlFile(
      _getResumeData?.getLatestCandidateResume?.originalUrl,
      _getResumeData?.getLatestCandidateResume?.resumeFileName
    );
    setResumeLoading(false);
    // }
  };

  const onDownloadCandidateWriteup = () => {
    setWriteupLoading(true);
    handleHtmlToPdfDownload(
      summaryWriteup?.candidateSummaryWriteUp?.writeupSummary,
      `${candidateName}'s-writeup`
    );
    setWriteupLoading(false);
  };

  const onPreviousFileRemove = (id: string) => {
    setFileId(id);
    fileDeleteToggle();
  };

  const fileDeleteToggle = () => {
    setFileDeleteModal(!fileDeleteModal);
  };

  const onPreviousFilesToggle = () => {
    setPreviousFilesList(!previousFilesList);
  };

  const onNewFilesToggle = () => {
    setNewFilesList(!newFilesList);
  };

  return (
    <div>
      <Row>
        <Col
          lg="12"
          className="mt-3 d-flex justify-content-start align-items-center mb-2"
        >
          <Label className="m-0">
            Candidate Submitted Resume:{" "}
            <span className="text-muted">{`${
              candidateName?.length > 16
                ? candidateName?.substring(0, 16)
                : candidateName
            }-Resume`}</span>
          </Label>

          <Button
            size="sm"
            className={"agreement-download bg-transparent p-0 ml-2 d-flex"}
            onClick={onDownloadCandidateResume}
          >
            <img
              src={DownloadImg}
              alt="download"
              className={`${resumeDownload && "blink_me"} mr-2 mb-1`}
            />
            {resumeDownload ? "Downloading" : "Download"}
          </Button>
        </Col>

        <Col
          lg="12"
          className="mt-0 d-flex justify-content-start align-items-center"
        >
          <Label className="m-0">
            Candidate Writeup:{" "}
            <span className="text-muted">{`${
              candidateName?.length > 16
                ? candidateName?.substring(0, 16)
                : candidateName
            }-Writeup.pdf`}</span>
          </Label>
          <Button
            size="sm"
            className="agreement-download bg-transparent ml-2 p-0"
            onClick={onDownloadCandidateWriteup}
          >
            <img
              src={DownloadImg}
              alt="download"
              className={`${writeupLoading && "blink_me"} mr-2 mb-1`}
            />
            {writeupLoading ? "Downloading" : "Download"}
          </Button>
        </Col>

        <Col lg="12" className="mt-3">
          <Label>Attachments</Label>
          <div className="mb-3">
            <Dropzone onDrop={handleDrop} maxSize={MAX_FILE_SIZE}>
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps({ className: "dropzone" })}>
                  <input {...getInputProps()} />

                  <div className="file-upload-csv-index text-center pt-3">
                    <img
                      src={scvFileIcon}
                      alt="csv-file-upload-icon"
                      className="my-5"
                    />
                    <p>Drag or upload your file here</p>

                    {isFileTooLarge && (
                      <div className="text-danger mt-2">File is too large.</div>
                    )}

                    {fileAlreadyExists && (
                      <div className="text-danger mt-2">
                        {fileAlreadyExists}
                      </div>
                    )}
                  </div>
                </div>
              )}
            </Dropzone>
          </div>
        </Col>

        <Col lg="12" className="mt-3">
          {Array.isArray(data?.interviewEmailAttachments) &&
          data?.interviewEmailAttachments?.length ? (
            <h6 className="mb-2">Previous Files</h6>
          ) : null}
          {Array.isArray(data?.interviewEmailAttachments) &&
          data?.interviewEmailAttachments?.length <= 3 ? (
            <div className="d-flex flex-wrap">
              {data?.interviewEmailAttachments?.map((item: any) => {
                return (
                  <div className="d-flex align-items-center mb-2 mr-3">
                    <p className="file-name-csv-index m-0 text-truncate">
                      {item?.fileName?.match(/(\w+)/g)?.length > 3
                        ? `${item?.fileName?.slice(0, 20)}...`
                        : item?.fileName}
                    </p>
                    <Button
                      onClick={() => onPreviousFileRemove(item?.id)}
                      className="color-red bg-transparent border-0 ml-3 p-0"
                    >
                      <img
                        style={{
                          marginRight: 0,
                        }}
                        src={deleteIcon}
                        alt=""
                      />
                    </Button>
                  </div>
                );
              })}
            </div>
          ) : (
            <>
              <div className="d-flex flex-wrap">
                {data?.interviewEmailAttachments
                  ?.slice(0, 3)
                  .map((item: any) => {
                    return (
                      <div className="d-flex align-items-center mb-2 mr-3">
                        <p className="file-name-csv-index m-0 text-truncate">
                          {item?.fileName?.match(/(\w+)/g)?.length > 3
                            ? `${item?.fileName?.slice(0, 20)}...`
                            : item?.fileName}
                        </p>
                        <Button
                          onClick={() => onPreviousFileRemove(item?.id)}
                          className="color-red bg-transparent border-0 ml-3 p-0"
                          style={{ lineHeight: "0px" }}
                        >
                          <img
                            style={{
                              marginRight: 0,
                            }}
                            src={deleteIcon}
                            alt=""
                          />
                        </Button>
                      </div>
                    );
                  })}

                {Array.isArray(data?.interviewEmailAttachments) &&
                data?.interviewEmailAttachments?.length ? (
                  <p className="file-name-csv-index m-0 text-truncate">
                    {
                      data?.interviewEmailAttachments?.slice(
                        3,
                        data?.interviewEmailAttachments?.length
                      ).length
                    }{" "}
                    more selected files...
                    {!previousFilesList ? (
                      <span
                        className="client-calendar-showmore"
                        onClick={onPreviousFilesToggle}
                      >
                        Show more
                      </span>
                    ) : (
                      <span
                        className="client-calendar-showmore"
                        onClick={onPreviousFilesToggle}
                      >
                        Hide more files
                      </span>
                    )}
                  </p>
                ) : null}
              </div>
            </>
          )}

          <div className="d-flex flex-wrap">
            {previousFilesList && (
              <>
                {data?.interviewEmailAttachments?.slice(3).map((item: any) => {
                  return (
                    <div className="d-flex align-items-center mb-2 mr-3">
                      <p className="file-name-csv-index m-0 text-truncate">
                        {item?.fileName?.match(/(\w+)/g)?.length > 3
                          ? `${item?.fileName?.slice(0, 20)}...`
                          : item?.fileName}
                      </p>
                      <Button
                        onClick={() => onPreviousFileRemove(item?.id)}
                        className="color-red bg-transparent border-0 ml-3 p-0"
                        style={{ lineHeight: "0px" }}
                      >
                        <img
                          style={{
                            marginRight: 0,
                          }}
                          src={deleteIcon}
                          alt=""
                        />
                      </Button>
                    </div>
                  );
                })}
              </>
            )}
          </div>
        </Col>

        <Col lg="12" className="mt-3">
          {Array.isArray(files) && files?.length ? (
            <h6 className="mb-2">New Files</h6>
          ) : null}
          {Array.isArray(files) && files?.length <= 3 ? (
            <div className="d-flex flex-wrap">
              {files?.map((item: any) => {
                return (
                  <div className="d-flex align-items-center mb-2 mr-3">
                    <p className="file-name-csv-index m-0 text-truncate">
                      {item?.name?.match(/(\w+)/g)?.length > 3
                        ? `${item?.name?.slice(0, 20)}...`
                        : item?.name}
                    </p>
                    <Button
                      onClick={() => onDeleteSelectedFile(item?.name)}
                      className="color-red bg-transparent border-0 ml-3 p-0"
                      style={{ lineHeight: "0px" }}
                    >
                      <img
                        style={{
                          marginRight: 0,
                        }}
                        src={deleteIcon}
                        alt=""
                      />
                    </Button>
                  </div>
                );
              })}
            </div>
          ) : (
            <>
              <div className="d-flex flex-wrap">
                {files?.slice(0, 3).map((item: any) => {
                  return (
                    <div className="d-flex align-items-center mb-2 mr-3">
                      <p className="file-name-csv-index m-0 text-truncate">
                        {item?.name?.match(/(\w+)/g)?.length > 3
                          ? `${item?.name?.slice(0, 20)}...`
                          : item?.name}
                      </p>
                      <Button
                        onClick={() => onDeleteSelectedFile(item?.name)}
                        className="color-red bg-transparent border-0 ml-3 p-0"
                      >
                        <img
                          style={{
                            marginRight: 0,
                          }}
                          src={deleteIcon}
                          alt=""
                        />
                      </Button>
                    </div>
                  );
                })}

                <p className="file-name-csv-index m-0 text-truncate">
                  {files?.slice(3, files?.length).length} more selected files...
                  {!newFilesList ? (
                    <span
                      className="client-calendar-showmore"
                      onClick={onNewFilesToggle}
                    >
                      Show more
                    </span>
                  ) : (
                    <span
                      className="client-calendar-showmore"
                      onClick={onNewFilesToggle}
                    >
                      Hide more files
                    </span>
                  )}
                </p>
              </div>
            </>
          )}

          <div className="d-flex flex-wrap">
            {newFilesList && (
              <>
                {files?.slice(3).map((item: any) => {
                  return (
                    <div className="d-flex align-items-center mb-2 mr-3">
                      <p className="file-name-csv-index m-0 text-truncate">
                        {item?.name?.match(/(\w+)/g)?.length > 3
                          ? `${item?.name?.slice(0, 20)}...`
                          : item?.name}
                      </p>
                      <Button
                        onClick={() => onDeleteSelectedFile(item?.name)}
                        className="color-red bg-transparent border-0 ml-3 p-0"
                        style={{ lineHeight: "0px" }}
                      >
                        <img
                          style={{
                            marginRight: 0,
                          }}
                          src={deleteIcon}
                          alt=""
                        />
                      </Button>
                    </div>
                  );
                })}
              </>
            )}
          </div>
        </Col>
      </Row>

      <ConfirmPopUp
        confirmText="Are you sure you want to delete this file?"
        isOpen={fileDeleteModal}
        toggle={fileDeleteToggle}
        confirmAction={() => {
          onDeletePreviousSelectedFile();
        }}
        modalHeading="Delete File"
        btnText="Delete"
        btnColor="primary"
        className="for-modal-styling"
      />
    </div>
  );
}
