import { useLazyQuery, useMutation } from '@apollo/client';
import moment from 'moment';
import { useCallback, useContext, useEffect, useState } from 'react';
import {
  Badge,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from 'reactstrap';
import {
  DrawerHeader,
  Sidedrawer,
} from '../../../../components/layout/Sidedrawer';
import { AppContext } from '../../../../context';
import { createMarkup } from '../../../../utils/commonFn';
import { interviewStatusType } from '../../../../utils/constant';
import {
  checkInterviewExpiration,
  getInterviewNumber,
} from '../../../../utils/helper';
import EditInterviewScheduleModal from '../../../candidate-pipeline/interviews/EditInterviewScheduleModal';
import { LOCK_INTERVIEW } from '../../../candidate-pipeline/interviews/gql';
import { ISelectedInterviewStatus } from '../../../candidate-pipeline/interviews/InterviewDetailCard';
import InterviewScheduleModal from '../../../candidate-pipeline/interviews/InterviewScheduleModal';
import RejectNotes from '../../../candidate-pipeline/interviews/RejectNotes';
import CreateEditOffer from '../../../candidate-pipeline/offers/CreateEditOffer';
import JobOrderDetailWidgetForDrawer from '../../candidate-components/JobOrderDetailWidgetForDrawer';
// import { GET_SINGLE_JOB_APPLICANT_ON_SUBMISSION } from "../../../job-order/gql";
import { GET_INTERVIEW_EMAILS } from '../../gql';

const InterviewViewDrawer = ({
  toggle,
  isOpen,
  applicantData,
  refetch,
  latestInterview,
  setStatusFilter,
}) => {
  const { user, userRoles } = useContext(AppContext);
  const [editInterviewToggle, setEditInterviewToggle] = useState(false);
  const [rejectNoteModal, setRejectNoteModal] = useState<boolean>(false);
  const [offerModal, setOfferModal] = useState<boolean>(false);
  const [selectedInterviewStatus, setSelectedInterviewStatus] =
    useState<ISelectedInterviewStatus>();
  const [getInterviewEmails] = useLazyQuery(GET_INTERVIEW_EMAILS);
  const [interviewScheduleModal, setInterviewScheduleModal] =
    useState<boolean>(false);
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [lockInterview] = useMutation(LOCK_INTERVIEW);

  const editPermissionCheck = () => {
    const companyOwnerShareIds =
      applicantData?.JobOrder?.company?.ownerAndSharees?.map(
        (item) => item?.owner?.id
      );
    const movedToOffers = applicantData.interviews?.find(
      (item: { interviewStatus: string }) =>
        item?.interviewStatus === 'MOVE_TO_OFFER'
    );
    if (
      (userRoles?.includes('ADMIN') || userRoles?.includes('EXECUTIVE')) &&
      !movedToOffers
    ) {
      return false;
    } else if (companyOwnerShareIds?.includes(user?.id) && !movedToOffers) {
      return false;
    } else {
      return true;
    }
  };

  const handleAction = (option: ISelectedInterviewStatus) => {
    setSelectedInterviewStatus(option);
    switch (option?.value) {
      case 'CLIENT_DECLINED':
      case 'CANDIDATE_DECLINED':
        setRejectNoteModal(!rejectNoteModal);
        break;
      case 'MOVE_TO_OFFER':
        setOfferModal(true);
        break;
      case 'Schedule_Next_Interview':
        setInterviewScheduleModal(true);
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    if (applicantData) {
      editPermissionCheck();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicantData]);

  const lockExpireInterview = useCallback(
    async (id: string) => {
      await lockInterview({
        variables: {
          lockInterviewDTO: { id },
        },
      });
    },
    [lockInterview]
  );

  const checkInterviews = useCallback(async () => {
    let isExpired = false;
    await Promise.all(
      applicantData?.interviews?.map((interview) => {
        if (!interview.locked) {
          isExpired = checkInterviewExpiration(
            interview?.interviewDateTime,
            interview?.timeZoneId
          );
        }

        if (isExpired && !interview.locked) {
          lockExpireInterview(interview.id);
        }

        if (!isExpired && !interview.locked) {
          setIsEditable(true);
        }

        return null;
      })
    );

    if (isExpired) {
      refetch();
    }
  }, [applicantData?.interviews, lockExpireInterview, refetch]);

  useEffect(() => {
    checkInterviews();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Sidedrawer toggle={toggle} isOpen={isOpen}>
        <div className="mb-3">
          <DrawerHeader
            prevClick={() => toggle()}
            nextClick={() => {
              setEditInterviewToggle(!editInterviewToggle);
            }}
            buttonText="Edit"
            heading="Interview Detail"
            disabled={editPermissionCheck() || !isEditable}
          >
            {!editPermissionCheck() && (
              <div className="actionsDropdown actionsDropdownSpacing dropdown">
                <UncontrolledDropdown className="customMenuDropdown">
                  <DropdownToggle caret style={{ minHeight: '40px' }}>
                    Actions
                  </DropdownToggle>

                  <DropdownMenu right>
                    {interviewStatusType?.map((item, index) => (
                      <DropdownItem
                        className="bg-transparent border-0 color-default btn-sm"
                        key={index}
                        onClick={() => handleAction(item)}
                      >
                        {item?.label}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            )}
          </DrawerHeader>
        </div>

        <JobOrderDetailWidgetForDrawer applicantData={applicantData} />
        {/* step start from here */}
        {applicantData?.interviews?.map((item, index) => {
          let isExpired = false;

          if (!item.locked) {
            isExpired = checkInterviewExpiration(
              item?.interviewDateTime,
              item?.timeZoneId
            );
          }

          return (
            <div className="pb-4" key={index}>
              <div className="mb-2">
                <div className="ml-0">
                  <strong>
                    {getInterviewNumber(item?.interviewRoundIndex)} Interview
                    {(item?.locked || isExpired) && <Badge>Locked</Badge>}
                  </strong>
                </div>
              </div>

              <Row className="mb-2">
                <Col xs={4}>
                  <h6 className="text-14 text-blackele">Interview State</h6>
                </Col>
                <Col xs={8}>
                  <h5>
                    <Badge>{item?.InterviewState}</Badge>
                  </h5>
                </Col>
              </Row>

              <Row className="mb-2">
                <Col xs={4}>
                  <h6 className="text-14 text-blackele">Interview Type</h6>
                </Col>
                <Col xs={8}>
                  <h5 className="font-weight-normal text-14 text-blackten">
                    {item?.interviewType || '--'}
                  </h5>
                </Col>
              </Row>

              <Row className="mb-2">
                <Col xs={4}>
                  <h6 className="text-14 text-blackele">Interview Date</h6>
                </Col>
                <Col xs={8}>
                  <h5 className="font-weight-normal text-14 text-blackten">
                    {moment(item?.interviewDateTime)
                      .utcOffset(item?.timeZoneId)
                      .format('MM/DD/YYYY')}
                  </h5>
                </Col>
              </Row>

              <Row className="mb-2">
                <Col xs={4}>
                  <h6 className="text-14 text-blackele">Interview Time</h6>
                </Col>
                <Col xs={8}>
                  <h5 className="font-weight-normal text-14 text-blackten">
                    {moment(item?.interviewDateTime)
                      .utcOffset(item?.timeZoneId)
                      .format('hh:mm A')}
                    , {item?.timeZone}
                  </h5>
                </Col>
              </Row>

              <Row className="mb-2">
                <Col xs={4}>
                  <h6 className="text-14 text-blackele">Location</h6>
                </Col>
                <Col xs={8}>
                  <h5 className="font-weight-normal text-14 text-blackten">
                    {item?.location || '--'}
                  </h5>
                </Col>
              </Row>

              <Row className="mb-2">
                <Col xs={4}>
                  <h6 className="text-14 text-blackele">Details</h6>
                </Col>
                <Col xs={8}>
                  <h5 style={{whiteSpace: 'break-spaces', color: '#424242'}}
                    className="font-weight-normal w-100"
                    dangerouslySetInnerHTML={createMarkup(item?.details)}
                  />
                </Col>
              </Row>
            </div>
          );
        })}
      </Sidedrawer>
      {editInterviewToggle && (
        <EditInterviewScheduleModal
          refetch={refetch}
          interviewId={latestInterview?.id}
          parentToggle={() => toggle()}
          applicantData={applicantData}
          editInterviewScheduleModal={editInterviewToggle}
          editInterviewScheduleModalToggle={() =>
            setEditInterviewToggle(!editInterviewToggle)
          }
          reFetchInterviewEmail={() => {
            getInterviewEmails({
              variables: { interviewId: latestInterview?.id },
            });
          }}
        />
      )}
      {offerModal && (
        <CreateEditOffer
          applicantData={applicantData}
          setCandidateFilter={() => {}}
          counterOffer={false}
          fromUpdate={false}
          latestOffer={null}
          interviewId={latestInterview?.id}
          setOfferToggle={() => setOfferModal(!offerModal)}
          offerToggle={offerModal}
          refetch={refetch}
          parentToggle={toggle}
        />
      )}

      {/* {activityNotes && (
        <AddNotesModal
          haveActionPersmissions={interviewPermissions}
          toggleActivityNotes={toggleActivityNotes}
          activityNotes={activityNotes}
          noteableId={applicantData?.id}
          reFetch={() => _getNotes()}
          type="Interview"
          editData={noteData}
          resetField={setNoteData}
        />
      )} */}

      <RejectNotes
        applicantData={applicantData}
        refetch={refetch}
        modal={rejectNoteModal}
        toggle={() => setRejectNoteModal(!rejectNoteModal)}
        selectedStatus={selectedInterviewStatus}
        interviewId={latestInterview?.id}
      />

      {applicantData && interviewScheduleModal && (
        <InterviewScheduleModal
          accountManagerAndCandidateRecruiterSame={
            applicantData?.JobOrder?.company?.companyOwner?.owner?.id ===
            applicantData?.candidate?.recruiterOwner?.id
          }
          setCandidateFilter={setStatusFilter}
          firstInterview={false}
          candidateId={applicantData?.candidate?.id}
          interviewScheduleModal={interviewScheduleModal}
          applicantData={applicantData}
          interviewScheduleModalToggle={() => {
            toggle();
            setInterviewScheduleModal(!interviewScheduleModal);
          }}
          refetch={refetch}
          closeModel={() => setInterviewScheduleModal(false)}
        />
      )}
    </>
  );
};

export default InterviewViewDrawer;
