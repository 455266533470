import { gql } from "@apollo/client";

export const LIST_DATA_CSV_IMPORTS = gql`
query ($fileName:String,$page:Int!,$limit:Int!,$adminImport:Boolean!){
  listDataCsvImport(fileName:$fileName,payload:{page:$page,limit:$limit},adminImport:$adminImport){
    dataCsvImports {
      id
      fileName
      sourceType
      user {
        firstName
        lastName
      }
      jobStartedAt
      totalCSVRows
      jobResponse
      status
      fileUrl
    }
    count
    message
    status
  }
}`;

export const DELETE_DATA_CSV_IMPORT_RECORD = gql`
mutation ($id:String!){
  removeDataCsvImport(id:$id){
    message
    status
  }
}`

export const RETRY_DATA_CSV_IMPORTS = gql`
mutation($id:String!) {
  retryDataCsvImport(id:$id) {
    message
    status
  }
}
`
export const CREATE_DATA_CSV_IMPORTS = gql`
mutation($createDataCsvImportInput:CreateDataCsvImportInput!) {
  createDataCsvImport(createDataCsvImportInput:$createDataCsvImportInput) {
    message
    status
  }
}
`