import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { useState, useEffect } from 'react';
import { Button, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';

import { Hour } from '../../../interfaces';
import { STATUS_ME, USER_STATUS_UPDATE } from './gql';
import RangeSlider from 'react-bootstrap-range-slider';
import { useLazyQuery, useMutation } from '@apollo/client';

import EditSchedule from './EditSchedule';
import ModalComponent from '../../../common/Modal';
import Loader from '../../../components/layout/Loader';
import { DeleteIcon } from '../../../assets/images/svgs';
import InputField from '../../../components/layout/InputField';
import { INTERSELLER_TIME_ZONES, SETTINGS_ROUTE } from '../../../utils/constant';

import {
  ADD,
  CUSTOM_HOLIDAYS,
  CUSTOM_HOLIDAYS_DESC,
  DAY_HOUR_IDS,
  EXCLUDE_US_HOLIDAYS,
  MAX_EMAIL_DESC,
  PREVENT_MESSAGE,
  SAVE,
  SCHEDULE,
  SCHEDULE_DESC,
  SECONDS_DESC,
  SELECT_DATE,
  TIMEZONE,
  TIMEZONE_DESC,
  US_HOLIDAYS_DATA,
  VOLUME,
  VOLUME_DESC,
  WEEK_HOURS,
} from '../../../constants';
import GoBack from '../../../components/GoBack';
import { findSequences } from '../../../utils/commonFn';

const TjLookupSetting = () => {
  const steps = [30, 60, 100, 150, 300];
  const [timeValue, setTimeValue] = useState(30);

  // const [sliderValue, setSliderValue] = useState(30);
  const [selectDay, setSelectDay] = useState({});
  const [selectedShowingHour, setSelectedShowingHour] = useState({});
  const [selectHours, setSelectHours] = useState<Hour[]>([]);
  const [selectedDates, setSelectedDates] = useState<string[]>([]);
  const [emailValue, setEmailValue] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [holidayChecked, setHolidayChecked] = useState(false);

  const { handleSubmit, setValue, register, errors } = useForm();
  const [userStatsMe, { data: userStats, loading: userStatsLoading, refetch }] =
    useLazyQuery(STATUS_ME);
  const [userStatsUpdate] = useMutation(USER_STATUS_UPDATE);
  const updateSettings = async (value) => {
    const selectedDateValue = selectHours?.length
      ? selectHours.map((hour) => hour.value)
      : [];
    const respUpdateStats = await userStatsUpdate({
      variables: {
        userStatsInput: {
          limit_day: emailValue,
          limit_frequency: timeValue,
          timezone: value.timeZone,
          exclude_holidays: holidayChecked,
          custom_holidays: selectedDates,
          schedule: selectedDateValue,
        },
      },
    });
    if (respUpdateStats?.data?.userStatsUpdate?.status === 200) {
      toast.success('Setting Update Successfully!');
      refetch();
    } else {
      toast.success('Something Went Wrong!');
    }
  };

  const handleAddDate = async (value) => {
    if (!value?.selectDate) {
      toast.error('Please Select Date');
    } else if (selectedDates.includes(value?.selectDate)) {
      toast.error('This Date Is Already Added');
    } else {
      setSelectedDates((previousDates) => [
        ...previousDates,
        value?.selectDate,
      ]);
    }
  };

  const handleDeleteDate = (date) => {
    setSelectedDates((previousDates) =>
      previousDates.filter((selectedDate) => selectedDate !== date)
    );
  };

  const getTimeData = (id: number) => {
    setOpenModal(true);
    const myDay = WEEK_HOURS.find((day) => id === day.id);
    if (myDay) {
      setSelectDay(myDay);
      setOpenModal(true);
    }
  };

  const userStatsMeRequest = async () => {
    await userStatsMe();
  };

  useEffect(() => {
    userStatsMeRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userStats?.userStatsMe?.status === 200) {
      setEmailValue(userStats?.userStatsMe?.data?.limitDay);
      setTimeValue(userStats?.userStatsMe?.data?.limitFrequency);
      setValue('timeZone', userStats?.userStatsMe?.data?.timeZone);
      setHolidayChecked(userStats?.userStatsMe?.data?.exclude_holidays);
      setSelectedDates(
        userStats?.userStatsMe?.data?.custom_holidays?.length
          ? userStats?.userStatsMe?.data?.custom_holidays
          : []
      );
      if (userStats?.userStatsMe?.data?.schedule?.length) {
        const matchedWorkhours: Hour[] = userStats?.userStatsMe?.data?.schedule
          .map((value) => {
            for (const weekHour of WEEK_HOURS) {
              const matchedHour = weekHour.hours.find(
                (hour) => hour.value === value
              );
              if (matchedHour) {
                return matchedHour;
              }
            }
            return null;
          })
          .filter((obj) => obj !== null);
        setSelectHours(matchedWorkhours);
      } else {
        setSelectHours([]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userStats]);

  useEffect(() => {
    if (selectHours?.length) {
      const valuesOfHours = selectHours.map(obj => obj?.value);
      valuesOfHours.sort((a, b) => a - b)
      const dataInSequences = findSequences(valuesOfHours);
      const hoursObject = {};
      for (let value of dataInSequences) {
        if (value.length > 1) {
          const startValue = value[0];
          const endValue = value[1];
          const startIndex = DAY_HOUR_IDS.findIndex(arr => arr.includes(startValue)) + 1;
          const matchingObject = WEEK_HOURS.find(obj => obj.id === startIndex);
          const matchingStartHours = matchingObject?.hours?.find(hour => hour.value === startValue);
          const matchingEndHours = matchingObject?.hours?.find(hour => hour.value === endValue);
          if (matchingStartHours && matchingEndHours && matchingObject) {
            const startHour = matchingStartHours?.hr?.split(' - ')[0];
            const endHour = matchingEndHours?.hr?.split(' - ')[1];
            const id = matchingObject?.id;
            const hr = `${startHour}-${endHour}`;
            if (!hoursObject[id]) {
              hoursObject[id] = [];
            }
            hoursObject[id].push({ hr });
          }
        } else {
          const singleValue = value[0];
          const startIndex = DAY_HOUR_IDS.findIndex(arr => arr?.includes(singleValue)) + 1;
          const matchingObject = WEEK_HOURS?.find(obj => obj?.id === startIndex);
          const matchingStartHours = matchingObject?.hours?.find(hour => hour.value === singleValue);
          if (matchingStartHours && matchingObject) {
            const id = matchingObject?.id;
            if (!hoursObject[id]) {
              hoursObject[id] = [];
            }
            hoursObject[id].push({ hr: matchingStartHours?.hr });
          }
        }
      }
      if (Object.keys(hoursObject)?.length) {
        setSelectedShowingHour(hoursObject)
      }
    } else {
      setSelectedShowingHour([])
    }
  }, [selectHours]);

  useEffect(() => {
  }, [selectedShowingHour])

  if (userStatsLoading) {
    return <Loader />;
  }

  return (
    <Form>
      <GoBack url={SETTINGS_ROUTE} />
      <div className="d-flex justify-content-between align-items-center flex-wrap mb-4">
        <p className="mb-0 font-weight-bold">{SCHEDULE}</p>
        {/* <Button
          className="buttonGenericStyle filledButton"
          onClick={handleSubmit(updateSettings)}
          type="button"
        >
          {SAVE}
        </Button> */}
      </div>

      <div className="card dark-schedule-card">
        <div className="card-body">
          {/* VOLUME */}
          <p className="mb-2 font-weight-bold">{VOLUME}</p>
          <p className="text-muted text-sm mb-4">{VOLUME_DESC}</p>

          <div className="card dark-schedule-card mb-4">
            <div className="card-body">
              <Row className="align-items-center">
                <Col sm="6">
                  <p className="mb-0">{MAX_EMAIL_DESC}</p>
                </Col>

                <Col sm="6">
                  <Row className="align-items-center">
                    <Col sm="10">
                      <div className="mx-auto" style={{ width: '82%' }}>
                        <RangeSlider
                          value={emailValue}
                          onChange={(e) =>
                            setEmailValue(parseInt(e.target.value))
                          }
                          tooltip="off"
                          variant="warning"
                          max={1000}
                          min={20}
                          step={20}
                        />
                      </div>
                    </Col>

                    <Col sm="2">
                      <p className="font-weight-bold mb-0 person-credit">
                        {emailValue}
                      </p>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </div>

          <div className="card dark-schedule-card mb-4">
            <div className="card-body">
              <Row className="align-items-center">
                <Col sm="6">
                  <p className="mb-0">{SECONDS_DESC}</p>
                </Col>

                <Col sm="6">
                  <Row>
                    <Col sm="12">
                      <Row className="my-2">
                        <Col sm="10">
                          <div className="d-flex justify-between custom-stepper">
                            {steps?.map((step, index) => (
                              <div
                                key={index}
                                // onClick={() => handleStepClick(step)}
                                onClick={() => setTimeValue(step)}
                                className={`step-item ${timeValue === step && 'active'
                                  }`}
                              >
                                <div className="step cursor-pointer" />
                                <p className="mb-0 mt-1 text-muted">{step}</p>
                              </div>
                            ))}
                          </div>
                        </Col>

                        <Col sm="2">
                          <p className="font-weight-bold mb-0 mt-2 person-credit">
                            {timeValue}
                          </p>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </div>

          <Row>
            <Col sm="12" md="10" lg="8" className="mx-auto">
              {/* TIMEZONE */}
              <p className="my-2 font-weight-bold">{TIMEZONE}</p>

              <div className="timezone-width">
                <InputField
                  inputtype="select"
                  required={true}
                  isRequired={true}
                  selectItems={INTERSELLER_TIME_ZONES}
                  label={TIMEZONE_DESC}
                  inputid="timeZone"
                  placeholder="Please select"
                  inputRef={register({
                    required: {
                      value: true,
                      message: 'Time Zone is required!',
                    },
                  })}
                  errMsg={errors?.timeZone?.message}
                />
              </div>

              {/* SCHEDULE */}
              <p className="mt-4 mb-2 font-weight-bold">{SCHEDULE}</p>
              <p className="text-muted text-sm mb-4">{SCHEDULE_DESC}</p>
            </Col>

            <Col sm="12" md="10" lg="8" className="mx-auto">
              {/* DAYS */}
              {WEEK_HOURS.map((weekDay, index) => {
                const { day, id } = weekDay;

                return (
                  <div
                    className="card dark-schedule-card mb-4 cursor-pointer"
                    onClick={() => getTimeData(id)}
                  >
                    <div className="card-body">
                      <div className="d-flex justify-content-between align-items-center">
                        <p className="mb-0 mr-2" key={index}>
                          {day}
                        </p>
                        <p className="mb-0 flex-1 text-right">
                          {Object.keys(selectedShowingHour)?.length ? (
                            <>
                              {selectedShowingHour[index + 1]?.map(
                                (hour, index, array) => {
                                  const isLastDiv = index === array.length - 1;

                                  return (
                                    <p className="d-inline-flex flex-wrap mx-1 my-1 text-muted text-sm">
                                      {hour?.hr}
                                      {!isLastDiv && ' | '}
                                    </p>
                                  );
                                }
                              )}
                            </>
                          ) : null}

                          {/* {selectHours.map((hour) => {
                            // const { hr } = hour || {};
                            const currentDayHour = DAY_HOUR_IDS[ id - 1].includes(hour.value);
                            return (
                              currentDayHour && (
                                <p className="d-inline-flex flex-wrap mx-1 my-1 text-muted text-sm">
                                  {hour?.hr}
                                </p>
                              )
                            );
                          })} */}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}

              {/* US-HOLIDAYS */}
              <div className="card dark-schedule-card mb-4">
                <div className="card-body px-5">
                  <FormGroup className="d-flex align-items-baseline">
                    <Input
                      type="checkbox"
                      checked={holidayChecked}
                      onClick={() => {
                        setHolidayChecked(!holidayChecked);
                      }}
                    />
                    <p className="font-weight-bold mb-0 pl-1">
                      {EXCLUDE_US_HOLIDAYS}
                    </p>
                  </FormGroup>

                  <p className="text-muted text-sm mb-3">{PREVENT_MESSAGE}</p>

                  {US_HOLIDAYS_DATA.map((item, index) => {
                    return (
                      <p key={index} className="text-muted text-sm m-0">
                        {item}
                      </p>
                    );
                  })}
                </div>
              </div>

              {/* CUSTOM-HOLIDAYS */}
              <div className="card dark-schedule-card mb-4">
                <div className="card-body">
                  <p className="font-weight-bold m-0">{CUSTOM_HOLIDAYS}</p>
                  <p className="text-muted text-sm mb-3 mt-0">
                    {CUSTOM_HOLIDAYS_DESC}
                  </p>

                  <div className="d-flex align-items-end">
                    <FormGroup className="mb-0 w-50">
                      <Label>
                        {SELECT_DATE}
                        <span className="text-danger"></span>
                      </Label>
                      <Input
                        type="date"
                        name="selectDate"
                        innerRef={register()}
                      />
                      <small className="text-danger">
                        {errors?.date?.message}
                      </small>
                    </FormGroup>

                    <Button
                      className="buttonGenericStyle filledButton ml-3"
                      onClick={handleSubmit((data) => handleAddDate(data))}
                      type="button"
                    >
                      {ADD}
                    </Button>
                  </div>
                  {selectedDates?.length ? (
                    <>
                      {selectedDates.map((date, index) => (
                        <div className="card dark-schedule-card my-2 w-50">
                          <div className="card-body py-2">
                            <div
                              key={index}
                              className="d-flex align-items-center justify-content-between"
                            >
                              <p className="text-muted text-sm mb-0">{date}</p>
                              <div
                                className="cursor-pointer"
                                onClick={() => handleDeleteDate(date)}
                              >
                                <DeleteIcon color="red" />
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </>
                  ) : null}
                </div>
              </div>
            </Col>
          </Row>

          {/* save-btn */}
          <div className="d-flex justify-content-center align-items-center my-3">
            <Button
              className="buttonGenericStyle filledButton"
              onClick={handleSubmit(updateSettings)}
              type="button"
            >
              {SAVE}
            </Button>
          </div>
        </div>
      </div>

      {/* Modal */}
      <ModalComponent
        size="xl"
        isOpen={openModal}
        toggle={() => setOpenModal(false)}
      >
        <EditSchedule
          weekDay={selectDay}
          selectedHours={selectHours}
          setSelectHours={setSelectHours}
          onClose={() => setOpenModal(false)}
          selectDay={selectDay}
        />
      </ModalComponent>
    </Form>
  );
};

export default TjLookupSetting;
