import moment from 'moment';
import { Controller } from 'react-hook-form';
import { useEffect } from 'react';
import ReactQuill from 'react-quill';
import { useLocation } from 'react-router-dom';
import { Col, Input, Label, Row, Spinner } from 'reactstrap';
import InputField from '../../../../../components/layout/InputField';
import { GET_SUBMISSION_BY_JOBAPPLICANT } from '../../../placements/gql';
import { useLazyQuery } from '@apollo/client';
import { reactQuillReg } from '../../../../../utils/constant';

export default function ClientCalendarInviteBasicInfo({
  register,
  errors,
  control,
  reset,
  fromEdit,
  clientInterviewEmailData,
  clientDefaultInviteFromInterview,
  watch,
  setValue,
  jobApplicantId,
}) {
  const location = useLocation();
  const jobApplicantIdFromLocation = new URLSearchParams(location?.search).get(
    'jobApplicantId'
  );

  const [
    _getSubmission,
    { data: _getSubmissionData, loading: _getSubmissionLoading },
  ] = useLazyQuery(GET_SUBMISSION_BY_JOBAPPLICANT);

  useEffect(() => {
    if (clientInterviewEmailData || clientDefaultInviteFromInterview) {
      const { interviewDateTime, time } = clientDefaultInviteFromInterview;
      const date = moment(`${interviewDateTime} ${time}`).format(
        'YYYY-MM-DDTHH:mm'
      );

      const values: any = {};
      values.subject = clientInterviewEmailData?.subject;
      // values.inviteTime = clientInterviewEmailData?.interviewEmailDateTime
      //   ? moment(clientInterviewEmailData?.interviewEmailDateTime)
      //       .utcOffset(
      //         clientInterviewEmailData?.timeZoneId ||
      //           clientDefaultInviteFromInterview?.timeZoneId
      //       )
      //       .format('YYYY-MM-DDTHH:mm')
      //   : date;


      values.inviteTime =fromEdit?  moment(clientInterviewEmailData?.interviewEmailDateTime)
          .utcOffset(
            clientInterviewEmailData?.timeZoneId ||
              clientDefaultInviteFromInterview?.timeZoneId
          )
          .format('YYYY-MM-DDTHH:mm')
      : date;

      // values.inviteTime =date
      // this comment is used for the purpose of prefill the clientInterviewEmailData data

      //   let date1 = moment(clientInterviewEmailData?.interviewEmailDateTime)
      //   .utcOffset(clientInterviewEmailData?.timeZoneId)
      //   .format("MM/DD/YYYY");

      // let time1 = moment(clientInterviewEmailData?.interviewEmailDateTime)
      //   .utcOffset(clientInterviewEmailData?.timeZoneId)
      //   .format("hh:mm A");

      // let date12 = moment(`${date1} ${time1}`).format("YYYY-MM-DDTHH:mm");
      
      // values.inviteTime = date12;

      reset({ ...values });
    }

  }, [clientDefaultInviteFromInterview, clientInterviewEmailData, fromEdit, reset]);

  const customStyles = {
    control: (styles, provided) => ({
      ...styles,

      fontSize: 12,
    }),

    option: (provided, state) => ({
      ...provided,
      fontSize: 12,
    }),

    container: (provided, state) => ({
      ...provided,
      fontSize: 12,
    }),
  };

  const onSubmissionSalaryPharase = () => {
    if (jobApplicantId || jobApplicantIdFromLocation) {
      _getSubmission({
        variables: {
          jobApplicantId: jobApplicantId || jobApplicantIdFromLocation,
        },
      });
    }
  };

  useEffect(() => {
    if (_getSubmissionData?.submissionCandidatePipelined) {
      const detailVal = `${watch('details')}<br/><p>${
        _getSubmissionData?.submissionCandidatePipelined?.targetSalary
      }</p>`;

      setValue('details', detailVal);
    }

    // eslint-disable-next-line
  }, [_getSubmissionData?.submissionCandidatePipelined]);

  console.log('----------------------------------fromEdit', fromEdit);

  return (
    <div>
      <Row>
        <Col
          lg="6"
          md="6"
          sm="12"
          xs="12"
          className="custom-form-input form-input"
        >
          <Label className="text-14 text-blackele pb-3">
            Subject<span className="text-danger">*</span>
          </Label>
          <Input
            defaultValue={`${!fromEdit ? 'Client Invite' : ''}`}
            name="subject"
            innerRef={register({
              required: {
                value: true,
                message: 'Subject is required!',
              },
            })}
          />
          <small className="text-danger">{errors?.subject?.message}</small>
        </Col>

        <Col
          lg="6"
          md="6"
          sm="12"
          xs="12"
          className="custom-form-input form-input"
        >
          <Label className="text-14 text-blackele pb-3">
            Interview Date/Time<span className="text-danger">*</span>
          </Label>
          <Input
            disabled={true}
            type="datetime-local"
            name="inviteTime"
            min={new Date().toISOString().substring(0, 19)}
            innerRef={register({
              required: {
                value: true,
                message: 'Invite Date Time is required!',
              },
            })}
          />
          <small className="text-danger">{errors?.inviteTime?.message}</small>
        </Col>
      </Row>

      <Row className="mt-3">
        <Col
          lg="6"
          md="6"
          sm="12"
          xs="12"
          className="custom-form-input form-input"
        >
          <Label className="text-14 text-blackele pb-3">
            Interview Timezone
          </Label>
          <InputField
            disabled={true}
            inputtype="text"
            required={true}
            label="Interview Timezone"
            isSidebarField={true}
            inputid="timeZone"
            defaultValue={
              fromEdit
                ? clientInterviewEmailData?.timeZone
                : clientDefaultInviteFromInterview?.timeZone
            }
            placeholder="Timezone"
            inputRef={register({
              required: { value: true, message: 'Timezone is required' },
            })}
            errMsg={errors?.timeZone?.message}
          />
        </Col>

        <Col
          lg="6"
          md="6"
          sm="12"
          xs="12"
          className="custom-form-input form-input"
        >
          <Label className="text-14 text-blackele pb-3">
            Location<sup className="text-danger">*</sup>
          </Label>

          <InputField
            inputtype="text"
            isRequired
            label="Location"
            inputid="location"
            isSidebarField
            defaultValue={
              fromEdit
                ? clientInterviewEmailData?.location
                : clientDefaultInviteFromInterview?.location || ''
            }
            placeholder="location"
            inputRef={register({
              required: { value: true, message: 'Location is required' },
            })}
            errMsg={errors?.location?.message}
          />
        </Col>

        <Col
          lg="12"
          md="12"
          sm="12"
          xs="12"
          className="custom-form-input form-input"
        >
          <div className="d-flex justify-content-between">
            <Label className="text-14 text-blackele pb-3">
              Details <span className="text-danger">*</span>
            </Label>
            <button
              type="button"
              className="border-0 bg-transparent color-default add-salary-pharase"
              onClick={onSubmissionSalaryPharase}
            >
              {_getSubmissionLoading && (
                <Spinner
                  size="sm"
                  style={{ color: '#eaa827' }}
                  className="mr-2"
                />
              )}
              Add Target Salary Phrase
            </button>
          </div>
          <Controller
            control={control}
            name="details"
            rules={{
              required: {
                value: true,
                message: 'Add some detail content!',
              },
            }}
            defaultValue={
              (fromEdit
                ? clientInterviewEmailData?.body || null
                : clientDefaultInviteFromInterview?.details) || null
            }
            render={({ onChange, ref, value }) => (
              <div className="editor-height w-100">
                <ReactQuill
                  onChange={(value) => {
                    if (reactQuillReg.test(value)) {
                      onChange(null);
                    } else {
                      onChange(value);
                    }
                  }}
                  ref={ref}
                  value={value}
                />
                <small className="text-danger pt-2">
                  {errors?.details?.message}
                </small>
              </div>
            )}
            styles={customStyles}
          />
        </Col>
      </Row>
    </div>
  );
}
