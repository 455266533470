import { Button, Col, Row } from "reactstrap";
import GoBack from "../../../components/GoBack";
import Preview from "./document-preview/Preview";
import { useEffect, useState } from "react";

import PreviewDocumentNotes from "./PreviewDocumentNotes";
import { GET_SINGLE_ATTACHMENT } from "../../candidate/gql";
import { useLazyQuery } from "@apollo/client";
import { downloadSignedUrlFile } from "../../../utils/commonFn";

const ClientDocumentPreviewAndNotes = ({ ...props }: any) => {
  const [previewModal] = useState<boolean>(false);
  const [jobOrderId, setJobOrderId] = useState<string>();
  const [attachmentId, setAttachmentId] = useState<string>();
  const [docSate, setDocSate] = useState<string>("Original");

  const [getAttachment, { data }] = useLazyQuery(GET_SINGLE_ATTACHMENT);

  useEffect(() => {
    const { id } = props?.match?.params;
    if (id) {
      getAttachment({ variables: { id: id } });
    }
    const jobOrderId = props?.location?.state?.jobOrderId;
    setJobOrderId(jobOrderId);
    setAttachmentId(id);
    // eslint-disable-next-line
  }, []);

  return (
    <div className="admin-tabs team-tabs">
      {jobOrderId ? (
        <GoBack url={`/update-job-order/${jobOrderId}`} />
      ) : (
        <GoBack url={"/job-orders"} />
      )}
      <div className="primaryHeading primaryHeaderSpacing">
        <h5 className="m-0">View Client Document </h5>
      </div>

      <Row>
        <Col xl="8" md="12" className="pdf-viewer">
          <div className="d-flex justify-content-end">
            <Button
              className="border-0 bg-transparent color-default px-2"
              size="sm"
              style={{ backgroundColor: "#e9e9e9" }}
              onClick={() => {
                setDocSate("Original");
              }}
            >
              Original
            </Button>
            <Button
              className="bg-info border-0 bg-transparent color-default px-2"
              size="sm"
              onClick={() => {
                setDocSate("Highlighted");
              }}
            >
              Highlighted
            </Button>
            <Button
              className="border-0 bg-transparent color-default px-2"
              size="sm"
              onClick={() =>
                downloadSignedUrlFile(
                  data?.attachment?.documentUrl,
                  data?.attachment?.documentName
                )
              }
            >
              Download
            </Button>
          </div>

          {docSate === "Original" && (
            <Preview
              documentUrl={data?.attachment?.documentUrl}
              documentName={data?.attachment?.documentName}
            />
          )}
          {docSate === "Highlighted" && (
            // <HighlightedDoc
            //   name={data?.attachment?.documentName}
            //   hasHtml={data?.attachment?.hasHtml}
            //   refetch={()=> getAttachment({ variables: { id: attachmentId } })}
            //   id={data?.attachment?.id}
            // />
            <div className="mt-5">
              <iframe
                src={data?.attachment?.driveUrl}
                title="xyz"
                width="1000"
                height="1000"
                style={{
                  width: "100%",
                  height: "45em",
                  display: "block",
                  border: "1px solid #aaa",
                }}
                frameBorder="0"
                allowFullScreen
              ></iframe>
            </div>
          )}
        </Col>
        <Col xl="4" md="12">
          <PreviewDocumentNotes
            previewModal={previewModal}
            noteableId={attachmentId}
          />
        </Col>
      </Row>
    </div>
  );
};

export default ClientDocumentPreviewAndNotes;
