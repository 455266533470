// packages block
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  FC,
  Reducer,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
// packages block
import AddNotesModal from '../../../components/AddNotesModal';
import ContentViewSidebar from '../../../components/ContentViewSidebar';
import InputField from '../../../components/layout/InputField';
import NoDataFound from '../../../components/layout/NoDataFound';
import Pagination from '../../../components/layout/Pagination';
import TableComponent from '../../../components/layout/TableComponent';
import ItemSummary from './ItemSummary';
// graphql, utils, svgs and constants block
import Delete_Img from '../../../assets/images/candidate-delete.svg';
import EDIT_ICON from '../../../assets/images/edit-icon-menu.svg';
import SEARCH_IMG from '../../../assets/images/search.svg';
import VIEW_ICON from '../../../assets/images/viewIcon.svg';
import ConfirmPopUp from '../../../components/ConfirmPopUp';
import { EMPLOYMENT_HEADER } from '../../../constants';
import { AppContext } from '../../../context';
import { CandidateNotesListProps } from '../../../interfaces';
import {
  Action,
  ActionType,
  candidateReducer,
  initialState,
  State,
} from '../../../reducers/candidateReducer';
import {
  Action as NoteAction,
  ActionType as NoteActionType,
  initialState as noteInitialState,
  noteReducer,
  State as NoteState,
} from '../../../reducers/noteReducer';
import { formatDateUS } from '../../../utils/commonFn';
import {
  candidateNotesCategories,
  companyNotesCategories,
  PAGE_LIMIT,
  sortingAlphabetical,
} from '../../../utils/constant';
import {
  DELETE_NOTE,
  GET_COMPANY_CONTACT_NOTES,
  GET_NOTES,
} from '../../company/gql';
import { GET_ES_NOTES } from '../../NotesSearch/gql';

export const CompensationNote: FC<CandidateNotesListProps> = (
  {
    recordId,
    type,
    haveActionPermissions,
    isPrivatePermission,
  },
  props
): JSX.Element => {
  const { theme } = useContext(AppContext);
  const [category, setCategory] = useState();
  const [state, dispatch] = useReducer<Reducer<State, Action>>(
    candidateReducer,
    initialState
  );
  const [
    {
      contactId,
      limit: contactLimit,
      currentPage: contactCurrentPage,
    },
    noteDispatch,
  ] = useReducer<Reducer<NoteState, NoteAction>>(noteReducer, noteInitialState);

  const {
    activityNotes,
    confirmNotePopup,
    deleteNoteId,
    limit,
    modalNoteContent,
    noteCategory,
    noteContent,
    noteCreator,
    noteData,
    noteModal,
    currentPage,
    selectedNote,
    noteState,
  } = state;

  const [getNotes, { data: notesDBData }] = useLazyQuery(GET_NOTES);
  const [getEsNotes, { data: notesESData }] = useLazyQuery(GET_ES_NOTES);
  const [getCompanyContactNotes, { data: companyContactNotesData }] =
    useLazyQuery(GET_COMPANY_CONTACT_NOTES);

  const toggleActivityNotes = () => {
    activityNotes &&
      dispatch({ type: ActionType.SET_SELECTED_NOTE, selectedNote: {} });
    dispatch({
      type: ActionType.SET_ACTIVITY_NOTE,
      activityNote: !activityNotes,
    });
  };

  const [_deleteNote] = useMutation(DELETE_NOTE);

  const deleteNote = async () => {
    let res = await _deleteNote({ variables: { noteId: deleteNoteId } });
    if (res?.data) {
      toast.success('Successfully deleted!');
      fetchNotes();
      dispatch({
        type: ActionType.SET_CONFIRM_NOTE_POPUP,
        confirmNotePopup: false,
      });
    }
  };

  const noteToggle = () =>
    dispatch({ type: ActionType.SET_NOTE_MODAL, noteModal: !noteModal });

  const fetchNotes = useCallback(async () => {
    getNotes({
      variables: {
        id: recordId,
        notePayload: {
          type,
          content: noteContent,
          category: 'Candidate Compensation',
          createdBy: noteCreator,
          compensationNote: true,
        },
        limit,
        page: currentPage,
      },
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    contactCurrentPage,
    contactId,
    contactLimit,
    currentPage,
    getCompanyContactNotes,
    getEsNotes,
    getNotes,
    limit,
    noteCategory,
    noteContent,
    noteCreator,
    recordId,
    type,
  ]);

  const handleEdit = (item: any) => {
    dispatch({ type: ActionType.SET_SELECTED_NOTE, selectedNote: item });
    dispatch({ type: ActionType.SET_ACTIVITY_NOTE, activityNote: true });
  };

  const customStyles = {
    control: (styles, provided) => ({
      ...styles,
      width: `${props.width} !important`,
      background: theme === 'light' ? provided.background : '#282828',
      borderColor: theme === 'light' ? '#ced4da' : '#282828',
      color: theme === 'light' ? provided.background : '#d9d9d9',
      fontSize: 12,
      '&:hover': {
        borderColor: theme === 'light' ? '#ced4da' : '#282828',
        color: theme === 'light' ? provided.background : '#d9d9d9',
      },
    }),
    menu: (provided) => ({
      ...provided,
      background: theme === 'light' ? provided.background : '#282828',
      zIndex: props?.zIndex || 999999,
      color: theme === 'light' ? provided.background : '#d9d9d9',
    }),
    option: (provided) => ({
      ...provided,
      fontSize: 12,
      background: theme === 'light' ? provided.background : '#363636',
      '&:hover': {
        background: theme === 'light' ? provided.background : '#474444',
        color: theme === 'light' ? provided.background : '#d9d9d9',
      },
    }),
    container: (provided, state) => ({
      ...provided,
      fontSize: 12,
    }),
  };

  useEffect(() => {
    recordId && fetchNotes();
  }, [
    recordId,
    currentPage,
    contactCurrentPage,
    limit,
    contactLimit,
    noteCategory,
    noteContent,
    noteCreator,
    fetchNotes,
  ]);

  useEffect(() => {
    if (!!notesDBData || !!companyContactNotesData) {
      if (type === 'Company') {
        const { getCompanyContactsNotes } = companyContactNotesData || {};

        !!getCompanyContactsNotes &&
          noteDispatch({
            type: NoteActionType.SET_CONTACT_NOTES,
            contactNotes: getCompanyContactsNotes || {},
          });
      } else {
        const { getNotes } = notesDBData || {};

        !!getNotes &&
          dispatch({ type: ActionType.SET_NOTE_DATA, noteData: getNotes });
      }
    } else if (!!notesESData) {
      const { getESNotes } = notesESData;
      !!getESNotes &&
        dispatch({ type: ActionType.SET_NOTE_DATA, noteData: getESNotes });
    }
  }, [companyContactNotesData, noteData, notesDBData, notesESData, type]);

  const renderItem = (item) => (
    <tr>
      <td>{item?.category}</td>
      <td className="rich-text-paragraph">
        <ItemSummary item={item} from={''} />
      </td>

      {type === 'Company' && <td>{item?.contactName}</td>}

      <td>{item?.user?.fullName || '--'}</td>
      <td>
        {item?.modifiedOn ? formatDateUS(item?.modifiedOn) : '--'}
        {'  '}
        {item?.modifiedOn &&
          new Date(item?.modifiedOn).toLocaleTimeString()}{' '}
      </td>

      <td align="left">
        <div
          className="d-flex align-items-center justify-content-start"
          style={{ width: 150 }}
        >
          <button
            className="iconButtonAction"
            disabled={haveActionPermissions}
            onClick={() => handleEdit(item)}
          >
            <img src={EDIT_ICON} alt="Edit" />
          </button>

          <button
            onClick={() => {
              dispatch({
                type: ActionType.SET_MODAL_NOTE_CONTENT,
                modalNoteContent: item?.content,
              });
              dispatch({ type: ActionType.SET_NOTE_STATE, noteState: item });
              noteToggle();
            }}
            className="iconButtonAction mx-2"
          >
            <img src={VIEW_ICON} alt="view" width="21px" />
          </button>

          <button
            disabled={haveActionPermissions}
            onClick={() => {
              dispatch({
                type: ActionType.SET_CONFIRM_NOTE_POPUP,
                confirmNotePopup: !confirmNotePopup,
              });
              dispatch({
                type: ActionType.SET_DELETE_NOTE_ID,
                deleteNoteId: item.id,
              });
            }}
            className="iconButtonAction"
          >
            <img src={Delete_Img} alt="delete" width="16px" />
          </button>
        </div>
      </td>
    </tr>
  );

  const renderNotesPagination = () => {
    return (
      <div className="users-pagination">
        <Pagination
          onPageChange={(pageClicked: number) =>
            dispatch({
              type: ActionType.SET_CURRENT_PAGE,
              currentPage: pageClicked,
            })
          }
          pageCount={Math.ceil(noteData?.count / limit)}
          currentPage={currentPage}
        />

        <div className="d-flex align-items-center">
          <div className="pagination-display paginationItemsSelect">
            <p className="m-0">
              {(currentPage - 1) * limit + 1} to{' '}
              {Math.min(currentPage * limit, noteData?.count)} of{' '}
              {noteData?.count}
            </p>

            <div className="divider"></div>

            <label>Display</label>

            <InputField
              label=""
              inputtype="select"
              selectItems={PAGE_LIMIT}
              inputid="limit"
              placeholder={limit !== 10 ? limit.toString() : 'Set Limit'}
              inputMethod={(value) => {
                if (!value) value = limit.toString();
                dispatch({ type: ActionType.SET_CURRENT_PAGE, currentPage: 1 });
                dispatch({
                  type: ActionType.SET_LIMIT,
                  limit: parseInt(value),
                });
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="table-responsive tableResponsiveInitial view-candidate-list-table">
        <TableComponent tableHeader={EMPLOYMENT_HEADER}>
          <tbody>
            <tr className="searchFilterTable">
              <td>
                <div className="searchFieldWidth">
                  <Select
                    isClearable
                    options={sortingAlphabetical([
                      ...candidateNotesCategories,
                      ...companyNotesCategories,
                    ])}
                    onChange={(event: any) => {
                      dispatch({
                        type: ActionType.SET_NOTE_CATEGORY,
                        noteCategory: event?.value,
                      });
                      setCategory(event);
                    }}
                    placeholder="Category"
                    maxMenuHeight={140}
                    styles={{ ...customStyles }}
                    isDisabled={isPrivatePermission}
                    value={
                      isPrivatePermission
                        ? {
                            label: 'Compensation Notes',
                            value: 'Candidate Compensation',
                          }
                        : category
                    }
                  />
                </div>
              </td>

              <td>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <img src={SEARCH_IMG} alt="search" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    type="text"
                    placeholder="Note content"
                    value={noteContent}
                    onChange={(e) =>
                      dispatch({
                        type: ActionType.SET_NOTE_CONTENT,
                        noteContent: e.target.value,
                      })
                    }
                    className="search-by-company"
                  />
                </InputGroup>
              </td>

              <td>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <img src={SEARCH_IMG} alt="search" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    type="text"
                    placeholder="Note created by"
                    value={noteCreator}
                    onChange={(e) =>
                      dispatch({
                        type: ActionType.SET_NOTE_CREATOR,
                        noteCreator: e.target.value,
                      })
                    }
                    className="search-by-company"
                  />
                </InputGroup>
              </td>

              <td></td>

              <td></td>
            </tr>

            {noteData?.notes?.map((item) => renderItem(item))}

            {!noteData?.notes?.length && (
              <tr>
                <td colSpan={6} className="empty-table-td">
                  <NoDataFound text="No Notes Found!" />
                </td>
              </tr>
            )}
          </tbody>
        </TableComponent>

        {!!noteData?.notes?.length && renderNotesPagination()}
      </div>

      <ContentViewSidebar
        noteData={noteState}
        toggle={noteToggle}
        content={modalNoteContent}
        emailNotes={null}
        isOpen={noteModal}
        heading={'View Candidate Note Content'}
        emailHeader={null}
      />

      <AddNotesModal
        type={type}
        noteableId={recordId}
        editData={selectedNote}
        activityNotes={activityNotes}
        reFetch={() => fetchNotes()}
        toggleActivityNotes={toggleActivityNotes}
        resetField={() =>
          dispatch({ type: ActionType.SET_NOTE_DATA, noteData: '' })
        }
      />

      <ConfirmPopUp
        confirmText="Are you sure you want to delete this note?"
        isOpen={confirmNotePopup}
        toggle={() =>
          dispatch({
            type: ActionType.SET_CONFIRM_NOTE_POPUP,
            confirmNotePopup: !confirmNotePopup,
          })
        }
        confirmAction={deleteNote}
        modalHeading="Delete Note"
        btnText="Delete"
        btnColor="primary"
        className="for-modal-styling"
      />
    </>
  );
};
