import { useMutation } from "@apollo/client";
import { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { toast } from "react-toastify";
import {
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";

import { IdealWorkType } from "../../../utils/constant";

import "../candidate.scss";
import { EDIT_EMPLOYMENT_HISTORY } from "../gql";

import EDIT_ICON from "../../../assets/images/edit-icon-menu.svg";
import CustomButton from "../../../components/layout/CustomButton";
import { AppContext } from "../../../context";
import { customSelectDropDown } from "../../../components/styled/customSelect";

const EditEmploymentHistory = ({ item, updateUI, candidateId }: any) => {
  const { theme } = useContext(AppContext)
  const [checked, setChecked] = useState(false);
  const [editModal, setEditModal] = useState(false);

  const convertDate = (inputFormat) => {
    function pad(s) {
      return s < 10 ? "0" + s : s;
    }
    var d = new Date(inputFormat);
    return [d.getFullYear(), pad(d.getMonth() + 1), pad(d.getDate())].join("-");
  };

  // console.log(companyName)
  const [editEmployment, { loading }] = useMutation(EDIT_EMPLOYMENT_HISTORY);
  useEffect(() => {
    setChecked(item?.endedAt === null ? true : false);
    // eslint-disable-next-line
  }, []);

  const editEmpHistory = async (values) => {
    values.workType = values?.workType?.value;
    values.id = item?.id;
    values.currentlyWorking = checked;
    values.candidateId = candidateId;
    if (checked) {
      values.endedAt = null;
    }

    if (!checked && !values.endedAt) {
      toast.error("End date is required");
      return;
    }

    const res = await editEmployment({ variables: { ...values } });
    if (res?.data) {
      toast.success("Successfully Edited!");
      updateUI();
      setEditModal(!editModal);
      setChecked(false);
    } else if (res?.errors) {
      toast.error(res.errors[0]?.message);
    }
    // console.log(item?.id)
  };
  const { errors, handleSubmit, register, control } = useForm();

  return (
    <div className="mr-2">
      <button
        className="iconButtonAction"
        onClick={() => setEditModal(!editModal)}
      >
        <img src={EDIT_ICON} alt="edit" />
      </button>

      <Modal
        isOpen={editModal}
        toggle={() => setEditModal(!editModal)}
        className="revampDialog revampDialogWidth"
      >
        <ModalHeader toggle={() => setEditModal(!editModal)}>
          Employment History
        </ModalHeader>
        <ModalBody>
          <Form>
            <Row>
              <Col lg="6" md="6" sm="12" xs="12">
                <FormGroup>
                  <Label>Company Name</Label>
                  <Input
                    defaultValue={item?.companyName}
                    type="text"
                    name="companyName"
                    innerRef={register({
                      required: {
                        value: true,
                        message: "Company name is required!",
                      },
                    })}
                  />
                  <small className="text-danger">
                    {errors?.companyName?.message}
                  </small>
                </FormGroup>
              </Col>
              <Col lg="6" md="6" sm="12" xs="12">
                <FormGroup>
                  <Label>Job Title</Label>
                  <Input
                    type="text"
                    defaultValue={item?.jobTitle}
                    name="jobTitle"
                    innerRef={register()}
                  />
                </FormGroup>
              </Col>
              <Col lg="6" md="6" sm="12" xs="12">
                <FormGroup>
                  <Label>Work Type</Label>
                  <Controller
                    name="workType"
                    as={Select}
                    rules={{ required: "Please select work type!" }}
                    options={IdealWorkType}
                    control={control}
                    placeholder="Work Type"
                    defaultValue={IdealWorkType?.find(
                      (val) => val.value === item?.workType
                    )}
                    styles={{...customSelectDropDown(theme)}}
                  />
                  <small className="text-danger">
                    {errors?.workType?.message}
                  </small>
                </FormGroup>
              </Col>
              <Col lg="6" md="6" sm="12" xs="12">
                <FormGroup>
                  <Label>Start Date</Label>
                  <Input
                    defaultValue={convertDate(item?.startedAt)}
                    type="date"
                    name="startedAt"
                    innerRef={register({
                      required: {
                        value: true,
                        message: "Start date is required!",
                      },
                    })}
                  />
                  <small className="text-danger">
                    {errors?.companyName?.message}
                  </small>
                </FormGroup>
              </Col>
              <Col lg="6" md="6" sm="12" xs="12">
                <FormGroup>
                  <Label>End Date</Label>
                  <Input
                    disabled={checked}
                    defaultValue={
                      item?.endedAt ? convertDate(item?.endedAt) : ""
                    }
                    type="date"
                    name="endedAt"
                    innerRef={register()}
                  />
                </FormGroup>
              </Col>
              <Col lg="6" md="6" sm="12" xs="12">
                <FormGroup className="d-flex align-items-center pt-4">
                  <Input
                    className="mb-1 mr-2"
                    style={{ position: "static", marginLeft: "0.1rem" }}
                    type="checkbox"
                    onChange={() => setChecked(!checked)}
                    checked={checked}
                  />
                  <Label>Currently Working</Label>
                </FormGroup>
              </Col>
            </Row>

            <FormGroup>
              <Label>Description</Label>
              <textarea
                className="form-control"
                // style={{ minHeight: "150px" }}
                rows={4}
                id="description"
                ref={register({ required: false })}
                name="description"
                defaultValue={item?.description}
                placeholder={"No description Found"}
              ></textarea>
            </FormGroup>
            <div className="d-flex align-items-center justify-content-end pt-2 pb-2">
              <button
                onClick={() => setEditModal(!editModal)}
                className="buttonPrimary mr-3"
                type="button"
              >
                Cancel
              </button>

              <CustomButton
                buttonText="Edit"
                buttonColor="primary"
                buttonType="submit"
                className="big-width"
                buttonClick={handleSubmit(editEmpHistory)}
                loading={loading}
              />
            </div>
          </Form>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default EditEmploymentHistory;
