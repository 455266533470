import { useLazyQuery, useMutation } from "@apollo/client";
import { FC, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Button, Card, CardBody, Label } from "reactstrap";
import deleteIcon from "../../../assets/images/delete-field.svg";
import editIcon from "../../../assets/images/edit-icon-new.svg";
import ConfirmPopUp from "../../../components/ConfirmPopUp";
import NoDataFound from "../../../components/layout/NoDataFound";
import InputField from "../../../components/layout/InputField";
import Loader from "../../../components/layout/Loader";
import { Pagination } from "../../../components/layout/Pagination";
import { CompanyAddressCardProps } from "../../../interfaces";
import { PAGE_LIMIT, UK } from "../../../utils/constant";
import "../../company/company.scss";
import { DELETE_COMPANY_ADDRESS, FETCH_COMPANY_DETAILS, GET_COMPANY_ADDRESS, UPDATE_COMPANY_PRIMARY_ADDRESS } from "../gql";
import AddUpdateAddress from "./AddUpdateAddress";

const CompanyAddressCard: FC<CompanyAddressCardProps> = ({
  companyId,
  haveActionPermissions,
}) => {
  // constants and states
  const page = parseInt("1");
  const [currentPage, setCurrentPage] = useState(page);
  const [limit, setLimit] = useState(6);
  const [deleteAddress] = useMutation(DELETE_COMPANY_ADDRESS);
  const [delAddressConfirm, setDelAddressConfirm] = useState(false);
  const [addressId, setAddressId] = useState("");
  const [formToggle, setFormToggle] = useState<boolean>(false);
  const [updateAddressConfirm, setUpdateAddressConfirm] = useState(false);
  const [primaryAddressId, setPrimaryAddressId] = useState(null);
  let defaultValues = { id: '', state: '', city: '', country: '', zipCode: '', streetAddress: '' };
  const [addressObj, setAddressObj] = useState(defaultValues);

  // queries and mutations
  const [_updatePrimaryAddress, { loading: settingAddress }] = useMutation(
    UPDATE_COMPANY_PRIMARY_ADDRESS
  );
  const [getCompanyDetail] = useLazyQuery(
    FETCH_COMPANY_DETAILS
  );
  const [
    getCompanyAddres,
    { data: companyAddress, loading: companyAddressLoading },
  ] = useLazyQuery(GET_COMPANY_ADDRESS);

  const fetchCompanyDetails = () => {
    getCompanyDetail({ variables: { companyId } });
  };

  // methods
  const updatePrimaryAddress = async () => {
    let res = await _updatePrimaryAddress({
      variables: {
        updatePrimaryInput: {
          companyId,
          id: primaryAddressId,
        },
      },
    });


    if (res?.data?.updatePrimaryAddress) {
      setUpdateAddressConfirm(false);
      getCompanyAddres({
        variables: { id: companyId, limit, page: currentPage },
      });
      fetchCompanyDetails();
      toast.success('Primary address successfully changed!');
    } else {
      toast.error('Unable to update primary address!')
    }
  };

  const removeAddress = async () => {
    const res = await deleteAddress({ variables: { addressId } });
    setDelAddressConfirm(false);
    getCompanyAddres({
      variables: { id: companyId, limit, page: currentPage },
    });
    if (res?.data) {
      toast.success("Address Successfully deleted!");
      setFormToggle(false);
    } else {
      toast.error("Address could not be deleted!");
    }
  };

  useEffect(() => {
    if (companyId) {
      getCompanyAddres({
        variables: { id: companyId, limit, page: currentPage },
      });
    }
    // eslint-disable-next-line
  }, [companyId, limit, currentPage]);

  const updateAddressData = (item) => {
    setAddressId(item?.id);
    if (item?.country === UK) item.state = null;
    setAddressObj({
      id: item?.id,
      state: item?.state,
      city: item?.city,
      country: item?.country,
      zipCode: item?.zipCode,
      streetAddress: item?.streetAddress,
    });
    setFormToggle(true);
  };

  return (
    <div className="user-basic-form tab-pane-padding">
      <div>
        <div className="d-flex justify-content-end">
          <button
            type="button"
            disabled={haveActionPermissions}
            className="buttonGenericStyle filledButton m-0"
            onClick={() => {
              setFormToggle(true);
              setAddressId("");
            }}
          >
            Create New Address
          </button>
        </div>
        <div className="card-inner-padding px-0">
          <div className="">
            {companyAddressLoading ? (
              <Loader loaderClass="sm-height" />
            ) : (
              <div>
                {!companyAddressLoading &&
                  !companyAddress?.getCompanyAddress?.address?.length && (
                    <div className="for-position-multiselect">
                      <NoDataFound text="No Address Found!" />
                    </div>
                  )}

                {companyAddress?.getCompanyAddress?.address?.map(
                  (item: any, index: number) => (
                    <Card
                      className={`address-card ${item?.isPrimary ? "active" : ""
                        }`}
                      key={index}
                    >
                      <CardBody>
                        <div className="notes-address">
                          <div className="address-grid">
                            <h6>
                              {`${item?.streetAddress}`}
                            </h6>
                            {
                              ['US', 'CA', null, undefined, ''].includes(item?.country) ? (
                                <h6 className="pt-1">{`${item?.city}, ${item?.state}, ${item?.zipCode}, ${item?.country}`}</h6>
                              ) : (
                                <h6 className="pt-1">{`${item?.city}, ${item?.zipCode}, ${item?.country}`}</h6>
                              )
                            }
                          </div>

                          <div className="d-flex items-center">
                            <div className="d-flex items-center" style={{ marginTop: '5px' }}>
                              <Label className="radio-container">
                                is Primary
                                <input
                                  type="checkbox"
                                  name="oldAddress"
                                  checked={item?.isPrimary}
                                  onChange={() => {
                                    setUpdateAddressConfirm(true);
                                    setPrimaryAddressId(item?.id);
                                  }}
                                />
                                <span className="checkmark"></span>
                              </Label>
                            </div>

                            <Button
                              onClick={() => {
                                updateAddressData({
                                  id: item?.id,
                                  state: item?.state,
                                  city: item?.city,
                                  country: item?.country,
                                  zipCode: item?.zipCode,
                                  streetAddress: item?.streetAddress,
                                })
                              }}
                              className="my-auto bg-transparent border-0 w-auto d-flex justify-content-center align-items-center ml-3"
                              disabled={haveActionPermissions}
                            >
                              <img
                                className="m-0"
                                src={editIcon}
                                alt=""
                                width="24"
                                height="24"
                              />
                            </Button>
                            <Button
                              onClick={() => {
                                setDelAddressConfirm(true);
                                setAddressId(item?.id);
                              }}
                              className="my-auto bg-transparent border-0 w-auto d-flex justify-content-center align-items-center"
                              disabled={haveActionPermissions}
                            >
                              <img
                                className="m-0"
                                src={deleteIcon}
                                alt=""
                                width="24"
                                height="24"
                              />
                            </Button>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  )
                )}
              </div>
            )}

            {companyAddress?.getCompanyAddress?.count ? (
              <div className="users-pagination">
                <Pagination
                  onPageChange={(pageClicked: number) => {
                    setCurrentPage(pageClicked);
                  }}
                  pageCount={Math.ceil(
                    companyAddress?.getCompanyAddress?.count / limit
                  )}
                  currentPage={currentPage}
                />

                <div className="d-flex align-items-center">
                  <div className="pagination-display paginationItemsSelect">
                    <p className="m-0">
                      {(currentPage - 1) * limit + 1} to{" "}
                      {Math.min(
                        currentPage * limit,
                        companyAddress?.getCompanyAddress?.count
                      )}{" "}
                      of {companyAddress?.getCompanyAddress?.count}
                    </p>

                    <div className="divider"></div>

                    <label>Display</label>

                    <InputField
                      label=""
                      inputtype="select"
                      selectItems={PAGE_LIMIT.filter(
                        (item) => item.value !== limit
                      )}
                      inputid="limit"
                      placeholder={limit.toString()}
                      inputMethod={(value) => {
                        setCurrentPage(1);
                        setLimit(parseInt(value));
                      }}
                    />
                  </div>
                </div>
              </div>
            ) : null}
          </div>
          {(formToggle) && (
            <AddUpdateAddress
              listLength={companyAddress?.getCompanyAddress?.address?.length}
              companyId={companyId}
              refetchAddress={() => {
                getCompanyAddres({
                  variables: { id: companyId, limit, page: currentPage },
                });
              }}
              formToggle={formToggle}
              setFormToggle={setFormToggle}
              addressObj={addressObj}
              addressId={addressId}
            />
          )}
          <ConfirmPopUp
            confirmText="Are you sure you want to set as primary address?"
            isOpen={updateAddressConfirm}
            toggle={setUpdateAddressConfirm}
            confirmAction={updatePrimaryAddress}
            modalHeading="Set Primary Address"
            btnText="Confirm"
            btnColor="primary"
            loading={settingAddress}
            disabled={settingAddress}
          />
          <ConfirmPopUp
            confirmText="Are you sure you want to delete this address?"
            isOpen={delAddressConfirm}
            toggle={setDelAddressConfirm}
            confirmAction={removeAddress}
            modalHeading="Delete Address"
            btnText="Delete"
            btnColor="primary"
          />
        </div>
      </div>
    </div>
  );
};

export default CompanyAddressCard;