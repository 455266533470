import { useMutation } from "@apollo/client";
import { FC, useContext, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Select from 'react-select';
import { toast } from "react-toastify";
import { Col, Form, Input, Label, ModalHeader, Row, Spinner } from "reactstrap";
import CustomButton from "../../components/layout/CustomButton";
import { customSelectStyleNew } from "../../components/styled/customSelect";
import { AppContext } from "../../context";
import { CSVUploadFormType, UploadFormProps } from "../../interfaces";
import { downloadSignedUrlFile } from "../../utils/commonFn";
import { ADMIN_IMPORT_SOURCE, CSV_IMPORTS_HEADER, CSV_IMPORTS_SAMPLE_FILES, IMPORT_SOURCE, bucketFolders } from "../../utils/constant";
import { updateFileName, uploadFile, validateCSVbyHeaders } from "../../utils/helper";
import DragDropFile from "../candidate/candidate-components/DragDropFile";
import { CREATE_DATA_CSV_IMPORTS } from "./gql";

const UploadForm: FC<UploadFormProps> = ({ children, refetch, toggle, adminImport }) => {
  const { theme } = useContext(AppContext);
  const [file, setFile] = useState<File>()
  const [fileError, setFileError] = useState<string>()
  const [uploading, setUploading] = useState<boolean>(false)
  const [downloading, setDownloading] = useState<boolean>(false)
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(true)
  const { control, handleSubmit, getValues, setValue, errors } = useForm<CSVUploadFormType>();
  const [createDataCsvImport, { loading: createLoader }] = useMutation(CREATE_DATA_CSV_IMPORTS)
  const createImport = async ({ sourceType, endDate, startDate }: CSVUploadFormType) => {
    try {
      setUploading(true)
      const upload = await uploadFile(
        new File([file!], updateFileName(file!.name), { type: file!.type }),
        bucketFolders.csv,
        false, "attachment"
      );
      setUploading(false)
      if (upload?.status === 200) {
        const csvImport = await createDataCsvImport({
          variables: {
            createDataCsvImportInput: {
              sourceType, startDate, endDate,
              fileUrl: upload?.source,
              fileName: updateFileName(file!.name),
              adminImport
            }
          }
        })
        if (csvImport?.data?.createDataCsvImport?.status === 201) {
          toast.success(csvImport?.data?.createDataCsvImport?.message)
        }
        refetch && refetch()
        toggle && toggle()
      }
    } catch (error) {
      setUploading(false)
      console.log(error)
      toast.error("Something went wrong!")
    }
  }

  const validateFile = (file: File) => {
    if (!getValues()?.sourceType) {
      setFileError("Please Select Source First")
    } else {
      const reader = new FileReader();
      reader.onload = (event) => {
        const result = event!.target!.result;
        const rows = String(result!).split('\n').map((row) => row.split(','));
        const headers = rows[0];

        const checkHeaders = validateCSVbyHeaders(CSV_IMPORTS_HEADER[`${getValues()?.sourceType}`], headers)
          || (getValues()?.sourceType === 'SEAM_LESS_ADMIN'
            && CSV_IMPORTS_HEADER['SEAM_LESS_ADMIN'].length === headers.length)
        setButtonDisabled(!checkHeaders)
        setFileError(checkHeaders ? "" : "Invalid File : File has missing columns")
        checkHeaders && setFile(file)
      }
      reader.readAsText(file);
    }
  }

  const handleSampleDownload = () => {
    if (getValues()?.sourceType) {
      setDownloading(true)
      downloadSignedUrlFile(CSV_IMPORTS_SAMPLE_FILES[`${getValues()?.sourceType}`], `${getValues()?.sourceType}.csv`, setDownloading)
    }
    else {
      toast.warning("Please select source first")
    }
  }

  return (
    <>
      <div className="d-flex justify-content-between mb-4">
        <ModalHeader className="pl-3">
          <Row className="align-items-center" >
            <div>Import CSV</div>
            {!adminImport && <button className="btn btn-sm btn-primary rounded-pill ml-3 "
              onClick={handleSampleDownload} style={{ fontSize: 13 }} >
              {downloading && <Spinner size="sm" style={{ color: "#eaa827" }} className="mr-2" />}
              Download CSV Sample
            </button>}
          </Row>
        </ModalHeader>
      </div>
      <Form>
        <Col className="p-0">
          <Col className="my-3 p-0">
            <p className="font-weight-bold">Source <span className="text-danger">*</span></p>
            <Controller
              control={control}
              name="sourceType"
              rules={{
                required: {
                  value: true, message: 'Source should be selected!',
                }
              }}
              render={
                ({ onChange }) => (
                  <Select
                    placeholder="Source"
                    value={adminImport ?
                      ADMIN_IMPORT_SOURCE.find(({ value }) => value === getValues()?.sourceType)
                      : IMPORT_SOURCE.find(({ value }) => value === getValues()?.sourceType)}
                    isSearchable={false}
                    options={adminImport ? ADMIN_IMPORT_SOURCE : IMPORT_SOURCE}
                    onChange={(item) => {
                      onChange(item!.value)
                      validateFile(file!)
                    }}
                    styles={{ ...customSelectStyleNew(theme) }}
                  />)}
            />
            <small className="text-danger">
              {errors?.sourceType?.message}
            </small>
          </Col>
          {
            getValues().sourceType === "OUTREACH" &&
            <>
              <Col className="my-3 p-0">
                <p className="font-weight-bold">Start Date <span className="text-danger">*</span></p>
                <Controller
                  name="startDate"
                  rules={{
                    required: {
                      value: getValues().sourceType === "OUTREACH",
                      message: 'Start date should be selected!'
                    }
                  }}
                  defaultValue={new Date().toISOString().substring(0, 10)}
                  control={control}
                  render={(field) => (
                    <Input
                      {...field}
                      type="date"
                      onChange={(e) => {
                        setValue("startDate", e.target.value)
                      }}
                      max={new Date().toISOString().substring(0, 10)}
                    />
                  )}
                />
                <small className="text-danger">
                  {errors?.startDate?.message}
                </small>
              </Col>

              <Col className="my-3 p-0">
                <p className="font-weight-bold">End Date <span className="text-danger">*</span></p>
                <Controller
                  name="endDate"
                  rules={{
                    required: {
                      value: getValues().sourceType === "OUTREACH",
                      message: 'End date should be selected!'
                    }
                  }}
                  defaultValue={new Date().toISOString().substring(0, 10)}
                  control={control}
                  render={(field) => (
                    <Input
                      {...field}
                      type="date"
                      onChange={(e) => {
                        setValue("endDate", e.target.value)
                      }}
                      max={new Date().toISOString().substring(0, 10)}
                    />
                  )}
                />
                <small className="text-danger">
                  {errors?.endDate?.message}
                </small>
              </Col>

            </>
          }
          <p className="font-weight-bold mb-2">Start by uploading your CSV file <span className="text-danger">*</span></p>
          <Label className={fileError ? "text-danger" : "my-2 font-weight-normal"}>{fileError ? fileError : file?.name && `File Name: ${file.name}`}</Label>
          <Controller
            control={control} name="file"
            rules={{
              required: {
                value: true, message: 'File Should be selected!'
              }
            }}
            render={
              ({ onChange }) => (
                <Col style={{ backgroundColor: "#F7FDFF" }}>
                  <DragDropFile onUpload={(file: File) => {
                    onChange(file.name)
                    validateFile(file)
                    setFile(file)
                  }} />
                </Col>)

            }
          />
          <small className="text-danger">
            {errors?.file?.message}
          </small>

          <div className="mt-3 d-flex w-40 align-self ml-auto">
            {children}
            <CustomButton
              className="ml-auto" disabled={buttonDisabled} loading={uploading || createLoader}
              buttonClick={handleSubmit(createImport)} buttonText="Upload File"
            />
          </div>
        </Col>
      </Form >
    </>
  )
}

export default UploadForm;