import TableComponent from "../../components/layout/TableComponent";
import AvatarImage from "../../assets/images/user-empty.svg";
import { formatDateUS } from "../../utils/commonFn";
const TABLE_HEADER = ["MEMBER", "EMAIL", "PHONE NO.", "JOINING DATE"];

interface TeamWidgetProps {
  teamMembers: Array<any>;
}

const TeamWidget = ({ teamMembers }: TeamWidgetProps) => {
  return (
    <div>
      <div className="section-header p-0">
        <h5 className="mb-2">
          {teamMembers && teamMembers[0]?.team?.name
            ? teamMembers[0]?.team?.name
            : "Team Widget"}
        </h5>
      </div>

      <div className="table-responsive">
        <TableComponent tableHeader={TABLE_HEADER}>
          <tbody>
            {teamMembers &&
              teamMembers.map((item: any, index: number) => (
                <tr key={index}>
                  <td>
                    <div className="avatar-profile">
                      <div className="avatar">
                        <img
                          src={item.user?.photo || AvatarImage}
                          alt="avatar"
                        />
                      </div>
                      {item.user?.fullName}
                    </div>
                  </td>
                  <td>
                    <a href={`mailto:${item.user?.email}`}>
                      {item.user?.email}
                    </a>
                  </td>
                  <td>
                    <a href={`tel:${item.user?.phone}`}>{item.user?.phone}</a>
                  </td>
                  <td>{item?.startAt ? formatDateUS(item?.startAt) : "--"}</td>
                </tr>
              ))}
          </tbody>
        </TableComponent>
      </div>
    </div>
  );
};

export default TeamWidget;
