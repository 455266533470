import { useCallback, useContext, useEffect, useState } from 'react';
import { Button, Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import '../../candidate.scss';
import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_INTERVIEW_EMAILS } from '../../gql';
import NoDataFound from '../../../../components/layout/NoDataFound';
import Pagination from '../../../../components/layout/Pagination';
import InputField from '../../../../components/layout/InputField';
import {
  APPLICANT_STATUS,
  jobApplicantStatusForCandidate,
  PAGE_LIMIT,
  VIEW_PLACEMENT_MANAGEMENT_ROUTE,
} from '../../../../utils/constant';
import { useHistory, useLocation } from 'react-router-dom';
import SEARCH_IMG from '../../../../assets/images/search.svg';
import JobApplicationDetail from './JobApplicationDetail';
import {
  GET_JOB_APPLICANTS_PIPELINE,
  UPDATE_APPLICANTS_STATUS,
  UPDATE_APPLICANT_UPDATE,
  UPDATE_JOB_APPLICANT_STATUS_TO_INTERVIEW,
} from '../../../job-order/gql';
import SubmissionViewDrawer from '../../application-flow/submission/SubmissionViewDrawer';
import ClientCandidateReasonModal from '../../../candidate-pipeline/ClientCandidateReasonModal';
import InterviewScheduleModal from '../../../candidate-pipeline/interviews/InterviewScheduleModal';
import InterviewViewDrawer from '../../application-flow/interview/InterviewViewDrawer';
import CandidateCalendarInviteModal from '../../../candidate-pipeline/interviews/calendar-invite/candidate/CandidateCalendarInviteModal';
import moment from 'moment';
import ClientCalendarInviteModal from '../../../candidate-pipeline/interviews/calendar-invite/client/ClientCalendarInviteModal';
import CreateEditOffer from '../../../candidate-pipeline/offers/CreateEditOffer';
import OfferViewDrawer from '../../application-flow/offers/OfferViewDrawer';
import ConfirmPopUp from '../../../../components/ConfirmPopUp';
import { toast } from 'react-toastify';
import { UPDATE_OFFER_STATUS, VERIFY_INTERVIEW } from '../../../candidate-pipeline/offers/gql';
import {
  DECREMENT_IN_COUNT,
  UPDATE_INTERVIEW_FLAG,
} from '../../../candidate-pipeline/interviews/gql';
import AsyncCompanyDropdown from '../../../../components/AsyncCompanySelect';
import Select from 'react-select';
import CustomButton from '../../../../components/layout/CustomButton';
import { AppContext } from '../../../../context';
import Loader from '../../../../components/layout/Loader';
import ReplacementModal from './ReplacementModal';

let time = 0;
//todo need to update the job applicants query for fetching same as pipeline job detail
const JobApplicationStatus = ({ candidate, isEdit = false, refetchCandidateOwner, ...props }: any) => {
  const history = useHistory();
  const { theme } = useContext(AppContext);
  const location = useLocation();
  const [getJobApplicants, { data, loading, refetch }] = useLazyQuery(
    GET_JOB_APPLICANTS_PIPELINE
  );
  const [updateApplicantStatus] = useMutation(UPDATE_APPLICANT_UPDATE);

  const [getInterviewEmails, { data: getInterviewEmailsData }] =
    useLazyQuery(GET_INTERVIEW_EMAILS);
  const [updateOfferStatusMutation] = useMutation(UPDATE_OFFER_STATUS);
  const [verifyInterview] = useMutation(VERIFY_INTERVIEW);
  const [updateApplicantsStatus] = useMutation(UPDATE_APPLICANTS_STATUS);
  const [updateJobApplicantStatusInterview] = useMutation(
    UPDATE_JOB_APPLICANT_STATUS_TO_INTERVIEW
  );

  const comingState: any = location.state;

  const page = parseInt('1');
  const [currentPage, setCurrentPage] = useState(page);
  const [limit, setLimit] = useState(5);
  const [candidateFilter, setCandidateFilter] = useState<Array<any>>([]);
  const [acountManagerPernmission, setAcountManagerPernmission] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useState<string>('');
  const [applicantDetail, setApplicantDetail] = useState<any>({});
  const [applicantDetailForPlacement, setApplicantDetailForPlacement] = useState<any>({});
  const [rejectDetail, setRejectDetail] = useState<any>({});
  const [submissionViewToggle, setSubmissionViewToggle] =
    useState<boolean>(false);
  const [notesModal, setNotesModal] = useState<boolean>(false);
  const [firstInterview, setFirstInterview] = useState<boolean>(false);
  const [backToInterview, setBackToInterview] = useState<boolean>(false);
  const [candidateInterviewEmailData, setCandidateInterviewEmailData] =
    useState({});
  const [clientInterviewEmailData, setClientInterviewEmailData] = useState({});
  const [latestInterviewData, setLatestInterviewData] = useState<any>(null);
  const [interviewScheduleModal, setInterviewScheduleModal] =
    useState<boolean>(false);
  const [interviewDetailToggle, setInterviewDetailToggle] =
    useState<boolean>(false);
  const [calendarInviteModal, setCalendarInviteModal] =
    useState<boolean>(false);
  const [clientCalendarInviteModal, setClientCalendarInviteModal] =
    useState<boolean>(false);
  const [offerModal, setOfferModal] = useState<boolean>(false);
  const [offerDrawerToggle, setOfferDrawerToggle] = useState<boolean>(false);
  const [editOfferToggle, setEditOfferToggle] = useState<boolean>(false);
  const [selectedCompany, setSelectedCompany] = useState<any>();
  const [counterOfferToggle, setCounterOfferToggle] = useState<boolean>(false);
  const [counterDecrementInPendingAction] = useMutation(DECREMENT_IN_COUNT);
  const [updateFlag] = useMutation(UPDATE_INTERVIEW_FLAG);
  const [latestOffer, setLatestOffer] = useState<any>(null);
  const [actionData, setActionData] = useState<any>([]);
  const [confirmBox, setConfirmBox] = useState(false);

  const [currentBulkAction, setCurrentBulkAction] = useState('');
  const [replacementModalToggle, setReplacementModalToggle] = useState<boolean>(false);
  const [placementId, setPlacementId] = useState<string>('');

  const updateStatusToInterview = async (id: string) => {
    const res = await updateJobApplicantStatusInterview({
      variables: { id },
    });

    if (res?.data) {
      toast.success('The job app status is set to Interviewed');
    }
  };


  const actionHandler = (data, type) => {
    setApplicantDetail({ ...data, candidate, type });
    switch (type) {
      case 'View Submission Detail':
        setSubmissionViewToggle(true);
        break;

      case 'Client Decline':
        setRejectDetail({ ...data, type });
        setNotesModal(!notesModal);
        break;

      case 'Candidate Decline':
        setRejectDetail({ ...data, type });
        setNotesModal(!notesModal);
        break;
      case 'Schedule First Interview':
        interviewSpecificDetail({ ...data, type });
        setFirstInterview(true);
        setInterviewScheduleModal(!interviewScheduleModal);
        break;
      case 'View Interview Details':
        interviewSpecificDetail({ ...data, type });
        getLatestInterview({ ...data, type });
        setInterviewDetailToggle(!interviewDetailToggle);
        break;
      case 'Schedule Next Interview':
        interviewSpecificDetail({ ...data, type });
        setFirstInterview(false);
        setInterviewScheduleModal(!interviewScheduleModal);
        break;

      case 'Back To Interview':
        updateStatusToInterview(data?.id)
        refetch()
        break;

      case 'Send Candidate Invite':
        getLatestInterview({ ...data, type });
        setCalendarInviteModal(!calendarInviteModal);
        break;
      case 'Send Client Invite':
        getLatestInterview({ ...data, type });
        setClientCalendarInviteModal(!clientCalendarInviteModal);
        break;
      case 'Clear Pending Client Action':
        clientFlag({ data, clientInviteFlag: true });
        break;
      case 'Clear Pending Candidate Action':
        clientFlag({ data, candidateInviteFlag: true });
        break;
      case 'Convert to Offer':
        latestOfferFn({ ...data, type });
        getLatestInterview({ ...data, type });
        setOfferModal(!offerModal);
        break;
      case 'View Offer Details':
        setOfferDrawerToggle(!offerDrawerToggle);
        break;
      case 'Move To Placement':
        updateOfferToPlacement();
        // setPlacementMoveToggle(!placementMoveToggle);
        break;
      case 'Update Offer':
        latestOfferFn({ ...data, type });
        setCounterOfferToggle(false);
        setEditOfferToggle(true);
        setOfferModal(!offerModal);
        break;
      case 'Counter Offer':
        latestOfferFn({ ...data, type });
        setEditOfferToggle(false);
        setCounterOfferToggle(!counterOfferToggle);
        setOfferModal(!offerModal);
        break;
      case 'Clear Pending Interviews':
        findPendingInterview({ ...data })
        break;

      default:
        setConfirmBox(!confirmBox);

        break;
    }
  };

  const findPendingInterview = (pendingInterview) => {
    let pendingInterviews = pendingInterview?.interviews.filter(interview => interview?.InterviewState === "PENDING").map(pendingInterview => pendingInterview?.id);
    veriyinterviews(pendingInterviews)
  }

  const veriyinterviews = async (pendingInterviewIds) => {
    let verifiedInterviewResponse = await verifyInterview({
      variables: { verifyInterviewInput: { interviewIds: pendingInterviewIds } },
    });
    if (verifiedInterviewResponse?.data?.verifyInterview?.status === 200) {
      refetch()
      setActionData([])
      toast.success('Successfully Updated!');

    }
  }
  const createStatusFilter = () => {
    return candidateFilter?.length
      ? candidateFilter
        ?.filter((item) => item?.value !== 'All')
        ?.map((item) => item?.value)
      : [];
  };

  const latestOfferFn = (data) => {
    let latest = data?.offers?.find((item) => !item?.locked);
    setLatestOffer(latest);
  };

  // this method is used for the convert the latest offer into offer
  const updateOfferToPlacement = useCallback(async (data?: { offers?: string, id?: string, replacementId?: string }, isReplacement: boolean = true) => {
    const latestOffer = applicantDetailForPlacement?.offers?.find((item) => !item?.locked);
    if (!latestOffer) {
      toast.warn("Placement offer is not locked")
      return
    };
    const response = await updateOfferStatusMutation({
      variables: {
        updatingOfferStatusInput: {
          id: latestOffer?.id,
          jobApplicantId: applicantDetailForPlacement?.id,
          status: 'MOVE_TO_PLACEMENT',
          replacementId: data?.replacementId,
          isReplacement,
        },
      },
    });

    if (response?.data) {
      const updatingOfferStatus = response?.data?.updatingOfferStatus;
      // status 202 for replacement workflow (ATS-1011)
      if (updatingOfferStatus?.status === 202) {
        setReplacementModalToggle(!replacementModalToggle);
        setPlacementId(updatingOfferStatus?.placementId);
      } else if (updatingOfferStatus?.status === 400) {
        toast.error('Replacement Days Must be a Number!');
      } else {
        toast.success('Offer status updated successfully');
        // supposing we are receiving an id of placement and view that placement
        if (updatingOfferStatus?.placementId) {
          history.push({
            pathname: `${VIEW_PLACEMENT_MANAGEMENT_ROUTE}/${updatingOfferStatus?.placementId}`,
          });
        }
      }
    }
  }, [applicantDetailForPlacement, updateOfferStatusMutation, replacementModalToggle, history]);

  const getLatestInterview = (data) => {
    let interview =
      data.interviews?.length && data.interviews?.find((item) => !item?.locked);
    if (interview) {
      let interviewDate = {
        interviewDateTime: moment(interview?.interviewDateTime)
          .utcOffset(interview?.timeZoneId)
          .format('MM/DD/YYYY'),
        time: moment(interview?.interviewDateTime)
          .utcOffset(interview?.timeZoneId)
          .format('hh:mm A'),
      };
      setLatestInterviewData({ ...interview, ...interviewDate });
    } else {
      setLatestInterviewData(null);
    }
  };

  const interviewSpecificDetail = (data) => {
    const actualJobLocation = data?.JobOrder?.companyLocation
      ? ['US', null, undefined, ''].includes(data?.JobOrder?.companyLocation?.country) ? `${data?.JobOrder?.companyLocation?.streetAddress}, ${data?.JobOrder?.companyLocation?.city}, ${data?.JobOrder?.companyLocation?.state}, ${data?.JobOrder?.companyLocation?.zipCode}` : `${data?.JobOrder?.companyLocation?.streetAddress}, ${data?.JobOrder?.companyLocation?.city}, ${data?.JobOrder?.companyLocation?.zipCode}, ${data?.JobOrder?.companyLocation?.country}`
      : '--';
    setApplicantDetail({
      ...data,
      jobTimezone: data?.JobOrder?.timezone,
      jobTimezoneId: data?.JobOrder?.timezoneId,
      jobDstOffset: data?.JobOrder?.dstOffset,
      jobOrderId: data?.JobOrder?.id,
      candidateId: candidate?.id,
      companyId: data?.JobOrder?.company?.id,
      jobApplicantId: data?.id,
      company: data?.JobOrder?.company?.name || '',
      jobTitle: data?.JobOrder?.jobTitle || '',
      name: candidate?.fullName || '',
      jobLocation: actualJobLocation,
      workplaceTypes: data?.JobOrder?.workplaceTypes

    });
  };

  const clientFlag = async (value) => {
    let interview = value?.data?.interviews?.find((item) => !item?.locked);

    if (interview) {
      const { data } = await counterDecrementInPendingAction({
        variables: { category: 'Interview' },
      });
      let UpdateInterviewFlagInput: any = {};
      if (value?.candidateInviteFlag) {
        UpdateInterviewFlagInput = {
          interviewId: interview?.id,
          candidateInviteFlag: value?.candidateInviteFlag,
        };
      } else if (value?.clientInviteFlag) {
        UpdateInterviewFlagInput = {
          interviewId: interview?.id,
          clientInviteFlag: value?.clientInviteFlag,
        };
      }

      await updateFlag({ variables: { UpdateInterviewFlagInput } });

      if (data) {
        const { status } = data.counterDecrementInPendingAction;
        if (status === 200) {
          toast.success('Updated Successfully!');
          // searchControl('');
        }
      }
    }
  };

  const fetchJobApplicant = () => {
    getJobApplicants({
      variables: {
        limit,
        page: currentPage,
        id: candidate?.id,
        status: createStatusFilter()?.length ? createStatusFilter() : ['All'],
        searchParams: searchParams,
        companyId: selectedCompany?.value,
      },
    });
  };

  const searchControl = (value: string) => {
    setSearchParams(value);
    setCurrentPage(1);
    setLimit(25);
    if (!value || value?.length > 2) {
      window.clearTimeout(time);
      time = window.setTimeout(() => {
        getJobApplicants({
          variables: {
            limit,
            page: currentPage,
            id: candidate?.id,
            status: createStatusFilter()?.length
              ? createStatusFilter()
              : ['All'],
            searchParams: value,
            companyId: selectedCompany?.value,
          },
        });
      }, 1000);
    }
  };

  useEffect(() => {
    if (getInterviewEmailsData?.getInterviewEmails?.interviewEmails?.length) {
      const clientInterviewEmail =
        getInterviewEmailsData?.getInterviewEmails?.interviewEmails?.find(
          (item: { isToClient: boolean }) => item?.isToClient
        );

      const candidateInterviewEmails =
        getInterviewEmailsData?.getInterviewEmails?.interviewEmails?.find(
          (item: { isToClient: boolean }) => !item?.isToClient
        );

      if (candidateInterviewEmails) {
        setCandidateInterviewEmailData(candidateInterviewEmails);
      }

      if (clientInterviewEmail) {
        let newObj: any = { ...clientInterviewEmail };
        newObj.attachingFromCandidateEmails = false;
        setClientInterviewEmailData(newObj);
      } else {
        let newObj: any = { ...candidateInterviewEmails };
        newObj.attachingFromCandidateEmails = true;
        setClientInterviewEmailData(newObj);
      }
    }

    // eslint-disable-next-line
  }, [getInterviewEmailsData]);

  useEffect(() => {
    if (latestInterviewData) {
      getInterviewEmails({
        variables: { interviewId: latestInterviewData?.id },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [latestInterviewData]);

  useEffect(() => {
    let applicantId = new URLSearchParams(location.search).get('applicantId');
    setSearchParams(applicantId || '');
    if (candidate?.id) {
      getJobApplicants({
        variables: {
          limit,
          page: currentPage,
          id: candidate?.id,
          status: createStatusFilter()?.length ? createStatusFilter() : ['All'],
          searchParams: searchParams || applicantId || '',
          // applicantId: applicantId,
          companyId: selectedCompany?.value,
        },
      });
    }

    // eslint-disable-next-line
  }, [
    candidateFilter,
    limit,
    currentPage,
    comingState,
    selectedCompany?.value,
  ]);

  useEffect(() => {
    let filter = new URLSearchParams(location?.search).get('candidateFilter');
    if (filter) setCandidateFilter([{ label: filter, value: filter }]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // following if conditinon is for ATS-1011 : 
    // bug for state not being set on initial render, which is required in updateOfferToPlacement api call 
    if (data?.getApplicants) {
      setApplicantDetailForPlacement(data?.getApplicants?.jobApplicants[0]);
    }
    let applicantId = new URLSearchParams(location.search)?.get('applicantId');

    if (
      applicantId &&
      data?.getApplicants &&
      data?.getApplicants?.count === 1
    ) {
      let applicant = data?.getApplicants?.jobApplicants[0];

      switch (applicant?.JobAppStatus) {
        case 'SUBMITTED':
          actionHandler(applicant, 'View Submission Detail');
          break;
        case 'INTERVIEWED':
          actionHandler(applicant, 'View Interview Details');
          break;
        case 'OFFERED':
          actionHandler(applicant, 'View Offer Details');
          break;
        default:
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.getApplicants]);

  const handleBulkAction = async () => {
    if (currentBulkAction !== 'Clear Pending Interviews') {
      const objectTypeData = actionData?.filter((data) => typeof data !== 'string')
      const applicantIds = objectTypeData.map((data) => {
        return data.userId;
      });
      let res = await updateApplicantsStatus({
        variables: { data: { applicantIds, status: currentBulkAction } },
      });
      if (res?.data) {
        // setConfirmBox(false);
        toast.success('Successfully Updated!');
        searchControl('');
      }
      setCurrentBulkAction('');
      searchControl('');
    } else {
      const pendingInterviewIds: [string] = actionData?.filter((data) => typeof data === 'string')
      let verifiedInterviewResponse = await verifyInterview({
        variables: { verifyInterviewInput: { interviewIds: pendingInterviewIds } },
      });
      if (verifiedInterviewResponse?.data?.verifyInterview?.status === 200) {
        fetchJobApplicant()
        toast.success('Successfully Updated!');
      }
      setCurrentBulkAction('');
      searchControl('');
    }

    setActionData([]);
  };

  const changeApplicantStatus = async () => {
    let { id, type } = applicantDetail;
    let res = await updateApplicantStatus({
      variables: { applicantId: id, status: type === "Submit_Again" ? "SUBMITTED" : type },
    });
    if (res?.data) {
      setConfirmBox(false);
      toast.success('Successfully Updated!');
      searchControl('');
    }
  };

  const customStyles = {
    control: (styles, provided) => ({
      ...styles,
      width: `${props.width} !important`,
      background: theme === 'light' ? provided.background : '#282828',
      borderColor: theme === 'light' ? '#ced4da' : '#282828',
      color: theme === 'light' ? provided.background : '#d9d9d9',
      fontSize: 12,
      '&:hover': {
        borderColor: theme === 'light' ? '#ced4da' : '#282828',
        color: theme === 'light' ? provided.background : '#d9d9d9',
      },
    }),
    menu: (provided) => ({
      ...provided,
      background: theme === 'light' ? provided.background : '#282828',
      zIndex: props?.zIndex || 999999,
      color: theme === 'light' ? provided.background : '#d9d9d9',
    }),
    option: (provided) => ({
      ...provided,
      fontSize: 12,
      background: theme === 'light' ? provided.background : '#363636',
      '&:hover': {
        background: theme === 'light' ? provided.background : '#474444',
        color: theme === 'light' ? provided.background : '#d9d9d9',
      },
    }),
    container: (provided, state) => ({
      ...provided,
      fontSize: 12,
      minWidth: 200,
      maxWidth: 'auto',
    }),
  };

  return (
    <div className="jobapp-status-wrap">
      <div
        className="job-status-border flex-sm-row flex-column mb-0 pb-2 mb-2 search-filter
      primaryHeaderSpacing justify-content-start mb-3 align-items-start"
      >
        <div className="filter-company-search">
          <InputGroup className="mb-0">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <img src={SEARCH_IMG} alt="search" />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              type="text"
              placeholder="Search By Job title "
              value={searchParams}
              onChange={(e) => {
                setSearchParams(e.target.value);
                searchControl(e.target.value);
              }}
              className="search-by-company"
            />
          </InputGroup>
        </div>

        <div className="ml-3 mb-2">
          <AsyncCompanyDropdown
            width={'12rem'}
            value={selectedCompany || null}
            placeholder="Filter By Company"
            onChange={(value) => {
              setSelectedCompany(value);
              setActionData([]);
            }}
          />
        </div>

        <div className="pl-sm-3 jobapp-category-dropdown mt-md-0 mt-3">
          <Select
            isMulti
            isSearchable
            isClearable
            styles={customStyles}
            options={jobApplicantStatusForCandidate}
            value={candidateFilter?.length ? candidateFilter : null}
            onChange={(val: any) => {
              setCandidateFilter([...val]);
              setActionData([]);
              history?.replace({});
            }}
          />
        </div>

        <div>
          <button
            className="buttonGenericStyle filledButton ml-md-2 mt-3 mt-md-0"
            onClick={() => {
              setSearchParams('');
              setActionData([]);
              setSelectedCompany(null);
              setCandidateFilter([]);
              history.push(`${location.pathname}?activeTab=8`);
              getJobApplicants({
                variables: {
                  limit,
                  page: currentPage,
                  id: candidate?.id,
                  status: 'All',
                  searchParams: '',
                  companyId: null,
                },
              });
            }}
          >
            Clear
          </button>
        </div>
      </div>

      {!!actionData?.length && (
        <div className="sticky-action-container sticky-extra my-2 border-top-0" style={{ position: 'initial' }}>
          <div className="d-selected-num bulk_actions_label">Bulk Actions:</div>
          <div className="d-selected-num">{actionData?.length} Selected</div>
          <div className="sticky-action-btns">
            <Button
              className="m-1 d-block px-3 py-1 btn-sm border-0 buttonGenericStyle filledButton"
              onClick={() => veriyinterviews(actionData)}
              disabled={!acountManagerPernmission}
            >
              Clear Pending Interviews
            </Button>

            <InputField
              style={{ minWidth: 'max-content' }}
              className="save-changes"
              inputtype="select"
              selectItems={APPLICANT_STATUS}
              inputid="currentBulkAction"
              disabled={actionData?.length ? true : false}
              inputValue={currentBulkAction}
              placeholder="Status Update"
              inputMethod={(value) => {
                let val = value === 'default' ? '' : value;
                setCurrentBulkAction(val);
              }}
            />

            <CustomButton
              buttonText="Update"
              buttonType="button"
              buttonColor="primary"
              disabled={currentBulkAction && actionData?.length ? false : true}
              className="save-changes ml-3 mr-2"
              buttonClick={handleBulkAction}
            />
          </div>
        </div>
      )}

      {data?.getApplicants?.jobApplicants?.map((item) => (
        <div key={item?.id}>
          <JobApplicationDetail
            data={{ ...item, candidate: candidate }}
            actionHandler={actionHandler}
            setActionData={setActionData}
            actionData={actionData}
            location={location}
            isEdit={isEdit}
            setAcountManagerPernmission={setAcountManagerPernmission}
          />
        </div>
      ))}

      {!data?.getApplicants?.jobApplicants?.length && !loading && (
        <div className="empty-table-td">
          <NoDataFound text="No Application Status Found!" />
        </div>
      )}

      {loading && !data?.getApplicants?.jobApplicants?.length && (
        <div
          style={{ maxHeight: '50vh' }}
          className="d-flex align-items-center justify-content-center"
        >
          <Loader />
        </div>
      )}

      {data?.getApplicants?.jobApplicants?.length ? (
        <div className="users-pagination">
          <Pagination
            onPageChange={(pageClicked: number) => {
              setCurrentPage(pageClicked);
            }}
            pageCount={Math.ceil(data?.getApplicants?.count / limit)}
            currentPage={currentPage}
          />

          <div className="d-flex align-items-center">
            <div className="pagination-display paginationItemsSelect">
              <p className="m-0">
                {(currentPage - 1) * limit + 1} to{' '}
                {Math.min(currentPage * limit, data?.getApplicants?.count)} of{' '}
                {data?.getApplicants?.count}
              </p>

              <div className="divider"></div>

              <label>Display</label>

              <InputField
                label=""
                inputtype="select"
                selectItems={PAGE_LIMIT.filter((item) => item.value !== limit)}
                inputid="limit"
                placeholder={limit.toString()}
                inputMethod={(value: string) => {
                  setCurrentPage(1);
                  setLimit(parseInt(value));
                }}
              />
            </div>
          </div>
        </div>
      ) : null}

      {/* submission view drawer  */}
      {submissionViewToggle && (
        <SubmissionViewDrawer
          toggle={() => setSubmissionViewToggle(!submissionViewToggle)}
          isOpen={submissionViewToggle}
          applicantData={applicantDetail}
          setCandidateFilter={setCandidateFilter}
          refetch={() => fetchJobApplicant()}
        />
      )}

      {/* this drawer is used for the schedule interview for the first time and for schedule interview for the next */}
      {applicantDetail && interviewScheduleModal && (
        <InterviewScheduleModal
          accountManagerAndCandidateRecruiterSame={
            applicantDetail?.JobOrder?.company?.companyOwner?.owner?.id ===
            applicantDetail?.candidate?.recruiterOwner?.id
          }
          setCandidateFilter={setCandidateFilter}
          firstInterview={firstInterview}
          backToInterview={backToInterview}
          candidateId={candidate?.id}
          interviewScheduleModal={interviewScheduleModal}
          applicantData={applicantDetail}
          interviewScheduleModalToggle={() =>
            setInterviewScheduleModal(!interviewScheduleModal)
          }
          refetch={() => fetchJobApplicant()}
          refetchCandidateOwner={refetchCandidateOwner}
          closeModel={() => setInterviewScheduleModal(false)}
        />
      )}

      {/* this drawer is used for client calender decline  */}
      {notesModal && (
        <ClientCandidateReasonModal
          refetch={() => {
            searchControl('');
          }}
          modal={notesModal}
          toggle={() => {
            setNotesModal(!notesModal);
          }}
          rejectDetail={rejectDetail}
        />
      )}

      {/* this drawer is used for the view interview details */}
      {interviewDetailToggle && (
        <InterviewViewDrawer
          toggle={() => setInterviewDetailToggle(!interviewDetailToggle)}
          isOpen={interviewDetailToggle}
          latestInterview={latestInterviewData}
          applicantData={applicantDetail}
          refetch={() => fetchJobApplicant()}
          setStatusFilter={setCandidateFilter}
        />
      )}

      {/* this piece of code is used for sending the candidate calender invite */}
      {calendarInviteModal && (
        <CandidateCalendarInviteModal
          reFetchInterviewEmail={() => {
            getInterviewEmails({
              variables: { interviewId: latestInterviewData?.id },
            });
          }}
          fromInterviewDetailCard={true}
          jobId={applicantDetail?.JobOrder?.id}
          reFetchInterviews={() => fetchJobApplicant()}
          updateClientCalendarModal={false}
          candidateInterviewEmailData={candidateInterviewEmailData}
          fromEdit={
            getInterviewEmailsData?.getInterviewEmails?.interviewEmails?.find(
              (item: { isToClient: boolean }) => !item?.isToClient
            )
              ? true
              : false
          }
          calendarPrefilledValues={latestInterviewData}
          candidateId={applicantDetail?.candidate?.id}
          name={applicantDetail?.candidate?.fullName}
          type={latestInterviewData?.interviewType}
          interviewId={latestInterviewData?.id}
          calendarInviteModal={calendarInviteModal}
          calendarInviteModalToggle={() =>
            setCalendarInviteModal(!calendarInviteModal)
          }
        />
      )}

      {/* client calender invite drawer serve from here */}
      {clientCalendarInviteModal && (
        <ClientCalendarInviteModal
          reFetchInterviewEmail={() => {
            getInterviewEmails({
              variables: { interviewId: latestInterviewData?.id },
            });
          }}
          skipIt={false}
          from={'interview-card'}
          reFetchInterviews={() => fetchJobApplicant()}
          jobApplicantId={applicantDetail?.id}
          clientInterviewEmailData={clientInterviewEmailData}
          clientDefaultInviteFromInterview={latestInterviewData}
          fromEdit={
            getInterviewEmailsData?.getInterviewEmails?.interviewEmails?.find(
              (item: { isToClient: boolean }) => item?.isToClient
            )
              ? true
              : false
          }
          jobId={applicantDetail?.JobOrder?.id}
          companyId={applicantDetail?.JobOrder?.company?.id}
          candidateId={applicantDetail?.candidate?.id}
          name={applicantDetail?.candidate?.fullName}
          type={latestInterviewData?.interviewType}
          interviewId={latestInterviewData?.id}
          clientCalendarInviteModal={clientCalendarInviteModal}
          clientCalendarInviteModalToggle={() =>
            setClientCalendarInviteModal(!clientCalendarInviteModal)
          }
        />
      )}

      {/* this is for the create, edit and create counter offer the offer */}
      {offerModal && (
        <CreateEditOffer
          applicantData={applicantDetail}
          setCandidateFilter={setCandidateFilter}
          counterOffer={counterOfferToggle}
          fromUpdate={editOfferToggle}
          latestOffer={latestOffer}
          interviewId={latestInterviewData?.id}
          setOfferToggle={() => setOfferModal(!offerModal)}
          offerToggle={offerModal}
          refetch={() => fetchJobApplicant()}
          parentToggle={() => { }}
        />
      )}

      {/* this is used for the view offer detail drawer */}
      {offerDrawerToggle && (
        <OfferViewDrawer
          applicantData={applicantDetail}
          toggle={() => setOfferDrawerToggle(!offerDrawerToggle)}
          isOpen={offerDrawerToggle}
          refetch={() => fetchJobApplicant()}
          setCandidateFilter={setCandidateFilter}
        />
      )}

      {/* this pop up is used for the replacement confirmation modal*/}
      {replacementModalToggle && (
        <ReplacementModal
          jobApplicants={false}
          isOpen={replacementModalToggle}
          toggle={() => setReplacementModalToggle(!replacementModalToggle)}
          placementId={placementId}
          updateOfferToPlacement={updateOfferToPlacement}
        />
      )}


      {/* this pop up is used for the convert applicant Offer to Placement */}
      {/* <ConfirmPopUp
        confirmText="Are you sure you want to make a placement?"
        isOpen={placementMoveToggle}
        toggle={() => {
          setPlacementMoveToggle(!placementMoveToggle);
        }}
        confirmAction={updateOfferToPlacement}
        modalHeading="Move to Placement"
        btnText="Yes, Move"
        btnColor="primary"
        className="for-modal-styling"
      /> */}

      <ConfirmPopUp
        confirmText="Are you sure you want to change this applicant status?"
        isOpen={confirmBox}
        toggle={setConfirmBox}
        confirmAction={changeApplicantStatus}
        modalHeading="Applicant Status"
        btnText="Confirm"
        btnColor="primary"
      />
    </div>
  );
};

export default JobApplicationStatus;
