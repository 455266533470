import { useLazyQuery, useMutation } from "@apollo/client";
import { useCallback, useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import GoBack from "../../components/GoBack";
import CustomButton from "../../components/layout/CustomButton";
import InputField from "../../components/layout/InputField";
import { CREATE_LEGAL_AGREEMENT } from "./gql";
import uploadImg from "../../assets/images/upload.png";
import AsyncSelect from "react-select/async";
import { customSelectDropDown } from "../../components/styled/customSelect";
import {
  IComingState,
  ICompanyContactErrors,
  ICompanyErrors,
  ICreateCompany,
} from "./interfaces";
import { companyAndAddressErrors } from "./utils/companyErrors";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { companyContactAndEmailErrors } from "./utils/contactErrors";
import { RouteComponentProps, useLocation, withRouter } from "react-router-dom";
import CreateContactModal from "../company/company-components/CreateContactModal";
import client from "../../apollo";
import { pushInHistory, uploadImage } from "../../utils/helper";
import { AppContext } from "../../context";
import { FETCH_COMPANIES_OWNER_BASED, GET_COMPANY_BY_ID, GET_OWNER_SHAREES } from "../company/gql";
import { AGREEMENTS_ROUTE, bucketFolders, CONTACT, LEGAL_CONTRACT, LEGAL_DIRECT_HIRE, UPDATE_COMPANY_ROUTE } from "../../utils/constant";
import { FETCH_ALL_ACTIVE_RECRUITERS } from "../admin/gql";
import { RecruiterType } from "../../interfaces";
import Select from 'react-select';

let time = 0;

const CreateLegacyAgreement = (
  { history }: RouteComponentProps
): JSX.Element => {
  // States
  const { theme } = useContext(AppContext);
  const [, setOwnershipError] = useState<string>("");
  const [currentUserCompanies, setCurrentUserCompanies] = useState<any>([]);
  const [selectedCompany, setSelectedCompany] = useState<any>({});
  const [selectedCompanyContact, setSelectedCompanyContact] = useState<any>({});
  const [selectedContactEmail, setSelectedContactEmail] = useState<any>({});
  const [selectedAddress, setSelectedAddress] = useState<any>({});
  const [agreementType, setAgreementType] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState("");
  const [file, setFile] = useState<any>(null);
  const [hasOwner, setHasOwner] = useState<boolean>(false);
  const [fileName, setFileName] = useState<any>("");
  const [companyErrors, setCompanyErrors] = useState<Array<ICompanyErrors>>([]);
  const [recruiters, setRecruiters] = useState<RecruiterType[]>([])
  const [companyContactErrors, setCompanyContactErrors] = useState<
    Array<ICompanyContactErrors>
  >([]);
  const [contactsSelectedCompany, setContactsSelectedCompany] = useState<
    Array<any>
  >([]);
  const [contactToggle, setContactToggle] = useState(false);
  const toggle = () => setContactToggle(!contactToggle);
  const location = useLocation<IComingState>();
  const [signedDate, setSignedDate] = useState<string>(new Date().toISOString().substring(0, 10));
  const [agreementCreator, setAgreementCreator] = useState<boolean>(false)


  const { errors, handleSubmit, register, control, reset, setValue, getValues, watch } =
    useForm();
  const { companyId, userId } = watch()

  document.title = "Create Legacy Agreement";
  const [_createAgreement, { loading: createAgreementLoading }] = useMutation(
    CREATE_LEGAL_AGREEMENT
  );
  const [fetchRecruiters] = useLazyQuery(
    FETCH_ALL_ACTIVE_RECRUITERS,
    {
      onCompleted(data) {
        const { fetchingAllActiveRecruiters: { users } } = data;
        setRecruiters(users)
      }
    }
  );
  const [getOwnerAndSharees] =
    useLazyQuery(GET_OWNER_SHAREES,
      {
        onCompleted(data) {
          const { getShareesAndOwner } = data

          setHasOwner(!!getShareesAndOwner.length)
        },
      });

  const [uploadLogin, setUploadLogin] = useState(false);

  const createAgreement = async (values: ICreateCompany) => {
    try {
      if (file) {
        setUploadLogin(true);
        let response = await uploadImage(
          file,
          bucketFolders.agreement,
          false,
          false,
          false
        );
        setUploadLogin(false);
        if (response.success) {
          const { source } = response;
          const companyId = selectedCompany.id;
          const companyContactId = values.companyContactId?.value?.id;
          const commissionPercentage = parseFloat(values.commissionPercentage);
          const payableDays = parseInt(values.payableDays);
          const replacementDays = parseInt(values.replacementDays);
          const finalValues = {
            ...values,
            companyId,
            signedAt: signedDate,
            agreementType: agreementType,
            commissionPercentage,
            companyContactId,
            payableDays,
            replacementDays,
            documentUri: source,
          };

          const agreementData = await _createAgreement({
            variables: { agreementInput: { ...finalValues } },
          });
          if (agreementData?.data) {
            toast.success("Agreement successfully created!");
            history.push(AGREEMENTS_ROUTE);
          }
        }
      }
    } catch (error) {
      toast.error("Error while uploading!");
      setUploadLogin(false);
    }
  };

  const agreementTypeHandler = (agreementTypeHandler: string) => {
    switch (agreementTypeHandler) {
      case LEGAL_DIRECT_HIRE:
        setAgreementType(agreementTypeHandler);
        break;
      case LEGAL_CONTRACT:
        setAgreementType(agreementTypeHandler);
        break;
      default:
        break;
    }
  };

  const companyFilter = (companyId: string) => {
    if (companyId) {
      setSelectedCompany({});
      setSelectedAddress({});

      const currentUserCompaniesFilter = currentUserCompanies?.find(
        (company) => company?.id === companyId
      );

      setContactsSelectedCompany(currentUserCompaniesFilter?.companyContacts?.map((company) => {
        return { label: company?.firstName + " " + company?.lastName, value: company }
      }));

      if (
        currentUserCompaniesFilter &&
        Object.keys(currentUserCompaniesFilter)?.length
      ) {
        setSelectedCompany(currentUserCompaniesFilter);

        const currentUserCompaniesAddressFilter =
          currentUserCompaniesFilter?.address?.find(
            (address) => address.isPrimary === true
          );

        if (
          currentUserCompaniesAddressFilter &&
          Object.keys(currentUserCompaniesAddressFilter)?.length
        ) {
          setSelectedAddress(currentUserCompaniesAddressFilter);
        }

        const errors = companyAndAddressErrors(currentUserCompaniesFilter);

        const contactErrorExists = errors?.find(
          (contactError) => contactError.type === "contact"
        );

        if (contactErrorExists) {
          setSelectedCompanyContact({});
        }

        setCompanyErrors(errors);
      }
    } else {
      setCompanyErrors([]);
      setSelectedCompany({});
      setSelectedAddress({});
    }
  };

  const companyContactFilter = (companyContactId: string) => {
    if (companyContactId) {
      setSelectedCompanyContact({});
      setSelectedContactEmail({});

      let contact = contactsSelectedCompany?.find(
        (contact) => contact && contact?.id === companyContactId
      );

      if (contact) {
        setSelectedCompanyContact(contact);

        const email = contact?.contactEmail?.find(
          (email) => email.isPrimary === true
        );

        if (email) {
          setSelectedContactEmail(email);
        } else {
          setSelectedContactEmail({});
        }

        const errors = companyContactAndEmailErrors(contact, email);

        setCompanyContactErrors(errors);
      }
    } else {
      setSelectedCompanyContact({});
      setCompanyContactErrors([]);
      setSelectedContactEmail({});
    }
  };

  const _filterMyCompanies = async (value: string) => {
    const { data, errors } = await client.query({
      query: FETCH_COMPANIES_OWNER_BASED,
      variables: {
        limit: 25,
        page: 1,
        searchText: value || "",
        ownerIdFlag: true,
      },
    });
    if (data?.fetchCompaniesOfCurrentUser?.length) {
      setCurrentUserCompanies(data?.fetchCompaniesOfCurrentUser);
      let currentCompany;
      if (selectedCompany?.id) {
        currentCompany = data?.fetchCompaniesOfCurrentUser?.find(
          (company) => company?.id === selectedCompany?.id
        );
        if (!currentCompany) {
          let { data } = await client.query({
            query: GET_COMPANY_BY_ID,
            variables: {
              companyId: selectedCompany?.id,
            },
          });
          currentCompany = data?.getCompany;
        }
      }

      if (selectedCompany?.id && currentCompany) {
        setContactsSelectedCompany(currentCompany?.companyContacts?.map((company) => {
          return { label: company?.firstName + " " + company?.lastName, value: company }
        }));
        const companyError = companyAndAddressErrors(currentCompany);
        const contactErrorExists = companyError?.find(
          (contactError) => contactError?.type === CONTACT
        );

        if (contactErrorExists) {
          setSelectedCompanyContact({});
        }
        setCompanyErrors(companyError);
      }
    }
    if (errors) {
      setOwnershipError(errors[0].message);
    }
    return data && data.fetchCompaniesOfCurrentUser
      ? data?.fetchCompaniesOfCurrentUser?.map((item) => ({
        value: item.id,
        label: item?.name,
      }))
      : [];
  };

  const promiseOptions = (value: any): any =>
    new Promise((resolve) => {
      if (!value) {
        resolve(_filterMyCompanies(value));
      } else window.clearTimeout(time);
      time = window.setTimeout(() => {
        resolve(_filterMyCompanies(value));
      }, 1000);
    });

  useEffect(() => {
    let stateData: any = location?.state;

    if (stateData?.companyId && currentUserCompanies) {
      companyFilter(stateData?.companyId?.value);
      companyContactFilter(stateData?.companyContactId);
      agreementTypeHandler(stateData?.agreementType);
      reset({ ...stateData });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, currentUserCompanies, contactsSelectedCompany]);

  const fetchActiveRecruiters = useCallback(async () => {

    await fetchRecruiters({ variables: { limit: 25, page: 1 } })
  }, [fetchRecruiters])

  const checkCompanyOwnership = useCallback(async () => {
    !!companyId && !!companyId?.value && getOwnerAndSharees({ variables: { id: companyId?.value } });
  }, [companyId, getOwnerAndSharees])

  useEffect(() => {
    checkCompanyOwnership()
  }, [checkCompanyOwnership, companyId?.value])

  useEffect(() => {
    fetchActiveRecruiters()
  }, [fetchActiveRecruiters])

  return (
    <div className="admin-tabs team-tabs">
      <GoBack url={AGREEMENTS_ROUTE} />
      <div className="mobileResponsiveFlexAlign primaryHeaderSpacing">
        <h5 className="m-0">Create Legacy Agreement</h5>
      </div>

      <div className="create-company">
        <Form onSubmit={handleSubmit(createAgreement)}>
          <div className="jobOrderRevamp">
            <Row>
              <Col xl="4" lg="4" md="6" sm="12">
                <div className="form-group mb-0">
                  <Controller
                    control={control}
                    name="companyId"
                    rules={{
                      required: {
                        value: true,
                        message: "Company should be selected!",
                      },
                    }}
                    render={({ onChange, ref, value }) => (
                      <AsyncSelect
                        cacheOptions
                        value={value}
                        isSearchable
                        isClearable
                        ref={ref}
                        styles={{ ...customSelectDropDown(theme) }}
                        defaultOptions
                        onChange={(selected: any) => {
                          onChange(selected);
                          companyFilter(selected?.value);
                          setValue("companyContactId", null)
                          setSelectedCompanyContact(null);
                          history.replace({
                            pathname: location.pathname,
                            state: null,
                          });
                        }}
                        loadOptions={promiseOptions}
                        placeholder="Select Company"
                      />
                    )}
                  />
                  <div className="d-flex justify-content-between">
                    <div>
                      <small className="text-danger">
                        {errors?.companyId?.message}
                      </small>
                      <ul
                        className="ml-3 pl-1 mb-2 mt-2"
                        style={{ fontSize: "14px" }}
                      >
                        {companyErrors?.map((error, index) => {
                          return (
                            <li className="text-danger " key={index}>
                              {error.message}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                    <div className="justify-content-end d-flex align-items-start mt-2">
                      <Button
                        className="bg-transparent border-0 text-info pr-0 pt-0"
                        onClick={() => {
                          history.push({
                            pathname: `${UPDATE_COMPANY_ROUTE}/${selectedCompany?.id}`,
                            search: "?from=legacy-agreements",
                            state: { ...getValues() },
                          });

                          pushInHistory('/create-legacy-agreement', location.search)
                        }}
                        disabled={selectedCompany?.id ? false : true}
                      >
                        Update Company Info
                      </Button>
                    </div>
                  </div>
                </div>
              </Col>

              {selectedCompany &&
                Object.keys(selectedCompany).length &&
                selectedAddress &&
                Object.keys(selectedAddress).length ? (
                <Col md="6" sm="12" className="pt-no-label-agreement pt-1">
                  <Label className="d-block m-0 w-100">
                    <b>Company name:</b> {selectedCompany?.name}
                  </Label>

                  <Label className="d-block m-0 w-100">
                    <b>HQ address:</b> {selectedAddress?.streetAddress}
                  </Label>
                </Col>
              ) : null}
            </Row>

            <Row>
              <Col xl="4" lg="4" md="6" sm="12">
                <div className="form-group m-0">
                  <Label>Company Contact</Label>
                  {/* <Input
                    type="select"
                    name="companyContactId"
                    onChange={({
                      target,
                    }: React.ChangeEvent<HTMLInputElement>) => {
                      setCompanyContactErrors([]);
                      companyContactFilter(target.value);
                    }}
                    innerRef={register({
                      required: {
                        value: true,
                        message: "Company contact should be selected!",
                      },
                    })}
                  >
                    <option value="">Select Contact</option>
                    {contactsSelectedCompany?.map((contact) => {
                      return (
                        <option key={contact?.id} value={contact?.id}>
                          {contact?.firstName} {contact?.lastName}
                        </option>
                      );
                    })}
                  </Input> */}
                  <Controller
                    name="companyContactId"
                    rules={{
                      required: {
                        value: true,
                        message: "Company contact should be selected!",
                      },
                    }}
                    render={({ onChange, onBlur, value, name, ref }) => (
                      <Select
                        value={value}
                        name={name}
                        ref={ref}
                        onBlur={onBlur}
                        options={contactsSelectedCompany}
                        placeholder="Select Company Contact"
                        onChange={({ value, label }) => {
                          setValue("companyContactId", { value, label })
                          setSelectedCompanyContact(value)
                          setSelectedContactEmail(value?.contactEmail?.find(
                            (email) => email.isPrimary === true
                          ))
                          onChange({ value, label })
                        }}
                        styles={{ ...customSelectDropDown(theme) }}
                      />
                    )}
                    control={control}
                  />
                  <div className="d-flex justify-content-between">
                    <div>
                      <small className="text-danger">
                        {errors?.companyContactId?.message}
                      </small>
                      <ul
                        className="ml-3 pl-1 mb-2 mt-2"
                        style={{ fontSize: "14px" }}
                      >
                        {companyContactErrors?.map((error, index) => {
                          return (
                            <li className="text-danger " key={index}>
                              {error.message}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                    <div className="justify-content-end d-flex align-items-start mt-2">
                      <Button
                        className="bg-transparent border-0 text-info pr-0 pt-0"
                        disabled={selectedCompany?.id ? false : true}
                        onClick={toggle}
                      >
                        Create Contact
                      </Button>
                    </div>
                  </div>
                </div>
              </Col>

              {selectedCompany && Object.keys(selectedCompany)?.length ? (
                <>
                  {selectedCompanyContact &&
                    Object.keys(selectedCompanyContact)?.length &&
                    selectedContactEmail &&
                    Object.keys(selectedContactEmail)?.length ? (
                    <Col md="6" sm="12" className="pt-no-label-agreement pt-1 mt-3">
                      <Label className="d-block m-0 w-100">
                        <b>Contact name:</b> {selectedCompanyContact?.firstName}{" "}
                        {selectedCompanyContact?.lastName}
                      </Label>

                      <Label className="d-block m-0 w-100">
                        <b>Contact Primary Email:</b>{" "}
                        {selectedContactEmail?.email}
                      </Label>
                    </Col>
                  ) : null}
                </>
              ) : null}
            </Row>

            <Row>
              <Col xl="4" lg="4" md="6" sm="12" className="my-2" >
                <FormGroup>
                  <Label>Signed Date</Label>
                  <Input
                    defaultValue={signedDate}
                    type="date"
                    name="signedAt"
                    innerRef={register({
                      required: {
                        value: true,
                        message: "Signed date is required"
                      }
                    })}
                    onChange={(e) => setSignedDate(e.target.value)}
                    max={new Date().toISOString().substring(0, 10)}
                  />
                  <div className="d-flex flex-column">
                    <small className="text-danger">
                      {errors?.signedAt?.message}
                    </small>
                  </div>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col xl="4" lg="4" md="6" sm="12">
                <div className="form-group m-0 my-2">
                  <Label>Agreement Creator </Label>
                  <Input
                    type="select"
                    name="agreementCreatorId"
                    onChange={(e) => setAgreementCreator(true)}
                    innerRef={register({
                      required: {
                        value: agreementCreator,
                        message: "Agreement Creator should be selected!",
                      },
                    })}
                  >
                    <option value="">Select User</option>
                    {recruiters?.map((recruiter) => {
                      return (
                        <option key={recruiter?.id} value={recruiter?.id}>
                          {recruiter?.firstName} {recruiter?.lastName}
                        </option>
                      );
                    })}
                  </Input>
                  <div className="d-flex flex-column">
                    <small className="text-danger">
                      {errors?.agreementCreatorId?.message}
                    </small>
                  </div>

                </div>
              </Col>
            </Row>

            <Row>
              <Col xl="4" lg="4" md="6" sm="12" className="nick-name my-3">
                <InputField
                  inputtype="text"
                  label="Nickname"
                  name="nickName"
                  inputid="nickName"
                  placeholder="Nickname"
                  inputRef={register({
                    required: {
                      value: true,
                      message: "Nickname is required!",
                    },
                  })}
                />
                <div className="d-flex flex-column">
                  <small className="text-info">
                    Example: “Legacy DH25PCT30REPL”.
                  </small>
                  <small className="text-danger">
                    {errors?.nickName?.message}
                  </small>
                </div>
              </Col>
            </Row>

            <div className="d-flex d-flex-field justify-content-between create-agreement-radio pt-3">
              <div className="d-flex create-agreement-radio radioWrapperWidth">
                <Label className="radio-container mr-5">
                  Legacy Direct Hire
                  <Input
                    type="radio"
                    checked={agreementType === LEGAL_DIRECT_HIRE}
                    name="agreementType"
                    value="LEGAL_DIRECT_HIRE"
                    onChange={({
                      target,
                    }: React.ChangeEvent<HTMLInputElement>) => {
                      agreementTypeHandler(target.value);
                    }}
                    innerRef={register({
                      required: {
                        value: true,
                        message: "Please select any agreement type",
                      },
                    })}
                  />
                  <span className="checkmark" />
                </Label>

                <Label className="radio-container mr-3">
                  Legacy Contract Agreement{" "}
                  <Input
                    type="radio"
                    checked={agreementType === LEGAL_CONTRACT}
                    name="agreementType"
                    value="LEGAL_CONTRACT"
                    onChange={({
                      target,
                    }: React.ChangeEvent<HTMLInputElement>) => {
                      agreementTypeHandler(target.value);
                    }}
                    innerRef={register({
                      required: {
                        value: true,
                        message: "Please select any agreement type",
                      },
                    })}
                  />
                  <span className="checkmark" />
                </Label>
              </div>

              <div>
                <a
                  href={
                    agreementType === LEGAL_CONTRACT
                      ? "https://storage.googleapis.com/ats-realrepp-stage-public/documents/1726724551459-LegacyCustomAgreement.pdf"
                      : "https://storage.googleapis.com/ats-realrepp-stage-public/documents/1726724689310-LegacyDirectHire.pdf"
                  }
                  target="_blank"
                  rel="noreferrer"
                  className="for-link-theme-color"
                >
                  <button
                    className="buttonGenericStyle filledButton m-0"
                    type="button"
                  >
                    View Agreement{" "}
                  </button>
                </a>
              </div>
            </div>

            <small className="text-danger">
              {errors?.agreementType?.message}
            </small>

            <div>
              <Row>
                <div
                  className="user-info upload-btn-wrapper mt-3"
                  style={{
                    cursor: "pointer",
                  }}
                >
                  <input
                    type="file"
                    name="image"
                    id="image"
                    accept={""}
                    onChange={async ({ target: { files } }) => {
                      setErrorMessage("");
                      if (files) {
                        let [file]: any = files;

                        if (file.size > 25 * 1024 * 1024) {
                          return setErrorMessage(
                            "File size it too large (should be 25MB)."
                          );
                        }

                        if (file?.type !== "application/pdf") {
                          return setErrorMessage("Please select pdf format file");
                        }

                        if (file) {
                          setFileName(file?.name);
                        }

                        setFile(file);
                      }
                    }}
                  />
                  <img
                    src={uploadImg}
                    className="mx-3"
                    alt="Upload File"
                    style={{
                      maxHeight: "26px",
                      maxWidth: "26px",
                      cursor: "pointer",
                    }}
                  ></img>

                  <Label className="pt-1 w-100" htmlFor="image">
                    <a
                      href="''"
                      target="_blank"
                      rel="noreferrer"
                      className="for-link-theme-color"
                    >
                      Upload File{" "}
                    </a>
                  </Label>
                </div>
              </Row>
              <div className="mt-2 text-info mb-2 text-truncate textTruncateWidth">
                {fileName}
              </div>
              {errorMessage && (
                <div className="text-danger">{errorMessage}</div>
              )}
            </div>

            <div className="user-basic-form agreementLabel">
              {agreementType === LEGAL_DIRECT_HIRE && (
                <Row>
                  <Col xl="4" md="6" sm="12">
                    <InputField
                      label="Client Fee Percentage"
                      inputtype="text"
                      // selectItems={commissionPercentage}
                      inputid="commissionPercentage"
                      placeholder="Select client fee percentage"
                      inputRef={register({
                        required: {
                          value: true,
                          message: "Client Fee Percentage is required!",
                        },
                        pattern: {
                          value: /^\d*(\.\d+)?$/,
                          message: "Client Fee Percentage must be number!",
                        },
                      })}
                      errMsg={errors?.commissionPercentage?.message}
                    />
                  </Col>

                  <Col xl="4" md="6" sm="12">
                    <InputField
                      label="Replacement Days"
                      inputtype="text"
                      // selectItems={replacementDays}
                      inputid="replacementDays"
                      placeholder="Select replacement days"
                      inputRef={register({
                        required: {
                          value: true,
                          message: "Replacement days is required!",
                        },
                        pattern: {
                          value: /^\d*(\.\d+)?$/,
                          message: "Replacement days must be number!",
                        },
                      })}
                      errMsg={errors?.replacementDays?.message}
                    />
                  </Col>

                  <Col xl="4" md="6" sm="12">
                    <InputField
                      label="Payable Days"
                      inputtype="text"
                      placeholder="Select payable days"
                      inputid="payableDays"
                      inputRef={register({
                        required: {
                          value: true,
                          message: "Payable days is required!",
                        },
                        pattern: {
                          value: /^\d*(\.\d+)?$/,
                          message: "Payable days must be number!",
                        },
                      })}
                      errMsg={errors?.payableDays?.message}
                    />
                  </Col>
                </Row>
              )}
            </div>

            <div className="text-right">
              <CustomButton
                buttonText={"Create Agreement"}
                buttonColor="primary"
                buttonType="submit"
                className="big-width"
                loading={createAgreementLoading || uploadLogin}
                disabled={
                  (companyErrors?.length === 0 && agreementCreator &&
                    companyContactErrors?.length === 0
                    ? false
                    : true) ||
                  fileName.length === 0 ||
                  createAgreementLoading ||
                  uploadLogin
                }
              />
            </div>
          </div>
        </Form>
      </div >

      <CreateContactModal
        toggle={toggle}
        open={contactToggle}
        companyId={selectedCompany?.id}
        companyName={selectedCompany?.name}
        fetchCompany={() => {
          _filterMyCompanies("");
        }}
        preSelectContact={(preSelect) => {
          setCompanyContactErrors([]);
          setValue("companyContactId", { label: preSelect?.firstName + " " + preSelect?.lastName, value: preSelect })
          setSelectedCompanyContact(preSelect)
          setSelectedContactEmail(preSelect?.contactEmail?.find(
            (email) => email.isPrimary === true
          ))
        }}
      />
    </div >
  );
};

export default withRouter(CreateLegacyAgreement);
