import { CLOSE } from "../../constants";
import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";

const NotesModal = ({ isOpen, toggle, modalHeading, notesText }) => {
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
    >
      <ModalHeader>
        <div className="d-flex">{modalHeading}</div>
      </ModalHeader>

      <ModalBody>
        <Row>
          <Col xs="12" className="dark-theme-text">{notesText}</Col>
        </Row>
      </ModalBody>

      <ModalFooter>
        <div className="d-flex justify-content-end align-items-center w-100">
          <button
            className="btn btn-primary py-2 m-2"
            onClick={() => {
              toggle()
            }}
            type="button"
          >
            {CLOSE}
          </button>

        </div>
      </ModalFooter>
    </Modal>
  )
}

export default NotesModal;
