// utils block
import { formatValue } from "../../../utils/helper";

export default function AgreementEscalationStatus({ agreementStatus }) {
  return (
    <div className="subTextSpacing">
      Status:{" "}
      <span>{formatValue(agreementStatus || '')}</span>
    </div>
  );
}
