import { useLazyQuery, useMutation } from '@apollo/client';
import { FC, Fragment, useCallback, useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Row } from 'reactstrap';
import DELETE_ICON from "../../assets/images//delete-icon-menu.svg";
import EDIT_ICON from "../../assets/images/edit-icon-menu.svg";
import ConfirmPopUp from '../../components/ConfirmPopUp';
import Loader from '../../components/layout/Loader';
import NoDataFound from '../../components/layout/NoDataFound';
import TableComponent from '../../components/layout/TableComponent';
import { ReportEnum, REPORTS_HEADER } from '../../constants';
import { AppContext } from '../../context';
import { ReportType } from '../../interfaces';
import { ROLES } from '../../utils/constant';
import CreateReportModal from './CreateReportModal';
import { CREATE_REPORT, DELETE_REPORT, GET_ALL_REPORTS, UPDATE_REPORT } from './gql';
import UpdateReportModal from './UpdateReportModal';

const Reports: FC = (): JSX.Element => {
  // constants
  const { userRoles } = useContext(AppContext);
  // states
  const [reportId, setReportId] = useState<string>('');
  const [reportCategory, setReportCategory] = useState<string>('');
  const [createModalOpen, setCreateModalOpen] = useState<boolean>(false);
  const [updateModalOpen, setUpdateModalOpen] = useState<boolean>(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [reportObj, setReportObj] = useState<ReportType>({
    id: '',
    name: '',
    link: '',
    category: ReportEnum.TEAM_REPORT,
  });
  // queries & mutations
  const [getAllReports, { data: listData, loading: listLoading }] =
    useLazyQuery(GET_ALL_REPORTS)
  const [createReport] = useMutation(CREATE_REPORT);
  const [updateReport] = useMutation(UPDATE_REPORT);
  const [deleteReport] = useMutation(DELETE_REPORT);

  // methods
  const updateReportData = (item: ReportType) => {
    setReportObj({
      id: item?.id,
      name: item?.name,
      link: item?.link,
      category: item?.category,
    });
    setUpdateModalOpen(!updateModalOpen);
  };

  const deleteReportData = async () => {
    if (reportId) {
      const res = await deleteReport({
        variables: { input: { id: reportId, category: reportCategory } },
      });
      if (res?.data) {
        toast.success('Report removed successfully!');
        refetchData();
        setDeleteModalOpen(false);
      }
    }
  };

  const refetchData = async () => {
    await getAllReports();
  }
  
  
  const fetchReportsData = useCallback(async () => {
      await getAllReports();
    }
  , [getAllReports]);

  useEffect(() => {
    fetchReportsData();
  }, [fetchReportsData]);

  return (
    <>
    <Row>
      <div className='w-100 d-flex justify-content-between align-items-center'>
        <p className="mb-0 font-weight-bold">Team Reports</p>
        <div>
          {userRoles?.includes(ROLES.ADMIN) ||
            userRoles?.includes(ROLES.EXECUTIVE) ? (
              <button
              className="buttonGenericStyle filledButton"
              onClick={() => setCreateModalOpen(!createModalOpen)}
              disabled={
                userRoles?.includes(ROLES.ADMIN) ||
                userRoles?.includes(ROLES.EXECUTIVE)
                ? false
                : true
              }
              >
                Add New Report
              </button>
          ) : ('')}
        </div>
      </div>
      
        <div className="table-responsive mt-3 candidates-list-table">
          <TableComponent tableHeader={REPORTS_HEADER}>
            {listLoading ? (
              <tbody>
                <tr>
                  <td colSpan={4}>
                    <Loader loaderClass="sm-height " />
                  </td>
                </tr>
              </tbody>
            ) : (
              <tbody>
                {listData?.getAllReports?.reportsTeam?.length > 0 ? (
                  listData?.getAllReports?.reportsTeam?.map((item, index) => {
                    return (
                      <tr key={`${index} indexOf ${item?.link}`} className="report-table-row">
                        <td>
                          <span className="d-inline-block text-truncate">
                            {item.name}
                          </span>
                        </td>
                        <td>
                          <Button color="link" onClick={() => window.open(item?.link)} target="_blank" className="p-0 text-left text-blue-link">
                            <span className="text-start cursor-pointer d-inline-block text-truncate">
                              Report Link
                            </span>
                          </Button>
                        </td>

                        <td>
                          <span className="d-inline-block text-truncate">
                            {ReportEnum[item?.category]}
                          </span>
                        </td>

                        <td>
                          <div className="d-flex justify-center">
                            <Button
                              onClick={() =>
                                updateReportData({
                                  id: item?.id,
                                  name: item?.name,
                                  link: item?.link,
                                  category: item?.category,
                                })
                              }
                              disabled={userRoles?.includes(ROLES.ADMIN) || userRoles?.includes(ROLES.EXECUTIVE) ? false : true}
                              className="btn bg-transparent p-0 border-0"
                              >
                              <img src={EDIT_ICON} alt="edit" />
                            </Button>

                            <Button
                              onClick={() => {
                                setReportId(item?.id);
                                setReportCategory(item?.category);
                                setDeleteModalOpen(true);
                              }}
                              className="btn bg-transparent p-0 border-0"
                              disabled={userRoles?.includes(ROLES.ADMIN) || userRoles?.includes(ROLES.EXECUTIVE) ? false : true}
                              
                              >
                              <img src={DELETE_ICON} alt="delete" />
                            </Button>
                          </div>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td
                      colSpan={REPORTS_HEADER.length}
                      className="empty-table-td"
                      >
                      <NoDataFound text="No Reports Found!" />
                    </td>
                  </tr>
                )}
              </tbody>
            )}
          </TableComponent>
        </div>  
      </Row>

      <Row className="mt-3">
          <p className="mb-0 font-weight-bold">Recruiter Reports</p>  
          <div className="table-responsive mt-3 candidates-list-table">
            <TableComponent tableHeader={REPORTS_HEADER}>
              {listLoading ? (
                <tbody>
                  <tr>
                    <td colSpan={4}>
                      <Loader loaderClass="sm-height " />
                    </td>
                  </tr>
                </tbody>
              ) : (
                <tbody>
                  {listData?.getAllReports?.reportsRecruiter?.length > 0 ? (
                    listData?.getAllReports?.reportsRecruiter?.map((item, index) => {
                      return (
                        <tr key={`${index} indexOf ${item?.link}`} className="report-table-row">
                          <td>
                            <span className="d-inline-block text-truncate">
                              {item.name}
                            </span>
                          </td>
                          <td>
                            <Button color="link" onClick={() => window.open(item?.link)} target="_blank" className="p-0 text-left text-blue-link">
                              <span className="text-start cursor-pointer d-inline-block text-truncate">
                                Report Link
                              </span>
                            </Button>
                          </td>

                          <td>
                            <span className="d-inline-block text-truncate">
                              {ReportEnum[item?.category]}
                            </span>
                          </td>

                          <td>
                            <div className="d-flex justify-center">
                              <Button
                                onClick={() =>
                                  updateReportData({
                                    id: item?.id,
                                    name: item?.name,
                                    link: item?.link,
                                    category: item?.category,
                                  })
                                }
                                disabled={userRoles?.includes(ROLES.ADMIN) || userRoles?.includes(ROLES.EXECUTIVE) ? false : true}
                                className="btn bg-transparent p-0 border-0"
                              >
                                <img src={EDIT_ICON} alt="edit" />
                              </Button>

                              <Button
                                onClick={() => {
                                  setReportId(item?.id);
                                  setReportCategory(item?.category);
                                  setDeleteModalOpen(true);
                                }}
                                className="btn bg-transparent p-0 border-0"
                                disabled={userRoles?.includes(ROLES.ADMIN) || userRoles?.includes(ROLES.EXECUTIVE) ? false : true}

                              >
                                <img src={DELETE_ICON} alt="delete" />
                              </Button>
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td
                        colSpan={REPORTS_HEADER.length}
                        className="empty-table-td"
                      >
                        <NoDataFound text="No Reports Found!" />
                      </td>
                    </tr>
                  )}
                </tbody>
              )}
            </TableComponent>
          </div> 
      </Row>
      <Row className="mt-3">
        <p className="mb-0 font-weight-bold">Company Reports</p>  
        <div className="table-responsive mt-3 candidates-list-table">
          <TableComponent tableHeader={REPORTS_HEADER}>
            {listLoading ? (
              <tbody>
                <tr>
                  <td colSpan={4}>
                    <Loader loaderClass="sm-height " />
                  </td>
                </tr>
              </tbody>
            ) : (
              <tbody>
                {listData?.getAllReports?.reportsCompany?.length > 0 ? (
                  listData?.getAllReports?.reportsCompany?.map((item, index) => {
                    return (
                      <tr key={`${index} indexOf ${item?.link}`} className="report-table-row">
                        <td>
                          <span className="d-inline-block text-truncate">
                            {item.name}
                          </span>
                        </td>
                        <td>
                          <Button color="link" onClick={() => window.open(item?.link)} target="_blank" className="p-0 text-left text-blue-link">
                            <span className="text-start cursor-pointer d-inline-block text-truncate">
                              Report Link
                            </span>
                          </Button>
                        </td>

                        <td>
                          <span className="d-inline-block text-truncate">
                            {ReportEnum[item?.category]}
                          </span>
                        </td>

                        <td>
                          <div className="d-flex justify-center">
                            <Button
                              onClick={() =>
                                updateReportData({
                                  id: item?.id,
                                  name: item?.name,
                                  link: item?.link,
                                  category: item?.category,
                                })
                              }
                              disabled={userRoles?.includes(ROLES.ADMIN) || userRoles?.includes(ROLES.EXECUTIVE) ? false : true}
                              className="btn bg-transparent p-0 border-0"
                            >
                              <img src={EDIT_ICON} alt="edit" />
                            </Button>

                            <Button
                              onClick={() => {
                                setReportId(item?.id);
                                setReportCategory(item?.category);
                                setDeleteModalOpen(true);
                              }}
                              className="btn bg-transparent p-0 border-0"
                              disabled={userRoles?.includes(ROLES.ADMIN) || userRoles?.includes(ROLES.EXECUTIVE) ? false : true}

                            >
                              <img src={DELETE_ICON} alt="delete" />
                            </Button>
                          </div>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td
                      colSpan={REPORTS_HEADER.length}
                      className="empty-table-td"
                    >
                      <NoDataFound text="No Reports Found!" />
                    </td>
                  </tr>
                )}
              </tbody>
            )}
          </TableComponent>
        </div> 
      </Row>
      <Row className="mt-3"> 
        {(userRoles?.includes(ROLES.ADMIN) ||
          userRoles?.includes(ROLES.EXECUTIVE) ||
          userRoles?.includes(ROLES.HR)) && 
          <Fragment> 
            <p className="mb-0 font-weight-bold">Executive & HR Reports</p>  
            <div className="table-responsive mt-3 candidates-list-table">
              <TableComponent tableHeader={REPORTS_HEADER}>
                {listLoading ? (
                  <tbody>
                    <tr>
                      <td colSpan={4}>
                        <Loader loaderClass="sm-height " />
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody>
                    {listData?.getAllReports?.reportsExecutiveAndHr?.length > 0 ? (
                      listData?.getAllReports?.reportsExecutiveAndHr?.map((item, index) => {
                        return (
                          <tr key={`${index} indexOf ${item?.link}`} className="report-table-row">
                            <td>
                              <span className="d-inline-block text-truncate">
                                {item.name}
                              </span>
                            </td>
                            <td>
                              <Button color="link" onClick={() => window.open(item?.link)} target="_blank" className="p-0 text-left text-blue-link">
                                <span className="text-start cursor-pointer d-inline-block text-truncate">
                                  Report Link
                                </span>
                              </Button>
                            </td>

                            <td>
                              <span className="d-inline-block text-truncate">
                                {ReportEnum[item?.category]}
                              </span>
                            </td>

                            <td>
                              <div className="d-flex justify-center">
                                <Button
                                  onClick={() =>
                                    updateReportData({
                                      id: item?.id,
                                      name: item?.name,
                                      link: item?.link,
                                      category: item?.category,
                                    })
                                  }
                                  disabled={userRoles?.includes(ROLES.ADMIN) || userRoles?.includes(ROLES.EXECUTIVE) ? false : true}
                                  className="btn bg-transparent p-0 border-0"
                                >
                                  <img src={EDIT_ICON} alt="edit" />
                                </Button>

                                <Button
                                  onClick={() => {
                                    setReportId(item?.id);
                                    setReportCategory(item?.category);
                                    setDeleteModalOpen(true);
                                  }}
                                  className="btn bg-transparent p-0 border-0"
                                  disabled={userRoles?.includes(ROLES.ADMIN) || userRoles?.includes(ROLES.EXECUTIVE) ? false : true}

                                >
                                  <img src={DELETE_ICON} alt="delete" />
                                </Button>
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td
                          colSpan={REPORTS_HEADER.length}
                          className="empty-table-td"
                        >
                          <NoDataFound text="No Reports Found!" />
                        </td>
                      </tr>
                    )}
                  </tbody>
                )}
              </TableComponent>
            </div>   
          </Fragment>
        }
      </Row>

      <CreateReportModal
        refetchData={refetchData}
        isOpen={createModalOpen}
        toggle={() => setCreateModalOpen(!createModalOpen)}
        createReport={createReport}
      />

      {updateModalOpen && (
        <UpdateReportModal
          isOpen={updateModalOpen}
          toggle={() => setUpdateModalOpen(!updateModalOpen)}
          reportObj={reportObj}
          updateReport={updateReport}
          refetchData={refetchData}
        />
      )}

      <ConfirmPopUp
        isOpen={deleteModalOpen}
        toggle={() => setDeleteModalOpen(false)}
        confirmAction={() => deleteReportData()}
        confirmText="Are You Sure You Want To Delete This Report?"
        modalHeading="Delete Report"
      />
    </>
  );
};

export default Reports;