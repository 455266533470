import moment from 'moment';
import { localStringMaker } from '../../../../utils/helper';

interface LabelValueGeneral {
  label?: string;
  value?: string;
}

interface PlacementSource extends LabelValueGeneral {}

interface PlacementType extends LabelValueGeneral {}

interface AgreementType extends LabelValueGeneral {}

interface TimeCardApprover extends LabelValueGeneral {}

interface PlacementCommissionType extends LabelValueGeneral {}

export interface PlacementEditableValue {
  placementSource?: PlacementSource;
  placementType?: PlacementType;
  startDate?: string;
  endDate?: string | Date;
  salary?: string;
  commissionType?: PlacementCommissionType | null;
  agreement?: AgreementType | null;
  payableDays?: string;
  replacementDays?: string;
  totalFee?: string;
  adjustmentAmount?: string;
  adjustmentReason?: string;
  assignedDuration?: string;
  payRate?: string;
  billRate?: string;
  markupPercentage?: string;
  overtimeBillRate?: string;
  timeCardApprover?: TimeCardApprover;
  estimatedLessPayrollBurdenPercenatge?:number
}

export enum PlacementTypeEnum {
  CONTRACT = 'Contract',
  DIRECT_HIRE = 'Direct Hire',
}

export enum PlacementCommissionTypeEnum {
  FLAT_FEE = 'Flat Fee',
  PERCENTAGE = 'Percentage',
}

export const prefillPlacementData = (
  incomingValues: any
): PlacementEditableValue => {
  let values: any = {};

  values.startDate = moment(incomingValues.startDateString).format(
    'YYYY-MM-DD'
  );
  values.placementEndDate = incomingValues?.placementEndDate
    ? moment(incomingValues.placementEndDate).format('YYYY-MM-DD')
    : '';
  values.placementSource = incomingValues?.source;
  values.estimatedLessPayrollBurdenPercenatge = incomingValues?.estimatedLessPayrollBurdenPercenatge;

  values.noteToAccounting = incomingValues?.noteToAccounting;
  values.placementNote = incomingValues?.placementNote;

  values.placementType = {
    label:
      incomingValues?.placementType &&
      PlacementTypeEnum[incomingValues?.placementType],
    value: incomingValues?.placementType,
  };

  values.endDate = moment(incomingValues?.estimateEndDate).format('YYYY-MM-DD');

  if (incomingValues?.salaryType === 'HOURLY') {
    values.salary = localStringMaker(incomingValues?.salaryAmount / (40 * 52));
  } else {
    values.salary = localStringMaker(incomingValues?.salaryAmount);
  }

  values.commissionType = {
    label:
      incomingValues?.commissionType &&
      PlacementCommissionTypeEnum[incomingValues?.commissionType],
    value: incomingValues?.commissionType,
  };

  values.agreement = {
    label: incomingValues?.agreement?.nickName,
    value: incomingValues?.agreement?.id,
  };

  values.replacementDays = incomingValues?.replacementTerm;
  values.payableDays = incomingValues?.payableTerm;

  values.totalFee = localStringMaker(incomingValues?.totalCommission);
  values.netCommission = localStringMaker(incomingValues?.netCommission);
  values.feeStructured = localStringMaker(incomingValues?.feeStructured);
  values.adjustmentAmount = localStringMaker(incomingValues?.adjustmentTotal);

  values.adjustmentReason = incomingValues?.adjustmentsReason;
  values.assignedDuration = incomingValues?.assignmentDuration;

  values.payRate = localStringMaker(incomingValues?.payRate);
  values.billRate = localStringMaker(incomingValues?.billRate);
  values.markupPercentage = incomingValues?.markupPercentage?.toString();
  values.overtimeBillRate = localStringMaker(incomingValues?.overtimeBillRate);

  return values;
};

export const compactObject = (obj: any) => {
  let newObj = {};
  Object.keys(obj).forEach((key) => {
    if (obj[key] || obj[key] === false) {
      newObj = { ...newObj, [key]: obj[key] };
    }
  });
  return newObj;
};
