import {
  Button,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import CustomButton from "../../../../components/layout/CustomButton";

export default function ExecutiveRejectModalEscalation({
  modal,
  toggle,
  setNote,
  executiveRejectProtocolLoading,
  rejectAction,
  onClosed,
}) {
  return (
    <Modal
      isOpen={modal}
      toggle={toggle}
      scrollable
      className="for-modal-styling"
      onClosed={onClosed}
    >
      <ModalHeader>Reject - Executive</ModalHeader>
      <ModalBody>
        <div className="approval-content">
          <p>Are you sure you want to reject this agreement?</p>
          <p>Rejecting this will send it back to recruiter.</p>
        </div>

        <div>
          <Label>Add text</Label>
          <textarea
            name="sourceNote"
            onChange={(e) => setNote(e.target.value)}
            placeholder="Please add notes"
            rows={2}
            className="form-control h-150"
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          onClick={onClosed}
          className="modal-close bg-transparent border-0 text-dark"
        >
          Cancel
        </Button>
        <CustomButton
          buttonType="button"
          buttonText={"Reject"}
          buttonColor={"danger"}
          buttonClick={rejectAction}
          loading={executiveRejectProtocolLoading}
        />
      </ModalFooter>
    </Modal>
  );
}