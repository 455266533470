import React from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { createMarkup } from "../utils/commonFn";
import CustomButton from "./layout/CustomButton";

const ContentViewModal = ({ isOpen, toggle, content, heading }: any) => {
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      style={{ maxWidth: 850 }}
      id="noteModal"
      className="revampDialog revampDialogWidth"
    >
      <ModalHeader toggle={toggle}>{heading}</ModalHeader>
      <ModalBody style={{ height: 465 }}>
        <div
          className="for-wordfull-break"
          dangerouslySetInnerHTML={createMarkup(content)}
        />
      </ModalBody>
      <ModalFooter>
        <CustomButton
          buttonText="Close"
          buttonColor="default"
          buttonType="button"
          buttonClick={toggle}
          className="btn-primary"
        />
      </ModalFooter>
    </Modal>
  );
};

export default ContentViewModal;
