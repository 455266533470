import { gql } from '@apollo/client';
export const GET_FOLDER_LISTING = gql`
  query GetAllFolder($page: Int!, $limit: Int!, $searchText: String!) {
    fetchAllFolders(
      payload: { page: $page, limit: $limit }
      searchText: $searchText
    ) {
      recruiterFolders {
        id
        folderName
        currentCount
      }
      count
    }
  }
`;

export const CREATE_RECRUITER_FOLDER = gql`
  mutation CreateFolder($folderName: String!) {
    createFolder(folderName: $folderName) {
      message
      status
    }
  }
`;
export const GET_JOB_ORDERS_FOR_COMPANY = gql`
  query FindJobOrdersForCompany($page: Int!, $limit: Int!, $companyId: String) {
    findJobOrdersForCompany(
      payload: { page: $page, limit: $limit }
      filters: { companyId: $companyId }
    ) {
      jobOrders {
        id
        recruitingLevel
        jobTitle
        status
        allowRemote
        workplaceTypes
        company {
          id
          companyOwner {
            owner {
              id
              fullName
            }
          }
        }
        companyLocation {
          id
          city
          state
          country
        }
        recruitingAssigned {
          id
          user {
            id
            fullName
          }
        }
      }
      count
    }
  }
`;

export const GET_SUBMISSIONS_FROM_APPLICANT = gql`
  query GetSubmission($candidateId: String!, $jobOrderId: String!) {
    getSubmissionfromJobApplicant(
      candidateId: $candidateId
      jobOrderId: $jobOrderId
    ) {
      id
      status
      salary
      salaryType
      targetSalary
      submitWith
      resumeUrl
      resumeName
      resumeId
      submissionSummary
      submissionEmail {
        id
        ccContacts
        bccContacts
        toContacts
        subject
        bodyContent
        status
      }
      submissionAttachments {
        id
        attachmentURL
        attachmentType
        attachmentName
      }
      submissionStatusHistory {
        id
        note
        status
        createdAt
        user {
          id
          fullName
        }
      }
    }
  }
`;

export const GET_SUBMISSIONS = gql`
  query GetSubmission($applicantId: String!) {
    getSubmissionWithApplicant(applicantId: $applicantId) {
      id
      status
      salary
      salaryType
      targetSalary
      submitWith
      resumeUrl
      resumeName
      resumeId
      submissionSummary
      submissionEmail {
        id
        ccContacts
        bccContacts
        toContacts
        subject
        bodyContent
        status
      }
      submissionAttachments {
        id
        attachmentURL
        attachmentType
        attachmentName
      }
      submissionStatusHistory {
        id
        note
        status
        createdAt
        user {
          id
          fullName
        }
      }
    }
  }
`;

export const EDIT_RECRUITER_FOLDER = gql`
  mutation EditFolder($folderName: String!, $folderId: String!) {
    editRecruiterFolder(folderName: $folderName, folderId: $folderId) {
      message
      status
    }
  }
`;

export const DELETE_RECRUITER_FOLDER = gql`
  mutation DeleteFolder($folderId: String!) {
    deleteRecruiterFolder(folderId: $folderId) {
      message
      status
    }
  }
`;

export const ADD_TO_RECRUITER_FOLDER = gql`
  mutation AddToRecruiterFolder($recruiterFolder: AddToFolderDto!) {
    addToRecruiterFolder(recruiterFolder: $recruiterFolder) {
      candidate {
        id
        fullName
      }
      response {
        message
        status
      }
    }
  }
`;

export const GET_FOLDER_DETAIL = gql`
  query GetFolderDetail($folderDetailInput: FolderDetailDto!) {
    fetchAllFolderDetails(folderDetailInput: $folderDetailInput) {
      recruiterFolders {
        id
        candidate {
          id
          fullName
          emails {
            id
            email
            isPrimary
          }
          phones {
            id
            phoneNumber
            isPrimary
          }
          recruiterOwner {
            id
            fullName
          }
          status
          jobTitle
          companyName
          employmentHistory {
            id
            jobTitle
            companyName
            endedAt
            startedAt
          }
           recentEmployment {
          companyName
        	}
        }
      }
      currentFolder {
        id
        folderName
      }
      count
    }
  }
`;

export const REMOVE_CANDIDATE_IN_FOLDER = gql`
  mutation RemoveCandidate($removeCandidateInput: RemoveCandidateInFolderDto!) {
    removeCandidateInFolder(removeCandidateInput: $removeCandidateInput) {
      message
      status
    }
  }
`;
export const MOVE_CANDIDATE_IN_FOLDER = gql`
  mutation MoveCandidate($moveCandidateInput: MoveCandidateInput!) {
    moveCandidate(moveCandidateInput: $moveCandidateInput) {
      candidate {
        id
        fullName
      }
      response {
        message
        status
      }
    }
  }
`;

// PRIVATE CANDIDATE LISTING QUERIES BELOW

export const CANDIDATES_PRIVATE_LISTING = gql`
  query GetCandidatesList(
    $page: Int!
    $limit: Int!
    $searchText: String
    $statusSearch: String
  ) {
    allPrivateCandidates(
      payload: { page: $page, limit: $limit }
      filters: { searchText: $searchText, statusSearch: $statusSearch }
    ) {
      candidates {
        id
        fullName
        jobTitle
        companyName
        status
        source
        city
        stateCode
        idealJobTitle
        idealWorkType
        availability
        isPrivateCandidate
        resume {
          id
          resumeFileName
          originalUrl
          createdAt
          updatedAt
          uploadedByUser {
            id
            fullName
          }
        }
        recruiterOwner {
          id
          fullName
        }
        phones {
          id
          phoneNumber
          isPrimary
          phoneType
        }
        emails {
          id
          email
          isPrimary
        }
        employmentHistory {
          id
          companyName
          mostRecentExperience
          jobTitle
        }
      }
      count
    }
  }
`;

export const REMOVE_PRIVATE_CANDIDATE = gql`
  mutation RemovePrivateCandidate($candidateId: String!) {
    removePrivateCandidate(candidateId: $candidateId) {
      message
      status
    }
  }
`;

export const GET_SUBMISSIONS_HISTORY = gql`
  query GetSubmissionHistory(
    $submissionId: String!
    $page: Int!
    $limit: Int!
  ) {
    getSubmissionHistory(
      submissionId: $submissionId
      payload: { page: $page, limit: $limit }
    ) {
      submissionHistory {
        id
        note
        status
        createdAt
        user {
          id
          fullName
        }
      }
      count
    }
  }
`;
