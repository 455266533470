import { Col, Row } from "reactstrap";

const SubmittedWarningsModal = ({ backUrl, err }) => {
  console.log("error in error component", err);
  return (
    <Row className="">
      <Col xs="12">
        <div className="conditions-terminate">
          <div className="mx-0">
            <ul>
              <div
                className={`text-${err?.ownerCandidate ? "danger" : "success"}`}
              >
                <li>You need to be an owner of this candidate.</li>
              </div>

              <div
                className={`text-${
                  err?.candidatePhones ? "danger" : "success"
                }`}
              >
                <li>Candidate must have a phone number.</li>
              </div>
              <div
                className={`text-${
                  err?.candidateAddress ? "danger" : "success"
                }`}
              >
                <li>Candidate must have address.</li>
              </div>
              <div
                className={`text-${
                  err?.candidateEmails ? "danger" : "success"
                }`}
              >
                <li>Candidate must have an email.</li>
              </div>
              <div className={`text-${err?.jobAssign ? "danger" : "success"}`}>
                <li>
                  You need to be the account manager or as assigned recruiter
                  for this job!
                </li>
              </div>
              <div className={`text-${err?.summary ? "danger" : "success"}`}>
                <li>Candidate does not have a completed summary!</li>
              </div>
              <div
                className={`text-${err?.webInterview ? "danger" : "success"}`}
              >
                <li>Candidate must have internal web interview</li>
              </div>
              <div
                className={`text-${err?.noteCategory ? "danger" : "success"}`}
              >
                <li>
                  Candidate does not have phone call or internal interview note!
                </li>
              </div>
            </ul>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default SubmittedWarningsModal;
