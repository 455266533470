import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import NoDataFound from "../../../components/layout/NoDataFound";
import { formatDateUS } from "../../../utils/commonFn";
import { GET_NOTES } from "../../company/gql";
import ItemSummary from "./ItemSummary";
import Pagination from "../../../components/layout/Pagination";


import NOTES_ICON from "../../../assets/images/notes-icon.svg";
import Loader from "../../../components/layout/Loader";
import { PAGE_LIMIT } from "../../../utils/constant";
import InputField from "../../../components/layout/InputField";

const CandidateNotesModal = ({ candidateId }) => {
  const page = parseInt("1");
  const [currentPage, setCurrentPage] = useState(page);
  const [limit, setLimit] = useState(3);

  const [getNotes, { data: notesData, loading }] = useLazyQuery(GET_NOTES);

  const _getNotes = () => {
    getNotes({
      variables: {
        id: candidateId,
        notePayload: { type: "Candidate" },
        limit,
        page: currentPage,
      },
    });
  };

  useEffect(() => {
    _getNotes();
    // eslint-disable-next-line
  }, [currentPage, limit]);

  return (
    <div>
      <div>
        {loading ? (
          <Loader loaderClass="sm-height " />
        ) : (
          <>
            {notesData?.getNotes?.notes?.map((item) => (
              <div className="candidate-notes-wrapper">
                <div className="candidate-notes">
                  <label>
                    <img src={NOTES_ICON} alt="notes" className="mr-1" />
                    {item?.category}
                  </label>
                  <h6>
                    by: <span className="pl-1">{item?.user?.fullName}</span>,{" "}
                    <span>
                      {item?.createdAt &&
                        new Date(item.createdAt).toLocaleTimeString()}{" "}
                      {item?.createdAt ? formatDateUS(item?.createdAt) : "--"}
                    </span>
                  </h6>

                  <ItemSummary item={item} from={""} />

                  <div className="d-flex flex-wrap mt-3">
                    <div className="notesBadge d-flex align-items-center justify-content-center">
                      Productivity
                    </div>
                    <div className="notesBadge d-flex align-items-center justify-content-center">
                      Training
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </>
        )}

        {!loading && !notesData?.getNotes?.notes?.length && (
          <div className="empty-table-td">
            <NoDataFound text="No Notes Found!" />
          </div>
        )}

        {notesData?.getNotes?.notes?.length ? (
          <div className="users-pagination flex-column">
            <Pagination
              onPageChange={(pageClicked: number) => {
                setCurrentPage(pageClicked);
              }}
              pageCount={Math.ceil(notesData?.getNotes?.count / limit)}
              currentPage={currentPage}
            />

            <div className="d-flex align-items-center">
              <div className="pagination-display paginationItemsSelect">
                <p className="m-0">
                  {(currentPage - 1) * limit + 1} to{" "}
                  {Math.min(currentPage * limit, notesData?.getNotes?.count)} of{" "}
                  {notesData?.getNotes?.count}
                </p>

                <div className="divider"></div>

                <label>Display</label>

                <InputField
                  label=""
                  inputtype="select"
                  selectItems={PAGE_LIMIT.filter(
                    (item) => item.value !== limit
                  )}
                  inputid="limit"
                  placeholder={limit.toString()}
                  inputMethod={(value: string) => {
                    setCurrentPage(1);
                    setLimit(parseInt(value));
                  }}
                />
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default CandidateNotesModal;
