import {
  Form,
} from 'reactstrap';
import './candidate-calendar.scss';
import CustomButton from '../../../../../components/layout/CustomButton';
import { useForm } from 'react-hook-form';
import { useLazyQuery, useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { useState, useContext, useEffect } from 'react';
import { RE_SEND_INVITE, SEND_INTERVIEW_EMAIL, SEND_INVITE } from '../../gql';
import { uploadingInterviewAttachmentsFiles } from '../utils';
import CandidateClendarInviteCombined from './CandidateClendarInviteCombined';
import CandidateBox from './CandidateBox';
import { AppContext } from '../../../../../context';
import { IAdditionalFileAttachment } from '../../interview-interfaces';

import { GET_CANDIDATE_SUMMARY } from '../../../../candidate/gql';
import {
  Sidedrawer,
  DrawerHeader,
} from '../../../../../components/layout/Sidedrawer';
import moment from "moment-timezone";

export interface ISingleCandidateData {
  name: string;
  jobLocation: string;
  company: string;
  jobTitle: string;
}

export default function CandidateCalendarInviteModal({
  interviewId,
  calendarInviteModal,
  type,
  reFetchInterviewEmail,
  candidateId,
  jobId,
  calendarPrefilledValues,
  candidateInterviewEmailData,
  fromInterviewDetailCard,
  fromEdit,
  updateClientCalendarModal,
  calendarInviteModalToggle,
  reFetchInterviews,
  name,
  // accountManagerAndCandidateRecruiterSame,
  // accountManagerAndCandidateRecruiterSameAndUerIsExecutiveOrAdmin,
}) {
  const { register, errors, control, handleSubmit, reset, setValue, watch } =
    useForm();
  const [selectedEmails, setSelectedEmails] = useState<Array<any>>([]);
  const [attachments, setAttachments] = useState<any>([]);
  const [disableInviteBtn, setDisableInviteBtn] = useState<boolean>(false);
  const [additionalFileAttachments, setAdditionalFileAttachments] = useState<
    Array<IAdditionalFileAttachment>
  >([]);
  const { user } = useContext(AppContext);

  const [_sendInvite] = useMutation(SEND_INVITE);
  const [_reSendInvite] = useMutation(RE_SEND_INVITE);

  const [getCandidateSummary, { data: dataSummary }] = useLazyQuery(
    GET_CANDIDATE_SUMMARY
  );
  const [_sendInterviewEmail] = useMutation(SEND_INTERVIEW_EMAIL);

  const onSendInvite = async (values: any) => {
    setDisableInviteBtn(true);
    

      let dateTime = `${calendarPrefilledValues?.interviewDateTime} ${calendarPrefilledValues?.time}`
      let formatedDateAndTime= moment(dateTime).format(
        `YYYY-MM-DDTHH:mm:ss${calendarPrefilledValues?.timeZoneId}`
      );

    let finalObject: any = {
      interviewId,
      subject: values?.subject,
      candidateInviteTemplate: values?.candidateInviteTemplate?.value || '',
      body: values?.details,
      location: values?.location,
      isToClient: false,
      interviewEmailDateTime: formatedDateAndTime,
    };

    if (selectedEmails?.length) {
      finalObject = {
        ...finalObject,
        includeCandidateResume: true,
        includeCandidateWriteup: true,
        selectedCandidateEmails: selectedEmails?.map((item) => item.label),
      };
    }

    const sendingInviteResponse = await _sendInvite({
      variables: {
        sendInviteDTO: finalObject,
      },
    });

    if (sendingInviteResponse?.data) {
      let filesResponse: any;

      if (attachments?.length) {
        filesResponse = await uploadingInterviewAttachmentsFiles(
          sendingInviteResponse?.data?.sendInvite?.id,
          attachments
        );
      }

      if (attachments?.length ? filesResponse : true) {
        const sendEmailAttachmentsResponse = await _sendInterviewEmail({
          variables: {
            sendInterviewEmailDTO: {
              candidateId,
              additionalFiles: additionalFileAttachments,
              interviewEmailId: sendingInviteResponse?.data?.sendInvite?.id,
              jobOrderId: jobId,
              fromUserId: dataSummary?.getCandidateSummary?.recruitingOwner?.id,
            },
          },
        });
        if (sendEmailAttachmentsResponse?.data) {
          setDisableInviteBtn(false);
          reFetchInterviews();
          reFetchInterviewEmail();
          calendarInviteModalToggle();
          toast.success(`Invite sent for candidate ${name}`);
       
        }
      }
    }
  };

  const _getCandidateSummary = () => {
    getCandidateSummary({ variables: { id: candidateId } });
  };

  useEffect(() => {
    if (candidateId) {
      _getCandidateSummary();
    }

    // eslint-disable-next-line
  }, [candidateId]);

  useEffect(() => {
    if (candidateInterviewEmailData?.additionalFiles) {
      const newArray = candidateInterviewEmailData?.additionalFiles.map(
        ({ __typename, ...keepAttrs }) => keepAttrs
      );

      setAdditionalFileAttachments(newArray);
    }
  }, [candidateInterviewEmailData?.additionalFiles]);

  const onReSendInvite = async (values: any) => {
    setDisableInviteBtn(true);
    let dateTime = `${calendarPrefilledValues?.interviewDateTime} ${calendarPrefilledValues?.time}`
    let formatedDateAndTime= moment(dateTime).format(
      `YYYY-MM-DDTHH:mm:ss${calendarPrefilledValues?.timeZoneId}`
    );
    let finalObject: any = {
      interviewId,
      candidateInviteTemplate: values?.candidateInviteTemplate?.value || '',
      interviewEmailId: candidateInterviewEmailData?.id,
      subject: values?.subject,
      body: values?.details,
      location: values?.location,
      isToClient: false,
      interviewEmailDateTime:formatedDateAndTime
    };

    if (selectedEmails?.length) {
      finalObject = {
        ...finalObject,
        includeCandidateResume: true,
        includeCandidateWriteup: true,
        selectedCandidateEmails: selectedEmails?.map((item) => item.label),
      };
    }

    const sendingInviteResponse = await _reSendInvite({
      variables: {
        reSendInviteDTO: finalObject,
      },
    });

    if (sendingInviteResponse?.data) {
      let filesResponse: any;

      if (attachments?.length) {
        filesResponse = await uploadingInterviewAttachmentsFiles(
          sendingInviteResponse?.data?.reSendInvite?.id,
          attachments
        );
      }

      if (attachments?.length ? filesResponse : true) {
        const sendEmailAttachmentsResponse = await _sendInterviewEmail({
          variables: {
            sendInterviewEmailDTO: {
              candidateId,
              additionalFiles: additionalFileAttachments,
              interviewEmailId: sendingInviteResponse?.data?.reSendInvite?.id,
              jobOrderId: jobId,
              fromUserId: dataSummary?.getCandidateSummary?.recruitingOwner?.id,
            },
          },
        });

        if (sendEmailAttachmentsResponse?.data) {
          setDisableInviteBtn(false);
          reFetchInterviews();
          reFetchInterviewEmail();
          calendarInviteModalToggle();
          toast.success(`Invite resent for candidate ${name}`);
        }
      }
    }
  };

  const recievePipeSelectedEmails = (data: any) => {
    setSelectedEmails(data);
  };

  const recievePipeSelectedFiles = (data: any) => {
    setAttachments(data);
  };

  const onAdditionalFileAttachmentsHandler = (
    comingAdditionalFile: IAdditionalFileAttachment
  ) => {
    const currentIndex = additionalFileAttachments?.findIndex(
      (item) => item.fileName === comingAdditionalFile.fileName
    );
    const newChecked = [...additionalFileAttachments];

    if (currentIndex === -1) {
      newChecked.push(comingAdditionalFile);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setAdditionalFileAttachments(newChecked);
  };

  const onCloseCandidateInvite = () => {
    calendarInviteModalToggle();
    // if (accountManagerAndCandidateRecruiterSame && !fromEdit) {
    //   updateClientCalendarModal(true);
    // }
  };

  return (
    <div>
      <Sidedrawer toggle={() => {}} isOpen={calendarInviteModal}>
        <div className="mb-3">
          <DrawerHeader
            prevClick={onCloseCandidateInvite}
            heading={
              fromEdit
                ? 'Update Candidate Calendar Invite'
                : 'Candidate Calendar Invite'
            }
            nextButtonView={false}
          />
        </div>
        <Form>
          <div className="jobOrderRevamp p-0 bg-transparent m-0">
            <div className="d-flex justify-content-between mb-2 contentGridLabel">
              <label>Interview Type</label>
              <h6>{type}</h6>
            </div>

            <div className="d-flex justify-content-between mb-2 contentGridLabel">
              <label>Candidate Name</label>
              <h6>{name}</h6>
            </div>

            <div className="d-flex justify-content-between mb-2 contentGridLabel">
              <label>From Name</label>
              <h6>
                {dataSummary?.getCandidateSummary?.recruitingOwner?.fullName ||
                  '--'}
              </h6>
            </div>

            <div className="d-flex justify-content-between mb-2 contentGridLabel">
              <label>From Email</label>
              <h6>
                {dataSummary?.getCandidateSummary?.recruitingOwner?.email}
              </h6>
            </div>
          </div>
          <CandidateBox
            pipeSelectedCandidateEmails={recievePipeSelectedEmails}
            candidateName={name}
            candidateId={candidateId}
          />

          <CandidateClendarInviteCombined
            setAdditionalFileAttachments={setAdditionalFileAttachments}
            onAdditionalFileAttachmentsHandler={
              onAdditionalFileAttachmentsHandler
            }
            additionalFileAttached={
              candidateInterviewEmailData?.additionalFiles
            }
            fromInterviewDetailCard={fromInterviewDetailCard}
            candidateId={candidateId}
            candidateName={name}
            reset={reset}
            calendarPrefilledValues={calendarPrefilledValues}
            candidateInterviewEmailData={candidateInterviewEmailData}
            fromEdit={fromEdit}
            setPipeSelectedFiles={recievePipeSelectedFiles}
            register={register}
            errors={errors}
            control={control}
            watch={watch}
            setValue={setValue}
          />

          <div className="text-right">
            {!dataSummary?.getCandidateSummary?.recruitingOwner
              ?.outLookToken ? (
              <CustomButton
                disabled={
                  !dataSummary?.getCandidateSummary?.recruitingOwner
                    ?.outLookToken
                }
                buttonText={`${fromEdit ? 'Resend' : 'Send'} Invite`}
                buttonColor={'primary'}
                loading={disableInviteBtn}
                buttonType="button"
              />
            ) : (
              <CustomButton
                disabled={
                  selectedEmails?.length &&
                  user?.id ===
                    dataSummary?.getCandidateSummary?.recruitingOwner?.id
                    ? false
                    : true || disableInviteBtn
                }
                buttonText={`${fromEdit ? 'Resend' : 'Send'} Invite`}
                buttonColor={'primary'}
                loading={disableInviteBtn}
                buttonType="button"
                buttonClick={
                  fromEdit
                    ? handleSubmit(onReSendInvite)
                    : handleSubmit(onSendInvite)
                }
              />
            )}
          </div>
        </Form>
      </Sidedrawer>
    </div>
  );
}
