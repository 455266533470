import { useMutation } from "@apollo/client";
import { FC, useContext, useEffect, useState } from "react";
import Select from "react-select";
import { toast } from "react-toastify";
import { Button, Col, Input, InputGroup, InputGroupAddon, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { AppContext } from "../../context";
import { InvoiceAttachmentModalProps } from "../../interfaces";
import DragDropFile from "../../Pages/candidate/candidate-components/DragDropFile";
import { INVOICE_ATTACHMENT_TYPE_OPTIONS, INVOICE_EMAIL_CONTENT, ROLES, bucketFolders, numbRegex } from "../../utils/constant";
import { maxDate, priceHandler, uploadFile } from "../../utils/helper";
import CustomButton from "../layout/CustomButton";
import { customSelectStyleNew } from "../styled/customSelect";
import { ADD_MUTATION, UPDATE_MUTATION } from "./gql";

const InvoiceAttachmentModal: FC<InvoiceAttachmentModalProps> = ({
  isOpen, refetch, editableRecord, placementStatus, placementId, toggle }) => {
  const { theme, userRoles } = useContext(AppContext)
  const [addMutation, { loading }] = useMutation(ADD_MUTATION)
  const [updateMutation, { loading: updating }] = useMutation(UPDATE_MUTATION)
  const [attachmentType, setAttachmentType] = useState<string>('');
  const [amount, setAmount] = useState<string>('')
  const [receivedDate, setReceivedDate] = useState<string>('')
  const [invoiceId, setInvoiceId] = useState<string>('')
  const [fileName, setFileName] = useState<string>('')
  const [activityReport, setActivityReport] = useState<string>('')
  const [selectedFile, setSelectedFile] = useState<any>();
  const [uploading, setUploading] = useState<boolean>(false)


  const uploadFiles = async () => {
    setUploading(true)
    const firstFileRes = selectedFile && await uploadFile(
      new File([selectedFile], selectedFile.name, { type: selectedFile.type }),
      bucketFolders.invoiceAttachments,
      false, "attachment"
    )
    setUploading(false)
    return firstFileRes?.source
  }
  const addInvoiceAttachment = async () => {
    const fileUrl = await uploadFiles()
    const response = await addMutation({
      variables: {
        placementId, placementStatus, input: {
          fileUrl,
          attachmentType, fileName, amount: amount?.replace(/[$,]/g, '')?.replace(/^\s+/, ''),
          invoiceId: invoiceId?.replace(/[#,]/g, '')?.replace(/^\s+/, ''),
          ...((attachmentType === "CHECK" || attachmentType === "WIRED") && { receivedDate, activityReport }),
        }
      }
    })
    if (response?.data) {
      toast.success("attachment added")
      toggle()
      refetch && window.location.reload();
      return
    }
    toast.error("something went wrong")
  }

  const amountHandler = (value?: string) => {
    if (value === '$   ') {
      setAmount("")
    } else {
      const filteredValue = value?.replace(/[$,]/g, '')?.replace(/^\s+/, '')
      if (numbRegex.test(filteredValue!) || !value) {
        let val = priceHandler(value?.replace(/[$,]/g, '')!);
        setAmount('$   ' + val!)
      }
    }
  }

  const numberHandler = (value?: string) => {
    console.log("value :: ", value)
    if (value === '#   ') {
      setInvoiceId("")
    } else {
      setInvoiceId('#   ' + value?.replace(/[#,]/g, '')?.replace(/^\s+/, '')!)
    }
  }

  const updateInvoiceAttachment = async () => {
    const fileUrl = await uploadFiles()
    const response = await updateMutation({
      variables: {
        input: {
          id: editableRecord?.id,
          ...(attachmentType && { attachmentType }),
          ...(fileName && { fileName }),
          ...(fileUrl && { fileUrl }),
          ...((attachmentType === "CHECK" || attachmentType === "WIRED") && { receivedDate, activityReport }),
          ...(amount && { amount: amount?.replace(/[$,]/g, '')?.replace(/^\s+/, '') }),
          ...(invoiceId && { invoiceId: invoiceId?.replace(/[#,]/g, '')?.replace(/^\s+/, '') }),
        },
        placementStatus
      }
    })
    if (response?.data) {
      toast.success("attachment updated")
      toggle()
      refetch && window.location.reload();
      return
    }
    toast.error("something went wrong")
  }

  useEffect(() => {
    if (isOpen) {
      if (editableRecord?.attachmentType) {
        setAttachmentType(editableRecord?.attachmentType?.toUpperCase()!)
        setFileName(editableRecord?.fileName!)
        setActivityReport(editableRecord?.activityReport!)
        setAmount('$   ' + priceHandler(editableRecord?.amount!.replace(/[$,]/g, ''))!)
        setInvoiceId('#   ' + editableRecord?.invoiceId!)
        setReceivedDate(editableRecord?.receivedDate!)
      }
    }
    else {
      setAmount("")
      setReceivedDate("")
      setAttachmentType("")
      setFileName("")
      setSelectedFile("")
      setActivityReport('')
    }
  }, [isOpen, editableRecord])

  return <Modal isOpen={isOpen} toggle={toggle} className="revampDialog revampDialogWidth" centered>
    <ModalHeader>{INVOICE_EMAIL_CONTENT}</ModalHeader>
    <ModalBody >
      <Col className="my-2">
        <Row >
          <Col xs="6" className="mb-4 ">
            <p>{"Attachment Type"} <span className="text-danger">*</span></p>
            <Select
              value={INVOICE_ATTACHMENT_TYPE_OPTIONS?.find(({ value }) => value === attachmentType)}
              styles={customSelectStyleNew(theme)}
              options={INVOICE_ATTACHMENT_TYPE_OPTIONS}
              onChange={(item) => {
                setAttachmentType(item!.value)
              }}
            />
          </Col >
          {(attachmentType === "CHECK" || attachmentType === "WIRED") ?
            <Col xs="6" className="mb-4">
              <div className="custom-amount-form-input">
                <p>Received Date <span className="text-danger">*</span></p>
                <Input
                  max={maxDate()}
                  value={receivedDate}
                  placeholder="Received Date"
                  type="date"
                  onChange={({ target: { value } }) => {
                    setReceivedDate(value)
                  }}
                />
              </div>
            </Col >
            : <Col xs="6" className="mb-4">
              <div className="custom-amount-form-input">
                <p>Amount <span className="text-danger">*</span></p>
                <Input
                  value={amount}
                  placeholder="Amount"
                  type="text"
                  onChange={({ target: { value } }) => amountHandler(value)}
                />
              </div>
            </Col >}
        </Row >

        <Row>
          <Col xs="6" className="mb-4">
            <div className="custom-amount-form-input">
              <p>File Name <span className="text-danger">*</span></p>
              <Input
                value={fileName}
                placeholder="file name"
                type="text"
                onChange={({ target: { value } }) => {
                  setFileName(value!)
                }
                }
              />
            </div>
          </Col >

          {(userRoles?.includes(ROLES.ADMIN) || userRoles?.includes(ROLES.EXECUTIVE))
            && attachmentType === "INVOICE" ?
            <Col xs="6" className="mb-4">
              <div className="custom-amount-form-input">
                <p>Invoice Number <span className="text-danger">*</span></p>
                <Input
                  value={invoiceId}
                  placeholder="invoice number"
                  type="text"
                  onChange={({ target: { value } }) => numberHandler(value)}
                />
              </div>
            </Col > :
            (attachmentType === "CHECK" || attachmentType === "WIRED") &&
            <>
              <Col>
                <p>Activity Report </p>
                <Input
                  type="month"
                  value={activityReport}
                  onChange={({ target: { value } }) => {
                    if (value)
                      setActivityReport(new Date(value).toISOString().substring(0, 7))
                    else
                      setActivityReport('')
                  }}
                  max={new Date().toISOString().substring(0, 7)}
                  styles={customSelectStyleNew(theme)}
                />
              </Col>
              <Col xs="6" className="mb-4">
                <div className="custom-amount-form-input">
                  <p>Amount <span className="text-danger">*</span></p>
                  <Input
                    value={amount}
                    placeholder="Amount"
                    type="text"
                    onChange={({ target: { value } }) => amountHandler(value)}
                  />
                </div>
              </Col >
            </>
          }
        </Row>
      </Col>

      <Col className="my-2">
        <p>{attachmentType === 'CHECK' ? "Front Image" : "File"} <span className="text-danger">*</span></p>
        <Label>{(selectedFile && fileName) ? `name: ${fileName}` : ''}</Label>
        <Col style={{ backgroundColor: "#F7FDFF" }}>
          <DragDropFile onUpload={(file: File) => {
            setSelectedFile(file)
            const extension = file?.name?.lastIndexOf('.') !== -1 ? file?.name?.substring(file?.name?.lastIndexOf('.') + 1) : '';

            if (attachmentType === "INVOICE")
              setFileName(`invoice-file.${extension}`)
            else if (attachmentType === "CHECK")
              setFileName(`check-image.${extension}`)
            else
              setFileName(file?.name)
          }} />
        </Col>
      </Col>
    </ModalBody >
    <ModalFooter ModalFooter >
      <Button className="modal-close bg-transparent border-0 text-dark" onClick={() => {
        toggle()
      }}>Cancel</Button>
      <CustomButton buttonType="button" buttonText={editableRecord?.id ? "Update" : "Add"} buttonColor={"primary"}
        loading={loading || uploading || updating}
        disabled={loading || uploading || updating || !attachmentType || !fileName || !amount || (attachmentType === "INVOICE" && !invoiceId) || ((attachmentType === "CHECK" || attachmentType === "WIRED") && !receivedDate) || (editableRecord?.id ? false : !selectedFile)}
        buttonClick={editableRecord?.id ? updateInvoiceAttachment : addInvoiceAttachment}
      />
    </ModalFooter >
  </Modal >
}

export default InvoiceAttachmentModal;