import React, { FC } from "react";
import { Link } from "react-router-dom";
import { Container } from "reactstrap";

import ErrorImage from "../assets/images/error-page.svg";

const Page404: FC = () => {
  return (
    <div className="page-not-found">
      <div className="inner">
        <Container>
          <img src={ErrorImage} alt="404 error" />

          <h1>Page Not Found</h1>

          <p>
            Uh ho! We can’t seem to find the page you are looking for. Lets go
            back where you will meet us.
          </p>

          <Link to="/">Go To Homepage</Link>
        </Container>
      </div>
    </div>
  );
};
export default Page404;
