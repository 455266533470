import { useMutation } from '@apollo/client';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import {
  Button,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  Spinner,
} from 'reactstrap';
import AddressInfo from '../../company/company-components/AddressInfo';
import { ADD_NEW_COMPANY_ADDRESS } from '../../company/gql';

import CLOSE_ICON from '../../../assets/images/close-icon.svg';

const CreateAddressModal = ({ open, toggle, companyId, ...props }) => {
  const { errors, handleSubmit, register, reset, watch ,setValue} = useForm();
  const [addNewCompanyAddress, { loading }] = useMutation(
    ADD_NEW_COMPANY_ADDRESS
  );
  const createCompanyAddress = async (address: any) => {
    address.companyId = companyId;
    address.country=address.countryCode;
    address.state=address.stateCode;
    delete address.countryCode;
    delete address.stateCode;
    let res = await addNewCompanyAddress({
      variables: { addressInput: { ...address } },
    });
    if (res?.data) {
      toggle();
      reset();
      toast.success('Address successfully added!');
      // props?.getCompanies();
      props?.preSelect(res?.data?.addNewAddress);
    }
  };

  useEffect(() => {
  }, []);
  return (
    <Modal
      isOpen={open}
      toggle={toggle}
      size="xl"
      className="revampDialog revampDialogAddress"
    >
      <div className="modalHeaderCustom d-flex align-items-center justify-content-between">
        <Button
          className="p-0 bg-transparent border-0 closeButton"
          onClick={toggle}
          style={{ zIndex: 100 }}
        >
          <img src={CLOSE_ICON} alt="close" />
        </Button>
      </div>

      <Form>
        <ModalBody>
          <AddressInfo
            watch={watch}
            register={register}
            errors={errors}
            companyId={companyId}
            setValue={setValue}
          />
        </ModalBody>
        <ModalFooter>
          <button onClick={toggle} className="buttonPrimary mr-3" type="button">
            Cancel
          </button>

          <button
            className="btn btn-primary"
            type="submit"
            onClick={handleSubmit(createCompanyAddress)}
          >
            {loading && <Spinner size="sm" className="mr-2" />}
            Save
          </button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default CreateAddressModal;
