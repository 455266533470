import { useMutation } from "@apollo/client";
import { formatDateUS } from "../../../utils/commonFn";
import {
  DISMISSAL_POPUP_NOTIFICATION,
  MARK_AS_READ,
} from "../gql";
import { ReactComponent as SVGClose } from "../../../assets/images/close.svg";
import { useEffect } from "react";
import { messageParsing } from "../../../utils/Temp-Helper";

export default function NotificationBar(props: any) {

  const [_markAsRead] = useMutation(MARK_AS_READ);
  // const [_dismissingNotification] = useMutation(DISMISSING_NOTIFICATION);
  const [_dismissalPopUpNotification] = useMutation(
    DISMISSAL_POPUP_NOTIFICATION
  );

  const markAsReadHandler = async (id: string) => {
    await _markAsRead({
      variables: { notificationInput: { id } },
    });
  };

  // const dismissingHandler = async (id: React.MouseEvent) => {
  //   await _dismissingNotification({
  //     variables: { notificationInput: { id } },
  //   });
  // };

  const dismissalNotificationPopUp = async (id: string) => {
    await _dismissalPopUpNotification({
      variables: { notificationId: id },
    });
  };

  useEffect(() => {
    setTimeout(() => {
      dismissalNotificationPopUp(props?.id);
    }, 3000);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  return (
    <div className="notifications d-flex">
      <div onClick={() => markAsReadHandler(props.id)} style={{ width: "95%" }}>
        <h6>{messageParsing(props)}</h6>
        <label className="m-0">
          {formatDateUS(props.date)} {new Date(props.date).toLocaleTimeString()}
        </label>
      </div>

      <button
        onClick={() => dismissalNotificationPopUp(props.id)}
        type="button"
      >
        <SVGClose />
      </button>
    </div>
  );
}
