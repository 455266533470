import { FC } from "react";
import EmptyBox from "../../../assets/images/empty.svg";
import CloseButton from "../../../assets/images/close.svg";

import "./style.scss";

type NODATAFOUND = {
  text?: string;
  toggle: Function;
};

const NoCompanyFoundInterseller: FC<NODATAFOUND> = ({ text, toggle }) => {
  return (
    <div className="company-no-found">
      <img
        src={CloseButton}
        alt="close"
        onClick={() => {
          toggle();
        }}
      />
      <div className="no-data-found-interseller-company">
        <img src={EmptyBox} alt="empty" />
        {text}
      </div>
    </div>
  );
};

export default NoCompanyFoundInterseller;
