import {  forwardRef, useState } from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
// components block
import ActionsLogs from './ActionsLogs';
import ContactEmailNotes from '../Pages/contacts/contact-components/ContactEmailNotes';
import CandidateEmailNote from '../Pages/candidate/candidate-components/CandidateEmailNote';
import { CandidateNotesList } from '../Pages/candidate/candidate-components/CandidateNotesList';
// interfaces block
import { CompensationNote } from '../Pages/candidate/candidate-components/CompensationNotes';
import { ParentRef } from '../Pages/agreements/interfaces';

const ActionLogsAndNotes = forwardRef<ParentRef | undefined,any>(({
  type,
  recordId,
  option,
  haveActionPermissions,
  companyContacts,
  isPrivatePermission = false,
  updateNotesRef
},ref) => {
  const [activeTab, setActiveTab] = useState('1');
  const toggle = (tab) => activeTab !== tab && setActiveTab(tab);
  return (
    <div className="secondaryNavTabs">
      <Nav tabs className="navTabsOverflow">
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '1' })}
            onClick={() => {
              toggle('1');
            }}
          >
            Action Logs
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '2' })}
            onClick={() => {
              toggle('2');
            }}
          >
            {type} Notes
          </NavLink>
        </NavItem>
        {(type === 'Contact' || type === 'Candidate') && (
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '3' })}
              onClick={() => {
                toggle('3');
              }}
            >
              {type} Email Notes
            </NavLink>
          </NavItem>
        )}
        <div className="d-flex align-items-center ml-2">
          <Link
            target="_blank"
            className='nav-link'
            to={{
              pathname: `/notes-es?${new URLSearchParams(option).toString()}`,
            }}
          >
            Note Search
          </Link>
        </div>
        {isPrivatePermission && type === 'Candidate' && (
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '4' })}
              onClick={() => {
                toggle('4');
              }}
            >
              Compensation Notes
            </NavLink>
          </NavItem>
        )}
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <div>
            <ActionsLogs
              actionableId={recordId}
              actionableType={type}
              haveActionPersmissions={haveActionPermissions}
            />
          </div>
        </TabPane>
        <TabPane tabId="2">
          <div>
            <CandidateNotesList
              type={type}
              recordId={recordId}
              haveActionPermissions={haveActionPermissions}
              companyContacts={type === 'Company' ? companyContacts : []}
              updateNotesRef={updateNotesRef}
            />
          </div>
        </TabPane>
        {(type === 'Contact' || type === 'Candidate') && (
          <TabPane tabId="3">
            <div>
              {type === 'Contact' ? (
                <ContactEmailNotes />
              ) : (
                <CandidateEmailNote recordId={recordId} type={type} />
              )}
            </div>
          </TabPane>
        )}
        <TabPane tabId="4">
          <div>
            <CompensationNote
              type={type}
              recordId={recordId}
              haveActionPermissions={haveActionPermissions}
              isPrivatePermission={isPrivatePermission}
            />
          </div>
        </TabPane>
      </TabContent>
    </div>
  )
})
export default ActionLogsAndNotes;
