import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import InputField from "../../../components/layout/InputField";
import Loader from "../../../components/layout/Loader";
import NoDataFound from "../../../components/layout/NoDataFound";
import Pagination from "../../../components/layout/Pagination";
import TableComponent from "../../../components/layout/TableComponent";
import { formatDateUS } from "../../../utils/commonFn";
import { PAGE_LIMIT } from "../../../utils/constant";
import { FETCH_CONTACT_HISTORY } from "../gql";
const TABLE_HEADER = [
  "Contact",
  "Old Company",
  "New Company",
  "MoveBy",
  "Date",
];
const ContactHistory = ({ contactId }) => {
  const location = useLocation();
  const page =
    new URLSearchParams(location.search || "").get("currentPage") || "1";
  const _currentPage: number = parseInt(page);
  const [currentPage, setCurrentPage] = useState(_currentPage);
  const [limit, setLimit] = useState(10);
  const [fetchContactHistory, { data, loading }] = useLazyQuery(
    FETCH_CONTACT_HISTORY
  );

  useEffect(() => {
    if (contactId) {
      fetchContactHistory({
        variables: { contactId, page: currentPage, limit },
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactId]);

  return (
    <div className="table-responsive mt-3">
      <TableComponent tableHeader={TABLE_HEADER}>
        {loading && (
          <tbody>
            <tr>
              <td colSpan={11}>
                <Loader loaderClass="sm-height " />
              </td>
            </tr>
          </tbody>
        )}
        {!data?.fetchContactHistory?.contactHistory?.length && (
          <tbody>
            <tr>
              <td colSpan={5} className="empty-table-td">
                <NoDataFound text="No History Found!" />
              </td>
            </tr>
          </tbody>
        )}
        <tbody>
          {data?.fetchContactHistory?.contactHistory?.map(
            (item: any, index: number) => (
              <tr key={index}>
                <td>{item?.contact?.fullName || "--"}</td>
                <td>{item?.oldCompany?.name}</td>
                <td>{item?.newCompany?.name}</td>
                <td>{item?.movedBy?.fullName}</td>
                <td>
                  {item?.createdAt ? formatDateUS(item?.createdAt) : "--"}
                </td>
              </tr>
            )
          )}
        </tbody>
      </TableComponent>
      <div>
        {data?.fetchContactHistory?.contactHistory?.length ? (
          <div className="users-pagination">
            <Pagination
              onPageChange={(pageClicked: number) => {
                setCurrentPage(pageClicked);
              }}
              pageCount={Math.ceil(data?.fetchContactHistory?.count / limit)}
              currentPage={currentPage}
            />

            <div className="d-flex align-items-center">
              <div className="pagination-display paginationItemsSelect">
                <p className="m-0">
                  {(currentPage - 1) * limit + 1} to{" "}
                  {Math.min(
                    currentPage * limit,
                    data?.fetchContactHistory?.count
                  )}{" "}
                  of {data?.fetchContactHistory?.count}
                </p>

                <div className="divider"></div>

                <label>Display</label>

                <InputField
                  label=""
                  inputtype="select"
                  selectItems={PAGE_LIMIT}
                  inputid="limit"
                  placeholder={limit !== 10 ? limit.toString() : "Set Limit"}
                  inputMethod={(value) => {
                    setCurrentPage(1);
                    setLimit(parseInt(value));
                  }}
                />
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ContactHistory;
