import React, { useState } from "react";
import { Col, Row, Tooltip } from "reactstrap";
import { formatDateUS } from "../../../utils/commonFn";
import { moneyFormatter } from "../../../utils/helper";
import "./style.scss";

const WidgetCard = ({ stats, sourceNote }: any) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <div className="companyWidget">
      <Row>
        <Col lg="6" md="6" sm="12" xs="12">
          <div className="d-flex justify-content-between mb-2 contentGridLabel">
            <label>Active job order</label>

            <div className="contentGrid">
              <h6>{stats?.totalActiveJobOrder || 0}</h6>
            </div>
          </div>
        </Col>

        <Col lg="6" md="6" sm="12" xs="12">
          <div className="d-flex justify-content-between mb-2 contentGridLabel">
            <label>Total Interviews</label>

            <div className="contentGrid">
              <h6>{stats?.totalInterviews || 0}</h6>
            </div>
          </div>
        </Col>

        <Col lg="6" md="6" sm="12" xs="12">
          <div className="d-flex justify-content-between mb-2 contentGridLabel">
            <label>Total Submitted</label>

            <div className="contentGrid">
              <h6>{stats?.totalSubmitted || 0}</h6>
            </div>
          </div>
        </Col>

        <Col lg="6" md="6" sm="12" xs="12">
          <div className="d-flex justify-content-between mb-2 contentGridLabel">
            <label>Total placements</label>

            <div className="contentGrid">
              <h6>{stats?.totalPlacement || 0}</h6>
            </div>
          </div>
        </Col>

        <Col lg="6" md="6" sm="12" xs="12">
          <div className="d-flex justify-content-between mb-2 contentGridLabel">
            <label>Last checkin date</label>

            <div className="contentGrid">
              <h6>
                {stats?.lastCheckInAt
                  ? formatDateUS(stats?.lastCheckInAt)
                  : "--"}
              </h6>
            </div>
          </div>
        </Col>

        <Col lg="6" md="6" sm="12" xs="12">
          <div className="d-flex justify-content-between mb-2 contentGridLabel">
            <label>Total billing</label>

            <div className="contentGrid">
              <h6>
                {stats?.totalBilling
                  ? moneyFormatter(stats?.totalBilling)
                  : 0.0}
              </h6>
            </div>
          </div>
        </Col>

        <Col lg="6" md="6" sm="12" xs="12">
          <div className="d-flex justify-content-between mb-2 contentGridLabel">
            <label>Last checkin recruiter</label>

            <div className="contentGrid">
              <h6>{stats?.lastCheckInRecruiter?.fullName || "--"}</h6>
            </div>
          </div>
        </Col>

        <Col lg="6" md="6" sm="12" xs="12">
          <div className="d-flex justify-content-between mb-2 contentGridLabel">
            <label>LinkedIn</label>

            <div className="contentGrid">
              <h6>
                <a
                  href={stats?.company?.linkedInUrl || "#"}
                  className=" UrlCollapse"
                  target={stats?.company?.linkedInUrl ? "blank" : ""}
                  title={stats?.company?.linkedInUrl || "--"}
                >
                  {stats?.company?.linkedInUrl || "--"}
                </a>
              </h6>
            </div>
          </div>
        </Col>

        <Col lg="6" md="6" sm="12" xs="12">
          <div className="d-flex justify-content-between mb-2 contentGridLabel">
            <label>Source Note</label>

            <div className="contentGrid">
              <h6>{sourceNote || "--"}</h6>
            </div>
          </div>
        </Col>

        <Col lg="6" md="6" sm="12" xs="12">
          <div className="d-flex justify-content-between mb-2 contentGridLabel">
            <label>Source</label>

            {stats?.company?.source && (
              <Tooltip
                placement="top"
                isOpen={tooltipOpen}
                target="TooltipExample"
                toggle={toggle}
              >
                {stats?.company?.source || "--"}
              </Tooltip>
            )}

            <div className="contentGrid">
              <h6 id="TooltipExample">{stats?.company?.source || "--"}</h6>
            </div>
          </div>
        </Col>

        <Col lg="6" md="6" sm="12" xs="12">
          <div className="d-flex justify-content-between mb-2 contentGridLabel">
            <label>Latest signed agreement date</label>

            <div className="contentGrid">
              <h6>
                {" "}
                {stats?.mostRecentSignedAgreementDate
                  ? formatDateUS(stats?.mostRecentSignedAgreementDate)
                  : "--"}
              </h6>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default WidgetCard;
