import { Fragment, useContext, useEffect, useState } from 'react';
import { Badge } from 'reactstrap';

import CustomButton from '../../components/layout/CustomButton';
import TableComponent from '../../components/layout/TableComponent';

import './style.scss';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  ADD_USER_WIDGET,
  FETCH_WIDGETS,
  FETCH_USER_WIDGET,
  UPDATE_USER_WIDGET,
  REMOVE_USER_WIDGET,
} from './gql';
import Loader from '../../components/layout/Loader';
import { AppContext } from '../../context';
import ConfirmPopUp from '../../components/ConfirmPopUp';
import { toast } from 'react-toastify';
import { formatDateUS } from '../../utils/commonFn';
import GoBack from '../../components/GoBack';
import NoDataFound from '../../components/layout/NoDataFound';
import { DASHBOARD_ROUTE } from '../../utils/constant';

const ACTIVE_WIDGETS_HEADER = [
  'Name',
  'Description',
  'privilege',
  'Started At',
  'position',
];

const EXECUTIVE_WIDGETS_HEADER = ['Name', 'Description', 'Show on dashboard'];

const groupByKey = (array: any[], key: string) => {
  return array.reduce((hash, obj) => {
    if (obj[key] === undefined) return hash;
    return Object.assign(hash, {
      [obj[key]]: (hash[obj[key]] || []).concat(obj),
    });
  }, {});
};

const CustomiseDashboard = (): JSX.Element => {
  const [fetchWidgets, { data, loading }] = useLazyQuery(FETCH_WIDGETS);
  const [fetchUserWidgets, { data: widgetsofUser, loading: widgetLoading }] =
    useLazyQuery(FETCH_USER_WIDGET);
  const [userWidgets, setUserWidgets] = useState<any>({});
  const [selectedWidgets, setSelectedWidgets] = useState<Array<string>>([]);
  const [addUserWidget] = useMutation(ADD_USER_WIDGET);
  const [updateWidget, { loading: _loading }] = useMutation(UPDATE_USER_WIDGET);
  const [removeUserWidget] = useMutation(REMOVE_USER_WIDGET);
  const [widgetRanks, setWidgetsRank] = useState<
    Array<{ widgetId: string; rank: number }>
  >([]);
  const { userRoles } = useContext(AppContext);
  const [activeWidget, setActiveWidget] = useState(false);
  const [activeWidgetId, setActiveWidgetId] = useState({
    id: null,
    name: null,
    active: false,
  });

  useEffect(() => {
    if (data && data.getAllWidgets) {
      setUserWidgets(groupByKey(data?.getAllWidgets, 'role'));
    }
  }, [data]);

  //this effect is use for check user widget
  useEffect(() => {
    if (widgetsofUser && widgetsofUser.getUserWidgets) {
      let widgets = widgetsofUser.getUserWidgets.map(
        (item: any) => item.widget.name
      );
      setSelectedWidgets([...widgets]);
      let widgetUser = widgetsofUser.getUserWidgets.map((widget: any) => ({
        widgetId: widget.id,
        rank: widget.rank,
      }));
      setWidgetsRank([...widgetUser]);
    }
  }, [widgetsofUser]);

  const addWidget = async () => {
    try {
      if (activeWidgetId.active) {
        await addUserWidget({ variables: { widgetId: activeWidgetId.id } });
      }
      if (!activeWidgetId.active) {
        removeUserWidget({ variables: { widgetId: activeWidgetId.id } });
      }
      setActiveWidget(false);
      fetchWidgets();
      fetchUserWidgets();
    } catch (error) {
      console.log(error);
    }
  };

  const updateWidgetRank = async () => {
    try {
      await updateWidget({
        variables: { payload: widgetRanks },
      });
      fetchUserWidgets();
      setTimeout(() => {
        toast.success('Updated Successfully!');
      }, 500);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchWidgets();
    fetchUserWidgets();
    // eslint-disable-next-line
  }, []);

  if (loading || widgetLoading) {
    return <Loader />;
  }

  return (
    <div className="admin-tabs team-tabs">
      <GoBack url={DASHBOARD_ROUTE} />

      <div className="primaryHeading primaryHeaderSpacing d-flex align-items-center justify-content-between w-auto">
        <h6 className="m-0">Dashboard Widgets</h6>
        {/* Comment Ref: ATS-737 */}
        {/* {userRoles?.length && !userRoles.includes('ADMIN') && ( */}
        <CustomButton
          buttonText="Save Changes"
          buttonType="button"
          buttonColor="primary"
          className="save-changes"
          loading={_loading}
          disabled={_loading}
          buttonClick={updateWidgetRank}
        />
        {/* )} */}
      </div>
      {((userRoles?.length === 1 && userRoles.includes('ADMIN')) ||
        !userRoles?.length) && (
        <div className="for-position-multiselect">
          <NoDataFound text="No Active Widgets!" />
        </div>
      )}
      {widgetsofUser?.getUserWidgets &&
      widgetsofUser?.getUserWidgets?.length ? (
        <>
          <div className="section-header m-0">
            <h5>Active Widgets</h5>
          </div>

          <div className="table-responsive">
            <TableComponent tableHeader={ACTIVE_WIDGETS_HEADER}>
              <tbody>
                {widgetsofUser?.getUserWidgets &&
                  []
                    .concat(widgetsofUser?.getUserWidgets)
                    .sort(function (a: any, b: any) {
                      return a.rank - b.rank;
                    })
                    .map((item: any, index: number) => (
                      <tr key={index}>
                        <td>{item?.widget?.name}</td>
                        <td>{item?.widget?.description}</td>
                        <td>{item?.widget?.role}</td>
                        <td className='badge-dark-text'>
                          <Badge color="light" className="text-dark">
                            {item?.createdAt && formatDateUS(item?.createdAt)}
                          </Badge>
                        </td>
                        <td>
                          <select
                            className="form-control position"
                            onChange={(e) => {
                              let userWidgets =
                                widgetRanks &&
                                widgetRanks.filter(
                                  (value) => value.widgetId !== item.id
                                );
                              setWidgetsRank([
                                ...userWidgets,
                                {
                                  widgetId: item.id,
                                  rank: parseInt(e.target.value),
                                },
                              ]);
                            }}
                            value={
                              (widgetRanks &&
                                widgetRanks.length &&
                                widgetRanks.filter(
                                  (widgetRank) =>
                                    widgetRank.widgetId === item.id
                                )[0]?.rank) ||
                              0
                            }
                          >
                            {widgetsofUser?.getUserWidgets &&
                              widgetsofUser?.getUserWidgets.map(
                                (value: any, index: number) => (
                                  <option value={index + 1}>{index + 1}</option>
                                )
                              )}
                          </select>
                        </td>
                      </tr>
                    ))}
              </tbody>
            </TableComponent>
          </div>
        </>
      ) : (
        ''
      )}
      <div className="level-tables mt-4">
        {Object.keys(userWidgets) && Object.keys(userWidgets).length ? (
          // eslint-disable-next-line array-callback-return
          Object.keys(userWidgets).map((item: string, index: number) => {
            if (userRoles?.includes(item.toUpperCase()) || item === 'User') {
              return (
                <Fragment key={index}>
                  <div className="section-header p-0 mb-2 mt-4 ">
                    <h5>{`${item} Level Widgets`}</h5>
                  </div>
                  <div className="table-responsive">
                    <TableComponent tableHeader={EXECUTIVE_WIDGETS_HEADER}>
                      <tbody>
                        {userWidgets[item] &&
                          userWidgets[item].map((_item: any, index: number) => (
                            <tr key={index}>
                              <td>{_item.name}</td>
                              <td>{_item.description}</td>
                              <td>
                                <label className="switch">
                                  <input
                                    type="checkbox"
                                    onClick={(e: any) => {
                                      setActiveWidget(!activeWidget);
                                      setActiveWidgetId({
                                        id: _item.id,
                                        active: e.target.checked,
                                        name: _item.name,
                                      });
                                    }}
                                    checked={selectedWidgets?.includes(
                                      _item.name
                                    )}
                                  />
                                  <span className="slider"></span>
                                </label>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </TableComponent>
                  </div>
                </Fragment>
              );
            }
          })
        ) : (
          <></>
        )}
        <ConfirmPopUp
          confirmText={`Are you sure you want to ${
            activeWidgetId.active ? 'activate' : 'deactivate'
          }`}
          isOpen={activeWidget}
          toggle={setActiveWidget}
          confirmAction={addWidget}
          modalHeading={`${
            activeWidgetId.active ? 'Activate Widget' : 'Deactivate Widget'
          }`}
          btnText="Confirm"
          btnColor="primary"
          extraProp={activeWidgetId?.name}
          secondaryText="widget?"
          className="revampDialog revampDialogWidth"
        />
      </div>
    </div>
  );
};

export default CustomiseDashboard;
