import { useQuery } from "@apollo/client";
import { useState } from "react";
import EDIT_ICON from '../../assets/images/edit-icon-menu.svg';
import { ADD, EMAIL_TEMPLATES } from "../../constants";
import { IEmailTemplates } from "../../interfaces";
import { ADMIN_ROUTE, EMAIL_TEMPLATES_HEADERS } from "../../utils/constant";
import GoBack from "../GoBack";
import Loader from "../layout/Loader";
import NoDataFound from "../layout/NoDataFound";
import TableComponent from "../layout/TableComponent";
import EmailTemplateEditDrawer from "./Drawer";
import { GET_ALL_EMAIL_TEMPLATES } from "./gql";
import AddEmailTemplateModal from "./AddModal";

const AllEmailTemplates = () => {
  const [editTemplate, setEditTemplate] = useState<boolean>(false);
  const [addTemplate, setAddTemplate] = useState<boolean>(false);
  const [emailTemplates, setEmailTemplates] = useState<IEmailTemplates[]>();
  const [editRecord, setEditRecord] = useState<IEmailTemplates>();
  const { loading, refetch } = useQuery(GET_ALL_EMAIL_TEMPLATES, {
    onCompleted: (data) => setEmailTemplates(data?.allEmailTemplates?.emailTemplates)
  })
  return (
    <>
      <div className="admin-tabs team-tabs">
        <GoBack url={ADMIN_ROUTE} />

        <div className="primaryHeaderSpacing primaryHeading d-flex justify-content-between align-items-center">
          <h5 className="m-0">{EMAIL_TEMPLATES}</h5>

          <button
            type="button"
            onClick={() => setAddTemplate(!addTemplate)}
            className="buttonGenericStyle filledButton"
          >
            {ADD}
          </button>
        </div>

        <div className="mr-3">
          <TableComponent tableHeader={EMAIL_TEMPLATES_HEADERS}>
            <tbody>
              {loading &&
                <tr>
                  <td colSpan={12}>
                    <Loader loaderClass="sm-height " />
                  </td>
                </tr>
              }

              {!loading && emailTemplates?.length ?
                emailTemplates.map(({ id, module, subject, template, updatedAt }) => {
                  return (
                    <tr>
                      <td>{module}</td>
                      <td>
                        <div className='text-truncate max-800 max-200' >
                          {subject}</div>
                      </td>
                      <td>
                        <div className='text-truncate max-800 max-200' >
                          {template.replace(/<\/?[^>]+(>|$)/g, "")}</div>
                      </td>
                      <td className="text-truncate max-200">
                        {updatedAt}
                      </td>
                      <td>
                        <button
                          onClick={async () => {
                            setEditRecord({ id, module, subject, template, updatedAt })
                            setEditTemplate(!editTemplate)
                          }}
                          className="iconButtonAction"
                        >
                          <img src={EDIT_ICON} alt="Edit" />
                        </button>
                      </td>
                    </tr>
                  );
                }
                ) : !loading && !emailTemplates?.length && (
                  <tr>
                    <td colSpan={5}>
                      <div className="for-position-multiselect">
                        <NoDataFound text="Email Templates Not Found!" />
                      </div>
                    </td>
                  </tr>)}
            </tbody>
          </TableComponent>
        </div>
      </div>

      <EmailTemplateEditDrawer isOpen={editTemplate} refetch={refetch} toggle={() => setEditTemplate(false)} editRecord={editRecord} />
      <AddEmailTemplateModal isOpen={addTemplate} toggle={() => setAddTemplate(!addTemplate)} refetch={refetch} />
    </>
  )
}
export default AllEmailTemplates