import { useLazyQuery, useMutation } from "@apollo/client";
import { useCallback, useContext, useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { toast } from "react-toastify";
import GoBack from "../../components/GoBack";
import CustomButton from "../../components/layout/CustomButton";
import Loader from "../../components/layout/Loader";
import TableComponent from "../../components/layout/TableComponent";
import { compactObject, formatDateUS } from "../../utils/commonFn";
import Pagination from "../../components/layout/Pagination";
import {
  Badge,
  Button,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  Tooltip,
} from "reactstrap";
import InputField from "../../components/layout/InputField";
import deleteIcon from "../../assets/images/delete-field.svg";

import { COMPANY_DETAILS_ROUTE, MergeCompanyType, PAGE_LIMIT } from "../../utils/constant";
import {
  CREATE_MERGE_COMPANIES,
  GET_MERGE_COMPANIES,
  GET_PLACEMENTS_BY_COMPANY_ID,
  MERGE_COMPANY_STATUS_CHANGE,
} from "./gql";
import { Controller, useForm } from "react-hook-form";
import NoDataFound from "../../components/layout/NoDataFound";
import AsyncCompanyDropdown from "../../components/AsyncCompanySelect";
import AsyncMergeCompanies from "../../components/AsyncMergeCompanies";
import ConfirmPopUp from "../../components/ConfirmPopUp";
import { DELETE_MERGE_COMPANY } from "./gql/index";
import { AppContext } from "../../context";

import CLOSE_ICON from "../../assets/images/close-icon.svg";

const MERGE_COMPANY_HEADER = [
  "Source Company Name",
  "Destination Company Name",
  "Time Inserted",
  "Time Merged",
  "Status",
  "Note",
  "Action",
];

const MergeCompanies = ({ location, history }: RouteComponentProps) => {
  const page = new URLSearchParams(location.search).get("currentPage") || "1";
  const _currentPage: number = parseInt(page);
  const [currentPage, setCurrentPage] = useState(_currentPage);
  const { userRoles } = useContext(AppContext);
  const [limit, setLimit] = useState(10);
  const [searchParams, setSearchParams] = useState<string>("");
  const [statusFilter, setStatusFilter] = useState<string>("");
  const [selectedCompanyName, setSelectedCompanyName] = useState<string>("");
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const {
    errors,
    handleSubmit,
    register,
    watch,
    control,
    getValues,
    setValue,
  } = useForm();
  const [actionConfirm, setCompanyDeleteConfirm] = useState(false);
  const [actionId, setDeleteActionId] = useState("");
  const [companyId, setCompanyId] = useState("");
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle1 = () => setTooltipOpen(!tooltipOpen);

  const [mergeActionConfirm, setCompanyMergeConfirm] = useState(false);
  const [mergeActionId, setMergeActionId] = useState("");
  const [placementsData, setPlacementsData] = useState([]);

  const [fetchMergeCompanies, { data, loading, error }] =
    useLazyQuery(GET_MERGE_COMPANIES);

  const [
    getPlacementsByCompanyId,
    { data: placements, loading: placementLoading },
  ] = useLazyQuery(GET_PLACEMENTS_BY_COMPANY_ID);

  const [_deleteAction] = useMutation(DELETE_MERGE_COMPANY);

  const [_mergeAction] = useMutation(MERGE_COMPANY_STATUS_CHANGE);

  const [_createMergeCompanies, { loading: waiting, error: createError }] =
    useMutation(CREATE_MERGE_COMPANIES);

  const _fetchMergeCompanies = () => {
    try {
      setQueryParams();
      fetchMergeCompanies({
        variables: {
          limit,
          page: currentPage,
          searchText: searchParams,
          statusSearch: statusFilter,
          companyId: selectedCompanyName,
        },
      });
    } catch (error) {}
  };

  const createMergeCompany = async (values: any) => {
    values.sourceCompanyId = values.sourceCompanyId?.value;
    values.destinationCompanyId = values.destinationCompanyId?.value;

    let res = await _createMergeCompanies({ variables: { ...values } });
    if (res?.data) {
      toast.success("Request successfully created!");
      toggle();
      _fetchMergeCompanies();
    }
    if (createError && createError?.graphQLErrors) {
      toast.error(createError?.graphQLErrors[0]?.message);
      toggle();
    }
  };

  const setQueryParams = () => {
    const criteria = compactObject({
      currentPage,
      searchParams,
      statusFilter,
    });
    const urlQueryParams: any = [];
    if (Object.keys(criteria).length) {
      for (const key in criteria) {
        if (
          Object.prototype.hasOwnProperty.call(criteria, key) &&
          criteria[key]
        ) {
          urlQueryParams.push(`${key}=${criteria[key]}`);
        }
      }
      history.push(`/merge-company?${urlQueryParams.join("&")}`);
    }
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const _fetchPlacementByCompanies = useCallback(
    async (id: string) => {
      try {
        id &&
          getPlacementsByCompanyId({
            variables: {
              companyId: id,
            },
          });

        setPlacementsData(placements);
      } catch (error) {}
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getPlacementsByCompanyId]
  );

  useEffect(() => {
    companyId && _fetchPlacementByCompanies(companyId);
  }, [_fetchPlacementByCompanies, companyId]);

  const confirmAction = async () => {
    const response = await _deleteAction({
      variables: { mergeCompanyId: actionId },
    });
    if (response.data) {
      toast.success("Successfully deleted!");
      setCompanyDeleteConfirm(false);
      _fetchMergeCompanies();
    } else {
      setCompanyDeleteConfirm(false);
      // toast.error(deleteMergeCompanyError?.message);
    }
  };

  const mergeCompanyConfirmation = async () => {
    const response = await _mergeAction({
      variables: { mergeCompanyId: mergeActionId },
    });
    if (response.data) {
      toast.success("Successfully merged");
      setCompanyMergeConfirm(false);
      _fetchMergeCompanies();
    } else {
      setCompanyMergeConfirm(false);
      // toast.error(deleteMergeCompanyError?.message);
    }
  };

  const customStyles = {
    control: (styles, provided) => ({
      ...styles,

      fontSize: 12,
    }),

    option: (provided, state) => ({
      ...provided,
      fontSize: 12,
    }),

    container: (provided, state) => ({
      ...provided,
      fontSize: 12,
    }),
  };

  useEffect(() => {
    _fetchMergeCompanies();
    // eslint-disable-next-line
  }, [statusFilter, selectedCompanyName, currentPage, limit]);

  useEffect(() => {
    placements &&
      !placementLoading &&
      setPlacementsData(placements.getPlacementsByCompanyId);
  }, [placementLoading, placements]);

  return (
    <div className="admin-tabs team-tabs">
      <GoBack url="/admin" />

      <div className="primaryHeading primaryHeaderSpacing mobileResponsiveFlexAlign justify-content-between">
        <h5 className="m-0">Merge Companies</h5>

        <div className="search-filter">
          <div className="filter-select d-flex">
            <button
              className="buttonGenericStyle filledButton mr-3"
              onClick={() => {
                toggle();
              }}
              type="button"
            >
              Merge Companies
            </button>

            <div className="filter-by">
              <div className="mr-4">
                <AsyncMergeCompanies
                  placeholder="Destination Company"
                  width={"20rem"}
                  onChange={(value) => {
                    setSelectedCompanyName(value);
                    setCurrentPage(1);
                    setLimit(25);
                  }}
                />
              </div>

              <InputField
                inputtype="select"
                selectItems={MergeCompanyType}
                inputid="status"
                placeholder="Status"
                inputMethod={(value) => {
                  setStatusFilter(value);
                  setCurrentPage(1);
                  setLimit(25);
                }}
                inputValue={statusFilter}
              />

              <button
                className="buttonGenericStyle filledButton ml-3"
                onClick={() => {
                  setSearchParams("");
                  setStatusFilter("");
                  setSelectedCompanyName("");
                }}
              >
                Clear
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="table-responsive">
        <TableComponent tableHeader={MERGE_COMPANY_HEADER}>
          {loading && (
            <tbody>
              <tr>
                <td colSpan={7}>
                  <Loader loaderClass="sm-height " />
                </td>
              </tr>
            </tbody>
          )}
          {!loading && !data?.getMargeCompanies?.mergeCompanies?.length && (
            <tbody>
              <tr>
                <td colSpan={7} className="empty-table-td">
                  <NoDataFound text="No Merge Company Found!" />
                </td>
              </tr>
            </tbody>
          )}
          <tbody>
            {data?.getMargeCompanies?.mergeCompanies?.map(
              (item: any, index: number) => (
                <tr key={index}>
                  <td>
                    {item?.sourceCompanyId && (
                      <div>
                        {item?.status === "MERGED" ? (
                          <div className="text-danger text-truncate max-200">
                            {item.sourceCompanyName}
                          </div>
                        ) : (
                          <div
                            className="text-info cursor-pointer text-truncate max-200"
                            onClick={() => {
                              history.push(
                                `${COMPANY_DETAILS_ROUTE}/${item?.sourceCompanyId}`
                              );
                            }}
                          >
                            {item.sourceCompanyName}
                          </div>
                        )}
                      </div>
                    )}
                  </td>
                  <td>
                    <div
                      className="text-info cursor-pointer text-truncate max-200"
                      onClick={() => {
                        history.push(
                          `/company-details/${item?.destinationCompanyId}`
                        );
                      }}
                    >
                      {item.destinationCompanyName}
                    </div>
                  </td>
                  <td>
                    {" "}
                    {item?.createdAt
                      ? formatDateUS(item?.createdAt)
                      : "--"}{" "}
                    {item?.createdAt
                      ? new Date(item?.createdAt).toLocaleTimeString()
                      : "--"}
                  </td>{" "}
                  {item.status === "PENDING" ? (
                    <td>{"--"}</td>
                  ) : (
                    <td>
                      {" "}
                      {item?.mergedAt
                        ? formatDateUS(item?.mergedAt)
                        : "--"}{" "}
                      {item?.mergedAt
                        ? new Date(item?.mergedAt).toLocaleTimeString()
                        : "--"}
                    </td>
                  )}
                  <td>
                    <Badge
                      color={
                        item.status === "MERGED"
                          ? "success"
                          : item.status === "PENDING"
                          ? "info"
                          : item.status === "ERROR"
                          ? "danger"
                          : ""
                      }
                      className="text-capitalize"
                    >
                      {item.status}
                    </Badge>
                  </td>
                  <td>
                    <div
                      className="text-ellipsis"
                      title={item.mergeNotes || "--"}
                    >
                      {" "}
                      {item.mergeNotes || "--"}
                    </div>
                  </td>
                  <td className="d-flex align-items-center">
                    {item.status === "PENDING" &&
                    (userRoles?.includes("ADMIN") ||
                      userRoles?.includes("EXECUTIVE")) ? (
                      <Button
                        onClick={() => {
                          setCompanyMergeConfirm(true);
                          setMergeActionId(item?.id);
                        }}
                        className="bg-transparent border-0 for-link-theme-color"
                      >
                        Merge
                      </Button>
                    ) : (
                      <Button
                        className="bg-transparent border-0 for-link-theme-color"
                        disabled={true}
                      >
                        Merge
                      </Button>
                    )}

                    {item.status === "PENDING" &&
                    (userRoles?.includes("ADMIN") ||
                      userRoles?.includes("EXECUTIVE")) ? (
                      <Button
                        onClick={() => {
                          setCompanyDeleteConfirm(true);
                          setDeleteActionId(item?.id);
                        }}
                        className="my-auto bg-transparent border-0 w-auto d-flex justify-content-center align-items-center"
                      >
                        <img src={deleteIcon} alt="" width="20" height="20" />
                      </Button>
                    ) : (
                      <Button
                        onClick={() => {
                          setCompanyDeleteConfirm(true);
                          setDeleteActionId(item?.id);
                        }}
                        disabled={true}
                        className="my-auto bg-transparent border-0 w-auto d-flex justify-content-center align-items-center"
                      >
                        <img src={deleteIcon} alt="" width="20" height="20" />
                      </Button>
                    )}
                  </td>
                </tr>
              )
            )}
          </tbody>
        </TableComponent>
      </div>

      {data?.getMargeCompanies?.mergeCompanies.length > 0 && (
        <div className="users-pagination">
          <Pagination
            onPageChange={(pageClicked: number) => {
              setCurrentPage(pageClicked);
              history.push(`/merge-company?currentPage=${pageClicked}`);
            }}
            pageCount={Math.ceil(data?.getMargeCompanies?.count / limit)}
            currentPage={currentPage}
          />

          <div className="d-flex align-items-center">
            <div className="pagination-display paginationItemsSelect">
              <p className="m-0">
                {(currentPage - 1) * limit + 1} to{" "}
                {Math.min(currentPage * limit, data?.getMargeCompanies?.count)}{" "}
                of {data?.getMargeCompanies?.count}
              </p>

              <div className="divider"></div>

              <label>Display</label>

              <InputField
                label=""
                inputtype="select"
                selectItems={PAGE_LIMIT.filter((item) => item.value !== limit)}
                inputid="limit"
                placeholder={limit.toString()}
                inputMethod={(value) => {
                  setCurrentPage(1);
                  setLimit(parseInt(value));
                }}
              />
            </div>
          </div>
        </div>
      )}
      {error?.graphQLErrors[0]?.message}

      <Modal
        isOpen={modal}
        toggle={toggle}
        className="revampDialog"
        id="merge-companies"
      >
        <div className="modalHeaderCustom d-flex align-items-center justify-content-between">
          <h5 className="m-0">Merge Companies</h5>

          <Button className="p-0 bg-transparent border-0" onClick={toggle}>
            <img src={CLOSE_ICON} alt="close" />
          </Button>
        </div>

        <Form onSubmit={handleSubmit(createMergeCompany)}>
          <ModalBody>
            <Label>
              Source Company<span className="text-danger">*</span>
              {!placementLoading && !!placementsData?.length && (
                <>
                  <span
                    id="TooltipExample4"
                    style={{
                      marginLeft: "5px",
                      fontWeight: "bold",
                      fontSize: "15px",
                      color: "red",
                    }}
                  >
                    !
                  </span>

                  <Tooltip
                    placement="top"
                    isOpen={tooltipOpen}
                    target="TooltipExample4"
                    toggle={toggle1}
                  >
                    Placements Found
                  </Tooltip>
                </>
              )}
            </Label>
            <Controller
              control={control}
              defaultValue={""}
              rules={{
                required: {
                  value: true,
                  message: "Source company is required!",
                },
              }}
              name="sourceCompanyId"
              render={({ onChange }) => (
                <AsyncCompanyDropdown
                  mergeCompany={true}
                  placeholder="Source Company name"
                  value={getValues()?.sourceCompanyId}
                  onChange={(value) => {
                    setValue("sourceCompanyId", value);
                    setCompanyId(value.value);
                  }}
                />
              )}
              styles={customStyles}
            />
            <small className="text-danger pt-2">
              {errors?.sourceCompanyId?.message}
            </small>

            <Label className="d-block mt-2">
              Destination Company<span className="text-danger">*</span>
            </Label>
            <Controller
              control={control}
              defaultValue={""}
              rules={{
                required: {
                  value: true,
                  message: "Destination company is required!",
                },
                validate: (vl) => {
                  const { value } = watch("sourceCompanyId");
                  if (value === vl?.value) {
                    return "Selected companies should be different!";
                  }
                },
              }}
              name="destinationCompanyId"
              render={({ onChange }) => (
                <AsyncCompanyDropdown
                  placeholder="Destination Company name"
                  value={getValues()?.destinationCompanyId}
                  onChange={(value) => {
                    setValue("destinationCompanyId", value);
                  }}
                  mergeCompany={true}
                />
              )}
              styles={customStyles}
            />
            <small className="text-danger pt-2">
              {errors?.destinationCompanyId?.message}
            </small>
            <FormGroup className="mt-2">
              <Label>Merge Notes</Label>
              <textarea
                name="mergeNotes"
                ref={register({ required: false })}
                placeholder="Merge notes..."
                rows={3}
                className="w-100 form-control"
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <div>
              <button
                onClick={toggle}
                className="buttonPrimary mr-3"
                type="button"
              >
                Cancel
              </button>
              <CustomButton
                buttonText="Merge Companies"
                buttonColor="primary"
                buttonType="submit"
                className="big-width"
                loading={waiting}
                disabled={waiting}
              />
            </div>
          </ModalFooter>
        </Form>
      </Modal>

      <ConfirmPopUp
        confirmText="Are you sure you want to delete this company from merged companies list?"
        isOpen={actionConfirm}
        toggle={setCompanyDeleteConfirm}
        confirmAction={confirmAction}
        modalHeading="Delete action"
        btnText="Delete"
        btnColor="primary"
        className="revampDialog revampDialogWidth"
      />

      <ConfirmPopUp
        confirmText="Are you sure you want to merge this company from merged companies list?"
        isOpen={mergeActionConfirm}
        toggle={setCompanyMergeConfirm}
        confirmAction={mergeCompanyConfirmation}
        modalHeading="Merge action"
        btnText="Confirm"
        btnColor="primary"
        className="revampDialog revampDialogWidth"
      />
    </div>
  );
};

export default MergeCompanies;
