import { useHistory } from "react-router-dom";
import { COMPANY_DETAILS_ROUTE, VIEW_CANDIDATE_ROUTE, VIEW_JOB_ORDER_ROUTE } from "../../../../utils/constant";
import { moneyFormatter } from "../../../../utils/helper";

const BillingType = ({ billingTypeCardProps }) => {
  const history = useHistory();
  return (
    <div>
      <h5>Basic Info</h5>
      <div className="d-flex justify-content-between mb-2 contentGridLabel">
        <label>Placement ID</label>
        <h6>{billingTypeCardProps?.placementId}</h6>
      </div>
      <div className="d-flex justify-content-between mb-2 contentGridLabel">
        <label>Candidate</label>
        <h6
          className="anchorColorText route-link"
          onClick={() => {
            history.push({
              pathname: `${VIEW_CANDIDATE_ROUTE}/${billingTypeCardProps?.candidateId}`,
            });
          }}
        >
          {billingTypeCardProps?.candidateName}
        </h6>
      </div>

      <div className="d-flex justify-content-between mb-2 contentGridLabel">
        <label>Company</label>
        <h6
          className="anchorColorText route-link"
          onClick={() => {
            history.push({
              pathname: `${COMPANY_DETAILS_ROUTE}/${billingTypeCardProps?.companyId}`
            });
          }}
        >{billingTypeCardProps?.companyName}</h6>
      </div>

      <div className="d-flex justify-content-between mb-2 contentGridLabel">
        <label>Position Title</label>
        <h6
          className="anchorColorText route-link"
          onClick={() => {
            history.push({
              pathname: `${VIEW_JOB_ORDER_ROUTE}/${billingTypeCardProps?.jobId}`,
            });
          }}
        >{billingTypeCardProps?.positionTitle}</h6>
      </div>

      <div className="d-flex justify-content-between mb-2 contentGridLabel">
        <label>Location</label>
        <h6>{billingTypeCardProps?.location}</h6>
      </div>

      <div className="d-flex justify-content-between mb-2 contentGridLabel">
        <label>Start Date</label>
        <h6>{billingTypeCardProps?.startDate}</h6>
      </div>

      <div className="d-flex justify-content-between mb-2 contentGridLabel">
        <label>Duration of Assignment</label>
        <h6>
          {billingTypeCardProps?.durationAssignment === 1
            ? `${billingTypeCardProps?.durationAssignment} week`
            : `${billingTypeCardProps?.durationAssignment} weeks`}{" "}
        </h6>
      </div>

      <div className="d-flex justify-content-between mb-2 contentGridLabel">
        <label>Less Employee Pay Rate</label>
        <h6>({moneyFormatter(billingTypeCardProps?.payRate || 0)}/hour)</h6>
      </div>

      <div className="d-flex justify-content-between mb-2 contentGridLabel">
        <label>Company Bill Rate</label>
        <h6>{moneyFormatter(billingTypeCardProps?.billRate || 0)}/hour</h6>
      </div>
    </div>
  );
};

export default BillingType;
