import { useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import {
  Form,
  FormGroup,
  Label,
} from 'reactstrap';
import CustomButton from '../../../../components/layout/CustomButton';
import {
  DrawerHeader,
  Sidedrawer,
} from '../../../../components/layout/Sidedrawer';
import { SUBMISSION_REJECTED } from '../../../candidate-submission/gql';

export default function RejectSubmission({
  isOpen,
  toggle,
  submissionId,
  refetch,
  applicantId,
}) {
  const { register, handleSubmit, errors } = useForm();

  const [submissionReject, { loading }] = useMutation(SUBMISSION_REJECTED);

  const declineSubmission = async (values) => {
    values.submissionId = submissionId;
    values.applicantId = applicantId;

    let res = await submissionReject({
      variables: { rejectSubmissionInput: values },
    });
    if (res?.data) {
      toast.info('Submission Rejected!');
      toggle();
      refetch();
    }
  };

  return (
    <Sidedrawer toggle={toggle} isOpen={isOpen}>
      <div className="mb-3">
        <DrawerHeader
          prevClick={() => toggle()}
          nextClick={handleSubmit(declineSubmission)}
          buttonText="Reject"
          heading="Reject Submission"
          prevButtonView={true}
          nextButtonView={false}
          loading={loading}
          disable={loading}
        />
      </div>
      <Form>
        <FormGroup>
          <Label className="text-14 text-blackele pb-3">
            Reject Reason<span className="text-danger">*</span>
          </Label>

          <textarea
            className="form-control"
            name="reason"
            rows={5}
            ref={register({
              required: { value: true, message: 'Reason must be define!' },
            })}
            placeholder="Candidate reject reason..."
          />
          <small className="text-danger">{errors?.reason?.message}</small>
        </FormGroup>

        <div className="text-right py-3">
          <CustomButton
            buttonText="Reject"
            buttonColor="primary"
            buttonType="submit"
            className="big-width"
            loading={loading}
            disabled={loading}
            buttonClick={handleSubmit(declineSubmission)}
          />
        </div>
      </Form>
    </Sidedrawer>
  );
}
