import { useLazyQuery, useMutation } from "@apollo/client";
import classnames from "classnames";
import { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Modal, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import CLOSE_ICON from "../../assets/images/close-tab-icon.png";
import GoBack from "../../components/GoBack";
import GoalSheet from "../../components/goal-sheet-report";
import CustomButton from "../../components/layout/CustomButton";
import Loader from "../../components/layout/Loader";
import { downloadSignedUrlFile } from "../../utils/commonFn";
import { GOAL_SHEET_REPORT, MONTHS_IN_YEAR, SETTINGS_ROUTE } from "../../utils/constant";
import Preview from "../job-order/job-order-components/document-preview/Preview";
import { GET_SIGNED_URL, REMOVE_TAB } from "../settings/gql";
import OpenNewSheetModal from "./OpenNewSheetModal";
import { GET_RECRUITER_FULL_NAME } from "./gql";

const GoalSheetReport = () => {
  const history = useHistory();
  const { id }: { id: string } = useParams();
  const [month, setMonth] = useState<string>()
  const [year, setYear] = useState<string>()
  const [tabs, setTabs] = useState<string[]>(JSON.parse(localStorage.getItem("goalSheetTabs")!))
  const [modal, setModal] = useState<boolean>(false)
  const [viewDocument, setViewDocument] = useState<boolean>()
  const [downloading, setLoading] = useState<boolean>(false);
  const [documentLink, setDocumentLink] = useState<string>('')
  const [previewUrl, setPreviewUrl] = useState<string>('')
  const [activeTab, setActiveTab] = useState<string>();
  const [getRecruiterFullNameQuery, { data, loading }] = useLazyQuery(GET_RECRUITER_FULL_NAME)
  const [getSignedURL] = useLazyQuery(GET_SIGNED_URL);
  const [removeTab] = useMutation(REMOVE_TAB)

  const toggleTab = (tab: string, month?: number, year?: number) => {
    setActiveTab(tab);
    history.push(`${GOAL_SHEET_REPORT}/${id}?month=${month}&year=${year}`)
    setMonth(month?.toString())
    setYear(year?.toString())
  };

  useEffect(() => {
    const month = Number(new URLSearchParams(history?.location?.search).get("month")!) > 9 ? new URLSearchParams(history?.location?.search).get("month") : `0${new URLSearchParams(history?.location?.search).get("month")}`
    setMonth(month!)
    setYear(new URLSearchParams(history?.location?.search).get("year")!)
    setActiveTab(`${tabs.findIndex((value) => value === `${month!}-${new URLSearchParams(history?.location?.search).get("year")!}`) + 1}`)
  }, [])

  useEffect(() => {
    if (id) {
      getRecruiterFullNameQuery({ variables: { id } })
    }
  }, [id, getRecruiterFullNameQuery])



  const SignedUrl = useCallback(async (url) => {
    const { data } = await getSignedURL({ variables: { url } })
    const { getSignedUrl } = data || {};
    const { signedUrl } = getSignedUrl || {};
    return signedUrl;
  }, [getSignedURL])

  return (
    <>
      <GoBack url={SETTINGS_ROUTE + '?activeTab=8'} />

      {loading ?
        <Loader />
        :
        (<>
          <div className="primaryHeading primaryHeaderSpacing mobileResponsiveFlexAlign justify-content-between" >
            <h5 className="m-0">{data?.getRecruiterFullName?.fullName} Goals / Actuals</h5>
            <div className="d-flex flex-wrap gap-10">
              <CustomButton
                disabled={!documentLink} className="buttonGenericStyle filledButton m-0"
                buttonClick={async () => {
                  setPreviewUrl(await SignedUrl(documentLink))
                  setViewDocument(true)
                }}
                buttonText="View Activity Report" />
              <CustomButton
                loading={downloading}
                buttonClick={() => {
                  setLoading(true);
                  downloadSignedUrlFile(documentLink, `${data?.getRecruiterFullName?.fullName}-activity-report.pdf`, setLoading)
                }}
                disabled={!documentLink || downloading}
                buttonText="Download Activity Report" />
            </div>
          </div>
          <div className="CandidateTabsWrapper">
            <Nav tabs>
              {tabs?.map((item, index) => {
                return (
                  <NavItem>
                    <div className="d-flex align-items-center">
                      <NavLink
                        className={classnames({ active: activeTab === `${index + 1}` }) + " mx-1"}
                        onClick={() => {
                          toggleTab(`${index + 1}`, Number(item.slice(0, 2)), Number(item.slice(3)));
                        }}
                      >
                        <span>{MONTHS_IN_YEAR[Number(item.slice(0, 2)) - 1]} {Number(item.slice(3))}</span>

                      </NavLink>
                      <button
                        className="bg-transparent border-0 p-0 mr-3" onClick={() => {
                          if (tabs.length !== 1) {
                            removeTab({
                              variables: {
                                removeGoalSheetTabDto: {
                                  month: tabs[index].slice(0, 2),
                                  year: tabs[index].slice(3),
                                  recruiterId: id
                                }
                              }, onCompleted: (data) => {
                                setTabs(data?.removeTab?.tabs)
                                localStorage.setItem(`goalSheetTabs`, JSON.stringify(data?.removeTab?.tabs))
                                index === tabs.length - 1 ?
                                  toggleTab(`${index}`, Number(tabs[index - 1].slice(0, 2)), Number(tabs[index - 1].slice(3))) :
                                  toggleTab(`${index + 1}`, Number(tabs[index + 1].slice(0, 2)), Number(tabs[index + 1].slice(3)))
                              }
                            })
                          }
                        }}>
                        <img src={CLOSE_ICON} alt="close" />
                      </button>
                    </div>
                  </NavItem>)
              })}

              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === `${tabs?.length! + 1}` })}
                  onClick={() => {
                    setModal(true)
                  }}
                >
                  More +
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId={`${activeTab}`} >
                <GoalSheet
                  setDocumentLink={setDocumentLink}
                  recruiterId={id}
                  month={month!} year={year!}
                />
              </TabPane>
            </TabContent>
          </div>
          <OpenNewSheetModal isOpen={modal} toggle={() => setModal(!modal)}
            setTabs={setTabs} tabs={tabs!} toggleTab={toggleTab} id={id} />
          <Modal
            isOpen={viewDocument}
            toggle={() => setViewDocument(!viewDocument)}
            centered={true}
            size="xl"
            className="revampDialog border-0"
          >
            <div className="pdf-viewer">
              <Preview
                documentUrl={previewUrl}
                documentName={`${data?.getRecruiterFullName?.fullName}-activity-report.pdf`}
                isDownload={true}
              />
            </div>
          </Modal>
        </>)}
    </>

  )
}
export default GoalSheetReport