import { useLazyQuery, useMutation } from "@apollo/client";
import { useContext, useEffect, useState } from "react";
import { withRouter } from "react-router";
import { toast } from "react-toastify";
import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Tooltip,
  UncontrolledDropdown,
} from "reactstrap";
import ConfirmPopUp from "../../components/ConfirmPopUp";
import GoBack from "../../components/GoBack";
import Loader from "../../components/layout/Loader";
import NoDataFound from "../../components/layout/NoDataFound";
import TableComponent from "../../components/layout/TableComponent";
import {
  downloadSignedUrlFile,
  formatDateUS,
  removingExtension,
  urlExtension,
} from "../../utils/commonFn";
import Preview from "../job-order/job-order-components/document-preview/Preview";
import ResumeUpload from "./candidate-components/ResumeUpload";
import {
  DELETE_CANDIDATE_RESUME,
  GET_CANDIDATE_DETAILS_SPECIFIC,
  GET_CANDIDATE_SUMMARY,
  GET_SIGNED_URL,
  GET_SINGLE_RESUME,
} from "./gql";

import INFO_ICON_5 from "../../assets/images/email-icon.svg";
import INFO_ICON_1 from "../../assets/images/info-icon-1.svg";
import LINKEDIN_IMG from "../../assets/images/social1.svg";
import INFO_ICON_4 from "../../assets/images/phone-icon.svg";
import INFO_ICON_6 from "../../assets/images/location-icon.svg";
import AddNotesModal from "../../components/AddNotesModal";
import { GET_NOTES } from "../company/gql";
import { AppContext } from "../../context";
import { ROLES, VIEW_CANDIDATE_ROUTE } from "../../utils/constant";

const CandidateDocPreview = ({ match, location }) => {
  const resumeId = new URLSearchParams(location.search).get("resumeId");

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [documentDetail, setDocumentDetail] = useState({
    url: "",
    name: "",
    driveUrl: "",
    id: "",
  });

  const [candidateId, setCandidateId] = useState("");
  const [noteData, setNoteData] = useState(null);
  const [activityNotes, setActivityNotes] = useState<boolean>(false);
  const { userRoles, user } = useContext(AppContext);
  const [compensationNote, setCompensationNote] = useState<boolean>(false);

  const [fileStatus, setFileStatus] = useState("");
  const [confirmPop, setConfirmPop] = useState(false);
  const [resume, setResume] = useState({ resumeId: "", name: "" });
  const [getNotes] = useLazyQuery(GET_NOTES);

  const [tooltipOpen1, setTooltipOpen1] = useState(false);
  const toggle1 = () => setTooltipOpen1(!tooltipOpen1);

  const [tooltipOpen2, setTooltipOpen2] = useState(false);
  const toggle2 = () => setTooltipOpen2(!tooltipOpen2);

  const [tooltipOpen3, setTooltipOpen3] = useState(false);
  const toggle3 = () => setTooltipOpen3(!tooltipOpen3);

  const [tooltipOpen4, setTooltipOpen4] = useState(false);
  const toggle4 = () => setTooltipOpen4(!tooltipOpen4);

  const [tooltipOpen5, setTooltipOpen5] = useState(false);
  const toggle5 = () => setTooltipOpen5(!tooltipOpen5);

  const [getDetails, { data, loading }] = useLazyQuery(
    GET_CANDIDATE_DETAILS_SPECIFIC
  );

  const _getDetails = () => {
    getDetails({ variables: { id: candidateId } });
    setFileStatus("");
  };

  const [_removeResume, { loading: deleting }] = useMutation(
    DELETE_CANDIDATE_RESUME
  );

  const [signedUrlGetter] = useMutation(GET_SIGNED_URL);
  const [getCandidateSummary, { data: dataSummary }] = useLazyQuery(
    GET_CANDIDATE_SUMMARY
  );
  
  const [getCandidateSingleResume, { data: getCandidateResumeData }] =
    useLazyQuery(GET_SINGLE_RESUME);

  const deleteCandidateResume = async () => {
    let res = await _removeResume({
      variables: { resumeId: resume?.resumeId },
    });
    if (res?.data) {
      toast.success("Resume Successfully Deleted!");
      setConfirmPop(false);
      _getDetails();
    }
  };

  useEffect(() => {
    let id = match.params.id;

    if (id) {
      setCandidateId(id);
      _getDetails();
    }
    getCandidateSummary({ variables: { id: candidateId } });

    if (resumeId) {
      getCandidateSingleResume({ variables: { resumeId } });
    }

    // eslint-disable-next-line
  }, [candidateId, resumeId]);

  useEffect(() => {
    if (getCandidateResumeData?.getSingleResume) {
      onOriginalResumeHandler(getCandidateResumeData?.getSingleResume);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getCandidateResumeData]);

  const onOriginalResumeHandler = async (item: {
    originalUrl: string;
    resumeFileName: string;
    id: string;
    pdfVersionUrl: string;
  }) => {
    if (urlExtension(item.originalUrl) === "pdf") {
      const res = await signedUrlGetter({
        variables: {
          remoteFilePath: item.originalUrl,
        },
      });
      if (res?.data?.getSignedUrl?.signedUrl) {
        setDocumentDetail({
          url: res?.data?.getSignedUrl?.signedUrl,
          name: item?.resumeFileName,
          driveUrl: "",
          id: item.id,
        });
        setFileStatus("Original");
      }
    } else {
      const res = await signedUrlGetter({
        variables: {
          remoteFilePath: item.pdfVersionUrl,
        },
      });

      if (res?.data?.getSignedUrl?.signedUrl) {
        const pdfVersionFileName = removingExtension(item?.resumeFileName);

        setDocumentDetail({
          url: res?.data?.getSignedUrl?.signedUrl,
          name: `${pdfVersionFileName}.pdf`,
          driveUrl: "",
          id: item.id,
        });
        setFileStatus("Original");
      }
    }
  };

  const onHighlightedResumeHandler = async (item: {
    originalUrl: string;
    resumeFileName: string;
    highlightUrl: string;
    id: string;
  }) => {
    setDocumentDetail({
      url: item?.originalUrl,
      name: item?.resumeFileName,
      driveUrl: item?.highlightUrl,
      id: item?.id,
    });
    setFileStatus("Highlighted");
  };

  const viewAndDownloadFileOriginal = async (item) => {
    if (urlExtension(item.originalUrl) === "pdf") {
      const res = await signedUrlGetter({
        variables: {
          remoteFilePath: item.originalUrl,
        },
      });
      if (res?.data?.getSignedUrl?.signedUrl) {
        setDocumentDetail({
          url: res?.data?.getSignedUrl?.signedUrl,
          name: item?.resumeFileName,
          driveUrl: "",
          id: item.id,
        });
        setFileStatus("Original");

        downloadSignedUrlFile(item.originalUrl, item?.resumeFileName);
      }
    } else {
      if (item?.pdfVersionrl) {
        const res = await signedUrlGetter({
          variables: {
            remoteFilePath: item.pdfVersionrl,
          },
        });
        if (res?.data?.getSignedUrl?.signedUrl) {
          setDocumentDetail({
            url: res?.data?.getSignedUrl?.signedUrl,
            name: item?.resumeFileName,
            driveUrl: "",
            id: item.id,
          });
          setFileStatus("Original");
        }
      }

      downloadSignedUrlFile(item.originalUrl, item?.resumeFileName);
    }
  };

  const toggleActivityNotes = () => setActivityNotes(!activityNotes);

  const _getNotes = () => {
    getNotes({
      variables: {
        id: candidateId,
        notePayload: { type: "Candidate", compensationNote: compensationNote },
        page: 1,
        limit: 3,
      },
    });
  };

  const isUserCandidateOwnerOrAdminExec = () => {
    if (
      user?.id === dataSummary?.getCandidateSummary?.recruitingOwner?.id ||
      userRoles?.includes(ROLES.ADMIN) ||
      userRoles?.includes(ROLES.EXECUTIVE)
    ) {
      return false;
    }
    return true;
  };

  return (
    <div className="admin-tabs team-tabs">
      <GoBack url={`${VIEW_CANDIDATE_ROUTE}/${candidateId}`} />

      <AddNotesModal
        toggleActivityNotes={toggleActivityNotes}
        activityNotes={activityNotes}
        noteableId={candidateId}
        reFetch={() => _getNotes()}
        type="Candidate"
        editData={noteData}
        resetField={setNoteData}
        isPrivateCandidate={data?.getCandidate?.isPrivateCandidate}
        setCompensationNote={setCompensationNote}
      />

      {loading ? (
        <Loader />
      ) : (
        <div>
          <div className="d-flex align-items-center primaryHeaderSpacing">
            <h5 className="m-0 mr-2">
              {data?.getCandidate?.fullName || "--"}
            </h5>

            <Badge
              color={
                data?.getCandidate?.status === "ACTIVE"
                  ? "success"
                  : data?.getCandidate?.status === "DO_NOT_CONTACT"
                  ? "danger"
                  : ""
              }
              className="text-capitalize"
            >
              {data?.getCandidate?.status ?? "--"}
            </Badge>
          </div>

          <div className="applicantDetail primaryHeaderSpacing">
            <div className="infoCardDivider">
              <a
                href={
                  data?.getCandidate?.linkedInUrl
                    ? data?.getCandidate?.linkedInUrl
                    : "#"
                }
                target={data?.getCandidate?.linkedInUrl ? "_blank" : ""}
                className="m-0 d-flex"
                rel="noreferrer"
              >
                <Tooltip
                  placement="top"
                  isOpen={tooltipOpen1}
                  target="TooltipExample1"
                  toggle={toggle1}
                >
                  Linkedin
                </Tooltip>
                <img
                  src={LINKEDIN_IMG}
                  alt="linkedin"
                  width="17px"
                  id="TooltipExample1"
                />
              </a>
            </div>

            <div className="divider"></div>

            <div className="infoCardDivider">
              <Tooltip
                placement="top"
                isOpen={tooltipOpen2}
                target="TooltipExample2"
                toggle={toggle2}
              >
                Company
              </Tooltip>
              <img
                src={INFO_ICON_1}
                alt="company"
                width="17px"
                id="TooltipExample2"
              />
              <label>{data?.getCandidate?.companyName || "--"}</label>
            </div>

            <div className="divider"></div>

            <div className="infoCardDivider">
              <Tooltip
                placement="top"
                isOpen={tooltipOpen3}
                target="TooltipExample3"
                toggle={toggle3}
              >
                Address
              </Tooltip>
              <img
                src={INFO_ICON_6}
                alt="address"
                width="17px"
                id="TooltipExample3"
              />
              <label>
                {data?.getCandidate?.streetAddress} {data?.getCandidate?.city}{" "}
                {"-"} {data?.getCandidate?.postalCode}
              </label>
            </div>

            <div className="divider"></div>

            <div className="infoCardDivider">
              <Tooltip
                placement="top"
                isOpen={tooltipOpen4}
                target="TooltipExample4"
                toggle={toggle4}
              >
                Phone
              </Tooltip>
              <img
                src={INFO_ICON_4}
                alt="phone"
                width="17px"
                id="TooltipExample4"
              />
              <label>
                <div className="d-flex align-items-center">
                  <a href={`tel:${data?.getCandidate?.phones}`} className="m-0">
                    {(data?.getCandidate?.phones?.length &&
                      data?.getCandidate?.phones
                        ?.filter(
                          (item: { isPrimary: boolean }) => item.isPrimary
                        )
                        ?.map(
                          (item: { phoneNumber: string }) => item.phoneNumber
                        )
                        .join(", ")) ||
                      "--"}
                  </a>
                </div>
              </label>
            </div>

            <div className="divider"></div>

            <div className="infoCardDivider d-flex align-items-center emailLengthWidth emailLengthWidthApproach">
              <Tooltip
                placement="top"
                isOpen={tooltipOpen5}
                target="TooltipExample5"
                toggle={toggle5}
              >
                Email
              </Tooltip>
              <img
                src={INFO_ICON_5}
                alt="emails"
                width="19px"
                id="TooltipExample5"
              />
              <label>
                <a
                  href={`mailto:${data?.getCandidate?.emails}`}
                  className="m-0"
                >
                  {(data?.getCandidate?.emails?.length &&
                    data?.getCandidate?.emails?.find(
                      (item: { isPrimary: boolean }) => item.isPrimary
                    )?.email) ||
                    (data?.getCandidate?.emails?.length &&
                      data?.getCandidate?.emails[0]?.email) ||
                    "--"}
                </a>
              </label>
            </div>
          </div>
        </div>
      )}

      {loading && <Loader />}
      <Row>
        <Col md="12">
          <Card className="agreement-details activity-card mt-0 border-0">
            <div className="section-header candidate-header ">
              <h5 className="m-0 font-weight-normal">Resume</h5>

              <button
                type="button"
                className="buttonGenericStyle filledButton"
                onClick={toggle}
                disabled={isUserCandidateOwnerOrAdminExec()}
              >
                Upload New
              </button>
            </div>
            <CardBody>
              <div className="table-responsive">
                <TableComponent tableHeader={["Name", "Uploaded At", ""]}>
                  <tbody>
                    {data?.getCandidate?.resume?.map((item, index) => (
                      <tr key={index}>
                        <td>
                          <div>
                            <span
                              className="font-weight-bold d-block text-dark pl-0 text-truncate"
                              style={{ width: "40ch" }}
                            >
                              {item.resumeFileName}
                            </span>
                            Uploaded:{" "}
                            {item?.updatedAt
                              ? formatDateUS(item.updatedAt)
                              : "--"}
                          </div>
                        </td>
                        <td>
                          {item?.createdAt
                            ? formatDateUS(item.createdAt)
                            : "--"}
                        </td>
                        <td>
                          <div className="d-flex justify-content-end">
                            <Button
                              className="border-0 bg-transparent color-default px-2"
                              size="sm"
                              style={{ backgroundColor: "#e9e9e9" }}
                              onClick={() => onOriginalResumeHandler(item)}
                              // disabled={!!documentDetail?.url}
                            >
                              Original
                            </Button>

                            <Button
                              className="border-0 bg-transparent color-default px-2"
                              size="sm"
                              // disabled={item?.fileType === "pdf" ? true : false}
                              onClick={() => onHighlightedResumeHandler(item)}
                            >
                              Highlighted
                            </Button>

                            <UncontrolledDropdown>
                              <DropdownToggle
                                caret
                                className="bg-transparent color-default border-0"
                                size="sm"
                              >
                                Actions
                              </DropdownToggle>
                              <DropdownMenu>
                                <DropdownItem
                                  className="text-sm text-muted"
                                  onClick={() => {
                                    viewAndDownloadFileOriginal(item);
                                  }}
                                >
                                  Download Original
                                </DropdownItem>

                                <DropdownItem
                                  disabled={isUserCandidateOwnerOrAdminExec()}
                                  className="text-sm text-muted"
                                  onClick={() => {
                                    setResume({
                                      resumeId: item?.id,
                                      name: item?.resumeFileName,
                                    });
                                    setConfirmPop(!confirmPop);
                                  }}
                                >
                                  Delete
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </TableComponent>
              </div>

              {!data?.getCandidate?.resume?.length && (
                <div className="empty-table-td">
                  <NoDataFound text="No Resume Found!" />
                </div>
              )}
            </CardBody>
          </Card>
        </Col>

        <ResumeUpload
          toggle={toggle}
          modal={modal}
          candidateId={candidateId}
          refetch={_getDetails}
          fileType=".doc, .docx,.pdf"
          driveUpload={true}
        />
      </Row>

      <Row>
        <Col md={activityNotes ? 8 : 12}>
          {fileStatus === "Original" && (
            <div className="pdf-viewer">
              <Preview
                documentName={documentDetail?.name}
                documentUrl={documentDetail?.url}
                isDownload={false}
                fileStatus={fileStatus}
              />
            </div>
          )}
          {fileStatus === "Highlighted" && (
            <div
              style={{ background: "#33475b", padding: "22px" }}
              // className="w-100"
            >
              {documentDetail?.driveUrl ? (
                <iframe
                  src={documentDetail?.driveUrl}
                  title="xyz"
                  width="1000"
                  height="1000"
                  style={{
                    width: "100%",
                    height: "45em",
                    display: "block",
                    border: "1px solid #aaa",
                  }}
                  frameBorder="0"
                  allowFullScreen
                ></iframe>
              ) : (
                <h4 className="mb-0 color-default d-flex justify-content-center align-items-center">
                  ===== Highlighted version is not available! =====
                </h4>
              )}
            </div>
          )}
        </Col>
      </Row>

      <ConfirmPopUp
        confirmText="Are you sure you want to delete"
        extraProp={resume?.name}
        secondaryText="Resume?"
        isOpen={confirmPop}
        toggle={() => {
          setConfirmPop(!confirmPop);
        }}
        confirmAction={deleteCandidateResume}
        modalHeading="Delete Resume"
        btnText="Delete"
        btnColor="primary"
        loading={deleting}
        className="deleteResume"
      />
    </div>
  );
};

export default withRouter(CandidateDocPreview);
