import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Col, Input, Label, Row } from 'reactstrap';
import InputField from '../../../components/layout/InputField';
import { CANDIDATE_ONLY, countries, COUNTRY_STATES_PROVINCE } from '../../../utils/constant';
import '../../company/company.scss';

const AddressInfo = (params: any) => {
  const {
    register,
    errors,
    watch,
    companyId,
    headQatar = true,
    givenStates,
    isEdit,
    defaultChecked = false,
    setValue,
    placementAddressInfo = false,
    addressObj,
    reset,
    addressId = '',
    ...props
  } = params || {}
  const { countryCode } = watch();
  const { pathname } = useLocation();
  const [countriesOption] = useState(() =>
    (pathname.includes('candidate') || props?.fromCandidate) ? [...countries, ...CANDIDATE_ONLY] : [...countries]
  );

  useEffect(() => {
    if (!placementAddressInfo) {
      setValue('countryCode', 'US');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!['US', 'CA', 'MX', null, undefined, ''].includes(countryCode)) {
      setValue('stateCode', undefined);
    }
  }, [countryCode, setValue]);
  return (
    <div>
      <div>
        {companyId && !addressId ? (
          <h5 className="m-0">New Address</h5>
        ) : companyId && addressId ? <h5 className="m-0">Update Address</h5> : (
          <div>
            {headQatar && (
              <Label className="m-0">Head Quarter/Primary address</Label>
            )}
          </div>
        )}
      </div>
      <div className="user-basic-form">
        <Row>
          {
            countryCode !== 'MX' ? (
              <Col xl="3" lg="6" xs="12">
                <InputField
                  inputtype="text"
                  isRequired={!!companyId}
                  label="Street"
                  inputid="streetAddress"
                  placeholder="Street"
                  inputRef={register({
                    required: {
                      value: !!companyId,
                      message: 'Street address is required!',
                    },
                  })}
                  errMsg={errors?.streetAddress?.message}
                />
              </Col>

            ) : (
              <>
                <Col xl="3" lg="6" xs="12">
                  <InputField
                    inputtype="text"
                    isRequired={!!companyId}
                    label="Address 1"
                    inputid="address1"
                    placeholder="Address 1"
                    inputRef={register({
                      required: {
                        value: !!companyId,
                        message: 'Address 1 is required!',
                      },
                    })}
                    errMsg={errors?.address1?.message}
                  />
                </Col>
                <Col xl="3" lg="6" xs="12">
                  <InputField
                    inputtype="text"
                    isRequired={!!companyId}
                    label="Address 2"
                    inputid="address2"
                    placeholder="Address 2"
                    inputRef={register({
                      required: {
                        value: !!companyId,
                        message: 'Address 2 is required!',
                      },
                    })}
                    errMsg={errors?.address2?.message}
                  />
                </Col>
              </>
            )
          }


          <Col xl={countryCode !== 'MX' ? "3" : "6"} lg="6" xs="12">
            <InputField
              inputtype="text"
              label="City"
              inputid="city"
              isRequired={true}
              placeholder="City"
              inputRef={register({
                required: {
                  value: true,
                  message: 'City is required!',
                },
              })}
              errMsg={errors?.city?.message}
            />
          </Col>

          <Col xl="4" lg="6" xs="12">

            <InputField
              inputid="stateCode"
              label={countryCode === 'CA' ? 'Province' : 'State'}
              disable={!['US', 'MX', 'CA', null, undefined, ''].includes(countryCode)}
              inputtype="select"
              selectItems={COUNTRY_STATES_PROVINCE(countryCode)}
              isRequired={['US', 'MX', 'CA', null, undefined, ''].includes(countryCode)}
              placeholder={`Please select any ${countryCode === 'CA' ? 'province' : 'state'}`}
              inputRef={register({
                required: {
                  value: ['US', 'CA', 'MX', null, undefined, ''].includes(countryCode),
                  message: `${countryCode === 'CA' ? 'Province' : 'State'} is required!`,
                },
              })}
              errMsg={errors?.stateCode?.message}
              {...register}
            />
          </Col>
          <Col xl="2" lg="6" xs="12">
            <InputField
              inputtype="text"
              label="Zip"
              inputid="zipCode"
              placeholder="Zip code"
              inputRef={register({
                required: {
                  value: false,
                  message: 'Zip code is required!',
                },
              })}
              errMsg={errors?.zipCode?.message}
            />
          </Col>
          <Col xl="6" lg="6" xs="12">
            <InputField
              label="Country"
              inputtype="select"
              selectItems={countriesOption}
              inputid="countryCode"
              isRequired
              placeholder="Please select any country"
              inputRef={register({
                required: {
                  value: true,
                  message: 'Country is required!',
                },
              })}
              errMsg={errors?.countryCode?.message}
            />
          </Col>

          {((defaultChecked || companyId) && !addressId) && (
            <Col xl="3" lg="6" xs="12">
              <div className="d-flex-field address-is-primary">
                <label>&nbsp;</label>
                <Label className="radio-container">
                  is Primary
                  <Input
                    defaultChecked={defaultChecked}
                    name="isPrimary"
                    type="checkbox"
                    innerRef={register()}
                  />
                  <span className="checkmark"></span>
                </Label>
              </div>
            </Col>
          )}
        </Row>
        <Row></Row>
      </div>
    </div>
  );
};

export default AddressInfo;