import { useContext, useEffect, useRef, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import Sidebar from '../Sidebar';
import '../../../Pages/admin/style.scss';
import { AppContext } from '../../../context';
import { Alert, Button } from 'reactstrap';
import {
  FETCH_NEW_NOTIFICATIONS,
  PENDING_ACTIONS_CATEGORIES,
  PENDING_ACTIONS_SCHEDULER,
} from '../../../Pages/notifications/gql';
import { useLazyQuery, useMutation } from '@apollo/client';
import NotificationBar from '../../../Pages/notifications/components/NotificationBar';
import { toast, ToastContainer } from 'react-toastify';
import Notifications from '../../../components/layout/Notification';
import io, { Socket } from 'socket.io-client';
import { DefaultEventsMap } from '@socket.io/component-emitter';
interface mainLayoutPropType {
  history?: RouteComponentProps;
  children: JSX.Element;
}

export const loginAsWithPrevious = async () => {
  const preToken = window.localStorage.previousToken;
  const previousSideBarItem = window.localStorage.previousSideBarItem
  const currentToken = window.localStorage.token;
  if (preToken && currentToken) {
    window.localStorage.removeItem('token');
    window.localStorage.removeItem('sideBarItem');
    window.localStorage.setItem('token', preToken);
    window.localStorage.setItem('sideBarItem', previousSideBarItem);
    window.localStorage.removeItem('previousToken');
    window.localStorage.removeItem('previousSideBarItem');
    window.location.reload();
  }
};

const MainLayout = ({ children, history }: mainLayoutPropType): JSX.Element => {

  const [isMenuActive, setIsMenuActive] = useState<boolean>(false);
  const [isOpen, setOpen] = useState<boolean>(false);
  const socket = useRef<Socket<DefaultEventsMap, DefaultEventsMap> | undefined>();
  const [pendingActionFromSockets, setPendingActionFromSockets] = useState(null);

  const { user } = useContext(AppContext);
  const page = new URLSearchParams(history?.location.search).get('page') || '1';
  const _currentPage: number = parseInt(page);
  const [currentPage] = useState(_currentPage);
  const [limit] = useState(5);
  const [searchParams] = useState<string>('');
  const [statusFilter] = useState<string>('');

  const [
    pedingActionOfAllCategories,
    { data: pedingActions, loading: loaderForPendingAction },
  ] = useLazyQuery(PENDING_ACTIONS_CATEGORIES);

  const [PendingActionScheduler, { loading: loaderForRecount }] = useMutation(
    PENDING_ACTIONS_SCHEDULER
  );

  const [fetchAllNewNotifications] = useLazyQuery(FETCH_NEW_NOTIFICATIONS, {
    onCompleted: (data) => {
      data?.allNewNotifications.notifications?.forEach(
        (n: {
          alertMsg: string;
          createdAt: Date | string;
          id: string;
          notifyableType: string;
          alertType: string;
        }) =>
          toast(
            <NotificationBar
              alertMsg={n.alertMsg}
              date={n.createdAt}
              id={n.id}
              notifyableType={n.notifyableType}
              alertType={n.alertType}
            />,
            {
              position: toast.POSITION.BOTTOM_RIGHT,
              toastId: n.id,
              autoClose: 3000,
              draggable: false,
              closeButton: false,
            }
          )
      );
    },
  });

  const fetchNewtNotifications = () => {
    fetchAllNewNotifications({
      variables: {
        limit,
        page: currentPage,
        searchText: searchParams,
        alertStatus: statusFilter,
        popUpDismissal: false,
      },
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchPendingActions = () => {
    pedingActionOfAllCategories();
  };

  useEffect(() => {
    fetchPendingActions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location?.pathname]);

  useEffect(() => {
    if (!!socket) {
      if (
        !socket.current || !socket.current.connected) {
        socket.current = io(process.env.REACT_APP_WEBSOCKET_BASEURL || "localhost:3001", {
          path: '/websockets',
          transports: ['websocket'],
        });
      }
      socket?.current?.emit('connection', { userId: user?.id });
      socket?.current?.on('heart-beat', (_) => { });
      socket?.current?.on('pendingActionUpdater', (data: any) => {
        setPendingActionFromSockets(data)
      });
      return () => {
        socket?.current?.close();
      };
    }
  }, [pedingActions, user?.id]);

  useEffect(() => {
    fetchNewtNotifications();

    // eslint-disable-next-line
  }, [window.location?.pathname]);

  const toggleNotification = () => setOpen(!isOpen);

  useEffect(() => {
    window.scrollTo(100, 0)
  }, []);

  return (
    <div className="wrapper">
      <Notifications
        isOpen={isOpen}
        toggle={toggleNotification}
        pedingActions={pendingActionFromSockets ? pendingActionFromSockets : pedingActions}
        PendingActionScheduler={PendingActionScheduler}
        loaderForRecount={loaderForRecount}
        pedingActionOfAllCategories={pedingActionOfAllCategories}
        loaderForPendingAction={loaderForPendingAction}
        userId={user?.id}
        pendingActionFromSockets={pendingActionFromSockets}
      />

      <ToastContainer autoClose={3000} />
      <Sidebar
        isOpen={isMenuActive}
        isMenuActive={isMenuActive}
        setIsMenuActive={setIsMenuActive}
      />

      <div
        className={isMenuActive ? 'hamburger is-active' : 'hamburger'}
        onClick={() => {
          setIsMenuActive(!isMenuActive);
        }}
      >
        <span className="line"></span>
        <span className="line"></span>
        <span className="line"></span>
      </div>
      <div
        className="main-container"
        onClick={() => {
          if (isMenuActive) {
            setIsMenuActive(!isMenuActive);
          }
        }}
      >
        {window.localStorage.previousToken && (
          <Alert color="primary" className="p-2 px-3 d-flex text-dark">
            You are viewing account as &nbsp;
            <b className="font-weight-bold">{user?.fullName}</b>,
            <Button
              className="border-0 bg-transparent text-left text-primary py-0 px-1"
              onClick={() => loginAsWithPrevious()}
            >
              <u>click here</u>
            </Button>
            to revert back to your account.
          </Alert>
        )}
        {children}
      </div>
    </div>
  );
};

export default MainLayout;
