import { yupResolver } from '@hookform/resolvers/yup';
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm
} from 'react-hook-form';
import { toast } from 'react-toastify';
import {
  Button,
  Col,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap';
import * as Yup from 'yup';
import DELETE_ICON from '../../../assets/images/delete-icon-menu.svg';
import { AddOutlinedIcon } from '../../../assets/images/svgs';
import CustomButton from '../../../components/layout/CustomButton';
import { URL_REGEX } from '../../../utils/constant';

const validationSchema = Yup.object({
  CreateTjLookUpVideos: Yup.array().of(
    Yup.object().shape({
      videoTitle: Yup.string().required('Video Title is required!'),
      videoUrl: Yup.string().required('Video Link is required!').matches(URL_REGEX, "Invalid URL"),
    })
  ),
});

const AddTjLookupVideoModal = ({
  toggle,
  isOpen,
  addTjLookUpVideos,
  refetchList,
  loading,
}) => {
  const mehtods = useForm({
    defaultValues: { CreateTjLookUpVideos: [{ videoTitle: '', videoUrl: '' }] },
    resolver: yupResolver(validationSchema),
  });
  const { control, reset, handleSubmit, errors } = mehtods;
  const {
    fields: copayFields,
    remove: removeCopayField,
    append: appendCopayField,
  } = useFieldArray({ control, name: 'CreateTjLookUpVideos' });

  const tjookvideoSubmiter = async (data) => {
    let response = await addTjLookUpVideos({
      variables: { CreateTjLookUpVideos: data },
    });
    if (response?.data?.addTjLookUpVideos?.status === 200) {
      toggle();
      reset();
      refetchList();
      toast.success('Video Saved Successfully!');
    } else {
      toggle();
      reset();
      toast.error('Video Can Not Save');
    }
  };
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className="revampDialog revampDialogWidth"
      centered
    >
      <ModalHeader className="py-3">
        <h4>Add New Video</h4>
      </ModalHeader>
      <FormProvider {...mehtods}>
        <form onSubmit={handleSubmit(tjookvideoSubmiter)}>
          <ModalBody className="pt-2 pb-5">
            <Row>
              <Col lg={10}>
                {copayFields.map((_, index) => {
                  return (
                    <Row className="mb-2 pb-2" key={_.id}>
                      <Col lg={5}>
                        <Controller
                          name={`CreateTjLookUpVideos.${index}.videoTitle`}
                          control={control}
                          render={(
                            { onChange, ref, name, value },
                            { invalid }
                          ) => (
                            <div>
                              <Label>
                                Video Title
                                <span className="text-danger"> *</span>
                              </Label>

                              <input
                                name={name}
                                value={value}
                                ref={ref}
                                placeholder="Add Video Title..."
                                onChange={(e) => {
                                  onChange(e);
                                }}
                                className="form-control"
                              />
                              {invalid && (
                                <div className="error">
                                  {
                                    errors?.CreateTjLookUpVideos?.[index]
                                      ?.videoTitle?.message
                                  }
                                </div>
                              )}
                            </div>
                          )}
                        />
                      </Col>

                      <Col lg={5}>
                        <Controller
                          name={`CreateTjLookUpVideos.${index}.videoUrl`}
                          control={control}
                          render={(
                            { onChange, ref, value, name },
                            { invalid }
                          ) => (
                            <div>
                              <Label>
                                Video Link
                                <span className="text-danger"> *</span>
                              </Label>
                              <input
                                name={name}
                                value={value}
                                ref={ref}
                                placeholder="Add Video URL..."
                                onChange={(e) => {
                                  onChange(e);
                                }}
                                className="form-control"
                              />
                              {invalid && (
                                <div className="error">
                                  {
                                    errors?.CreateTjLookUpVideos?.[index]
                                      ?.videoUrl?.message
                                  }{' '}
                                </div>
                              )}
                            </div>
                          )}
                        />
                      </Col>

                      <Col lg={2}>
                        <Button
                          disabled={index === 0}
                          onClick={() => removeCopayField(index)}
                          className="btn bg-transparent p-0 border-0"
                          style={{ marginTop: '2.2rem' }}
                        >
                          <img src={DELETE_ICON} alt="delete" />
                        </Button>
                      </Col>
                    </Row>
                  );
                })}
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <div className="d-flex justify-content-between align-items-center w-100">
              <Button
                onClick={() =>
                  appendCopayField({ videoUrl: '', videoTitle: '' })
                }
                className="btn bg-transparent p-0 border-0"
              >
                <AddOutlinedIcon />
              </Button>
              <div>
                <button
                  className="btn btn-primary buttonPrimary m-2"
                  onClick={() => {
                    reset();
                    toggle();
                  }}
                  type="button"
                >
                  Cancel
                </button>
                <CustomButton
                  buttonText="Save"
                  buttonColor="default"
                  buttonType="submit"
                  className="btn-primary py-2 m-2"
                  disabled={loading}
                />
              </div>
            </div>
          </ModalFooter>
        </form>
      </FormProvider>
    </Modal>
  );
};

export default AddTjLookupVideoModal;
