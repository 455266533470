import ClientCalendarInviteBasicInfo from "./ClientCalendarInviteBasicInfo";
import ClientCalendarInviteDetailedInfo from "./ClientCalendarInviteDetailedInfo";

export default function ClientClendarInviteCombined({
  register,
  errors,
  control,
  clientInterviewEmailData,
  reset,
  clientDefaultInviteFromInterview,
  setPipeSelectedFiles,
  fromEdit,
  onAdditionalFileAttachmentsHandler,
  candidateId,
  candidateName,
  additionalFileAttached,
  setAdditionalFileAttachments,
  watch,
  setValue,
  jobApplicantId,
}) {
  return (
    <div>
      <ClientCalendarInviteBasicInfo
        reset={reset}
        clientDefaultInviteFromInterview={clientDefaultInviteFromInterview}
        clientInterviewEmailData={clientInterviewEmailData}
        register={register}
        errors={errors}
        control={control}
        fromEdit={fromEdit}
        watch={watch}
        setValue={setValue}
        jobApplicantId={jobApplicantId}
      />

      <ClientCalendarInviteDetailedInfo
        setAdditionalFileAttachments={setAdditionalFileAttachments}
        candidateId={candidateId}
        candidateName={candidateName}
        clientInterviewEmailData={clientInterviewEmailData}
        fromEdit={fromEdit}
        setPipeSelectedFiles={setPipeSelectedFiles}
        onAdditionalFileAttachmentsHandler={onAdditionalFileAttachmentsHandler}
        additionalFileAttached={additionalFileAttached}
      />
    </div>
  );
}
